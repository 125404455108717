import React from 'react';

export default function LineNewMessage() {
    return (
        <div className="chat_new_line_wrap" data-testid="new-message-line">
            <div className="chat_new_line" />
            <div className="chat_new_text">NEW</div>
            <div className="chat_new_line" />
        </div>
    );
}
