import React, { ChangeEvent, useEffect, useState } from 'react';
import IconCross from '../../Icons/IconCross';
import IconSearch from '../../Icons/IconSearch';

interface ISearchInput {
    handleSearch: (param: string) => void;
    value: string;
}

export default function SearchInput({ handleSearch, value }: ISearchInput) {
    const [inputValue, setInputValue] = useState<string>(value);

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => handleSearch(inputValue), 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    useEffect(() => {
        if (value === '') {
            setInputValue('');
        }
    }, [value]);

    return (
        <div className="c3lh_search_id_wrap">
            <input
                type="text"
                name="searchField"
                data-testid="id-or-name"
                value={inputValue}
                onChange={onChangeInput}
                placeholder="Search ID or name"
            />
            <button
                type="button"
                onClick={() => setInputValue('')}
                className={inputValue?.length ? 'c3lh_search_close' : 'c3lh_search_search'}
            >
                {inputValue?.length ? <IconCross /> : <IconSearch />}
            </button>
        </div>
    );
}
