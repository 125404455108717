import React from 'react';

export default function IconActiveMeetingRequest() {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="#474F5C"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M20.4083 15.2228C20.8745 14.9898 21.1641 14.5212 21.1641 14V12.6328H22.018C23.5072 12.6328 24.7188 11.4127 24.7188 9.91309V2.71972C24.7188 1.22008 23.5072 0 22.018 0H6.03674C4.54749 0 3.33595 1.22008 3.33595 2.71972V9.91304C3.33595 11.4127 4.54749 12.6328 6.03674 12.6328H6.89063V14C6.89063 14.5212 7.1802 14.9898 7.64641 15.2228C7.84148 15.3204 8.05099 15.3684 8.25924 15.3684C8.54865 15.3684 8.8357 15.2756 9.07813 15.0938L12.3594 12.6328H15.6953L18.9766 15.0938C19.3935 15.4065 19.9421 15.4559 20.4083 15.2228ZM7.71095 10.9922H6.03674C5.45213 10.9922 4.97657 10.5081 4.97657 9.91309V2.71972C4.97657 2.12472 5.45213 1.64062 6.03674 1.64062H22.018C22.6026 1.64062 23.0781 2.12472 23.0781 2.71972V9.91304C23.0781 10.5081 22.6026 10.9921 22.018 10.9921H20.3438C19.8907 10.9921 19.5234 11.3594 19.5234 11.8124V13.4531L16.4609 11.1562C16.319 11.0497 16.1463 10.9921 15.9688 10.9921H12.0859C11.9084 10.9921 11.7357 11.0497 11.5938 11.1562L8.53126 13.4531V11.8124C8.53126 11.3595 8.16398 10.9922 7.71095 10.9922Z" />
            <path d="M11.5165 24.6196C11.3312 23.0535 10.2776 21.7804 8.87221 21.2574C9.17447 20.7884 9.35067 20.231 9.35067 19.6328C9.35067 17.9743 8.00137 16.625 6.34286 16.625C4.68435 16.625 3.33505 17.9743 3.33505 19.6328C3.33505 20.2221 3.50606 20.7718 3.8 21.2364C2.3656 21.7457 1.28531 23.032 1.09751 24.6197L0.806025 27.0833C0.778517 27.3161 0.851798 27.5494 1.00749 27.7246C1.16319 27.8999 1.38631 27.9999 1.62065 27.9999H10.9934C11.2277 27.9999 11.4509 27.8997 11.6065 27.7245C11.7622 27.5494 11.8355 27.316 11.808 27.0832L11.5165 24.6196ZM6.34286 18.2656C7.09673 18.2656 7.71005 18.8789 7.71005 19.6328C7.71005 20.3866 7.09673 20.9999 6.34286 20.9999C5.58899 20.9999 4.97567 20.3866 4.97567 19.6328C4.97567 18.8789 5.58899 18.2656 6.34286 18.2656ZM2.54377 26.3593L2.72681 24.8124C2.87327 23.5742 3.92392 22.6406 5.17069 22.6406H7.44339C8.69016 22.6406 9.74081 23.5742 9.88727 24.8124L10.0703 26.3593H2.54377Z" />
            <path d="M27.194 27.0833L26.9025 24.6196C26.7172 23.0535 25.6636 21.7804 24.2582 21.2574C24.5605 20.7884 24.7367 20.231 24.7367 19.6328C24.7367 17.9743 23.3874 16.625 21.7288 16.625C20.0703 16.625 18.721 17.9743 18.721 19.6328C18.721 20.2221 18.892 20.7718 19.186 21.2364C17.7516 21.7457 16.6713 23.032 16.4835 24.6197L16.192 27.0833C16.1645 27.3161 16.2378 27.5494 16.3935 27.7246C16.5492 27.8999 16.7722 28 17.0066 28H26.3794C26.6137 28 26.8369 27.8998 26.9925 27.7246C27.1482 27.5494 27.2215 27.316 27.194 27.0833ZM21.7288 18.2656C22.4827 18.2656 23.096 18.8789 23.096 19.6328C23.096 20.3867 22.4827 21 21.7288 21C20.975 21 20.3617 20.3867 20.3617 19.6328C20.3617 18.8789 20.9749 18.2656 21.7288 18.2656ZM17.9297 26.3594L18.1127 24.8124C18.2593 23.5743 19.3099 22.6406 20.5566 22.6406H22.8293C24.0761 22.6406 25.1267 23.5743 25.2732 24.8124L25.4562 26.3594H17.9297Z" />
            <path d="M8.03907 5.48975H15.8047C16.2577 5.48975 16.625 5.12246 16.625 4.66943C16.625 4.2164 16.2577 3.84912 15.8047 3.84912H8.03907C7.58604 3.84912 7.21876 4.2164 7.21876 4.66943C7.21876 5.12246 7.58604 5.48975 8.03907 5.48975Z" />
            <path d="M20.8359 7.95068C20.8359 7.49765 20.4687 7.13037 20.0156 7.13037H8.03907C7.58604 7.13037 7.21876 7.49765 7.21876 7.95068C7.21876 8.40371 7.58604 8.771 8.03907 8.771H20.0156C20.4687 8.771 20.8359 8.40371 20.8359 7.95068Z" />
        </svg>
    );
}
