import FrontSyncApi from '../../services/api/v3/FrontSyncApi';
import * as FRONT_SYNC from '../../constants/frontSync';

export default function useSendActionSync() {
    const postUserAction = (recipientExternalID: number, action: string) => {
        if (FRONT_SYNC.ACTION_LIST.includes(action) && recipientExternalID) {
            new FrontSyncApi().postUserActionSync({ recipientExternalID, action });
        }
    };

    return {
        postUserAction,
    };
}
