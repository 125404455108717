import * as purchaseAction from '../../store/actions/purchase';
import * as PURCHASE from '../../constants/purchase';
import * as userAction from '../../store/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import useInitApplePay from './useInitApplePay';
import useGoogleDataLayer from '../event/useGoogleDataLayer';
import MarketingApi from '../../services/api/MarketingApi';
import { RootState } from '../../index';
import { IPurchasePackageItem } from '../../store/reducers/purchase/index.interface';

export default function useBuyCredits() {
    const userState = useSelector((state: RootState) => state.user);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const dispatch = useDispatch();
    const initApplePay = useInitApplePay();
    const { pushEventBeginCheckout } = useGoogleDataLayer();

    const initBuyCreditsSession = (packageBuy: IPurchasePackageItem) => {
        if (packageBuy?.id) {
            const beginCheckoutStore = localStorage.getItem('begin_checkout');

            if (!beginCheckoutStore) {
                localStorage.setItem('begin_checkout', '1');

                new MarketingApi().postCheckExistEvent('begin_checkout').then((res) => {
                    if (res?.status && res?.response?.check) {
                        // Push DataLayer for FB
                        pushEventBeginCheckout();
                    }
                });
            }

            dispatch(purchaseAction.addLastBuyPackage(packageBuy));

            if (purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_APPLEPAY) {
                initApplePay.startApplePaySession(packageBuy);
                return;
            } else if (
                purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_APPLEPAY_PASTABANK
            ) {
                initApplePay.startApplePayPastaSession(packageBuy);
                return;
            }

            if (
                window?.location?.href?.includes('purchase-history') &&
                userState?.isBuying &&
                +userState?.lastBuyCredits
            )
                return;
            dispatch(userAction.addLastBuyCredits(packageBuy?.credits));
            dispatch(userAction.isBuying(true));
        }
    };

    const buyCredits = (credits: number) => {
        const packageBuy = purchaseState?.purchaseList?.find((item) => +item?.credits === +credits);

        if (packageBuy) {
            initBuyCreditsSession(packageBuy);
        }
    };

    const buyCreditsByActivePackage = () => {
        if (purchaseState?.activePackage) {
            initBuyCreditsSession(purchaseState?.activePackage);
        }
    };

    const subscribeByActivePackage = (credits: number) => {
        if (
            window?.location?.href?.includes('purchase-history') &&
            userState?.isBuying &&
            +userState?.lastBuyCredits
        )
            return;
        dispatch(userAction.addLastBuyCredits(credits));
        dispatch(userAction.isBuying(true));
    };

    return {
        buyCredits,
        buyCreditsByActivePackage,
        subscribeByActivePackage,
    };
}
