import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import QuizApi from '../../../../../services/api/v3/QuizApi';
import * as QUIZ from '../../../../../constants/quiz';
import { getUrlParameter } from '../../../../../utils/url-utils';
import SITE from '../../../../../config/site';
import { IPollProps } from './index.interface';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';

export default function Poll({ quizData, typeList, closeQuizPopup }: IPollProps) {
    const [checkDataArray, setCheckDataArray] = useState<any>([]);
    const [ratingLength] = useState<10>(10);
    const [formData, setFormData] = useState<any>([]);

    const checkType = (name: string) => {
        const questionType = typeList.find((type: any) => type?.type === name);
        if (questionType) {
            if (quizData?.question_list)
                return quizData?.question_list.find((el: any) => +el?.type_id === +questionType?.id)
                    ?.type_id;
        }
    };
    const onRatingChange = (name: string, val: number, id: number) => {
        const answerArr = {
            question: name,
            answer: val,
            question_id: id,
            changed: true,
        };

        setFormData([
            ...(formData?.filter((item: any) => item?.question !== answerArr?.question) || []),
            ...[answerArr],
        ]);
    };
    const onInputChange = (
        name: string,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        id: number,
        check = false,
        text = false,
    ) => {
        if (!check) {
            const answerArr = {
                question: name,
                ...(text
                    ? {
                          answer: [QUIZ.OTHER_TITLE],
                          other: event?.target?.value,
                      }
                    : {
                          answer: [event?.target?.value],
                          other:
                              formData?.find((item: any) => +item?.question_id === +id)?.other ??
                              '',
                      }),
                question_id: id,
                changed: true,
            };
            setFormData([
                ...(formData?.filter(
                    (item: any) => +item?.question_id !== +answerArr?.question_id,
                ) || []),
                ...[answerArr],
            ]);
        } else {
            const idx = checkDataArray.find((el: any) => el?.question_id === event?.target?.name);

            if (idx !== undefined) {
                const isExist = () => {
                    const res = text
                        ? QUIZ.OTHER_TITLE
                        : idx.value.find((el: any) => el === event?.target?.value);
                    if (res !== undefined) {
                        return idx?.value?.filter((el: any) => el !== event?.target?.value);
                    } else {
                        return [...(idx?.value || []), event?.target?.value];
                    }
                };
                const newArray = {
                    question_id: event?.target?.name,
                    value: isExist(),
                };

                const answerObject = {
                    ...(text
                        ? {
                              answer: newArray.value,
                              other: event?.target?.value,
                          }
                        : {
                              answer: newArray.value,
                              other:
                                  formData?.find((item: any) => +item?.question_id === +id)
                                      ?.other ?? '',
                          }),
                    question: name,
                    question_id: id,
                    changed: true,
                };

                if (!text)
                    setCheckDataArray([
                        ...(checkDataArray?.filter(
                            (item: any) => item?.question_id !== newArray?.question_id,
                        ) || []),
                        ...[newArray],
                    ]);
                setFormData([
                    ...(formData?.filter(
                        (item: any) => item?.question !== answerObject?.question,
                    ) || []),
                    ...[answerObject],
                ]);
            } else {
                const newArray = {
                    question_id: event?.target?.name,
                    value: [event?.target?.value],
                };

                const answerObject = {
                    ...(text
                        ? {
                              answer: [QUIZ.OTHER_TITLE],
                              other: event?.target?.value,
                          }
                        : {
                              answer: [event?.target?.value],
                              other:
                                  formData?.find((item: any) => +item?.question_id === +id)
                                      ?.other ?? '',
                          }),
                    question: name,
                    question_id: id,
                    changed: true,
                };

                setCheckDataArray([
                    ...(checkDataArray?.filter(
                        (item: any) => item?.question_id !== answerObject?.question_id,
                    ) || []),
                    ...[newArray],
                ]);
                setFormData([
                    ...(formData?.filter(
                        (item: any) => item?.question !== answerObject?.question,
                    ) || []),
                    ...[answerObject],
                ]);
            }
        }
    };
    const generateQuizAnswer = (answerData: any) => {
        if (!Array.isArray(answerData?.answer)) return answerData?.answer;
        else if (!answerData?.answer?.includes(QUIZ.OTHER_TITLE)) return answerData?.answer;
        return answerData?.answer?.map((answerItem: any) =>
            answerItem === QUIZ.OTHER_TITLE
                ? `${answerItem}: ${answerData?.other ?? ''}`
                : answerItem,
        );
    };
    const onSubmitForm = async () => {
        const userExternalID = Number(getUrlParameter('external_id'));
        if (!userExternalID) return;

        const answers = [...formData]
            ?.filter((item) => item?.changed)
            ?.map((item) => ({
                answer: generateQuizAnswer(item),
                question: item?.question,
                question_id: item?.question_id,
            }));

        if (!answers.length) {
            await new QuizApi().sendQuizWithoutAuth({
                external_id: userExternalID,
                quiz_id: quizData?.id,
                answer_type: QUIZ.ANSWER_TYPE_CLOSE_QUIZ,
            });
        } else {
            await new QuizApi().sendQuizWithoutAuth({
                external_id: userExternalID,
                quiz_id: quizData?.id,
                data: JSON?.stringify(answers),
                answer_type: QUIZ.ANSWER_TYPE_ANSWER,
            });
        }
    };
    const titleStartQuiz = () => {
        switch (+quizData?.category) {
            case QUIZ.CATEGORY_AFTER_DEL:
            case QUIZ.CATEGORY_2_WEEK:
                return null;
            case QUIZ.CATEGORY_BEFORE_DEL:
                return (
                    <>
                        <div className={styles.poll_modal_text}>
                            We deeply regret your decision to delete your account. We would like to
                            ask your advice on what we should improve to make other users feel more
                            comfortable.
                        </div>
                        <div className={styles.poll_modal_text}>
                            It will not take you much time, and we will be very grateful to you
                            since your opinion is incredibly important to us!
                        </div>
                    </>
                );
            case QUIZ.CATEGORY_ALL:
            case QUIZ.CATEGORY_BY_EXTERNAL_ID:
            default:
                return (
                    <>
                        <div className={styles.poll_modal_text}>Good afternoon!</div>
                        <div className={styles.poll_modal_text}>
                            We are measuring the level of users’ satisfaction with our service and
                            would like to ask you to take part in a short survey. It won't take more
                            than 2 minutes, but your answers will definitely help us improve your
                            experience of using the site and we will even award you with 25 bonus
                            credits!
                        </div>
                    </>
                );
        }
    };
    const titleEndQuiz = () => {
        switch (+quizData?.category) {
            case QUIZ.CATEGORY_AFTER_DEL:
                return (
                    <>
                        <div className={styles.poll_modal_text}>
                            We thank you for completing the survey and hope that it did not cause
                            you any inconvenience.
                        </div>
                        <div className={styles.poll_modal_text}>
                            If you'd like to write something to us, you can respond to our letter
                            and communicate directly with the site's administration.
                        </div>
                        <div className={styles.poll_modal_text}>
                            With respect and gratitude (Kind Regards), the administration of the{' '}
                            {SITE?.copyrightsText} website!
                        </div>
                    </>
                );
            case QUIZ.CATEGORY_2_WEEK:
                return (
                    <>
                        <div className={styles.poll_modal_text}>
                            Thanks for your time. You will receive 25 credits and your answers will
                            help us make your experience on the site more enjoyable for you!
                        </div>
                        <div className={styles.poll_modal_text}>
                            If you'd like to write something to us, you can respond to our letter
                            and communicate directly with the site's administration.
                        </div>
                        <div className={styles.poll_modal_text}>
                            With respect and gratitude / Kind Regards, the administration of the{' '}
                            {SITE?.copyrightsText} website!
                        </div>
                    </>
                );
            case QUIZ.CATEGORY_BEFORE_DEL:
                return null;
            case QUIZ.CATEGORY_ALL:
            case QUIZ.CATEGORY_BY_EXTERNAL_ID:
            default:
                return (
                    <>
                        <div className={styles.poll_modal_text}>
                            Thanks for your time. You will get 25 credits and your answers will help
                            us make your experience on the site more enjoyable for you!
                        </div>
                        <div className={styles.poll_modal_text}>
                            With respect and gratitude / Kind Regards, administration of the{' '}
                            {SITE?.copyrightsText} website!
                        </div>
                    </>
                );
        }
    };

    useEffect(() => {
        const userExternalID = Number(getUrlParameter('external_id'));
        if (quizData?.id && userExternalID) {
            new QuizApi().sendQuizWithoutAuth({
                external_id: userExternalID,
                quiz_id: quizData?.id,
                answer_type: QUIZ.ANSWER_TYPE_OPEN_QUIZ,
            });
        }
        if (quizData?.question_list) {
            setFormData(
                quizData?.question_list.map((item: any) => ({
                    question: item.title,
                    answer: null,
                    question_id: item.id,
                    changed: false,
                })),
            );
        }
    }, [quizData]);

    useEffect(() => {
        const inputs = document.querySelectorAll('input[type=checkbox], input[type=radio]');

        inputs.forEach((input) => {
            const inputElement = input as HTMLInputElement;
            inputElement.addEventListener('change', () => {
                inputElement.checked &&
                    inputElement?.parentNode?.querySelector('input[type=text]') &&
                    (
                        inputElement?.parentNode?.querySelector(
                            'input[type=text]',
                        ) as HTMLInputElement
                    )?.focus();
            });
        });
    }, []);

    return (
        <div className={styles.poll_modal}>
            <div
                className={`${styles.poll_modal_wrap} ${+quizData?.category === QUIZ.CATEGORY_BEFORE_DEL ? styles.delete_modal : ''}`}
            >
                <div className={styles.poll_modal_title}>{quizData?.name}</div>
                <div className={styles.poll_modal_subtitle}>
                    It is important for us to constantly improve our site to please you
                </div>
                {titleStartQuiz()}
                <div className={styles.poll_modal_questions_list}>
                    {!!quizData?.question_list?.length &&
                        quizData?.question_list?.map((item: any, key: number) => {
                            return (
                                <div
                                    key={`${item?.title}_${key}`}
                                    className={styles.poll_modal_question_item}
                                >
                                    <div className={styles.poll_modal_question_title}>
                                        {item?.title}
                                    </div>
                                    <div className={styles.poll_modal_question_answer}>
                                        {item?.type_id === checkType('text') && (
                                            <textarea
                                                value={
                                                    formData.find(
                                                        (el: any) => +el?.question_id === +item?.id,
                                                    )?.answer || ''
                                                }
                                                onChange={(event) =>
                                                    onInputChange(item?.title, event, item?.id)
                                                }
                                                id={item?.id}
                                                placeholder="Tell us your thoughts here"
                                            />
                                        )}
                                        {!!(
                                            item?.type_id === checkType('radio') &&
                                            JSON.parse(item?.sub_list)?.length
                                        ) && (
                                            <>
                                                {JSON.parse(item?.sub_list)?.map(
                                                    (list: any, key: number) => {
                                                        return (
                                                            <div
                                                                key={`${list}_${key}`}
                                                                className={
                                                                    styles.poll_modal_question_answer_inp
                                                                }
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name={item?.title}
                                                                    value={list}
                                                                    id={`${item?.title}_${key}`}
                                                                    onChange={(event) =>
                                                                        onInputChange(
                                                                            item?.title,
                                                                            event,
                                                                            item?.id,
                                                                        )
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor={`${item?.title}_${key}`}
                                                                >
                                                                    {list}
                                                                </label>
                                                            </div>
                                                        );
                                                    },
                                                )}
                                                <div
                                                    key={`other_${key}`}
                                                    className={
                                                        styles.poll_modal_question_answer_inp
                                                    }
                                                >
                                                    <input
                                                        type="radio"
                                                        name={item?.title}
                                                        value={QUIZ.OTHER_TITLE}
                                                        onChange={(event) =>
                                                            onInputChange(
                                                                item?.title,
                                                                event,
                                                                item?.id,
                                                            )
                                                        }
                                                        id={`${item?.title}_${key}_other`}
                                                    />
                                                    <label htmlFor={`${item?.title}_${key}_other`}>
                                                        Other
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter your option"
                                                        name={item?.title}
                                                        value={
                                                            formData?.find(
                                                                (formDataItem: any) =>
                                                                    +formDataItem?.question_id ===
                                                                    +item?.id,
                                                            )?.other ?? ''
                                                        }
                                                        id={`${item?.title}_${key}_other_text`}
                                                        onChange={(event) =>
                                                            onInputChange(
                                                                item?.title,
                                                                event,
                                                                item?.id,
                                                                false,
                                                                true,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {item?.type_id === checkType('checkbox') &&
                                            JSON.parse(item?.sub_list)?.length && (
                                                <>
                                                    {JSON.parse(item?.sub_list).map(
                                                        (list: any, key: number) => {
                                                            return (
                                                                <div
                                                                    key={`${list}_${key}`}
                                                                    className={
                                                                        styles.poll_modal_question_answer_inp
                                                                    }
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        name={item?.title}
                                                                        value={list}
                                                                        onChange={(event) =>
                                                                            onInputChange(
                                                                                item?.title,
                                                                                event,
                                                                                item?.id,
                                                                                true,
                                                                            )
                                                                        }
                                                                        id={`${item?.title}_${key}`}
                                                                    />
                                                                    <label
                                                                        htmlFor={`${item?.title}_${key}`}
                                                                    >
                                                                        {list}
                                                                    </label>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                    <div
                                                        key={`other_${key}`}
                                                        className={
                                                            styles.poll_modal_question_answer_inp
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name={item?.title}
                                                            value={QUIZ.OTHER_TITLE}
                                                            onChange={(event) =>
                                                                onInputChange(
                                                                    item?.title,
                                                                    event,
                                                                    item?.id,
                                                                    true,
                                                                )
                                                            }
                                                            id={`${item?.title}_${key}_other`}
                                                        />
                                                        <label
                                                            htmlFor={`${item?.title}_${key}_other`}
                                                        >
                                                            Other
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter your option"
                                                            name={item?.title}
                                                            value={
                                                                formData?.find(
                                                                    (formDataItem: any) =>
                                                                        +formDataItem?.question_id ===
                                                                        +item?.id,
                                                                )?.other ?? ''
                                                            }
                                                            id={`${item?.title}_${key}_other_text`}
                                                            onChange={(event) =>
                                                                onInputChange(
                                                                    item?.title,
                                                                    event,
                                                                    item?.id,
                                                                    true,
                                                                    true,
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        {item?.type_id === checkType('rate') && (
                                            <div className={styles.poll_modal_rating}>
                                                {[...Array(ratingLength)].map((x, i) => {
                                                    return (
                                                        <React.Fragment key={`star_rate_${i}`}>
                                                            <label
                                                                htmlFor={`${item?.id}_star_${i + 1}`}
                                                            >
                                                                <svg
                                                                    viewBox="0 0 513 490"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M510.316 186.567L510.316 186.567C511.964 191.635 510.59 197.198 506.774 200.917L507.472 201.633L506.774 200.917L391.913 312.872L391.536 313.239L391.625 313.757L418.742 471.838C419.643 477.091 417.482 482.4 413.171 485.532C408.86 488.664 403.146 489.077 398.427 486.597L256.466 411.96L256.001 411.715L255.536 411.96L113.569 486.598L114.034 487.483L113.568 486.598C111.521 487.675 109.285 488.206 107.056 488.206C104.153 488.206 101.265 487.304 98.8259 485.533C94.5146 482.4 92.355 477.093 93.2566 471.84L92.271 471.671L93.2566 471.84L120.365 313.757L120.453 313.239L120.077 312.872L5.22902 200.918L5.22893 200.918C1.41256 197.199 0.0386992 191.635 1.68603 186.566L1.68606 186.566C3.33245 181.498 7.71446 177.804 12.9866 177.039L171.707 153.974L172.227 153.898L172.46 153.427L243.448 9.59958C245.806 4.82092 250.673 1.79599 256.002 1.79599C261.33 1.79599 266.198 4.82097 268.555 9.59943L269.452 9.15699L268.555 9.59957L339.54 153.427L339.773 153.898L340.293 153.974L499.016 177.039L499.16 176.049L499.016 177.039C504.289 177.804 508.669 181.498 510.316 186.567Z" />
                                                                </svg>
                                                            </label>
                                                            <input
                                                                onChange={() =>
                                                                    onRatingChange(
                                                                        item?.title,
                                                                        i + 1,
                                                                        item?.id,
                                                                    )
                                                                }
                                                                type="radio"
                                                                value={i + 1}
                                                                checked={
                                                                    formData.find(
                                                                        (el: any) =>
                                                                            +el?.question_id ===
                                                                            +item?.id,
                                                                    )?.answer ===
                                                                        i + 1 ||
                                                                    (formData?.find(
                                                                        (el: any) =>
                                                                            +el?.question_id ===
                                                                            +item?.id,
                                                                    )?.answer === null &&
                                                                        i + 1 === 5)
                                                                        ? true
                                                                        : false
                                                                }
                                                                name={`star_radio_${item?.id}`}
                                                                id={`${item?.id}_star_${i + 1}`}
                                                            />
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {titleEndQuiz()}
                <div
                    className={styles.poll_modal_send_btn}
                    onClick={() => {
                        onSubmitForm();
                        closeQuizPopup();
                    }}
                >
                    <IconCheckedCircle />
                    <span>
                        {+quizData?.category === QUIZ.CATEGORY_BEFORE_DEL
                            ? 'Rate us and delete profile'
                            : 'Rate us!'}
                    </span>
                </div>
            </div>
            <div
                className={styles.poll_modal_close}
                onClick={() => {
                    const userExternalID = Number(getUrlParameter('external_id'));
                    if (userExternalID) {
                        new QuizApi().sendQuizWithoutAuth({
                            external_id: userExternalID,
                            quiz_id: quizData?.id,
                            answer_type: QUIZ.ANSWER_TYPE_CLOSE_QUIZ,
                        });
                    }
                    closeQuizPopup();
                }}
            />
        </div>
    );
}
