import React from 'react';
import GiftsCatalogItem from '../GiftsCatalogItem';
import { ICategoryItem, IGiftItem } from '../../../store/reducers/gifts/index.interface';

interface IGiftsCatalogCategory {
    category: ICategoryItem;
    gifts: IGiftItem[];
    addToCart: (item: IGiftItem) => void;
    openItemModal: (item: IGiftItem) => void;
}

export default function GiftsCatalogCategory({
    category = {
        id: 0,
        name: 'string',
    },
    gifts,
    addToCart,
    openItemModal,
}: IGiftsCatalogCategory) {
    return (
        <>
            <div className="c3gp_gifts_page_main_title">{category?.name || ''}</div>
            <div className="c3gp_gifts_page_main_list c3gp_gifts_page_main_list_pop">
                {gifts.map((item, key) => (
                    <GiftsCatalogItem
                        key={key}
                        item={item}
                        addToCart={addToCart}
                        openItemModal={openItemModal}
                    />
                ))}
            </div>
        </>
    );
}
