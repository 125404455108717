import { useDispatch, useSelector } from 'react-redux';
import * as SEX from '../../constants/sex';
import { WHO_LIKED_YOU } from '../../constants/dictionary';
import { useState } from 'react';
import { changeFilterType } from '../../store/actions/likes';
import { closeMobChatList } from '../../services/dom';
import * as likesAction from '../../store/actions/likes';
import * as userAction from '../../store/actions/user';
import * as FILTER from '../../constants/filter';
import * as modalsAction from '../../store/actions/modals';
import { RootState } from '../../index';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { FilterType } from '../../constants/filter';

export default function useHandleLikeHerFilter() {
    const [wideTab, setWideTab] = useState(false);
    const userState = useSelector((state: RootState) => state.user);
    const likesState = useSelector((state: RootState) => state.likes);

    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();
    const isWomanProfile = userState?.info?.gender === 1;

    const dictionary = {
        // @ts-expect-error userState?.info?.sex_text
        ONLINE: SEX.SEX_TEXT[userState?.info?.sex_text].LIKEHER_FAST_FILTER_ALL_ONLINE,
        LIKED_YOU: WHO_LIKED_YOU,
    };

    const changeTabWide = () => {
        if (!wideTab) {
            dispatch(changeFilterType('WIDE'));
            setDefaultFilterValue('WIDE');
            setWideTab(true);
        }
    };
    const changeTabNoWide = () => {
        if (wideTab) {
            dispatch(changeFilterType('ONLINE'));
            setDefaultFilterValue('ONLINE');
            setWideTab(false);
        }
    };

    const setFilterType = (filterType: 'ONLINE' | 'LIKED_YOU') => {
        dispatch(changeFilterType(filterType));
        setDefaultFilterValue(filterType);
        dispatch(modalsAction.setActiveSubMenu());
        closeMobChatList();
    };

    const setDefaultFilterValue = (newTab?: 'WIDE' | 'ONLINE' | 'LIKED_YOU') => {
        const isNewWideTab = newTab === 'WIDE';
        //When user clicks the button, Wide filter
        const sexFilterInit = {
            ...(isNewWideTab && userState?.info?.sex ? { sexFilter: userState?.info?.sex } : {}),
        };
        //When user clicks the button, Clears all filters
        const sexFilterReset =
            wideTab && !newTab?.length ? { sexFilter: isWomanProfile ? 4 : 1 } : {};

        const defaultGenderFilter: typeof FILTER.LIKE_FILTERS_GENDER_DEFAULT = {
            ...FILTER.LIKE_FILTERS_GENDER_DEFAULT,
            ...(Object.keys(sexFilterInit).length ? sexFilterInit : sexFilterReset),
            ...(newTab?.length ? { type: newTab } : { type: wideTab ? 'WIDE' : 'ONLINE' }),
        };

        const defaultNOTGenderFilter: typeof FILTER.LIKE_FILTERS_DEFAULT = {
            ...FILTER.LIKE_FILTERS_DEFAULT,
            ...(newTab?.length ? { type: newTab } : { type: wideTab ? 'WIDE' : 'ONLINE' }),
        };

        const filter: FilterType = handleActiveBlocks.isSearchV3
            ? defaultGenderFilter
            : defaultNOTGenderFilter;

        if (newTab?.length) {
            dispatch(likesAction.setFilter(filter));
        } else {
            dispatch(likesAction.initLikesState(filter));
            //For the old filter, which has a search button
            if (!handleActiveBlocks.isSearchV3) {
                dispatch(likesAction.getLikeList(1, defaultNOTGenderFilter));
                dispatch(likesAction.setLikeListPage(1));
            }
        }

        if (filter?.sexFilter) {
            dispatch(userAction.updateInfoSex(filter?.sexFilter));
        }
    };

    const submitFilter = (isCloseMobSideMenu = false) => {
        dispatch(likesAction.getLikeList(1, likesState?.filter));
        dispatch(likesAction.setLikeListPage(1));

        if (isCloseMobSideMenu) {
            dispatch(modalsAction.setActiveSubMenu());
            closeMobChatList();
        }
    };

    return {
        wideTab,
        changeTabNoWide,
        changeTabWide,
        setFilterType,
        dictionary,
        setDefaultFilterValue,
        submitFilter,
    };
}
