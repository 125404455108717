import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import RadioButtonInput from './RadioButtonInput/RadioButtonInput';

interface IInitialList {
    id: number;
    status: number;
    value: string;
}

interface IModifiedList {
    id: number;
    status: number;
    title: string;
}

interface IOnboardingRadioButton {
    initList: IInitialList[];
    value: number;
    onChange: (value: number) => void;
    onClick: () => void;
    activeStep: number;
    maxStep: number;
    title: string;
    skipStep: () => void;
}

const OnboardingRadioButton = ({
    initList,
    value,
    onChange,
    onClick,
    activeStep,
    maxStep,
    title,
    skipStep,
}: IOnboardingRadioButton) => {
    const [list, setList] = useState<IModifiedList[]>([]);
    const [disabledButton, setDisabledButton] = useState(true);

    const onChangeID = (id: number) => {
        onChange(id);
        setList(
            list?.map((item) =>
                item?.id === id ? { ...item, status: 1 } : { ...item, status: 0 },
            ),
        );
    };

    useEffect(() => {
        setDisabledButton(!list?.filter((item) => !!item?.status)?.length);
    }, [list]);

    useEffect(() => {
        if (initList?.length) {
            setList(initList?.map((item) => ({ title: item?.value, status: 0, id: item?.id })));
        }
    }, [initList]);

    useEffect(() => {
        if (value) {
            setList(
                initList?.map((item) =>
                    value === item?.id
                        ? {
                              title: item?.value,
                              status: 1,
                              id: item?.id,
                          }
                        : { title: item?.value, status: 0, id: item?.id },
                ),
            );
        }
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>Pick one option</p>
            <div className={styles.list}>
                {list?.map((item, key) => (
                    <RadioButtonInput {...item} key={key} onChangeID={onChangeID} />
                ))}
            </div>

            <div className={styles.button_row}>
                <button
                    className={`${styles.button} ${disabledButton ? styles.disabled : ''}`}
                    onClick={onClick}
                    disabled={disabledButton}
                >
                    Continue
                </button>
                <button className={`${styles.button} ${styles.skip}`} onClick={skipStep}>
                    Skip
                </button>
            </div>
        </div>
    );
};

export default OnboardingRadioButton;
