import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AttachModal from '../../../../../common/AttachModal';
import modalStyleProps from '../../../../../../styles/react-modal/modal.style';
import ChatsApi from '../../../../../../services/api/ChatsApi';
import { switchChatList } from '../../../../../../services/methods';
import * as filesAction from '../../../../../../store/actions/files';
import * as currentChatAction from '../../../../../../store/actions/currentChat';
import * as alertAction from '../../../../../../store/actions/alert';
import * as ERROR from '../../../../../../constants/error';
import * as ALERT from '../../../../../../constants/alert';
import * as chatsAction from '../../../../../../store/actions/chats';
import { insertedMessageBeforeSync } from '../../../../../../services/init';
import * as userAction from '../../../../../../store/actions/user';
import * as notifyAction from '../../../../../../store/actions/notify';
import { RED_USER_LOG_TYPES } from '../../../../../../constants/user';
import useHandleRedUser from '../../../../../../hooks/user/useHandleRedUser';
import { RootState } from '../../../../../../index';

export default function Attach() {
    const dispatch = useDispatch();
    const handleRedUser = useHandleRedUser();

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const userState = useSelector((state: RootState) => state.user);
    const chatsState = useSelector((state: RootState) => state.chats);
    const menuState = useSelector((state: RootState) => state.menu);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const openModal = () => {
        setModalIsOpen(true);
        dispatch(filesAction.getAllImages());
        dispatch(filesAction.getAllVideos());
        dispatch(filesAction.getAllAudios());
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const sendFile = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: string) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.id) return;

        const checkedList =
            event?.currentTarget?.parentNode?.parentNode?.querySelectorAll('input:checked');

        if (checkedList?.length) {
            closeModal();

            for (const checkedItem of checkedList) {
                const hashId = moment().unix();
                const element = checkedItem as HTMLElement;

                await new ChatsApi()
                    .postMessage(
                        userState?.info?.external_id,
                        currentChatState?.info?.id,
                        element?.dataset?.link || '',
                        type,
                        element?.dataset?.filename || '',
                    )
                    .then((res) => {
                        if (res?.status) {
                            if (!userState?.info?.external_id) return;
                            if (!currentChatState?.info?.chat_uid) return;
                            if (!res?.response?.message_object) {
                                dispatch(
                                    alertAction.setMessage({
                                        message: res?.message || ERROR.ERROR_REQUEST,
                                    }),
                                );
                                dispatch(currentChatAction.deleteMessageInHistory(hashId));
                                return;
                            }
                            dispatch(
                                currentChatAction.addMessageToHistory(
                                    insertedMessageBeforeSync(
                                        hashId,
                                        element?.dataset?.link || '',
                                        userState?.info?.external_id,
                                        type,
                                    ),
                                ),
                            );

                            dispatch(
                                currentChatAction.updateMessageInHistory(
                                    hashId,
                                    res?.response?.message_object,
                                ),
                            );
                            dispatch(
                                chatsAction.getChatListByUserId(
                                    userState?.info?.external_id,
                                    chatsState?.onlineStatus,
                                    chatsState?.searchInput,
                                    switchChatList(menuState?.sub),
                                ),
                            );
                            dispatch(
                                notifyAction.deleteNotify(
                                    userState?.info?.external_id,
                                    currentChatState?.info?.chat_uid,
                                ),
                            );
                            dispatch(userAction.setBalance());
                            handleRedUser.submitLog(
                                RED_USER_LOG_TYPES.MESSAGE_FROM,
                                JSON.stringify({
                                    womanExternalID: currentChatState?.info?.id,
                                    action: type,
                                    content: element?.dataset?.link || '',
                                }),
                            );
                        } else if (res?.block) {
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.NOT_EXIST_OR_BLOCK,
                                }),
                            );
                        } else {
                            dispatch(
                                alertAction.setMessage({
                                    message: res?.message || ERROR.ERROR_REQUEST,
                                    title: res?.title,
                                    isPaymentBtn: res?.activePayment,
                                }),
                            );
                            dispatch(currentChatAction.deleteMessageInHistory(hashId));
                        }
                    });
            }
        } else {
            dispatch(alertAction.setMessage({ message: 'File not selected' }));
        }
    };

    return (
        <>
            <div className="c3_attach" onClick={openModal} data-testid="add-files-btn">
                <div className="c3_attach_img" />
                <div className="c3_attach_text">Add files</div>
            </div>
            <Modal
                style={modalStyleProps({ isAttachment: true })}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <AttachModal
                    closeModal={closeModal}
                    sendImg={(e) => sendFile(e, 'SENT_IMAGE')}
                    sendVideo={(e) => sendFile(e, 'SENT_VIDEO')}
                    sendAudio={(e) => sendFile(e, 'SENT_AUDIO')}
                />
            </Modal>
        </>
    );
}
