import React, { MouseEventHandler, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoaderGif from '../../../LoaderGif';
import {
    getLegalEntityInfo,
    getRoutingRefundPolicy,
} from '../../../../../services/methods/purchase';
import * as userAction from '../../../../../store/actions/user';
import { RED_USER_LOG_TYPES } from '../../../../../constants/user';
import useTransition from '../../../../../hooks/transition/useTransition';
import useHandleRedUser from '../../../../../hooks/user/useHandleRedUser';
import {
    BILLING_ACTION_CLOSE_PAYMENT_ROUTING_MODAL,
    BILLING_ACTION_OPEN_PAYMENT_ROUTING_MODAL,
} from '../../../../../constants/billing';
import useTransactionConfig from '../../../../../hooks/transaction/useTransactionConfig';
import useHandleBilling from '../../../../../hooks/billing/useHandleBilling';
import useSuccessPaymentCallback from '../../../../../hooks/purchase/useSuccessPaymentCallback';
import SITE from '../../../../../config/site';
import PaymentApi from '../../../../../services/api/v3/PaymentApi';
import useInitSubscribe from '../../../../../hooks/purchase/subscribe/useInitSubscribe';
import useGoogleDataLayer from '../../../../../hooks/event/useGoogleDataLayer';
import useHandleZendesk from '../../../../../hooks/ze/useHandleZendesk';
import { sendErrToSentry } from '../../../../../services/sentry';
import { RootState } from '../../../../../index';
import { localStorageGetItem } from '../../../../../utils/local-storage';
import IconCross from '../../../../Icons/IconCross';

export default function PaymentRouting({ onClose }: { onClose: MouseEventHandler }) {
    const [paymentData, setPaymentData] = useState<{
        userToken: string | null | Record<never, never>;
    }>({
        userToken: null,
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const userState = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();
    const transition = useTransition();
    const handleRedUser = useHandleRedUser();
    const transactionConfig = useTransactionConfig();
    const handleBilling = useHandleBilling();
    const successPaymentCallback = useSuccessPaymentCallback();
    const initSubscribe = useInitSubscribe();
    const { pushEventTryPay } = useGoogleDataLayer();
    const { openZendesk } = useHandleZendesk();

    const paymentLink =
        window?.location?.host?.includes('stage') || window?.location?.host?.includes('localhost')
            ? `https://dev-payment.${SITE.key}.com`
            : `https://payment.${SITE.key}.com`;

    const removeScript = () => {
        const existScript = document.getElementById('sphera-script');
        if (existScript) existScript?.parentNode?.removeChild(existScript);

        window.sphera = undefined;
    };

    const createScript = () => {
        try {
            const script = document.createElement('script');

            script.id = 'sphera-script';
            script.src = `${paymentLink}/lib.min.js`;

            document.body.appendChild(script);
            script.addEventListener('load', fetchData);
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const onPaymentDone = async () => {
        if (+userState?.abtest && !userState?.info?.ghost)
            transactionConfig.fetchTransactionConfig();

        const lastPurchase = await new PaymentApi()
            .getLastPurchase()
            .then((res) => (res?.status ? res?.data : null));
        if (lastPurchase) {
            successPaymentCallback({
                count: lastPurchase?.packages,
                credits: lastPurchase?.lastPurchase?.amount,
                price: lastPurchase?.lastPurchase?.price,
                isRouting: true,
            });
            handleRedUser.submitLog(
                RED_USER_LOG_TYPES.PAYMENT_SUCCESS,
                JSON.stringify({ payment_method: 'sphera', ...lastPurchase }),
            );
        }

        dispatch(userAction.getInfo(true));
        initSubscribe.initSubscribeStatus();
    };

    const onPaymentError = async () => {
        const lastPurchase = await new PaymentApi()
            .getLastPurchase()
            .then((res) => (res?.status ? res?.data : null));
        if (lastPurchase) {
            if (lastPurchase?.packages > 0) transition.addTail('?utm_credits_issue');

            handleRedUser.submitLog(
                RED_USER_LOG_TYPES.PAYMENT_DECLINE,
                JSON.stringify({ payment_method: 'sphera', ...lastPurchase }),
            );

            if (lastPurchase?.packages === 0) {
                pushEventTryPay();
            }
        }

        dispatch(userAction.getInfo());
    };

    const fetchData = () => {
        try {
            const userToken = localStorageGetItem('token', true);

            if (userToken && window.sphera) {
                setPaymentData({ userToken });
                setIsLoading(false);
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    useEffect(() => {
        if (paymentData?.userToken) {
            try {
                window.sphera.init(paymentLink, paymentData?.userToken, {
                    policyLink: getRoutingRefundPolicy(),
                    corpDesc: getLegalEntityInfo(),
                    merchantDomain: window?.location?.host,
                    onClose,
                    onPaymentDone,
                    onPaymentError,
                    openZendesk,
                });
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }
    }, [paymentData]);

    useEffect(() => {
        createScript();
        handleBilling.createBillingLog(BILLING_ACTION_OPEN_PAYMENT_ROUTING_MODAL);
        // window.addEventListener('beforeunload', () =>
        //     handleBilling.createBillingLog(BILLING_ACTION_CLOSE_PAYMENT_ROUTING_MODAL),
        // );

        return () => {
            removeScript();
            handleBilling.createBillingLog(BILLING_ACTION_CLOSE_PAYMENT_ROUTING_MODAL);
            // window.removeEventListener('beforeunload', () =>
            //     handleBilling.createBillingLog(BILLING_ACTION_CLOSE_PAYMENT_ROUTING_MODAL),
            // );
        };
    }, []);

    return (
        <div className={styles.routing_onb_wrapper}>
            <div className={styles.routing_onb_item}>
                <div id="sphera-form" className={styles.routing_onb_item_form} />
                {isLoading && (
                    <div className={styles.loader}>
                        <LoaderGif />
                    </div>
                )}
                <div className={styles.routing_onb_cross} onClick={onClose}>
                    <IconCross />
                </div>
            </div>
        </div>
    );
}
