import React from 'react';
import { formatDate } from '../../../../../services/time';
import styles from '../styles.module.scss';
import IconCross from '../../../../Icons/IconCross';
import IconChat from '../../../../Icons/IconChat';
import { IIsOnlineHiNotifyProps } from './index.interface';
import placeholder from '../../../../../img/placeholder.gif';

export default function IsOnlineHiNotify({ item, deleteNotify, openChat }: IIsOnlineHiNotifyProps) {
    return (
        <>
            <li
                className={`${styles.notify_item} ${styles.activity} ${styles.is_online} ${styles.is_online_hi} ${styles.online}`}
            >
                <div className={styles.notify_item_photo_wrap}>
                    <img
                        src={item?.avatar || placeholder}
                        alt="user avatar"
                        className={styles.notify_item_photo}
                    />
                </div>
                <div className={styles.notify_item_right}>
                    <p className={`${styles.notify_item_text}`}>
                        <span className={styles.green}>{item?.name ?? ''} is online now! </span>
                        Maybe you want to say hi?
                    </p>
                </div>
                <button
                    type="button"
                    className={styles.notify_item_activity_btn}
                    onClick={() => openChat(item, item?.sender_external_id)}
                >
                    <IconChat />
                    <span>Go to chat</span>
                </button>
                <button
                    type="button"
                    className={styles.notify_item_clear_mask}
                    onClick={() => deleteNotify(item)}
                >
                    <IconCross />
                </button>
                <p className={styles.notify_item_time}>{formatDate(item?.date_created)}</p>
            </li>
        </>
    );
}
