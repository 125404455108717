import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAvatarMiniature,
    renderLastMessage,
    stringToColor,
    substrName,
} from '../../../../services/methods';
import Emoji from 'react-emoji-render';
import { scrollTopToMainChatWithPagination } from '../../../../services/dom';
import { formatDate } from '../../../../services/time';
import * as modalsAction from '../../../../store/actions/modals';
import AudioPlayer from '../../AudioPlayer';
import * as STICKERS from '../../../../constants/stickers';
import * as CHATS from '../../../../constants/chats';
import { filterToHideChatHistoryByType, stopAudio } from '../../../../services/methods/chat';
import { IMessageRightProps } from './index.interface';
import { RootState } from '../../../../index';
import wink from '../../../../img/wink.svg';
import ChatMedia from '../ChatMedia';

export default function MessageRight({ message }: IMessageRightProps) {
    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const dispatch = useDispatch();

    const selectedSticker = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
        e?.name?.includes(message?.message_content),
    )?.image;
    const selectedStickerName = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
        e?.name?.includes(message?.message_content),
    )?.name;

    const openImageModal = () => {
        if (message?.message_popup) {
            dispatch(
                modalsAction.openPhotoContentModal({
                    link: message?.message_popup,
                    attachment_payed: '1',
                }),
            );
        }
    };

    const openVideoModal = () => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: message?.message_content,
                attachment_payed: '1',
            }),
        );
    };

    useEffect(() => {
        if (currentChatState?.history?.length) {
            if (!currentChatState?.isNewMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            } else if (!currentChatState?.isReadLastMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            }
        }
    }, [currentChatState?.history]);

    return (
        <div
            className={`chat_message_wrap right
                ${message?.message_type === CHATS.MESSAGE_CONTENT_TYPE_STICKER ? 'sticker' : ''}
                ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}
            `}
            id={`mess-${message?.id}`}
            data-testid="sent-message"
        >
            <div
                className={`chat_message ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}`}
            >
                <div className="message_content" data-testid="message-content">
                    {(function () {
                        switch (message?.message_type) {
                            case CHATS.MESSAGE_CONTENT_TYPE_IMAGE:
                                return (
                                    <div className="message_text" data-testid="message-image">
                                        <ChatMedia
                                            mediaUrl={message?.message_thumb}
                                            onClickCallback={openImageModal}
                                            dataTestId={message?.message_content}
                                        />
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_VIDEO: {
                                const hasThumbLink = !!message?.thumb_link?.length;
                                const mediaUrl = hasThumbLink
                                    ? message?.thumb_link
                                    : message?.message_content;

                                return (
                                    <div className="message_text" data-testid="message-video">
                                        <div className="message_video" onClick={openVideoModal}>
                                            <ChatMedia
                                                mediaUrl={mediaUrl}
                                                isVideo={!hasThumbLink}
                                                dataTestId={message?.message_content}
                                            />
                                        </div>
                                    </div>
                                );
                            }

                            case CHATS.MESSAGE_CONTENT_TYPE_AUDIO:
                                return (
                                    <div className="message_text" data-testid="message-audio">
                                        <div className="message_audio_wrap">
                                            <AudioPlayer
                                                src={message?.message_content}
                                                viewedAudio={() =>
                                                    stopAudio(
                                                        message?.message_content,
                                                        true,
                                                        message?.id,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_WINK:
                                if (message?.message_content?.includes('wink')) {
                                    return (
                                        <div
                                            className="message_text wink"
                                            data-testid="message-wink"
                                        >
                                            <img
                                                src={wink}
                                                width="90px"
                                                height="100px"
                                                alt=""
                                                className="message_img"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="message_text" data-testid="message-emoji">
                                            <Emoji text={message?.message_content} />
                                        </div>
                                    );
                                }

                            case CHATS.MESSAGE_CONTENT_TYPE_TEXT:
                            case CHATS.MESSAGE_CONTENT_TYPE_ASK_VIDEO:
                                // eslint-disable-next-line no-case-declarations
                                const lines = message?.message_content.split('\n');

                                return (
                                    <div className="message_text" data-testid="message-text">
                                        {!!+lines?.length &&
                                            lines.map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_STICKER:
                                return (
                                    <div className="message_text" data-testid="message-sticker">
                                        {selectedSticker && (
                                            <img
                                                src={selectedSticker}
                                                data-testid={selectedStickerName}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_LIKE:
                                return (
                                    <div className="message_text like" data-testid="message-like">
                                        <img src="../../../../img/c4-like.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_VIEW:
                                return (
                                    <div className="message_text like" data-testid="message-view">
                                        <img src="../../../../img/c4-viwed.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            default:
                                return null;
                        }
                    })()}
                    <div className="message_bottom">
                        {message?.date_created && (
                            <div className="message_date" data-testid="message-date">
                                {formatDate(message?.date_created)}
                            </div>
                        )}
                        {message?.read_status !== undefined && (
                            <div
                                className={`message_status ${+message?.read_status === 0 ? 'send' : 'readed'}`}
                                data-testid="message-status"
                            />
                        )}
                    </div>
                </div>
                <div className="message_avatar" data-testid="message-avatar">
                    {userState?.info?.photo_link ? (
                        <img src={getAvatarMiniature(userState?.info?.photo_link)} alt="" />
                    ) : (
                        <div
                            style={{ backgroundColor: stringToColor(userState?.info?.name || '') }}
                        >
                            {substrName(userState?.info?.name)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
