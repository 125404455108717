import React from 'react';
import '../../styles/online-now.scss';
import OnlineNow from '../../components/online-now';
import Banner from '../../components/Banner';

export default function OnlineNowPage() {
    return (
        <div className="column-3 online_now_page c3_fx">
            <Banner />
            <OnlineNow />
        </div>
    );
}
