import image_mp_free_reg from '../../img/mp-free-reg.svg';
import image_mp_visual_content from '../../img/mp-visual-content.svg';
import image_mp_tools from '../../img/mp-tools.svg';
import image_mp_commun from '../../img/mp-commun.svg';
import image_mp_profiles from '../../img/mp-profiles.svg';
import image_mp_meeting from '../../img/mp-meeting.svg';
import image_mp_safe_pay from '../../img/mp-safe-pay.svg';
import image_mp_support from '../../img/mp-support.svg';
// import mp_video_shows from '../../img/mp-video-shows.svg';

export const PRODUCT_VERSION = process.env.REACT_APP_PRODUCT_VERSION || '0.1.1';
export const REGISTRATION_FORM_TITLE = 'Register for free today';
export const REGISTRATION_FORM_DOB_SELECT_DAY = 'DD';
export const REGISTRATION_FORM_DOB_SELECT_MONTH = 'MM';
export const REGISTRATION_FORM_DOB_SELECT_YEAR = 'YYYY';
export const REGISTRATION_FORM_LOOKING_SELECT_MALE = 'Man';
export const REGISTRATION_FORM_LOOKING_SELECT_FEMALE = 'Woman';
export const REGISTRATION_FORM_LOOKING_SELECT_BOTH = 'Both';
export const REGISTRATION_FORM_GENDER_TITLE = 'I am a..';

// Policy Pages Menu
export const POLICY_MENU_TERMS = 'Terms and Conditions';
export const POLICY_MENU_PRIVACY_POLICY = 'Privacy Policy';
export const POLICY_MENU_COOKIES_POLICY = 'Cookies Policy';
export const POLICY_MENU_REFUND_POLICY = 'Refund Policy';
export const POLICY_MENU_STANDARTS = 'Community Standards';
export const POLICY_MENU_AUTOMATIC_TOPUP = 'Automatic Top-up';
export const POLICY_MENU_ONE_CLICK_UNLIMINT = 'One click unlimint';
export const POLICY_MENU_COMPLAINT_POLICY = 'Complaint Policy';

// Categories block
export const CATEGORIES_TITLE = 'The Most Popular Categories';

// Questions block
export const ADVANTAGES_LIST = [
    {
        image: image_mp_free_reg,
        title: 'Free registration',
        description:
            'Registration is cost-free and any <br/>addition feels are completely transparent',
    },
    {
        image: image_mp_visual_content,
        title: 'Thousands of free photos',
        description: 'Free photo galleries are available for every category',
    },
    // {
    //     image: mp_video_shows,
    //     title: 'Great video shows',
    //     description:
    //         'See your chat partner in real life <br/>as if there was no distance between you',
    // },
    {
        image: image_mp_tools,
        title: 'Variety of communication tools',
        description: 'Use chats, letters, and likes to search and communicate with other members',
    },
    {
        image: image_mp_commun,
        title: 'Passion for communication',
        description: 'All participants are longing for lasting communication',
    },
    {
        image: image_mp_profiles,
        title: 'Manually verified profiles',
        description: "All members' profiles are manually verified by our moderation team",
    },
    {
        image: image_mp_meeting,
        title: 'Constant access to correspondence',
        description: 'Your correspondence always stays yours. Chats and letters are stored forever',
    },
    {
        image: image_mp_safe_pay,
        title: 'Safe payments',
        description: 'Maximum safety and privacy during communication and payments',
    },
    {
        image: image_mp_support,
        title: '24/7 support',
        description:
            'Our support team is always at your disposal with round-the-clock service to help with any possible issues',
    },
];

// Girls video block
export const GIRLS_VIDEO_GIRL_ITEM_CHAT_BUTTON = 'Chat';
export const GIRLS_VIDEO_GIRL_ITEM_FREE_LABEL = 'Free';

export const FOOTER_COOKIE = 'Cookie policy';
export const FOOTER_PRIVACY_POLICY = 'Privacy Policy';
export const FOOTER_TERMS = 'TERMS AND CONDITIONS';
export const FOOTER_REFUND = 'Refund Policy';
export const FOOTER_AFFILIATES = 'For Affiliates';
export const FOOTER_STANDARTS = 'Community Standards';
export const FOOTER_AUTOMATIC_TOP_UP = 'Automatic Top-up';
export const FOOTER_DISPUTE_RESOLUTION = 'Online Dispute Resolution';
export const LINK_FACEBOOK = 'https://www.facebook.com/sofiadateblog/';
export const LINK_INSTAGRAM = 'https://www.instagram.com/sofia_date/';
export const LINK_TWITTER = 'https://twitter.com/date_sofia';
export const LINK_YOUTUBE = 'https://www.youtube.com/channel/UCVz0JuxuImIvNF3Qn4JMy5g';
