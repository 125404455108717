import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLogout from '../logout/useLogout';
import * as loaderAction from '../../store/actions/loader';
import QuizApi from '../../services/api/v3/QuizApi';
import * as QUIZ from '../../constants/quiz';
import * as quizAction from '../../store/actions/quiz';
import LoginApi from '../../services/api/LoginApi';
import useHandleZendesk from '../ze/useHandleZendesk';
import { RootState } from '../../index';

export default function useInitDeleteProfileModal() {
    const dispatch = useDispatch();
    const logout = useLogout();
    const { zendeskDeleteProfile } = useHandleZendesk();

    const userState = useSelector((state: RootState) => state.user);

    const [isDeleteProfileOpen, setIsDeleteProfileOpen] = useState(false);
    const [deletionStep, setDeletionStep] = useState(1);

    const openDeleteProfileModal = async () => {
        if (userState?.info?.payment_total) {
            zendeskDeleteProfile();
        } else {
            setIsDeleteProfileOpen(true);
        }
    };
    const closeDeleteProfileModal = () => setIsDeleteProfileOpen(false);

    const openQuizModal = () => {
        closeDeleteProfileModal();
        dispatch(loaderAction.setActiveGifLoader(true));
        new QuizApi()
            .getQuizByCategory(QUIZ.CATEGORY_BEFORE_DEL)
            .then((res) => {
                if (res?.status && res?.result) {
                    dispatch(quizAction.getQuizData(res?.result));
                    dispatch(quizAction.getTypeList(res?.type_list));
                    dispatch(
                        quizAction.setIsActiveBeforeDeletingQuizModal(true, () => {
                            openDeleteProfileModal();
                            setDeletionStep(2);
                        }),
                    );
                }
            })
            .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
    };

    const acceptDeleting = () => {
        new LoginApi().postDelete().then((res) => {
            if (res?.status) {
                closeDeleteProfileModal();
                logout.handleClickLogout();
            }
        });
    };

    const closeModal = () => {
        setDeletionStep(1);
        closeDeleteProfileModal();
    };

    return {
        isDeleteProfileOpen,
        openDeleteProfileModal,
        closeDeleteProfileModal,
        deletionStep,
        closeModal,
        acceptDeleting,
        openQuizModal,
    };
}
