import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TransactionApi from '../../services/api/v3/TransactionApi';
import * as CONFIG from '../../constants/config';
import * as SYNC from '../../constants/sync';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';

export default function useInitInviteLimit() {
    const userState = useSelector((state: RootState) => state.user);

    useEffect(() => {
        try {
            if (
                !userState?.info?.convert_user &&
                +userState?.parallel_sender &&
                userState?.info?.invite_limit &&
                Array.isArray(userState?.sender_manual) &&
                !+userState?.info?.ghost
            ) {
                if (+userState?.sender_manual?.length === +userState?.info?.invite_limit) {
                    userState.sender_manual.forEach((item, key) => {
                        setTimeout(() => {
                            new TransactionApi().postAskTransaction({
                                type: SYNC.INVITE_ACTION,
                                delay: key > 0 ? +item - +userState?.sender_manual[key - 1] : item,
                                lifeTime: 10,
                                configType: CONFIG.CONFIG_TYPE_INFINITY,
                                isInvite: true,
                            });
                        }, 1000 * +item);
                    });
                } else {
                    const diffSenderManual =
                        +userState?.sender_manual?.length - +userState?.info?.invite_limit;
                    const newSenderManual = userState?.sender_manual.slice(diffSenderManual);

                    if (newSenderManual?.length) {
                        newSenderManual.forEach((item, key) => {
                            let initTimer = 1000 * +item;

                            if (key === 0 && diffSenderManual === 1)
                                initTimer = 1000 * 60; //1 min
                            else if (key === 0 && diffSenderManual > 1) initTimer = 1000 * 60 * 2; //2 min

                            setTimeout(() => {
                                new TransactionApi().postAskTransaction({
                                    type: SYNC.INVITE_ACTION,
                                    delay:
                                        key > 0
                                            ? (+initTimer -
                                                  1000 * +userState?.sender_manual[key - 1]) /
                                              1000
                                            : initTimer / 1000,
                                    lifeTime: 10,
                                    configType: CONFIG.CONFIG_TYPE_INFINITY,
                                    isInvite: true,
                                });
                            }, initTimer);
                        });
                    }
                }
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }, [userState.sender_manual]);
}
