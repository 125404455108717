import { FormEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useSocialGoogle from '../social/useSocialGoogle';

import LoginApi from '../../services/api/LoginApi';
import { getUrlParameter } from '../../utils/url-utils';
import * as loaderAction from '../../store/actions/loader';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as MIRROR from '../../constants/mirror';
import * as LENGTH from '../../constants/length';
import { checkDate, checkDaysAmount, checkEmailAPI, findAge } from '../../services/methods';
import useAuthRedirect from '../transition/useAuthRedirect';
import SITE from '../../config/site';
import * as REGISTER from '../../constants/register';
import useAddTail from '../utm/useAddTail';
import useDetectedDevice from '../device/useDetectedDevice';
import useGetVConfig from '../v/useGetVConfig';
import useGetCaptchaConfig from '../captcha/useGetCaptchaConfig';
import { isMobile } from 'react-device-detect';
import { redirectToMobileVersion } from '../../services/methods/mobile';
import * as UTM from '../../constants/utm';
import ReCAPTCHA from 'react-google-recaptcha';
import { IUseInitRegister } from './index.interface';
import useTransition from '../transition/useTransition';

export default function useInitRegister(
    handleFormDataLoginEmail: (value: string) => void,
): IUseInitRegister {
    const defaultGender: 'Man' | 'Woman' =
        SITE.ID === 4 ? REGISTER.GENDER_FEMALE_TITLE : REGISTER.GENDER_MALE_TITLE;

    const initialStateFormDataRegister = {
        name: '',
        ...(MIRROR.WITH_NICKNAME.includes(SITE.ID) ? { username: '' } : {}), //for mirror with nickname/username
        email: '',
        password: '',
        bDay: 0,
        bMonth: 0,
        bYear: 0,
        gender: defaultGender, //Title
        agree: false,
    };

    const dispatch = useDispatch();
    const redirect = useAuthRedirect();
    const addTail = useAddTail();
    const transition = useTransition();
    // Check if need to show recaptcha on site
    const { hasCaptcha } = useGetCaptchaConfig();
    const { isNotAndroid, mobileModel, mobileVendor } = useDetectedDevice();
    const { hasV } = useGetVConfig();

    const [formDataRegister, setFormDataRegister] = useState(initialStateFormDataRegister);
    const [modalIsActiveRegister, setModalIsActiveRegister] = useState(false);
    const [isOpenEmailUsedModal, setIsOpenEmailUsedModal] = useState(false);
    const [emailUsedModalText, setEmailUsedModalText] = useState('');
    const [errorFields, setErrorFields] = useState<string[]>([]);

    const history = useHistory();

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const scrollToRegisterForm = () => {
        const registerForm = document.querySelector('#root');

        if (registerForm) registerForm.scrollIntoView();
    };

    const openRegisterModal = (link: string | null = null, profileID: string | null = null) => {
        if (SITE.ID === 1) {
            setErrorFields([]);
            link && localStorage.setItem('goto', JSON.stringify(link));
        } else {
            if (profileID) addTail('redirect_url', profileID);
        }

        setModalIsActiveRegister(true);
    };

    const closeRegisterModal = () => {
        setErrorFields([]);
        setModalIsActiveRegister(false);
        // setFormDataRegister(initialStateFormDataRegister);
    };

    const openEmailUsedModal = () => setIsOpenEmailUsedModal(true);
    const closeEmailUsedModal = () => setIsOpenEmailUsedModal(false);

    const filterErrorFields = (elem: string) => {
        if (errorFields?.length && errorFields?.includes(elem)) {
            setErrorFields(errorFields?.filter((e) => e !== elem));
        }
    };

    const onBirthdayChange = (value: number, field: string) => {
        const userBirthDate = { ...formDataRegister, [field]: value };
        if (!checkDaysAmount(userBirthDate.bDay, userBirthDate.bMonth, userBirthDate.bYear)) {
            setFormDataRegister({ ...formDataRegister, ...{ [field]: value, bDay: 0 } });
        } else {
            setFormDataRegister({ ...formDataRegister, ...{ [field]: value } });
        }
    };

    const checkValidFields = () => {
        const arr = [];

        for (const el in formDataRegister) {
            if (
                !formDataRegister?.[el as keyof typeof formDataRegister] &&
                // @ts-expect-error length
                !+formDataRegister?.[el as keyof typeof formDataRegister]?.length &&
                +!formDataRegister?.[el as keyof typeof formDataRegister] > 0
            ) {
                arr.push(el);
                dispatch(alertAction.setMessage({ message: `All fields are required` }));
            } else if (el === 'username') {
                const value = formDataRegister?.[el] || '';
                const minLength = LENGTH.MIN_NICKNAME;
                const maxLength = LENGTH.MAX_NICKNAME;
                // only Latin, numbers, symbols: "_", "."
                const regExp = new RegExp('^[a-z0-9_.]{' + minLength + ',' + maxLength + '}$');

                if (!regExp.test(value)) {
                    arr.push(el);
                    dispatch(
                        alertAction.setMessage({
                            message: `Length must be between ${minLength} and ${maxLength} characters, allowing lowercase Latin letters, numbers, and symbols: "_", "."`,
                        }),
                    );
                }
            }
        }

        setErrorFields(arr);
        return !arr?.length;
    };

    const addUtmTags = () => {
        const utmNetw = getUrlParameter(UTM.UTM_NETW_KEY);
        const utmAff = getUrlParameter('utm_aff');
        const isSeoReferrer =
            !!document.referrer?.length && !document.referrer?.includes(window?.location?.hostname);
        const years = findAge(
            +formDataRegister?.bYear,
            +formDataRegister?.bMonth,
            +formDataRegister?.bDay,
        );
        let genderUtm = formDataRegister?.gender === REGISTER.GENDER_FEMALE_TITLE ? 'fem' : 'man';
        genderUtm = `${genderUtm}${years >= 45 ? '_45' : '_not_45'}`;

        addTail('utm_gen', genderUtm);
        addTail('utm_real_age', years?.toString());

        if (isNotAndroid && years >= 45) addTail('utm_os', 'target');

        if (!utmNetw && !utmAff && isSeoReferrer) addTail(UTM.UTM_NETW_KEY, UTM.UTM_NETW_VALUE);
    };

    const checkUserEmail = async () => {
        const vCount = localStorage.getItem('v_count') || 0;

        if (+vCount >= REGISTER.MAX_V_COUNT) {
            dispatch(alertAction.setMessage({ message: 'Please enter real email' }));
            return false;
        }

        dispatch(loaderAction.setActiveGifLoader(true));
        const res = await checkEmailAPI(formDataRegister?.email, hasV);
        dispatch(loaderAction.setActiveGifLoader(false));
        const isEmailInUse = res?.status;
        const isVError = res?.v_error;
        const isVLimitError = res?.v_limit;

        if (isEmailInUse === undefined) {
            dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
            return false;
        } else if (isEmailInUse) {
            if (handleFormDataLoginEmail) handleFormDataLoginEmail(formDataRegister?.email);
            setEmailUsedModalText(
                'This email is in use, please choose another email for registration or sign in your account',
            );
            if (SITE.ID === 4) {
                dispatch(
                    alertAction.setMessage({
                        message:
                            'This email is in use, please choose another email for registration or sign in your account',
                    }),
                );
                return false;
            }
            openEmailUsedModal();
            if (modalIsActiveRegister) closeRegisterModal();
            return false;
        }

        if (hasV) {
            if (isVError && !isVLimitError) {
                if (+vCount < REGISTER.MAX_V_COUNT) {
                    localStorage.setItem('v_count', (+vCount + 1)?.toString());
                }
                dispatch(alertAction.setMessage({ message: 'Please enter real email.' }));
                return false;
            } else {
                sessionStorage.setItem('is_real_email', '1');
            }
        }

        if (formDataRegister?.password?.length < 8) {
            dispatch(
                alertAction.setMessage({
                    message: 'Please enter a password of 8 or more characters',
                }),
            );
            return false;
        }

        return true;
    };

    const onSubmitFormRegister = async (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (!checkValidFields()) return false;

        //await POST to the server for check valid recaptcha token
        if (hasCaptcha) {
            const recaptchaToken = await recaptchaRef?.current?.executeAsync();
            recaptchaRef?.current?.reset();
            if (recaptchaToken) {
                const checkRecaptchaRes = await new LoginApi().postCheckRecaptchaV2(recaptchaToken);
                if (!checkRecaptchaRes?.success) {
                    return dispatch(
                        alertAction.setMessage({
                            message: 'Your response to the CAPTCHA appears to be invalid',
                        }),
                    );
                }
            }
        }

        if (!(await checkUserEmail())) return;
        if (!checkDate(formDataRegister?.bYear, formDataRegister?.bMonth, formDataRegister?.bDay)) {
            return dispatch(
                alertAction.setMessage({
                    message: `You must be at least ${REGISTER.MIN_AGE} years old`,
                }),
            );
        }

        dispatch(loaderAction.setActiveGifLoader(true));

        addUtmTags();
        const genderId =
            REGISTER.GENDER_ARRAY.find((el) => el?.title === formDataRegister?.gender)?.id || 0;

        return new LoginApi()
            .postRegister({
                userRealName: formDataRegister?.name,
                username: formDataRegister?.username,
                password: formDataRegister?.password,
                email: formDataRegister?.email,
                day: formDataRegister?.bDay,
                month: formDataRegister?.bMonth,
                year: formDataRegister?.bYear,
                gender: genderId,
                mobileModel,
                mobileVendor,
            })
            .then((res) => {
                dispatch(loaderAction.setActiveGifLoader(false));
                if (res?.forbiddenInfo?.redirect) {
                    return transition.transitionWithOnlyHistoryPush(
                        `/forbidden?country_name=${res?.forbiddenInfo?.countryName}`,
                    );
                }
                if (res?.status) {
                    localStorage.setItem('fingerprintHash', res?.fingerprint);
                    localStorage.setItem('token', JSON.stringify(res?.token));
                    addTail('registration', 'success');
                    if (res?.forMobile && res?.accessKey?.length && isMobile) {
                        return redirectToMobileVersion(res?.accessKey, `/${redirect}`);
                    }
                    history.push(`/${redirect}${window?.location?.search}`);
                } else {
                    if (res?.error_code === 'EMAIL_IN_USE') {
                        if (handleFormDataLoginEmail)
                            handleFormDataLoginEmail(formDataRegister?.email);
                        setEmailUsedModalText(res?.message);
                        if (SITE.ID === 4) {
                            return dispatch(
                                alertAction.setMessage({
                                    message: res?.message || res?.error || ERROR.ERROR_REQUEST,
                                }),
                            );
                        }
                        openEmailUsedModal();
                        if (modalIsActiveRegister) closeRegisterModal();
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || res?.error || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                }
            })
            .catch(() => dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST })))
            .finally(() => {
                dispatch(loaderAction.setActiveGifLoader(false));
                sessionStorage.removeItem('is_real_email');
            });
    };

    const { postResponseGoogle } = useSocialGoogle(`/${redirect}`, mobileModel, mobileVendor);

    return {
        formDataRegister,
        setFormDataRegister,
        onSubmitFormRegister,
        modalIsActiveRegister,
        openRegisterModal,
        closeRegisterModal,
        isOpenEmailUsedModal,
        closeEmailUsedModal,
        emailUsedModalText,
        postResponseGoogle,
        scrollToRegisterForm,
        errorFields,
        filterErrorFields,
        onBirthdayChange,
        hasCaptcha,
        recaptchaRef,
    };
}
