import React, { MouseEventHandler } from 'react';
import LoaderGif from '../../../LoaderGif';
import { useSelector } from 'react-redux';
import {
    A_CREDIT_POLICY,
    DEFAULT_CREDIT_POLICY,
    HIDE_CREDIT_POLICY,
} from '../../../../../constants/terms';
import { RootState } from '../../../../../index';
import styles from './styles.module.scss';

export default function TermCredits({ close }: { close: MouseEventHandler<HTMLDivElement> }) {
    const userState = useSelector((state: RootState) => state.user);

    const isUser = userState?.info?.external_id;
    const isNewMail = userState?.info?.newMail;
    const page = isUser
        ? isNewMail
            ? A_CREDIT_POLICY
            : DEFAULT_CREDIT_POLICY
        : HIDE_CREDIT_POLICY;

    return page ? (
        <div className={styles.term_popup_wrap}>
            <div className={styles.term_popup_title}>Credit cost policy</div>
            <div className={styles.term_popup_content} dangerouslySetInnerHTML={{ __html: page }} />
            <div className={styles.term_popup_close} onClick={close} />
        </div>
    ) : (
        <LoaderGif />
    );
}
