import { RequestsActionTypes } from '../../constants/requests';
import { IRequestState, RequestsActions } from './index.interface';

const initState = {
    showContactRequestModal: false,
    contactRequestProfile: {},
    activeContactRequests: [],
};

export default function (state: IRequestState = initState, action: RequestsActions): IRequestState {
    switch (action.type) {
        case RequestsActionTypes.SHOW_CONTACT_REQUEST_MODAL: {
            return {
                ...state,
                showContactRequestModal: true,
                contactRequestProfile: { ...action.data },
            };
        }

        case RequestsActionTypes.CLOSE_CONTACT_REQUEST_MODAL: {
            return {
                ...state,
                showContactRequestModal: false,
                contactRequestProfile: {},
            };
        }

        case RequestsActionTypes.SET_ACTIVE_CONTACT_REQUESTS: {
            return {
                ...state,
                activeContactRequests: action.data,
            };
        }

        default:
            return state;
    }
}
