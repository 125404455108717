import { sendErrToSentry } from '../../services/sentry';

export function getUrlParameter(sParam: string) {
    try {
        const sPageURL = window?.location?.search?.substring(1),
            sURLVariables = sPageURL?.split('&');
        let sParameterName;

        for (let i = 0; i < sURLVariables?.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined
                    ? true
                    : decodeURIComponent(sParameterName[1]);
            }
        }
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}
