import React, { MouseEventHandler } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../index';
import user_birthday from '../../../../../img/user_birthday.svg';
import IconCross from '../../../../Icons/IconCross';

export default function UserBirthday({
    closeModal,
    birthdayBonusCredits,
}: {
    closeModal: MouseEventHandler<HTMLDivElement>;
    birthdayBonusCredits: number;
}) {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <div className={styles.user_birthday_modal_wrap}>
            <div className={styles.modal_title}>CONGRATULATION!</div>
            <img
                src={user_birthday}
                alt="user birthday image"
                className={styles.user_birthday_modal_img}
            />
            <div className={styles.modal_text}>
                <p>Dear {userState?.info?.name}!</p>
            </div>
            {(function () {
                switch (+birthdayBonusCredits) {
                    case 100:
                        return (
                            <div className={styles.modal_text}>
                                <p>
                                    We know that as your birthday, this day is very special to you!
                                    We would like to sincerely congratulate you and wish you the
                                    best in another wonderful year. May life bring you the greatest
                                    joy and never-ending happiness. As a special gift, we would very
                                    much like to allow you to get closer to your sweetheart today.
                                    Please use the 100 credits we've added to your account to have
                                    an amazing chat with your significant other!
                                </p>
                            </div>
                        );
                    case 20:
                        return (
                            <div className={styles.modal_text}>
                                <p>
                                    Don't think for a second that we forgot that today is the
                                    greatest holiday for you! Congratulations and best wishes on
                                    your birthday, friend! May it bring a smile to your face and
                                    passionate love to your heart! Reach out to your beloved member
                                    with the 20 free credits we've gifted to you and share your
                                    happiness with her in a chat session right now!
                                </p>
                            </div>
                        );
                    default:
                        return (
                            <div className={styles.modal_text}>
                                <p>You received {birthdayBonusCredits} credits!</p>
                            </div>
                        );
                }
            })()}
            <div className={styles.modal_buttons}>
                <div className={`${styles.modal_btn} ${styles.color_btn}`} onClick={closeModal}>
                    Close
                </div>
            </div>
            <div className={styles.close_modal} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
