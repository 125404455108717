import { SyncActionTypes } from '../../constants/sync';
import { ISyncState, SyncActions } from './index.interface';

const initState = {
    provider: null,
    lastProvider: null,
};

export default function (state: ISyncState = initState, action: SyncActions): ISyncState {
    switch (action.type) {
        case SyncActionTypes.INIT_LAST:
            return {
                ...state,
                ...{
                    lastProvider: state.provider,
                },
            };
        case SyncActionTypes.CLEAR_LAST:
            return {
                ...state,
                ...{
                    lastProvider: null,
                },
            };
        case SyncActionTypes.INIT:
            return {
                ...state,
                ...{
                    provider: action.data,
                },
            };
        case SyncActionTypes.REMOVE:
            return {
                ...state,
                ...{
                    provider: action.data,
                },
            };
        default:
            return state;
    }
}
