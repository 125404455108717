import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatMailboxAction from '../../../store/actions/currentChatMailbox';
import * as alertAction from '../../../store/actions/alert';
import styles from './LikeItemSimple.module.scss';
import MailboxApi from '../../../services/api/MailboxApi';
import * as MENU from '../../../constants/menu';
import * as ERROR from '../../../constants/error';
import * as ALERT from '../../../constants/alert';
import useTransition from '../../../hooks/transition/useTransition';
import * as modalsAction from '../../../store/actions/modals';
import useLike from '../../../hooks/likes/useLike';
import useOpenVideoShow from '../../../hooks/search/useOpenVideoShow';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import IconChat from '../../Icons/IconChat';
import IconPicture from '../../Icons/IconPicture';
import IconProfile from '../../Icons/IconProfile';
import IconLetters from '../../Icons/IconLetters';
import IconVideos from '../../Icons/IconVideos';
import IconLikeActive from '../../Icons/IconLikeActive';
import IconLike from '../../Icons/IconLike';
import { RootState } from '../../../index';
import { ILikeHistoryItem } from '../../../store/reducers/likes/index.interface';
import { IAddMailboxChatInfoPayload } from '../../../store/actions/currentChatMailbox/index.interface';
import VerifiedProfile from '../../common/VerifiedProfile';

interface IProfile extends ILikeHistoryItem {
    [key: string]: any;
}

interface LikeItemSimple {
    profile: IProfile;
    isLikeDisabled?: boolean;
}

export default function LikeItemSimple({ profile, isLikeDisabled = false }: LikeItemSimple) {
    const userState = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();
    const transition = useTransition();
    const like = useLike();
    const { openVideo } = useOpenVideoShow(profile?.external_id);

    const mainImage = profile?.search_img;
    const isVideo = !!(handleActiveBlocks.isVideoShow && !!+profile?.has_video);

    const redirectProfile = (e: MouseEvent<HTMLButtonElement>) => {
        if (!profile?.external_id) return;

        e.stopPropagation();
        e.preventDefault();

        dispatch(modalsAction.openWomanProfileModal(profile?.external_id));
    };

    const openLetter = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        dispatch(
            currentChatMailboxAction.addMailboxChatInfo({
                ...profile,
                sender_image: profile.avatar ?? profile.photo_link,
                sender_name: profile?.name,
                sender_age: profile?.age,
                sender_city: profile?.city_name ?? profile?.country_name,
                sender_gifts: profile?.gifts,
                sender_search_img: profile?.link,
                sender_id: profile?.external_id,
                female_id: +profile?.external_id,
                male_id: userState?.info ? +userState?.info?.external_id : 0,
                openFromChat: true,
            } as Partial<IAddMailboxChatInfoPayload>),
        );
        dispatch(
            currentChatMailboxAction.getMailboxChatHistory({
                userId: userState?.info ? +userState?.info?.external_id : 0,
                girlId: profile?.public_external_id,
                page: 1,
            }),
        );
        transition.transitionWithHistoryPush('letter', `/letter/`);
    };

    const onSetLike = async () => {
        if (profile?.liked || isLikeDisabled) return;
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        like(profile?.external_id);
    };

    const openMiniChat = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        new MailboxApi()
            .createChatWithProfile(
                userState?.info ? userState?.info?.external_id : 0,
                profile?.external_id,
            )
            .then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                        }),
                    );
                }
            });
    };

    const openGalleryModal = () => {
        if (!profile?.has_image) return false;

        dispatch(modalsAction.openWomanGalleryModal(profile?.external_id, profile?.images));
    };

    if (!profile?.external_id) return null;
    return (
        <li
            className={styles.search_item_wrap}
            id={`search-item-${profile?.external_id}`}
            data-testid={`result-item-${profile?.external_id}`}
        >
            <div className={styles.search_item_photo_wrap} onClick={openGalleryModal}>
                <img
                    className={styles.search_item_photo}
                    src={mainImage}
                    alt={profile?.name ?? ''}
                />
                {!!profile?.has_image && (
                    <p
                        className={styles.search_item_photo_all_btn}
                        data-testid="item-all-photo-btn"
                    >
                        <IconPicture />
                        <span>All </span>photos
                    </p>
                )}
            </div>

            {profile?.online ? (
                <div className={styles.search_item_online_status} data-testid="item-online-status">
                    I’m online now
                </div>
            ) : (
                <div />
            )}

            <div className={styles.search_item_white_bg} />
            <div className={styles.search_item_bottom}>
                <div className={styles.search_item_name_wrap}>
                    <h4 className={styles.search_item_name} data-testid="item-name">
                        {profile?.name ?? ''}, {profile?.age ?? ''}
                    </h4>
                    <VerifiedProfile isSearch />
                </div>

                <div className={styles.search_item_big_buttons}>
                    <button
                        type="button"
                        className={styles.search_item_chat_btn}
                        onClick={openMiniChat}
                        data-testid="item-chat-btn"
                    >
                        <IconChat />
                        <span>Chat Now</span>
                    </button>
                    <button
                        type="button"
                        className={`${styles.search_item_like_btn} ${profile?.liked || isLikeDisabled ? styles.active : ''}`}
                        onClick={onSetLike}
                        data-testid="item-like-btn"
                    >
                        {profile?.liked || isLikeDisabled ? <IconLikeActive /> : <IconLike />}
                        <span>{profile?.liked || isLikeDisabled ? 'Liked' : 'Like'}</span>
                    </button>
                </div>
                <div
                    className={`${styles.search_item_small_buttons} ${isVideo ? '' : styles.no_video}`}
                >
                    <button
                        type="button"
                        className={`${styles.search_item_letter} ${styles.search_item_small_btn}`}
                        onClick={openLetter}
                        data-testid="item-letter-btn"
                    >
                        <IconLetters />
                        <span>Letter</span>
                    </button>
                    <button
                        type="button"
                        className={styles.search_item_small_btn}
                        onClick={redirectProfile}
                        data-testid="item-profile-btn"
                    >
                        <IconProfile />
                        <span>Profile</span>
                    </button>

                    {isVideo && (
                        <button
                            type="button"
                            className={styles.search_item_small_btn}
                            onClick={openVideo}
                            data-testid="item-video-btn"
                        >
                            <IconVideos />
                            <span>Video</span>
                        </button>
                    )}
                </div>
            </div>
        </li>
    );
}
