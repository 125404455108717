import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactsApi from '../../services/api/v3/Contacts';
import * as userAction from '../../store/actions/user';
import * as alertAction from '../../store/actions/alert';
import * as loaderAction from '../../store/actions/loader';
import ProfileApi from '../../services/api/ProfileApi';
import * as SUPPORT from '../../constants/support';
import * as ERROR from '../../constants/error';
import SITE from '../../config/site';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import * as ALERT from '../../constants/alert';

export default function useInitVerifyStatus() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const initPhoneData =
        !!userState?.info?.is_approve_phone && userState?.info?.phone?.length
            ? userState?.info?.phone?.slice(1)
            : '';
    const initVerifyCode = { code1: '', code2: '', code3: '', code4: '' };
    const initIsValidContact = !!userState?.info?.is_approve_phone;
    const initEmailData = userState?.info?.email || '';

    const [isActiveVerifyStatusModal, setIsActiveVerifyStatusModal] = useState(false);
    const [isActiveConfirmPhoneModal, setIsActiveConfirmPhoneModal] = useState(false);
    const [isVerifyPhoneCodeModal, setIsVerifyPhoneCodeModal] = useState(false);
    const [isPhoneCodeCanceled, setIsPhoneCodeCanceled] = useState(false);
    const [isActiveConfirmEmailModal, setIsActiveConfirmEmailModal] = useState(false);
    const [isActiveConfirmNameModal, setIsActiveConfirmNameModal] = useState(false);
    const [phoneData, setPhoneData] = useState(initPhoneData);
    const [isValidContact, setIsValidContact] = useState(initIsValidContact);
    const [verifyCode, setVerifyCode] = useState(initVerifyCode);
    const [emailData, setEmailData] = useState(initEmailData);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [nameData, setNameData] = useState('');

    const isVerifiedAllStatusCondition =
        !!userState?.info?.is_approve_phone &&
        !!+userState?.info?.email_confirmed &&
        !!userState?.info?.real_name?.length;

    const openVerifyStatusModal = () => setIsActiveVerifyStatusModal(SITE.ID !== 23);
    const closeVerifyStatusModal = () => setIsActiveVerifyStatusModal(false);

    const openConfirmPhoneModal = () => setIsActiveConfirmPhoneModal(true);
    const closeConfirmPhoneModal = () => {
        setIsActiveConfirmPhoneModal(false);
        setIsPhoneCodeCanceled(false);
    };

    const openVerifyPhoneCodeModal = () => setIsVerifyPhoneCodeModal(true);
    const closeVerifyPhoneCodeModal = () => {
        setVerifyCode(initVerifyCode);
        setIsVerifyPhoneCodeModal(false);
    };

    const openConfirmEmailModal = () => setIsActiveConfirmEmailModal(true);
    const closeConfirmEmailModal = () => {
        setIsActiveConfirmEmailModal(false);
        setIsEmailSent(false);
    };

    const openConfirmNameModal = () => setIsActiveConfirmNameModal(true);
    const closeConfirmNameModal = () => setIsActiveConfirmNameModal(false);

    const switchPhoneNumber = () => {
        switch (userState?.info?.country_code) {
            case 'US':
                return { phone: SUPPORT.SUPPORT_PHONE_US, code: 'US' };
            case 'CA':
                return { phone: SUPPORT.SUPPORT_PHONE_CA, code: 'CA' };
            case 'GB':
                return { phone: SUPPORT.SUPPORT_PHONE_GB, code: 'GB' };
            default:
                return { phone: SUPPORT.SUPPORT_PHONE_US, code: 'US' };
        }
    };

    const changePhoneData = (value: string, isValid: boolean) => {
        setIsValidContact(isValid);
        setPhoneData(value);
    };

    const handleVerifyCode = (event: ChangeEvent<HTMLInputElement>) => {
        if (isNaN(+event?.target?.value)) return;
        setVerifyCode({ ...verifyCode, ...{ [event?.target?.name]: event?.target?.value } });

        const nextSibling = event?.target?.nextSibling as HTMLElement | null;
        if (event?.target?.value?.length && nextSibling) {
            nextSibling?.focus();
        }
    };

    const handleKeyDownEnter = (
        event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        if (event?.key === 'Enter') {
            submitVerifyCode();
        }
    };

    const changeEmailData = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailData(event?.target?.value);
    };

    const changeNameData = (event: ChangeEvent<HTMLInputElement>) => {
        setNameData(event?.target?.value);
    };

    const submitPhoneData = () => {
        if (isValidContact) {
            new ContactsApi().sendContacts(`+${phoneData}`).then((res) => {
                if (res?.status) {
                    if (userState?.info) {
                        dispatch(
                            userAction.setIsApprovePhone(false, userState?.info?.is_bonus_phone),
                        );
                    }
                    openVerifyPhoneCodeModal();
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                        }),
                    );
                }
            });
        } else {
            dispatch(alertAction.setMessage({ message: 'Invalid phone number' }));
        }
    };

    const submitVerifyCode = () => {
        const code = Object.values(verifyCode).join('');

        if (code?.length === 4) {
            new ContactsApi().verifyContacts(code).then((res) => {
                if (res?.status && res?.result) {
                    dispatch(
                        userAction.setIsApprovePhone(true, res?.result?.bonus, res?.result?.phone),
                    );
                    closeVerifyPhoneCodeModal();
                    closeConfirmPhoneModal();

                    if (
                        !!userState?.info?.email_confirmed &&
                        !!userState?.info?.real_name?.length &&
                        !+userState?.info?.is_verified
                    ) {
                        dispatch(userAction.setIsUserVerified());
                        closeVerifyStatusModal();
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: 'Your phone number has been verified',
                            }),
                        );
                    }
                } else {
                    setIsPhoneCodeCanceled(true);
                    closeVerifyPhoneCodeModal();
                }
            });
        } else {
            dispatch(alertAction.setMessage({ message: 'Invalid code number' }));
        }
    };

    const submitEmailData = (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        try {
            dispatch(loaderAction.setActiveGifLoader(true));
            new ProfileApi()
                .updateEmail(emailData)
                .then((res) => {
                    if (res?.status) {
                        setIsEmailSent(true);
                        dispatch(userAction.setUserEmail(emailData));
                        closeVerifyStatusModal();
                        if (SITE.ID === 23) {
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.EMAIL_CONFIRM_SENT,
                                    message: emailData,
                                }),
                            );
                            closeConfirmEmailModal();
                        }
                    } else {
                        dispatch(alertAction.setMessage({ message: res?.message }));
                    }
                })
                .finally(() => {
                    dispatch(loaderAction.setActiveGifLoader(false));
                });
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const submitNameData = () => {
        if (nameData?.length) {
            new ContactsApi().saveRealName(nameData).then((res) => {
                if (res?.status) {
                    dispatch(userAction.setUserRealName(res?.result?.realName));
                    closeConfirmNameModal();

                    if (
                        !!userState?.info?.is_approve_phone &&
                        !!+userState?.info?.email_confirmed &&
                        !+userState?.info?.is_verified
                    ) {
                        dispatch(userAction.setIsUserVerified());
                        closeVerifyStatusModal();
                    } else {
                        dispatch(alertAction.setMessage({ message: 'Your name has been saved' }));
                    }
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
        } else {
            dispatch(alertAction.setMessage({ message: 'This field is required.' }));
        }
    };

    return {
        isActiveVerifyStatusModal,
        openVerifyStatusModal,
        closeVerifyStatusModal,
        isActiveConfirmPhoneModal,
        openConfirmPhoneModal,
        closeConfirmPhoneModal,
        isVerifyPhoneCodeModal,
        closeVerifyPhoneCodeModal,
        isActiveConfirmEmailModal,
        openConfirmEmailModal,
        closeConfirmEmailModal,
        isActiveConfirmNameModal,
        openConfirmNameModal,
        closeConfirmNameModal,

        phoneData,
        changePhoneData,
        submitPhoneData,
        verifyCode,
        handleVerifyCode,
        handleKeyDownEnter,
        submitVerifyCode,
        isPhoneCodeCanceled,
        setIsPhoneCodeCanceled,
        userInfo: userState?.info,
        emailData,
        changeEmailData,
        submitEmailData,
        isEmailSent,
        nameData,
        changeNameData,
        submitNameData,
        isVerifiedAllStatusCondition,
        switchPhoneNumber,
        isApprovePhone: !!userState?.info?.is_approve_phone,
    };
}
