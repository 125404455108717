import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import Slider from 'react-slick';
import SliderItem from './SliderItem';
import useInitGirlsSlider from '../../../hooks/girlsSlider/useInitGirlsSlider';
import SITE from '../../../config/site';
import { SLIDER_SETTINGS } from '../../../constants/slider';

export default function GirlsSliderProfile({ womanExternalID }: { womanExternalID: number }) {
    const {
        sliderWomanList,
        sliderObj,
        sliderRef,
        openChat,
        setLike,
        openProfileModal,
        openGalleryModal,
        onPrevSlide,
        onNextSlide,
        getSimilarProfileWomanList,
    } = useInitGirlsSlider();

    useEffect(() => {
        getSimilarProfileWomanList(womanExternalID);
    }, [womanExternalID]);

    return (
        <>
            {sliderWomanList?.length && SITE.ID !== 4 ? (
                <div className={`${styles.girls_slider_wrap} girls_slider_wrap_global`}>
                    <div className={`${styles.girls_slider_info}`}>
                        <div className={styles.girls_slider_title}>{sliderObj?.title}</div>
                        <div className={styles.girls_slider_text}>
                            {window.innerWidth > 600 ? sliderObj?.textPC : sliderObj?.textMob}
                        </div>
                    </div>
                    <div
                        className={`${styles.girls_slider_list_wrap} girls_slider_list_wrap_global`}
                    >
                        <div className={styles.girls_slider_list}>
                            <Slider {...SLIDER_SETTINGS} ref={sliderRef}>
                                {sliderWomanList.map((item, key) => (
                                    <SliderItem
                                        key={key + item?.externalID}
                                        womanProfile={item}
                                        openChat={openChat}
                                        setLike={setLike}
                                        openProfileModal={openProfileModal}
                                        openGalleryModal={openGalleryModal}
                                    />
                                ))}
                            </Slider>
                        </div>
                    </div>
                    {/*<div className={styles.girls_slider_btn_buttons}>*/}
                    <div
                        className={`${styles.girls_slider_btn} ${styles.girls_slider_prev_btn}`}
                        onClick={onPrevSlide}
                    />
                    <div
                        className={`${styles.girls_slider_btn} ${styles.girls_slider_next_btn}`}
                        onClick={onNextSlide}
                    />
                    {/*</div>*/}
                </div>
            ) : (
                <div />
            )}
        </>
    );
}
