import * as PROFILE from '../../constants/profile';
import {
    IGetAboutMySelf,
    IGetGirlsAppearance,
    IGetMainInfo,
    IGetPhotos,
    IManProfileInitData,
    IManProfileReferenceHobbyItem,
    IManProfileReferencies,
} from './ManProfile.interface';

export default class ManProfile {
    data: IManProfileInitData;
    directory: IManProfileReferencies;

    constructor(data: IManProfileInitData, directory: IManProfileReferencies) {
        this.data = data;
        this.directory = directory;
    }

    getMainInfo(): IGetMainInfo {
        return {
            name: this.data?.man?.name ?? null,
            email: this.data?.man?.email ?? null,
            external_id: this.data?.man?.external_id ?? null,
            day: +this.data?.man?.b_day,
            month: +this.data?.man?.b_month,
            year: +this.data?.man?.b_year,
            city: this.data?.man?.city_name !== 'Not specified' ? this.data?.man?.city_name : null,
            city_list: [],
            country_id: this?.data?.man?.country ?? null,
            country_code: this?.data?.man?.country_code ?? null,
            country_name:
                this?.data?.man?.country_name !== 'Not specified'
                    ? this?.data?.man?.country_name
                    : null,
            countryList: this.directory?.country_list ?? null,
            country_image: this?.data?.man?.country_image ?? null,
            photo_link: this.data?.man?.photo_link?.length > 0 ? this.data?.man?.photo_link : '',
            ...this.getPhotos(),
        };
    }

    getAboutMySelf(): IGetAboutMySelf {
        const hobbiesList: IManProfileReferenceHobbyItem[] = [];

        if (Array.isArray(this?.data?.hobbies)) {
            this.data.hobbies.forEach((hobby) => {
                const foundName = this.directory?.hobbie_list.find(
                    (item) => +item.id === +hobby?.id,
                );
                if (foundName) hobbiesList.push(foundName);
            });
        }

        return {
            summary: this.data?.man?.summary ?? '',
            hobbies: hobbiesList ?? [],
            alcohol: this.directory?.alcohol_list?.find(
                (item) => +item.id === +this.data?.man?.alcohol_id,
            ),
            smoke: this.directory?.smoke_list?.find(
                (item) => +item.id === +this.data?.man?.smoke_id,
            ),
            occupation: this.directory?.occupation_list?.find(
                (item) => +item.id === +this.data?.man?.occupation_id,
            ),
            marital: this.directory?.marital_list?.find(
                (item) => +item.id === +this.data?.man?.marital_id,
            ),
            education: this.directory?.education_list?.find(
                (item) => +item.id === +this.data?.man?.education_id,
            ),
            children: this.directory?.children_list?.find(
                (item) => +item.id === +this.data?.man?.children_id,
            ),
            religion: this.directory?.religion_list?.find(
                (item) => +item.id === +this.data?.man?.religion_id,
            ),
            morechildren: this.directory?.morechildren_list?.find(
                (item) => +item.id === +this.data?.man?.morechildren_id,
            ),
            reason: this.directory?.reason_list?.find(
                (item) => +item.id === +this.data?.man?.reason_id,
            ),
        } as IGetAboutMySelf;
    }

    getGirlsAppearance(): IGetGirlsAppearance {
        const hobbiesList: IManProfileReferenceHobbyItem[] = [];
        if (Array.isArray(this?.data?.woman_hobbies)) {
            this.data.woman_hobbies.forEach((hobby) => {
                const foundName = this.directory?.hobbie_list.find(
                    (item) => +item.id === +hobby?.id,
                );
                if (foundName) hobbiesList.push(foundName);
            });
        }

        return {
            woman_hair: {
                ...PROFILE.HAIR_TYPE.find(
                    (itemHairType) =>
                        itemHairType.name ===
                        this.directory?.hair_list?.find(
                            (item) => +item.id === +this.data?.woman?.hair_id,
                        )?.hair,
                ),
                ...{ id: +this.data?.woman?.hair_id },
            },
            woman_eye: {
                ...PROFILE.EYE_COLOR.find(
                    (itemHairType) =>
                        itemHairType.name ===
                        this.directory?.eye_list?.find(
                            (item) => +item.id === +this.data?.woman?.eye_id,
                        )?.eye,
                ),
                ...{ id: +this.data?.woman?.eye_id },
            },
            girls_age_from: this.data?.woman?.girls_age_from,
            girls_age_to: this.data?.woman?.girls_age_to,
            body: this.directory?.body_list?.find(
                (item) => +item.id === +this.data?.woman?.body_id,
            ),
            height: {
                ...this.directory?.height_list?.find(
                    (item) => +item.id === +this.data?.woman?.girls_height_to,
                ),
                ...{
                    name: this.directory?.height_list?.find(
                        (item) => +item.id === +this.data?.woman?.girls_height_to,
                    )?.height,
                },
            },
            woman_occupation: this.directory?.occupation_list?.find(
                (item) => +item.id === +this.data?.woman?.occupation_id,
            ),
            woman_marital: this.directory?.marital_list?.find(
                (item) => +item.id === +this.data?.woman?.marital_id,
            ),
            woman_education: this.directory?.education_list?.find(
                (item) => +item.id === +this.data?.woman?.education_id,
            ),
            woman_children: this.directory?.children_list?.find(
                (item) => +item.id === +this.data?.woman?.children_id,
            ),
            woman_religion: this.directory?.religion_list?.find(
                (item) => +item.id === +this.data?.woman?.religion_id,
            ),
            woman_morechildren: this.directory?.morechildren_list?.find(
                (item) => +item.id === +this.data?.woman?.morechildren_id,
            ),
            looking: this.data?.man?.looking ?? '',
            woman_hobbies: hobbiesList ?? [],
            woman_alcohol: this.directory?.alcohol_list?.find(
                (item) => +item.id === +this.data?.woman?.alcohol_id,
            ),
            woman_smoke: this.directory?.smoke_list?.find(
                (item) => +item.id === +this.data?.woman?.smoke_id,
            ),
        };
    }

    getPhotos(): IGetPhotos {
        return {
            photos: this.data?.content,
        };
    }
}
