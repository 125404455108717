import React from 'react';
import { ICart, IGiftItem } from '../../../store/reducers/gifts/index.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';

export interface IContentGallery {
    link: string;
}

interface IGiftsCatalogItemModal {
    item: IGiftItem | null;
    cart: ICart;
    closeModal: () => void;
    addToCart: (item: IGiftItem) => void;
    onSelectImage: (index: number, gallery: IContentGallery[]) => void;
}

export default function GiftsCatalogItemModal({
    item,
    cart,
    closeModal,
    addToCart,
    onSelectImage,
}: IGiftsCatalogItemModal) {
    const contentGallery: IContentGallery[] = [];
    const userState = useSelector((state: RootState) => state.user);

    const addToGallery = (i: { image: string }) => contentGallery.push({ link: i?.image });

    const actualPrice = item ? +(userState?.giftsDiscount ? item?.new_price : item?.price) : 0;

    if (item?.image) addToGallery(item);
    else addToGallery({ image: '/img/gifts/img_no_photo.jpg' });

    const addToCartButton = (item: IGiftItem) => {
        if (cart?.items && cart?.items[item?.id]) {
            return (
                <div className="popup_gift_details_item_added">
                    <img src="../../../img/gifts/check-circle-darkblue.svg" alt="" />
                    <span>Added to cart</span>
                </div>
            );
        }

        return (
            <div className="popup_gift_details_add_btn" onClick={() => addToCart(item)}>
                <img src="../../../img/gifts/cart-white.svg" alt="" />
                <span>Add to cart</span>
            </div>
        );
    };

    return (
        <div className="popup_gift_details_wrap">
            <div className="popup_gift_details">
                <div className="popup_gift_details_gallery">
                    <div
                        className={`popup_gift_details_gallery_big_wrap ${userState?.giftsDiscount ? 'popup_gift_details_gallery_big_wrap_discount_label' : ''}`}
                    >
                        <img
                            src={item?.image || '/img/gifts/img_no_photo.jpg'}
                            alt={item?.name || ''}
                            onClick={() => onSelectImage(0, contentGallery)}
                            className="popup_gift_details_gallery_big"
                        />
                        {userState?.giftsDiscount && item?.discount && (
                            <div className="popup_gift_details_gallery_big_wrap_discount_amount">{`- ${+item?.discount}%`}</div>
                        )}
                    </div>
                    <div className="popup_gift_details_gallery_list">
                        {item?.gallery?.length
                            ? item?.gallery?.map((i, key) => {
                                  addToGallery(i);
                                  return (
                                      <img
                                          onClick={() => onSelectImage(key + 1, contentGallery)}
                                          key={key}
                                          src={i?.image}
                                          alt=""
                                      />
                                  );
                              })
                            : ''}
                    </div>
                </div>
                <div className="popup_gift_details_info">
                    <div className="popup_gift_details_title">{item?.name}</div>
                    <div className="popup_gift_details_desc">{item?.description}</div>
                    <div
                        className={`popup_gift_details_price ${userState?.giftsDiscount ? 'popup_gift_details_price_with_discount' : ''}`}
                    >
                        Price<span>{actualPrice} credits</span>
                        {userState?.giftsDiscount && item?.old_price && (
                            <span className="popup_gift_details_old_price">{`${+item?.old_price} credits`}</span>
                        )}
                    </div>
                    {item ? addToCartButton(item) : null}
                </div>
            </div>
            <div className="popup_gift_details_close" onClick={closeModal}>
                <img src="../../../img/gifts/cross-darkblue.svg" alt="" />
            </div>
        </div>
    );
}
