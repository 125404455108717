import React from 'react';
import SearchFilterWomanAge from '../../search/SearchFilterWomanAge';
import SearchInput from '../../common/SearchInput';

interface IOnlineNowFilters {
    setNameFilter: (value: string) => void;
    nameFilter: string;
    ageFromFilter: number;
    setAgeFromFilter: (value: number) => void;
    ageToFilter: number;
    setAgeToFilter: (value: number) => void;
}

export default function OnlineNowFilters({
    setNameFilter,
    ageFromFilter,
    setAgeFromFilter,
    ageToFilter,
    setAgeToFilter,
    nameFilter,
}: IOnlineNowFilters) {
    return (
        <>
            <div className="online_now_filters">
                <SearchFilterWomanAge
                    ageFromFilter={ageFromFilter}
                    setAgeFromFilter={setAgeFromFilter}
                    ageToFilter={ageToFilter}
                    setAgeToFilter={setAgeToFilter}
                />
                <SearchInput handleSearch={(value) => setNameFilter(value)} value={nameFilter} />
            </div>
        </>
    );
}
