import React from 'react';

export default function IconQuoteClose() {
    return (
        <svg
            width="30"
            height="18"
            viewBox="0 0 30 18"
            fill="#383B5A"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17.8557 -0.000144958H29.0557L21.8877 17.1519H14.5277L17.8557 -0.000144958ZM3.32766 -0.000144958H14.4637L7.29566 17.1519H-0.000335693L3.32766 -0.000144958Z" />
        </svg>
    );
}
