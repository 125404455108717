import React from 'react';

export default function IconProfile() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5164 0.25C8.09727 0.25 5.30835 2.98903 5.30765 6.3887C5.29672 9.77796 8.0672 12.5197 11.4755 12.5311L11.478 12.5311H11.5164C14.9364 12.5311 17.7241 9.78985 17.7241 6.38999C17.7241 2.98992 14.9362 0.25 11.5164 0.25ZM6.80765 6.38999C6.80765 3.83613 8.90716 1.75 11.5164 1.75C14.1255 1.75 16.2241 3.83593 16.2241 6.38999C16.2241 8.94424 14.1253 11.0311 11.5164 11.0311H11.4791C8.8822 11.0217 6.79892 8.93627 6.80764 6.39256H6.80765V6.38999ZM11.5162 14.9491C9.20384 14.9491 6.97423 15.119 5.29979 15.6734C4.45911 15.9518 3.70192 16.3449 3.14782 16.913C2.57538 17.4998 2.25 18.2442 2.25 19.1319C2.25 20.018 2.57256 20.763 3.14183 21.3521C3.69327 21.9229 4.44777 22.3198 5.2875 22.6017C6.95975 23.1632 9.19019 23.3393 11.5162 23.3393C13.8286 23.3393 16.058 23.1692 17.7321 22.6146C18.5727 22.3361 19.3297 21.9429 19.8836 21.3749C20.4559 20.7882 20.7812 20.0439 20.7812 19.1565C20.7812 18.2707 20.4587 17.5259 19.8896 16.9368C19.3383 16.3661 18.584 15.9692 17.7444 15.6872C16.0724 15.1255 13.8423 14.9491 11.5162 14.9491ZM3.75 19.1319C3.75 18.6462 3.91496 18.2747 4.22158 17.9604C4.54653 17.6272 5.05711 17.3339 5.77129 17.0974C7.20658 16.6221 9.23507 16.4491 11.5162 16.4491C13.8085 16.4491 15.8358 16.6284 17.2668 17.1091C17.9787 17.3482 18.4872 17.644 18.8108 17.979C19.1167 18.2956 19.2812 18.6694 19.2812 19.1565C19.2812 19.642 19.1164 20.0133 18.8098 20.3276C18.4849 20.6607 17.9745 20.9541 17.2604 21.1907C15.8254 21.666 13.7972 21.8393 11.5162 21.8393C9.22383 21.8393 7.19617 21.6603 5.76492 21.1797C5.0529 20.9407 4.5443 20.6449 4.22057 20.3099C3.91467 19.9933 3.75 19.6193 3.75 19.1319Z"
            />
        </svg>
    );
}
