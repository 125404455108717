import { ModalsActionTypes } from '../../constants/modals';
import SITE from '../../../config/site';
import { IModalsState, ModalsActions } from './index.interface';

const initState = {
    showEmailEditModal: false,
    showAddCreditCardModal: false,
    activeSubmenu: '',
    womanProfileModal: {
        isActive: false,
        externalID: null,
    },
    womanVideoShowModal: {
        isActive: false,
        externalID: null,
    },
    womanGalleryModal: {
        isActive: false,
        externalID: null,
        currentPhotoIndex: 0,
        galleryImg: [],
    },
    galleryModal: {
        isActive: false,
        currentPhotoIndex: 0,
        profile: null,
        photosList: [],
    },
    videoContentModal: {
        isActive: false,
        item: {},
    },
    photoContentModal: {
        isActive: false,
        item: {},
    },
    paymentRoutingModal: {
        isActive: false,
    },
    lessCreditsModal: {
        isActive: false,
        message: '',
        title: '',
        packId: '',
    },
    welcomeModal: {
        isActive: false,
    },
    NewFeatureModal: {
        isActive: false,
    },
    replyModal: {
        isActive: false,
        post: null,
        openCurrentPost: null,
    },
    successReplyModal: {
        isActive: false,
        goToChat: null,
        goToNewsFeed: null,
    },
    isOpenProfileIdentificationModal: false,
};

export default function (state: IModalsState = initState, action: ModalsActions): IModalsState {
    switch (action.type) {
        case ModalsActionTypes.SHOW_EMAIL_EDIT_MODAL: {
            return {
                ...state,
                showEmailEditModal: true,
            };
        }

        case ModalsActionTypes.CLOSE_EMAIL_EDIT_MODAL: {
            return {
                ...state,
                showEmailEditModal: false,
            };
        }
        case ModalsActionTypes.SHOW_ADD_CREDIT_CARD_MODAL: {
            return {
                ...state,
                showAddCreditCardModal: true,
            };
        }

        case ModalsActionTypes.CLOSE_ADD_CREDIT_CARD_MODAL: {
            return {
                ...state,
                showAddCreditCardModal: false,
            };
        }
        case ModalsActionTypes.ACTIVE_SUB_MENU: {
            return {
                ...state,
                activeSubmenu: action.data,
            };
        }
        case ModalsActionTypes.OPEN_WOMAN_PROFILE_MODAL: {
            return {
                ...state,
                womanProfileModal: {
                    isActive: true,
                    externalID: action?.externalID,
                },
            };
        }
        case ModalsActionTypes.CLOSE_WOMAN_PROFILE_MODAL: {
            return {
                ...state,
                womanProfileModal: {
                    isActive: false,
                    externalID: null,
                },
            };
        }

        case ModalsActionTypes.OPEN_WOMAN_VIDEO_SHOW_MODAL: {
            return {
                ...state,
                womanVideoShowModal: {
                    isActive: true,
                    externalID: action?.externalID,
                },
            };
        }
        case ModalsActionTypes.CLOSE_WOMAN_VIDEO_SHOW_MODAL: {
            return {
                ...state,
                womanVideoShowModal: {
                    isActive: false,
                    externalID: null,
                },
            };
        }
        case ModalsActionTypes.OPEN_WOMAN_GALLERY_MODAL: {
            return {
                ...state,
                womanGalleryModal: {
                    isActive: true,
                    externalID: action?.externalID,
                    currentPhotoIndex: action?.currentPhotoIndex,
                    galleryImg: action?.galleryImg,
                },
            };
        }
        case ModalsActionTypes.CLOSE_WOMAN_GALLERY_MODAL: {
            return {
                ...state,
                womanGalleryModal: {
                    isActive: false,
                    externalID: null,
                    currentPhotoIndex: 0,
                    galleryImg: [],
                },
            };
        }
        case ModalsActionTypes.OPEN_SENDNERLY_GALLERY_MODAL: {
            return {
                ...state,
                galleryModal: {
                    isActive: true,
                    currentPhotoIndex: action?.currentPhotoIndex,
                    profile: action?.profile,
                    photosList: action?.photosList,
                },
            };
        }
        case ModalsActionTypes.CLOSE_SENDNERLY_GALLERY_MODAL: {
            return {
                ...state,
                galleryModal: {
                    isActive: false,
                    currentPhotoIndex: 0,
                    profile: null,
                    photosList: [],
                },
            };
        }
        case ModalsActionTypes.OPEN_VIDEO_CONTENT_MODAL: {
            return {
                ...state,
                videoContentModal: {
                    isActive: true,
                    item: action?.item,
                },
            };
        }
        case ModalsActionTypes.CLOSE_VIDEO_CONTENT_MODAL: {
            return {
                ...state,
                videoContentModal: {
                    isActive: false,
                    item: {},
                },
            };
        }
        case ModalsActionTypes.OPEN_PHOTO_CONTENT_MODAL: {
            return {
                ...state,
                photoContentModal: {
                    isActive: true,
                    item: action?.item,
                },
            };
        }
        case ModalsActionTypes.CLOSE_PHOTO_CONTENT_MODAL: {
            return {
                ...state,
                photoContentModal: {
                    isActive: false,
                    item: {},
                },
            };
        }

        case ModalsActionTypes.OPEN_PAYMENT_ROUTING_MODAL: {
            return { ...state, paymentRoutingModal: { isActive: true } };
        }
        case ModalsActionTypes.CLOSE_PAYMENT_ROUTING_MODAL: {
            return { ...state, paymentRoutingModal: { isActive: false } };
        }

        case ModalsActionTypes.OPEN_LESS_THEN_CREDITS: {
            return {
                ...state,
                lessCreditsModal: {
                    isActive: true,
                    message: action?.message,
                    title: action?.title,
                    packId: action?.packId,
                },
            };
        }
        case ModalsActionTypes.CLOSE_LESS_THEN_CREDITS: {
            return {
                ...state,
                lessCreditsModal: {
                    isActive: false,
                    message: '',
                    title: '',
                    packId: '',
                },
            };
        }

        case ModalsActionTypes.OPEN_WELCOME_MODAL: {
            return { ...state, welcomeModal: { isActive: SITE.ID !== 23 } };
        }
        case ModalsActionTypes.CLOSE_WELCOME_MODAL: {
            return { ...state, welcomeModal: { isActive: false } };
        }

        case ModalsActionTypes.OPEN_NEW_FEATURE_MODAL: {
            return { ...state, NewFeatureModal: { isActive: true } };
        }
        case ModalsActionTypes.CLOSE_NEW_FEATURE_MODAL: {
            return { ...state, NewFeatureModal: { isActive: false } };
        }

        case ModalsActionTypes.OPEN_REPLY_MODAL: {
            return {
                ...state,
                replyModal: {
                    isActive: true,
                    post: action?.post,
                    openCurrentPost: action?.openCurrentPost,
                },
            };
        }
        case ModalsActionTypes.CLOSE_REPLY_MODAL: {
            return { ...state, replyModal: { isActive: false, post: null, openCurrentPost: null } };
        }

        case ModalsActionTypes.OPEN_SUCCESS_REPLY_MODAL: {
            return {
                ...state,
                successReplyModal: {
                    isActive: true,
                    goToChat: action?.goToChat,
                    goToNewsFeed: action?.goToNewsFeed,
                },
            };
        }
        case ModalsActionTypes.CLOSE_SUCCESS_REPLY_MODAL: {
            return {
                ...state,
                successReplyModal: { isActive: false, goToChat: null, goToNewsFeed: null },
            };
        }

        case ModalsActionTypes.OPEN_PROFILE_IDENTIFICATION_MODAL: {
            return {
                ...state,
                isOpenProfileIdentificationModal: true,
            };
        }

        case ModalsActionTypes.CLOSE_PROFILE_IDENTIFICATION_MODAL: {
            return {
                ...state,
                isOpenProfileIdentificationModal: false,
            };
        }

        default: {
            return state;
        }
    }
}
