import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import UnlockedFeature from './UnlockedFeature';
import { IUnlockedFeatureModalProps } from './index.interface';

export default function UnlockedFeatureModal({
    isUnlockedFeatureModal,
    closeUnlockedFeatureModal,
    typeModal = '',
}: IUnlockedFeatureModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isUnlockedFeatureModal}
            onRequestClose={closeUnlockedFeatureModal}
            ariaHideApp={false}
        >
            <UnlockedFeature onClose={closeUnlockedFeatureModal} type={typeModal} />
        </Modal>
    );
}
