import infoSVG from '../../../img/info-icon.svg';
import dangerIconSVG from '../../../img/danger-icon-red.svg';

import mp1WEBP from '../../../img/mp1-min.webp';
import mp2WEBP from '../../../img/mp2-min.webp';
import mp3WEBP from '../../../img/mp3-min.webp';

import mp1JPG from '../../../img/mp1-min.jpg';
import mp2JPG from '../../../img/mp2-min.jpg';
import mp3JPG from '../../../img/mp3-min.jpg';

import mv1WEBP from '../../../img/mv1-min.webp';
import mv2WEBP from '../../../img/mv2-min.webp';

import mv1JPG from '../../../img/mv1-min.jpg';
import mv2JPG from '../../../img/mv2-min.jpg';

import c2_search_btnSVG from '../../../img/c2_search_btn.svg';
import c2lh_your_likesSVG from '../../../img/c2lh-your-likes.svg';
import c2l_search_btnSVG from '../../../img/c2lh-search-btn.svg';
import c2_removeSVG from '../../../img/c2-remove.svg';
import c2_removeFavSVG from '../../../img/c2-remove-fav.svg';
import c3_msg_attachSVG from '../../../img/c3-msg-attach.svg';
import c3_msg_sendSVG from '../../../img/c3-msg-send.svg';
import winkSVG from '../../../img/wink.svg';
import c3_top_favs2SVG from '../../../img/c3-top-favs2.svg';
import c3_top_favsSVG from '../../../img/c3-top-favs.svg';
import c3_top_letterSVG from '../../../img/c3-top-letter.svg';
import c3_top_blockSVG from '../../../img/c3-top-block.svg';
import c3_top_attentionSVG from '../../../img/c3-top-attention.svg';
import c3_opt_giftSVG from '../../../img/c3-opt-gift.svg';
import c3_opt_meetSVG from '../../../img/c3-opt-meet.svg';
import profile_icoSVG from '../../../img/profile_ico.svg';
import profile_ico_blue_boldSVG from '../../../img/profile-ico-blue-bold.svg';
import question_mark_whiteSVG from '../../../img/question-mark-white.svg';
import heart_chainedSVG from '../../../img/heart-chained.svg';
import pic_whiteSVG from '../../../img/pic-white.svg';
import profile_ico_whiteSVG from '../../../img/profile-ico-white.svg';
import c3_top_options_btnSVG from '../../../img/c3-top-options-btn.svg';
import c2_favs_star_bdSVG from '../../../img/c2-favs-star-bd.svg';
import c2_photo_icoSVG from '../../../img/c2-photo-ico.svg';
import c2_video_icoSVG from '../../../img/c2-video-ico.svg';
import c2_profile_icoSVG from '../../../img/c2-profile-ico.svg';
import arrow_left_purpleSVG from '../../../img/arrow-left-purple.svg';
import c3l_deleteSVG from '../../../img/c3l-delete.svg';
import c3l_delete2SVG from '../../../img/c3l-delete2.svg';
import c2l_id_search_btnSVG from '../../../img/c2l-id-search-btn.svg';
import fi_1SVG from '../../../img/payment_mac.svg';
import fi_2SVG from '../../../img/payment_ssl.svg';
import fi_3SVG from '../../../img/payment_mastercard.svg';
import fi_4SVG from '../../../img/mf_visa.png';
import fi_5SVG from '../../../img/payment_pci.svg';
import fs_2SVG from '../../../img/youtube_icon.svg';
import fs_3SVG from '../../../img/facebook_icon.svg';
import fs_5SVG from '../../../img/instagram_icon.svg';
import fs_6SVG from '../../../img/twitter_icon.svg';
import chat_whiteSVG from '../../../img/chat-white.svg';
import play_btnSVG from '../../../img/play-btn.svg';
import uaSVG from '../../../img/ua.svg';
import login_btn_whiteSVG from '../../../img/login-btn-white.svg';
import c3l_replySVG from '../../../img/c3l-reply.svg';
import c3l_profileSVG from '../../../img/c3l-profile.svg';
import c3l_gotochat_vSVG from '../../../img/c3l-gotochat-v.svg';
import c3l_restore_msgSVG from '../../../img/c3l-restore-msg.svg';
import credit_card_imgSVG from '../../../img/manage-credit-card-modal/credit-card-img.svg';
import check_circle_greenSVG from '../../../img/manage-credit-card-modal/check-circle-green.svg';
import check_circle_green_boldSVG from '../../../img/check-circle-green-bold.svg';
import check_circle_green_fillSVG from '../../../img/check-circle-green-fill.svg';
import delete_redSVG from '../../../img/manage-credit-card-modal/delete-red.svg';
import plus_circle_blueSVG from '../../../img/manage-credit-card-modal/plus-circle-blue.svg';
import check_circle_whiteSVG from '../../../img/manage-credit-card-modal/check-circle-white.svg';
import check_circle_gray_boldSVG from '../../../img/check-circle-gray-bold.svg';
import close_one_popupSVG from '../../../img/close-one-popup.svg';
import plusSVG from '../../../img/create-profile-page/plus.svg';
import cross_whiteSVG from '../../../img/create-profile-page/cross-white.svg';
import check_circle_blueSVG from '../../../img/create-profile-page/check-circle-blue.svg';
import cross_blueSVG from '../../../img/create-profile-page/cross-blue.svg';
import delete_photoSVG from '../../../img/delete-photo.svg';
import c3ph_purchaseSVG from '../../../img/c3ph-purchase.svg';
import profile_empty_video_playSVG from '../../../img/profile-empty-video-play.svg';
import profile_empty_video_icoSVG from '../../../img/profile-empty-video-ico.svg';
import profile_likeSVG from '../../../img/profile-like.svg';
import profile_like_aSVG from '../../../img/profile-like-a.svg';
import profile_prev_btnSVG from '../../../img/profile-prev-btn.svg';
import profile_next_btnSVG from '../../../img/profile-next-btn.svg';
import profile_mob_favsSVG from '../../../img/profile-mob-favs.svg';
import profile_mob_favs_aSVG from '../../../img/profile-mob-favs-a.svg';
import profile_giftSVG from '../../../img/profile-gift.svg';
import profile_complainSVG from '../../../img/profile-complain.svg';
import profile_letterSVG from '../../../img/profile-letter.svg';
import delete_profile_iconSVG from '../../../img/delete_profile_icon.svg';
import heart_redSVG from '../../../img/heart-red.svg';
import next_arrow_blueSVG from '../../../img/next-arrow-blue.svg';
import hello_ico_lightblueSVG from '../../../img/hello-ico-lightblue.svg';
import c3_media_unpaidSVG from '../../../img/c3-media-unpaid.svg';
import c3_media_unpaid_blueSVG from '../../../img/c3-media-unpaid-blue.svg';
import c3_media_video_playSVG from '../../../img/c3-media-video-play.svg';
import say_hello_yellowSVG from '../../../img/say-hello-yellow-emoji.svg';
import empty_opened_chatSVG from '../../../img/empty-opened-chat.svg';
import chat_hello_yellowSVG from '../../../img/chat-hello-yellow.svg';
import four_o_four_bgSVG from '../../../img/404-bg.svg';
import muteSVG from '../../../img/mute.svg';
import mutedSVG from '../../../img/muted.svg';
import moveSVG from '../../../img/move.svg';
import emojiSpritesPNG from '../../../img/emoji/emoji-sprites.png';

import cardpay_logoPNG from '../../../img/payments/cardpay_logo.png';
import pxpPNG from '../../../img/payments/pxp.png';
import routingJPG from '../../../img/payments/routing.jpg';
import unlimintSVG from '../../../img/payments/unlimint.svg';
import mastercardSVG from '../../../img/payments/mastercard.svg';
import mastercard_whiteSVG from '../../../img/payments/mastercard-white.svg';
import mastercard_new_SVG from '../../../img/payments/mastercard_new.svg';
import maestroSVG from '../../../img/payments/maestro.svg';
import amexSVG from '../../../img/payments/amex.svg';
import amex_newPNG from '../../../img/payments/amex_new.png';
import lpbSVG from '../../../img/payments/lpb.svg';
import lpbV2SVG from '../../../img/payments/lpb_v2.png';
import securionpaySVG from '../../../img/payments/securionpay.png';
import firstCreditBuySVG from '../../../img/first-credit-icon.svg';
import visaSVG from '../../../img/payments/visa.png';
import visa_white_bgSVG from '../../../img/payments/visa_white_bg.svg';
import trustSVG from '../../../img/payments/trust_logo.jpg';
import coinbaseSVG from '../../../img/payments/coinbase.svg';
import apple_logo_blackSVG from '../../../img/payments/apple-logo-black.svg';
import applepaySVG from '../../../img/payments/applepay.svg';
import discoverPNG from '../../../img/payments/discover.png';
import discoverSVG from '../../../img/payments/discover.svg';
import google_colorSVG from '../../../img/payments/google-color.svg';
import googlepaySVG from '../../../img/payments/googlepay.svg';
import uniSVG from '../../../img/payments/uni.png';
import apple_pay_whiteSVG from '../../../img/payments/apple-pay-white.svg';

import auto_shieldSVG from '../../../img/payments/auto_shield.svg';
import auto_infoSVG from '../../../img/payments/auto_info.svg';

import c3lh_item_profileSVG from '../../../img/c3lh-item-profile.svg';
import c3lh_item_videoSVG from '../../../img/c3lh-item-video.svg';
import c3lh_item_like_activeSVG from '../../../img/c3lh-item-like-active.svg';
import c3lh_control_like_herSVG from '../../../img/c3lh-control-like-her.svg';
import c3lh_item_likeSVG from '../../../img/c3lh-item-like.svg';
import c3lh_item_mailSVG from '../../../img/c3lh-item-mail.svg';
import attach_upload_icoSVG from '../../../img/attach-upload-ico.svg';
import attach_upload_ico_greySVG from '../../../img/attach-upload-ico-grey.svg';
import attach_send_icoSVG from '../../../img/attach-send-ico.svg';
import attach_close_icoSVG from '../../../img/attach-close-ico.svg';
import icon_routing_closeSVG from '../../../img/icon_routing_close.svg';
import attach_file_drop_activeSVG from '../../../img/attach_file_drop_active.svg';
import attach_file_dropSVG from '../../../img/attach_file_drop.svg';
import c4_supportSVG from '../../../img/c4-support.svg';
import c4_bottom_callSVG from '../../../img/c4_bottom_call.svg';
import c4_bottom_live_chatSVG from '../../../img/c4_bottom_live_chat.svg';
import c4_bottom_emailSVG from '../../../img/c4_bottom_email.svg';
import c4_bottom_whats_appSVG from '../../../img/c4_bottom_whats_app.svg';
import c4_support_primary_blueSVG from '../../../img/c4-support-primary-blue.svg';
import mp_free_regSVG from '../../../img/mp-free-reg.svg';
import mp_visual_contentSVG from '../../../img/mp-visual-content.svg';
import mp_video_showsSVG from '../../../img/mp-video-shows.svg';
import mp_toolsSVG from '../../../img/mp-tools.svg';
import mp_communSVG from '../../../img/mp-commun.svg';
import mp_profilesSVG from '../../../img/mp-profiles.svg';
import mp_meetingSVG from '../../../img/mp-meeting.svg';
import mp_safe_paySVG from '../../../img/mp-safe-pay.svg';
import mp_supportSVG from '../../../img/mp-support.svg';
import pap_btn_closeSVG from '../../../img/pap-btn-close.svg';

import sweetheart_imageSVG from '../../../img/sweetheart_image.svg';
import new_year_empty_imageSVG from '../../../img/new_year_empty.svg';
import not_found_results_2SVG from '../../../img/not_found_results_2.svg';
import c3_emptySVG from '../../../img/empty_result_chat.svg';
import copy_whiteSVG from '../../../img/copy_white.svg';
import subscribe_icoSVG from '../../../img/subscribe_ico.svg';
import c3lh_item_big_close_lightSVG from '../../../img/c3lh-item-big-close-light.svg';
import profile_img_emptyPNG from '../../../img/profile_img_empty.png';
import reg_closeSVG from '../../../img/reg-close.svg';
import m_cross_greySVG from '../../../img/m-cross-grey.svg';
import arrow_down_blueSVG from '../../../img/arrow-down-blue.svg';
import c3lh_item_chatSVG from '../../../img/c3lh-item-chat.svg';
import right_arrow_blueSVG from '../../../img/right-arrow-blue.svg';
import arrow_right_whiteSVG from '../../../img/arrow_right_white.svg';
import c3_smileSVG from '../../../img/c3-smile.svg';
import c3ph_videoSVG from '../../../img/c3ph-video.svg';
import c3ph_giftSVG from '../../../img/c3ph-gift.svg';
import c3ph_online_nowSVG from '../../../img/c3ph-online-now.svg';
import c3ph_contactSVG from '../../../img/c3ph-contact.svg';
import c3ph_meetSVG from '../../../img/c3ph-meet.svg';
import block_list_pSVG from '../../../img/block-list-p.svg';
import faq_mob_menuSVG from '../../../img/faq_mob_menu.svg';
import tutorial_mob_menuSVG from '../../../img/tutorial/tutorial_mob_menu.svg';
import c1_profile_btnSVG from '../../../img/c1-profile_btn.svg';
import c3lh_item_big_close_blueSVG from '../../../img/c3lh-item-big-close-blue.svg';
import c1_credits_whiteSVG from '../../../img/c1-credits-white.svg';
import get_credit_mailSVG from '../../../img/get_credit_mail.svg';
import get_credit_write_usSVG from '../../../img/get_credit_write_us.svg';
import c3mp_letterSVG from '../../../img/c3mp-letter.svg';
import profile_new_avatarSVG from '../../../img/profile-new-avatar.svg';
import profile_select_arrowSVG from '../../../img/profile-select-arrow.svg';
import profile_select_arrow_passSVG from '../../../img/profile-select-arrow-pass.svg';
import np_aboutSVG from '../../../img/np-about.svg';
import np_hobbySVG from '../../../img/np-hobby.svg';
import np_photoSVG from '../../../img/np-photo.svg';
import profile_click_infoSVG from '../../../img/profile-click-info.svg';
import profile_add_photo_plusSVG from '../../../img/profile-add-photo-plus.svg';

import welcome_photo1JPG from '../../../img/welcome-alert/1.jpg';
import welcome_photo2JPG from '../../../img/welcome-alert/2.jpg';
import welcome_photo3JPG from '../../../img/welcome-alert/3.jpg';
import welcome_photo4JPG from '../../../img/welcome-alert/4.jpg';
import welcome_photo5JPG from '../../../img/welcome-alert/5.jpg';
import welcome_photo6JPG from '../../../img/welcome-alert/6.jpg';
import welcome_photo7JPG from '../../../img/welcome-alert/7.jpg';
import welcome_photo_new_mobPNG from '../../../img/welcome-alert/new_mob.png';
import welcome_photo_newPNG from '../../../img/welcome-alert/new.png';

import welcome_photo_ok1JPG from '../../../img/welcome-alert/ok1.jpg';
import welcome_photo_ok2JPG from '../../../img/welcome-alert/ok2.jpg';
import welcome_photo_ok3JPG from '../../../img/welcome-alert/ok3.jpg';
import welcome_photo_ok4JPG from '../../../img/welcome-alert/ok4.jpg';
import welcome_photo_ok5JPG from '../../../img/welcome-alert/ok5.jpg';
import welcome_photo_ok6JPG from '../../../img/welcome-alert/ok6.jpg';
import welcome_photo_ok7JPG from '../../../img/welcome-alert/ok7.jpg';

import welcome_popup_closeSVG from '../../../img/welcome-alert/welcome-popup-close.svg';
import your_balanceSVG from '../../../img/your-balance.svg';
import liked_darkblueSVG from '../../../img/liked-darkblue.svg';
import like_violetSVG from '../../../img/like-violet.svg';
import liked_violetSVG from '../../../img/liked-violet.svg';
import gift_violetSVG from '../../../img/gift-violet.svg';
import lh_next_arrowSVG from '../../../img/lh-next-arrow.svg';
import video_lightblueSVG from '../../../img/video-lightblue.svg';
import letters_lightblueSVG from '../../../img/letters-lightblue.svg';
import letter_violetSVG from '../../../img/letter-violet.svg';
import profile_violetSVG from '../../../img/profile-violet.svg';
import video_violetSVG from '../../../img/video-violet.svg';
import right_arrow_long_whiteSVG from '../../../img/right-arrow-long-white.svg';
import mob_letterSVG from '../../../img/mob-letter.svg';
import mob_favSVG from '../../../img/mob-fav.svg';
import mob_fav_aSVG from '../../../img/mob-fav-a.svg';
import chat_in_letters_mobileSVG from '../../../img/chat_in_letters_mobile.svg';
import left_arrowSVG from '../../../img/left-arrow.svg';
import profile_ico_gallerySVG from '../../../img/profile-ico-gallery.svg';
import coinsSVG from '../../../img/c3/coins.svg';
import verify_phoneSVG from '../../../img/verify_phone.svg';

import video_credits_icoSVG from '../../../img/video-credits-ico.svg';
import video_credits_dark_icoSVG from '../../../img/video-credits-dark-ico.svg';
import c3_media_mob_arrowSVG from '../../../img/c3-media-mob-arrow.svg';

import credit_clockSVG from '../../../img/credits-clock.svg';

import credits_offer_girlPNG from '../../../img/credits-offer-girl.png';
import check_circle_darkSVG from '../../../img/check-circle-dark.svg';
import confirm_email_icoSVG from '../../../img/confirm-email-ico.svg';
import vipBannerSVG from '../../../img/vip/vip_banner.svg';
import vipOnlineNowBannerSVG from '../../../img/vip/vip_online_now_banner.svg';
import vipStarSVG from '../../../img/vip/vip_star.svg';
import vipCrossSVG from '../../../img/vip/vip_cross.svg';
import c3l_add_more_men_btnSVG from '../../../img/c3l-add-more-men-btn.svg';
import no_pause_recurringSVG from '../../../img/no_pause_recurring.svg';
import user_birthdaySVG from '../../../img/user_birthday.svg';
import congratulation_boxSVG from '../../../img/congratulations_box.svg';
import emailSubscriptionSVG from '../../../img/email_subscription.svg';

//verification
import verify_status_verificationSVG from '../../../img/verify_status_verification.svg';
import verify_status_verifiedSVG from '../../../img/verify_status_verified.svg';
import verify_status_uploadSVG from '../../../img/verify_status_upload.svg';
import verify_status_canceledSVG from '../../../img/verify_status_canceled.svg';
import imbra_downloadSVG from '../../../img/imbra_download.svg';
import imbra_uploadSVG from '../../../img/imbra_upload.svg';
import imbra_waitingSVG from '../../../img/imbra_waiting.svg';
import imbra_canceledSVG from '../../../img/imbra_canceled.svg';
import imbra_verifiedSVG from '../../../img/imbra_verified.svg';
import cloud_download_whiteSVG from '../../../img/cloud-download-white.svg';
import cloud_upload_whiteSVG from '../../../img/cloud-upload-white.svg';
import benefit_contactSVG from '../../../img/benefit_contact.svg';
import benefit_bookingSVG from '../../../img/benefit_booking.svg';
import benefit_questionSVG from '../../../img/benefit_question.svg';
import edit_whiteSVG from '../../../img/edit_white.svg';
import avatar_user_vipSVG from '../../../img/avatar_user_vip.svg';
import avatar_crown_vipSVG from '../../../img/avatar_crown_vip.svg';

// hobbies

import artSVG from '../../../img/hobby/art.svg';

//jobs

import educationSVG from '../../../img/jobs/education.svg';
import informationSVG from '../../../img/jobs/information.svg';
import lawSVG from '../../../img/jobs/law.svg';
import c3p_manage_emailSVG from '../../../img/src_img_c3p-manage-email.svg';
import c3p_manage_passSVG from '../../../img/src_img_c3p-manage-pass.svg';
import c3p_manage_logoutSVG from '../../../img/c3p_manage_logout.svg';
import down_arrow_whiteSVG from '../../../img/down-arrow-white.svg';
import video_errorSVG from '../../../img/video-error.svg';
import policy_icon_mob_menuSVG from '../../../img/policy_icon_mob_menu.svg';

//stickers

import sticker_heart_arrowSVG from '../../../img/stickers/heart/sticker-heart-arrow.svg';
import sticker_heart_brokenSVG from '../../../img/stickers/heart/sticker-heart-broken.svg';
import sticker_heart_confusedSVG from '../../../img/stickers/heart/sticker-heart-confused.svg';
import sticker_heart_crySVG from '../../../img/stickers/heart/sticker-heart-cry.svg';
import sticker_heart_hiSVG from '../../../img/stickers/heart/sticker-heart-hi.svg';
import sticker_heart_hugSVG from '../../../img/stickers/heart/sticker-heart-hug.svg';
import sticker_heart_laughSVG from '../../../img/stickers/heart/sticker-heart-laugh.svg';
import sticker_heart_likeSVG from '../../../img/stickers/heart/sticker-heart-like.svg';
import sticker_heart_loveSVG from '../../../img/stickers/heart/sticker-heart-love.svg';
import sticker_heart_sadSVG from '../../../img/stickers/heart/sticker-heart-sad.svg';

import sticker_guy_angrySVG from '../../../img/stickers/guy/sticker-guy-angry.svg';
import sticker_guy_confusedSVG from '../../../img/stickers/guy/sticker-guy-confused.svg';
import sticker_guy_coolSVG from '../../../img/stickers/guy/sticker-guy-cool.svg';
import sticker_guy_happySVG from '../../../img/stickers/guy/sticker-guy-happy.svg';
import sticker_guy_kissSVG from '../../../img/stickers/guy/sticker-guy-kiss.svg';
import sticker_guy_likeSVG from '../../../img/stickers/guy/sticker-guy-like.svg';
import sticker_guy_pleaseSVG from '../../../img/stickers/guy/sticker-guy-please.svg';
import sticker_guy_roll_eyesSVG from '../../../img/stickers/guy/sticker-guy-roll-eyes.svg';
import sticker_guy_sleepSVG from '../../../img/stickers/guy/sticker-guy-sleep.svg';
import sticker_guy_strongSVG from '../../../img/stickers/guy/sticker-guy-strong.svg';
import sticker_guy_surprizeSVG from '../../../img/stickers/guy/sticker-guy-surprize.svg';
import sticker_guy_thinkSVG from '../../../img/stickers/guy/sticker-guy-think.svg';
import sticker_guy_waveSVG from '../../../img/stickers/guy/sticker-guy-wave.svg';

import sticker_animal_1SVG from '../../../img/stickers/animal/sticker-animal-1.svg';
import sticker_animal_2SVG from '../../../img/stickers/animal/sticker-animal-2.svg';
import sticker_animal_3SVG from '../../../img/stickers/animal/sticker-animal-3.svg';
import sticker_animal_4SVG from '../../../img/stickers/animal/sticker-animal-4.svg';
import sticker_animal_5SVG from '../../../img/stickers/animal/sticker-animal-5.svg';
import sticker_animal_6SVG from '../../../img/stickers/animal/sticker-animal-6.svg';
import sticker_animal_7SVG from '../../../img/stickers/animal/sticker-animal-7.svg';
import sticker_animal_8SVG from '../../../img/stickers/animal/sticker-animal-8.svg';
import sticker_animal_9SVG from '../../../img/stickers/animal/sticker-animal-9.svg';
import sticker_animal_10SVG from '../../../img/stickers/animal/sticker-animal-10.svg';

import sticker_tiger_1SVG from '../../../img/stickers/tiger/sticker-tiger-1.png';
import sticker_tiger_2SVG from '../../../img/stickers/tiger/sticker-tiger-2.png';
import sticker_tiger_3SVG from '../../../img/stickers/tiger/sticker-tiger-3.png';
import sticker_tiger_4SVG from '../../../img/stickers/tiger/sticker-tiger-4.png';
import sticker_tiger_5SVG from '../../../img/stickers/tiger/sticker-tiger-5.png';
import sticker_tiger_6SVG from '../../../img/stickers/tiger/sticker-tiger-6.png';
import sticker_tiger_7SVG from '../../../img/stickers/tiger/sticker-tiger-7.png';
import sticker_tiger_8SVG from '../../../img/stickers/tiger/sticker-tiger-8.png';
import sticker_tiger_9SVG from '../../../img/stickers/tiger/sticker-tiger-9.png';
import sticker_tiger_10SVG from '../../../img/stickers/tiger/sticker-tiger-10.png';

import sticker_girl_angrySVG from '../../../img/stickers/girl/sticker-girl-angry.svg';
import sticker_girl_confuseSVG from '../../../img/stickers/girl/sticker-girl-confuse.svg';
import sticker_girl_crySVG from '../../../img/stickers/girl/sticker-girl-cry.svg';
import sticker_girl_thinkSVG from '../../../img/stickers/girl/sticker-girl-think.svg';
import sticker_girl_waveSVG from '../../../img/stickers/girl/sticker-girl-wave.svg';
import sticker_girl_happySVG from '../../../img/stickers/girl/sticker-girl-happy.svg';
import sticker_girl_kissSVG from '../../../img/stickers/girl/sticker-girl-kiss.svg';
import sticker_girl_likeSVG from '../../../img/stickers/girl/sticker-girl-like.svg';
import sticker_girl_pleaseSVG from '../../../img/stickers/girl/sticker-girl-please.svg';
import sticker_girl_roll_eyesSVG from '../../../img/stickers/girl/sticker-girl-roll-eyes.svg';
import sticker_girl_sleepSVG from '../../../img/stickers/girl/sticker-girl-sleep.svg';
import sticker_girl_surprizeSVG from '../../../img/stickers/girl/sticker-girl-surprize.svg';

import sticker_type1_1SVG from '../../../img/stickers/type1/sticker-type1-1.svg';
import sticker_type1_2SVG from '../../../img/stickers/type1/sticker-type1-2.svg';
import sticker_type1_3SVG from '../../../img/stickers/type1/sticker-type1-3.svg';
import sticker_type1_4SVG from '../../../img/stickers/type1/sticker-type1-4.svg';
import sticker_type1_5SVG from '../../../img/stickers/type1/sticker-type1-5.svg';
import sticker_type1_6SVG from '../../../img/stickers/type1/sticker-type1-6.svg';
import sticker_type1_7SVG from '../../../img/stickers/type1/sticker-type1-7.svg';
import sticker_type1_8SVG from '../../../img/stickers/type1/sticker-type1-8.svg';
import sticker_type1_9SVG from '../../../img/stickers/type1/sticker-type1-9.svg';
import sticker_type1_10SVG from '../../../img/stickers/type1/sticker-type1-10.svg';

import sticker_type2_1SVG from '../../../img/stickers/type2/sticker-type2-1.svg';
import sticker_type2_2SVG from '../../../img/stickers/type2/sticker-type2-2.svg';
import sticker_type2_3SVG from '../../../img/stickers/type2/sticker-type2-3.svg';
import sticker_type2_4SVG from '../../../img/stickers/type2/sticker-type2-4.svg';
import sticker_type2_5SVG from '../../../img/stickers/type2/sticker-type2-5.svg';
import sticker_type2_6SVG from '../../../img/stickers/type2/sticker-type2-6.svg';
import sticker_type2_7SVG from '../../../img/stickers/type2/sticker-type2-7.svg';
import sticker_type2_8SVG from '../../../img/stickers/type2/sticker-type2-8.svg';
import sticker_type2_9SVG from '../../../img/stickers/type2/sticker-type2-9.svg';
import sticker_type2_10SVG from '../../../img/stickers/type2/sticker-type2-10.svg';

import sticker_type3_1SVG from '../../../img/stickers/type3/sticker-type3-1.svg';
import sticker_type3_2SVG from '../../../img/stickers/type3/sticker-type3-2.svg';
import sticker_type3_3SVG from '../../../img/stickers/type3/sticker-type3-3.svg';
import sticker_type3_4SVG from '../../../img/stickers/type3/sticker-type3-4.svg';
import sticker_type3_5SVG from '../../../img/stickers/type3/sticker-type3-5.svg';
import sticker_type3_6SVG from '../../../img/stickers/type3/sticker-type3-6.svg';
import sticker_type3_7SVG from '../../../img/stickers/type3/sticker-type3-7.svg';
import sticker_type3_8SVG from '../../../img/stickers/type3/sticker-type3-8.svg';
import sticker_type3_9SVG from '../../../img/stickers/type3/sticker-type3-9.svg';
import sticker_type3_10SVG from '../../../img/stickers/type3/sticker-type3-10.svg';

import sticker_type4_1SVG from '../../../img/stickers/type4/sticker-type4-1.svg';
import sticker_type4_2SVG from '../../../img/stickers/type4/sticker-type4-2.svg';
import sticker_type4_3SVG from '../../../img/stickers/type4/sticker-type4-3.svg';
import sticker_type4_4SVG from '../../../img/stickers/type4/sticker-type4-4.svg';
import sticker_type4_5SVG from '../../../img/stickers/type4/sticker-type4-5.svg';
import sticker_type4_6SVG from '../../../img/stickers/type4/sticker-type4-6.svg';
import sticker_type4_7SVG from '../../../img/stickers/type4/sticker-type4-7.svg';
import sticker_type4_8SVG from '../../../img/stickers/type4/sticker-type4-8.svg';
import sticker_type4_9SVG from '../../../img/stickers/type4/sticker-type4-9.svg';
import sticker_type4_10SVG from '../../../img/stickers/type4/sticker-type4-10.svg';

import sticker_type5_1SVG from '../../../img/stickers/type5/sticker-type5-1.svg';
import sticker_type5_2SVG from '../../../img/stickers/type5/sticker-type5-2.svg';
import sticker_type5_3SVG from '../../../img/stickers/type5/sticker-type5-3.svg';
import sticker_type5_4SVG from '../../../img/stickers/type5/sticker-type5-4.svg';
import sticker_type5_5SVG from '../../../img/stickers/type5/sticker-type5-5.svg';
import sticker_type5_6SVG from '../../../img/stickers/type5/sticker-type5-6.svg';
import sticker_type5_7SVG from '../../../img/stickers/type5/sticker-type5-7.svg';
import sticker_type5_8SVG from '../../../img/stickers/type5/sticker-type5-8.svg';
import sticker_type5_9SVG from '../../../img/stickers/type5/sticker-type5-9.svg';
import sticker_type5_10SVG from '../../../img/stickers/type5/sticker-type5-10.svg';

import sticker_type6_1SVG from '../../../img/stickers/type6/sticker-type6-1.svg';
import sticker_type6_2SVG from '../../../img/stickers/type6/sticker-type6-2.svg';
import sticker_type6_3SVG from '../../../img/stickers/type6/sticker-type6-3.svg';
import sticker_type6_4SVG from '../../../img/stickers/type6/sticker-type6-4.svg';
import sticker_type6_5SVG from '../../../img/stickers/type6/sticker-type6-5.svg';
import sticker_type6_6SVG from '../../../img/stickers/type6/sticker-type6-6.svg';
import sticker_type6_7SVG from '../../../img/stickers/type6/sticker-type6-7.svg';
import sticker_type6_8SVG from '../../../img/stickers/type6/sticker-type6-8.svg';
import sticker_type6_9SVG from '../../../img/stickers/type6/sticker-type6-9.svg';
import sticker_type6_10SVG from '../../../img/stickers/type6/sticker-type6-10.svg';
import sticker_type7_1SVG from '../../../img/stickers/type7/sticker-type7-1.svg';
import sticker_type7_2SVG from '../../../img/stickers/type7/sticker-type7-2.svg';
import sticker_type7_3SVG from '../../../img/stickers/type7/sticker-type7-3.svg';
import sticker_type7_4SVG from '../../../img/stickers/type7/sticker-type7-4.svg';
import sticker_type7_5SVG from '../../../img/stickers/type7/sticker-type7-5.svg';
import sticker_type7_6SVG from '../../../img/stickers/type7/sticker-type7-6.svg';
import sticker_type7_7SVG from '../../../img/stickers/type7/sticker-type7-7.svg';
import sticker_type7_8SVG from '../../../img/stickers/type7/sticker-type7-8.svg';
import sticker_type7_9SVG from '../../../img/stickers/type7/sticker-type7-9.svg';
import sticker_type7_10SVG from '../../../img/stickers/type7/sticker-type7-10.svg';

import sticker_type8_1SVG from '../../../img/stickers/type8/sticker-type8-1.svg';
import sticker_type8_2SVG from '../../../img/stickers/type8/sticker-type8-2.svg';
import sticker_type8_3SVG from '../../../img/stickers/type8/sticker-type8-3.svg';
import sticker_type8_4SVG from '../../../img/stickers/type8/sticker-type8-4.svg';
import sticker_type8_5SVG from '../../../img/stickers/type8/sticker-type8-5.svg';
import sticker_type8_6SVG from '../../../img/stickers/type8/sticker-type8-6.svg';
import sticker_type8_7SVG from '../../../img/stickers/type8/sticker-type8-7.svg';
import sticker_type8_8SVG from '../../../img/stickers/type8/sticker-type8-8.svg';
import sticker_type8_9SVG from '../../../img/stickers/type8/sticker-type8-9.svg';
import sticker_type8_10SVG from '../../../img/stickers/type8/sticker-type8-10.svg';

import sticker_type9_1SVG from '../../../img/stickers/type9/sticker-type9-1.svg';
import sticker_type9_2SVG from '../../../img/stickers/type9/sticker-type9-2.svg';
import sticker_type9_3SVG from '../../../img/stickers/type9/sticker-type9-3.svg';
import sticker_type9_4SVG from '../../../img/stickers/type9/sticker-type9-4.svg';
import sticker_type9_5SVG from '../../../img/stickers/type9/sticker-type9-5.svg';
import sticker_type9_6SVG from '../../../img/stickers/type9/sticker-type9-6.svg';
import sticker_type9_7SVG from '../../../img/stickers/type9/sticker-type9-7.svg';
import sticker_type9_8SVG from '../../../img/stickers/type9/sticker-type9-8.svg';
import sticker_type9_9SVG from '../../../img/stickers/type9/sticker-type9-9.svg';
import sticker_type9_10SVG from '../../../img/stickers/type9/sticker-type9-10.svg';

import sticker_type10_1SVG from '../../../img/stickers/type10/sticker-type10-1.svg';
import sticker_type10_2SVG from '../../../img/stickers/type10/sticker-type10-2.svg';
import sticker_type10_3SVG from '../../../img/stickers/type10/sticker-type10-3.svg';
import sticker_type10_4SVG from '../../../img/stickers/type10/sticker-type10-4.svg';
import sticker_type10_5SVG from '../../../img/stickers/type10/sticker-type10-5.svg';
import sticker_type10_6SVG from '../../../img/stickers/type10/sticker-type10-6.svg';
import sticker_type10_7SVG from '../../../img/stickers/type10/sticker-type10-7.svg';
import sticker_type10_8SVG from '../../../img/stickers/type10/sticker-type10-8.svg';
import sticker_type10_9SVG from '../../../img/stickers/type10/sticker-type10-9.svg';
import sticker_type10_10SVG from '../../../img/stickers/type10/sticker-type10-10.svg';

//tutorial #3

import welcome_alert_bgSVG from '../../../img/tutorial/welcome_alert_bg.jpg';

import how_to_start_icoSVG from '../../../img/tutorial/how_to_start_ico.svg';
import tut_arrow_1SVG from '../../../img/tutorial/tut_arrow_1.svg';
import tut_arrow_3SVG from '../../../img/tutorial/tut_arrow_3.svg';
import tut_arrow_3_mobSVG from '../../../img/tutorial/tut_arrow_3_mob.svg';
import tut_arrow_4_1SVG from '../../../img/tutorial/tut_arrow_4_1.svg';
import tut_arrow_4_2SVG from '../../../img/tutorial/tut_arrow_4_2.svg';

import tut_not_girl_1PNG from '../../../img/tutorial/tut_not_girl_1.png';
import tut_not_girl_2PNG from '../../../img/tutorial/tut_not_girl_2.png';
import tut_not_girl_3PNG from '../../../img/tutorial/tut_not_girl_3.png';
import tut_man_avatarPNG from '../../../img/tutorial/tut_man_avatar.png';

import tut_not_man_1JPG from '../../../img/tutorial/tut_not_man_1.jpg';
import tut_not_man_2JPG from '../../../img/tutorial/tut_not_man_2.jpg';

import tut_search_icon_activeSVG from '../../../img/tutorial/tut_search_icon_active.svg';
import tut_likeher_icoSVG from '../../../img/tutorial/tut_likeher_ico.svg';
import tut_video_icoSVG from '../../../img/tutorial/tut_video_ico.svg';
import tut_chat_icoSVG from '../../../img/tutorial/tut_chat_ico.svg';
import tut_letters_icoSVG from '../../../img/tutorial/tut_letters_ico.svg';
import tut_like_heart_iconSVG from '../../../img/tutorial/tut_like_heart_icon.svg';
import tut_star_yellowSVG from '../../../img/tutorial/tut_star_yellow.svg';

import tut_step_1PNG from '../../../img/tutorial/tut_step_1.png';
import tut_step_1_mobPNG from '../../../img/tutorial/tut_step_1_mob.png';
import tut_step_3PNG from '../../../img/tutorial/tut_step_3.png';
import tut_step_3_mobPNG from '../../../img/tutorial/tut_step_3_mob.png';
import tut_step_4PNG from '../../../img/tutorial/tut_step_4.png';
import tut_step_4_mobPNG from '../../../img/tutorial/tut_step_4_mob.png';
import tut_step_6_mobPNG from '../../../img/tutorial/tut_step_6_mob.png';

import tut_g_step_1PNG from '../../../img/tutorial/tut_g_step_1.png';
import tut_g_step_3PNG from '../../../img/tutorial/tut_g_step_3.png';
import tut_g_step_3_mobPNG from '../../../img/tutorial/tut_g_step_3_mob.png';
import tut_g_step_4PNG from '../../../img/tutorial/tut_g_step_4.png';
import tut_g_step_4_mobPNG from '../../../img/tutorial/tut_g_step_4_mob.png';
import tut_g_step_6_mobPNG from '../../../img/tutorial/tut_g_step_6_mob.png';

// New HowToStart
import tut_icon_accountSVG from '../../../img/tutorial/icon_account.svg';
import tut_step_1_girlPNG from '../../../img/tutorial/tut_step_1_girl.png';
import tut_step_1_manPNG from '../../../img/tutorial/tut_step_1_man.png';
import tut_step_5_girlPNG from '../../../img/tutorial/tut_step_5_girl.png';
import tut_step_3_girl_1PNG from '../../../img/tutorial/tut_step_3_girl_1.png';
import tut_step_3_man_1PNG from '../../../img/tutorial/tut_step_3_man_1.png';
import tut_step_3_girl_2PNG from '../../../img/tutorial/tut_step_3_girl_2.png';
import tut_step_3_man_2PNG from '../../../img/tutorial/tut_step_3_man_2.png';
import tut_step_3_girl_3PNG from '../../../img/tutorial/tut_step_3_girl_3.png';
import tut_step_3_man_3PNG from '../../../img/tutorial/tut_step_3_man_3.png';
import tut_step_4_girl_primary_photoPNG from '../../../img/tutorial/tut_step_4_primary_girl.png';
import tut_step_4_man_primary_photoPNG from '../../../img/tutorial/tut_step_4_primary_man.png';
import tut_step_4_girl_secondary_1PNG from '../../../img/tutorial/tut_step_4_girl_secondary_1.png';
import tut_step_4_man_secondary_1PNG from '../../../img/tutorial/tut_step_4_man_secondary_1.png';
import tut_step_4_girl_secondary_2PNG from '../../../img/tutorial/tut_step_4_girl_secondary_2.png';
import tut_step_4_man_secondary_2PNG from '../../../img/tutorial/tut_step_4_man_secondary_2.png';
import tut_step_4_girl_secondary_3PNG from '../../../img/tutorial/tut_step_4_girl_secondary_3.png';
import tut_step_4_man_secondary_3PNG from '../../../img/tutorial/tut_step_4_man_secondary_3.png';
import tut_step_4_girl_small_1PNG from '../../../img/tutorial/tut_step_4_girl_small_1.png';
import tut_step_4_man_small_1PNG from '../../../img/tutorial/tut_step_4_man_small_1.png';
import tut_step_4_girl_small_2PNG from '../../../img/tutorial/tut_step_4_girl_small_2.png';
import tut_step_4_man_small_2PNG from '../../../img/tutorial/tut_step_4_man_small_2.png';
import tut_step_6_girl1JPG from '../../../img/tutorial/tut_step_6_girl1.png';
import tut_step_6_man1JPG from '../../../img/tutorial/tut_step_6_man1.png';
import tut_step_6_girl2JPG from '../../../img/tutorial/tut_step_6_girl2.png';
import tut_step_6_man2JPG from '../../../img/tutorial/tut_step_6_man2.png';

import pciSVG from '../../../img/pci.svg';
import stripeSVG from '../../../img/stripe.svg';

//reset pass instruction
import placeholderGif from '../../../img/placeholder.gif';
import deletedAvatarIconSVG from '../../../img/deleted_avatar_icon.svg';

//love stories page
import love_stories_banner_titleSVG from '../../../img/love-stories-page/love_stories_banner_title.svg';
import photo_slider_arrow_leftSVG from '../../../img/love-stories-page/photo_slider_arrow_left.svg';
import photo_slider_arrow_rightSVG from '../../../img/love-stories-page/photo_slider_arrow_right.svg';
import photo_slider_arrow_left_whiteSVG from '../../../img/love-stories-page/photo_slider_arrow_left_white.svg';
import photo_slider_arrow_right_whiteSVG from '../../../img/love-stories-page/photo_slider_arrow_right_white.svg';
import video_slider_arrow_leftSVG from '../../../img/love-stories-page/video_slider_arrow_left.svg';
import video_slider_arrow_rightSVG from '../../../img/love-stories-page/video_slider_arrow_right.svg';
import ls_photo_slider_1JPG from '../../../img/love-stories-page/ls_photo_slider_1.jpg';
import ls_photo_slider_2JPG from '../../../img/love-stories-page/ls_photo_slider_2.jpg';
import ls_photo_slider_3JPG from '../../../img/love-stories-page/ls_photo_slider_3.jpg';
import ls_preview_video_slider_1JPG from '../../../img/love-stories-page/ls_preview_video_slider_1.jpg';
import ls_preview_video_slider_2JPG from '../../../img/love-stories-page/ls_preview_video_slider_2.jpg';
import ls_video_slider_1MP4 from '../../../img/love-stories-page/ls_video_slider_1.mp4';
import ls_video_slider_2MP4 from '../../../img/love-stories-page/ls_video_slider_2.mp4';
import ls_photo_comment_1PNG from '../../../img/love-stories-page/ls_photo_comment_1.png';
import ls_photo_comment_2PNG from '../../../img/love-stories-page/ls_photo_comment_2.png';
import ls_quot_openSVG from '../../../img/love-stories-page/ls_quot_open.svg';
import ls_quot_closeSVG from '../../../img/love-stories-page/ls_quot_close.svg';
import c3sFiltersBtnSVG from '../../../img/c3s-filters-btn.svg';

// Virtual gifts - Flowers
import gift_azureFlowerPNG from '../../../img/virtual-gifts/flowers/azure_flower.png';
import gift_pinkFlowerPNG from '../../../img/virtual-gifts/flowers/pink_flower.png';
import gift_yellowFlowerPNG from '../../../img/virtual-gifts/flowers/yellow_flower.png';
import gift_bouquetRosesPNG from '../../../img/virtual-gifts/flowers/bouquet_roses.png';
import gift_pinkRosesPNG from '../../../img/virtual-gifts/flowers/pink_roses.png';
import gift_yellowBouquetPNG from '../../../img/virtual-gifts/flowers/yellow_bouquet.png';
import gift_lotusPNG from '../../../img/virtual-gifts/flowers/lotus.png';
import gift_redFlowerPNG from '../../../img/virtual-gifts/flowers/red_flower.png';
import gift_redRosesPNG from '../../../img/virtual-gifts/flowers/red_roses.png';
import gift_waterLillyPNG from '../../../img/virtual-gifts/flowers/water_lily.png';
import gift_callaLilyPNG from '../../../img/virtual-gifts/flowers/calla_lily.png';
import gift_redBouquetPNG from '../../../img/virtual-gifts/flowers/red_bouquet.png';
import gift_daffodilPNG from '../../../img/virtual-gifts/flowers/daffodil.png';
import gift_whiteFlowerPNG from '../../../img/virtual-gifts/flowers/white_flower.png';
import gift_tulipsPNG from '../../../img/virtual-gifts/flowers/tulips.png';
import gift_daisyPNG from '../../../img/virtual-gifts/flowers/daisy.png';
import gift_jasminePNG from '../../../img/virtual-gifts/flowers/jasmine.png';
import gift_sakuraPNG from '../../../img/virtual-gifts/flowers/sakura.png';
import gift_rosePNG from '../../../img/virtual-gifts/flowers/rose.png';
import gift_orchidPNG from '../../../img/virtual-gifts/flowers/orchid.png';
import gift_irisPNG from '../../../img/virtual-gifts/flowers/iris.png';
import gift_pinkBouquetPNG from '../../../img/virtual-gifts/flowers/pink_bouquet.png';
import gift_blueBouquetPNG from '../../../img/virtual-gifts/flowers/blue_bouquet.png';
// Virtual gifts - Expensive brands
import gift_braceletPNG from '../../../img/virtual-gifts/expensive-brands/bracelet.png';
import gift_weddingRingsPNG from '../../../img/virtual-gifts/expensive-brands/wedding_rings.png';
import gift_purple_ringPNG from '../../../img/virtual-gifts/expensive-brands/purple_ring.png';
import gift_diamond_ringPNG from '../../../img/virtual-gifts/expensive-brands/diamond_ring.png';
import gift_heart_pendantPNG from '../../../img/virtual-gifts/expensive-brands/heart_pendant.png';
import gift_silver_ringPNG from '../../../img/virtual-gifts/expensive-brands/silver_ring.png';
import gift_pair_heart_ringsPNG from '../../../img/virtual-gifts/expensive-brands/pair_heart_rings.png';
import gift_diamond_heartPNG from '../../../img/virtual-gifts/expensive-brands/diamond_heart.png';
import gift_diamondPNG from '../../../img/virtual-gifts/expensive-brands/diamond.png';
import gift_pair_of_ringsPNG from '../../../img/virtual-gifts/expensive-brands/pair_of_rings.png';
import gift_earringsPNG from '../../../img/virtual-gifts/expensive-brands/earrings.png';
import gift_heart_ringPNG from '../../../img/virtual-gifts/expensive-brands/heart_ring.png';
import gift_necklacePNG from '../../../img/virtual-gifts/expensive-brands/necklace.png';
import gift_golden_ringPNG from '../../../img/virtual-gifts/expensive-brands/golden_ring.png';
// Virtual gifts - Food
import gift_avocadoPNG from '../../../img/virtual-gifts/food/avocado.png';
import gift_cheesecakePNG from '../../../img/virtual-gifts/food/cheesecake.png';
import gift_coffeePNG from '../../../img/virtual-gifts/food/coffee.png';
import gift_pastePNG from '../../../img/virtual-gifts/food/paste.png';
import gift_cocktailPNG from '../../../img/virtual-gifts/food/cocktail.png';
import gift_icecreamPNG from '../../../img/virtual-gifts/food/icecream.png';
import gift_cakePNG from '../../../img/virtual-gifts/food/cake.png';
import gift_donutPNG from '../../../img/virtual-gifts/food/donut.png';
import gift_seafoodPNG from '../../../img/virtual-gifts/food/seafood.png';
import gift_champagnePNG from '../../../img/virtual-gifts/food/champagne.png';
import gift_burgerPNG from '../../../img/virtual-gifts/food/burger.png';
import gift_pizzaPNG from '../../../img/virtual-gifts/food/pizza.png';
// Virtual gifts - Virtual symbols and icons
import gift_awardPNG from '../../../img/virtual-gifts/symbols/award.png';
import gift_bookPNG from '../../../img/virtual-gifts/symbols/book.png';
import gift_shoesPNG from '../../../img/virtual-gifts/symbols/shoes.png';
import gift_phonographPNG from '../../../img/virtual-gifts/symbols/phonograph.png';
import gift_microphonePNG from '../../../img/virtual-gifts/symbols/microphone.png';
import gift_footballPNG from '../../../img/virtual-gifts/symbols/football.png';
import gift_tvPNG from '../../../img/virtual-gifts/symbols/tv.png';
import gift_cameraPNG from '../../../img/virtual-gifts/symbols/camera.png';
import gift_painting_and_brushPNG from '../../../img/virtual-gifts/symbols/painting_and_brush.png';
import gift_sportPNG from '../../../img/virtual-gifts/symbols/sport.png';
import gift_food_and_drinkPNG from '../../../img/virtual-gifts/symbols/food_and_drink.png';
// Virtual gifts - Virtual travel
import gift_balloonPNG from '../../../img/virtual-gifts/travel/balloon.png';
import gift_passportPNG from '../../../img/virtual-gifts/travel/passport.png';
import gift_beach_vacationPNG from '../../../img/virtual-gifts/travel/beach_vacation.png';
import gift_suitcase_and_cameraPNG from '../../../img/virtual-gifts/travel/suitcase_and_camera.png';
import gift_travel_kitPNG from '../../../img/virtual-gifts/travel/travel_kit.png';
import gift_vacationPNG from '../../../img/virtual-gifts/travel/vacation.png';
import gift_travel_expensesPNG from '../../../img/virtual-gifts/travel/travel_expenses.png';
import gift_campingPNG from '../../../img/virtual-gifts/travel/camping.png';
import gift_plane_and_passportPNG from '../../../img/virtual-gifts/travel/plane_and_passport.png';
import gift_flightPNG from '../../../img/virtual-gifts/travel/flight.png';
//Virtual gifts - Virtual Music Tracks
import gift_bluesPNG from '../../../img/virtual-gifts/music/blues.png';
import gift_jazzPNG from '../../../img/virtual-gifts/music/jazz.png';
import gift_rockPNG from '../../../img/virtual-gifts/music/rock.png';
import gift_discoPNG from '../../../img/virtual-gifts/music/disco.png';
import gift_rapPNG from '../../../img/virtual-gifts/music/rap.png';
//Virtual gifts - Feelings
import gift_amorousPNG from '../../../img/virtual-gifts/feelings/amorous.png';
import gift_broPNG from '../../../img/virtual-gifts/feelings/bro.png';
import gift_bottle_blue_waterPNG from '../../../img/virtual-gifts/feelings/bottle_blue_water.png';
import gift_crushPNG from '../../../img/virtual-gifts/feelings/crush.png';
import gift_goodmoodPNG from '../../../img/virtual-gifts/feelings/goodmood.png';
import gift_fiercelyPNG from '../../../img/virtual-gifts/feelings/fiercely.png';
import gift_excitedPNG from '../../../img/virtual-gifts/feelings/excited.png';
import gift_heart_in_bulbPNG from '../../../img/virtual-gifts/feelings/heart_in_bulb.png';
import gift_weepyPNG from '../../../img/virtual-gifts/feelings/weepy.png';
import gift_bottlePNG from '../../../img/virtual-gifts/feelings/bottle.png';
import gift_crownPNG from '../../../img/virtual-gifts/feelings/crown.png';
import gift_heartPNG from '../../../img/virtual-gifts/feelings/heart.png';
import gift_in_depressionPNG from '../../../img/virtual-gifts/feelings/in_depression.png';
//Virtual gifts - Zodiac sign
import gift_aquariusPNG from '../../../img/virtual-gifts/zodiac/aquarius.png';
import gift_libraPNG from '../../../img/virtual-gifts/zodiac/libra.png';
import gift_piscesPNG from '../../../img/virtual-gifts/zodiac/pisces.png';
import gift_geminiPNG from '../../../img/virtual-gifts/zodiac/gemini.png';
import gift_ariesPNG from '../../../img/virtual-gifts/zodiac/aries.png';
import gift_virgoPNG from '../../../img/virtual-gifts/zodiac/virgo.png';
import gift_capricornPNG from '../../../img/virtual-gifts/zodiac/capricorn.png';
import gift_cancerPNG from '../../../img/virtual-gifts/zodiac/cancer.png';
import gift_sagittariusPNG from '../../../img/virtual-gifts/zodiac/sagittarius.png';
import gift_scorpioPNG from '../../../img/virtual-gifts/zodiac/scorpio.png';
import gift_leoPNG from '../../../img/virtual-gifts/zodiac/leo.png';
import gift_taurusPNG from '../../../img/virtual-gifts/zodiac/taurus.png';

export const emojiSprites = emojiSpritesPNG;
export const c3sFiltersBtn = c3sFiltersBtnSVG;
export const mp3webp = mp3WEBP;
export const mp2webp = mp2WEBP;
export const mp1webp = mp1WEBP;

export const mp1jpg = mp1JPG;
export const mp2jpg = mp2JPG;
export const mp3jpg = mp3JPG;

export const mv2webp = mv2WEBP;
export const mv1webp = mv1WEBP;

export const mv2jpg = mv2JPG;
export const mv1jpg = mv1JPG;

export const wink = winkSVG;
export const confirm_email_ico = confirm_email_icoSVG;
export const vipBanner = vipBannerSVG;
export const vipOnlineNowBanner = vipOnlineNowBannerSVG;
export const vipCross = vipCrossSVG;
export const vipStar = vipStarSVG;
export const check_circle_dark = check_circle_darkSVG;
export const c3p_manage_email = c3p_manage_emailSVG;
export const c3p_manage_pass = c3p_manage_passSVG;
export const c3p_manage_logout = c3p_manage_logoutSVG;
export const down_arrow_white = down_arrow_whiteSVG;
export const c3l_add_more_men_btn = c3l_add_more_men_btnSVG;
export const no_pause_recurring = no_pause_recurringSVG;
export const user_birthday = user_birthdaySVG;
export const video_error = video_errorSVG;
export const policy_icon_mob_menu = policy_icon_mob_menuSVG;
export const congratulation_box = congratulation_boxSVG;

export const letters_lightblue = letters_lightblueSVG;
export const letter_violet = letter_violetSVG;
export const profile_violet = profile_violetSVG;
export const video_violet = video_violetSVG;
export const video_lightblue = video_lightblueSVG;
export const lh_next_arrow = lh_next_arrowSVG;
export const liked_darkblue = liked_darkblueSVG;
export const your_balance = your_balanceSVG;
export const right_arrow_long_white = right_arrow_long_whiteSVG;
export const mob_letter = mob_letterSVG;
export const mob_fav = mob_favSVG;
export const mob_fav_a = mob_fav_aSVG;
export const chat_in_letters_mobile = chat_in_letters_mobileSVG;
export const left_arrow = left_arrowSVG;
export const profile_ico_gallery = profile_ico_gallerySVG;
export const coins = coinsSVG;
export const verify_phone = verify_phoneSVG;

export const welcome_popup_close = welcome_popup_closeSVG;
export const welcome_photo7 = welcome_photo7JPG;
export const welcome_photo_new = welcome_photo_newPNG;
export const welcome_photo_new_mob = welcome_photo_new_mobPNG;
export const welcome_photo6 = welcome_photo6JPG;
export const welcome_photo5 = welcome_photo5JPG;
export const welcome_photo4 = welcome_photo4JPG;
export const welcome_photo3 = welcome_photo3JPG;
export const welcome_photo2 = welcome_photo2JPG;
export const welcome_photo1 = welcome_photo1JPG;
export const welcome_photo7_okamour = welcome_photo_ok7JPG;
export const welcome_photo6_okamour = welcome_photo_ok6JPG;
export const welcome_photo5_okamour = welcome_photo_ok5JPG;
export const welcome_photo4_okamour = welcome_photo_ok4JPG;
export const welcome_photo3_okamour = welcome_photo_ok3JPG;
export const welcome_photo2_okamour = welcome_photo_ok2JPG;
export const welcome_photo1_okamour = welcome_photo_ok1JPG;

export const art = artSVG;
export const get_credit_mail = get_credit_mailSVG;
export const get_credit_write_us = get_credit_write_usSVG;

export const first_credit_buy = firstCreditBuySVG;
export const c3mp_letter = c3mp_letterSVG;
export const profile_new_avatar = profile_new_avatarSVG;
export const profile_select_arrow = profile_select_arrowSVG;
export const profile_select_arrow_pass = profile_select_arrow_passSVG;
export const np_about = np_aboutSVG;
export const np_hobby = np_hobbySVG;
export const np_photo = np_photoSVG;
export const profile_click_info = profile_click_infoSVG;
export const profile_add_photo_plus = profile_add_photo_plusSVG;
export const arrow_down_blue = arrow_down_blueSVG;

//export jobs
export const education = educationSVG;
export const information = informationSVG;
export const law = lawSVG;

//export stickers
export const sticker_heart_arrow = sticker_heart_arrowSVG;
export const sticker_heart_broken = sticker_heart_brokenSVG;
export const sticker_heart_confused = sticker_heart_confusedSVG;
export const sticker_heart_cry = sticker_heart_crySVG;
export const sticker_heart_hi = sticker_heart_hiSVG;
export const sticker_heart_hug = sticker_heart_hugSVG;
export const sticker_heart_laugh = sticker_heart_laughSVG;
export const sticker_heart_like = sticker_heart_likeSVG;
export const sticker_heart_love = sticker_heart_loveSVG;
export const sticker_heart_sad = sticker_heart_sadSVG;

export const sticker_guy_angry = sticker_guy_angrySVG;
export const sticker_guy_confused = sticker_guy_confusedSVG;
export const sticker_guy_cool = sticker_guy_coolSVG;
export const sticker_guy_happy = sticker_guy_happySVG;
export const sticker_guy_kiss = sticker_guy_kissSVG;
export const sticker_guy_like = sticker_guy_likeSVG;
export const sticker_guy_please = sticker_guy_pleaseSVG;
export const sticker_guy_roll_eyes = sticker_guy_roll_eyesSVG;
export const sticker_guy_sleep = sticker_guy_sleepSVG;
export const sticker_guy_strong = sticker_guy_strongSVG;
export const sticker_guy_surprize = sticker_guy_surprizeSVG;
export const sticker_guy_think = sticker_guy_thinkSVG;
export const sticker_guy_wave = sticker_guy_waveSVG;

export const sticker_animal_1 = sticker_animal_1SVG;
export const sticker_animal_2 = sticker_animal_2SVG;
export const sticker_animal_3 = sticker_animal_3SVG;
export const sticker_animal_4 = sticker_animal_4SVG;
export const sticker_animal_5 = sticker_animal_5SVG;
export const sticker_animal_6 = sticker_animal_6SVG;
export const sticker_animal_7 = sticker_animal_7SVG;
export const sticker_animal_8 = sticker_animal_8SVG;
export const sticker_animal_9 = sticker_animal_9SVG;
export const sticker_animal_10 = sticker_animal_10SVG;

export const sticker_tiger_1 = sticker_tiger_1SVG;
export const sticker_tiger_2 = sticker_tiger_2SVG;
export const sticker_tiger_3 = sticker_tiger_3SVG;
export const sticker_tiger_4 = sticker_tiger_4SVG;
export const sticker_tiger_5 = sticker_tiger_5SVG;
export const sticker_tiger_6 = sticker_tiger_6SVG;
export const sticker_tiger_7 = sticker_tiger_7SVG;
export const sticker_tiger_8 = sticker_tiger_8SVG;
export const sticker_tiger_9 = sticker_tiger_9SVG;
export const sticker_tiger_10 = sticker_tiger_10SVG;

export const sticker_girl_angry = sticker_girl_angrySVG;
export const sticker_girl_confuse = sticker_girl_confuseSVG;
export const sticker_girl_cry = sticker_girl_crySVG;
export const sticker_girl_happy = sticker_girl_happySVG;
export const sticker_girl_kiss = sticker_girl_kissSVG;
export const sticker_girl_like = sticker_girl_likeSVG;
export const sticker_girl_please = sticker_girl_pleaseSVG;
export const sticker_girl_roll_eyes = sticker_girl_roll_eyesSVG;
export const sticker_girl_sleep = sticker_girl_sleepSVG;
export const sticker_girl_surprize = sticker_girl_surprizeSVG;
export const sticker_girl_think = sticker_girl_thinkSVG;
export const sticker_girl_wave = sticker_girl_waveSVG;

export const sticker_type1_1 = sticker_type1_1SVG;
export const sticker_type1_2 = sticker_type1_2SVG;
export const sticker_type1_3 = sticker_type1_3SVG;
export const sticker_type1_4 = sticker_type1_4SVG;
export const sticker_type1_5 = sticker_type1_5SVG;
export const sticker_type1_6 = sticker_type1_6SVG;
export const sticker_type1_7 = sticker_type1_7SVG;
export const sticker_type1_8 = sticker_type1_8SVG;
export const sticker_type1_9 = sticker_type1_9SVG;
export const sticker_type1_10 = sticker_type1_10SVG;

export const sticker_type2_1 = sticker_type2_1SVG;
export const sticker_type2_2 = sticker_type2_2SVG;
export const sticker_type2_3 = sticker_type2_3SVG;
export const sticker_type2_4 = sticker_type2_4SVG;
export const sticker_type2_5 = sticker_type2_5SVG;
export const sticker_type2_6 = sticker_type2_6SVG;
export const sticker_type2_7 = sticker_type2_7SVG;
export const sticker_type2_8 = sticker_type2_8SVG;
export const sticker_type2_9 = sticker_type2_9SVG;
export const sticker_type2_10 = sticker_type2_10SVG;

export const sticker_type3_1 = sticker_type3_1SVG;
export const sticker_type3_2 = sticker_type3_2SVG;
export const sticker_type3_3 = sticker_type3_3SVG;
export const sticker_type3_4 = sticker_type3_4SVG;
export const sticker_type3_5 = sticker_type3_5SVG;
export const sticker_type3_6 = sticker_type3_6SVG;
export const sticker_type3_7 = sticker_type3_7SVG;
export const sticker_type3_8 = sticker_type3_8SVG;
export const sticker_type3_9 = sticker_type3_9SVG;
export const sticker_type3_10 = sticker_type3_10SVG;

export const sticker_type4_1 = sticker_type4_1SVG;
export const sticker_type4_2 = sticker_type4_2SVG;
export const sticker_type4_3 = sticker_type4_3SVG;
export const sticker_type4_4 = sticker_type4_4SVG;
export const sticker_type4_5 = sticker_type4_5SVG;
export const sticker_type4_6 = sticker_type4_6SVG;
export const sticker_type4_7 = sticker_type4_7SVG;
export const sticker_type4_8 = sticker_type4_8SVG;
export const sticker_type4_9 = sticker_type4_9SVG;
export const sticker_type4_10 = sticker_type4_10SVG;

export const sticker_type5_1 = sticker_type5_1SVG;
export const sticker_type5_2 = sticker_type5_2SVG;
export const sticker_type5_3 = sticker_type5_3SVG;
export const sticker_type5_4 = sticker_type5_4SVG;
export const sticker_type5_5 = sticker_type5_5SVG;
export const sticker_type5_6 = sticker_type5_6SVG;
export const sticker_type5_7 = sticker_type5_7SVG;
export const sticker_type5_8 = sticker_type5_8SVG;
export const sticker_type5_9 = sticker_type5_9SVG;
export const sticker_type5_10 = sticker_type5_10SVG;

export const sticker_type6_1 = sticker_type6_1SVG;
export const sticker_type6_2 = sticker_type6_2SVG;
export const sticker_type6_3 = sticker_type6_3SVG;
export const sticker_type6_4 = sticker_type6_4SVG;
export const sticker_type6_5 = sticker_type6_5SVG;
export const sticker_type6_6 = sticker_type6_6SVG;
export const sticker_type6_7 = sticker_type6_7SVG;
export const sticker_type6_8 = sticker_type6_8SVG;
export const sticker_type6_9 = sticker_type6_9SVG;
export const sticker_type6_10 = sticker_type6_10SVG;

export const sticker_type7_1 = sticker_type7_1SVG;
export const sticker_type7_2 = sticker_type7_2SVG;
export const sticker_type7_3 = sticker_type7_3SVG;
export const sticker_type7_4 = sticker_type7_4SVG;
export const sticker_type7_5 = sticker_type7_5SVG;
export const sticker_type7_6 = sticker_type7_6SVG;
export const sticker_type7_7 = sticker_type7_7SVG;
export const sticker_type7_8 = sticker_type7_8SVG;
export const sticker_type7_9 = sticker_type7_9SVG;
export const sticker_type7_10 = sticker_type7_10SVG;

export const sticker_type8_1 = sticker_type8_1SVG;
export const sticker_type8_2 = sticker_type8_2SVG;
export const sticker_type8_3 = sticker_type8_3SVG;
export const sticker_type8_4 = sticker_type8_4SVG;
export const sticker_type8_5 = sticker_type8_5SVG;
export const sticker_type8_6 = sticker_type8_6SVG;
export const sticker_type8_7 = sticker_type8_7SVG;
export const sticker_type8_8 = sticker_type8_8SVG;
export const sticker_type8_9 = sticker_type8_9SVG;
export const sticker_type8_10 = sticker_type8_10SVG;

export const sticker_type9_1 = sticker_type9_1SVG;
export const sticker_type9_2 = sticker_type9_2SVG;
export const sticker_type9_3 = sticker_type9_3SVG;
export const sticker_type9_4 = sticker_type9_4SVG;
export const sticker_type9_5 = sticker_type9_5SVG;
export const sticker_type9_6 = sticker_type9_6SVG;
export const sticker_type9_7 = sticker_type9_7SVG;
export const sticker_type9_8 = sticker_type9_8SVG;
export const sticker_type9_9 = sticker_type9_9SVG;
export const sticker_type9_10 = sticker_type9_10SVG;

export const sticker_type10_1 = sticker_type10_1SVG;
export const sticker_type10_2 = sticker_type10_2SVG;
export const sticker_type10_3 = sticker_type10_3SVG;
export const sticker_type10_4 = sticker_type10_4SVG;
export const sticker_type10_5 = sticker_type10_5SVG;
export const sticker_type10_6 = sticker_type10_6SVG;
export const sticker_type10_7 = sticker_type10_7SVG;
export const sticker_type10_8 = sticker_type10_8SVG;
export const sticker_type10_9 = sticker_type10_9SVG;
export const sticker_type10_10 = sticker_type10_10SVG;

export const c3lh_item_big_close_blue = c3lh_item_big_close_blueSVG;
export const c1_profile_btn = c1_profile_btnSVG;
export const block_list_p = block_list_pSVG;
export const faq_mob_menu = faq_mob_menuSVG;
export const tutorial_mob_menu = tutorial_mob_menuSVG;
export const c3ph_meet = c3ph_meetSVG;
export const c3ph_contact = c3ph_contactSVG;
export const c3ph_gift = c3ph_giftSVG;
export const c3ph_online_now = c3ph_online_nowSVG;
export const c3ph_video = c3ph_videoSVG;
export const emoji = c3_smileSVG;

export const right_arrow_blue = right_arrow_blueSVG;
export const arrow_right_white = arrow_right_whiteSVG;
export const m_cross_grey = m_cross_greySVG;
export const reg_close = reg_closeSVG;
export const profile_img_empty = profile_img_emptyPNG;
export const c3lh_item_big_close_light = c3lh_item_big_close_lightSVG;
export const c3_empty = c3_emptySVG;
export const c4_support = c4_supportSVG;
export const c4_bottom_call = c4_bottom_callSVG;
export const c4_bottom_live_chat = c4_bottom_live_chatSVG;
export const c4_bottom_email = c4_bottom_emailSVG;
export const c4_bottom_whats_app = c4_bottom_whats_appSVG;
export const c4_support_primary_blue = c4_support_primary_blueSVG;
export const c3lh_control_like_her = c3lh_control_like_herSVG;
export const attach_file_drop_active = attach_file_drop_activeSVG;
export const attach_file_drop = attach_file_dropSVG;
export const attach_close_ico = attach_close_icoSVG;
export const icon_routing_close = icon_routing_closeSVG;
export const attach_send_ico = attach_send_icoSVG;
export const attach_upload_ico = attach_upload_icoSVG;
export const attach_upload_ico_grey = attach_upload_ico_greySVG;
export const close_one_popup = close_one_popupSVG;
export const check_circle_white = check_circle_whiteSVG;
export const check_circle_gray_bold = check_circle_gray_boldSVG;
export const plus_circle_blue = plus_circle_blueSVG;
export const delete_red = delete_redSVG;
export const check_circle_green = check_circle_greenSVG;
export const check_circle_green_bold = check_circle_green_boldSVG;
export const check_circle_green_fill = check_circle_green_fillSVG;
export const credit_card_img = credit_card_imgSVG;
export const c3l_restore_msg = c3l_restore_msgSVG;
export const c3l_gotochat_v = c3l_gotochat_vSVG;
export const c3l_reply = c3l_replySVG;
export const c3l_profile = c3l_profileSVG;
export const c1_credits_white = c1_credits_whiteSVG;
// CREATE PROFILE PAGE
export const copy_white = copy_whiteSVG;
export const subscribe_ico = subscribe_icoSVG;
export const c3ph_purchase = c3ph_purchaseSVG;
export const cross_blue = cross_blueSVG;
export const delete_photo = delete_photoSVG;
export const check_circle_blue = check_circle_blueSVG;
export const plus = plusSVG;
export const cross_white = cross_whiteSVG;
export const profile_empty_video_play = profile_empty_video_playSVG;
export const profile_empty_video_ico = profile_empty_video_icoSVG;
export const profile_like = profile_likeSVG;
export const profile_like_a = profile_like_aSVG;
export const profile_prev_btn = profile_prev_btnSVG;
export const profile_next_btn = profile_next_btnSVG;
export const profile_mob_favs = profile_mob_favsSVG;
export const profile_mob_favs_a = profile_mob_favs_aSVG;
export const profile_gift = profile_giftSVG;
export const profile_complain = profile_complainSVG;
export const profile_letter = profile_letterSVG;
export const delete_profile_icon = delete_profile_iconSVG;
export const heart_red = heart_redSVG;
export const next_arrow_blue = next_arrow_blueSVG;
export const hello_ico_lightblue = hello_ico_lightblueSVG;
export const say_hello_yellow = say_hello_yellowSVG;
export const empty_opened_chat = empty_opened_chatSVG;
export const chat_hello_yellow = chat_hello_yellowSVG;
export const four_o_four_bg = four_o_four_bgSVG;
export const move = moveSVG;
export const mute = muteSVG;
export const muted = mutedSVG;

export const cardpay_logo = cardpay_logoPNG;
export const pxp = pxpPNG;
export const routing = routingJPG;
export const unlimint = unlimintSVG;
export const mastercard = mastercardSVG;
export const mastercard_white = mastercard_whiteSVG;
export const mastercard_new = mastercard_new_SVG;
export const maestro = maestroSVG;
export const amex = amexSVG;
export const amex_new = amex_newPNG;
export const lpb = lpbSVG;
export const lpbV2 = lpbV2SVG;
export const securionpay = securionpaySVG;
export const centrobill = '';
export const square = '';
export const pci = pciSVG;
export const stripe = stripeSVG;
export const visa = visaSVG;
export const visa_white_bg = visa_white_bgSVG;
export const paypal = '';
export const trust = trustSVG;
export const coinbase = coinbaseSVG;
export const apple_logo_black = apple_logo_blackSVG;
export const applepay = applepaySVG;
export const discover = discoverPNG;
export const discover_svg = discoverSVG;
export const google_color = google_colorSVG;
export const googlepay = googlepaySVG;
export const uni = uniSVG;
export const apple_pay_white = apple_pay_whiteSVG;

export const auto_shield = auto_shieldSVG;
export const auto_info = auto_infoSVG;
// MAIN PAGE
export const pap_btn_close = pap_btn_closeSVG;
export const mp_support = mp_supportSVG;
export const mp_safe_pay = mp_safe_paySVG;
export const mp_meeting = mp_meetingSVG;
export const mp_profiles = mp_profilesSVG;
export const mp_commun = mp_communSVG;
export const mp_tools = mp_toolsSVG;
export const mp_video_shows = mp_video_showsSVG;
export const mp_visual_content = mp_visual_contentSVG;
export const mp_free_reg = mp_free_regSVG;
export const c3lh_item_profile = c3lh_item_profileSVG;
export const c3lh_item_video = c3lh_item_videoSVG;
export const c3lh_item_chat = c3lh_item_chatSVG;
export const c3lh_item_mail = c3lh_item_mailSVG;
export const c3lh_item_like = c3lh_item_likeSVG;
export const c3lh_item_like_active = c3lh_item_like_activeSVG;
export const login_btn_white = login_btn_whiteSVG;
export const ua = uaSVG;
export const play_btn = play_btnSVG;
export const fi_1 = fi_1SVG;
export const fi_2 = fi_2SVG;
export const fi_3 = fi_3SVG;
export const fi_4 = fi_4SVG;
export const fi_5 = fi_5SVG;
export const fs_2 = fs_2SVG;
export const fs_3 = fs_3SVG;
export const fs_5 = fs_5SVG;
export const fs_6 = fs_6SVG;
export const chat_white = chat_whiteSVG;
// LETTER PAGE
export const c2l_id_search_btn = c2l_id_search_btnSVG;
export const c3l_delete = c3l_deleteSVG;
export const c3l_delete2 = c3l_delete2SVG;
// CHAT PAGE
export const c2_profile_ico = c2_profile_icoSVG;
export const c2_video_ico = c2_video_icoSVG;
export const c3_msg_attach = c3_msg_attachSVG;
export const c3_msg_send = c3_msg_sendSVG;
export const c3_top_favs2 = c3_top_favs2SVG;
export const c3_top_favs = c3_top_favsSVG;
export const c3_top_letter = c3_top_letterSVG;
export const c3_top_block = c3_top_blockSVG;
export const c3_top_attention = c3_top_attentionSVG;
export const c3_opt_gift = c3_opt_giftSVG;
export const c3_opt_meet = c3_opt_meetSVG;
export const profile_ico = profile_icoSVG;
export const profile_ico_blue_bold = profile_ico_blue_boldSVG;
export const question_mark_white = question_mark_whiteSVG;
export const heart_chained = heart_chainedSVG;
export const pic_white = pic_whiteSVG;
export const profile_ico_white = profile_ico_whiteSVG;
export const c3_top_options_btn = c3_top_options_btnSVG;
export const c2_search_btn = c2_search_btnSVG;
export const c2lh_your_likes = c2lh_your_likesSVG;
export const c2l_search_btn = c2l_search_btnSVG;
export const c2_remove = c2_removeSVG;
export const c2_removeFav = c2_removeFavSVG;
export const c2_favs_star_bd = c2_favs_star_bdSVG;
export const c2_photo_ico = c2_photo_icoSVG;
export const placeholder = placeholderGif;
export const deletedAvatarIcon = deletedAvatarIconSVG;
export const sweetheart_image = sweetheart_imageSVG;
export const new_year_empty = new_year_empty_imageSVG;
export const not_found_results_2 = not_found_results_2SVG;
export const like_violet = like_violetSVG;
export const liked_violet = liked_violetSVG;
export const gift_violet = gift_violetSVG;
export const video_credits_ico = video_credits_icoSVG;
export const video_credits_dark_ico = video_credits_dark_icoSVG;
export const c3_media_unpaid = c3_media_unpaidSVG;
export const c3_media_unpaid_blue = c3_media_unpaid_blueSVG;
export const c3_media_video_play = c3_media_video_playSVG;
export const c3_media_mob_arrow = c3_media_mob_arrowSVG;
export const arrow_left_purple = arrow_left_purpleSVG;

export const credit_clock = credit_clockSVG;
export const credits_offer_girl = credits_offer_girlPNG;
export const welcome_alert_bg = welcome_alert_bgSVG;
export const emailSubscription = emailSubscriptionSVG;

export const how_to_start_ico = how_to_start_icoSVG;
export const tut_arrow_1 = tut_arrow_1SVG;
export const tut_arrow_3 = tut_arrow_3SVG;
export const tut_arrow_3_mob = tut_arrow_3_mobSVG;
export const tut_arrow_4_1 = tut_arrow_4_1SVG;
export const tut_arrow_4_2 = tut_arrow_4_2SVG;

export const tut_not_girl_1 = tut_not_girl_1PNG;
export const tut_not_girl_2 = tut_not_girl_2PNG;
export const tut_not_girl_3 = tut_not_girl_3PNG;
export const tut_man_avatar = tut_man_avatarPNG;

export const tut_not_man_1 = tut_not_man_1JPG;
export const tut_not_man_2 = tut_not_man_2JPG;

export const tut_search_icon_active = tut_search_icon_activeSVG;
export const tut_likeher_ico = tut_likeher_icoSVG;
export const tut_video_ico = tut_video_icoSVG;
export const tut_chat_ico = tut_chat_icoSVG;
export const tut_letters_ico = tut_letters_icoSVG;
export const tut_like_heart_icon = tut_like_heart_iconSVG;
export const tut_star_yellow = tut_star_yellowSVG;

export const tut_step_1 = tut_step_1PNG;
export const tut_step_1_mob = tut_step_1_mobPNG;
export const tut_step_3 = tut_step_3PNG;
export const tut_step_3_mob = tut_step_3_mobPNG;
export const tut_step_4 = tut_step_4PNG;
export const tut_step_4_mob = tut_step_4_mobPNG;
export const tut_step_6_mob = tut_step_6_mobPNG;

export const tut_g_step_1 = tut_g_step_1PNG;
export const tut_g_step_3 = tut_g_step_3PNG;
export const tut_g_step_3_mob = tut_g_step_3_mobPNG;
export const tut_g_step_4 = tut_g_step_4PNG;
export const tut_g_step_4_mob = tut_g_step_4_mobPNG;
export const tut_g_step_6_mob = tut_g_step_6_mobPNG;

// New HowToStart
export const tut_icon_account = tut_icon_accountSVG;
export const tut_step_1_girl = tut_step_1_girlPNG;
export const tut_step_1_man = tut_step_1_manPNG;
export const tut_step_5_girl = tut_step_5_girlPNG;
export const tut_step_3_girl_1 = tut_step_3_girl_1PNG;
export const tut_step_3_man_1 = tut_step_3_man_1PNG;
export const tut_step_3_girl_2 = tut_step_3_girl_2PNG;
export const tut_step_3_man_2 = tut_step_3_man_2PNG;
export const tut_step_3_girl_3 = tut_step_3_girl_3PNG;
export const tut_step_3_man_3 = tut_step_3_man_3PNG;
export const tut_step_4_girl_primary = tut_step_4_girl_primary_photoPNG;
export const tut_step_4_man_primary = tut_step_4_man_primary_photoPNG;
export const tut_step_4_girl_secondary_1 = tut_step_4_girl_secondary_1PNG;
export const tut_step_4_man_secondary_1 = tut_step_4_man_secondary_1PNG;
export const tut_step_4_girl_secondary_2 = tut_step_4_girl_secondary_2PNG;
export const tut_step_4_man_secondary_2 = tut_step_4_man_secondary_2PNG;
export const tut_step_4_girl_secondary_3 = tut_step_4_girl_secondary_3PNG;
export const tut_step_4_man_secondary_3 = tut_step_4_man_secondary_3PNG;
export const tut_step_4_girl_small_1 = tut_step_4_girl_small_1PNG;
export const tut_step_4_man_small_1 = tut_step_4_man_small_1PNG;
export const tut_step_4_girl_small_2 = tut_step_4_girl_small_2PNG;
export const tut_step_4_man_small_2 = tut_step_4_man_small_2PNG;
export const tut_step_6_girl1 = tut_step_6_girl1JPG;
export const tut_step_6_man1 = tut_step_6_man1JPG;
export const tut_step_6_girl2 = tut_step_6_girl2JPG;
export const tut_step_6_man2 = tut_step_6_man2JPG;

export const info_icon = infoSVG;
export const danger_icon_red = dangerIconSVG;

//verification
export const verify_status_verification = verify_status_verificationSVG;
export const verify_status_verified = verify_status_verifiedSVG;
export const verify_status_upload = verify_status_uploadSVG;
export const verify_status_canceled = verify_status_canceledSVG;
export const imbra_download = imbra_downloadSVG;
export const imbra_upload = imbra_uploadSVG;
export const imbra_waiting = imbra_waitingSVG;
export const imbra_canceled = imbra_canceledSVG;
export const imbra_verified = imbra_verifiedSVG;
export const cloud_download_white = cloud_download_whiteSVG;
export const cloud_upload_white = cloud_upload_whiteSVG;
export const benefit_contact = benefit_contactSVG;
export const benefit_booking = benefit_bookingSVG;
export const benefit_question = benefit_questionSVG;
export const edit_white = edit_whiteSVG;
export const avatar_user_vip = avatar_user_vipSVG;
export const avatar_crown_vip = avatar_crown_vipSVG;

//love stories page
export const love_stories_banner_title = love_stories_banner_titleSVG;
export const photo_slider_arrow_left = photo_slider_arrow_leftSVG;
export const photo_slider_arrow_right = photo_slider_arrow_rightSVG;
export const photo_slider_arrow_left_white = photo_slider_arrow_left_whiteSVG;
export const photo_slider_arrow_right_white = photo_slider_arrow_right_whiteSVG;
export const video_slider_arrow_left = video_slider_arrow_leftSVG;
export const video_slider_arrow_right = video_slider_arrow_rightSVG;
export const ls_photo_slider_1 = ls_photo_slider_1JPG;
export const ls_photo_slider_2 = ls_photo_slider_2JPG;
export const ls_photo_slider_3 = ls_photo_slider_3JPG;
export const ls_preview_video_slider_1 = ls_preview_video_slider_1JPG;
export const ls_preview_video_slider_2 = ls_preview_video_slider_2JPG;
export const ls_video_slider_1 = ls_video_slider_1MP4;
export const ls_video_slider_2 = ls_video_slider_2MP4;
export const ls_photo_comment_1 = ls_photo_comment_1PNG;
export const ls_photo_comment_2 = ls_photo_comment_2PNG;
export const ls_quot_open = ls_quot_openSVG;
export const ls_quot_close = ls_quot_closeSVG;

// Virtual gifts - Flowers
export const gift_azure_flower = gift_azureFlowerPNG;
export const gift_pink_flower = gift_pinkFlowerPNG;
export const gift_yellow_flower = gift_yellowFlowerPNG;
export const gift_bouquet_roses = gift_bouquetRosesPNG;
export const gift_pink_roses = gift_pinkRosesPNG;
export const gift_yellow_bouquet = gift_yellowBouquetPNG;
export const gift_lotus = gift_lotusPNG;
export const gift_red_flower = gift_redFlowerPNG;
export const gift_red_roses = gift_redRosesPNG;
export const gift_water_lilly = gift_waterLillyPNG;
export const gift_calla_lily = gift_callaLilyPNG;
export const gift_red_bouquet = gift_redBouquetPNG;
export const gift_daffodil = gift_daffodilPNG;
export const gift_white_flower = gift_whiteFlowerPNG;
export const gift_tulips = gift_tulipsPNG;
export const gift_daisy = gift_daisyPNG;
export const gift_jasmine = gift_jasminePNG;
export const gift_sakura = gift_sakuraPNG;
export const gift_rose = gift_rosePNG;
export const gift_orchid = gift_orchidPNG;
export const gift_iris = gift_irisPNG;
export const gift_pink_bouquet = gift_pinkBouquetPNG;
export const gift_blue_bouquet = gift_blueBouquetPNG;

// Virtual gifts - Expensive brands
export const gift_bracelet = gift_braceletPNG;
export const gift_wedding_rings = gift_weddingRingsPNG;
export const gift_purple_ring = gift_purple_ringPNG;
export const gift_diamond_ring = gift_diamond_ringPNG;
export const gift_heart_pendant = gift_heart_pendantPNG;
export const gift_silver_ring = gift_silver_ringPNG;
export const gift_pair_heart_rings = gift_pair_heart_ringsPNG;
export const gift_diamond_heart = gift_diamond_heartPNG;
export const gift_diamond = gift_diamondPNG;
export const gift_pair_of_rings = gift_pair_of_ringsPNG;
export const gift_earrings = gift_earringsPNG;
export const gift_heart_ring = gift_heart_ringPNG;
export const gift_necklace = gift_necklacePNG;
export const gift_golden_ring = gift_golden_ringPNG;

// Virtual gifts - Food
export const gift_avocado = gift_avocadoPNG;
export const gift_cheesecake = gift_cheesecakePNG;
export const gift_coffee = gift_coffeePNG;
export const gift_paste = gift_pastePNG;
export const gift_cocktail = gift_cocktailPNG;
export const gift_icecream = gift_icecreamPNG;
export const gift_cake = gift_cakePNG;
export const gift_donut = gift_donutPNG;
export const gift_seafood = gift_seafoodPNG;
export const gift_champagne = gift_champagnePNG;
export const gift_burger = gift_burgerPNG;
export const gift_pizza = gift_pizzaPNG;

//Virtual gifts - Virtual symbols and icons
export const gift_award = gift_awardPNG;
export const gift_book = gift_bookPNG;
export const gift_shoes = gift_shoesPNG;
export const gift_phonograph = gift_phonographPNG;
export const gift_microphone = gift_microphonePNG;
export const gift_football = gift_footballPNG;
export const gift_tv = gift_tvPNG;
export const gift_camera = gift_cameraPNG;
export const gift_painting_and_brush = gift_painting_and_brushPNG;
export const gift_sport = gift_sportPNG;
export const gift_food_and_drink = gift_food_and_drinkPNG;

//Virtual gifts - Virtual travel
export const gift_balloon = gift_balloonPNG;
export const gift_passport = gift_passportPNG;
export const gift_beach_vacation = gift_beach_vacationPNG;
export const gift_suitcase_and_camera = gift_suitcase_and_cameraPNG;
export const gift_travel_kit = gift_travel_kitPNG;
export const gift_vacation = gift_vacationPNG;
export const gift_travel_expenses = gift_travel_expensesPNG;
export const gift_camping = gift_campingPNG;
export const gift_plane_and_passport = gift_plane_and_passportPNG;
export const gift_flight = gift_flightPNG;

//Virtual gifts - Virtual Music Tracks
export const gift_blues = gift_bluesPNG;
export const gift_jazz = gift_jazzPNG;
export const gift_rock = gift_rockPNG;
export const gift_disco = gift_discoPNG;
export const gift_rap = gift_rapPNG;

//Virtual gifts - Feelings
export const gift_amorous = gift_amorousPNG;
export const gift_bro = gift_broPNG;
export const gift_bottle_blue_water = gift_bottle_blue_waterPNG;
export const gift_crush = gift_crushPNG;
export const gift_goodmood = gift_goodmoodPNG;
export const gift_fiercely = gift_fiercelyPNG;
export const gift_excited = gift_excitedPNG;
export const gift_heart_in_bulb = gift_heart_in_bulbPNG;
export const gift_weepy = gift_weepyPNG;
export const gift_bottle = gift_bottlePNG;
export const gift_crown = gift_crownPNG;
export const gift_heart = gift_heartPNG;
export const gift_in_depression = gift_in_depressionPNG;

//Virtual gifts - Zodiac sign
export const gift_aquarius = gift_aquariusPNG;
export const gift_libra = gift_libraPNG;
export const gift_pisces = gift_piscesPNG;
export const gift_gemini = gift_geminiPNG;
export const gift_aries = gift_ariesPNG;
export const gift_virgo = gift_virgoPNG;
export const gift_capricorn = gift_capricornPNG;
export const gift_cancer = gift_cancerPNG;
export const gift_sagittarius = gift_sagittariusPNG;
export const gift_scorpio = gift_scorpioPNG;
export const gift_leo = gift_leoPNG;
export const gift_taurus = gift_taurusPNG;
