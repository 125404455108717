import Base from '../Base';
import {
    ITransactionSyncConfig,
    ITransactionSyncRegisterSettings,
    IUserActionSyncData,
} from './FrontSyncApi.interface';

export default class FrontSyncApi extends Base {
    async postTransactionSync(
        config: ITransactionSyncConfig,
        registerSettings: ITransactionSyncRegisterSettings,
    ) {
        const isRegister = window?.location?.search?.includes('registration=success');

        return super
            .post(`v3/front-sync/transaction`, { isRegister, config, registerSettings })
            .then((res) => res?.data);
    }

    async postNextTransactionSync() {
        return super.post(`v3/front-sync/transaction/next`).then((res) => res?.data);
    }

    async postCheckTransactionSync(delay: number) {
        return super.post(`v3/front-sync/transaction/check`, { delay }).then((res) => res?.data);
    }

    async postUserActionSync({ recipientExternalID, action }: IUserActionSyncData) {
        return super
            .post(`v3/front-sync/action`, { recipientExternalID, action })
            .then((res) => res?.data);
    }

    async postUserTmpActionSync(action: string) {
        return super.post(`v3/front-sync/action/tmp`, { action }).then((res) => res?.data);
    }

    async postCheckUserBirthday() {
        return super.post(`v3/front-sync/birthday`).then((res) => res?.data);
    }
}
