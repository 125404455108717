import React from 'react';

export default function IconOnlineNow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.83333 7.28571H16.1667C18.698 7.28571 20.75 9.39637 20.75 12C20.75 14.6037 18.698 16.7143 16.1667 16.7143H7.83333C5.30202 16.7143 3.25 14.6037 3.25 12C3.25 9.39637 5.30202 7.28571 7.83333 7.28571ZM2 12C2 8.68629 4.61168 6 7.83333 6H16.1667C19.3883 6 22 8.68629 22 12C22 15.3137 19.3883 18 16.1667 18H7.83333C4.61168 18 2 15.3137 2 12ZM16.1667 15.4286C18.0076 15.4286 19.5 13.8935 19.5 12C19.5 10.1065 18.0076 8.57143 16.1667 8.57143C14.3257 8.57143 12.8333 10.1065 12.8333 12C12.8333 13.8935 14.3257 15.4286 16.1667 15.4286Z"
            />
        </svg>
    );
}
