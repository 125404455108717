import { RefObject, useRef, useState } from 'react';
import useOutsideClickerChatMob from '../../dom/useOutsideClickerChatMob';

export default function useInitSmileMobBlock(writeMsgRef?: RefObject<HTMLDivElement | null>) {
    const [isOpenMobSmileBlock, setIsOpenMobSmileBlock] = useState<boolean>(false);
    const selectRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const btnStickerRef = useRef<HTMLDivElement | null>(null);

    useOutsideClickerChatMob(selectRef, setIsOpenMobSmileBlock, writeMsgRef);

    return {
        isOpenMobSmileBlock,
        setIsOpenMobSmileBlock,
        selectRef,
        buttonRef,
        btnStickerRef,
    };
}
