import React from 'react';

export default function IconGallery() {
    return (
        <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="#00317B"
            stroke="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3_27)">
                <mask id="mask0_3_27" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="18">
                    <path d="M17 0H0V18H17V0Z" />
                </mask>
                <g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.21164 0.900024C1.22454 0.900024 0.424683 1.7034 0.424683 2.68698V15.313C0.424683 16.3002 1.22806 17.1 2.21164 17.1H14.8377C15.8248 17.1 16.6247 16.2967 16.6247 15.313V2.68698C16.6247 1.69987 15.8213 0.900024 14.8377 0.900024H2.21164ZM1.42566 2.68698C1.42566 2.25509 1.77975 1.901 2.21164 1.901H14.8377C15.2696 1.901 15.6237 2.25509 15.6237 2.68698V10.23L12.5513 7.1606C12.3552 6.96465 12.0402 6.96464 11.8443 7.16062L7.2333 11.7715L4.71145 9.24971C4.51547 9.05372 4.20042 9.05372 4.00444 9.24971L1.42566 11.8285V2.68698ZM5.66781 3.18518C4.35416 3.18518 3.2821 4.25724 3.2821 5.57089C3.2821 6.88444 4.35039 7.9566 5.66781 7.9566C6.98525 7.9566 8.05354 6.88444 8.05354 5.57089C8.05354 4.25724 6.98148 3.18518 5.66781 3.18518ZM4.38098 5.57089C4.38098 4.86282 4.95642 4.28405 5.66781 4.28405C6.37922 4.28405 6.95465 4.86282 6.95465 5.57089C6.95465 6.27879 6.37572 6.85773 5.66781 6.85773C4.95992 6.85773 4.38098 6.27879 4.38098 5.57089ZM7.58845 12.8337L12.1994 8.22277L15.6237 11.6471V15.313C15.6237 15.7449 15.2696 16.099 14.8377 16.099H2.21164C1.77975 16.099 1.42566 15.7449 1.42566 15.313V13.2457L4.35958 10.3118L6.88144 12.8337C7.07742 13.0297 7.39247 13.0297 7.58845 12.8337Z"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3_27">
                    <rect width="17" height="18" />
                </clipPath>
            </defs>
        </svg>
    );
}
