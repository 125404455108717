import React from 'react';
import styles from './styles.module.scss';

import MenuHeader from './MenuHeader';
import MenuBody from './MenuBody';

export default function NewMenuMain() {
    return (
        <div className={`${styles.column_1} custom_column_1`}>
            <MenuHeader />
            <MenuBody />
        </div>
    );
}
