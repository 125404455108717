import { FilterActionTypes } from '../../constants/filter';
import { Dispatch } from 'redux';
import { ISetFilterByIdParams } from './index.interface';

export function setGirlFilter(menuName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: FilterActionTypes.SET_GIRL_FILTER,
            data: menuName,
        });
    };
}

export function setLettersFilter(menuName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: FilterActionTypes.SET_LETTERS_FILTER,
            data: menuName,
        });
    };
}

export function setFilterById(options: ISetFilterByIdParams) {
    return function (dispatch: Dispatch<{ type: string; data: ISetFilterByIdParams }>) {
        return dispatch({
            type: FilterActionTypes.SET_FILTER_BY_ID,
            data: options,
        });
    };
}

export function setFilterByUsername(username: string) {
    return function (dispatch: Dispatch<{ type: string; username: string }>) {
        return dispatch({
            type: FilterActionTypes.SET_FILTER_BY_USERNAME,
            username,
        });
    };
}

export function setOnlineFilter(menuName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: FilterActionTypes.SET_ONLINE_FILTER,
            data: menuName,
        });
    };
}

export function setDefaultFilterValue() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: FilterActionTypes.SET_DEFAULT_FILTER_VALUE });
    };
}
