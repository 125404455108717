import React from 'react';

export default function IconAboutBook() {
    return (
        <svg
            width="29"
            height="36"
            viewBox="0 0 29 36"
            fill="#446077"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2_6)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.55271 0.753906C4.13922 0.753906 2.78363 1.31541 1.78415 2.31489C0.784656 3.31438 0.223145 4.66999 0.223145 6.08347V30.5609C0.223145 31.9743 0.784713 33.33 1.78419 34.3295C2.78367 35.3289 4.13924 35.8904 5.55272 35.8904L27.5824 35.8904C28.2728 35.8904 28.8324 35.3308 28.8324 34.6404V26.4782V2.00391C28.8324 1.31355 28.2728 0.753906 27.5824 0.753906H5.55271ZM26.3324 3.25391H5.55271C4.80227 3.25391 4.08256 3.55202 3.55191 4.08266C3.02126 4.61331 2.72314 5.33303 2.72314 6.08347V26.0445C3.56457 25.5173 4.5439 25.2313 5.55272 25.2313H26.3324V3.25391ZM5.55272 27.7313C4.80227 27.7313 4.08257 28.0294 3.55193 28.5601C3.02125 29.0908 2.72314 29.8104 2.72314 30.5609C2.72314 31.1693 2.9191 31.7575 3.27605 32.2412C3.35937 32.3541 3.45146 32.4613 3.55187 32.5617C4.08253 33.0923 4.80229 33.3904 5.55272 33.3904H26.3324V27.7313H5.55272Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_2_6">
                    <rect width="29" height="36" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
