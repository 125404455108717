import React, { MouseEventHandler } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ImbraForm from './ImbraForm';

export default function ImbraFormModal({
    isActiveImbraFormModal,
    closeImbraFormModal,
}: {
    isActiveImbraFormModal: boolean;
    closeImbraFormModal: MouseEventHandler<HTMLDivElement>;
}) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isActiveImbraFormModal}
            onRequestClose={closeImbraFormModal}
            ariaHideApp={false}
        >
            <ImbraForm closeModal={closeImbraFormModal} />
        </Modal>
    );
}
