import React from 'react';
import UnsubscribeForm from '../../components/Mail-Unsubscribe/UnsubscribeForm';
import GirlsProfiles from '../../components/Mail-Unsubscribe/GirlsProfiles';
import GirlsProfilesSmall from '../../components/Mail-Unsubscribe/GirlsProfilesSmall';
import GirlsSlider from '../../components/Mail-Unsubscribe/GirlsSlider/GirlsSlider';

import useInitMailUnsubscribe from '../../hooks/mailUnsubscribe/useInitMailUnsubscribe';
import useInitGirlsSlider from '../../hooks/girlsSlider/useInitGirlsSlider';
import LoaderGif from '../../components/common/LoaderGif';
import { useSelector } from 'react-redux';
import UnsubscribeFormPayer from '../../components/Mail-Unsubscribe/UnsubscribeFormPayer';
import { RootState } from '../../index';

export default function MailUnsubscribePage() {
    const initMailUnsubscribe = useInitMailUnsubscribe();
    const initGirlsSliderMain = useInitGirlsSlider();
    const initGirlsSlider = useInitGirlsSlider();

    const userState = useSelector((state: RootState) => state.user);

    const isPayer = !!userState?.info?.payment_total;

    return (
        <div className="column-3 unsubscribe-page c3_fx">
            {initMailUnsubscribe?.topProfiles?.top3?.length ? (
                <div className="unsubscribe-page-wrap">
                    <div className="unsubscribe-container">
                        <h1 className="unsubscribe-page-title">Unsubscribe form</h1>

                        <GirlsProfiles
                            profiles={initMailUnsubscribe?.topProfiles?.topLike}
                            openChat={initGirlsSliderMain?.openChat}
                            openLetter={initGirlsSliderMain?.openLetter}
                            openGalleryModal={initGirlsSliderMain?.openGalleryModal}
                            sliderRef={initGirlsSliderMain?.sliderRef}
                            onNextSlide={initGirlsSliderMain?.onNextSlide}
                            onPrevSlide={initGirlsSliderMain?.onPrevSlide}
                        />

                        {isPayer && (
                            <>
                                <h2 className="unsubscribe-page-title">Unsubscribe</h2>
                                <p className="unsubscribe-page-text">
                                    After unsubscribing from the mailing list, you will no longer
                                    receive notifications about messages sent to you by your
                                    interlocutors. You will not learn about new users who have
                                    appeared on the site and will not be able to benefit from great
                                    promotions and discounts. Also, you will not be able to get VIP
                                    status, which will help you find the most interesting
                                    interlocutors. And most importantly, we will miss you a lot!
                                </p>
                            </>
                        )}

                        {isPayer && (
                            <GirlsProfilesSmall
                                profiles={initMailUnsubscribe?.topProfiles?.top4}
                                openChat={initGirlsSlider.openChat}
                                openGalleryModal={initGirlsSlider.openGalleryModal}
                            />
                        )}

                        {isPayer ? (
                            <UnsubscribeFormPayer
                                userStepData={initMailUnsubscribe.userStepData}
                                selectStepText={initMailUnsubscribe.selectStepText}
                                otherTextData={initMailUnsubscribe.otherTextData}
                                handleOtherTextForm={initMailUnsubscribe.handleOtherTextForm}
                                unsubscribeEmail={initMailUnsubscribe.unsubscribeEmail}
                                handleUnsubscribeEmail={initMailUnsubscribe.handleUnsubscribeEmail}
                                isUnsubscribeEmailError={
                                    initMailUnsubscribe.isUnsubscribeEmailError
                                }
                                activeStep={initMailUnsubscribe.activeStep}
                                onSubmitUnsubscribeForm={
                                    initMailUnsubscribe.onSubmitUnsubscribeForm
                                }
                                isLoadingSubmit={initMailUnsubscribe.isLoadingSubmit}
                            />
                        ) : (
                            <UnsubscribeForm
                                unsubscribeEmail={initMailUnsubscribe.unsubscribeEmail}
                                handleUnsubscribeEmail={initMailUnsubscribe.handleUnsubscribeEmail}
                                isUnsubscribeEmailError={
                                    initMailUnsubscribe.isUnsubscribeEmailError
                                }
                                onSubmitUnsubscribeForm={
                                    initMailUnsubscribe.onSubmitUnsubscribeForm
                                }
                                isLoadingSubmit={initMailUnsubscribe.isLoadingSubmit}
                            />
                        )}
                    </div>

                    <GirlsSlider
                        profiles={initMailUnsubscribe?.topProfiles?.topLike}
                        sliderRef={initGirlsSlider.sliderRef}
                        openChat={initGirlsSlider.openChat}
                        openGalleryModal={initGirlsSlider.openGalleryModal}
                        onPrevSlide={initGirlsSlider.onPrevSlide}
                        onNextSlide={initGirlsSlider.onNextSlide}
                    />
                </div>
            ) : (
                <LoaderGif />
            )}
        </div>
    );
}
