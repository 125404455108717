import React from 'react';
import { ICreditCardsListProps } from './index.interface';
import credit_card_image from '../../../../img/manage-credit-card-modal/credit-card-img.svg';
import IconCross from '../../../../components/Icons/IconCross';
import IconCheckedCircle from '../../../../components/Icons/IconCheckedCircle';
import IconTrash from '../../../../components/Icons/IconTrash';
import IconCirclePlus from '../../../../components/Icons/IconCirclePlus';

export default function CreditCardsList({
    fetchTokenList,
    openRemoveCreditCardAlert,
    createToken,
    selectDefaultCard,
    closeCreditCardListModal,
}: ICreditCardsListProps) {
    return (
        <div className="popup_your_credit_cards" data-testid="user-cards-modal">
            <div className="popup_your_credit_cards_wrap">
                <div className="popup_your_credit_cards_title" data-testid="user-cards-title">
                    Your credit cards
                </div>
                {
                    <div className="popup_your_credit_cards_list">
                        {fetchTokenList?.length > 0 &&
                            fetchTokenList
                                ?.filter((item) => item?.status === 1)
                                ?.map((item, key) => (
                                    <div
                                        className={
                                            +item?.default_card === 1
                                                ? 'popup_your_credit_cards_current_card'
                                                : 'popup_your_credit_cards_list_item'
                                        }
                                        key={key}
                                        data-testid="card-item"
                                    >
                                        <div
                                            className={
                                                +item?.default_card === 1
                                                    ? 'popup_your_credit_cards_current_card_left'
                                                    : 'popup_your_credit_cards_list_item_left'
                                            }
                                        >
                                            <div
                                                className={
                                                    +item?.default_card === 1
                                                        ? 'popup_your_credit_cards_current_card_input'
                                                        : 'popup_your_credit_cards_list_item_input'
                                                }
                                                onClick={() =>
                                                    selectDefaultCard(
                                                        item,
                                                        +(item?.default_card === 1),
                                                    )
                                                }
                                                data-testid="default-card-btn"
                                            />
                                            <img
                                                src={credit_card_image}
                                                alt=""
                                                className="popup_your_credit_cards_current_card_img"
                                                data-testid="card-logo"
                                            />
                                            <span
                                                className="popup_your_credit_cards_current_card_text"
                                                data-testid="card-number"
                                            >
                                                {item?.number}
                                            </span>
                                        </div>
                                        {+item?.default_card === 1 && (
                                            <div
                                                className="popup_your_credit_cards_current_card_info_text"
                                                data-testid="current-card-text"
                                            >
                                                <IconCheckedCircle />
                                                <span>Current card</span>
                                            </div>
                                        )}
                                        <div
                                            className="popup_your_credit_cards_list_item_remove"
                                            onClick={() => openRemoveCreditCardAlert(item)}
                                            data-testid="delete-card-btn"
                                        >
                                            <IconTrash />
                                        </div>
                                    </div>
                                ))}
                    </div>
                }
                <div className="popup_your_credit_cards_buttons">
                    <div
                        className="popup_your_credit_cards_add_card_btn"
                        onClick={createToken}
                        data-testid="add-card-btn"
                    >
                        <IconCirclePlus />
                        <span>Add new card</span>
                    </div>
                    <div
                        className="popup_your_credit_cards_save_changes_btn"
                        onClick={closeCreditCardListModal}
                        data-testid="ok-btn"
                    >
                        <IconCheckedCircle />
                        <span>Ok</span>
                    </div>
                </div>
            </div>
            <div
                className="close_one_popup"
                onClick={closeCreditCardListModal}
                data-testid="close-btn"
            >
                <IconCross />
            </div>
        </div>
    );
}
