import { FilterActionTypes } from '../../constants/filter';
import { FilterActions, IFilterState } from './index.interface';

const initState = {
    girlFilter: 'all',
    lettersFilter: 'all',
    girlId: '',
    onlineFilter: '',
    username: '',
};

export default function (state: IFilterState = initState, action: FilterActions): IFilterState {
    switch (action.type) {
        case FilterActionTypes.SET_GIRL_FILTER:
            return {
                ...state,
                ...{
                    girlFilter: action.data,
                    girlId: '',
                },
            };

        case FilterActionTypes.SET_LETTERS_FILTER:
            return {
                ...state,
                ...{
                    lettersFilter: action.data,
                },
            };

        case FilterActionTypes.SET_FILTER_BY_ID:
            return {
                ...state,
                ...{
                    girlFilter: action.data.filterName,
                    girlId: action.data.id,
                },
            };

        case FilterActionTypes.SET_FILTER_BY_USERNAME:
            return {
                ...state,
                ...{
                    girlFilter: action?.username ? 'username' : 'all',
                    username: action?.username,
                },
            };

        case FilterActionTypes.SET_ONLINE_FILTER:
            return {
                ...state,
                ...{
                    onlineFilter: action.data,
                },
            };

        case FilterActionTypes.SET_DEFAULT_FILTER_VALUE:
            return initState;

        default:
            return state;
    }
}
