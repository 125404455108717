import React, { useRef, useState } from 'react';
import styles from '../styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatAction from '../../../../store/actions/currentChat';
import * as ERROR from '../../../../constants/error';
import * as ALERT from '../../../../constants/alert';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import AttachModal from '../../../common/AttachModal';
import Modal from 'react-modal';
import * as filesAction from '../../../../store/actions/files';
import moment from 'moment';
import ChatsApi from '../../../../services/api/ChatsApi';
import * as alertAction from '../../../../store/actions/alert';
import { insertedMessageBeforeSync } from '../../../../services/init';
import * as chatsAction from '../../../../store/actions/chats';
import { convertPrice, switchChatList } from '../../../../services/methods';
import * as notifyAction from '../../../../store/actions/notify';
import * as userAction from '../../../../store/actions/user';
import useHandleChatForm from '../../../../hooks/chat/useHandleChatForm';
import TextareaAutosize from 'react-textarea-autosize';
import * as STICKERS from '../../../../constants/stickers';
import SITE from '../../../../config/site';
import * as PRICES from '../../../../constants/prices';
import useSendSticker from '../../../../hooks/emoji/sticker/useSendSticker';
import useInitStickerMobBlock from '../../../../hooks/chat/helpers/useInitStickerMobBlock';
import * as LENGTH from '../../../../constants/length';
import { useEffect } from 'react';
import useScrollChatMessageList from '../../../../hooks/chat/helpers/useScrollChatMessageList';
import { RED_USER_LOG_TYPES } from '../../../../constants/user';
import useHandleRedUser from '../../../../hooks/user/useHandleRedUser';
import { IChatMobileFormProps } from './index.interface';
import { RootState } from '../../../../index';
import { IStickerItem } from '../../Chat/BodyChat/MessageForm/Emoji/index.interface';

export default function ChatMobileForm({ writeMsgRef }: IChatMobileFormProps) {
    const inputTextareaRef = useRef<HTMLTextAreaElement | null>(null);

    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);
    const chatsState = useSelector((state: RootState) => state.chats);
    const menuState = useSelector((state: RootState) => state.menu);

    const [modalIsAttachOpen, setModalIsAttachOpen] = useState(false);

    const dispatch = useDispatch();
    const handleChatForm = useHandleChatForm(writeMsgRef, inputTextareaRef);
    const sendSticker = useSendSticker();
    const initStickerMobBlock = useInitStickerMobBlock();
    const scrollChatMessageList = useScrollChatMessageList();
    const handleRedUser = useHandleRedUser();

    const onAttach = () => {
        setModalIsAttachOpen(true);
        dispatch(filesAction.getAllImages());
        dispatch(filesAction.getAllVideos());
        dispatch(filesAction.getAllAudios());
    };
    const closeAttachModal = () => setModalIsAttachOpen(false);
    const sendAttachFile = async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        type: string,
    ) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.id) return;

        const checkedList =
            event?.currentTarget?.parentNode?.parentNode?.querySelectorAll('input:checked');

        if (checkedList?.length) {
            closeAttachModal();

            for (const checkedItem of checkedList) {
                const hashId = moment().unix();
                const element = checkedItem as HTMLElement;

                await new ChatsApi()
                    .postMessage(
                        userState?.info?.external_id,
                        currentChatState?.info?.id,
                        element?.dataset?.link || '',
                        type,
                        element?.dataset?.filename || '',
                    )
                    .then((res) => {
                        if (res?.status) {
                            if (!userState?.info?.external_id) return;
                            if (!currentChatState?.info?.chat_uid) return;
                            if (!res?.response?.message_object) {
                                dispatch(
                                    alertAction.setMessage({
                                        message: res?.message || ERROR.ERROR_REQUEST,
                                    }),
                                );
                                dispatch(currentChatAction.deleteMessageInHistory(hashId));
                                return;
                            }
                            dispatch(
                                currentChatAction.addMessageToHistory(
                                    insertedMessageBeforeSync(
                                        hashId,
                                        element?.dataset?.link || '',
                                        userState?.info?.external_id,
                                        type,
                                    ),
                                ),
                            );

                            dispatch(
                                currentChatAction.updateMessageInHistory(
                                    hashId,
                                    res?.response?.message_object,
                                ),
                            );
                            dispatch(
                                chatsAction.getChatListByUserId(
                                    userState?.info?.external_id,
                                    chatsState?.onlineStatus,
                                    chatsState?.searchInput,
                                    switchChatList(menuState?.sub),
                                ),
                            );
                            dispatch(
                                notifyAction.deleteNotify(
                                    userState?.info?.external_id,
                                    currentChatState?.info?.chat_uid,
                                ),
                            );
                            dispatch(userAction.setBalance());
                            handleRedUser.submitLog(
                                RED_USER_LOG_TYPES.MESSAGE_FROM,
                                JSON.stringify({
                                    womanExternalID: currentChatState?.info?.id,
                                    action: type,
                                    content: element?.dataset?.link || '',
                                }),
                            );
                        } else if (res?.block) {
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.NOT_EXIST_OR_BLOCK,
                                }),
                            );
                        } else {
                            dispatch(
                                alertAction.setMessage({
                                    message: res?.message || ERROR.ERROR_REQUEST,
                                    title: res?.title,
                                    isPaymentBtn: res?.activePayment,
                                }),
                            );
                            dispatch(currentChatAction.deleteMessageInHistory(hashId));
                        }
                    });
            }
        } else {
            dispatch(alertAction.setMessage({ message: 'File not selected' }));
        }
    };
    const onStickerWrapActive = () =>
        initStickerMobBlock.setIsOpenMobStickerBlock(!initStickerMobBlock.isOpenMobStickerBlock);

    useEffect(() => {
        if (initStickerMobBlock.isOpenMobStickerBlock) {
            // const mobChatBody = document.querySelector('#mobile-chat-body');
            // const mobile_chat_footer = document.querySelector('#mobile-chat-footer');
            // mobChatBody.setAttribute('style', 'height: calc(' + window.innerHeight + 'px - 50px - ' + mobile_chat_footer.offsetHeight + 'px);max-height: calc(' + window.innerHeight + 'px - 50px - ' + mobile_chat_footer.offsetHeight + 'px);');
            scrollChatMessageList.onScrollDown();
        } else {
            // const mobChatBody = document.querySelector('#mobile-chat-body');
            // const mobile_chat_footer = document.querySelector('#mobile-chat-footer');
            // mobChatBody.setAttribute('style', 'height: calc(' + window.innerHeight + 'px - 50px - ' + mobile_chat_footer.offsetHeight + 'px);max-height: calc(' + window.innerHeight + 'px - 50px - ' + mobile_chat_footer.offsetHeight + 'px);');
            scrollChatMessageList.onScrollDown();
        }
    }, [initStickerMobBlock.isOpenMobStickerBlock]);

    const onSelectSticker = (stickerName: string) => {
        if (!currentChatState?.info?.id) return;
        initStickerMobBlock.setIsOpenMobStickerBlock(false);
        sendSticker(stickerName, currentChatState?.info?.id, currentChatState?.info?.chat_uid);
    };

    const stickerListMap = (stickerArray: IStickerItem[]) => {
        return (
            !!stickerArray?.length &&
            stickerArray?.map((sticker) => (
                <div
                    className="c3_stickers_list_item"
                    onClick={() => onSelectSticker(sticker?.name)}
                    key={sticker?.image}
                >
                    <img src={sticker?.image} alt="" />
                </div>
            ))
        );
    };

    return (
        <div
            className={`${styles.mobile_chat_footer} ${handleChatForm?.inputData ? styles.selected : ''}`}
        >
            <form className={styles.mobile_chat_message}>
                <button
                    type="button"
                    className={styles.mobile_chat_message_btn_attachment}
                    onClick={onAttach}
                />
                <div className={styles.mobile_user_message_wrap}>
                    <TextareaAutosize
                        className={styles.mobile_user_message}
                        id="mobile_user_message"
                        placeholder="Type in to chat..."
                        value={handleChatForm.inputData}
                        maxLength={LENGTH.MESSAGE_MAX_LENGTH}
                        onChange={handleChatForm.handleInput}
                        onKeyDown={handleChatForm.onSubmitMessageEnter}
                        onFocus={handleChatForm.onFocusInput}
                        onBlur={handleChatForm.onBlurInput}
                        ref={inputTextareaRef}
                    />
                    <button
                        type="button"
                        className={styles.mobile_user_message_btn_sticker}
                        onClick={onStickerWrapActive}
                    />
                </div>

                <button
                    type="button"
                    id="mobile_form_send_btn"
                    className={styles.mobile_chat_message_btn_submit}
                    onClick={handleChatForm.onSubmitMessage}
                />
            </form>
            <p className={styles.mobile_user_message_counter}>
                {handleChatForm?.inputData?.trim()?.length}/{LENGTH.MESSAGE_MAX_LENGTH} symbols
            </p>

            {initStickerMobBlock.isOpenMobStickerBlock && (
                <div
                    ref={initStickerMobBlock.selectStickerRef}
                    className={`c3_touch_stickers_wrap mobile active ${styles.mobile_c3_touch_stickers_wrap}`}
                >
                    {STICKERS.ARRAY_ALL_STICKER.map((item, key) => (
                        <div className="c3_touch_stickers_row" key={key}>
                            <div className="c3_touch_stickers_head">
                                <div className="c3_touch_stickers_head_title">{item?.title}</div>
                                {SITE.ID !== 4 && (
                                    <>
                                        <div className="c3_touch_stickers_head_price">
                                            The cost of sending one sticker is{' '}
                                            {convertPrice(
                                                +(userState.prices?.[PRICES.SENT_STICKER] || 0),
                                            )}{' '}
                                            credits
                                        </div>
                                        {key === 0 && (
                                            <div className="c3_touch_stickers_head_price mob">
                                                Оne sticker costs{' '}
                                                {convertPrice(
                                                    +(userState.prices?.[PRICES.SENT_STICKER] || 0),
                                                )}{' '}
                                                credits
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="c3_touch_stickers_list_wrap mobile">
                                <div className="c3_touch_stickers_list mobile">
                                    {stickerListMap(item?.stickerArray)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <Modal
                style={modalStyleProps({ isAttachment: true })}
                isOpen={modalIsAttachOpen}
                onRequestClose={closeAttachModal}
                ariaHideApp={false}
            >
                <AttachModal
                    closeModal={closeAttachModal}
                    sendImg={(event) => sendAttachFile(event, 'SENT_IMAGE')}
                    sendVideo={(event) => sendAttachFile(event, 'SENT_VIDEO')}
                    sendAudio={(event) => sendAttachFile(event, 'SENT_AUDIO')}
                />
            </Modal>
        </div>
    );
}
