import React from 'react';

export default function IconDeleteProfile() {
    return (
        <svg
            width="32"
            height="26"
            viewBox="0 0 32 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.4545 25V22.3333C21.4545 20.9188 20.8799 19.5623 19.8569 18.5621C18.834 17.5619 17.4466 17 16 17H6.45455C5.00791 17 3.62053 17.5619 2.5976 18.5621C1.57467 19.5623 1 20.9188 1 22.3333V25M24.1818 7.66667L31 14.3333M31 7.66667L24.1818 14.3333M16.6818 6.33333C16.6818 9.27885 14.2397 11.6667 11.2273 11.6667C8.21481 11.6667 5.77273 9.27885 5.77273 6.33333C5.77273 3.38781 8.21481 1 11.2273 1C14.2397 1 16.6818 3.38781 16.6818 6.33333Z"
                stroke="#446077"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
