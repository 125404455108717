import { AlertActionTypes } from '../../constants/alert';
import { Dispatch } from 'redux';
import {
    ISetAlertMessageActionParams,
    ISetAlertMessageAction,
    ISetCustomAlertTypeParams,
    ISetCustomAlertTypeAction,
} from './index.interface';

export function setMessage({
    message,
    title,
    isPaymentBtn,
    successCallback,
    btnName,
}: ISetAlertMessageActionParams) {
    return function (dispatch: Dispatch<ISetAlertMessageAction>) {
        return dispatch({
            type: AlertActionTypes.SET_MESSAGE,
            message,
            title,
            isPaymentBtn,
            successCallback,
            btnName,
        });
    };
}

export function setCustomAlertType({
    alertType,
    message = '',
    successCallback = () => {},
}: ISetCustomAlertTypeParams) {
    return function (dispatch: Dispatch<ISetCustomAlertTypeAction>) {
        return dispatch({
            type: AlertActionTypes.SET_CUSTOM_MESSAGE,
            alertType,
            message,
            successCallback,
        });
    };
}

export function closeAlertModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: AlertActionTypes.INITIAL_STATE,
        });
    };
}
