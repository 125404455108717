import { useDispatch } from 'react-redux';
import SocialApi from '../../services/api/v3/SocialApi';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as UTM from '../../constants/utm';
import * as loaderAction from '../../store/actions/loader';
import SITE from '../../config/site';
import LoginApi from '../../services/api/LoginApi';
import { getUrlParameter } from '../../utils/url-utils';
import useAddTail from '../utm/useAddTail';
import { isMobile } from 'react-device-detect';
import { redirectToMobileVersion } from '../../services/methods/mobile';
import { sendErrToSentry } from '../../services/sentry';
import useTransition from '../transition/useTransition';

export default function useSocialGoogle(
    redirect = '/like',
    mobileModel: string,
    mobileVendor: string,
) {
    const dispatch = useDispatch();
    const addTail = useAddTail();
    const transition = useTransition();

    const postResponseGoogle = async (userData: { name: string; email: string; sub: number }) => {
        dispatch(loaderAction.setActiveGifLoader(true));
        const utmNetw = getUrlParameter(UTM.UTM_NETW_KEY);
        const utmAff = getUrlParameter('utm_aff');
        const isSeoReferrer =
            !!document.referrer?.length && !document.referrer?.includes(window?.location?.hostname);

        if (!window?.location?.search?.includes('utm_unik')) {
            const resCheckIP = await new LoginApi().checkIp();

            if (resCheckIP?.status) {
                if (Number(resCheckIP?.count) === 0) addTail('utm_unik', 'true');
                else addTail('utm_unik', 'false');
            } else {
                return dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
            }
        }

        if (!utmNetw && !utmAff && isSeoReferrer) addTail(UTM.UTM_NETW_KEY, UTM.UTM_NETW_VALUE);

        const frontReferrer = isSeoReferrer ? document.referrer : '';
        // @ts-expect-error-next-line deviceMemory
        const deviceMemory = navigator?.deviceMemory ?? 0;
        const deviceData = {
            memory: deviceMemory,
            height: window.screen.height,
            width: window.screen.width,
            mobileModel,
            mobileVendor,
            userAgent: window?.navigator?.userAgent,
        };

        const googleData = {
            name: userData?.name,
            email: userData?.email,
            userID: userData?.sub,
            tail: window?.location?.search,
            site_id: SITE.ID,
            month: 1,
            day: 1,
            year: new Date().getFullYear() - 45,
            deviceData,
            ...(frontReferrer ? { frontReferrer } : {}),
        };

        try {
            new SocialApi()
                .postGoogleAuth(googleData)
                .then((res) => {
                    if (res?.status && res?.token) {
                        localStorage.setItem('fingerprintHash', res?.fingerprint);
                        localStorage.setItem('token', JSON.stringify(res?.token));

                        if (res?.gaRegisterStatus) {
                            if (!window?.location?.search?.includes('utm_real_age')) {
                                addTail('utm_real_age', '45');
                            }
                            addTail('registration', 'success');

                            if (res?.forMobile && res?.accessKey?.length && isMobile) {
                                return redirectToMobileVersion(res?.accessKey, redirect);
                            }
                            window.location.href = `${redirect}${window.location.search}`;
                        } else {
                            if (res?.forMobile && res?.accessKey?.length && isMobile) {
                                return redirectToMobileVersion(res?.accessKey, '/chat');
                            }
                            window.location.href = `/chat${window.location.search}`;
                        }
                    } else if (res?.forbiddenInfo?.redirect) {
                        return transition.transitionWithOnlyHistoryPush(
                            `/forbidden?country_name=${res?.forbiddenInfo?.countryName}`,
                        );
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error as Error);
            dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
            dispatch(loaderAction.setActiveGifLoader(false));
        }
    };

    return {
        postResponseGoogle,
    };
}
