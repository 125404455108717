import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import SITE from '../../../../../../config/site';
import Logotypes from '../../../../../../img/logotypes';
import { RootState } from '../../../../../../index';
import { isHolidayActive } from '../../../../../../constants/holiday';
import tut_step_1_girl from '../../../../../../img/tutorial/tut_step_1_girl.png';
import tut_step_1_man from '../../../../../../img/tutorial/tut_step_1_man.png';
import IconProfile from '../../../../../Icons/IconProfile';
import IconCopyright from '../../../../../Icons/IconCopyright';
import IconChat from '../../../../../Icons/IconChat';
import IconUnread from '../../../../../Icons/IconUnread';
import IconFAQQuestionBackArrow from '../../../../../Icons/IconFAQQuestionBackArrow';

export default function Step1() {
    const userState = useSelector((state: RootState) => state.user);
    const activeHoliday = isHolidayActive();

    const LogoSize = () => {
        switch (SITE?.ID) {
            case 1:
                return '';
            case 2:
                return '';
            case 5:
                return '';
            default:
                return 'step_profile_logo_large';
        }
    };

    return (
        <>
            <div
                className={`${styles.tutorial_step_illustration} ${styles.step_profile} ${styles.desktop}`}
            >
                <div className={styles.step_profile_menu}>
                    <div className={styles.step_profile_logo_wrap}>
                        {activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}.svg`] ? (
                            <img
                                className={`${styles.step_profile_logo} ${styles[`${LogoSize()}`]}`}
                                src={Logotypes.images[`logo_${activeHoliday?.name}.svg`]}
                                width="78"
                                height="37"
                                alt=""
                            />
                        ) : (
                            <img
                                className={`${styles.step_profile_logo} ${styles[`${LogoSize()}`]}`}
                                src={Logotypes.images['logo.svg']}
                                width="78"
                                height="37"
                                alt=""
                            />
                        )}
                    </div>
                    <div className={styles.step_profile_card}>
                        <span className={styles.step_profile_avatar}>
                            <IconProfile />
                        </span>
                        <div className={styles.step_profile_btns}>
                            <div className={styles.step_btn_profile}>
                                <IconProfile />
                                <span>My profile</span>
                            </div>
                            <div className={styles.step_btn_credits}>
                                <IconCopyright />
                                <span>Add credits</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step_menu_btn_credits}>
                        <IconCopyright />
                        <span>Add credits</span>
                    </div>
                    <ul className={styles.step_menu}>
                        <li className={styles.step_menu_item}>
                            <IconChat />
                            <span>All chats</span>
                        </li>
                        <li className={styles.step_menu_item}>
                            <IconUnread />
                            <span>Unread</span>
                        </li>
                    </ul>
                    <div></div>
                </div>
                <div className={styles.step_profile_body}>
                    <div className={styles.step_profile_girls_slider}>
                        <div className={styles.girls_slider_text_wrap}>
                            <p className={styles.girls_slider_title}>You may like</p>
                            <p className={styles.girls_slider_text}>
                                You may be interested in these members
                            </p>
                        </div>
                        <ul className={styles.girls_slider_list}>
                            <li className={styles.girls_slider_list_item}>
                                <span className={styles.girls_slider_status}>Online</span>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_1_girl}
                                        alt="girl photo"
                                        className={styles.girls_slider_img}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_1_man}
                                        alt="man photo"
                                        className={styles.girls_slider_img}
                                    />
                                )}
                            </li>
                        </ul>
                        <div className={styles.girls_slider_btn}>
                            <IconFAQQuestionBackArrow />
                        </div>
                    </div>
                    <div className={styles.step_profile_box} />
                </div>
            </div>
            <div
                className={`${styles.tutorial_step_illustration} ${styles.step_profile} ${styles.mobile}`}
            >
                <div className={styles.step_profile_card}>
                    <span className={styles.step_profile_avatar}>
                        <IconProfile />
                    </span>
                    <div className={styles.step_profile_btns}>
                        <div className={styles.step_btn_profile}>
                            <IconProfile />
                            <span>My profile</span>
                        </div>
                        <div className={styles.step_btn_credits}>
                            <IconCopyright />
                            <span>Add credits</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.tutorial_body}>
                <h2 className={styles.tutorial_step_title}>Complete your profile</h2>
                <p className={styles.tutorial_step_text}>
                    Let other users get to know you better and make your conversation more pleasant
                    and productive. Add your profile photo, information about yourself and a person
                    you would like to find on the site
                </p>
            </div>
        </>
    );
}
