import React, { useState } from 'react';
import styles from '../styles.module.scss';
import { useDispatch } from 'react-redux';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import AttachModal from '../../../common/AttachModal';
import Modal from 'react-modal';
import AttachDataToLetter from '../../../common/AttachDataToLetter';
import useHandleLettersForm from '../../../../hooks/letters/useHandleLettersForm';
import * as filesAction from '../../../../store/actions/files';
import TextareaAutosize from 'react-textarea-autosize';
import * as LENGTH from '../../../../constants/length';
import SlickContainer from '../../../common/SlickContainer';

export default function LettersChatMobileForm() {
    const [attachModalIsOpen, setAttachModalIsOpen] = useState(false);

    const dispatch = useDispatch();

    const openAttachModal = () => {
        setAttachModalIsOpen(true);
        dispatch(filesAction.getAllImages());
        dispatch(filesAction.getAllVideos());
        dispatch(filesAction.getAllAudios());
    };

    const closeAttachModal = () => setAttachModalIsOpen(false);

    const handleLettersForm = useHandleLettersForm({ closeAttachModal });

    const onSubmit = () => !handleLettersForm?.disabledButton && handleLettersForm.submitLetter();

    return (
        <div
            className={`${styles.mobile_chat_footer} ${handleLettersForm?.inputData ? styles.selected : ''}`}
        >
            <form className={styles.mobile_chat_message}>
                <button
                    type="button"
                    className={styles.mobile_chat_message_btn_attachment}
                    onClick={openAttachModal}
                />
                <TextareaAutosize
                    className={styles.mobile_user_message}
                    placeholder="Write a letter..."
                    // maxRows={8}
                    value={handleLettersForm?.inputData}
                    onChange={handleLettersForm.handleChangeInput}
                    onFocus={handleLettersForm.onFocusInput}
                    onBlur={handleLettersForm.onBlurInput}
                    maxLength={LENGTH.LETTER_MAX_LENGTH}
                />
                <button
                    type="button"
                    className={styles.mobile_chat_message_btn_submit}
                    onClick={onSubmit}
                />
            </form>
            <p className={styles.mobile_user_message_counter}>
                {handleLettersForm?.inputData?.length}/{LENGTH.LETTER_MAX_LENGTH} symbols
            </p>

            {!!handleLettersForm?.attachData?.length && (
                <div
                    className={`c3_write_msg_textareas_files ${styles.mobile_c3_write_msg_textareas_files}`}
                >
                    <div className={styles.letters_attach_files_list}>
                        <SlickContainer attachData={handleLettersForm?.attachData}>
                            {handleLettersForm?.attachData.map((item, key) => (
                                <AttachDataToLetter
                                    key={key}
                                    index={key}
                                    item={item}
                                    deleteAttach={handleLettersForm.deleteAttach}
                                />
                            ))}
                        </SlickContainer>
                    </div>
                </div>
            )}

            <Modal
                style={modalStyleProps({ isAttachment: true })}
                isOpen={attachModalIsOpen}
                onRequestClose={closeAttachModal}
                ariaHideApp={false}
            >
                <AttachModal
                    closeModal={closeAttachModal}
                    sendImg={handleLettersForm.sendImg}
                    sendVideo={handleLettersForm.sendVideo}
                    sendAudio={handleLettersForm.sendAudio}
                />
            </Modal>
        </div>
    );
}
