import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import AutoTopUpSuccess from './AutoTopUpSuccess';
import { useDispatch, useSelector } from 'react-redux';
import * as purchaseActions from '../../../../store/actions/purchase';
import { RootState } from '../../../../index';

export default function AutoTopUpSuccessModal() {
    const purchaseState = useSelector((state: RootState) => state.purchase);
    const dispatch = useDispatch();

    const onClose = () => dispatch(purchaseActions.setSuccessSubscribeModal(false));

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={purchaseState?.isOpenSuccessSubscribeModal}
            onRequestClose={onClose}
            ariaHideApp={false}
        >
            {!!purchaseState?.isOpenSuccessSubscribeModal && (
                <AutoTopUpSuccess
                    closeModal={onClose}
                    activeSubscribeData={purchaseState?.activeSubscribeData}
                />
            )}
        </Modal>
    );
}
