import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import UserBirthday from './UserBirthday';
import { IUserBirthdayModalProps } from './index.interface';

export default function UserBirthdayModal({
    isUserBirthdayModal,
    closeUserBirthdayModal,
    birthdayBonusCredits,
}: IUserBirthdayModalProps) {
    if (!birthdayBonusCredits) return null;
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isUserBirthdayModal}
            onRequestClose={closeUserBirthdayModal}
            ariaHideApp={false}
        >
            {!!isUserBirthdayModal && (
                <UserBirthday
                    closeModal={closeUserBirthdayModal}
                    birthdayBonusCredits={birthdayBonusCredits}
                />
            )}
        </Modal>
    );
}
