import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Styles from '../../components/letters/LettersChat/MessageForm/MessageForm.module.css';

import { checkUrl, hideStringStar } from '../../services/methods';
import MailboxApi from '../../services/api/MailboxApi';
import * as REGEXP from '../../constants/regExp';
import * as alertAction from '../../store/actions/alert';
import * as loaderAction from '../../store/actions/loader';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import * as LENGTH from '../../constants/length';
import * as mailboxAction from '../../store/actions/mailbox';
import * as TIMER from '../../constants/timer';
import useInitSmileMobBlock from '../chat/helpers/useInitSmileMobBlock';
import * as userAction from '../../store/actions/user';
import SITE from '../../config/site';
import * as MIRROR from '../../constants/mirror';
import { scrollSmoothDownInLetterChatV2 } from '../../services/dom';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useHandleRedUser from '../user/useHandleRedUser';
import { RootState } from '../../index';
import { PickerProps } from 'emoji-mart/dist-es/utils/shared-props';
import { IMailAttachment } from '../../services/api/MailboxApi.interface';
import { IFile } from '../../store/reducers/files/index.interface';

interface IUseHandleLettersForm {
    setOpenForm?: (value: boolean) => void;
    closeAttachModal: () => void;
    attachLetter?: IMailAttachment[] | IFile[];
    checkedRecipientsID?: number[];
    fromComposeModal?: boolean;
    openLetterWasSentModal?: () => void;
}

export default function useHandleLettersForm({
    setOpenForm,
    closeAttachModal,
    attachLetter = [],
    checkedRecipientsID = [],
    fromComposeModal = false,
    openLetterWasSentModal,
}: IUseHandleLettersForm) {
    const dispatch = useDispatch();
    const initSmileMobBlock = useInitSmileMobBlock();
    const inputTextareaRef = useRef<HTMLTextAreaElement | null>(null);
    const debounceTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const handleRedUser = useHandleRedUser();

    const userState = useSelector((state: RootState) => state.user);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const menuState = useSelector((state: RootState) => state.menu);
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);
    const mailboxState = useSelector((state: RootState) => state.mailbox);

    const [draftId, setDraftId] = useState<number[]>([]);
    const [inputData, setInputData] = useState('');
    const [attachData, setAttachData] = useState<IMailAttachment[]>([]);
    const [inputDataDraft, setInputDataDraft] = useState<string>('');
    const [attachDataDraft, setAttachDataDraft] = useState<IMailAttachment[]>([]);
    const [disabledButton, setDisabledButton] = useState(false);
    const [sendButtonClass, setSendButtonClass] = useState(Styles.enable_send_button);

    const [isActiveLargeInput, setIsActiveLargeInput] = useState(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [isChangedDraft, setIsChangedDraft] = useState<boolean>(false);

    const isHideDraft = SITE.ID === 23;
    const isEmptyDraft = !+inputDataDraft?.trim()?.length && !+attachDataDraft?.length;

    const stylesAddNextLine = () => {
        if (window.innerWidth < 600 && inputTextareaRef && inputTextareaRef?.current) {
            const clientHeight = 50;
            const scrollHeight = inputTextareaRef?.current?.scrollHeight;
            // const isLarge = inputTextareaRef.current?.classList?.value?.includes('large');

            setIsActiveLargeInput(scrollHeight > clientHeight);
        }
    };

    const addEmoji = (emoji: PickerProps) => {
        if (LENGTH.LETTER_MAX_LENGTH >= (inputData + emoji?.native)?.length) {
            setInputData(inputData + emoji?.native);
        }
    };

    const submitLetter = () => {
        scrollSmoothDownInLetterChatV2();
        if (isLoadingRequest) return false;
        if (!userState?.info?.external_id) return false;
        if (inputData?.trim()?.length === 0) {
            return dispatch(
                alertAction.setMessage({ message: 'The letter must contain the text' }),
            );
        }

        const _inputData = MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID)
            ? inputData
            : hideStringStar(inputData, REGEXP.NUMBER_IN_STRING);

        if (!MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID) && checkUrl(_inputData)) {
            return dispatch(alertAction.setMessage({ message: 'Invalid message' }));
        }

        dispatch(loaderAction.setActiveGifLoader(true)); // make visual loader
        setSendButtonClass(Styles.disable_send_button); // disable button for prevent double-click
        setDisabledButton(true);

        setIsLoadingRequest(true);

        const recipients = checkedRecipientsID?.length
            ? checkedRecipientsID
            : [currentChatMailboxState?.info?.female_id];
        const attachments = attachLetter?.length
            ? attachLetter
            : attachData?.filter((item) => !item?.hidden);

        new MailboxApi()
            .postMail({
                userId: userState?.info?.external_id,
                recipients,
                messageContent: _inputData,
                messageType: 'SENT_TEXT',
                attachments,
                parentMailId: !currentChatMailboxState?.info?.openFromChat
                    ? currentChatMailboxState?.info?.id
                    : null,
            })
            .then((res) => {
                dispatch(loaderAction.setActiveGifLoader(false)); // remove visual loader
                setDisabledButton(false);
                setSendButtonClass(Styles.enable_send_button); // enable send button
                if (res?.status) {
                    setInputData('');
                    setAttachData([]);
                    if (setOpenForm) setOpenForm(false);
                    if (userState?.info?.external_id) {
                        new MailboxApi()
                            .postDeleteDraft({
                                userId: userState?.info?.external_id,
                                draftIds: draftId,
                            })
                            .then((res) => {
                                if (res?.status) setDraftId([]);
                            });
                    }

                    dispatch(userAction.setBalance());
                    handleRedUser.submitLog(
                        RED_USER_LOG_TYPES.LETTER_FROM,
                        JSON.stringify({
                            womanExternalID: currentChatMailboxState?.info?.female_id,
                            action: 'SENT_LETTER',
                            content: _inputData,
                        }),
                    );

                    if (fromComposeModal) {
                        if (openLetterWasSentModal) openLetterWasSentModal();

                        if (userState?.info?.external_id) {
                            dispatch(
                                mailboxAction.getMails({
                                    userId: userState?.info?.external_id,
                                    folder: menuState?.letters,
                                    girlFilter: letterFilterState?.girlFilter,
                                    girlId: letterFilterState?.girlId || '',
                                    letterFilter: letterFilterState?.lettersFilter,
                                    onlineFilter: letterFilterState?.onlineFilter,
                                    username: letterFilterState?.username,
                                    page: mailboxState?.mailListPage,
                                }),
                            );
                        }
                    } else {
                        if (userState?.info?.external_id) {
                            dispatch(
                                currentChatMailboxAction.getMailboxChatHistorySendMess({
                                    userId: userState?.info?.external_id,
                                    folder: 'dialog',
                                    girlId: currentChatMailboxState?.info?.public_external_id,
                                    page: 1,
                                }),
                            );
                        }
                    }
                    dispatch(currentChatMailboxAction.setMailboxChatInfoOpenFromChat(false));
                } else if (res?.block) {
                    dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                    // dispatch(alertAction.setCustomAlertType({alertType: ALERT.ENOUGH_CREDITS_LETTER}));
                }
            })
            .finally(() => setIsLoadingRequest(false));

        dispatch(loaderAction.setActiveGifLoader(false)); // remove visual loader
        setSendButtonClass(Styles.enable_send_button); // enable send button
    };

    const onFocusInput = () => {
        // setTimeout(() => scrollSmoothDownInLetterChat(), 500);
        scrollSmoothDownInLetterChatV2();
    };

    const onBlurInput = () => {
        // setTimeout(() => scrollSmoothDownInLetterChat(), 500);
        scrollSmoothDownInLetterChatV2();
    };

    const handleChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const text = event?.target?.value;
        const newlineCount = (text.match(/\n/g) || []).length;

        if (newlineCount <= LENGTH.MESSAGE_MAX_NUMBER_LINE) {
            setInputData(text);

            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
            debounceTimerRef.current = setTimeout(() => {
                setInputDataDraft(text);
                if (attachData) setAttachDataDraft(attachData);
                setIsChangedDraft(true);
            }, TIMER.LETTER_UPDATE_DRAFT);
        }

        scrollSmoothDownInLetterChatV2();
        // setTimeout(() => scrollSmoothDownInLetterChat(), 500);
    };

    const sendMedia = (
        e: React.MouseEvent<HTMLDivElement>,
        messageType: 'SENT_IMAGE' | 'SENT_VIDEO' | 'SENT_AUDIO',
    ) => {
        const checkedList =
            e?.currentTarget?.parentNode?.parentNode?.querySelectorAll('input:checked');
        const arr = [];

        if (checkedList && checkedList?.length > 0) {
            for (let i = 0; i < checkedList.length; i++) {
                const element = checkedList[i] as HTMLInputElement;
                // const title =
                //     messageType === 'SENT_IMAGE' ? element?.name : element?.dataset?.filename;
                const title = element?.dataset?.filename;

                arr.push({
                    title: title ?? '',
                    link: element?.dataset?.link ?? '',
                    message_type: messageType,
                    id: +element?.name,
                });
            }
        } else {
            dispatch(alertAction.setMessage({ message: 'File not selected' }));
        }

        if (
            +attachData.filter((item: any) => !item.hidden).length + +arr.length <=
            LENGTH.MAX_ATTACHMENTS_TO_LETTER
        ) {
            setAttachData(attachData.concat(arr));
            setAttachDataDraft(attachData.concat(arr));
            if (inputData) setInputDataDraft(inputData);
            setIsChangedDraft(true);
            closeAttachModal();
        } else {
            dispatch(alertAction.setCustomAlertType({ alertType: ALERT.LETTER_FILES_LIMIT }));
        }
    };

    const deleteAttach = (event: React.MouseEvent<HTMLDivElement>) => {
        const newAttach = attachData?.filter(
            (el, index) => +index !== +(event?.currentTarget?.dataset?.id || 0),
        );
        setAttachData(newAttach);
    };

    const onSubmitMessageEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event?.key === 'Enter') {
            const target = event?.target as HTMLInputElement;
            const value = target?.value;
            const lines = value.split('\n');

            //Enter + Shift
            if (event?.shiftKey) {
                if (lines?.length < LENGTH.MESSAGE_MAX_NUMBER_LINE) return false;
                else return event?.preventDefault();
            }
            event?.preventDefault();
            submitLetter();
        }
    };

    useEffect(() => {
        (async function () {
            if (!isChangedDraft || isHideDraft) return;
            stylesAddNextLine();

            if (!draftId?.length) {
                const res =
                    userState?.info &&
                    (await new MailboxApi().postAddDraft({
                        userId: userState?.info?.external_id,
                        recipients: [currentChatMailboxState?.info?.female_id],
                        messageContent: inputDataDraft,
                        attachments: attachDataDraft.filter((item) => !item?.hidden),
                    }));
                if (res?.status && userState.info && res?.result?.length) {
                    if (res?.status) setDraftId(res?.result);
                }
            } else {
                if (isEmptyDraft) {
                    const res =
                        userState?.info &&
                        (await new MailboxApi().postDeleteDraft({
                            userId: userState?.info?.external_id,
                            draftIds: draftId,
                        }));
                    if (res?.status) {
                        setDraftId([]);
                    }
                } else {
                    if (userState?.info) {
                        new MailboxApi().postUpdateDraft({
                            userId: userState?.info?.external_id,
                            draftIds: draftId,
                            messageContent: inputDataDraft,
                            attachments: attachDataDraft.filter((item) => !item?.hidden),
                        });
                    }
                }
            }
        })();
    }, [inputDataDraft, attachDataDraft]);

    return {
        ...initSmileMobBlock,
        inputData,
        attachData,
        disabledButton,
        sendButtonClass,
        deleteAttach,
        sendImg: (e: React.MouseEvent<HTMLDivElement>) => sendMedia(e, 'SENT_IMAGE'),
        sendVideo: (e: React.MouseEvent<HTMLDivElement>) => sendMedia(e, 'SENT_VIDEO'),
        sendAudio: (e: React.MouseEvent<HTMLDivElement>) => sendMedia(e, 'SENT_AUDIO'),
        handleChangeInput,
        onSubmitMessageEnter,
        submitLetter,
        addEmoji,
        onFocusInput,
        onBlurInput,
        inputTextareaRef,
        isActiveLargeInput,
        isLoadingRequest,
        setIsLoadingRequest,
    };
}
