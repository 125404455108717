import moment from 'moment';

export function parseUTC(s: string) {
    try {
        if (typeof s === 'object') s = moment(s).format('YYYY-MM-DD HH:mm:ss');
        if (s.includes('Z') || s.includes('T')) s = moment(s).utc().format('YYYY-MM-DD HH:mm:ss');

        const b = s?.split(/\D/);
        // eslint-disable-next-line
        // @ts-ignore
        return new Date(Date.UTC(+b[0], --b[1], +b[2], +b[3], +b[4], +b[5]));
    } catch (e) {
        return new Date();
    }
}

export function formatDate(date: string, chatList = false) {
    if (!date) return;
    if (!parseUTC(date)) return;

    return moment().diff(moment(parseUTC(date).toString()).startOf('day').format(), 'days') > 0
        ? moment(parseUTC(date).toString())
              .locale('en')
              .format(chatList ? 'DD/MM' : 'DD/MM LT')
              .toLowerCase()
        : moment(parseUTC(date).toString()).locale('en').format('LT').toLowerCase();
}

export function formatDateMedia(date: string) {
    if (!date) return;
    if (!parseUTC(date)) return;

    return moment(parseUTC(date).toString()).locale('en').format('MMMM D, YYYY');
}

export function diffWithCurrentTimeInSeconds(date: string) {
    const firstDate = new Date();
    const secondDate = parseUTC(date);

    return +secondDate - +firstDate >= 0;
}

export function diffWithCurrentDate(date: string) {
    return +new Date() - +new Date(date);
}

export function diffTimeInSeconds(firstDate: string | Date, secondDate: string | Date | null) {
    return moment(firstDate).diff(moment(secondDate), 'seconds');
}

export function diffTimeInHours(firstDate: string | Date, secondDate: string | Date) {
    return moment(firstDate).diff(moment(secondDate), 'hours');
}

export function diffTimeInDaysWithCurrentDay(diffDay: string) {
    if (!diffDay) return 0;

    try {
        return moment(new Date()).diff(moment(diffDay), 'days');
    } catch (e) {
        return 0;
    }
}

export function compareDates(firstDate: string, secondDate: string) {
    return new Date(firstDate) >= new Date(secondDate);
}

export function getStartDate() {
    const date = new Date();
    date.setHours(date.getHours(), 0, 0);
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}
