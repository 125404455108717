import React, { RefObject } from 'react';
import global from '../global.module.scss';
import styles from './styles.module.scss';
import usePhotosScroll from '../../../../../hooks/profile/my-profile-v2/usePhotosScroll';
import { IPhotosProps } from './index.interface';
import IconAboutPhoto from '../../../../Icons/IconAboutPhoto';
import IconAddPlus from '../../../../Icons/IconAddPlus';
import IconDeletePhoto from '../../../../Icons/IconDeletePhoto';

export default function Photos({ handleMainInfo }: IPhotosProps) {
    const { mainInfo } = handleMainInfo;
    const { scrollNext, scrollPrev, galleryContent } = usePhotosScroll({
        photos: mainInfo?.photos,
    });

    return galleryContent ? (
        <div
            className={`${styles.profile_photos} ${global.profile_div}`}
            data-testid="delete-photo-block"
        >
            <div className={`${global.profile_div_head}`}>
                <div className={`${global.profile_div_head_title}`} data-testid="count-photo">
                    <div className={styles.profile_about_icon}>
                        <IconAboutPhoto />
                    </div>
                    <h2 className={`${global.profile_block_title}`}>
                        photos
                        <span>({galleryContent?.length})</span>
                    </h2>
                </div>
                <div
                    className={`${styles.profile_div_head_add_photo}`}
                    onClick={handleMainInfo.showInputFile}
                >
                    <input
                        type="file"
                        className={styles.hidden_upload}
                        ref={handleMainInfo.inputUploadPhoto as RefObject<HTMLInputElement>}
                        onChange={handleMainInfo.uploadUserPhoto}
                        onClick={(e) => {
                            const target = e?.target as HTMLInputElement;
                            target.value = '';
                        }}
                        data-testid="upload-photo"
                    />
                    <IconAddPlus />
                    <span>Add photo</span>
                </div>
            </div>
            {!!galleryContent?.length && (
                <div className={`${styles.profile_photos_scroll_wrap}`}>
                    <div className={`${styles.profile_photos_scroll}`} id="profile_photos_scroll">
                        <ul className={`${styles.profile_photos_list}`}>
                            {galleryContent?.map((item, key) => (
                                <li
                                    className={`${styles.profile_photos_item}`}
                                    key={key}
                                    data-testid="photo-img"
                                >
                                    <img
                                        className={styles.profile_photos_item_img}
                                        src={item?.link}
                                        alt="photo"
                                    />
                                    <div
                                        className={`${styles.profile_photos_item_delete}`}
                                        onClick={(e) =>
                                            handleMainInfo.openModalDeleteUserPhoto(e, item)
                                        }
                                        data-testid="delete-photo"
                                    >
                                        <IconDeletePhoto />
                                        <span>Delete</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        className={`${styles.profile_photos_arrow} ${styles.left_arrow}`}
                        onClick={scrollPrev}
                    />
                    <div
                        className={`${styles.profile_photos_arrow} ${styles.right_arrow}`}
                        onClick={scrollNext}
                    />
                </div>
            )}
        </div>
    ) : (
        <div />
    );
}
