import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as PURCHASE from '../../constants/purchase';
import PaymentApi from '../../services/api/v3/PaymentApi';
import * as userAction from '../../store/actions/user';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import AccountApi from '../../services/api/Account';
import useQueryOpenPayment from './useQueryOpenPayment';
import * as loaderAction from '../../store/actions/loader';
import * as purchaseAction from '../../store/actions/purchase';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useHandleRedUser from '../user/useHandleRedUser';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import {
    IClientAcquiringApplePayToken,
    IClientAcquiringToken,
    IFrameFormData,
    ISecurionpayPaymentData,
    IStripePaymentData,
    IUniversePaymentData,
} from '../../pages/CheckoutPage/PaymentFrame/index.interface';
import { IGooglePayPaymentData } from '../../pages/CheckoutPage/PaymentFrame/GooglePayForm/index.interface';

export default function useInitPaymentPackageList() {
    const purchaseState = useSelector((state: RootState) => state.purchase);
    const userState = useSelector((state: RootState) => state.user);

    const [frameLink, setFrameLink] = useState<string>('');
    const [frameFormData, setFrameFormData] = useState<IFrameFormData | null>(null);
    const [clientAcquiringToken, setClientAcquiringToken] = useState<IClientAcquiringToken | null>(
        null,
    );
    const [clientAcquiringApplePayToken, setClientAcquiringApplePayToken] =
        useState<IClientAcquiringApplePayToken | null>(null);
    const [clientAcquiringGooglePayToken, setClientAcquiringGooglePayToken] =
        useState<IClientAcquiringApplePayToken | null>(null);
    const [paymentData, setPaymentData] = useState<
        | IGooglePayPaymentData
        | IUniversePaymentData
        | ISecurionpayPaymentData
        | IStripePaymentData
        | null
    >(null);

    const dispatch = useDispatch();
    const handleRedUser = useHandleRedUser();

    useQueryOpenPayment({
        setFrameLink,
        setFrameFormData,
    });

    const removeScript = (id: string) => {
        const existScript = document.getElementById(id);
        if (existScript) existScript?.parentNode?.removeChild(existScript);
    };

    const createSecurionpayScript = (data: any) => {
        try {
            const script = document.createElement('script');

            script.id = PURCHASE.PAYMENT_METHOD_SECURIONPAY;
            script.src = `https://js.dev.shift4.com/shift4.js`;
            script.addEventListener('load', () => {
                setPaymentData(data);
            });

            document.body.appendChild(script);
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const createAcquiringScript = (callback: () => void) => {
        try {
            const script = document.createElement('script');

            script.id = PURCHASE.PAYMENT_METHOD_ACQUIRING;
            script.src = `https://webservices.securetrading.net/js/v3/st.js`;
            script.addEventListener('load', () => {
                callback();
            });

            document.body.appendChild(script);
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const checkSubscribePayment = () => {
        return !!(
            (purchaseState?.activeSubscribeStatus && !purchaseState?.activeSubscribeData) ||
            purchaseState?.isOpenChangeSubscribeModal
        );
    };

    useEffect(() => {
        if (purchaseState?.isBuyingPastabank) {
            dispatch(purchaseAction.setIsBuyingPastabank(false));

            if (purchaseState.activePackage?.id) {
                new AccountApi().postBuyCredits('', purchaseState.activePackage?.id).then((res) => {
                    if (res?.status) {
                        setFrameLink(res?.result?.action);
                        setFrameFormData(res?.result?.fields);

                        setTimeout(() => {
                            const block = document?.querySelector(
                                '#form-credit',
                            ) as HTMLFormElement;
                            if (block) block?.submit();
                        }, 500);
                    } else {
                        setFrameLink(res?.message);
                        dispatch(userAction.isBuying(false));
                    }
                });
            } else {
                dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
                dispatch(userAction.isBuying(false));
            }
        }
    }, [purchaseState.isBuyingPastabank]);

    useEffect(() => {
        (async function () {
            if (userState?.lastBuyCredits) {
                setFrameLink('');
                setFrameFormData(null);
                setClientAcquiringToken(null);
                setClientAcquiringApplePayToken(null);
                setClientAcquiringGooglePayToken(null);
                setPaymentData(null);
                removeScript(PURCHASE.PAYMENT_METHOD_SECURIONPAY);
                removeScript(PURCHASE.PAYMENT_METHOD_ACQUIRING);

                if (userState?.isBuying && purchaseState.activePackage?.id) {
                    handleRedUser.submitLog(
                        RED_USER_LOG_TYPES.PAYMENT_OPEN,
                        JSON.stringify({
                            activePaymentMethod: purchaseState.activePaymentMethod,
                            activePackage: purchaseState.activePackage,
                        }),
                    );

                    switch (purchaseState.activePaymentMethod) {
                        case PURCHASE.PAYMENT_METHOD_ACQUIRING:
                            new PaymentApi()
                                .postAcquiringCreate(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) {
                                        createAcquiringScript(() =>
                                            setClientAcquiringToken(res?.data),
                                        );
                                    } else {
                                        dispatch(userAction.isBuying(false));
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                    }
                                });
                            break;
                        case PURCHASE.PAYMENT_METHOD_ACQUIRING_APPLE_PAY:
                            new PaymentApi()
                                .postAcquiringApplePayCreated(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) {
                                        createAcquiringScript(() => {
                                            setClientAcquiringApplePayToken(res?.data);
                                        });
                                    } else {
                                        dispatch(userAction.isBuying(false));
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                    }
                                });
                            break;
                        case PURCHASE.PAYMENT_METHOD_ACQUIRING_GOOGLE_PAY:
                            new PaymentApi()
                                .postAcquiringGooglePayCreated(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) {
                                        createAcquiringScript(() => {
                                            setClientAcquiringGooglePayToken(res?.data);
                                        });
                                    } else {
                                        dispatch(userAction.isBuying(false));
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                    }
                                });
                            break;
                        case PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK:
                            await new PaymentApi()
                                .postCardPayRecurringOneClickCreate(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) {
                                        setFrameLink(res?.data?.redirect_url);

                                        if (res?.data?.message)
                                            dispatch(
                                                alertAction.setMessage({
                                                    message: res?.data?.message,
                                                }),
                                            );

                                        setTimeout(() => {
                                            const block = document?.querySelector(
                                                '#form-credit',
                                            ) as HTMLFormElement;
                                            if (block) block?.submit();
                                        }, 500);
                                    } else {
                                        dispatch(userAction.isBuying(false));
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                    }
                                });

                            dispatch(loaderAction.setActiveGifLoader(false));
                            break;
                        case PURCHASE.PAYMENT_METHOD_CARDPAY:
                            if (checkSubscribePayment()) {
                                try {
                                    await new PaymentApi()
                                        .postCardpayCreateSubscribe(purchaseState.activePackage?.id)
                                        .then((res) => {
                                            if (res?.status) {
                                                setFrameLink(res?.data?.redirect_url);

                                                if (res?.data?.message) {
                                                    dispatch(
                                                        alertAction.setMessage({
                                                            message: res?.data?.message,
                                                        }),
                                                    );
                                                }
                                                setTimeout(() => {
                                                    const block = document?.querySelector(
                                                        '#form-credit',
                                                    ) as HTMLFormElement;
                                                    if (block) block?.submit();
                                                }, 500);
                                            } else {
                                                dispatch(userAction.isBuying(false));
                                                dispatch(
                                                    alertAction.setMessage({
                                                        message: ERROR.ERROR_REQUEST,
                                                    }),
                                                );
                                            }
                                        });
                                } catch (error) {
                                    sendErrToSentry(error as Error);
                                }
                            } else {
                                await new PaymentApi()
                                    .postCardPayCreate(purchaseState.activePackage?.id)
                                    .then((res) => {
                                        if (res?.status) {
                                            setFrameLink(res?.data?.redirect_url);

                                            if (res?.data?.message) {
                                                dispatch(
                                                    alertAction.setMessage({
                                                        message: res?.data?.message,
                                                    }),
                                                );
                                            }

                                            setTimeout(() => {
                                                const block = document?.querySelector(
                                                    '#form-credit',
                                                ) as HTMLFormElement;
                                                if (block) block?.submit();
                                            }, 500);
                                        } else {
                                            dispatch(userAction.isBuying(false));
                                            dispatch(
                                                alertAction.setMessage({
                                                    message: ERROR.ERROR_REQUEST,
                                                }),
                                            );
                                        }
                                    });
                            }

                            dispatch(loaderAction.setActiveGifLoader(false));
                            break;
                        case PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY:
                            await new PaymentApi()
                                .postCardPayGooglePayCreate(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) {
                                        setPaymentData(res?.data);
                                        if (res?.data?.message) {
                                            dispatch(
                                                alertAction.setMessage({
                                                    message: res?.data?.message,
                                                }),
                                            );
                                        }
                                    } else {
                                        dispatch(userAction.isBuying(false));
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                    }
                                });
                            dispatch(loaderAction.setActiveGifLoader(false));
                            break;
                        case PURCHASE.PAYMENT_METHOD_PASTABANK:
                            if (checkSubscribePayment()) {
                                try {
                                    await new PaymentApi()
                                        .getPastabankSubscribeInfo()
                                        .then((res) => {
                                            if (!res?.status) return;
                                            if (!purchaseState.activePackage?.id) return;

                                            new AccountApi()
                                                .postPastaRecurring(
                                                    '',
                                                    purchaseState.activePackage?.id,
                                                )
                                                .then((res) => {
                                                    if (res?.status) {
                                                        if (
                                                            res?.data?.newCard &&
                                                            purchaseState.activePackage?.id
                                                        ) {
                                                            new AccountApi()
                                                                .postNewCardPastaRecurring(
                                                                    '',
                                                                    purchaseState.activePackage?.id,
                                                                )
                                                                .then((res) => {
                                                                    if (res?.status) {
                                                                        dispatch(
                                                                            loaderAction.setActiveGifLoader(
                                                                                false,
                                                                            ),
                                                                        );
                                                                        setFrameLink(
                                                                            res?.result?.action,
                                                                        );
                                                                        setFrameFormData(
                                                                            res?.result?.fields,
                                                                        );

                                                                        setTimeout(() => {
                                                                            const block =
                                                                                document?.querySelector(
                                                                                    '#form-credit',
                                                                                ) as HTMLFormElement;
                                                                            if (block) {
                                                                                block?.submit();
                                                                            }
                                                                        }, 500);
                                                                    } else {
                                                                        // setFrameLink(res.result.fields.ERROR_CALLBACK);
                                                                        // setFrameLink(res?.message);
                                                                        dispatch(
                                                                            alertAction.setMessage({
                                                                                message:
                                                                                    ERROR.ERROR_REQUEST,
                                                                            }),
                                                                        );
                                                                    }
                                                                });
                                                        } else {
                                                            dispatch(
                                                                loaderAction.setActiveGifLoader(
                                                                    false,
                                                                ),
                                                            );
                                                        }
                                                        dispatch(
                                                            loaderAction.setActiveGifLoader(false),
                                                        );
                                                    } else {
                                                        // setFrameLink(res?.message);
                                                        dispatch(
                                                            alertAction.setMessage({
                                                                message: ERROR.ERROR_REQUEST,
                                                            }),
                                                        );
                                                    }
                                                });
                                        });
                                } catch (error) {
                                    sendErrToSentry(error as Error);
                                }
                            } else {
                                if (
                                    !purchaseState?.isFirstPurchase &&
                                    !purchaseState?.tokenList?.some(
                                        (el) =>
                                            el?.payment_method === 'PASTABANK' && +el?.default_card,
                                    )
                                ) {
                                    new AccountApi().postCreateToken(1).then((res) => {
                                        if (res?.status) {
                                            setFrameLink(res?.result?.action);
                                            setFrameFormData(res?.result?.fields);

                                            setTimeout(() => {
                                                const block = document?.querySelector(
                                                    '#form-credit',
                                                ) as HTMLFormElement;
                                                if (block) block?.submit();
                                            }, 500);
                                        } else {
                                            dispatch(userAction.isBuying(false));
                                        }
                                    });
                                } else {
                                    if (purchaseState.activePackage?.id) {
                                        new AccountApi()
                                            .postBuyCredits('', purchaseState.activePackage?.id)
                                            .then((res) => {
                                                if (res?.status) {
                                                    setFrameLink(res?.result?.action);
                                                    setFrameFormData(res?.result?.fields);

                                                    setTimeout(() => {
                                                        const block = document?.querySelector(
                                                            '#form-credit',
                                                        ) as HTMLFormElement;
                                                        if (block) block?.submit();
                                                    }, 500);
                                                }
                                            });
                                    } else {
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                        dispatch(userAction.isBuying(false));
                                    }
                                }
                            }
                            dispatch(loaderAction.setActiveGifLoader(false));
                            break;
                        case PURCHASE.PAYMENT_METHOD_APPLEPAY:
                        case PURCHASE.PAYMENT_METHOD_APPLEPAY_PASTABANK:
                            dispatch(userAction.isBuying(false));
                            break;
                        case PURCHASE.PAYMENT_METHOD_SECURIONPAY:
                            new PaymentApi()
                                .postSecurionpayCreateV2(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) createSecurionpayScript(res?.data);
                                    else {
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                        dispatch(userAction.isBuying(false));
                                    }
                                });
                            break;
                        case PURCHASE.PAYMENT_METHOD_UNIVERSEPAY:
                            new PaymentApi()
                                .postUniversepayCreate(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) setPaymentData(res?.data);
                                    else {
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                        dispatch(userAction.isBuying(false));
                                    }
                                });
                            break;
                        case PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING:
                            new PaymentApi()
                                .postUniversepayRecurringCreate(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) setPaymentData(res?.data);
                                    else {
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                        dispatch(userAction.isBuying(false));
                                    }
                                });
                            break;

                        case PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY: {
                            await new PaymentApi()
                                .postPastaGooglePayCreate(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status) {
                                        setPaymentData(res?.data);
                                        if (res?.data?.message) {
                                            dispatch(
                                                alertAction.setMessage({
                                                    message: res?.data?.message,
                                                }),
                                            );
                                        }
                                    } else {
                                        dispatch(userAction.isBuying(false));
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                    }
                                });
                            dispatch(loaderAction.setActiveGifLoader(false));
                            break;
                        }

                        case PURCHASE.PAYMENT_METHOD_STRIPE: {
                            await new PaymentApi()
                                .postStripeCreate(purchaseState.activePackage?.id)
                                .then((res) => {
                                    if (res?.status && res?.data?.clientSecret) {
                                        setPaymentData(res?.data);
                                    } else {
                                        dispatch(
                                            alertAction.setMessage({
                                                message: ERROR.ERROR_REQUEST,
                                            }),
                                        );
                                        dispatch(userAction.isBuying(false));
                                    }
                                });
                            dispatch(loaderAction.setActiveGifLoader(false));
                            break;
                        }

                        default:
                            break;
                    }
                }
            }
        })();
    }, [userState.isBuying, purchaseState.activePaymentMethod]);

    useEffect(() => {
        if (purchaseState?.purchaseList?.length) {
            if (
                purchaseState.activePackage?.id &&
                purchaseState?.purchaseList?.some(
                    (item) => +item?.id === purchaseState.activePackage?.id,
                )
            ) {
                return;
            }
            dispatch(purchaseAction.setActivePackage(purchaseState?.purchaseList?.[0]));
        }
    }, [purchaseState.purchaseList]);

    return {
        isLoadedPaymentFrame:
            !!paymentData ||
            !!frameLink?.length ||
            !!clientAcquiringToken?.jwt ||
            !!clientAcquiringApplePayToken?.jwt ||
            !!clientAcquiringGooglePayToken?.jwt,
        paymentData,
        frameLink,
        frameFormData,
        clientAcquiringToken,
        clientAcquiringApplePayToken,
        clientAcquiringGooglePayToken,
    };
}
