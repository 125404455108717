import React from 'react';
import useInitSupport from '../../hooks/support/useInitSupport';
import useInitLogin from '../../hooks/login/useInitLogin';
import useInitRegister from '../../hooks/register/useInitRegister';
import useInitTerm from '../../hooks/term/useInitTerm';
import useInitAffiliats from '../../hooks/support/useInitAffiliats';
import useInitPollModalWithoutAuth from '../../hooks/quiz/useInitPollModalWithoutAuth';
import useInitCheckIP from '../../hooks/ip/useInitCheckIP';
import Auth from '../../components/Auth';
import LoginModal from '../../components/common/Modals/LoginModal';
import RegisterModal from '../../components/common/Modals/RegisterModal';
import AlertModal from '../../components/common/Modals/AlertModal';
import SupportModal from '../../components/common/Modals/SupportModal';
import TermModal from '../../components/common/Modals/TermModal';
import AffiliatsModal from '../../components/common/Modals/AffiliatsModal';
import PollMainPageModal from '../../components/common/Modals/PollMainPageModal';

export default function AuthPage() {
    const initSupport = useInitSupport();
    const initLogin = useInitLogin();
    const initRegister = useInitRegister(initLogin.handleFormDataLoginEmail);
    const initTerm = useInitTerm();
    const initAffiliats = useInitAffiliats();
    const initPollModalWithoutAuth = useInitPollModalWithoutAuth();
    useInitCheckIP();

    return (
        <>
            <Auth
                initLogin={initLogin}
                initRegister={initRegister}
                openSupportModal={initSupport.openSupportModal}
                openTermModal={initTerm.openTermModal}
                openAffiliatsModal={initAffiliats.openAffiliatsModal}
            />

            {/*MODALS ---------------------------------------*/}
            <PollMainPageModal {...initPollModalWithoutAuth} />
            <SupportModal {...initSupport} />
            <AffiliatsModal {...initAffiliats} />
            <AlertModal />
            <LoginModal {...initLogin} />
            <RegisterModal
                {...initRegister}
                openTermModal={initTerm.openTermModal}
                openSignInBlock={initLogin.openSignInBlock}
            />
            <TermModal {...initTerm} />
        </>
    );
}
