import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import NpsApi from '../../../../../../services/api/v3/NpsApi';
import { OPEN_ANSWER_MAX_LENGTH } from '../../../../../../constants/length';
import { IMixNPSProps } from './index.interface';
import useContactInputValidation from '../../../../../../hooks/inputContactValidation/useContactInputValidate';

export default function MixNPS({
    pollShownId,
    setNpsData,
    npsData,
    npsTitle,
    npsQuestion,
}: IMixNPSProps) {
    const { validate } = useContactInputValidation();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isContactValid, setIsContactValid] = useState<boolean>(true);
    const [isContactsActive, setIsContactsActive] = useState<boolean>(false);
    const [values, setValues] = useState<{
        scoreAnswer: number | null;
        textAnswer: string;
        contactInfo: string;
    }>({
        scoreAnswer: null,
        textAnswer: '',
        contactInfo: '',
    });

    const inputs = Array.from({ length: 10 }, (_, i) => (i + 1).toString());

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, scoreAnswer: parseInt(event.target.value) });
    };

    const handleChangeContactCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            setValues({ ...values, contactInfo: '' });
            setIsContactValid(true);
        }
        setIsContactsActive(event.target.checked);
    };

    useEffect(() => {
        const isScoreAnswerValid =
            values?.scoreAnswer && values?.textAnswer?.length <= OPEN_ANSWER_MAX_LENGTH;
        setIsDisabled(!isScoreAnswerValid);
    }, [values]);

    const onSubmit = () => {
        const isValid = !isContactsActive || validate(values?.contactInfo);
        setIsContactValid(isValid);

        if (!isDisabled && isValid) {
            new NpsApi()
                .answerNpsPoll({
                    pollShownId,
                    textAnswer: values?.textAnswer,
                    scoreAnswer: values?.scoreAnswer,
                    ...(isContactsActive && values?.contactInfo
                        ? { contactInfo: values?.contactInfo }
                        : {}),
                })
                .then(() => setNpsData({ ...npsData, pollType: 'success' }));
        }
    };

    return (
        <>
            <div className={styles.mix_nps_content}>
                <p className={styles.nps_modal_text}>{!!npsTitle && `1. ${npsTitle}`}</p>
                <div className={styles.rating_inputs_row} data-testid="rate-list">
                    {inputs.map((input, index) => (
                        <>
                            <input
                                className={styles.rating_radio}
                                type="radio"
                                id={'rate_' + input}
                                name="rate"
                                value={input}
                                key={index}
                                onChange={handleChange}
                            />
                            <label className={styles.rating_radio_label} htmlFor={'rate_' + input}>
                                {index + 1}
                                <div className={styles.rating_radio_label_circle_background}>
                                    <span
                                        className={styles.rating_radio_label_emoji}
                                        data-index={'rate_' + input}
                                        aria-hidden="true"
                                    />
                                </div>
                            </label>
                        </>
                    ))}
                </div>
                <p className={styles.nps_modal_text}>{!!npsQuestion && `2. ${npsQuestion}`}</p>
                <div className={styles.rating_textarea_wrapper} data-testid="text-block">
                    <textarea
                        className={styles.rating_textarea}
                        name="nps_rating_textarea"
                        id="nps_rating_textarea"
                        placeholder="Tell us your thoughts here"
                        value={values?.textAnswer}
                        onChange={(e) => {
                            setValues({ ...values, textAnswer: e.target.value });
                        }}
                        data-testid="text-area"
                        maxLength={OPEN_ANSWER_MAX_LENGTH}
                    ></textarea>
                    <div className={styles.rating_textarea_counter}>
                        {values?.textAnswer?.length}/{OPEN_ANSWER_MAX_LENGTH}
                    </div>
                </div>
                {values.textAnswer.length >= OPEN_ANSWER_MAX_LENGTH && (
                    <p className={styles.textarea_error_message}>
                        The character limit has been exceeded.
                    </p>
                )}
                <div className={styles.contacts_checkbox_wrapper}>
                    <input
                        type="checkbox"
                        id="contacts_checkbox"
                        className={styles.contacts_checkbox}
                        checked={isContactsActive}
                        onChange={handleChangeContactCheckbox}
                    />
                    <label htmlFor="contacts_checkbox" className={styles.contacts_checkbox_label} />
                    <p className={styles.contacts_checkbox_info}>
                        I would like to be contacted by support team.
                    </p>
                </div>
                <div
                    className={`${styles.contacts_input_wrapper} ${isContactsActive ? styles.contacts_input_active : ''}`}
                >
                    <input
                        type="text"
                        id="contacts_value"
                        className={styles.contacts_input}
                        value={values?.contactInfo}
                        onChange={(e) => setValues({ ...values, contactInfo: e.target.value })}
                        placeholder="Enter your phone number or email"
                    />
                    <label htmlFor="contacts_value" className={styles.contacts_input_label}>
                        Phone number/email
                    </label>
                    {!isContactValid && (
                        <p className={styles.contacts_input_error_message}>
                            Please enter a valid email or phone number
                        </p>
                    )}
                </div>
            </div>
            <button
                type="button"
                className={`${styles.nps_button_continue} ${isDisabled ? styles.disabled : ''}`}
                onClick={onSubmit}
                data-testid="send-btn"
                disabled={isDisabled}
            >
                <span className={styles.nps_button_continue_text}>Send</span>
            </button>
        </>
    );
}
