import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function useInitSound() {
    const userState = useSelector((state: RootState) => state.user);
    const [isActiveSound, setIsActiveSound] = useState(
        !!JSON.parse(localStorage.getItem('sound') as string) ?? null,
    );

    const setSoundNotification = () => {
        setIsActiveSound(!isActiveSound);
        if (isActiveSound) localStorage.setItem('sound', '0');
        else localStorage.setItem('sound', '1');
    };

    useEffect(() => {
        if (userState?.info?.external_id) {
            const isExistSoundFlag = JSON.parse(localStorage.getItem('sound') as string) ?? null;

            if (isExistSoundFlag === null) {
                setIsActiveSound(false);
                localStorage.setItem('sound', '0');
            }
        }
    }, [userState.info]);

    return { setSoundNotification, isActiveSound };
}
