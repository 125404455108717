import { useEffect, useState } from 'react';
import LoginApi from '../../services/api/LoginApi';
import { getUrlParameter } from '../../utils/url-utils';
import * as alertAction from '../../store/actions/alert';
import { useDispatch } from 'react-redux';
import { deleteUrlParameter } from '../../services/methods';
import { useHistory } from 'react-router-dom';
import { redirectToMobileVersion } from '../../services/methods/mobile';
import { isMobile } from 'react-device-detect';
import * as ERROR from '../../constants/error';
import { sendErrToSentry } from '../../services/sentry';

export default function useAccessKeyRedirect() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isAccessKeyChecked, setIsAccessKeyChecked] = useState(false);

    const clearAccessKey = () => {
        deleteUrlParameter('accessKey');
        setTimeout(() => sessionStorage.removeItem('accessKey'), 5000);
    };

    const accessKeyRedirect = async () => {
        try {
            const token = localStorage.getItem('token');
            const accessKey = getUrlParameter('accessKey');
            const currentPathname = window.location.pathname.split('/')[1];

            sessionStorage.setItem('accessKey', '1');

            if (accessKey && accessKey !== 'undefined') {
                const res = await new LoginApi().getAccessToken(accessKey as string);

                if (res?.status && res?.response) {
                    localStorage.setItem('fingerprintHash', res?.fingerprint);
                    localStorage.setItem('token', JSON.stringify(res?.response));
                    if (
                        res?.isMobileData?.forMobile &&
                        res?.isMobileData?.accessKey?.length &&
                        isMobile
                    ) {
                        return redirectToMobileVersion(
                            res?.isMobileData?.accessKey,
                            window?.location?.pathname,
                        );
                    }
                } else {
                    if (!token) history.push(`/${window?.location?.search}`);
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            } else if (accessKey === '' && !token && currentPathname === 'search') {
                // Check for search because backend redirects to /search by default from /v3/auth/cross-register
                history.push(`/${window?.location?.search}`);
            }

            setIsAccessKeyChecked(true);
            clearAccessKey();
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    useEffect(() => {
        if (
            window?.location?.search?.includes('accessKey') &&
            !window?.location?.href?.includes('promocode')
        ) {
            accessKeyRedirect();
        } else {
            setIsAccessKeyChecked(true);
        }
    }, []);

    return { isAccessKeyChecked };
}
