import { LoaderActionTypes } from '../../constants/loader';
import { ILoaderState, LoaderActions } from './index.interface';

const initState = {
    isActive: false,
    activeGifLoader: false,
};

export default function (state: ILoaderState = initState, action: LoaderActions): ILoaderState {
    switch (action.type) {
        case LoaderActionTypes.SET_ACTIVE_STATUS:
            return {
                ...state,
                ...{
                    isActive: action.data,
                },
            };

        case LoaderActionTypes.SET_ACTIVE_GIF_LOADER:
            return {
                ...state,
                ...{
                    activeGifLoader: action.data,
                },
            };

        default:
            return state;
    }
}
