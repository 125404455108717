import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ConfirmStatusEmail from './ConfirmStatusEmail';
import { IConfirmStatusEmailModalProps } from './index.interface';

export default function ConfirmStatusEmailModal({
    isActiveConfirmEmailModal,
    closeConfirmEmailModal,
    emailData,
    changeEmailData,
    submitEmailData,
    isEmailSent,
}: IConfirmStatusEmailModalProps) {
    return (
        <Modal style={modalStyleProps()} isOpen={isActiveConfirmEmailModal} ariaHideApp={false}>
            <ConfirmStatusEmail
                closeModal={closeConfirmEmailModal}
                emailData={emailData}
                changeEmailData={changeEmailData}
                submitEmailData={submitEmailData}
                isEmailSent={isEmailSent}
            />
        </Modal>
    );
}
