import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import * as currentChatAction from '../../store/actions/currentChat';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as chatsAction from '../../store/actions/chats';
import * as countersAction from '../../store/actions/counters';
import * as notifyAction from '../../store/actions/notify';
import * as syncAction from '../../store/actions/sync';
import * as allMediaAction from '../../store/actions/allMedia';
import * as settingsAction from '../../store/actions/settings';
import * as purchaseAction from '../../store/actions/purchase';
import * as mailboxAction from '../../store/actions/mailbox';
import * as modalsActions from '../../store/actions/modals';
import * as purchaseActions from '../../store/actions/purchase';
import * as transactionAction from '../../store/actions/transaction';
import * as connectionAction from '../../store/actions/connection';
import * as loaderAction from '../../store/actions/loader';
import * as likesAction from '../../store/actions/likes';

import * as SYNC from '../../constants/sync';
import * as MENU from '../../constants/menu';
import * as USER from '../../constants/user';
import * as PURCHASE from '../../constants/purchase';

import ChatsApi from '../../services/api/ChatsApi';
import TransactionApi from '../../services/api/v3/TransactionApi';
import AccountApi from '../../services/api/Account';

import { generationDateOnFewMinutesLonger } from '../../services/methods';
import { switchChatList, timeDiffFromMin } from '../../services/methods';
import { sendAfficeFromStore } from '../../services/affise';
import { sendBinomoFromStore } from '../../services/binomo';
import { getLastMessageContent } from '../../services/methods/chat';

import useTransition from '../transition/useTransition';
import useMakeNotificationSound from '../sound/useMakeNotificationSound';
import useSuccessPaymentCallback from '../purchase/useSuccessPaymentCallback';
import useLogout from '../logout/useLogout';
import useTransactionConfig from '../transaction/useTransactionConfig';
import useInitSubscribe from '../purchase/subscribe/useInitSubscribe';
import useHandleRedUser from '../user/useHandleRedUser';
import useHandleBilling from '../billing/useHandleBilling';
import useHandlePayment from '../purchase/useHandlePayment';
import useGoogleDataLayer from '../event/useGoogleDataLayer';
import * as videoShowAction from '../../store/actions/videoShow';
import * as searchAction from '../../store/actions/search';
import useActionLike from '../likes/useActionLike';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import { BillingActionEnum } from '../../services/api/v3/BillingApi.interface';
import { ISyncMessage } from './useSync.interface';
import { IDraftsFullInfo, IMailsFullInfo } from '../../services/api/MailboxApi.interface';
import SITE from '../../config/site';

export default function useSync() {
    const dispatch = useDispatch();
    const history = useHistory();
    const transition = useTransition();
    const makeNotificationSound = useMakeNotificationSound();
    const successPaymentCallback = useSuccessPaymentCallback();
    const logout = useLogout();
    const transactionConfig = useTransactionConfig();
    const initSubscribe = useInitSubscribe();
    const handleRedUser = useHandleRedUser();
    const handleBilling = useHandleBilling();
    const handlePayment = useHandlePayment();
    const { pushEventTryPay, pushEventFirstCreditSpent } = useGoogleDataLayer();
    const actionLike = useActionLike();

    const purchaseState = useSelector((state: RootState) => state.purchase);
    const userState = useSelector((state: RootState) => state.user);
    const chatsState = useSelector((state: RootState) => state.chats);
    const modalsState = useSelector((state: RootState) => state.modals);
    const menuState = useSelector((state: RootState) => state.menu);
    const mailboxState = useSelector((state: RootState) => state.mailbox);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const syncProvider = useSelector((state: RootState) => state.sync.provider);
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);
    const transactionState = useSelector((state: RootState) => state.transaction);
    const videoShowState = useSelector((state: RootState) => state.videoShow);
    const searchState = useSelector((state: RootState) => state.search);
    const likesState = useSelector((state: RootState) => state.likes);

    const [messages, setMessages] = useState<ISyncMessage>();

    const callbackChangeSubscription = () => {
        handleBilling.createBillingLog(BillingActionEnum.RECURRING_CHANGE);
        initSubscribe.initSubscribeStatus();
        dispatch(purchaseActions.setChangeSubscribeModal(false));
        dispatch(purchaseActions.setSuccessChangeSubscribeModal(true));
        dispatch(userAction.isBuying(false));
    };

    useEffect(() => {
        dispatch(syncAction.init());
    }, []);

    useEffect(() => {
        if (syncProvider && userState?.info?.external_id) {
            let foundChat = null;

            switch (messages?.action) {
                case SYNC.NOTIFY_MATCH_ACTION:
                case SYNC.NOTIFY_NEW_GIRL_ACTION:
                case SYNC.NOTIFY_STRANGER_ACTION:
                case SYNC.NOTIFY_POSSIBLE_MATCH_ACTION:
                case SYNC.NOTIFY_PROFILE_UPDATE_ACTION:
                case SYNC.NOTIFY_SEARCH_PARTNER_ACTION:
                case SYNC.NOTIFY_VIEW_PROFILE_ACTION:
                case SYNC.NOTIFY_LIKE_ACTION:
                case SYNC.NOTIFY_ONLINE_NOW:
                case SYNC.NOTIFY_MAYBE_APPEAL:
                case SYNC.NOTIFY_NO_CHAT:
                case SYNC.NOTIFY_MAYBE_CHAT:
                    if (messages?.dataForSocketLog) {
                        new TransactionApi().saveFrontSocketLog(messages?.dataForSocketLog);
                    }

                    if (!+userState?.info?.ghost) {
                        if (
                            +userState?.info?.external_id ===
                            +messages?.notification_object?.recipient_external_id
                        ) {
                            makeNotificationSound();
                            dispatch(
                                notifyAction.addTransactionNotify({
                                    ...{
                                        id:
                                            moment().unix() +
                                            messages?.notification_object?.sender_external_id,
                                    },
                                    ...messages?.notification_object,
                                    ...{
                                        chat_uid:
                                            messages?.message_object?.chat_uid ||
                                            messages?.notification_object?.chat_uid,
                                    },
                                }),
                            );
                        }

                        if (messages?.action === SYNC.NOTIFY_MATCH_ACTION) {
                            if (
                                +userState?.info?.external_id ===
                                +messages?.notification_object?.recipient_external_id
                            ) {
                                if (
                                    chatsState?.chatList?.some(
                                        (item) =>
                                            item?.chat_uid === messages?.message_object?.chat_uid,
                                    )
                                ) {
                                    dispatch(
                                        chatsAction.updateMessageInChatList(
                                            messages?.message_object?.chat_uid,
                                            getLastMessageContent(
                                                messages?.message_object?.message_content,
                                                messages?.message_object?.message_type,
                                            ),
                                            messages?.message_object?.message_type,
                                            messages?.message_object?.date_created,
                                            messages?.message_object?.sender_external_id,
                                        ),
                                    );
                                }
                            }

                            if (
                                currentChatState?.info?.chat_uid ===
                                messages?.message_object?.chat_uid
                            ) {
                                if (
                                    !currentChatState?.history.find(
                                        (item) => item?.id === messages?.message_object?.id,
                                    )
                                ) {
                                    dispatch(currentChatAction.setIsNewMessage(true));
                                    dispatch(
                                        currentChatAction.addMessageToHistory(
                                            messages?.message_object,
                                        ),
                                    );
                                    dispatch(
                                        chatsAction.updateNewMess(
                                            userState?.info?.external_id,
                                            chatsState?.onlineStatus,
                                            chatsState?.searchInput,
                                            switchChatList(menuState?.sub),
                                            messages?.message_object?.chat_uid,
                                        ),
                                    );

                                    new ChatsApi()
                                        .postReadChat(messages?.message_object?.chat_uid)
                                        .finally(() => {
                                            if (userState?.info?.external_id) {
                                                dispatch(
                                                    countersAction.getChatCounter(
                                                        userState?.info?.external_id,
                                                    ),
                                                );
                                            }
                                        });
                                }
                            }
                        }
                    }

                    break;

                case SYNC.LIKE_ACTION:
                case SYNC.MESS_WINKED:
                case SYNC.MESS_ACTION:
                case SYNC.VIEW_ACTION:
                    if (messages?.dataForSocketLog) {
                        new TransactionApi().saveFrontSocketLog(messages?.dataForSocketLog);
                    }

                    if (
                        +userState?.info?.external_id ===
                        +messages?.message_object?.sender_external_id
                    ) {
                        if (messages?.action === SYNC.LIKE_ACTION) {
                            actionLike.callbackAfterLike(
                                messages?.message_object?.recipient_external_id,
                            );
                        }
                    }

                    // NOTIFICATION
                    if (
                        +userState?.info?.external_id ===
                        +messages?.notification_object?.recipient_external_id
                    ) {
                        makeNotificationSound();

                        dispatch(countersAction.getChatCounter(userState?.info?.external_id));

                        dispatch(
                            notifyAction.updateNotify(
                                {
                                    ...messages?.notification_object,
                                    ...{
                                        sender: +messages?.sender,
                                        message_id: +messages?.message_object?.id,
                                        status: +messages?.message_object?.status,
                                    },
                                    ...messages?.womanInfo,
                                },
                                messages?.action,
                            ),
                        );
                    }

                    // CHAT LIST
                    foundChat = chatsState?.chatList?.find(
                        (item) => item?.chat_uid === messages?.notification_object?.chat_uid,
                    );

                    if (
                        +userState?.info?.external_id ===
                            +messages?.notification_object?.recipient_external_id &&
                        messages?.action !== SYNC.VIEW_ACTION &&
                        messages?.message_object?.status
                    ) {
                        handleRedUser.submitLog(
                            USER.RED_USER_LOG_TYPES.MESSAGE_TO,
                            JSON.stringify({
                                womanExternalID: messages?.message_object?.sender_external_id,
                                action: messages?.message_object?.message_type,
                                content: messages?.message_object?.message_content,
                            }),
                        );

                        if (foundChat?.chat_uid) {
                            dispatch(
                                chatsAction.updateMessageInChatList(
                                    messages?.message_object?.chat_uid,
                                    getLastMessageContent(
                                        messages?.message_object?.message_content,
                                        messages?.message_object?.message_type,
                                    ),
                                    messages?.message_object?.message_type,
                                    messages?.message_object?.date_created,
                                    messages?.message_object?.sender_external_id,
                                ),
                            );
                        } else {
                            if (
                                menuState?.sub === MENU.MENU_FAVORITE ||
                                menuState?.sub === MENU.MENU_LIKE
                            ) {
                                //no added chat to chat list
                            } else {
                                dispatch(
                                    chatsAction.addNewChat({
                                        ...messages?.notification_object,
                                        ...messages?.message_object,
                                        ...messages?.chat_list_object,
                                        ...messages?.womanInfo,
                                        ...{
                                            id: messages?.notification_object?.sender_external_id,
                                            unread_count: 1,
                                            last_message_date: new Date().toUTCString(),
                                            last_message: getLastMessageContent(
                                                messages?.message_object?.message_content,
                                                messages?.message_object?.message_type,
                                            ),
                                        },
                                    }),
                                );
                            }
                        }
                    }

                    // CURRENT CHAT
                    if (
                        currentChatState?.info?.chat_uid === messages?.message_object?.chat_uid &&
                        messages?.action !== SYNC.VIEW_ACTION &&
                        messages?.message_object?.status
                    ) {
                        if (
                            !currentChatState.history.find(
                                (item) => item?.id === messages?.message_object?.id,
                            )
                        ) {
                            if (
                                SYNC.NOTIFY_ALL_MEDIA_LIST.some(
                                    (el) => el === messages?.message_object?.message_type,
                                )
                            ) {
                                dispatch(
                                    allMediaAction.updateAllMediaList(messages?.message_object),
                                );
                            }
                            dispatch(currentChatAction.setIsNewMessage(true));
                            dispatch(
                                currentChatAction.addMessageToHistory(messages?.message_object),
                            );
                            dispatch(
                                chatsAction.updateNewMess(
                                    userState?.info?.external_id,
                                    chatsState?.onlineStatus,
                                    chatsState?.searchInput,
                                    switchChatList(menuState?.sub),
                                    messages?.message_object?.chat_uid,
                                ),
                            );

                            new ChatsApi()
                                .postReadChat(messages?.message_object?.chat_uid)
                                .finally(() => {
                                    if (userState?.info?.external_id) {
                                        dispatch(
                                            countersAction.getChatCounter(
                                                userState?.info?.external_id,
                                            ),
                                        );
                                    }
                                });
                        }
                    }

                    break;

                case SYNC.POST_COMMENT_REPLY:
                    // NOTIFICATION
                    if (+userState?.info?.external_id === +messages?.external_id) {
                        makeNotificationSound();

                        // TODO: add effect after notify
                    }

                    break;

                case SYNC.SUBSCRIBE_ACTION:
                    dispatch(userAction.getInfo());
                    initSubscribe.initSubscribeStatus();
                    break;

                case SYNC.CREDITS_ACTION:
                    if (messages?.status) {
                        if (+userState?.abtest && !+userState?.info?.ghost) {
                            transactionConfig.fetchTransactionConfig();
                        }
                        successPaymentCallback({
                            count: +messages?.total_packages,
                            credits: messages?.credits,
                            price: +messages?.price,
                        });

                        handlePayment.getProposition();
                        handleRedUser.submitLog(
                            USER.RED_USER_LOG_TYPES.PAYMENT_SUCCESS,
                            JSON.stringify(messages),
                        );

                        if (
                            messages?.payment_method &&
                            !purchaseState.activeSubscribePaymentMethod
                        ) {
                            initSubscribe.switchGetSubscribeInfo(messages?.payment_method);
                        }

                        if (
                            [
                                PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING,
                                PURCHASE.PAYMENT_METHOD_PASTABANK_RECURRING,
                            ]?.includes(messages?.payment_method)
                        ) {
                            initSubscribe.initSubscribeStatus();
                            dispatch(loaderAction.setActiveGifLoader(false));
                            if (
                                !purchaseState?.activeSubscribePaymentMethod &&
                                !purchaseState?.activeSubscribeData
                            ) {
                                handleBilling.createBillingLog(BillingActionEnum.RECURRING_CREATE);
                                dispatch(purchaseActions.setSuccessSubscribeModal(true));
                            }
                        }
                    }

                    history.push('/chat');

                    break;

                case SYNC.CHANGE_3DS_SUBSCRIPTION:
                    if (
                        messages?.paymentMethod?.includes('RECURRING') &&
                        messages?.usersExternalId?.includes(userState?.info?.external_id || 0)
                    ) {
                        callbackChangeSubscription();
                    }
                    break;

                case SYNC.CHANGE_SUBSCRIPTION:
                    if (messages?.paymentMethod?.includes('RECURRING')) {
                        callbackChangeSubscription();
                    }
                    break;

                case SYNC.CREDITS_ISSUE:
                    if (
                        (Array.isArray(purchaseState?.lastPurchaseList) &&
                            +purchaseState?.lastPurchaseList?.[0]?.first === 1) ||
                        (Array.isArray(purchaseState?.purchaseList) &&
                            +purchaseState?.purchaseList?.[0]?.first === 1)
                    ) {
                        transition.addTail('?utm_credits_issue');
                    }

                    dispatch(userAction.getInfo());

                    if (purchaseState?.activePaymentMethod === 'PASTABANK') {
                        dispatch(purchaseAction.setIsPastaPaymentFailed(true, messages?.message));
                    }

                    dispatch(purchaseAction.setIsPaymentFailed(true, +messages?.count));
                    handleRedUser.submitLog(
                        USER.RED_USER_LOG_TYPES.PAYMENT_DECLINE,
                        JSON.stringify(messages),
                    );

                    if (!document.hidden && messages?.count === 0) {
                        pushEventTryPay();
                    }

                    break;

                case SYNC.CREDITS_CLOSE:
                    dispatch(userAction.isBuying(false));

                    break;

                case SYNC.CREDITS_UNAPPROVED:
                    dispatch(
                        alertAction.setMessage({
                            message: messages?.message,
                            title: 'Error!',
                        }),
                    );

                    break;

                case SYNC.ABADON_CART:
                    if (!modalsState?.showAddCreditCardModal) {
                        dispatch(userAction.getInfo());
                        dispatch(userAction.isBuying(false));
                    }
                    dispatch(modalsActions.closeAddCreditCard());

                    break;

                case SYNC.LESS_THEN_CREDITS:
                    try {
                        // eslint-disable-next-line no-case-declarations
                        const expDate = JSON.parse(localStorage.getItem('less-then') as string);
                        if (expDate === null || timeDiffFromMin(expDate))
                            dispatch(
                                modalsActions.openLessThenCreditsMessage({
                                    message: messages?.message,
                                    title: messages?.header,
                                    packId: messages?.package_id,
                                    time: messages?.less_then_time_in_minutes,
                                }),
                            );
                    } catch (error) {
                        sendErrToSentry(error as Error);
                    }

                    break;

                case SYNC.GA_EVENT:
                    sendBinomoFromStore({
                        externalID: userState?.info?.external_id,
                        siteID: userState?.info?.site_id,
                        status: USER.BINOMO_SPEND_FIRST_CREDITS,
                        clickid: userState?.clickid,
                        utm_aff_hash: userState?.utm_aff_hash,
                        ghost: userState?.info?.ghost,
                        email: userState?.info?.email,
                        tail: userState?.tail,
                    });
                    sendAfficeFromStore({
                        status: USER.AFFISE_SPEND_FIRST_CREDITS,
                        info: userState?.info,
                    });

                    if (messages?.message === 'spend-first-credit') {
                        dispatch(userAction.setUserSpendFirstCredit());

                        // This applies only to bonuses - free credits
                        if (!document.hidden && !+userState?.info?.payment_total) {
                            pushEventFirstCreditSpent();
                        }
                    }

                    break;

                case SYNC.TOKEN_ACTION:
                    if (+messages?.buyCredits) {
                        dispatch(purchaseAction.setIsBuyingPastabank(true));
                        break;
                    }

                    dispatch(purchaseActions.setIsAddCard(true));
                    dispatch(modalsActions.closeAddCreditCard());
                    dispatch(userAction.getInfo());
                    dispatch(userAction.isBuying(false));

                    break;

                case SYNC.ONLINE_STATUS:
                    if (messages?.externalIDList?.length) {
                        const isExistChatList =
                            chatsState?.chatList?.length &&
                            chatsState?.chatList?.some((item) =>
                                messages?.externalIDList?.includes(item?.external_id),
                            );
                        const isExistMailboxList =
                            mailboxState.mailList?.length &&
                            (mailboxState.mailList as IMailsFullInfo[])?.some((item) =>
                                messages?.externalIDList?.includes(item?.mail?.female_id),
                            );
                        const isExistDraftMailboxList =
                            mailboxState.mailList?.length &&
                            (mailboxState.mailList as IDraftsFullInfo[])?.some((item) =>
                                messages?.externalIDList?.includes(item?.draft?.recipient_id || 0),
                            );
                        const isExistCurrentChat = messages?.externalIDList?.includes(
                            currentChatState?.info?.external_id || 0,
                        );
                        const isExistCurrentChatMailbox = messages?.externalIDList?.includes(
                            currentChatMailboxState?.info?.female_id,
                        );
                        const isExistSearch =
                            searchState.list?.length &&
                            searchState.list?.some(
                                (item) =>
                                    'external_id' in item &&
                                    messages?.externalIDList?.includes(item?.external_id),
                            );
                        const isExistVideoShow =
                            videoShowState.list?.length &&
                            videoShowState.list?.some((item) =>
                                messages?.externalIDList?.includes(item?.external_id),
                            );
                        const isExistLikeHistory =
                            likesState.likeHistory?.length &&
                            likesState.likeHistory?.some((item) =>
                                messages?.externalIDList?.includes(item?.external_id),
                            );

                        if (isExistChatList) {
                            dispatch(
                                chatsAction.setChatListOnlineStatus(
                                    messages?.externalIDList,
                                    messages?.status,
                                ),
                            );
                        }

                        if (isExistMailboxList) {
                            dispatch(
                                mailboxAction.setMailboxListOnlineStatus(
                                    messages?.externalIDList,
                                    messages?.status,
                                ),
                            );
                        }

                        if (isExistDraftMailboxList) {
                            dispatch(
                                mailboxAction.setDraftMailboxListOnlineStatus(
                                    messages?.externalIDList,
                                    messages?.status,
                                ),
                            );
                        }

                        if (isExistCurrentChat) {
                            dispatch(
                                currentChatAction.setCurrentChatOnlineStatus(messages?.status),
                            );
                        }

                        if (isExistCurrentChatMailbox) {
                            dispatch(
                                currentChatMailboxAction.setCurrentChatOnlineStatus(
                                    messages?.status,
                                ),
                            );
                        }

                        if (isExistSearch) {
                            dispatch(
                                searchAction.setSearchOnlineStatus(
                                    messages?.externalIDList,
                                    messages?.status,
                                ),
                            );
                        }

                        if (isExistVideoShow) {
                            dispatch(
                                videoShowAction.setVideoShowOnlineStatus(
                                    messages?.externalIDList,
                                    messages?.status,
                                ),
                            );
                        }

                        if (isExistLikeHistory) {
                            dispatch(
                                likesAction.setLikeHistoryOnlineStatus(
                                    messages?.externalIDList,
                                    messages?.status,
                                ),
                            );
                        }
                    }

                    break;

                case SYNC.MAIL_ACTION:
                    handleRedUser.submitLog(
                        USER.RED_USER_LOG_TYPES.LETTER_TO,
                        JSON.stringify({
                            womanExternalID: messages?.external_id,
                            action: messages?.notification_object?.message_type,
                            content: messages?.notification_object?.message_content,
                        }),
                    );

                    if (+currentChatMailboxState?.info?.female_id === +messages?.external_id) {
                        dispatch(currentChatMailboxAction.setIsNewMessage(true));
                        if (userState?.info?.external_id) {
                            dispatch(countersAction.getLetterCounter(userState?.info?.external_id));
                        }
                    } else {
                        makeNotificationSound();
                        if (userState?.info?.external_id) {
                            dispatch(countersAction.getLetterCounter(userState?.info?.external_id));
                        }
                        dispatch(
                            notifyAction.updateMailNotify({
                                ...messages?.notification_object,
                                ...{
                                    date_created: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                                    date_expired:
                                        messages?.notification_object?.expired_at ||
                                        moment(generationDateOnFewMinutesLonger(3))
                                            .utc()
                                            .format('YYYY-MM-DD HH:mm:ss'),
                                    notification_type: 'mail',
                                    sender_external_id: +messages?.external_id,
                                },
                            }),
                        );
                    }

                    if (menuState?.letters === 'inbox' && +mailboxState?.mailListPage === 1) {
                        dispatch(
                            mailboxAction.getMailsWithoutReload({
                                userId: userState?.info?.external_id,
                                folder: menuState?.letters,
                                girlFilter: letterFilterState?.girlFilter,
                                girlId: letterFilterState?.girlId || '',
                                letterFilter: letterFilterState?.lettersFilter,
                                onlineFilter: letterFilterState?.onlineFilter,
                                page: 1,
                            }),
                        );
                    }

                    break;

                case SYNC.READ_MAIL_ACTION:
                    if (+currentChatMailboxState?.info?.female_id === +messages?.external_id) {
                        dispatch(currentChatMailboxAction.setReadStatus());
                    }

                    break;

                case SYNC.OPEN_CHAT:
                    if (currentChatState?.info?.chat_uid === messages?.chat_uid) {
                        dispatch(currentChatAction.setReadLastMessages());
                    }
                    dispatch(chatsAction.setReadLastMessagesFromYou(messages?.chat_uid));

                    break;

                case SYNC.NOTIFY_SETTINGS:
                    if (messages?.data) {
                        dispatch(notifyAction.setMobileBottomNotification(messages?.data));
                        dispatch(notifyAction.setMobilePushNotification(messages?.data));
                    }
                    break;

                case SYNC.AB_CONFIG_UPDATE:
                    if (+userState?.abtest && !+userState?.info?.ghost) {
                        transactionConfig.fetchTransactionConfig();
                    }

                    break;

                case SYNC.CONFIG_UPDATED:
                    if (messages) transactionConfig.fetchTransactionConfig();

                    break;

                case SYNC.SUPPORT_SWITCH_CALL_BUTTON:
                    if (+messages?.message?.id !== +userState?.info?.site_id) return;
                    if (messages)
                        dispatch(
                            settingsAction.setIsActiveCallButton(
                                messages?.message?.isActiveCallButton,
                            ),
                        );

                    break;

                case SYNC.GLOBAL_UPDATE:
                    localStorage.setItem('update', '1');

                    break;

                case SYNC.LOGOUT:
                    if (document?.hidden) logout.handleActionLogout();
                    break;

                case SYNC.QUIZ_ADD_PROMO_CREDITS:
                    dispatch(
                        alertAction.setMessage({
                            message:
                                'Thank you for the feedback. We are happy to try our best and improve our work for you!',
                            title: 'Your bonus credits have been accrued!',
                        }),
                    );
                    dispatch(userAction.setBalance());
                    break;

                case SYNC.ADD_BONUS_CREDITS:
                case SYNC.SUPPORT_ADD_BONUS_CREDITS:
                    if (SITE.ID === 23 && messages?.action === SYNC.ADD_BONUS_CREDITS) {
                        dispatch(
                            alertAction.setMessage({
                                title: 'Verification Complete',
                                message: `You are now a verified Premium user. Enjoy exclusive features and a trusted profile. Your ${messages?.message?.credits ?? ''} bonus credits have been added.`,
                            }),
                        );
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: `Your ${messages?.message?.credits ?? ''} bonus credits have been added! Have a nice chat`,
                            }),
                        );
                    }

                    dispatch(userAction.setBalance());
                    break;

                case SYNC.FRONT_SYNC_TRANSACTION:
                    if (
                        messages?.message &&
                        JSON?.stringify(transactionState?.transaction) !==
                            JSON?.stringify(messages?.message?.transaction)
                    ) {
                        dispatch(transactionAction.setTransaction(messages?.message?.transaction));
                    }
                    break;

                case SYNC.IMBRA_UPDATED:
                    if (messages?.messages) {
                        dispatch(userAction.setImbraData(messages?.messages));
                    }
                    break;

                case SYNC.UPDATE_TOKEN_LIST:
                    if (
                        messages?.payment_method === PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING
                    ) {
                        new AccountApi()
                            .postTokenList()
                            .then(
                                (res) =>
                                    res?.status &&
                                    dispatch(purchaseActions.setTokenList(res?.list)),
                            );
                    }

                    break;

                default:
                    break;
            }

            if (syncProvider && syncProvider.subscribe && syncProvider.addListener) {
                syncProvider.subscribe([
                    `counters_profile_${userState?.info?.external_id}`,
                    'woman_info_channel_0',
                ]);
                syncProvider.addListener(SYNC.MESSAGE_EVENT, setMessages);
            }
        }
    }, [messages, syncProvider, userState?.info?.external_id]);

    useEffect(() => {
        if (syncProvider)
            syncProvider.addConnection((status: string) =>
                dispatch(connectionAction.setConnection(status)),
            );
    }, [syncProvider]);
}
