import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as chatsAction from '../../store/actions/chats';
import * as MENU from '../../constants/menu';
import * as currentChatAction from '../../store/actions/currentChat';
import useTransition from '../transition/useTransition';
import * as modalsAction from '../../store/actions/modals';
import { closeMobChatList } from '../../services/dom';
import { getChatListType } from '../../services/methods/chat';
import { RootState } from '../../index';

export default function useHandleChatFilters() {
    const dispatch = useDispatch();
    const location = useLocation();
    const transition = useTransition(); // Tab Navigation

    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const chatsState = useSelector((state: RootState) => state.chats);

    // Method for removing characters from the search input.
    const deleteSearchInput = () => {
        if (!userState?.info?.external_id) return;

        dispatch(chatsAction.setSearchInput('')); // Set initial state of the search string.
        dispatch(
            chatsAction.getChatListByUserIdWithReload(
                userState?.info?.external_id,
                chatsState?.onlineStatus,
                '',
                getChatListType(location?.pathname),
                1,
            ),
        );
    };

    // Method for character-by-character search in the search input.
    const handleSearch = (value: string) => {
        dispatch(currentChatAction.removeMainChatInfo()); // Close current chat.
        dispatch(chatsAction.setSearchInput(value)); // Set store search input.

        if (menuState.main === MENU.MENU_BLOCK) {
            transition.transitionWithOnlyHistoryPush(`/my-profile/block_list`); // Navigate to block list
        } else {
            transition.transitionWithOnlyHistoryPush(
                `/${menuState?.sub?.length > 0 ? menuState?.sub : menuState?.main}`,
            );
        }
    };

    // Online status switch.
    const handleOnline = () => {
        dispatch(currentChatAction.removeMainChatInfo()); // Close current chat.
        dispatch(chatsAction.setOnlineStatus(!chatsState.onlineStatus)); // Set store online (false/true).

        if (menuState.main === MENU.MENU_BLOCK) {
            transition.transitionWithOnlyHistoryPush(`/my-profile/block_list`); // Navigate to block list
        } else {
            transition.transitionWithOnlyHistoryPush(
                `/${menuState?.sub?.length > 0 ? menuState?.sub : menuState?.main}`,
            );
        }
    };

    const closeMobileFiltersBlock = () => {
        closeMobChatList();
        dispatch(modalsAction.setActiveSubMenu());
    };

    return {
        closeMobileFiltersBlock,
        deleteSearchInput,
        handleSearch,
        handleOnline,
        searchInput: chatsState.searchInput,
        onlineStatus: chatsState.onlineStatus,
    };
}
