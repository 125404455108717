import React from 'react';
import styles from './styles.module.scss';
import IconCross from '../../../../Icons/IconCross';
import { IEmailResubscriptionProps } from './index.inerface';
import email_subscription from '../../../../../img/email_subscription.svg';

export default function EmailResubscription({
    closeRegisteredUserModal,
    sendEmailSubscription,
}: IEmailResubscriptionProps) {
    return (
        <div className={styles.email_resubscribe_popup} data-testid="email-resubscription-modal">
            <h3 className={styles.email_resubscribe_title} data-testid="title">
                Sign up for email newsletters
            </h3>
            <img
                className={styles.email_resubscribe_img}
                src={email_subscription ?? ''}
                alt="Email subscrtions"
                width="350"
                height="233"
            />
            <ul className={styles.email_resubscribe_text}>
                <li className={styles.email_resubscribe_text_item}>
                    <h4 className={styles.email_resubscribe_text_item_title}>Stay connected</h4>
                    <p>Get notifications about new likes, messages, and exciting matches.</p>
                </li>
                <li className={styles.email_resubscribe_text_item}>
                    <h4 className={styles.email_resubscribe_text_item_title}>
                        Personalized recommendations
                    </h4>
                    <p>Be the first to discover profiles that match your interests.</p>
                </li>
                <li className={styles.email_resubscribe_text_item}>
                    <h4 className={styles.email_resubscribe_text_item_title}>Exclusive offers</h4>
                    <p>Gain access to private deals, special discounts and free credits.</p>
                </li>
            </ul>
            <div className={styles.email_resubscribe_btn_wrap}>
                <button
                    className={`${styles.email_resubscribe_btn} ${styles.outline}`}
                    type="button"
                    onClick={closeRegisteredUserModal}
                >
                    <span>Close</span>
                </button>
                <button
                    className={styles.email_resubscribe_btn}
                    type="button"
                    onClick={sendEmailSubscription}
                >
                    <span>Confirm</span>
                </button>
            </div>

            <button
                type="button"
                className={styles.close_modal}
                onClick={closeRegisteredUserModal}
                data-testid="close-btn"
            >
                <IconCross />
            </button>
        </div>
    );
}
