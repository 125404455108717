import React from 'react';
import HeaderProfile from '../ProfileHeader';
import ChatList from '../../Chat/ChatList';
import Chat from '../../Chat/Chat';
import NotifyEmailConfirm from '../../Banner';
import useBLockListPage from '../../../hooks/blockList/useBLockListPage';

export default function ProfileBlockList() {
    useBLockListPage();

    return (
        <div className="column-3 profile_man">
            <NotifyEmailConfirm />
            <HeaderProfile />
            <div className="block-block-list">
                <ChatList />
                <Chat />
            </div>
        </div>
    );
}
