import React from 'react';

export default function IconCloudUpload() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.609 2.013C8.10822 0.720203 10.0204 0.00620777 12 0C16.035 0 19.3845 3 19.749 6.8685C22.137 7.206 24 9.2055 24 11.6595C24 14.3535 21.753 16.5 19.0305 16.5H15C14.8011 16.5 14.6103 16.421 14.4697 16.2803C14.329 16.1397 14.25 15.9489 14.25 15.75C14.25 15.5511 14.329 15.3603 14.4697 15.2197C14.6103 15.079 14.8011 15 15 15H19.032C20.9685 15 22.5 13.482 22.5 11.6595C22.5 9.8355 20.97 8.3175 19.0305 8.3175H18.2805V7.5675C18.282 4.2375 15.492 1.5 12 1.5C10.3798 1.50647 8.81526 2.09165 7.5885 3.15C6.453 4.128 5.859 5.307 5.859 6.2325V6.9045L5.1915 6.978C3.096 7.2075 1.5 8.928 1.5 10.977C1.5 13.1775 3.345 15 5.6715 15H9C9.19891 15 9.38968 15.079 9.53033 15.2197C9.67098 15.3603 9.75 15.5511 9.75 15.75C9.75 15.9489 9.67098 16.1397 9.53033 16.2803C9.38968 16.421 9.19891 16.5 9 16.5H5.6715C2.562 16.5 0 14.049 0 10.977C0 8.3325 1.899 6.1425 4.413 5.5875C4.6275 4.293 5.46 3.003 6.609 2.013Z" />
            <path d="M11.469 5.71997C11.5386 5.65013 11.6214 5.59472 11.7125 5.55691C11.8036 5.5191 11.9013 5.49963 12 5.49963C12.0986 5.49963 12.1963 5.5191 12.2874 5.55691C12.3785 5.59472 12.4613 5.65013 12.531 5.71997L17.031 10.22C17.1718 10.3608 17.2509 10.5518 17.2509 10.751C17.2509 10.9501 17.1718 11.1411 17.031 11.282C16.8901 11.4228 16.6991 11.5019 16.5 11.5019C16.3008 11.5019 16.1098 11.4228 15.969 11.282L12.75 8.06147V21.251C12.75 21.4499 12.671 21.6407 12.5303 21.7813C12.3896 21.922 12.1989 22.001 12 22.001C11.8011 22.001 11.6103 21.922 11.4696 21.7813C11.329 21.6407 11.25 21.4499 11.25 21.251V8.06147L8.03097 11.282C7.89014 11.4228 7.69913 11.5019 7.49997 11.5019C7.30081 11.5019 7.1098 11.4228 6.96897 11.282C6.82814 11.1411 6.74902 10.9501 6.74902 10.751C6.74902 10.5518 6.82814 10.3608 6.96897 10.22L11.469 5.71997Z" />
        </svg>
    );
}
