import Base from './Base';
import SITE from '../../config/site';
import { IMediaUploadRequestData, IUpdateSortOrderList } from './FilesApi.interface';

export default class FilesAPI extends Base {
    async getAllImages() {
        const sortParams = SITE.ID === 23 ? '?sort_field=date_created&sort_direction=DESC' : '';
        const res = await super.get(`files/images${sortParams}`);
        return res?.data;
    }

    async getAllVideos() {
        const sortParams = SITE.ID === 23 ? '?sort_field=date_created&sort_direction=DESC' : '';
        const res = await super.get(`files/videos${sortParams}`);
        return res?.data;
    }

    async getAllAudios() {
        const sortParams = SITE.ID === 23 ? '?sort_field=date_created&sort_direction=DESC' : '';
        const res = await super.get(`files/audios${sortParams}`);
        return res?.data;
    }

    async postImage(data: IMediaUploadRequestData) {
        const res = await super.post(`files/image`, data);
        return res?.data;
    }

    async postVideo(data: IMediaUploadRequestData) {
        const res = await super.post(`files/video`, data);
        return res?.data;
    }

    async postAudio(data: IMediaUploadRequestData) {
        const res = await super.post(`files/audio`, data);
        return res?.data;
    }

    async postDeleteMedia(id: number, userId: number) {
        const res = await super.post(`files/deleteMedia`, {
            id: id,
            user_id: userId,
        });
        return res?.data;
    }

    async postUpdateSortOrder(contentType: string, list: IUpdateSortOrderList[]) {
        const res = await super.post(`files/updateSortOrder`, {
            content_type: contentType,
            list: list,
        });
        return res?.data;
    }
}
