import Base from './Base';
import { ICheckAffOfferRequestData } from './TailApi.interface';

export default class TailApi extends Base {
    async checkAffOffer({ external_id, utm_aff_hash, device, status }: ICheckAffOfferRequestData) {
        const res = await super.post(`tail/checkAffOffer`, {
            external_id,
            utm_aff_hash,
            device,
            status,
        });
        return res?.data;
    }
}
