import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import EmailResubscription from './EmailResubscription';
import useEmailResubscription from '../../../../hooks/emailResubscription/useEmailResubscription';
import EmailResubscriptionSpecial from './EmailResubscriptionSpecial';

export default function EmailResubscriptionModal() {
    const {
        isEmailResubscription,
        isEmailResubscriptionSpecial,
        closeRegisteredUserModal,
        closeResubscriptionSpecialModal,
        sendEmailSubscription,
        bonusData,
    } = useEmailResubscription();

    if (isEmailResubscription) {
        return (
            <Modal
                style={modalStyleProps()}
                isOpen={isEmailResubscription}
                onRequestClose={closeRegisteredUserModal}
                ariaHideApp={false}
            >
                <EmailResubscription
                    closeRegisteredUserModal={closeRegisteredUserModal}
                    sendEmailSubscription={sendEmailSubscription}
                />
            </Modal>
        );
    }

    if (isEmailResubscriptionSpecial) {
        return (
            <Modal
                style={modalStyleProps()}
                isOpen={isEmailResubscriptionSpecial}
                onRequestClose={closeResubscriptionSpecialModal}
                ariaHideApp={false}
            >
                <EmailResubscriptionSpecial
                    closeRegisteredUserModal={closeResubscriptionSpecialModal}
                    sendEmailSubscription={sendEmailSubscription}
                    bonusData={bonusData}
                />
            </Modal>
        );
    }

    return null;
}
