import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import circleImg from '../../img/akar-icons_circle-chevron-down.png';
import PaymentMethodList from './PaymentMethodList';
import PaymentPackageList from './PaymentPackageList';
import PaymentFrame from './PaymentFrame';
import PaymentManageCreditCard from './PaymentManageCreditCard';
import * as purchaseAction from '../../store/actions/purchase';
import * as userAction from '../../store/actions/user';
import useInitPaymentManageCreditCard from '../../hooks/purchase/useInitPaymentManageCreditCard';
import useInitPaymentMethodList from '../../hooks/purchase/useInitPaymentMethodList';
import useInitPaymentPackageList from '../../hooks/purchase/useInitPaymentPackageList';
import useInitSubscribe from '../../hooks/purchase/subscribe/useInitSubscribe';
import { getLegalEntityInfo } from '../../services/methods/purchase';
import useHandleBilling from '../../hooks/billing/useHandleBilling';
import useHandlePayment from '../../hooks/purchase/useHandlePayment';
import useHandleZendesk from '../../hooks/ze/useHandleZendesk';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../index';
import { BillingActionEnum } from '../../services/api/v3/BillingApi.interface';
import useBuyCredits from '../../hooks/purchase/useBuyCredits';
import IconFAQQuestionBackArrow from '../../components/Icons/IconFAQQuestionBackArrow';

export default function CheckoutPage() {
    const userState = useSelector((state: RootState) => state.user);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const dispatch = useDispatch();
    const history = useHistory();
    const handleBilling = useHandleBilling();
    const initPaymentMethodList = useInitPaymentMethodList();
    const initPaymentPackageList = useInitPaymentPackageList();
    const initSubscribe = useInitSubscribe();
    const handlePayment = useHandlePayment();
    const { zendeskPaymentFailed } = useHandleZendesk();
    const { buyCreditsByActivePackage } = useBuyCredits();
    const handleActiveBlocks = useHandleActiveBlocks();
    const initPaymentManageCreditCard = useInitPaymentManageCreditCard({
        switchGetSubscribeInfo: initSubscribe.switchGetSubscribeInfo,
    });

    const priceToFixed = () => {
        return purchaseState?.lastBuyPackage?.price?.toFixed(2);
    };

    const paymentModalClose = () => {
        dispatch(purchaseAction.setIsPastaPaymentFailed(false));
        dispatch(userAction.isBuying(false));
    };

    const onClose = () => {
        if (userState?.isBuying) paymentModalClose();
        else history.push('/chat');
    };

    useEffect(() => {
        handlePayment.getProposition();
        handleBilling.createBillingLog(BillingActionEnum.OPEN_PAYMENT_PAGE);

        return () => {
            handleBilling.createBillingLog(BillingActionEnum.CLOSE_PAYMENT_PAGE);
        };
    }, []);

    useEffect(() => {
        initPaymentMethodList.effectActivePaymentMethodList();
    }, [purchaseState.activePaymentMethodList]);

    useEffect(() => {
        initPaymentMethodList.effectActivePaymentMethod();
    }, [purchaseState.activePaymentMethod]);

    return (
        <div className={styles.payment_modal_fixed}>
            <div
                className={`${styles.payment_modal_wrap} ${handleActiveBlocks?.isNewPaymentPackages ? styles.payment_modal_diamond : ''} ${userState?.isBuying ? styles.frame_open : ''}`}
            >
                <div
                    className={`${styles.payment_modal_close} ${userState?.isBuying ? styles.back : ''}`}
                    onClick={onClose}
                    data-testid="close-payment-btn"
                >
                    <IconFAQQuestionBackArrow />
                    <span>Go back to credit packs</span>
                </div>
                <div className={styles.new_payment_balance} data-testid="user-balance-block">
                    Your balance
                    <div
                        className={styles.new_payment_balance_icon}
                        data-testid="balance-image"
                    ></div>
                    <span data-testid="balance">{userState?.balance} credits</span>
                </div>
                {userState?.isBuying && (
                    <div className={styles.new_payment_buying_package_info}>
                        <div>
                            Credits amount: <span>{purchaseState?.lastBuyPackage?.credits}</span>
                        </div>
                        <span>|</span>
                        <div>
                            Total payment: <span>${priceToFixed()}</span>
                        </div>
                    </div>
                )}

                <div
                    className={`${styles.new_payment_row} ${userState?.isBuying ? styles.frame_open : ''}`}
                >
                    <div className={styles.new_payment_left}>
                        <p
                            className={`${styles.new_payment_left_title} ${styles.new_payment_title}`}
                        >
                            Choose desirable package
                        </p>
                    </div>
                    <div className={`${styles.new_payment_right}`}>
                        <p
                            className={`${styles.new_payment_right_title} ${styles.new_payment_title} ${styles.pc}`}
                        >
                            Choose payment method
                        </p>
                        <div
                            className={`${styles.new_payment_right_title} ${styles.new_payment_title} ${styles.mob}`}
                        >
                            Choose <br />
                            how to pay
                        </div>
                    </div>
                </div>
                {!!purchaseState?.isPaymentFailed && (
                    <>
                        <div className={styles.payment_error_wrapper}>
                            <div className={styles.payment_error_left}>
                                <b>Your payment has failed.</b> Please, try again or choose another
                                payment method
                            </div>
                            <div className={styles.payment_error_right}>
                                <b>Please, choose another method</b> <img src={circleImg} alt="" />
                            </div>
                        </div>

                        {purchaseState?.packageBuyCount === 0 && (
                            <div
                                className={styles.payment_error_support_btn}
                                onClick={zendeskPaymentFailed}
                            >
                                Get help from support
                            </div>
                        )}
                    </>
                )}

                <div
                    className={`${styles.new_payment_row} ${userState?.isBuying ? styles.frame_open : ''}`}
                >
                    <div className={styles.new_payment_left}>
                        <div className={styles.new_payment_body}>
                            {userState?.isBuying ? (
                                <PaymentFrame
                                    paymentData={initPaymentPackageList.paymentData}
                                    frameFormData={initPaymentPackageList.frameFormData}
                                    frameLink={initPaymentPackageList.frameLink}
                                    isLoadedPaymentFrame={
                                        initPaymentPackageList.isLoadedPaymentFrame
                                    }
                                    clientAcquiringToken={
                                        initPaymentPackageList.clientAcquiringToken
                                    }
                                    clientAcquiringApplePayToken={
                                        initPaymentPackageList.clientAcquiringApplePayToken
                                    }
                                    clientAcquiringGooglePayToken={
                                        initPaymentPackageList.clientAcquiringGooglePayToken
                                    }
                                    getDefaultCreditCard={
                                        initPaymentMethodList.getDefaultCreditCard
                                    }
                                    getDefaultCreditCardData={
                                        initPaymentMethodList.getDefaultCreditCardData
                                    }
                                    openManageCreditCardModal={
                                        initPaymentManageCreditCard.openManageCreditCardModal
                                    }
                                />
                            ) : (
                                !!purchaseState?.activePackage && (
                                    <PaymentPackageList
                                        onSetActiveSubscribe={initSubscribe.onSetActiveSubscribe}
                                    />
                                )
                            )}
                        </div>
                    </div>
                    {purchaseState.activePaymentMethod && (
                        <PaymentMethodList
                            isLoadedPaymentFrame={initPaymentPackageList.isLoadedPaymentFrame}
                            openManageCreditCardModal={
                                initPaymentManageCreditCard.openManageCreditCardModal
                            }
                            getDefaultCreditCard={initPaymentMethodList.getDefaultCreditCard}
                            getCreditCardList={initPaymentMethodList.getCreditCardList}
                            isAvailablePaymentMethod={
                                initPaymentMethodList.isAvailablePaymentMethod
                            }
                            setActivePaymentMethodWithClick={
                                initPaymentMethodList.setActivePaymentMethodWithClick
                            }
                        />
                    )}
                </div>
                {!userState?.isBuying && (
                    <div
                        className={styles.new_payment_get_credit_mob_btn}
                        onClick={buyCreditsByActivePackage}
                    >
                        Get {purchaseState?.activePackage?.credits} credit pack
                    </div>
                )}
            </div>

            {!!+getLegalEntityInfo()?.length && (
                <div className={styles.legal_entity_info}>{getLegalEntityInfo()}</div>
            )}

            <PaymentManageCreditCard
                isActiveManageCreditCard={initPaymentManageCreditCard.isActiveManageCreditCard}
                isActiveNoCreditCardModal={initPaymentManageCreditCard.isActiveNoCreditCardModal}
                isActiveCreditCardListModal={
                    initPaymentManageCreditCard.isActiveCreditCardListModal
                }
                isActiveAddCreditCardModal={initPaymentManageCreditCard.isActiveAddCreditCardModal}
                closeNoCreditCardModal={initPaymentManageCreditCard.closeNoCreditCardModal}
                createToken={initPaymentManageCreditCard.createToken}
                closeCreditCardListModal={initPaymentManageCreditCard.closeCreditCardListModal}
                closeAddCreditCardModal={initPaymentManageCreditCard.closeAddCreditCardModal}
                fetchTokenList={initPaymentManageCreditCard.fetchTokenList}
                openRemoveCreditCardAlert={initPaymentManageCreditCard.openRemoveCreditCardAlert}
                selectDefaultCard={initPaymentManageCreditCard.selectDefaultCard}
                frame={initPaymentManageCreditCard.frame}
                frameData={initPaymentManageCreditCard.frameData}
            />
        </div>
    );
}
