// TODO: Verify if c3lh_search_filters_list_wrap2_title and c3lh_search_filters_list_wrap2_close can be safely removed as they are always set to display: none.

import React, { useState } from 'react';
import DoubleSelect from '../../select/DoubleSelect';
import LargeSelect from '../../select/LargeSelect';
import * as DICTIONARY_CREATE_PROFILE_PAGE from '../../../../constants/dictionary/create-profile-page';
import SearchInput from '../../SearchInput';
import SelectAge from '../../../search/SearchFilterAge/SelectAge';
import SearchFilterOnline from '../../../search/SearchFilterOnline';
import { closePanel, openFilterSearch } from '../../../../services/dom';
import * as MENU from '../../../../constants/menu';
import SearchCountry from '../../../search/SearchCountry';
import SearchCity from '../../../search/SearchCity';
import SITE from '../../../../config/site';
import { ISearchToolbarProps } from './index.interface';
import IconFilter from '../../../Icons/IconFilter';
import IconCross from '../../../Icons/IconCross';

export default function SearchToolbar({
    submitSearch,
    bodyList,
    eyeList,
    hairList,
    religionList,
    childrenList,
    moreChildrenList,
    alcoholList,
    smokeList,
    educationList,
    occupationList,
    reasonList,
    maritalList,
    heightList,
    fetchTmpData,
    formDataLooking,
    setFormDataLooking,
    handleSearchName,
    handleOnline,
    online,
    ageFromFilter,
    setAgeFromFilter,
    ageToFilter,
    setAgeToFilter,
    countryFilter,
    handleCountryFilter,
    inputCityRef,
    cityFilter,
    handleCityFilter,
    cityList,
    nameFilter,
}: ISearchToolbarProps) {
    const [openAgeFromBox, setOpenAgeFromBox] = useState<boolean>(false);
    const [openAgeToBox, setOpenAgeToBox] = useState<boolean>(false);
    const [openHeightFromBox, setOpenHeightFromBox] = useState<boolean>(false);
    const [openHeightToBox, setOpenHeightToBox] = useState<boolean>(false);
    const [openBodyTypeBox, setOpenBodyTypeBox] = useState<boolean>(false);
    const [openEyeBox, setOpenEyeBox] = useState<boolean>(false);
    const [openReligionBox, setOpenReligionBox] = useState<boolean>(false);
    const [openChildNumBox, setOpenChildNumBox] = useState<boolean>(false);
    const [openChildMoreBox, setOpenChildMoreBox] = useState<boolean>(false);
    const [openAlcoholBox, setOpenAlcoholBox] = useState<boolean>(false);
    const [openSmokingBox, setOpenSmokingBox] = useState<boolean>(false);
    const [openEducationBox, setOpenEducationBox] = useState<boolean>(false);
    const [openJobBox, setOpenJobBox] = useState<boolean>(false);
    const [openHobbyBox, setOpenHobbyBox] = useState<boolean>(false);
    const [openHairBox, setOpenHairBox] = useState<boolean>(false);
    const [openMaritalBox, setOpenMaritalBox] = useState<boolean>(false);
    const [openPurposeBox, setOpenPurposeBox] = useState<boolean>(false);

    const closeAllSelects = () => {
        if (window.innerWidth < 1000) openFilterSearch();

        if (
            openAgeFromBox ||
            openAgeToBox ||
            openHeightFromBox ||
            openHeightToBox ||
            openBodyTypeBox ||
            openEyeBox ||
            openReligionBox ||
            openChildNumBox ||
            openChildMoreBox ||
            openAlcoholBox ||
            openSmokingBox ||
            openEducationBox ||
            openJobBox ||
            openHobbyBox ||
            openPurposeBox ||
            openHairBox ||
            openMaritalBox
        ) {
            setOpenAgeFromBox(false);
            setOpenAgeToBox(false);
            setOpenHeightFromBox(false);
            setOpenHeightToBox(false);
            setOpenBodyTypeBox(false);
            setOpenEyeBox(false);
            setOpenReligionBox(false);
            setOpenChildNumBox(false);
            setOpenChildMoreBox(false);
            setOpenAlcoholBox(false);
            setOpenSmokingBox(false);
            setOpenEducationBox(false);
            setOpenJobBox(false);
            setOpenHobbyBox(false);
            setOpenHairBox(false);
            setOpenMaritalBox(false);
            setOpenPurposeBox(false);
        }
    };

    return (
        <>
            <div className="c3lh_search_filters_list_wrap2_title">
                <IconFilter />
                <span>All filters</span>
            </div>
            <div
                className="c3lh_search_filters_list_wrap2_close"
                onClick={() => {
                    submitSearch();
                    closePanel();
                }}
            >
                <IconCross />
            </div>
            <div className="c3lh_search_filters_list_wrap2" onClick={closeAllSelects}>
                <div className="c3lh_search_range_wrap">
                    <span className="c3lh_search_range_title">
                        <span>Member’s </span>age range
                    </span>
                    <div className="c3lh_search_range_selects_wrap">
                        <SelectAge
                            opened={openAgeFromBox}
                            handleOpen={setOpenAgeFromBox}
                            value={
                                ageFromFilter ??
                                formDataLooking?.AGE_FROM?.id ??
                                formDataLooking?.AGE_FROM
                            }
                            defaultValue="From"
                            handleChange={(item: number | string) => {
                                const ageFrom = +item;
                                const ageTo = +ageToFilter;
                                if (
                                    (ageFrom > ageTo || !!(isNaN(ageFrom) && !isNaN(ageTo))) &&
                                    +ageTo !== 0
                                )
                                    return false;

                                if (window.innerWidth < 1000) openFilterSearch();

                                if (setAgeFromFilter) setAgeFromFilter(item);
                                else
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ AGE_FROM: { id: item } },
                                    });

                                setOpenAgeFromBox(false);
                            }}
                        />

                        <SelectAge
                            opened={openAgeToBox}
                            handleOpen={setOpenAgeToBox}
                            value={
                                ageToFilter ?? formDataLooking.AGE_TO?.id ?? formDataLooking.AGE_TO
                            }
                            defaultValue="To"
                            handleChange={(item) => {
                                const ageFrom = +ageFromFilter;
                                const ageTo = +item;
                                if (
                                    (ageFrom > ageTo || !!(isNaN(ageFrom) && !isNaN(ageTo))) &&
                                    ageTo !== 0
                                )
                                    return false;

                                if (window.innerWidth < 1000) openFilterSearch();

                                if (setAgeToFilter) setAgeToFilter(item);
                                else
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ AGE_TO: { id: item } },
                                    });

                                setOpenAgeToBox(false);
                            }}
                        />
                    </div>
                </div>
                {window?.innerWidth < 1140 && (
                    <div className="c3lh_search_filters_list_mob_top">
                        <SearchInput handleSearch={handleSearchName} value={nameFilter} />
                        {SITE.ID !== 4 && (
                            <SearchFilterOnline handleChange={handleOnline} selected={online} />
                        )}
                    </div>
                )}

                {SITE.ID !== 4 && (
                    <>
                        {window.location.href.includes(MENU.MENU_SEARCH) && (
                            <div className="c3lh_search_filter_item">
                                <SearchCountry
                                    handleCountryFilter={handleCountryFilter}
                                    initInputValue={countryFilter}
                                />
                            </div>
                        )}

                        {window.location.href.includes(MENU.MENU_SEARCH) && (
                            <div className={`c3lh_search_filter_item city_item`}>
                                <SearchCity
                                    inputCityRef={inputCityRef}
                                    handleCityFilter={handleCityFilter}
                                    cityList={cityList}
                                    initInputValue={cityFilter}
                                />
                            </div>
                        )}

                        <div className="c3lh_search_filter_item">
                            <DoubleSelect
                                index="height"
                                formDataFrom={formDataLooking.HEIGHT_FROM}
                                formDataTo={formDataLooking.HEIGHT_TO}
                                handleClickFrom={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ HEIGHT_FROM: item },
                                    });
                                    setOpenHeightFromBox(false);
                                }}
                                handleClickTo={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ HEIGHT_TO: item },
                                    });
                                    setOpenHeightToBox(false);
                                }}
                                arrayFieldsFrom={heightList}
                                arrayFieldsTo={heightList}
                                defaultNameFrom={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_FROM}
                                defaultNameTo={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_TO}
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_HEIGHT}
                            />
                        </div>

                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="body"
                                formData={
                                    formDataLooking.BODY && formDataLooking.BODY.body
                                        ? formDataLooking.BODY.body
                                        : formDataLooking.BODY
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({ ...formDataLooking, ...{ BODY: item } });
                                    setOpenBodyTypeBox(false);
                                }}
                                arrayFields={bodyList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.BODY
                                        ? fetchTmpData.BODY
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_BODY}
                                dataTestId="body"
                            />
                        </div>

                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="eye"
                                formData={
                                    formDataLooking.EYE && formDataLooking.EYE.eye
                                        ? formDataLooking.EYE.eye
                                        : formDataLooking.EYE
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({ ...formDataLooking, ...{ EYE: item } });
                                    setOpenEyeBox(false);
                                }}
                                arrayFields={eyeList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.EYE
                                        ? fetchTmpData.EYE
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_EYE}
                                dataTestId="eye"
                            />
                        </div>

                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="occupation"
                                formData={
                                    formDataLooking.OCCUPATION &&
                                    formDataLooking.OCCUPATION.occupation
                                        ? formDataLooking.OCCUPATION.occupation
                                        : formDataLooking.occupation_id
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ OCCUPATION: item },
                                    });
                                    setOpenJobBox(false);
                                }}
                                arrayFields={occupationList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.occupation
                                        ? fetchTmpData.occupation
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_JOB}
                                dataTestId="occupation"
                            />
                        </div>

                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="hair"
                                formData={
                                    formDataLooking.HAIR && formDataLooking.HAIR.hair
                                        ? formDataLooking.HAIR.hair
                                        : formDataLooking.HAIR
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({ ...formDataLooking, ...{ HAIR: item } });
                                    setOpenHairBox(false);
                                }}
                                arrayFields={hairList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.HAIR
                                        ? fetchTmpData.HAIR
                                        : DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_CHOOSE
                                }
                                selectTitle={
                                    DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_HAIR
                                }
                                dataTestId="hair"
                            />
                        </div>

                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="marital"
                                formData={
                                    formDataLooking.STATUS && formDataLooking.STATUS.marital
                                        ? formDataLooking.STATUS.marital
                                        : formDataLooking.STATUS
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({ ...formDataLooking, ...{ STATUS: item } });
                                    setOpenMaritalBox(false);
                                }}
                                arrayFields={maritalList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.marital
                                        ? fetchTmpData.marital
                                        : DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_CHOOSE
                                }
                                selectTitle={
                                    DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_MARITAL
                                }
                                dataTestId="marital"
                            />
                        </div>
                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="education"
                                formData={
                                    formDataLooking.EDUCATION && formDataLooking.EDUCATION.education
                                        ? formDataLooking.EDUCATION.education
                                        : formDataLooking.EDUCATION
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ EDUCATION: item },
                                    });
                                    setOpenEducationBox(false);
                                }}
                                arrayFields={educationList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.education
                                        ? fetchTmpData.education
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={
                                    DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_EDUCATION
                                }
                                dataTestId="education"
                            />
                        </div>

                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="reason"
                                formData={
                                    formDataLooking.PURPOSE && formDataLooking.PURPOSE.reason
                                        ? formDataLooking.PURPOSE.reason
                                        : formDataLooking.PURPOSE
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ PURPOSE: item },
                                    });
                                    setOpenPurposeBox(false);
                                }}
                                arrayFields={reasonList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.reason
                                        ? fetchTmpData.reason
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_PURSOSE}
                                dataTestId="reason"
                            />
                        </div>

                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="religion"
                                formData={
                                    formDataLooking.RELIGION && formDataLooking.RELIGION.religion
                                        ? formDataLooking.RELIGION.religion
                                        : formDataLooking.religion_id
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ RELIGION: item },
                                    });
                                    setOpenReligionBox(false);
                                }}
                                arrayFields={religionList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.religion
                                        ? fetchTmpData.RELIGION
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_RELIGION}
                                dataTestId="religion"
                            />
                        </div>
                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="children"
                                formData={
                                    formDataLooking.CHILDREN && formDataLooking.CHILDREN.children
                                        ? formDataLooking.CHILDREN.children
                                        : formDataLooking.CHILDREN
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ CHILDREN: item },
                                    });
                                    setOpenChildNumBox(false);
                                }}
                                arrayFields={childrenList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.CHILDREN
                                        ? fetchTmpData.CHILDREN
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={
                                    DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_NUM_CHILD
                                }
                                dataTestId="count-children"
                            />
                        </div>
                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="children"
                                formData={
                                    formDataLooking.MORECHILDREN &&
                                    formDataLooking.MORECHILDREN.children
                                        ? formDataLooking.MORECHILDREN.children
                                        : formDataLooking.MORECHILDREN
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ MORECHILDREN: item },
                                    });
                                    setOpenChildMoreBox(false);
                                }}
                                arrayFields={moreChildrenList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.MORECHILDREN
                                        ? fetchTmpData.MORECHILDREN
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={
                                    DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_MORE_CHILD
                                }
                                dataTestId="more-children"
                            />
                        </div>
                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="alcohol"
                                formData={
                                    formDataLooking.ALCOHOL && formDataLooking.ALCOHOL.alcohol
                                        ? formDataLooking.ALCOHOL.alcohol
                                        : formDataLooking.ALCOHOL
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ ALCOHOL: item },
                                    });
                                    setOpenAlcoholBox(false);
                                }}
                                arrayFields={alcoholList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.alcohol
                                        ? fetchTmpData.alcohol
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_ALCOHOL}
                                dataTestId="alcohol"
                            />
                        </div>
                        <div className="c3lh_search_filter_item">
                            <LargeSelect
                                index="smoke"
                                formData={
                                    formDataLooking.SMOKING && formDataLooking.SMOKING.smoke
                                        ? formDataLooking.SMOKING.smoke
                                        : formDataLooking.SMOKING
                                }
                                handleClick={(item) => {
                                    setFormDataLooking({
                                        ...formDataLooking,
                                        ...{ SMOKING: item },
                                    });
                                    setOpenSmokingBox(false);
                                }}
                                arrayFields={smokeList}
                                defaultName={
                                    fetchTmpData && fetchTmpData.smoke
                                        ? fetchTmpData.smoke
                                        : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                                }
                                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_SMOKING}
                                dataTestId="smoke"
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="c3lh_search_filters_list_mobile_info">
                All filters are applied automatically
            </div>
        </>
    );
}
