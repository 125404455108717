import React from 'react';
import * as AFFILIATS from '../../../../constants/affiliats';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Modal from 'react-modal';
import { IAffiliatsModalProps } from './index.interface';

export default function AffiliatsModal({
    onSubmit,
    validationContacts,
    validationName,
    validationMessage,
    handleContactsInput,
    handleNameInput,
    handleMessageInput,
    messageContent,
    contacts,
    closeAffiliatsModal,
    firstname,
    type = '',
    modalSupportIsOpen,
}: IAffiliatsModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={modalSupportIsOpen}
            onRequestClose={closeAffiliatsModal}
            ariaHideApp={false}
        >
            <div className="popup_support_wrap">
                <div className="popup_support_body">
                    <div className="popup_support_head">For Affiliats</div>
                    {type === AFFILIATS.MODAL_TYPE_AFFILIATS && (
                        <>
                            <div className="popup_support_desc">
                                To start working with us, please fill form
                            </div>
                            <input
                                className={`popup_support_email_input  ${validationContacts ? 'invalid' : ''}`}
                                type="text"
                                name="contacts"
                                required
                                placeholder="Contacts"
                                value={contacts}
                                onChange={handleContactsInput}
                            />
                            <input
                                className={`popup_support_email_input ${validationName ? 'invalid' : ''}`}
                                type="text"
                                name="firstname"
                                required
                                placeholder="Name"
                                value={firstname}
                                onChange={handleNameInput}
                            />
                        </>
                    )}
                    {/*<div className="popup_support_title">Your suggestions</div>*/}
                    <textarea
                        className={`popup_support_textarea ${validationMessage ? 'invalid' : ''}`}
                        name="messageContent"
                        value={messageContent}
                        onChange={handleMessageInput}
                        placeholder="Your suggestions"
                    />
                    {/* <div className="popup_support_send_info_hr"/> */}
                    <button type="button" className="popup_support_send_btn" onClick={onSubmit}>
                        <span className="popup_support_send_btn_text">Send</span>
                    </button>
                    <button
                        type="button"
                        className="popup_complaint_close"
                        onClick={closeAffiliatsModal}
                    ></button>
                </div>
            </div>
        </Modal>
    );
}
