import Base from './Base';
import * as USER from '../../constants/user';

export default class BinomoApi extends Base {
    getBinomo(cnv_status: string, clickid: string) {
        const eventIndex = +USER.BINOMO_EVENT?.indexOf(cnv_status) + 1;
        // const eventData = eventIndex === 10 ? 11 : eventIndex;
        const addStatusTail = USER.BINOMO_EVENT?.includes(cnv_status)
            ? `&cnv_status={cnv_status}&event${eventIndex}=1`
            : `&cnv_status=${cnv_status}`;

        return super.getThirdParty(
            `https://binom.love-2-date.com/click.php?cnv_id=${clickid}&payout={payout}${addStatusTail}`,
        );
    }

    //GT-11242 Api crix
    sendEventCrix(clickid: string | null, amount: number) {
        return super.getThirdParty(
            `https://optimizer.brmediatrk.com/sale?cid=${clickid}&amount=${amount}`,
        );
    }
}
