export const LOGIN_BUTTON = 'Log in';
export const LOGIN_FORGET_PASSWORD = 'Forget a password?';
export const LOGIN_FAST = 'Log in fast:';
export const FORGET_BUTTON = 'Reset password';
export const SET_PASSWORD_BUTTON = 'Set password';

// export const EDIT_EMAIL_MESSAGE_WITHOUT_EMAIL = 'You didn\'t provide your email during registration. Please enter your valid email and after confirmation you will get free credits';
export const EDIT_EMAIL_MESSAGE_WITHOUT_EMAIL =
    "You didn't provide your email during registration.";
// export const EDIT_EMAIL_MESSAGE_WITH_EMAIL = 'Confirmation message will be send to your email. After confirmation you will get free credits';
export const EDIT_EMAIL_MESSAGE_WITH_EMAIL = 'Confirmation message will be send to your email.';
export const EDIT_EMAIL_MESSAGE_WITH_EMAIL_HIGHLIGHTED =
    'If you want to change the registration email, type in a new one in the field below';
export const RESTORE_EMAIL_INSTRUCTIONS_TITLE = 'Instruction';
export const RESTORE_EMAIL_STEP_1 =
    'We have sent you a letter to the email you provided for password recovery. The letter will be received within a few minutes';
export const RESTORE_EMAIL_STEP_2 =
    'Check your email and follow the link from the letter we sent you';
export const RESTORE_EMAIL_STEP_3 = 'Enter new password';
export const RESTORE_EMAIL_STEP_4 = 'Enjoy';
