import React, { useState } from 'react';
import { closePanel } from '../../../../services/dom';
import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';
import { RootState } from '../../../../index';

export default function Header() {
    const notifyState = useSelector((state: RootState) => state.notify);
    const [isOpenNotify, setIsOpenNotify] = useState(true);
    const openNotify = () => setIsOpenNotify(!isOpenNotify);
    const docsState = useSelector((state: RootState) => state.profile.docsState);

    return (
        <div
            className={`c4_top ${isOpenNotify ? 'active' : ''} ${styles.notify_girls_title_wrap}`}
            onClick={openNotify}
        >
            <h2 className={styles.notify_title}>
                Notifications
                {(!!notifyState?.list?.length || docsState?.banner) && (
                    <div className={styles.notify_girls_title_mob_dot} />
                )}
            </h2>
            <div
                className="c4_top_close"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    closePanel();
                }}
            />
        </div>
    );
}
