import React from 'react';
import styles from './styles.module.scss';
import SITE from '../../../../../../config/site';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import IconFavourites from '../../../../../Icons/IconFavourites';
import IconLikeHerHeartStroke from '../../../../../Icons/IconLikeHerHeartStroke';
import IconLetters from '../../../../../Icons/IconLetters';
import IconSearch from '../../../../../Icons/IconSearch';
import IconLikeMenu from '../../../../../Icons/IconLikeMenu';
import IconFAQHelp from '../../../../../Icons/IconFAQHelp';
import IconProfile from '../../../../../Icons/IconProfile';
import IconAddPlus from '../../../../../Icons/IconAddPlus';
import IconCross from '../../../../../Icons/IconCross';
import IconChat from '../../../../../Icons/IconChat';
import IconMobileMenu from '../../../../../Icons/IconMobileMenu';
import IconText from '../../../../../Icons/IconText';
import IconUpdate from '../../../../../Icons/IconUpdate';
import IconLock from '../../../../../Icons/IconLock';

export default function Step2() {
    const userState = useSelector((state: RootState) => state.user);

    const switcherName = () => {
        switch (SITE?.ID) {
            case 1:
                return { name: !Number(userState?.info?.gender) ? 'Kristina, 26' : 'Maickel, 32' };
            case 2:
                return { name: !Number(userState?.info?.gender) ? 'Kristina, 26' : 'Maickel, 32' };
            case 5:
                return { name: !Number(userState?.info?.gender) ? 'Elsa, 25' : 'Beeman, 51' };
            case 6:
                return { name: !Number(userState?.info?.gender) ? 'Kateryna, 29' : 'Maickel, 32' };
            case 7:
                return { name: !Number(userState?.info?.gender) ? 'Iryna, 24' : 'Beeman, 51' };
            case 8:
                return { name: !Number(userState?.info?.gender) ? 'Maria, 27' : 'Maickel, 32' };
            case 9:
                return { name: !Number(userState?.info?.gender) ? 'Olga, 39' : 'Beeman, 51' };
            case 10:
                return { name: !Number(userState?.info?.gender) ? 'Vlada, 36' : 'Maickel, 32' };
            case 11:
                return { name: !Number(userState?.info?.gender) ? 'Julia, 22' : 'Tom, 28' };
            case 12:
                return { name: !Number(userState?.info?.gender) ? 'Lea, 26' : 'Wang, 62' };
            case 13:
                return { name: !Number(userState?.info?.gender) ? 'Carla, 28' : 'Tom, 28' };
            case 14:
                return { name: !Number(userState?.info?.gender) ? 'Yunita, 25' : 'Wang, 62' };
            case 15:
                return { name: !Number(userState?.info?.gender) ? 'Diana, 23' : 'Maickel, 32' };
            case 16:
                return { name: !Number(userState?.info?.gender) ? 'Iryna, 24' : 'Beeman, 51' };
            case 17:
                return { name: !Number(userState?.info?.gender) ? 'Natali, 25' : 'Maickel, 32' };
            case 18:
                return { name: !Number(userState?.info?.gender) ? 'Amina, 27' : 'Beeman, 51' };
            case 19:
                return { name: !Number(userState?.info?.gender) ? 'Vita, 28' : 'Maickel, 32' };
            case 20:
                return { name: !Number(userState?.info?.gender) ? 'Svetlana, 38' : 'Beeman, 51' };
            case 21:
                return { name: !Number(userState?.info?.gender) ? 'Kira, 29' : 'Maickel, 32' };
            case 22:
                return { name: !Number(userState?.info?.gender) ? 'Rose, 28' : 'Beeman, 51' };
            default:
                return { name: !Number(userState?.info?.gender) ? 'Kristina, 26' : 'Maickel, 32' };
        }
    };
    const switcherMenuVideo = () => {
        switch (SITE?.ID) {
            // case 1:
            //     return <><VideoIcon/><span>Videos</span></>;
            // case 5:
            //     return <><VideoIcon/><span>Videos</span></>;
            // case 6:
            //     return <><VideoIcon/><span>Videos</span></>;
            // case 7:
            //     return <><VideoIcon/><span>Videos</span></>;
            // case 8:
            //     return <><VideoIcon/><span>Videos</span></>;
            default:
                return (
                    <>
                        <div className={styles.step_search_menu_icon_faq}>
                            <IconFAQHelp />
                        </div>
                        <span>FAQ</span>
                    </>
                );
        }
    };

    return (
        <>
            <div className={`${styles.tutorial_step_illustration} ${styles.desktop}`}>
                <div className={styles.step_search}>
                    <div className={styles.step_search_left}>
                        <ul className={styles.step_search_categories}>
                            <li className={styles.step_search_categories_item}>
                                <div className={styles.step_search_categories_icon}>
                                    <IconFavourites />
                                </div>
                                <span>Favorites</span>
                            </li>
                            <li className={styles.step_search_categories_item}>
                                <div
                                    className={`${styles.step_search_categories_icon} ${styles.step_search_categories_icon_heart}`}
                                >
                                    <IconLikeHerHeartStroke />
                                </div>
                                <span>Your likes</span>
                            </li>
                        </ul>
                        <ul className={styles.step_search_menu}>
                            <li className={styles.step_search_menu_item}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconLetters />
                                </div>
                                <span>Letters</span>
                            </li>
                            <li className={`${styles.step_search_menu_item} ${styles.active}`}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconSearch />
                                </div>
                                <span>Search</span>
                            </li>
                            <li className={`${styles.step_search_menu_item} ${styles.active}`}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconLikeMenu />
                                </div>
                                <span>Like</span>
                            </li>
                            <li className={styles.step_search_menu_item}>{switcherMenuVideo()}</li>
                        </ul>
                    </div>
                    <div className={styles.step_search_right}>
                        <div className={styles.step_search_avatar_wrap}>
                            <div className={styles.step_search_avatar_icon}>
                                <IconProfile />
                            </div>
                            <div className={styles.step_search_avatar_button}>
                                <div className={styles.step_search_plus_button}>
                                    <div className={styles.step_search_plus_icon}>
                                        <IconAddPlus />
                                    </div>
                                </div>
                                <span>New avatar</span>
                            </div>
                        </div>
                        <div className={styles.step_search_user_id}>
                            <span>ID 1945945090</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.tutorial_step_illustration} ${styles.mobile}`}>
                <div className={styles.step_search}>
                    <div className={styles.step_search_mob_top}>
                        <div className={styles.step_search_mob_chat_item}>
                            <div className={styles.step_search_mob_cross_icon}>
                                <IconCross />
                            </div>
                            <div className={styles.step_search_mob_chat_image}></div>
                            <div className={styles.step_search_mob_chat_info}>
                                <p className={styles.step_search_mob_chat_user_name}>
                                    {switcherName()?.name}
                                </p>
                                <p className={styles.step_search_mob_chat_message}>
                                    Hi dear. Long time no see. How are you? What are you doing on
                                    weekends?
                                </p>
                                <p className={styles.step_search_mob_chat_time}>1:32 pm</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step_search_mob_bottom}>
                        <ul className={styles.step_search_mob_menu}>
                            <li className={styles.step_search_mob_menu_item}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconLetters />
                                </div>
                                <span>Letters</span>
                            </li>
                            <li className={`${styles.step_search_mob_menu_item} ${styles.search}`}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconSearch />
                                </div>
                                <span>Search</span>
                            </li>
                            <li className={styles.step_search_mob_menu_item}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconChat />
                                </div>
                                <span>Chat</span>
                            </li>
                            <li className={`${styles.step_search_mob_menu_item} ${styles.like}`}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconLikeMenu />
                                </div>
                                <span>Like</span>
                            </li>
                            <li className={styles.step_search_mob_menu_item}>
                                <div className={styles.step_search_menu_icon}>
                                    <IconMobileMenu />
                                </div>
                                <span>Menu</span>
                            </li>
                        </ul>
                        <div className={styles.step_search_mob_site_bar}>
                            <div className={styles.step_search_mob_text_icon}>
                                <IconText />
                            </div>
                            <div className={styles.step_search_mob_site_bar_text}>
                                <div className={styles.step_search_mob_lock_icon}>
                                    <IconLock />
                                </div>
                                <span>{SITE.copyrightsText}</span>
                            </div>
                            <div className={styles.step_search_mob_update_icon}>
                                <IconUpdate />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.tutorial_body}>
                <h2 className={styles.tutorial_step_title}>
                    Choose “Search” page to look for a member
                </h2>
                <p className={styles.tutorial_step_text}>
                    Our platform's "Search" feature allows members to easily find and connect with
                    others.
                </p>
            </div>
        </>
    );
}
