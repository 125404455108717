import BotApi from '../../services/api/v3/BotApi';
import {
    deviceType,
    browserName,
    osName,
    fullBrowserVersion,
    osVersion,
} from 'react-device-detect';

export const crashCallback = (message: string) => {
    if (process?.env?.NODE_ENV === 'development') return;
    const deviceInfo = `${deviceType} / ${browserName} ${fullBrowserVersion} / ${osName} ${osVersion}`;
    const fullMessage = `${window?.location?.href} | ${deviceInfo} \n--> ${message}`;
    new BotApi().sendCrashBot(fullMessage).then((res) => (res?.status ? res?.result : null));
};
