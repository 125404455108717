import React from 'react';

export default function IconPlayVideo() {
    return (
        <svg
            width="81"
            height="81"
            viewBox="0 0 81 81"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.49146 39.2888C5.49146 19.9898 21.1925 4.28882 40.4915 4.28882C59.7905 4.28882 75.4915 19.9898 75.4915 39.2888C75.4915 58.5878 59.7905 74.2888 40.4915 74.2888C21.1925 74.2888 5.49146 58.5878 5.49146 39.2888ZM7.82479 39.2888C7.82479 57.301 22.4793 71.9555 40.4915 71.9555C58.5036 71.9555 73.1581 57.301 73.1581 39.2888C73.1581 21.2767 58.5036 6.62215 40.4915 6.62215C22.4793 6.62215 7.82479 21.2767 7.82479 39.2888ZM35.356 24.9791L55.0338 38.5418C55.278 38.71 55.4247 38.9894 55.4247 39.2886C55.4247 39.5879 55.278 39.8673 55.0338 40.0355L35.356 53.5982C35.2049 53.7022 35.0287 53.7555 34.8525 53.7555C34.7093 53.7555 34.5653 53.7212 34.4347 53.6507C34.1414 53.4942 33.958 53.1868 33.958 52.8514V25.7259C33.958 25.3905 34.1414 25.083 34.4339 24.9266C34.7272 24.7702 35.0814 24.791 35.356 24.9791ZM52.9426 39.2886L35.7469 27.4375V51.1397L52.9426 39.2886Z"
            />
        </svg>
    );
}
