import React from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import '../../../styles/audio-player/style.scss';
import { IAudioPlayerProps } from './index.interface';
import useDetectedDevice from '../../../hooks/device/useDetectedDevice';

export default function AudioPlayer({
    src,
    viewedAudio,
    onPayAudio,
    className,
}: IAudioPlayerProps) {
    const { isMobileIOS } = useDetectedDevice();
    return (
        <H5AudioPlayer
            className={`${className} ${isMobileIOS ? 'hide_volume_btn' : ''}`}
            src={src}
            onPlay={viewedAudio}
            customIcons={{
                play: (
                    <img
                        src="../../../img/chat-audio-play.svg"
                        alt=""
                        onClick={src?.length === 0 ? onPayAudio : () => null}
                    />
                ),
                pause: <img src="../../../img/audio-pause.svg" alt="" />,
                volume: <img src="../../../img/audio-volume.svg" alt="" />,
                volumeMute: <img src="../../../img/chat-audio-volume-mute.svg" alt="" />,
            }}
        />
    );
}
