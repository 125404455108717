import React, { useEffect, useState } from 'react';
import Card from '../Card';
import update from 'immutability-helper';
import { IContainerProps } from './index.interface';
import { IFile } from '../../../../../store/reducers/files/index.interface';

const Container = ({ type, data, setModalContentIsOpen, setCurrItem }: IContainerProps) => {
    const [cards, setCards] = useState<IFile[]>([]);
    const moveCard = (dragIndex: number, hoverIndex: number) => {
        const dragCard = cards[dragIndex];
        setCards(
            update(cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }),
        );
    };

    useEffect(() => {
        setCards(data);
    }, [data]);

    return (
        <React.Fragment>
            {!!cards?.length &&
                cards.map((card: IFile, i: number) => (
                    // @ts-expect-error card props
                    <Card
                        type={type}
                        key={card?.id}
                        index={i}
                        id={card?.id}
                        data={card}
                        moveCard={moveCard}
                        setModalContentIsOpen={setModalContentIsOpen}
                        setCurrItem={setCurrItem}
                    />
                ))}
        </React.Fragment>
    );
};

export default Container;
