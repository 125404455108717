import React, { useState } from 'react';
import DoubleSelect from '../../../common/select/DoubleSelect';
import * as DICTIONARY_CREATE_PROFILE_PAGE from '../../../../constants/dictionary/create-profile-page';
import { sequenceArray } from '../../../../services/time/DateUtils';
import SearchCountry from '../../../search/SearchCountry';
import LargeSelect from '../../../common/select/LargeSelect';
import { useDispatch, useSelector } from 'react-redux';
import { LIKE_FILTERS_GENDER_DEFAULT } from '../../../../constants/filter';
import { RootState } from '../../../../index';
import { LOOKING_GENDER_LIST } from '../../../../constants/search';
import * as userAction from '../../../../store/actions/user';

interface ILikeFilter {
    filter: typeof LIKE_FILTERS_GENDER_DEFAULT;
    setFilter: (value: typeof LIKE_FILTERS_GENDER_DEFAULT) => void;
}

export default function LikeFilterGender({ filter, setFilter }: ILikeFilter) {
    const dispatch = useDispatch();

    const referenciesState = useSelector((state: RootState) => state.referencies);
    const userState = useSelector((state: RootState) => state.user);

    const [openAgeFromBox, setOpenAgeFromBox] = useState(false);
    const [openAgeToBox, setOpenAgeToBox] = useState(false);

    const closeAllSelects = () => {
        if (openAgeFromBox || openAgeToBox) {
            setOpenAgeFromBox(false);
            setOpenAgeToBox(false);
        }
    };

    const setFilterField = async (field: string, value: any) => {
        const newFilter = { ...filter, [field]: value };

        if (field === 'country') {
            if (newFilter.country === filter.country) return;

            newFilter.country_name = value;
            newFilter.country =
                referenciesState?.list?.country_list?.find((el) => el?.country_name === value)
                    ?.id ?? 0;
            if (value && !newFilter.country) return;
        }

        setFilter(newFilter);
        setOpenAgeFromBox(false);
    };

    const setGenderFilter = async (gender: 'ALL' | number, sexFilter: number) => {
        const newFilter = { ...filter, gender, sexFilter };
        setFilter(newFilter);
        dispatch(userAction.updateInfoSex(sexFilter));
    };

    return (
        <div className="fill_data_form_select_list" onClick={closeAllSelects}>
            <div className="c3lh_search_filter_item">
                <SearchCountry
                    handleCountryFilter={(value) => setFilterField('country', value)}
                    initInputValue={filter?.country_name}
                />
            </div>
            <DoubleSelect
                formDataFrom={filter.from}
                formDataTo={filter.to}
                handleClickFrom={(value) => setFilterField('from', value)}
                handleClickTo={(value) => setFilterField('to', value)}
                arrayFieldsFrom={['', ...sequenceArray(18, 70), '70+']}
                arrayFieldsTo={['', ...sequenceArray(18, 70), '70+']}
                defaultNameFrom={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_FROM}
                defaultNameTo={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_TO}
                selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_AGE}
            />
            <div className="c3lh_search_filter_item">
                <LargeSelect
                    index="value"
                    formData={
                        LOOKING_GENDER_LIST?.find(
                            (item) => item?.sexFilter === userState?.info?.sex,
                        )?.value || LOOKING_GENDER_LIST?.[0]?.value
                    }
                    handleClick={(item) => setGenderFilter(item?.id, +item?.sexFilter)}
                    arrayFields={LOOKING_GENDER_LIST}
                    defaultName={
                        LOOKING_GENDER_LIST?.find(
                            (item) => item?.sexFilter === userState?.info?.sex,
                        )?.value || LOOKING_GENDER_LIST?.[0]?.value
                    }
                    selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_LOOKING_FOR}
                    dataTestId="sex-filter"
                />
            </div>
        </div>
    );
}
