import React, { ChangeEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as filesAction from '../../../../../store/actions/files';
import FilesApi from '../../../../../services/api/FilesApi';
import AttachAudioPlayer from '../../../../common/AttachAudioPlayer';
import { cutLengthString } from '../../../../../services/methods';
import * as LENGTH from '../../../../../constants/length';
import * as ATTACH from '../../../../../constants/attach';
import { IItemAudioProps } from './index.interface';
import IconMove from '../../../../Icons/IconMove';
import IconTrash from '../../../../Icons/IconTrash';
import { IUpdateSortOrderList } from '../../../../../services/api/FilesApi.interface';

const ItemAudio = ({ elementRef, data, opacity }: IItemAudioProps) => {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleInputChangeAudio = (
        e: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>,
    ) => {
        const checkBox = e.currentTarget.querySelector('input');
        if (checkBox) {
            checkBox.checked = !checkBox.checked;
        }
    };

    const onDeleteItem = () => {
        new FilesApi().postDeleteMedia(data?.id, data?.external_id).then((res) => {
            if (res?.status) {
                dispatch(filesAction.getAllAudios());
            }
        });
    };

    const handleSort = () => {
        if (containerRef.current) {
            const listItems = containerRef.current?.parentNode?.querySelectorAll<HTMLElement>(
                '.upload_popup_tabs_content_audio_item',
            );
            if (!listItems) return;
            const sortedArr: IUpdateSortOrderList[] = [];

            for (const [key, item] of listItems.entries()) {
                if (item?.dataset?.id) {
                    sortedArr.push({
                        id: +item.dataset.id,
                        sort_order: key,
                    });
                }
            }

            if (sortedArr?.length) {
                new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_AUDIO, sortedArr).then((res) => {
                    if (res?.status) {
                        dispatch(filesAction.setSortedFiles('audios', sortedArr));
                    }
                });
            }
        }
    };

    return (
        <div
            className={`upload_popup_tabs_content_audio_item`}
            ref={containerRef}
            style={{ opacity }}
            data-id={data?.id}
        >
            <div className="popup_audio_item_top">
                <div className="popup_audio_item_play_btn">
                    <AttachAudioPlayer src={data?.link} />
                </div>
                <div
                    className="popup_audio_item_move_icon"
                    ref={elementRef}
                    onDrop={(e) => e.preventDefault()}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnd={handleSort}
                    onTouchStart={(e) => e.preventDefault()}
                    onTouchMove={(e) => e.preventDefault()}
                    onTouchEnd={handleSort}
                >
                    <IconMove propStyles={{ cursor: 'grab' }} />
                </div>
                <div
                    className="no-drag popup_audio_item_check"
                    onClick={handleInputChangeAudio}
                    data-testid={data?.filename}
                >
                    <input
                        type="checkbox"
                        className="no-drag"
                        name={`${data?.id}`}
                        id={'ap' + data?.id}
                        data-link={data?.link}
                        data-filename={data?.filename}
                        onChange={handleInputChangeAudio}
                    />
                    <label htmlFor={'ap' + data?.id} className="no-drag" />
                </div>
            </div>
            <div className="popup_audio_item_bottom">
                <span className="popup_audio_item_name">
                    {cutLengthString(data?.filename, LENGTH.MAX_AUDIO_TITLE)}
                </span>
                <div
                    className="popup_audio_item_delete"
                    onClick={onDeleteItem}
                    data-testid="delete-btn"
                >
                    <IconTrash />
                </div>
            </div>
        </div>
    );
};

export default ItemAudio;
