import React from 'react';
import styles from '../../styles.module.scss';
import { OPEN_ANSWER_MAX_LENGTH } from '../../../../../../../constants/length';
import { IOpenQuestionProps } from './index.interface';

export default function OpenQuestion({
    questionText,
    textAnswer,
    handleFormData,
    isRateQuestion,
}: IOpenQuestionProps) {
    return (
        <>
            <p className={styles.support_feedback_modal_text}>
                {isRateQuestion && '2.'} {questionText}
            </p>
            <div
                className={styles.rating_textarea_wrapper}
                data-testid="text-support-feedback-block"
            >
                <textarea
                    className={styles.rating_textarea}
                    name="support_feedback_rating_textarea"
                    id="support_feedback_rating_textarea"
                    placeholder="Enter your answear"
                    value={textAnswer}
                    onChange={(e) => {
                        handleFormData('textAnswer', e.target.value);
                    }}
                    data-testid="text-support-feedback-area"
                    maxLength={OPEN_ANSWER_MAX_LENGTH}
                />
                <div className={styles.rating_textarea_counter}>
                    {textAnswer?.length}/{OPEN_ANSWER_MAX_LENGTH}
                </div>
            </div>
            {textAnswer?.length >= OPEN_ANSWER_MAX_LENGTH && (
                <p className={styles.textarea_error_message}>
                    The character limit has been exceeded.
                </p>
            )}
        </>
    );
}
