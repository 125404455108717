import { useEffect, useState } from 'react';
import { IManProfileContentItem } from '../../../models/profile/ManProfile.interface';

export default function usePhotosScroll({ photos }: { photos: IManProfileContentItem[] }) {
    const [galleryContent, setGalleryContent] = useState<IManProfileContentItem[] | null>(null);

    const scrollNext = () => {
        setGalleryContent([
            ...(galleryContent?.slice(1) as IManProfileContentItem[]),
            galleryContent?.[0] as IManProfileContentItem,
        ]);
    };

    const scrollPrev = () => {
        setGalleryContent([
            galleryContent?.[galleryContent?.length - 1] as IManProfileContentItem,
            ...(galleryContent?.slice(0, galleryContent?.length - 1) as IManProfileContentItem[]),
        ]);
    };

    useEffect(() => {
        if (Array.isArray(photos)) setGalleryContent(photos);
    }, [photos]);

    useEffect(() => {
        const sliderBlock = document.querySelector(`#profile_photos_scroll`);

        function scrollHorizontally(event: any) {
            if (sliderBlock) {
                event = window?.event || event;
                const delta = Math.max(-1, Math.min(1, event?.wheelDelta || -event?.detail));
                sliderBlock.scrollLeft -= delta * 40; // Multiplied by 40
                event.preventDefault();
            }
        }

        if (sliderBlock) {
            // IE9, Chrome, Safari, Opera
            sliderBlock.addEventListener('mousewheel', scrollHorizontally, false);
            // Firefox
            sliderBlock.addEventListener('DOMMouseScroll', scrollHorizontally, false);
        }

        return () => {
            if (sliderBlock) {
                // IE9, Chrome, Safari, Opera
                sliderBlock.removeEventListener('mousewheel', scrollHorizontally, false);
                // Firefox
                sliderBlock.removeEventListener('DOMMouseScroll', scrollHorizontally, false);
            }
        };
    }, [galleryContent]);

    return { scrollNext, scrollPrev, galleryContent };
}
