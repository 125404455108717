export enum UserActionTypes {
    SET_LOYALTY_PROGRAM = 'SET_LOYALTY_PROGRAM',
    USER_INFO_GET = 'user_info_get',
    USER_INFO_UPDATE = 'user_info_update',
    USER_CREDIT = 'user_credit',
    ADD_LAST_BUY_CREDITS = 'user_last_buy_credits',
    IS_BUYING = 'user_is_buying',
    SET_BALANCE = 'SET_BALANCE',
    EDIT_USER_PHOTO_LINK = 'EDIT_USER_PHOTO_LINK',
    DELETE_USER_PHOTO_LINK = 'DELETE_USER_PHOTO_LINK',
    SET_USER_EMAIL = 'SET_USER_EMAIL',
    SET_IS_APPROVE_PHONE = 'SET_IS_APPROVE_PHONE',
    SET_USER_SPEND_FIRST_CREDIT = 'SET_USER_SPEND_FIRST_CREDIT',
    SET_USER_REAL_NAME = 'SET_USER_REAL_NAME',
    SET_IS_USER_VERIFIED = 'SET_IS_USER_VERIFIED',
    SET_IMBRA_DATA = 'SET_IMBRA_DATA',
    SET_IMBRA_STATUS = 'SET_IMBRA_STATUS',
    SET_USER_CONTACT_REQUEST_INFO = 'SET_USER_CONTACT_REQUEST_INFO',
    SET_IS_FB_CONVERSION = 'SET_IS_FB_CONVERSION',
    UPDATE_INFO_SEX = 'UPDATE_INFO_SEX',
    UPDATE_USERNAME = 'EDIT_USERNAME',
}

export enum VerifiedStatus {
    DECLINED = 'DECLINED',
    APPROVED = 'APPROVED',
    NEED_APPROVE = 'NEED_APPROVE',
}
