import React from 'react';

export default function IconLiveChat() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="#720066"
        >
            <path d="M20.8333 10.3333C20.8333 5.73 17.1038 2 12.5 2C7.8975 2 4.16675 5.73 4.16675 10.3333C3.24625 10.3333 2.5 11.0795 2.5 12V17C2.5 17.9205 3.24625 18.6667 4.16675 18.6667H6.66675C7.127 18.6667 7.5 18.294 7.5 17.8333V13.6667C7.5 13.2085 7.292 12.5208 7.03775 12.14L5.8335 10.3335C5.8335 6.651 8.818 3.66675 12.5002 3.66675C16.1835 3.66675 19.167 6.651 19.167 10.3335L17.9625 12.14C17.7083 12.5208 17.5 13.2085 17.5 13.6667V18.0662C16.2785 19.4538 14.494 20.3333 12.5 20.3333H10.8333V22H12.5C15.2245 22 17.635 20.6873 19.1553 18.6667H20.8333C21.7537 18.6667 22.5 17.9205 22.5 17V12C22.5 11.0795 21.7537 10.3333 20.8333 10.3333ZM5.83325 17H4.1665V12H4.94125L5.65075 13.0645C5.72575 13.1825 5.82975 13.5278 5.833 13.6683L5.83325 17ZM20.8333 17H19.1667V13.6683C19.17 13.5275 19.2743 13.1825 19.349 13.0645L20.0585 12H20.8333V17Z" />
        </svg>
    );
}
