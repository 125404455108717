export const START_DAY = 1;
export const END_DAY = 31;

export const START_YEAR = 1920;
export const END_YEAR = new Date().getFullYear() - 18;
export const MIN_AGE = 18;

export const FORM_SELECT_DAY = 'Day';
export const FORM_SELECT_MONTH = 'Month';
export const FORM_SELECT_YEAR = 'Year';

export const GENDER_MALE_ID = 0;
export const GENDER_FEMALE_ID = 1;
export const GENDER_MALE_TITLE = 'Man';
export const GENDER_FEMALE_TITLE = 'Woman';

export const GENDER_ARRAY = [
    { title: GENDER_FEMALE_TITLE as 'Woman', id: GENDER_FEMALE_ID },
    { title: GENDER_MALE_TITLE as 'Man', id: GENDER_MALE_ID },
];

export const GENDER_ARRAY_REVERS = GENDER_ARRAY.reverse();

export const ARRAY_MONTH = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
];

export const FIELD_NAME = 'name';
export const FIELD_USERNAME = 'username';
export const FIELD_EMAIL = 'email';
export const FIELD_PASSWORD = 'password';
export const FIELD_DAY = 'bDay';
export const FIELD_MONTH = 'bMonth';
export const FIELD_YEAR = 'bYear';
export const FIELD_AGREE = 'agree';
export const ARRAY_SELECT_DATE = [FIELD_DAY, FIELD_MONTH, FIELD_YEAR];

export const DOMAIN_ARRAY = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'outlook.com',
    'msn.com',
    'icloud.com',
];

export const MAX_V_COUNT = 100;
// If REACT_APP_RECAPTCHA_SITE_KEY undefined we take the reCaptcha test SITE_KEY
export const RECAPTCHA_SITE_KEY =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
