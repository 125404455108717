import React from 'react';
// import { MetricsLogger } from '@cabify/prom-react';
import usePerformanceMetrics from '../../../hooks/metrics/usePerformanceMetrics';
import { IMetricsLayoutProps } from '../index.interface';

const MetricsInnerWrap = ({ children }: IMetricsLayoutProps) => {
    usePerformanceMetrics();

    return (
        <>
            {/*<MetricsLogger />*/}
            {children}
        </>
    );
};

export default MetricsInnerWrap;
