import Base from './Base';
const { REACT_APP_DATALAYER_TOKEN = '' } = process.env;

export default class DataLayerLogApi extends Base {
    private readonly headers: Record<string, any>;
    private static readonly BASE_PATH = 'v3/conversion-integration';

    constructor() {
        super();
        this.headers = {
            ...super.getAuthHeader(),
            'x-log-token': REACT_APP_DATALAYER_TOKEN,
        };
    }

    regularLog(data: any) {
        const path = `${DataLayerLogApi.BASE_PATH}/dataLayer-regular-logs`;
        return super.post(path, data, { headers: this.headers });
    }

    ecommerceLog(data: any) {
        const path = `${DataLayerLogApi.BASE_PATH}/dataLayer-ecommerce-logs`;
        return super.post(path, data, { headers: this.headers });
    }
}
