import React, { useEffect, useState } from 'react';
import { scrollTopToMainChatWithPagination } from '../../../../../services/dom';
import Emoji from 'react-emoji-render';
import { useDispatch, useSelector } from 'react-redux';
import ChatsApi from '../../../../../services/api/ChatsApi';
import * as currentChatAction from '../../../../../store/actions/currentChat';
import * as allMediaAction from '../../../../../store/actions/allMedia';
import * as modalsAction from '../../../../../store/actions/modals';
import * as userAction from '../../../../../store/actions/user';
import { formatDate } from '../../../../../services/time';
import AudioPlayer from '../../../../common/AudioPlayer';
import * as alertAction from '../../../../../store/actions/alert';
import * as ERROR from '../../../../../constants/error';
import * as ALERT from '../../../../../constants/alert';
import * as PRICES from '../../../../../constants/prices';
import { convertPrice, renderLastMessage } from '../../../../../services/methods';
import * as STICKERS from '../../../../../constants/stickers';
import * as CHATS from '../../../../../constants/chats';
import { filterToHideChatHistoryByType, stopAudio } from '../../../../../services/methods/chat';
import SITE from '../../../../../config/site';
import styles from '../../styles.module.scss';
import { RootState } from '../../../../../index';
import { IMessageLeftProps } from '../../../../common/MessageBlocks/MessageLeft/index.interface';
import { IChatHistoryItem } from '../../../../../store/reducers/currentChat/index.interface';
import avatar_placeholder from '../../../../../img/placeholder.gif';

export default function MessageLeft({
    message,
    isLoadingRequest,
    setIsLoadingRequest,
    messageIdArr,
    setMessageIdArr,
}: IMessageLeftProps) {
    const dispatch = useDispatch();

    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const [imageOnLoad, setImageOnLoad] = useState(false);

    const selectedSticker = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
        e?.name?.includes(message?.message_content),
    )?.image;
    const isNewMail = userState?.info?.newMail;

    const openImageModal = (link: string) =>
        dispatch(modalsAction.openPhotoContentModal({ link, attachment_payed: '1' }));
    const openVideoModal = (link: string) =>
        dispatch(modalsAction.openVideoContentModal({ link, attachment_payed: '1' }));

    const readAudio = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.id)) return false;

        const newMessageIdArr = [...messageIdArr, data?.id];

        setMessageIdArr([...newMessageIdArr]);
        setIsLoadingRequest(true);

        new ChatsApi()
            .postReadAudio({
                userId: userState?.info?.external_id,
                messageId: data?.id,
                chatUid: currentChatState?.info?.chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(currentChatAction.readMessageAudio(data?.id, res?.message_content));
                    dispatch(allMediaAction.setBuyedMedia(data?.id));
                    dispatch(userAction.setBalance());

                    if (res?.message_content)
                        viewedAudio({ ...data, message_content: res?.message_content });
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                }
            })
            .finally(() => {
                setIsLoadingRequest(false);
                setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.id));
            });
    };
    const readVideo = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.id)) return false;

        const newMessageIdArr = [...messageIdArr, data?.id];

        setMessageIdArr([...newMessageIdArr]);
        setIsLoadingRequest(true);

        new ChatsApi()
            .postReadVideo({
                userId: userState?.info?.external_id,
                messageId: data?.id,
                chatUid: currentChatState?.info?.chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(currentChatAction.readMessageVideo(data?.id, res?.message_content));
                    dispatch(allMediaAction.setBuyedMedia(data?.id));
                    dispatch(userAction.setBalance());

                    if (res?.message_content)
                        viewedVideo({ ...data, message_content: res?.message_content });
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                }
            })
            .finally(() => {
                setIsLoadingRequest(false);
                setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.id));
            });
    };

    const viewedVideo = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        new ChatsApi().postViewMedia(
            userState?.info?.external_id,
            data?.id,
            currentChatState?.info?.chat_uid,
        );
        openVideoModal(data?.message_content);
    };

    const viewedAudio = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        stopAudio(data?.message_content, true, data?.id);
        new ChatsApi().postViewMedia(
            userState?.info?.external_id,
            data?.id,
            currentChatState?.info?.chat_uid,
        );
    };

    const checkBeforeBuyingMessageContent = (type: string, callback: () => void) => {
        if (userState?.info?.convert_user || SITE.ID === 4) callback();
        else
            dispatch(
                alertAction.setCustomAlertType({
                    alertType: type,
                    successCallback: () => callback(),
                }),
            );
    };

    useEffect(() => {
        if (history?.length) {
            if (!currentChatState?.isNewMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            } else if (!currentChatState?.isReadLastMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            }
        }
    }, [history, imageOnLoad]);

    return (
        <div
            className={`chat_message_wrap left 
                ${message?.message_type === CHATS.MESSAGE_CONTENT_TYPE_WINK ? 'wink' : ''}
                ${message?.message_type === CHATS.MESSAGE_CONTENT_TYPE_STICKER ? 'sticker' : ''}
                ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}
            `}
            id={`mess-${message?.id}`}
        >
            <div
                className={`${styles.chat_message} ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}`}
            >
                <div className={styles.message_avatar}>
                    <img src={message?.avatar || avatar_placeholder} alt="avatar" />
                </div>
                <div className="message_content">
                    {(function () {
                        switch (message?.message_type) {
                            case CHATS.MESSAGE_CONTENT_TYPE_IMAGE:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_left} ${styles.photo}`}
                                    >
                                        <img
                                            src={message?.message_thumb}
                                            alt=""
                                            className="message_img"
                                            onLoad={() => setImageOnLoad(true)}
                                            onClick={() =>
                                                openImageModal(message?.message_popup || '')
                                            }
                                        />
                                    </div>
                                );
                            case CHATS.MESSAGE_CONTENT_TYPE_AUDIO: {
                                const price = isNewMail
                                    ? `Listening to this audio costs ${convertPrice(+(userState?.prices?.[PRICES.GET_AUDIO_NEW] || 0))} credits`
                                    : `Listening to this audio costs ${convertPrice(+(userState?.prices?.[PRICES.GET_AUDIO] || 0))} credits`;

                                return (
                                    <div className="message_text">
                                        {+message?.payed === 0 ? (
                                            <div
                                                className={`message_audio_wrap ${isLoadingRequest && messageIdArr?.some((el) => +el === +message?.id) ? 'blocked' : ''}`}
                                                onClick={() =>
                                                    checkBeforeBuyingMessageContent(
                                                        ALERT.AUDIO_MESSAGE_CONTENT,
                                                        () => readAudio(message),
                                                    )
                                                }
                                            >
                                                {SITE.ID !== 4 && (
                                                    <span className="need_pay">{price}</span>
                                                )}
                                                <AudioPlayer
                                                    src={''}
                                                    onPayAudio={() =>
                                                        checkBeforeBuyingMessageContent(
                                                            ALERT.AUDIO_MESSAGE_CONTENT,
                                                            () => readAudio(message),
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="message_audio_wrap"
                                                onClick={() => viewedAudio(message)}
                                            >
                                                <AudioPlayer
                                                    src={message?.message_content}
                                                    viewedAudio={() => viewedAudio(message)}
                                                />
                                                <div className="message_video_status paid">
                                                    {message?.date_viewed === null &&
                                                    SITE.ID !== 4 ? (
                                                        `Paid`
                                                    ) : (
                                                        <span>
                                                            Listened{' '}
                                                            {message?.date_viewed
                                                                ? formatDate(message?.date_viewed)
                                                                : ''}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }

                            case CHATS.MESSAGE_CONTENT_TYPE_VIDEO: {
                                const price = isNewMail
                                    ? `Viewing this video costs ${convertPrice(+(userState?.prices?.[PRICES.GET_VIDEO_NEW] || 0))} credits`
                                    : `Viewing this video costs ${convertPrice(+(userState?.prices?.[PRICES.GET_VIDEO] || 0))} credits`;

                                return (
                                    <>
                                        <div
                                            className={`message_text ${styles.message_text_left} ${styles.video}`}
                                        >
                                            {+message?.payed === 0 ? (
                                                <div
                                                    className="message_video_wrap"
                                                    onClick={() =>
                                                        checkBeforeBuyingMessageContent(
                                                            ALERT.VIDEO_MESSAGE_CONTENT,
                                                            () => readVideo(message),
                                                        )
                                                    }
                                                >
                                                    {SITE.ID !== 4 && (
                                                        <span className="need_pay">{price}</span>
                                                    )}
                                                    <div
                                                        className={`message_video ${isLoadingRequest && messageIdArr?.some((el) => +el === +message?.id) ? 'blocked' : ''}`}
                                                    >
                                                        {message?.thumb_link?.length ? (
                                                            <img
                                                                src={message?.thumb_link}
                                                                alt=""
                                                                onLoad={() => setImageOnLoad(true)}
                                                            />
                                                        ) : (
                                                            <video width="320" preload="metadata">
                                                                <source
                                                                    src={`${message?.message_content}#t=0.6`}
                                                                    type="video/mp4"
                                                                />
                                                            </video>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : !message?.date_viewed && SITE.ID !== 4 ? (
                                                <div
                                                    className="message_video_wrap"
                                                    onClick={() => viewedVideo(message)}
                                                >
                                                    <div className="message_video">
                                                        {!!message?.thumb_link?.length && (
                                                            <img
                                                                src={message?.thumb_link}
                                                                alt=""
                                                                onLoad={() => setImageOnLoad(true)}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="message_video_status paid">
                                                        Paid
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="message_video_wrap"
                                                    onClick={() => viewedVideo(message)}
                                                >
                                                    <div className="message_video">
                                                        {message?.thumb_link?.length ? (
                                                            <img
                                                                src={message?.thumb_link}
                                                                alt=""
                                                                onLoad={() => setImageOnLoad(true)}
                                                            />
                                                        ) : (
                                                            <video width="320" preload="metadata">
                                                                <source
                                                                    src={`${message?.message_content}#t=0.3`}
                                                                    type="video/mp4"
                                                                />
                                                            </video>
                                                        )}
                                                    </div>
                                                    <div className="message_video_status viewed">
                                                        <span>
                                                            Viewed{' '}
                                                            {message?.date_viewed
                                                                ? formatDate(message?.date_viewed)
                                                                : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                );
                            }

                            case CHATS.MESSAGE_CONTENT_TYPE_TEXT:
                            case CHATS.MESSAGE_CONTENT_TYPE_WINK:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_left} ${styles.emoji}`}
                                    >
                                        <Emoji text={message?.message_content} />
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_STICKER:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_left} ${styles.sticker}`}
                                    >
                                        {selectedSticker && <img src={selectedSticker} alt="" />}
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_LIKE:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_left} ${styles.like}`}
                                    >
                                        <img src="../../../../../img/c4-like.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_VIEW:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_left} ${styles.like}`}
                                    >
                                        <img src="../../../../../img/c4-viwed.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            default:
                                return null;
                        }
                    })()}
                    <div className="message_bottom">
                        <div className="message_date">{formatDate(message?.date_created)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
