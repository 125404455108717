import { ReferenciesActionTypes } from '../../constants/referencies';
import { IReferenciesState, ReferenciesActions } from './index.interface';

const initState = {
    list: null,
};

export default function (
    state: IReferenciesState = initState,
    action: ReferenciesActions,
): IReferenciesState {
    switch (action.type) {
        case ReferenciesActionTypes.GET_REFERENCIES_LIST:
            return { ...state, ...{ list: action.referencies } };

        default:
            return state;
    }
}
