import { useDispatch, useSelector } from 'react-redux';
import * as giftsAction from '../../store/actions/gifts';
import { RootState } from '../../index';
import { IRecipientProfile } from '../../store/reducers/gifts/index.interface';
import useTransition from '../transition/useTransition';

export default function useHandleGiftsPage() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const giftsState = useSelector((state: RootState) => state.gifts);

    const openGiftsPage = (recipientProfile: IRecipientProfile) => {
        if (giftsState?.cart?.recipient !== recipientProfile?.external_id) {
            dispatch(giftsAction.emptyCart());
        }
        dispatch(giftsAction.setRecipientProfile(recipientProfile));
        dispatch(giftsAction.setRecipient(recipientProfile?.external_id));
        transition.transitionWithHistoryPush('gifts', '/gifts');
    };

    return {
        openGiftsPage,
    };
}
