import React from 'react';

export default function IconArrowInbox() {
    return (
        <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M20.7436 7.96541L12.8732 0.251125C12.6231 0.00683093 12.2479 -0.0677505 11.92 0.0651317C11.593 0.197135 11.3804 0.510863 11.3804 0.857152V4.28915C6.34863 4.49143 1.95873 7.82143 0.552541 12.5931C-0.0560992 14.6623 -0.00887338 16.3431 0.00859077 16.9766L0.0120921 17.1429C0.0120921 17.5286 0.275293 17.8672 0.653953 17.9692C0.731795 17.9897 0.809593 18 0.886581 18C1.18915 18 1.47771 17.8449 1.63775 17.5817C5.10159 11.8749 9.6909 11.7129 11.3804 11.874V16.2858C11.3804 16.632 11.593 16.9458 11.92 17.0778C12.2479 17.2115 12.6231 17.1369 12.8732 16.8918L20.7436 9.1775C21.0855 8.84226 21.0855 8.30052 20.7436 7.96541Z" />
        </svg>
    );
}
