interface IConfigSite {
    [key: string]: {
        ID: number;
        key: string;
        brand: string;
        name: string;
        copyrightsText: string;
        brandUrl: string;
        googleClientId?: string;
        onesignalAppId?: string;
        onesignalSafariWebId?: string;
        welcomeModalText?: string;
        resetPasswordTitle?: string;
    };
}

const CONFIG_SITE: IConfigSite = {
    sofiadate: {
        ID: 1,
        key: 'sofiadate',
        brand: 'Sofia Date',
        name: 'SofiaDate',
        copyrightsText: 'SofiaDate.com',
        brandUrl: 'sofia20',
        googleClientId: '539249899214-o2vvebvr6d29as2nk6ap087ln2698704.apps.googleusercontent.com',
        onesignalAppId: 'c78f5c79-8eb7-45f9-9701-cc416a05c253',
        onesignalSafariWebId: 'web.onesignal.auto.2b30b273-8f48-4327-8bae-bed77c33071b',
        welcomeModalText: "Join SofiaDate's hourly success stories and find your soul mate.",
        resetPasswordTitle:
            'Enter the email which you registered your account, which you want to restore is now',
    },
    myspecialdates: {
        ID: 2,
        key: 'myspecialdates',
        brand: 'My Special Dates',
        name: 'MySpecialDates',
        copyrightsText: 'Myspecialdates.com',
        brandUrl: 'myspecialdates',
        googleClientId: '539249899214-uhqtm2qr87ell45a9v2rqspqdh4fll80.apps.googleusercontent.com',
        onesignalAppId: 'a1a24422-2887-4acd-912f-a24d074570d3',
        onesignalSafariWebId: 'web.onesignal.auto.251f0eae-dd1c-4527-b78a-dfbe622fe6a9',
        welcomeModalText: 'Your next MySpecialDates match could be your perfect companion.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
    },
    loveforheart: {
        ID: 5,
        key: 'loveforheart',
        brand: 'Love for heart',
        name: 'Loveforheart',
        copyrightsText: 'Loveforheart.com',
        brandUrl: 'loveforheart',
        googleClientId: '539249899214-rb3l77o7u6jsuotfvf3r2bl7a1a4ff91.apps.googleusercontent.com',
        onesignalAppId: 'e1a8d189-e721-4117-aec5-fdf888592d34',
        onesignalSafariWebId: 'null',
        welcomeModalText: 'Your next companion is only an hour away with Loveforheart.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
    },
    amourmeet: {
        ID: 6,
        key: 'amourmeet',
        brand: 'Amour meet',
        name: 'Amourmeet',
        copyrightsText: 'Amourmeet.com',
        brandUrl: 'amourmeet',
        googleClientId: '539249899214-uqsehlqo2oqjnvn9cisn053i1d8e9ni4.apps.googleusercontent.com',
        onesignalAppId: 'f2787d29-b521-4ae8-81e5-3827c3f7caa5',
        onesignalSafariWebId: 'null',
        welcomeModalText: 'Find your next companion with hourly Amourmeet matches.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
    },
    lilu: {
        ID: 4,
        key: 'lilu',
        brand: 'Lilu',
        name: 'Lilu',
        copyrightsText: 'Lilu.club',
        brandUrl: 'lilu',
        // googleClientId: '',
        onesignalAppId: 'null',
        onesignalSafariWebId: 'null',
        welcomeModalText: 'Find your next companion with hourly matches.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
    },
    okamour: {
        ID: 7,
        key: 'okamour',
        brand: 'Okamour',
        name: 'Okamour',
        copyrightsText: 'Okamour.com',
        brandUrl: 'okamour',
        googleClientId: '539249899214-ievhgh2eeqf2uuhh801m2jml1bv2dme8.apps.googleusercontent.com',
        welcomeModalText: 'Okamour: hourly connection possible.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    avodate: {
        ID: 8,
        key: 'avodate',
        brand: 'Avodate',
        name: 'Avodate',
        copyrightsText: 'Avodate.com',
        brandUrl: 'avodate',
        googleClientId: '539249899214-cnt6a46a7mmj5h60c7cv78ruv2m8rvu6.apps.googleusercontent.com',
        welcomeModalText: 'Avodate: where to find a companion in just an hour.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    datempire: {
        ID: 9,
        key: 'datempire',
        brand: 'Datempire',
        name: 'Datempire',
        copyrightsText: 'Datempire.com',
        brandUrl: 'datempire',
        googleClientId: '539249899214-2jaonm3i7893ndcng9eb5njnbg8h2h0c.apps.googleusercontent.com',
        welcomeModalText: 'Love is only an hour away with Datempire matches.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    feelflame: {
        ID: 10,
        key: 'feelflame',
        brand: 'Feelflame',
        name: 'Feelflame',
        copyrightsText: 'Feelflame.com',
        brandUrl: 'feelflame',
        googleClientId: '539249899214-0m38hu5vi7tocg9d8vk1a99ul3rkr1t7.apps.googleusercontent.com',
        welcomeModalText: 'Feelflame: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    latidate: {
        ID: 11,
        key: 'latidate',
        brand: 'Latidate',
        name: 'Latidate',
        copyrightsText: 'Latidate.com',
        brandUrl: 'latidate',
        googleClientId: '539249899214-i747vlac9slqv1mvioem0fdpphtsaf90.apps.googleusercontent.com',
        welcomeModalText: "Don't wait another hour to find your perfect match on Latidate.",
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    sakuradate: {
        ID: 12,
        key: 'sakuradate',
        brand: 'Sakuradate',
        name: 'Sakuradate',
        copyrightsText: 'Sakuradate.com',
        brandUrl: 'sakuradate',
        googleClientId: '539249899214-n8iibhp11msglfg72uudifj4o4g8e1mg.apps.googleusercontent.com',
        welcomeModalText:
            'You only have an hour away to find your perfect companion on Sakuradate.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    latidreams: {
        ID: 13,
        key: 'latidreams',
        brand: 'Latidreams',
        name: 'Latidreams',
        copyrightsText: 'Latidreams.com',
        brandUrl: 'latidreams',
        googleClientId: '539249899214-ts3vq8te00f7dhl3mcij898br93g7st0.apps.googleusercontent.com',
        welcomeModalText: "Don't wait another hour to find your perfect match on Latidreams.",
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    naomidate: {
        ID: 14,
        key: 'naomidate',
        brand: 'Naomidate',
        name: 'Naomidate',
        copyrightsText: 'Naomidate.com',
        brandUrl: 'naomidate',
        googleClientId: '539249899214-67ldh83uiuk6u0c6fn38rm0tqtjbqfhh.apps.googleusercontent.com',
        welcomeModalText: 'You only have an hour away to find your perfect companion on Naomidate.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    amorpulse: {
        ID: 15,
        key: 'amorpulse',
        brand: 'Amorpulse',
        name: 'Amorpulse',
        copyrightsText: 'Amorpulse.com',
        brandUrl: 'amorpulse',
        googleClientId: '539249899214-rr9temf6ak1kd46e2l8jm4bs666etfhk.apps.googleusercontent.com',
        welcomeModalText: 'Amorpulse: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    nikadate: {
        ID: 16,
        key: 'nikadate',
        brand: 'Nikadate',
        name: 'Nikadate',
        copyrightsText: 'Nikadate.com',
        brandUrl: 'nikadate',
        googleClientId: '539249899214-cl86j2da9l38r3g0cv178fddt2c6g0pb.apps.googleusercontent.com',
        welcomeModalText: 'Nikadate: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    vikiline: {
        ID: 18,
        key: 'vikiline',
        brand: 'Vikiline',
        name: 'Vikiline',
        copyrightsText: 'Vikiline.com',
        brandUrl: 'vikiline',
        googleClientId: '539249899214-qja4dadlk435pj47spoqacml4j9lrnuu.apps.googleusercontent.com',
        welcomeModalText: 'Vikiline: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    niodate: {
        ID: 20,
        key: 'niodate',
        brand: 'Niodate',
        name: 'Niodate',
        copyrightsText: 'Niodate.com',
        brandUrl: 'niodate',
        googleClientId: '539249899214-70o0uj9um2u2epbtaif9rab7744se8oj.apps.googleusercontent.com',
        welcomeModalText: 'Niodate: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    dremeet: {
        ID: 21,
        key: 'dremeet',
        brand: 'Dremeet',
        name: 'Dremeet',
        copyrightsText: 'Dremeet.com',
        brandUrl: 'dremeet',
        googleClientId: '539249899214-srgj4gahe133vgjib5hd9up9p8o25r6l.apps.googleusercontent.com',
        welcomeModalText: 'Dremeet: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    loviaflame: {
        ID: 22,
        key: 'loviaflame',
        brand: 'Loviaflame',
        name: 'Loviaflame',
        copyrightsText: 'Loviaflame.com',
        brandUrl: 'loviaflame',
        googleClientId: '539249899214-2hds6o0ltdrk8lt2rkb5miussp2he8ji.apps.googleusercontent.com',
        welcomeModalText: 'Loviaflame: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    sendberly: {
        ID: 23,
        key: 'sendberly',
        brand: 'Sendberly',
        name: 'Sendberly',
        copyrightsText: 'Sendberly.com',
        brandUrl: 'sendberly',
        googleClientId: '539249899214-5636r5krg0qn11tlunc6drvbg5np4pic.apps.googleusercontent.com',
        welcomeModalText: 'You only have an hour away to find your perfect companion',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    conektik: {
        ID: 26,
        key: 'conektik',
        brand: 'Conektik',
        name: 'Conektik',
        copyrightsText: 'Conektik.com',
        brandUrl: 'conektik',
        googleClientId: '539249899214-fah8lmq76amgt2cql1cdla0i378bq7e3.apps.googleusercontent.com',
        welcomeModalText: 'Conektik: where to find a companion in just an hour.',
        resetPasswordTitle:
            'Enter the email which you registered your account, which you want to restore is now',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    lunameet: {
        ID: 27,
        key: 'lunameet',
        brand: 'Lunameet',
        name: 'Lunameet',
        copyrightsText: 'Lunameet.com',
        brandUrl: 'lunameet',
        googleClientId: '539249899214-33g1lnpfhkmr3tmpqeclvm8t9gt02tkd.apps.googleusercontent.com',
        welcomeModalText: 'Lunameet: where to find a companion in just an hour.',
        resetPasswordTitle:
            'Enter the email which you registered your account, which you want to restore is now',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    evadate: {
        ID: 28,
        key: 'evadate',
        brand: 'Evadate',
        name: 'Evadate',
        copyrightsText: 'Evadate.com',
        brandUrl: 'evadate',
        googleClientId: '539249899214-a0rc26im7lbur0ojh0rtapr2rap03p2u.apps.googleusercontent.com',
        welcomeModalText: 'Evadate: where to find a companion in just an hour.',
        resetPasswordTitle:
            'Enter the email which you registered your account, which you want to restore is now',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    miladate: {
        ID: 29,
        key: 'miladate',
        brand: 'Miladate',
        name: 'Miladate',
        copyrightsText: 'Miladate.com',
        brandUrl: 'miladate',
        googleClientId: '539249899214-5amtenf7mmcgmr7dd57kj1ean4c0ett1.apps.googleusercontent.com',
        welcomeModalText: 'Miladate: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    alonadate: {
        ID: 30,
        key: 'alonadate',
        brand: 'Alonadate',
        name: 'Alonadate',
        copyrightsText: 'Alonadate.com',
        brandUrl: 'alonadate',
        googleClientId: '539249899214-4dnrq9agbdqblgi2uaa2p0kv2f0he3lj.apps.googleusercontent.com',
        welcomeModalText: 'Alonadate: where to find a companion in just an hour.',
        resetPasswordTitle:
            'Enter the email which you registered your account, which you want to restore is now',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    lilidate: {
        ID: 31,
        key: 'lilidate',
        brand: 'Lilidate',
        name: 'Lilidate',
        copyrightsText: 'Lilidate.com',
        brandUrl: 'lilidate',
        googleClientId: '539249899214-u3jedl6ep02tf8lpp63f83knfv308frm.apps.googleusercontent.com',
        welcomeModalText: 'Lilidate: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    magnoliadate: {
        ID: 32,
        key: 'magnoliadate',
        brand: 'magnoliadate',
        name: 'Magnoliadate',
        copyrightsText: 'Magnoliadate.com',
        brandUrl: 'magnoliadate',
        googleClientId: '539249899214-nei6el66sv3b6f9a99tuu5fdtc4uojkk.apps.googleusercontent.com',
        welcomeModalText: 'Magnoliadate: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
    salmadate: {
        ID: 33,
        key: 'salmadate',
        brand: 'salmadate',
        name: 'Salmadate',
        copyrightsText: 'Salmadate.com',
        brandUrl: 'salmadate',
        googleClientId: '539249899214-608rld0vcnnjkj34rp928qhj3har5fcv.apps.googleusercontent.com',
        welcomeModalText: 'Salmadate: Connecting companions every hour, including you.',
        resetPasswordTitle:
            'Enter the email used during sing-up process, which you want to restore',
        // onesignalAppId: "null",
        // onesignalSafariWebId: "null",
    },
};

export default CONFIG_SITE[process.env.REACT_APP_SITE || 'sofiadate'] || CONFIG_SITE.sofiadate;
