import React, { ChangeEvent, useRef } from 'react';
import * as AWS from '../../../../../constants/aws';
import { cutLengthString } from '../../../../../services/methods';
import * as LENGTH from '../../../../../constants/length';
import { useDispatch } from 'react-redux';
import * as filesAction from '../../../../../store/actions/files';
import FilesApi from '../../../../../services/api/FilesApi';
import * as ATTACH from '../../../../../constants/attach';
import { IItemVideoProps } from './index.interface';
import IconMove from '../../../../Icons/IconMove';
import IconTrash from '../../../../Icons/IconTrash';
import IconPhotoFullSize from '../../../../Icons/IconPhotoFullSize';
import { IUpdateSortOrderList } from '../../../../../services/api/FilesApi.interface';

export default function ItemVideo({
    elementRef,
    data,
    opacity,
    setCurrItem,
    setModalContentIsOpen,
}: IItemVideoProps) {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleInputChangeVideo = (
        e: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>,
    ) => {
        const checkBox = e.currentTarget.querySelector('input');
        if (checkBox) {
            checkBox.checked = !checkBox.checked;
        }
    };

    const openContentModal = () => {
        setCurrItem &&
            setCurrItem({
                link: data.link,
                attachment_payed: '1',
            });
        setModalContentIsOpen && setModalContentIsOpen(true);
    };

    const onDeleteItem = () => {
        new FilesApi().postDeleteMedia(data?.id, data?.external_id).then((res) => {
            if (res?.status) {
                dispatch(filesAction.getAllVideos());
            }
        });
    };

    const handleSort = () => {
        if (containerRef.current) {
            const listItems = containerRef.current?.parentNode?.querySelectorAll<HTMLElement>(
                '.upload_popup_tabs_content_item',
            );
            if (!listItems) return;
            const sortedArr: IUpdateSortOrderList[] = [];

            for (const [key, item] of listItems.entries()) {
                if (item?.dataset?.id) {
                    sortedArr.push({
                        id: +item.dataset.id,
                        sort_order: key,
                    });
                }
            }

            if (sortedArr?.length) {
                new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_VIDEO, sortedArr).then((res) => {
                    if (res?.status) {
                        dispatch(filesAction.setSortedFiles('videos', sortedArr));
                    }
                });
            }
        }
    };

    const makeCDN = (image: string, type = 'IMAGE') => {
        switch (type) {
            case 'VIDEO':
                return image?.replace(
                    AWS.AWS_CLOUDFRONT_VIDEO?.origin,
                    AWS.AWS_CLOUDFRONT_VIDEO?.cdn,
                );
            case 'AUDIO':
                return image?.replace(
                    AWS.AWS_CLOUDFRONT_AUDIO?.origin,
                    AWS.AWS_CLOUDFRONT_AUDIO?.cdn,
                );
            case 'IMAGE':
            default:
                return image?.replace(
                    AWS.AWS_CLOUDFRONT_IMAGE?.origin,
                    AWS.AWS_CLOUDFRONT_IMAGE?.cdn,
                );
        }
    };

    return (
        <div
            className="upload_popup_tabs_content_item"
            ref={containerRef}
            style={{ opacity }}
            data-id={data?.id}
        >
            <div
                className="no-drag upload_popup_tabs_content_item_top"
                onClick={handleInputChangeVideo}
                data-testid={data?.filename}
            >
                <input
                    type="checkbox"
                    className="no-drag"
                    name={`${data?.id}`}
                    onChange={handleInputChangeVideo}
                    data-link={data?.link}
                    data-filename={data?.filename}
                    id={'vp' + data?.id}
                />
                <label htmlFor={'vp' + data?.id} className="no-drag video_label">
                    {data?.thumb_link?.length ? (
                        <img className="popup_full_img" src={data?.thumb_link} alt="" />
                    ) : (
                        <video
                            width="158"
                            height="80"
                            id={'video-' + data?.id}
                            controls={false}
                            preload="metadata"
                        >
                            <source
                                src={`${makeCDN(data?.link, 'VIDEO')}#t=0.6`}
                                type="video/mp4"
                            />
                        </video>
                    )}
                </label>
            </div>
            <div
                className="upload_popup_tabs_content_item_bottom"
                ref={elementRef}
                onDrop={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
                onDragEnd={handleSort}
                onTouchStart={(e) => e.preventDefault()}
                onTouchMove={(e) => e.preventDefault()}
                onTouchEnd={handleSort}
            >
                <div className="popup_full_img" onClick={openContentModal}>
                    <IconPhotoFullSize />
                </div>
                <div className="popup_move">
                    <IconMove propStyles={{ cursor: 'grab' }} />
                </div>
                <div className="popup_time">
                    {cutLengthString(data.filename, LENGTH.ATTACH_VIDEO_TITLE)}
                </div>
                <div className="popup_trash" onClick={onDeleteItem}>
                    <IconTrash />
                </div>
            </div>
        </div>
    );
}
