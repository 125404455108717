import React, { Fragment } from 'react';
import { sequenceArray } from '../../../../services/time/DateUtils';

interface ISelectAge {
    handleOpen: () => void;
    opened: boolean;
    handleChange: (value: number | string) => void;
    value: number | string;
    defaultValue: string;
    dataTestId: string;
}

export default function SelectAge({
    handleOpen,
    opened,
    handleChange,
    value,
    defaultValue,
    dataTestId,
}: ISelectAge) {
    return (
        <div className="cl2h_tab_wide_filter_item_wrap" data-testid={dataTestId}>
            <div
                className={`cl2h_tab_wide_filter_item ${opened ? 'open' : ''}`}
                data-testid={`${dataTestId}-snapshot`}
                onClick={handleOpen}
            >
                <div
                    className={`like_select_head ${opened ? 'open' : ''} ${value ? 'selected' : ''}`}
                >
                    {+value > 0 ? value : value?.toString()?.includes('+') ? value : defaultValue}
                </div>
                {opened && (
                    <div className="like_select_list_wrap open">
                        <div className="like_select_list open" data-testid="select-list">
                            {['', ...sequenceArray(18, 70), '70+'].map((item, index) => {
                                return (
                                    <Fragment key={`height_list${index}`}>
                                        <input
                                            type="radio"
                                            name={`like_sel-height_list-${index}`}
                                            id={`like_sel-height_list-${index}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-height_list-${index}`}
                                            className="like_select_label"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleChange(item);
                                            }}
                                        >
                                            {item || '—'}
                                        </label>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
