import { CountersActionTypes } from '../../constants/counters';
import ChatsApi from '../../../services/api/ChatsApi';
import MailboxApi from '../../../services/api/MailboxApi';
import { Dispatch } from 'redux';
import { CancelToken } from 'axios';

export function getChatCounter(userId: number, token?: CancelToken) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new ChatsApi().getCounters(userId, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: CountersActionTypes.CHAT_COUNTER_GET,
                data: res?.counters,
            });
        });
    };
}

// TODO: unused action
export function addUnreadCounter() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: CountersActionTypes.CHAT_ADD_UNREAD,
        });
    };
}

export function addLetterUnreadCounter() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: CountersActionTypes.LETTER_ADD_UNREAD,
        });
    };
}

export function getLetterCounter(userId: number) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new MailboxApi().getUnreadCount(userId).then((res) => {
            return dispatch({
                type: CountersActionTypes.LETTER_COUNTER_GET,
                data: res?.result,
            });
        });
    };
}
