import React, { useEffect, useState } from 'react';
import LoaderGif from '../../../components/common/LoaderGif';
import { useDispatch, useSelector } from 'react-redux';
import * as PURCHASE from '../../../constants/purchase';
import SecurionPayMethodForm from './SecurionPayMethodForm';
import * as userAction from '../../../store/actions/user';
import '../../../styles/payment/styles.css';
import styles from '../styles.module.scss';
import { PAYMENT_METHODS_CONFIG } from '../../../constants/purchase';
import * as purchaseAction from '../../../store/actions/purchase';
import GooglePayForm from './GooglePayForm';
import { sendErrToSentry } from '../../../services/sentry';
import { RootState } from '../../../index';
import {
    IPaymentFrameProps,
    ISecurionpayPaymentData,
    IStripePaymentData,
    IUniversePaymentData,
} from './index.interface';
import IconCircleInfo from '../../../components/Icons/IconCircleInfo';
import IconInfoDanger from '../../../components/Icons/IconInfoDanger';
import { IGooglePayPaymentData } from './GooglePayForm/index.interface';
import StripeForm from './StripeForm';

export default function PaymentFrame({
    paymentData,
    frameFormData,
    frameLink,
    isLoadedPaymentFrame,
    clientAcquiringToken,
    clientAcquiringApplePayToken,
    clientAcquiringGooglePayToken,
    getDefaultCreditCard,
    getDefaultCreditCardData,
    openManageCreditCardModal,
}: IPaymentFrameProps) {
    const userState = useSelector((state: RootState) => state.user);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch = useDispatch();

    const errorIndex = PAYMENT_METHODS_CONFIG[
        purchaseState.activePaymentMethod as keyof typeof PAYMENT_METHODS_CONFIG
    ]?.catchableErrors?.findIndex((i: string) => i?.includes(purchaseState.errorMassage));
    const recommendation =
        PAYMENT_METHODS_CONFIG[
            purchaseState.activePaymentMethod as keyof typeof PAYMENT_METHODS_CONFIG
        ]?.recommendations[errorIndex];

    const tryAgainAfterFail = () => {
        dispatch(purchaseAction.setIsPastaPaymentFailed(false));
        dispatch(userAction.isBuying(false));
    };

    useEffect(() => {
        if (clientAcquiringToken?.jwt) {
            try {
                const config = {
                    jwt: clientAcquiringToken?.jwt,
                    livestatus: clientAcquiringToken?.livestatus,
                    submitOnSuccess: false,
                    submitOnError: false,
                    submitOnCancel: false,
                    ...(clientAcquiringToken?.isToken ? { fieldsToSubmit: ['securitycode'] } : {}),
                };
                const st = window?.SecureTrading(config);
                st.Components();
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }
    }, [clientAcquiringToken]);

    useEffect(() => {
        if (clientAcquiringApplePayToken?.jwt) {
            try {
                if (window?.ApplePaySession) {
                    const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(
                        clientAcquiringApplePayToken?.merchantID,
                    );
                    promise.then(function (canMakePayments: boolean) {
                        if (canMakePayments) {
                            const st = window?.SecureTrading({
                                jwt: clientAcquiringApplePayToken?.jwt,
                            });
                            st.ApplePay({
                                buttonStyle: 'white-outline',
                                buttonText: 'plain',
                                merchantId: clientAcquiringApplePayToken?.merchantID,
                                placement: 'st-apple-pay',
                                paymentRequest: {
                                    countryCode: 'US',
                                    currencyCode: 'USD',
                                    merchantCapabilities: [
                                        'supports3DS',
                                        'supportsCredit',
                                        'supportsDebit',
                                    ],
                                    supportedNetworks: ['visa', 'masterCard', 'amex'],
                                    total: {
                                        label: 'By credits',
                                        amount: userState?.lastBuyCredits?.toString(),
                                    },
                                },
                            });
                        } else {
                            setErrorMessage('Does not have at least one provisioned card.');
                        }
                    });
                }
            } catch (error) {
                sendErrToSentry(error as Error);
                setErrorMessage((error as Error)?.message);
            }
        }
    }, [clientAcquiringApplePayToken]);

    useEffect(() => {
        if (clientAcquiringGooglePayToken?.jwt) {
            try {
                const st = window?.SecureTrading({
                    jwt: clientAcquiringGooglePayToken?.jwt,
                    livestatus: clientAcquiringGooglePayToken?.livestatus,
                    submitOnSuccess: false,
                    submitOnError: false,
                    submitOnCancel: false,
                });
                const googlepayData = {
                    buttonOptions: { buttonRootNode: 'st-google-pay' },
                    paymentRequest: {
                        allowedPaymentMethods: [
                            {
                                parameters: {
                                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                    allowedCardNetworks: [
                                        'AMEX',
                                        'DISCOVER',
                                        'JCB',
                                        'MASTERCARD',
                                        'VISA',
                                    ],
                                },
                                tokenizationSpecification: {
                                    type: 'PAYMENT_GATEWAY',
                                    parameters: {
                                        gateway: 'trustpayments',
                                        gatewayMerchantId:
                                            clientAcquiringGooglePayToken?.gatewayMerchantID,
                                    },
                                },
                                type: 'CARD',
                            },
                        ],
                        environment: +clientAcquiringGooglePayToken?.livestatus
                            ? 'PRODUCTION'
                            : 'TEST',
                        apiVersion: 2,
                        apiVersionMinor: 0,
                        merchantInfo: {
                            merchantId: clientAcquiringGooglePayToken?.merchantID,
                        },
                        transactionInfo: {
                            countryCode: 'US',
                            currencyCode: 'USD',
                            checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
                            totalPriceStatus: 'FINAL',
                            totalPrice: clientAcquiringGooglePayToken?.paymentAmount?.toString(),
                            displayItems: [
                                {
                                    label: 'Buy credits',
                                    price: clientAcquiringGooglePayToken?.paymentAmount?.toString(),
                                    type: 'LINE_ITEM',
                                    status: 'FINAL',
                                },
                            ],
                        },
                    },
                };
                st.GooglePay(googlepayData);
            } catch (error) {
                sendErrToSentry(error as Error);
                setErrorMessage('Not supported');
            }
        }
    }, [clientAcquiringGooglePayToken]);

    return (
        <div data-testid="payment-form">
            {(function () {
                if (isLoadedPaymentFrame || purchaseState.isPaymentPastaFailed) {
                    switch (purchaseState.activePaymentMethod) {
                        case PURCHASE.PAYMENT_METHOD_STRIPE: {
                            const clientSecret = (paymentData as IStripePaymentData)?.clientSecret;
                            const isDefaultCardExist = !!getDefaultCreditCard(
                                PURCHASE.PAYMENT_METHOD_STRIPE,
                            );

                            if (!clientSecret) return null;
                            return (
                                <StripeForm
                                    clientSecret={clientSecret}
                                    isDefaultCardExist={isDefaultCardExist}
                                />
                            );
                        }

                        case PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY:
                            return (
                                <div className={styles.google_pay_wrap}>
                                    <GooglePayForm
                                        paymentData={paymentData as IGooglePayPaymentData}
                                        method={PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY}
                                    />
                                </div>
                            );
                        case PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY:
                            return (
                                <div className={styles.google_pay_wrap}>
                                    <GooglePayForm
                                        paymentData={paymentData as IGooglePayPaymentData}
                                        method={PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY}
                                    />
                                </div>
                            );

                        case PURCHASE.PAYMENT_METHOD_SECURIONPAY:
                            return (
                                <SecurionPayMethodForm
                                    publicKey={(paymentData as ISecurionpayPaymentData)?.publicKey}
                                    amount={(paymentData as ISecurionpayPaymentData)?.amount}
                                    orderID={(paymentData as ISecurionpayPaymentData)?.orderID}
                                    getDefaultCreditCardData={getDefaultCreditCardData}
                                    getDefaultCreditCard={getDefaultCreditCard}
                                    openManageCreditCardModal={openManageCreditCardModal}
                                />
                            );

                        case PURCHASE.PAYMENT_METHOD_ACQUIRING_GOOGLE_PAY:
                            return errorMessage ? (
                                <div className={styles.error_message}>{errorMessage}</div>
                            ) : (
                                <div className={styles.google_pay_wrap}>
                                    <div id="st-notification-frame" />
                                    <form id="st-form" action="/chat" />
                                    <div id="st-google-pay" />
                                </div>
                            );
                        case PURCHASE.PAYMENT_METHOD_ACQUIRING_APPLE_PAY:
                            return errorMessage ? (
                                <div className={styles.error_message}>{errorMessage}</div>
                            ) : (
                                <>
                                    <div id="st-notification-frame" />
                                    <form id="st-form" action="https://www.example.com" />
                                    <div id="st-apple-pay" />
                                </>
                            );
                        case PURCHASE.PAYMENT_METHOD_ACQUIRING:
                            return (
                                <>
                                    {clientAcquiringToken?.isToken && (
                                        <div
                                            className={`${styles.new_payment_method_chosen_card} ${styles.active}`}
                                        >
                                            <span>
                                                {getDefaultCreditCard(
                                                    PURCHASE.PAYMENT_METHOD_ACQUIRING,
                                                )}
                                            </span>
                                            <div onClick={openManageCreditCardModal}>Change</div>
                                        </div>
                                    )}
                                    <div className="trust_form">
                                        <div id="st-notification-frame" />
                                        <form id="st-form" action="" method="POST">
                                            {!clientAcquiringToken?.isToken && (
                                                <div id="st-card-number" />
                                            )}
                                            {!clientAcquiringToken?.isToken && (
                                                <div id="st-expiration-date" />
                                            )}
                                            <div id="st-security-code" />
                                            <button type="submit" disabled>
                                                Loading...
                                            </button>
                                        </form>
                                    </div>
                                </>
                            );

                        case PURCHASE.PAYMENT_METHOD_PASTABANK:
                            return purchaseState.isPaymentPastaFailed ? (
                                <>
                                    <div className={styles.payment_failed_wrapper}>
                                        <p className={styles.payment_failed_title}>
                                            <IconCircleInfo />
                                            Your Payment has failed
                                        </p>

                                        <p className={styles.payment_failed_text}>
                                            Dear user, you’ve received an error while trying make a
                                            payment via{' '}
                                            {
                                                PAYMENT_METHODS_CONFIG[
                                                    purchaseState.activePaymentMethod as keyof typeof PAYMENT_METHODS_CONFIG
                                                ]?.title
                                            }
                                            .
                                        </p>

                                        <p className={styles.payment_failed_error}>
                                            <IconInfoDanger />
                                            {purchaseState?.errorMassage}
                                        </p>

                                        {recommendation && (
                                            <div className={styles.payment_failed_recomendation}>
                                                <p
                                                    className={
                                                        styles.payment_failed_recomendation_title
                                                    }
                                                >
                                                    Recomended:
                                                </p>
                                                <p
                                                    className={
                                                        styles.payment_failed_recomendation_content
                                                    }
                                                >
                                                    {recommendation}
                                                </p>
                                            </div>
                                        )}

                                        <div
                                            className={styles.payment_failed_tryAgain}
                                            onClick={tryAgainAfterFail}
                                        >
                                            <span>Try again</span>
                                        </div>

                                        <p className={styles.payment_failed_info}>
                                            Our manager will contact you within couple of minutes to
                                            assist you with the payment.
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <form
                                        action={frameLink}
                                        target="credits-iframe"
                                        id="form-credit"
                                        method={'post'}
                                    >
                                        <input
                                            type="hidden"
                                            name="INTERFACE"
                                            value={frameFormData?.INTERFACE ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="KEY_INDEX"
                                            value={frameFormData?.KEY_INDEX ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="KEY"
                                            value={frameFormData?.KEY ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="DATA"
                                            value={frameFormData?.DATA ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="SIGNATURE"
                                            value={frameFormData?.SIGNATURE ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="CALLBACK"
                                            value={frameFormData?.CALLBACK ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="ERROR_CALLBACK"
                                            value={frameFormData?.ERROR_CALLBACK ?? ''}
                                        />
                                    </form>
                                    <iframe
                                        id="frame-credit"
                                        title={'Iframe'}
                                        name="credits-iframe"
                                        style={{ border: 'none', overflow: 'hidden' }}
                                        width={'100%'}
                                        height={'1100px'}
                                    />
                                </>
                            );

                        case PURCHASE.PAYMENT_METHOD_UNIVERSEPAY:
                        case PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING:
                            if (!(paymentData as IUniversePaymentData)?.checkout?.redirect_url) {
                                return <LoaderGif />;
                            }

                            return (
                                <>
                                    <iframe
                                        id="frame-credit"
                                        title={'Iframe'}
                                        name="credits-iframe"
                                        src={
                                            (paymentData as IUniversePaymentData)?.checkout
                                                ?.redirect_url
                                        }
                                        style={{ border: 'none', overflow: 'hidden' }}
                                        width={'100%'}
                                        height={'1100px'}
                                    />
                                </>
                            );

                        default:
                            return (
                                <>
                                    <form
                                        action={frameLink}
                                        target="credits-iframe"
                                        id="form-credit"
                                        method={'post'}
                                    >
                                        <input
                                            type="hidden"
                                            name="INTERFACE"
                                            value={frameFormData?.INTERFACE ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="KEY_INDEX"
                                            value={frameFormData?.KEY_INDEX ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="KEY"
                                            value={frameFormData?.KEY ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="DATA"
                                            value={frameFormData?.DATA ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="SIGNATURE"
                                            value={frameFormData?.SIGNATURE ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="CALLBACK"
                                            value={frameFormData?.CALLBACK ?? ''}
                                        />
                                        <input
                                            type="hidden"
                                            name="ERROR_CALLBACK"
                                            value={frameFormData?.ERROR_CALLBACK ?? ''}
                                        />
                                    </form>
                                    <iframe
                                        id="frame-credit"
                                        title={'Iframe'}
                                        name="credits-iframe"
                                        style={{ border: 'none', overflow: 'hidden' }}
                                        width={'100%'}
                                        height={'1100px'}
                                    />
                                </>
                            );
                    }
                } else {
                    return <LoaderGif />;
                }
            })()}
        </div>
    );
}
