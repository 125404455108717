import { ConfigActionTypes } from '../../constants/config';
import ConfigApi from '../../../services/api/ConfigApi';
import { Dispatch } from 'redux';

export function initConfigByExternalID() {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new ConfigApi().getConfigByExternalID().then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: ConfigActionTypes.INIT_CONFIG,
                data: res?.result,
            });
        });
    };
}
