import React, { useState } from 'react';
import styles from '../styles.module.scss';
import { cutLengthString, getReadableFileSizeString } from '../../../../../../services/methods';
import IconCross from '../../../../../Icons/IconCross';
import UploadedPhoto from '../UploadedPhoto';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import { IUserSelfieBlockProps } from './index.interface';

export default function UserSelfieBlock({
    handleChangeBlock,
    handleInput,
    deleteUploadedFile,
    isWarning,
    isUserSelfieFileApproved,
    setIsUserSelfieFileApproved,
}: IUserSelfieBlockProps) {
    const docsState = useSelector((state: RootState) => state.profile.docsState);
    const [isOpenPhoto, setIsOpenPhoto] = useState<boolean>(false);

    const handleOpenPhoto = () => {
        if (docsState?.userSelfieFile?.name) {
            setIsOpenPhoto((prev) => !prev);
        }
    };

    const deleteFile = () => {
        setIsUserSelfieFileApproved(false);
        deleteUploadedFile('userSelfieFile');
    };
    return (
        <>
            <h3 className={`${styles.block_title} ${styles.user_id_title}`}>
                Take a selfie with the payment card
            </h3>
            <div className={styles.requirements_details}>
                <p className={styles.block_text}>
                    Take a photo of yourself with the payment card that you previously used here in
                    your arms.
                </p>
                <ul className={`${styles.requirements_list} ${styles.requirements_list_selfie}`}>
                    <li
                        className={`${styles.requirements_item} ${styles.requirements_item_correct}`}
                    >
                        Make sure your whole face is visible, centred, and your eyes are open
                    </li>
                    <li
                        className={`${styles.requirements_item} ${styles.requirements_item_incorrect}`}
                    >
                        Do not crop your card or use screenshots of your card
                    </li>
                </ul>
                <p className={styles.block_text}>Do not use a photo with:</p>
                <ul className={`${styles.examples_list} ${styles.examples_list_selfie}`}>
                    <li className={`${styles.examples_item} ${styles.examples_item_glasses}`}>
                        Glasses
                    </li>
                    <li className={`${styles.examples_item} ${styles.examples_item_hat}`}>Hats</li>
                    <li className={`${styles.examples_item} ${styles.examples_item_mask}`}>
                        Masks
                    </li>
                </ul>
            </div>
            <form className={`${styles.form} ${isWarning ? styles.form_error : ''}`}>
                <p
                    className={`${styles.form_info} ${isUserSelfieFileApproved ? styles.form_info_success : ''}`}
                >
                    {isUserSelfieFileApproved
                        ? 'The documents have been approved, and there’s no need to re-upload them. However, if you wish to, we will review them again.'
                        : 'File size must be between 10KB and 5120KB in jpg/jpeg/png format.'}
                </p>
                {(docsState?.userSelfieFile && docsState?.userSelfieFile?.name) ||
                isUserSelfieFileApproved ? (
                    <div className={styles.form_file}>
                        <button
                            type="button"
                            disabled={isUserSelfieFileApproved}
                            onClick={handleOpenPhoto}
                            className={styles.form_file_info}
                        >
                            <p className={styles.form_file_name}>
                                {isUserSelfieFileApproved
                                    ? 'Previously approved file'
                                    : cutLengthString(docsState?.userSelfieFile?.name, 30)}
                            </p>
                            {docsState?.userSelfieFile?.size && (
                                <p
                                    className={styles.form_file_size}
                                >{`(${getReadableFileSizeString(docsState?.userSelfieFile?.size)})`}</p>
                            )}
                        </button>
                        <button
                            type="button"
                            className={styles.form_file_delete}
                            onClick={deleteFile}
                        >
                            <IconCross />
                        </button>
                    </div>
                ) : (
                    <>
                        <input
                            type="file"
                            name="userSelfieFile"
                            id="popup_user_id_input"
                            className={styles.form_input}
                            onChange={handleInput}
                            accept=".jpg, .jpeg, .png"
                            multiple={false}
                        />
                        <label htmlFor="popup_user_id_input" className={styles.form_label}>
                            <span>Upload photo</span>
                        </label>
                    </>
                )}
            </form>
            {isOpenPhoto ? (
                <UploadedPhoto
                    image={docsState?.userSelfieFile}
                    onClose={() => setIsOpenPhoto(false)}
                />
            ) : null}
            <button
                type="button"
                disabled={!docsState?.userSelfieFile?.name && !isUserSelfieFileApproved}
                className={styles.block_button_main}
                onClick={() => handleChangeBlock()}
            >
                Continue
            </button>
        </>
    );
}
