import Base from './Base';

export default class AccountApi extends Base {
    async postBuyCredits(token: string, credit: number) {
        const res = await super.post('v3/payment/pastabank/credits', {
            token: token,
            credits: credit,
        });
        return res?.data;
    }

    async postPastaRecurring(token: string, credit: number) {
        const res = await super.post('v3/payment/pastabank/subscription-create', {
            token: token,
            credits: credit,
        });
        return res?.data;
    }

    async postNewCardPastaRecurring(token: string, credit: number) {
        const res = await super.post('v3/payment/pastabank/recurring/form', {
            token: token,
            credits: credit,
        });
        return res?.data;
    }

    async postTokenList(token = null) {
        const res = await super.get(`v3/payment/token/all`, token ? { cancelToken: token } : {});
        return res?.data;
    }

    async postDefaultToken(tokenId: number, paymentMethod: string) {
        const res = await super.put(`v3/payment/token/default`, {
            token_id: tokenId,
            payment_method: paymentMethod,
        });
        return res?.data;
    }

    async postDeleteToken(tokenId: number) {
        return super.delete(`v3/payment/token/${tokenId}`).then((res) => res?.data);
    }

    async postCreateToken(isBuyCredits = 0) {
        const res = await super.post('v3/payment/pastabank/token', { isBuyCredits });
        return res?.data;
    }
}
