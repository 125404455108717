import React from 'react';

export default function IconPolicyTopUp() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#00317B"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M21.2896 4.75894C20.8599 4.71977 20.4798 5.03637 20.4407 5.46605L20.3371 6.60156C18.5809 3.84352 15.5323 2 12 2C8.19992 2 4.85105 4.16883 3.18863 7.42398C2.99238 7.80824 3.1448 8.27883 3.52906 8.47508C3.91339 8.67133 4.3839 8.51894 4.58015 8.13465C5.98707 5.3798 8.81234 3.5625 12 3.5625C14.9053 3.5625 17.5273 5.07543 19.0294 7.44562L17.9154 6.65004C17.5643 6.3993 17.0764 6.48062 16.8256 6.83172C16.5749 7.18285 16.6562 7.67074 17.0073 7.92152L20.3852 10.3341C20.8803 10.687 21.5632 10.3639 21.6173 9.76926L21.9966 5.60793C22.0359 5.1782 21.7193 4.79812 21.2896 4.75894Z" />
            <path d="M20.4709 15.5249C20.0866 15.3287 19.616 15.4811 19.4198 15.8654C18.0128 18.6202 15.1876 20.4375 12 20.4375C9.09461 20.4375 6.47265 18.9246 4.97054 16.5544L6.08449 17.35C6.43562 17.6007 6.92351 17.5194 7.17429 17.1683C7.42504 16.8171 7.34371 16.3293 6.99261 16.0785L3.61468 13.6659C3.12566 13.3166 2.4373 13.63 2.38261 14.2307L2.00328 18.3921C1.9641 18.8218 2.2807 19.2018 2.71035 19.241C3.14058 19.2801 3.52015 18.9632 3.55929 18.5339L3.66281 17.3984C5.41898 20.1565 8.46757 22 12 22C15.8 22 19.1489 19.8312 20.8113 16.576C21.0075 16.1918 20.8551 15.7212 20.4709 15.5249Z" />
        </svg>
    );
}
