import React, { MouseEventHandler, useState } from 'react';
import styles from '../styles.module.scss';
import PropTypes from 'prop-types';
import IconCross from '../../../../../Icons/IconCross';

UploadedPhoto.propTypes = {
    onClose: PropTypes.func,
    image: PropTypes.object,
};
export default function UploadedPhoto({
    onClose,
    image,
}: {
    onClose: MouseEventHandler<HTMLButtonElement>;
    image: any;
}) {
    const [loadedImage, setLoadedImage] = useState<string>('');
    const handleImageUpload = () => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setLoadedImage(e.target?.result as string);
        };
        reader.readAsDataURL(image);
    };

    handleImageUpload();

    return (
        <div className={styles.uploaded_photo_wrap}>
            <button
                type="button"
                className={`${styles.close_btn} ${styles.close_btn_photo}`}
                onClick={onClose}
            >
                <IconCross />
            </button>
            <img src={loadedImage} alt="Uploaded photo" className={styles.uploaded_photo} />
        </div>
    );
}
