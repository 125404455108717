import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import ContentAttachModal from '../Modals/ContentAttachModal/ImgContent';
import VideoContentAttachModal from '../Modals/ContentAttachModal/VideoContent';
import LetterAudioPlayer from '../LetterAudioPlayer';
import MailboxApi from '../../../services/api/MailboxApi';
import { formatDate } from '../../../services/time';
import * as mailboxAction from '../../../store/actions/mailbox';
import * as currentChatMailboxAction from '../../../store/actions/currentChatMailbox';
import * as alertAction from '../../../store/actions/alert';
import * as ERROR from '../../../constants/error';
import * as ALERT from '../../../constants/alert';
import * as LETTERS from '../../../constants/letters';
import { stopAudio } from '../../../services/methods/chat';
import * as userAction from '../../../store/actions/user';
import SITE from '../../../config/site';
import { RootState } from '../../../index';
import { ISwitchLetterAttachmentProps } from './index.interface';
import { IProfileGiftCurrentItem } from '../../Profile/ProfileGifts/index.interface';

export default function SwitchLetterAttachment({
    data,
    isFreeToWatch,
    letterID,
}: ISwitchLetterAttachmentProps) {
    const dispatch = useDispatch();

    const userState = useSelector((state: RootState) => state.user);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);

    const [modalContentIsOpen, setModalContentIsOpen] = useState<boolean>(false);
    const [mainItem, setMainItem] = useState<IProfileGiftCurrentItem | null>(null);
    const [messageIdArr, setMessageIdArr] = useState<number[] | never[]>([]);
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

    const openContentModal = () => {
        if (
            +data?.attachment_payed === 0 &&
            +data?.attachment_free === 1 &&
            data?.message_type === LETTERS.IMG_MAIL
        ) {
            onViewPhoto();
        }
        setModalContentIsOpen(true);
    };
    const closeContentModal = () => setModalContentIsOpen(false);

    const checkBeforeBuyingMessageContent = (type: string, callback: () => void) => {
        if (+data?.attachment_free === 1 || SITE.ID === 4) return callback();

        if (userState?.info && +userState?.info?.convert_user) callback();
        else
            dispatch(
                alertAction.setCustomAlertType({
                    alertType: type,
                    successCallback: () => callback(),
                }),
            );
    };

    const onViewPhoto = () => {
        if (data?.attachment_id && userState?.info?.external_id) {
            if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.attachment_id)) {
                return false;
            }

            const newMessageIdArr = [...messageIdArr, data?.attachment_id];
            setMessageIdArr([...newMessageIdArr]);
            setIsLoadingRequest(true);

            new MailboxApi()
                .postReadImage({
                    userId: userState?.info?.external_id,
                    attachmentId: data?.attachment_id,
                })
                .then((res) => {
                    if (res?.status) {
                        dispatch(userAction.setBalance());

                        if (currentChatMailboxState?.info?.id) {
                            dispatch(
                                currentChatMailboxAction.currentLetterRoomPaidLetterContent(
                                    letterID,
                                    data?.attachment_id,
                                    res?.message_content,
                                ),
                            );
                        } else {
                            dispatch(
                                mailboxAction.paidLetterContent(
                                    letterID,
                                    data?.attachment_id,
                                    res?.message_content,
                                ),
                            );
                        }

                        if (res?.message_content)
                            setMainItem({ attachment_payed: 1, link: res?.message_content });
                    } else {
                        closeContentModal();
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                                title: res?.title,
                            }),
                        );
                        // dispatch(alertAction.setCustomAlertType({alertType: ALERT.ENOUGH_CREDITS_TO_VIEW}));
                    }
                })
                .finally(() => {
                    setIsLoadingRequest(false);
                    setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.attachment_id));
                });
        }
    };

    const onPayAudio = () => {
        if (data?.attachment_id && userState?.info?.external_id && letterID) {
            if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.attachment_id)) {
                return false;
            }

            const newMessageIdArr = [...messageIdArr, data?.attachment_id];
            setMessageIdArr([...newMessageIdArr]);
            setIsLoadingRequest(true);

            new MailboxApi()
                .postReadAudio({
                    userId: userState?.info?.external_id,
                    attachmentId: data?.attachment_id,
                })
                .then((res) => {
                    if (res?.status) {
                        dispatch(userAction.setBalance());

                        if (currentChatMailboxState?.info?.id) {
                            dispatch(
                                currentChatMailboxAction.currentLetterRoomPaidLetterContent(
                                    letterID,
                                    data?.attachment_id,
                                    res?.message_content,
                                ),
                            );
                        } else {
                            dispatch(
                                mailboxAction.paidLetterContent(
                                    letterID,
                                    data?.attachment_id,
                                    res?.message_content,
                                ),
                            );
                        }
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                                title: res?.title,
                            }),
                        );
                    }
                })
                .finally(() => {
                    setIsLoadingRequest(false);
                    setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.attachment_id));
                });
        }
    };

    const onPayVideo = () => {
        if (data?.attachment_id && userState?.info?.external_id && letterID) {
            if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.attachment_id)) {
                return false;
            }

            const newMessageIdArr = [...messageIdArr, data?.attachment_id];
            setMessageIdArr([...newMessageIdArr]);
            setIsLoadingRequest(true);

            new MailboxApi()
                .postReadVideo({
                    userId: userState?.info?.external_id,
                    attachmentId: data?.attachment_id,
                })
                .then((res) => {
                    if (res?.status) {
                        dispatch(userAction.setBalance());

                        if (currentChatMailboxState?.info?.id) {
                            dispatch(
                                currentChatMailboxAction.currentLetterRoomPaidLetterContent(
                                    letterID,
                                    data?.attachment_id,
                                    res?.message_content,
                                ),
                            );
                        } else {
                            dispatch(
                                mailboxAction.paidLetterContent(
                                    letterID,
                                    data?.attachment_id,
                                    res?.message_content,
                                ),
                            );
                        }

                        if (res?.message_content) onViewVideo(res?.message_content);
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                                title: res?.title,
                            }),
                        );
                    }
                })
                .finally(() => {
                    setIsLoadingRequest(false);
                    setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.attachment_id));
                });
        }
    };

    const onViewAudio = () => {
        if (+data?.attachment_payed === 0 && +data?.attachment_free === 1 && userState?.info) {
            new MailboxApi().postReadAudio({
                userId: userState?.info?.external_id,
                attachmentId: data?.attachment_id,
            });
        }

        stopAudio(data?.link, true, data?.attachment_id);
        postViewMedia(data?.attachment_id);
    };

    const onViewVideo = (link: string) => {
        setMainItem({ attachment_payed: 1, link });
        openContentModal();

        if (!isFreeToWatch) postViewMedia(data?.attachment_id);
    };

    const postViewMedia = (id: number) => {
        if (id) new MailboxApi().postViewMedia(id);
    };

    if (data?.message_type === LETTERS.IMG_MAIL) {
        const imgLink =
            data?.attachment_payed === 0 && data?.attachment_free === 0
                ? data?.thumb_link
                : data?.link;
        return (
            <>
                <div
                    className={`letters_attach_files_item file_photo ${+data?.attachment_payed === 0 ? '' : ''}`}
                    onClick={openContentModal}
                    data-testid={data.title}
                >
                    <img src={imgLink || data?.link} alt="" />
                </div>
                {!!(mainItem || data) && (
                    <Modal
                        style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                        isOpen={modalContentIsOpen}
                        onRequestClose={closeContentModal}
                        ariaHideApp={false}
                    >
                        <ContentAttachModal
                            isFreeToWatch={isFreeToWatch}
                            item={mainItem || data}
                            image={data?.link || data?.thumb_link}
                            closeModal={closeContentModal}
                            onViewPhoto={onViewPhoto}
                        />
                    </Modal>
                )}
            </>
        );
    }

    if (data?.message_type === LETTERS.VIDEO_MAIL) {
        return (
            <>
                {isFreeToWatch ? (
                    <div
                        className={`letters_attach_files_item file_video`}
                        onClick={() => onViewVideo(data?.link)}
                        data-testid={data.title}
                    >
                        <div className="file_video_overflow">
                            <div className="file_video_inside">
                                {data?.thumb_link ? (
                                    <img className="popup_full_img" src={data?.thumb_link} alt="" />
                                ) : (
                                    data?.link && (
                                        <video
                                            width="96"
                                            height="56"
                                            controls={false}
                                            preload="metadata"
                                        >
                                            <source src={`${data?.link}#t=0.6`} type="video/mp4" />
                                        </video>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={`letters_attach_files_item file_video ${isLoadingRequest ? 'blocked' : ''}`}
                        onClick={() =>
                            +data?.attachment_payed === 0
                                ? checkBeforeBuyingMessageContent(ALERT.VIDEO_MESSAGE_CONTENT, () =>
                                      onPayVideo(),
                                  )
                                : onViewVideo(data?.link)
                        }
                        data-testid={data.title}
                    >
                        <div className="file_video_overflow">
                            <div
                                className={
                                    +data?.attachment_payed === 0 && +data?.attachment_free === 0
                                        ? 'not-payed file_video_inside'
                                        : 'file_video_inside'
                                }
                            >
                                {data?.thumb_link ? (
                                    <img className="popup_full_img" src={data?.thumb_link} alt="" />
                                ) : (
                                    data?.link && (
                                        <video
                                            width="96"
                                            height="56"
                                            controls={false}
                                            preload="metadata"
                                        >
                                            <source src={`${data?.link}#t=0.6`} type="video/mp4" />
                                        </video>
                                    )
                                )}
                            </div>
                        </div>
                        {+data?.attachment_payed === 1 &&
                            (data?.ma_date_viewed === null ? (
                                +data?.attachment_free === 0 && (
                                    <div className="media_file_status st_paid">Paid</div>
                                )
                            ) : (
                                <div className="media_file_status st_viewed">
                                    <span>Viewed {formatDate(data?.ma_date_viewed)}</span>
                                </div>
                            ))}
                    </div>
                )}
                <Modal
                    style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                    isOpen={modalContentIsOpen}
                    onRequestClose={closeContentModal}
                    ariaHideApp={false}
                >
                    <VideoContentAttachModal
                        // @ts-expect-error item prop is null
                        item={mainItem}
                        onViewVideo={() => onViewVideo(data?.link)}
                        closeModal={closeContentModal}
                    />
                </Modal>
            </>
        );
    }

    if (data?.message_type === LETTERS.AUDIO_MAIL) {
        return (
            <>
                {+data?.attachment_payed === 0 && +data?.attachment_free === 0 ? (
                    <div
                        className={`letters_attach_files_item file_audio ${isLoadingRequest ? 'blocked' : ''}`}
                        onClick={() => {
                            checkBeforeBuyingMessageContent(
                                ALERT.AUDIO_MESSAGE_CONTENT,
                                onPayAudio,
                            );
                        }}
                        data-testid={data.title}
                    >
                        <div className="letters_attach_files_item_play">
                            {/*<div className="letters_attach_files_item_play_title">*/}
                            {/*    <img src="../../../img/letter-audio-notes.svg" alt="" />*/}
                            {/*    <span>*/}
                            {/*        {cutLengthString(data?.title, LENGTH.ATTACH_LETTER_AUDIO_TITLE)}*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                            <LetterAudioPlayer
                                src={''}
                                onPayAudio={() => {
                                    checkBeforeBuyingMessageContent(
                                        ALERT.AUDIO_MESSAGE_CONTENT,
                                        onPayAudio,
                                    );
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className="letters_attach_files_item file_audio"
                        id={`lett-${data?.attachment_id}`}
                        data-testid={data?.title}
                    >
                        <div className="letters_attach_files_item_play">
                            {/*<div className="letters_attach_files_item_play_title">*/}
                            {/*    <img src="../../../img/letter-audio-notes.svg" alt="" />*/}
                            {/*    <span>*/}
                            {/*        {cutLengthString(data?.title, LENGTH.ATTACH_LETTER_AUDIO_TITLE)}*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                            <LetterAudioPlayer
                                src={data?.link}
                                onViewMedia={!isFreeToWatch ? onViewAudio : undefined}
                            />
                        </div>
                        {!isFreeToWatch &&
                            (data?.ma_date_viewed === null ? (
                                +data?.attachment_free === 0 && (
                                    <div className="media_file_status st_paid">Paid</div>
                                )
                            ) : (
                                <div className="media_file_status st_viewed">
                                    <span>Listened {formatDate(data?.ma_date_viewed)}</span>
                                </div>
                            ))}
                    </div>
                )}
            </>
        );
    }

    return <div />;
}
