import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import tut_step_6_girl1 from '../../../../../../img/tutorial/tut_step_6_girl1.png';
import tut_step_6_girl2 from '../../../../../../img/tutorial/tut_step_6_girl2.png';
import tut_step_6_man1 from '../../../../../../img/tutorial/tut_step_6_man1.png';
import tut_step_6_man2 from '../../../../../../img/tutorial/tut_step_6_man2.png';
import tut_step_1_girl from '../../../../../../img/tutorial/tut_step_1_girl.png';
import tut_step_1_man from '../../../../../../img/tutorial/tut_step_1_man.png';
import tut_step_3_girl_1 from '../../../../../../img/tutorial/tut_step_3_girl_1.png';
import tut_step_3_man_1 from '../../../../../../img/tutorial/tut_step_3_man_1.png';
import IconProfile from '../../../../../Icons/IconProfile';
import IconFAQQuestionArrow from '../../../../../Icons/IconFAQQuestionArrow';
import IconHeartMobile from '../../../../../Icons/IconHeartMobile';
import IconChat from '../../../../../Icons/IconChat';
import IconLikeHerHeartStroke from '../../../../../Icons/IconLikeHerHeartStroke';

export default function Step6() {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <>
            <div
                className={`${styles.tutorial_step_illustration} ${styles.step_notify} ${styles.desktop}`}
            >
                <div className={styles.step_notify_body}>
                    <div className={styles.step_notify_girls_slider}>
                        <ul className={styles.girls_slider_list}>
                            <li className={styles.girls_slider_list_item}>
                                <span className={styles.girls_slider_status}>Online</span>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_6_girl1}
                                        alt="Candidate"
                                        className={styles.girls_slider_img}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_6_man1}
                                        alt="Candidate"
                                        className={styles.girls_slider_img}
                                    />
                                )}
                            </li>
                            <li className={styles.girls_slider_list_item}>
                                <span className={styles.girls_slider_status}>Online</span>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_6_girl2}
                                        alt="Candidate"
                                        className={styles.girls_slider_img}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_6_man2}
                                        alt="Candidate"
                                        className={styles.girls_slider_img}
                                    />
                                )}
                            </li>
                        </ul>
                        <div className={styles.girls_slider_btn}>
                            <IconFAQQuestionArrow />
                        </div>
                    </div>
                    <div className={styles.step_notify_box} />
                </div>

                <div className={`${styles.step_notify_sidebar}`}>
                    <p className={styles.step_notify_sidebar_title}>Notifications</p>
                    <p className={styles.step_notify_sidebar_activity_title}>Member’s activity</p>
                    <div className={styles.matchbox}>
                        <p className={styles.matchbox_title}>You like each other</p>
                        <div className={styles.matchbox_btn_close} />
                        <div className={styles.matchbox_users_info}>
                            <div className={styles.matchbox_user_avatar}>
                                <IconProfile />
                            </div>
                            <div className={styles.matchbox_icon}>
                                <div className={styles.match_heart_icon}>
                                    <IconHeartMobile />
                                </div>
                            </div>
                            <div className={styles.matchbox_user_avatar}>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_1_girl}
                                        alt="Candidate"
                                        className={styles.matchbox_user_avatar_img}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_1_man}
                                        alt="Candidate"
                                        className={styles.matchbox_user_avatar_img}
                                    />
                                )}
                                <span className={styles.matchbox_user_status}></span>
                            </div>
                        </div>
                        <p className={styles.matchbox_time}>1:32 pm</p>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.tutorial_step_illustration} ${styles.step_notify} ${styles.mobile}`}
            >
                <div className={styles.step_notify_body}>
                    <div className={styles.card_candidates_card}>
                        <div className={styles.card_candidates_profile}>
                            {!Number(userState?.info?.gender) ? (
                                <img
                                    src={tut_step_3_girl_1}
                                    alt="Candidate"
                                    width="147"
                                    height="211"
                                    className={styles.card_candidates_photo}
                                />
                            ) : (
                                <img
                                    src={tut_step_3_man_1}
                                    alt="Candidate"
                                    width="147"
                                    height="211"
                                    className={styles.card_candidates_photo}
                                />
                            )}
                        </div>
                        <div className={styles.card_candidates_info}>
                            <div className={styles.card_candidates_info_wrap}>
                                <p className={styles.card_candidates_info_text}>Alena, 29</p>
                                <div className={styles.card_candidates_info_buttons}>
                                    <div className={styles.card_candidates_info_button_chat}>
                                        <div className={styles.card_candidates_info_button_icon}>
                                            <IconChat />
                                        </div>
                                        <p className={styles.card_candidates_info_button_chat_text}>
                                            Chat now
                                        </p>
                                    </div>
                                    <div className={styles.card_candidates_info_button_like}>
                                        <div className={styles.card_candidates_info_button_icon}>
                                            <IconLikeHerHeartStroke />
                                        </div>
                                        <p className={styles.card_candidates_info_button_like_text}>
                                            Like
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.step_notify_sidebar}`}>
                    <p className={styles.step_notify_sidebar_activity_title}>Member’s activity</p>
                    <div className={styles.matchbox}>
                        <p className={styles.matchbox_title}>You like each other</p>
                        <div className={styles.matchbox_btn_close} />
                        <div className={styles.matchbox_users_info}>
                            <div className={styles.matchbox_user_avatar}>
                                <IconProfile />
                            </div>
                            <span className={styles.matchbox_icon}>
                                <div className={styles.match_heart_icon}>
                                    <IconHeartMobile />
                                </div>
                            </span>
                            <div className={styles.matchbox_user_avatar}>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_1_girl}
                                        alt="Candidate"
                                        className={styles.matchbox_user_avatar_img}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_1_girl}
                                        alt="Candidate"
                                        className={styles.matchbox_user_avatar_img}
                                    />
                                )}
                                <span className={styles.matchbox_user_status}></span>
                            </div>
                        </div>
                        <p className={styles.matchbox_time}>1:32 pm</p>
                    </div>
                </div>
            </div>
            <div className={styles.tutorial_body}>
                <h2 className={styles.tutorial_step_title}>
                    Notifications will help you not to miss important info
                </h2>
                <p className={styles.tutorial_step_text}>
                    Attention: if you've liked a member's profile and he/she in turn has liked yours
                    you get matched. Start communicating with this user at once, something great may
                    be waiting for you!
                </p>
            </div>
        </>
    );
}
