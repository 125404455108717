import Base from './Base';

export default class VirtualGiftsApi extends Base {
    async getVirtualGiftsData() {
        try {
            const res = await super.post(`virtual/gift/group/all`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
