import Modal from 'react-modal';
import React from 'react';

import styles from './styles.module.scss';

import MeetingRequest from './MeetingRequest';
import { IMeetingRequestModalProps } from './index.interface';

export default function MeetingRequestModal({
    modalIsActiveMeeting,
    closeMeetingModal,
    profile,
    submitMeetingRequest,
}: IMeetingRequestModalProps) {
    return (
        <Modal
            overlayClassName={styles.ReactModal__Overlay}
            className={styles.ReactModal__Content}
            isOpen={modalIsActiveMeeting}
            onRequestClose={closeMeetingModal}
            ariaHideApp={false}
        >
            <MeetingRequest
                closeMeetingModal={closeMeetingModal}
                profile={profile}
                submitMeetingRequest={submitMeetingRequest}
            />
        </Modal>
    );
}
