import React from 'react';

export default function IconVideos() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 7.98463C2.5 6.34137 3.84137 5 5.48463 5H14.803C16.4414 5 17.7876 6.31414 17.7876 7.98463V8.3022L19.7737 7.15642C19.8854 7.08939 20.0195 7.02235 20.1536 7C21.0251 6.79888 21.8072 7.46927 21.8072 8.29608V15.2011C21.8072 15.4469 21.7401 15.6704 21.6284 15.8715C21.2485 16.5195 20.4441 16.743 19.796 16.3631L17.7876 15.2118V15.5153C17.7876 17.1586 16.4463 18.4999 14.803 18.4999H5.48463C3.84137 18.4999 2.5 17.1586 2.5 15.5153V7.98463ZM4 7.98463C4 7.1698 4.6698 6.5 5.48463 6.5H14.803C15.6228 6.5 16.2876 7.15233 16.2876 7.98463V9.16759V14.3519V15.5153C16.2876 16.3302 15.6179 16.9999 14.803 16.9999H5.48463C4.6698 16.9999 4 16.3302 4 15.5153V7.98463ZM17.7876 10.0339L20.3072 8.58036V14.9271L17.7876 13.4828V10.0339Z"
            />
        </svg>
    );
}
