import React, { ChangeEvent, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import DropZone from 'react-dropzone';
import '../../../styles/attach/attach-new.scss';
import UploadImageModal from './UploadImageModal';
import UploadVideoModal from './UploadVideoModal';
import UploadAudioModal from './UploadAudioModal';
import ListVideos from './ListVideos';
import ListImages from './ListImages';
import ListAudios from './ListAudios';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import { checkFileAndShowAlert, convertPrice } from '../../../services/methods';
import awsUploadFile from '../../../services/aws/uploadFile';
import FilesAPI from '../../../services/api/FilesApi';
import * as filesAction from '../../../store/actions/files';
import * as alertAction from '../../../store/actions/alert';
import * as AWS from '../../../constants/aws';
import * as ATTACH from '../../../constants/attach';
import * as LENGTH from '../../../constants/length';
import * as PRICES from '../../../constants/prices';
import * as ERROR from '../../../constants/error';
import VideoConverteApi from '../../../services/api/v3/VideoConverte';
import SITE from '../../../config/site';
import { isTouchDevice } from '../../../services/utils';
import { sendErrToSentry } from '../../../services/sentry';
import { RootState } from '../../../index';
import { IAttachModalProps, IUploadProps } from './index.interface';
import IconUploadAttachment from '../../Icons/IconUploadAttachment';
import IconSendAttachment from '../../Icons/IconSendAttachment';
import IconDropAttachment from '../../Icons/IconDropAttachment';
import IconMove from '../../Icons/IconMove';
import IconCross from '../../Icons/IconCross';

export default function AttachModal({
    sendImg,
    sendVideo,
    sendAudio,
    closeModal,
}: IAttachModalProps) {
    const dispatch = useDispatch();

    const filesState = useSelector((state: RootState) => state.files);
    const userState = useSelector((state: RootState) => state.user);

    const [modalIsOpenImg, setModalIsOpenImg] = useState<boolean>(false);
    const [modalIsOpenVideo, setModalIsOpenVideo] = useState<boolean>(false);
    const [modalIsOpenAudio, setModalIsOpenAudio] = useState<boolean>(false);
    const [imgFile, setImgFile] = useState<File | Record<string, never>>({});
    const [videoFile, setVideoFile] = useState<File | Record<string, never>>({});
    const [audioFile, setAudioFile] = useState<File | Record<string, never>>({});
    const [imageLoadingStatus, setImageLoadingStatus] = useState<boolean>(false);
    const [videoLoadingStatus, setVideoLoadingStatus] = useState<boolean>(false);
    const [audioLoadingStatus, setAudioLoadingStatus] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(ATTACH.TAB_IMAGE);

    const isNewMail = userState?.info?.newMail;

    const openModalImg = () => setModalIsOpenImg(true);
    const closeModalImg = () => setModalIsOpenImg(false);

    const openModalVideo = () => setModalIsOpenVideo(true);
    const closeModalVideo = () => {
        setVideoFile({});
        setModalIsOpenVideo(false);
    };

    const openModalAudio = () => setModalIsOpenAudio(true);
    const closeModalAudio = () => {
        setAudioFile({});
        setModalIsOpenAudio(false);
    };

    const handleUploadAudio = (files: File[] | null) => {
        if (!files?.length || !files)
            return dispatch(alertAction.setMessage({ message: 'File must be audio' }));

        if (
            !checkFileAndShowAlert(
                files[0],
                ATTACH.TYPE_AUDIO,
                (message: string, title: string) => {
                    dispatch(alertAction.setMessage({ message, title }));
                },
            )
        )
            return;

        openModalAudio();
        setAudioFile(files[0]);
    };

    const handleUploadImg = (files: File[] | null) => {
        if (!files?.length || !files)
            return dispatch(alertAction.setMessage({ message: 'File must be image' }));

        if (
            !checkFileAndShowAlert(
                files[0],
                ATTACH.TYPE_IMAGE,
                (message: string, title: string) => {
                    dispatch(alertAction.setMessage({ message, title }));
                },
            )
        )
            return;

        openModalImg();
        setImgFile(files[0]);
    };

    const handleUploadVideo = (files: File[] | null) => {
        if (!files?.length || !files)
            return dispatch(alertAction.setMessage({ message: 'File must be video' }));

        if (
            !checkFileAndShowAlert(
                files[0],
                ATTACH.TYPE_VIDEO,
                (message: string, title: string) => {
                    dispatch(alertAction.setMessage({ message, title }));
                },
            )
        )
            return;

        openModalVideo();
        setVideoFile(files[0]);
    };

    const handleDrop = (file: File[]) => {
        // @ts-expect-error !file[0]?.type
        if (!file && !file[0] && !file[0]?.type) {
            return dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
        }

        switch (file[0].type.split('/')[0]) {
            case ATTACH.TYPE_IMAGE:
                setActiveTab(ATTACH.TAB_IMAGE);
                return handleUploadImg(file);
            case ATTACH.TYPE_VIDEO:
                setActiveTab(ATTACH.TAB_VIDEO);
                return handleUploadVideo(file);
            case ATTACH.TYPE_AUDIO:
                setActiveTab(ATTACH.TAB_AUDIO);
                return handleUploadAudio(file);
            default:
                return '';
        }
    };

    const uploadImg = ({ file, fileName, index }: IUploadProps) => {
        try {
            setImageLoadingStatus(true);
            userState?.info?.external_id &&
                awsUploadFile(
                    file,
                    fileName,
                    userState?.info?.external_id?.toString(),
                    AWS.IMG_BUCKET,
                    index,
                ).then((res) => {
                    if (res?.status) {
                        new FilesAPI().postImage(res?.data).then((res) => {
                            if (res?.status) {
                                dispatch(
                                    filesAction.getAllImages(() => setImageLoadingStatus(false)),
                                );
                            }
                        });
                    } else {
                        setImageLoadingStatus(false);
                        if (res?.message || res?.error) {
                            dispatch(
                                alertAction.setMessage({ message: res?.message || res?.error }),
                            );
                        }
                    }
                });
        } catch (error) {
            sendErrToSentry(error as Error);
            setImageLoadingStatus(false);
        }
    };

    const uploadVideo = ({ file, fileName, index }: IUploadProps) => {
        try {
            setVideoLoadingStatus(true);
            userState?.info?.external_id &&
                awsUploadFile(
                    file,
                    fileName,
                    userState?.info?.external_id?.toString(),
                    AWS.VIDEO_BUCKET,
                    index,
                ).then((resAWS) => {
                    if (resAWS?.status) {
                        new FilesAPI().postVideo(resAWS?.data).then((res) => {
                            if (res?.status) {
                                dispatch(
                                    filesAction.getAllVideos(() => setVideoLoadingStatus(false)),
                                );
                                new VideoConverteApi().postVideoLinkToConvert(resAWS?.data?.link);

                                dispatch(filesAction.getAllVideos());
                            }
                        });
                    } else {
                        setVideoLoadingStatus(false);
                        if (resAWS?.message || resAWS?.error) {
                            dispatch(
                                alertAction.setMessage({
                                    message: resAWS?.message || resAWS?.error,
                                }),
                            );
                        }
                    }
                });
        } catch (error) {
            sendErrToSentry(error as Error);
            setVideoLoadingStatus(false);
        }
    };

    const uploadAudio = ({ file, fileName, index }: IUploadProps) => {
        try {
            setAudioLoadingStatus(true);
            userState?.info?.external_id &&
                awsUploadFile(
                    file,
                    fileName,
                    userState?.info?.external_id?.toString(),
                    AWS.AUDIO_BUCKET,
                    index,
                ).then((res) => {
                    if (res?.status) {
                        new FilesAPI()
                            .postAudio({ ...res?.data, filename: fileName })
                            .then((res) => {
                                if (res?.status) {
                                    dispatch(
                                        filesAction.getAllAudios(() =>
                                            setAudioLoadingStatus(false),
                                        ),
                                    );
                                }
                            });
                    } else {
                        setAudioLoadingStatus(false);
                        if (res?.message || res?.error) {
                            dispatch(
                                alertAction.setMessage({ message: res?.message || res?.error }),
                            );
                        }
                    }
                });
        } catch (error) {
            sendErrToSentry(error as Error);
            setAudioLoadingStatus(false);
        }
    };

    const switchInfoText = (type: string) => {
        switch (type) {
            case ATTACH.TAB_IMAGE:
                return `Image upload limit <br/>is ${LENGTH.MAX_IMG_SIZE_TITLE} Mb`;
            case ATTACH.TAB_VIDEO:
                return `Video upload limit <br/>is ${LENGTH.MAX_VIDEO_SIZE_TITLE} Mb`;
            case ATTACH.TAB_AUDIO:
                return `Audio upload limit <br/>is ${LENGTH.MAX_AUDIO_SIZE_TITLE} Mb`;
            default:
                return '';
        }
    };

    const switchCostText = (type: string) => {
        if (userState?.prices) {
            switch (type) {
                case ATTACH.TAB_IMAGE:
                    if (window?.location?.href?.includes('letter')) {
                        return `The cost of sending one photo is ${convertPrice(+userState?.prices?.[PRICES.SENT_IMAGE_MAIL])} credits`;
                    } else {
                        return `The cost of sending one photo is ${convertPrice(+userState?.prices?.[PRICES.SENT_IMAGE])} credits`;
                    }
                case ATTACH.TAB_VIDEO:
                    if (window?.location?.href?.includes('letter')) {
                        return `The cost of sending one video is  ${convertPrice(+userState.prices[isNewMail ? PRICES.SENT_VIDEO_MAIL_NEW : PRICES.SENT_VIDEO_MAIL])} credits`;
                    } else {
                        return `The cost of sending one video is  ${convertPrice(+userState.prices[isNewMail ? PRICES.SENT_VIDEO_NEW : PRICES.SENT_VIDEO])} credits`;
                    }
                case ATTACH.TAB_AUDIO:
                    if (window?.location?.href?.includes('letter')) {
                        return `The cost of sending one audio is  ${convertPrice(+userState.prices[isNewMail ? PRICES.SENT_AUDIO_MAIL_NEW : PRICES.SENT_AUDIO_MAIL])} credits`;
                    } else {
                        return `The cost of sending one audio is  ${convertPrice(+userState.prices[isNewMail ? PRICES.SENT_AUDIO_NEW : PRICES.SENT_AUDIO])} credits`;
                    }
                default:
                    return '';
            }
        }
        return '';
    };

    const switchListItems = (type: string) => {
        switch (type) {
            case ATTACH.TAB_IMAGE:
                return (
                    <ListImages
                        status={imageLoadingStatus}
                        images={filesState?.images}
                        type={ATTACH.IMG_LIST}
                    />
                );

            case ATTACH.TAB_VIDEO:
                return (
                    <ListVideos
                        status={videoLoadingStatus}
                        videos={filesState?.videos}
                        type={ATTACH.VIDEO_LIST}
                    />
                );
            case ATTACH.TAB_AUDIO:
                return (
                    <ListAudios
                        status={audioLoadingStatus}
                        audios={filesState?.audios}
                        type={ATTACH.AUDIO_LIST}
                    />
                );
            default:
                return '';
        }
    };

    const switchBottomButtons = (type: string) => {
        switch (type) {
            case ATTACH.TAB_IMAGE:
                return (
                    <>
                        <input
                            type="file"
                            id="popup_inp_file1"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                const files = e?.target?.files ? Array.from(e.target.files) : null;
                                handleUploadImg(files);
                            }}
                            onClick={(e) => {
                                const inputElementTarget = e.target as HTMLInputElement;
                                inputElementTarget.value = '';
                                imgFile && imgFile.size && setModalIsOpenImg(true);
                            }}
                            data-testid="upload"
                        />
                        <label htmlFor="popup_inp_file1" className="attach_new_popup_upload_btn">
                            <IconUploadAttachment />
                            <span>Upload</span>
                        </label>
                        <div
                            className="attach_new_popup_send_btn"
                            onClick={sendImg}
                            data-testid="send-btn"
                        >
                            <IconSendAttachment />
                            <span>
                                Send <span>image</span>
                            </span>
                        </div>
                    </>
                );
            case ATTACH.TAB_VIDEO:
                return (
                    <>
                        <input
                            type="file"
                            id="popup_inp_file1"
                            accept="video/*"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                const files = e?.target?.files ? Array.from(e.target.files) : null;
                                handleUploadVideo(files);
                            }}
                            onClick={(e) => {
                                const inputElementTarget = e.target as HTMLInputElement;
                                inputElementTarget.value = '';
                                videoFile && videoFile.size && setModalIsOpenVideo(true);
                            }}
                            data-testid="upload"
                        />
                        <label htmlFor="popup_inp_file1" className="attach_new_popup_upload_btn">
                            <IconUploadAttachment />
                            <span>Upload</span>
                        </label>
                        <div
                            className="attach_new_popup_send_btn"
                            onClick={sendVideo}
                            data-testid="send-btn"
                        >
                            <IconSendAttachment />
                            <span>
                                Send <span>video</span>
                            </span>
                        </div>
                    </>
                );
            case ATTACH.TAB_AUDIO:
                return (
                    <>
                        <input
                            type="file"
                            id="popup_inp_file1"
                            accept="audio/mp3, audio/wav, audio/mpeg, audio/x-m4a, audio/ogg"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                const files = e?.target?.files ? Array.from(e.target.files) : null;
                                handleUploadAudio(files);
                            }}
                            onClick={(e) => {
                                const inputElementTarget = e.target as HTMLInputElement;
                                inputElementTarget.value = '';
                                audioFile && audioFile.size && setModalIsOpenAudio(true);
                            }}
                            data-testid="upload"
                        />
                        <label htmlFor="popup_inp_file1" className="attach_new_popup_upload_btn">
                            <IconUploadAttachment />
                            <span>Upload</span>
                        </label>
                        <div
                            className="attach_new_popup_send_btn"
                            onClick={sendAudio}
                            data-testid="send-btn"
                        >
                            <IconSendAttachment />
                            <span>
                                Send <span>audio</span>
                            </span>
                        </div>
                    </>
                );
            default:
                return '';
        }
    };

    const switchModals = (type: string) => {
        switch (type) {
            case ATTACH.TAB_IMAGE:
                return (
                    <Modal
                        style={modalStyleProps({ isAttachment: true })}
                        isOpen={modalIsOpenImg}
                        onRequestClose={closeModalImg}
                        ariaHideApp={false}
                    >
                        <UploadImageModal
                            images={filesState?.images}
                            imgFile={imgFile}
                            setImgFile={setImgFile}
                            handleUploadImg={(e: ChangeEvent<HTMLInputElement>) => {
                                const files = e?.target?.files ? Array.from(e.target.files) : null;
                                handleUploadImg(files);
                            }}
                            closeModalImg={closeModalImg}
                            uploadImg={uploadImg}
                        />
                    </Modal>
                );
            case ATTACH.TAB_VIDEO:
                return (
                    <Modal
                        style={modalStyleProps({ isAttachment: true })}
                        isOpen={modalIsOpenVideo}
                        onRequestClose={closeModalVideo}
                        ariaHideApp={false}
                    >
                        <UploadVideoModal
                            videos={filesState?.videos}
                            videoFile={videoFile}
                            setVideoFile={setVideoFile}
                            handleUploadVideo={(e: ChangeEvent<HTMLInputElement>) => {
                                const files = e?.target?.files ? Array.from(e.target.files) : null;
                                handleUploadVideo(files);
                            }}
                            closeModalVideo={closeModalVideo}
                            uploadVideo={uploadVideo}
                        />
                    </Modal>
                );
            case ATTACH.TAB_AUDIO:
                return (
                    <Modal
                        style={modalStyleProps({ isAttachment: true })}
                        isOpen={modalIsOpenAudio}
                        onRequestClose={closeModalAudio}
                        ariaHideApp={false}
                    >
                        <UploadAudioModal
                            audios={filesState?.audios}
                            audioFile={audioFile}
                            setAudioFile={setAudioFile}
                            handleUploadAudio={(e: ChangeEvent<HTMLInputElement>) => {
                                const files = e?.target?.files ? Array.from(e.target.files) : null;
                                handleUploadAudio(files);
                            }}
                            closeModalAudio={closeModalAudio}
                            uploadAudio={uploadAudio}
                        />
                    </Modal>
                );
            default:
                return '';
        }
    };

    return (
        <DndProvider
            // @ts-expect-error DndProvider backend prop
            backend={isTouchDevice() ? TouchBackend : Backend}
            options={{ enableMouseEvents: true }}
        >
            <div className="upload_popup_wrap" data-testid="file-modal" id="attach-modal">
                <div className="attach_new_popup_left">
                    <ul className="attach_new_popup_ul" data-testid="tab-block">
                        <li
                            className={`${activeTab === ATTACH.TAB_IMAGE ? 'active' : ''}`}
                            onClick={() => setActiveTab(ATTACH.TAB_IMAGE)}
                            data-testid="photo"
                        >
                            <div className="attach_new_popup_li_wrap">
                                <div className="attach_new_popup_li_photo" />
                                <span>{ATTACH.TAB_IMAGE}</span>
                            </div>
                        </li>
                        <li
                            className={`${activeTab === ATTACH.TAB_VIDEO ? 'active' : ''}`}
                            onClick={() => setActiveTab(ATTACH.TAB_VIDEO)}
                            data-testid="video"
                        >
                            <div className="attach_new_popup_li_wrap">
                                <div className="attach_new_popup_li_video" />
                                <span>{ATTACH.TAB_VIDEO}</span>
                            </div>
                        </li>
                        <li
                            className={`${activeTab === ATTACH.TAB_AUDIO ? 'active' : ''}`}
                            onClick={() => setActiveTab(ATTACH.TAB_AUDIO)}
                            data-testid="audio"
                        >
                            <div className="attach_new_popup_li_wrap">
                                <div className="attach_new_popup_li_audio" />
                                <span>{ATTACH.TAB_AUDIO}</span>
                            </div>
                        </li>
                    </ul>
                    <DropZone onDrop={handleDrop}>
                        {({ getRootProps, isDragActive }) => (
                            <div
                                {...getRootProps({
                                    className: `attach_new_popup_drag_drop ${isDragActive ? 'accept' : ''}`,
                                })}
                            >
                                <div>Drag & drop your files here</div>
                                <IconDropAttachment />
                            </div>
                        )}
                    </DropZone>
                    <div
                        className="attach_new_popup_info_text"
                        dangerouslySetInnerHTML={{ __html: switchInfoText(activeTab) }}
                    />
                </div>
                <div className="attach_new_popup_right">
                    <div className="attach_new_popup_tab_wrap">
                        <div className="attach_new_popup_tab_content">
                            <div className="attach_new_popup_tab_content_top">
                                {SITE.ID !== 4 && (
                                    <div className="attach_new_popup_tab_content_top_text">
                                        {switchCostText(activeTab)}
                                    </div>
                                )}
                                <div className="attach_new_popup_tab_content_top_sort">
                                    <IconMove />
                                    <span>Sorting</span>
                                </div>
                            </div>
                            <div className="attach_new_popup_tab_content_midddle">
                                {switchListItems(activeTab)}
                            </div>
                            <div className="attach_new_popup_tab_content_bottom">
                                {switchBottomButtons(activeTab)}
                            </div>
                        </div>
                    </div>
                    {switchModals(activeTab)}
                </div>
                <div
                    className="attach_new_popup_close"
                    onClick={closeModal}
                    data-testid="close-btn"
                >
                    <IconCross />
                </div>
            </div>
        </DndProvider>
    );
}
