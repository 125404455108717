import React from 'react';

export default function IconAlarm() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3863 3.90728C13.4852 3.92217 13.5867 3.93819 13.6913 3.95533L13.7352 3.96253L13.7779 3.9751C16.5506 4.79163 18.5762 7.29092 18.5762 10.2439V11.4894C18.5762 13.6392 19.4211 15.6756 20.9569 17.2273C21.3132 17.5873 21.4134 18.1187 21.2114 18.5775C21.0094 19.0363 20.5443 19.3341 20.0294 19.3341H3.59963C3.08476 19.3341 2.61957 19.0364 2.41755 18.5775C2.21553 18.1187 2.3157 17.5873 2.67207 17.2273C4.20793 15.6755 5.05282 13.6393 5.05282 11.4894V10.2439C5.05282 7.29089 7.07835 4.79163 9.85108 3.9751L9.88709 3.96449L9.92407 3.9577C10.005 3.94283 10.0842 3.92886 10.1617 3.91579C10.4665 2.91976 11.0753 2.5 11.7753 2.5C12.4733 2.5 13.0806 2.91737 13.3863 3.90728ZM11.8149 21.5C13.0155 21.5 14.0494 20.7974 14.5031 19.7945H9.12671C9.58037 20.7974 10.6143 21.5 11.8149 21.5ZM6.44946 10.2439C6.44946 7.91707 8.03779 5.9395 10.222 5.28021C11.4347 5.06027 12.1288 5.0739 13.4133 5.28213C15.5942 5.94333 17.1795 7.91937 17.1795 10.2439V11.4894C17.1795 13.9047 18.0989 16.2014 19.7725 17.981H3.85653C5.53006 16.2014 6.44946 13.9048 6.44946 11.4894V10.2439ZM16.5933 3.78136C16.7847 3.45694 17.2112 3.34423 17.5461 3.52961C18.7324 4.18639 20.8188 6.37526 20.8188 10.1808C20.8188 10.5545 20.5061 10.8574 20.1205 10.8574C19.7348 10.8574 19.4222 10.5545 19.4222 10.1808C19.4222 6.87155 17.6141 5.12567 16.8532 4.70443C16.5183 4.51905 16.402 4.10578 16.5933 3.78136ZM6.00471 3.52961C6.33957 3.34423 6.76614 3.45694 6.95748 3.78136C7.14883 4.10578 7.03249 4.51905 6.69764 4.70443C5.93675 5.12567 4.12865 6.87155 4.12865 10.1808C4.12865 10.5545 3.816 10.8574 3.43033 10.8574C3.04466 10.8574 2.73202 10.5545 2.73202 10.1808C2.73202 6.37526 4.81837 4.18639 6.00471 3.52961Z"
            />
        </svg>
    );
}
