import React, { MouseEventHandler } from 'react';
import IconCheckedCircle from '../../../Icons/IconCheckedCircle';
import IconCross from '../../../Icons/IconCross';

export default function GiftSuccess({ onClose }: { onClose: MouseEventHandler<HTMLDivElement> }) {
    return (
        <div className="popup_gift_bought">
            <div className="popup_gift_bought_title">
                <IconCheckedCircle />
                <span>Gift bought</span>
            </div>
            <p className="popup_gift_bought_text">Your gift will be presented in 10 days.</p>
            <p className="popup_gift_bought_sub_text">
                Once the gift is handed in, you will receive a message from our moderation team.{' '}
                <br />
                <br /> The delivery of the gift can be cancelled only in 24 hours from the moment
                you made this order.
            </p>
            <div className="popup_gift_close" onClick={onClose}>
                <IconCross />
            </div>
        </div>
    );
}
