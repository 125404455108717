import React, { useRef, useState } from 'react';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import { IDoubleSelectNewProps } from './index.interface';
import { IArrayFields } from '../../SearchFilter/SearchToolbar/index.interface';

export default function DoubleSelect({
    formDataFrom,
    formDataTo,
    selectTitle,
    defaultNameFrom,
    defaultNameTo,
    arrayFieldsFrom,
    arrayFieldsTo,
    handleClickFrom,
    handleClickTo,
    index,
    dataTestId = '',
}: IDoubleSelectNewProps) {
    const [openBoxTo, setOpenBoxTo] = useState<boolean>(false);
    const [openBoxFrom, setOpenBoxFrom] = useState<boolean>(false);
    const selectRef = useRef(null);

    useOutsideClicker(selectRef, () => {
        setOpenBoxTo(false);
        setOpenBoxFrom(false);
    });

    const activeItemFrom =
        arrayFieldsFrom?.find((el: any) => +el?.id === +formDataFrom) ?? formDataFrom;
    const activeItemTo = arrayFieldsTo?.find((el: any) => +el?.id === +formDataTo) ?? formDataTo;

    const selectFrom = (item: number | IArrayFields) => {
        let newItem;
        if (typeof item === 'object') {
            newItem = item.id;
        } else newItem = item;

        // @ts-expect-error isNaN(formDataTo)
        if ((newItem > formDataTo || (isNaN(newItem) && !isNaN(formDataTo))) && +formDataTo !== 0)
            return false;

        handleClickFrom(newItem);
        setOpenBoxFrom(false);
    };

    const selectTo = (item: number | IArrayFields) => {
        let newItem;
        if (typeof item === 'object') {
            newItem = item.id;
        } else newItem = item;

        // @ts-expect-error isNaN(formDataFrom)
        if ((formDataFrom > newItem || (isNaN(formDataFrom) && !isNaN(newItem))) && +newItem !== 0)
            return false;

        handleClickTo(newItem);
        setOpenBoxTo(false);
    };

    if (!arrayFieldsFrom?.length || !arrayFieldsTo?.length) {
        return null;
    }

    return (
        <div className="fill_data_form_select_item_wrap">
            <div className="fill_data_form_select_item_name">{selectTitle}</div>
            <div className="fill_data_form_select_item item_two">
                <div
                    className="cl2h_tab_wide_filter_item_wrap"
                    ref={selectRef}
                    data-testid={dataTestId}
                >
                    <div
                        className={`cl2h_tab_wide_filter_item ${openBoxFrom ? 'open' : ''}`}
                        data-testid={`${index}-from`}
                    >
                        <div
                            // @ts-expect-error formDataType
                            className={`like_select_head ${formDataFrom && +formDataFrom[`${index}`] !== 0 ? 'selected' : ''}`}
                            onClick={() => {
                                setOpenBoxFrom(!openBoxFrom);
                                setOpenBoxTo(false);
                            }}
                        >
                            {formDataFrom &&
                            +activeItemFrom !== 0 &&
                            +activeItemFrom[`${index}`] !== 0
                                ? index
                                    ? activeItemFrom[`${index}`]
                                    : activeItemFrom
                                : defaultNameFrom}
                        </div>
                        <div className={`like_select_list_wrap ${openBoxFrom ? 'open' : ''}`}>
                            <div
                                className={`like_select_list ${openBoxFrom ? 'open' : ''}`}
                                data-testid="select-list"
                            >
                                {arrayFieldsFrom.map((item: any, key: number) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label"
                                            onClick={() => selectFrom(item)}
                                        >
                                            {index ? item[`${index}`] : item || '—'}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`cl2h_tab_wide_filter_item ${openBoxTo ? 'open' : ''}`}
                        data-testid={`${index}-to`}
                    >
                        <div
                            // @ts-expect-error formDataTo
                            className={`like_select_head ${formDataTo && +formDataTo[`${index}`] !== 0 ? 'selected' : ''}`}
                            onClick={() => {
                                setOpenBoxTo(!openBoxTo);
                                setOpenBoxFrom(false);
                            }}
                        >
                            {formDataTo && +activeItemTo !== 0 && +activeItemTo[`${index}`] !== 0
                                ? index
                                    ? activeItemTo[`${index}`]
                                    : activeItemTo
                                : defaultNameTo}
                        </div>
                        <div className={`like_select_list_wrap ${openBoxTo ? 'open' : ''}`}>
                            <div
                                className={`like_select_list ${openBoxTo ? 'open' : ''}`}
                                data-testid="select-list"
                            >
                                {arrayFieldsTo.map((item: any, key: number) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label"
                                            onClick={() => selectTo(item)}
                                        >
                                            <>
                                                {index
                                                    ? item[index as keyof IArrayFields]
                                                    : item || '—'}
                                            </>
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
