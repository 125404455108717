import React, { useState } from 'react';
import styles from './styles.module.scss';
import useSendWink from '../../../../../../hooks/wink/useSendWink';
import { useSelector } from 'react-redux';
import * as SEX from '../../../../../../constants/sex';
import useHandleChatFunctions from '../../../../../../hooks/chat/useHandleChatFunctions';
import { SUPPORT_WOMAN } from '../../../../../../constants/user';
import useHandleActiveBlocks from '../../../../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../../../../index';
import empty_opened_chat from '../../../../../../img/empty-opened-chat.svg';
import IconCross from '../../../../../Icons/IconCross';

export default function MessageListEmpty() {
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);

    const [isOpenHelloList, setIsOpenHelloList] = useState(false);

    const sendWink = useSendWink();
    const { sayHelloList } = useHandleChatFunctions();
    const handleActiveBlocks = useHandleActiveBlocks();

    const isSupportWoman = currentChatState?.info?.id === SUPPORT_WOMAN;
    const winkStatus = currentChatState?.info?.wink;
    const subTitle = handleActiveBlocks.isSearchV3
        ? 'To show your interest, say hello'
        : SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
              .CHAT_EMPTY_CHATROOM_SAY_HELLO_BTN;

    const toggleHelloList = () => setIsOpenHelloList(!isOpenHelloList);

    return (
        <div className={styles.empty_opened_chat_wrap}>
            <div className={styles.empty_opened_chat}>
                <img
                    src={empty_opened_chat}
                    alt="empty chat"
                    className={styles.empty_opened_chat_img}
                />
                <div className={styles.empty_opened_chat_content}>
                    <div className={styles.empty_opened_chat_title}>Your chat history is empty</div>
                    <div className={styles.empty_opened_chat_text}>{subTitle}</div>
                </div>
            </div>

            {!winkStatus && !isSupportWoman && (
                <div className={styles.empty_chat_say_hello_wrap}>
                    <div
                        className={`${styles.empty_chat_say_hello_list_wrap} ${isOpenHelloList ? styles.active : ''}`}
                    >
                        <ul className={styles.empty_chat_say_hello_list}>
                            {!!sayHelloList?.length &&
                                sayHelloList.map((item, key) => (
                                    <li
                                        className={styles.empty_chat_say_hello_list_item}
                                        key={key}
                                        onClick={() => {
                                            if (!currentChatState?.info?.chat_uid) return;
                                            sendWink({
                                                id: currentChatState?.info?.id,
                                                helloText: item,
                                            }).then(() => setIsOpenHelloList(false));
                                        }}
                                    >
                                        {item}
                                    </li>
                                ))}
                        </ul>
                    </div>

                    <div
                        className={`${styles.empty_chat_say_hello_more_btn} ${isOpenHelloList ? styles.active : ''}`}
                        onClick={toggleHelloList}
                    >
                        {isOpenHelloList ? (
                            <div>
                                <IconCross />
                                <span>Close</span>
                            </div>
                        ) : (
                            <>
                                <div>...</div>
                                <div>More</div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
