import * as onlineNowAction from '../../store/actions/onlineNow';
import { useDispatch, useSelector } from 'react-redux';
import { UIEvent, useEffect } from 'react';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { RootState } from '../../index';

export default function useSetOnlineNowList() {
    const userState = useSelector((state: RootState) => state.user);
    const onlineNowState = useSelector((state: RootState) => state.onlineNow);
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();

    const searchFilterByType = handleActiveBlocks?.isSearchV3
        ? { gender: 'ALL', ...onlineNowState?.filter }
        : { ...onlineNowState?.filter, gender: userState?.info?.gender };

    const setDefaultFilterValue = () => dispatch(onlineNowAction.setOnlineNowListFilter());

    const handleCountryFilter = (value: string) => {
        const currentCountry = referenciesState?.list?.country_list?.find(
            (el) => el?.country_name?.toLowerCase() === value?.toLowerCase(),
        );

        dispatch(
            onlineNowAction.setOnlineNowListFilter({
                ...onlineNowState?.filter,
                country: currentCountry?.country_name || '',
            }),
        );
    };

    const handleCityFilter = (value: string) => {
        const currentCity = onlineNowState?.filter?.cityList?.find(
            (el: any) => el?.city_name?.toLowerCase() === value?.toLowerCase(),
        );
        dispatch(
            onlineNowAction.setOnlineNowListFilter({
                ...onlineNowState?.filter,
                city: currentCity?.city_name || '',
            }),
        );
    };

    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;

        const getNextPage =
            target?.scrollTop > target?.scrollHeight - target?.offsetHeight - 460 * 2;

        if (getNextPage && !onlineNowState?.isLoadingPage && !onlineNowState?.isLoadedAll) {
            dispatch(onlineNowAction.setOnlineNowListPage(+onlineNowState?.page + 1));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(onlineNowAction.initOnlineNowState());
        };
    }, []);

    useEffect(() => {
        dispatch(onlineNowAction.setOnlineNowListPage(1));
        dispatch(onlineNowAction.getOnlineNowList(1, searchFilterByType));
    }, [onlineNowState?.filter]);

    useEffect(() => {
        if (onlineNowState?.page > 1) {
            dispatch(onlineNowAction.getOnlineNowList(onlineNowState?.page, searchFilterByType));
        }
    }, [onlineNowState?.page]);

    return {
        handleScroll,
        handleCountryFilter,
        handleCityFilter,
        setDefaultFilterValue,
    };
}
