import { useRef, useState } from 'react';
import useOutsideClicker from '../../dom/useOutsideClicker';

export default function useHandleStickerBlock(
    mobileCallback: (value: boolean) => void,
    setIsOpenMobSmileBlock: (value: boolean) => void,
) {
    const stickerBoxRef = useRef(null);
    const [isStickerBlock, setIsStickerBlock] = useState(false);

    const openStickerBlock = () => {
        if (window.innerWidth < 1000) {
            if (mobileCallback) mobileCallback(true);
            if (setIsOpenMobSmileBlock) setIsOpenMobSmileBlock(false);
        } else {
            setIsStickerBlock(true);
        }
    };

    const closeStickerBlock = () => {
        setIsStickerBlock(false);
    };

    useOutsideClicker(stickerBoxRef, setIsStickerBlock);

    return {
        isStickerBlock,
        openStickerBlock,
        closeStickerBlock,
        stickerBoxRef,
    };
}
