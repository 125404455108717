import AbTestApi from '../../services/api/v3/AbTestApi';
import FrontSyncApi from '../../services/api/v3/FrontSyncApi';

export default function useTransactionConfig() {
    const fetchTransactionConfig = () => {
        new AbTestApi().getConfig().then((configRes) => {
            if (configRes) {
                new FrontSyncApi().postTransactionSync(
                    {
                        register: Array.isArray(configRes?.register) ? configRes?.register : [],
                        recycle: Array.isArray(configRes?.recycle) ? configRes?.recycle : [],
                        infinity: Array.isArray(configRes?.infinity) ? configRes?.infinity : [],
                    },
                    configRes?.startTransaction ?? {},
                );
            }
        });
    };
    return {
        fetchTransactionConfig,
    };
}
