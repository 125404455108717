import React from 'react';

export default function IconPolicyCookies() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#D35B00"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.997 9.34875 20.9425 6.80695 19.0678 4.93223C17.1931 3.05751 14.6512 2.00299 12 2ZM12 20.5714C10.3047 20.5714 8.64754 20.0687 7.23797 19.1269C5.82841 18.185 4.72979 16.8464 4.08104 15.2801C3.43229 13.7139 3.26254 11.9905 3.59327 10.3278C3.924 8.6651 4.74035 7.13782 5.93909 5.93908C7.13782 4.74035 8.66511 3.924 10.3278 3.59327C11.9905 3.26254 13.7139 3.43228 15.2801 4.08103C16.8464 4.72978 18.185 5.8284 19.1269 7.23797C20.0687 8.64753 20.5714 10.3047 20.5714 12C20.569 14.2725 19.6651 16.4513 18.0582 18.0582C16.4513 19.6651 14.2725 20.5689 12 20.5714Z" />
            <path d="M5.57146 13.4286C5.96595 13.4286 6.28575 13.1088 6.28575 12.7143C6.28575 12.3198 5.96595 12 5.57146 12C5.17697 12 4.85718 12.3198 4.85718 12.7143C4.85718 13.1088 5.17697 13.4286 5.57146 13.4286Z" />
            <path d="M9.85711 16.9999C10.2516 16.9999 10.5714 16.6801 10.5714 16.2856C10.5714 15.8911 10.2516 15.5713 9.85711 15.5713C9.46262 15.5713 9.14282 15.8911 9.14282 16.2856C9.14282 16.6801 9.46262 16.9999 9.85711 16.9999Z" />
            <path d="M13.4286 13.4286C13.8231 13.4286 14.1429 13.1088 14.1429 12.7143C14.1429 12.3198 13.8231 12 13.4286 12C13.0342 12 12.7144 12.3198 12.7144 12.7143C12.7144 13.1088 13.0342 13.4286 13.4286 13.4286Z" />
            <path d="M17.7143 11.9999C18.1088 11.9999 18.4286 11.6801 18.4286 11.2856C18.4286 10.8911 18.1088 10.5713 17.7143 10.5713C17.3198 10.5713 17 10.8911 17 11.2856C17 11.6801 17.3198 11.9999 17.7143 11.9999Z" />
            <path d="M14.4999 17.7141C15.0916 17.7141 15.5713 17.2345 15.5713 16.6427C15.5713 16.051 15.0916 15.5713 14.4999 15.5713C13.9082 15.5713 13.4285 16.051 13.4285 16.6427C13.4285 17.2345 13.9082 17.7141 14.4999 17.7141Z" />
            <path d="M9.4999 12.0003C10.0916 12.0003 10.5713 11.5206 10.5713 10.9289C10.5713 10.3371 10.0916 9.85742 9.4999 9.85742C8.90816 9.85742 8.42847 10.3371 8.42847 10.9289C8.42847 11.5206 8.90816 12.0003 9.4999 12.0003Z" />
            <path d="M14.4999 8.78543C15.0916 8.78543 15.5713 8.30574 15.5713 7.71401C15.5713 7.12227 15.0916 6.64258 14.4999 6.64258C13.9082 6.64258 13.4285 7.12227 13.4285 7.71401C13.4285 8.30574 13.9082 8.78543 14.4999 8.78543Z" />
            <path d="M9.85711 6.99986C10.2516 6.99986 10.5714 6.68006 10.5714 6.28557C10.5714 5.89109 10.2516 5.57129 9.85711 5.57129C9.46262 5.57129 9.14282 5.89109 9.14282 6.28557C9.14282 6.68006 9.46262 6.99986 9.85711 6.99986Z" />
        </svg>
    );
}
