import React, { useState } from 'react';
import useTouchMove from '../../../../hooks/dom/useTouchMove';
import { getImageLink } from '../../../../services/methods/search/images';
import * as IMAGES from '../../../../constants/images';
import { IGallerySliderProps } from './index.interface';

export default function GallerySlider({
    gallery,
    selectedIndex = 0,
    closeGalleryModal,
    gifts = false,
}: IGallerySliderProps) {
    const [selectedImage, changeSelectedImage] = useState(selectedIndex);
    const touchMove = useTouchMove();

    const nextImage = (e?: React.TouchEvent) => {
        e && e.preventDefault();
        return changeSelectedImage(selectedImage + 1 > gallery?.length - 1 ? 0 : selectedImage + 1);
    };

    const prevImage = (e?: React.TouchEvent) => {
        e && e.preventDefault();
        return changeSelectedImage(selectedImage - 1 < 0 ? gallery?.length - 1 : selectedImage - 1);
    };

    return (
        <div className="popup_likeher_search_photos">
            <div className="popup_likeher_search_photos_content">
                <div
                    className="popup_likeher_search_photos_big_photo"
                    onTouchStart={touchMove.handleTouchStart}
                    onTouchMove={(e) =>
                        touchMove.handleTouchMove(
                            e,
                            () => nextImage(e),
                            () => prevImage(e),
                        )
                    }
                >
                    {gallery?.map((item, key) => (
                        <img
                            key={key}
                            src={gifts ? item.link : getImageLink(item.link, IMAGES.SIZE_L)}
                            alt=""
                            style={
                                +selectedImage === +key
                                    ? {
                                          display: 'block',
                                          objectFit: 'contain',
                                      }
                                    : { display: 'none' }
                            }
                        />
                    ))}
                </div>
            </div>
            <div className="popup_likeher_search_photos_prev" onClick={() => prevImage()}>
                <img src="/img/slider-prev.svg" alt="" />
            </div>
            <div className="popup_likeher_search_photos_next" onClick={() => nextImage()}>
                <img src="/img/slider-next.svg" alt="" />
            </div>
            <div className="popup_likeher_search_photos_close" onClick={closeGalleryModal}>
                <img src="/img/c3lh-item-big-close.svg" alt="" />
            </div>
        </div>
    );
}
