export const ONBOARDING_TITLE_THANK_YOU = 'ONBOARDING_TITLE_THANK_YOU';
export const ONBOARDING_MAN_PHOTO = 'ONBOARDING_MAN_PHOTO';
export const ONBOARDING_MAN_DESCRIBE_YOURSELF = 'ONBOARDING_MAN_DESCRIBE_YOURSELF';
export const ONBOARDING_MAN_DESCRIBE_PARTNER = 'ONBOARDING_MAN_DESCRIBE_PARTNER';
export const ONBOARDING_MAN_HOBBIES = 'ONBOARDING_MAN_HOBBIES';
export const ONBOARDING_MAN_SMOKE = 'ONBOARDING_MAN_SMOKE';
export const ONBOARDING_MAN_ALCOHOL = 'ONBOARDING_MAN_ALCOHOL';
export const ONBOARDING_MAN_OCCUPATION = 'ONBOARDING_MAN_OCCUPATION';
export const ONBOARDING_MAN_EDUCATION = 'ONBOARDING_MAN_EDUCATION';
export const ONBOARDING_MAN_WANT_MORE_CHILDREN = 'ONBOARDING_MAN_WANT_MORE_CHILDREN';
export const ONBOARDING_MAN_HAVE_CHILDREN = 'ONBOARDING_MAN_HAVE_CHILDREN';
export const ONBOARDING_MAN_BODY_TYPE = 'ONBOARDING_MAN_BODY_TYPE';
export const ONBOARDING_MAN_HEIGHT_WEIGHT = 'ONBOARDING_MAN_HEIGHT_WEIGHT';
export const ONBOARDING_MAN_COUNTRY_CITY = 'ONBOARDING_MAN_COUNTRY_CITY';
export const ONBOARDING_TITLE_ABOUT_YOURSELF = 'ONBOARDING_TITLE_ABOUT_YOURSELF';
export const ONBOARDING_WOMAN_HAVE_CHILDREN = 'ONBOARDING_WOMAN_HAVE_CHILDREN';
export const ONBOARDING_WOMAN_SMOKE = 'ONBOARDING_WOMAN_SMOKE';
export const ONBOARDING_WOMAN_ALCOHOL = 'ONBOARDING_WOMAN_ALCOHOL';
export const ONBOARDING_WOMAN_AGE = 'ONBOARDING_WOMAN_AGE';
export const ONBOARDING_WOMAN_BODY_TYPE = 'ONBOARDING_WOMAN_BODY_TYPE';
export const ONBOARDING_WOMAN_WEIGHT = 'ONBOARDING_WOMAN_WEIGHT';
export const ONBOARDING_WOMAN_HEIGHT = 'ONBOARDING_WOMAN_HEIGHT';
export const ONBOARDING_WOMAN_EYE_HEIR = 'ONBOARDING_WOMAN_EYE_HEIR';
export const ONBOARDING_TITLE_WELCOME = 'ONBOARDING_TITLE_WELCOME';
export const ONBOARDING_WOMAN_SEARCH = 'ONBOARDING_WOMAN_SEARCH';

export const ONBOARDING_TEMPLATE_LIST = [
    ONBOARDING_TITLE_WELCOME,
    ONBOARDING_WOMAN_HEIGHT,
    ONBOARDING_WOMAN_WEIGHT,
    ONBOARDING_WOMAN_BODY_TYPE,
    ONBOARDING_WOMAN_AGE,
    ONBOARDING_WOMAN_ALCOHOL,
    ONBOARDING_WOMAN_SMOKE,
    ONBOARDING_WOMAN_HAVE_CHILDREN,
    ONBOARDING_WOMAN_EYE_HEIR,
    ONBOARDING_TITLE_ABOUT_YOURSELF,
    ONBOARDING_MAN_COUNTRY_CITY,
    ONBOARDING_MAN_HEIGHT_WEIGHT,
    ONBOARDING_MAN_BODY_TYPE,
    ONBOARDING_MAN_HAVE_CHILDREN,
    ONBOARDING_MAN_WANT_MORE_CHILDREN,
    ONBOARDING_MAN_EDUCATION,
    ONBOARDING_MAN_OCCUPATION,
    ONBOARDING_MAN_ALCOHOL,
    ONBOARDING_MAN_SMOKE,
    ONBOARDING_MAN_HOBBIES,
    ONBOARDING_MAN_DESCRIBE_YOURSELF,
    ONBOARDING_MAN_DESCRIBE_PARTNER,
    ONBOARDING_MAN_PHOTO,
    ONBOARDING_TITLE_THANK_YOU,
    ONBOARDING_WOMAN_SEARCH,
];
