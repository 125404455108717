import { AffiliatsActionTypes } from '../../constants/affiliats';
import { Dispatch } from 'redux';
import { ISetAffiliatsStatusAction } from './index.interface';

export function setAffiliatsStatus(status: boolean) {
    return function (dispatch: Dispatch<ISetAffiliatsStatusAction>) {
        return dispatch({
            type: AffiliatsActionTypes.SET_AFFILIATS_STATUS,
            data: status,
        });
    };
}
