import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';
import useTransition from '../transition/useTransition';
import { insertedMessageBeforeSync } from '../../services/init';
import { initCancelTokenSource } from '../../services/methods';
import ChatsApi from '../../services/api/ChatsApi';
import * as currentChatAction from '../../store/actions/currentChat';
import * as notifyAction from '../../store/actions/notify';
import * as chatsAction from '../../store/actions/chats';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as MENU from '../../constants/menu';
import * as ALERT from '../../constants/alert';
import * as womanAction from '../../store/actions/woman';
import MailboxApi from '../../services/api/MailboxApi';
import * as currentChatMailboxStateAction from '../../store/actions/currentChatMailbox';
import useActiveButton from '../activeBtn/useActiveButton';
import useGetWinkList from '../wink/useGetWinkList';
import * as userAction from '../../store/actions/user';
import useCheckSpecialPaidAction from '../user/useCheckSpecialPaidAction';
import useSendActionSync from '../frontSync/useSendActionSync';
import * as FRONT_SYNC from '../../constants/frontSync';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useHandleRedUser from '../user/useHandleRedUser';
import { getLastMessageContent } from '../../services/methods/chat';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import { CancelToken } from 'axios';
import useHandleGiftsPage from '../gifts/useHandleGiftsPage';

export default function useHandleChatFunctions() {
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const chatsState = useSelector((state: RootState) => state.chats);
    const handleRedUser = useHandleRedUser();
    const handleGiftsPage = useHandleGiftsPage();

    const [canceledToken, setCanceledToken] = useState<CancelToken | null>(null);

    const dispatch = useDispatch();
    const transition = useTransition();
    const activeButton = useActiveButton();
    const { sayHelloList } = useGetWinkList({});
    const checkSpecialPaidAction = useCheckSpecialPaidAction();
    const sendActionSync = useSendActionSync();

    const getWomanExternalID = () =>
        currentChatState?.info?.id ?? currentChatMailboxState?.info?.female_id;

    const handleSetFavorite = async () => {
        if (!userState?.info?.external_id) return;

        const source = initCancelTokenSource(canceledToken, setCanceledToken);
        const { chat_uid, favorite } = getCurrentChatInfo();

        new ChatsApi()
            .postSetFavorite({
                userId: userState?.info?.external_id,
                chat_uid,
                favorite: +!favorite,
                token: source?.token,
            })
            .then((res) => {
                if (res?.canceled) return;
                if (res?.status) {
                    dispatch(currentChatAction.setFavoriteStatus(!favorite));
                    dispatch(currentChatMailboxAction.setFavorite(!favorite));
                    dispatch(notifyAction.toggleFavoriteNotification(chat_uid, !favorite));
                    dispatch(chatsAction.setFavorite(chat_uid, !favorite));
                }
            });
    };

    const openGift = () => {
        const isOpenFormChat = currentChatMailboxState?.info?.openFromChat;
        let womanProfile;
        let enableGift;

        if (menuState?.main === MENU.MENU_CHAT || isOpenFormChat) {
            const chatInfo = isOpenFormChat
                ? currentChatMailboxState?.info
                : currentChatState?.info;

            womanProfile = {
                avatar: chatInfo?.avatar,
                external_id: chatInfo?.external_id,
                public_external_id: chatInfo?.public_external_id,
                online: chatInfo?.online,
                name: chatInfo?.name,
                age: chatInfo?.age,
                country_code: chatInfo?.country_code,
                country_name: chatInfo?.country_name,
                city: chatInfo?.city,
            };

            enableGift = chatInfo?.gifts;
        } else if (menuState?.main === MENU.MENU_LETTER) {
            const mailboxInfo = currentChatMailboxState?.info;
            const isSenderWoman = mailboxInfo?.sender_id === mailboxInfo?.female_id;

            womanProfile = {
                avatar: isSenderWoman ? mailboxInfo?.sender_avatar : mailboxInfo?.recipient_avatar,
                external_id: mailboxInfo?.female_id,
                public_external_id: mailboxInfo?.public_external_id,
                online: mailboxInfo?.online,
                name: isSenderWoman ? mailboxInfo?.sender_name : mailboxInfo?.recipient_name,
                age: isSenderWoman ? mailboxInfo?.sender_age : mailboxInfo?.recipient_age,
                country_code: isSenderWoman
                    ? mailboxInfo?.sender_country_code
                    : mailboxInfo?.recipient_country_code,
                country_name: isSenderWoman
                    ? mailboxInfo?.sender_country_name
                    : mailboxInfo?.recipient_country_name,
                city: isSenderWoman ? mailboxInfo?.sender_city : mailboxInfo?.recipient_city,
            };

            enableGift = isSenderWoman ? mailboxInfo?.sender_gifts : mailboxInfo?.recipient_gifts;
        }

        if (womanProfile && enableGift) {
            handleGiftsPage.openGiftsPage(womanProfile);
        }
    };

    const goTo = () => {
        if (!userState?.info?.external_id) return;

        if (window.innerWidth <= 1024) dispatch(currentChatAction.touchSwipeChatList('left'));

        if (currentChatState?.info?.id) {
            dispatch(
                currentChatMailboxAction.addMailboxChatInfo({
                    ...currentChatState?.info,
                    sender_image: currentChatState?.info?.photo_link,
                    sender_name: currentChatState?.info?.name,
                    sender_age: currentChatState?.info?.age,
                    sender_city: currentChatState?.info?.city,
                    sender_id: currentChatState?.info?.id,
                    sender_gifts: currentChatState?.info?.gift,
                    sender_search_img: currentChatState?.info?.search_img,
                    female_id: currentChatState?.info?.id,
                    male_id: userState?.info?.external_id,
                    openFromChat: true,
                }),
            );
            dispatch(
                currentChatMailboxAction.getMailboxChatHistory({
                    userId: userState?.info?.external_id,
                    girlId: currentChatState?.info?.public_external_id?.toString() || '',
                    page: 1,
                }),
            );

            transition.transitionWithHistoryPush('letter', `/letter/`);
        } else if (
            currentChatMailboxState?.mailChatInfo?.chat_uid &&
            currentChatMailboxState?.info?.female_id
        ) {
            new MailboxApi()
                .createChatWithProfile(
                    currentChatMailboxState?.info?.male_id,
                    currentChatMailboxState?.info?.female_id,
                )
                .then((res) => {
                    if (+res?.chat_info?.male_block) {
                        return dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    }
                    if (res?.status && res?.chat_uid) {
                        const foundChat = chatsState?.chatList?.find(
                            (el) => el?.chat_uid === res?.chat_uid,
                        );
                        if (foundChat && res?.chat_info) {
                            dispatch(chatsAction.updateChatListItemInfo(res?.chat_info));
                        }
                        transition.transitionWithHistoryPush(
                            MENU.OPEN_CHAT,
                            `/chat/${res?.chat_uid}`,
                        );
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                });
        }
    };

    const handleLike = () => {
        if (!userState?.info?.external_id) return;

        const hashId = moment().unix();
        const source = initCancelTokenSource(canceledToken, setCanceledToken);
        const { id, chat_uid } = getCurrentChatInfo();

        try {
            new ChatsApi()
                .postMessage(
                    userState?.info?.external_id,
                    id,
                    `${userState.info.name} liked your profile`,
                    'SENT_LIKE',
                    '',
                    source?.token,
                )
                .then((res) => {
                    if (res?.canceled) return;
                    if (res?.status) {
                        if (!res?.response?.message_object) {
                            dispatch(
                                alertAction.setMessage({
                                    message: res?.message || ERROR.ERROR_REQUEST,
                                }),
                            );
                            return;
                        }
                        if (checkSpecialPaidAction()) {
                            dispatch(userAction.setBalance());
                        }
                        handleRedUser.submitLog(
                            RED_USER_LOG_TYPES.MESSAGE_FROM,
                            JSON.stringify({
                                womanExternalID: id,
                                action: 'LIKE',
                                content: `${userState?.info?.name} liked your profile`,
                            }),
                        );
                        dispatch(currentChatAction.setCurrentChatLikeStatus());
                        dispatch(
                            chatsAction.updateMessageInChatList(
                                res?.response?.message_object?.chat_uid,
                                getLastMessageContent(
                                    res?.response?.message_object?.message_content,
                                    res?.response?.message_object?.message_type,
                                ),
                                res?.response?.message_object?.message_type,
                                res?.response?.message_object?.date_created,
                                res?.response?.message_object?.sender_external_id,
                            ),
                        );

                        if (userState?.info?.external_id) {
                            dispatch(
                                currentChatAction.addMessageToHistory(
                                    insertedMessageBeforeSync(
                                        hashId,
                                        `${userState?.info?.name} liked your profile`,
                                        userState?.info?.external_id,
                                        'SENT_LIKE',
                                        new Date().toUTCString(),
                                    ),
                                ),
                            );
                        }

                        if (res?.response?.match_object?.chat_uid) {
                            dispatch(
                                notifyAction.addTransactionNotify({
                                    id:
                                        moment().unix() +
                                        res?.response?.match_object?.sender_external_id,
                                    ...res?.response?.match_object,
                                    chat_uid: res?.response?.match_object?.chat_uid,
                                }),
                            );
                        }
                        dispatch(chatsAction.setLike(chat_uid));
                        sendActionSync.postUserAction(+id, FRONT_SYNC.ACTION_LIKE);
                    } else if (res?.block) {
                        dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                        activeButton.onDefaultBlockBtn();
                        return false;
                    } else {
                        if (res?.message)
                            dispatch(
                                alertAction.setMessage({
                                    message: res?.message,
                                    title: res?.title,
                                }),
                            );
                        else
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.ENOUGH_CREDITS_CHAT,
                                }),
                            );
                        activeButton.onDefaultBlockBtn();
                    }
                });
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const handleBlackList = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);
        const { chat_uid } = getCurrentChatInfo();

        dispatch(currentChatAction.removeMainChatInfo());
        dispatch(currentChatMailboxStateAction.clearMailboxChatInfoForce());

        if (menuState?.main === MENU.MENU_BLOCK) {
            new ChatsApi()
                .postRemoveBlackList({
                    chatId: chat_uid,
                    token: source?.token,
                })
                .then((res) => {
                    if (res?.canceled) return;
                    if (res?.status) {
                        dispatch(womanAction.setIsBlocked(getWomanExternalID(), 0));
                        dispatch(chatsAction.removeFromBlockList(chat_uid));
                    }
                });
        } else {
            dispatch(currentChatAction.removeMainChatInfo());
            new ChatsApi()
                .postAddBlackList({
                    chatId: chat_uid,
                    token: source?.token,
                })
                .then((res) => {
                    if (res?.canceled) return;
                    if (res?.status) {
                        dispatch(womanAction.setIsBlocked(getWomanExternalID(), 1));
                        dispatch(chatsAction.removeFromChatList(chat_uid));
                    }
                });
        }
    };

    const getProfileInfo = () => {
        if (currentChatState?.info?.id) return currentChatState?.info;
        else if (currentChatMailboxState?.info?.female_id) {
            const {
                recipient_id,
                female_id,
                recipient_image,
                recipient_name,
                recipient_age,
                recipient_country_code,
                recipient_city,
                sender_city,
                sender_country_code,
                sender_age,
                sender_image,
                sender_name,
                recipient_search_img,
                sender_search_img,
                public_external_id,
                online,
                recipient_online,
                sender_online,
                country_code,
            } = currentChatMailboxState?.info || {};

            return {
                photo_link: +recipient_id === +female_id ? recipient_image : sender_image,
                search_img: +recipient_id === +female_id ? recipient_search_img : sender_search_img,
                name: +recipient_id === +female_id ? recipient_name : sender_name,
                id: +female_id,
                age: +recipient_id === +female_id ? recipient_age : sender_age,
                country_code:
                    (+recipient_id === +female_id ? recipient_country_code : sender_country_code) ||
                    country_code,
                city: +recipient_id === +female_id ? recipient_city : sender_city,
                public_external_id,
                online: (+recipient_id === +female_id ? recipient_online : sender_online) || online,
            };
        } else return {};
    };

    const getCurrentChatInfo = () => {
        if (currentChatState?.info?.id) {
            return {
                ...currentChatState?.info,
                ...{
                    goto: 'letter',
                    ...(currentChatState?.info?.favorite === undefined
                        ? { favorite: !!currentChatState?.info?.female_favorite }
                        : {}),
                },
            };
        } else if (
            currentChatMailboxState?.mailChatInfo?.chat_uid &&
            currentChatMailboxState?.info?.female_id
        ) {
            const { female_favorite, female_like, wink, chat_uid, connect } =
                currentChatMailboxState?.mailChatInfo || {};
            const { recipient_gifts, sender_gifts, recipient_id, female_id } =
                currentChatMailboxState?.info || {};

            return {
                chat_uid,
                goto: 'chat',
                wink: !+wink,
                like: !!+female_like,
                favorite: !!+female_favorite,
                gift: +recipient_id === +female_id ? !!+recipient_gifts : !!+sender_gifts,
                id: currentChatMailboxState?.info?.female_id,
                public_external_id: currentChatMailboxState?.info?.public_external_id,
                connect: connect,
            };
        } else return {};
    };

    const setChatPinned = () => {
        const { chat_uid } = getCurrentChatInfo();
        const chatPinned = currentChatState?.info?.pinned ? 0 : 1;

        new ChatsApi().postSetChatPinned(chat_uid, chatPinned).then((res) => {
            if (res?.status) {
                dispatch(currentChatAction.saveChatPinned(chatPinned));
                dispatch(chatsAction.setPinnedChatList(chat_uid, chatPinned));
            }
        });
    };

    return {
        profileChatInfo: { ...getProfileInfo(), ...getCurrentChatInfo() },
        currentChatInfo: getCurrentChatInfo(),
        getWomanExternalID,
        handleSetFavorite,
        openGift,
        handleLike,
        goTo,
        handleBlackList,
        getProfileInfo,
        sayHelloList,
        setChatPinned,
    };
}
