import { VideoShowActionTypes } from '../../constants/videoShow';
import { VIDEO_SHOW_FILTERS_DEFAULT } from '../../../constants/search';
import { IVideoShowState, VideoShowActions } from './index.interface';

const initState = {
    list: [],
    filter: VIDEO_SHOW_FILTERS_DEFAULT,
    page: 1,
    isLoading: true,
    isLoadingPage: false,
    isLoadedAll: false,
    isEmpty: false,
};

export default function (
    state: IVideoShowState = initState,
    action: VideoShowActions,
): IVideoShowState {
    switch (action.type) {
        case VideoShowActionTypes.INIT_VIDEO_SHOW_SEARCH: {
            return initState;
        }

        case VideoShowActionTypes.GET_VIDEO_SHOW_LIST: {
            if (!Array.isArray(action?.searchList)) return state;

            const updatedList =
                state?.page === 1 ? action?.searchList : [...state.list, ...action.searchList];

            return {
                ...state,
                ...{
                    list: updatedList,
                    isEmpty: !updatedList?.length,
                    isLoading: false,
                    isLoadingPage: false,
                    isLoadedAll: !!(updatedList?.length % 20),
                },
            };
        }

        case VideoShowActionTypes.SET_VIDEO_SHOW_LIST_PAGE: {
            return {
                ...state,
                ...{
                    page: action?.page,
                    isLoadingPage: true,
                },
            };
        }

        case VideoShowActionTypes.SET_IS_LOADING_PAGE: {
            return {
                ...state,
                ...{
                    isLoadingPage: action?.status,
                },
            };
        }

        case VideoShowActionTypes.SET_VIDEO_SHOW_LIST_FILTER: {
            return {
                ...state,
                ...{
                    filter: action?.filter,
                },
            };
        }

        case VideoShowActionTypes.SET_VIDEO_SHOW_ONLINE_STATUS: {
            const newList = state?.list?.length
                ? state?.list?.map((item) => {
                      if (action?.externalIDList?.includes(item?.external_id)) {
                          return { ...item, ...{ online: action?.status } };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ list: newList },
            };
        }

        case VideoShowActionTypes.SET_VIDEO_SHOW_LIKE_STATUS: {
            const newList = state?.list?.length
                ? state?.list?.map((item) => {
                      if (item?.external_id === +action?.womanExternalID) {
                          return { ...item, ...{ liked: 1 } };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ list: newList },
            };
        }

        default:
            return state;
    }
}
