import SITE from '../config/site';

const isLilu = SITE.ID === 4;

export const MALE_SEX = 'MALE_SEX';
export const FEMALE_SEX = 'FEMALE_SEX';

export const SEX_TEXT = {
    [FEMALE_SEX]: {
        //CHAT
        CHAT_EMPTY_SEARCH_BTN: 'Find a person',
        CHAT_EMPTY_VIEW_VIDEOS_BTN: 'Enjoy our gentlemen gorgeous appearance',
        CHAT_EMPTY_CHATROOM_SAY_HELLO_BTN: isLilu
            ? 'To show your interest, say hello'
            : 'To show your interest, say hello to him',

        //LETTER
        LETTER_FILTER_TITLE: isLilu ? 'PERSON' : 'Users',
        LETTER_COMPOSE_MODAL_ADD_BTN: isLilu ? 'Add person' : 'Add users',
        LETTER_WRITE_MODAL_ADD_BTN: isLilu ? 'Add more person' : 'Add more users',
        LETTER_FIRST_LETTER_TO_HER: isLilu ? 'First letter' : 'First letter to him',

        //SEARCH
        SEARCH_SIDE_MENU_BTN: 'Search',
        SEARCH_TITLE_FILTER_RANGE: isLilu ? 'Person’s' : 'User’s',

        //LIKEHER
        LIKE_SIDE_MENU_BTN: 'Like',
        LIKE_URL: 'like',
        LIKEHER_YOU_LIKED_TITLE: isLilu ? 'You liked' : 'You liked',
        LIKEHER_NEXT_BTN: 'Next',
        LIKEHER_FAST_FILTER_ALL_ONLINE: isLilu ? 'All people online' : 'All people online',

        //VIDEOSHOW
        VIDEOSHOW_SIDE_MENU_BTN: isLilu ? 'Videos' : "Users' Videos",

        //PROFILE
        PROFILE_APPEARANCE_TITLE: isLilu ? 'preferences' : 'user’s preferences',
        PROFILE_APPEARANCE_AGE_RANGE_TITLE: 'USER’S AGE RANGE',
        PROFILE_HOBBY_TITLE: 'USER’S HOBBY & HABITS',

        //FAQ
        FAQ_SIDE_MENU_BTN: 'FAQ',
        FAQ_URL: 'faq',

        //TUTORIAL
        TUTORIAL_SIDE_MENU_BTN: 'Tutorial',
        TUTORIAL_URL: 'tutorial',

        //POLICY
        POLICY_SIDE_MENU_BTN: 'Policy',

        //OTHER MODALS
        MODERATION_MODAL_ID: isLilu ? 'Person ID' : 'User ID',
        CONTACT_REQUEST_MODAL_TITLE:
            'Contact request implies that you can receive gentlemen’s e-mail and start communicating with him outside our platform.',
        CONTACT_REQUEST_MODAL_SEX: isLilu ? 'person' : 'user',
        CONTACT_REQUEST_MODAL_SEX2: isLilu ? 'person`s' : 'user`s',
        CONTACT_REQUEST_MODAL_SEX3: 'gentlemen',
        CONTACT_REQUEST_MODAL_SEX4: isLilu ? 'this person' : 'him',
        CONTACT_REQUEST_MODAL_SEX5: 'he',
        GIFT_MODAL_MESSAGE_TITLE: isLilu ? 'person' : 'user',
        GIFT_MODAL_MESSAGE_DESCRIPTION: isLilu ? 'person' : 'user',
        GALLERY_MODAL_START_CHATTING_BTN: isLilu ? 'start chatting' : 'start chatting with him',
        GALLERY_MODAL_START_CHATTING_TITLE: isLilu
            ? 'start chatting'
            : 'To view member’s private photos, start chatting with him',
        WOMAN_PROFILE_MODAL_ASK_VIDEO_TITLE: 'ASK USER FOR A VIDEO',
    },
    [MALE_SEX]: {
        //CHAT
        CHAT_EMPTY_SEARCH_BTN: 'Find a member',
        CHAT_EMPTY_VIEW_VIDEOS_BTN: "Enjoy our members' gorgeous appearance",
        CHAT_EMPTY_CHATROOM_SAY_HELLO_BTN: 'To show your interest, say hello to her',

        //LETTER
        LETTER_FILTER_TITLE: 'MEMBERS',
        LETTER_COMPOSE_MODAL_ADD_BTN: 'Add members',
        LETTER_WRITE_MODAL_ADD_BTN: 'Add more members',
        LETTER_FIRST_LETTER_TO_HER: 'First letter to her',

        //SEARCH
        SEARCH_SIDE_MENU_BTN: 'Search',
        SEARCH_TITLE_FILTER_RANGE: 'Member’s',

        //LIKEHER
        LIKE_SIDE_MENU_BTN: 'Like',
        LIKE_URL: 'like',
        LIKEHER_YOU_LIKED_TITLE: 'Members you liked',
        LIKEHER_NEXT_BTN: 'Next member',
        LIKEHER_FAST_FILTER_ALL_ONLINE: 'All online members',

        //VIDEOSHOW
        VIDEOSHOW_SIDE_MENU_BTN: 'Videos',

        //PROFILE
        PROFILE_APPEARANCE_TITLE: 'MEMBER YOU’RE LOOKING FOR',
        PROFILE_APPEARANCE_AGE_RANGE_TITLE: 'MEMBER’S AGE RANGE',
        PROFILE_HOBBY_TITLE: 'MEMBER’S HOBBY & HABITS',

        //FAQ
        FAQ_SIDE_MENU_BTN: 'FAQ',
        FAQ_URL: 'faq',

        //TUTORIAL
        TUTORIAL_SIDE_MENU_BTN: 'Tutorial',
        TUTORIAL_URL: 'tutorial',

        //POLICY
        POLICY_SIDE_MENU_BTN: 'Policy',

        //OTHER MODALS
        CONTACT_REQUEST_MODAL_TITLE:
            'A contact request is made so that you can receive the member’s e-mail and start communicating with her outside of our platform.',
        CONTACT_REQUEST_MODAL_SEX: 'member',
        CONTACT_REQUEST_MODAL_SEX2: 'member`s',
        CONTACT_REQUEST_MODAL_SEX3: 'member',
        CONTACT_REQUEST_MODAL_SEX4: 'her',
        CONTACT_REQUEST_MODAL_SEX5: 'she',
        GIFT_MODAL_MESSAGE_TITLE: 'member',
        GIFT_MODAL_MESSAGE_DESCRIPTION: 'member',
        MODERATION_MODAL_ID: 'Member ID',
        GALLERY_MODAL_START_CHATTING_BTN: 'start chatting with her',
        GALLERY_MODAL_START_CHATTING_TITLE:
            'To view member’s private photos, start chatting with her',
        WOMAN_PROFILE_MODAL_ASK_VIDEO_TITLE: 'ASK MEMBER FOR A VIDEO',
    },
};
