import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';
import Styles from './Header.module.scss';
import { getAvatarMiniature, stringToColor, substrName } from '../../../../services/methods';
import * as MENU from '../../../../constants/menu';
import IconCoins from '../../../Icons/IconCoins';
import IconProfile from '../../../Icons/IconProfile';
import useTransition from '../../../../hooks/transition/useTransition';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import MenuLogo from './MenuLogo';
import MenuCreditButton from './MenuCreditButton';
import { RootState } from '../../../../index';
import IconCrownVip from '../../../Icons/IconCrownVip';
import IconAvatarVip from '../../../Icons/IconAvatarVip';

export default function MenuHeader() {
    const userState = useSelector((state: RootState) => state.user);
    const giftsState = useSelector((state: RootState) => state.gifts);

    const transition = useTransition();
    const handleActiveBlocks = useHandleActiveBlocks();

    const userAvatar = () => {
        if (userState?.info?.photo_link_w56_h56) {
            return (
                <>
                    {!!+userState?.info?.is_verified && (
                        <div className={styles.c1_profile_photo_crown_vip}>
                            <IconCrownVip />
                        </div>
                    )}
                    <img src={userState?.info?.photo_link_w56_h56} alt="" />
                </>
            );
        } else if (userState?.info?.photo_link) {
            return (
                <>
                    {!!+userState?.info?.is_verified && (
                        <div className={styles.c1_profile_photo_crown_vip}>
                            <IconCrownVip />
                        </div>
                    )}
                    <img src={getAvatarMiniature(userState?.info?.photo_link)} alt="" />
                </>
            );
        } else {
            return (
                <div className="user-info">
                    {userState?.info?.is_verified ? (
                        <div className={styles.c1_profile_photo_user_vip}>
                            <IconAvatarVip />
                        </div>
                    ) : (
                        <div
                            className={Styles.user_info_avatar}
                            style={{ backgroundColor: stringToColor(userState?.info?.name || '') }}
                        >
                            {substrName(userState?.info?.name)}
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <div className={styles.c1_top} data-testid="header-menu">
            <MenuLogo />
            <div className={styles.c1_profile_wrap}>
                <div
                    className={`${styles.c1_profile_photo} ${userState?.info?.is_verified ? styles.user_vip : ''}`}
                    data-testid="avatar"
                >
                    {userAvatar()}
                </div>
                <div className={styles.c1_profile_links}>
                    <button
                        type="button"
                        className={styles.profile}
                        onClick={(event) => {
                            if (window?.location?.pathname !== `/${MENU.PROFILE_PAGE}`) {
                                transition.transitionWithOnlyHistoryPush('/my-profile');
                            }
                            event?.currentTarget?.blur();
                        }}
                        data-testid="profile-btn"
                    >
                        {!giftsState.newGiftMessageCount && <IconProfile />}
                        <span>My profile</span>
                        {!!giftsState.newGiftMessageCount && (
                            <b>{giftsState.newGiftMessageCount}</b>
                        )}
                    </button>
                    {handleActiveBlocks.menu?.isActiveCreditMenu && (
                        <button
                            type="button"
                            className={styles.credits}
                            onClick={(event) => {
                                transition.transitionWithOnlyHistoryPush(
                                    '/my-profile/purchase-history',
                                );
                                event?.currentTarget?.blur();
                            }}
                            data-testid="credits-btn"
                        >
                            <IconCoins />
                            <span>Credits</span>
                        </button>
                    )}
                </div>
            </div>
            {handleActiveBlocks.menu?.isActiveCreditMenu && <MenuCreditButton />}
        </div>
    );
}
