export enum PurchaseActionTypes {
    GET_PURCHASE_LIST = 'GET_PURCHASE_LIST',
    ADD_LAST_BUY_PACKAGE = 'ADD_LAST_BUY_PACKAGE',
    REMOVE_LAST_BUY_PACKAGE = 'REMOVE_LAST_BUY_PACKAGE',
    SET_TOKEN_LIST = 'SET_TOKEN_LIST',
    REMOVE_PURCHASE_LIST = 'REMOVE_PURCHASE_LIST',
    SET_IS_ADD_CARD = 'SET_IS_ADD_CARD',
    INIT_SUBSCRIBE_STATUS = 'INIT_SUBSCRIBE_STATUS',
    SET_ACTIVE_SUBSCRIBE = 'SET_ACTIVE_SUBSCRIBE',
    CANCEL_ACTIVE_SUBSCRIBE = 'CANCEL_ACTIVE_SUBSCRIBE',
    GET_ACTIVE_SUBSCRIBE_LIST_INFO = 'GET_ACTIVE_SUBSCRIBE_LIST_INFO',
    CLEAR_ACTIVE_SUBSCRIBE_LIST_INFO = 'CLEAR_ACTIVE_SUBSCRIBE_LIST_INFO',
    // SET_SUBSCRIBE_EXTRA_STATUS = 'SET_SUBSCRIBE_EXTRA_STATUS', // removed
    // GET_SUBSCRIBE_EXTRA_PLAN_METHOD = 'GET_SUBSCRIBE_EXTRA_PLAN_METHOD', // removed
    SET_SUCCESS_SUBSCRIBE_MODAL = 'SET_SUCCESS_SUBSCRIBE_MODAL',
    SET_CHANGE_SUBSCRIBE_MODAL = 'SET_CHANGE_SUBSCRIBE_MODAL',
    SET_SUCCESS_CHANGE_SUBSCRIBE_MODAL = 'SET_SUCCESS_CHANGE_SUBSCRIBE_MODAL',
    SET_IS_CHANGE_SUBSCRIPTION = 'SET_IS_CHANGE_SUBSCRIPTION',
    SET_IS_BUYING_PASTABANK = 'SET_IS_BUYING_PASTABANK',
    SET_IS_PAYMENT_FAILED = 'SET_IS_PAYMENT_FAILED',
    SET_ACTIVE_PACKAGE = 'SET_ACTIVE_PACKAGE',
    SET_ACTIVE_PAYMENT_METHOD = 'SET_ACTIVE_PAYMENT_METHOD',
    SET_IS_PASTA_PAYMENT_FAILED = 'SET_IS_PASTA_PAYMENT_FAILED',
}
