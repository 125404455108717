import IPApi from '../api/IPApi';
import FrodApi from '../api/FrodApi';
import BinomoApi from '../api/BinomoApi';
import TailApi from '../api/TailApi';
import { osVersion, osName, isMobile } from 'react-device-detect';
import { diffTimeInDaysWithCurrentDay } from '../time';
import * as USER from '../../constants/user';
import ClickHistoryApi from '../api/v3/ClickHistoryApi';
import { ISendBinomoFromStore } from './index.interface';
import { localStorageGetItem, localStorageSetItem } from '../../utils/local-storage';
import { sendErrToSentry } from '../sentry';
import { IUserState } from '../../store/reducers/user/index.interface';
import { BINOMO_SIGNUP, BINOMO_UPLOAD_AVATAR } from '../../constants/user';

export function checkFraud(externalID: number, siteID: number): void {
    try {
        new IPApi().getIPData().then((ipData) => {
            if (ipData) {
                new FrodApi().postFrod({
                    ...ipData,
                    ...{
                        external_id: externalID,
                        site_id: siteID,
                        getFingerprint: localStorageGetItem('fingerprintHash') || '',
                    },
                });
            }
        });
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}

export async function sendBinomoFromStore({
    externalID,
    siteID,
    status,
    clickid,
    utm_aff_hash,
    ghost,
    email,
    tail = '',
    count = 0,
}: ISendBinomoFromStore): Promise<void> {
    const isTestEmail = email?.includes('test');
    if (isTestEmail) return;
    try {
        if (externalID && siteID && !ghost) {
            new ClickHistoryApi().postEvents(externalID, status);
            await checkFraud(externalID, siteID);

            // LOGIC FOR SIGHNUP AND PHOTO GO TO BACKEND
            if (status && clickid && ![BINOMO_SIGNUP, BINOMO_UPLOAD_AVATAR].includes(status)) {
                if (utm_aff_hash) {
                    const checkOfferReq = await new TailApi().checkAffOffer({
                        external_id: externalID,
                        utm_aff_hash: utm_aff_hash,
                        device: {
                            device: isMobile ? 'mob' : 'desk',
                            os: `${osName} ${osVersion}`,
                        },
                        status,
                    });
                    if (checkOfferReq?.status) {
                        new BinomoApi().getBinomo(status, clickid);
                        if (tail?.includes('utm_aff=aff_crix') && +count > 0 && +count < 4) {
                            const params = new URLSearchParams(tail);
                            const crixClickid = params.get('utm_medium');
                            const amount = +`1.0${count}`;

                            new BinomoApi().sendEventCrix(crixClickid, amount);
                        }
                    }
                } else new BinomoApi().getBinomo(status, clickid);
            }
        }
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}

export function diffBinomoByRegisterDate(userInfo: IUserState): void {
    if (!userInfo?.info?.external_id) return;
    if (!userInfo?.info?.site_id) return;

    const diffDay: string = localStorageGetItem(`rd-${userInfo?.info?.external_id}`, true) || '';
    const is2Day = localStorageGetItem(`rdb-2-${userInfo?.info?.external_id}`, true);
    const is6Day = localStorageGetItem(`rdb-6-${userInfo?.info?.external_id}`, true);

    if (diffTimeInDaysWithCurrentDay(diffDay) >= 2 && !is2Day) {
        localStorageSetItem(`rdb-2-${userInfo?.info?.external_id}`, '1');
        if (diffTimeInDaysWithCurrentDay(diffDay) === 2) {
            sendBinomoFromStore({
                externalID: userInfo?.info?.external_id,
                siteID: userInfo?.info?.site_id,
                status: USER.BINOMO_AFTER_REGISTER_2_DAY,
                clickid: userInfo?.clickid,
                utm_aff_hash: userInfo?.utm_aff_hash,
                ghost: userInfo?.info?.ghost,
                email: userInfo?.info?.email,
                tail: userInfo?.tail,
            });
        }
    }

    if (diffTimeInDaysWithCurrentDay(diffDay) >= 6 && !is6Day) {
        localStorageSetItem(`rdb-6-${userInfo?.info?.external_id}`, '1');
        if (diffTimeInDaysWithCurrentDay(diffDay) === 6) {
            sendBinomoFromStore({
                externalID: userInfo?.info?.external_id,
                siteID: userInfo?.info?.site_id,
                status: USER.BINOMO_AFTER_REGISTER_6_DAY,
                clickid: userInfo?.clickid,
                utm_aff_hash: userInfo?.utm_aff_hash,
                ghost: userInfo?.info?.ghost,
                email: userInfo?.info?.email,
                tail: userInfo?.tail,
            });
        }
    }
}
