import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PATHS } from '../constants/path';

import MainLayout from '../layouts/MainLayout';
import UserRoute from './UserRoute';
import HiddenAfterAuthRoute from './HiddenAfterAuthRoute';

import AuthPage from '../pages/AuthPage';
import ChatPage from '../pages/ChatPage';
import LettersPage from '../pages/LettersPage';
import ProfilePage from '../pages/ProfilePage';
import ProfilePageBlockListPage from '../pages/ProfilePageBlockListPage';
import ProfilePurchaseHistoryPage from '../pages/ProfilePurchaseHistoryPage';
import ProfileRequestContactPage from '../pages/ProfileRequestContactPage';
import ProfileRequestMeetingPage from '../pages/ProfileRequestMeetingPage';
import ProfileGiftsPage from '../pages/ProfileGiftsPage';
import LikePage from '../pages/LikePage';
import LikeHistoryPage from '../pages/LikeHistoryPage';
import SearchPage from '../pages/SearchPage';
import ProfileWomanPage from '../pages/ProfileWomanPage';
import VideoShowPage from '../pages/VideoShowPage';
import GiftsPage from '../pages/GiftsPage';
import GiftsCartPage from '../pages/GiftsCartPage';
import StaticPage from '../pages/StaticPage';
import ConfirmPage from '../pages/ConfirmPage';
import MenuPage from '../pages/MenuPage';
import PromocodePage from '../pages/PromocodePage';
import LoveStoriesPage from '../pages/LoveStoriesPage';
import OnlineNowPage from '../pages/OnlineNowPage';
import FAQPage from '../pages/FAQPage';
import TutorialPage from '../pages/TutorialPage';
import PolicyPage from '../pages/PolicyPage';
import CheckoutPage from '../pages/CheckoutPage';
import MailUnsubscribePage from '../pages/MailUnsubscribePage';
import ErrorPage from '../pages/ErrorPage';
import withErrorCatch from '../hocs/error-catch';
import useAccessKeyRedirect from '../hooks/transition/useAccessKeyRedirect';
import PostsPage from '../pages/PostsPage';
import ProfileEditPage from '../pages/ProfileEditPage';
import ProfileAccountSettingsPage from '../pages/ProfileAccountSettingsPage';
import ProfilePremiumPage from '../pages/ProfilePremiumPage';
import ProfileOtherUserPage from '../pages/ProfileAnotherUserPage';
import ForbiddenPage from '../pages/ForbiddenPage';

export const Router = withErrorCatch(() => {
    const { isAccessKeyChecked } = useAccessKeyRedirect();

    return (
        <main className="content">
            {isAccessKeyChecked && (
                <Switch>
                    <HiddenAfterAuthRoute
                        exact
                        path="/"
                        component={(props) => (
                            <MainLayout {...props}>
                                <AuthPage {...props} />
                            </MainLayout>
                        )}
                    />

                    <UserRoute exact path={PATHS.ONLINE_NOW.path} component={OnlineNowPage} />
                    <UserRoute exact path={PATHS.LETTER.path} component={LettersPage} />
                    <UserRoute exact path={PATHS.LIKE_HISTORY.path} component={LikeHistoryPage} />
                    <UserRoute exact path={PATHS.LIKE.path} component={LikePage} />
                    <UserRoute exact path={PATHS.MENU.path} component={MenuPage} />
                    <UserRoute exact path={PATHS.SEARCH.path} component={SearchPage} />
                    <UserRoute exact path={PATHS.CHAT.path} component={ChatPage} />
                    <UserRoute exact path={PATHS.CHAT_UNANSWERED.path} component={ChatPage} />
                    <UserRoute exact path={PATHS.CHAT_FAVORITE.path} component={ChatPage} />
                    <UserRoute exact path={PATHS.CHAT_LIKES.path} component={ChatPage} />
                    <UserRoute exact path={PATHS.MY_PROFILE.path} component={ProfilePage} />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_PURCHASE_HISTORY.path}
                        component={ProfilePurchaseHistoryPage}
                    />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_CONTACT_REQUEST.path}
                        component={ProfileRequestContactPage}
                    />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_MEETING_REQUEST.path}
                        component={ProfileRequestMeetingPage}
                    />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_GIFTS.path}
                        component={ProfileGiftsPage}
                    />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_BLOCK_LIST.path}
                        component={ProfilePageBlockListPage}
                    />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_EDIT.path}
                        component={ProfileEditPage}
                    />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_ACCOUNT_SETTINGS.path}
                        component={ProfileAccountSettingsPage}
                    />
                    <UserRoute
                        exact
                        path={PATHS.MY_PROFILE_PREMIUM.path}
                        component={ProfilePremiumPage}
                    />
                    <UserRoute exact path={PATHS.VIDEO_SHOW.path} component={VideoShowPage} />
                    <UserRoute exact path={PATHS.PROFILE_WOMAN.path} component={ProfileWomanPage} />
                    <UserRoute exact path={PATHS.PROFILE.path} component={ProfileOtherUserPage} />
                    <UserRoute exact path={PATHS.GIFTS.path} component={GiftsPage} />
                    <UserRoute exact path={PATHS.GIFTS_CART.path} component={GiftsCartPage} />
                    <UserRoute exact path={PATHS.FAQ.path} component={FAQPage} />
                    <UserRoute exact path={PATHS.TUTORIAL.path} component={TutorialPage} />
                    <UserRoute exact path={PATHS.POLICY.path} component={PolicyPage} />
                    <UserRoute exact path={PATHS.CHECKOUT.path} component={CheckoutPage} />
                    <UserRoute
                        exact
                        path={PATHS.MAIL_UNSUBSCRIBE.path}
                        component={MailUnsubscribePage}
                    />
                    <UserRoute exact path={PATHS.POSTS.path} component={PostsPage} />
                    <HiddenAfterAuthRoute
                        exact
                        path={PATHS.HASH.path}
                        component={(props) => (
                            <MainLayout {...props}>
                                <AuthPage {...props} />
                            </MainLayout>
                        )}
                    />
                    <Route exact path={PATHS.CONFIRM.path} component={ConfirmPage} />
                    <Route exact path={PATHS.PROMOCODE.path} component={PromocodePage} />
                    <Route exact path={PATHS.LOVE_STORIES.path} component={LoveStoriesPage} />
                    <Route
                        exact
                        path={PATHS.ERROR.path}
                        component={() => (
                            <ErrorPage
                                customError={'Page Error'}
                                customErrorDescription={'Something went wrong...'}
                            />
                        )}
                    />
                    <HiddenAfterAuthRoute
                        exact
                        path={PATHS.FORBIDDEN.path}
                        component={(props) => (
                            <MainLayout {...props}>
                                <ForbiddenPage />
                            </MainLayout>
                        )}
                    />
                    <Route exact path={PATHS.NOT_FOUND.path} component={ErrorPage} />
                    <Route exact path={PATHS.STATIC_PAGE.path} component={StaticPage} />
                </Switch>
            )}
        </main>
    );
});
