import React, { useEffect } from 'react';
import LikeFilter from './LikeFilter';
import SITE from '../../../config/site';
import { useDispatch, useSelector } from 'react-redux';
import * as likesAction from '../../../store/actions/likes';
import IconSearch from '../../Icons/IconSearch';
import { RootState } from '../../../index';
import { LIKE_FILTERS_DEFAULT } from '../../../constants/filter';

interface ILikeFilters {
    wideTab: boolean;
    changeTabNoWide: () => void;
    changeTabWide: () => void;
    setFilterType: (filter: 'ONLINE' | 'LIKED_YOU') => void;
    dictionary: {
        ONLINE: string;
        LIKED_YOU: string;
    };
    submitFilter: (isCloseMobSideMenu?: boolean) => void;
}

export default function LikeFilters({
    wideTab,
    changeTabNoWide,
    changeTabWide,
    setFilterType,
    dictionary,
    submitFilter,
}: ILikeFilters) {
    const likesState = useSelector((state: RootState) => state.likes);
    const tabs = ['ONLINE', 'LIKED_YOU'] as const;

    function isDefaultFilter(filter: any): filter is typeof LIKE_FILTERS_DEFAULT {
        return (
            typeof filter.heightFrom === 'number' &&
            typeof filter.heightTo === 'number' &&
            typeof filter.bodyType === 'number' &&
            typeof filter.hairColor === 'number' &&
            typeof filter.eyeColor === 'number' &&
            typeof filter.maritalStatus === 'number'
        );
    }

    const dispatch = useDispatch();

    const filterState = likesState?.filter as typeof LIKE_FILTERS_DEFAULT;

    useEffect(() => {
        return () => {
            dispatch(likesAction.initLikesState(LIKE_FILTERS_DEFAULT));
        };
    }, []);

    useEffect(() => {
        submitFilter();
    }, [
        filterState?.type,
        // filterState?.from,
        // filterState?.to,
        // filterState?.heightFrom,
        // filterState?.heightTo,
        // filterState?.bodyType,
        // filterState?.hairColor,
        // filterState?.eyeColor,
        // filterState?.maritalStatus,
    ]);

    useEffect(() => {
        if (likesState?.pageLikeList > 1) {
            dispatch(likesAction.getLikeList(likesState?.pageLikeList, likesState?.filter));
        }
    }, [likesState?.pageLikeList]);

    useEffect(() => {
        if (likesState?.list?.length && !likesState?.isLoadedAll) {
            if (likesState?.list?.length - likesState?.selectedListIndex <= 15) {
                dispatch(likesAction.setLikeListPage(likesState?.pageLikeList + 1));
            }
        }
    }, [likesState?.selectedListIndex, likesState?.list?.length]);

    return (
        <>
            {SITE.ID !== 4 && (
                <div className="cl2h_tabs_wrap">
                    <div className="cl2h_tabs_buttons">
                        <div
                            className={`cl2h_tab_btn fast_btn ${!wideTab ? 'active' : ''}`}
                            onClick={changeTabNoWide}
                        >
                            Fast filter
                        </div>
                        <div
                            className={`cl2h_tab_btn wide_btn  ${wideTab ? 'active' : ''}`}
                            onClick={changeTabWide}
                        >
                            Wide filter
                        </div>
                    </div>

                    {!wideTab && (
                        <div className="cl2h_tab cl2h_tab_fast_filter">
                            {tabs.map((item) => {
                                const isActive = item === filterState?.type ? 'active' : '';
                                return (
                                    <div
                                        key={item}
                                        className={`cl2h_tab_fast_filter_item ${isActive}`}
                                        onClick={() => setFilterType(item)}
                                    >
                                        {dictionary[item]}
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {wideTab && isDefaultFilter(likesState?.filter) && (
                        <div className="cl2h_tab cl2h_tab_wide_filter">
                            <LikeFilter
                                filter={likesState?.filter}
                                setFilter={(value) => dispatch(likesAction.setFilter(value))}
                            />

                            <div
                                className="cl2h_tab_wide_filter_search_btn"
                                onClick={() => submitFilter(window.innerWidth < 1140)}
                            >
                                <IconSearch />
                                <span>Search</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
