import Base from './Base';

export default class MarketingApi extends Base {
    async getCrossBannerData() {
        const res = await super.get('marketing/cross-banner');
        return res?.data;
    }

    async postViewCrossBanner(logId: number) {
        const res = await super.post('marketing/view-cross-banner', { log_id: logId });
        return res && res?.data;
    }

    async postClickCrossBanner(logId: number) {
        const res = await super.post('marketing/click-cross-banner', { log_id: logId });
        return res && res?.data;
    }

    async postCheckExistEvent(eventName: string) {
        //if res.data.response.check: true, event does not exist yet
        try {
            const res = await super.post('marketing/checkMarketingConversion', { eventName });
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
