import React, { useEffect, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { getImageLink } from '../../../../../services/methods/search/images';
import * as IMAGES from '../../../../../constants/images';
import MailboxApi from '../../../../../services/api/MailboxApi';
import * as alertAction from '../../../../../store/actions/alert';
import * as ERROR from '../../../../../constants/error';
import * as ALERT from '../../../../../constants/alert';
import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../../../../../hooks/transition/useTransition';
import * as MENU from '../../../../../constants/menu';
import NotifyApi from '../../../../../services/api/NotifyApi';
import * as modalsAction from '../../../../../store/actions/modals';
import * as WOMAN_PROFILE from '../../../../../constants/woman-profile';
import useSendWink from '../../../../../hooks/wink/useSendWink';
import * as SEX from '../../../../../constants/sex';
import LoaderGif from '../../../LoaderGif';
import * as loaderAction from '../../../../../store/actions/loader';
import * as FRONT_SYNC from '../../../../../constants/frontSync';
import useSendActionSync from '../../../../../hooks/frontSync/useSendActionSync';
import SearchApi from '../../../../../services/api/v3/SearchApi';
import styles from './index.module.scss';
import { ImageCarousel } from '../../../ImageCarousel';
import useHandleActiveBlocks from '../../../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../../../index';
import { IWomanGalleryProps } from './index.interface';
import IconChat from '../../../../Icons/IconChat';
import IconProfileChat from '../../../../Icons/IconProfileChat';
import IconHelloHand from '../../../../Icons/IconHelloHand';
import IconCross from '../../../../Icons/IconCross';
import useGetWinkList from '../../../../../hooks/wink/useGetWinkList';

export default function WomanGallery({
    externalID,
    closeGalleryModal,
    currentPhotoID = 0,
}: IWomanGalleryProps) {
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const modalsState = useSelector((state: RootState) => state.modals);

    const [gallery, setGallery] = useState<any>([]);
    const [startIndex, setStartIndex] = useState<number>(0);
    const [winkStatus, setWinkStatus] = useState<boolean>(false);
    const [countPrivate, setCountPrivate] = useState<number | null>(null);
    const [privateImage, setPrivateImage] = useState(null);
    const [isHelloOpen, setIsHelloOpen] = useState(false);

    const dispatch = useDispatch();
    const transition = useTransition();
    const { sayHelloList } = useGetWinkList({});
    const sendWink = useSendWink();
    const sendActionSync = useSendActionSync();
    const handleActiveBlocks = useHandleActiveBlocks();

    const changeIsHelloOpen = () => setIsHelloOpen(!isHelloOpen);

    const activeSecondBtn =
        externalID && !winkStatus
            ? WOMAN_PROFILE.PROFILE_SAY_HELLO_BUTTON
            : WOMAN_PROFILE.PROFILE_PROFILE_BUTTON;

    const renderButton = (type: string) => {
        switch (type) {
            case WOMAN_PROFILE.PROFILE_CHAT_BUTTON:
                return (
                    <div
                        className="popup_likeher_search_photos_big_photo_chat_btn"
                        onClick={goToChat}
                    >
                        <IconChat />
                        <span>Start chatting</span>
                    </div>
                );

            case WOMAN_PROFILE.PROFILE_PROFILE_BUTTON:
                return (
                    <div
                        className="popup_likeher_search_photos_big_photo_profile_btn"
                        onClick={goToProfile}
                    >
                        <IconProfileChat />
                        <span>View profile</span>
                    </div>
                );

            case WOMAN_PROFILE.PROFILE_SAY_HELLO_BUTTON:
                return (
                    <div className="popup_likeher_search_photos_big_photo_hello_btn_wrap">
                        <div
                            className={`popup_likeher_search_photos_big_photo_profile_btn hello_btn ${isHelloOpen ? 'active' : ''}`}
                            onClick={changeIsHelloOpen}
                        >
                            <IconHelloHand />
                            <span>Say “Hello”</span>
                        </div>
                        {isHelloOpen && (
                            <div className="c3_wp_hello_list_wrap">
                                <div className="c3_wp_hello_list_title">Choose first message</div>
                                <div className="c3_wp_hello_list">
                                    {!!sayHelloList?.length &&
                                        sayHelloList?.map((item, key) => (
                                            <div
                                                className="c3_wp_hello_list_item"
                                                onClick={(e) => sayHello(e, item)}
                                                key={key}
                                            >
                                                {item}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                );

            default:
                return <div />;
        }
    };

    const filterByBlurImage = (list: any) => {
        const freeImageList = list.filter((item: any) => +item?.free);
        const paidImageList = list.filter((item: any) => !+item?.free);

        let resultPaidList = [];

        if (paidImageList?.length) {
            resultPaidList = +paidImageList[0]?.payed ? paidImageList : [];
        }

        return [...freeImageList, ...resultPaidList];
    };

    const goToChat = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(modalsAction.closeWomanProfileModal());

        userState?.info &&
            new MailboxApi()
                .createChatWithProfile(userState?.info?.external_id, externalID)
                .then((res) => {
                    if (+res?.chat_info?.male_block) {
                        return dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    }
                    if (res?.status && res?.chat_uid) {
                        closeGalleryModal();
                        transition.transitionWithHistoryPush(
                            MENU.OPEN_CHAT,
                            `/chat/${res?.chat_uid}`,
                        );
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                });
    };

    const sayHello = (e: React.MouseEvent<HTMLDivElement>, message: string) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(modalsAction.closeWomanProfileModal());
        dispatch(loaderAction.setActiveGifLoader(true));

        userState?.info &&
            new MailboxApi()
                .createChatWithProfile(userState?.info?.external_id, externalID)
                .then((res) => {
                    if (+res?.chat_info?.male_block) {
                        dispatch(loaderAction.setActiveGifLoader(false));
                        return dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    }
                    if (res?.status && res?.chat_uid) {
                        closeGalleryModal();
                        sendWink({
                            id: externalID,
                            helloText: message,
                        }).then(() => dispatch(loaderAction.setActiveGifLoader(false)));
                        transition.transitionWithHistoryPush(
                            MENU.OPEN_CHAT,
                            `/chat/${res?.chat_uid}`,
                        );
                    } else {
                        dispatch(loaderAction.setActiveGifLoader(false));
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                })
                .finally(() => setIsHelloOpen(false))
                .catch(() => dispatch(loaderAction.setActiveGifLoader(false)));
    };

    const goToProfile = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (Number(modalsState?.womanProfileModal?.externalID) === +externalID) closeGalleryModal();
        else {
            closeGalleryModal();
            dispatch(modalsAction.closeWomanProfileModal());
            dispatch(modalsAction.openWomanProfileModal(externalID));
        }
    };

    useEffect(() => {
        if (MENU.OPEN_PHOTO_GALLERY_ENABLE_PAGES.includes(menuState.main)) {
            new NotifyApi().postViewPhotos(+externalID).then((res) => {
                if (!res?.response?.message_object) return;
                sendActionSync.postUserAction(+externalID, FRONT_SYNC.ACTION_VIEW_PHOTO);
            });
        }

        if (!gallery?.length) {
            sendActionSync.postUserAction(+externalID, FRONT_SYNC.ACTION_VIEW_PHOTO);

            new SearchApi()
                .getWoman([+externalID])
                .then((res) => (res?.status ? res?.response : []))
                .then((array) => {
                    const womanProfile = array?.[0];
                    if (!womanProfile?.images || !womanProfile?.images?.length) return;

                    const galleryFull = womanProfile?.images;
                    const gallery = filterByBlurImage(
                        galleryFull.filter((image: any) => +image?.free === 1 || image?.payed),
                    );
                    const searchIndex = gallery.findIndex((obj) => obj?.id === currentPhotoID);
                    const isWinked = !!womanProfile?.wink;

                    setWinkStatus(isWinked);
                    setCountPrivate(
                        galleryFull?.filter((image: any) => +image?.free === 0 && !image?.payed)
                            ?.length,
                    );
                    setPrivateImage(
                        galleryFull?.filter(
                            (image: any) => +image?.free === 0 && !image?.payed,
                        )?.[0] || null,
                    );
                    setGallery(gallery);
                    setStartIndex(currentPhotoID ? (searchIndex > 0 ? searchIndex : 0) : 0);

                    if (!gallery?.length) closeGalleryModal();
                });
        }
    }, []);

    const renderGalleryItemCounterWithButtons = (item: any) => {
        const viewTitle = handleActiveBlocks.isSearchV3
            ? 'To view member’s private photos, start chatting'
            : 'To view member’s private photos, start chatting with her';

        return (
            <div className={styles.image_gallery_container}>
                {!item?.free && (
                    <div className="popup_likeher_search_photos_unpaid_warning">
                        <span>
                            This member has {Number(countPrivate) > 0 ? countPrivate : ''} other
                            private photos
                        </span>
                        {viewTitle}
                    </div>
                )}
                <img className={styles.image} src={item.original} alt="" />
                <div className="popup_likeher_search_photos_big_photo_chat_wrap">
                    {renderButton(WOMAN_PROFILE.PROFILE_CHAT_BUTTON)}
                    {renderButton(activeSecondBtn)}
                </div>
            </div>
        );
    };

    return gallery?.length ? (
        <div className="popup_likeher_search_photos">
            <div className="popup_likeher_search_photos_content content_new_gallery">
                <ImageCarousel
                    startIndex={startIndex}
                    photos={privateImage ? [...gallery, privateImage] : gallery}
                    setCurrentIndex={setStartIndex}
                    renderGalleryItemCounter={renderGalleryItemCounterWithButtons}
                />
                <div className="popup_likeher_search_photos_list_wrap">
                    <div className="popup_likeher_search_photos_list">
                        {gallery.map((item: any, key: number) => {
                            const isFreeClass = !item?.payed && !+item?.free ? 'unpaid' : '';
                            return (
                                <div
                                    key={key}
                                    className={`popup_likeher_search_photos_list_item ${isFreeClass}`}
                                    onClick={() => setStartIndex(key)}
                                >
                                    <img src={getImageLink(item?.link, IMAGES.SIZE_L)} alt="" />
                                </div>
                            );
                        })}
                        {!!countPrivate && (
                            <div className="popup_likeher_search_photos_list_item w_text">
                                <div className="popup_likeher_search_photos_list_item_title">{`To view ${+countPrivate > 0 ? countPrivate : ''} other private photos,`}</div>
                                <div className="popup_likeher_search_photos_list_item_text">
                                    {handleActiveBlocks.isSearchV3
                                        ? 'start chatting'
                                        : SEX.SEX_TEXT[
                                              userState?.info?.sex_text as 'FEMALE_SEX' | 'MALE_SEX'
                                          ].GALLERY_MODAL_START_CHATTING_BTN}
                                </div>
                                <div
                                    className="popup_likeher_search_photos_list_item_chat_btn"
                                    onClick={goToChat}
                                >
                                    <IconChat />
                                    <span>Chat</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="popup_likeher_search_photos_close" onClick={() => closeGalleryModal()}>
                <IconCross />
            </div>
        </div>
    ) : (
        <LoaderGif />
    );
}
