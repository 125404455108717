import React from 'react';
import DnDContainer from '../DragAndDrop/Container';
import Loader from '../Loader';
import { IListAudiosProps } from './index.interface';
import EmptyFilesList from '../EmptyFilesList';

export default function ListAudios({ type, audios, status }: IListAudiosProps) {
    return (
        <>
            {!audios?.length && !status ? (
                <EmptyFilesList />
            ) : (
                <div className="upload_popup_tabs_content_middle audio" data-testid="file-list">
                    <DnDContainer type={type} data={audios} />
                    <Loader status={status} />
                </div>
            )}
        </>
    );
}
