import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProfileAnotherUser from '../../components/Profile/ProfileAnotherUser';
import * as menuAction from '../../store/actions/menu';

export default function ProfileOtherUserPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuAction.setMainMenu('profile'));
    }, []);

    return (
        <>
            <ProfileAnotherUser />
        </>
    );
}
