import React from 'react';
import { useSelector } from 'react-redux';
import { firstCharacterToUppercase } from '../../../../services/methods';
import IconMailbox from '../../../Icons/IconMailbox';
import IconDrafts from '../../../Icons/IconDrafts';
import IconTrash from '../../../Icons/IconTrash';
import IconBookmark from '../../../Icons/IconBookmark';
import { RootState } from '../../../../index';
import { IOpenFormLetterButtonProps } from './index.interface';

export default function OpenFormLetterButton({
    onPressButton,
    titleButton = '',
    SVGButton = null,
    style = null,
}: IOpenFormLetterButtonProps) {
    const menuState = useSelector((state: RootState) => state.menu);

    function getMenuIcon(value: string) {
        if (!value) return '';
        switch (value) {
            case 'inbox':
            case 'outbox':
                return <IconMailbox />;
            case 'drafts':
                return <IconDrafts />;
            case 'trash':
                return <IconTrash />;
            case 'bookmarks':
                return <IconBookmark />;
        }
    }

    return (
        <div className="c2l_compose_wrap">
            <div className={`c3l_mob_folder ${menuState.letters ?? ''}`}>
                {getMenuIcon(menuState.letters)}
                <div className="c3l_mob_folder_text">
                    {firstCharacterToUppercase(
                        menuState.letters === 'inbox' ? 'mailbox' : menuState.letters,
                    )}
                </div>
            </div>
            <div
                className="c2l_compose_btn"
                style={style ?? {}}
                onClick={onPressButton}
                data-testid="new-letter-btn"
            >
                {SVGButton && <SVGButton />}
                {titleButton}
            </div>
        </div>
    );
}
