export enum MailboxActionTypes {
    REMOVE_MAILBOX_CHAT = 'REMOVE_MAILBOX_CHAT',
    MAILBOX_GET = 'mailbox_get',
    MAILBOX_GET_DRAFTS = 'mailbox_get_drafts',
    UPDATE_MAIL_LIST_DRAFTS = 'UPDATE_MAIL_LIST_DRAFTS',
    DELETE_DRAFT_MAIL_LIST = 'DELETE_DRAFT_MAIL_LIST',
    MAILBOX_CHAT_ADD_INFO = 'mailbox_chat_add_info',
    MAILBOX_CHAT_SET_INFO_OPEN_FROM_CHAT = 'MAILBOX_CHAT_SET_INFO_OPEN_FROM_CHAT',
    MAILBOX_CHAT_CLEAR_INFO = 'mailbox_chat_clear_info',
    MAILBOX_CHAT_GET_HISTORY = 'mailbox_chat_get_history',
    MAILBOX_CHAT_GET_HISTORY_FORCE = 'mailbox_chat_get_history_force',
    MAILBOX_CHAT_GET_HISTORY_SEND_MESS = 'mailbox_chat_get_history_send_mess',
    MAILBOX_CHAT_ADD_SPECIAL_LETTER = 'mailbox_chat_add_special_letter',
    MAILBOX_CHAT_ADD_BOOKMARK_LETTER = 'mailbox_chat_add_bookmark_letter',
    MAILBOX_ADD_DRAFT_ID = 'mailbox_add_draft_id',
    MAILBOX_REMOVE_DRAFT_ID = 'MAILBOX_REMOVE_DRAFT_ID',
    MAILBOX_SET_FAVORITE = 'MAILBOX_SET_FAVORITE',
    MAILBOX_SET_LIKE = 'mailbox_set_like',
    SET_IS_NEW_MESS = 'SET_IS_NEW_MESS',
    MAILBOX_SET_READ_LETTER = 'MAILBOX_SET_READ_LETTER',
    MAILBOX_SET_PAYED_READ_LETTER = 'MAILBOX_SET_PAYED_READ_LETTER',
    FETCH_MAILBOX_GET = 'FETCH_MAILBOX_GET',
    FETCH_MAILBOX_GET_SEND_MESS = 'FETCH_MAILBOX_GET_SEND_MESS',
    MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP = 'MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP',
    MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN = 'MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN',
    SET_INFO_CURRENT_TARGET = 'SET_INFO_CURRENT_TARGET',

    PAID_LETTER_CONTENT = 'PAID_LETTER_CONTENT',

    CURRENT_LETTER_ROOM_PAID_LETTER_CONTENT = 'CURRENT_LETTER_ROOM_PAID_LETTER_CONTENT',

    MAILBOX_GET_FEMALE_LIST = 'mailbox_get_female_list',

    ADD_CHECKED_RECIPIENTS = 'add_checked_recipients',
    REMOVE_CHECKED_RECIPIENTS = 'remove_checked_recipients',
    REMOVE_ALL_CHECKED_RECIPIENTS = 'remove_all_checked_recipients',
    SET_CURRENT_CHAT_MAILBOX_ONLINE_STATUS = 'SET_CURRENT_CHAT_MAILBOX_ONLINE_STATUS',
    SET_CURRENT_CHAT_MAILBOX_LIKE_STATUS = 'SET_CURRENT_CHAT_MAILBOX_LIKE_STATUS',
    SET_MAILBOX_LIST_ONLINE_STATUS = 'SET_MAILBOX_LIST_ONLINE_STATUS',
    SET_DRAFT_MAILBOX_LIST_ONLINE_STATUS = 'SET_DRAFT_MAILBOX_LIST_ONLINE_STATUS',
    MAILBOX_LIST_SET_FAVORITE = 'MAILBOX_LIST_SET_FAVORITE',
    INIT_MAILBOX = 'INIT_MAILBOX',
    MAILBOX_SET_CURRENT_PAGE = 'MAILBOX_SET_CURRENT_PAGE',
}
