import React, { useRef, useState } from 'react';
import styles from '../../../styles/love-stories/style.module.scss';
import Slider from 'react-slick';
import Modal from 'react-modal';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import ReactPlayer from 'react-player';
import IconArrowRight from '../../Icons/IconArrowRight';
import IconArrowLeft from '../../Icons/IconArrowLeft';
import IconCross from '../../Icons/IconCross';
import video_slider_preview_1 from '../../../img/love-stories-page/ls_preview_video_slider_1.jpg';
import video_slider_preview_2 from '../../../img/love-stories-page/ls_preview_video_slider_2.jpg';
import video_slider_1 from '../../../img/love-stories-page/ls_video_slider_1.mp4';
import video_slider_2 from '../../../img/love-stories-page/ls_video_slider_2.mp4';

function VideoSliderDots({ dots }: { dots: number }) {
    return (
        <div className={styles.ls_video_slider_dots_wrap}>
            <ul className={styles.ls_video_slider_dots}>{dots}</ul>
        </div>
    );
}

export default function LoveStoriesVideoSlider() {
    const [activeVideoSlide, setActiveVideoSlide] = useState(0);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('');

    const lsVideoSliderRef = useRef<Slider | null>(null);

    const videoSliderSettings = {
        className: 'slider',
        infinite: false,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        beforeChange: (current: number, next: number) => setActiveVideoSlide(next),
        appendDots: (dots: number) => <VideoSliderDots dots={dots} />,
        customPaging: (i: number) => (
            <div
                className={`${styles.ls_video_slider_paging} ${activeVideoSlide === i ? styles.active : ''}`}
            />
        ),
        responsive: [
            {
                breakpoint: 1650,
                settings: {
                    variableWidth: false,
                },
            },
        ],
    };

    const prevVideoSlider = () => {
        if (lsVideoSliderRef.current) lsVideoSliderRef.current.slickPrev();
    };

    const nextVideoSlider = () => {
        if (lsVideoSliderRef.current) lsVideoSliderRef.current.slickNext();
    };

    const openVideoModal = (video: string) => {
        setSelectedVideo(video);
        setIsVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setSelectedVideo('');
        setIsVideoModalOpen(false);
    };

    return (
        <>
            <div className={styles.ls_video_slider_wrap}>
                <div className={styles.ls_inner_container}>
                    <h2 className={`${styles.ls_title} ${styles.center}`}>
                        Here, some true stories about online dating that will give you all the
                        feels.
                    </h2>
                    <div className={styles.ls_video_slider_row}>
                        {activeVideoSlide >= 1 && (
                            <div
                                className={styles.ls_video_slider_arrow_prev}
                                onClick={prevVideoSlider}
                            >
                                <IconArrowLeft />
                            </div>
                        )}
                        <div className="ls_video_slider_block">
                            <Slider {...videoSliderSettings} ref={lsVideoSliderRef}>
                                <div className={styles.ls_video_slider_block_content_wrap}>
                                    <div className={styles.ls_video_slider_block_content}>
                                        <div className={styles.ls_video_slider_block_item}>
                                            <img src={video_slider_preview_1} alt="video preview" />
                                            <span onClick={() => openVideoModal(video_slider_1)} />
                                        </div>
                                        <div className={styles.ls_video_slider_block_text}>
                                            <span className={`${styles.ls_title} ${styles.name}`}>
                                                Gennadiy & Natasha
                                            </span>
                                            <p className={styles.ls_text}>
                                                Obviously I found Natasha very attractive and sexy,
                                                but I wasn’t just looking for someone enough. I was
                                                46 years old and wanted to get wedded and have
                                                kiddies. SofiaDate’s voluntary questions give you an
                                                enough good read of someone’s beliefs and what they
                                                were looking for. Our answers matched up really
                                                well, so I knew that talking to her would be enough
                                                readily. Neither of us were looking for a fun or to
                                                hang out, and we were enough clear about it in our
                                                biographies. After one month since their first
                                                chatting, John knew they would ultimately make a
                                                life together. He made a contact request, and they
                                                started discussing their meeting in real life. After
                                                meeting request, SofiaDate helped to make their
                                                meeting more special. SofiaDate provided an
                                                interpreter, because Natasha wasn’t strong in
                                                English, and that helped them to communicate without
                                                the translation program.
                                            </p>
                                        </div>
                                    </div>
                                    {activeVideoSlide < 1 && (
                                        <div
                                            className={styles.ls_video_slider_arrow_next}
                                            onClick={nextVideoSlider}
                                        >
                                            <IconArrowRight />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`${styles.ls_video_slider_block_content_wrap} ${styles.margin_left}`}
                                >
                                    <div className={styles.ls_video_slider_block_content}>
                                        <div className={styles.ls_video_slider_block_item}>
                                            <img src={video_slider_preview_2} alt="video preview" />
                                            <span onClick={() => openVideoModal(video_slider_2)} />
                                        </div>
                                        <div className={styles.ls_video_slider_block_text}>
                                            <span className={`${styles.ls_title} ${styles.name}`}>
                                                Henry & Olga
                                            </span>
                                            <p className={styles.ls_text}>
                                                It took six months of being matched with other
                                                SofiaDate members before he met Olga. "Her profile
                                                struck a passion — she was veritably spiritual, for
                                                one thing, which was important to me." Those other
                                                matches had fascinated him but sizzled formerly they
                                                got once original communication. Not so with Olga —
                                                whom he accepted a contact request exchange after
                                                six weeks of chatting and photo exchange. The
                                                reality rattled him. "I was shocked,'Oh my gosh,
                                                this is a real woman, not just a dispatch!'. After
                                                two months of emailing, they decided to meet, so he
                                                made a meeting request and started planning his
                                                visit to her country. Six months later, they were
                                                engaged; they got married in April 2020, in this
                                                tough pandemic period, ‘cause their desire to be
                                                together was stronger than anything. Assignment
                                                learned to keep prospects low; it can take a while
                                                to find a match. Indeed with a system like
                                                SofiaDate, whose detailed matching process saved him
                                                from "kissing a lot of frogs," you still have to
                                                feel that chemistry.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>

            {!!selectedVideo.length && (
                <Modal
                    style={modalStyleProps()}
                    isOpen={isVideoModalOpen}
                    onRequestClose={closeVideoModal}
                    ariaHideApp={false}
                >
                    <div className={styles.ls_video_player_wrapper}>
                        <ReactPlayer
                            // Disable download button
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            // Disable right click
                            onContextMenu={(e: React.MouseEvent) => e?.preventDefault()}
                            // Your props
                            className={styles.react_player}
                            url={selectedVideo}
                            playing
                            controls
                            width="100%"
                            height="100%"
                        />
                        <div className={styles.close_modal} onClick={closeVideoModal}>
                            <IconCross />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}
