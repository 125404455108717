import LikesApi from '../../../services/api/Likes';
import { LikesActionTypes } from '../../constants/likes';
import SearchApi from '../../../services/api/v3/SearchApi';
import { Dispatch } from 'redux';
import { IAddLikeHistoryAction, IAddLikeHistoryParams, IFilter } from './index.interface';
import { FilterType } from '../../../constants/filter';
import { IFilterChangeAction } from '../../reducers/likes/index.interface';

export function removeFromLikeList(externalID: number) {
    return function (dispatch: Dispatch<{ type: string; externalID: number }>) {
        return dispatch({
            type: LikesActionTypes.REMOVE_FROM_LIKE_LIST,
            externalID,
        });
    };
}

export function changeFilterType(filter: 'ONLINE' | 'LIKED_YOU' | 'WIDE') {
    return function (dispatch: Dispatch<IFilterChangeAction>) {
        return dispatch({
            type: LikesActionTypes.FILTER_TYPE_CHANGE,
            data: filter,
        });
    };
}

export function setSelectedPerson(index: number) {
    return function (dispatch: Dispatch<{ type: string; data: number }>) {
        return dispatch({
            type: LikesActionTypes.SET_SELECTED_INDEX,
            data: index,
        });
    };
}

export function setLike(recipientId: number) {
    return function () {
        return new LikesApi().setLike({ recipient_id: recipientId }).then((res) => {
            return res;
        });
    };
}

export function getLikeHistory(page = 1, isReverse = false) {
    return function (dispatch: Dispatch<{ type: string; likeHistory: any }>) {
        return new SearchApi().getLikesHistory(page, isReverse).then((res) => {
            if (res?.status && res?.response?.data?.length) {
                return dispatch({
                    type: LikesActionTypes.GET_LIKE_HISTORY_LIST,
                    likeHistory: res?.response?.data?.length ? res?.response?.data : [],
                    likeHistoryPage: page,
                });
            }
        });
    };
}

export function setLikeHistoryPage(page = 1) {
    return function (dispatch: Dispatch<{ type: string; page: number }>) {
        return dispatch({
            type: LikesActionTypes.SET_LIKE_HISTORY_PAGE,
            page,
        });
    };
}

export function initSearchState() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: LikesActionTypes.INIT_LIKE_HISTORY_PAGE,
        });
    };
}

export function addLikeHistory(newLikeItem: IAddLikeHistoryParams) {
    return function (dispatch: Dispatch<{ type: string; likeHistory: IAddLikeHistoryAction }>) {
        return dispatch({
            type: LikesActionTypes.ADD_LIKE_HISTORY_LIST,
            likeHistory: { ...newLikeItem, created_at: new Date() },
        });
    };
}

export function setFilter(filter: FilterType) {
    return function (dispatch: Dispatch<{ type: string; filter: FilterType }>) {
        return dispatch({
            type: LikesActionTypes.SET_FILTER,
            filter,
        });
    };
}

export function initLikesState(filter: FilterType) {
    return function (dispatch: Dispatch<{ type: string; filter: FilterType }>) {
        return dispatch({
            type: LikesActionTypes.INIT_LIKES,
            filter,
        });
    };
}

export function getLikeList(page = 1, filter: FilterType) {
    return function (dispatch: Dispatch<{ type: string; likeList: number[] }>) {
        const cleanedFilters = Object.keys(filter).reduce((acc: IFilter, key: keyof IFilter) => {
            // @ts-expect-error filter[key]
            if (filter[key]) {
                // @ts-expect-error filter[key]
                acc[key] = filter[key];
            }
            return acc;
        }, {} as IFilter);

        return new SearchApi().getLikeListMulti({ ...cleanedFilters, page }).then((res) => {
            if (res?.status) {
                dispatch({
                    type: LikesActionTypes.GET_LIKE_LIST,
                    likeList: res?.response?.map((item: any) => item?.external_id),
                });

                return res?.response;
            }

            return [];
        });
    };
}

export function setLikeListPage(page = 1) {
    return function (dispatch: Dispatch<{ type: string; page: number }>) {
        return dispatch({
            type: LikesActionTypes.SET_LIKE_LIST_PAGE,
            page,
        });
    };
}

export function setLikeHistoryOnlineStatus(externalIDList: number[], status: number) {
    return function (
        dispatch: Dispatch<{ type: string; externalIDList: number[]; status: number }>,
    ) {
        return dispatch({
            type: LikesActionTypes.SET_LIKE_HISTORY_ONLINE_STATUS,
            externalIDList,
            status,
        });
    };
}
