import { useRef, useState } from 'react';
import useOutsideClicker from '../../dom/useOutsideClicker';

export default function useHandleSmileBlock(
    mobileCallback: (value: boolean) => void,
    setIsOpenMobStickerBlock: (value: boolean) => void,
) {
    const smileBoxRef = useRef(null);
    const [isSmileBlock, setIsSmileBlock] = useState(false);

    const openSmileBlock = () => {
        if (window.innerWidth < 1000) {
            if (mobileCallback) mobileCallback(true);
            if (setIsOpenMobStickerBlock) setIsOpenMobStickerBlock(false);
        } else {
            setIsSmileBlock(true);
        }
    };

    const closeSmileBlock = () => {
        setIsSmileBlock(false);
    };

    useOutsideClicker(smileBoxRef, setIsSmileBlock);

    return {
        isSmileBlock,
        openSmileBlock,
        closeSmileBlock,
        smileBoxRef,
    };
}
