import React from 'react';
import { useSelector } from 'react-redux';
import { convertPrice } from '../../../../../../services/methods';
import * as PRICES from '../../../../../../constants/prices';
import SITE from '../../../../../../config/site';
import { SUPPORT_WOMAN } from '../../../../../../constants/user';
import { ISendButtonProps } from './index.interface';
import { RootState } from '../../../../../../index';
import IconSendMessage from '../../../../../Icons/IconSendMessage';

export default function SendButton({
    onSubmitMessage,
    buttonRef,
    isLoadingRequest,
}: ISendButtonProps) {
    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const isSupportWoman = currentChatState?.info?.id === SUPPORT_WOMAN;

    return (
        <div className="c3_send" ref={buttonRef}>
            <div
                className={`c3_send_btn ${isLoadingRequest ? 'blocked' : ''}`}
                onClick={onSubmitMessage}
                data-testid="send-btn"
            >
                <IconSendMessage />
                <span>Send</span>
                {SITE.ID !== 4 && !isSupportWoman && (
                    <p className="c3_chat_send_btn_cost_text" data-testid="message-cost">
                        <span>Unlimited text messages per</span>{' '}
                        {convertPrice(+(userState.prices?.[PRICES.SENT_TEXT] || 0))} credits each
                        minute
                    </p>
                )}
            </div>
        </div>
    );
}
