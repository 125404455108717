import React from 'react';

export default function IconVerificationComplete() {
    return (
        <svg
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="1"
                width="72"
                height="72"
                rx="36"
                stroke="#D4D4D4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6"
            />
            <path
                d="M37 1C42.6812 1 48.2817 2.34457 53.3437 4.92376C58.4056 7.50296 62.7853 11.2436 66.1246 15.8397C69.4639 20.4359 71.668 25.7571 72.5568 31.3684C73.4455 36.9796 72.9936 42.7215 71.238 48.1246C69.4825 53.5277 66.473 58.4386 62.4558 62.4558C58.4387 66.473 53.5277 69.4825 48.1246 71.238C42.7215 72.9936 36.9796 73.4455 31.3684 72.5568C25.7571 71.6681 20.4359 69.4639 15.8397 66.1246"
                stroke="#00317B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g clipPath="url(#clip0_230_22551)">
                <path
                    d="M25.8533 36.5647H25C25.1081 33.532 26.3612 30.6529 28.5071 28.507C30.6529 26.3612 33.532 25.1081 36.5648 25V25.8533C33.759 25.9629 31.0977 27.1267 29.1122 29.1122C27.1267 31.0977 25.963 33.7589 25.8533 36.5647Z"
                    fill="#00317B"
                />
                <path
                    d="M28.5419 37.4224H29.3995C29.5114 39.3615 30.36 41.1847 31.7716 42.5189C33.1832 43.8532 35.0512 44.5978 36.9936 44.6005C46.8236 44.2375 47.1867 30.2995 37.4223 29.3995C37.4234 28.7926 37.4216 26.4939 37.4224 25.8533V25C40.5289 25.113 43.4706 26.4257 45.6296 28.6622C47.7885 30.8988 48.9966 33.885 49 36.9936C48.3914 52.6619 26.1454 53.0286 25 37.4223C25.5335 37.4207 27.9546 37.4235 28.5419 37.4224Z"
                    fill="#00317B"
                />
                <path
                    d="M29.3995 36.5647H28.5419C28.6481 34.4713 29.5275 32.4918 31.0096 31.0096C32.4918 29.5275 34.4713 28.6481 36.5648 28.5419V29.3995C34.6992 29.5064 32.9382 30.2956 31.6169 31.6169C30.2957 32.9382 29.5064 34.6992 29.3995 36.5647Z"
                    fill="#00317B"
                />
            </g>
            <defs>
                <clipPath id="clip0_230_22551">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 49 25)" />
                </clipPath>
            </defs>
        </svg>
    );
}
