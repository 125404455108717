import React, { useRef, useState } from 'react';
import SelectAge from './SelectAge';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import * as SEX from '../../../constants/sex';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import { SEX_TEXT } from '../../../constants/sex';

interface ISearchFilterWomanAge {
    ageFromFilter: number | string;
    setAgeFromFilter: (value: number) => void;
    ageToFilter: number | string;
    setAgeToFilter: (value: number) => void;
}

export default function SearchFilterWomanAge({
    ageFromFilter,
    setAgeFromFilter,
    ageToFilter,
    setAgeToFilter,
}: ISearchFilterWomanAge) {
    const selectRef = useRef<HTMLDivElement | null>(null);
    const [isOpenFromBlock, setIsOpenFromBlock] = useState(false);
    const [isOpenToBlock, setIsOpenToBlock] = useState(false);
    const userState = useSelector((state: RootState) => state.user);

    useOutsideClicker(selectRef, () => {
        setIsOpenFromBlock(false);
        setIsOpenToBlock(false);
    });

    return (
        <div className="c3lh_search_range_wrap">
            <span className="c3lh_search_range_title">
                <span>
                    {userState?.info &&
                        SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX_TEXT]
                            ?.SEARCH_TITLE_FILTER_RANGE}
                </span>{' '}
                age range
            </span>
            <div className="c3lh_search_range_selects_wrap" ref={selectRef}>
                <SelectAge
                    opened={isOpenFromBlock}
                    handleOpen={() => {
                        setIsOpenToBlock(false);
                        isOpenFromBlock ? setIsOpenFromBlock(false) : setIsOpenFromBlock(true);
                    }}
                    value={ageFromFilter}
                    handleChange={(item) => {
                        const ageFrom = +item;
                        const ageTo = +ageToFilter;
                        if ((ageFrom > ageTo || (isNaN(ageFrom) && !isNaN(ageTo))) && +ageTo !== 0)
                            return false;

                        setIsOpenFromBlock(false);
                        setAgeFromFilter(+item);
                    }}
                    defaultValue={'From'}
                    dataTestId="age-from"
                />

                <SelectAge
                    opened={isOpenToBlock}
                    handleOpen={() => {
                        setIsOpenFromBlock(false);
                        isOpenToBlock ? setIsOpenToBlock(false) : setIsOpenToBlock(true);
                    }}
                    value={ageToFilter}
                    handleChange={(item) => {
                        const ageFrom = +ageFromFilter;
                        const ageTo = +item;
                        if ((ageFrom > ageTo || (isNaN(ageFrom) && !isNaN(ageTo))) && ageTo !== 0)
                            return false;

                        setIsOpenToBlock(false);
                        setAgeToFilter(+item);
                    }}
                    defaultValue={'To'}
                    dataTestId="age-to"
                />
            </div>
        </div>
    );
}
