import { BannerActionTypes } from '../../constants/banners';
import { BannerActions, IBannerState } from './index.interface';

const initState = {
    crossBannersData: null,
    isActiveCrossBanners: false,
    crossBannersStartTime: '', //utc 'YYYY-MM-DD HH:mm:ss',
    isConfirmTermModal: false,
    customBannerData: null,
};

export default function (state: IBannerState = initState, action: BannerActions): IBannerState {
    switch (action.type) {
        case BannerActionTypes.INIT_CROSS_BANNERS:
            return { ...state, ...{ crossBannersData: action?.data } };

        case BannerActionTypes.SET_ACTIVE_CROSS_BANNERS:
            return {
                ...state,
                ...{
                    isActiveCrossBanners: action?.status,
                    crossBannersStartTime: action?.startTime,
                },
            };

        case BannerActionTypes.CLEAR_CROSS_BANNERS:
            return {
                ...state,
                ...{
                    crossBannersData: null,
                    isActiveCrossBanners: false,
                    crossBannersStartTime: '',
                },
            };

        case BannerActionTypes.OPEN_CONFIRM_TERMS_MODAL:
            return {
                ...state,
                ...{ isConfirmTermModal: true, customBannerData: action?.customBannerData },
            };

        case BannerActionTypes.CLOSE_CONFIRM_TERMS_MODAL:
            return { ...state, ...{ isConfirmTermModal: false, customBannerData: null } };

        default:
            return state;
    }
}
