import { useHistory, useLocation } from 'react-router-dom';
import { getUrlParameter } from '../../utils/url-utils';
import { sendErrToSentry } from '../../services/sentry';

export default function useAddTail() {
    const currentLocation = useLocation();
    const history = useHistory();

    return (key: string, value: string) => {
        try {
            const param = key + '=' + value;
            let searchStr = window?.location?.search;

            if (searchStr?.includes(key))
                searchStr = searchStr.replace(`${key}=${getUrlParameter(key)}`, `${key}=${value}`);
            else searchStr = searchStr?.length ? `${searchStr}&${param}` : `?${param}`;

            history.push(`${currentLocation?.pathname}${searchStr ?? ''}`);
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };
}
