import { VirtualGiftsActionTypes } from '../../constants/virtualGifts';
import VirtualGiftsApi from '../../../services/api/VirtualGifts';
import { Dispatch } from 'redux';
import { IGetVirtualGiftsDataAction } from '../../reducers/virtualGifts/index.interface';

export function getVirtualGiftsData() {
    return function (dispatch: Dispatch<IGetVirtualGiftsDataAction>) {
        new VirtualGiftsApi().getVirtualGiftsData().then((res) => {
            if (res?.status && (!!res?.result?.chat?.length || !!res?.result?.stream?.length)) {
                return dispatch({
                    type: VirtualGiftsActionTypes.GET_VIRTUAL_GIFTS_DATA,
                    chat: res?.result?.chat,
                    stream: res?.result?.stream,
                });
            }
        });
    };
}
