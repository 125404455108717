import { MailboxActionTypes } from '../../constants/mailbox';
import MailboxApi from '../../../services/api/MailboxApi';
import { sendErrToSentry } from '../../../services/sentry';
import { Dispatch } from 'redux';
import {
    IGetDraftsData,
    IGetFemalesData,
    IGetMailsData,
} from '../../../services/api/MailboxApi.interface';
import { CancelToken } from 'axios';
import {
    IMailboxGetDraftsAction,
    IMailboxListSetCurrentPageAction,
} from '../../reducers/mailbox/index.interface';

export function paidLetterContent(letterID: number, contentID: number, content: string) {
    return function (dispatch: Dispatch<{ type: string; contentID: number; content: string }>) {
        dispatch({
            type: MailboxActionTypes.PAID_LETTER_CONTENT,
            letterID,
            contentID,
            content,
        });
    };
}

export function getMails(data: IGetMailsData, callback?: () => void) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: MailboxActionTypes.FETCH_MAILBOX_GET,
        });
        try {
            new MailboxApi()
                .postMails(data)
                .then((res) => {
                    if (res?.status) {
                        return dispatch({
                            type: MailboxActionTypes.MAILBOX_GET,
                            data: res,
                        });
                    }
                })
                .finally(() => callback && callback());
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };
}

export function getMailsWithoutReload(data: IGetMailsData) {
    return function (dispatch: Dispatch) {
        new MailboxApi().postMails(data).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: MailboxActionTypes.MAILBOX_GET,
                    data: res,
                });
            }
        });
    };
}

export function getMailDrafts(data: IGetDraftsData, callback?: () => void) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: MailboxActionTypes.FETCH_MAILBOX_GET,
        });
        try {
            new MailboxApi()
                .postDrafts(data)
                .then((res) => {
                    if (res?.status) {
                        return dispatch({
                            type: MailboxActionTypes.MAILBOX_GET_DRAFTS,
                            data: res,
                            page: data?.page,
                        });
                    }
                })
                .finally(() => callback && callback());
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };
}

export function getMailDraftsWithoutReload(data: IGetDraftsData) {
    return function (dispatch: Dispatch<IMailboxGetDraftsAction>) {
        new MailboxApi().postDrafts(data).then((res) => {
            if (res?.status) {
                dispatch({
                    type: MailboxActionTypes.MAILBOX_GET_DRAFTS,
                    data: res,
                    page: data?.page,
                });
            }
        });
    };
}

export function updateMailListDrafts(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: MailboxActionTypes.UPDATE_MAIL_LIST_DRAFTS,
            data,
        });
    };
}

export function setFavorite(chatId: string, isFavorite: boolean) {
    return function (dispatch: Dispatch<{ type: string; chatId: string; isFavorite: boolean }>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_LIST_SET_FAVORITE,
            chatId,
            isFavorite,
        });
    };
}

export function deleteDraftMailList(draftIdArr: number[] = []) {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.DELETE_DRAFT_MAIL_LIST,
            draftIdArr,
        });
    };
}

export function addRecipient(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: MailboxActionTypes.ADD_CHECKED_RECIPIENTS,
            data: data,
        });
    };
}

export function removeRecipient(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: MailboxActionTypes.REMOVE_CHECKED_RECIPIENTS,
            data: data,
        });
    };
}

export function removeAllRecipient() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.REMOVE_ALL_CHECKED_RECIPIENTS,
        });
    };
}

export function getFemaleList(data: IGetFemalesData, token?: CancelToken) {
    return function (dispatch: Dispatch<{ type: string; data: IGetFemalesData }>) {
        new MailboxApi().postFemales(data, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: MailboxActionTypes.MAILBOX_GET_FEMALE_LIST,
                data: res,
            });
        });
    };
}

export function setMailboxListOnlineStatus(externalIDList: number[], status: number) {
    return function (
        dispatch: Dispatch<{ type: string; externalIDList: number[]; status: number }>,
    ) {
        return dispatch({
            type: MailboxActionTypes.SET_MAILBOX_LIST_ONLINE_STATUS,
            externalIDList,
            status,
        });
    };
}

export function setDraftMailboxListOnlineStatus(externalIDList: number[], status: number) {
    return function (
        dispatch: Dispatch<{ type: string; externalIDList: number[]; status: number }>,
    ) {
        return dispatch({
            type: MailboxActionTypes.SET_DRAFT_MAILBOX_LIST_ONLINE_STATUS,
            externalIDList,
            status,
        });
    };
}

export function setInitMailbox() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.INIT_MAILBOX,
        });
    };
}

export function setCurrentPageMailboxList(page: number) {
    return function (dispatch: Dispatch<IMailboxListSetCurrentPageAction>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_SET_CURRENT_PAGE,
            page,
        });
    };
}
