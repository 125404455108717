import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import AutoTopUpChange from './AutoTopUpChange';
import { useDispatch, useSelector } from 'react-redux';
import * as purchaseActions from '../../../../store/actions/purchase';
import { RootState } from '../../../../index';

export default function AutoTopUpChangeModal() {
    const purchaseState = useSelector((state: RootState) => state.purchase);
    const dispatch = useDispatch();

    const onClose = () => dispatch(purchaseActions.setChangeSubscribeModal(false));

    if (!purchaseState?.activeSubscribeListInfo?.length) return null;
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={purchaseState?.isOpenChangeSubscribeModal}
            onRequestClose={onClose}
            ariaHideApp={false}
        >
            {!!purchaseState?.isOpenChangeSubscribeModal && purchaseState?.activeSubscribeData && (
                <AutoTopUpChange
                    closeModal={onClose}
                    activeSubscribeListInfo={purchaseState?.activeSubscribeListInfo}
                    activeSubscribeData={purchaseState?.activeSubscribeData}
                    activeSubscribePaymentMethod={purchaseState?.activeSubscribePaymentMethod}
                />
            )}
        </Modal>
    );
}
