import React from 'react';
import styles from '../styles.module.scss';
import { useDispatch } from 'react-redux';
import ChatsAPI from '../../../../../services/api/ChatsApi';
import AudioPlayer from '../../../AudioPlayer';
import * as currentChatAction from '../../../../../store/actions/currentChat';
import * as alertAction from '../../../../../store/actions/alert';
import * as ALERT from '../../../../../constants/alert';
import { makeCDN } from '../../../../../services/methods';
import { stopAudio } from '../../../../../services/methods/chat';
import { IMediaListAudioProps } from './index.interface';
import IconLockPlain from '../../../../Icons/IconLockPlain';

export default function MediaListAudio({
    message_content,
    recipient_external_id,
    id,
    chat_uid,
    payed,
    onSetAllMediaList,
    checkBeforeBuyingMessageContent,
}: IMediaListAudioProps) {
    const dispatch = useDispatch();

    const readAudio = () => {
        new ChatsAPI()
            .postReadAudio({
                userId: +recipient_external_id,
                messageId: +id,
                chatUid: chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(currentChatAction.readMessageAudio(+id, res?.message_content));
                    onSetAllMediaList();

                    if (res?.message_content) viewedAudio(res?.message_content);
                } else {
                    if (res?.message)
                        dispatch(
                            alertAction.setMessage({ message: res?.message, title: res?.title }),
                        );
                    else
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.ENOUGH_CREDITS_TO_LISTEN,
                            }),
                        );
                }
            });
    };

    const viewedAudio = (link: string) => {
        stopAudio(makeCDN(link, 'AUDIO'));
        new ChatsAPI().postViewMedia(+recipient_external_id, +id, chat_uid);
    };

    return (
        <div
            className={`${styles.media_list_item} ${styles.audio}`}
            onClick={() => {
                if (!+payed)
                    checkBeforeBuyingMessageContent(ALERT.AUDIO_MESSAGE_CONTENT, () => readAudio());
                else viewedAudio(message_content);
            }}
        >
            {!+payed && (
                <div className={styles.media_list_item_unpaid_wrap}>
                    <div className={styles.media_list_item_unpaid_main}>
                        <IconLockPlain />
                        <span>Unpaid</span>
                    </div>
                </div>
            )}
            {!+payed ? (
                <AudioPlayer
                    className={`${styles.media_list_item_audio_content}`}
                    src={''}
                    onPayAudio={() =>
                        checkBeforeBuyingMessageContent(ALERT.AUDIO_MESSAGE_CONTENT, () =>
                            readAudio(),
                        )
                    }
                />
            ) : (
                <AudioPlayer
                    className={`${styles.media_list_item_audio_content}`}
                    src={makeCDN(message_content, 'AUDIO')}
                    viewedAudio={() => viewedAudio(message_content)}
                />
            )}
        </div>
    );
}
