import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import { RootState } from '../../index';
import { match } from 'react-router';
import { Params } from '@sentry/react/types/types';
import { ChatModel } from '../../models/chat';

export default function useEffectToOpenLettersChat(match: match<Params>) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (match?.params?.letter_uid) {
            ChatModel.getStoreChatItem(match?.params?.letter_uid).then((res) => {
                if (res) {
                    dispatch(
                        currentChatMailboxAction.addMailboxChatInfo({
                            ...res,
                            sender_image: res?.photo_link,
                            sender_name: res?.name,
                            sender_age: res?.age,
                            sender_city: res?.city,
                            sender_id: res?.id,
                            sender_gifts: res?.gift,
                            sender_search_img: res?.search_img,
                            female_id: res?.id,
                            male_id: userState?.info?.external_id,
                            openFromChat: true,
                        }),
                    );
                    if (userState?.info?.external_id) {
                        dispatch(
                            currentChatMailboxAction.getMailboxChatHistory({
                                userId: userState?.info?.external_id,
                                girlId: res?.public_external_id?.toString(),
                                page: 1,
                            }),
                        );
                    }
                }
            });
        }
    }, [match.params]);
}
