import React from 'react';

export default function IconUpdate() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="#333333"
        >
            <path d="M0.900391 8.26875C0.900391 11.2195 3.30462 13.5874 6.29466 13.5874C9.28469 13.5874 11.6828 11.2195 11.6828 8.26875C11.6828 7.98029 11.4753 7.76995 11.1763 7.76995C10.8895 7.76995 10.7004 7.98029 10.7004 8.26875C10.7004 10.6847 8.7416 12.6198 6.29466 12.6198C3.84771 12.6198 1.88283 10.6847 1.88283 8.26875C1.88283 5.85886 3.84771 3.92974 6.29466 3.92974C6.75842 3.92974 7.19167 3.9658 7.55169 4.04994L5.72716 5.82881C5.63563 5.92497 5.58681 6.04516 5.58681 6.17136C5.58681 6.44781 5.79428 6.65214 6.06888 6.65214C6.22143 6.65214 6.33737 6.60406 6.4228 6.51392L8.93687 4.0259C9.04671 3.92373 9.08942 3.80354 9.08942 3.66531C9.08942 3.5331 9.0345 3.40089 8.93687 3.30473L6.4228 0.792671C6.33737 0.696515 6.21533 0.648438 6.06888 0.648438C5.79428 0.648438 5.58681 0.864787 5.58681 1.14123C5.58681 1.26744 5.63563 1.38763 5.72106 1.48379L7.34422 3.06434C7.02691 3.00425 6.66688 2.96218 6.29466 2.96218C3.30462 2.96218 0.900391 5.324 0.900391 8.26875Z" />
        </svg>
    );
}
