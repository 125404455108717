import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import AutoTopUpSuccessChange from './AutoTopUpSuccessChange';
import { ISuccessChangeSubscribeModalProps } from './index.interface';

export default function AutoTopUpSuccessChangeModal({
    isOpenSuccessChangeSubscribeModal,
    closeSuccessChangeSubscribeModal,
    activeSubscribeData,
    prevSubscriptionCreditPack,
}: ISuccessChangeSubscribeModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isOpenSuccessChangeSubscribeModal}
            onRequestClose={closeSuccessChangeSubscribeModal}
            ariaHideApp={false}
        >
            <AutoTopUpSuccessChange
                closeModal={closeSuccessChangeSubscribeModal}
                activeSubscribeData={activeSubscribeData}
                prevSubscriptionCreditPack={prevSubscriptionCreditPack}
            />
        </Modal>
    );
}
