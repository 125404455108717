import React from 'react';

export default function IconArrowDropdown() {
    return (
        <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="#677F92"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_70)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.0732 0.927697C1.26847 0.732434 1.58505 0.732434 1.78031 0.927697L7.42676 6.57414L13.0732 0.927695C13.2685 0.732434 13.5851 0.732435 13.7804 0.927698C13.9756 1.12296 13.9756 1.43954 13.7803 1.6348L7.78031 7.6348C7.58505 7.83007 7.26847 7.83007 7.0732 7.6348L1.0732 1.6348C0.877942 1.43954 0.877942 1.12296 1.0732 0.927697Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_70">
                    <rect width="14" height="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
