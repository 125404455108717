import React from 'react';
import styles from './styles.module.scss';
import Banners from '../../../img/cross-banners';
import { checkNationalitySite } from '../../../services/methods';
import { ICrossBannersData } from '../../../store/reducers/banners/index.interface';
import MarketingApi from '../../../services/api/MarketingApi';
import * as bannersAction from '../../../store/actions/banners';
import { useDispatch } from 'react-redux';

export default function SearchCrossBannerNewDesign(props: ICrossBannersData) {
    const { target_site_id, log_id } = props;
    const nationality = checkNationalitySite(target_site_id);
    const imageSrc = Banners.images[`banner-search-${nationality}.png`];
    const dispatch = useDispatch();
    const openConfirmTermModal = () => {
        if (log_id) {
            new MarketingApi().postClickCrossBanner(log_id);
        }
        dispatch(bannersAction.setOpenConfirmTermsModal(props));
    };
    return (
        <li
            className={styles.search_cross_banner_item_wrap}
            id="search-cross-banner"
            data-testid="result-cross-banner-item"
            onClick={openConfirmTermModal}
        >
            <div className={styles.search_cross_banner_item_photo_wrap}>
                <img
                    className={styles.search_cross_banner_item_photo}
                    src={imageSrc}
                    alt={`${nationality} girls`}
                />
            </div>
            <div className={styles.search_cross_banner_item_bottom}>
                <h2 className={styles.online_now_cross_banner_bottom_title}>
                    Try <span>{nationality}</span> chatting
                </h2>
                <p className={styles.online_now_cross_banner_bottom_text}>
                    Meet people, share moments, and enjoy conversations anywhere
                </p>
                <div className={styles.search_cross_banner_item_chat_btn}>
                    <span>Try {nationality} Chatting</span>
                </div>
            </div>
        </li>
    );
}
