import { useEffect } from 'react';
import * as MENU from '../../constants/menu';
import { useSelector } from 'react-redux';
import { getUrlParameter } from '../../utils/url-utils';
import useTransition from './useTransition';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';

export default function useDomainRedirect() {
    const transition = useTransition();
    const userState = useSelector((state: RootState) => state.user);

    const saveToken = () => {
        try {
            if (window?.location?.search?.includes('?')) {
                window.location.href =
                    window?.location?.href?.replace(window?.location?.origin, MENU.NEW_DOMAIN) +
                    '&callback=' +
                    btoa(JSON.parse(localStorage.getItem('token') as string));
                return;
            }
            window.location.href =
                window?.location?.href?.replace(window?.location?.origin, MENU.NEW_DOMAIN) +
                '?callback=' +
                btoa(JSON.parse(localStorage.getItem('token') as string));
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const getToken = () => {
        try {
            const callback = atob(getUrlParameter('callback') as string);

            if (callback) {
                localStorage.setItem('token', JSON.stringify(callback));
                localStorage.setItem('redirectDisabled', JSON.stringify(1));
                transition.addTail(
                    window?.location?.search?.replace(
                        `callback=${getUrlParameter('callback')}`,
                        '',
                    ),
                );
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    useEffect(() => {
        if (
            userState?.info?.external_id &&
            !window?.location?.href?.includes(MENU.NEW_DOMAIN) &&
            !window?.location?.href?.includes('localhost') &&
            // !window?.location?.href?.includes('bs-local.com') &&
            !window?.location?.href?.includes('stage')
        ) {
            saveToken();
        }
    }, [userState.info]);

    useEffect(() => {
        if (window?.location?.search?.includes('callback')) getToken();
    }, []);
}
