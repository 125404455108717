import * as loaderAction from '../../../store/actions/loader';
import * as alertAction from '../../../store/actions/alert';
import { checkFileAndShowAlert } from '../../../services/methods';
import * as ATTACH from '../../../constants/attach';
import awsUploadFile from '../../../services/aws/uploadFile';
import * as AWS from '../../../constants/aws';
import { useDispatch, useSelector } from 'react-redux';
import { sendErrToSentry } from '../../../services/sentry';
import { RootState } from '../../../index';
import { IGetMainInfo, IManProfileContentItem } from '../../../models/profile/ManProfile.interface';
import React, { ChangeEvent } from 'react';
import { IUseHandleProfilePhotos } from './index.interface';

export default function useHandleProfilePhotos({
    saveEdits,
    mainInfo,
    setMainInfo,
    updateUserAvatarLocal,
}: IUseHandleProfilePhotos) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const onDeleteUserPhoto = async (item: IManProfileContentItem) => {
        dispatch(loaderAction.setActiveGifLoader(true));

        const newFilteredArray = mainInfo?.photos?.filter((el) => el?.link !== item?.link);

        setMainInfo({
            ...(mainInfo as IGetMainInfo),
            ...{
                photos: newFilteredArray as IManProfileContentItem[],
                content: newFilteredArray as IManProfileContentItem[],
            },
        });
        saveEdits({ user_content: newFilteredArray });
        dispatch(loaderAction.setActiveGifLoader(false));
    };

    const openModalDeleteUserPhoto = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: IManProfileContentItem,
    ) => {
        event.stopPropagation();

        dispatch(
            alertAction.setMessage({
                title: 'Delete your profile photo?',
                message: 'Press the “Delete photo” button to delete\n your profile photo',
                btnName: 'Delete photo',
                successCallback: () => onDeleteUserPhoto(item),
            }),
        );
    };

    const uploadUserPhoto = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (event?.target?.files?.[0] && userState?.info?.external_id) {
            if (
                !checkFileAndShowAlert(
                    event?.target?.files?.[0],
                    ATTACH.TYPE_IMAGE,
                    (message: string, title: string) => {
                        dispatch(alertAction.setMessage({ message, title }));
                    },
                )
            ) {
                return;
            }

            awsUploadUserPhoto({
                file: event?.target?.files?.[0],
                fileName: event?.target?.files?.[0]?.name,
                external_id: userState?.info?.external_id,
            });
        }
    };

    const awsUploadUserPhoto = async ({
        file,
        fileName,
        external_id,
    }: {
        file: File;
        fileName: string;
        external_id: number;
    }) => {
        dispatch(loaderAction.setActiveGifLoader(true));

        try {
            let awsUploadFileResLink = '';

            if (!mainInfo?.photo_link?.length) {
                awsUploadFileResLink = await awsUploadFile(
                    file,
                    'avatar',
                    external_id?.toString(),
                    AWS.MEN_IMAGES,
                )
                    .then(async (res) => {
                        if (res?.success && res?.data?.link) {
                            await updateUserAvatarLocal(res?.data?.link);
                            return res?.data?.link;
                        } else {
                            if (res?.message || res?.error) {
                                dispatch(
                                    alertAction.setMessage({ message: res?.message || res?.error }),
                                );
                            }
                        }
                    })
                    .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
            }

            awsUploadFile(file, fileName, external_id?.toString(), AWS.MEN_IMAGES)
                .then(async (res) => {
                    if (res?.success && res?.data?.link) {
                        setMainInfo(<any>{
                            ...(mainInfo as IGetMainInfo),
                            ...{
                                photo_link: awsUploadFileResLink?.length
                                    ? awsUploadFileResLink
                                    : mainInfo?.photo_link,
                                photos: [
                                    ...[{ link: res?.data?.link, content_type: 'image' }],
                                    ...(mainInfo?.photos as IManProfileContentItem[]),
                                ],
                                content: [
                                    ...[{ link: res?.data?.link, content_type: 'image' }],
                                    ...(mainInfo?.photos as IManProfileContentItem[]),
                                ],
                            },
                        });

                        saveEdits({
                            user_content: [
                                ...[{ link: res?.data?.link, content_type: 'image' }],
                                ...(mainInfo?.photos as IManProfileContentItem[]),
                            ],
                        });
                    } else {
                        if (res?.message || res?.error) {
                            dispatch(
                                alertAction.setMessage({ message: res?.message || res?.error }),
                            );
                        }
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error as Error);
            dispatch(loaderAction.setActiveGifLoader(false));
        }
    };

    return {
        openModalDeleteUserPhoto,
        uploadUserPhoto,
    };
}
