import { WinkActionTypes } from '../../constants/wink';
import * as USER from '../../../constants/user';
import ChatsAPI from '../../../services/api/ChatsApi';
import { Dispatch } from 'redux';
import { IGetSayHelloListAction } from '../../reducers/wink/index.interface';

export function getSayHelloList() {
    return function (dispatch: Dispatch<IGetSayHelloListAction>) {
        new ChatsAPI().getSayHelloList().then((res) => {
            if (res?.status && res?.list?.length) {
                dispatch({
                    type: WinkActionTypes.GET_SAY_HELLO_LIST,
                    sayHelloList: [
                        ...[USER.DEFAULT_WINK_MESSAGE],
                        ...(res?.list.map((item: any) => item?.message_content) || []),
                    ],
                });
            }
        });
    };
}
