import React, { memo } from 'react';
import { formatDate } from '../../../../../services/time';
import styles from '../styles.module.scss';
import IconFavouriteNotify from '../../../../Icons/IconFavouriteNotify';
import { cutLengthString } from '../../../../../services/methods';
import IconCross from '../../../../Icons/IconCross';
import { ILikedNotifyProps } from './index.interface';
import placeholder from '../../../../../img/placeholder.gif';

const LikedNotify = ({ item, deleteNotification, openChat }: ILikedNotifyProps) => {
    const classNameNotify = `${styles.notify_item} ${styles.liked} ${item?.online ? styles.online : ''} ${item?.favorite ? styles.favorites : ''}`;

    return (
        <li
            className={classNameNotify}
            onClick={() =>
                openChat(
                    item.chat_uid ? item.chat_uid : null,
                    item.notification_id,
                    item.sender_external_id,
                )
            }
            id={`noty-${item.id}`}
            data-id={item.id}
        >
            <div
                className={`${styles.notify_item_photo_wrap} ${+item?.female_favorite === 1 ? styles.favorite : ''}`}
            >
                <img
                    src={item?.avatar || placeholder}
                    alt="user avatar"
                    className={styles.notify_item_photo}
                />
                {+item.female_favorite === 1 && <IconFavouriteNotify />}
                {+item?.unread_count > 1 && (
                    <span className={styles.unread} data-testid="notification-message-count">
                        {item?.unread_count}
                    </span>
                )}
            </div>
            <div className={styles.notify_content}>
                <p className={styles.notify_item_name}>{`${item.name}, ${item.age}`}</p>
                <p className={styles.notify_item_text}>
                    {cutLengthString(item?.message_content ?? '', 50)}
                </p>
            </div>
            <button
                type="button"
                className={styles.notify_item_clear_mask}
                onClick={(e) => deleteNotification(e, item.chat_uid)}
            >
                <IconCross />
            </button>
            <p className={styles.notify_item_time}>{formatDate(item.date_created)}</p>
        </li>
    );
};

export default memo(LikedNotify);
