import React from 'react';

export default function IconDropAttachment() {
    return (
        <svg
            width="30"
            height="38"
            viewBox="0 0 30 38"
            fill="#446077"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_17_1139)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.55999 2.17522C2.35375 1.38147 3.4303 0.935551 4.55284 0.935551H18.4829C18.6818 0.935551 18.8726 1.01457 19.0132 1.15522L29.4608 11.6028C29.6015 11.7434 29.6805 11.9342 29.6805 12.1331V33.0283C29.6805 34.1508 29.2346 35.2274 28.4408 36.0211C27.6471 36.8149 26.5705 37.2608 25.448 37.2608H4.55284C3.4303 37.2608 2.35375 36.8149 1.55999 36.0211C0.766242 35.2274 0.320312 34.1508 0.320312 33.0283V5.16807C0.320312 4.04554 0.76624 2.96897 1.55999 2.17522ZM4.55284 2.43555C3.82812 2.43555 3.1331 2.72344 2.62065 3.23588C2.1082 3.74833 1.82031 4.44336 1.82031 5.16807V33.0283C1.82031 33.753 2.1082 34.448 2.62065 34.9605C3.1331 35.4729 3.82812 35.7608 4.55284 35.7608H25.448C26.1727 35.7608 26.8677 35.4729 27.3802 34.9605C27.8926 34.448 28.1805 33.753 28.1805 33.0283V12.8831H18.4829C18.0687 12.8831 17.7329 12.5473 17.7329 12.1331V2.43555H4.55284ZM19.2329 3.4962L27.1198 11.3831H19.2329V3.4962ZM15.0004 18.3482C15.4146 18.3482 15.7504 18.684 15.7504 19.0982V23.572H20.2242C20.6384 23.572 20.9742 23.9078 20.9742 24.322C20.9742 24.7362 20.6384 25.072 20.2242 25.072H15.7504V29.5457C15.7504 29.9599 15.4146 30.2957 15.0004 30.2957C14.5862 30.2957 14.2504 29.9599 14.2504 29.5457V25.072H9.77663C9.36242 25.072 9.02663 24.7362 9.02663 24.322C9.02663 23.9078 9.36242 23.572 9.77663 23.572H14.2504V19.0982C14.2504 18.684 14.5862 18.3482 15.0004 18.3482Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_17_1139">
                    <rect width="30" height="38" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
