import { QuizActionTypes } from '../../constants/quiz';
import { IQuizState, QuizActions } from './index.interface';

const initState = {
    typeList: [],
    quizData: {},
    isActiveModal: false,
    callback: null,
};

export default function (state: IQuizState = initState, action: QuizActions): IQuizState {
    switch (action.type) {
        case QuizActionTypes.IS_ACTIVE_BEFORE_DEL_QUIZ_MODAL:
            return {
                ...state,
                ...{
                    isActiveModal: action?.isActiveModal,
                    callback: action?.callback,
                },
            };
        case QuizActionTypes.IS_ACTIVE_QUIZ_MODAL:
            return {
                ...state,
                ...{
                    isActiveModal: action?.isActiveModal,
                    callback: null,
                },
            };
        case QuizActionTypes.GET_QUIZ_DATA:
            return {
                ...state,
                ...{
                    quizData: action?.quizData,
                },
            };
        case QuizActionTypes.GET_TYPE_LIST:
            return {
                ...state,
                ...{
                    typeList: action?.typeList,
                },
            };

        default:
            return state;
    }
}
