import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import arrow from '../../../img/onboarding/arrow.svg';
import {
    IManProfileReferenceCityItem,
    IManProfileReferenceCountryItem,
    IManProfileReferenceHeightItem,
} from '../../../models/profile/ManProfile.interface';

type DropdownItem =
    | IManProfileReferenceHeightItem
    | IManProfileReferenceCityItem
    | IManProfileReferenceCountryItem;

interface IOnboardingDropdown<T extends DropdownItem> {
    value: T[keyof T];
    setValue: (value: T[keyof T]) => void;
    title: string;
    dropdownArray: T[];
    onClick?: ((item: T) => void) | null;
    index: keyof T;
    isCaret?: boolean;
    displayKey?: keyof T;
}

const OnboardingDropdown = <T extends DropdownItem>({
    value,
    setValue,
    title,
    dropdownArray,
    onClick = null,
    index,
    isCaret = true,
    displayKey,
}: IOnboardingDropdown<T>) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const onCloseDropdown = () => setIsOpen(false);
    const onToggleDropdown = () => setIsOpen(!isOpen);

    const onSelectItem = (item: T) => {
        setValue(item[index]);
        if (onClick) onClick(item);
        onCloseDropdown();
    };
    useOutsideClicker(dropdownRef, onCloseDropdown, wrapperRef);

    return (
        <div className={styles.onboardingInput}>
            <p>{title}</p>

            <div className={styles.inputWrapper} ref={wrapperRef} onClick={onToggleDropdown}>
                <input
                    className={`${styles.input} ${value ? styles.input_filled : ''} ${isOpen ? styles.input_opened : ''} ${isCaret ? '' : styles.input_noCaret}`}
                    value={String(value)}
                    type={'text'}
                    onClick={onToggleDropdown}
                    onChange={(event) => {
                        if (isCaret) setValue(event?.target?.value as T[keyof T]);
                    }}
                    placeholder={'Not specified'}
                />
                <img className={styles.arrow} src={arrow} alt={''} />
            </div>

            {isOpen && (
                <div ref={dropdownRef} className={styles.list}>
                    {dropdownArray?.map((item, key) => (
                        <div onClick={() => onSelectItem(item)} className={styles.option} key={key}>
                            {displayKey
                                ? (item[displayKey] as string)
                                : (item[index] as string | number)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default OnboardingDropdown;
