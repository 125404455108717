import { useEffect, useState } from 'react';
import { useLifecycleEventListener, useMetrics } from '@cabify/prom-react';
import useDetectedDevice from '../device/useDetectedDevice';
import * as PATH from '../../constants/path';
import { sendErrToSentry } from '../../services/sentry';

const usePerformanceMetrics = () => {
    const { observe } = useMetrics(); //Metrics for prometheus and grafana
    const { fullInfo } = useDetectedDevice();
    const initPerformance = {
        dcl: 0, //DOMContentLoaded
        ttfb: 0, //Time to First Byte
        // ttfp: 0, //Time to First Paint (Has only in Chrome browser. Same value as ttfcp)
        ttfcp: 0, //Time to First Contentful Paint
        load: 0, //Page Load Time
    };
    const [performance, setPerformance] = useState(initPerformance);
    const [loadV2, setLoadV2] = useState(0);
    const [dclV2, setDclV2] = useState(0);

    useLifecycleEventListener((event) => {
        const type = event?.type ?? '';
        const value = +(event?.start / 1000).toFixed(3) ?? 0; //in seconds
        if (type in performance) {
            setPerformance((prevState) => {
                return { ...prevState, ...{ [type]: value } };
            });
        }
    });

    const browserText = (browser = '') => {
        const browserLow = browser.toLowerCase();
        if (browserLow.includes('safari')) {
            return 'Safari';
        } else if (browserLow.includes('chrome')) {
            return 'Chrome';
        } else {
            return 'other';
        }
    };

    const osText = (os = '') => {
        const osLow = os.toLowerCase();
        if (osLow.includes('android')) {
            return 'Android';
        } else if (osLow.includes('ios')) {
            return 'IOS';
        } else if (osLow.includes('mac os')) {
            return 'MacOS';
        } else if (osLow.includes('windows')) {
            return 'Windows';
        } else {
            return 'other';
        }
    };

    useEffect(() => {
        function loadCallback() {
            try {
                const timing = window.performance.timing;

                setTimeout(() => {
                    setLoadV2(+((timing.loadEventEnd - timing.navigationStart) / 1000).toFixed(3));
                }, 0);
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }

        function domContentLoadedCallback() {
            try {
                const timing = window.performance.timing;

                setTimeout(() => {
                    let domContentLoadedTime;

                    if (timing.domContentLoadedEventEnd > 0) {
                        domContentLoadedTime =
                            timing.domContentLoadedEventEnd - timing.navigationStart;
                    } else {
                        domContentLoadedTime = Date.now() - timing.navigationStart;
                    }

                    setDclV2(+(domContentLoadedTime / 1000).toFixed(3));
                }, 0);
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }

        window.addEventListener('load', loadCallback);

        if (document.readyState === 'interactive' || document.readyState === 'complete') {
            domContentLoadedCallback();
        } else {
            document.addEventListener('DOMContentLoaded', domContentLoadedCallback);
        }

        return () => {
            window.removeEventListener('load', loadCallback);
            document.removeEventListener('DOMContentLoaded', domContentLoadedCallback);
        };
    }, []);

    useEffect(() => {
        try {
            const { device, browser, os } = fullInfo();
            const isMetricGegStore = localStorage.getItem('metric_reg');
            const isRegisterSuccess = window?.location?.search.includes('registration=success');
            const type = isRegisterSuccess && !isMetricGegStore ? 'reg' : 'visit';
            let updatedPath = window.location?.pathname;

            for (const key in PATH.PATHS) {
                // @ts-expect-error PATH.PATHS
                const replacePath = PATH.PATHS[key]?.replacePath;
                if (replacePath) {
                    const regex = new RegExp(`(${replacePath})[^/]+`, 'g');
                    updatedPath = updatedPath.replace(regex, `$1id`);
                }
            }

            if (loadV2 > 0) performance.load = loadV2;
            if (dclV2 > 0) performance.dcl = dclV2;

            const { dcl, load, ttfb, ttfcp } = performance;

            if (dcl > 0 && load > 0 && ttfb > 0 && ttfcp > 0) {
                const keys = Object.keys(performance);
                const lastKey = keys[keys.length - 1];
                if (isRegisterSuccess) localStorage.setItem('metric_reg', '1');

                for (const key in performance) {
                    const isSkipSending = key !== lastKey;
                    // GT-12697
                    // @ts-expect-error performance
                    const eventValue = performance[key] > 60 ? 60 : performance[key];

                    observe(
                        'page_performance',
                        {
                            device_type: device,
                            browser: browserText(browser),
                            os: osText(os),
                            event_type: key,
                            page: updatedPath,
                            type,
                        },
                        eventValue,
                        isSkipSending,
                    );
                }
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }, [performance, loadV2, dclV2]);
};

export default usePerformanceMetrics;
