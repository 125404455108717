import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as PROFILE from '../../../constants/profile';
import * as MENU from '../../../constants/menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import { IManProfileReferenceHobbyItem } from '../../../models/profile/ManProfile.interface';
import { IUseHandleSubInfo } from './index.interface';

export default function useHandleSubInfo({ subInfo, setSubInfo, saveEdits }: IUseHandleSubInfo) {
    const history = useHistory();
    const referenciesList: any = useSelector((state: RootState) => state.referencies.list);

    const [editedData, setEditedData] = useState<any>(null);
    const [timerID, setTimerID] = useState<NodeJS.Timeout | number>(0);
    const HOBBY_VIEW_COUNT = 3;
    const HOBBY_MAX_COUNT = 5;

    const onChangeInputValue = ({
        value,
        field,
        section,
        payloadKey,
        payloadValue,
    }: {
        value: any;
        field: string;
        section: string;
        payloadKey?: string;
        payloadValue?: any;
    }) => {
        let newValue = value;

        if (field === 'hair_id') {
            newValue = referenciesList?.hair_list?.find((el: any) => el?.hair === value?.name)?.id;

            setSubInfo({
                ...subInfo,
                ...{ woman_hair_id: newValue, woman_hair: value },
            });
        } else if (field === 'eye_id') {
            newValue = referenciesList?.eye_list?.find((el: any) => el?.eye === value?.name)?.id;

            setSubInfo({
                ...subInfo,
                ...{ woman_eye_id: newValue, woman_eye: value },
            });
        } else {
            setSubInfo({
                ...subInfo,
                ...{ [field]: value, [`${field}_id`]: value?.id },
            });
        }

        const newPayloadKey = payloadKey ?? field;
        const newPayloadValue = payloadValue ?? newValue;

        setEditedData({
            ...editedData,
            [section]: { ...editedData?.[section], [newPayloadKey]: newPayloadValue },
        });
    };

    const getHobbiesViewTitle = () => {
        return subInfo?.hobbies?.length
            ? subInfo?.hobbies
                  ?.slice(0, HOBBY_VIEW_COUNT)
                  ?.map((item: IManProfileReferenceHobbyItem) => item?.hobbie)
                  ?.join(', ')
            : 'Not Specified';
    };

    const getHobbiesViewIcon = (): string[] => {
        return subInfo?.hobbies?.length
            ? subInfo?.hobbies
                  ?.slice(0, HOBBY_VIEW_COUNT)
                  ?.map((item: IManProfileReferenceHobbyItem) => item?.icon)
            : [];
    };

    const getHobbiesViewCount = () => {
        const result = subInfo?.hobbies?.length - HOBBY_VIEW_COUNT;

        return subInfo?.hobbies?.length ? (result > 0 ? `+${result} more` : '') : '';
    };

    /**
     * Method to change profile hobby list
     * @param item (String)
     * @param field (String)
     */
    const handleChangeHobbies = (item: IManProfileReferenceHobbyItem) => {
        const hobbiesArr: IManProfileReferenceHobbyItem[] = subInfo?.hobbies || [];
        let editedHobbies: IManProfileReferenceHobbyItem[] = [];

        if (hobbiesArr?.some((hobby: IManProfileReferenceHobbyItem) => +hobby?.id === +item?.id)) {
            editedHobbies = hobbiesArr?.filter(
                (hobby: IManProfileReferenceHobbyItem) => +hobby?.id !== +item?.id,
            );
        } else if (hobbiesArr?.length < HOBBY_MAX_COUNT) {
            editedHobbies = [...hobbiesArr, ...[item]];
        }

        setSubInfo({ ...subInfo, ...{ hobbies: editedHobbies } });

        const payloadHobbies = editedHobbies.map((hobby) => ({ hobby_id: hobby?.id }));
        setEditedData({ ...editedData, user_hobby: payloadHobbies });
    };

    useEffect(() => {
        let timer: any = 0;
        clearTimeout(timerID);

        //updating profile
        if (editedData) {
            timer = setTimeout(() => {
                saveEdits(editedData);
                setEditedData(null);
            }, PROFILE.EDIT_PROFILE_TIMER);

            setTimerID(timer);
        }

        //updating profile when switching to another page
        history.block(({ pathname }) => {
            if (pathname !== `/${MENU.PROFILE_PAGE}`) {
                clearTimeout(timer);
                if (editedData !== null) {
                    saveEdits(editedData);
                }
            }
        });
    }, [editedData]);

    return {
        subInfo,

        getHobbiesViewTitle,
        getHobbiesViewIcon,
        getHobbiesViewCount,

        onChangeInputValue,
        handleChangeHobbies,
    };
}
