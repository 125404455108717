import React from 'react';

export default function IconCopyright() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="white"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.375 14.5C4.375 9.18426 8.68426 4.875 14 4.875C19.3157 4.875 23.625 9.18426 23.625 14.5C23.625 19.8157 19.3157 24.125 14 24.125C8.68426 24.125 4.375 19.8157 4.375 14.5ZM14 3.125C7.71776 3.125 2.625 8.21776 2.625 14.5C2.625 20.7822 7.71776 25.875 14 25.875C20.2822 25.875 25.375 20.7822 25.375 14.5C25.375 8.21776 20.2822 3.125 14 3.125ZM14.5089 19.1667C13.5198 19.1667 12.6285 18.9701 11.8349 18.577C11.0528 18.1727 10.4375 17.6167 9.98891 16.9091C9.55186 16.2016 9.33333 15.3985 9.33333 14.5C9.33333 13.6015 9.55186 12.7984 9.98891 12.0909C10.4375 11.3833 11.0528 10.8329 11.8349 10.4398C12.6285 10.0355 13.5198 9.83333 14.5089 9.83333C15.4865 9.83333 16.3376 10.0355 17.0622 10.4398C17.7983 10.8329 18.3331 11.4001 18.6667 12.1414L16.5792 13.2365C16.0961 12.4053 15.4003 11.9898 14.4917 11.9898C13.7901 11.9898 13.2093 12.2144 12.7492 12.6637C12.2892 13.1129 12.0591 13.725 12.0591 14.5C12.0591 15.275 12.2892 15.8871 12.7492 16.3363C13.2093 16.7856 13.7901 17.0102 14.4917 17.0102C15.4118 17.0102 16.1076 16.5947 16.5792 15.7635L18.6667 16.8755C18.3331 17.5943 17.7983 18.1558 17.0622 18.5602C16.3376 18.9645 15.4865 19.1667 14.5089 19.1667Z"
            />
        </svg>
    );
}
