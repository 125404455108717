import React from 'react';

export default function IconChangeEmailAt() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#00317B"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_68)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.4533 5.47534C9.81936 4.85905 8.95401 4.47954 8.00002 4.47954C6.05596 4.47954 4.48 6.05551 4.48 7.99955C4.48 9.94361 6.05597 11.5196 8.00002 11.5196C9.15043 11.5196 10.1719 10.9677 10.8142 10.1142C10.9363 10.3295 11.0876 10.5292 11.2656 10.7072C11.7858 11.2275 12.4912 11.5196 13.2267 11.5196C13.9622 11.5196 14.6676 11.2274 15.1878 10.7072C15.7079 10.1871 16 9.48169 16 8.74622V7.99952C15.9999 6.19394 15.3891 4.4415 14.2666 3.02713C13.1442 1.61276 11.5765 0.619688 9.81813 0.209343C8.0598 -0.201002 6.21436 -0.00446366 4.58188 0.767002C2.94941 1.53847 1.62592 2.83946 0.8266 4.45848C0.0272827 6.0775 -0.200816 7.91927 0.179351 9.68437C0.559512 11.4495 1.5256 13.0341 2.92053 14.1805C4.31544 15.3269 6.05714 15.9678 7.86248 15.9988C9.6678 16.0299 11.4305 15.4493 12.864 14.3515C13.0979 14.1724 13.1423 13.8376 12.9632 13.6038C12.7841 13.3699 12.4493 13.3255 12.2155 13.5046C10.9731 14.4561 9.44541 14.9592 7.88081 14.9323C6.31624 14.9055 4.80673 14.35 3.59779 13.3564C2.38886 12.3629 1.55159 10.9896 1.22211 9.45979C0.892634 7.93004 1.09031 6.33384 1.78306 4.93069C2.4758 3.52753 3.62283 2.40001 5.03764 1.73141C6.45245 1.0628 8.05182 0.89247 9.57572 1.2481C11.0996 1.60373 12.4583 2.46439 13.4311 3.69019C13.5556 3.84714 13.6729 4.00888 13.7828 4.17492C13.832 4.24937 13.8798 4.32468 13.926 4.4008C14.582 5.48085 14.9333 6.72491 14.9334 7.99955V8.74622C14.9334 8.90686 14.9107 9.06515 14.8672 9.21683C14.7881 9.49259 14.64 9.74649 14.4335 9.95293C14.1135 10.273 13.6794 10.4529 13.2267 10.4529C12.7741 10.4529 12.3399 10.2731 12.0199 9.95298C11.6998 9.63293 11.52 9.19883 11.52 8.74622V5.01288C11.52 4.71832 11.2812 4.47954 10.9867 4.47954C10.6921 4.47954 10.4533 4.71832 10.4533 5.01288V5.47534ZM8.00002 5.54621C6.64507 5.54621 5.54667 6.64462 5.54667 7.99955C5.54667 9.3545 6.64507 10.4529 8.00002 10.4529C9.35495 10.4529 10.4533 9.3545 10.4533 7.99955C10.4533 6.64461 9.35495 5.54621 8.00002 5.54621Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_68">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
