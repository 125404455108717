import React from 'react';

export default function IconMuted() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 4.75001C14 4.4617 13.8347 4.19892 13.5749 4.07403C13.315 3.94914 13.0066 3.98425 12.7815 4.16436L7.98691 8.00001H4.25C3.83579 8.00001 3.5 8.33579 3.5 8.75001V14.75C3.5 15.1642 3.83579 15.5 4.25 15.5H7.98691L12.7815 19.3357C13.0066 19.5158 13.315 19.5509 13.5749 19.426C13.8347 19.3011 14 19.0383 14 18.75V4.75001ZM8.71852 9.33566L12.5 6.31048V17.1895L8.71852 14.1644C8.58554 14.058 8.4203 14 8.25 14H5V9.50001H8.25C8.4203 9.50001 8.58554 9.44205 8.71852 9.33566ZM15.7028 9.13278C15.9731 8.86242 16.4115 8.86242 16.6818 9.13278L18.5 10.9509L20.3182 9.13278C20.5885 8.86242 21.0269 8.86242 21.2972 9.13278C21.5676 9.40314 21.5676 9.84149 21.2972 10.1119L19.4791 11.93L21.2972 13.7482C21.5676 14.0185 21.5676 14.4569 21.2972 14.7272C21.0269 14.9976 20.5885 14.9976 20.3182 14.7272L18.5 12.9091L16.6818 14.7272C16.4115 14.9976 15.9731 14.9976 15.7028 14.7272C15.4324 14.4569 15.4324 14.0185 15.7028 13.7482L17.5209 11.93L15.7028 10.1119C15.4324 9.84149 15.4324 9.40314 15.7028 9.13278Z"
            />
        </svg>
    );
}
