import React, { useRef, useState } from 'react';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import { IDoubleSelectProps } from './index.interface';
import { IArrayFields } from '../../SearchFilter/SearchToolbar/index.interface';

export default function DoubleSelect({
    formDataFrom,
    formDataTo,
    selectTitle,
    defaultNameFrom,
    defaultNameTo,
    arrayFieldsFrom,
    arrayFieldsTo,
    handleClickFrom,
    handleClickTo,
    index,
}: IDoubleSelectProps) {
    const [openBoxTo, setOpenBoxTo] = useState<boolean>(false);
    const [openBoxFrom, setOpenBoxFrom] = useState<boolean>(false);
    const selectRef = useRef(null);

    useOutsideClicker(selectRef, () => {
        setOpenBoxTo(false);
        setOpenBoxFrom(false);
    });

    if (!(arrayFieldsFrom?.length > 0) || !(arrayFieldsTo?.length > 0)) return null;

    return (
        <div className="fill_data_form_select_item_wrap">
            <div className="fill_data_form_select_item_name">{selectTitle}</div>
            <div className="fill_data_form_select_item item_two">
                <div className="cl2h_tab_wide_filter_item_wrap" ref={selectRef}>
                    <div
                        className={`cl2h_tab_wide_filter_item ${openBoxFrom ? 'open' : ''}`}
                        data-testid={`${defaultNameFrom?.toLowerCase()}`}
                    >
                        <div
                            className={`like_select_head ${typeof formDataFrom === 'object' && index && +formDataFrom[index] !== 0 ? 'selected' : ''}`}
                            onClick={() => {
                                setOpenBoxFrom(!openBoxFrom);
                                setOpenBoxTo(false);
                            }}
                        >
                            <>
                                {formDataFrom &&
                                +formDataFrom !== 0 &&
                                +formDataFrom[`${index}` as keyof typeof formDataFrom] !== 0
                                    ? index
                                        ? formDataFrom[`${index}` as keyof typeof formDataFrom]
                                        : formDataFrom
                                    : defaultNameFrom}
                            </>
                        </div>
                        <div className={`like_select_list_wrap ${openBoxFrom ? 'open' : ''}`}>
                            <div
                                className={`like_select_list ${openBoxFrom ? 'open' : ''}`}
                                data-testid="select-list"
                            >
                                {arrayFieldsFrom.map((item, key) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label"
                                            onClick={() => {
                                                handleClickFrom(item);
                                                setOpenBoxFrom(false);
                                            }}
                                        >
                                            <>
                                                {index
                                                    ? (item as IArrayFields)[
                                                          index as keyof IArrayFields
                                                      ]
                                                    : item || '—'}
                                            </>
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`cl2h_tab_wide_filter_item ${openBoxTo ? 'open' : ''}`}
                        data-testid={`${defaultNameTo?.toLowerCase()}`}
                    >
                        <div
                            className={`like_select_head ${typeof formDataTo === 'object' && index && +formDataTo[`${index}`] !== 0 ? 'selected' : ''}`}
                            onClick={() => {
                                setOpenBoxTo(!openBoxTo);
                                setOpenBoxFrom(false);
                            }}
                        >
                            <>
                                {formDataTo &&
                                +formDataTo !== 0 &&
                                +formDataTo[`${index}` as keyof typeof formDataTo] !== 0
                                    ? index
                                        ? formDataTo[`${index}` as keyof typeof formDataTo]
                                        : formDataTo
                                    : defaultNameTo}
                            </>
                        </div>
                        <div className={`like_select_list_wrap ${openBoxTo ? 'open' : ''}`}>
                            <div
                                className={`like_select_list ${openBoxTo ? 'open' : ''}`}
                                data-testid="select-list"
                            >
                                {arrayFieldsTo.map((item, key) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label"
                                            onClick={() => {
                                                handleClickTo(item);
                                                setOpenBoxTo(false);
                                            }}
                                        >
                                            <>
                                                {index
                                                    ? (item as IArrayFields)[
                                                          index as keyof IArrayFields
                                                      ]
                                                    : item || '—'}
                                            </>
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
