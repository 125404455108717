import { useDispatch, useSelector } from 'react-redux';
import ChatsApi from '../../services/api/ChatsApi';
import { hideStringStar } from '../../services/methods';
import * as alertAction from '../../store/actions/alert';
import * as ALERT from '../../constants/alert';
import * as MENU from '../../constants/menu';
import * as REGEXP from '../../constants/regExp';
import useTransition from '../transition/useTransition';
import * as currentChatAction from '../../store/actions/currentChat';
import * as notifyAction from '../../store/actions/notify';
import * as chatsAction from '../../store/actions/chats';
import * as userAction from '../../store/actions/user';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import moment from 'moment';
import { closePanel } from '../../services/dom';
import * as FRONT_SYNC from '../../constants/frontSync';
import useSendActionSync from '../frontSync/useSendActionSync';
import SITE from '../../config/site';
import * as MIRROR from '../../constants/mirror';
import { getLastMessageContent } from '../../services/methods/chat';
import * as ERROR from '../../constants/error';
import { RootState } from '../../index';

export default function useInitOnlineWoman() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const sendActionSync = useSendActionSync();

    const userState = useSelector((state: RootState) => state.user);

    const askVideo = (chat_uid: string, external_id: number, is_blocked: number) => {
        if (!userState?.info?.external_id) return;
        if (+is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }
        if (!chat_uid || !external_id) return;

        const hashId = moment().unix();
        const inputData = 'Hello! Can you send me your video, please?';
        let _inputData = inputData.replace(/(\r\n|\n|\r)/gm, '');

        _inputData = MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID)
            ? inputData
            : hideStringStar(_inputData, REGEXP.NUMBER_IN_STRING);

        new ChatsApi()
            .postMessage(userState?.info?.external_id, external_id, _inputData, 'ASK_VIDEO')
            .then((res) => {
                if (res?.canceled) return;
                if (res?.status) {
                    if (!res?.response?.message_object) {
                        dispatch(currentChatAction.deleteMessageInHistory(hashId));
                        if (res?.message)
                            dispatch(alertAction.setMessage({ message: res?.message }));
                        else
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.ENOUGH_CREDITS_CHAT,
                                }),
                            );
                        return;
                    }
                    sendActionSync.postUserAction(+external_id, FRONT_SYNC.ACTION_ASK_VIDEO);
                    dispatch(
                        currentChatAction.updateMessageInHistory(
                            hashId,
                            res?.response?.message_object,
                        ),
                    );
                    dispatch(
                        chatsAction.updateMessageInChatList(
                            res?.response?.message_object?.chat_uid,
                            getLastMessageContent(
                                res?.response?.message_object?.message_content,
                                res?.response?.message_object?.message_type,
                            ),
                            res?.response?.message_object?.message_type,
                            res?.response?.message_object?.date_created,
                            res?.response?.message_object?.sender_external_id,
                        ),
                    );
                    dispatch(userAction.setBalance());
                    if (userState?.info?.external_id) {
                        dispatch(notifyAction.deleteNotify(userState?.info?.external_id, chat_uid));
                    }
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${chat_uid}`);
                } else if (res?.block) {
                    dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                } else {
                    if (res?.message)
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message,
                                title: res?.title,
                            }),
                        );
                    else
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.ENOUGH_CREDITS_CHAT,
                            }),
                        );
                }
                dispatch(currentChatAction.deleteMessageInHistory(hashId));
            });
    };

    const openChat = (chatUid: string | null = null, is_blocked: number) => {
        if (!userState?.info?.external_id) return;
        if (+is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }
        if (!chatUid) return;

        closePanel();
        dispatch(notifyAction.deleteNotify(userState?.info?.external_id, chatUid));
        transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${chatUid}`);
    };

    const openLetter = (profile: any) => {
        if (!userState?.info?.external_id) return;
        if (!+profile?.is_blocked) {
            dispatch(
                currentChatMailboxAction.addMailboxChatInfo({
                    ...profile,
                    sender_image: profile.avatar ?? profile.photo_link,
                    sender_name: profile?.name,
                    sender_age: profile?.age,
                    sender_city: profile?.city_name ?? profile?.country_name,
                    sender_gifts: profile?.gifts,
                    sender_search_img: profile?.link,
                    sender_id: profile?.external_id,
                    female_id: +profile?.external_id,
                    male_id: +userState?.info?.external_id,
                    openFromChat: true,
                }),
            );
            dispatch(
                currentChatMailboxAction.getMailboxChatHistory({
                    userId: +userState?.info?.external_id,
                    girlId: profile?.public_external_id || '',
                    page: 1,
                }),
            );
            transition.transitionWithHistoryPush('letter', `/letter/`);
        } else {
            dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }
    };

    return { askVideo, openChat, openLetter };
}
