import { StatusNotifyActionTypes } from '../../constants/statusNotify';
import { StatusNotifyAction, IStatusNotifyState } from './index.interface';

const initState = {
    notifyType: '',
    message: '',
    title: '',
};

export default function (
    state: IStatusNotifyState = initState,
    action: StatusNotifyAction,
): IStatusNotifyState {
    switch (action.type) {
        case StatusNotifyActionTypes.INITIAL_NOTIFY_STATE:
            return initState;

        case StatusNotifyActionTypes.SET_STATUS_NOTIFY:
            return {
                ...state,
                ...{
                    notifyType: action?.notifyType ?? '',
                    message: action?.message ?? '',
                    title: action?.title ?? '',
                },
            };

        default:
            return state;
    }
}
