import { AlertActionTypes } from '../../constants/alert';
import * as ALERT_TYPE from '../../../constants/alert';
import { AlertAction, IAlertState } from './index.interface';

const initState = {
    alertType: '',
    message: '',
    title: '',
    btnName: '',
    successCallback: () => null,
    isPaymentBtn: false,
};

export default function (state: IAlertState = initState, action: AlertAction): IAlertState {
    switch (action.type) {
        case AlertActionTypes.INITIAL_STATE:
            return initState;

        case AlertActionTypes.SET_MESSAGE:
            return {
                ...state,
                ...{
                    alertType: ALERT_TYPE.NEW_MESSAGE,
                    message: action?.message ?? '',
                    title: action?.title ?? '',
                    btnName: action?.btnName ?? '',
                    successCallback: action?.successCallback ?? null,
                    isPaymentBtn: action?.isPaymentBtn ?? false,
                },
            };

        case AlertActionTypes.SET_CUSTOM_MESSAGE:
            return {
                ...state,
                ...{
                    alertType: action?.alertType ?? '',
                    message: action?.message ?? '',
                    successCallback: action?.successCallback ?? null,
                },
            };

        default:
            return state;
    }
}
