import { CountersActionTypes } from '../../constants/counters';
import { CountersActions, ICountersState } from './index.interface';

const initState = {
    letterCounter: null,
    chatCounter: {},
};

export default function (
    state: ICountersState = initState,
    action: CountersActions,
): ICountersState {
    switch (action.type) {
        case CountersActionTypes.CHAT_COUNTER_GET:
            return {
                ...state,
                ...{
                    chatCounter: action.data,
                },
            };

        case CountersActionTypes.LETTER_COUNTER_GET:
            return {
                ...state,
                ...{
                    letterCounter: action.data,
                },
            };

        case CountersActionTypes.CHAT_ADD_UNREAD:
            return {
                ...state,
                ...{
                    chatCounter: {
                        unanswered:
                            state?.chatCounter?.unanswered && +state?.chatCounter?.unanswered + 1,
                    },
                },
            };

        case CountersActionTypes.LETTER_ADD_UNREAD:
            return {
                ...state,
                ...{ letterCounter: state.letterCounter && +state.letterCounter + 1 },
            };

        case CountersActionTypes.CHAT_COUNTER_REMOVE:
            return {
                ...state,
                ...{
                    chatCounter: null,
                },
            };

        case CountersActionTypes.LETTER_COUNTER_REMOVE:
            return {
                ...state,
                ...{
                    letterCounter: null,
                },
            };

        default:
            return state;
    }
}
