import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PurchaseGifts from '../../components/Profile/ProfileGifts';
import * as menuAction from '../../store/actions/menu';

export default function ProfileGiftsPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuAction.setMainMenu('my-profile/gifts'));
    }, []);

    return (
        <>
            <PurchaseGifts />
        </>
    );
}
