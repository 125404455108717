import React from 'react';

export default function IconText() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="#333333"
        >
            <path d="M7.34515 9.43063C7.7239 9.43063 7.92474 9.26961 8.05099 8.86707L8.69371 7.07287H11.9704L12.6074 8.86707C12.7336 9.26961 12.9345 9.43063 13.3132 9.43063C13.7149 9.43063 13.9732 9.1891 13.9732 8.80956C13.9732 8.67155 13.9502 8.55654 13.8986 8.40127L11.3507 1.52352C11.1785 1.04046 10.8571 0.804688 10.3349 0.804688C9.83568 0.804688 9.50858 1.04046 9.34216 1.51777L6.78277 8.43002C6.73113 8.57954 6.70817 8.7003 6.70817 8.82106C6.70817 9.20061 6.94919 9.43063 7.34515 9.43063ZM9.02081 6.00901L10.3177 2.2711H10.3521L11.6491 6.00901H9.02081ZM0.900762 9.41338C1.19917 9.41338 1.3828 9.25811 1.49183 8.90157L1.90501 7.66519H4.1373L4.54474 8.90732C4.65377 9.26961 4.84314 9.41338 5.15303 9.41338C5.49734 9.41338 5.73262 9.19485 5.73262 8.86707C5.73262 8.7348 5.70393 8.60254 5.65228 8.45302L3.9135 3.79501C3.74708 3.34071 3.45442 3.12219 3.01255 3.12219C2.58789 3.12219 2.30097 3.33496 2.13455 3.79501L0.395769 8.45302C0.344122 8.59104 0.31543 8.74056 0.31543 8.86707C0.31543 9.20636 0.539233 9.41338 0.900762 9.41338ZM2.18046 6.76809L2.94942 4.37008H3.07567L3.85611 6.76809H2.18046Z" />
        </svg>
    );
}
