import { useState } from 'react';

export default function useModalManagePassword() {
    const [isOpenManagePassword, setIsOpenManagePassword] = useState(false);

    // Open manage password modal
    const openManagePasswordModal = () => setIsOpenManagePassword(true);
    // Close manage password modal
    const closeManagePasswordModal = () => setIsOpenManagePassword(false);

    return {
        isOpenManagePassword,
        openManagePasswordModal,
        closeManagePasswordModal,
    };
}
