import ProfileApi from '../../../services/api/ProfileApi';

export default function useEditManProfile() {
    /**
     * Saving edited profile information
     * @param editData (Object)
     */

    const saveEdits = async (editData: any) => {
        await new ProfileApi().editProfileNew(editData);
    };

    return { saveEdits };
}
