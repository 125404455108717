import React, { useEffect, useState } from 'react';
import SearchInput from '../../common/SearchInput';
import SearchFilterOnline from '../SearchFilterOnline';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import SearchFilterDetailedV3 from './SearchFilterDetailedV3';
import * as userAction from '../../../store/actions/user';
import { RootState } from '../../../index';
import { ISearchFilter } from '../../../store/reducers/search/index.interface';
import { VIDEO_SHOW_FILTERS_DEFAULT } from '../../../constants/search';

interface ISearchFiltersV3<T> {
    filter: T;
    setFilter: (value: T) => void;
    isActiveOnlineFilter?: boolean;
    isLoadingPage: boolean;
}

export default function SearchFiltersV3<
    T extends ISearchFilter | typeof VIDEO_SHOW_FILTERS_DEFAULT,
>({ filter, setFilter, isActiveOnlineFilter = true, isLoadingPage }: ISearchFiltersV3<T>) {
    const dispatch = useDispatch();
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const isISearchFilter = (filter: any): filter is ISearchFilter => {
        return 'country' in filter;
    };

    const setFilterField = async (field: string, value: any) => {
        const newFilter = { ...filter, [field]: value };

        if (field === 'id' && newFilter.id === filter.id) return;
        if (field === 'country' && isISearchFilter(newFilter)) {
            if (newFilter.country === filter.country) return;

            newFilter.country_name = value;
            newFilter.country =
                referenciesState?.list?.country_list?.find((el) => el?.country_name === value)
                    ?.id ?? 0;
            if (value && !newFilter.country) return;
        }

        setFilter(newFilter);
    };

    const setGenderFilter = async (gender: number | string, sexFilter: number) => {
        const newFilter = { ...filter, gender, sexFilter };
        setFilter(newFilter);
        dispatch(userAction.updateInfoSex(sexFilter));
    };

    const handleOnline = () => {
        if (isLoadingRequest) return;
        setFilterField('online', !filter?.online);
    };

    useEffect(() => {
        if (isLoadingPage !== isLoadingRequest) {
            setIsLoadingRequest(isLoadingPage);
        }
    }, [isLoadingPage]);

    return (
        <div className="c3lh_search_top" data-testid="filters-block">
            <div className={styles.wrapper}>
                <SearchInput
                    handleSearch={(value) => setFilterField('id', value)}
                    value={filter?.id}
                />
                {isActiveOnlineFilter && (
                    <SearchFilterOnline handleChange={handleOnline} selected={filter?.online} />
                )}
            </div>

            <SearchFilterDetailedV3
                isActiveOnlineFilter={isActiveOnlineFilter}
                setFilterField={setFilterField}
                handleOnline={handleOnline}
                filterOnline={filter?.online}
                countryFilter={isISearchFilter(filter) ? filter?.country_name : ''}
                handleCountryFilter={(value) => setFilterField('country', value)}
                ageFromFilter={filter?.from}
                setAgeFromFilter={(value) => setFilterField('from', value)}
                ageToFilter={filter?.to}
                setAgeToFilter={(value) => setFilterField('to', value)}
                genderFilter={'gender' in filter ? (filter?.gender as number) : 0}
                setGenderFilter={setGenderFilter}
                nameFilter={filter?.id}
            />
        </div>
    );
}
