import { useState } from 'react';

export default function useInitImbraFormModal() {
    const [isActiveImbraFormModal, setIsActiveImbraFormModal] = useState(false);

    const openImbraFormModal = () => {
        setIsActiveImbraFormModal(true);
    };

    const closeImbraFormModal = () => {
        setIsActiveImbraFormModal(false);
    };

    return {
        isActiveImbraFormModal,
        openImbraFormModal,
        closeImbraFormModal,
    };
}
