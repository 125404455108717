//title
export const SLIDER_TITLE_PROFILE_MAN = 'You may like';
export const SLIDER_TITLE_PROFILE_WOMAN = 'Similar members';
export const SLIDER_TITLE_LIKEHER = 'Up next';
export const SLIDER_TITLE_LETTER = 'You may like'; // 'You may like'
// export const SLIDER_TITLE_LETTER = 'You viewed'; // 'You may like'
export const SLIDER_TITLE_CHAT = 'You may like'; // 'You may like'
// export const SLIDER_TITLE_CHAT = 'You viewed'; // 'You may like'

//text PC
export const SLIDER_TEXT_PROFILE_MAN_PC = 'You may be interested in these members';
export const SLIDER_TEXT_PROFILE_WOMAN_PC = 'You may be interested in these members';
export const SLIDER_TEXT_LIKEHER_PC = 'You may be interested in these members';
export const SLIDER_TEXT_LETTER_PC = 'Start a conversation with these members';
export const SLIDER_TEXT_CHAT_PC = 'Start a conversation with these members';

//text mobile
export const SLIDER_TEXT_PROFILE_MAN_MOB = 'View those lovely members';
export const SLIDER_TEXT_PROFILE_WOMAN_MOB = 'View those lovely members';
export const SLIDER_TEXT_LIKEHER_MOB = '';
export const SLIDER_TEXT_LETTER_MOB = 'Start conversation with them';
export const SLIDER_TEXT_CHAT_MOB = 'Start conversation with them';

//type
export const SLIDER_TYPE_PROFILE_MAN = 'my-profile';
export const SLIDER_TYPE_PROFILE_WOMAN = 'profile-woman';
export const SLIDER_TYPE_LIKEHIM = 'like';
export const SLIDER_TYPE_LIKEHER = 'like';
export const SLIDER_TYPE_LETTER = 'letter';
export const SLIDER_TYPE_CHAT = 'chat';

//array
export const ARRAY_SLIDER = {
    [SLIDER_TYPE_PROFILE_MAN]: {
        title: SLIDER_TITLE_PROFILE_MAN,
        textPC: SLIDER_TEXT_PROFILE_MAN_PC,
        textMob: SLIDER_TEXT_PROFILE_MAN_MOB,
    },
    [SLIDER_TYPE_PROFILE_WOMAN]: {
        title: SLIDER_TITLE_PROFILE_WOMAN,
        textPC: SLIDER_TEXT_PROFILE_WOMAN_PC,
        textMob: SLIDER_TEXT_PROFILE_WOMAN_MOB,
    },
    [SLIDER_TYPE_LIKEHER]: {
        title: SLIDER_TITLE_LIKEHER,
        textPC: SLIDER_TEXT_LIKEHER_PC,
        textMob: SLIDER_TEXT_LIKEHER_MOB,
    },
    [SLIDER_TYPE_LIKEHIM]: {
        title: SLIDER_TITLE_LIKEHER,
        textPC: SLIDER_TEXT_LIKEHER_PC,
        textMob: SLIDER_TEXT_LIKEHER_MOB,
    },
    [SLIDER_TYPE_LETTER]: {
        title: SLIDER_TITLE_LETTER,
        textPC: SLIDER_TEXT_LETTER_PC,
        textMob: SLIDER_TEXT_LETTER_MOB,
    },
    [SLIDER_TYPE_CHAT]: {
        title: SLIDER_TITLE_CHAT,
        textPC: SLIDER_TEXT_CHAT_PC,
        textMob: SLIDER_TEXT_CHAT_MOB,
    },
};

export enum SliderTypeEnum {
    ProfileMan = 'my-profile',
    ProfileWoman = 'profile-woman',
    LikeHim = 'like',
    LikeHer = 'like',
    Letter = 'letter',
    Chat = 'chat',
}

export const SLIDER_SETTINGS = {
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    arrows: false,
    touchThreshold: 10,
    swipeToSlide: true,
    draggable: false,
};
