import { useDispatch, useSelector } from 'react-redux';
import * as modalsAction from '../../store/actions/modals';
import { RootState } from '../../index';

export default function useInitWelcomeAlert() {
    const modalsState = useSelector((state: RootState) => state.modals);

    const dispatch = useDispatch();

    const openWelcomeAlertModal = () => {
        dispatch(modalsAction.openWelcomeModal());
    };
    const closeWelcomeAlertModal = () => {
        dispatch(modalsAction.closeWelcomeModal());
    };

    return {
        isWelcomeAlert: modalsState?.welcomeModal?.isActive,
        openWelcomeAlertModal,
        closeWelcomeAlertModal,
    };
}
