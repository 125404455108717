import React, { useEffect, useState } from 'react';
import MessageForm from './MessageForm';
import { useDispatch, useSelector } from 'react-redux';
// import * as mailboxAction from '../../../../store/actions/mailbox';
import * as currentChatMailboxAction from '../../../../store/actions/currentChatMailbox';
import * as SEX from '../../../../constants/sex';
import { RootState } from '../../../../index';
import avatar_placeholder from '../../../../img/placeholder.gif';
import IconCirclePlus from '../../../Icons/IconCirclePlus';
import IconCross from '../../../Icons/IconCross';

export default function ComposeWriteLetterModal({
    openComposeModal,
    closeWriteLetterModal,
}: {
    openComposeModal?: () => void;
    closeWriteLetterModal: () => void;
}) {
    const [isOpenBlock, setIsOpenBlock] = useState(false);

    const userState = useSelector((state: RootState) => state.user);
    const mailboxState = useSelector((state: RootState) => state.mailbox);
    const { checkedRecipients } = mailboxState;

    const dispatch = useDispatch();

    const styleWomanListButton = `popup_write_letter_user online ${isOpenBlock ? 'active' : ''}`;
    const styleWomanListBlock = `popup_write_letter_users_hidden ${isOpenBlock ? 'active' : ''}`;

    const toggleBlock = () => setIsOpenBlock(!isOpenBlock);

    useEffect(() => {
        return function cleanup() {
            dispatch(currentChatMailboxAction.removeDraftID());
            // dispatch(mailboxAction.removeAllRecipient());
        };
    }, []);

    return (
        <div className="popup_write_letter" data-testid="write-letter-modal">
            <div className="popup_write_letter_head">
                {openComposeModal ? (
                    <div
                        className="c3l_letters_list_write_go_to_chat"
                        onClick={openComposeModal}
                        data-testid="add-more-btn"
                    >
                        <IconCirclePlus />
                        <span>
                            {
                                SEX.SEX_TEXT[userState?.info?.sex_text as 'FEMALE_SEX' | 'MALE_SEX']
                                    .LETTER_WRITE_MODAL_ADD_BTN
                            }
                        </span>
                    </div>
                ) : (
                    <div />
                )}
                <div className="popup_write_letter_fromto" data-testid="recipient-list-btn">
                    <div
                        className={styleWomanListButton}
                        onClick={toggleBlock}
                        data-testid={`recipient-item-${checkedRecipients[0]?.external_id}`}
                    >
                        <div
                            className="popup_write_letter_user_photo"
                            data-testid="recipient-photo"
                        >
                            <img
                                src={checkedRecipients[0]?.avatar || avatar_placeholder}
                                alt="user avatar"
                            />
                        </div>
                        <div className="popup_write_letter_user_info">
                            <div
                                className="popup_write_letter_user_name"
                                data-testid="recipient-name"
                            >{`${checkedRecipients[0]?.name}, ${checkedRecipients[0]?.age}`}</div>
                            <div className="popup_write_letter_user_bottom">
                                <div
                                    className="popup_write_letter_user_to_id"
                                    data-testid="recipient-external_id"
                                >
                                    ID {checkedRecipients[0]?.public_external_id}
                                </div>
                                <div
                                    className="popup_write_letter_user_place"
                                    data-testid="recipient-city"
                                >
                                    {checkedRecipients[0]?.city ? checkedRecipients[0].city : ''}
                                </div>
                            </div>
                        </div>
                        <div className={styleWomanListBlock}>
                            {checkedRecipients?.map((item, key) => {
                                return key > 0 ? (
                                    <div
                                        className={`popup_write_letter_user ${item?.online ? 'online' : ''}`}
                                        key={key}
                                        data-testid={`recipient-item-${checkedRecipients[0]?.external_id}`}
                                    >
                                        <div
                                            className="popup_write_letter_user_photo"
                                            data-testid="recipient-photo"
                                        >
                                            <img
                                                src={item?.avatar || avatar_placeholder}
                                                alt="user avatar"
                                            />
                                        </div>
                                        <div className="popup_write_letter_user_info">
                                            <div
                                                className="popup_write_letter_user_name"
                                                data-testid="recipient-name"
                                            >{`${item?.name}, ${item?.age}`}</div>
                                            <div className="popup_write_letter_user_bottom">
                                                <div
                                                    className="popup_write_letter_user_to_id"
                                                    data-testid="recipient-external_id"
                                                >
                                                    ID {item?.public_external_id}
                                                </div>
                                                <div
                                                    className="popup_write_letter_user_place"
                                                    data-testid="recipient-city"
                                                >
                                                    {item?.city ? item.city : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={key} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <MessageForm closeWriteLetterModal={closeWriteLetterModal} />
            <div
                className="close_one_popup"
                onClick={closeWriteLetterModal}
                data-testid="close-btn"
            >
                <IconCross />
            </div>
        </div>
    );
}
