import React from 'react';
import useHandleLikeHer from '../../../hooks/likeher/useHandleLikeHer';
import { useDispatch, useSelector } from 'react-redux';
import * as SEX from '../../../constants/sex';
import { RootState } from '../../../index';
import IconArrowRight from '../../Icons/IconArrowRight';
import { isHolidayActive } from '../../../constants/holiday';
import { ICrossBannersData } from '../../../store/reducers/banners/index.interface';
import { checkNationalitySite } from '../../../services/methods';
import MarketingApi from '../../../services/api/MarketingApi';
import * as bannersAction from '../../../store/actions/banners';
import Banners from '../../../img/cross-banners';
import styles from './styles.module.scss';

export default function LikeCrossBannerNewDesign(props: ICrossBannersData) {
    const userState = useSelector((state: RootState) => state.user);
    const { target_site_id, log_id } = props;
    const { incrementSelectedIndex } = useHandleLikeHer();
    const nationality = checkNationalitySite(target_site_id);
    const dispatch = useDispatch();
    const openConfirmTermModal = () => {
        if (log_id) {
            new MarketingApi().postClickCrossBanner(log_id);
        }
        dispatch(bannersAction.setOpenConfirmTermsModal(props));
    };
    const activeHoliday = isHolidayActive();

    return (
        <div className="temp_wrap">
            <div className={`likeher_page_wrap ${activeHoliday && activeHoliday?.name}`}>
                <div className={styles.likeher_cross_banner_photos_wrapper}>
                    <div className={styles.likeher_cross_banner_photos}>
                        <div className={styles.likeher_cross_banner_photo_item}>
                            <img
                                src={Banners.images[`banner-like-${nationality}-1.png`]}
                                className={styles.likeher_cross_banner_photo}
                                alt={`${nationality} girl`}
                            />
                        </div>
                        <div className={styles.likeher_cross_banner_photo_item}>
                            <img
                                src={Banners.images[`banner-like-${nationality}-2.png`]}
                                className={styles.likeher_cross_banner_photo}
                                alt={`${nationality} girl`}
                            />
                            <div className={styles.likeher_cross_banner_content}>
                                <h2 className={styles.likeher_cross_banner_title}>
                                    Try <span>{nationality}</span> chatting
                                </h2>
                                <p className={styles.likeher_cross_banner_text}>
                                    Meet people, share moments, and enjoy conversations anywhere
                                </p>
                            </div>
                        </div>
                        <div className={styles.likeher_cross_banner_photo_item}>
                            <img
                                src={Banners.images[`banner-like-${nationality}-3.png`]}
                                className={styles.likeher_cross_banner_photo}
                                alt={`${nationality} girl`}
                            />
                        </div>
                    </div>
                </div>
                <div className="likeher_page_middle"></div>
                <div className="likeher_page_bottom cross_banner">
                    <button
                        type="button"
                        className="likeher_page_next_btn likeher_page_control_btn"
                        onClick={incrementSelectedIndex}
                    >
                        <IconArrowRight />
                        <span>
                            {
                                SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
                                    .LIKEHER_NEXT_BTN
                            }
                        </span>
                    </button>
                    <button
                        type="button"
                        className="likeher_page_like_btn likeher_page_control_btn cross_banner"
                        onClick={openConfirmTermModal}
                    >
                        <span>Try {nationality} Chatting</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
