import { ConnectionActionTypes } from '../../constants/connection';
import { ConnectionActions, IConnectionState } from './index.interface';

const initState = {
    status: null,
};

export default function (
    state: IConnectionState = initState,
    action: ConnectionActions,
): IConnectionState {
    switch (action.type) {
        case ConnectionActionTypes.SET_CONNECTION:
            return {
                ...state,
                ...{
                    status: action.status,
                },
            };

        default:
            return state;
    }
}
