import { useDispatch, useSelector } from 'react-redux';
import MarketingApi from '../../services/api/MarketingApi';
import * as bannersAction from '../../store/actions/banners';
import * as termsAction from '../../store/actions/terms';
import { RootState } from '../../index';

export default function useInitCrossBanner() {
    const userState = useSelector((state: RootState) => state.user);
    const bannersState = useSelector((state: RootState) => state.banners);
    const dispatch = useDispatch();

    const getCrossBannerInfo = () => {
        new MarketingApi().getCrossBannerData().then((res) => {
            if (res?.status && res?.response) {
                dispatch(bannersAction.initCrossBannerData(res?.response));
            }
        });
    };

    const setActiveCrossBanners = (status = false, startTime: string, logId: number) => {
        const bannerLogIdStore = +(sessionStorage?.getItem('banner_log_id') || 0);

        if (logId && bannerLogIdStore !== logId) {
            sessionStorage.setItem('banner_log_id', (+logId)?.toString());
            dispatch(bannersAction.setActiveStatusCrossBanners(status, startTime));
            new MarketingApi().postViewCrossBanner(logId);
        }
    };

    const clearCrossBannersData = () => {
        dispatch(bannersAction.clearCrossBanners());

        getCrossBannerInfo();
    };

    const openConfirmTermModal = () => {
        if (userState?.info?.ghost) return;

        if (bannersState?.crossBannersData?.log_id) {
            new MarketingApi().postClickCrossBanner(bannersState?.crossBannersData?.log_id);
        }
        dispatch(bannersAction.setOpenConfirmTermsModal(null));
    };

    const closeConfirmTermModal = (setting = '') => {
        dispatch(bannersAction.setCloseConfirmTermsModal());
        if (setting === 'clearData') clearCrossBannersData();
    };

    const openTermModal = (pageName: string) => dispatch(termsAction.openModel(pageName));

    return {
        getCrossBannerInfo,
        setActiveCrossBanners,
        clearCrossBannersData,
        openConfirmTermModal,
        closeConfirmTermModal,
        openTermModal,
    };
}
