import { useDispatch, useSelector } from 'react-redux';
import * as MENU from '../../constants/menu';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import * as modalsAction from '../../store/actions/modals';
import ChatsApi from '../../services/api/ChatsApi';
import { closeMobChatList } from '../../services/dom';
import useTransition from '../transition/useTransition';
import { switchChatList } from '../../services/methods';
import moment from 'moment';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import React from 'react';
import { IStoreChatListItemModel } from '../../models/chat/index.interface';

export default function useHandleChatListItem(chatListRef?: React.RefObject<HTMLDivElement>) {
    const dispatch = useDispatch();
    const transition = useTransition();

    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const chatsState = useSelector((state: RootState) => state.chats);

    /**
     * Method to delete chat.
     * @param event (Object) - Dom event
     * @param chatInfo
     */
    const onDeleteChat = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        chatInfo: IStoreChatListItemModel,
    ) => {
        if (!userState?.info?.external_id) return;

        try {
            event.stopPropagation();
            new ChatsApi()
                .postHideChat(userState?.info?.external_id, chatInfo?.chat_uid)
                .then((res) => {
                    if (res?.status) {
                        dispatch(currentChatAction.removeMainChatInfo()); // Close current chat.
                        dispatch(chatsAction.removeFromChatList(chatInfo?.chat_uid));
                        if (currentChatState?.info?.chat_uid) {
                            transition.transitionWithHistoryPush(
                                'chat',
                                `/${menuState?.sub?.length > 0 ? menuState?.sub : menuState?.main}`,
                            );
                        }
                    }
                });
            if (chatsState.chatList?.length === 10) {
                dispatch(
                    chatsAction.getChatListByUserId(
                        userState?.info?.external_id,
                        !!chatInfo?.online,
                        '',
                        switchChatList(menuState?.sub),
                    ),
                );
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    /**
     * Method for choosing chat and going to the current chat room.
     * @param chatInfo
     */
    const onActiveChat = (chatInfo: IStoreChatListItemModel) => {
        if (!chatInfo?.chat_uid) return;

        dispatch(currentChatAction.touchSwipeChatList('left'));
        closeMobChatList();

        // pzds abtest GT-10025
        if (moment(userState?.info?.created_at).isAfter('2024-05-30 00:00:00')) {
            if (chatListRef?.current) chatListRef.current.scrollIntoView();
        }

        if (menuState.main === MENU.MENU_BLOCK) {
            return transition.transitionWithOnlyHistoryPush(
                `/my-profile/block_list/${chatInfo?.chat_uid}`,
            );
        }

        return transition.transitionWithHistoryPush(
            MENU.OPEN_CHAT_FROM_CHAT_LIST,
            `/${menuState.sub.length > 0 ? menuState.sub : menuState.main}/${chatInfo?.chat_uid}`,
        );
    };

    /**
     * Method for checking whether to hide chat.
     * @return {boolean|boolean}
     * @param chatInfo
     */
    const isHideChatItem = (chatInfo: IStoreChatListItemModel) => {
        if (menuState.main === MENU.MENU_BLOCK) return false;
        return (
            (chatInfo?.last_message?.length === 0 &&
                +chatInfo?.female_favorite === 0 &&
                chatInfo?.chat_uid !== currentChatState?.info?.chat_uid) ||
            !chatInfo?.status
        );
    };

    const openWomanProfileModal = (externalID: number) => {
        if (!+externalID) return;
        dispatch(modalsAction.openWomanProfileModal(externalID));
    };

    const openWomanVideoShowModal = (externalID: number) => {
        if (!+externalID) return;
        dispatch(modalsAction.openWomanVideoShowModal(externalID));
    };

    return {
        onDeleteChat,
        onActiveChat,
        isHideChatItem,
        openWomanProfileModal,
        openWomanVideoShowModal,
    };
}
