import React from 'react';

export default function IconCircleInfo() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="#00317B"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_74)">
                <path d="M16 28.8C19.3948 28.8 22.6505 27.4514 25.051 25.051C27.4514 22.6505 28.8 19.3948 28.8 16C28.8 12.6052 27.4514 9.3495 25.051 6.94903C22.6505 4.54857 19.3948 3.2 16 3.2C12.6052 3.2 9.3495 4.54857 6.94903 6.94903C4.54857 9.3495 3.2 12.6052 3.2 16C3.2 19.3948 4.54857 22.6505 6.94903 25.051C9.3495 27.4514 12.6052 28.8 16 28.8ZM16 32C7.1632 32 0 24.8368 0 16C0 7.1632 7.1632 0 16 0C24.8368 0 32 7.1632 32 16C32 24.8368 24.8368 32 16 32ZM14.4 22.4H17.6V25.6H14.4V22.4ZM14.4 6.4H17.6V19.2H14.4V6.4Z" />
            </g>
            <defs>
                <clipPath id="clip0_1_74">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
