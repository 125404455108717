import Base from '../Base';

export default class VideoConverteApi extends Base {
    async postVideoLinkToConvert(link: string) {
        const res = await super.post(`v3/video_converter`, {
            type: 'link',
            data: link,
        });
        return res?.data;
    }
}
