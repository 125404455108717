export const READ_MAIL = 'READ_MAIL';
export const SENT_TEXT = 'SENT_TEXT';
export const SENT_IMAGE = 'SENT_IMAGE';

export const SENT_AUDIO = 'SENT_AUDIO';
export const SENT_AUDIO_NEW = 'SENT_AUDIO_NEW';

export const GET_AUDIO = 'GET_AUDIO';
export const GET_AUDIO_NEW = 'GET_AUDIO_NEW';

export const SENT_VIDEO = 'SENT_VIDEO';
export const SENT_VIDEO_NEW = 'SENT_VIDEO_NEW';

export const GET_IMAGE = 'GET_IMAGE';
export const GET_VIDEO = 'GET_VIDEO';
export const GET_VIDEO_NEW = 'GET_VIDEO_NEW';

export const SENT_WINK = 'SENT_WINK';
export const SENT_STICKER = 'SENT_STICKER';
export const SENT_VIRTUAL_GIFT = 'SENT_VIRTUAL_GIFT';
export const SENT_LIKE = 'SENT_LIKE';
export const SENT_MAIL = 'SENT_MAIL';
export const SENT_MAIL_FIRST = 'SENT_MAIL_FIRST';
export const SENT_MAIL_SECOND = 'SENT_MAIL_SECOND';
export const SENT_IMAGE_MAIL = 'SENT_IMAGE_MAIL';

export const SENT_AUDIO_MAIL = 'SENT_AUDIO_MAIL';
export const SENT_AUDIO_MAIL_NEW = 'SENT_AUDIO_MAIL_NEW';

export const SENT_VIDEO_MAIL = 'SENT_VIDEO_MAIL';
export const SENT_VIDEO_MAIL_NEW = 'SENT_VIDEO_MAIL_NEW';

export const GET_IMAGE_MAIL = 'GET_IMAGE_MAIL';

export const GET_AUDIO_MAIL = 'GET_AUDIO_MAIL';
export const GET_AUDIO_MAIL_NEW = 'GET_AUDIO_MAIL_NEW';

export const GET_VIDEO_MAIL = 'GET_VIDEO_MAIL';
export const GET_VIDEO_MAIL_NEW = 'GET_VIDEO_MAIL_NEW';

export const GET_VIDEO_SHOW = 'GET_VIDEO_SHOW';
