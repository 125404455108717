import React from 'react';
import styles from './styles.module.scss';
import SuccessSupportFeedback from './SuccessSupportFeedback';
import MixSupportFeedback from './MixSupportFeedback';
import { ISupportFeedbackProps } from './index.interface';

export default function SupportFeedback({
    isSuccessModal,
    closeSupportFeedbackModal,
    questionData,
    onSubmit,
    formData,
    handleFormData,
}: ISupportFeedbackProps) {
    return (
        <>
            <div
                className={`${styles.support_feedback_modal} ${isSuccessModal ? styles.success : ''}`}
                data-testid="support-feedback-modal"
            >
                <div className={styles.support_feedback_modal_header} />
                <div
                    className={styles.support_feedback_content}
                    data-testid="support-feedback-modal"
                >
                    <h2 className={styles.support_feedback_modal_title}>
                        {isSuccessModal ? 'Thank You!' : 'Support Feedback'}
                    </h2>
                    {isSuccessModal ? (
                        <SuccessSupportFeedback
                            closeSupportFeedbackModal={closeSupportFeedbackModal}
                        />
                    ) : (
                        <MixSupportFeedback
                            questionData={questionData}
                            onSubmit={onSubmit}
                            formData={formData}
                            handleFormData={handleFormData}
                        />
                    )}
                    <button
                        type="button"
                        className={styles.support_feedback_modal_btn_close}
                        onClick={closeSupportFeedbackModal}
                        data-testid="close-support-feedback-btn"
                    />
                </div>
            </div>
        </>
    );
}
