export const WHO_LIKED_YOU = 'Who liked you';
export const GEO_TIER1 = [
    'US',
    'GB',
    'CA',
    'FR',
    'DE',
    'JP',
    'KR',
    'AU',
    'NZ',
    'CH',
    'NL',
    'SE',
    'DK',
    'NO',
    'FI',
    'AT',
    'BE',
    'IE',
    'SG',
    'HK',
    'TW',
];
export const GEO_EVENT_PUSH = ['US', 'GB', 'CA', 'AU']; //Google Ads
export const GEO_EVENT_PUSH_V2 = ['US', 'GB', 'CA', 'AU', 'NZ']; //Google Ads
// GT-15842
export const BLOCKED_COUNTRIES_EVENT = [
    'AB', // Abkhazia
    'AF', // Afghanistan
    'DZ', // Algeria
    'AO', // Angola
    'BH', // Bahrain
    'BD', // Bangladesh
    'BB', // Barbados
    'BY', // Belarus
    'BW', // Botswana
    'BG', // Bulgaria
    'BF', // Burkina Faso
    'BI', // Burundi
    'CM', // Cameroon
    'CV', // Cape Verde
    'KY', // Cayman Islands
    'CF', // Central African Republic
    'TD', // Chad
    'CN', // China
    'UA-43', // Crimea (region code in Ukraine)
    'CI', // Côte d’Ivoire
    'CU', // Cuba
    'CD', // Democratic Republic of the Congo
    'DJ', // Djibouti
    'UA-14', // Donbas (region code in Ukraine)
    'EG', // Egypt
    'ET', // Ethiopia
    'GM', // Gambia
    'GH', // Ghana
    'GI', // Gibraltar
    'HT', // Haiti
    'HK', // Hong Kong
    'HU', // Hungary
    'IR', // Iran
    'IQ', // Iraq
    'JM', // Jamaica
    'JO', // Jordan
    'KZ', // Kazakhstan
    'KW', // Kuwait
    'LB', // Lebanon
    'LR', // Liberia
    'LY', // Libya
    'UA-09', // Luhansk (region code in Ukraine)
    'ML', // Mali
    'MD', // Moldova
    'MA', // Morocco
    'MZ', // Mozambique
    'MM', // Myanmar
    'NA', // Namibia
    'NP', // Nepal
    'NI', // Nicaragua
    'NE', // Niger
    'NG', // Nigeria
    'KP', // North Korea
    'OM', // Oman
    'PK', // Pakistan
    'PS', // Palestine
    'PA', // Panama
    'PH', // Philippines
    'QA', // Qatar
    'CG', // Republic of the Congo
    'RU', // Russia
    'RW', // Rwanda
    'SA', // Saudi Arabia
    'SN', // Senegal
    'SL', // Sierra Leone
    'SK', // Slovakia
    'SO', // Somalia
    'SS', // South Sudan
    'LK', // Sri Lanka
    'SD', // Sudan
    'SR', // Suriname
    'SY', // Syria
    'TZ', // Tanzania
    'BA', // The Balkans (Bosnia and Herzegovina as a placeholder)
    'TT', // Trinidad and Tobago
    'TN', // Tunisia
    'UG', // Uganda
    'UA', // Ukraine
    'AE', // United Arab Emirates
    'VU', // Vanuatu
    'VE', // Venezuela
    'YE', // Yemen
    'ZM', // Zambia
    'ZW', // Zimbabwe
];
