import React from 'react';

export default function IconUnread() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.2 3.49797V11.5594C5.04218 11.1206 5.81871 10.8558 6.63817 10.7878C7.74022 10.6964 8.84803 10.9669 10.2136 11.5061L10.2212 11.5091L10.2288 11.5123C11.5354 12.071 12.4635 12.4285 13.3351 12.5442C14.1039 12.6461 14.8551 12.5619 15.8 12.1936V4.09004C14.8553 4.46837 14.0093 4.6218 13.1315 4.5778C12.0868 4.52543 11.0409 4.1942 9.79413 3.72212L9.76974 3.71288L9.74621 3.7015C8.42796 3.06363 7.49821 2.76085 6.66035 2.74461C5.89893 2.72985 5.1525 2.95142 4.2 3.49797ZM4.2 12.971V17.8781C4.2 18.2216 3.93137 18.5 3.6 18.5C3.26863 18.5 3 18.2216 3 17.8781V3.23236C3 2.95095 3.1453 2.6925 3.37687 2.55237C4.54561 1.84514 5.56682 1.47929 6.68278 1.50092C7.77307 1.52206 8.88331 1.91292 10.23 2.56287C11.4524 3.0248 12.3454 3.29304 13.1895 3.33535C14.0185 3.37691 14.8436 3.20152 15.9168 2.68737C16.4116 2.45035 17 2.81517 17 3.4034V12.5042C17 12.8229 16.814 13.1097 16.5315 13.2311C15.2966 13.762 14.2652 13.9216 13.1828 13.778C12.133 13.6387 11.0675 13.2164 9.77884 12.6655C8.48176 12.154 7.57287 11.9582 6.73392 12.0278C5.96867 12.0913 5.20172 12.3807 4.2 12.971Z"
            />
        </svg>
    );
}
