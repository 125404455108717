import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import HeaderProfile from '../ProfileHeader';
import NotifyEmailConfirm from '../../Banner';
import GetCreditsButton from '../../common/Buttons/GetCreditsButton';
import CreditCardsList from '../../common/Modals/ManageCredit/CreditCardsList';
import AddNewCreditCard from '../../common/Modals/ManageCredit/AddNewCreditCard';
import AddNewCardButton from '../../common/Buttons/AddNewCardButton';
import AutoTopUpSuccessChangeModal from '../../common/Modals/AutoTopUpSuccessChangeModal';
import AutoTopUpUnsubscribeModal from '../../common/Modals/AutoTopUpUnsubscribeModal';
import '../../../styles/manage-credit-card/style.scss';
import '../../../styles/man-profile/main.scss';
import '../../../styles/man-profile/media.scss';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import '../../../styles/pruchase-history/main.scss';
import '../../../styles/pruchase-history/purchase.scss';
import '../../../styles/pruchase-history/media.scss';
import '../../../styles/columns/column.scss';
import styles from './styles.module.scss';
import ProfileApi from '../../../services/api/ProfileApi';
import AccountApi from '../../../services/api/Account';
import * as userAction from '../../../store/actions/user';
import { currencyPriceString } from '../../../services/methods';
import * as modalsActions from '../../../store/actions/modals';
import * as PURCHASE from '../../../constants/purchase';
import useTransition from '../../../hooks/transition/useTransition';
import * as purchaseActions from '../../../store/actions/purchase';
import useInitSubscribe from '../../../hooks/purchase/subscribe/useInitSubscribe';
import * as alertAction from '../../../store/actions/alert';
import * as ALERT from '../../../constants/alert';
import { PAYMENT_METHOD_ROUTING } from '../../../constants/purchase';
import useHandleRouting from '../../../hooks/purchase/useHandleRouting';
import { sendErrToSentry } from '../../../services/sentry';
import { RootState } from '../../../index';
import { IPurchaseTokenItem } from '../../../store/reducers/purchase/index.interface';
import { IManProfilePurchaseHistory } from '../../../models/profile/ManProfile.interface';
import credit_card_image from '../../../img/manage-credit-card-modal/credit-card-img.svg';
import IconCheckedCircle from '../../Icons/IconCheckedCircle';
import IconPolicy from '../../Icons/IconPolicy';

export default function ProfilePurchaseHistory() {
    const dispatch = useDispatch();
    const transition = useTransition();

    const userState = useSelector((state: RootState) => state.user);
    const modalsState = useSelector((state: RootState) => state.modals);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const [history, setHistory] = useState<IManProfilePurchaseHistory>();
    const [fetchTokenList, setFetchTokenList] = useState<IPurchaseTokenItem[] | null>(null);
    const [isActiveCreditCardListModal, setIsActiveCreditCardListModal] = useState(false);
    const [isActiveAddCreditCardModal, setIsActiveAddCreditCardModal] = useState(false);
    const [frame, setFrame] = useState('');
    const [frameData, setFrameData] = useState();

    const initSubscribe = useInitSubscribe();
    const handleRouting = useHandleRouting();

    const closeAddNewCreditCardModal = () => {
        if (isActiveAddCreditCardModal) {
            dispatch(purchaseActions.getPurchaseCreditsList());
            dispatch(modalsActions.closeAddCreditCard());
        }
        closeCreditCardListModal();
        setIsActiveAddCreditCardModal(false);
    };

    const openCreditCardListModal = (paymentMethod: string) => {
        if (getFilterTokenListByPaymentMethod(paymentMethod)?.length) {
            setFetchTokenList(getFilterTokenListByPaymentMethod(paymentMethod));
            setIsActiveCreditCardListModal(true);
        }
    };

    const openModalPurchaseCredit = () => {
        dispatch(userAction.setCredit(true));
    };

    const closeCreditCardListModal = () => {
        setIsActiveCreditCardListModal(false);
    };

    const isAvailablePaymentMethod = (paymentMethod: string) => {
        if (handleRouting.isRouting()) return false;
        return !!purchaseState?.paymentMethodList?.some(
            (item) => !!item?.status && item?.method === paymentMethod,
        );
    };

    const getFilterTokenListByPaymentMethod = (paymentMethod: string) => {
        return purchaseState?.tokenList?.filter((item) => item?.payment_method === paymentMethod);
    };

    const getDefaultCardByPaymentMethod = (paymentMethod: string) => {
        return purchaseState?.tokenList?.find(
            (item) => +item?.default_card === 1 && item?.payment_method === paymentMethod,
        );
    };

    const getCardListByPaymentMethod = (paymentMethod: string) => {
        return purchaseState?.tokenList?.filter((item) => item?.payment_method === paymentMethod);
    };

    const openRemoveCreditCardAlert = (selectedCard: IPurchaseTokenItem) => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.DELETE_CREDIT_CARD,
                message: selectedCard?.payment_method,
                successCallback: () => removeCreditCard(selectedCard),
            }),
        );
    };

    const removeCreditCard = async (selectedCard: IPurchaseTokenItem) => {
        if (
            purchaseState?.activeSubscribePaymentMethod?.length &&
            purchaseState?.activeSubscribePaymentMethod === selectedCard?.payment_method
        ) {
            await initSubscribe?.switchCancelSubscribe(purchaseState?.activeSubscribePaymentMethod);
        }

        await new AccountApi().postDeleteToken(selectedCard?.id).then((res) => {
            if (res?.status) {
                closeCreditCardListModal();
                setFetchTokenList(
                    (res?.list as IPurchaseTokenItem[])?.filter(
                        (item) => item?.payment_method === selectedCard?.payment_method,
                    ),
                );
                dispatch(purchaseActions.setTokenList(res?.list));
                initSubscribe.switchGetSubscribeInfo(selectedCard?.payment_method);
            }
        });
    };

    const createToken = (paymentMethod: string) => {
        closeCreditCardListModal();
        switch (paymentMethod) {
            case PURCHASE.PAYMENT_METHOD_PASTABANK:
                new AccountApi().postCreateToken().then((res) => {
                    setFrame(res?.result?.action);
                    setFrameData(res?.result?.fields);
                    dispatch(modalsActions.openAddCreditCard());
                });

                break;

            case PURCHASE.PAYMENT_METHOD_CARDPAY:
                new AccountApi()
                    .postDefaultToken(0, PURCHASE.PAYMENT_METHOD_CARDPAY)
                    .then((res) => {
                        if (res?.status) {
                            transition.addTail(
                                `?cardpay=true&credits_amount=${PURCHASE.DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY ?? purchaseState?.purchaseList.find((item) => +item?.id === purchaseState?.mostPopularPackageID)?.credits}`,
                            );
                            openModalPurchaseCredit();
                        }
                    });
                break;

            default:
                break;
        }
    };

    const selectDefaultCard = (selectedCard: IPurchaseTokenItem, isDefault: boolean) => {
        if (!isDefault) {
            new AccountApi()
                .postDefaultToken(selectedCard?.id, selectedCard?.payment_method)
                .then((res) => {
                    if (res?.status) {
                        setFetchTokenList(
                            (res?.list as IPurchaseTokenItem[])?.filter(
                                (item) => item?.payment_method === selectedCard?.payment_method,
                            ),
                        );
                        dispatch(purchaseActions.setTokenList(res?.list));
                    }
                });
        }
    };

    const PURCHASE_HISTORY = () => {
        try {
            if (history && Object.values(history)?.length) {
                return Object.values(history).map((monthItem, monthKey) => (
                    <div className={styles.credits_table_list} key={monthKey}>
                        <div className={styles.credits_table_date_row}>
                            {Object.keys(history)[monthKey]}
                        </div>
                        {Object.values(monthItem).map((elem, key2) => (
                            <div
                                className={
                                    key2 % 2 ? styles.row_color_white : styles.row_color_blue
                                }
                                key={key2}
                            >
                                {elem.map((elem2, key3) => {
                                    const isDate = elem2?.date_added?.includes('T') && key3 === 0;
                                    const date = isDate ? elem2?.date_added?.split('T')[0] : '';
                                    const dateTitle = isDate ? moment(date).format('D.M.Y') : '';

                                    return (
                                        <div className={styles.credits_table_row} key={key3}>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.date}`}
                                            >
                                                {dateTitle}
                                            </div>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.credits}`}
                                            >
                                                {+elem2?.amount || ''} credits
                                            </div>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.card}`}
                                            >
                                                {elem2?.card}
                                            </div>
                                            <div
                                                className={`${styles.credits_table_list_col} ${styles.price}`}
                                            >
                                                {currencyPriceString(
                                                    elem2?.currency,
                                                    +elem2?.price,
                                                ) || ''}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                ));
            }
        } catch (error) {
            sendErrToSentry(error as Error);
            return <div />;
        }
    };

    useEffect(() => {
        if (!modalsState.showAddCreditCardModal && frame?.length) {
            closeAddNewCreditCardModal();
        }
    }, [modalsState.showAddCreditCardModal]);

    useEffect(() => {
        new ProfileApi()
            .getProfileInfo()
            .then((response) => setHistory(response?.purchase_history));
    }, [userState.balance]);

    useEffect(() => {
        dispatch(purchaseActions.getPurchaseCreditsList());
    }, [userState]);

    useEffect(() => {
        if (purchaseState?.tokenList?.length && !isActiveCreditCardListModal) {
            setFetchTokenList(purchaseState?.tokenList);
        }
    }, [purchaseState.tokenList]);

    useEffect(() => {
        if (!document.querySelector('.popup_get_credits_wrap')) {
            setIsActiveAddCreditCardModal(modalsState.showAddCreditCardModal);
        }
    }, [modalsState.showAddCreditCardModal]);

    return (
        <>
            <div className="column-3 profile_man mob_hor_scroll credits_page_column">
                <>
                    <NotifyEmailConfirm />
                    <HeaderProfile />
                    <div className={styles.credits_page_scroll}>
                        <div className="c3_profile_credits_wrap">
                            {isAvailablePaymentMethod(PURCHASE.PAYMENT_METHOD_PASTABANK) && (
                                <div className="c3_profile_credits_your_cards">
                                    <div className="c3_profile_credits_your_cards_title_row">
                                        <p className="c3_profile_credits_your_cards_title">
                                            LPB Bank credit cards
                                        </p>
                                        {getDefaultCardByPaymentMethod(
                                            PURCHASE.PAYMENT_METHOD_PASTABANK,
                                        )?.number && (
                                            <AddNewCardButton
                                                onClickAction={() =>
                                                    createToken(PURCHASE.PAYMENT_METHOD_PASTABANK)
                                                }
                                            />
                                        )}
                                    </div>
                                    {getDefaultCardByPaymentMethod(
                                        PURCHASE.PAYMENT_METHOD_PASTABANK,
                                    )?.number ? (
                                        <div className="c3_profile_credits_your_cards_item">
                                            <img
                                                className="c3_profile_credits_your_cards_item_img"
                                                src={credit_card_image}
                                                alt="credit card image"
                                            />
                                            <div className="c3_profile_credits_your_cards_item_card_num">
                                                {
                                                    getDefaultCardByPaymentMethod(
                                                        PURCHASE.PAYMENT_METHOD_PASTABANK,
                                                    )?.number
                                                }
                                            </div>
                                            <div className="c3_profile_credits_your_cards_item_current_card">
                                                <IconCheckedCircle />
                                                <span>Current credit card</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="c3_profile_credits_your_cards_empty_row">
                                            <div className="c3_profile_credits_your_cards_empty_card">
                                                <img
                                                    src={credit_card_image}
                                                    alt="credit card image"
                                                />
                                                <span>No credit cards added</span>
                                            </div>
                                            <AddNewCardButton
                                                onClickAction={() =>
                                                    createToken(PURCHASE.PAYMENT_METHOD_PASTABANK)
                                                }
                                            />
                                        </div>
                                    )}
                                    {!!getCardListByPaymentMethod(PURCHASE.PAYMENT_METHOD_PASTABANK)
                                        ?.length && (
                                        <div
                                            className="c3_profile_credits_your_cards_manage_link"
                                            onClick={() =>
                                                openCreditCardListModal(
                                                    PURCHASE.PAYMENT_METHOD_PASTABANK,
                                                )
                                            }
                                        >
                                            <span>Manage your credit cards</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="c3_profile_credits_balance">
                                <div className="c3_profile_credits_balance_img"></div>
                                <div className="c3_profile_credits_balance_middle">
                                    <span className="c3_profile_credits_balance_middle_text">
                                        Your balance
                                    </span>
                                    <div className="c3_profile_credits_balance_middle_row">
                                        <span className="c3_profile_credits_balance_middle_value">
                                            {userState?.balance ?? 0}
                                        </span>
                                        <span className="c3_profile_credits_balance_middle_value_text">
                                            credits
                                        </span>
                                    </div>
                                </div>
                                <GetCreditsButton />
                            </div>
                        </div>
                        {purchaseState?.activeSubscribePaymentMethod &&
                            purchaseState?.activeSubscribeData && (
                                <div className={styles.auto_top_up_wrap}>
                                    <div className={styles.auto_top_up_left}>
                                        <div className={styles.top_up_title}>
                                            <IconPolicy />
                                            <span>
                                                Auto top-up for{' '}
                                                {purchaseState?.activeSubscribeData?.credits ?? 0}{' '}
                                                credits is active
                                            </span>
                                        </div>
                                        {purchaseState?.activeSubscribePaymentMethod !==
                                            PAYMENT_METHOD_ROUTING && (
                                            <div
                                                className={styles.top_up_button}
                                                onClick={
                                                    initSubscribe.openChangeAmountSubscribeModal
                                                }
                                            >
                                                Change the amount of credits
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.auto_top_up_subscribe}>
                                        <p className={styles.top_up_subscribe_title}>
                                            Click to Unsubscribe
                                        </p>
                                        <div className={styles.top_up_subscribe_switch}>
                                            <input
                                                type="checkbox"
                                                id="top_up_subscribe"
                                                checked={
                                                    !!(
                                                        purchaseState?.activeSubscribePaymentMethod &&
                                                        purchaseState?.activeSubscribeData
                                                    )
                                                }
                                                onChange={() =>
                                                    !!(
                                                        purchaseState?.activeSubscribePaymentMethod &&
                                                        purchaseState?.activeSubscribeData
                                                    ) && initSubscribe?.openUnsubscribeModal()
                                                }
                                            />
                                            <label htmlFor="top_up_subscribe" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        <div className={styles.credits_table_wrap}>
                            <div className={styles.credits_table_title}>Purchase history</div>
                            <div className={styles.credits_table_mob_hor}>
                                <div className={styles.credits_table_head}>
                                    <div
                                        className={`${styles.credits_table_head_item} ${styles.date}`}
                                    >
                                        Date
                                    </div>
                                    <div
                                        className={`${styles.credits_table_head_item} ${styles.credits}`}
                                    >
                                        Credit amount
                                    </div>
                                    <div
                                        className={`${styles.credits_table_head_item} ${styles.card}`}
                                    >
                                        Card
                                    </div>
                                    <div
                                        className={`${styles.credits_table_head_item} ${styles.price}`}
                                    >
                                        Price
                                    </div>
                                </div>
                                <div className={styles.credits_table_list_wrap}>
                                    {history ? PURCHASE_HISTORY() : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>

            {!!(
                purchaseState?.activeSubscribeData && purchaseState?.prevSubscriptionCreditPack
            ) && (
                <AutoTopUpSuccessChangeModal
                    isOpenSuccessChangeSubscribeModal={
                        purchaseState?.isOpenSuccessChangeSubscribeModal
                    }
                    closeSuccessChangeSubscribeModal={() =>
                        dispatch(purchaseActions.setSuccessChangeSubscribeModal(false))
                    }
                    activeSubscribeData={purchaseState?.activeSubscribeData}
                    prevSubscriptionCreditPack={purchaseState?.prevSubscriptionCreditPack}
                />
            )}

            <AutoTopUpUnsubscribeModal
                isOpenUnsubscribeModal={initSubscribe?.isOpenUnsubscribeModal}
                closeUnsubscribeModal={initSubscribe?.closeUnsubscribeModal}
                activeSubscribeData={purchaseState?.activeSubscribeData}
                activeSubscribePaymentMethod={purchaseState?.activeSubscribePaymentMethod}
                switchCancelSubscribe={initSubscribe?.switchCancelSubscribe}
            />

            <Modal
                style={modalStyleProps()}
                isOpen={isActiveCreditCardListModal && fetchTokenList?.length}
                onRequestClose={closeCreditCardListModal}
                ariaHideApp={false}
            >
                <CreditCardsList
                    hideAddNewCardButton={true}
                    fetchTokenList={fetchTokenList}
                    openRemoveCreditCardAlert={openRemoveCreditCardAlert}
                    selectDefaultCard={selectDefaultCard}
                    closeModal={closeCreditCardListModal}
                />
            </Modal>
            <Modal
                style={modalStyleProps()}
                isOpen={isActiveAddCreditCardModal}
                onRequestClose={closeAddNewCreditCardModal}
                ariaHideApp={false}
            >
                <AddNewCreditCard
                    frame={frame}
                    frameData={frameData}
                    closeModal={closeAddNewCreditCardModal}
                />
            </Modal>
        </>
    );
}
