import Modal from 'react-modal';
import React from 'react';

import styles from './styles.module.scss';

import modalStyleProps from '../../../../styles/react-modal/modal.style';
import NewRequestContactModal from '../../../Requests/NewRequestContactModal';
import { IContactRequestModalProps } from './index.interface';

export default function ContactRequestModal({
    showContactRequestModal,
    closeContactRequestModal,
    profile,
}: IContactRequestModalProps) {
    return (
        <Modal
            overlayClassName={styles.ReactModal__Overlay}
            style={modalStyleProps()}
            isOpen={showContactRequestModal}
            onRequestClose={closeContactRequestModal}
            ariaHideApp={false}
        >
            <NewRequestContactModal
                profile={profile}
                closeContactRequestModal={closeContactRequestModal}
            />
        </Modal>
    );
}
