import React from 'react';
import styles from './styles.module.scss';
import { getReadableFileSizeString } from '../../../../../services/methods';
import * as SUPPORT from '../../../../../constants/support';
import { ISupportProps } from './index.interface';
import IconCross from '../../../../Icons/IconCross';
import IconAttachment from '../../../../../templates/sendberly/Icons/IconAttachment';
import * as LENGTH from '../../../../../constants/length';

export default function Support({
    delUploadedFile,
    onSubmitSupport,
    handleInput,
    formData,
    closeSupportModal,
    type = '',
}: ISupportProps) {
    const getFileNameExtension = (filename: string) => filename.slice(filename.lastIndexOf('.'));
    const getfileName = (filename: string) => filename.substring(0, filename.lastIndexOf('.'));
    return (
        <div className={styles.popup_support_wrap}>
            <h2 className={styles.popup_title}>Message to Support</h2>
            <p className={styles.popup_text}>Please, describe your problem for us</p>
            <div className={styles.popup_fields}>
                {type === SUPPORT.MODAL_TYPE_HELP && (
                    <>
                        <input
                            className={styles.popup_support_input_text}
                            type="email"
                            name="email"
                            required
                            placeholder="E-mail"
                            value={formData.email}
                            onChange={handleInput}
                        />
                        <input
                            className={styles.popup_support_input_text}
                            type="text"
                            name="name"
                            required
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleInput}
                        />
                    </>
                )}
                <div className={styles.textarea_container}>
                    <textarea
                        className={styles.popup_support_textarea}
                        placeholder="Your message to support"
                        name="reason"
                        maxLength={LENGTH.SUPPORT_TEXTAREA_MAX_LENGTH}
                        value={formData.reason}
                        onChange={handleInput}
                    />
                    <div
                        className={`${styles.textarea_counter} ${formData.reason?.length >= LENGTH.SUPPORT_TEXTAREA_MAX_LENGTH ? styles.invalid : ''}`}
                    >
                        <span>{formData.reason?.length || `0`}</span>/
                        {LENGTH.SUPPORT_TEXTAREA_MAX_LENGTH}
                    </div>
                </div>

                {formData.file && formData.file.name ? (
                    <div className={styles.file_uploaded}>
                        <p className={styles.file_uploaded_text}>File uploaded</p>
                        <div className={styles.file_uploaded_name}>
                            <span className={styles.file_uploaded_name_text}>
                                {getfileName(formData.file.name)}
                            </span>
                            <span>{getFileNameExtension(formData.file.name)}</span>
                        </div>
                        <p className={styles.file_uploaded_size}>
                            {`(${getReadableFileSizeString(formData.file.size)})`}
                        </p>
                        <button
                            type="button"
                            className={styles.file_uploaded_button_remove}
                            onClick={delUploadedFile}
                            title="Remove"
                        />
                    </div>
                ) : (
                    <React.Fragment>
                        <input
                            type="file"
                            id="popup_support_file_input"
                            className={styles.popup_support_file_input}
                            onChange={handleInput}
                        />
                        <label
                            htmlFor="popup_support_file_input"
                            className={styles.popup_support_file_input_label}
                        >
                            <IconAttachment />
                            <span>Choose your file, please</span>
                        </label>
                    </React.Fragment>
                )}
                {type === SUPPORT.MODAL_TYPE_DEFAULT && (
                    <p className={styles.popup_support_send_info_text}>
                        You will receive a message from our moderation team via e-mail you mentioned
                        while registering
                    </p>
                )}
                <button
                    type="button"
                    className={styles.popup_button_send}
                    onClick={onSubmitSupport}
                >
                    <span className={styles.popup_button_send_text}>Send</span>
                </button>
            </div>
            <button
                type="button"
                className={styles.popup_button_close}
                onClick={closeSupportModal}
                title="Close"
            >
                <IconCross />
            </button>
        </div>
    );
}
