import {
    closeFilterSearch,
    closeMobChatList,
    closeMobileMenu,
    closePanel,
    openNotifyPanel,
} from '../../services/dom';
import * as modalsAction from '../../store/actions/modals';
import * as LENGTH from '../../constants/length';
import * as MENU from '../../constants/menu';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function useTouchNotification() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [touchStart, setTouchStart] = useState(0);
    const [isSwipeFromRightSide, setIsSwipeFromRightSide] = useState(false);
    const [isActiveSideMenu, setIsActiveSideMenu] = useState(false);

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        if (window.innerWidth < 1000) {
            const searchFilterBlock = document.querySelector('.mob_head_nav_btn.active');

            if (searchFilterBlock) setIsActiveSideMenu(true);

            if (window.innerWidth - LENGTH.WIDTH_RIGHT_SIDE <= event?.targetTouches?.[0]?.clientX) {
                setIsSwipeFromRightSide(true);
            } else {
                setIsSwipeFromRightSide(false);
            }

            setTouchStart(event?.targetTouches?.[0]?.clientX);
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        if (window.innerWidth < 1000) {
            if (touchStart - 150 > event?.targetTouches?.[0]?.clientX) {
                if (isActiveSideMenu) {
                    if (!MENU.CHAT_PAGES.some((item) => location?.pathname.includes(item))) {
                        closeMobChatList();
                        closeMobileMenu();
                        closeFilterSearch();
                        dispatch(modalsAction.setActiveSubMenu());
                    }
                    setIsActiveSideMenu(false);
                    setTouchStart(0);
                } else {
                    if (isSwipeFromRightSide) openNotifyPanel();
                }
            }
            if (touchStart + 150 < event?.targetTouches?.[0]?.clientX) {
                closePanel();
            }
        }
    };

    return {
        handleTouchStart,
        handleTouchMove,
    };
}
