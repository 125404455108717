import React from 'react';

export default function IconCrownVip() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0.185 0 23.815 21.36"
            fill="#FEB930"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 4.154 19.167 C 6.564 20.399 9.259 21.048 11.95 21.048 C 14.642 21.048 17.339 20.398 19.746 19.166 C 20.231 18.919 20.661 18.555 20.99 18.113 C 21.319 17.674 21.549 17.153 21.656 16.61 L 23.551 6.917 C 23.618 6.575 23.469 6.228 23.176 6.045 C 22.886 5.865 22.516 5.889 22.251 6.107 L 20.839 7.258 C 20.445 7.582 19.975 7.819 19.482 7.942 C 18.989 8.066 18.467 8.078 17.968 7.978 C 17.472 7.878 16.992 7.663 16.582 7.358 C 16.172 7.053 15.824 6.651 15.577 6.198 L 12.66 0.824 C 12.516 0.561 12.245 0.4 11.951 0.4 C 11.657 0.4 11.385 0.561 11.242 0.824 L 8.324 6.197 C 8.078 6.651 7.732 7.053 7.321 7.358 C 6.911 7.663 6.429 7.877 5.932 7.978 C 5.436 8.078 4.911 8.066 4.419 7.943 C 3.925 7.819 3.457 7.582 3.06 7.258 L 1.652 6.107 C 1.385 5.889 1.015 5.864 0.724 6.045 C 0.434 6.228 0.284 6.575 0.35 6.917 L 2.247 16.61 C 2.353 17.153 2.583 17.674 2.912 18.114 C 3.24 18.555 3.671 18.92 4.154 19.167 Z M 3.836 16.285 L 2.371 8.798 C 2.877 9.146 3.444 9.406 4.034 9.554 C 4.754 9.733 5.518 9.752 6.246 9.604 C 6.974 9.458 7.675 9.145 8.275 8.699 C 8.875 8.252 9.38 7.666 9.741 7.004 L 11.95 2.935 L 14.161 7.004 C 14.52 7.666 15.029 8.252 15.628 8.698 C 16.227 9.145 16.928 9.458 17.656 9.603 C 18.384 9.751 19.148 9.732 19.868 9.553 C 20.457 9.404 21.026 9.145 21.53 8.796 L 20.066 16.285 C 20.008 16.581 19.882 16.867 19.702 17.107 C 19.522 17.349 19.287 17.547 19.022 17.683 C 16.836 18.8 14.392 19.391 11.95 19.391 C 9.509 19.391 7.064 18.801 4.881 17.683 C 4.614 17.547 4.379 17.349 4.199 17.108 C 4.019 16.867 3.894 16.581 3.836 16.285 Z M 7.999 13.753 C 7.601 13.544 7.112 13.704 6.909 14.113 C 6.704 14.519 6.861 15.019 7.259 15.228 C 10.297 16.823 13.606 16.823 16.644 15.228 C 17.041 15.019 17.198 14.519 16.994 14.113 C 16.789 13.704 16.301 13.544 15.901 13.753 C 13.33 15.104 10.571 15.104 7.999 13.753 Z"
            />
        </svg>
    );
}
