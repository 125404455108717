import React from 'react';
import Form from '../../../../Auth/AuthRegistration/Form';
import * as DICTIONARY_MAIN_PAGE from '../../../../../constants/dictionary/main-page';
import { IForm } from '../../../../Auth/index.interface';
import IconCross from '../../../../Icons/IconCross';

export default function Register({
    openTermModal,
    formDataRegister,
    setFormDataRegister,
    onRegister,
    closeRegisterModal,
    errorFields,
    filterErrorFields,
    openSignInBlock,
    modalIsActiveRegister,
    hasCaptcha,
    recaptchaRef,
    onBirthdayChange,
}: IForm) {
    return (
        <div className="popup_main_reg">
            <div className="popup_main_reg_wrap">
                <div className="main_registration_form_wrap">
                    <div className="main_registration_form_title" data-testid="register-form-title">
                        {DICTIONARY_MAIN_PAGE.REGISTRATION_FORM_TITLE}
                    </div>
                    <Form
                        openTermModal={openTermModal}
                        formDataRegister={formDataRegister}
                        setFormDataRegister={setFormDataRegister}
                        onRegister={onRegister}
                        errorFields={errorFields}
                        filterErrorFields={filterErrorFields}
                        openSignInBlock={openSignInBlock}
                        closeRegisterModal={closeRegisterModal}
                        modalIsActiveRegister={modalIsActiveRegister}
                        hasCaptcha={hasCaptcha}
                        recaptchaRef={recaptchaRef}
                        onBirthdayChange={onBirthdayChange}
                    />
                </div>
                <div
                    className="popup_main_reg_close"
                    data-testid="button-close-register-modal"
                    onClick={closeRegisterModal}
                >
                    <IconCross />
                </div>
            </div>
        </div>
    );
}
