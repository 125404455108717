import { RefObject, useEffect } from 'react';

export default function useOutsideClickerChatMob(
    ref: RefObject<HTMLDivElement | null>,
    callback: (value: boolean) => void,
    exceptionRef: RefObject<HTMLDivElement | null> | null = null,
) {
    useEffect(() => {
        // Alert if clicked on outside of element
        function handleClickOutside(event: Event) {
            const target = event.target as HTMLElement;

            if (target?.id === 'mobile_form_send_btn') return;

            if (target?.classList?.contains('c3_textaera_wrap')) return callback(false);
            if (target?.classList?.contains('c3_write_msg')) return callback(false);
            else if (!ref?.current?.contains(target)) {
                if (exceptionRef) {
                    if (!exceptionRef?.current?.contains(target)) {
                        callback(false);
                    }
                } else {
                    callback(false);
                }
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback, exceptionRef]);
}
