import React from 'react';
import global from '../global.module.scss';
import styles from './styles.module.scss';
import * as LENGTH from '../../../../../constants/length';
import useInitSelectBlocks from '../../../../../hooks/profile/my-profile-v2/useInitSelectBlocks';
import { useSelector } from 'react-redux';
import useHandleSubInfo from '../../../../../hooks/profile/my-profile-v2/useHandleSubInfo';
import { RootState } from '../../../../../index';
import { IAboutMySelfPProps } from './index.interface';
import IconAboutBook from '../../../../Icons/IconAboutBook';
import IconInfo from '../../../../Icons/IconInfo';
import IconArrowDropdown from '../../../../Icons/IconArrowDropdown';

export default function AboutMySelf({
    aboutMySelf,
    setAboutMySelf,
    saveEdits,
}: IAboutMySelfPProps) {
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const initSelectBlocks = useInitSelectBlocks();
    const handleSubInfo = useHandleSubInfo({
        subInfo: aboutMySelf,
        setSubInfo: setAboutMySelf,
        saveEdits,
    });

    return (
        <div className={`${global.profile_div}`} data-testid="about-myself-block">
            <div className={`${global.profile_div_head}`}>
                <div className={`${global.profile_div_head_title}`}>
                    <div className={styles.profile_about_icon}>
                        <IconAboutBook />
                    </div>
                    <h2 className={`${global.profile_block_title}`}>About myself</h2>
                </div>
                <div className={`${global.profile_div_head_click_info}`}>
                    <IconInfo />
                    <span>Click a field to edit</span>
                </div>
            </div>
            <div className={global.profile_middle_item}>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Marital status</span>
                    <div
                        ref={initSelectBlocks.selectMaritalRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openMaritalBlock ? global.open : ''} 
                            ${+(aboutMySelf?.marital?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="man-marital"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenMaritalBlock(
                                    !initSelectBlocks.openMaritalBlock,
                                )
                            }
                        >
                            <span>{aboutMySelf?.marital?.marital}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.marital_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(aboutMySelf?.marital?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'marital',
                                                section: 'user_reference',
                                                payloadKey: 'marital_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenMaritalBlock(false);
                                        }}
                                    >
                                        {item?.marital}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Education</span>
                    <div
                        ref={initSelectBlocks.selectEducationRef}
                        className={`
                            ${global.profile_select_wrap}
                            ${initSelectBlocks.openEducationBlock ? global.open : ''} 
                            ${+(aboutMySelf?.education?.id || 0) > 0 ? global.selected : ''}
                        `}
                        data-testid="man-education"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenEducationBlock(
                                    !initSelectBlocks.openEducationBlock,
                                )
                            }
                        >
                            <span>{aboutMySelf?.education?.education}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.education_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(aboutMySelf?.education?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'education',
                                                section: 'user_reference',
                                                payloadKey: 'education_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenEducationBlock(false);
                                        }}
                                    >
                                        {item?.education}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <div className={`${global.profile_info_row_half}`}>
                        <span className={global.profile_label}>children</span>
                        <div
                            ref={initSelectBlocks.selectChildrenRef}
                            className={`
                                 ${global.profile_select_wrap}
                                 ${initSelectBlocks.openChildrenBlock ? global.open : ''} 
                                 ${+(aboutMySelf?.children?.id || 0) > 0 ? global.selected : ''}
                             `}
                            data-testid="man-count-children"
                        >
                            <div
                                className={`${global.profile_select_head}`}
                                onClick={() =>
                                    initSelectBlocks.setOpenChildrenBlock(
                                        !initSelectBlocks.openChildrenBlock,
                                    )
                                }
                            >
                                <span>{aboutMySelf?.children?.children}</span>
                                <div className={`${global.profile_select_head_arrow}`}>
                                    <IconArrowDropdown />
                                </div>
                            </div>
                            <div className={`${global.profile_select_list_wrap}`}>
                                <ul
                                    className={`${global.profile_select_list}`}
                                    data-testid="select-list"
                                >
                                    {referenciesState?.list?.children_list?.map((item, key) => (
                                        <li
                                            key={key}
                                            className={`${global.profile_select_list_item} ${+(aboutMySelf?.children?.id || 0) === +item?.id ? global.active : ''}`}
                                            onClick={() => {
                                                handleSubInfo.onChangeInputValue({
                                                    value: item,
                                                    field: 'children',
                                                    section: 'user_reference',
                                                    payloadKey: 'children_id',
                                                    payloadValue: item?.id,
                                                });
                                                initSelectBlocks.setOpenChildrenBlock(false);
                                            }}
                                        >
                                            {item?.children}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={`${global.profile_info_row_half}`}>
                        <span className={global.profile_label}>Want more children</span>
                        <div
                            ref={initSelectBlocks.selectMoreChildrenRef}
                            className={`
                                ${global.profile_select_wrap}
                                ${initSelectBlocks.openMoreChildrenBlock ? global.open : ''} 
                                ${+(aboutMySelf?.morechildren?.id || 0) > 0 ? global.selected : ''}
                             `}
                            data-testid="man-more-children"
                        >
                            <div
                                className={`${global.profile_select_head}`}
                                onClick={() =>
                                    initSelectBlocks.setOpenMoreChildrenBlock(
                                        !initSelectBlocks.openMoreChildrenBlock,
                                    )
                                }
                            >
                                <span>{aboutMySelf?.morechildren?.children}</span>
                                <div className={`${global.profile_select_head_arrow}`}>
                                    <IconArrowDropdown />
                                </div>
                            </div>
                            <div className={`${global.profile_select_list_wrap}`}>
                                <ul
                                    className={`${global.profile_select_list}`}
                                    data-testid="select-list"
                                >
                                    {referenciesState?.list?.morechildren_list?.map((item, key) => (
                                        <li
                                            key={key}
                                            className={`${global.profile_select_list_item} ${+(aboutMySelf?.morechildren?.id || 0) === +item?.id ? global.active : ''}`}
                                            onClick={() => {
                                                handleSubInfo.onChangeInputValue({
                                                    value: item,
                                                    field: 'morechildren',
                                                    section: 'user_reference',
                                                    payloadKey: 'morechildren_id',
                                                    payloadValue: item?.id,
                                                });
                                                initSelectBlocks.setOpenMoreChildrenBlock(false);
                                            }}
                                        >
                                            {item?.children}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Religion</span>
                    <div
                        ref={initSelectBlocks.selectReligionRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openReligionBlock ? global.open : ''} 
                            ${+(aboutMySelf?.religion?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="man-religion"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenReligionBlock(
                                    !initSelectBlocks.openReligionBlock,
                                )
                            }
                        >
                            <span>{aboutMySelf?.religion?.religion}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.religion_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(aboutMySelf?.religion?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'religion',
                                                section: 'user_reference',
                                                payloadKey: 'religion_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenReligionBlock(false);
                                        }}
                                    >
                                        {item?.religion}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Job or profession</span>
                    <div
                        ref={initSelectBlocks.selectJobRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openJobBlock ? global.open : ''} 
                            ${+(aboutMySelf?.occupation?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="man-occupation"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenJobBlock(!initSelectBlocks.openJobBlock)
                            }
                        >
                            <span>{aboutMySelf?.occupation?.occupation}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.occupation_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(aboutMySelf?.occupation?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'occupation',
                                                section: 'user_reference',
                                                payloadKey: 'occupation_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenJobBlock(false);
                                        }}
                                    >
                                        {item?.occupation}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Hobby</span>
                    <div className={global.profile_info_hobby_row}>
                        <div
                            className={`${global.profile_info_hobby_selected} ${handleSubInfo?.subInfo?.hobbies?.length ? global.selected : ''}`}
                        >
                            <div
                                className={global.profile_info_hobby_selected_names}
                                data-testid="selected-item"
                            >
                                {handleSubInfo.getHobbiesViewTitle()}
                            </div>
                            <div className={global.profile_info_hobby_selected_more}>
                                {handleSubInfo.getHobbiesViewCount()}
                            </div>
                            <div className={global.profile_info_hobby_selected_images}>
                                {handleSubInfo.getHobbiesViewIcon().map((item, key: number) => (
                                    <div
                                        key={key}
                                        className={global.profile_info_hobby_selected_img_item}
                                    >
                                        <img src={item} alt="hobby" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div
                            className={global.profile_info_hobby_add_btn}
                            onClick={() =>
                                initSelectBlocks.setOpenHobbyBlock(!initSelectBlocks.openHobbyBlock)
                            }
                            data-testid="hobby"
                        >
                            <div className={`${global.profile_info_hobby_add_btn_icon}`}></div>
                            <span>Add hobby</span>
                        </div>
                        <div
                            ref={initSelectBlocks.selectHobbyRef}
                            className={`
                                ${global.profile_info_hobby_list_wrap} 
                                ${initSelectBlocks.openHobbyBlock ? global.active : ''}
                            `}
                            data-testid="hobby-select"
                        >
                            <div className={global.profile_info_hobby_list_head}>
                                <p className={global.profile_info_hobby_list_head_title}>
                                    Choose hobby from the list below
                                </p>
                                <span className={global.profile_info_hobby_list_head_count}>
                                    {aboutMySelf?.hobbies?.length} hobbies chosen
                                </span>
                            </div>
                            <ul
                                className={global.profile_info_hobby_list_scroll}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.hobbie_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`
                                             ${global.profile_info_hobby_list_item} 
                                             ${aboutMySelf?.hobbies?.map((item) => +item?.id)?.includes(+item?.id) ? global.active : ''}
                                         `}
                                        onClick={() => handleSubInfo.handleChangeHobbies(item)}
                                    >
                                        <img src={item?.icon} alt="hobby" />
                                        <span>{item?.hobbie}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={`${global.profile_info_row} ${global.alc_smoke}`}>
                    <div className={`${global.profile_info_row_half}`}>
                        <span className={global.profile_label}>Alcohol</span>
                        <div
                            ref={initSelectBlocks.selectAlcoholRef}
                            className={`
                                ${global.profile_select_wrap} 
                                ${initSelectBlocks.openAlcoholBlock ? global.open : ''} 
                                ${+(aboutMySelf?.alcohol?.id || 0) > 0 ? global.selected : ''}
                             `}
                            data-testid="man-alcohol"
                        >
                            <div
                                className={`${global.profile_select_head}`}
                                onClick={() =>
                                    initSelectBlocks.setOpenAlcoholBlock(
                                        !initSelectBlocks.openAlcoholBlock,
                                    )
                                }
                            >
                                <span>{aboutMySelf?.alcohol?.alcohol}</span>
                                <div className={`${global.profile_select_head_arrow}`}>
                                    <IconArrowDropdown />
                                </div>
                            </div>
                            <div className={`${global.profile_select_list_wrap}`}>
                                <ul
                                    className={`${global.profile_select_list}`}
                                    data-testid="select-list"
                                >
                                    {referenciesState?.list?.alcohol_list?.map((item, key) => (
                                        <li
                                            key={key}
                                            className={`${global.profile_select_list_item} ${+(aboutMySelf?.alcohol?.id || 0) === +item?.id ? global.active : ''}`}
                                            onClick={() => {
                                                handleSubInfo.onChangeInputValue({
                                                    value: item,
                                                    field: 'alcohol',
                                                    section: 'user_reference',
                                                    payloadKey: 'alcohol_id',
                                                    payloadValue: item?.id,
                                                });
                                                initSelectBlocks.setOpenAlcoholBlock(false);
                                            }}
                                        >
                                            {item?.alcohol}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={`${global.profile_info_row_half}`}>
                        <span className={global.profile_label}>Smoking</span>
                        <div
                            ref={initSelectBlocks.selectSmokingRef}
                            className={`
                                ${global.profile_select_wrap} 
                                ${initSelectBlocks.openSmokingBlock ? global.open : ''} 
                                ${+(aboutMySelf?.smoke?.id || 0) > 0 ? global.selected : ''}
                             `}
                            data-testid="man-smoke"
                        >
                            <div
                                className={`${global.profile_select_head}`}
                                onClick={() =>
                                    initSelectBlocks.setOpenSmokingBlock(
                                        !initSelectBlocks.openSmokingBlock,
                                    )
                                }
                            >
                                <span>{aboutMySelf?.smoke?.smoke}</span>
                                <div className={`${global.profile_select_head_arrow}`}>
                                    <IconArrowDropdown />
                                </div>
                            </div>
                            <div className={`${global.profile_select_list_wrap}`}>
                                <ul
                                    className={`${global.profile_select_list}`}
                                    data-testid="select-list"
                                >
                                    {referenciesState?.list?.smoke_list?.map((item, key) => (
                                        <li
                                            key={key}
                                            className={`${global.profile_select_list_item} ${+(aboutMySelf?.smoke?.id || 0) === +item?.id ? global.active : ''}`}
                                            onClick={() => {
                                                handleSubInfo.onChangeInputValue({
                                                    value: item,
                                                    field: 'smoke',
                                                    section: 'user_reference',
                                                    payloadKey: 'smoke_id',
                                                    payloadValue: item?.id,
                                                });
                                                initSelectBlocks.setOpenSmokingBlock(false);
                                            }}
                                        >
                                            {item?.smoke}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Tell more about yourself here</span>
                    <div className={global.profile_info_about_text_wrap}>
                        <textarea
                            className={global.profile_info_about_text}
                            value={aboutMySelf?.summary}
                            data-testid="about-youlself-text"
                            maxLength={LENGTH.MAX_PROFILE_TEXTAREA}
                            onChange={(e) =>
                                handleSubInfo.onChangeInputValue({
                                    value: e?.target?.value,
                                    field: 'summary',
                                    section: 'user_reference',
                                })
                            }
                            placeholder="Write about your brightest childhood memories, dreams, goals, or life priorities... "
                        />
                        <span>{`${aboutMySelf?.summary?.length}/${LENGTH.MAX_PROFILE_TEXTAREA}`}</span>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>What do you expect from our site</span>
                    <div
                        ref={initSelectBlocks.selectReasonRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openReasonBlock ? global.open : ''} 
                            ${+(aboutMySelf?.reason?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="man-reason"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenReasonBlock(
                                    !initSelectBlocks.openReasonBlock,
                                )
                            }
                        >
                            <span>{aboutMySelf?.reason?.reason}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.reason_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(aboutMySelf?.reason?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'reason',
                                                section: 'user_reference',
                                                payloadKey: 'reason_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenReasonBlock(false);
                                        }}
                                    >
                                        {item?.reason}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
            </div>
        </div>
    );
}
