import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInitSubscribe from './subscribe/useInitSubscribe';
import * as purchaseActions from '../../store/actions/purchase';
import {
    PAYMENT_METHOD_ACQUIRING,
    PAYMENT_METHOD_ACQUIRING_APPLE_PAY,
    PAYMENT_METHOD_APPLEPAY,
    PAYMENT_METHOD_PASTABANK,
} from '../../constants/purchase';
import * as purchaseAction from '../../store/actions/purchase';
import SITE from '../../config/site';
import moment from 'moment';
import { RootState } from '../../index';

export default function useInitPaymentMethodList() {
    const dispatch = useDispatch();
    const { switchGetSubscribeInfo } = useInitSubscribe();

    const purchaseState = useSelector((state: RootState) => state.purchase);
    const userState = useSelector((state: RootState) => state.user);

    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('');

    const setActivePayment = (payment: string) => {
        dispatch(purchaseActions.clearActiveSubscribeInfo());
        if (payment) dispatch(purchaseActions.setActivePaymentMethod(payment));
    };

    const effectActivePaymentMethodList = () => {
        if (!purchaseState?.activePaymentMethod) {
            dispatch(
                purchaseActions.setActivePaymentMethod(purchaseState?.activePaymentMethodList),
            );
        }

        setDefaultPaymentMethod(purchaseState?.activePaymentMethodList);
    };

    const effectActivePaymentMethod = () => {
        dispatch(purchaseActions.clearActiveSubscribeInfo());
        dispatch(purchaseAction.setIsPastaPaymentFailed(false));

        if (
            purchaseState?.activePaymentMethod?.length &&
            !(purchaseState.activeSubscribeData && purchaseState.activeSubscribePaymentMethod)
        ) {
            switchGetSubscribeInfo(purchaseState?.activePaymentMethod);
        }
    };

    const isAvailablePaymentMethod = (paymentMethod: string): boolean => {
        // LFH: only applepay, pastabank, trust
        if (
            SITE.ID === 5 &&
            moment(userState?.info?.created_at).isAfter('2023-05-11') &&
            moment(userState?.info?.created_at).isBefore('2023-06-14 14:00:00')
        ) {
            if (
                ![
                    PAYMENT_METHOD_APPLEPAY,
                    PAYMENT_METHOD_PASTABANK,
                    PAYMENT_METHOD_ACQUIRING,
                    PAYMENT_METHOD_ACQUIRING_APPLE_PAY,
                ]?.includes(paymentMethod)
            ) {
                return false;
            }
        }

        return purchaseState?.paymentMethodList?.some(
            (item: any) => !!item?.status && item?.method === paymentMethod,
        );
    };

    const getDefaultCreditCard = (activePaymentMethod: string) => {
        return (
            purchaseState?.tokenList?.find(
                (item) => +item?.default_card === 1 && item?.payment_method === activePaymentMethod,
            )?.number ?? ''
        );
    };

    const getDefaultCreditCardData = (activePaymentMethod: string) => {
        return (
            purchaseState?.tokenList?.find(
                (item) => +item?.default_card === 1 && item?.payment_method === activePaymentMethod,
            ) || null
        );
    };

    const getCreditCardList = (activePaymentMethod: string) => {
        return purchaseState?.tokenList?.filter(
            (item) => item?.payment_method === activePaymentMethod,
        );
    };

    const getCountPaymentMethodList = () => {
        return purchaseState?.paymentMethodList?.length;
    };

    const setActivePaymentMethodWithClick = (payment: string) => {
        dispatch(purchaseActions.setIsPaymentFailed(false));
        dispatch(purchaseActions.setActiveSubscribe(false));
        dispatch(purchaseActions.setActivePaymentMethod(payment));
    };

    return {
        isLoadedPaymentMethodList: !!purchaseState?.paymentMethodList?.length,
        defaultPaymentMethod,
        setActivePayment,
        isAvailablePaymentMethod,
        getDefaultCreditCard,
        getCreditCardList,
        setActivePaymentMethodWithClick,
        getCountPaymentMethodList,
        getDefaultCreditCardData,
        effectActivePaymentMethodList,
        effectActivePaymentMethod,
    };
}
