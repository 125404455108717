import React from 'react';
import { useSelector } from 'react-redux';
import * as IMAGES from '../../../common/Images';
import * as MENU from '../../../../constants/menu';
import * as SEX from '../../../../constants/sex';
import { RootState } from '../../../../index';

interface IFiltersProps {
    inputId: string;
    handleOnline: (value: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputId: (value: React.ChangeEvent<HTMLInputElement>) => void;
    filterById: () => void;
    handleChangeFilterMen: (value: string) => void;
    handleChangeFilterLetters: (value: string) => void;
    onlineStatus: string;
}

export default function Filters({
    inputId,
    handleOnline,
    handleInputId,
    filterById,
    handleChangeFilterMen,
    handleChangeFilterLetters,
    onlineStatus,
}: IFiltersProps) {
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const menuState = useSelector((state: RootState) => state.menu);
    const userState = useSelector((state: RootState) => state.user);

    const isActiveFilters =
        !currentChatMailboxState?.info?.id && menuState.letters !== MENU.DRAFT_FOLDER;

    if (!isActiveFilters) return <div />;
    return (
        <div className="c2l_letter_filters_wrap">
            <div className="c2l_letter_filters_wrap2">
                <div className="c2l_letter_filters">
                    <div className="c2l_letter_filters_box" data-testid="recipient-filter">
                        <div className="c2l_filters_head">
                            <div className="c2l_filters_head_title">
                                {
                                    SEX.SEX_TEXT[
                                        userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                    ].LETTER_FILTER_TITLE
                                }
                            </div>
                            <div className="c2l_filters_head_switch" data-testid="recipient-online">
                                <span>Online</span>
                                <input
                                    type="checkbox"
                                    id="c2l_online"
                                    checked={onlineStatus === 'online'}
                                    onChange={handleOnline}
                                />
                                <label htmlFor="c2l_online" />
                            </div>
                        </div>
                        <div className="c2l_filters_row" data-testid="recipient-all">
                            <input
                                type="radio"
                                id="c2l_radio_1"
                                name="c2l_radio_1"
                                data-testid="all"
                                checked={letterFilterState.girlFilter === 'all'}
                                onChange={() => handleChangeFilterMen('all')}
                            />
                            <label htmlFor="c2l_radio_1">All</label>
                        </div>
                        <div className="c2l_filters_row" data-testid="recipient-id">
                            <input
                                type="radio"
                                id="c2l_radio_2"
                                name="c2l_radio_1"
                                data-testid="id"
                                checked={letterFilterState.girlFilter === 'id'}
                                onChange={() => handleChangeFilterMen('id')}
                            />
                            <label htmlFor="c2l_radio_2">ID</label>
                            <div className="c2l_id_input">
                                <input
                                    type="text"
                                    placeholder="Enter ID"
                                    value={inputId}
                                    data-testid="recipient-input"
                                    onChange={handleInputId}
                                />
                                <button
                                    onClick={filterById}
                                    data-testid="submit-recipient-input-btn"
                                >
                                    <img src={IMAGES.c2l_id_search_btn} alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="c2l_filters_row" data-testid="recipient-dialogues">
                            <input
                                type="radio"
                                id="c2l_radio_3"
                                name="c2l_radio_1"
                                data-testid="dialogues"
                                checked={letterFilterState.girlFilter === 'dialogues'}
                                onChange={() => handleChangeFilterMen('dialogues')}
                            />
                            <label htmlFor="c2l_radio_3">Dialogues</label>
                        </div>
                        <div className="c2l_filters_row" data-testid="recipient-favorites">
                            <input
                                type="radio"
                                id="c2l_radio_4"
                                name="c2l_radio_1"
                                data-testid="favorites"
                                checked={letterFilterState.girlFilter === 'favorites'}
                                onChange={() => handleChangeFilterMen('favorites')}
                            />
                            <label htmlFor="c2l_radio_4">Favorites</label>
                        </div>
                        <div className="c2l_filters_row" data-testid="recipient-likes">
                            <input
                                type="radio"
                                id="c2l_radio_5"
                                name="c2l_radio_1"
                                data-testid="likes"
                                checked={letterFilterState.girlFilter === 'likes'}
                                onChange={() => handleChangeFilterMen('likes')}
                            />
                            <label htmlFor="c2l_radio_5">Your likes</label>
                        </div>
                    </div>
                    <div className="c2l_letter_filters_box" data-testid="letter-filter">
                        <div className="c2l_filters_head">
                            <div className="c2l_filters_head_title">Letters</div>
                        </div>
                        <div className="c2l_filters_row" data-testid="letter-all">
                            <input
                                type="radio"
                                id="c2l_radio_10"
                                name="c2l_radio_2"
                                data-testid="all"
                                checked={letterFilterState.lettersFilter === 'all'}
                                onChange={() => handleChangeFilterLetters('all')}
                            />
                            <label htmlFor="c2l_radio_10">All</label>
                        </div>
                        <div className="c2l_filters_row" data-testid="letter-unread">
                            <input
                                type="radio"
                                id="c2l_radio_11"
                                name="c2l_radio_2"
                                data-testid="unread"
                                checked={letterFilterState.lettersFilter === 'unread'}
                                onChange={() => handleChangeFilterLetters('unread')}
                            />
                            <label htmlFor="c2l_radio_11">Unread</label>
                        </div>
                        <div className="c2l_filters_row" data-testid="letter-unanswered">
                            <input
                                type="radio"
                                id="c2l_radio_12"
                                name="c2l_radio_2"
                                data-testid="unanswered"
                                checked={letterFilterState.lettersFilter === 'unanswered'}
                                onChange={() => handleChangeFilterLetters('unanswered')}
                            />
                            <label htmlFor="c2l_radio_12">Unanswered</label>
                        </div>
                        {menuState.letters !== 'bookmarks' && (
                            <div className="c2l_filters_row" data-testid="letter-answered">
                                <input
                                    type="radio"
                                    id="c2l_radio_13"
                                    name="c2l_radio_2"
                                    data-testid="answered"
                                    checked={letterFilterState.lettersFilter === 'answered'}
                                    onChange={() => handleChangeFilterLetters('answered')}
                                />
                                <label htmlFor="c2l_radio_13">Answered</label>
                            </div>
                        )}
                        <div className="c2l_filters_row" data-testid="letter-first">
                            <input
                                type="radio"
                                id="c2l_radio_14"
                                name="c2l_radio_2"
                                data-testid="first_letter"
                                checked={letterFilterState.lettersFilter === 'first_letter'}
                                onChange={() => handleChangeFilterLetters('first_letter')}
                            />
                            {/*GT-11770*/}
                            {/*<label htmlFor="c2l_radio_14">*/}
                            {/*    {*/}
                            {/*        SEX.SEX_TEXT[userState?.info?.sex_text]*/}
                            {/*            .LETTER_FIRST_LETTER_TO_HER*/}
                            {/*    }*/}
                            {/*</label>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
