import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeMobChatList,
    closeMobileMenu,
    closePanel,
    openFilterSearch,
    openMobChatList,
} from '../../../services/dom';
import * as modalsAction from '../../../store/actions/modals';
import * as MENU from '../../../constants/menu';
import useTransition from '../../../hooks/transition/useTransition';
import { useLocation } from 'react-router-dom';
import * as currentChatAction from '../../../store/actions/currentChat';
import useHandleMobileChat from '../../../hooks/chat/helpers/useHandleMobileChat';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import Logotypes from '../../../img/logotypes';
import SITE from '../../../config/site';
import { RootState } from '../../../index';
import { isHolidayActive } from '../../../constants/holiday';

export default function MobileHead() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const location = useLocation();
    const handleMobileChat = useHandleMobileChat();
    const handleActiveBlocks = useHandleActiveBlocks();
    const activeHoliday = isHolidayActive();

    const menuState = useSelector((state: RootState) => state.menu);
    const modalsState = useSelector((state: RootState) => state.modals);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);

    const [isOpenChatList, setIsOpenChatList] = useState(true);

    const currentPage = location?.pathname?.split('/')?.[1];
    const currentSubPage = location?.pathname?.split('/')?.[2];

    const isLetterPage = ['letter'].includes(currentPage);
    const isChatPage = ['chat', 'dialogues', 'unanswered', 'favorite', 'likes'].includes(
        currentPage,
    );
    const isHideMenuIcon = [
        ...(handleActiveBlocks.isSearchV3 ? ['online-now'] : []),
        'videoshow',
        'search',
        'likehistory',
        'gifts',
        'profile-woman',
        'menu',
    ].includes(currentPage);
    const isProfilesSubMenu = ['block_list'].includes(currentSubPage);
    const isFiltersSubMenu = [
        ...(handleActiveBlocks.isSearchV3 ? ['online-now'] : []),
        'letter',
        'search',
        'like',
        'videoshow',
    ].includes(currentPage);
    const isGoBackMenu =
        ['purchase-history', 'gifts', 'contact-request', 'meeting-request'].includes(
            currentSubPage,
        ) ||
        location.pathname === '/my-profile' ||
        location.pathname.includes('/likehistory') ||
        location.pathname.includes('/cart') ||
        (location.pathname.includes('/profile-woman') && localStorage.getItem('from-page')) ||
        (location.pathname.includes('/gifts') && localStorage.getItem('from-page')) ||
        location.pathname.includes('/faq') ||
        isChatPage;

    const openSubMenu = (nameTab: string) => {
        closePanel();
        openMobChatList();
        dispatch(modalsAction.setActiveSubMenu(nameTab));
        if (isFiltersSubMenu) openFilterSearch();
    };

    const openBlockListMenu = () => {
        closePanel();
        closeMobileMenu();
    };

    const openMenuBlock = () => {
        closePanel();
        closeMobChatList();
        transition.transitionWithOnlyHistoryPush('/menu');
    };

    const goBack = () => {
        closePanel();

        switch (menuState.main) {
            case MENU.MENU_LIKE_HISTORY_PAGE:
                transition.transitionWithOnlyHistoryPush('/like');
                break;

            case MENU.MENU_CART_PAGE:
                transition.transitionWithOnlyHistoryPush('/gifts');
                break;

            case MENU.MENU_PROFILE_WOMAN_PAGE:
                transition.transitionWithOnlyHistoryPush(`${localStorage.getItem('from-page')}`);
                localStorage.removeItem('from-page');
                break;

            case MENU.SEND_GIFTS_PAGE:
                transition.transitionWithOnlyHistoryPush(`${localStorage.getItem('from-page')}`);
                localStorage.removeItem('from-page');
                // history.goBack();
                break;

            case MENU.MENU_CHAT:
                openMobChatList();
                dispatch(currentChatAction.touchSwipeChatList('right'));
                localStorage.removeItem('from-page');
                break;

            default:
                transition.transitionWithOnlyHistoryPush('/menu');
                break;
        }
    };

    useEffect(() => {
        if (currentChatState?.info?.id) setIsOpenChatList(false);
    }, [currentChatState.info]);

    useEffect(() => {
        if (currentChatState?.touchSwipeType)
            setIsOpenChatList(currentChatState?.touchSwipeType === 'right');
        else setIsOpenChatList(true);
    }, [currentChatState.touchSwipeType]);

    if (handleMobileChat?.isActiveNewMobileChat) return null;
    return (
        <div className="mob_head">
            <div className="mob_head_nav">
                {isProfilesSubMenu && !isOpenChatList && (
                    <div
                        className={`mob_head_nav_btn ${modalsState.activeSubmenu === 'filters' ? 'active' : ''} ${currentPage === 'search' ? 'mob_head_nav_btn_search' : ''} ${currentPage === 'videoshow' ? 'mob_head_nav_btn_search' : ''}`}
                        onClick={() => openSubMenu('profiles')}
                    >
                        <div className="mob_head_nav_btn_ico mob_head_nav_btn_ico_profile" />
                        <div className="mob_head_nav_btn_text">Profiles</div>
                    </div>
                )}
                {isFiltersSubMenu && (
                    <div
                        className={`mob_head_nav_btn ${modalsState.activeSubmenu === 'filters' ? 'active' : ''} ${isLetterPage ? 'letters' : ''}`}
                        onClick={() => openSubMenu('filters')}
                    >
                        <div className="mob_head_nav_btn_ico mob_head_nav_btn_ico_filters" />
                        <div className="mob_head_nav_btn_text">Filters</div>
                    </div>
                )}
                {(isChatPage ? isGoBackMenu && !isOpenChatList : isGoBackMenu) && (
                    <div
                        className={`mob_head_nav_btn ${isChatPage ? 'chat_go_back' : ''}`}
                        onClick={goBack}
                    >
                        <div className="mob_head_nav_btn_ico mob_head_nav_btn_ico_goback" />
                        <div className="mob_head_nav_btn_text">Go back</div>
                    </div>
                )}
            </div>
            <div className="mob_head_left">
                <div className="left_menu_btn" onClick={openMenuBlock}>
                    <span />
                    <span />
                    <span />
                </div>
                {!isHideMenuIcon && (
                    <div className="left_menu_chat_list" onClick={() => openSubMenu('sub')}>
                        <img src="../../../img/sub_menu.svg" alt="" />
                    </div>
                )}
                {menuState.main === MENU.MENU_BLOCK && (
                    <div className="mob_block_list_menu_btn" onClick={openBlockListMenu}>
                        <img src="../../../img/c3lh-item-chat.svg" alt="" />
                    </div>
                )}
            </div>

            <div className="head_logo">
                {userState?.info?.is_verified ? (
                    <img src={Logotypes.images['logo_vip_light.svg']} alt={SITE.name} />
                ) : activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}_light.svg`] ? (
                    <img
                        src={Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]}
                        alt={SITE.brand}
                    />
                ) : (
                    <img src={Logotypes.images['logo_light.svg']} alt={SITE.brand} />
                )}
            </div>
            <div className="head_empty" />
        </div>
    );
}
