import SITE from '../../config/site';

const backgroundColor = SITE.ID === 23 ? 'rgba(0, 0, 0, 0.60)' : 'rgba(55, 78, 108, 0.32)';

const modalStyleProps = ({
    isLoaderModal = false,
    isAttachment = false,
    isDropdown = false,
    isGallery = false,
    isProfile = false,
    zIndex = 9999,
    isShowModal = true,
    isBackgroundColor = true,
} = {}) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: isShowModal ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isBackgroundColor ? backgroundColor : 'transparent',
        backdropFilter: 'blur(4px)',
        zIndex: zIndex,
        width: isProfile ? '100vw' : 'unset',
        height: isProfile ? '100vh' : 'unset',
    },
    content: {
        top: 'unset',
        left: 'unset',
        right: 'unset',
        bottom: 'unset',
        width: 'max-content',
        height: isGallery || isProfile ? '100%' : 'unset',
        maxWidth: '100%',
        maxHeight: '100%',
        position: isAttachment || isGallery ? 'static' : 'relative',
        padding: 0,
        border: 'none',
        borderRadius: '0',
        backgroundColor: 'transparent',
        overflow: isAttachment || isDropdown ? 'unset' : 'auto',
        minWidth: isLoaderModal ? '100vw' : 'unset',
        minHeight: isLoaderModal ? '100vh' : 'unset',
    },
});

export default modalStyleProps;
