import React, { ChangeEvent, useState } from 'react';
import Modal from 'react-modal';
import '../../../../styles/man-profile/password.scss';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ProfileApi from '../../../../services/api/ProfileApi';
import { IManagePasswordProps } from './index.interface';
import IconCheckedCircle from '../../../Icons/IconCheckedCircle';
import IconCross from '../../../Icons/IconCross';

export default function ManagePassword({
    closeManagePasswordModal,
    isOpenManagePassword,
}: IManagePasswordProps) {
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [infoText, setInfoText] = useState<string>('');
    const [isVisibleEnterPassword, setIsVisibleEnterPassword] = useState<boolean>(false);
    const [isVisibleReEnterPassword, setIsVisibleReEnterPassword] = useState<boolean>(false);
    const isCorrectForm =
        password === repeatPassword &&
        password &&
        repeatPassword &&
        password.length &&
        repeatPassword.length;

    const changePassword = () => {
        if (password?.length < 8) {
            return setInfoText('Please enter a new password of 8 or more characters');
        } else if (!repeatPassword?.length) {
            return setInfoText('Please re-enter the new password');
        } else if (!isCorrectForm) {
            return setInfoText('Please fill forms correctly');
        }

        new ProfileApi().updatePassword(repeatPassword).then(() => closeManagePasswordModal());
    };

    const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setInfoText('');
    };

    const handleRepeatPassword = (e: ChangeEvent<HTMLInputElement>) => {
        setRepeatPassword(e.target.value);
        setInfoText('');
    };

    const showEnterPassword = () => setIsVisibleEnterPassword(!isVisibleEnterPassword);

    const showReEnterPassword = () => setIsVisibleReEnterPassword(!isVisibleReEnterPassword);

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isOpenManagePassword}
            onRequestClose={closeManagePasswordModal}
            ariaHideApp={false}
        >
            <div className="popup_manprof_change_password">
                <div className="popup_manprof_change_password_wrap">
                    <h2 className="popup_manprof_change_password_title">Change your password</h2>
                    <div className="popup_manprof_change_password_row">
                        <div className="popup_manprof_change_password_item">
                            <label
                                htmlFor="password"
                                className="popup_manprof_change_password_label"
                            >
                                Enter New Password
                            </label>
                            <div className="popup_manprof_change_password_input_wrap">
                                <input
                                    type={`${isVisibleEnterPassword ? ' ' : 'password'}`}
                                    id="password"
                                    className="popup_manprof_change_password_input"
                                    placeholder="New password (8+ characters)"
                                    value={password}
                                    onChange={handlePassword}
                                />
                                <button
                                    className={`popup_manprof_change_password_visible_btn ${isVisibleEnterPassword ? 'active' : ''}`}
                                    onClick={showEnterPassword}
                                />
                            </div>
                        </div>
                        <div className="popup_manprof_change_password_item">
                            <label
                                htmlFor="repassword"
                                className="popup_manprof_change_password_label"
                            >
                                Re-Enter New Password
                            </label>
                            <div className="popup_manprof_change_password_input_wrap">
                                <input
                                    type={`${isVisibleReEnterPassword ? ' ' : 'password'}`}
                                    id="repassword"
                                    className="popup_manprof_change_password_input"
                                    placeholder="New password (8+ characters)"
                                    value={repeatPassword}
                                    onChange={handleRepeatPassword}
                                />
                                <button
                                    className={`popup_manprof_change_password_visible_btn ${isVisibleReEnterPassword ? 'active' : ''}`}
                                    onClick={showReEnterPassword}
                                />
                            </div>
                            {password !== repeatPassword && repeatPassword?.length ? (
                                <div className="popup_manprof_change_password_input_status wrong">
                                    <span>The password doesn't match</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {isCorrectForm ? (
                                <div className="popup_manprof_change_password_input_status right">
                                    <span>The password matches</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                    <div
                        className={`popup_manprof_change_password_save_btn`}
                        onClick={changePassword}
                    >
                        <IconCheckedCircle />
                        <span>Change password</span>
                    </div>
                    {!!+infoText?.length && (
                        <div className="popup_manprof_change_password_info_text">{infoText}</div>
                    )}
                </div>
                <div
                    className="popup_manprof_change_password_close"
                    onClick={closeManagePasswordModal}
                >
                    <IconCross />
                </div>
            </div>
        </Modal>
    );
}
