import React, { useState } from 'react';
import styles from './styles.module.scss';
import useTransition from '../../../../hooks/transition/useTransition';
import useHandleVIP from '../../../../hooks/vip/useHandleVIP';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import SITE from '../../../../config/site';
import IconVipStar from '../../../Icons/IconVipStar';
import IconCross from '../../../Icons/IconCross';
import vipOnlineNowBanner from '../../../../img/vip/vip_online_now_banner.svg';

export default function VIPListBanner({ customStyleItem = '' }: { customStyleItem?: string }) {
    const [showBlock, setShowBlock] = useState<boolean>(true);

    const transition = useTransition();
    const { isShowVIPBanner } = useHandleVIP();
    const handleActiveBlocks = useHandleActiveBlocks();

    const handleOpen = () => {
        transition.transitionWithOnlyHistoryPush('/my-profile');
    };

    const handleClose = () => {
        setShowBlock(false);
    };

    if (!showBlock || !isShowVIPBanner || SITE.ID === 23) return null;
    return (
        <div className={styles.vip_onb_wrapper}>
            <div className={`${styles.vip_onb_item} ${customStyleItem}`}>
                <div className={styles.vip_onb_container}>
                    <div>
                        <div className={styles.vip_onb_title}>
                            Get the maximum benefit with <span>VIP status</span>
                        </div>
                        <div className={styles.vip_onb_container_txt}>
                            <IconVipStar />
                            <span className={styles.vip_onb_txt}>
                                Finding a soul mate is even easier. Members trust verified users
                                more
                            </span>
                        </div>
                        <div className={styles.vip_onb_container_txt}>
                            <IconVipStar />
                            <span className={styles.vip_onb_txt}>
                                Your profile is always at the top of the search
                            </span>
                        </div>
                        <div className={styles.vip_onb_container_txt}>
                            <IconVipStar />
                            <span className={styles.vip_onb_txt}>
                                The VIP support team processes your requests out of turn 24/7
                            </span>
                        </div>
                        {handleActiveBlocks.isContactRequest && (
                            <div className={styles.vip_onb_container_txt}>
                                <IconVipStar />
                                <span className={styles.vip_onb_txt}>
                                    Possibility to exchange contacts with members
                                </span>
                            </div>
                        )}
                        <div className={styles.vip_onb_pict_wrap_mob}>
                            <img
                                className={styles.vip_onb_pict_mob}
                                src={vipOnlineNowBanner}
                                alt="vip online banner"
                            />
                        </div>
                        <div className={styles.vip_onb_btn} onClick={handleOpen}>
                            Get VIP and bonus 100 free credits
                        </div>
                    </div>
                    <div className={styles.vip_onb_pict_wrap}>
                        <img
                            className={styles.vip_onb_pict}
                            src={vipOnlineNowBanner}
                            alt="vip online banner"
                        />
                    </div>
                </div>
                <div className={styles.vip_onb_cross} onClick={handleClose}>
                    <IconCross />
                </div>
            </div>
        </div>
    );
}
