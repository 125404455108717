import { LoaderActionTypes } from '../../constants/loader';
import { Dispatch } from 'redux';

export function setActiveStatus(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; data: boolean }>) {
        return dispatch({
            type: LoaderActionTypes.SET_ACTIVE_STATUS,
            data: status,
        });
    };
}
export function setActiveGifLoader(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; data: boolean }>) {
        return dispatch({
            type: LoaderActionTypes.SET_ACTIVE_GIF_LOADER,
            data: status,
        });
    };
}
