import React from 'react';

export default function IconQuoteOpen() {
    return (
        <svg
            width="30"
            height="18"
            viewBox="0 0 30 18"
            fill="#383B5A"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11.2 17.152H0L7.168 0H14.528L11.2 17.152ZM25.728 17.152H14.592L21.76 0H29.056L25.728 17.152Z" />
        </svg>
    );
}
