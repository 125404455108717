import { useEffect, useState } from 'react';
import ClickHistoryApi from '../../services/api/v3/ClickHistoryApi';

export default function useGetVConfig() {
    const [hasV, setHasV] = useState(false);

    useEffect(() => {
        new ClickHistoryApi().getVSettings().then((res) => {
            if (res?.status) setHasV(res?.status);
        });
    }, []);

    return { hasV };
}
