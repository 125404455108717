import React from 'react';
import styles from './styles.module.scss';
import { FORM_DATA, MAX_LENGTH_TEXTAREA } from '../../../constants/mailUnsubscribe';

interface IUnsubscribeFormPayerProps {
    userStepData: {
        step1: { text: string };
        step2: { text: string };
        step3: { text: string };
    };
    selectStepText: (step: string, text: string) => void;
    otherTextData: {
        step1: { text: string };
        step2: { text: string };
        step3: { text: string };
    };
    handleOtherTextForm: (value: string, step: string) => void;
    unsubscribeEmail: string;
    handleUnsubscribeEmail: (value: string) => void;
    isUnsubscribeEmailError: boolean;
    activeStep: number;
    onSubmitUnsubscribeForm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    isLoadingSubmit: boolean;
}

export default function UnsubscribeFormPayer({
    userStepData,
    selectStepText,
    otherTextData,
    handleOtherTextForm,
    unsubscribeEmail,
    handleUnsubscribeEmail,
    isUnsubscribeEmailError,
    activeStep,
    onSubmitUnsubscribeForm,
    isLoadingSubmit,
}: IUnsubscribeFormPayerProps) {
    return (
        <>
            <form className={styles.unsubscribe_form} onSubmit={onSubmitUnsubscribeForm}>
                <fieldset
                    className={`${styles.unsubscribe_form_fieldgroup} ${activeStep > 1 ? styles.selected : ''}`}
                >
                    <div className={styles.unsubscribe_form_fieldgroup_header}>
                        <p className={styles.unsubscribe_form_number}>Step 1</p>
                        <h2 className={styles.unsubscribe_form_title}>
                            Why do you want to unsubscribe?
                        </h2>
                    </div>
                    <div className={styles.unsubscribe_form_fieldgroup_body}>
                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_1_choice1"
                            checked={userStepData?.step1?.text === FORM_DATA?.step1?.text1}
                            onChange={() => selectStepText('step1', FORM_DATA?.step1?.text1)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_1_choice1"
                        >
                            {FORM_DATA?.step1?.text1}
                        </label>

                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_1_choice2"
                            checked={userStepData?.step1?.text === FORM_DATA?.step1?.text2}
                            onChange={() => selectStepText('step1', FORM_DATA?.step1?.text2)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_1_choice2"
                        >
                            {FORM_DATA?.step1?.text2}
                        </label>

                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_1_choice3"
                            checked={userStepData?.step1?.text === FORM_DATA?.step1?.text3}
                            onChange={() => selectStepText('step1', FORM_DATA?.step1?.text3)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_1_choice3"
                        >
                            {FORM_DATA?.step1?.text3}
                        </label>

                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_1_choice4"
                            checked={userStepData?.step1?.text === FORM_DATA?.step1?.text4}
                            onChange={() => selectStepText('step1', FORM_DATA?.step1?.text4)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_1_choice4"
                        >
                            {FORM_DATA?.step1?.text4}
                        </label>

                        <input
                            className={`${styles.unsubscribe_form_radio_input} ${styles.unsubscribe_form_radio_textarea}`}
                            type="radio"
                            id="step_1_choice5"
                            checked={userStepData?.step1?.text === FORM_DATA?.step1?.text5}
                            onChange={() => selectStepText('step1', FORM_DATA?.step1?.text5)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_1_choice5"
                        >
                            {FORM_DATA?.step1?.text5}
                        </label>
                        <textarea
                            className={styles.unsubscribe_form_textarea}
                            name="step_1_choice5_text"
                            value={otherTextData?.step1?.text}
                            onChange={(e) => handleOtherTextForm(e.currentTarget.value, 'step1')}
                        />
                        {otherTextData?.step1?.text?.length >= MAX_LENGTH_TEXTAREA && (
                            <span className={styles.error_text}>
                                maximum message length cannot exceed {MAX_LENGTH_TEXTAREA}{' '}
                                characters
                            </span>
                        )}
                    </div>
                </fieldset>
                <fieldset
                    className={`${styles.unsubscribe_form_fieldgroup} ${activeStep > 2 ? styles.selected : ''}`}
                    disabled={activeStep < 2}
                >
                    <div className={styles.unsubscribe_form_fieldgroup_header}>
                        <p className={styles.unsubscribe_form_number}>Step 2</p>
                        <h2 className={styles.unsubscribe_form_title}>
                            What content has been most useful to you
                        </h2>
                    </div>
                    <div className={styles.unsubscribe_form_fieldgroup_body}>
                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_2_choice1"
                            checked={userStepData?.step2?.text === FORM_DATA?.step2?.text1}
                            onChange={() => selectStepText('step2', FORM_DATA?.step2?.text1)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_2_choice1"
                        >
                            {FORM_DATA?.step2?.text1}
                        </label>

                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_2_choice2"
                            checked={userStepData?.step2?.text === FORM_DATA?.step2?.text2}
                            onChange={() => selectStepText('step2', FORM_DATA?.step2?.text2)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_2_choice2"
                        >
                            {FORM_DATA?.step2?.text2}
                        </label>

                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_2_choice3"
                            checked={userStepData?.step2?.text === FORM_DATA?.step2?.text3}
                            onChange={() => selectStepText('step2', FORM_DATA?.step2?.text3)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_2_choice3"
                        >
                            {FORM_DATA?.step2?.text3}
                        </label>

                        <input
                            className={`${styles.unsubscribe_form_radio_input} ${styles.unsubscribe_form_radio_textarea}`}
                            type="radio"
                            id="step_2_choice4"
                            checked={userStepData?.step2?.text === FORM_DATA?.step2?.text4}
                            onChange={() => selectStepText('step2', FORM_DATA?.step2?.text4)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_2_choice4"
                        >
                            {FORM_DATA?.step2?.text4}
                        </label>
                        <textarea
                            className={styles.unsubscribe_form_textarea}
                            name="step_2_choice4_text"
                            value={otherTextData?.step2?.text}
                            onChange={(e) => handleOtherTextForm(e.currentTarget.value, 'step2')}
                        />
                        {otherTextData?.step2?.text?.length >= MAX_LENGTH_TEXTAREA && (
                            <span className={styles.error_text}>
                                maximum message length cannot exceed {MAX_LENGTH_TEXTAREA}{' '}
                                characters
                            </span>
                        )}
                    </div>
                </fieldset>
                <fieldset
                    className={`${styles.unsubscribe_form_fieldgroup} ${activeStep > 3 ? styles.selected : ''}`}
                    disabled={activeStep < 3}
                >
                    <div className={styles.unsubscribe_form_fieldgroup_header}>
                        <p className={styles.unsubscribe_form_number}>Step 3</p>
                        <h2 className={styles.unsubscribe_form_title}>
                            How we can improve the quality of newsletters{' '}
                        </h2>
                    </div>
                    <div className={styles.unsubscribe_form_fieldgroup_body}>
                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_3_choice1"
                            checked={userStepData?.step3?.text === FORM_DATA?.step3?.text1}
                            onChange={() => selectStepText('step3', FORM_DATA?.step3?.text1)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_3_choice1"
                        >
                            {FORM_DATA?.step3?.text1}
                        </label>

                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_3_choice2"
                            checked={userStepData?.step3?.text === FORM_DATA?.step3?.text2}
                            onChange={() => selectStepText('step3', FORM_DATA?.step3?.text2)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_3_choice2"
                        >
                            {FORM_DATA?.step3?.text2}
                        </label>

                        <input
                            className={styles.unsubscribe_form_radio_input}
                            type="radio"
                            id="step_3_choice3"
                            checked={userStepData?.step3?.text === FORM_DATA?.step3?.text3}
                            onChange={() => selectStepText('step3', FORM_DATA?.step3?.text3)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_3_choice3"
                        >
                            {FORM_DATA?.step3?.text3}
                        </label>

                        <input
                            className={`${styles.unsubscribe_form_radio_input} ${styles.unsubscribe_form_radio_textarea}`}
                            type="radio"
                            id="step_3_choice4"
                            checked={userStepData?.step3?.text === FORM_DATA?.step3?.text4}
                            onChange={() => selectStepText('step3', FORM_DATA?.step3?.text4)}
                        />
                        <label
                            className={styles.unsubscribe_form_radio_label}
                            htmlFor="step_3_choice4"
                        >
                            {FORM_DATA?.step3?.text4}
                        </label>
                        <textarea
                            className={styles.unsubscribe_form_textarea}
                            name="step_3_choice4_text"
                            value={otherTextData?.step3?.text}
                            onChange={(e) => handleOtherTextForm(e.currentTarget.value, 'step3')}
                        />
                        {otherTextData?.step3?.text?.length >= MAX_LENGTH_TEXTAREA && (
                            <span className={styles.error_text}>
                                maximum message length cannot exceed {MAX_LENGTH_TEXTAREA}{' '}
                                characters
                            </span>
                        )}
                    </div>
                </fieldset>
                <fieldset
                    className={`${styles.unsubscribe_form_fieldgroup} ${activeStep > 4 ? styles.selected : ''}`}
                    disabled={activeStep < 4 || isLoadingSubmit}
                >
                    <div className={styles.unsubscribe_form_fieldgroup_header}>
                        <p className={styles.unsubscribe_form_number}>Step 4</p>
                        <h2 className={styles.unsubscribe_form_title}>
                            To unsubscribe, enter the email that is linked to this account.
                        </h2>
                    </div>
                    <div className={styles.unsubscribe_form_fieldgroup_body}>
                        <input
                            className={styles.unsubscribe_form_text_input}
                            type="email"
                            placeholder="Enter your email"
                            required
                            value={unsubscribeEmail}
                            onChange={(e) => handleUnsubscribeEmail(e.currentTarget.value)}
                        />
                        {!!isUnsubscribeEmailError && (
                            <span className={styles.error_text}>
                                The email address you entered does not match the one associated with
                                your account registration.
                            </span>
                        )}
                        <button className={styles.unsubscribe_form_submit_btn} type="submit">
                            <span className={styles.unsubscribe_form_submit_btn_text}>
                                Unsubscribe
                            </span>
                        </button>
                    </div>
                </fieldset>
            </form>
        </>
    );
}
