import Base from './Base';
import { objectToArray } from '../methods';
import {
    IAddDraftData,
    IDeleteDraftData,
    IGetDraftsData,
    IGetDraftsRes,
    IGetFemalesData,
    IGetMailsData,
    IGetMailsRes,
    IMailData,
    IMailsFullInfo,
    IPaidReadMailData,
    IReadMailAudioData,
    IReadMailImageData,
    IReadMailVideoData,
    IRestoreMailData,
    ISetBookmarkData,
    ITrashMailData,
    IUpdateDraftData,
} from './MailboxApi.interface';
import { CancelToken } from 'axios';

export default class MailboxApi extends Base {
    async paidReadMail(data: IPaidReadMailData) {
        try {
            const res = await super.post(`mailbox/paidReadMail`, data);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postMails({
        userId,
        mailId,
        folder,
        girlFilter,
        girlId,
        letterFilter,
        onlineFilter,
        page,
        username,
    }: IGetMailsData): Promise<IGetMailsRes> {
        return super
            .post(`mailbox/mails`, {
                user_id: userId,
                mail_id: mailId,
                folder: folder ? folder : 'dialog',
                girl_filter: girlFilter,
                girl_id: girlId,
                letter_filter: letterFilter,
                online_filter: onlineFilter,
                page: page ? page : 1,
                ...(username ? { username } : {}),
            })
            .then((res) => res?.data)
            .then((res) => {
                return {
                    mails: res?.response?.mails?.map((item: IMailsFullInfo) => ({
                        ...item,
                        attachments: objectToArray(item?.attachments),
                    })),
                    pages: res?.response?.pages,
                    page: res?.response?.current,
                    status: res?.status,
                    ...(res?.response?.chat?.chat_uid ? { chat: res?.response?.chat } : {}),
                    ...(res?.response?.womanInfo ? { womanInfo: res?.response?.womanInfo } : {}),
                };
            });
    }

    async postDrafts({ userId, draftId, page = 1 }: IGetDraftsData): Promise<IGetDraftsRes> {
        return super
            .post(`mailbox/drafts`, {
                user_id: userId,
                draft_id: draftId ? draftId : '',
                page: page,
            })
            .then((res) => res?.data)
            .then((res) => {
                return {
                    drafts: res?.result,
                    pages: res?.pages,
                    status: res?.status,
                };
            });
    }

    async getUnreadCount(userId: number, token = null) {
        try {
            const res = await super.get(
                `mailbox/count?user_id=${userId}`,
                token ? { cancelToken: token } : {},
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async postRestoreMail({ userId, mailId }: IRestoreMailData) {
        const res = await super.post(`mailbox/restoremail`, {
            user_id: userId,
            mail_id: mailId,
        });
        return res?.data;
    }

    async postTrashMail({ userId, mailId, outbox }: ITrashMailData) {
        try {
            const res = await super.post(`mailbox/trashmail`, {
                user_id: userId,
                mail_id: mailId,
                outbox: outbox,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postTrashThreadMail(chat_id: string) {
        const res = await super.post(`mailbox/trashThread`, {
            chat_id,
        });
        return res?.data;
    }

    async postSetBookmark({ userId, mailId }: ISetBookmarkData) {
        const res = await super.post(`mailbox/setbookmark`, {
            user_id: userId,
            mail_id: mailId,
        });
        return res?.data;
    }

    async postUnsetBookmark({ userId, mailId }: ISetBookmarkData) {
        const res = await super.post(`mailbox/unsetbookmark`, {
            user_id: userId,
            mail_id: mailId,
        });
        return res?.data;
    }

    async postAddDraft({ userId, recipients, messageContent, attachments }: IAddDraftData) {
        const res = await super.post(`mailbox/adddraft`, {
            user_id: userId,
            recipients: recipients,
            message_content: messageContent,
            attachments: attachments,
        });
        return res?.data;
    }

    async postUpdateDraft({
        userId,
        draftIds,
        recipients,
        messageContent,
        attachments,
    }: IUpdateDraftData) {
        const res = await super.post(`mailbox/updatedraft`, {
            user_id: userId,
            draft_ids: draftIds,
            recipients: recipients,
            message_content: messageContent,
            attachments: attachments,
        });
        return res?.data;
    }

    async postDeleteDraft({ userId, draftIds }: IDeleteDraftData) {
        const res = await super.post(`mailbox/deletedraft`, {
            user_id: userId,
            draft_ids: draftIds,
        });
        return res?.data;
    }

    async postFemales({ personFilter, userId, search }: IGetFemalesData, token?: CancelToken) {
        const res = await super.post(
            `mailbox/females`,
            {
                person_filter: personFilter,
                user_id: userId,
                search: search,
            },
            token ? { cancelToken: token } : {},
        );
        return res?.data;
    }

    async postMail({
        userId,
        recipients,
        messageContent,
        messageType,
        attachments,
        parentMailId,
    }: IMailData) {
        const res = await super.post(`mailbox/mail`, {
            user_id: userId,
            recipients: recipients,
            message_content: messageContent,
            message_type: messageType,
            attachments: attachments,
            parent_mail_id: parentMailId,
        });
        return res?.data;
    }

    async postReadMail(mailId: number, userId: number, girlId = null) {
        const res = await super.post(`mailbox/readmail`, {
            user_id: userId,
            mail_id: mailId,
            girl_id: girlId,
        });
        return res?.data;
    }

    async postReadImage({ userId, attachmentId }: IReadMailImageData) {
        const res = await super.post(`mailbox/readimage`, {
            user_id: userId,
            attachment_id: attachmentId,
        });
        return res?.data;
    }

    async postReadAudio({ userId, attachmentId }: IReadMailAudioData) {
        const res = await super.post(`mailbox/readaudio`, {
            user_id: userId,
            attachment_id: attachmentId,
        });
        return res?.data;
    }

    async postReadVideo({ userId, attachmentId }: IReadMailVideoData) {
        const res = await super.post(`mailbox/readvideo`, {
            user_id: userId,
            attachment_id: attachmentId,
        });
        return res?.data;
    }

    async createChatWithProfile(male_id: number, female_id: number, token = null) {
        try {
            const res = await super.get(
                `chat/chatWithProfile?male_id=` + male_id + `&female_id=` + female_id,
                token ? { cancelToken: token } : {},
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async postViewMedia(attachmentId: number) {
        const res = await super.post(`mailbox/viewmedia`, {
            attachment_id: attachmentId,
        });
        return res?.data;
    }
}
