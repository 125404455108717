import { useEffect } from 'react';
import QuizApi from '../../services/api/v3/QuizApi';
import { useDispatch, useSelector } from 'react-redux';
import * as quizAction from '../../store/actions/quiz';
import { getUrlParameter } from '../../utils/url-utils';
import { RootState } from '../../index';

export default function useInitPollModal() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const openQuizPopup = (delay: number) => {
        setTimeout(() => {
            dispatch(quizAction.setIsActiveQuizModal(true));
        }, delay * 1000);
    };

    useEffect(() => {
        if (userState?.info?.external_id) {
            if (window?.location?.search?.includes('open_quiz')) {
                const quizCategory = +(getUrlParameter('open_quiz') || 0);

                switch (quizCategory) {
                    case 3:
                    case 4:
                        new QuizApi().getQuizByCategory(+quizCategory).then((res) => {
                            if (res?.status && res?.result) {
                                dispatch(quizAction.getQuizData(res?.result));
                                dispatch(quizAction.getTypeList(res?.type_list));
                                openQuizPopup(+res?.result?.delay ?? 0);
                            }
                        });
                        break;
                    default:
                        break;
                }
            } else {
                new QuizApi().getQuiz().then((res) => {
                    if (res?.status && res?.result) {
                        dispatch(quizAction.getQuizData(res?.result));
                        dispatch(quizAction.getTypeList(res?.type_list));
                        openQuizPopup(+res?.result?.delay ?? 0);
                    }
                });
            }
        }
    }, [userState?.info?.external_id]);
}
