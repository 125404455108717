import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import StripeCheckoutForm from './StripeCheckoutForm';
import { sendErrToSentry } from '../../../../services/sentry';
import { Stripe } from '@stripe/stripe-js';
import LoaderGif from '../../../../components/common/LoaderGif';

interface IStripeFormProps {
    clientSecret: string;
    isDefaultCardExist: boolean;
}

export default function StripeForm({ clientSecret, isDefaultCardExist }: IStripeFormProps) {
    const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);

    useEffect(() => {
        try {
            if (process.env.REACT_APP_STRIPE_KEY) {
                import('@stripe/stripe-js').then((module) => {
                    setStripe(module.loadStripe(process.env.REACT_APP_STRIPE_KEY as string));
                });
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }, []);

    if (!clientSecret || !stripe || isDefaultCardExist) return <LoaderGif />;
    return (
        <Elements
            options={{ clientSecret, appearance: { theme: 'stripe' }, loader: 'auto' }}
            stripe={stripe}
        >
            <StripeCheckoutForm />
        </Elements>
    );
}
