export const NEW_MESSAGE = 'NEW_MESSAGE';
export const SUCCESSFULLY_BOUGHT = 'SUCCESSFULLY_BOUGHT';
export const SUCCESS_CONTACT_REQUEST = 'SUCCESS_CONTACT_REQUEST';
export const ENOUGH_CREDITS_REQUEST = 'ENOUGH_CREDITS_REQUEST';
export const NOT_EXIST_OR_BLOCK = 'NOT_EXIST_OR_BLOCK';
export const EMAIL_CONFIRM_SENT = 'EMAIL_CONFIRM_SENT';
export const LETTER_FILES_LIMIT = 'LETTER_FILES_LIMIT';
export const ENOUGH_CREDITS_CHAT = 'ENOUGH_CREDITS_CHAT';
export const ENOUGH_CREDITS_VIDEOSHOW = 'ENOUGH_CREDITS_VIDEOSHOW';
export const ENOUGH_CREDITS_LETTER = 'ENOUGH_CREDITS_LETTER';
export const ENOUGH_CREDITS_TO_VIEW = 'ENOUGH_CREDITS_TO_VIEW';
export const ENOUGH_CREDITS_TO_LISTEN = 'ENOUGH_CREDITS_TO_LISTEN';
export const ADD_TO_BLOCK_LIST = 'ADD_TO_BLOCK_LIST';
export const ADD_TO_TRASH = 'ADD_TO_TRASH';
export const ADD_TO_TRASH_FROM_DIALOGS = 'ADD_TO_TRASH_FROM_DIALOGS';
export const ENOUGH_CREDITS_GIFTS = 'ENOUGH_CREDITS_GIFTS';
export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const VIDEO_MESSAGE_CONTENT = 'VIDEO_MESSAGE_CONTENT';
export const AUDIO_MESSAGE_CONTENT = 'AUDIO_MESSAGE_CONTENT';
export const VIDEO_SHOW_ALERT = 'VIDEO_SHOW_ALERT';
export const MESSAGE_RELOAD_PAGE = 'MESSAGE_RELOAD_PAGE';

export const ALERT_TYPE_LIST = [
    NEW_MESSAGE,
    NOT_EXIST_OR_BLOCK,
    SUCCESSFULLY_BOUGHT,
    SUCCESS_CONTACT_REQUEST,
    EMAIL_CONFIRM_SENT,
    LETTER_FILES_LIMIT,
    ENOUGH_CREDITS_CHAT,
    ENOUGH_CREDITS_VIDEOSHOW,
    ENOUGH_CREDITS_LETTER,
    ENOUGH_CREDITS_TO_VIEW,
    ENOUGH_CREDITS_TO_LISTEN,
    ADD_TO_BLOCK_LIST,
    ADD_TO_TRASH,
    ADD_TO_TRASH_FROM_DIALOGS,
    ENOUGH_CREDITS_GIFTS,
    ENOUGH_CREDITS_REQUEST,
    DELETE_CREDIT_CARD,
    VIDEO_MESSAGE_CONTENT,
    AUDIO_MESSAGE_CONTENT,
    VIDEO_SHOW_ALERT,
    MESSAGE_RELOAD_PAGE,
];
