import { WomanActionTypes } from '../../constants/woman';
import { Dispatch } from 'redux';

export function addWomanList(womanList: any) {
    return function (dispatch: Dispatch<{ type: string; womanList: any }>) {
        return dispatch({
            type: WomanActionTypes.ADD_WOMAN_LIST,
            womanList,
        });
    };
}

export function setIsBlocked(womanExternalID: number, isBlocked: number) {
    return function (
        dispatch: Dispatch<{ type: string; womanExternalID: number; isBlocked: number }>,
    ) {
        return dispatch({
            type: WomanActionTypes.SET_IS_BLOCKED_WOMAN_LIST,
            womanExternalID,
            isBlocked,
        });
    };
}

export function saveSimilarWomanList(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: WomanActionTypes.SET_SIMILAR_WOMAN_LIST,
            data,
        });
    };
}
