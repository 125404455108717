import React, { useEffect, useRef, useState } from 'react';
import SearchToolbarV3 from './SearchToolbarV3';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import SITE from '../../../../config/site';
import styles from './styles.module.scss';
import * as modalsAction from '../../../../store/actions/modals';
import { closeFilterSearch } from '../../../../services/dom';
import { RootState } from '../../../../index';
import { IArrayFields } from '../../../common/SearchFilter/SearchToolbar/index.interface';
import IconFilter from '../../../Icons/IconFilter';

interface ISearchFilterDetailedV3 {
    ageFromFilter: number | string;
    setAgeFromFilter: (value: number | string | IArrayFields) => void;
    ageToFilter: number | string;
    setAgeToFilter: (value: number | string | IArrayFields) => void;
    countryFilter: string;
    handleCountryFilter: (value: string) => void;
    genderFilter: number;
    setGenderFilter: (gender: number | string, sexFilter: number) => void;
    setFilterField: (field: string, value: any) => void;
    filterOnline: boolean;
    isActiveOnlineFilter: boolean;
    nameFilter: string;
    handleOnline: () => void;
}

export default function SearchFilterDetailedV3({
    ageFromFilter,
    setAgeFromFilter,
    ageToFilter,
    setAgeToFilter,
    countryFilter,
    handleCountryFilter,
    genderFilter,
    setGenderFilter,
    setFilterField,
    filterOnline,
    isActiveOnlineFilter = true,
    nameFilter,
    handleOnline,
}: ISearchFilterDetailedV3) {
    const selectRef = useRef<HTMLDivElement | null>(null);
    const select2Ref = useRef<HTMLDivElement | null>(null);

    const modalsState = useSelector((state: RootState) => state.modals);

    const [isOpenFilterDetailedBlock, setIsOpenFilterDetailedBlock] = useState(false);

    const dispatch = useDispatch();

    useOutsideClicker(selectRef, () => setIsOpenFilterDetailedBlock(false), select2Ref);

    const openFilterDetail = () => {
        setIsOpenFilterDetailedBlock(!isOpenFilterDetailedBlock);
    };

    useEffect(() => {
        window.addEventListener('resize', (event: UIEvent) => {
            const target = event.currentTarget as Window;
            if (target.innerWidth >= 1140) {
                dispatch(modalsAction.setActiveSubMenu());
                closeFilterSearch();
            }
        });

        return window.removeEventListener('resize', (event: UIEvent) => {
            const target = event.currentTarget as Window;
            if (target.innerWidth >= 1140) {
                dispatch(modalsAction.setActiveSubMenu());
                closeFilterSearch();
            }
        });
    }, []);

    return (
        <>
            {SITE.ID !== 4 && (
                <div
                    ref={selectRef}
                    className={`c3lh_search_filters_btn_wrap ${isOpenFilterDetailedBlock ? 'active' : ''} ${styles.search_hover}`}
                    onClick={openFilterDetail}
                    data-testid="detailed-filter-btn"
                >
                    <IconFilter />
                    <span>Search filter</span>
                </div>
            )}

            {window.innerWidth < 1140
                ? modalsState?.activeSubmenu === 'filters' && (
                      <div
                          ref={selectRef}
                          className={`c3lh_search_filters_list_wrap c3lh_search_filters_list_wrap_mobile ${styles.filters_mobile}`}
                          data-testid="detailed-filter-block"
                      >
                          <SearchToolbarV3
                              isActiveOnlineFilter={isActiveOnlineFilter}
                              countryFilter={countryFilter}
                              handleCountryFilter={handleCountryFilter}
                              ageFromFilter={ageFromFilter}
                              setAgeFromFilter={setAgeFromFilter}
                              ageToFilter={ageToFilter}
                              setAgeToFilter={setAgeToFilter}
                              genderFilter={genderFilter}
                              setGenderFilter={setGenderFilter}
                              setFilterField={setFilterField}
                              filterOnline={filterOnline}
                              nameFilter={nameFilter}
                              handleOnline={handleOnline}
                          />
                      </div>
                  )
                : isOpenFilterDetailedBlock && (
                      <div
                          ref={select2Ref}
                          className={`c3lh_search_filters_list_wrap ${styles.filters_wrap}`}
                          data-testid="detailed-filter-modal"
                      >
                          <SearchToolbarV3
                              ageFromFilter={ageFromFilter}
                              setAgeFromFilter={setAgeFromFilter}
                              ageToFilter={ageToFilter}
                              setAgeToFilter={setAgeToFilter}
                              countryFilter={countryFilter}
                              handleCountryFilter={handleCountryFilter}
                              genderFilter={genderFilter}
                              setFilterField={setFilterField}
                              setGenderFilter={setGenderFilter}
                              filterOnline={filterOnline}
                              nameFilter={nameFilter}
                              handleOnline={handleOnline}
                          />
                      </div>
                  )}
        </>
    );
}
