import React from 'react';

export default function IconComplain() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="white"
        >
            <path d="M21.0819 25.2473V16.6073C21.0819 16.0109 20.5983 15.5273 20.0019 15.5273C19.4054 15.5273 18.9219 16.0109 18.9219 16.6073V25.2473C18.9219 25.8438 19.4054 26.3273 20.0019 26.3273C20.5983 26.3273 21.0819 25.8438 21.0819 25.2473Z" />
            <path d="M21.502 30.0096C21.502 30.8381 20.8304 31.5096 20.002 31.5096C19.1735 31.5096 18.502 30.8381 18.502 30.0096C18.502 29.1812 19.1735 28.5096 20.002 28.5096C20.8304 28.5096 21.502 29.1812 21.502 30.0096Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.481 4.39583C18.6005 2.45676 21.3993 2.45677 22.5188 4.39583L38.8578 32.6957C39.9773 34.6348 38.5779 37.0586 36.3388 37.0586H3.66098C1.42194 37.0586 0.0225463 34.6347 1.14206 32.6957L17.481 4.39583ZM20.3538 5.64583C20.1965 5.37343 19.8033 5.37343 19.6461 5.64583L3.30713 33.9457C3.14986 34.2181 3.34645 34.5586 3.66098 34.5586H36.3388C36.6534 34.5586 36.85 34.2181 36.6927 33.9457L20.3538 5.64583Z"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_23_97"
                    x1="18.502"
                    y1="15.5273"
                    x2="21.5085"
                    y2="15.5286"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00317B" />
                    <stop offset="1" stopColor="#720066" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_23_97"
                    x1="18.502"
                    y1="15.5273"
                    x2="21.5085"
                    y2="15.5286"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00317B" />
                    <stop offset="1" stopColor="#720066" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_23_97"
                    x1="0.748047"
                    y1="2.94153"
                    x2="39.3361"
                    y2="3.03722"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00317B" />
                    <stop offset="1" stopColor="#720066" />
                </linearGradient>
            </defs>
        </svg>
    );
}
