import React from 'react';
import styles from './styles.module.scss';
import { IEmailUsed } from '../index.interface';
import IconCross from '../../../../Icons/IconCross';

export default function EmailUsed({ closeModal, emailUsedModalText, openSignInBlock }: IEmailUsed) {
    const setOpenSignInBlock = () => {
        closeModal();
        openSignInBlock();
    };

    return (
        <div className={styles.email_used_modal_wrap}>
            <h2 className={styles.modal_text}>{emailUsedModalText}</h2>
            <div className={styles.modal_buttons}>
                <button
                    type="button"
                    className={`${styles.modal_btn} ${styles.grey_btn} ${styles.continue}`}
                    onClick={closeModal}
                >
                    Continue registration
                </button>
                <button
                    type="button"
                    className={`${styles.modal_btn} ${styles.color_btn}`}
                    onClick={setOpenSignInBlock}
                >
                    Sign in
                </button>
            </div>
            <button type="button" className={styles.close_modal} onClick={closeModal}>
                <IconCross />
            </button>
        </div>
    );
}
