import React, { useEffect } from 'react';
import SITE from '../../../config/site';
import { useDispatch, useSelector } from 'react-redux';
import LikeFilterGender from './LikeFilterGender';
import * as likesAction from '../../../store/actions/likes';
import { RootState } from '../../../index';
import { LIKE_FILTERS_GENDER_DEFAULT } from '../../../constants/filter';

interface ILikeFiltersGender {
    wideTab: boolean;
    changeTabNoWide: () => void;
    changeTabWide: () => void;
    setFilterType: (filter: 'ONLINE' | 'LIKED_YOU') => void;
    dictionary: {
        ONLINE: string;
        LIKED_YOU: string;
    };
    submitFilter: () => void;
}

export default function LikeFiltersGender({
    wideTab,
    changeTabNoWide,
    changeTabWide,
    dictionary,
    setFilterType,
    submitFilter,
}: ILikeFiltersGender) {
    const dispatch = useDispatch();

    const likesState = useSelector((state: RootState) => state.likes);

    const tabs = ['ONLINE', 'LIKED_YOU'] as const;
    const filterState = likesState?.filter as typeof LIKE_FILTERS_GENDER_DEFAULT;

    useEffect(() => {
        return () => {
            dispatch(likesAction.initLikesState(LIKE_FILTERS_GENDER_DEFAULT));
        };
    }, []);

    useEffect(() => {
        submitFilter();
    }, [
        filterState?.type,
        filterState?.sexFilter,
        filterState?.country,
        filterState?.from,
        filterState?.to,
    ]);

    useEffect(() => {
        if (likesState?.pageLikeList > 1) {
            dispatch(likesAction.getLikeList(likesState?.pageLikeList, filterState));
        }
    }, [likesState?.pageLikeList]);

    useEffect(() => {
        if (likesState?.list?.length && !likesState?.isLoadedAll) {
            if (likesState?.list?.length - likesState?.selectedListIndex <= 15) {
                dispatch(likesAction.setLikeListPage(likesState?.pageLikeList + 1));
            }
        }
    }, [likesState?.selectedListIndex, likesState?.list?.length]);

    return (
        <>
            {SITE.ID !== 4 && (
                <div className="cl2h_tabs_wrap">
                    <div className="cl2h_tabs_buttons">
                        <div
                            className={`cl2h_tab_btn fast_btn ${!wideTab ? 'active' : ''}`}
                            onClick={changeTabNoWide}
                        >
                            Fast filter
                        </div>
                        <div
                            className={`cl2h_tab_btn wide_btn  ${wideTab ? 'active' : ''}`}
                            onClick={changeTabWide}
                        >
                            Wide filter
                        </div>
                    </div>

                    {!wideTab && (
                        <div className="cl2h_tab cl2h_tab_fast_filter">
                            {tabs.map((item) => (
                                <div
                                    key={item}
                                    className={`cl2h_tab_fast_filter_item ${item === filterState?.type ? 'active' : ''}`}
                                    onClick={() => setFilterType(item)}
                                >
                                    {dictionary[item]}
                                </div>
                            ))}
                        </div>
                    )}

                    {wideTab && (
                        <div className="cl2h_tab cl2h_tab_wide_filter">
                            <LikeFilterGender
                                filter={likesState?.filter as typeof LIKE_FILTERS_GENDER_DEFAULT}
                                setFilter={(value) => dispatch(likesAction.setFilter(value))}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
