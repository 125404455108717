import React, { MouseEventHandler } from 'react';
import styles from '../styles.module.scss';

export default function SuccessNPS({
    closeNPSModal,
}: {
    closeNPSModal: MouseEventHandler<HTMLButtonElement>;
}) {
    return (
        <>
            <p className={styles.nps_modal_text}>
                You have successfully completed the survey and helped us to improve our service.
            </p>
            <button
                type="button"
                className={styles.nps_button_success}
                onClick={closeNPSModal}
                data-testid="ok-btn"
            >
                <span className={styles.nps_button_success_text}>Got it</span>
            </button>
        </>
    );
}
