export enum GiftsActionTypes {
    SAVE_GIFTS = 'SAVE_GIFTS',
    SHOW_SUCCESS_MODAL = 'SHOW_SUCCESS_MODAL',
    CLOSE_SUCCESS_MODAL = 'CLOSE_SUCCESS_MODAL',
    SAVE_CATEGORIES = 'SAVE_CATEGORIES',
    SAVE_GIFTS_BY_CATEGORIES = 'SAVE_GIFTS_BY_CATEGORIES',
    SAVE_FILTER_CATEGORIES_LIST = 'SAVE_FILTER_CATEGORIES_LIST',
    SAVE_FILTER_CATEGORY = 'SAVE_FILTER_CATEGORY',
    ADD_TO_CART = 'ADD_TO_CART',
    DELETE_FROM_CART = 'DELETE_FROM_CART',
    SET_RECIPIENT = 'SET_RECIPIENT',
    SET_RECIPIENT_PROFILE = 'SET_RECIPIENT_PROFILE',
    EMPTY_CART = 'EMPTY_CART',
}
