import React from 'react';
import { cutLengthString } from '../../../../../services/methods';
import styles from './../styles.module.scss';
import * as SYNC from '../../../../../constants/sync';
import { useSelector } from 'react-redux';
import IconCross from '../../../../Icons/IconCross';
import IconChain from '../../../../Icons/IconChain';
import IconHeartMobile from '../../../../Icons/IconHeartMobile';
import IconProfileChat from '../../../../Icons/IconProfileChat';
import { RootState } from '../../../../../index';
import useHandleMobileNotification from '../../../../../hooks/notify/useHandleMobileNotification';

export default function BottomNotificationList() {
    const userState = useSelector((state: RootState) => state.user);
    const notifyState = useSelector((state: RootState) => state.notify);

    const handleMobileNotification = useHandleMobileNotification();

    return (
        <div className="mob_notify_bottom_list">
            {notifyState.mobileBottomList.map((item, key) =>
                !SYNC.TRANSACTION_NOTIFY_LIST.includes(item?.notification_type) ? (
                    <React.Fragment key={key}>
                        <div
                            className={`mob_notify_bottom_list_item ${notifyState?.mobileBottomList?.length > 1 ? 'small' : ''}`}
                        >
                            <div
                                className={`mob_notify_bottom_list_item_photo_wrap ${item?.online ? 'online' : ''}`}
                            >
                                <img
                                    src={item?.photo_link || ''}
                                    alt=""
                                    className="mob_notify_bottom_list_item_photo"
                                />
                            </div>
                            <div
                                className="mob_notify_bottom_list_item_info"
                                onClick={() => handleMobileNotification.openChat(item)}
                            >
                                <div className="mob_notify_bottom_list_item_name">{`${item?.name ?? ''}, ${item?.age ?? ''}`}</div>
                                <div className="mob_notify_bottom_list_item_text">
                                    {(function () {
                                        switch (item?.message_type) {
                                            case SYNC.NOTIFY_SENT_AUDIO:
                                                return 'Sent you a video file';

                                            case SYNC.NOTIFY_SENT_IMAGE:
                                                return `Sent you a photo`;

                                            case SYNC.NOTIFY_SENT_STICKER:
                                                return 'Sent you a sticker';

                                            case SYNC.NOTIFY_SENT_VIDEO:
                                                return 'Sent you a video file';

                                            default:
                                                return cutLengthString(
                                                    item?.message_content ?? '',
                                                    39,
                                                );
                                        }
                                    })()}
                                </div>
                            </div>
                            <div
                                className="mob_notify_bottom_list_item_close"
                                onClick={() => {
                                    handleMobileNotification.removeMobileBottomNotify(
                                        item?.chat_uid,
                                    );
                                }}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    (function () {
                        switch (item?.notification_type) {
                            case SYNC.NOTIFY_NEW_GIRL_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.joined}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p
                                                className={styles.mob_notify_name}
                                            >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                            <p className={styles.mob_notify_text}>
                                                New member’s joined the site
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_STRANGER_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.unknown_like}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap} />
                                        <div className={styles.mob_notify_right}>
                                            <p className={styles.mob_notify_text}>
                                                This member liked your profile
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_POSSIBLE_MATCH_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.ideal_match}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <p className={styles.mob_notify_ideal_text}>
                                            We’ve found the ideal match for you
                                        </p>
                                        <div className={styles.mob_notify_ideal_right}>
                                            {userState?.info?.photo_link ? (
                                                <img
                                                    src={userState?.info?.photo_link}
                                                    alt="user avatar"
                                                    className={styles.mob_notify_ideal_man_photo}
                                                />
                                            ) : (
                                                <div
                                                    className={
                                                        styles.mob_notify_ideal_man_photo_placeholder
                                                    }
                                                >
                                                    <IconProfileChat />
                                                </div>
                                            )}
                                            <IconChain />
                                            <div
                                                className={styles.mob_notify_ideal_woman_photo_wrap}
                                            >
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_ideal_woman_photo}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_PROFILE_UPDATE_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.updated}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p
                                                className={styles.mob_notify_name}
                                            >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                            <p className={styles.mob_notify_text}>
                                                {item?.name ?? ''} has updated content in her...
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_SEARCH_PARTNER_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.searching}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p className={styles.mob_notify_name}>
                                                {`${item?.name ?? ''}, ${item?.age ?? ''}`}
                                            </p>
                                            <p className={styles.mob_notify_text}>
                                                {item?.name ?? ''} is searching for a partner
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_VIEW_PROFILE_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.viewed}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p
                                                className={styles.mob_notify_name}
                                            >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                            <p className={styles.mob_notify_text}>
                                                {item?.name ?? ''} viewed your profile
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_LIKE_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.liked}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p
                                                className={styles.mob_notify_name}
                                            >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                            <p className={styles.mob_notify_text}>
                                                {item?.name ?? ''} liked your profile
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_MATCH_ACTION:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.each_like}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.removeMobileBottomNotify(
                                                item?.chat_uid,
                                            );
                                            handleMobileNotification.openChat(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_activity_each_like_left}>
                                            You like each other
                                        </div>
                                        <div className={styles.mob_notify_activity_each_like_right}>
                                            {userState?.info?.photo_link ? (
                                                <img
                                                    src={userState?.info?.photo_link}
                                                    alt="user avatar"
                                                    className={styles.each_like_man_photo}
                                                />
                                            ) : (
                                                <div
                                                    className={
                                                        styles.each_like_man_photo_placeholder
                                                    }
                                                >
                                                    <IconProfileChat />
                                                </div>
                                            )}

                                            <IconHeartMobile />
                                            <div className={styles.each_like_woman_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    alt=""
                                                    className={styles.each_like_woman_photo}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_each_like_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_ONLINE_NOW:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.online_hi}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p className={styles.mob_notify_text}>
                                                <span>{item?.name ?? ''} is online now!</span>
                                                <br />
                                                Maybe you want to say hi?
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_MAYBE_APPEAL:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.appeal}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p className={styles.mob_notify_text}>
                                                Maybe {item?.name ?? ''}
                                                <span> appeals </span> to you
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_NO_CHAT:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.no_chat}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p className={styles.mob_notify_text}>
                                                {item?.name ?? ''} <span>has no chats</span> now.
                                                Wanna say hi?
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            case SYNC.NOTIFY_MAYBE_CHAT:
                                return (
                                    <div
                                        className={`${styles.mob_notify_activity} ${styles.want_chat}`}
                                        key={key}
                                        onClick={() => {
                                            handleMobileNotification.openWomanProfile(item);
                                        }}
                                    >
                                        <div className={styles.mob_notify_photo_wrap}>
                                            <img
                                                src={item?.photo_link ?? ''}
                                                className={styles.mob_notify_photo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.mob_notify_right}>
                                            <p className={styles.mob_notify_text}>
                                                {item?.name ?? ''} <span>has no chats</span> now.
                                                Wanna say hi?
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className={styles.mob_notify_close}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleMobileNotification.removeMobileBottomNotify(
                                                    item?.chat_uid,
                                                );
                                            }}
                                        >
                                            <IconCross />
                                        </button>
                                    </div>
                                );

                            default:
                                return <div key={key} />;
                        }
                    })()
                ),
            )}
        </div>
    );
}
