import React from 'react';
import styles from '../FAQ/styles.module.scss';
import Topics from './Topics';
import Category from './Category';
import SupportModal from '../common/Modals/SupportModal';
import useInitSupport from '../../hooks/support/useInitSupport';
import useInitTutorialPage from '../../hooks/tutorial/useInitTutorialPage';
import LoaderGif from '../common/LoaderGif';
import Banner from '../Banner';
import { Redirect } from 'react-router-dom';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';

export default function NewTutorial() {
    const initTutorialPage = useInitTutorialPage();
    const initSupport = useInitSupport();
    const handleActiveBlocks = useHandleActiveBlocks();

    const TutorialIcon = () => (
        <svg
            width="17"
            height="21"
            viewBox="0 0 17 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.83691 17.0889C1.83691 16.4921 2.06738 15.9198 2.47762 15.4979C2.88785 15.0759 3.44425 14.8389 4.02441 14.8389H15.8369M1.83691 17.0889C1.83691 17.6856 2.06738 18.2579 2.47762 18.6799C2.88785 19.1018 3.44425 19.3389 4.02441 19.3389H15.8369V1.33887H4.02441C3.44425 1.33887 2.88785 1.57592 2.47762 1.99788C2.06738 2.41983 1.83691 2.99213 1.83691 3.58887V17.0889Z"
                stroke="#00317b"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );

    return (
        <>
            <div className={`column-3 ${styles.faq_column_3}`}>
                {handleActiveBlocks.isTwitterUser ? (
                    <Redirect to="/like" />
                ) : (
                    <>
                        <Banner />

                        <div className={styles.faq_header}>
                            <div className={styles.header_middle}>
                                <TutorialIcon />
                                <span>Tutorial</span>
                            </div>
                        </div>
                        {!initTutorialPage?.tutorialData?.length ? (
                            <LoaderGif />
                        ) : (
                            <div className={styles.faq_content_row}>
                                <Topics {...initTutorialPage} />
                                <Category {...initTutorialPage} />
                            </div>
                        )}
                        <div className={styles.faq_bottom_text}>
                            If you have not found the answer to your question, please contact{' '}
                            <span onClick={initSupport.openSupportModal}>support</span>
                        </div>
                    </>
                )}
            </div>

            <SupportModal {...initSupport} />
        </>
    );
}
