import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function useCheckSpecialPaidAction() {
    const userState = useSelector((state: RootState) => state.user);

    return () =>
        +(userState?.info?.age || 0) <= +(userState?.service_settings?.FREE_ACTION_FROM_AGE || 0) &&
        +(userState?.service_settings?.FREE_ACTION_PRICE || 0) > 0;
}
