import Base from './Base';
import { IAffiliatesRequestData } from './AffiliatsApi.interface';

export default class AffiliatsApi extends Base {
    async postAffiliatsRequest({ contacts, name, message_content }: IAffiliatesRequestData) {
        try {
            return await super
                .postThirdParty(`api/v3/fraud/affiliate`, {
                    contacts,
                    name,
                    message_content,
                })
                .then((res) => res);
        } catch {
            return { status: false };
        }
    }
}
