export enum ChatsActionTypes {
    CHATS_GET = 'CHATS_GET',
    TOUCH_SWIPE_TYPE_CHAT_LIST = 'TOUCH_SWIPE_TYPE_CHAT_LIST',
    CHATS_GET_BY_PAGE = 'CHATS_GET_BY_PAGE',
    BLOCK_CHATS_GET = 'BLOCK_CHATS_GET',
    UPDATE_NEW_MESS = 'UPDATE_NEW_MESS',
    ONLINE_STATUS = 'ONLINE_STATUS',
    SEARCH_INPUT = 'SEARCH_INPUT',
    SET_IS_NEW_MESS = 'SET_IS_NEW_MESS',
    SET_READ_LAST_MESS = 'SET_READ_LAST_MESS',
    FETCH_CHAT_GET = 'FETCH_CHAT_GET',
    FETCH_CHAT_LIST = 'FETCH_CHAT_LIST',
    INIT_CHAT_FILTERS = 'INIT_CHAT_FILTERS',
    ADD_NEW_CHAT = 'ADD_NEW_CHAT',
    IS_OPEN_FROM_NOTIFY = 'IS_OPEN_FROM_NOTIFY',
    REMOVE_FROM_CHATLIST = 'REMOVE_FROM_CHATLIST',
    FETCH_BLOCK_LIST = 'FETCH_BLOCK_LIST',
    REMOVE_FROM_BLOCKLIST = 'REMOVE_FROM_BLOCKLIST',
    UPDATE_MESS_IN_CHAT_LIST = 'UPDATE_MESS_IN_CHAT_LIST',
    READ_MESS = 'READ_MESS',
    SET_FAVORITE_CHAT_LIST = 'SET_FAVORITE_CHAT_LIST',
    SET_LIKE_CHAT_LIST = 'SET_LIKE_CHAT_LIST',

    MAIN_CHAT_START_PAGE = 'MAIN_CHAT_START_PAGE',
    MAIN_CHAT_ADD_INFO = 'MAIN_CHAT_ADD_INFO',
    MAIN_CHAT_REMOVE_INFO = 'MAIN_CHAT_REMOVE_INFO',
    REMOVE_UNREAD_COUNTER = 'REMOVE_UNREAD_COUNTER',
    MAIN_CHAT_ADD_MESSAGE = 'MAIN_CHAT_ADD_MESSAGE',
    MAIN_CHAT_UPDATE_MESSAGE = 'MAIN_CHAT_UPDATE_MESSAGE',
    MAIN_CHAT_DELETE_MESSAGE = 'MAIN_CHAT_DELETE_MESSAGE',
    MAIN_CHAT_ADD_LAST_MESSAGE = 'MAIN_CHAT_ADD_LAST_MESSAGE',
    MAIN_CHAT_ADD_HISTORY = 'MAIN_CHAT_ADD_HISTORY',
    CURRENT_CHAT_SET_FAVORITE_STATUS = 'CURRENT_CHAT_SET_FAVORITE_STATUS',
    SET_PINNED_CURRENT_CHAT = 'SET_PINNED_CURRENT_CHAT',
    SET_PINNED_CHAT_LIST = 'SET_PINNED_CHAT_LIST',
    MAIN_CHAT_ADD_HISTORY_FORCE = 'MAIN_CHAT_ADD_HISTORY_FORCE',
    MAIN_CHAT_ADD_HISTORY_PAGE = 'MAIN_CHAT_ADD_HISTORY_PAGE',
    MAIN_CHAT_SET_HIDE_PAGE_DOWN = 'MAIN_CHAT_SET_HIDE_PAGE_DOWN',
    MAIN_CHAT_ADD_HISTORY_PAGE_LOADING = 'MAIN_CHAT_ADD_HISTORY_PAGE_LOADING',
    SET_READ_LAST_MESS_FROM_YOU = 'SET_READ_LAST_MESS_FROM_YOU',
    SET_CHAT_LIST_ONLINE_STATUS = 'SET_CHAT_LIST_ONLINE_STATUS',
    SET_CHAT_LIST_LIKE_STATUS = 'SET_CHAT_LIST_LIKE_STATUS',
    SET_CURRENT_CHAT_ONLINE_STATUS = 'SET_CURRENT_CHAT_ONLINE_STATUS',
    SET_CURRENT_CHAT_LIKE_STATUS = 'SET_CURRENT_CHAT_LIKE_STATUS',
    UPDATE_CHAT_LIST_ITEM_INFO = 'UPDATE_CHAT_LIST_ITEM_INFO',
}
