import React, { MouseEventHandler } from 'react';
import styles from '../styles.module.scss';
import IconCheckedCircle from '../../../../../Icons/IconCheckedCircle';
import IconArrowInbox from '../../../../../Icons/IconArrowInbox';

interface IAlertMessage {
    titleText: string;
    titleImage?: boolean;
    titleTestId?: string;
    message?: string;
    text?: string;
    subtext?: string;
    mainButtonText: string;
    mainButtonIcon?: string;
    mainButtonTestId?: string;
    mainButtonHandler: MouseEventHandler<HTMLButtonElement>;
    cancelButtonText?: string;
    cancelButtonHandler?: MouseEventHandler<HTMLButtonElement>;
    additionalText?: string;
    isWarning?: boolean;
}

export default function AlertMessage({
    titleText = '',
    titleImage = false,
    titleTestId = 'title',
    message = '',
    text = '',
    subtext = '',
    mainButtonText = '',
    mainButtonIcon = '',
    mainButtonTestId = 'submit-btn',
    mainButtonHandler,
    cancelButtonText = '',
    cancelButtonHandler,
    additionalText = '',
    isWarning = false,
}: IAlertMessage) {
    const getMainButtonIcon = () => {
        switch (mainButtonIcon) {
            case 'check':
                return <IconCheckedCircle />;
            case 'arrow':
                return <IconArrowInbox />;
        }
    };
    const renderText = (text: string) => {
        return text.split(/(<br\s*\/?>|<a\s+href=['"].*?['"]>.*?<\/a>)/gi).map((part, index) => {
            if (/<br\s*\/?>/i.test(part)) {
                return <br key={index} />;
            } else if (/<a\s+href=['"](.*?)['"]>(.*?)<\/a>/i.test(part)) {
                const hrefMatch = part.match(/<a\s+href=['"](.*?)['"]>(.*?)<\/a>/i);
                if (hrefMatch) {
                    const [, href, linkText] = hrefMatch;
                    return (
                        <a key={index} href={href}>
                            {linkText}
                        </a>
                    );
                }
            } else {
                return <React.Fragment key={index}>{part}</React.Fragment>;
            }
        });
    };
    return (
        <>
            {titleText && (
                <h2
                    className={`${styles.alert_modal_title} ${titleImage || isWarning ? styles.alert_modal_title_image : ''} ${isWarning ? styles.warning_icon : ''}`}
                    data-testid={titleTestId}
                >
                    {titleImage && <IconCheckedCircle />}
                    {renderText(titleText)}
                </h2>
            )}
            {text && (
                <p className={styles.alert_modal_text} data-testid="text">
                    {renderText(text)}
                </p>
            )}
            {subtext && <p className={styles.alert_modal_sub_text}>{subtext}</p>}
            {message && (
                <p className={styles.alert_modal_text_email} data-testid="text_email">
                    {message}
                </p>
            )}
            <div
                className={`${isWarning ? styles.alert_modal_btn_row : styles.alert_modal_btn_col}`}
            >
                {mainButtonText && mainButtonHandler && (
                    <button
                        className={styles.alert_modal_btn}
                        type="button"
                        data-testid={mainButtonTestId}
                        onClick={mainButtonHandler}
                    >
                        {getMainButtonIcon()}
                        <span>{mainButtonText}</span>
                    </button>
                )}

                {cancelButtonText && cancelButtonHandler && (
                    <button
                        className={`${styles.alert_modal_cancel_btn} ${isWarning ? styles.outline : ''}`}
                        type="button"
                        data-testid="cancel-btn"
                        onClick={cancelButtonHandler}
                    >
                        {cancelButtonText}
                    </button>
                )}
            </div>
            {additionalText && <p className={styles.alert_modal_sub_text}>{additionalText}</p>}
        </>
    );
}
