import React from 'react';
import SearchResults from '../../components/search/SearchResults';
import Banner from '../../components/Banner';
import { Redirect } from 'react-router-dom';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import SearchFiltersV3 from '../../components/search/SearchFiltersV3';
import { useDispatch, useSelector } from 'react-redux';
import * as searchAction from '../../store/actions/search';
import SearchFiltersV2 from '../../components/search/SearchFiltersV2';
import { RootState } from '../../index';
import { ISearchFilter } from '../../store/reducers/search/index.interface';

export default function SearchPage() {
    const searchState = useSelector((state: RootState) => state.search);
    const userState = useSelector((state: RootState) => state.user);

    const handleActiveBlocks = useHandleActiveBlocks();
    const dispatch = useDispatch();

    return (
        <>
            <div className="column-3 likeher c3_fx">
                <Banner />
                {handleActiveBlocks.isTwitterUser ? (
                    <Redirect to="/like" />
                ) : (
                    <div className="c3lh_your_likes_wrap">
                        {handleActiveBlocks.isSearchV3 ? (
                            <SearchFiltersV3<ISearchFilter>
                                filter={searchState?.filter}
                                setFilter={(value) =>
                                    dispatch(searchAction.setSearchListFilter(value))
                                }
                                isActiveOnlineFilter={!userState?.info?.gender}
                                isLoadingPage={searchState?.isLoadingPage}
                            />
                        ) : (
                            <SearchFiltersV2<ISearchFilter>
                                filter={searchState?.filter}
                                setFilter={(value) =>
                                    dispatch(searchAction.setSearchListFilter(value))
                                }
                                isLoadingPage={searchState?.isLoadingPage}
                            />
                        )}
                        <SearchResults />
                    </div>
                )}
            </div>
        </>
    );
}
