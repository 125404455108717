import * as REGEXP from '../../constants/regExp';

export default function useContactInputValidation() {
    const validate = (value: string): boolean => {
        const isValidLength = value.length >= 5 && value.length <= 50;
        const hasValidSymbols = REGEXP.LATIN_SYMBOLS.test(value);
        const hasNoSpaces = !/\s/.test(value);

        return isValidLength && hasValidSymbols && hasNoSpaces;
    };

    return { validate };
}
