import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { IMailAttachment } from '../../services/api/MailboxApi.interface';

export default function useSlickConfig({
    attachData = [],
}: {
    attachData: IMailAttachment[] | never[];
}) {
    const [isShowArrows, setIsShowArrows] = useState(false);

    const slickContainerRef = useRef<HTMLDivElement | null>(null);
    const sliderRef = useRef<Slider | null>(null);

    const onNextSlide = () => {
        if (sliderRef?.current) sliderRef?.current?.slickNext();
    };

    const onPrevSlide = () => {
        if (sliderRef?.current) sliderRef?.current?.slickPrev();
    };

    const PrevArrow = () => {
        if (!isShowArrows) return null;
        return (
            <div className="c3l_men_list_item_main_btn btn-prev" onClick={onPrevSlide}>
                <img src="../../img/slider-prev.svg" alt="prev" width="14" height="24" />
            </div>
        );
    };

    const NextArrow = () => {
        if (!isShowArrows) return null;
        return (
            <div className="c3l_men_list_item_main_btn btn-next" onClick={onNextSlide}>
                <img src="../../img/slider-next.svg" alt="next" width="14" height="24" />
            </div>
        );
    };

    useEffect(() => {
        const checkSlickWidth = () => {
            if (slickContainerRef?.current) {
                const slickList = slickContainerRef?.current?.querySelector(
                    '.slick-list',
                ) as HTMLElement;
                const slickTrack = slickContainerRef?.current?.querySelector('.slick-track');
                const slickTrackChildren = slickTrack?.children || [];
                let totalWidth = 0;

                for (let i = 0; i < slickTrackChildren?.length; i++) {
                    const child = slickTrackChildren[i] as HTMLElement;
                    totalWidth += child?.offsetWidth;
                }

                if (totalWidth > slickList?.offsetWidth) {
                    setIsShowArrows(true);
                } else {
                    setIsShowArrows(false);
                }
            }
        };

        window.addEventListener('resize', checkSlickWidth);

        setTimeout(() => {
            checkSlickWidth();
        }, 1000);

        return () => {
            window.removeEventListener('resize', checkSlickWidth);
        };
    }, [slickContainerRef, attachData]);

    const settings = {
        className: 'slider variable-width',
        infinite: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

    return {
        settings,
        slickContainerRef,
        sliderRef,
    };
}
