import React from 'react';
import Modal from 'react-modal';

import modalStyleProps from '../../../../styles/react-modal/modal.style';

import WelcomeAlert from './WelcomeAlert';
import { IWelcomeAlertModalProps } from './index.interface';

export default function WelcomeAlertModal({
    isWelcomeAlert,
    closeWelcomeAlertModal,
    openTutorialModal,
}: IWelcomeAlertModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isWelcomeAlert}
            onRequestClose={closeWelcomeAlertModal}
            ariaHideApp={false}
        >
            {!!isWelcomeAlert && (
                <WelcomeAlert
                    closeWelcomeAlertModal={closeWelcomeAlertModal}
                    openTutorialModal={openTutorialModal}
                />
            )}
        </Modal>
    );
}
