import React from 'react';
import Item from './Item';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';
import { NOTIFY_MOBILE_ACTIVE_PAGE_PUSH } from '../../../../constants/notify';

export default function NotifyPushList() {
    const menuState = useSelector((state: RootState) => state.menu);
    const notifyState = useSelector((state: RootState) => state.notify);

    if (!notifyState.mobileList?.length) return <div />;
    if (!NOTIFY_MOBILE_ACTIVE_PAGE_PUSH.includes(menuState?.main)) return <div />;

    return (
        <div className={styles.mob_notify_list_fixed}>
            {notifyState.mobileList?.map((item, key) => (
                <Item key={`${key}_${item?.id}`} item={item} />
            ))}
        </div>
    );
}
