import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as virtualGiftsAction from '../../store/actions/virtualGifts';
import moment from 'moment/moment';
import ChatsAPI from '../../services/api/ChatsApi';
import * as PRICES from '../../constants/prices';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import { switchChatList } from '../../services/methods';
import * as notifyAction from '../../store/actions/notify';
import * as userAction from '../../store/actions/user';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import useHandleRedUser from '../user/useHandleRedUser';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import { RootState } from '../../index';
import { IVirtualGiftItem } from '../../store/reducers/virtualGifts/index.interface';

export default function useVirtualGifts({
    womanExternalId,
    chatUid,
}: {
    womanExternalId: number;
    chatUid: string;
}) {
    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();
    const handleRedUser = useHandleRedUser();

    const virtualGiftsState = useSelector((state: RootState) => state.virtualGifts);
    const userState = useSelector((state: RootState) => state.user);
    const chatsState = useSelector((state: RootState) => state.chats);
    const menuState = useSelector((state: RootState) => state.menu);

    const [isOpenVirtualGifts, setIsOpenVirtualGifts] = useState(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const isStreamPage = window.location?.pathname?.split('/')[1] === 'stream';
    const isMobVirtualGiftsBtn = window.innerWidth <= 1140;

    const virtualGiftListRef = useRef(null);
    const virtualGiftBtnRef = useRef(null);
    const virtualGiftBtnMobRef = useRef(null);

    const toggleVirtualGifts = () => {
        setIsOpenVirtualGifts(!isOpenVirtualGifts);
    };

    const sendVirtualGift = (gift: IVirtualGiftItem) => {
        if (!userState?.info?.external_id) return false;
        if (isLoadingRequest) return false;
        setIsLoadingRequest(true);

        const hashId = moment().unix();

        new ChatsAPI()
            .postMessage(
                userState.info.external_id,
                womanExternalId,
                gift?.id?.toString(),
                PRICES.SENT_VIRTUAL_GIFT,
                gift?.name,
            )
            .then((res) => {
                setIsLoadingRequest(false);

                if (res?.status) {
                    if (!userState?.info?.external_id) return;
                    if (!res?.response?.message_object) {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                                title: res?.title,
                            }),
                        );
                        dispatch(currentChatAction.deleteMessageInHistory(hashId));
                        return;
                    }

                    dispatch(
                        currentChatAction.updateMessageInHistory(
                            hashId,
                            res?.response?.message_object,
                        ),
                    );
                    dispatch(
                        chatsAction.getChatListByUserId(
                            userState?.info?.external_id,
                            chatsState.onlineStatus,
                            chatsState.searchInput,
                            switchChatList(menuState.sub),
                        ),
                    );
                    dispatch(notifyAction.deleteNotify(userState.info.external_id, chatUid));

                    dispatch(userAction.setBalance());
                    handleRedUser.submitLog(
                        RED_USER_LOG_TYPES.MESSAGE_FROM,
                        JSON.stringify({
                            womanExternalID: womanExternalId,
                            action: PRICES.SENT_VIRTUAL_GIFT,
                            content: gift?.id,
                        }),
                    );

                    setIsOpenVirtualGifts(false);
                } else if (res?.block) {
                    dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                            isPaymentBtn: res?.activePayment,
                        }),
                    );
                    dispatch(currentChatAction.deleteMessageInHistory(hashId));
                }
            });
    };

    useEffect(() => {
        if (handleActiveBlocks.isPaymentUser && userState?.giftActive) {
            if (!virtualGiftsState?.chat?.length && !virtualGiftsState?.stream?.length) {
                dispatch(virtualGiftsAction.getVirtualGiftsData());
            }
        }
    }, [userState?.giftActive]);

    return {
        isMobVirtualGiftsBtn,
        isOpenVirtualGifts,
        toggleVirtualGifts,
        virtualGiftsData: isStreamPage ? virtualGiftsState?.stream : virtualGiftsState?.chat,
        sendVirtualGift,
        virtualGiftListRef,
        virtualGiftBtnRef,
        virtualGiftBtnMobRef,
    };
}
