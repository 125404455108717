import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import * as MENU from '../../constants/menu';
import LoaderGif from '../common/LoaderGif';
import SITE from '../../config/site';
import * as DATE from '../../constants/date';
import useInitTermsPage from '../../hooks/term/useInitTermsPage';
import TermCreditsModal from '../common/Modals/TermCreditsModal';
import { RootState } from '../../index';
import IconFAQQuestionBackArrow from '../Icons/IconFAQQuestionBackArrow';
import IconPolicyTerms from '../Icons/IconPolicyTerms';
import IconPolicyPrivacy from '../Icons/IconPolicyPrivacy';
import IconPolicyCookies from '../Icons/IconPolicyCookies';
import IconPolicyRefund from '../Icons/IconPolicyRefund';
import IconPolicyCommunity from '../Icons/IconPolicyCommunity';
import IconPolicyTopUp from '../Icons/IconPolicyTopUp';
import IconPolicyPayment from '../Icons/IconPolicyPayment';
import IconPolicy from '../Icons/IconPolicy';

export default function Policy() {
    const userState = useSelector((state: RootState) => state.user);

    const initTermsPage = useInitTermsPage();

    return (
        <div className={styles.policy_content_block_wrap}>
            <div className={styles.policy_header_wrap}>
                <IconPolicy />
                Policy
            </div>
            <div
                className={`${styles.policy_page_wrap} ${initTermsPage.isMobileOpen ? styles.opened : ''}`}
            >
                <div className={styles.policy_links_list_wrap}>
                    <div className={styles.policy_links_list}>
                        <button
                            type="button"
                            className={`${styles.policy_link_item} ${initTermsPage?.pageName === MENU.LINK_TERMS ? styles.active : ''}`}
                            onClick={() => initTermsPage.changePolicyPage(MENU.LINK_TERMS)}
                        >
                            <IconPolicyTerms />
                            Terms and Conditions
                        </button>
                        <button
                            type="button"
                            className={`${styles.policy_link_item} ${initTermsPage?.pageName === MENU.LINK_PRIVACY ? styles.active : ''}`}
                            onClick={() => initTermsPage.changePolicyPage(MENU.LINK_PRIVACY)}
                        >
                            <IconPolicyPrivacy />
                            Privacy Policy
                        </button>
                        <button
                            type="button"
                            className={`${styles.policy_link_item} ${initTermsPage?.pageName === MENU.LINK_COOKIE ? styles.active : ''}`}
                            onClick={() => initTermsPage.changePolicyPage(MENU.LINK_COOKIE)}
                        >
                            <IconPolicyCookies />
                            Cookies Policy
                        </button>
                        {SITE.ID !== 4 && (
                            <button
                                type="button"
                                className={`${styles.policy_link_item} ${initTermsPage?.pageName === MENU.LINK_RETURN_REFUND ? styles.active : ''}`}
                                onClick={() =>
                                    initTermsPage.changePolicyPage(MENU.LINK_RETURN_REFUND)
                                }
                            >
                                <IconPolicyRefund />
                                Refund Policy
                            </button>
                        )}
                        <button
                            type="button"
                            className={`${styles.policy_link_item} ${initTermsPage?.pageName === MENU.LINK_STANDARDS ? styles.active : ''}`}
                            onClick={() => initTermsPage.changePolicyPage(MENU.LINK_STANDARDS)}
                        >
                            <IconPolicyCommunity />
                            Community Standards
                        </button>
                        {SITE.ID !== 4 && (
                            <button
                                type="button"
                                className={`${styles.policy_link_item} ${initTermsPage?.pageName === MENU.LINK_AUTOMATIC_TOP_UP ? styles.active : ''}`}
                                onClick={() =>
                                    initTermsPage.changePolicyPage(MENU.LINK_AUTOMATIC_TOP_UP)
                                }
                            >
                                <IconPolicyTopUp />
                                Automatic Top-up
                            </button>
                        )}
                        {SITE.ID !== 4 && (
                            <button
                                type="button"
                                className={`${styles.policy_link_item} ${initTermsPage?.pageName === MENU.LINK_ONE_CLICK_UNLIMINT ? styles.active : ''}`}
                                onClick={() =>
                                    initTermsPage.changePolicyPage(MENU.LINK_ONE_CLICK_UNLIMINT)
                                }
                            >
                                <IconPolicyPayment />
                                One-click payment method on Unlimint
                            </button>
                        )}
                    </div>
                    <div className={styles.policy_link_bottom}>
                        {userState?.supportEmail && (
                            <a
                                href={`mailto: ${userState?.supportEmail}`}
                                className={styles.c1_support_mail}
                            >
                                {userState?.supportEmail}
                            </a>
                        )}
                        <div className={styles.copy}>
                            © {DATE.CURRENT_YEAR} {SITE?.copyrightsText} All rights reserved
                        </div>
                    </div>
                </div>
                <div className={styles.policy_content_column}>
                    <div
                        className={styles.mobile_close}
                        onClick={initTermsPage.goBackPolicyPageMob}
                    >
                        <IconFAQQuestionBackArrow />
                        <span>To topics</span>
                    </div>
                    {initTermsPage.policyOpen ? (
                        initTermsPage?.title?.length && initTermsPage?.page?.length ? (
                            <>
                                <div className={styles.policy_content_title}>
                                    {initTermsPage?.title}
                                </div>
                                <div className={styles.policy_content_wrap}>
                                    <div
                                        className={styles.policy_content}
                                        dangerouslySetInnerHTML={{ __html: initTermsPage?.page }}
                                    />
                                </div>
                            </>
                        ) : (
                            <LoaderGif />
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <TermCreditsModal {...initTermsPage} />
        </div>
    );
}
