import React from 'react';

export default function IconVideoCredits() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#00317B"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7.99999 0.9C4.08501 0.9 0.9 4.08502 0.9 8C0.9 11.915 4.08501 15.1 7.99999 15.1C11.915 15.1 15.1 11.915 15.1 8C15.1 4.08499 11.915 0.9 7.99999 0.9ZM7.99999 14.4C4.47121 14.4 1.59998 11.529 1.59998 8C1.59998 4.47096 4.47124 1.60001 7.99999 1.60001C11.5287 1.60001 14.4 4.47099 14.4 8.00003C14.4 11.5291 11.5288 14.4 7.99999 14.4Z" />
            <path d="M8.32717 11C7.69131 11 7.1183 10.8736 6.60813 10.6209C6.10536 10.361 5.7098 10.0036 5.42144 9.54874C5.14048 9.09386 5 8.57762 5 8C5 7.42238 5.14048 6.90614 5.42144 6.45126C5.7098 5.99639 6.10536 5.6426 6.60813 5.38989C7.1183 5.12996 7.69131 5 8.32717 5C8.95564 5 9.50277 5.12996 9.96858 5.38989C10.4418 5.6426 10.7856 6.00722 11 6.48375L9.65804 7.18773C9.3475 6.65343 8.90018 6.38628 8.31608 6.38628C7.86506 6.38628 7.49168 6.53069 7.19593 6.81949C6.90018 7.1083 6.75231 7.5018 6.75231 8C6.75231 8.49819 6.90018 8.8917 7.19593 9.18051C7.49168 9.46931 7.86506 9.61372 8.31608 9.61372C8.90758 9.61372 9.3549 9.34657 9.65804 8.81227L11 9.52708C10.7856 9.98917 10.4418 10.3502 9.96858 10.6101C9.50277 10.87 8.95564 11 8.32717 11Z" />
        </svg>
    );
}
