import * as alertAction from '../../store/actions/alert';
import SupportApi from '../../services/api/SupportApi';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmailPattern } from '../../services/methods';
import { RootState } from '../../index';
import { IFormDataSupport } from './useInitSupport';
import { MouseEvent } from 'react';

export default function useSubmitSupport(
    formDataSupport: IFormDataSupport,
    endCallback: () => void,
) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    return (event: MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();

        if (!formDataSupport?.email?.length || !formDataSupport?.name?.length) {
            return dispatch(
                alertAction.setMessage({ message: 'E-mail address and name are required.' }),
            );
        }

        if (!checkEmailPattern(formDataSupport?.email)) {
            return dispatch(alertAction.setMessage({ message: 'Invalid E-mail address' }));
        }

        if (formDataSupport?.reason?.length) {
            new SupportApi()
                .postHelp({
                    email: formDataSupport?.email,
                    messageContent: formDataSupport?.reason,
                    file: formDataSupport?.file,
                    name: formDataSupport?.name,
                    payment_total: userState?.info?.payment_total || 0,
                    giftActive: +userState?.giftActive || 0,
                })
                .then((res) => {
                    if (res?.status) {
                        dispatch(
                            alertAction.setMessage({
                                title: `Ticket ID #${res?.result}.`,
                                message: `The answer will be sent to your email.\n If you don't receive a reply within 3 days, please write to us at \n <a href='mailto:${userState?.supportEmail}'>${userState?.supportEmail}</a>`,
                            }),
                        );
                    }
                });
            endCallback();
        } else {
            dispatch(alertAction.setMessage({ message: 'Please, describe the reason' }));
        }
    };
}
