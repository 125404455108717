import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRandomIntInclusive, sleepPromise } from '../../services/methods';
import useHandleZendesk from './useHandleZendesk';
import { RootState } from '../../index';

export default function useWebWidget() {
    const userInfo = useSelector((state: RootState) => state.user.info);
    const purchaseState = useSelector((state: RootState) => state.purchase);
    const { zendeskIdentify, zendeskPaymentFailedAgain } = useHandleZendesk();

    useEffect(() => {
        if (userInfo?.external_id) {
            zendeskIdentify();
        }
    }, [userInfo]);

    useEffect(() => {
        (async function () {
            if (!document.hidden) {
                if (purchaseState.isPaymentFailed) {
                    await sleepPromise(getRandomIntInclusive(300, 2000));
                    const localDuplicateSite = localStorage.getItem('duplicate_site');
                    localStorage.setItem('duplicate_site', '1');
                    if (!localDuplicateSite) {
                        if (
                            (purchaseState?.packageBuyCount || 0) >= 1 &&
                            (purchaseState?.packageBuyCount || 0) < 3
                        ) {
                            zendeskPaymentFailedAgain();
                        }
                        setTimeout(() => localStorage.removeItem('duplicate_site'), 5000);
                    }
                }
            }
        })();
    }, [purchaseState.isPaymentFailed, purchaseState.packageBuyCount]);
}
