import React from 'react';
import { IWhitePrimaryButtonProps } from './index.interface';

export default function WhitePrimaryButton({
    onPressButton,
    titleButton = '',
    linkSVGButton = null,
}: IWhitePrimaryButtonProps) {
    return (
        <div
            className="c3l_letters_list_write_go_to_chat"
            onClick={onPressButton}
            data-testid="return-to-letters-btn"
        >
            {linkSVGButton && <img src={linkSVGButton} alt="" />}
            <span>{titleButton}</span>
        </div>
    );
}
