import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as SYNC from '../../constants/sync';
import { v4 as uuidv4 } from 'uuid';
import { localStorageGetItem, localStorageSetItem } from '../../utils/local-storage';
import { sendErrToSentry } from '../sentry';

const RESEND_COUNT = 3;

interface AxiosBaseConfig extends AxiosRequestConfig {
    startTime?: number;
    headers: {
        Authorization: string;
        'X-Request-ID': string;
        fingerprintHash: string;
        'cf-ray'?: string;
    };
}

export default class Base {
    protected url: string;

    constructor() {
        const BASE_URL = process.env.REACT_APP_BACKEND_URL || window.location.origin + '/';
        this.url = BASE_URL + 'api';
    }

    getUserInfo() {
        return localStorageGetItem('token', true);
    }

    getUserFingerprint() {
        const fingerprintHash = localStorageGetItem('fingerprintHash');
        return fingerprintHash === 'null' ? '' : fingerprintHash || '';
    }

    getXRequestID() {
        let xRequestID;
        let uuid;

        try {
            xRequestID = localStorageGetItem('X-Request-ID', true) || '0';
        } catch (err) {
            xRequestID = '0';
        }

        try {
            uuid = uuidv4() || '';
        } catch (err) {
            uuid = '';
        }

        return xRequestID + uuid;
    }

    getAuthHeader() {
        return {
            headers: {
                Authorization: `Bearer ${this.getUserInfo() || ''}`,
                'X-Request-ID': `${this.getXRequestID()}`,
                fingerprintHash: `${this.getUserFingerprint() || ''}`,
            },
        };
    }

    setSyncProvider(syncProvider: string) {
        localStorageSetItem(SYNC.PROVIDER_STORAGE_KEY, syncProvider);
    }

    getUrl(path: string) {
        return `${this.url}/${path}`;
    }

    getTimeout(startTime: number) {
        try {
            return Math.round((new Date().getTime() - startTime) / 1000) + ' sec';
        } catch (err) {
            return null;
        }
    }

    resendRequest(status: number, callback: any, url: string, resend: number) {
        if (+status === 502 && resend > 0) {
            return callback(resend - 1);
        }
    }

    callbackAuthErrorRequest(
        status: number,
        url: string,
        message: string,
        headers: AxiosBaseConfig,
    ) {
        if ([401, 403]?.includes(status) && !url?.includes('/session')) {
            this.bot401Request(message, headers);

            const isAccessKey =
                window?.location?.search?.includes('accessKey') ||
                sessionStorage.getItem('accessKey');

            if (!isAccessKey && localStorageGetItem('redirectDisabled', true) !== 1) {
                localStorage.removeItem('token');

                if (window?.location?.pathname?.length > 1) {
                    window.location.href = '/' + window.location?.search;
                }
            } else {
                localStorage.removeItem('redirectDisabled');
            }
        }
    }

    async botRequest(
        status: number,
        message: string,
        headers: AxiosResponse['headers'],
        headersReq: AxiosBaseConfig,
    ) {
        if (
            !window.origin?.includes('stage') &&
            +status >= 500 &&
            +status < 600 &&
            +status !== 501
        ) {
            const traceData = await axios
                .get(`${window.location.origin}/cdn-cgi/trace`)
                .then((res) => res?.data)
                .catch(() => null);

            const cfRay = headers?.['cf-ray'] || '';
            if (!cfRay) return;

            const externalID = localStorageGetItem('external_id');
            const xRequestID = headersReq?.headers?.['X-Request-ID'] || '';
            const traceMessage = traceData ? traceData?.toString()?.replaceAll('=', ': ') : '';
            const messageData = `${message}\nExternalID: ${externalID}\n\nX-Request-ID: ${xRequestID}\nCf-Ray: ${cfRay}\n${traceMessage}`;

            const extraData = {
                externalID,
                xRequestID,
                cfRay,
                traceMessage,
            };
            sendErrToSentry(new Error(message), status, extraData);

            await axios
                .post(this.getUrl(`sent/500x_error`), { message: messageData })
                .catch(() => null);
        }
    }

    async bot401Request(message: string, headersReq: AxiosBaseConfig) {
        if (message && headersReq?.headers) {
            // TODO: 403 error
            // const xRequestID = headersReq?.headers?.['X-Request-ID'] || '';
            // const authorization = headersReq?.headers?.['Authorization'] || '';
            // const messageData = `${message}\nExternalID: ${localStorageGetItem('external_id')}\n\nX-Request-ID: ${xRequestID}\nAuthorization: ${authorization}\n`;
            //
            // await axios
            //     .post(this.getUrl(`v3/sent/error-401`), { message: messageData })
            //     .catch(() => null);
        }
    }

    get(url: string, config = {}, resend = RESEND_COUNT): Promise<AxiosResponse<any, any> | null> {
        const headers = this.getAuthHeader();

        return axios
            .get(this.getUrl(url), {
                ...headers,
                ...config,
                startTime: new Date().getTime(),
            } as AxiosBaseConfig)
            .then((res: any) => {
                const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${res?.status},\nMethod: 'GET',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;

                this.botRequest(
                    +res?.status,
                    message,
                    res?.headers as AxiosBaseConfig['headers'],
                    headers as AxiosBaseConfig,
                );
                this.resendRequest(
                    +res?.status,
                    (resend: number) => this.get(url, config, resend),
                    url,
                    resend,
                );
                this.setSyncProvider(res.headers['socket-provider']);

                return res;
            })
            .catch((error) => {
                const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${error},\nMethod: 'GET',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;
                this.botRequest(
                    +error?.response?.status,
                    message,
                    error?.response?.headers,
                    headers as AxiosBaseConfig,
                );

                if (axios?.isCancel(error)) return null;

                this.callbackAuthErrorRequest(+error?.response?.status, url, message, headers);
                this.resendRequest(
                    +error?.response?.status,
                    (resend: number) => this.get(url, config, resend),
                    url,
                    resend,
                );
                return null;
            });
    }

    post(url: string, data = {}, config = {}, resend = RESEND_COUNT) {
        const headers = this.getAuthHeader();

        return axios
            .post(this.getUrl(url), data, {
                ...headers,
                ...config,
                startTime: new Date().getTime(),
            } as AxiosBaseConfig)
            .then((res: any) => {
                const message = `Endpoint: ${this.getUrl(url)},\nPayload: ${JSON.stringify(data)},\nResponse: ${res?.status},\nMethod: 'POST',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;

                this.botRequest(+res?.status, message, res?.headers, headers);
                this.resendRequest(
                    +res?.status,
                    (resend: number) => this.post(url, data, config, resend),
                    url,
                    resend,
                );
                this.setSyncProvider(res?.headers['socket-provider']);

                return res;
            })
            .catch((error) => {
                const message = `Endpoint: ${this.getUrl(url)},\nPayload: ${JSON.stringify(data)},\nResponse: ${error},\nMethod: 'POST',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;
                this.botRequest(
                    +error?.response?.status,
                    message,
                    error?.response?.headers,
                    headers,
                );

                if (axios.isCancel(error)) return;

                this.callbackAuthErrorRequest(+error?.response?.status, url, message, headers);
                this.resendRequest(
                    +error?.response?.status,
                    (resend: number) => this.post(url, data, config, resend),
                    url,
                    resend,
                );

                return error?.response || null;
            });
    }

    put(url: string, data = {}, config = {}, resend = RESEND_COUNT) {
        const headers = this.getAuthHeader();

        return axios
            .put(this.getUrl(url), data, {
                ...headers,
                ...config,
                startTime: new Date().getTime(),
            } as AxiosBaseConfig)
            .then((res: any) => {
                const message = `Endpoint: ${this.getUrl(url)},\nPayload: ${JSON.stringify(data)},\nResponse: ${res?.status},\nMethod: 'PUT',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;

                this.botRequest(+res?.status, message, res?.headers, headers);
                this.resendRequest(
                    +res?.status,
                    (resend: number) => this.put(url, data, config, resend),
                    url,
                    resend,
                );
                this.setSyncProvider(res?.headers['socket-provider']);

                return res;
            })
            .catch((error) => {
                const message = `Endpoint: ${this.getUrl(url)},\nPayload: ${JSON.stringify(data)},\nResponse: ${error},\nMethod: 'PUT',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;
                this.botRequest(
                    +error?.response?.status,
                    message,
                    error?.response?.headers,
                    headers,
                );

                if (axios.isCancel(error)) return;

                this.callbackAuthErrorRequest(+error?.response?.status, url, message, headers);
                this.resendRequest(
                    +error?.response?.status,
                    (resend: number) => this.put(url, data, config, resend),
                    url,
                    resend,
                );
            });
    }

    delete(url: string, config = {}, resend = RESEND_COUNT) {
        const headers = this.getAuthHeader();

        return axios
            .delete(this.getUrl(url), {
                ...headers,
                ...config,
                startTime: new Date().getTime(),
            } as AxiosBaseConfig)
            .then((res: any) => {
                const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${res?.status},\nMethod: 'DELETE',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;

                this.botRequest(+res?.status, message, res?.headers, headers);
                this.resendRequest(
                    +res?.status,
                    (resend: number) => this.delete(url, config, resend),
                    url,
                    resend,
                );
                this.setSyncProvider(res.headers['socket-provider']);

                return res;
            })
            .catch((error) => {
                const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${error},\nMethod: 'DELETE',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;
                this.botRequest(
                    +error?.response?.status,
                    message,
                    error?.response?.headers,
                    headers,
                );

                if (axios?.isCancel(error)) return;

                this.callbackAuthErrorRequest(+error?.response?.status, url, message, headers);
                this.resendRequest(
                    +error?.response?.status,
                    (resend: number) => this.delete(url, config, resend),
                    url,
                    resend,
                );
            });
    }

    getThirdParty(url: string, header?: AxiosBaseConfig, resend = RESEND_COUNT) {
        try {
            const headers = this.getAuthHeader();

            return axios
                .get(url, { ...header, startTime: new Date().getTime() } as AxiosBaseConfig)
                .then((res: any) => {
                    const message = `Endpoint: ${window?.location?.origin + url},\nResponse: ${res?.status},\nMethod: 'GET',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;

                    this.botRequest(+res?.status, message, res?.headers, headers);
                    this.resendRequest(
                        +res?.status,
                        (resend: number) => this.getThirdParty(url, header, resend),
                        url,
                        resend,
                    );

                    return res?.data;
                })
                .catch((error) => {
                    const message = `Endpoint: ${window?.location?.origin + url},\nResponse: ${error},\nMethod: 'GET',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;

                    this.botRequest(
                        +error?.response?.status,
                        message,
                        error?.response?.headers,
                        headers,
                    );
                    this.resendRequest(
                        +error?.response?.status,
                        (resend: number) => this.getThirdParty(url, header, resend),
                        url,
                        resend,
                    );
                });
        } catch (error) {
            return null;
        }
    }

    postThirdParty(url: string, data: any, header?: AxiosBaseConfig, resend = RESEND_COUNT) {
        const headers = this.getAuthHeader();

        return axios
            .post(url, data, { ...header, startTime: new Date().getTime() } as AxiosBaseConfig)
            .then((res: any) => {
                const message = `Endpoint: ${window?.location?.origin + url},\nPayload: ${JSON.stringify(data)},\nResponse: ${res?.status},\nMethod: 'POST',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;

                this.botRequest(+res?.status, message, res?.headers, headers);
                this.resendRequest(
                    +res?.status,
                    (resend: number) => this.postThirdParty(url, data, header, resend),
                    url,
                    resend,
                );

                return res?.data;
            })
            .catch((error) => {
                const message = `Endpoint: ${window?.location?.origin + url},\nPayload: ${JSON.stringify(data)},\nResponse: ${error},\nMethod: 'POST',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;

                this.botRequest(
                    +error?.response?.status,
                    message,
                    error?.response?.headers,
                    headers,
                );
                this.resendRequest(
                    +error?.response?.status,
                    (resend: number) => this.postThirdParty(url, data, header, resend),
                    url,
                    resend,
                );
            });
    }
}
