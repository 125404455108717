import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';
import * as MENU from '../../../constants/menu';
import useInitTerm from '../../../hooks/term/useInitTerm';
import PackageList from './PackageList';
import { IPaymentPackageListProps } from './index.interface';
import { RootState } from '../../../index';
import { IPurchaseActiveSubscribedItem } from '../../../store/reducers/purchase/index.interface';
import useBuyCredits from '../../../hooks/purchase/useBuyCredits';
import IconPolicy from '../../../components/Icons/IconPolicy';
import IconCircleInfoV2 from '../../../components/Icons/IconCircleInfoV2';

export default function PaymentPackageList({ onSetActiveSubscribe }: IPaymentPackageListProps) {
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);

    const initTerm = useInitTerm();
    const { buyCreditsByActivePackage } = useBuyCredits();

    const topUpText = `You can choose the option for automatically replenishing your account on our Website. To do this, you need to  switch the toggle for the “Automatic Top-Up” button to the left when buying Credits. Turn on “Automatic Top-Up” to have your balance automatically replenished with the ${purchaseState?.activePackage?.credits} Credits any time your credit balance reaches 5 Credits or less. By turning on “Automatic Top-up” you agree with Automatic Top-up `;

    const Rules = () => {
        return (
            <p className={styles.new_payment_get_credit_btns_top_text}>
                {topUpText}
                <button
                    className={styles.topup_bottom_link}
                    onClick={() => initTerm.openTermModal(MENU.LINK_AUTOMATIC_TOP_UP)}
                >
                    {' '}
                    rules
                </button>
                . You may disable automatic replenishment by switching the “Automatic Top-Up” toggle
                off in Your Profile at any time.
            </p>
        );
    };

    const openAutoPopup = () => setIsAutoPopupOpen(true);
    const closeAutoPopup = () => setIsAutoPopupOpen(false);

    const isFoundActivePackage = (array: IPurchaseActiveSubscribedItem[]) => {
        if (array?.length) {
            return array?.some((el) => +el?.amount === purchaseState?.activePackage?.price);
        }
    };

    return (
        <>
            <PackageList />

            {!!purchaseState?.vat?.rate && (
                <div className={styles.new_payment_taxes}>*Taxes included</div>
            )}

            <div className={styles.new_payment_get_credit_btns}>
                {!!isFoundActivePackage(purchaseState?.activeSubscribeListInfo) &&
                    !purchaseState?.activeSubscribeData && (
                        <div className={styles.new_payment_get_credit_btns_top_row}>
                            <div
                                className={styles.new_payment_get_credit_btns_top_auto_switch_wrap}
                            >
                                {!!isFoundActivePackage(purchaseState?.activeSubscribeListInfo) && (
                                    <div
                                        className={
                                            styles.new_payment_get_credit_btns_top_auto_switch
                                        }
                                    >
                                        <IconPolicy />
                                        <span className={styles.auto_switch_text}>
                                            Automatic Top-up
                                        </span>
                                        <div className={styles.switch_wrap}>
                                            <input
                                                type="checkbox"
                                                id="auto_switch_main"
                                                checked={purchaseState?.activeSubscribeStatus}
                                                onChange={() =>
                                                    onSetActiveSubscribe(
                                                        purchaseState?.activeSubscribeStatus,
                                                        purchaseState.activePaymentMethod,
                                                    )
                                                }
                                            />
                                            <label htmlFor="auto_switch_main" />
                                        </div>
                                        <div
                                            className={styles.auto_switch_info_btn}
                                            onClick={openAutoPopup}
                                        >
                                            <IconCircleInfoV2 />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Rules />
                        </div>
                    )}
                <div className={styles.new_payment_get_credit_btn_wrap}>
                    <button
                        type="button"
                        className={styles.new_payment_get_credit_btn}
                        onClick={buyCreditsByActivePackage}
                        data-testid="get-credit-pack-btn"
                    >
                        Get credit pack
                    </button>
                </div>
            </div>

            <div
                className={`${styles.auto_payment_popup_wrap} ${isAutoPopupOpen ? styles.active : ''}`}
            >
                <div className={styles.auto_payment_popup}>
                    <div className={styles.head}>
                        <span>Automatic Top-up</span>
                        <IconCircleInfoV2 />
                    </div>
                    <div className={styles.text}>{topUpText}</div>
                    <span className={styles.new_payment_get_credit_btn} onClick={closeAutoPopup}>
                        OK
                    </span>
                </div>
            </div>
        </>
    );
}
