import { TermsActionTypes } from '../../constants/terms';
import { Dispatch } from 'redux';

//For policy modal
export function openModel(pageName: string) {
    return function (dispatch: Dispatch<{ type: string; pageName: string }>) {
        return dispatch({
            type: TermsActionTypes.TERMS_OPEN_MODEL,
            pageName: pageName,
        });
    };
}

export function closeModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: TermsActionTypes.TERMS_CLOSE_MODEL,
        });
    };
}

//For policy page, inside the site
export function openPage(pageName: string) {
    return function (dispatch: Dispatch<{ type: string; pageName: string }>) {
        return dispatch({
            type: TermsActionTypes.TERMS_OPEN_PAGE,
            pageName: pageName,
        });
    };
}
