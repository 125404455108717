import * as USER from '../../constants/user';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function useHandleVIP() {
    const userState = useSelector((state: RootState) => state.user);

    const canUserAccessVIP =
        !!userState?.userRoles?.heightRole ||
        !!userState?.userRoles?.maybeHeight ||
        +(userState?.info?.payment_total || 0) >= USER.PAYMENT_TOTAL_TO_SHOW_CONTACTS;

    const isShowVIPBanner = canUserAccessVIP && !+(userState?.info?.is_verified || 0);

    return {
        isShowVIPBanner,
        canUserAccessVIP,
    };
}
