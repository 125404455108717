import React from 'react';

export default function IconSearch() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.33895 10.1818C4.33895 6.66728 7.22022 3.81818 10.7744 3.81818C14.3287 3.81818 17.2099 6.66728 17.2099 10.1818C17.2099 13.6964 14.3287 16.5455 10.7744 16.5455C7.22022 16.5455 4.33895 13.6964 4.33895 10.1818ZM10.7744 2C6.20473 2 2.50024 5.66312 2.50024 10.1818C2.50024 14.7005 6.20473 18.3636 10.7744 18.3636C12.4128 18.3636 13.9399 17.8928 15.225 17.0805L19.9308 21.7337C20.2898 22.0888 20.8719 22.0888 21.231 21.7337C21.59 21.3787 21.59 20.8031 21.231 20.4481L16.6622 15.9304C18.1378 14.4527 19.0486 12.4225 19.0486 10.1818C19.0486 5.66312 15.3441 2 10.7744 2Z"
            />
        </svg>
    );
}
