import React from 'react';
import { useSelector } from 'react-redux';
import useTransition from '../../../hooks/transition/useTransition';
import { RootState } from '../../../index';

export default function GiftsCartButton() {
    const currentGiftsState = useSelector((state: RootState) => state.gifts);
    const { cart } = currentGiftsState;

    const transition = useTransition();

    const cartCount = Object.keys(cart.items).length;

    const goCart = () => {
        if (!cartCount) return;
        transition.transitionWithHistoryPush('gifts', '/gifts/cart');
    };

    return (
        <div className="c3gp_gifts_page_head_cart_btn" onClick={goCart}>
            <img src="/img/gifts/cart.svg" alt="" />
            <span>Cart</span>

            {cartCount ? (
                <span className="c3gp_gifts_page_head_cart_btn_count">{cartCount}</span>
            ) : (
                <div className="c3gp_gifts_page_head_cart_btn_info_text">
                    Your cart is empty. Add some gifts to go to this page
                </div>
            )}
        </div>
    );
}
