import React from 'react';

export default function IconLettersActive() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.30005 7.43632V7.50003V17V18C2.30005 19.1046 3.19548 20 4.30005 20H5.30005H19.3H20.3C21.4046 20 22.3 19.1046 22.3 18V17V7.50003V7.43632L12.6573 12.6595L12.3 12.853L11.9428 12.6595L2.30005 7.43632ZM22.2832 5.73951C22.1556 4.75803 21.3163 4.00003 20.3 4.00003H19.3H12.3H5.30005H4.30005H3.80005C2.51369 4.00003 2.33048 5.10317 2.30438 5.73275L12.3 11.1471L22.2832 5.73951Z"
            />
        </svg>
    );
}
