import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Emoji from './Emoji';
import AttachModal from '../../../common/AttachModal';
import AttachDataToLetter from '../../../common/AttachDataToLetter';
import { convertPrice } from '../../../../services/methods';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import * as filesAction from '../../../../store/actions/files';
import * as LENGTH from '../../../../constants/length';
import useHandleLettersForm from '../../../../hooks/letters/useHandleLettersForm';
import useHandleSmileBlock from '../../../../hooks/smile/useHandleSmileBlock';
import { useDispatch } from 'react-redux';
import SITE from '../../../../config/site';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import SlickContainer from '../../../common/SlickContainer';
import IconSendMessage from '../../../Icons/IconSendMessage';

interface IMessageFormProps {
    setOpenForm: (value: boolean) => void;
    setActiveSmiles: (value: boolean) => void;
    price: string;
}

export default function MessageForm({ setOpenForm, setActiveSmiles, price }: IMessageFormProps) {
    const dispatch = useDispatch();

    const [attachModalIsOpen, setAttachModalIsOpen] = useState(false);

    const titlePrice = `The cost of sending a letter is ${convertPrice(+price)} credits`;

    const openAttachModal = () => {
        setAttachModalIsOpen(true);
        dispatch(filesAction.getAllImages());
        dispatch(filesAction.getAllVideos());
        dispatch(filesAction.getAllAudios());
    };
    const closeAttachModal = () => {
        setAttachModalIsOpen(false);
    };

    const handleLettersForm = useHandleLettersForm({ setOpenForm, closeAttachModal });
    const handleSmileBlock = useHandleSmileBlock(handleLettersForm.setIsOpenMobSmileBlock);

    useEffect(() => {
        setActiveSmiles(handleLettersForm.isOpenMobSmileBlock);
    }, [handleLettersForm.isOpenMobSmileBlock]);

    return (
        <>
            <div className="c3l_letters_list_write_wrap" data-testid="letter-actions">
                <div className="c3l_letters_list_write_area">
                    <div className="c3l_letters_list_write_top">
                        <div
                            className={`c3_write_msg_textareas ${handleLettersForm?.attachData?.length ? 'with_files' : ''}`}
                        >
                            <textarea
                                name=""
                                className={handleLettersForm.isActiveLargeInput ? 'large' : ''}
                                ref={handleLettersForm.inputTextareaRef}
                                maxLength={LENGTH.LETTER_MAX_LENGTH}
                                placeholder="Start writing your letter here"
                                value={handleLettersForm?.inputData}
                                onKeyDown={handleLettersForm.onSubmitMessageEnter}
                                onChange={handleLettersForm.handleChangeInput}
                                data-testid="message"
                            />

                            {!!handleLettersForm?.attachData?.length && (
                                <div className="c3_write_msg_textareas_files">
                                    <div className="letters_attach_files_list">
                                        <SlickContainer attachData={handleLettersForm?.attachData}>
                                            {handleLettersForm?.attachData.map((item, key) => (
                                                <AttachDataToLetter
                                                    key={key}
                                                    index={key}
                                                    item={item}
                                                    deleteAttach={handleLettersForm.deleteAttach}
                                                />
                                            ))}
                                        </SlickContainer>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="c3l_letters_list_write_bottom">
                        <div
                            className="c3l_letters_list_write_attach_btn"
                            onClick={openAttachModal}
                            data-testid="add-files-btn"
                        >
                            {/*<img src={IMAGES.c3_msg_attach} alt=""/>*/}
                            <div className="c3_attach_img"></div>
                            <div className="c3_attach_text">Add files</div>
                        </div>
                        <div
                            className="c3l_letters_list_write_bottom_count"
                            data-testid="entered-symbols"
                        >{`${handleLettersForm?.inputData?.length} symbols entered`}</div>
                        <div className="c3l_letters_list_write_bottom_right">
                            <Emoji {...handleSmileBlock} addEmoji={handleLettersForm.addEmoji} />
                            <div
                                className={`c3_send_btn unpaid ${handleLettersForm?.disabledButton ? 'disabled' : ''} ${handleLettersForm?.isLoadingRequest ? 'blocked' : ''} ${handleLettersForm?.sendButtonClass}`}
                                onClick={() =>
                                    !handleLettersForm?.disabledButton &&
                                    handleLettersForm.submitLetter()
                                }
                                data-testid="send-btn"
                            >
                                <IconSendMessage />
                                <span>Send</span>
                                {SITE.ID !== 4 && (
                                    <div
                                        className="c3_send_btn_cost_text"
                                        data-testid="letter-price"
                                    >
                                        {titlePrice}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {!!(window.innerWidth < 1000 && handleLettersForm?.isOpenMobSmileBlock) && (
                        <div
                            className="mob-smiles"
                            style={{ width: window.innerWidth }}
                            ref={handleLettersForm?.selectRef}
                        >
                            <Picker
                                data={data}
                                onEmojiSelect={handleLettersForm.addEmoji}
                                emojiSize={32}
                                theme="light"
                                navPosition="none"
                                previewPosition="none"
                                searchPosition="none"
                            />
                        </div>
                    )}
                </div>
            </div>
            <Modal
                style={modalStyleProps({ isAttachment: true })}
                isOpen={attachModalIsOpen}
                onRequestClose={closeAttachModal}
                ariaHideApp={false}
            >
                <AttachModal
                    closeModal={closeAttachModal}
                    sendImg={handleLettersForm.sendImg}
                    sendVideo={handleLettersForm.sendVideo}
                    sendAudio={handleLettersForm.sendAudio}
                />
            </Modal>
        </>
    );
}
