import * as modalsAction from '../../store/actions/modals';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as alertAction from '../../store/actions/alert';
import MailboxApi from '../../services/api/MailboxApi';
import * as MENU from '../../constants/menu';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import { useMemo, useState } from 'react';
import VimeoApi from '../../services/api/VimeoApi';
import ProfileApi from '../../services/api/ProfileApi';
import useLike from '../likes/useLike';
import useOpenVideoShow from './useOpenVideoShow';
import { RootState } from '../../index';
import useHandleGiftsPage from '../gifts/useHandleGiftsPage';

export default function useHandleVideoShowItem({ profile }: { profile: any }) {
    const userState = useSelector((state: RootState) => state.user);

    const [videoPoster, setVideoPoster] = useState('');

    const dispatch = useDispatch();
    const transition = useTransition();
    const like = useLike();
    const { openVideo } = useOpenVideoShow(profile?.external_id);
    const handleGiftsPage = useHandleGiftsPage();

    useMemo(() => {
        if (profile?.video_show?.preview_link?.length) {
            setVideoPoster(profile?.video_show?.preview_link);
        } else {
            if (+profile?.video_show?.thumbnail_id) {
                new VimeoApi().getThumbnail(profile?.video_show?.thumbnail_id).then((vimeoRes) => {
                    setVideoPoster(vimeoRes?.data?.[0]?.sizes?.[3]?.link ?? '');

                    new ProfileApi().showNewVimeoThumb(
                        profile?.video_show?.id,
                        vimeoRes?.data?.[0]?.sizes?.[3]?.link ?? '',
                    );
                });
            }
        }
    }, [profile?.video_show?.preview_link]);

    const closeWomanVideoShowModal = () => dispatch(modalsAction.closeWomanVideoShowModal());

    const closeWomanProfileModal = () => dispatch(modalsAction.closeWomanProfileModal());

    const openWomanProfile = () => {
        if (!profile?.external_id) return;
        dispatch(modalsAction.openWomanProfileModal(profile?.external_id));
    };

    const openLetter = () => {
        if (!userState?.info?.external_id) return;

        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        dispatch(
            currentChatMailboxAction.addMailboxChatInfo({
                ...profile,
                sender_image: profile?.avatar || profile?.photo_link,
                sender_name: profile?.name,
                sender_age: profile?.age,
                sender_city: profile?.city_name || profile?.country_name,
                sender_gifts: profile?.gifts,
                sender_search_img: profile?.link,
                sender_id: profile?.external_id,
                female_id: +profile?.external_id,
                male_id: +userState?.info?.external_id,
                openFromChat: true,
            }),
        );
        dispatch(
            currentChatMailboxAction.getMailboxChatHistory({
                userId: +userState?.info?.external_id,
                girlId: profile?.public_external_id || '',
                page: 1,
            }),
        );
        transition.transitionWithHistoryPush('letter', `/letter/`);
        closeWomanVideoShowModal();
        closeWomanProfileModal();
    };

    const openChat = () => {
        if (!userState?.info?.external_id) return;

        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        new MailboxApi()
            .createChatWithProfile(userState?.info?.external_id, profile?.external_id)
            .then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
        closeWomanVideoShowModal();
        closeWomanProfileModal();
    };

    const setLike = () => {
        if (profile?.liked) return;
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        like(profile?.external_id);
    };

    const openGifts = () => {
        const enableGift = profile?.gifts;
        const womanProfile = {
            avatar: profile?.avatar,
            external_id: profile?.external_id,
            public_external_id: profile?.public_external_id,
            online: profile?.online,
            name: profile?.name,
            age: profile?.age,
            country_code: profile?.country_code,
            country_name: profile?.country_name,
            city: profile?.city_name,
        };

        if (enableGift) {
            closeWomanVideoShowModal();
            closeWomanProfileModal();
            handleGiftsPage.openGiftsPage(womanProfile);
        }
    };

    return {
        videoPoster,
        openVideo,
        openWomanProfile,
        setLike,
        openChat,
        openLetter,
        openGifts,
    };
}
