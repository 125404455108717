import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function useUnlockedFeatureModal() {
    const userState = useSelector((state: RootState) => state.user);

    const [isUnlockedFeatureModal, setUnlockedFeatureModal] = useState(false);
    const [typeModal, setTypeModal] = useState(''); //LETTERS or GIFT

    const openUnlockedFeatureModal = (type: string) => {
        setUnlockedFeatureModal(true);
        setTypeModal(type);
    };

    const closeUnlockedFeatureModal = () => {
        setUnlockedFeatureModal(false);
    };

    useEffect(() => {
        if (userState?.needShowLetterModal) {
            openUnlockedFeatureModal('LETTERS');
        } else if (userState?.needShowGiftModal) {
            openUnlockedFeatureModal('GIFT');
        }
    }, [userState?.needShowLetterModal, userState?.needShowGiftModal]);

    return {
        isUnlockedFeatureModal,
        closeUnlockedFeatureModal,
        typeModal,
    };
}
