import Base from './Base';

export default class VideoShowAPI extends Base {
    async getVideo(video_id: number, token = null) {
        try {
            const res = await super.get(`video/${video_id}`, token ? { cancelToken: token } : {});
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async getVideoByExternalID(externalID: number) {
        const res = await super.get(`video`, {
            params: {
                filter: {
                    AGE_FROM: null,
                    AGE_TO: null,
                    HEIGHT_FROM: null,
                    HEIGHT_TO: null,
                    BODY: null,
                    HAIR: null,
                    EYE: null,
                    STATUS: null,
                    NAME: externalID,
                },
            },
        });
        return res?.data;
    }
}
