import styles from '../../styles.module.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertPrice, currencyPriceString } from '../../../../services/methods';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../../index';
import { IPurchasePackageItem } from '../../../../store/reducers/purchase/index.interface';
import * as purchaseAction from '../../../../store/actions/purchase';
import SITE from '../../../../config/site';
import moment from 'moment/moment';

export default function PackageItem() {
    const purchaseState = useSelector((state: RootState) => state.purchase);
    const userState = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();

    // New view buy the first pack
    const checkNewFistPackage = (siteId: number) => {
        if (purchaseState?.purchaseList?.length !== 1) return false;

        const isBTest = userState?.info?.abtest?.toUpperCase() === 'B';
        const isUnik = userState?.tail?.includes('utm_unik=true');
        const isWomanProfile = userState?.info?.gender === 1; // GT-7038

        if (isWomanProfile) return true;

        if (siteId === 7) {
            const isStartDate = moment(userState?.info?.created_at).isAfter('2023-12-07 11:00:00');
            const isEndDate = moment(userState?.info?.created_at).isBefore('2024-01-16 15:00:00');

            const isStartDateV2 = moment(userState?.info?.created_at).isAfter(
                '2024-01-16 16:00:00',
            );

            if (isBTest && isStartDate && isEndDate && purchaseState?.isFirstPurchase && isUnik)
                return true;
            else if (isStartDateV2 && purchaseState?.isFirstPurchase && isUnik) return true; // GT-6926

            return false;
        }

        if (siteId === 6) {
            const isStartDate = moment(userState?.info?.created_at).isAfter('2024-01-16 15:00:00'); // GT-6897
            const isEndDate = moment(userState?.info?.created_at).isBefore('2024-02-15 12:00:00'); // GT-7658

            return !!(
                isBTest &&
                isStartDate &&
                isEndDate &&
                purchaseState?.isFirstPurchase &&
                isUnik
            );
        }

        // if (siteId === 11) {
        //     const isStartDate = moment(userState?.info?.created_at).isAfter('2024-04-09 12:00:00'); // GT-8799 - GT-9871
        //
        //     return !!(isBTest && isStartDate && +purchaseState?.isFirstPurchase && isUnik);
        // }

        if (siteId === 27) {
            const isStartDate = moment(userState?.info?.created_at).isAfter('2024-04-25 15:00:00'); // GT-9374

            return !!(isStartDate && purchaseState?.isFirstPurchase);
        }

        return false;
    };

    const calcDiscount = (price: number, discount: number) => {
        return (price / ((100 - discount) / 100) - price)?.toFixed(0);
    };

    const onSetActivePackage = (item: IPurchasePackageItem) => {
        dispatch(purchaseAction.setActivePackage(item));
    };

    const styleGrid = purchaseState?.isFirstPurchase
        ? purchaseState?.purchaseList?.length === 1
            ? styles.grid_4
            : styles.grid_2
        : purchaseState?.isReverse
          ? `${styles.grid_1} ${userState?.info?.is_verified ? styles.user_vip : ''}`
          : styles.grid_2;

    const styleNewView = checkNewFistPackage(SITE.ID) ? styles.new_view : ''; //GT-5682

    if (!purchaseState?.purchaseList?.length) return <div />;
    return (
        <div
            className={`${styles.new_payment_grid} ${styleGrid} ${styleNewView}`}
            data-testid="package-list-block"
        >
            {purchaseState?.purchaseList?.map((item, key) => (
                <div
                    key={key}
                    className={`
                     ${styles.new_payment_item_wrap}
                     ${handleActiveBlocks?.isNewPaymentPackages ? styles.diamonds : ''}
                     ${item?.vip ? styles.vip : ''}
                     ${styles[`item_${purchaseState?.purchaseList?.length - key}`]}
                     ${purchaseState?.activePackage?.id === +item?.id ? styles.active : ''}
                 `}
                    data-testid={`package-item-${item?.credits}`}
                    onClick={() => onSetActivePackage(item)}
                >
                    <div className={styles.new_payment_item_bg} data-testid="package-image" />
                    <div className={styles.new_payment_item_ribbon} data-testid="package-label" />
                    <div className={styles.new_payment_item}>
                        <div className={styles.new_payment_item_content}>
                            <div
                                className={styles.new_payment_item_credits_count}
                                data-testid="package-amount"
                            >
                                {item?.credits}
                                <span>credits</span>
                            </div>
                            <div className={styles.new_payment_item_bottom}>
                                {!!+item?.discount && (
                                    <div
                                        className={styles.new_payment_item_price}
                                        data-testid="package-discount"
                                    >
                                        -{item?.discount}%
                                    </div>
                                )}
                                <div className={styles.new_payment_item_save_wrap}>
                                    <div
                                        className={styles.new_payment_item_save_count}
                                        data-testid="package-price"
                                    >
                                        {currencyPriceString(item?.currency, item?.price)}
                                    </div>
                                    {!!+calcDiscount(+item?.price, +item?.discount) && (
                                        <div
                                            className={styles.new_payment_item_save_percent}
                                            data-testid="package-without-discount-price"
                                        >
                                            {currencyPriceString(
                                                item?.currency,
                                                +calcDiscount(+item?.price, +item?.discount) +
                                                    +convertPrice(item?.price),
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
