import SITE from '../../../config/site';
import { sendErrToSentry } from '../../sentry';

export function onesignalPush(): void {
    try {
        if (!window?.OneSignal) return;
        if (!window.OneSignal._initCalled && SITE?.onesignalAppId && SITE?.onesignalSafariWebId) {
            window.OneSignal.init({
                appId: SITE.onesignalAppId,
                safari_web_id: SITE.onesignalSafariWebId,
            });
        }

        if (!window.OneSignal.__initAlreadyCalled) {
            window.OneSignal.push(function () {
                window.OneSignal.isPushNotificationsEnabled(function (isEnabled: boolean) {
                    if (!isEnabled) {
                        window.OneSignal.showHttpPrompt();
                    }
                });
            });
        }
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}
