import React from 'react';
import styles from './styles.module.scss';
import { ITutorialData } from '../../../store/reducers/tutorial/index.interface';

interface ITopics {
    tutorialData: ITutorialData[];
    activeTopicId: number | null;
    changeTopic: (topicId: number) => void;
    isMobileCategoryOpened: boolean;
    isPaymentUser: boolean;
}

export default function Topics({
    tutorialData,
    activeTopicId,
    changeTopic,
    isMobileCategoryOpened,
    isPaymentUser,
}: ITopics) {
    return (
        <div className={`${styles.topics_col} ${isMobileCategoryOpened ? styles.mob_opened : ''}`}>
            {tutorialData
                ?.filter((el) =>
                    isPaymentUser ? el : el?.code !== 'CONTACT_REQUEST' && el?.code !== 'GIFTS',
                )
                ?.sort((a, b) => a?.order - b?.order)
                ?.map((el, idx) => (
                    <div
                        key={idx}
                        className={`${styles.topic_item} ${activeTopicId === el?.id ? styles.active : ''}`}
                        onClick={() => changeTopic(el?.id)}
                    >
                        <div className={styles.number} />
                        <div className={styles.title}>{el?.title}</div>
                    </div>
                ))}
        </div>
    );
}
