import { UserActionTypes } from '../../constants/user';
import { SettingsActionTypes } from '../../constants/settings';
import UserApi from '../../../services/api/UserApi';
import { NotifyActionTypes } from '../../constants/notify';
import CommonApi from '../../../services/api/CommonApi';
import ContactsApi from '../../../services/api/v3/Contacts';
import PaymentApi from '../../../services/api/v3/PaymentApi';
import { isMobile } from 'react-device-detect';
import { redirectToMobileVersion } from '../../../services/methods/mobile';
import { Dispatch } from 'redux';
import { localStorageSetItem } from '../../../utils/local-storage';

export function setLoyaltyProgram() {
    return function (dispatch: Dispatch<{ type: string; loyaltyProgram: any }>) {
        return new PaymentApi().getUserLoyaltyProgram().then((res) => {
            if (!res?.status) return null;

            return dispatch({
                type: UserActionTypes.SET_LOYALTY_PROGRAM,
                loyaltyProgram: res?.data,
            });
        });
    };
}

export function setUserEmail(email: string) {
    return function (dispatch: Dispatch<{ type: string; email: string }>) {
        return dispatch({
            type: UserActionTypes.SET_USER_EMAIL,
            email,
        });
    };
}

export function setUserSpendFirstCredit() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: UserActionTypes.SET_USER_SPEND_FIRST_CREDIT,
        });
    };
}

export function setUserRealName(realName: string) {
    return function (dispatch: Dispatch<{ type: string; realName: string }>) {
        return dispatch({
            type: UserActionTypes.SET_USER_REAL_NAME,
            realName,
        });
    };
}

export function setIsApprovePhone(status: boolean, isBonusPhone: number, phone = '') {
    return function (
        dispatch: Dispatch<{ type: string; status: boolean; isBonusPhone: number; phone: string }>,
    ) {
        return dispatch({
            type: UserActionTypes.SET_IS_APPROVE_PHONE,
            status,
            isBonusPhone,
            phone,
        });
    };
}

export function setIsUserVerified() {
    return function (dispatch: Dispatch<{ type: string; isVerified: boolean; isBonus: boolean }>) {
        new ContactsApi().sendVerifyStatus().then((res) => {
            if (res?.status) {
                return dispatch({
                    type: UserActionTypes.SET_IS_USER_VERIFIED,
                    isVerified: res?.result?.isVerified,
                    isBonus: res?.result?.isBonus,
                });
            }
        });
    };
}

export function getInfo(isNoCache: boolean | null = null) {
    return function (
        dispatch: Dispatch<{
            type: string;
            data?: any;
            settings?: any;
            config?: any;
            subscribeConfig?: any;
            isActiveCallButton?: boolean;
        }>,
    ) {
        new UserApi().getInfo(isNoCache).then((res) => {
            if (res?.status && res?.userInfo) {
                localStorageSetItem('user_email', res?.userInfo?.email);
                localStorageSetItem('user_name', res?.userInfo?.name);
                localStorageSetItem('convert_user', res?.userInfo?.convert_user);
                localStorageSetItem('tail', res?.utmTail?.tail);

                if (
                    res?.userInfo?.forMobile &&
                    res?.userInfo?.accessKey?.length &&
                    isMobile &&
                    !window?.location?.search.includes('mPayment')
                ) {
                    return redirectToMobileVersion(
                        res?.userInfo?.accessKey,
                        window?.location?.pathname,
                    );
                }

                if (res?.notification_settings) {
                    dispatch({
                        type: NotifyActionTypes.SET_SETTINGS_BOTTOM,
                        data: res.notification_settings,
                    });
                }

                return dispatch({
                    type: UserActionTypes.USER_INFO_GET,
                    data: res,
                });
            }
        });

        new CommonApi().getAppConfig().then((res) => {
            if (res?.status) {
                dispatch({
                    type: SettingsActionTypes.ADD_ROUTES_SETTINGS,
                    settings: res?.result,
                    config: res?.config,
                    subscribeConfig: res?.subscribeConfig,
                    isActiveCallButton: !!+res?.telConfig,
                    event: res?.event,
                });
            }
        });
    };
}

export function editUserPhotoLink(photoLink: string) {
    return function (dispatch: Dispatch<{ type: string; photoLink: string }>) {
        return dispatch({
            type: UserActionTypes.EDIT_USER_PHOTO_LINK,
            photoLink,
        });
    };
}

export function deleteUserPhotoLink() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: UserActionTypes.DELETE_USER_PHOTO_LINK,
        });
    };
}

export function updateUserInfo(info: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: UserActionTypes.USER_INFO_UPDATE,
            data: info,
        });
    };
}

export function setCredit(isCredit: boolean) {
    return function (dispatch: Dispatch<{ type: string; data: boolean }>) {
        return dispatch({
            type: UserActionTypes.USER_CREDIT,
            data: isCredit,
        });
    };
}

export function addLastBuyCredits(credits: number | null) {
    return function (dispatch: Dispatch<{ type: string; data: number | null }>) {
        return dispatch({
            type: UserActionTypes.ADD_LAST_BUY_CREDITS,
            data: credits,
        });
    };
}

export function isBuying(boolAction: boolean) {
    return function (dispatch: Dispatch<{ type: string; data: boolean }>) {
        return dispatch({
            type: UserActionTypes.IS_BUYING,
            data: boolAction,
        });
    };
}

export function setBalance() {
    return function (dispatch: Dispatch<{ type: string; balance: any }>) {
        new UserApi().getUserBalance().then((res) => {
            if (!res?.status) return;

            dispatch({ type: UserActionTypes.SET_BALANCE, balance: res?.result });
        });
    };
}

export function setImbraData(data = {}) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: UserActionTypes.SET_IMBRA_DATA,
            data,
        });
    };
}

export function setImbraStatus(status: number) {
    return function (dispatch: Dispatch<{ type: string; status: number }>) {
        return dispatch({
            type: UserActionTypes.SET_IMBRA_STATUS,
            status,
        });
    };
}

export function setIsFBConversion(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; status: boolean }>) {
        return dispatch({
            type: UserActionTypes.SET_IS_FB_CONVERSION,
            status,
        });
    };
}

export function updateInfoSex(sexFilter: number) {
    return function (dispatch: Dispatch<{ type: string; sexFilter: number }>) {
        return dispatch({
            type: UserActionTypes.UPDATE_INFO_SEX,
            sexFilter,
        });
    };
}

export function updateUsername(username: string) {
    return function (dispatch: Dispatch<{ type: string; username: string }>) {
        return dispatch({
            type: UserActionTypes.UPDATE_USERNAME,
            username,
        });
    };
}
