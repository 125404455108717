import React, { useState } from 'react';
import * as LENGTH from '../../constants/length';

const RIGHT_SWIPE = 50;
const LEFT_SWIPE = 50;

export default function useTouchMove(rightSwipe = RIGHT_SWIPE, leftSwipe = LEFT_SWIPE) {
    const [touchStart, setTouchStart] = useState(0);
    const [isMoveAction, setIsMoveAction] = useState(false);

    const handleTouchStart = (
        event: React.TouchEvent<HTMLDivElement>,
        callback: (() => void) | null = null,
    ) => {
        if (
            window?.innerWidth < 1000 &&
            window?.innerWidth - LENGTH.WIDTH_RIGHT_SIDE > event?.targetTouches?.[0]?.clientX
        ) {
            setTouchStart(event?.targetTouches?.[0]?.clientX);
            setIsMoveAction(true);

            if (callback) callback();
        }
    };
    const handleTouchMove = (
        event: React.TouchEvent,
        leftCallback: (() => void) | null = null,
        rightCallback: (() => void) | null = null,
    ) => {
        if (isMoveAction) {
            if (touchStart - leftSwipe > event?.targetTouches?.[0]?.clientX) {
                setIsMoveAction(false);

                // animationCallback && animationCallback();
                if (leftCallback) leftCallback();
            }
            if (touchStart + rightSwipe < event?.targetTouches?.[0]?.clientX) {
                setIsMoveAction(false);
                if (rightCallback) rightCallback();
            }
        }
    };

    return {
        handleTouchStart,
        handleTouchMove,
    };
}
