import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentApi from '../../../services/api/v3/PaymentApi';
import * as purchaseActions from '../../../store/actions/purchase';
import * as PURCHASE from '../../../constants/purchase';
import useHandleBilling from '../../billing/useHandleBilling';
import { RootState } from '../../../index';
import { BillingActionEnum } from '../../../services/api/v3/BillingApi.interface';

export default function useInitSubscribe() {
    const dispatch = useDispatch();
    const purchaseState = useSelector((state: RootState) => state.purchase);
    const handleBilling = useHandleBilling();

    const [isOpenUnsubscribeModal, setIsOpenUnsubscribeModal] = useState(false);

    const openChangeAmountSubscribeModal = async () => {
        if (
            purchaseState?.activeSubscribePaymentMethod &&
            purchaseState?.paymentMethodList?.length
        ) {
            await switchGetSubscribeInfo(purchaseState?.activeSubscribePaymentMethod);
            dispatch(purchaseActions.setChangeSubscribeModal(true));
        }
    };

    const openUnsubscribeModal = () => {
        setIsOpenUnsubscribeModal(true);
    };
    const closeUnsubscribeModal = () => setIsOpenUnsubscribeModal(false);

    const onSetActiveSubscribe = (
        activeSubscribeStatus: boolean,
        activePaymentMethodList: string,
    ) => {
        if (!activeSubscribeStatus) {
            handleBilling.createBillingLog(BillingActionEnum.RECURRING_CONFIRM);
        }
        dispatch(purchaseActions.setActiveSubscribe(!activeSubscribeStatus));
        if (activeSubscribeStatus) {
            switchCancelSubscribe(activePaymentMethodList);
        }
    };

    const initSubscribeStatus = () => {
        new PaymentApi().getInitSubscribe().then((res) => {
            dispatch(purchaseActions.initSubscribeStatus(res?.status, res?.data));
        });
    };

    const switchGetSubscribeInfo = async (activePaymentMethod: string, isDeletedCard = false) => {
        switch (activePaymentMethod) {
            case PURCHASE.PAYMENT_METHOD_PASTABANK:
                if (
                    purchaseState?.activeSubscribePaymentMethod &&
                    purchaseState?.activeSubscribeData &&
                    !isDeletedCard
                ) {
                    return new PaymentApi().getPastabankSubscribeInfoList().then((res) => {
                        if (res?.status) {
                            dispatch(purchaseActions.getActiveSubscribeInfo(res?.data));
                        } else {
                            dispatch(purchaseActions.getActiveSubscribeInfo(null));
                        }
                    });
                } else {
                    return new PaymentApi().getPastabankSubscribeInfo().then((res) => {
                        if (res?.status) {
                            dispatch(purchaseActions.getActiveSubscribeInfo(res?.data));
                        } else {
                            dispatch(purchaseActions.getActiveSubscribeInfo(null));
                        }
                    });
                }

            case PURCHASE.PAYMENT_METHOD_CARDPAY:
                if (
                    purchaseState?.activeSubscribePaymentMethod &&
                    purchaseState?.activeSubscribeData
                ) {
                    return new PaymentApi().getCardpaySubscribeInfoList().then((res) => {
                        if (res?.status) {
                            dispatch(purchaseActions.getActiveSubscribeInfo(res?.data));
                        } else {
                            dispatch(purchaseActions.getActiveSubscribeInfo(null));
                        }
                    });
                } else {
                    return new PaymentApi().getCardpaySubscribeInfo().then((res) => {
                        if (res?.status) {
                            dispatch(purchaseActions.getActiveSubscribeInfo(res?.data));
                        } else {
                            dispatch(purchaseActions.getActiveSubscribeInfo(null));
                        }
                    });
                }

            default:
                return '';
        }
    };

    const switchCancelSubscribe = (activePaymentMethod: string) => {
        switch (activePaymentMethod) {
            case PURCHASE.PAYMENT_METHOD_PASTABANK:
                return new PaymentApi().postPastabankCancelSubscribe().then((res) => {
                    if (res?.status) {
                        handleBilling.createBillingLog(BillingActionEnum.RECURRING_CANCEL);
                        dispatch(purchaseActions.cancelActiveSubscribe());
                        closeUnsubscribeModal();
                    }
                });
            case PURCHASE.PAYMENT_METHOD_CARDPAY:
                return new PaymentApi().postCardpayCancelSubscribe().then((res) => {
                    if (res?.status) {
                        handleBilling.createBillingLog(BillingActionEnum.RECURRING_CANCEL);
                        dispatch(purchaseActions.cancelActiveSubscribe());
                        closeUnsubscribeModal();
                    }
                });
            case PURCHASE.PAYMENT_METHOD_ROUTING:
                return new PaymentApi().postRoutingCancelSubscribe().then((res) => {
                    if (res?.status) {
                        handleBilling.createBillingLog(BillingActionEnum.RECURRING_ROUTING_CANCEL);
                        dispatch(purchaseActions.cancelActiveSubscribe());
                        closeUnsubscribeModal();
                    }
                });

            default:
                return '';
        }
    };

    return {
        onSetActiveSubscribe,
        initSubscribeStatus,
        switchGetSubscribeInfo,
        switchCancelSubscribe,
        isOpenUnsubscribeModal,
        openUnsubscribeModal,
        closeUnsubscribeModal,
        openChangeAmountSubscribeModal,
    };
}
