import Base from '../Base';
import { IAskTransactionData, ISaveFrontSocketLogData } from './TransactionApi.interface';

export default class TransactionApi extends Base {
    async postAskTransaction({
        type,
        delay,
        lifeTime,
        configType,
        limit = 0,
        isInvite = false,
        connect = 0,
        delayConnect = 1,
    }: IAskTransactionData) {
        const res = await super.post(
            `v3/transaction/ask/${configType?.toLowerCase()}${isInvite ? '/yes' : ''}`,
            {
                type,
                lifeTime,
                delay,
                limit,
                connect,
                delayConnect,
            },
        );
        return res?.data;
    }

    async setActiveMessage(notifyID: number) {
        return super.get(`v3/transaction/setActiveMessage/${notifyID}`).then((res) => res?.data);
    }

    async saveFrontSocketLog(data: ISaveFrontSocketLogData) {
        return super.post(`v3/transaction/saveFrontSocketLog`, { data }).then((res) => res?.data);
    }
}
