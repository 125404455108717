import React, { useState } from 'react';
import styles from './style.module.scss';

interface ChatMediaProps {
    mediaUrl: string;
    propsClass?: string;
    onClickCallback?: () => void;
    isVideo?: boolean;
    dataTestId?: string;
}

export default function ChatMedia({
    mediaUrl,
    propsClass = '',
    onClickCallback,
    isVideo = false,
    dataTestId = '',
}: ChatMediaProps) {
    const [isLoaded, setIsLoaded] = useState(false);
    const handleMediaLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div className={`${styles.image_container} ${isLoaded ? styles.loaded : styles.loading}`}>
            {isVideo ? (
                <video
                    className={`${styles.image} ${propsClass}`}
                    preload="metadata"
                    data-testid={dataTestId}
                    onLoadedData={handleMediaLoad}
                >
                    <source src={`${mediaUrl}#t=0.6`} type="video/mp4" />
                </video>
            ) : (
                <img
                    className={`${styles.image} ${propsClass}`}
                    src={mediaUrl}
                    alt=""
                    onLoad={handleMediaLoad}
                    onClick={() => onClickCallback && onClickCallback()}
                    data-testid={dataTestId}
                />
            )}
        </div>
    );
}
