import React, { useState, useRef } from 'react';
import styles from './styles.module.scss';
import formStyles from './Form/styles.module.scss';
import global_styles from '../global.module.scss';
import Form from './Form';
import ResetPasswordModal from '../../../../components/common/Modals/ResetPasswordModal';
import EmailUsedModal from '../../../../components/common/Modals/EmailUsedModal';
import * as REGISTER from '../../../../constants/register';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import { checkErrorField } from '../../../../services/methods/register';
import { checkEmailPattern } from '../../../../services/methods';
import { IAuthRegistration } from '../../../../components/Auth/index.interface';

export default function AuthRegistration({
    formDataRegister,
    setFormDataRegister,
    onSubmitFormRegister,
    onBirthdayChange,
    formDataLogin,
    openTermModal,
    errorFields,
    filterErrorFields,
    isOpenSignInBlock,
    openSignInBlock,
    closeSignInBlock,
    activeForgetPassword,
    openResetPassModal,
    closeResetPassModal,
    isOpenEmailUsedModal,
    closeEmailUsedModal,
    emailUsedModalText,
    handleFormDataLoginEmail,
    handleFormDataLoginPassword,
    onSubmitLogin,
    errorLoginFields,
    closeRegisterModal,
    hasCaptcha,
    modalIsActiveRegister,
    recaptchaRef,
}: IAuthRegistration) {
    const [openEmailList, setOpenEmailList] = useState(false);
    const [isPasswordHidden, setPasswordHidden] = useState(true);

    const selectEmailRef = useRef<HTMLDivElement | null>(null);

    useOutsideClicker(selectEmailRef, setOpenEmailList);

    const trimEmailLogin = formDataLogin?.email?.split('@')[0];

    const onSelectDomainLogin = (e: React.MouseEvent<HTMLDivElement>, item: string) => {
        e.stopPropagation();
        handleFormDataLoginEmail(`${trimEmailLogin}@${item}`);
        setOpenEmailList(false);
    };

    const onChangeEmail = (value: string) => {
        handleFormDataLoginEmail(value);

        if (checkEmailPattern(value) || !value?.length) {
            setOpenEmailList(false);
        } else {
            setOpenEmailList(true);
        }
    };

    return (
        <div className={`${styles.main_first_block} ${global_styles.main_section}`}>
            <div className={styles.first_block_bg} />
            <div className={styles.first_block_content_wrap_2}>
                <div className={global_styles.new_main_container}>
                    <div className={styles.first_block_content}>
                        <div className={styles.first_block_content_wrap}>
                            <div
                                className={styles.first_block_text_wrap}
                                id="anchor-form-scroll-mob"
                            >
                                <div className={styles.first_block_text_title}>
                                    Engage in conversations that stir genuine emotions, bonding over
                                    common interests
                                </div>
                                <div className={styles.first_block_text_content}>
                                    Become a member of our friendly family and see how efficient our
                                    services are. We will be by your side whenever you need
                                    professional assistance!
                                </div>
                            </div>
                            <div
                                className={`${styles.first_block_sign_forms_wrap} ${isOpenSignInBlock ? styles.sign_in : ''}`}
                            >
                                <div
                                    className={styles.first_block_sign_reg_wrap}
                                    data-testid="registration-modal"
                                >
                                    <div className={styles.first_block_registr_wrap}>
                                        <p className={styles.first_block_registr_title}>
                                            Register for free today
                                        </p>
                                        <Form
                                            formDataRegister={formDataRegister}
                                            setFormDataRegister={setFormDataRegister}
                                            onRegister={onSubmitFormRegister}
                                            onBirthdayChange={onBirthdayChange}
                                            openTermModal={openTermModal}
                                            errorFields={errorFields}
                                            filterErrorFields={filterErrorFields}
                                            openSignInBlock={openSignInBlock}
                                            closeRegisterModal={closeRegisterModal}
                                            hasCaptcha={hasCaptcha}
                                            modalIsActiveRegister={modalIsActiveRegister}
                                            recaptchaRef={recaptchaRef}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={styles.first_block_sign_in_wrap}
                                    data-testid="login-modal"
                                >
                                    <div className={styles.first_block_registr_title}>Sign In</div>
                                    <form
                                        onSubmit={onSubmitLogin}
                                        className={styles.first_block_sign_in_form}
                                    >
                                        <div
                                            className={
                                                styles.first_block_registr_form_text_fieldset
                                            }
                                        >
                                            <div
                                                className={`${formStyles.first_block_registr_form_text_row} ${formStyles.with_email_help} ${openEmailList ? formStyles.opened : ''}`}
                                                ref={selectEmailRef}
                                            >
                                                <input
                                                    autoComplete="username"
                                                    type="email"
                                                    className={`${formStyles.first_block_registr_form_input} ${formStyles[`${checkErrorField(errorLoginFields, REGISTER.FIELD_EMAIL)}`]}`}
                                                    name="email"
                                                    data-testid="email"
                                                    placeholder="Email"
                                                    value={formDataLogin?.email}
                                                    onChange={(e) => onChangeEmail(e.target.value)}
                                                />
                                                <div
                                                    className={
                                                        formStyles.first_block_registr_form_emails_list_wrap
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            formStyles.first_block_registr_form_emails_list
                                                        }
                                                    >
                                                        {REGISTER.DOMAIN_ARRAY.map(
                                                            (item, index) => (
                                                                <div
                                                                    key={`${index}_${item}`}
                                                                    className={
                                                                        formStyles.first_block_registr_form_emails_list_item
                                                                    }
                                                                    onClick={(e) =>
                                                                        onSelectDomainLogin(e, item)
                                                                    }
                                                                >
                                                                    {trimEmailLogin}@{item}
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    formStyles.first_block_registr_form_text_row
                                                }
                                            >
                                                <input
                                                    autoComplete="current-password"
                                                    type={isPasswordHidden ? 'password' : 'text'}
                                                    className={`${formStyles.first_block_registr_form_input} ${formStyles.first_block_registr_form_password} ${formStyles[`${checkErrorField(errorLoginFields, REGISTER.FIELD_PASSWORD)}`]}`}
                                                    name="password"
                                                    data-testid="password"
                                                    placeholder="Password"
                                                    value={formDataLogin?.password}
                                                    onChange={(e) =>
                                                        handleFormDataLoginPassword(e.target.value)
                                                    }
                                                />
                                                <button
                                                    type="button"
                                                    className={`${styles.first_block_registr_password_show} ${!isPasswordHidden ? styles.active : ''}`}
                                                    onClick={() =>
                                                        setPasswordHidden(!isPasswordHidden)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={styles.first_block_sign_in_reset_btn}
                                            onClick={openResetPassModal}
                                            data-testid="forgot-password-btn"
                                        >
                                            Forgot a password?
                                        </div>
                                        <button
                                            type="submit"
                                            className={styles.first_block_registr_form_find_btn}
                                            data-testid="submit-btn"
                                        >
                                            <span
                                                className={
                                                    styles.first_block_registr_form_find_btn_text
                                                }
                                            >
                                                Login
                                            </span>
                                        </button>
                                    </form>

                                    <div className={styles.registr_form_footer}>
                                        <p className={styles.registr_form_footer_text}>
                                            Don’t have a account?
                                        </p>
                                        <button
                                            type="button"
                                            className={styles.registr_form_footer_btn}
                                            onClick={closeSignInBlock}
                                            data-testid="sign-up-btn"
                                        >
                                            <span className={styles.registr_form_footer_btn_text}>
                                                Sign Up
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ResetPasswordModal
                activeForgetPassword={activeForgetPassword}
                closeResetPassModal={closeResetPassModal}
                formDataLogin={formDataLogin}
                handleFormDataLoginEmail={handleFormDataLoginEmail}
                onSubmitLogin={onSubmitLogin}
            />
            <EmailUsedModal
                isOpenEmailUsedModal={isOpenEmailUsedModal}
                closeEmailUsedModal={closeEmailUsedModal}
                emailUsedModalText={emailUsedModalText}
                openSignInBlock={openSignInBlock}
            />
        </div>
    );
}
