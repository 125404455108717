import React from 'react';

export default function IconFAQQuestionBackArrow() {
    return (
        <svg
            width="9"
            height="17"
            viewBox="0 0 9 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9 1.13799L2.0812 8.4994L8.99945 15.8605L7.98901 17L3.71573e-07 8.49939L7.98901 -4.41917e-08L9 1.13799Z" />
        </svg>
    );
}
