import Base from './Base';
import {
    ICreateMailUnsubscribeStepData,
    IPostMailUnsubscribeData,
    ISaveMailUnsubscribeStepData,
} from './UnsubscribeApi.interface';

export default class UnsubscribeAPI extends Base {
    async checkMailUnsubscribe() {
        try {
            const res = await super.get(`unsubscribe/checkUnsubscribe`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async createMailUnsubscribeStep(data: ICreateMailUnsubscribeStepData) {
        try {
            const res = await super.post(`unsubscribe/createStep`, data);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async saveMailUnsubscribeStep(data: ISaveMailUnsubscribeStepData) {
        try {
            const res = await super.post(`unsubscribe/saveStep`, data);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postMailUnsubscribe(data: IPostMailUnsubscribeData) {
        try {
            const res = await super.post(`unsubscribe/unsubscribe`, data);
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
