import React from 'react';
import Menu from '../../components/MobileMenu';

export default function MenuPage() {
    return (
        <>
            <Menu />
        </>
    );
}
