import EmptyResult from '../../common/EmptyResult';
import React from 'react';
import LoaderGif from '../../common/LoaderGif';
import '../../../styles/liluclub.css';
import VideoShowDefaultList from './VideoShowDefaultList';
import VideoShowWithBannerList from './VideoShowWithBannerList';
import useHandleVIP from '../../../hooks/vip/useHandleVIP';
import useSetVideoShowList from '../../../hooks/search/useSetVideoShowList';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import { IVideoShowState } from '../../../store/reducers/videoShow/index.interface';

export default function VideoShowList() {
    const videoShowState: IVideoShowState = useSelector((state: RootState) => state.videoShow);

    const { isShowVIPBanner } = useHandleVIP();
    const setVideoShowList = useSetVideoShowList();

    return (
        <div className="c3mp_videos_list_wrap" onScroll={setVideoShowList.handleScroll}>
            {videoShowState.isLoading ? (
                <LoaderGif />
            ) : videoShowState.isEmpty ? (
                <EmptyResult setDefaultFilterValue={setVideoShowList.setDefaultFilterValue} />
            ) : isShowVIPBanner ? (
                <VideoShowWithBannerList />
            ) : (
                <VideoShowDefaultList />
            )}
        </div>
    );
}
