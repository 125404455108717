import React from 'react';
import { useSelector } from 'react-redux';
import useTransition from '../../../hooks/transition/useTransition';
import { closeMobChatList } from '../../../services/dom';
import * as MENU from '../../../constants/menu';
import SITE from '../../../config/site';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../index';

export default function ProfileHeader() {
    const giftsState = useSelector((state: RootState) => state.gifts);
    const settingsState = useSelector((state: RootState) => state.settings);

    const transition = useTransition();
    const handleActiveBlocks = useHandleActiveBlocks();

    const linkToPage = (name: string) => {
        return window.location.pathname === `/${name}`
            ? null
            : transition.transitionWithOnlyHistoryPush(`/${name}`);
    };

    return (
        <header>
            <div className="container">
                <div className="c3_header" data-testid="header-block-navigation">
                    <nav className="header-menu">
                        <div className="header-menu-element">
                            <button
                                className={`header-menu-btn pointer-cursor ${window?.location?.pathname === `/${MENU.PROFILE_PAGE}` ? 'active' : ''}`}
                                onClick={() => linkToPage(MENU.PROFILE_PAGE)}
                                data-testid="profile-nav-button"
                            >
                                <div className="header-menu-item">
                                    <div className="c3ph_img c3ph_img_edit" />
                                    <div className="header-menu-text header-prof">Profile</div>
                                </div>
                            </button>
                        </div>
                        {!!(settingsState?.route?.credit && SITE.ID !== 4) && (
                            <div className="header-menu-element">
                                <button
                                    className={`header-menu-btn pointer-cursor ${window?.location?.href?.includes(MENU.CREDITS_PAGE) ? 'active' : ''}`}
                                    onClick={() => linkToPage(MENU.CREDITS_PAGE)}
                                    data-testid="credicts-nav-button"
                                >
                                    <div className="header-menu-item">
                                        <div className="c3ph_img c3ph_img_purchase" />
                                        <div className="header-menu-text">Credits</div>
                                    </div>
                                </button>
                            </div>
                        )}
                        {!handleActiveBlocks.isHideGift && (
                            <div className="header-menu-element">
                                <button
                                    className={`header-menu-btn pointer-cursor ${window?.location?.href?.includes(MENU.GIFTS_PAGE) ? 'active' : ''}`}
                                    onClick={() => linkToPage(MENU.GIFTS_PAGE)}
                                    data-testid="gift-nav-button"
                                >
                                    <div className="header-menu-item">
                                        <div className="c3ph_img c3ph_img_gift" />
                                        <div className="header-menu-text header-gif">
                                            Sent gifts
                                        </div>
                                    </div>
                                </button>
                                {+giftsState?.newGiftMessageCount > 0 ? (
                                    <div className="header-menu-item-notif">new messages</div>
                                ) : null}
                            </div>
                        )}
                        {handleActiveBlocks.isContactRequest && (
                            <div className="header-menu-element">
                                <button
                                    className={`header-menu-btn pointer-cursor ${window?.location?.href?.includes(MENU.CONTACT_PAGE) ? 'active' : ''}`}
                                    onClick={() => linkToPage(MENU.CONTACT_PAGE)}
                                    data-testid="contact-nav-button"
                                >
                                    <div className="header-menu-item">
                                        <div className="c3ph_img c3ph_img_contact" />
                                        <div className="header-menu-text ">Contact requests</div>
                                    </div>
                                </button>
                            </div>
                        )}
                        {handleActiveBlocks.isMeetingRequest && (
                            <div className="header-menu-element">
                                <button
                                    className={`header-menu-btn pointer-cursor ${window?.location?.href?.includes(MENU.MEETING_PAGE) ? 'active' : ''}`}
                                    onClick={() => linkToPage(MENU.MEETING_PAGE)}
                                    data-testid="meetong-nav-button"
                                >
                                    <div className="header-menu-item">
                                        <div className="c3ph_img c3ph_img_meet" />
                                        <div className="header-menu-text">Meeting requests</div>
                                    </div>
                                </button>
                            </div>
                        )}
                        <div
                            className="header-menu-element"
                            onClick={() => linkToPage(MENU.BLOCK_LIST_PAGE)}
                        >
                            <button
                                className={`header-menu-btn pointer-cursor ${window?.location?.href?.includes(MENU.BLOCK_LIST_PAGE) ? 'active' : ''}`}
                                data-testid="block-list-nav-button"
                            >
                                <div className="header-menu-item">
                                    <div className="c3ph_img c3ph_img_block" />
                                    <div className="header-menu-text text-nowrap">Block list</div>
                                </div>
                            </button>
                        </div>
                    </nav>
                    <div className="profile_header_menu_close" onClick={closeMobChatList}>
                        <img src="../../../img/cross_white.svg" alt="" />
                    </div>
                </div>
            </div>
        </header>
    );
}
