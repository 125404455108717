import React from 'react';
import { useSelector } from 'react-redux';
import InputTextField from './InputTextField';
import Emoji from './Emoji';
import SendButton from './SendButton';
import Attach from './Attach';
import useHandleSmileBlock from '../../../../../hooks/emoji/smile/useHandleSmileBlock';
import useHandleStickerBlock from '../../../../../hooks/emoji/sticker/useHandleStickerBlock';
import useSendSticker from '../../../../../hooks/emoji/sticker/useSendSticker';
import { convertPrice } from '../../../../../services/methods';
import * as PRICES from '../../../../../constants/prices';
import * as STICKERS from '../../../../../constants/stickers';
import SITE from '../../../../../config/site';
import * as LENGTH from '../../../../../constants/length';
import VirtualGiftsList from './VirtualGiftsList';
import useVirtualGifts from '../../../../../hooks/virtualGifts/useVirtualGifts';
import { SUPPORT_WOMAN } from '../../../../../constants/user';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { RootState } from '../../../../../index';
import * as MENU from '../../../../../constants/menu';
import { IMessageFormProps } from './index.interface';
import { IStickerItem } from './Emoji/index.interface';

export default function MessageForm({
    selectRef,
    buttonRef,
    btnStickerRef,
    isOpenMobSmileBlock,
    setIsOpenMobSmileBlock,
    isOpenMobStickerBlock,
    setIsOpenMobStickerBlock,
    selectStickerRef,
    inputData,
    handleInput,
    onSubmitMessage,
    addEmoji,
    onSubmitMessageEnter,
    handleLike,
    inputTextareaRef,
    writeMsgRef,
    isLoadingRequest,
}: IMessageFormProps) {
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const handleSmileBlock = useHandleSmileBlock(setIsOpenMobSmileBlock, setIsOpenMobStickerBlock);
    const handleStickerBlock = useHandleStickerBlock(
        setIsOpenMobStickerBlock,
        setIsOpenMobSmileBlock,
    );
    const sendSticker = useSendSticker();
    const virtualGifts = useVirtualGifts({
        womanExternalId: currentChatState?.info?.id || 0,
        chatUid: currentChatState?.info?.chat_uid || '',
    });

    const isSupportWoman = currentChatState?.info?.id === +SUPPORT_WOMAN;
    const inBlockList = menuState.main === MENU.MENU_BLOCK;

    const onSelectSticker = (stickerName: string) => {
        if (!currentChatState?.info?.id) return;

        setIsOpenMobStickerBlock(false);
        sendSticker(stickerName, currentChatState?.info?.id, currentChatState?.info?.chat_uid);
    };

    const stickerListMap = (stickerArray: IStickerItem[]) => {
        return (
            !!stickerArray?.length &&
            stickerArray?.map((sticker, index) => (
                <div
                    className="c3_touch_stickers_list_item"
                    onClick={(event) => {
                        event?.stopPropagation();
                        onSelectSticker(sticker?.name);
                    }}
                    key={`${sticker?.name}_${index}`}
                >
                    <img src={sticker?.image} alt="" />
                </div>
            ))
        );
    };

    return (
        <>
            {!inBlockList ? (
                <div
                    ref={writeMsgRef}
                    // Comment to return view as in prod (after start develop new mobile chat)
                    // className={`c3_write_msg ${handleSmileBlock?.isSmileBlock ? 'smiles_open' : ''} ${isActiveLargeInput ? 'big' : ''}`}>
                    className={`c3_write_msg ${handleSmileBlock?.isSmileBlock ? 'smiles_open' : ''}`}
                    data-testid="message-actions"
                >
                    <InputTextField
                        // Comment to return view as in prod (after start develop new mobile chat)
                        // isActiveLargeInput={isActiveLargeInput}
                        inputTextareaRef={inputTextareaRef}
                        inputData={inputData}
                        handleInput={handleInput}
                        onSubmitMessageEnter={onSubmitMessageEnter}
                    />
                    <div className="chat_message_counter" data-testid="message-counter">
                        {inputData?.length}/{LENGTH.MESSAGE_MAX_LENGTH}
                    </div>
                    <Attach />
                    {!currentChatState?.info?.liked && !isSupportWoman && (
                        <div
                            className="chat_hello_like_btn"
                            onClick={handleLike}
                            data-testid="like-btn"
                        >
                            <div className="chat_like_btn_img" />
                            <span>Like</span>
                        </div>
                    )}
                    {
                        // Desktop Button Virtual GiftSuccessModal
                        !!virtualGifts?.virtualGiftsData?.length &&
                            !virtualGifts.isMobVirtualGiftsBtn &&
                            !isSupportWoman && (
                                <button
                                    className={`chat_virtual_gift_btn ${virtualGifts.isOpenVirtualGifts ? 'active_btn' : ''}`}
                                    type="button"
                                    ref={virtualGifts.virtualGiftBtnRef}
                                    onClick={virtualGifts.toggleVirtualGifts}
                                >
                                    Virtual gift
                                </button>
                            )
                    }
                    <Emoji
                        isSmileBlock={handleSmileBlock.isSmileBlock}
                        openSmileBlock={handleSmileBlock.openSmileBlock}
                        closeSmileBlock={handleSmileBlock.closeSmileBlock}
                        smileBoxRef={handleSmileBlock.smileBoxRef}
                        isStickerBlock={handleStickerBlock.isStickerBlock}
                        openStickerBlock={handleStickerBlock.openStickerBlock}
                        closeStickerBlock={handleStickerBlock.closeStickerBlock}
                        stickerBoxRef={handleStickerBlock.stickerBoxRef}
                        addEmoji={addEmoji}
                        btnStickerRef={btnStickerRef}
                        isOpenMobSmileBlock={isOpenMobSmileBlock}
                        setIsOpenMobSmileBlock={setIsOpenMobSmileBlock}
                        isOpenMobStickerBlock={isOpenMobStickerBlock}
                        setIsOpenMobStickerBlock={setIsOpenMobStickerBlock}
                    />
                    <SendButton
                        buttonRef={buttonRef}
                        onSubmitMessage={onSubmitMessage}
                        isLoadingRequest={isLoadingRequest}
                    />
                </div>
            ) : (
                <div />
            )}
            {window.innerWidth < 1000 && isOpenMobStickerBlock ? (
                <div ref={selectStickerRef} className={'c3_touch_stickers_wrap active'}>
                    {STICKERS.ARRAY_ALL_STICKER.map((item, index) => (
                        <div className="c3_touch_stickers_row" key={index}>
                            <div className="c3_touch_stickers_head">
                                <div className="c3_touch_stickers_head_title">{item?.title}</div>
                                {SITE.ID !== 4 && (
                                    <>
                                        <div className="c3_touch_stickers_head_price">
                                            The cost of sending one sticker is{' '}
                                            {convertPrice(
                                                +(userState?.prices?.[PRICES.SENT_STICKER] || 0),
                                            )}{' '}
                                            credits
                                        </div>
                                        {index === 0 && (
                                            <div className="c3_touch_stickers_head_price mob">
                                                Оne sticker costs{' '}
                                                {convertPrice(
                                                    +(
                                                        userState?.prices?.[PRICES.SENT_STICKER] ||
                                                        0
                                                    ),
                                                )}{' '}
                                                credits
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="c3_touch_stickers_list_wrap">
                                <div className="c3_touch_stickers_list">
                                    {stickerListMap(item?.stickerArray)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div />
            )}
            {window?.innerWidth < 1000 && isOpenMobSmileBlock ? (
                <div className="mob-smiles" style={{ width: window?.innerWidth }} ref={selectRef}>
                    <Picker
                        data={data}
                        onEmojiSelect={addEmoji}
                        emojiSize={32}
                        theme="light"
                        navPosition="none"
                        previewPosition="none"
                        searchPosition="none"
                    />
                </div>
            ) : (
                <div />
            )}

            {virtualGifts.isOpenVirtualGifts && !isSupportWoman && (
                <VirtualGiftsList
                    closeVirtualGifts={virtualGifts.toggleVirtualGifts}
                    virtualGiftsData={virtualGifts.virtualGiftsData}
                    sendVirtualGift={virtualGifts.sendVirtualGift}
                    virtualGiftListRef={virtualGifts.virtualGiftListRef}
                    womanName={currentChatState?.info?.name || ''}
                />
            )}

            {
                // Mobile Button Virtual GiftSuccessModal
                !!virtualGifts?.virtualGiftsData?.length &&
                    virtualGifts.isMobVirtualGiftsBtn &&
                    !isSupportWoman && (
                        <button
                            className={`chat_virtual_gift_btn ${virtualGifts.isOpenVirtualGifts ? 'active_btn' : ''}`}
                            type="button"
                            ref={virtualGifts.virtualGiftBtnMobRef}
                            onClick={virtualGifts.toggleVirtualGifts}
                        >
                            Virtual gift
                        </button>
                    )
            }
        </>
    );
}
