import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { formatPhotosGallery } from '../../../utils/format-photos-gallery';
import React from 'react';
import SITE from '../../../config/site';
import { IImageCarouselProps } from './index.interface';

export const ImageCarousel = ({
    photos,
    renderGalleryItemCounter,
    startIndex,
    setCurrentIndex,
}: IImageCarouselProps) => {
    return (
        <ImageGallery
            startIndex={startIndex}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            items={formatPhotosGallery(photos)}
            renderItem={renderGalleryItemCounter}
            onSlide={(index: number) => {
                if (SITE.ID === 23) setCurrentIndex(index);
            }}
        />
    );
};
