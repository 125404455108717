import React, { MouseEventHandler } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Tutorial from './Tutorial';

export default function TutorialModal({
    isTutorialPopup,
    closeTutorialModal,
}: {
    isTutorialPopup: boolean;
    closeTutorialModal: MouseEventHandler<HTMLButtonElement>;
}) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isTutorialPopup}
            onRequestClose={closeTutorialModal}
            ariaHideApp={false}
        >
            {!!isTutorialPopup && <Tutorial closeTutorialModal={closeTutorialModal} />}
        </Modal>
    );
}
