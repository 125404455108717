import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FrontSyncApi from '../../services/api/v3/FrontSyncApi';
import * as userAction from '../../store/actions/user';
import { RootState } from '../../index';

export default function useInitUserBirthdayModal() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const [isUserBirthdayModal, setIsUserBirthdayModal] = useState(false);
    const [birthdayBonusCredits, setBirthdayBonusCredits] = useState(0);

    const openUserBirthdayModal = () => setIsUserBirthdayModal(true);
    const closeUserBirthdayModal = () => setIsUserBirthdayModal(false);

    const checkUserBirthday = () => {
        const date = new Date();
        const [currentYear, currentMonth, currentDay] = [
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
        ];
        const userBirthdayLocalStorage = localStorage.getItem('check_user_birthday') ?? '';
        const yearLocalStorage = userBirthdayLocalStorage?.split('-')[0];
        const checkUserBirthdayStatus = userBirthdayLocalStorage?.split('_')[1];
        const [userBirthMonth, userBirthDay] = [
            +(userState?.info?.b_month || 0),
            +(userState?.info?.b_day || 0),
        ];
        if (
            (+userBirthMonth === currentMonth + 1 &&
                (+userBirthDay === currentDay || currentDay - userBirthDay <= 7) &&
                !+checkUserBirthdayStatus) ||
            +yearLocalStorage !== currentYear
        ) {
            new FrontSyncApi().postCheckUserBirthday().then((res) => {
                if (res) {
                    if (res?.status && +res?.result > 0) {
                        setBirthdayBonusCredits(res?.result);
                        openUserBirthdayModal();
                        dispatch(userAction.setBalance());
                    }
                    localStorage.setItem(
                        'check_user_birthday',
                        `${currentYear}-${currentMonth + 1}-${currentDay}_${Number(res?.status)}`,
                    );
                }
            });
        }
    };

    return {
        isUserBirthdayModal,
        openUserBirthdayModal,
        closeUserBirthdayModal,
        checkUserBirthday,
        birthdayBonusCredits,
    };
}
