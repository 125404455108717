import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import tut_step_3_girl_1 from '../../../../../../img/tutorial/tut_step_3_girl_1.png';
import tut_step_3_man_1 from '../../../../../../img/tutorial/tut_step_3_man_1.png';
import IconLetters from '../../../../../Icons/IconLetters';
import IconSearch from '../../../../../Icons/IconSearch';
import IconChat from '../../../../../Icons/IconChat';
import IconMobileMenu from '../../../../../Icons/IconMobileMenu';
import IconLikeMenu from '../../../../../Icons/IconLikeMenu';
import IconCopyright from '../../../../../Icons/IconCopyright';

export default function Step5() {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <>
            <div className={`${styles.tutorial_step_illustration} ${styles.desktop}`}>
                <div className={styles.step_chat}>
                    <div className={styles.step_chat_left}>
                        <div className={styles.step_chat_credit}>
                            <div className={styles.step_chat_credit_icon}>
                                <IconCopyright />
                            </div>
                            <span>Add credits</span>
                        </div>
                        <ul className={styles.step_chat_menu}>
                            <li className={`${styles.step_chat_menu_item} ${styles.active}`}>
                                <div className={styles.step_chat_menu_icon}>
                                    <IconChat />
                                </div>
                                <span>All chats</span>
                            </li>
                            <li className={`${styles.step_chat_menu_item} ${styles.active}`}>
                                <div className={styles.step_chat_menu_icon}>
                                    <IconLetters />
                                </div>
                                <span>Letters</span>
                            </li>
                            <li className={styles.step_chat_menu_item}>
                                <div className={styles.step_chat_menu_icon}>
                                    <IconSearch />
                                </div>
                                <span>Search</span>
                            </li>
                            <li className={styles.step_chat_menu_item}>
                                <div className={styles.step_chat_menu_icon}>
                                    <IconLikeMenu />
                                </div>
                                <span>Like</span>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.step_chat_right}>
                        {!Number(userState?.info?.gender) ? (
                            <img
                                src={tut_step_3_girl_1}
                                alt="Girl"
                                className={styles.step_chat_girl_image}
                                width="235"
                                height="238"
                            />
                        ) : (
                            <img
                                src={tut_step_3_man_1}
                                alt="Man"
                                className={styles.step_chat_girl_image}
                                width="235"
                                height="238"
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={`${styles.tutorial_step_illustration} ${styles.mobile}`}>
                <div className={styles.step_chat}>
                    <ul className={styles.step_chat_mob_menu}>
                        <li className={`${styles.step_chat_mob_menu_item} ${styles.active}`}>
                            <div className={styles.step_chat_menu_icon}>
                                <IconLetters />
                            </div>
                            <span>Letters</span>
                        </li>
                        <li className={styles.step_chat_mob_menu_item}>
                            <div className={styles.step_chat_menu_icon}>
                                <IconSearch />
                            </div>
                            <span>Search</span>
                        </li>
                        <li className={`${styles.step_chat_mob_menu_item} ${styles.active}`}>
                            <div className={styles.step_chat_menu_icon}>
                                <IconChat />
                            </div>
                            <span>Chat</span>
                        </li>
                        <li className={styles.step_chat_mob_menu_item}>
                            <div className={styles.step_chat_menu_icon}>
                                <IconLikeMenu />
                            </div>
                            <span>Like</span>
                        </li>
                        <li className={styles.step_chat_mob_menu_item}>
                            <div className={styles.step_chat_menu_icon}>
                                <IconMobileMenu />
                            </div>
                            <span>Menu</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.tutorial_body}>
                <h2 className={styles.tutorial_step_title}>Use chats for great communication</h2>
                <p className={styles.tutorial_step_text}>
                    Share your thoughts and feelings sending messages in the chat. For more
                    convenient communication, add a member you like to your favorites.
                </p>
            </div>
            <div className={styles.tutorial_hello_box}>
                <p>
                    Click <strong>“Say Hello”</strong> to start a conversation. If you find a member
                    appealing, give a <strong>“Like”</strong>
                </p>
                <div className={styles.tutorial_hello_box_btns}>
                    <span className={styles.tutorial_btn_hello}>Say “Hello”</span>
                    <span className={styles.tutorial_btn_like}>Like</span>
                </div>
            </div>
        </>
    );
}
