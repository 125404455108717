import Base from './Base';
import {
    IActivatePromoCodeData,
    IConfirmEmailByHashData,
    IMailSubscriptionData,
} from './UserApi.interface';

export default class UserAPI extends Base {
    async getInfo(isNoCache: boolean | null = null) {
        const res = await super.get(`user/info${isNoCache ? '?cache=0' : ''}`);
        const res_1 = res?.data;
        if (!res_1?.status) {
            return {
                tailInfo: null,
                userInfo: null,
                status: res_1?.status,
                message: res_1?.message,
            };
        }
        if (res_1?.fingerprint) {
            localStorage.setItem('fingerprintHash', res_1?.fingerprint);
        }
        if (res_1?.user_info?.age) {
            localStorage.setItem('user_age', res_1?.user_info?.age);
        }
        if (res_1?.user_info?.email_token) {
            localStorage.setItem('email_token', res_1?.user_info?.email_token);
        }
        if (res_1?.user_info?.country_code) {
            localStorage.setItem('country_code', res_1?.user_info?.country_code);
        }
        if (res_1?.user_info?.name) {
            localStorage.setItem('user_name', res_1?.user_info?.name);
        }
        const newUtmTail = res_1?.utmTail?.result?.tail
            ? { ...res_1?.utmTail?.result, utm_aff_hash: res_1?.utmTail?.utm_aff_hash }
            : null;
        return {
            supportEmail: res_1?.supportEmail?.email,

            userRoles: {
                heightRole: res_1?.heightRole,
                maybeHeight: res_1?.maybeHeight,
            },

            userInfo: res_1?.user_info,
            utmTail: newUtmTail,

            parallel_sender: res_1?.parallel_sender,
            sender_manual: res_1?.sender_manual,
            abtest: res_1?.abtest,
            search_multigender: res_1?.search_multigender,
            hide_videoshow: res_1?.hide_videoshow,
            hide_gift: res_1?.hide_gift,
            common_files: res_1?.common_files,
            online_now: res_1?.online_now,
            multigender: res_1?.multigender,
            show_already_registration_bonus_popup:
                res_1?.show_already_registration_bonus_popup || false,
            notification_settings: res_1?.notification_settings ?? null,
            statistic_settings: res_1?.statistic_settings ?? null,
            service_settings: res_1?.service_settings,
            prices: res_1?.prices[0],
            status: res_1?.status,
            needShowGiftModal: res_1?.needShowGiftModal,
            needShowLetterModal: res_1?.needShowLetterModal,
            giftActive: res_1?.giftActive,
            giftsDiscount: res_1?.giftsDiscount,
            giftsDiscountValue: res_1?.giftsDiscountValue,
            letterActive: res_1?.letterActive,
            needShowVerify: res_1?.needShowVerify,
            verified: res_1?.verified,
        };
    }

    async confirmEmailByHash(payload: IConfirmEmailByHashData) {
        const res = await super.post(`login/confirmEmail`, payload);
        return res?.data;
    }

    async activatePromoCode(payload: IActivatePromoCodeData) {
        const res = await super.post(`user/activatePromoCode`, payload);
        return res?.data;
    }

    async getUserBalance() {
        const res = await super.get(`user/userBalance`);
        return res?.data;
    }

    async getSpentCreditsForContactRequest(femaleExternalId: number) {
        const res = await super.get(
            `user/spentCreditsForContactRequest?female_external_id=${femaleExternalId}`,
        );
        return res?.data;
    }

    async getMailSubscription() {
        const res = await super.get(`user/mailsubscription`);
        return res?.data;
    }

    async postMailSubscription({ subscribe, confirm, eventId }: IMailSubscriptionData) {
        const res = await super.post(`user/mailsubscription`, {
            subscribe,
            ...(confirm ? { confirm } : {}),
            ...(eventId ? { eventId } : {}),
        });
        return res?.data;
    }
}
