import { useEffect, useState } from 'react';
import SupportFeedbackApi from '../../services/api/v3/SupportFeedbackApi';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import { useDispatch } from 'react-redux';

export default function useSupportFeedbackModal() {
    const dispatch = useDispatch();

    const initFormData = {
        scoreAnswer: null,
        textAnswer: '',
    };

    const [isSupportFeedbackModal, setIsSupportFeedbackModal] = useState(false);
    const [isSuccessModal, setIsSuccessModal] = useState(false);
    const [formData, setFormData] = useState(initFormData);
    const [questionData, setQuestionData] = useState<any>({});

    const openSupportFeedbackModal = () => {
        setIsSupportFeedbackModal(true);
    };

    const closeSupportFeedbackModal = () => {
        setIsSupportFeedbackModal(false);
        setIsSuccessModal(false);
    };

    const handleFormData = (key: string, value: string) => {
        setFormData({ ...formData, ...{ [key]: value } });
    };

    const onSubmit = () => {
        const data = {
            questionnaireShownId: +questionData?.id,
            ...(formData?.scoreAnswer ? { scoreAnswer: +formData?.scoreAnswer } : {}),
            ...(formData?.textAnswer ? { textAnswer: formData?.textAnswer } : {}),
        };
        new SupportFeedbackApi().answerSupportFeedback(data).then((res) => {
            if (res?.status) {
                setIsSuccessModal(true);
            } else {
                dispatch(
                    alertAction.setMessage({
                        message: res?.message || ERROR.ERROR_REQUEST,
                    }),
                );
            }
        });
    };

    const initSupportFeedback = () => {
        new SupportFeedbackApi().getSupportFeedback().then((res) => {
            if (res?.status && res?.result) {
                setQuestionData({ ...res?.result });
                openSupportFeedbackModal();
            }
        });
    };

    useEffect(() => {
        initSupportFeedback();
    }, []);

    return {
        isSupportFeedbackModal,
        closeSupportFeedbackModal,
        isSuccessModal,
        questionData,
        onSubmit,
        formData,
        handleFormData,
    };
}
