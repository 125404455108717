import React from 'react';
import { formatDate } from '../../../../../services/time';
import styles from '../styles.module.scss';
import IconQuestionMark from '../../../../Icons/IconQuestionMark';
import IconCross from '../../../../Icons/IconCross';
import { IJoinedNotifyProps } from '../JoinedNotify/index.interface';

export default function UnknownLikeNotify({
    item,
    deleteNotify,
    openWomanProfile,
}: IJoinedNotifyProps) {
    return (
        <>
            <li
                className={`${styles.notify_item} ${styles.activity} ${styles.unknown_like} ${styles.online}`}
            >
                <div className={styles.notify_item_photo_wrap} />
                <div className={styles.notify_item_right}>
                    <p className={styles.notify_item_text}>This member view your profile</p>
                </div>
                <button
                    type="button"
                    className={styles.notify_item_activity_btn}
                    onClick={() => openWomanProfile(item, item?.sender_external_id)}
                >
                    <IconQuestionMark />
                    <span className={styles.big}>Find out who</span>
                    <span className={styles.small}>Who’s that</span>
                </button>
                <button
                    type="button"
                    className={styles.notify_item_clear_mask}
                    onClick={() => deleteNotify(item)}
                >
                    <IconCross />
                </button>
                <p className={styles.notify_item_time}>{formatDate(item?.date_created)}</p>
            </li>
        </>
    );
}
