import SITE from '../config/site';
import * as MIRROR from './mirror';

const itemL = `
    <p>l) To make a Contact Request - 500 Credits. Please be kindly informed that a member decides what kind of data
        they will provide you with (either an e-mail or a phone number).</p>
      </br>
  <p>This option will only be available after you have been continuously communicating with a member for at least one
        month, with a break no longer than 72 hours, and after 5,000 Credits have been spent on that particular Profile,
        but only for chats, e-mails, and media content. If you want to get any information regarding a Contact Request,
        please contact the Support Team by e-mail at support@${SITE.key}.com, and they will be glad to help you.</p>
`;

const itemM = `
  <p>m) Organize meetings by yourself for free (0,0 Credits). The option will be available after you have received the
        member's contact information (an e-mail or a phone number). You can discuss all the meeting details by yourself.
        If you want to get any information regarding a Meeting, please contact the Support Team by e-mail at
        support@${SITE.key}.com, and they will be glad to help you.</p>
`;

export const DEFAULT_CREDIT_POLICY = `
    <p>a) Press the “Like” button on the user’s profile – 2 Credits</p>
    <p>b) Press the “Like” button in a chat – 2 Credits</p>
    <p>c) Press the “Say “Hello” button on the user’s profile - 2 Credits</p>
    <p>d) The cost of one minute of chat correspondence is 2 Credits for every separate chat</p>
    <p>e) Send a wink in a chat - 2 Credits</p>
    <p>f) Send a sticker - 5 Credits</p>
    <p>g) Send or receive a photo (via letters/chat)- 10 Credits</p>
    <p>h) Send a letter - 20 Credits</p>
    <p>i) Send or receive an audio message (via letters/chat) - 15 Credits</p>
    <p>j) Send or receive a video message (via letters/chat) - 25 Credits</p>
    ${!MIRROR.HIDDEN_VIDEO_SHOW.includes(SITE.ID) ? '<p>k) To view a video show - 50 Credits</p>' : '<div/>'}
    ${!MIRROR.HIDDEN_CONTACT_REQUEST.includes(SITE.ID) ? itemL : '<div/>'}
      </br>
    ${!MIRROR.HIDDEN_MEETING_REQUEST.includes(SITE.ID) ? itemM : '<div/>'}
`;

export const A_CREDIT_POLICY = `
    <p>a) Press the “Like” button on the user’s profile – 2 Credits</p>
    <p>b) Press the “Like” button in a chat – 2 Credits</p>
    <p>c) Press the “Say “Hello” button on the user’s profile - 2 Credits</p>
    <p>d) The cost of one minute of chat correspondence is 2 Credits for every separate chat</p>
    <p>e) Send a wink in a chat - 2 Credits</p>
    <p>f) Send a sticker - 5 Credits</p>
    <p>g) Send or receive a photo (via letters/chat)- 10 Credits</p>
    <p>h) Send a letter / first letter - 30 Credits / 10 Credits</p>
    <p>i) Send or receive an audio message (via letters/chat) - 10 Credits</p>
    <p>j) Send or receive a video message (via letters/chat) - 50 Credits</p>
    ${!MIRROR.HIDDEN_VIDEO_SHOW.includes(SITE.ID) ? '<p>k) To view a video show - 50 Credits</p>' : '<div/>'}
    ${!MIRROR.HIDDEN_CONTACT_REQUEST.includes(SITE.ID) ? itemL : '<div/>'}
      </br>
    ${!MIRROR.HIDDEN_MEETING_REQUEST.includes(SITE.ID) ? itemM : '<div/>'}
`;
export const HIDE_CREDIT_POLICY = `
    <p style="text-align: center">You can get a full Credit cost policy after free registration.</p>
`;
