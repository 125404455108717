export const SEARCH_FILTERS_DEFAULT = {
    from: 0,
    to: 0,
    online: true,
    country: 0,
    country_name: '',
    city: 0,
    city_name: '',
    cityList: [],
    id: '',
    detail: {},
    alcohol: undefined,
    bodytype: undefined,
    children: undefined,
    education: undefined,
    eye: undefined,
    haircolor: undefined,
    heightfrom: undefined,
    heightto: undefined,
    wantchildren: undefined,
    job: undefined,
    purpose: undefined,
    religion: undefined,
    smoking: undefined,
    marital: undefined,
    sexFilter: undefined,
};
export const ONLINE_NOW_FILTERS_DEFAULT = {
    from: 0,
    to: 0,
    online: true,
    id: '',
    images: 1,
    country: 0,
};
export const VIDEO_SHOW_FILTERS_DEFAULT = {
    from: 0,
    to: 0,
    online: true,
    id: '',
    hasVideo: 1,
    videoShow: 1,
    country: 0,
    detail: {},
    alcohol: undefined,
    bodytype: undefined,
    children: undefined,
    education: undefined,
    eye: undefined,
    haircolor: undefined,
    heightfrom: undefined,
    heightto: undefined,
    wantchildren: undefined,
    job: undefined,
    purpose: undefined,
    religion: undefined,
    smoking: undefined,
    marital: undefined,
};

export const LOOKING_GENDER_LIST = [
    { sexFilter: 1, id: 'ALL', value: 'I`m a man looking for all' },
    { sexFilter: 2, id: 0, value: 'I`m a man looking for woman' },
    { sexFilter: 3, id: 1, value: 'I`m a man looking for man' },

    { sexFilter: 4, id: 'ALL', value: 'I`m a woman looking for all' },
    { sexFilter: 5, id: 1, value: 'I`m a woman looking for man' },
    { sexFilter: 6, id: 0, value: 'I`m a woman looking for woman' },
];
