import React, { MouseEventHandler, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import useTransition from '../../../../../hooks/transition/useTransition';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

export default function Tutorial({
    closeTutorialModal,
}: {
    closeTutorialModal: MouseEventHandler<HTMLButtonElement>;
}) {
    const transition = useTransition();
    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        if (window?.location?.search?.includes('tutorial=search')) {
            setCurrentStep(2);
            transition.addTail(window?.location?.search?.replace('tutorial=search', ''));
        }
    }, []);

    return (
        <div className={styles.tutorial_popup}>
            <h3 className={styles.tutorial_title}>How to start</h3>
            {(() => {
                switch (currentStep) {
                    case 1:
                        return <Step1 />;
                    case 2:
                        return <Step2 />;
                    case 3:
                        return <Step3 />;
                    case 4:
                        return <Step4 />;
                    case 5:
                        return <Step5 />;
                    case 6:
                        return <Step6 />;
                    default:
                        return;
                }
            })()}
            <ul className={`${styles.tutorial_pagination} ${styles[`step_${currentStep}`]}`}>
                <li
                    className={`${styles.tutorial_pagination_item} ${currentStep === 1 ? styles.active : ''}`}
                />
                <li
                    className={`${styles.tutorial_pagination_item} ${currentStep === 2 ? styles.active : ''}`}
                />
                <li
                    className={`${styles.tutorial_pagination_item} ${currentStep === 3 ? styles.active : ''}`}
                />
                <li
                    className={`${styles.tutorial_pagination_item} ${currentStep === 4 ? styles.active : ''}`}
                />
                <li
                    className={`${styles.tutorial_pagination_item} ${currentStep === 5 ? styles.active : ''}`}
                />
                <li
                    className={`${styles.tutorial_pagination_item} ${currentStep === 6 ? styles.active : ''}`}
                />
            </ul>
            <div className={`${styles.tutorial_footer} ${styles[`step_${currentStep}`]}`}>
                {currentStep < 6 ? (
                    <>
                        <button
                            type="button"
                            className={styles.tutorial_btn_skip}
                            onClick={closeTutorialModal}
                        >
                            Skip all
                        </button>
                        <button
                            type="button"
                            className={styles.tutorial_btn_continue}
                            onClick={(e) => {
                                setCurrentStep(currentStep + 1);
                                e.currentTarget.blur();
                            }}
                        >
                            Continue
                        </button>
                    </>
                ) : (
                    <button
                        type="button"
                        className={`${styles.tutorial_btn_continue} ${styles.tutorial_btn_find_match}`}
                        onClick={closeTutorialModal}
                    >
                        Find your match
                    </button>
                )}
            </div>
            <button
                type="button"
                className={styles.tutorial_btn_close}
                onClick={closeTutorialModal}
            />
        </div>
    );
}
