import React, { useEffect } from 'react';
import LoaderGif from '../../../../components/common/LoaderGif';
import { IAddNewCreditCardProps } from './index.interface';
import IconCross from '../../../../components/Icons/IconCross';

export default function AddNewCreditCard({
    frame,
    frameData,
    closeAddCreditCardModal,
}: IAddNewCreditCardProps) {
    useEffect(() => {
        if (frameData?.INTERFACE) {
            const timerID = setTimeout(() => {
                const formCreditBlock = document?.querySelector(`#form-credit`) as HTMLFormElement;
                if (formCreditBlock) formCreditBlock?.submit();
            }, 500);

            return () => clearTimeout(timerID);
        }
    }, [frameData]);

    return (
        <div className="popup_your_credit_cards">
            {frame?.length > 0 ? (
                <>
                    <div className="popup_your_credit_cards_wrap">
                        <form
                            action={frame}
                            target={`credits-iframe`}
                            id={`form-credit`}
                            method="post"
                        >
                            <input
                                type="hidden"
                                name="INTERFACE"
                                value={frameData ? frameData['INTERFACE'] : ''}
                            />
                            <input
                                type="hidden"
                                name="KEY_INDEX"
                                value={frameData ? frameData['KEY_INDEX'] : ''}
                            />
                            <input
                                type="hidden"
                                name="KEY"
                                value={frameData ? frameData['KEY'] : ''}
                            />
                            <input
                                type="hidden"
                                name="DATA"
                                value={frameData ? frameData['DATA'] : ''}
                            />
                            <input
                                type="hidden"
                                name="SIGNATURE"
                                value={frameData ? frameData['SIGNATURE'] : ''}
                            />
                            <input
                                type="hidden"
                                name="CALLBACK"
                                value={frameData ? frameData['CALLBACK'] : ''}
                            />
                            <input
                                type="hidden"
                                name="ERROR_CALLBACK"
                                value={frameData ? frameData['ERROR_CALLBACK'] : ''}
                            />
                        </form>
                        <iframe
                            title={'Iframe'}
                            id={`frame-credit`}
                            name={`credits-iframe`}
                            style={{ border: 'none', overflow: 'hidden' }}
                            width={'100%'}
                            height={'900px'}
                        />
                    </div>
                    <div className="close_one_popup" onClick={closeAddCreditCardModal}>
                        <IconCross />
                    </div>
                </>
            ) : (
                <LoaderGif />
            )}
        </div>
    );
}
