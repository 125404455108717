import React from 'react';

export default function IconHeadphones() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame">
                <path
                    id="Vector"
                    d="M19.5 10.5761C19.5 6.43317 16.1434 3.07617 12 3.07617C7.85775 3.07617 4.50007 6.43317 4.50007 10.5761C3.67162 10.5761 3 11.2477 3 12.0762V16.5762C3 17.4046 3.67162 18.0762 4.50007 18.0762H6.75007C7.1643 18.0762 7.5 17.7408 7.5 17.3261V13.5762C7.5 13.1638 7.3128 12.5449 7.08397 12.2022L6.00015 10.5763C6.00015 7.26207 8.6862 4.57625 12.0002 4.57625C15.3152 4.57625 18.0003 7.26207 18.0003 10.5763L16.9162 12.2022C16.6875 12.5449 16.5 13.1638 16.5 13.5762V17.5358C15.4007 18.7846 13.7946 19.5761 12 19.5761H10.5V21.0762H12C14.452 21.0762 16.6215 19.8947 17.9898 18.0762H19.5C20.3283 18.0762 21 17.4046 21 16.5762V12.0762C21 11.2477 20.3283 10.5761 19.5 10.5761ZM5.99992 16.5762H4.49985V12.0762H5.19712L5.83568 13.0342C5.90318 13.1404 5.99678 13.4512 5.9997 13.5776L5.99992 16.5762ZM19.5 16.5762H18V13.5776C18.003 13.4509 18.0969 13.1404 18.1641 13.0342L18.8026 12.0762H19.5V16.5762Z"
                />
            </g>
        </svg>
    );
}
