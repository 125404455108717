import React from 'react';

export default function IconVerifyRequestVerified() {
    return (
        <svg
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="#61C864"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M23.4 7.8C21.3313 7.8 19.3474 8.62179 17.8846 10.0846C16.4218 11.5474 15.6 13.5313 15.6 15.6V35.9073C16.8749 35.5469 18.1805 35.3053 19.5 35.1858V15.6C19.5 14.5657 19.9109 13.5737 20.6423 12.8423C21.3737 12.1109 22.3657 11.7 23.4 11.7H39V25.35C39 26.9015 39.6163 28.3895 40.7134 29.4866C41.8105 30.5837 43.2985 31.2 44.85 31.2H58.5V62.4C58.5 63.4343 58.0891 64.4263 57.3577 65.1577C56.6263 65.8891 55.6343 66.3 54.6 66.3H40.56C39.8503 67.6893 38.9911 68.9971 37.9977 70.2H54.6C56.6687 70.2 58.6526 69.3782 60.1154 67.9154C61.5782 66.4527 62.4 64.4687 62.4 62.4V28.9146C62.3986 27.3636 61.7814 25.8766 60.684 24.7806L45.4194 9.5121C44.3227 8.41613 42.8358 7.80033 41.2854 7.8H23.4ZM57.6927 27.3H44.85C44.3328 27.3 43.8368 27.0946 43.4711 26.7289C43.1054 26.3632 42.9 25.8672 42.9 25.35V12.5073L57.6927 27.3ZM39 56.55C39 61.2046 37.151 65.6685 33.8597 68.9597C30.5685 72.251 26.1045 74.1 21.45 74.1C16.7955 74.1 12.3315 72.251 9.04028 68.9597C5.74901 65.6685 3.9 61.2046 3.9 56.55C3.9 51.8955 5.74901 47.4315 9.04028 44.1403C12.3315 40.849 16.7955 39 21.45 39C26.1045 39 30.5685 40.849 33.8597 44.1403C37.151 47.4315 39 51.8955 39 56.55ZM30.6306 49.3194C30.4495 49.1378 30.2343 48.9937 29.9974 48.8954C29.7605 48.7971 29.5065 48.7465 29.25 48.7465C28.9935 48.7465 28.7395 48.7971 28.5026 48.8954C28.2657 48.9937 28.0505 49.1378 27.8694 49.3194L17.55 59.6427L15.0306 57.1194C14.6644 56.7532 14.1678 56.5475 13.65 56.5475C13.1322 56.5475 12.6356 56.7532 12.2694 57.1194C11.9032 57.4856 11.6975 57.9822 11.6975 58.5C11.6975 59.0178 11.9032 59.5144 12.2694 59.8806L16.1694 63.7806C16.3505 63.9622 16.5657 64.1063 16.8026 64.2046C17.0395 64.3029 17.2935 64.3535 17.55 64.3535C17.8065 64.3535 18.0605 64.3029 18.2974 64.2046C18.5343 64.1063 18.7495 63.9622 18.9306 63.7806L30.6306 52.0806C30.8122 51.8995 30.9563 51.6843 31.0546 51.4474C31.1529 51.2105 31.2035 50.9565 31.2035 50.7C31.2035 50.4435 31.1529 50.1895 31.0546 49.9526C30.9563 49.7157 30.8122 49.5005 30.6306 49.3194Z" />
        </svg>
    );
}
