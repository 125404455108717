import { AllMediaActionTypes } from '../../constants/allMedia';
import { AllMediaActions, IAllMediaState } from './index.interface';

const initState = {
    allMediaList: [],
    page: 1,
    isLoaded: false,
    mediaListType: 'all media',
    mediaListPaid: 'all',
};

export default function (
    state: IAllMediaState = initState,
    action: AllMediaActions,
): IAllMediaState {
    switch (action.type) {
        case AllMediaActionTypes.SET_ALL_MEDIA_LIST:
            return {
                ...state,
                ...{
                    allMediaList: action?.data,
                    isLoaded: true,
                },
            };

        case AllMediaActionTypes.UPDATE_ALL_MEDIA_LIST:
            return {
                ...state,
                ...{
                    allMediaList: [action?.data, ...state.allMediaList],
                },
            };

        case AllMediaActionTypes.SET_BUYED_MEDIA:
            return {
                ...state,
                ...{
                    allMediaList: state.allMediaList.map((item) => {
                        if (+item?.id === +action?.messageId) return { ...item, ...{ payed: '1' } };
                        else return item;
                    }),
                },
            };

        case AllMediaActionTypes.SET_MEDIA_LIST_BY_PAGE:
            return {
                ...state,
                ...{
                    page: action?.page,
                },
            };

        case AllMediaActionTypes.RESET_MEDIA_LIST_BY_PAGE:
            return {
                ...state,
                ...{
                    page: 1,
                    isLoaded: false,
                },
            };

        case AllMediaActionTypes.SET_MEDIA_LIST_TYPE:
            return {
                ...state,
                ...{
                    mediaListType: action.data,
                },
            };

        case AllMediaActionTypes.SET_MEDIA_LIST_PAID:
            return {
                ...state,
                ...{
                    mediaListPaid: action.data,
                },
            };

        case AllMediaActionTypes.RESET_ALL_MEDIA_LIST:
            return {
                ...state,
                ...{
                    mediaListType: 'all media',
                    mediaListPaid: 'all',
                },
            };

        default:
            return state;
    }
}
