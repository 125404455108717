import LoginApi from '../../services/api/LoginApi';
import * as loaderAction from '../../store/actions/loader';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useHandleRedUser from '../user/useHandleRedUser';
import useAuthRedirect from '../transition/useAuthRedirect';
import { isMobile } from 'react-device-detect';
import { redirectToMobileVersion } from '../../services/methods/mobile';
import { sendErrToSentry } from '../../services/sentry';
import { FormEvent } from 'react';

export default function useSubmitLogin(
    formData: { email: string; password: string },
    closeModal: () => void,
    activeSetPassword: boolean,
    activeForgetPassword: boolean,
    activationCode: string | null,
    setActiveSetPassword: (value: boolean) => void,
    setActivationCode: (value: string | null) => void,
    setActiveForgetPassword: (value: boolean) => void,
    checkValidFieldsLogin: () => boolean,
) {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleRedUser = useHandleRedUser();
    const redirect = useAuthRedirect('chat');

    const upperFirstLetter = (msg: string) => {
        return msg?.[0]?.toUpperCase() + msg?.slice(1);
    };

    return (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (activeSetPassword) {
            if (formData?.password?.length > 0 && activationCode) {
                dispatch(loaderAction.setActiveGifLoader(true));

                new LoginApi().postPasswordSet(formData?.password, activationCode).then((res) => {
                    if (res?.status && res?.email) {
                        setActiveSetPassword(false);
                        setActivationCode(null);
                        new LoginApi()
                            .postLoginToken(res?.email, formData?.password)
                            .then((res) => {
                                closeModal();
                                dispatch(loaderAction.setActiveGifLoader(false));
                                if (res?.status) {
                                    localStorage.setItem('fingerprintHash', res?.fingerprint);
                                    localStorage.setItem('token', JSON.stringify(res?.token));
                                    if (res?.forMobile && res?.accessKey?.length && isMobile) {
                                        return redirectToMobileVersion(
                                            res?.accessKey,
                                            `/${redirect}`,
                                        );
                                    }
                                    history.push(`/${redirect}${window?.location?.search}`);
                                }
                            })
                            .catch(() =>
                                dispatch(
                                    alertAction.setMessage({
                                        message: 'Invalid email or password',
                                    }),
                                ),
                            )
                            .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                        dispatch(loaderAction.setActiveGifLoader(false));
                    }
                });

                return;
            } else {
                return dispatch(alertAction.setMessage({ message: `This field is required` }));
            }
        }
        if (activeForgetPassword) {
            if (formData?.email?.length > 0) {
                try {
                    dispatch(loaderAction.setActiveGifLoader(true));
                    new LoginApi()
                        .postPasswordRestore(formData?.email)
                        .then((res) => {
                            if (res?.status) {
                                closeModal();
                                setActivationCode(res?.activation_code);
                                setActiveForgetPassword(false);
                                dispatch(alertAction.setMessage({ message: res?.message }));
                            } else {
                                dispatch(
                                    alertAction.setMessage({
                                        message:
                                            upperFirstLetter(res?.message) ||
                                            upperFirstLetter(res?.error) ||
                                            ERROR.ERROR_REQUEST,
                                    }),
                                );
                            }
                            dispatch(loaderAction.setActiveGifLoader(false));
                        })
                        .catch(() => {
                            dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
                        })
                        .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
                } catch (error) {
                    sendErrToSentry(error as Error);
                }
                return;
            } else {
                return dispatch(alertAction.setMessage({ message: `This field is required` }));
            }
        }

        if (checkValidFieldsLogin()) {
            try {
                dispatch(loaderAction.setActiveGifLoader(true));

                new LoginApi()
                    .postLoginToken(formData?.email, formData?.password)
                    .then((res) => {
                        dispatch(loaderAction.setActiveGifLoader(false));
                        if (res?.status) {
                            handleRedUser.submitLogNotAuth(
                                RED_USER_LOG_TYPES.LOGIN_SUCCESS,
                                JSON.stringify({ email: formData?.email }),
                            );
                            localStorage.setItem('fingerprintHash', res?.fingerprint);
                            localStorage.setItem('token', JSON.stringify(res?.token));
                            if (res?.forMobile && res?.accessKey?.length && isMobile) {
                                return redirectToMobileVersion(res?.accessKey, `/${redirect}`);
                            }
                            history.push(`/${redirect}${window?.location?.search}`);
                        } else {
                            handleRedUser.submitLogNotAuth(
                                RED_USER_LOG_TYPES.LOGIN_FAIL,
                                JSON.stringify({
                                    email: formData?.email,
                                    message: res?.message,
                                }),
                            );

                            dispatch(
                                alertAction.setMessage({
                                    message: res?.message || 'Invalid email or password',
                                }),
                            );
                        }
                    })
                    .catch(() =>
                        dispatch(alertAction.setMessage({ message: 'Invalid email or password' })),
                    )
                    .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        } else {
            return dispatch(alertAction.setMessage({ message: `All fields are required` }));
        }
    };
}
