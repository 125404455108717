import React, { useEffect } from 'react';
import LettersList from '../LettersList';
import LettersChat from '../LettersChat';
import { useDispatch, useSelector } from 'react-redux';
import NotifyEmailConfirm from '../../Banner';
import useHandleMobileChat from '../../../hooks/chat/helpers/useHandleMobileChat';
import LettersChatMobile from '../LettersChatMobile';
import * as mailboxAction from '../../../store/actions/mailbox';
import { RootState } from '../../../index';

export default function Letters() {
    const dispatch = useDispatch();
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const isActiveLetterChat = !!currentChatMailboxState?.info?.female_id;
    const handleMobileChat = useHandleMobileChat();

    useEffect(() => {
        return () => {
            dispatch(mailboxAction.setInitMailbox());
        };
    }, []);

    return (
        <div className="column-3 color column_3_letters_page" data-testid="letter-list">
            {!handleMobileChat?.isActiveNewMobileChat && <NotifyEmailConfirm />}

            {isActiveLetterChat ? (
                handleMobileChat?.isNewMobileChat ? (
                    <LettersChatMobile />
                ) : (
                    <LettersChat />
                )
            ) : (
                <LettersList />
            )}
        </div>
    );
}
