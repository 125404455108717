import React from 'react';
import { ICategoryItem, IGiftItem } from '../../../store/reducers/gifts/index.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';

interface IGiftsCartModalItem {
    item: IGiftItem;
    category: ICategoryItem;
    deleteFromCart: (item: IGiftItem) => void;
}

export default function GiftsCartModalItem({
    item,
    category,
    deleteFromCart,
}: IGiftsCartModalItem) {
    const userState = useSelector((state: RootState) => state.user);
    const actualPrice = item ? +(userState?.giftsDiscount ? item?.new_price : item?.price) : 0;

    return (
        <div className="popup_gifts_cart_item">
            <div className="popup_gifts_cart_item_top">
                <div
                    className={`popup_gifts_cart_item_img_wrap ${userState?.giftsDiscount ? 'popup_gifts_cart_item_img_wrap_discount_label' : ''}`}
                >
                    <img
                        src={item?.image || '/img/gifts/img_no_photo.jpg'}
                        alt={item?.name || ''}
                        className="popup_gifts_cart_item_img"
                    />
                    {userState?.giftsDiscount && item?.discount && (
                        <div className="popup_gifts_cart_item_img_wrap_discount_amount">{`- ${+item?.discount}%`}</div>
                    )}
                </div>
                <div className="popup_gifts_cart_item_info">
                    <div className="popup_gifts_cart_item_title">{item?.name}</div>
                    <div className="popup_gifts_cart_item_category">
                        Category<span>{category?.name}</span>
                    </div>
                </div>
            </div>
            <div className="popup_gifts_cart_item_bottom">
                <div
                    className={`popup_gifts_cart_item_price ${userState?.giftsDiscount ? 'popup_gifts_cart_item_price_with_discount' : ''}`}
                >
                    Price
                    <span>{actualPrice} credits</span>
                    {userState?.giftsDiscount && item?.old_price && (
                        <span className="popup_gifts_cart_item_old_price">{`${+item?.old_price} credits`}</span>
                    )}
                </div>
                <div className="popup_gifts_cart_item_remove" onClick={() => deleteFromCart(item)}>
                    <img src="/img/gifts/cross-circle-red.svg" alt="Remove from cart" />
                    <span>
                        Remove <br />
                        from cart
                    </span>
                </div>
            </div>
        </div>
    );
}
