import axios from 'axios';
import moment from 'moment';
import { daysUnitBirthday } from '../time/DateUtils';
import LoginApi from '../api/LoginApi';
import * as REGEXP from '../../constants/regExp';
import * as AWS from '../../constants/aws';
import * as ATTACH from '../../constants/attach';
import * as MENU from '../../constants/menu';
import { parseUTC } from '../time';
import * as REGISTER from '../../constants/register';
import * as LENGTH from '../../constants/length';
import * as MIRROR from '../../constants/mirror';
import * as POSTS from '../../constants/posts';
import { sendErrToSentry } from '../sentry';
import { CurrencyEnum } from '../../store/reducers/purchase/index.interface';

export function sortObjByKeys(obj: any) {
    const ordered: any = {};

    Object.keys(obj)
        .sort()
        .forEach(function (key) {
            ordered[key] = obj[key];
        });

    return ordered;
}

export function uniqueArrayByID(arr: any[]) {
    if (!Array.isArray(arr)) return null;
    const tmp: number[] = [];
    const result: any[] = [];

    for (const item of arr) {
        if (!tmp.includes(+item?.id)) {
            tmp.push(+item?.id);
            result.push(item);
        }
    }

    return result;
}

export function getCookie(name: string) {
    const value = `; ${document?.cookie}`;
    const parts = value?.split(`; ${name}=`);
    if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
}

export function generationDateOnFewMinutesLonger(minutes: number) {
    const expDate = new Date();
    expDate.setMinutes(expDate.getMinutes() + +minutes);
    return expDate;
}

export function generationDateOnFewSecondsLonger(dateCreated: string, seconds: number) {
    try {
        const expDate = new Date(parseUTC(dateCreated));
        expDate.setSeconds(expDate.getSeconds() + +seconds);
        return expDate;
    } catch (e) {
        return new Date(dateCreated);
    }
}

export function generationDateOnFewSecondsLongerWithoutUTC(
    dateCreated: string,
    seconds: number | undefined,
) {
    if (dateCreated && seconds) {
        const expDate = new Date(dateCreated);
        expDate.setSeconds(expDate.getSeconds() + +seconds);
        return expDate;
    } else {
        return new Date(dateCreated);
    }
}

export function timeDiffFromMin(date: string) {
    return moment() >= moment(date);
}

export function findAge(year: number, month: number, day: number) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dob = new Date(year, month - 1, day);
    const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
    let age;

    age = today.getFullYear() - dob.getFullYear();

    if (today < dobnow) {
        age = age - 1;
    }

    return +age;
}

export function checkDate(year: number, month: number, day: number) {
    return findAge(year, month, day) >= REGISTER.MIN_AGE;
}

export function unique(arr: any) {
    if (!Array.isArray(arr)) return null;
    const result: any[] = [];

    for (const str of arr) {
        if (!result.includes(str)) {
            result.push(str);
        }
    }

    return result;
}

export function objectToArray(obj: any) {
    if (typeof obj !== 'object') return null;
    const arr = [];

    for (const key in obj) {
        arr.push({ ...{ id: key }, ...obj[key] });
    }

    return arr;
}

export function cutLengthString(str: string, maxLength: number) {
    return str?.length <= maxLength ? str : str?.substr(0, maxLength)?.trim() + '...';
}

export function getReadableFileSizeString(fileSizeInBytes: number) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export function decimalAdjust(
    type: 'round' | 'floor' | 'ceil',
    value: number,
    exp?: number,
): number {
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }

    if (value < 0) {
        return -decimalAdjust(type, -value, exp);
    }

    let splitValue = value.toString().split('e');
    value = Math[type](+(splitValue[0] + 'e' + (splitValue[1] ? +splitValue[1] - exp : -exp)));

    splitValue = value.toString().split('e');
    return +(splitValue[0] + 'e' + (splitValue[1] ? +splitValue[1] + exp : exp));
}

export function convertPrice(price: number) {
    if (!price) return 0;
    return decimalAdjust('floor', +price, -2);
}

export function currencyPriceString(
    currency: CurrencyEnum | string | null | undefined,
    price: number,
) {
    if (!currency) return `$${convertPrice(price)}`;
    if (currency === CurrencyEnum.GBP) return `£${convertPrice(price)}`;
    if (currency === CurrencyEnum.EUR) return `€${convertPrice(price)}`;

    return `$${convertPrice(price)}`;
}

export function uniqueArrayByChatUid(arr: any[]) {
    if (!Array.isArray(arr)) return null;
    const tmp: string[] = [];
    const result: any[] = [];

    for (const item of arr) {
        if (!tmp.includes(item?.chat_uid)) {
            tmp.push(item?.chat_uid);
            result.push(item);
        }
    }

    return result;
}

export function injectParam(allParams: string, newParam: string, index: number) {
    if (allParams?.length === 0) return newParam;

    let queryParams = allParams;
    if (allParams?.startsWith('?')) queryParams = allParams?.substring(1);

    if (includesParam(queryParams, newParam)) {
        queryParams = removeParam(queryParams, newParam);
    }

    const params = queryParams?.split('&');
    if (params?.length > index) {
        params?.splice(index, 0, newParam);
    } else {
        params?.push(newParam);
    }

    return params?.join('&');
}

export function removeParam(allParams: string, toRemove: string) {
    if (allParams?.length === 0) return allParams;

    let queryParams = allParams;
    if (allParams?.startsWith('?')) queryParams = allParams?.substring(1);

    const params = queryParams?.split('&');
    const attributeName = toRemove?.split('=')?.[0] + '=';

    return params
        ?.filter((p) => {
            return !p?.startsWith(attributeName);
        })
        ?.join('&');
}

function includesParam(allParams: string, newParam: string) {
    let paramKey = newParam;

    if (newParam?.includes('=')) {
        paramKey = newParam?.split('=')?.[0] + '=';
    }

    return allParams?.includes(paramKey);
}

export function renderLastMessage(type: string, message: string, toYou: boolean, girlName: string) {
    let renderData;

    switch (type) {
        case 'SENT_IMAGE':
            renderData = 'sent image ' /*+ item.filename*/;
            break;
        case 'SENT_VIEW':
            renderData = toYou ? ' Viewed your profile' : '';
            break;
        case 'SENT_AUDIO':
            renderData = 'sent audio ' /*+ item.filename*/;
            break;
        case 'SENT_VIDEO':
            renderData = 'sent video ' /*+ item.filename*/;
            break;
        case 'SENT_WINK':
            renderData = 'Hello';
            break;
        case 'SENT_LIKE':
            renderData = toYou ? `${girlName} liked your profile` : `You liked ${girlName} profile`;
            break;
        case 'ASK_VIDEO':
        case 'SENT_TEXT':
            renderData = message;
            break;
        case 'SENT_STICKER':
            renderData = 'sent sticker';
            break;
        case 'SENT_VIRTUAL_GIFT':
            renderData = 'sent a virtual gift';
            break;
        default:
            renderData = '';
            break;
    }
    return renderData;
}

export function switchChatList(menu: string) {
    switch (menu) {
        case MENU.MENU_FAVORITE:
            return MENU.GET_FAVORITE;
        case MENU.MENU_DIALOGUES:
            return MENU.GET_DIALOGUES;
        case MENU.MENU_UNANSWERED:
            return MENU.GET_UNREAD;
        case MENU.MENU_LIKE:
            return MENU.GET_LIKE;
        default:
            return MENU.GET_ALL;
    }
}

export function getArrayOfNumbers(start: number, stop: number) {
    const result = [];
    while (start <= stop) {
        result.push(start++);
    }
    return result;
}

export function getDaysAmount(month: number, year: number) {
    month = month ? month : 1;
    year = year ? year : 1996; // у випадку, якщо рік не передається, по дефолту для обчислення беремо високосний, щоб для лютого показувати 29 днів
    const lastDayDate = new Date(year, month, 0);
    const lastDay = +lastDayDate?.toLocaleString('EN', { day: 'numeric' });
    let startDay = REGISTER.START_DAY;

    const result = [];
    while (startDay <= lastDay) {
        result.push(startDay++);
    }
    return result;
}

export function checkDaysAmount(day: number, month: number, year: number) {
    month = month ? month : 1;
    year = year ? year : 1996; // у випадку, якщо рік не передається, по дефолту для обчислення беремо високосний, щоб для лютого показувати 29 днів
    const lastDayDate = new Date(year, month, 0);
    const lastDay = +lastDayDate?.toLocaleString('EN', { day: 'numeric' });
    return day <= lastDay;
}

export function getArrayOfNumbersYear(start: number, stop: number) {
    const result = [];

    while (stop >= start) {
        result.push(stop--);
    }

    return result;
}

export function isTopPositionInArrHistoryMails(arr: any[], index: number) {
    let posIndex = 0;

    arr.forEach((item, key) => {
        if (+item?.mail?.id === +index) {
            posIndex = key;
        }
    });

    return arr?.length / 2 >= posIndex;
}

export function displayDaysUntilBirthdayOnProfilePage(month: number, day: number) {
    if (+month > 0 && +day > 0) {
        const daysUntilBirthday = daysUnitBirthday(+month, +day);

        if (daysUntilBirthday > 14) return null;
        if (daysUntilBirthday === 0) return 'Happy Birthday!';

        let _days = 'days';
        if (daysUntilBirthday === 1) _days = 'day';

        return `(${daysUntilBirthday} ${_days} till birthday)`;
    }
    return null;
}

export function firstCharacterToUppercase(str: string) {
    if (str?.length === 0 || !str) return '';
    return str?.[0]?.toUpperCase() + str?.slice(1);
}

export function checkUrl(str: string) {
    return str?.search(REGEXP.URL) >= 0 || str?.search(REGEXP.URL_DOMAIN) >= 0;
}

export function stringToColor(str: string) {
    let hash = 0;
    let color = '#';
    let i;
    let value;
    const strLength = str?.length;

    if (!str) return color + '333333';

    for (i = 0; i < strLength; i++) {
        hash = str?.charCodeAt(i) + ((hash << 5) - hash);
    }

    for (i = 0; i < 3; i++) {
        value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value?.toString(16)).substr(-2);
    }

    return color;
}

export function substrName(name = '') {
    return name?.substr(0, 2);
}

export function initCancelTokenSource(
    state: any,
    setState: any,
    message = 'Operation canceled by the user.',
) {
    try {
        state && state.cancel(message);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        setState(source);

        return source;
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}

export function getAvatarMiniature(
    link: string | null = null,
    sizeName = AWS.AWS_THUMBS_DEFAULT_TYPE,
    defaultLink = '/img/profile_img_empty.png',
) {
    if (!link) return defaultLink;

    try {
        const splitLink = link?.split(`/`);

        AWS.AWS_THUMBS.forEach((item) => {
            if (item?.name === sizeName) {
                splitLink[splitLink?.length - 1] =
                    `w-${item?.width}-h-${item?.height}-${splitLink?.[splitLink?.length - 1]}`;
                link = splitLink?.join('/');
            }
        });
        return link;
    } catch (err) {
        return link;
    }
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    // const i = Math.floor(Math.log(bytes) / Math.log(k));

    const i = 2; // for MB
    return parseFloat((bytes / Math.pow(k, i))?.toFixed(dm));
}

export function checkFileAndShowAlert(
    { type, name, size }: { type: string; name: string; size: number },
    fileType = '',
    callback: any,
) {
    switch (fileType) {
        case ATTACH.TYPE_IMAGE:
            if (!type?.includes('jpeg') && !type?.includes('jpg') && !type?.includes('png')) {
                callback(`Please upload a file in the jpg or png format`, 'Wrong file format');
                return false;
            }
            if (type?.split('/')?.[0] !== ATTACH.TYPE_IMAGE) {
                callback(
                    `You’ve likely not uploaded not an image file. The file must be an image`,
                    `Wrong file attached`,
                );
                return false;
            }
            if (formatBytes(size) > 5) {
                callback(`The size of the uploaded file must not exceed 5 MB`, `Size limit`);
                return false;
            }

            break;

        case ATTACH.TYPE_AUDIO:
            if (
                !type?.includes('mpeg') &&
                !type?.includes('x-m4a') &&
                !type?.includes('mp3') &&
                !type?.includes('ogg') &&
                !type?.includes('wav')
            ) {
                callback(
                    `Please upload a file in the mp3, mpeg, x-m4a, wav or ogg format`,
                    'Wrong file format',
                );
                return false;
            }
            if (type?.split('/')?.[0] !== ATTACH.TYPE_AUDIO) {
                callback(
                    `Probably, you've uploaded not an audio file. File must be an audio`,
                    `Wrong file attached`,
                );
                return false;
            }

            break;

        case ATTACH.TYPE_VIDEO:
            if (type?.split('/')?.[0] !== 'video') {
                callback(
                    `Probably, you've uploaded not a video file. File must be a video`,
                    `Wrong file attached`,
                );
                return false;
            }

            break;

        default:
            break;
    }

    if (fileType !== ATTACH.TYPE_VIDEO && fileType !== ATTACH.TYPE_AUDIO) {
        if (name?.search(REGEXP.IMAGE_FILENAME) >= 0) {
            callback(
                `The file name must only include letters, numbers, underscores, and should be in Latin characters.`,
                'Wrong file name',
            );
            return false;
        }
    }

    return true;
}

export function hideStringStar(string = '', rules = REGEXP.NUMBER_IN_STRING) {
    const foundStr = string?.match(rules);

    if (foundStr?.length) {
        foundStr.forEach((item) => {
            string = string?.replace(item, '*'.repeat(item?.length));
        });
    }

    return string;
}

export function filterToOpenAnsweredLetter(array: { mail: { operator: number; id: number } }[]) {
    const lastLetterByGirlId = array?.filter((item) => +item?.mail?.operator === 1)?.[
        array?.filter((item) => +item?.mail?.operator === 1)?.length - 1
    ]?.mail?.id;
    const lastLetterByGirlIndex = array?.findIndex(
        (item) => +item?.mail?.id === +lastLetterByGirlId,
    );

    if (+lastLetterByGirlIndex === 0) return array;

    const filteredArray = [...array];
    filteredArray?.splice(0, lastLetterByGirlIndex);

    return filteredArray;
}

export function makeCDN(image: string, type = 'IMAGE') {
    switch (type) {
        case 'POSTS':
            return image?.replace(AWS.AWS_CLOUDFRONT_POSTS?.origin, AWS.AWS_CLOUDFRONT_POSTS?.cdn);
        case 'VIDEO':
            return image?.replace(AWS.AWS_CLOUDFRONT_VIDEO?.origin, AWS.AWS_CLOUDFRONT_VIDEO?.cdn);
        case 'AUDIO':
            return image?.replace(AWS.AWS_CLOUDFRONT_AUDIO?.origin, AWS.AWS_CLOUDFRONT_AUDIO?.cdn);
        case 'IMAGE':
        default:
            return image?.replace(AWS.AWS_CLOUDFRONT_IMAGE?.origin, AWS.AWS_CLOUDFRONT_IMAGE?.cdn);
    }
}

export function getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function sleepPromise(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function checkEmailPattern(email: string | null = null) {
    if (email === null || +email?.length > LENGTH.MAX_EMAIL_LENGTH) return false;

    const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const cyrillicPattern = /[\u0400-\u04FF]/;

    return !cyrillicPattern?.test(email) && pattern?.test(email);
}

export async function checkEmailAPI(email: string, hasV = false) {
    if (!email?.length) return false;

    const resValidToken = await new LoginApi().checkLoginValidation();

    if (resValidToken?.status) {
        return new LoginApi().checkEmail(email, hasV);
    }
}

export function checkTwoInt(value: number) {
    return +value > 9 ? `${value}` : `0${value}`;
}

export function checkNationalitySite(siteId: number) {
    if (MIRROR.LATINA_SITES.includes(siteId)) {
        return 'latina';
    } else if (MIRROR.ASIA_SITES.includes(siteId)) {
        return 'asia';
    } else {
        return 'europe';
    }
}

export function addUrlParameter(paramsName: string, paramsValue: string) {
    const url = new URL(window?.location?.href);
    const searchParams = url?.searchParams;

    if (searchParams?.has(paramsName)) {
        searchParams?.delete(paramsName);
    }

    searchParams?.append(paramsName, paramsValue);
    const newUrl = `${url?.origin}${url?.pathname}?${searchParams?.toString()}`;
    window?.history?.replaceState({}, '', newUrl);
}

export function deleteUrlParameter(paramsName: string) {
    const url = new URL(window?.location?.href);
    const searchParams = url?.searchParams;

    if (searchParams?.has(paramsName)) {
        searchParams?.delete(paramsName);
    }

    const newUrl = `${url?.origin}${url?.pathname}${searchParams?.size === 0 ? '' : '?' + searchParams?.toString()}`;
    window?.history?.replaceState({}, '', newUrl);
}

export function getPostCount() {
    if (window?.innerWidth >= 1024) {
        return POSTS.POST_COUNT_PC;
    } else if (window?.innerWidth >= 768) {
        return window.location.pathname.includes('profile')
            ? POSTS.PROFILE_POST_COUNT_MOB
            : POSTS.POST_COUNT_TABLET;
    } else {
        return window.location.pathname.includes('profile')
            ? POSTS.PROFILE_POST_COUNT_MOB
            : POSTS.POST_COUNT_MOB;
    }
}
