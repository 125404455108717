import React, { useEffect, useRef } from 'react';
import SwitchLetterAttachment from '../../../common/SwitchLetterAttachment';
import { convertPrice } from '../../../../services/methods';
import MailboxApi from '../../../../services/api/MailboxApi';
import * as currentChatMailboxAction from '../../../../store/actions/currentChatMailbox';
import { formatDate } from '../../../../services/time';
import * as PRICES from '../../../../constants/prices';
import * as ALERT from '../../../../constants/alert';
import { useDispatch, useSelector } from 'react-redux';
import useHandleLettersListItem from '../../../../hooks/letters/useHandleLettersListItem';
import * as loaderAction from '../../../../store/actions/loader';
import * as userAction from '../../../../store/actions/user';
import * as alertAction from '../../../../store/actions/alert';
import SITE from '../../../../config/site';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import IconBookmark from '../../../Icons/IconBookmark';
import IconBookmarkActive from '../../../Icons/IconBookmarkActive';
import SlickContainer from '../../../common/SlickContainer';
import { sendErrToSentry } from '../../../../services/sentry';
import { RootState } from '../../../../index';
import { IMailsAttachment, IMailsFullInfo } from '../../../../services/api/MailboxApi.interface';
import avatar_placeholder from '../../../../img/placeholder.gif';
import IconTrash from '../../../Icons/IconTrash';
import * as countersAction from '../../../../store/actions/counters';

interface IMessageLeftProps {
    item: IMailsFullInfo;
    scrollPosition: (value: number) => void;
    scrollToBookmark: (value: React.RefObject<HTMLDivElement>, id: number) => void;
}

export default function MessageLeft({ item, scrollPosition, scrollToBookmark }: IMessageLeftProps) {
    const userState = useSelector((state: RootState) => state.user);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const messageRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const handleLettersListItem = useHandleLettersListItem(item?.mail);
    const handleActiveBlocks = useHandleActiveBlocks();

    const isNewMail = userState?.info?.newMail;
    const isFreeReading =
        isNewMail &&
        (!+currentChatMailboxState?.mailChatInfo?.connect ||
            !!(+item?.mail?.paid_read && !+item?.mail?.read_status));
    const isHideText =
        isNewMail &&
        (handleActiveBlocks.isNewMailCategory1
            ? !+item?.mail?.paid_read
            : !+item?.mail?.paid_read || !!(+item?.mail?.paid_read && !+item?.mail?.read_status));
    const isFreeAttachments = item?.attachments?.every(
        (item: IMailsAttachment) => +item?.attachment_free,
    );
    const isTitleAttachmentPrice = !isFreeAttachments && SITE.ID !== 4;

    const avatar = item?.mail?.sender_avatar || item?.mail?.sender_image || avatar_placeholder;

    const titleBookmark = +item?.mail?.bookmark ? 'Bookmarked' : 'Bookmarks';
    const titleReadLetterBtn = isFreeReading
        ? 'Read for free'
        : `Read for ${convertPrice(+(userState?.prices?.[PRICES.READ_MAIL] || 0))} credits`;
    const titleAttachmentPrice = `The cost of each attachment is: photo - ${convertPrice(+(userState?.prices?.[PRICES.GET_IMAGE_MAIL] || 0))} credits, video - ${convertPrice(+(userState?.prices?.[isNewMail ? PRICES.GET_VIDEO_MAIL_NEW : PRICES.GET_VIDEO_MAIL] || 0))} credits, audio - ${convertPrice(+(userState?.prices?.[isNewMail ? PRICES.GET_AUDIO_MAIL_NEW : PRICES.GET_AUDIO_MAIL] || 0))} credits. Special bonus - the first photo is free.`;

    const styleBookmark = +item?.mail?.bookmark ? 'bookmarks' : '';
    const styleActiveBookmark = +item?.mail?.bookmark ? 'active' : '';

    const mailDate = formatDate(item?.mail?.date_created);
    const mailText = item?.mail?.message_content || '';

    const textContent = () => {
        if (!isHideText) return mailText;

        const visibleText = mailText.substr(0, 25);
        const unVisibleText = mailText.substr(25);

        return (
            <>
                <span data-testid="letter-visible-content">{visibleText}</span>{' '}
                <span
                    className="c3l_men_list_item_text_blur unselectable"
                    data-testid="letter-blur-content"
                >
                    {unVisibleText}
                </span>
            </>
        );
    };

    const onReadLetter = async () => {
        dispatch(loaderAction.setActiveStatus(true));
        const paidRes = await handleLettersListItem.paidNewLetterText(
            item?.mail?.id,
            item?.mail?.female_id,
        );
        if (paidRes?.status) {
            dispatch(
                currentChatMailboxAction.setPayedReadStatus(
                    item?.mail?.id,
                    paidRes?.message_content,
                ),
            );
        } else {
            if (paidRes?.message)
                dispatch(
                    alertAction.setMessage({
                        message: paidRes?.message,
                        title: paidRes?.title,
                    }),
                );
        }
        dispatch(currentChatMailboxAction.setIsNewMessage(false));
        if (userState?.info?.external_id) {
            dispatch(countersAction.getLetterCounter(userState?.info?.external_id));
        }
        dispatch(loaderAction.setActiveStatus(false));

        if (!isFreeReading) dispatch(userAction.setBalance());
    };

    const setBookmark = () => {
        if (!userState?.info?.external_id) return;

        if (+item?.mail?.bookmark === 0) {
            new MailboxApi()
                .postSetBookmark({ userId: userState?.info?.external_id, mailId: item?.mail?.id })
                .then((res) => {
                    if (res?.status && userState?.info?.external_id) {
                        dispatch(
                            currentChatMailboxAction.getMailboxChatHistoryForce({
                                userId: userState?.info?.external_id,
                                folder: 'dialog',
                                girlId: item?.mail?.public_external_id?.toString(),
                            }),
                        );
                    }
                });
        } else {
            new MailboxApi()
                .postUnsetBookmark({ userId: userState?.info?.external_id, mailId: item?.mail?.id })
                .then((res) => {
                    if (res?.status && userState?.info?.external_id) {
                        dispatch(
                            currentChatMailboxAction.getMailboxChatHistoryForce({
                                userId: userState?.info?.external_id,
                                folder: 'dialog',
                                girlId: item?.mail?.public_external_id?.toString(),
                            }),
                        );
                    }
                });
        }
    };

    const moveToTrash = () => {
        if (!userState?.info?.external_id) return;
        try {
            new MailboxApi()
                .postTrashMail({
                    userId: userState?.info?.external_id,
                    mailId: item?.mail?.id,
                })
                .then((res) => {
                    if (res?.status && userState?.info?.external_id) {
                        dispatch(
                            currentChatMailboxAction.getMailboxChatHistoryForce({
                                userId: userState?.info?.external_id,
                                folder: 'dialog',
                                girlId: item?.mail?.public_external_id?.toString(),
                            }),
                        );
                    }
                });
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const openAlertMoveToTrash = () => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.ADD_TO_TRASH_FROM_DIALOGS,
                successCallback: moveToTrash,
            }),
        );
    };

    useEffect(() => {
        if (currentChatMailboxState?.specialLetterId && !currentChatMailboxState?.bookmarkLetterId)
            scrollPosition(currentChatMailboxState?.specialLetterId);
    }, [currentChatMailboxState.specialLetterId]);

    useEffect(() => {
        if (currentChatMailboxState?.bookmarkLetterId) scrollToBookmark(messageRef, item?.mail?.id);
    }, [currentChatMailboxState.bookmarkLetterId, currentChatMailboxState?.history]);

    return (
        <>
            <div
                className={`c3l_letters_list_item left ${styleBookmark}`}
                id={`letter-${item?.mail?.id}`}
                data-testid={`received-letter-${item?.mail?.id}`}
                ref={messageRef}
            >
                <div className="c3l_letters_list_item_head">
                    <div className="c3l_letters_list_item_user">
                        <img src={avatar} alt="avatar" data-testid="letter-avatar" />
                        <span data-testid="name">{`${item?.mail?.sender_name}, ${item?.mail?.sender_age}`}</span>
                    </div>
                    <div
                        className={`c3l_letters_list_item_bookmarks ${styleActiveBookmark}`}
                        onClick={setBookmark}
                        data-testid="bookmark-btn"
                    >
                        {+item?.mail?.bookmark ? <IconBookmarkActive /> : <IconBookmark />}
                        <span>{titleBookmark}</span>
                    </div>
                </div>
                <div className="c3l_letters_list_item_text" data-testid="letter-content">
                    <p className="large">{textContent()}</p>
                    {isHideText && (
                        <div
                            className="c3l_letters_list_item_read_btn"
                            onClick={onReadLetter}
                            data-testid="read-btn"
                        >
                            {SITE.ID === 4 ? 'Read' : titleReadLetterBtn}
                        </div>
                    )}
                </div>
                <div className="c3l_letters_list_item_time" data-testid="letter-date">
                    {mailDate}
                    <div
                        className="c3l_letters_list_item_delete left"
                        onClick={openAlertMoveToTrash}
                        data-testid="delete-btn"
                    >
                        <IconTrash />
                        <span>Delete</span>
                    </div>
                </div>

                {!!item?.attachments?.length && (
                    <div className="c3l_letters_list_item_bottom">
                        {isTitleAttachmentPrice && (
                            <div
                                className="c3l_men_list_item_main_bottom_cost_text"
                                data-testid="cost-price"
                            >
                                {titleAttachmentPrice}
                            </div>
                        )}
                        <div className="letters_attach_files_list" data-testid="attachment-list">
                            <SlickContainer>
                                {item?.attachments?.map((attach: IMailsAttachment, key: number) => (
                                    <SwitchLetterAttachment
                                        key={`${key}_${attach?.attachment_id}_${item?.mail?.id}`}
                                        data={attach}
                                        letterID={item?.mail?.id}
                                        isFreeToWatch={+item?.mail?.operator === 0}
                                    />
                                ))}
                            </SlickContainer>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
