import * as SYNC from '../../constants/sync';
import TransactionApi from '../../services/api/v3/TransactionApi';
import { useDispatch, useSelector } from 'react-redux';
import * as transactionAction from '../../store/actions/transaction';
import FrontSyncApi from '../../services/api/v3/FrontSyncApi';
import { RootState } from '../../index';

export default function useSendByConfigType() {
    const dispatch = useDispatch();
    const transactionState = useSelector((state: RootState) => state.transaction);

    const subSendByConfigType = async (attempt: number) => {
        attempt = attempt - 1;
        const transactionRes = await new TransactionApi().postAskTransaction(
            transactionState?.transaction,
        );

        if (transactionRes?.status || attempt < 1) {
            dispatch(transactionAction.setTransaction());
            new FrontSyncApi().postNextTransactionSync();
        } else {
            subSendByConfigType(attempt);
        }
    };

    return () => {
        if (SYNC.TRANSACTION_CONFIG_LIST.includes(transactionState?.transaction?.type)) {
            return setTimeout(async () => {
                const isCheck = await new FrontSyncApi().postCheckTransactionSync(
                    +transactionState?.transaction?.delay,
                );

                if (isCheck?.status) {
                    const transactionRes = await new TransactionApi().postAskTransaction(
                        transactionState?.transaction,
                    );

                    if (transactionRes?.status) {
                        dispatch(transactionAction.setTransaction(null));
                        new FrontSyncApi().postNextTransactionSync();
                    } else {
                        subSendByConfigType(10);
                    }
                }
            }, +transactionState?.transaction?.delay * 1000);
        }
    };
}
