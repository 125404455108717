import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as modalsAction from '../../store/actions/modals';
import { RootState } from '../../index';
import { IWomanGalleryImage } from '../../store/actions/modals/index.interface';

export default function useInitWomanProfileGallery() {
    const dispatch = useDispatch();

    const modalsState = useSelector((state: RootState) => state.modals);

    const [galleryContent, setGalleryContent] = useState<IWomanGalleryImage[]>([]);
    const [videoContent, setVideoContent] = useState<any>(null);

    const prevImage = () => {
        const filteredGalleryContent = galleryContent?.filter(
            (item, index) => index !== galleryContent?.length - 1,
        );

        setGalleryContent([
            galleryContent[galleryContent?.length - 1],
            ...(filteredGalleryContent?.length ? filteredGalleryContent : []),
        ]);
    };
    const nextImage = (
        event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
    ) => {
        event?.preventDefault();

        const filteredGalleryContent = galleryContent?.filter((item, index) => index !== 0);

        setGalleryContent([
            ...(filteredGalleryContent?.length ? filteredGalleryContent : []),
            galleryContent[0],
        ]);
    };

    const setSelectedImage = (ind: number) => {
        const filteredGalleryContent = galleryContent?.filter((item, index) => index !== ind);

        setGalleryContent([
            galleryContent[ind],
            ...(filteredGalleryContent?.length ? filteredGalleryContent : []),
        ]);
    };

    const prevVideo = () => {
        const filteredVideoContent = videoContent?.filter(
            (item: any, index: number) => index !== videoContent?.length - 1,
        );

        setVideoContent([
            videoContent[videoContent?.length - 1],
            ...(filteredVideoContent?.length ? filteredVideoContent : []),
        ]);
    };
    const nextVideo = () => {
        const filteredVideoContent = videoContent?.filter(
            (item: any, index: number) => index !== 0,
        );

        setVideoContent([
            ...(filteredVideoContent?.length ? filteredVideoContent : []),
            videoContent[0],
        ]);
    };

    const openGalleryModal = (
        url: string,
        free: number,
        images: IWomanGalleryImage[],
        index: number,
    ) => {
        if (!free) return;
        dispatch(
            modalsAction.openWomanGalleryModal(
                modalsState?.womanProfileModal?.externalID || 0,
                images,
                index,
            ),
        );
    };

    return {
        openGalleryModal,
        prevImage,
        prevVideo,
        setSelectedImage,
        nextImage,
        nextVideo,
        galleryContent,
        setGalleryContent,
        videoContent,
        setVideoContent,
    };
}
