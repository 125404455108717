import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderLetter from '../../common/HeaderChatRoom';
import MessageForm from './MessageForm';
import ScrollDownButton from '../../common/Buttons/ScrollDownButton';
import NewMessageInChat from '../../common/Buttons/NewMessageInChatButton';
import WhitePrimaryButton from '../../common/Buttons/WhitePrimaryButton';
import useHandleLettersChat from '../../../hooks/letters/useHandleLettersChat';
import { firstCharacterToUppercase, filterToOpenAnsweredLetter } from '../../../services/methods';
import * as LETTER from '../../../constants/letters';
import * as PRICES from '../../../constants/prices';
import OpenFormLetterButton from '../../common/Buttons/OpenFormLetterButton';
import { RootState } from '../../../index';
import { IMailsFullInfo } from '../../../services/api/MailboxApi.interface';
import MessageRight from './MessageRight';
import MessageLeft from './MessageLeft';
import IconSendMessage from '../../Icons/IconSendMessage';
import { isHolidayActive } from '../../../constants/holiday';

export default function LettersChat() {
    const handleLettersChat = useHandleLettersChat();
    const activeHoliday = isHolidayActive();
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);

    const [activeSmiles, setActiveSmiles] = useState(false);

    const isNewMail = userState?.info?.newMail;
    const isFirstUserLetter = !currentChatMailboxState?.history?.some(
        (item) => !+item?.mail?.operator,
    );
    const price = isNewMail
        ? isFirstUserLetter
            ? userState?.prices?.[PRICES.SENT_MAIL_FIRST]
            : userState?.prices?.[PRICES.SENT_MAIL_SECOND]
        : userState?.prices?.[PRICES.SENT_MAIL];

    const history: IMailsFullInfo[] =
        currentChatMailboxState?.info?.actionTarget === LETTER.ACTION_TARGET_LETTER &&
        !currentChatMailboxState?.bookmarkLetterId
            ? currentChatMailboxState?.history?.filter(
                  (item) => +item?.mail?.mail_read_status === 0 && +item?.mail?.operator === 1,
              )?.length > 0
                ? currentChatMailboxState?.history?.filter(
                      (item) => +item?.mail?.mail_read_status === 0 && +item?.mail?.operator === 1,
                  )
                : filterToOpenAnsweredLetter(currentChatMailboxState?.history)
            : currentChatMailboxState?.history;

    return (
        <div className={`c3l_letters_list_wrap ${activeHoliday && activeHoliday?.name}`}>
            <HeaderLetter />
            <div className="c3l_letters_list_wrap2" data-testid="letter-body">
                {!handleLettersChat.isViewLetterHistory &&
                    !currentChatMailboxState?.bookmarkLetterId && (
                        <div className="c3l_letters_list_history_wrap">
                            <div
                                className="c3l_letters_list_history_btn"
                                onClick={handleLettersChat.viewLetterHistory}
                                data-testid="view-history-btn"
                            >
                                <img src="../../../img/arrow-top-blue.svg" alt="" />
                                <span>Click to view letter history</span>
                            </div>
                        </div>
                    )}

                <div
                    className={`c3l_letters_list_box_wrap ${handleLettersChat.isOpenForm ? '' : 'close_letter_form'} c3l_letters_scroll ${activeSmiles ? 'active-smile' : ''}`}
                    onScroll={handleLettersChat.onScrollList}
                >
                    <NewMessageInChat
                        title={'View new letters'}
                        isActiveButton={currentChatMailboxState.isNewMessage}
                        onPressButton={handleLettersChat.scrollDown}
                        isLetterFormOpen={handleLettersChat.isOpenForm}
                    />

                    {handleLettersChat?.isOpenForm && (
                        <ScrollDownButton
                            bottomIndentation={handleLettersChat.isOpenForm}
                            isActiveButton={handleLettersChat.pageShiftDown > 1}
                            onPressButton={handleLettersChat.scrollDown}
                        />
                    )}

                    {!!history?.length &&
                        history?.map((item) => {
                            const isUserMail =
                                +item?.mail?.sender_id === +(userState?.info?.external_id || 0);

                            return isUserMail ? (
                                <MessageRight
                                    item={item}
                                    key={item?.mail?.id}
                                    scrollPosition={handleLettersChat.scrollPosition}
                                />
                            ) : (
                                <MessageLeft
                                    item={item}
                                    key={item?.mail?.id}
                                    scrollPosition={handleLettersChat.scrollPosition}
                                    scrollToBookmark={handleLettersChat.scrollToBookmark}
                                />
                            );
                        })}
                </div>

                {handleLettersChat?.isOpenForm ? (
                    <MessageForm
                        setOpenForm={handleLettersChat.setIsOpenForm}
                        setActiveSmiles={setActiveSmiles}
                        price={price || ''}
                    />
                ) : (
                    <>
                        <div className="c3l_letters_chat_bottom">
                            <WhitePrimaryButton
                                onPressButton={handleLettersChat.returnToLetterList}
                                titleButton={`Return to "${firstCharacterToUppercase(menuState.letters === 'mailbox' ? 'mailbox' : menuState.letters)}"`}
                            />
                            <OpenFormLetterButton
                                onPressButton={handleLettersChat.onOpenForm}
                                titleButton={'Send a letter'}
                                SVGButton={IconSendMessage}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
