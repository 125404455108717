import React, { memo } from 'react';
import styles from '../styles.module.scss';
import moment from 'moment';
import { ITopProfile } from '../../../../services/api/v3/SearchApi.interface';

interface ISliderItemProps {
    profile: ITopProfile;
    openGalleryModal: (id: number, images: any) => void;
    openChat: (id: number) => void;
    openLetter: (id: number) => void;
    className?: string;
}

const SliderItem = ({ profile, openGalleryModal, openChat, openLetter }: ISliderItemProps) => {
    return (
        <>
            <div className={styles.unsubscribe_girls_list_item_wrapper}>
                <div className={styles.unsubscribe_girls_list_item} key={profile?.id}>
                    <div className={styles.girl_card}>
                        <div className={styles.girl_card_photo_wrap}>
                            <img
                                className={styles.girl_card_photo}
                                src={profile?.search_img}
                                alt=""
                            />
                            <button
                                type="button"
                                className={styles.girl_card_photo_all_btn}
                                onClick={() => openGalleryModal(profile?.external_id, [])}
                            >
                                All photo
                            </button>
                        </div>
                        {/*{!!+profile?.online &&*/}
                        <div className={styles.girl_card_online_status}>I’m online now</div>
                        {/*}*/}
                        <div className={styles.girl_card_bottom}>
                            <p className={styles.girl_card_name}>
                                {profile?.name ?? ''},{' '}
                                {moment().diff(profile?.date_birth, 'years') || ''}
                            </p>
                            <div className={styles.girl_card_buttons}>
                                <button
                                    type="button"
                                    className={`${styles.girl_card_btn} ${styles.girl_card_btn_chat}`}
                                    onClick={() => openChat(profile?.external_id)}
                                >
                                    Chat
                                    {!!+profile?.unreadChat && (
                                        <span className={styles.girl_card_btn_label}>New</span>
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className={`${styles.girl_card_btn} ${styles.girl_card_btn_letter}`}
                                    onClick={() => openLetter(profile?.external_id)}
                                >
                                    Letter
                                    {!!+profile?.unreadLetter && (
                                        <span className={styles.girl_card_btn_label}>New</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default memo(SliderItem);
