import { getImageLink } from '../../services/methods/search/images';

// TODO: {IPhoto} need nested interface
export interface IPhoto {
    link: string;
    free: number;
    payed: number;
}

export const formatPhotosGallery = (photos: IPhoto[]) => {
    return photos.map((item: IPhoto) => ({
        original: getImageLink(item.link, [1024, 768]),
        thumbnail: getImageLink(item.link, [640, 480]),
        free: !(+item?.free === 0 && !item?.payed),
    }));
};
