import React, { useState } from 'react';
import styles from '../MessageListEmpty/styles.module.scss';
import IconCross from '../../../../../Icons/IconCross';
import useSendWink from '../../../../../../hooks/wink/useSendWink';

interface IWinkListProps {
    sayHelloList: string[];
    womanID: number;
}

export default function WinkList({ sayHelloList, womanID }: IWinkListProps) {
    const [isOpenHelloList, setIsOpenHelloList] = useState(false);

    const sendWink = useSendWink();

    const toggleHelloList = () => setIsOpenHelloList(!isOpenHelloList);

    return (
        <div className={styles.empty_chat_say_hello_wrap}>
            <div
                className={`${styles.empty_chat_say_hello_list_wrap} ${isOpenHelloList ? styles.active : ''}`}
            >
                <ul className={styles.empty_chat_say_hello_list} data-testid="say-hello-list">
                    {!!sayHelloList?.length &&
                        sayHelloList.map((item, key) => (
                            <li
                                className={styles.empty_chat_say_hello_list_item}
                                key={key}
                                onClick={() => {
                                    sendWink({
                                        id: womanID,
                                        helloText: item,
                                    }).then(() => setIsOpenHelloList(false));
                                }}
                            >
                                {item}
                            </li>
                        ))}
                </ul>
            </div>

            <div
                className={`${styles.empty_chat_say_hello_more_btn} ${isOpenHelloList ? styles.active : ''}`}
                onClick={toggleHelloList}
                data-testid="say-hello-btn"
            >
                {isOpenHelloList ? (
                    <div>
                        <IconCross />
                        <span>Close</span>
                    </div>
                ) : (
                    <>
                        <div>...</div>
                        <div>More</div>
                    </>
                )}
            </div>
        </div>
    );
}
