import React from 'react';

export default function IconRestore() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#6F0267"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_17_1174)">
                <path d="M13.5044 15.5803C13.9431 15.4206 14.3612 15.2094 14.75 14.9509L14.0588 13.9097C13.7528 14.1133 13.4236 14.2794 13.0781 14.4047L13.5044 15.5803Z" />
                <path d="M10.9055 14.6528L10.7549 15.8947C10.998 15.9235 11.2549 15.9335 11.4905 15.9378C11.7102 15.9378 11.9298 15.925 12.148 15.8997L12.0061 14.6585C11.8343 14.6778 11.6616 14.6876 11.4886 14.6878C11.2974 14.6835 11.0961 14.6766 10.9055 14.6528Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.41309 18.75H15.7881V13.125H17.0381V20H0.163086V4.11625L4.27934 0H17.0381V3.75H15.7881V1.25H5.16308V5H1.41309V18.75ZM3.91309 3.75V2.13375L2.29684 3.75H3.91309ZM5.53906 10.0004C5.53887 6.72121 8.19699 4.06273 11.4762 4.0625C14.6707 4.06621 17.2897 6.59684 17.403 9.78937L19.0968 8.09562L19.9805 8.97937L16.7887 12.1712L13.5949 8.97937L14.4787 8.09562L16.1499 9.76687C16.0578 7.89324 14.8575 6.255 13.0987 5.60242C10.6716 4.70184 7.97395 5.93934 7.07336 8.36648C6.17277 10.7936 7.41027 13.4913 9.83742 14.3919L9.39992 15.5625C7.07836 14.6958 5.53922 12.4784 5.53906 10.0004Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_17_1174">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
