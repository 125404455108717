import React from 'react';
import styles from './styles.module.scss';

interface IOnboardingTitle {
    image: string;
    title: string;
    subtitle: string;
    btnTitle: string;
    onClick: () => void;
}

const OnboardingTitle = ({ image, title, subtitle, btnTitle, onClick }: IOnboardingTitle) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.image}>
                <img src={image} alt={''} />
            </div>
            <p className={styles.title}>{title}</p>
            <p className={styles.text}>{subtitle}</p>
            <button className={styles.button} onClick={onClick}>
                {btnTitle}
            </button>
        </div>
    );
};

export default OnboardingTitle;
