import React from 'react';
import Policy from '../../components/Policy';

export default function PolicyPage() {
    return (
        <>
            <Policy />
        </>
    );
}
