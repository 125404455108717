import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderLastMessage } from '../../../../../services/methods';
import Emoji from 'react-emoji-render';
import { scrollTopToMainChatWithPagination } from '../../../../../services/dom';
import { formatDate } from '../../../../../services/time';
import * as modalsAction from '../../../../../store/actions/modals';
import AudioPlayer from '../../../../common/AudioPlayer';
import * as STICKERS from '../../../../../constants/stickers';
import * as CHATS from '../../../../../constants/chats';
import { filterToHideChatHistoryByType, stopAudio } from '../../../../../services/methods/chat';
import styles from '../../styles.module.scss';
import { RootState } from '../../../../../index';
import { IMessageRightProps } from '../../../../common/MessageBlocks/MessageRight/index.interface';
import wink from '../../../../../img/wink.svg';

export default function MessageRight({ message }: IMessageRightProps) {
    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const [imageOnLoad, setImageOnLoad] = useState(false);

    const dispatch = useDispatch();

    const selectedSticker = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
        e?.name?.includes(message?.message_content),
    )?.image;

    const openImageModal = () => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: message?.message_popup || '',
                attachment_payed: '1',
            }),
        );
    };

    const openVideoModal = () => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: message?.message_content || '',
                attachment_payed: '1',
            }),
        );
    };

    useEffect(() => {
        if (currentChatState?.history?.length) {
            if (!currentChatState?.isNewMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            } else if (!currentChatState?.isReadLastMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            }
        }
    }, [currentChatState?.history, imageOnLoad]);

    return (
        <div
            className={`chat_message_wrap right 
                ${message?.message_type === CHATS.MESSAGE_CONTENT_TYPE_STICKER ? 'sticker' : ''}
                ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}
            `}
            id={`mess-${message?.id}`}
        >
            <div
                className={`chat_message ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}`}
            >
                <div className="message_content">
                    {(function () {
                        switch (message?.message_type) {
                            case CHATS.MESSAGE_CONTENT_TYPE_IMAGE:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_right} ${styles.photo}`}
                                    >
                                        <img
                                            src={message?.message_thumb}
                                            onLoad={() => setImageOnLoad(true)}
                                            alt=""
                                            className="message_img"
                                            onClick={openImageModal}
                                        />
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_VIDEO:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_right} ${styles.video}`}
                                    >
                                        <div className="message_video" onClick={openVideoModal}>
                                            {message?.thumb_link?.length ? (
                                                <img src={message?.thumb_link} alt="" />
                                            ) : (
                                                <video width="320" preload="metadata">
                                                    <source
                                                        src={`${message?.message_content}#t=0.6`}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            )}
                                        </div>
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_AUDIO:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_right} ${styles.audio}`}
                                    >
                                        <div className="message_audio_wrap">
                                            {/*<div className="c3_message_audio_title">*/}
                                            {/*    {message?.filename}*/}
                                            {/*</div>*/}
                                            <AudioPlayer
                                                src={message?.message_content}
                                                viewedAudio={() =>
                                                    stopAudio(
                                                        message?.message_content,
                                                        true,
                                                        message?.id,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_WINK:
                                if (message?.message_content?.includes('wink')) {
                                    return (
                                        <div
                                            className={`message_text ${styles.message_text_right} ${styles.wink}`}
                                        >
                                            <img
                                                src={wink}
                                                onLoad={() => setImageOnLoad(true)}
                                                alt="wink smile"
                                                className="message_img"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            className={`message_text ${styles.message_text_right} ${styles.emoji}`}
                                        >
                                            <Emoji text={message?.message_content} />
                                        </div>
                                    );
                                }

                            case CHATS.MESSAGE_CONTENT_TYPE_TEXT:
                            case CHATS.MESSAGE_CONTENT_TYPE_ASK_VIDEO:
                                return (
                                    <div className="message_text">
                                        <Emoji text={message?.message_content} />
                                    </div>
                                );

                            // case CHATS.MESSAGE_CONTENT_TYPE_WINK:
                            //     return (
                            //         <div className="message_text_hello">
                            //             <div className="message_text_hello_content">
                            //                 <div className="message_text_hello_title">
                            //                     <span>Hello</span>
                            //                     <img src={IMAGES.chat_hello_yellow} alt=""/>
                            //                 </div>
                            //                 <div className="message_text_hello_text"><Emoji
                            //                     text={message?.message_content}/></div>
                            //             </div>
                            //             <img src={IMAGES[`hello_img_${(randomNum < 5) ? randomNum : 1}`]} alt=""
                            //                  className={`message_text_hello_img img_${(randomNum < 5) ? randomNum : 1}`}
                            //             />
                            //         </div>
                            //     );

                            case CHATS.MESSAGE_CONTENT_TYPE_STICKER:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_right} ${styles.sticker}`}
                                    >
                                        {selectedSticker && <img src={selectedSticker} alt="" />}
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_LIKE:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_right} ${styles.like}`}
                                    >
                                        <img src="../../../../../img/c4-like.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_VIEW:
                                return (
                                    <div
                                        className={`message_text ${styles.message_text_right} ${styles.like}`}
                                    >
                                        <img src="../../../../../img/c4-viwed.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            default:
                                return null;
                        }
                    })()}
                    <div className="message_bottom">
                        {message?.date_created && (
                            <div className="message_date">{formatDate(message?.date_created)}</div>
                        )}
                        {message?.read_status !== undefined && (
                            <div
                                className={`message_status ${+message?.read_status === 0 ? 'send' : 'readed'}`}
                            />
                        )}
                    </div>
                </div>
                {/*<div className="message_avatar">*/}
                {/*    {userState?.info?.photo_link ?*/}
                {/*        <img src={getAvatarMiniature(userState?.info?.photo_link)} alt=""/>*/}
                {/*        :*/}
                {/*        <div*/}
                {/*            style={{backgroundColor: stringToColor(userState?.info?.name)}}>{substrName(userState?.info?.name)}</div>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    );
}
