import Base from './Base';
import { ISendContactRequestData } from './Requests.interface';

export default class RequestsAPI extends Base {
    sendContactRequest(data: ISendContactRequestData) {
        return super.post(`chat/contactRequest`, data);
    }
    getActiveContactRequests() {
        return super.get(`chat/activeContactRequests`);
    }
}
