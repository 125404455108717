import React from 'react';
import styles from './styles.module.scss';
import { ICrossBannersData } from '../../../store/reducers/banners/index.interface';
import { checkNationalitySite } from '../../../services/methods';
import MarketingApi from '../../../services/api/MarketingApi';
import * as bannersAction from '../../../store/actions/banners';
import { useDispatch } from 'react-redux';
import Banners from '../../../img/cross-banners';

const OnlineNowCrossBannerNewDesign = (props: ICrossBannersData) => {
    const { target_site_id, log_id } = props;
    const nationality = checkNationalitySite(target_site_id);
    const imageSrc = Banners.images[`banner-online-now-${nationality}.png`];
    const dispatch = useDispatch();
    const openConfirmTermModal = () => {
        if (log_id) {
            new MarketingApi().postClickCrossBanner(log_id);
        }
        dispatch(bannersAction.setOpenConfirmTermsModal(props));
    };
    return (
        <div>
            <div className={styles.online_now_cross_banner_item}>
                <div className={styles.online_now_cross_banner_gallery}>
                    <img src={imageSrc} alt={`${nationality} girls`} />
                </div>
                <div className={styles.online_now_cross_banner_bottom}>
                    <h2 className={styles.online_now_cross_banner_bottom_title}>
                        Try <span>{nationality}</span> chatting
                    </h2>
                    <p className={styles.online_now_cross_banner_bottom_text}>
                        Meet people, share moments, and enjoy conversations anywhere
                    </p>
                    <button
                        type="button"
                        className={styles.online_now_cross_banner_button}
                        onClick={openConfirmTermModal}
                    >
                        <span className={styles.online_now_cross_banner_button_text}>
                            Try for free
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OnlineNowCrossBannerNewDesign;
