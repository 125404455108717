import React, { useRef, useState } from 'react';
import styles from '../../../styles/love-stories/style.module.scss';
import Slider from 'react-slick';
import photo_slider_1 from '../../../img/love-stories-page/ls_photo_slider_1.jpg';
import photo_slider_2 from '../../../img/love-stories-page/ls_photo_slider_2.jpg';
import photo_slider_3 from '../../../img/love-stories-page/ls_photo_slider_3.jpg';
import IconFAQQuestionArrow from '../../Icons/IconFAQQuestionArrow';
import IconFAQQuestionBackArrow from '../../Icons/IconFAQQuestionBackArrow';

export default function LoveStoriesPhotoSlider() {
    const [activePhotoSlide, setActivePhotoSlide] = useState(0);

    const lsPhotoSliderRef = useRef<Slider | null>(null);

    const prevPhotoSlider = () => {
        if (lsPhotoSliderRef?.current) lsPhotoSliderRef?.current?.slickPrev();
    };

    const nextPhotoSlider = () => {
        if (lsPhotoSliderRef?.current) lsPhotoSliderRef?.current?.slickNext();
    };

    const photoSliderSettings = {
        className: 'slider variable-width',
        infinite: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        focusOnSelect: true,
        beforeChange: (current: number, next: number) => setActivePhotoSlide(next),
        responsive: [
            {
                breakpoint: 699,
                settings: {
                    variableWidth: false,
                    centerMode: false,
                },
            },
        ],
    };

    const photoSliderArr = [
        { id: 1, img: photo_slider_1 },
        { id: 2, img: photo_slider_2 },
        { id: 3, img: photo_slider_3 },
    ];

    return (
        <div className={styles.ls_photo_slider_wrap}>
            <div className={styles.ls_inner_container}>
                <div className={styles.ls_photo_slider_row}>
                    <div className={styles.ls_photo_slider_text_block}>
                        <h2 className={styles.ls_title}>Real meetings</h2>
                        <p className={styles.ls_text}>
                            Time was, if you met your mate online, you developed a cover story. If
                            anyone asked, you'd say you met in a bar or at kayaking lessons. Not
                            anymore. Whether it's the fact that many of us already conduct so much
                            of our personal and business lives online, or the proliferation of
                            online dating sites touting their successful matches, it's perfectly
                            acceptable to say, with pride, that you met the love of your life with
                            your fingers on a keyboard, not wrapped around a cocktail at a singles
                            event.
                        </p>
                        <p className={styles.ls_text}>
                            Anyone who’s ever tried online dating has at least one horror story (or
                            many, many more), but that doesn’t mean that every viewed and liked
                            profile is a total dud.
                        </p>
                    </div>
                    <div className={styles.ls_photo_slider_block}>
                        <div className={`${styles.ls_title} ${styles.center}`}>Real meetings</div>
                        <Slider {...photoSliderSettings} ref={lsPhotoSliderRef}>
                            {photoSliderArr.map((item, index) => (
                                <div
                                    className={styles.ls_photo_slider_block_item_wrap}
                                    key={item.id}
                                >
                                    {activePhotoSlide === index && activePhotoSlide > 0 && (
                                        <div
                                            className={styles.ls_photo_slider_arrow_prev}
                                            onClick={prevPhotoSlider}
                                        >
                                            <IconFAQQuestionBackArrow />
                                        </div>
                                    )}
                                    {activePhotoSlide === index &&
                                        activePhotoSlide < photoSliderArr.length - 1 && (
                                            <div
                                                className={styles.ls_photo_slider_arrow_next}
                                                onClick={nextPhotoSlider}
                                            >
                                                <IconFAQQuestionArrow />
                                            </div>
                                        )}
                                    <div className={`${styles.ls_photo_slider_block_item}`}>
                                        <img src={item.img} alt="" />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}
