import React from 'react';

export default function IconAddPlus() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="#720066"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0.4C9.18538 0.4 8.525 1.06038 8.525 1.875V8.61918H1.80365C1.02844 8.61918 0.4 9.24761 0.4 10.0228C0.4 10.7981 1.02843 11.4264 1.80365 11.4264H8.525V18.125C8.525 18.9396 9.18538 19.6 10 19.6C10.8146 19.6 11.475 18.9396 11.475 18.125V11.4264H18.1963C18.9716 11.4264 19.6 10.7981 19.6 10.0228C19.6 9.24762 18.9715 8.61918 18.1963 8.61918H11.475V1.875C11.475 1.06038 10.8146 0.4 10 0.4ZM8.125 1.875C8.125 0.839471 8.96446 0 10 0C11.0356 0 11.875 0.839472 11.875 1.875V8.21918H18.1963C19.1925 8.21918 20 9.0267 20 10.0228C20 11.019 19.1924 11.8264 18.1963 11.8264H11.875V18.125C11.875 19.1605 11.0356 20 10 20C8.96446 20 8.125 19.1605 8.125 18.125V11.8264H1.80365C0.807534 11.8264 0 11.019 0 10.0228C0 9.0267 0.807522 8.21918 1.80365 8.21918H8.125V1.875Z"
            />
            <path d="M8.525 1.875C8.525 1.06038 9.18538 0.4 10 0.4C10.8146 0.4 11.475 1.06038 11.475 1.875V8.61918H18.1963C18.9715 8.61918 19.6 9.24762 19.6 10.0228C19.6 10.7981 18.9716 11.4264 18.1963 11.4264H11.475V18.125C11.475 18.9396 10.8146 19.6 10 19.6C9.18538 19.6 8.525 18.9396 8.525 18.125V11.4264H1.80365C1.02843 11.4264 0.4 10.7981 0.4 10.0228C0.4 9.24761 1.02844 8.61918 1.80365 8.61918H8.525V1.875Z" />
        </svg>
    );
}
