// eslint-disable-next-line
// @ts-ignore
import Resizer from 'react-image-file-resizer';
import moment from 'moment';

import * as AWS from '../../constants/aws';
import AwsApi from '../api/AwsApi';
import { sendErrToSentry } from '../sentry';

export default async (file: File, fileName: string, dir: string, bucketName: string, index = 1) => {
    const newFileName = moment().unix() + fileName;
    const fileParts = file?.name?.split('.');
    const fileType = fileParts[fileParts?.length - 1];

    if (bucketName === AWS.IMG_BUCKET || bucketName === AWS.MEN_IMAGES) {
        try {
            await new Promise((resolve) => {
                AWS.AWS_THUMBS.forEach((size) => {
                    Resizer.imageFileResizer(
                        file,
                        size.width,
                        size.height,
                        fileType,
                        90,
                        0,
                        async (uri: string | File | Blob | ProgressEvent<FileReader>) => {
                            let fileData: string | Blob;

                            if (typeof uri === 'string') {
                                fileData = uri;
                            } else if (uri instanceof Blob) {
                                fileData = uri;
                            } else if (uri instanceof File) {
                                fileData = uri;
                            } else if (uri instanceof ProgressEvent && uri.target?.result) {
                                fileData = uri.target.result as string;
                            } else {
                                return;
                            }

                            const formData2 = new FormData();

                            formData2.append('file', fileData);
                            formData2.append(
                                'newFileName',
                                `w-${size.width}-h-${size.height}-${newFileName}.${fileType}`,
                            );
                            formData2.append('fileName', fileName);
                            formData2.append('dir', dir);
                            formData2.append('bucketName', bucketName);
                            formData2.append('index', index?.toString());

                            resolve(await new AwsApi().generateLink(formData2));
                        },
                        'blob',
                    );
                });
            });
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }

    const formData = new FormData();

    formData.append('file', file);
    formData.append('newFileName', `${newFileName}.${fileType}`);
    formData.append('fileName', fileName);
    formData.append('dir', dir);
    formData.append('bucketName', bucketName);
    formData.append('index', index?.toString());

    let dataRes;
    switch (bucketName) {
        case AWS.VIDEO_BUCKET:
            dataRes = await new AwsApi().generateVideoLink(formData);
            if (dataRes?.status && dataRes.data) dataRes.data.sort_order = index;
            return dataRes;
        case AWS.AUDIO_BUCKET:
            dataRes = await new AwsApi().generateAudioLink(formData);
            if (dataRes?.status && dataRes.data) dataRes.data.sort_order = index;
            return dataRes;
        default:
            return new AwsApi().generateLink(formData);
    }
};
