import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { formatDate } from '../../../../services/time';
import { stringToColor, substrName } from '../../../../services/methods';
import { scrollTopToMainChatWithPagination } from '../../../../services/dom';
import { IVirtualGiftRowProps } from './index.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';

export default function VirtualGiftRow({ message, isRecipientSide = false }: IVirtualGiftRowProps) {
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);

    const [imageOnLoad, setImageOnLoad] = useState(false);

    useEffect(() => {
        if (currentChatState?.history?.length) {
            if (!currentChatState?.isNewMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            } else if (!currentChatState?.isReadLastMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            }
        }
    }, [currentChatState?.history, imageOnLoad]);

    return (
        <div
            className={styles.message_gift_wrap}
            id={`mess-${message?.id}`}
            data-testid={isRecipientSide ? 'received-message' : 'sent-message'}
        >
            <div className={`${styles.message_gift} ${isRecipientSide ? styles.recipient : ''}`}>
                <div className={styles.message_gift_content} data-testid="message-content">
                    <span className={styles.message_gift_name}>{message?.filename}</span>

                    {isRecipientSide ? (
                        <div className={styles.message_gift_info}>
                            <div
                                className={styles.message_gift_image_wrapper}
                                data-testid="message-avatar"
                            >
                                <img
                                    src={currentChatState?.info?.avatar}
                                    width="40"
                                    height="40"
                                    alt=""
                                />
                            </div>
                            <div
                                className={styles.message_gift_image_wrapper}
                                data-testid="message-avatar"
                            >
                                {userState?.info?.photo_link?.length ? (
                                    <img
                                        src={userState?.info?.photo_link}
                                        width="40"
                                        height="40"
                                        alt=""
                                    />
                                ) : (
                                    <div
                                        style={{
                                            backgroundColor: stringToColor(
                                                userState?.info?.name || '',
                                            ),
                                        }}
                                    >
                                        {substrName(userState?.info?.name)}
                                    </div>
                                )}
                            </div>
                            <span className={styles.message_gift_text}>
                                {currentChatState?.info?.name} sent a Virtual Gift for you
                            </span>
                        </div>
                    ) : (
                        <div className={styles.message_gift_info}>
                            <div
                                className={styles.message_gift_image_wrapper}
                                data-testid="message-avatar"
                            >
                                {userState?.info?.photo_link?.length ? (
                                    <img
                                        src={userState?.info?.photo_link}
                                        width="40"
                                        height="40"
                                        alt=""
                                    />
                                ) : (
                                    <div
                                        style={{
                                            backgroundColor: stringToColor(
                                                userState?.info?.name || '',
                                            ),
                                        }}
                                    >
                                        {substrName(userState?.info?.name)}
                                    </div>
                                )}
                            </div>
                            <div
                                className={styles.message_gift_image_wrapper}
                                data-testid="message-avatar"
                            >
                                <img
                                    src={currentChatState?.info?.avatar}
                                    width="40"
                                    height="40"
                                    alt=""
                                />
                            </div>
                            <span className={styles.message_gift_text}>
                                You sent a Virtual Gift for {currentChatState?.info?.name}
                            </span>
                        </div>
                    )}
                </div>

                <img
                    className={`${styles.message_gift_image} ${isRecipientSide ? styles.recipient : ''}`}
                    src={message?.message_content}
                    width="389"
                    height="389"
                    alt={message?.filename ?? ''}
                    onLoad={() => setImageOnLoad(true)}
                    data-testid={message?.message_content}
                />

                <div
                    className={`${styles.message_bottom} ${isRecipientSide ? styles.recipient : ''}`}
                >
                    {message?.date_created && (
                        <div className={styles.message_date} data-testid="message-date">
                            {formatDate(message?.date_created)}
                        </div>
                    )}
                    {message?.read_status !== undefined && !isRecipientSide && (
                        <div
                            className={`${styles.message_status} ${+message?.read_status === 0 ? styles.send : styles.read}`}
                            data-testid="message-status"
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
