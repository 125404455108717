import React from 'react';

export default function IconStarFill() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 1.44092L12.781 7.07492L19 7.98392L14.5 12.3669L15.562 18.5589L10 15.6339L4.438 18.5589L5.5 12.3669L1 7.98392L7.219 7.07492L10 1.44092Z" />
        </svg>
    );
}
