import React, { MouseEvent } from 'react';
import styles from './styles.module.scss';
import '../../../styles/faq-global.scss';
import { ICategoryItem } from '../../../store/reducers/tutorial/index.interface';

interface ICategory {
    categoryList: ICategoryItem[];
    activeCategory: number | null;
    activeTopicTitle?: string | null;
    activeTopicText?: string | null;
    isMobileCategoryOpened: boolean;
    backToTopics: () => void;
    changeActiveCategory: (id: number) => void;
    clickSubcategoryText: (event: MouseEvent<HTMLParagraphElement>) => void;
    fixMessageContent: (message: string) => string;
    isPaymentUser: boolean;
}

export default function Category({
    categoryList,
    activeCategory,
    activeTopicTitle,
    activeTopicText,
    isMobileCategoryOpened,
    backToTopics,
    changeActiveCategory,
    clickSubcategoryText,
    fixMessageContent,
    isPaymentUser,
}: ICategory) {
    const CategoryArrow = () => {
        return (
            <svg
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M-6.93351e-07 1.13799L6.9188 8.4994L0.00055218 15.8605L1.01099 17L9 8.49939L1.01099 -4.41917e-08L-6.93351e-07 1.13799Z"
                    fill="#374E6C"
                />
            </svg>
        );
    };

    const BackArrow = () => {
        return (
            <svg
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M9 1.13799L2.0812 8.4994L8.99945 15.8605L7.98901 17L3.71573e-07 8.49939L7.98901 -4.41917e-08L9 1.13799Z" />
            </svg>
        );
    };

    return (
        <div
            className={`${styles.category_col} ${isMobileCategoryOpened ? styles.mob_opened : ''}`}
        >
            <div className={styles.category_col_mob_head}>
                <div>
                    <div className={styles.head_back_btn} onClick={backToTopics}>
                        <BackArrow />
                        <span>To topics</span>
                    </div>
                </div>
                <div className={styles.head_title}>{activeTopicTitle}</div>
                <div />
            </div>
            <div className={styles.topic_text}>
                <p dangerouslySetInnerHTML={{ __html: fixMessageContent(activeTopicText ?? '') }} />
            </div>
            {categoryList
                .filter((el) => (isPaymentUser ? el : !el?.title?.includes('gift')))
                .sort((a, b) => a?.order - b?.order)
                .map((el, idx) => {
                    if (el?.subcategory_list?.length > 0) {
                        return (
                            <div
                                key={idx}
                                className={`${styles.category_item} ${el?.id === activeCategory ? styles.opened : ''}`}
                            >
                                <div
                                    className={styles.category_title}
                                    id={`category_item_${el?.id}`}
                                    onClick={() => {
                                        changeActiveCategory(el?.id);
                                    }}
                                >
                                    <span>{el?.title}</span>
                                    <CategoryArrow />
                                </div>
                                {el?.subcategory_list?.map((subcategory, id) => {
                                    return (
                                        <div key={`${idx}_${id}`} className={styles.subcategory}>
                                            {/*<div className={styles.subcategory_title}>{subcategory?.title}</div>*/}
                                            <p
                                                onClick={(e) => clickSubcategoryText(e)}
                                                dangerouslySetInnerHTML={{
                                                    __html: fixMessageContent(subcategory?.text),
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return false;
                    }
                })}
        </div>
    );
}
