import React from 'react';

export default function IconFavouriteNotify() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#F2C94C"
            stroke="#7878B4"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.638 2.33442C10.537 2.12964 10.3284 2 10.1 2C9.87165 2 9.66308 2.12964 9.56199 2.33442L7.38396 6.74687L2.51324 7.4588C2.2873 7.49182 2.0997 7.65022 2.02927 7.86743C1.95884 8.08463 2.01781 8.32298 2.18138 8.4823L5.7053 11.9146L4.87365 16.7635C4.83504 16.9887 4.92759 17.2162 5.11238 17.3504C5.29716 17.4846 5.54214 17.5023 5.74429 17.396L10.1 15.1054L14.4557 17.396C14.6579 17.5023 14.9029 17.4846 15.0877 17.3504C15.2724 17.2162 15.365 16.9887 15.3264 16.7635L14.4947 11.9146L18.0187 8.4823C18.1822 8.32298 18.2412 8.08463 18.1708 7.86743C18.1003 7.65022 17.9127 7.49182 17.6868 7.4588L12.8161 6.74687L10.638 2.33442Z"
                strokeWidth="1.5"
            />
        </svg>
    );
}
