import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface IInitialList {
    id: number;
    status: number;
    value: string;
}

interface IModifiedList {
    id: number;
    title: string;
}

interface IOnboardingDoubleRadio {
    activeStep: number;
    maxStep: number;
    title: string;
    subtitle: string;
    onClick: () => void;
    titleList1: string;
    titleList2: string;
    initList1: IInitialList[];
    initList2: IInitialList[];
    value1: number;
    value2: number;
    onChange1: (value: number) => void;
    onChange2: (value: number) => void;
    skipStep: () => void;
}

const OnboardingDoubleRadio = ({
    activeStep,
    maxStep,
    title,
    subtitle,
    onClick,
    titleList1,
    titleList2,
    initList1,
    initList2,
    value1,
    value2,
    onChange1,
    onChange2,
    skipStep,
}: IOnboardingDoubleRadio) => {
    const [list1, setList1] = useState<IModifiedList[]>([]);
    const [list2, setList2] = useState<IModifiedList[]>([]);
    const [selectedOptions1, setSelectedOptions1] = useState(value1 || null);
    const [selectedOptions2, setSelectedOptions2] = useState(value2 || null);
    const [disabledSubmit, setDisabledSubmit] = useState(true);

    const onSelectOption1 = (id: number) => {
        setSelectedOptions1(+id);
        onChange1(+id);
    };

    const onSelectOption2 = (id: number) => {
        setSelectedOptions2(+id);
        onChange2(+id);
    };

    useEffect(() => {
        if (typeof selectedOptions1 === 'number' || typeof selectedOptions2 === 'number')
            setDisabledSubmit(false);
    }, [selectedOptions1, selectedOptions2]);

    useEffect(() => {
        if (initList1?.length) {
            setList1(initList1?.map((item) => ({ title: item?.value, id: item?.id })));
        }
    }, [initList1]);
    useEffect(() => {
        if (initList2?.length) {
            setList2(initList2?.map((item) => ({ title: item?.value, id: item?.id })));
        }
    }, [initList2]);

    useEffect(() => {
        if (value1) setSelectedOptions1(value1);
    }, [value1]);
    useEffect(() => {
        if (value2) setSelectedOptions2(value2);
    }, [value2]);

    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>

            <p className={styles.title_list}>{titleList1}</p>
            <div className={styles.list}>
                {list1?.map((item, key) => (
                    <div
                        key={key}
                        className={`${styles.option} ${selectedOptions1 === item?.id ? styles.option_active : ''}`}
                        onClick={() => onSelectOption1(item?.id)}
                    >
                        {item?.title}
                    </div>
                ))}
            </div>

            <p className={styles.title_list}>{titleList2}</p>
            <div className={styles.list}>
                {list2?.map((item, key) => (
                    <div
                        key={key}
                        className={`${styles.option} ${selectedOptions2 === item?.id ? styles.option_active : ''}`}
                        onClick={() => onSelectOption2(item?.id)}
                    >
                        {item?.title}
                    </div>
                ))}
            </div>

            <div className={styles.button_row}>
                <button className={styles.button} onClick={onClick} disabled={disabledSubmit}>
                    Continue
                </button>
                <button className={`${styles.button} ${styles.skip}`} onClick={skipStep}>
                    Skip
                </button>
            </div>
        </div>
    );
};

export default OnboardingDoubleRadio;
