import React from 'react';
import styles from './styles.module.scss';
import { checkNationalitySite } from '../../../services/methods';
import { ICrossBannerEuropeProps } from './index.interface';

export default function CrossBannerEurope({
    openConfirmTermModal,
    targetSiteID,
    allBannerClick = false,
}: ICrossBannerEuropeProps) {
    const isSmallerBanner =
        window.location.pathname === '/like' || window.location.pathname === '/letter';
    const nationality = checkNationalitySite(targetSiteID);

    return (
        <>
            <div
                className={`${styles.remarket_banner} ${isSmallerBanner ? `${styles.smaller} remarket_banner_likeher` : ''}`}
                onClick={() => (allBannerClick ? openConfirmTermModal() : null)}
            >
                <div className={`${styles.remarket_banner_image} ${styles[nationality] || ''}`} />
                <button
                    type="button"
                    className={`${styles.remarket_banner_btn} ${styles[nationality] || ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        openConfirmTermModal();
                    }}
                >
                    Try
                    <span
                        className={`${styles.remarket_banner_btn_text} ${styles[nationality] || ''}`}
                    >
                        {nationality === 'asia' ? `asian` : nationality}
                    </span>
                    chatting
                </button>
            </div>
        </>
    );
}
