import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GiftsCartItem from '../GiftsCartItem';
import GiftsCartOrder from '../GiftsCartOrder';
import GiftsCartHeader from '../GiftsCartHeader';
import * as giftsAction from '../../../store/actions/gifts';
import UserApi from '../../../services/api/UserApi';
import * as alertAction from '../../../store/actions/alert';
import * as ERROR from '../../../constants/error';
import * as GIFTS from '../../../constants/gifts';
import * as LENGTH from '../../../constants/length';
import Modal from 'react-modal';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import GallerySlider from '../../common/Modals/GallerySlider';
import NotifyEmailConfirm from '../../Banner';
import * as userAction from '../../../store/actions/user';
import * as ALERT from '../../../constants/alert';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../index';
import { IContentGallery } from '../GiftsCatalogItemModal';
import { IGiftItem } from '../../../store/reducers/gifts/index.interface';

export default function GiftsCart() {
    const currentGiftsState = useSelector((state: RootState) => state.gifts);
    const userState = useSelector((state: RootState) => state.user);
    const { cart } = currentGiftsState;

    const [totalPrice, setTotalPrice] = useState(0);
    const [giftMessage, setGiftMessage] = useState('');
    const [giftPreferences, setGiftPreferences] = useState('');
    const [cartErrorMessage, setCartErrorMessage] = useState('');
    const [galleryContent, setGalleryContent] = useState<IContentGallery[]>([]);
    const [modalOpenGallery, setmodalOpenGallery] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isDisabledBuyBtn, setIsDisabledBuyBtn] = useState(false);

    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();

    const openGalleryModal = () => setmodalOpenGallery(true);
    const closeGalleryModal = () => setmodalOpenGallery(false);

    const handleGiftMessage = (value: string) => {
        if (value?.length <= LENGTH.GIFT_MESSAGE_MAX_LENGTH) setGiftMessage(value);
        else dispatch(alertAction.setMessage({ message: 'Maximum 300 symbols' }));
    };

    const handleGiftPreferences = (value: string) => {
        if (value?.length <= LENGTH.GIFT_MESSAGE_MAX_LENGTH) setGiftPreferences(value);
        else dispatch(alertAction.setMessage({ message: 'Maximum 300 symbols' }));
    };

    const onSelectImage = (index: number, gallery: IContentGallery[]) => {
        setGalleryContent(gallery);
        setSelectedIndex(index);
        openGalleryModal();
    };

    const deleteFromCart = (item: IGiftItem) => dispatch(giftsAction.deleteFromCart(item));

    const submitBuyGifts = () => {
        if (isDisabledBuyBtn) return;
        setIsDisabledBuyBtn(true);

        if (!cart.recipient) {
            setCartErrorMessage(GIFTS.CART_ERROR_RECIPIENT_MESSAGE);
            return;
        }

        if (!totalPrice) {
            setCartErrorMessage(GIFTS.CART_ERROR_ITEMS_MESSAGE);
            return;
        }

        new UserApi()
            .getInfo()
            .then((res) => {
                if (res?.status && res?.userInfo) {
                    const balance =
                        +res?.userInfo?.credits +
                        +res?.userInfo?.bonus_credits +
                        +res?.userInfo?.promo_credits;

                    if (+balance >= +totalPrice) {
                        if (!handleActiveBlocks.isGiftPaymentUser) {
                            dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
                            return;
                        }

                        return buyGiftsProcess();
                    } else {
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.ENOUGH_CREDITS_GIFTS,
                                message: `${totalPrice - balance}`,
                            }),
                        );
                    }
                }
            })
            .finally(() => setIsDisabledBuyBtn(false));
    };

    const buyGiftsProcess = async () => {
        for (const key of Object.keys(cart?.items)) {
            const item = cart?.items[+key];
            const res = await giftsAction.sendGift({
                female_id: cart?.recipient || 0,
                product_id: item?.id,
                comment: giftMessage,
                message: giftPreferences,
            });

            if (res?.data?.status) {
                dispatch(giftsAction.openSuccesGift());
                dispatch(userAction.setBalance());
            } else {
                dispatch(
                    alertAction.setMessage({
                        message: res?.data?.message || ERROR.ERROR_REQUEST,
                        title: res?.data?.title,
                    }),
                );
            }
        }

        dispatch(giftsAction.emptyCart());
    };

    const calcTotalPrice = () => {
        let calcPrice = 0;

        Object.keys(cart?.items).forEach((key) => {
            if (userState?.giftsDiscount) {
                calcPrice += +cart?.items[+key]?.new_price;
            } else {
                calcPrice += +cart?.items[+key]?.price;
            }
        });

        setTotalPrice(calcPrice);
    };

    useEffect(() => {
        calcTotalPrice();
    }, [cart]);

    return (
        <>
            <div className="column-3 gifts-page-column">
                <NotifyEmailConfirm />
                <div className="gifts_page_wrap">
                    <GiftsCartHeader />
                    <div className="c3gp_gifts_cart_main_wrap">
                        <div className="c3gp_gifts_cart_main">
                            <div className="c3gp_gifts_cart_main_list">
                                {Object.keys(cart.items).map((key) => (
                                    <GiftsCartItem
                                        key={key}
                                        item={cart.items[+key]}
                                        deleteFromCart={deleteFromCart}
                                        onSelectImage={onSelectImage}
                                    />
                                ))}
                            </div>
                            <GiftsCartOrder
                                cart={cart}
                                totalPrice={totalPrice}
                                giftMessage={giftMessage}
                                handleGiftMessage={handleGiftMessage}
                                giftPreferences={giftPreferences}
                                handleGiftPreferences={handleGiftPreferences}
                                submitBuyGifts={submitBuyGifts}
                                cartErrorMessage={cartErrorMessage}
                                isDisabledBuyBtn={isDisabledBuyBtn}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                style={modalStyleProps({ isAttachment: true })}
                isOpen={modalOpenGallery}
                onRequestClose={closeGalleryModal}
                ariaHideApp={false}
            >
                <GallerySlider
                    gallery={galleryContent}
                    selectedIndex={selectedIndex}
                    closeGalleryModal={closeGalleryModal}
                    gifts={true}
                />
            </Modal>
        </>
    );
}
