import React, { useState } from 'react';
import styles from './styles.module.scss';
import bannersIMG from '../../../../../img/banners';
import * as MENU from '../../../../../constants/menu';
import { IConfirmTermsProps } from './index.interface';

export default function ConfirmTerms({
    crossBannersData,
    openTermModal,
    closeConfirmTermModal,
}: IConfirmTermsProps) {
    const [isCheckedTerms, setIsCheckedTerms] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');

    const openNewSite = () => {
        if (!isCheckedTerms) {
            return setErrorText('Please accept our Terms and Conditions');
        }

        if (crossBannersData?.url?.length) window.open(crossBannersData?.url, '_blank');

        closeConfirmTermModal('clearData');
    };

    const handleCheckedTerms = () => {
        setIsCheckedTerms(!isCheckedTerms);
        setErrorText('');
    };

    return (
        <div className={styles.wrapper}>
            {!!crossBannersData?.target_site_id && (
                <img
                    className={styles.logo}
                    src={bannersIMG.images[`logo_id_${crossBannersData?.target_site_id}.png`]}
                    width="135"
                    height="64"
                    alt="logo"
                />
            )}

            <div className={styles.title}>Please confirm our rules</div>

            <div className={`${styles.policy_row}`}>
                <input type="checkbox" id="form_policy_check" checked={isCheckedTerms} />
                <label htmlFor="form_policy_check" onClick={handleCheckedTerms} />
                <div className={styles.policy_text}>
                    <span>By clicking “Find Your Matches” you agree with the </span>
                    <div onClick={() => openTermModal(MENU.LINK_TERMS)}> Terms and Conditions,</div>
                    <div onClick={() => openTermModal(MENU.LINK_PRIVACY)}> Privacy Policy</div>
                    and
                    <div onClick={() => openTermModal(MENU.LINK_COOKIE)}> Cookies Policy,</div>
                    <div onClick={() => openTermModal(MENU.LINK_RETURN_REFUND)}>
                        {' '}
                        Refund Policy,
                    </div>
                    <div onClick={() => openTermModal(MENU.LINK_STANDARDS)}>
                        {' '}
                        Community Standards.
                    </div>
                    <span>
                        {' '}
                        You also agree to receive any emails relating to{' '}
                        {crossBannersData?.domain || ''}
                    </span>
                </div>
            </div>

            {!!+errorText?.length && <div className={styles.error_text}>{errorText}</div>}

            <button type="button" className={styles.button} onClick={openNewSite}>
                Continue
            </button>

            <div className={styles.cross} onClick={() => closeConfirmTermModal()} />
        </div>
    );
}
