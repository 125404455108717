import { useEffect, useState } from 'react';
import { getUrlParameter } from '../../utils/url-utils';

export default function useAuthRedirect(defaultRedirect = `search`) {
    const REDIRECT_URLS = ['chat', 'search', 'letter', 'like', 'videoshow'];

    const [redirect, setRedirect] = useState(defaultRedirect);

    useEffect(() => {
        const dynamicRedirect = getUrlParameter('redirect_url');

        if (dynamicRedirect) {
            if (REDIRECT_URLS.includes(dynamicRedirect as string)) {
                setRedirect(dynamicRedirect as string);
            } else if (+dynamicRedirect) {
                setRedirect(`profile-woman/${dynamicRedirect}`);
            }
        }
    }, [window.location.search]);

    return redirect;
}
