const importAll = (require: __WebpackModuleApi.RequireContext) =>
    require.keys().reduce<Record<string, string>>((acc, next) => {
        acc[next.replace('./', '')] = require(next);
        return acc;
    }, {});

const images = importAll(require.context('./', false, /\.(png|jpe?g|svg|webp)$/));

export default {
    images,
};
