import { TransactionActionType } from '../../constants/transaction';
import { ITransactionState, TransactionActions } from './index.interface';

const initState = {
    transaction: null,
};

export default function (
    state: ITransactionState = initState,
    action: TransactionActions,
): ITransactionState {
    switch (action.type) {
        case TransactionActionType.SET_TRANSACTION_SYNC_POSITION:
            return { ...state, ...{ transaction: action?.transaction } };

        default:
            return state;
    }
}
