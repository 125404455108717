import Base from './Base';

export default class AwsApi extends Base {
    async generateLink(data: FormData) {
        const res = await super.post('v3/click-history/aws/generate-link', data);
        return res?.data;
    }

    async generateVideoLink(data: FormData) {
        try {
            const res = await super.post('v3/video_converter/new-user-video-convert', data);
            return res?.data;
        } catch {
            return null;
        }
    }

    async generateAudioLink(data: FormData) {
        const res = await super.post('v3/video_converter/user-audio-convert', data);
        return res?.data;
    }
}
