import Base from '../Base';

export default class VideoShowApi extends Base {
    async checkPaidVideoShow() {
        try {
            const res = await super.get(`v3/search/check-video`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
