import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Item from './Item';
import EmptyResult from '../../../common/EmptyResult';
import * as MENU from '../../../../constants/menu';
import useSortChatListByCurrentChatUid from '../../../../hooks/chat/helpers/useSortChatListByCurrentChatUid';
import useScrollLoadChatList from '../../../../hooks/chat/helpers/useScrollLoadChatList';
import useTouchChatList from '../../../../hooks/chat/useTouchChatList';
import { RootState } from '../../../../index';
import { IStoreChatListItemModel } from '../../../../models/chat/index.interface';
import { isHolidayActive } from '../../../../constants/holiday';

export default function List() {
    const menuState = useSelector((state: RootState) => state.menu);
    const chatsState = useSelector((state: RootState) => state.chats);

    const chatListRef = useRef(null);

    const { handleTouchMove, handleTouchStart } = useTouchChatList();
    const sortArrByChatUid = useSortChatListByCurrentChatUid(); // Hook to sort the chat list array.
    const scrollLoadChatList = useScrollLoadChatList();
    const activeHoliday = isHolidayActive();

    const filterBlockList = (blockList: IStoreChatListItemModel[]) => {
        const sanitizedSearchItem = /\d/.test(chatsState?.searchInput)
            ? chatsState?.searchInput?.replace(/\s/g, '')
            : chatsState?.searchInput;

        const isSearchById = /\d/.test(chatsState?.searchInput);

        return blockList.filter((item) => {
            if (!isSearchById) {
                return item?.name?.toLowerCase()?.includes(sanitizedSearchItem?.toLowerCase());
            } else {
                return item.public_external_id
                    ?.toString()
                    ?.includes(sanitizedSearchItem?.toLowerCase());
            }
        });
    };

    const chatList =
        menuState.main === MENU.MENU_BLOCK
            ? filterBlockList(chatsState.blockList)
            : chatsState.chatList; // Defining the current chat array.
    const emptyChatList =
        menuState.main === MENU.MENU_BLOCK ? chatsState.emptyBlockList : chatsState.empty;

    return (
        <div
            className="c2_list_wrap_2"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            data-testid="chat-list"
        >
            <div
                className={`c2_list_wrap ${activeHoliday && activeHoliday?.name}`}
                onScroll={scrollLoadChatList}
            >
                <div className="c2_list" ref={chatListRef}>
                    {!emptyChatList ? (
                        !!chatList?.length &&
                        sortArrByChatUid(chatList).map((item, key) => (
                            <Item
                                key={`${key}_${item?.id}`}
                                item={item}
                                chatListRef={chatListRef}
                            />
                        ))
                    ) : (
                        <EmptyResult
                            imageBlockStyles={{ width: '140px' }}
                            titleBlockStyles={{ fontSize: '20px' }}
                            descriptionBlockStyles={{ fontSize: '16px' }}
                            mainBlockStyles={{ marginTop: '20px' }}
                            type={menuState?.main === MENU.MENU_BLOCK ? 'block' : 'chat'}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
