import { useDispatch, useSelector } from 'react-redux';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useTransition from '../transition/useTransition';
import useHandleRedUser from '../user/useHandleRedUser';
import useTransactionConfig from '../transaction/useTransactionConfig';
import useHandleBilling from '../billing/useHandleBilling';
import useSuccessPaymentCallback from './useSuccessPaymentCallback';
import useInitSubscribe from './subscribe/useInitSubscribe';
import useGoogleDataLayer from '../event/useGoogleDataLayer';
import useHandleZendesk from '../ze/useHandleZendesk';
import SITE from '../../config/site';
import PaymentApi from '../../services/api/v3/PaymentApi';
import * as userAction from '../../store/actions/user';
import { getLegalEntityInfo, getRoutingRefundPolicy } from '../../services/methods/purchase';
import { PAYMENT_METHOD_ROUTING } from '../../constants/purchase';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import { BillingActionEnum } from '../../services/api/v3/BillingApi.interface';
import MarketingApi from '../../services/api/MarketingApi';

export default function useHandleRouting() {
    const userState = useSelector((state: RootState) => state.user);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const dispatch = useDispatch();
    const transition = useTransition();
    const handleRedUser = useHandleRedUser();
    const transactionConfig = useTransactionConfig();
    const handleBilling = useHandleBilling();
    const successPaymentCallback = useSuccessPaymentCallback();
    const initSubscribe = useInitSubscribe();

    const { pushEventTryPay, pushEventBeginCheckout } = useGoogleDataLayer();
    const { openZendesk } = useHandleZendesk();

    const paymentLink =
        window?.location?.host?.includes('stage') || window?.location?.host?.includes('localhost')
            ? `https://dev-payment.${SITE.key}.com`
            : `https://payment.${SITE.key}.com`;

    const createScript = (loadCallback: () => void) => {
        try {
            const block = document.createElement('div');
            block.id = 'sphera-form';
            document.body.appendChild(block);

            const script = document.createElement('script');

            script.id = 'sphera-script';
            script.src = `${paymentLink}/lib.min.js`;

            document.body.appendChild(script);
            script.addEventListener('load', loadCallback);
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const onPaymentDone = async () => {
        if (+userState?.abtest && !userState?.info?.ghost)
            transactionConfig.fetchTransactionConfig();

        const lastPurchase = await new PaymentApi()
            .getLastPurchase()
            .then((res) => (res?.status ? res?.data : null));
        if (lastPurchase) {
            successPaymentCallback({
                count: lastPurchase?.packages,
                credits: lastPurchase?.lastPurchase?.amount,
                price: lastPurchase?.lastPurchase?.price,
                isRouting: true,
            });
            handleRedUser.submitLog(
                RED_USER_LOG_TYPES.PAYMENT_SUCCESS,
                JSON.stringify({ payment_method: 'sphera', ...lastPurchase }),
            );
        }

        dispatch(userAction.getInfo(true));
        initSubscribe.initSubscribeStatus();
    };

    const onPaymentError = async () => {
        const lastPurchase = await new PaymentApi()
            .getLastPurchase()
            .then((res) => (res?.status ? res?.data : null));
        if (lastPurchase) {
            if (lastPurchase?.packages > 0) transition.addTail('?utm_credits_issue');

            handleRedUser.submitLog(
                RED_USER_LOG_TYPES.PAYMENT_DECLINE,
                JSON.stringify({ payment_method: 'sphera', ...lastPurchase }),
            );

            if (lastPurchase?.packages === 0) {
                pushEventTryPay();
            }
        }

        dispatch(userAction.getInfo());
    };

    const onClose = () => {
        handleBilling.createBillingLog(BillingActionEnum.CLOSE_PAYMENT_ROUTING_PAGE);
    };

    const isRouting = () => {
        return purchaseState?.paymentMethodList?.some(
            (item: any) => item?.method === PAYMENT_METHOD_ROUTING,
        );
    };

    const init = () => {
        createScript(() => {
            try {
                if (!window?.sphera) return;
                window?.sphera?.init(
                    paymentLink,
                    JSON.parse(localStorage.getItem('token') as string),
                    {
                        policyLink: getRoutingRefundPolicy(),
                        corpDesc: getLegalEntityInfo(),
                        merchantDomain: window?.location?.host,
                        onClose,
                        onPaymentDone,
                        onPaymentError,
                        onSupportCall: openZendesk,
                    },
                );
            } catch (error) {
                sendErrToSentry(error as Error);
                handleBilling.createBillingLog(BillingActionEnum.INIT_PAYMENT_ROUTING_ERROR);
            }
        });
    };

    const launch = () => {
        try {
            if (!window?.sphera) return;
            window?.sphera?.launch();

            if (!localStorage.getItem('begin_checkout')) {
                localStorage.setItem('begin_checkout', '1');

                new MarketingApi().postCheckExistEvent('begin_checkout').then((res) => {
                    if (res?.status && res?.response?.check) {
                        pushEventBeginCheckout();
                    }
                });
            }

            handleBilling.createBillingLog(BillingActionEnum.OPEN_PAYMENT_ROUTING_PAGE);
        } catch (error) {
            sendErrToSentry(error as Error);
            handleBilling.createBillingLog(BillingActionEnum.LAUNCH_PAYMENT_ROUTING_ERROR);
        }
    };

    return {
        init,
        launch,
        isRouting,
    };
}
