import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import VIPBanner from '../common/VIP/VIPBanner';
import useHandleVIP from '../../hooks/vip/useHandleVIP';
import moment from 'moment';
import EmailConfirmV2 from './EmailConfirmV2';
import SITE from '../../config/site';
import * as MENU from '../../constants/menu';
// import { isHolidayActive } from '../../constants/holiday';

import CrossBannerNew from './CrossBannerV2';
// import CrossBannerAsia from "./CrossBannerAsia";
// import CrossBannerEurope from "./CrossBannerEurope";
// import CrossBannerLatina from "./CrossBannerLatina";
// import CrossBannerAsiaPhotos from "./CrossBannerAsiaPhotos";
// import CrossBannerEuropePhotos from "./CrossBannerEuropePhotos";
// import CrossBannerLatinaPhotos from "./CrossBannerLatinaPhotos";
import useInitCrossBanner from '../../hooks/crossBanner/useInitCrossBanner';
import useSetBannerViewTimer from '../../hooks/crossBanner/useSetBannerViewTimer';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../index';
// import HolidayBanner from './HolidayBanner';

export default function Banner() {
    const { isShowVIPBanner } = useHandleVIP();
    const { setActiveCrossBanners, clearCrossBannersData, openConfirmTermModal } =
        useInitCrossBanner();
    useSetBannerViewTimer({ clearCrossBannersData });
    const handleActiveBlocks = useHandleActiveBlocks();
    // const activeHoliday = isHolidayActive();

    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const bannersState = useSelector((state: RootState) => state.banners);

    const { crossBannersData, isActiveCrossBanners, crossBannersStartTime } = bannersState;

    const [bannerActive, setBannerActive] = useState<ReactElement | null>(null);

    // const checkRecurringUserModal = async () => {
    //     if (purchaseState?.activeSubscribeData && purchaseState.activeSubscribePaymentMethod) return false;
    //     const result = await new FrontSyncApi()
    //         .getActionSyncStatistic({
    //             externalID: userState?.info?.external_id,
    //             action: 'RECURRING_BANNER'
    //         })
    //         .then(res => res?.status && res?.result?.length <= 25);
    //
    //     if (!result) return false;
    //     return new PaymentApi().checkRecurringUser().then(res => !!res?.data);
    // }

    const activeComponentCrossBanner = () => {
        const pathname = window?.location?.pathname?.split('/')?.[1];
        // const pagesPhotosBanner = [MENU.MENU_LETTER];
        const pagesRemarketingBanner = [
            MENU.MENU_LETTER,
            MENU.MENU_LIKE_HER,
            MENU.MENU_SEARCH,
            MENU.MENU_VIDEO_SHOW,
            MENU.MENU_ONLINE_NOW,
            MENU.FAQ_PAGE,
            MENU.TUTORIAL_PAGE,
            MENU.MENU_MY_PROFILE,
        ];
        const startTime = crossBannersStartTime?.length
            ? crossBannersStartTime
            : moment().utc().format('YYYY-MM-DD HH:mm:ss');

        // if (pagesPhotosBanner.includes(pathname)) {
        //     setActiveCrossBanners(true, startTime, crossBannersData?.log_id);
        //
        //     if (+crossBannersData?.target_site_id === 11) {
        //         return <CrossBannerLatinaPhotos openConfirmTermModal={openConfirmTermModal}/>;
        //     } else if (+crossBannersData?.target_site_id === 12) {
        //         return <CrossBannerAsiaPhotos openConfirmTermModal={openConfirmTermModal}/>;
        //     } else {
        //         return <CrossBannerEuropePhotos openConfirmTermModal={openConfirmTermModal}/>;
        //     }
        // } else
        if (pagesRemarketingBanner.includes(pathname) && crossBannersData) {
            setActiveCrossBanners(true, startTime, crossBannersData?.log_id);

            return (
                <CrossBannerNew
                    key={`${crossBannersData?.log_id}_${crossBannersData?.target_site_id}`}
                    openConfirmTermModal={openConfirmTermModal}
                    targetSiteID={+crossBannersData?.target_site_id}
                    allBannerClick={!!crossBannersData?.all_banner_click}
                />
            );

            // if (+crossBannersData?.target_site_id === 11) {
            //     return <CrossBannerLatina target_site_id={crossBannersData?.target_site_id}
            //                               openConfirmTermModal={openConfirmTermModal}/>;
            // } else if (+crossBannersData?.target_site_id === 12) {
            //     return <CrossBannerAsia target_site_id={crossBannersData?.target_site_id}
            //                             openConfirmTermModal={openConfirmTermModal}/>;
            // } else {
            //     return <CrossBannerEurope target_site_id={crossBannersData?.target_site_id}
            //                               openConfirmTermModal={openConfirmTermModal}/>
            // }
        }

        return null;
    };

    useMemo(() => {
        if (SITE.ID === 4) return false;

        let newBanner: ReactElement[] = [];
        let timeoutID: NodeJS.Timeout | null = null;

        // if (await checkRecurringUserModal()) newBanner = [...newBanner, ...[<RecurringBanner/>]];
        // newBanner = [...newBanner];

        if (
            !handleActiveBlocks.isRedUser &&
            crossBannersData?.target_site_id &&
            !currentChatState?.info?.id &&
            !currentChatMailboxState?.info?.id
        ) {
            const delayView = crossBannersData?.delay * 1000; //convert sec to ms

            if (isActiveCrossBanners) {
                if (activeComponentCrossBanner()) {
                    return setBannerActive(activeComponentCrossBanner());
                }
            } else {
                timeoutID = setTimeout(() => {
                    if (activeComponentCrossBanner()) {
                        return setBannerActive(activeComponentCrossBanner());
                    }
                }, delayView);
            }
        }
        // const utcDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        if (isShowVIPBanner && SITE.ID !== 23) {
            return setBannerActive(<VIPBanner key={1} />);
        }

        // if (activeHoliday && !isActiveCrossBanners && userState?.info?.email_confirmed) {
        //     return setBannerActive(<HolidayBanner key={2} />);
        // }

        if (!userState?.info?.email_confirmed) {
            newBanner = [...newBanner, ...[<EmailConfirmV2 key={3} />]];
        }

        setBannerActive(newBanner[Math.floor(Math.random() * Math.floor(newBanner?.length))]);

        return () => {
            if (timeoutID) clearTimeout(timeoutID);
        };
    }, [userState.info, userState.userRoles, crossBannersData]);

    return bannerActive || <></>;
}
