import React from 'react';

import LettersMenu from '../../components/letters/LettersMenu';
import Letters from '../../components/letters/Letters';

import '../../styles/letters/main.scss';
import '../../styles/letters/letters.scss';
import '../../styles/letters/media.scss';
import '../../styles/letters/letters-options.scss';
import '../../styles/letters/popup.scss';
import useEffectToOpenLettersChat from '../../hooks/letters/useEffectToOpenLettersChat';
import { Params } from '@sentry/react/types/types';
import { match } from 'react-router';

export default function LettersPage({ match }: { match: match<Params> }) {
    useEffectToOpenLettersChat(match);

    return (
        <>
            <LettersMenu />
            <Letters />
        </>
    );
}
