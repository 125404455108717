import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatMailboxAction from '../../../../store/actions/currentChatMailbox';
import useTransition from '../../../../hooks/transition/useTransition';
import * as alertAction from '../../../../store/actions/alert';
import * as ERROR from '../../../../constants/error';
import IconLetters from '../../../Icons/IconLetters';
import { RootState } from '../../../../index';
import { IWomanProfileFull } from '../../../../services/api/ProfileApi.interface';

interface ILetterButtonProps {
    type: string;
    profile: IWomanProfileFull;
    closeWomanProfileModal?: () => void;
    isModalOpen?: boolean;
}

export default function LetterButton({
    type,
    profile,
    closeWomanProfileModal,
    isModalOpen,
}: ILetterButtonProps) {
    const image = profile?.photo_link || profile?.avatar;
    const cityKey = profile?.city_name;
    const dispatch = useDispatch();
    const transition = useTransition();
    const userState = useSelector((state: RootState) => state.user);

    const openLetter = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (+profile?.is_blocked)
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));

        if (isModalOpen && closeWomanProfileModal) closeWomanProfileModal();

        if (+profile?.is_blocked === 0 && userState.info?.external_id) {
            dispatch(
                currentChatMailboxAction.addMailboxChatInfo({
                    external_id: profile?.external_id,
                    city_name: profile?.city_name,
                    age: profile?.age,
                    name: profile?.name,
                    photo_link: profile?.photo_link,
                    avatar: profile?.avatar,
                    gifts: profile?.gifts,
                    search_img: profile?.search_img,
                    is_blocked: profile?.is_blocked,
                    online: +profile?.online,
                    country_code: profile?.country_code,
                    type,
                    sender_search_img: profile?.search_img,
                    sender_gifts: profile?.gifts,
                    sender_image: image,
                    sender_name: profile?.name,
                    sender_age: profile?.age,
                    sender_city: cityKey,
                    sender_id: profile?.external_id,
                    female_id: profile?.external_id,
                    public_external_id: profile?.public_external_id,
                    male_id: userState.info?.external_id,
                    openFromChat: true,
                }),
            );
            dispatch(
                currentChatMailboxAction.getMailboxChatHistory({
                    userId: userState.info?.external_id,
                    girlId: profile?.public_external_id?.toString(),
                    page: 1,
                }),
            );
            transition.transitionWithHistoryPush('letter', `/letter/`);
        } else {
            dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }
    };

    return window.innerWidth > 767 ? (
        <button
            type="button"
            className="c3_woman_profile_head_item c3wphi_letter"
            onClick={openLetter}
            data-testid="woman-letter-btn"
        >
            <IconLetters />
            <span className="c3_woman_profile_head_item_text">Write a letter</span>
        </button>
    ) : (
        <button
            type="button"
            className="c3_woman_profile_mob_btn write_letter"
            onClick={openLetter}
        >
            <IconLetters />
            <span>Write a letter</span>
        </button>
    );
}
