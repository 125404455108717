import React from 'react';
import popup from '../../../../Profile/ProfilePurchaseHistory/popup.module.scss';
import { ISuccessSubscribeProps } from './index.interface';
import IconCross from '../../../../Icons/IconCross';

export default function AutoTopUpSuccess({
    closeModal,
    activeSubscribeData,
}: ISuccessSubscribeProps) {
    return (
        <div className={popup.top_up_modal_wrap}>
            <div className={popup.modal_title}>Thank you for subscribing!</div>
            <div className={popup.modal_text}>
                <p>
                    Now, when your balance is 5 credits or less, your account will be automatically
                    topped up with {activeSubscribeData?.credits ?? 0} credits.
                </p>
                <p>
                    Feel free to contact our support team with any questions. <br />
                    Enjoy!
                </p>
            </div>
            <div className={popup.modal_buttons}>
                <div className={`${popup.modal_btn} ${popup.color_btn}`} onClick={closeModal}>
                    Close
                </div>
            </div>
            <div className={popup.close_modal} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
