import { ChatsActionTypes } from '../../constants/chats';
import * as CHATS_CONST from '../../../constants/chats';
import { uniqueArrayByChatUid } from '../../../services/methods';
import { ChatActions, IChatState } from './index.interface';
import { IStoreChatListItemModel } from '../../../models/chat/index.interface';
import moment from 'moment';

const initState = {
    chatList: [],
    page: 1,
    isLoadedAllChats: false,
    loadChatList: false,
    newMessageList: [],
    onlineStatus: false,
    searchInput: '',
    status: false,
    empty: false,

    blockList: [],
    statusBlockList: false,
    emptyBlockList: false,
    loadBlockList: false,
    pageBlockList: 1,
    isLoadedAllBlockList: false,
};

export default function (state: IChatState = initState, action: ChatActions): IChatState {
    switch (action.type) {
        case ChatsActionTypes.INIT_CHAT_FILTERS:
            return {
                ...state,
                ...{
                    onlineStatus: false,
                    searchInput: '',
                },
            };

        case ChatsActionTypes.FETCH_CHAT_LIST:
            return {
                ...state,
                ...{
                    chatList: [],
                    loadChatList: true,
                    isLoadedAllChats: false,
                    empty: false,
                    page: 1,
                },
            };

        case ChatsActionTypes.ADD_NEW_CHAT:
            if (action?.chat?.chat_uid && state?.chatList) {
                const updChatList =
                    uniqueArrayByChatUid([...[action?.chat], ...(state?.chatList || [])]) ?? [];

                return {
                    ...state,
                    ...{
                        chatList: updChatList,
                        empty: !updChatList?.length,
                        loadChatList: false,
                        isLoadedAllChats: updChatList?.length < CHATS_CONST.CHAT_ON_PAGE_CHAT_LIST,
                        status: !!updChatList?.length,
                    },
                };
            }
            return state;

        case ChatsActionTypes.UPDATE_NEW_MESS: {
            if (state?.newMessageList) {
                const newChatInfo = {
                    chat_uid: action?.data?.chatList?.chats?.find(
                        (item: any) => item.chat_uid === action.data.chatId,
                    )?.chat_uid,
                    last_message_date: action?.data?.chatList?.chats?.find(
                        (item: any) => item.chat_uid === action.data.chatId,
                    )?.last_message_date,
                    unread_count: action?.data?.chatList?.chats?.find(
                        (item: any) => item.chat_uid === action.data.chatId,
                    )?.unread_count,
                };

                state.newMessageList.forEach((item) => {
                    if (item.chat_uid === newChatInfo?.chat_uid) {
                        item.last_message_date = newChatInfo?.last_message_date;
                        item.unread_count = newChatInfo?.unread_count;
                        item.first = false;
                    }
                });

                return {
                    ...state,
                    ...{
                        newMessageList: [...(state?.newMessageList || [])],
                    },
                };
            }
            return state;
        }

        case ChatsActionTypes.CHATS_GET:
            // eslint-disable-next-line no-case-declarations
            const urlChatUID = window?.location?.pathname?.split('/')[2];
            // eslint-disable-next-line no-case-declarations
            const activeChat =
                state.chatList && state.chatList.find((item) => item?.chat_uid === urlChatUID);
            // eslint-disable-next-line no-case-declarations
            const newChatList =
                activeChat?.chat_uid && action?.data?.chats
                    ? [
                          ...[activeChat],
                          ...(action?.data?.chats?.filter(
                              (item: any) => item?.chat_uid !== activeChat?.chat_uid,
                          ) || []),
                      ]
                    : (action?.data?.chats ?? []);

            if (state?.newMessageList?.length === 0) {
                return {
                    ...state,
                    ...{
                        empty: !action?.data?.chats?.length,
                        loadChatList: false,
                        isLoadedAllChats:
                            action?.data?.chats?.length < CHATS_CONST.CHAT_ON_PAGE_CHAT_LIST,
                        chatList: newChatList,
                        status: !!action?.data?.chats,
                        newMessageList: action?.data?.chats?.map((item: any) => ({
                            chat_uid: item?.chat_uid,
                            last_message_date: item?.last_message_date,
                            unread_count: item?.unread_count,
                            first: true,
                        })),
                        page: action?.page || 1,
                    },
                };
            } else {
                return {
                    ...state,
                    ...{
                        empty: !action.data?.chats?.length,
                        loadChatList: false,
                        chatList: newChatList,
                        status: !!action?.data?.chats,
                        page: action?.page || 1,
                    },
                };
            }

        case ChatsActionTypes.CHATS_GET_BY_PAGE:
            if (action?.data?.chats && action?.page) {
                return {
                    ...state,
                    ...{
                        isLoadedAllChats:
                            action.data?.chats?.length < CHATS_CONST.CHAT_ON_PAGE_CHAT_LIST,
                        page: action?.page,
                        chatList: Array.isArray(state?.chatList)
                            ? (uniqueArrayByChatUid([
                                  ...(state?.chatList || []),
                                  ...(action?.data?.chats || []),
                              ]) as IStoreChatListItemModel[])
                            : [],
                    },
                };
            }
            return state;

        case ChatsActionTypes.FETCH_BLOCK_LIST:
            return {
                ...state,
                ...{
                    blockList: [],
                    loadBlockList: true,
                    isLoadedAllBlockList: false,
                    emptyBlockList: false,
                    pageBlockList: 1,
                },
            };

        case ChatsActionTypes.BLOCK_CHATS_GET:
            return {
                ...state,
                ...{
                    isLoadedAllChats:
                        action?.data?.chats?.length < CHATS_CONST.CHAT_ON_PAGE_CHAT_LIST,
                    loadBlockList: false,
                    emptyBlockList: action?.page > 1 ? false : action?.data?.chats?.length <= 0,
                    page: action?.page,
                    blockList: Array.isArray(state?.blockList)
                        ? (uniqueArrayByChatUid([
                              ...(state?.blockList || []),
                              ...(action?.data?.chats || []),
                          ]) as IStoreChatListItemModel[])
                        : [],
                },
            };

        case ChatsActionTypes.ONLINE_STATUS:
            return {
                ...state,
                ...{
                    onlineStatus: action.data,
                },
            };

        case ChatsActionTypes.SEARCH_INPUT:
            return {
                ...state,
                ...{
                    searchInput: action.data,
                },
            };

        case ChatsActionTypes.REMOVE_UNREAD_COUNTER:
            if (Array.isArray(state?.chatList)) {
                state.chatList.forEach((item) => {
                    if (item.chat_uid === action.data) {
                        item.unread_count = 0;
                    }
                });

                return {
                    ...state,
                    ...{
                        chatList: [...state.chatList],
                    },
                };
            }
            return state;

        case ChatsActionTypes.SET_READ_LAST_MESS_FROM_YOU:
            if (state?.chatList) {
                return {
                    ...state,
                    ...{
                        chatList: Array.isArray(state?.chatList)
                            ? (state?.chatList?.map((item) => {
                                  if (item?.chat_uid === action?.chatId) {
                                      return { ...item, ...{ last_message_read_status: '1' } };
                                  } else {
                                      return item;
                                  }
                              }) as IStoreChatListItemModel[])
                            : [],
                    },
                };
            }
            return state;

        case ChatsActionTypes.REMOVE_FROM_CHATLIST:
            return {
                ...state,
                ...{
                    chatList: Array.isArray(state?.chatList)
                        ? [
                              ...(state.chatList?.filter(
                                  (item) => item.chat_uid !== action?.chatUid,
                              ) || []),
                          ]
                        : [],
                    empty: Array.isArray(state?.chatList)
                        ? ![
                              ...(state.chatList?.filter(
                                  (item) => item.chat_uid !== action?.chatUid,
                              ) || []),
                          ]?.length
                        : false,
                },
            };

        case ChatsActionTypes.REMOVE_FROM_BLOCKLIST:
            return {
                ...state,
                ...{
                    blockList: Array.isArray(state?.blockList)
                        ? [
                              ...(state.blockList?.filter(
                                  (item) => item.chat_uid !== action?.chatUid,
                              ) || []),
                          ]
                        : [],
                },
            };

        case ChatsActionTypes.UPDATE_MESS_IN_CHAT_LIST:
            return {
                ...state,
                ...{
                    chatList: Array.isArray(state?.chatList)
                        ? (state?.chatList?.map((item) => {
                              if (item?.chat_uid === action?.chatUid) {
                                  return {
                                      ...item,
                                      ...{
                                          last_message: action?.messageContent,
                                          last_message_date: new Date().toUTCString(),
                                          last_message_type: action?.messageType,
                                          sender_external_id: action?.senderExternalId,
                                          unread_count:
                                              +item?.id === +action?.senderExternalId
                                                  ? +item?.unread_count + 1
                                                  : 0,
                                          last_message_read_status: '0',
                                      },
                                  };
                              } else {
                                  return item;
                              }
                          }) as IStoreChatListItemModel[])
                        : [],
                },
            };

        case ChatsActionTypes.SET_FAVORITE_CHAT_LIST:
            if (action?.chatUid?.length > 0) {
                return {
                    ...state,
                    ...{
                        chatList: Array.isArray(state?.chatList)
                            ? state?.chatList.map((chat) => {
                                  if (chat?.chat_uid === action?.chatUid)
                                      return { ...chat, ...{ favorite: action?.favoriteStatus } };
                                  return chat;
                              })
                            : [],
                    },
                };
            }
            return state;

        case ChatsActionTypes.SET_LIKE_CHAT_LIST:
            if (action?.chatUid?.length > 0) {
                return {
                    ...state,
                    ...{
                        chatList: Array.isArray(state?.chatList)
                            ? state?.chatList?.map((chat) => {
                                  if (chat?.chat_uid === action?.chatUid)
                                      return {
                                          ...chat,
                                          ...{
                                              like: !chat?.like,
                                              liked: 1,
                                          },
                                      };
                                  return chat;
                              })
                            : [],
                    },
                };
            }
            return state;

        case ChatsActionTypes.SET_PINNED_CHAT_LIST: {
            const updatedChatList = Array.isArray(state?.chatList)
                ? state?.chatList?.map((chat) => {
                      if (chat?.chat_uid === action?.chatUid)
                          return { ...chat, ...{ pinned: action?.pinned } };
                      return chat;
                  })
                : [];

            const sortedChatList = updatedChatList.sort((a, b) => {
                if (+a?.pinned !== +b?.pinned) {
                    return +b?.pinned - +a?.pinned;
                }

                const dateA = moment(a?.last_message_date);
                const dateB = moment(b?.last_message_date);
                return dateB.diff(dateA);
            });

            return {
                ...state,
                ...{ chatList: sortedChatList as IStoreChatListItemModel[] },
            };
        }

        case ChatsActionTypes.SET_CHAT_LIST_ONLINE_STATUS: {
            const newChatList = state?.chatList?.length
                ? state?.chatList?.map((chat) => {
                      if (action?.externalIDList?.includes(chat?.external_id)) {
                          return { ...chat, ...{ online: action?.status } };
                      }
                      return chat;
                  })
                : [];

            return {
                ...state,
                ...{ chatList: newChatList as IStoreChatListItemModel[] },
            };
        }

        case ChatsActionTypes.SET_CHAT_LIST_LIKE_STATUS: {
            const newChatList = state?.chatList?.length
                ? state?.chatList?.map((chat) => {
                      if (action?.womanExternalID === chat?.external_id) {
                          return { ...chat, ...{ liked: 1 } };
                      }
                      return chat;
                  })
                : [];

            return {
                ...state,
                ...{ chatList: newChatList },
            };
        }

        case ChatsActionTypes.UPDATE_CHAT_LIST_ITEM_INFO: {
            const newChatList = state?.chatList?.length
                ? state?.chatList?.map((chat) => {
                      if (action?.data?.chat_uid === chat?.chat_uid) {
                          return { ...chat, ...action?.data };
                      }
                      return chat;
                  })
                : [];

            return {
                ...state,
                ...{ chatList: newChatList },
            };
        }

        default:
            return state;
    }
}
