import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import * as quizAction from '../../../../store/actions/quiz';
import { RootState } from '../../../../index';
import IconArrowRight from '../../../Icons/IconArrowRight';

export default function QuizNotify() {
    const dispatch = useDispatch();
    const quizState = useSelector((state: RootState) => state.quiz);

    if (!quizState?.quizData?.question_list) return null;

    const openQuizPopup = () => dispatch(quizAction.setIsActiveQuizModal(true));
    const removeQuiz = () => {
        dispatch(quizAction.getQuizData());
        dispatch(quizAction.getTypeList());
    };

    return (
        <div className={styles.quiz_notify_wrap}>
            <div className={styles.title}>Quick survey</div>
            <div className={styles.text}>Please share your feeling about our services</div>
            <div className={styles.button} onClick={openQuizPopup}>
                <span>Take survey</span>
                <IconArrowRight />
            </div>
            <div className={styles.close} onClick={removeQuiz} />
        </div>
    );
}
