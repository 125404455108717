import * as currentChatAction from '../../store/actions/currentChat';
import { closeMobileMenu, openMobChatList } from '../../services/dom';
import { useDispatch } from 'react-redux';
import useTransition from '../transition/useTransition';

export default function useHandleMenu() {
    const dispatch = useDispatch();
    const transition = useTransition();

    const onSetSubMenu = (menuTitle: string) => {
        dispatch(currentChatAction.removeMainChatInfo());
        transition.transitionWithOnlyHistoryPush(`/${menuTitle}`);

        closeMobileMenu();
        window.innerWidth < 900 && openMobChatList();
    };

    return {
        onSetSubMenu,
    };
}
