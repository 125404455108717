import React from 'react';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import LetterItem from './LetterItem';
import DraftItem from './DraftItem';
import EmptyResult from '../../common/EmptyResult';
import ComposeLetter from '../LettersMenu/ComposeLetter';
import LoaderGif from '../../common/LoaderGif';
import useHandleLettersList from '../../../hooks/letters/useHandleLettersList';
import GirlsSlider from '../../common/GirlsSlider/GirlsSlider';
import { RootState } from '../../../index';
import { IDraftsFullInfo, IMailsFullInfo } from '../../../services/api/MailboxApi.interface';

export default function LettersList() {
    const mailboxState = useSelector((state: RootState) => state.mailbox);
    const menuState = useSelector((state: RootState) => state.menu);

    const handleLettersList = useHandleLettersList();

    const isDraft = (mailboxState?.mailList as IDraftsFullInfo[])?.some((item) => item?.draft?.id);

    const mailList = isDraft
        ? (mailboxState?.mailList as IDraftsFullInfo[])
        : (mailboxState?.mailList as IMailsFullInfo[]);

    return (
        <div className="c3l_men_list_wrap">
            <GirlsSlider />

            <ComposeLetter />
            <div className="c3l_men_list_wrap3">
                <div className="c3l_men_list_wrap2">
                    {mailList?.length ? (
                        isDraft ? (
                            (mailList as IDraftsFullInfo[])?.map((item) => (
                                <DraftItem key={item?.draft?.id} item={item} />
                            ))
                        ) : (
                            (mailList as IMailsFullInfo[])?.map((item) => (
                                <LetterItem
                                    key={item?.mail?.id}
                                    item={item}
                                    type={menuState?.letters}
                                />
                            ))
                        )
                    ) : mailboxState?.empty ? (
                        <EmptyResult
                            type={handleLettersList.switchMailBox(menuState?.letters)}
                            setDefaultFilterValue={handleLettersList?.setDefaultFilterValue}
                        />
                    ) : (
                        <LoaderGif />
                    )}
                    {+mailboxState?.mailListAllPages > 1 && (
                        <div className="letter-pagination">
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                initialPage={mailboxState?.mailListPage - 1}
                                forcePage={mailboxState?.mailListPage - 1}
                                breakClassName={'break-me'}
                                pageCount={mailboxState?.mailListAllPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(event) => {
                                    if (event?.selected === 0 && mailboxState?.mailListPage <= 1)
                                        return;
                                    handleLettersList.handleChangePage(event?.selected + 1);
                                }}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
