export enum DataLayerStatuses {
    SUCCESS = 1,
    ERROR = 2,
}

export interface IGeneralData {
    externalID: number;
    siteID: number;
    source: string;
    event: string;
    conversionType: string;
    status: number;
}

export interface IImpression {
    name: string;
    id: number;
    position: number;
    price: number;
}

export interface IEcommerce {
    currencyCode: 'USD' | 'EUR';
    impressions: IImpression[];
}

export interface IUserData {
    external_id: number;
    email: string;
    first_name: string;
    last_name?: string;
    gender: 'm' | 'f';
    dob: string;
    country: string;
    transaction_id: string;
    new_customer: boolean;
    customer_lifetime_value: number;
    city?: string;
    fbp?: string;
    fbc?: string;
}

export interface IDataLayerEcommerce {
    general_data: IGeneralData;
    ecommerce: IEcommerce;
}

export interface IDataLayerRegular {
    value: number;
    currency: 'USD' | 'EUR';
    general_data: IGeneralData;
    user_data: IUserData;
}
// component input interface
interface UserData {
    email: string;
    first_name?: string;
    last_name?: string;
    gender?: 'm' | 'f';
    dob?: string;
    country?: string;
    city?: string;
    external_id: number;
    transaction_id?: string;
    new_customer?: boolean;
    customer_lifetime_value?: number;
}

export interface IInputRegularData {
    event: string;
    value: number;
    currency: any;
    user_data: UserData;
    fbp?: string;
    fbc?: string;
}

export type LogType = 'regular' | 'ecommerce';
export type LogData = Omit<IDataLayerRegular | IDataLayerEcommerce, 'status'>;

export interface LogConfig {
    regular: {
        method: 'regularLog';
        type: IDataLayerRegular;
    };
    ecommerce: {
        method: 'ecommerceLog';
        type: IDataLayerEcommerce;
    };
}
