import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import VerifyPhoneCode from './VerifyPhoneCode';
import { IVerifyPhoneCodeModalProps } from './index.interface';

export default function VerifyPhoneCodeModal({
    isVerifyPhoneCodeModal,
    closeVerifyPhoneCodeModal,
    verifyCode,
    handleVerifyCode,
    handleKeyDownEnter,
    submitVerifyCode,
    submitPhoneData,
    isApprovePhone,
    switchPhoneNumber,
}: IVerifyPhoneCodeModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isVerifyPhoneCodeModal}
            // onRequestClose={closeVerifyPhoneCodeModal}
            ariaHideApp={false}
        >
            <VerifyPhoneCode
                closeModal={closeVerifyPhoneCodeModal}
                handleVerifyCode={handleVerifyCode}
                handleKeyDownEnter={handleKeyDownEnter}
                verifyCode={verifyCode}
                submitVerifyCode={submitVerifyCode}
                submitPhoneData={submitPhoneData}
                isApprovePhone={isApprovePhone}
                switchPhoneNumber={switchPhoneNumber}
            />
        </Modal>
    );
}
