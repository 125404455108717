import React from 'react';

export default function IconClockArchive() {
    return (
        <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_93)">
                <path d="M10.1328 4.85156V8.70877L12.6925 11.2685C12.9671 11.5431 12.9671 11.9882 12.6925 12.2628C12.4179 12.5374 11.9727 12.5374 11.6981 12.2628L8.93251 9.49718C8.80064 9.36534 8.72656 9.18647 8.72656 9V4.85156C8.72656 4.46323 9.04135 4.14844 9.42969 4.14844C9.81802 4.14844 10.1328 4.46323 10.1328 4.85156ZM12.1953 0H6.66405C5.19136 0 3.73845 0.51757 2.57594 1.52852V0.703125C2.57594 0.314789 2.26115 0 1.87282 0C1.48448 0 1.16969 0.314789 1.16969 0.703125V3.46876C1.16969 3.8571 1.48719 4.17189 1.87282 4.17189H4.63845C5.02679 4.17189 5.34158 3.8571 5.34158 3.46876C5.34158 3.08043 5.02679 2.76564 4.63845 2.76564H3.30839C4.19833 1.90765 5.39966 1.40625 6.66405 1.40625H12.1953C14.8576 1.40625 17.0234 3.57212 17.0234 6.23436V11.7656C17.0234 14.4279 14.8576 16.5938 12.1953 16.5938H6.66405C4.00181 16.5938 1.83594 14.4279 1.83594 11.7656V9C1.83594 8.61166 1.52115 8.29688 1.13281 8.29688C0.744477 8.29688 0.429688 8.61166 0.429688 9V11.7656C0.429688 15.2033 3.2264 18 6.66405 18H12.1953C15.633 18 18.4297 15.2033 18.4297 11.7656V6.23436C18.4297 2.79671 15.633 0 12.1953 0Z" />
            </g>
            <defs>
                <clipPath id="clip0_1_93">
                    <rect width="19" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
