import styles from '../styles.module.scss';
import React, { useState } from 'react';
import * as PURCHASE from '../../../constants/purchase';
import { useDispatch, useSelector } from 'react-redux';
import * as userAction from '../../../store/actions/user';
import moment from 'moment';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../index';
import { IPaymentMethodListProps } from './index.interface';
import unlimit from '../../../img/payments/cardpay_logo.png';
import amex_new from '../../../img/payments/amex_new.png';
import discover from '../../../img/payments/discover.svg';
import mastercard_new from '../../../img/payments/mastercard_new.svg';
import visa from '../../../img/payments/visa.png';
import lpb from '../../../img/payments/lpb.svg';
import lpbV2 from '../../../img/payments/lpb_v2.png';
import securionpay from '../../../img/payments/securionpay.png';
import trust from '../../../img/payments/trust_logo.jpg';
import applepay from '../../../img/payments/applepay.svg';
import googlepay from '../../../img/payments/googlepay.svg';
import uni from '../../../img/payments/uni.png';
import stripeImg from '../../../img/payments/stripe.png';

export default function PaymentMethodList({
    isLoadedPaymentFrame,
    openManageCreditCardModal,
    getDefaultCreditCard,
    getCreditCardList,
    isAvailablePaymentMethod,
    setActivePaymentMethodWithClick,
}: IPaymentMethodListProps) {
    const userState = useSelector((state: RootState) => state.user);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const [lastCheck, setLastCheck] = useState(new Date());

    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();

    const checkActiveButton = () => {
        if (userState?.isBuying) {
            if (
                purchaseState?.paymentMethodList?.some((item) =>
                    item?.method?.includes(PURCHASE.PAYMENT_METHOD_ACQUIRING),
                )
            ) {
                if (
                    lastCheck &&
                    moment(new Date()).diff(moment(new Date(lastCheck)), 'seconds') >= 1
                ) {
                    setLastCheck(new Date());

                    return isLoadedPaymentFrame;
                }

                return false;
            }

            return isLoadedPaymentFrame;
        }

        return true;
    };

    return (
        <div className={`${styles.new_payment_right}`} data-testid="method-list-block">
            <ul className={styles.new_payment_methods_list}>
                {!!purchaseState?.paymentMethodList?.length &&
                    purchaseState?.paymentMethodList
                        ?.filter((item) => isAvailablePaymentMethod(item?.method))
                        ?.sort((a, b) => a.order - b.order)
                        ?.map((item, key) => {
                            switch (item.method) {
                                case PURCHASE.PAYMENT_METHOD_STRIPE:
                                    return (
                                        <div
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_STRIPE ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_STRIPE,
                                                )
                                            }
                                            data-testid={PURCHASE.PAYMENT_METHOD_STRIPE}
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={stripeImg}
                                                    className={
                                                        styles.new_payment_method_name_stripe
                                                    }
                                                    alt=""
                                                    data-testid="method-logo"
                                                />
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={amex_new}
                                                    alt=""
                                                    data-testid="method-amex-logo"
                                                />
                                                <img
                                                    src={discover}
                                                    alt=""
                                                    data-testid="method-discover-logo"
                                                />
                                                <img
                                                    src={mastercard_new}
                                                    alt=""
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt=""
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>
                                            {!!item?.isTechWork && (
                                                <div
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </div>
                                            )}
                                        </div>
                                    );

                                case PURCHASE.PAYMENT_METHOD_CARDPAY:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_CARDPAY ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_CARDPAY,
                                                )
                                            }
                                            data-testid={PURCHASE.PAYMENT_METHOD_CARDPAY}
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={unlimit}
                                                    alt="Unlimit payment logo"
                                                    data-testid="method-logo"
                                                />
                                                <span data-testid="method-name">
                                                    {handleActiveBlocks?.isUnlimitName
                                                        ? 'Unlimit'
                                                        : 'Unlimint'}
                                                </span>
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={amex_new}
                                                    alt="American express payment logo"
                                                    data-testid="method-amex-logo"
                                                />
                                                <img
                                                    src={discover}
                                                    alt="Discover payment logo"
                                                    data-testid="method-discover-logo"
                                                />
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>
                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                            {!!getCreditCardList(PURCHASE.PAYMENT_METHOD_CARDPAY)
                                                ?.length &&
                                                !userState?.isBuying &&
                                                !item?.isTechWork && (
                                                    <div
                                                        className={
                                                            styles.new_payment_method_chosen_card
                                                        }
                                                    >
                                                        <span data-testid="method-default-card">
                                                            {getDefaultCreditCard(
                                                                PURCHASE.PAYMENT_METHOD_CARDPAY,
                                                            )}
                                                        </span>
                                                        <p
                                                            onClick={openManageCreditCardModal}
                                                            data-testid="method-change-btn"
                                                        >
                                                            Change
                                                        </p>
                                                    </div>
                                                )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK:
                                    return (
                                        <div
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK,
                                                )
                                            }
                                            data-testid={
                                                PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK
                                            }
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={unlimit}
                                                    alt="Unlimit payment logo"
                                                    data-testid="method-logo"
                                                />
                                                <span data-testid="method-name">
                                                    {handleActiveBlocks?.isUnlimitName
                                                        ? 'Unlimit'
                                                        : 'Unlimint'}
                                                </span>
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={amex_new}
                                                    alt="American express payment logo"
                                                    data-testid="method-amex-logo"
                                                />
                                                <img
                                                    src={discover}
                                                    alt="Discover payment logo"
                                                    data-testid="method-discover-logo"
                                                />
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>
                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                            {!!getCreditCardList(
                                                PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK,
                                            )?.length &&
                                                !userState?.isBuying &&
                                                !item?.isTechWork && (
                                                    <div
                                                        className={
                                                            styles.new_payment_method_chosen_card
                                                        }
                                                    >
                                                        <span data-testid="method-default-card">
                                                            {getDefaultCreditCard(
                                                                PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK,
                                                            )}
                                                        </span>
                                                        <p
                                                            onClick={openManageCreditCardModal}
                                                            data-testid="method-change-btn"
                                                        >
                                                            Change
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    );

                                case PURCHASE.PAYMENT_METHOD_PASTABANK:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_PASTABANK ? styles.active : ''}`}
                                            onClick={() => {
                                                if (!item?.isTechWork && checkActiveButton()) {
                                                    if (userState?.isBuying)
                                                        dispatch(userAction.isBuying(false));
                                                    setActivePaymentMethodWithClick(
                                                        PURCHASE.PAYMENT_METHOD_PASTABANK,
                                                    );
                                                }
                                            }}
                                            data-testid={PURCHASE.PAYMENT_METHOD_PASTABANK}
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={
                                                        handleActiveBlocks.isNewPastaLogo
                                                            ? lpbV2
                                                            : lpb
                                                    }
                                                    className={styles.new_payment_method_name_lpb}
                                                    alt=""
                                                    data-testid="method-logo"
                                                />
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>

                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}

                                            {!!getCreditCardList(PURCHASE.PAYMENT_METHOD_PASTABANK)
                                                ?.length &&
                                                !userState?.isBuying &&
                                                !item?.isTechWork && (
                                                    <div
                                                        className={
                                                            styles.new_payment_method_chosen_card
                                                        }
                                                    >
                                                        <span data-testid="method-default-card">
                                                            {getDefaultCreditCard(
                                                                PURCHASE.PAYMENT_METHOD_PASTABANK,
                                                            )}
                                                        </span>
                                                        <p
                                                            onClick={openManageCreditCardModal}
                                                            data-testid="method-change-btn"
                                                        >
                                                            Change
                                                        </p>
                                                    </div>
                                                )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_SECURIONPAY:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_SECURIONPAY ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_SECURIONPAY,
                                                )
                                            }
                                            data-testid={PURCHASE.PAYMENT_METHOD_SECURIONPAY}
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={securionpay}
                                                    className={`${styles.new_payment_method_name_lpb} ${styles.new_payment_method_name_securionpay}`}
                                                    alt="Securionpay payment logo"
                                                    data-testid="method-logo"
                                                />
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>
                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_ACQUIRING:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_ACQUIRING ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_ACQUIRING,
                                                )
                                            }
                                            data-testid={PURCHASE.PAYMENT_METHOD_ACQUIRING}
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={trust}
                                                    className={styles.new_payment_method_name_lpb}
                                                    alt="Trust payment logo"
                                                    data-testid="method-logo"
                                                />
                                            </div>
                                            {handleActiveBlocks.isNewTrustProcessing ? (
                                                <div
                                                    className={
                                                        styles.new_payment_method_services_list
                                                    }
                                                >
                                                    <img
                                                        src={amex_new}
                                                        alt="American express payment logo"
                                                        style={{ width: '50px' }}
                                                        data-testid="method-amex-logo"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        styles.new_payment_method_services_list
                                                    }
                                                >
                                                    <img
                                                        src={amex_new}
                                                        alt="American express payment logo"
                                                        data-testid="method-amex-logo"
                                                    />
                                                    <img
                                                        src={mastercard_new}
                                                        alt="Mastercard payment logo"
                                                        data-testid="method-mastercard-logo"
                                                    />
                                                </div>
                                            )}
                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                            {!!getCreditCardList(PURCHASE.PAYMENT_METHOD_ACQUIRING)
                                                ?.length &&
                                                !userState?.isBuying &&
                                                !item?.isTechWork && (
                                                    <div
                                                        className={
                                                            styles.new_payment_method_chosen_card
                                                        }
                                                    >
                                                        <span data-testid="method-default-card">
                                                            {getDefaultCreditCard(
                                                                PURCHASE.PAYMENT_METHOD_ACQUIRING,
                                                            )}
                                                        </span>
                                                        <p
                                                            onClick={openManageCreditCardModal}
                                                            data-testid="method-change-btn"
                                                        >
                                                            Change
                                                        </p>
                                                    </div>
                                                )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_APPLEPAY:
                                    return (
                                        !!window?.ApplePaySession && (
                                            <li
                                                key={key}
                                                className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_APPLEPAY ? styles.active : ''}`}
                                                onClick={() =>
                                                    !item?.isTechWork &&
                                                    checkActiveButton() &&
                                                    setActivePaymentMethodWithClick(
                                                        PURCHASE.PAYMENT_METHOD_APPLEPAY,
                                                    )
                                                }
                                                data-testid={PURCHASE.PAYMENT_METHOD_APPLEPAY}
                                            >
                                                <div className={styles.new_payment_method_name}>
                                                    <img
                                                        src={applepay}
                                                        className={
                                                            styles.new_payment_method_name_lpb
                                                        }
                                                        alt="Applepay payment logo"
                                                        data-testid="method-logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.new_payment_method_services_list
                                                    }
                                                >
                                                    <img
                                                        src={mastercard_new}
                                                        alt="Master card payment logo"
                                                        data-testid="method-mastercard-logo"
                                                    />
                                                    <img
                                                        src={visa}
                                                        alt="Visa payment logo"
                                                        data-testid="method-visa-logo"
                                                    />
                                                </div>
                                                {!!item?.isTechWork && (
                                                    <p
                                                        className={
                                                            styles.new_payment_method_technical_works
                                                        }
                                                        data-testid="method-tech-works"
                                                    >
                                                        Technical works
                                                    </p>
                                                )}
                                            </li>
                                        )
                                    );

                                case PURCHASE.PAYMENT_METHOD_APPLEPAY_PASTABANK:
                                    return (
                                        !!window?.ApplePaySession && (
                                            <li
                                                key={key}
                                                className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_APPLEPAY_PASTABANK ? styles.active : ''}`}
                                                onClick={() =>
                                                    !item?.isTechWork &&
                                                    checkActiveButton() &&
                                                    setActivePaymentMethodWithClick(
                                                        PURCHASE.PAYMENT_METHOD_APPLEPAY_PASTABANK,
                                                    )
                                                }
                                                data-testid={
                                                    PURCHASE.PAYMENT_METHOD_APPLEPAY_PASTABANK
                                                }
                                            >
                                                <div className={styles.new_payment_method_name}>
                                                    <img
                                                        src={applepay}
                                                        className={
                                                            styles.new_payment_method_name_lpb
                                                        }
                                                        alt="Applepay payment logo"
                                                        data-testid="method-logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.new_payment_method_services_list
                                                    }
                                                >
                                                    <img
                                                        src={mastercard_new}
                                                        alt="Mastercard payment logo"
                                                        data-testid="method-mastercard-logo"
                                                    />
                                                    <img
                                                        src={visa}
                                                        alt="Visa payment logo"
                                                        data-testid="method-visa-logo"
                                                    />
                                                </div>
                                                {!!item?.isTechWork && (
                                                    <p
                                                        className={
                                                            styles.new_payment_method_technical_works
                                                        }
                                                        data-testid="method-tech-works"
                                                    >
                                                        Technical works
                                                    </p>
                                                )}
                                            </li>
                                        )
                                    );

                                case PURCHASE.PAYMENT_METHOD_ACQUIRING_APPLE_PAY:
                                    return (
                                        window?.ApplePaySession && (
                                            <li
                                                key={key}
                                                className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_ACQUIRING_APPLE_PAY ? styles.active : ''}`}
                                                onClick={() =>
                                                    !item?.isTechWork &&
                                                    checkActiveButton() &&
                                                    setActivePaymentMethodWithClick(
                                                        PURCHASE.PAYMENT_METHOD_ACQUIRING_APPLE_PAY,
                                                    )
                                                }
                                                data-testid={
                                                    PURCHASE.PAYMENT_METHOD_ACQUIRING_APPLE_PAY
                                                }
                                            >
                                                <div className={styles.new_payment_method_name}>
                                                    <img
                                                        src={applepay}
                                                        className={
                                                            styles.new_payment_method_name_lpb
                                                        }
                                                        alt="Applepay payment logo"
                                                        data-testid="method-logo"
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.new_payment_method_services_list
                                                    }
                                                >
                                                    <img
                                                        src={mastercard_new}
                                                        alt="Mastercard payment logo"
                                                        data-testid="method-mastercard-logo"
                                                    />
                                                    <img
                                                        src={visa}
                                                        alt="Visa payment logo"
                                                        data-testid="method-visa-logo"
                                                    />
                                                </div>
                                                {!!item?.isTechWork && (
                                                    <p
                                                        className={
                                                            styles.new_payment_method_technical_works
                                                        }
                                                        data-testid="method-tech-works"
                                                    >
                                                        Technical works
                                                    </p>
                                                )}
                                            </li>
                                        )
                                    );

                                case PURCHASE.PAYMENT_METHOD_ACQUIRING_GOOGLE_PAY:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${styles.dis_padding} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_ACQUIRING_GOOGLE_PAY ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_ACQUIRING_GOOGLE_PAY,
                                                )
                                            }
                                            data-testid={
                                                PURCHASE.PAYMENT_METHOD_ACQUIRING_GOOGLE_PAY
                                            }
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={googlepay}
                                                    className={
                                                        styles.new_payment_method_name_googlepay
                                                    }
                                                    alt="Googlepay payment logo"
                                                    data-testid="method-logo"
                                                />
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>
                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${styles.dis_padding} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY,
                                                )
                                            }
                                            data-testid={PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY}
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={googlepay}
                                                    className={
                                                        styles.new_payment_method_name_googlepay
                                                    }
                                                    alt="Googlepay payment logo"
                                                    data-testid="method-logo"
                                                />
                                            </div>

                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>

                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${styles.dis_padding} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY,
                                                )
                                            }
                                            data-testid={
                                                PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY
                                            }
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={googlepay}
                                                    className={
                                                        styles.new_payment_method_name_googlepay
                                                    }
                                                    alt="Googlepay mayment logo"
                                                    data-testid="method-logo"
                                                />
                                            </div>

                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                            </div>

                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_UNIVERSEPAY:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_UNIVERSEPAY ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_UNIVERSEPAY,
                                                )
                                            }
                                            data-testid={PURCHASE.PAYMENT_METHOD_UNIVERSEPAY}
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={uni}
                                                    className={styles.new_payment_method_name_uni}
                                                    alt="Universe payment logo"
                                                    data-testid="method-logo"
                                                />
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                {handleActiveBlocks.isDiscoverUniverse && (
                                                    <img
                                                        src={discover}
                                                        alt="Discover payment logo"
                                                        data-testid="method-discover-logo"
                                                    />
                                                )}
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={amex_new}
                                                    alt="American express payment logo"
                                                    data-testid="method-amex-logo"
                                                />
                                            </div>
                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}
                                        </li>
                                    );

                                case PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING:
                                    return (
                                        <li
                                            key={key}
                                            className={`${styles.new_payment_method_item} ${purchaseState.activePaymentMethod === PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING ? styles.active : ''}`}
                                            onClick={() =>
                                                !item?.isTechWork &&
                                                checkActiveButton() &&
                                                setActivePaymentMethodWithClick(
                                                    PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING,
                                                )
                                            }
                                            data-testid={
                                                PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING
                                            }
                                        >
                                            <div className={styles.new_payment_method_name}>
                                                <img
                                                    src={uni}
                                                    className={styles.new_payment_method_name_uni}
                                                    alt="Universe payment logo"
                                                    data-testid="method-logo"
                                                />
                                            </div>
                                            <div
                                                className={styles.new_payment_method_services_list}
                                            >
                                                {handleActiveBlocks.isDiscoverUniverse && (
                                                    <img
                                                        src={discover}
                                                        alt="Discover payment logo"
                                                        data-testid="method-discover-logo"
                                                    />
                                                )}
                                                <img
                                                    src={visa}
                                                    alt="Visa payment logo"
                                                    data-testid="method-visa-logo"
                                                />
                                                <img
                                                    src={mastercard_new}
                                                    alt="Mastercard payment logo"
                                                    data-testid="method-mastercard-logo"
                                                />
                                                <img
                                                    src={amex_new}
                                                    alt="American express payment logo"
                                                    data-testid="method-amex-logo"
                                                />
                                            </div>
                                            {!!item?.isTechWork && (
                                                <p
                                                    className={
                                                        styles.new_payment_method_technical_works
                                                    }
                                                    data-testid="method-tech-works"
                                                >
                                                    Technical works
                                                </p>
                                            )}

                                            {!!getDefaultCreditCard(
                                                PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING,
                                            )?.length &&
                                                !userState?.isBuying &&
                                                !item?.isTechWork && (
                                                    <div
                                                        className={
                                                            styles.new_payment_method_chosen_card
                                                        }
                                                    >
                                                        <span data-testid="method-default-card">
                                                            {getDefaultCreditCard(
                                                                PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING,
                                                            )}
                                                        </span>
                                                        <p
                                                            onClick={openManageCreditCardModal}
                                                            data-testid="method-change-btn"
                                                        >
                                                            Change
                                                        </p>
                                                    </div>
                                                )}
                                        </li>
                                    );

                                default:
                                    return null;
                            }
                        })}
            </ul>
        </div>
    );
}
