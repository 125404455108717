import React from 'react';
import { useSelector } from 'react-redux';
import '../../../styles/man-profile/main.scss';
import '../../../styles/man-profile/media.scss';
import '../../../styles/man-profile/new-profile.scss';
import '../../../styles/columns/column.scss';
import HeaderProfile from '../ProfileHeader';
import NotifyEmailConfirm from '../../Banner';
import ProfileV2 from './ProfileV2';
import { RootState } from '../../../index';

export default function Profile() {
    const giftsState = useSelector((state: RootState) => state.gifts);

    return (
        <>
            <div
                className={`column-3 profile_man profile_flex ${+giftsState.newGiftMessageCount > 0 ? 'notif' : ''}`}
            >
                <NotifyEmailConfirm />
                <HeaderProfile />
                <ProfileV2 />
            </div>
        </>
    );
}
