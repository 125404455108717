import React, { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import { IManProfileReferenceCityItem } from '../../../models/profile/ManProfile.interface';

interface ISearchCity {
    inputCityRef: RefObject<HTMLInputElement>;
    handleCityFilter: (inputValue: string) => void;
    cityList: IManProfileReferenceCityItem[];
    initInputValue: string;
}

export default function SearchCity({
    inputCityRef,
    handleCityFilter,
    cityList,
    initInputValue = '',
}: ISearchCity) {
    const selectCityRef = useRef<HTMLDivElement | null>(null);

    const [openCityBlock, setOpenCityBlock] = useState(false);
    const [inputValue, setInputValue] = useState(initInputValue);

    useOutsideClicker(selectCityRef, () => setOpenCityBlock(false));

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) =>
        setInputValue(event.target.value);

    useEffect(() => {
        const timeoutId = setTimeout(() => handleCityFilter(inputValue), 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    useEffect(() => {
        if (inputValue !== initInputValue) setInputValue(initInputValue);
    }, [initInputValue]);

    return (
        <div className="c3lh_search_country" ref={selectCityRef}>
            <div className="fill_data_form_select_item_wrap">
                <div className="fill_data_form_select_item_name">City</div>
                <div className="fill_data_form_select_item">
                    <div className="cl2h_tab_wide_filter_item_wrap">
                        <div
                            className={`cl2h_tab_wide_filter_item ${openCityBlock ? 'open' : ''}`}
                            data-testid="city"
                        >
                            <div className="fill_data_form_select_item_country_wrap">
                                <input
                                    className={`like_select_head ${inputValue?.length ? 'selected' : ''}`}
                                    ref={inputCityRef}
                                    placeholder={'First choose country'}
                                    value={inputValue}
                                    onChange={onChangeInput}
                                    onClick={() => setOpenCityBlock(!openCityBlock)}
                                />
                                {!!+inputValue?.length && (
                                    <span
                                        className="like_select_head_remove"
                                        onClick={() => setInputValue('')}
                                    />
                                )}
                            </div>
                            <div className={`like_select_list_wrap ${openCityBlock ? 'open' : ''}`}>
                                <div
                                    className={`like_select_list ${openCityBlock ? 'open' : ''}`}
                                    data-testid="select-list"
                                >
                                    {!!cityList?.length &&
                                        cityList.map((item, key) => (
                                            <React.Fragment key={key}>
                                                <input
                                                    type="radio"
                                                    name={`search_sel-${key}`}
                                                    id={`main-reg-${key}`}
                                                    className="like_select"
                                                />
                                                <label
                                                    htmlFor={`search_sel-${key}`}
                                                    className="like_select_label"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        setInputValue(item?.city_name);
                                                        setOpenCityBlock(false);
                                                    }}
                                                >
                                                    {item?.city_name}
                                                </label>
                                            </React.Fragment>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
