import { WomanActionTypes } from '../../constants/woman';
import { IWoman, IWomanState, WomanActions } from './index.interface';

const initState = {
    list: [],
    similarWomanList: [],
};

export default function (state: IWomanState = initState, action: WomanActions): IWomanState {
    switch (action.type) {
        case WomanActionTypes.ADD_WOMAN_LIST:
            return {
                ...state,
                ...{
                    list: [...action.womanList, ...state.list],
                },
            };

        case WomanActionTypes.SET_IS_BLOCKED_WOMAN_LIST:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (+item?.external_id === +action?.womanExternalID)
                            return {
                                ...item,
                                ...{ is_blocked: action?.isBlocked },
                            };
                        else return item;
                    }) as IWoman[],
                },
            };

        case WomanActionTypes.SET_SIMILAR_WOMAN_LIST:
            return {
                ...state,
                ...{
                    similarWomanList: action.data,
                },
            };

        default:
            return state;
    }
}
