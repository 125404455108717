import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import * as modalsAction from '../../../../store/actions/modals';
import WomanGallery from './WomanGallery';
import { RootState } from '../../../../index';

export default function WomanGalleryModal() {
    const dispatch = useDispatch();

    const modalsState = useSelector((state: RootState) => state.modals);

    const onCloseGalleryModal = () => {
        dispatch(modalsAction.closeWomanGalleryModal());
    };

    return (
        <Modal
            style={modalStyleProps({ isGallery: true })}
            isOpen={modalsState?.womanGalleryModal?.isActive}
            onRequestClose={onCloseGalleryModal}
            ariaHideApp={false}
        >
            {modalsState?.womanGalleryModal?.isActive && (
                <WomanGallery
                    // @ts-expect-error externalID =(
                    externalID={modalsState?.womanGalleryModal?.externalID}
                    closeGalleryModal={onCloseGalleryModal}
                />
            )}
        </Modal>
    );
}
