export enum ModalsActionTypes {
    SHOW_EMAIL_EDIT_MODAL = 'SHOW_EMAIL_EDIT_MODAL',
    CLOSE_EMAIL_EDIT_MODAL = 'CLOSE_EMAIL_EDIT_MODAL',
    SHOW_ADD_CREDIT_CARD_MODAL = 'SHOW_ADD_CREDIT_CARD_MODAL',
    CLOSE_ADD_CREDIT_CARD_MODAL = 'CLOSE_ADD_CREDIT_CARD_MODAL',
    ACTIVE_SUB_MENU = 'ACTIVE_SUB_MENU',
    OPEN_WOMAN_PROFILE_MODAL = 'OPEN_WOMAN_PROFILE_MODAL',
    CLOSE_WOMAN_PROFILE_MODAL = 'CLOSE_WOMAN_PROFILE_MODAL',
    OPEN_WOMAN_VIDEO_SHOW_MODAL = 'OPEN_WOMAN_VIDEO_SHOW_MODAL',
    CLOSE_WOMAN_VIDEO_SHOW_MODAL = 'CLOSE_WOMAN_VIDEO_SHOW_MODAL',
    OPEN_SENDNERLY_GALLERY_MODAL = 'OPEN_SENDNERLY_GALLERY_MODAL',
    CLOSE_SENDNERLY_GALLERY_MODAL = 'CLOSE_SENDNERLY_GALLERY_MODAL',
    OPEN_WOMAN_GALLERY_MODAL = 'OPEN_WOMAN_GALLERY_MODAL',
    CLOSE_WOMAN_GALLERY_MODAL = 'CLOSE_WOMAN_GALLERY_MODAL',
    OPEN_VIDEO_CONTENT_MODAL = 'OPEN_VIDEO_CONTENT_MODAL',
    CLOSE_VIDEO_CONTENT_MODAL = 'CLOSE_VIDEO_CONTENT_MODAL',
    OPEN_PHOTO_CONTENT_MODAL = 'OPEN_PHOTO_CONTENT_MODAL',
    CLOSE_PHOTO_CONTENT_MODAL = 'CLOSE_PHOTO_CONTENT_MODAL',
    OPEN_PAYMENT_ROUTING_MODAL = 'OPEN_PAYMENT_ROUTING_MODAL',
    CLOSE_PAYMENT_ROUTING_MODAL = 'CLOSE_PAYMENT_ROUTING_MODAL',
    OPEN_LESS_THEN_CREDITS = 'OPEN_LESS_THEN_CREDITS',
    CLOSE_LESS_THEN_CREDITS = 'CLOSE_LESS_THEN_CREDITS',
    OPEN_WELCOME_MODAL = 'OPEN_WELCOME_MODAL',
    CLOSE_WELCOME_MODAL = 'CLOSE_WELCOME_MODAL',
    OPEN_NEW_FEATURE_MODAL = 'OPEN_NEW_FEATURE_MODAL',
    CLOSE_NEW_FEATURE_MODAL = 'CLOSE_NEW_FEATURE_MODAL',
    OPEN_REPLY_MODAL = 'OPEN_REPLY_MODAL',
    CLOSE_REPLY_MODAL = 'CLOSE_REPLY_MODAL',
    OPEN_SUCCESS_REPLY_MODAL = 'OPEN_SUCCESS_REPLY_MODAL',
    CLOSE_SUCCESS_REPLY_MODAL = 'CLOSE_SUCCESS_REPLY_MODAL',
    OPEN_PROFILE_IDENTIFICATION_MODAL = 'OPEN_PROFILE_IDENTIFICATION_MODAL',
    CLOSE_PROFILE_IDENTIFICATION_MODAL = 'CLOSE_PROFILE_IDENTIFICATION_MODAL',
}
