import Base from '../Base';
import {
    IGetLikeListMultiData,
    IGetSearchListData,
    IGetTopProfilesRes,
} from './SearchApi.interface';
import { CancelToken } from 'axios';

export default class SearchApi extends Base {
    getSearchList(page = 1, data: Partial<IGetSearchListData>) {
        return super
            .post(`v3/search/list/${page}`, { ...data, cityList: undefined, detail: undefined })
            .then((res) => res?.data)
            .catch(() => null);
    }

    getLikeListMulti(data: IGetLikeListMultiData) {
        return super
            .post(`v3/search/likeListMulti`, { ...data, cityList: undefined, detail: undefined })
            .then((res) => res?.data)
            .catch(() => null);
    }

    getLikesHistory(page = 1, isReverse = false, pageSize = 20) {
        return super
            .get(
                `v3/search/likeHistory?page=${page}&sortOrder=${isReverse ? 'DESC' : 'ASC'}&pageSize=${pageSize}`,
            )
            .then((res) => res?.data);
    }

    getWoman(external_id: number[], token?: CancelToken | null) {
        return super
            .post(`v3/search/getWoman`, { external_id }, token ? { ...{ cancelToken: token } } : {})
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    async getTopProfiles(): Promise<IGetTopProfilesRes> {
        return super
            .get(`search/topProfiles`)
            .then((res) => res?.data)
            .catch(() => null);
    }
}
