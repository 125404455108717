import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../index';
import { updateEvent } from '../../store/actions/settings';
import CommonApi from '../../services/api/CommonApi';

export default function useHandleBonusCredits() {
    const dispatch = useDispatch();
    const eventState = useSelector((state: RootState) => state.settings.event);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleCloseModal = () => {
        setIsOpenModal(false);
        if (eventState?.eventId && eventState?.eventId > 1) {
            new CommonApi().updateBonusEventStatus(eventState?.eventId);
            dispatch(updateEvent());
        }
    };

    useEffect(() => {
        if (eventState?.eventId && eventState?.eventId > 1) {
            setIsOpenModal(true);
        }
    }, [eventState]);

    return { handleCloseModal, isOpenModal };
}
