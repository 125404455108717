import React from 'react';
import { useSelector } from 'react-redux';
import useTransition from '../../../../../hooks/transition/useTransition';
import SITE from '../../../../../config/site';
import useHandleActiveBlocks from '../../../../../hooks/user/useHandleActiveBlocks';
import styles from './styles.module.scss';
import IconCross from '../../../../Icons/IconCross';
import Logotypes from '../../../../../img/logotypes';
import { RootState } from '../../../../../index';
import { IWelcomeAlertProps } from './index.interface';
import { isHolidayActive } from '../../../../../constants/holiday';
import welcome_photo_new_mob from '../../../../../img/welcome-alert/new_mob.png';
import welcome_photo_new from '../../../../../img/welcome-alert/new.png';
import welcome_alert_bg from '../../../../../img/tutorial/welcome_alert_bg.jpg';

export default function WelcomeAlert({
    closeWelcomeAlertModal,
    openTutorialModal,
}: IWelcomeAlertProps) {
    const userState = useSelector((state: RootState) => state.user);
    const transition = useTransition();
    const handleActiveBlocks = useHandleActiveBlocks();
    const activeHoliday = isHolidayActive();

    const isBalance = userState?.balance > 0;
    const isMan = !Number(userState?.info?.gender);

    const onSearch = () => {
        const isSearchPage = window?.location?.href?.includes('/search');
        if (!isSearchPage) transition.transitionWithOnlyHistoryPush('/search');
        closeWelcomeAlertModal();
    };
    const onTutorial = () => {
        openTutorialModal();
        closeWelcomeAlertModal();
    };

    return (
        <div className={styles.popup_welcome} data-testid="success-reg-modal">
            <div className={styles.popup_welcome_logo}>
                {activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}.svg`] ? (
                    <img
                        src={Logotypes.images[`logo_${activeHoliday?.name}.svg`]}
                        width="85"
                        height="40"
                        alt={SITE.name}
                    />
                ) : (
                    <img
                        src={Logotypes.images['logo.svg']}
                        width="85"
                        height="40"
                        alt={SITE.name}
                    />
                )}
            </div>
            <div className={styles.popup_welcome_header}>
                <h2 className={styles.popup_welcome_title} data-testid="title">
                    Welcome to {SITE.name}!
                </h2>
                <p className={styles.popup_welcome_desc} data-testid="description">
                    {SITE.welcomeModalText}
                </p>
            </div>

            {isMan ? (
                <picture>
                    <source srcSet={welcome_photo_new_mob} media="(max-width: 600px)" />
                    <img
                        className={styles.popup_welcome_new_photo}
                        src={welcome_photo_new}
                        width="496"
                        height="227"
                        alt={`Welcome to ${SITE.name}`}
                    />
                </picture>
            ) : (
                <img
                    className={styles.popup_welcome_new_photo}
                    src={welcome_alert_bg}
                    alt={`Welcome to ${SITE.name}`}
                />
            )}
            {isBalance && (
                <p className={styles.popup_welcome_free_tips}>
                    You’ve got {userState?.balance} free credits to begin
                </p>
            )}
            <p className={styles.popup_welcome_text}>
                <span>Our tip:</span> Users who initiate a conversation on our site are more likely
                to find love quicker
            </p>

            <div className={styles.popup_welcome_buttons}>
                {!handleActiveBlocks.isTwitterUser && (
                    <button
                        type="button"
                        className={styles.popup_welcome_tutorial_btn}
                        onClick={onTutorial}
                        data-testid="how-to-start-btn"
                    >
                        <span className={styles.popup_welcome_tutorial_btn_text}>How to start</span>
                    </button>
                )}
                <button
                    type="button"
                    className={styles.popup_welcome_start_btn}
                    onClick={onSearch}
                    data-testid="search-btn"
                >
                    <span className={styles.popup_welcome_start_btn_text}>Find your match</span>
                </button>
            </div>
            <button
                type="button"
                className={styles.popup_welcome_close}
                onClick={() => closeWelcomeAlertModal()}
                data-testid="close-btn"
            >
                <IconCross />
            </button>
        </div>
    );
}
