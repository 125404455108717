import React, { useState } from 'react';
import * as SYNC from '../../../../constants/sync';
import styles from './styles.module.scss';
import globalStyles from '../styles.module.scss';
import { useSelector } from 'react-redux';
import EachLikeNotify from './EachLikeNotify';
import JoinedNotify from './JoinedNotify';
import LikedNotify from './LikedNotify';
import MatchNotify from './MatchNotify';
import SearchingNotify from './SearchingNotify';
import UnknownLikeNotify from './UnknownLikeNotify';
import UpdatedNotify from './UpdatedNotify';
import ViewedNotify from './ViewedNotify';
import IsOnlineHiNotify from './IsOnlineHiNotify';
import HasNoChatsNotify from './HasNoChatsNotify';
import AppealNotify from './AppealNotify';
import WantToChatNotify from './WantToChatNotify';
import useHandleTransactionNotify from '../../../../hooks/notify/useHandleTransactionNotify';
import { RootState } from '../../../../index';
import { isHolidayActive } from '../../../../constants/holiday';

export default function GirlsList() {
    const notifyState = useSelector((state: RootState) => state.notify);
    const [isOpenActivity, setIsOpenActivity] = useState(true);
    const activeHoliday = isHolidayActive();

    const handleTransactionNotify = useHandleTransactionNotify();

    const openActivity = () => {
        setIsOpenActivity(!isOpenActivity);
    };
    return (
        <div
            className={`${styles.notify_girls_wrap} ${isOpenActivity ? styles.active : ''} ${activeHoliday && styles[activeHoliday?.name]}`}
            data-testid="member-activity-list"
        >
            <div className={styles.notify_girls_title_wrap} onClick={openActivity}>
                <h2 className={styles.notify_girls_title}>
                    Members activity
                    {!!notifyState?.transactionList?.length && (
                        <span className={globalStyles.notify_girls_title_mob_dot} />
                    )}
                </h2>
            </div>
            <div className={styles.notify_girls_list_wrap}>
                <ul className={styles.notify_girls_list}>
                    {!!notifyState?.transactionList?.length &&
                        notifyState?.transactionList.map((item, key) =>
                            (function () {
                                switch (item?.notification_type) {
                                    case SYNC.NOTIFY_NEW_GIRL_ACTION:
                                        return (
                                            <JoinedNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_STRANGER_ACTION:
                                        return (
                                            <UnknownLikeNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_POSSIBLE_MATCH_ACTION:
                                        return (
                                            <MatchNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_PROFILE_UPDATE_ACTION:
                                        return (
                                            <UpdatedNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_SEARCH_PARTNER_ACTION:
                                        return (
                                            <SearchingNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_VIEW_PROFILE_ACTION:
                                        return (
                                            <ViewedNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_LIKE_ACTION:
                                        return (
                                            <LikedNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_MATCH_ACTION:
                                        return (
                                            <EachLikeNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_ONLINE_NOW:
                                        return (
                                            <IsOnlineHiNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_MAYBE_APPEAL:
                                        return (
                                            <AppealNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_NO_CHAT:
                                        return (
                                            <HasNoChatsNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    case SYNC.NOTIFY_MAYBE_CHAT:
                                        return (
                                            <WantToChatNotify
                                                key={key}
                                                item={item}
                                                {...handleTransactionNotify}
                                            />
                                        );

                                    default:
                                        return <div />;
                                }
                            })(),
                        )}
                </ul>
            </div>
        </div>
    );
}
