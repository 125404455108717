import * as AWS from '../../constants/aws';

export function insertedMessageBeforeSync(
    id: number,
    messageContent: string,
    externalId: number,
    type = 'SENT_TEXT',
    dateCreated = '',
) {
    const insertedObj =
        type === 'SENT_IMAGE'
            ? {
                  message_popup: `${messageContent.split(`${externalId}/`)[0]}${externalId}/w-${AWS.AWS_THUMBS_BIG_SIZE_WIDTH}-h-${AWS.AWS_THUMBS_BIG_SIZE_HEIGHT}-${messageContent.split(`${externalId}/`)[1]}`,
                  message_thumb: `${messageContent.split(`${externalId}/`)[0]}${externalId}/w-${AWS.AWS_THUMBS_SMALL_SIZE_WIDTH}-h-${AWS.AWS_THUMBS_SMALL_SIZE_HEIGHT}-${messageContent.split(`${externalId}/`)[1]}`,
              }
            : {};

    return {
        ...{
            id: id,
            sender_external_id: externalId,
            date_created: dateCreated,
            message_content: messageContent,
            message_type: type,
            read_status: 0,
        },
        ...insertedObj,
    };
}
