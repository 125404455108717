import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import * as SEX from '../../../../../../constants/sex';
import useHandleActiveBlocks from '../../../../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../../../../index';
import empty_opened_chat from '../../../../../../img/empty-opened-chat.svg';
import { isHolidayActive } from '../../../../../../constants/holiday';
import WinkList from '../WinkList';
import { SUPPORT_WOMAN } from '../../../../../../constants/user';
import useHandleChatFunctions from '../../../../../../hooks/chat/useHandleChatFunctions';

export default function MessageListEmpty() {
    const userState = useSelector((state: RootState) => state.user);

    const handleActiveBlocks = useHandleActiveBlocks();
    const activeHoliday = isHolidayActive();
    const { sayHelloList, currentChatInfo } = useHandleChatFunctions();

    const isSupportWoman = currentChatInfo?.id === SUPPORT_WOMAN;
    const winkStatus = !!currentChatInfo?.wink;
    const isActiveWinkList = !winkStatus && !isSupportWoman;

    const mainTitle = 'Your chat history is empty';
    const subTitle = handleActiveBlocks.isSearchV3
        ? 'To show your interest, say hello'
        : SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
              .CHAT_EMPTY_CHATROOM_SAY_HELLO_BTN;

    return (
        <div className={styles.empty_opened_chat_wrap}>
            <div className={styles.empty_opened_chat}>
                {!activeHoliday && (
                    <img
                        src={empty_opened_chat}
                        alt="empty chat"
                        className={styles.empty_opened_chat_img}
                    />
                )}
                <div className={styles.empty_opened_chat_content}>
                    <h2 className={styles.empty_opened_chat_title}>{mainTitle}</h2>
                    {isActiveWinkList && (
                        <h3 className={styles.empty_opened_chat_text}>{subTitle}</h3>
                    )}
                </div>
            </div>

            {isActiveWinkList && (
                <WinkList sayHelloList={sayHelloList} womanID={currentChatInfo?.id} />
            )}
        </div>
    );
}
