import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Modal from 'react-modal';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PaymentApi from '../../../../services/api/v3/PaymentApi';
import { RootState } from '../../../../index';
import IconCross from '../../../Icons/IconCross';

export default function LoyaltyProgramModal() {
    const userState = useSelector((state: RootState) => state.user);
    const [showBlock, setShowBlock] = useState<boolean>(false);

    const handleOpen = () => {
        if (userState?.loyaltyProgram?.id)
            new PaymentApi().createUserLoyaltyProgram(userState?.loyaltyProgram?.id);

        setShowBlock(false);
        localStorage.setItem('lprogram-modal', new Date().toISOString());

        const profileVipBlock = document.querySelector('#profile-vip-block');
        if (profileVipBlock) profileVipBlock.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };

    const handleClose = () => {
        setShowBlock(false);
        localStorage.setItem('lprogram-modal', new Date().toISOString());
    };

    const isShowByLocalStorageCondition = () => {
        const isActiveByLS = localStorage.getItem('lprogram-modal') || null;
        if (!isActiveByLS) return true;

        return moment(new Date()).diff(moment(new Date(isActiveByLS)), 'hours') >= 1;
    };

    useEffect(() => {
        if (
            userState?.loyaltyProgram &&
            !userState?.loyaltyProgram?.status &&
            isShowByLocalStorageCondition()
        ) {
            setShowBlock(true);
        }
    }, [userState?.loyaltyProgram]);

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={showBlock}
            onRequestClose={handleClose}
            ariaHideApp={false}
        >
            {showBlock && (
                <div className={styles.vip_onb_wrapper}>
                    <div className={styles.vip_onb_item}>
                        <div className={styles.vip_onb_title}>LOYALTY PROGRAM</div>
                        <div className={styles.vip_onb_container}>
                            <div>
                                <div className={styles.vip_onb_container_txt}>
                                    <span className={styles.vip_onb_txt}>
                                        <b>This is Agreement between ASTRASOFT PROJECTS LTD,</b>{' '}
                                        REGISTRATION NUMBER: ΗΕ 409810, duly incorporated and
                                        existing under the laws of the Republic of Cyprus and has an
                                        office located at: Ifigeneias 14, 3036, Limassol, Cyprus
                                        (hereinafter also referred to as the Company), on the one
                                        side, and You (hereinafter also referred to as "the User")
                                        from the other side (hereinafter together referred to as
                                        "Parties", and each individually as "Party") concluded this
                                        Agreement about the following:
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt}>
                                    <span className={styles.vip_onb_txt}>
                                        1. ASTRASOFT PROJECTS LTD operates https://www.sofiadate.com
                                        website (hereinafter "Service", "Website", "Sofia Date").
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt}>
                                    <span className={styles.vip_onb_txt}>
                                        2. This Agreement regulates and establishes the rules of
                                        using the Loyalty Program by the users of Sofia Date.
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt}>
                                    <span className={styles.vip_onb_txt}>
                                        3. The Parties agree that until the User uses the Sofia Date
                                        and executes terms and conditions of this Agreement, he can
                                        be a member of Loyalty Program.
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt}>
                                    <span className={styles.vip_onb_txt}>
                                        4. Loyal Program for user includes:
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt_sub}>
                                    <span className={styles.vip_onb_txt}>
                                        a. Weekly transfer to its members 100 Credits;
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt_sub}>
                                    <span className={styles.vip_onb_txt}>
                                        b. Permanent individual discounts and bonuses on Website
                                        goods and services;
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt_sub}>
                                    <span className={styles.vip_onb_txt}>
                                        c. Transfer 50-100 Credits to the users who gave the full
                                        response to the Website questionnaire.
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt}>
                                    <span className={styles.vip_onb_txt}>
                                        5. To be a member of the loyalty program, the User shall:
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt_sub}>
                                    <span className={styles.vip_onb_txt}>
                                        - In the case of receiving the questionnaire or another
                                        letter from Website - give a feedback during 3 days after
                                        receiving such letter;
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt_sub}>
                                    <span className={styles.vip_onb_txt}>
                                        - Visit the Website at least once every 3 days;
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt_sub}>
                                    <span className={styles.vip_onb_txt}>
                                        - Give full answers to the questions in the questionnaire.
                                    </span>
                                </div>
                                <div className={styles.vip_onb_container_txt}>
                                    <span className={styles.vip_onb_txt}>
                                        6. This Agreement will be terminated and the User ceases to
                                        be a member of the Loyalty Program if the User doesn’t
                                        execute his obligations under this Agreement. In this case,
                                        the Website terminates the Users’ membership in the Loyalty
                                        Program without notification.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.vip_onb_btn} onClick={handleOpen}>
                            Confirm
                        </div>
                        <div className={styles.vip_onb_cross} onClick={handleClose}>
                            <IconCross />
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}
