import { useEffect } from 'react';
import LoginApi from '../../services/api/LoginApi';
import { getUrlParameter } from '../../utils/url-utils';
import { addUrlParameter, injectParam } from '../../services/methods';
import { useHistory } from 'react-router-dom';

export default function useInitCheckIP() {
    const history = useHistory();

    useEffect(() => {
        const tailFromLS = localStorage.getItem('lnd_tail') ?? '';

        if (!tailFromLS?.includes('utm_unik')) {
            new LoginApi().checkIp().then((res) => {
                const newParam = `?utm_unik=${!+res?.count}`;
                const params = injectParam(window?.location?.search?.substring(1), newParam, 0);
                const myUrlArr = params?.split(/[/&]|[/?]/);
                const filteredMyUrl = `?${myUrlArr?.filter((item, index) => myUrlArr?.indexOf(item) === index && item?.length > 0 && item !== `utm_unik=${!!+res?.count}`)?.join('&')}`;

                if (filteredMyUrl?.length) history.replace(filteredMyUrl);
            });
        } else if (
            !window?.location?.search?.includes('utm_unik') ||
            !window?.location?.search?.length
        ) {
            const accessKey = getUrlParameter('accessKey');
            // @ts-expect-error accessKey
            const accessKeyUtm = accessKey?.length ? `&accessKey=${accessKey}` : '';
            // @ts-expect-error addUrlParameter
            addUrlParameter('utm_unik', false);

            if (tailFromLS) history.push(`${tailFromLS}${accessKeyUtm}`);
        }
    }, []);
}
