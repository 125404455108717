import * as CHATS from '../../../constants/chats';
import * as MENU from '../../../constants/menu';
import {
    MESSAGE_CONTENT_TYPE_AUDIO,
    MESSAGE_CONTENT_TYPE_IMAGE,
    MESSAGE_CONTENT_TYPE_VIDEO,
} from '../../../constants/chats';
import { sendErrToSentry } from '../../sentry';
import { IChatHistoryItem } from '../../../store/reducers/currentChat/index.interface';

export function filterToHideChatHistoryByType(item: IChatHistoryItem): boolean {
    if (CHATS.CHAT_MESSAGE_CONTENT.includes(item?.message_type)) {
        if (item?.message_type === MESSAGE_CONTENT_TYPE_VIDEO)
            return !!item?.thumb_link?.length || !!item?.message_content?.length;
        if (item?.message_type === MESSAGE_CONTENT_TYPE_AUDIO) return true;

        return !!item?.message_content?.length;
    }

    return false;
}

export function getChatListType(menu: string) {
    if (menu?.includes(MENU.MENU_FAVORITE)) return MENU.GET_FAVORITE;
    else if (menu?.includes(MENU.MENU_DIALOGUES)) return MENU.GET_DIALOGUES;
    else if (menu?.includes(MENU.MENU_UNANSWERED)) return MENU.GET_UNREAD;
    else if (menu?.includes(MENU.MENU_LIKE)) return MENU.GET_LIKE;
    else return MENU.GET_ALL;
}

export function stopAudio(
    src: string | null = null,
    isPlay = false,
    messageId: number | null = null,
): void {
    try {
        const arrAudios = document.querySelectorAll('audio');

        for (const audio of arrAudios) {
            if (audio?.src === src) {
                const wrapMessBlock = audio.closest(`#mess-${messageId}`);
                const wrapLettBlock = audio.closest(`#lett-${messageId}`);
                const wrapAttachBlock = audio.closest(`#attach-${messageId}`);
                const isCheckMessBlock =
                    wrapMessBlock &&
                    messageId &&
                    wrapMessBlock?.id?.includes(messageId?.toString());
                const isCheckLettBlock =
                    wrapLettBlock &&
                    messageId &&
                    wrapLettBlock?.id?.includes(messageId?.toString());
                const isCheckAttachBlock =
                    wrapAttachBlock &&
                    messageId &&
                    wrapAttachBlock?.id?.includes(messageId?.toString());

                if (isCheckMessBlock || isCheckLettBlock || isCheckAttachBlock) {
                    if (isPlay) audio.play();
                    continue;
                }
            }
            audio.pause();
        }
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}

export function getLastMessageContent(content: string, type: string): string {
    if (type === MESSAGE_CONTENT_TYPE_IMAGE) return 'sent image';
    if (type === MESSAGE_CONTENT_TYPE_VIDEO) return 'sent video';
    if (type === MESSAGE_CONTENT_TYPE_AUDIO) return 'sent audio';

    return content;
}
