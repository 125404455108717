import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import NPS from './NPS';
import NpsApi from '../../../../services/api/v3/NpsApi';
import * as alertAction from '../../../../store/actions/alert';
import { useDispatch } from 'react-redux';
import { deleteUrlParameter } from '../../../../services/methods';

export default function NPSModal() {
    const dispatch = useDispatch();

    const [npsData, setNpsData] = useState({
        pollType: '',
        status: false,
        result: {
            id: 0,
            title: '',
            questionText: '',
            isScale: true,
        },
    });

    useEffect(() => {
        new NpsApi().getNpsPoll().then((res) => {
            if (res?.status) {
                let pollType = '';
                if (
                    res?.result?.isScale &&
                    res?.result?.questionText?.trim() &&
                    res?.result?.title?.trim()
                ) {
                    pollType = 'mix';
                } else if (res?.result?.isScale && res?.result?.title?.trim()) {
                    pollType = 'scale';
                } else if (!res?.result?.isScale && res?.result?.questionText?.trim()) {
                    pollType = 'text';
                }
                setNpsData({ ...res, pollType: pollType });
            } else {
                if (window?.location?.search?.includes('utm_mail=nps')) {
                    dispatch(
                        alertAction.setMessage({
                            message: 'This NPS survey is no longer available.',
                            title: 'Survey Unavailable',
                        }),
                    );
                    deleteUrlParameter('utm_mail');
                }
            }
        });
    }, []);

    return (
        <>
            <Modal
                style={modalStyleProps()}
                isOpen={npsData.status && npsData.pollType}
                ariaHideApp={false}
            >
                <NPS
                    setNpsData={setNpsData}
                    npsData={npsData}
                    npsTitle={npsData?.result?.title}
                    npsQuestion={npsData?.result?.questionText}
                    pollShownId={npsData?.result?.id}
                    closeNPSModal={() => setNpsData({ ...npsData, status: false })}
                />
            </Modal>
        </>
    );
}
