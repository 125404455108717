import Base from './Base';

export default class NotifyAPI extends Base {
    async postCheck() {
        const res = await super.post(`notify/check`);
        return res?.data;
    }

    async postDeleteNotification(user_id: number, chat_uid: string | null) {
        const res = await super.post(`notify/deleteNotification`, { user_id, chat_uid });
        return res?.data;
    }

    async postViewPhotos(external_id: number) {
        try {
            const res = await super.post(`notify/view_photos`, { external_id });
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
