import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import GiftSuccess from './GiftSuccess';
import { closeSuccesGift } from '../../../../store/actions/gifts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../index';

export default function GiftSuccessModal() {
    const dispatch = useDispatch();
    const giftsState = useSelector((state: RootState) => state.gifts);

    const onClose = () => dispatch(closeSuccesGift());

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={giftsState?.successGiftModal}
            onRequestClose={onClose}
            ariaHideApp={false}
        >
            {!!giftsState?.successGiftModal && <GiftSuccess onClose={onClose} />}
        </Modal>
    );
}
