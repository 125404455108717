// REDIRECT
export const NEW_DOMAIN = process.env.REACT_APP_NEW_DOMAIN || 'https://www.sofiadate.com';

// OPEN_PHOTO GALLERY ENABLE PAGES
export const OPEN_PHOTO_GALLERY_ENABLE_PAGES = ['chat', 'search', 'likehistory'];

//COUNTER
export const MENU_CHAT = 'chat';
export const MENU_LETTER = 'letter';
export const MENU_LIKES = 'likes';
export const MENU_LIKE_HER = 'like';
export const MENU_SEARCH = 'search';
export const MENU_CHECKOUT = 'checkout';
export const MENU_ONLINE_NOW = 'online-now';
export const MENU_VIDEO_SHOW = 'videoshow';
export const MENU_MY_PROFILE = 'my-profile';
export const MENU_POSTS = 'posts';

export const MENU_DIALOGUES = 'dialogues';

export const MENU_UNANSWERED = 'unanswered';

export const MENU_FAVORITE = 'favorite';

export const MENU_LIKE = 'likes';

export const MENU_BLOCK = 'block_list';

export const VIDEO_SHOW = 'videoshow';

export const LETTERS_TRASH = 'trash';

//CHAT PAGES
export const CHAT_PAGES = [MENU_CHAT, MENU_DIALOGUES, MENU_UNANSWERED, MENU_FAVORITE, MENU_LIKE];

//GET CHAT LIST
export const GET_UNREAD = 'UNREAD';
export const GET_LIKE = 'LIKE';
export const GET_FAVORITE = 'FAVORITE';
export const GET_DIALOGUES = 'DIALOGUES';
export const GET_ALL = '';

export const DRAFT_FOLDER = 'drafts';
export const TRASH_FOLDER = 'trash';
export const BOOKMARK_FOLDER = 'bookmarks';

// FLAGS
export const OPEN_CHAT = 'open_chat';
export const OPEN_CHAT_FROM_CHAT_LIST = 'OPEN_CHAT_FROM_CHAT_LIST';

// PAGES
export const MENU_PAGE = 'menu';
export const GIFTS_PAGE = 'my-profile/gifts';
export const CREDITS_PAGE = 'my-profile/purchase-history';
export const CONTACT_PAGE = 'my-profile/contact-request';
export const MEETING_PAGE = 'my-profile/meeting-request';
export const PROFILE_PAGE = 'my-profile';
export const EDIT_PROFILE_PAGE = 'my-profile/edit';
export const BLOCK_LIST_PAGE = 'my-profile/block_list';
export const VIDEO_SHOW_PAGE = 'videoshow';
export const MENU_LIKE_HISTORY_PAGE = 'likehistory';
export const MENU_CART_PAGE = 'cart';
export const MENU_PROFILE_WOMAN_PAGE = 'profile-woman';
export const SEND_GIFTS_PAGE = 'gifts';
export const ADD_CREDITS_PAGE = 'credits';
export const SUBSCRIBE_PAGE = 'subscribe';
export const POLICY_PAGE = 'policy';

export const FAQ_PAGE = 'faq';
export const SUPPORT_PAGE = 'support';
export const TUTORIAL_PAGE = 'tutorial';

export const LINK_TERMS = 'terms-and-conditions';
export const LINK_PRIVACY = 'privacy-policy';
export const LINK_COOKIE = 'cookie-policy';
export const LINK_STANDARDS = 'community-standards';
export const LINK_RETURN_REFUND = 'return-and-refund-policy';
export const LINK_SUPPORT = 'support';
export const LINK_AUTOMATIC_TOP_UP = 'automatic-top-up';
export const LINK_ONE_CLICK_UNLIMINT = 'one-click-unlimint';
export const LINK_DISPUTE_MANAGEMENT = 'dispute-management';
export const LINK_COMPLAINT_POLICY = 'complaint-policy';
export const ARRAY_STATIC_PAGE = [
    LINK_TERMS,
    LINK_PRIVACY,
    LINK_COOKIE,
    LINK_RETURN_REFUND,
    LINK_STANDARDS,
    LINK_SUPPORT,
    LINK_AUTOMATIC_TOP_UP,
    LINK_ONE_CLICK_UNLIMINT,
    LINK_DISPUTE_MANAGEMENT,
    LINK_COMPLAINT_POLICY,
];
export const ARRAY_STATIC_PAGE_200 = [LINK_TERMS, LINK_RETURN_REFUND];
export const SETTING_MENU_QUERY = '?search=active';
