import React from 'react';

export default function IconFAQ() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.53846 12C3.53846 7.32682 7.32682 3.53846 12 3.53846C16.6732 3.53846 20.4615 7.32682 20.4615 12C20.4615 16.6732 16.6732 20.4615 12 20.4615C7.32682 20.4615 3.53846 16.6732 3.53846 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM10.7439 12.7774V13.3038H12.6388V12.9916C12.6388 12.861 12.6676 12.7447 12.7253 12.6427C12.787 12.5407 12.8878 12.4346 13.0277 12.3244C13.1717 12.2142 13.3672 12.0816 13.6141 11.9265C13.9638 11.7021 14.2457 11.4736 14.4597 11.241C14.6737 11.0084 14.83 10.7594 14.9288 10.4942C15.0275 10.2289 15.0769 9.93716 15.0769 9.61885C15.0769 8.9496 14.8197 8.41094 14.3054 8.00286C13.791 7.5907 13.0668 7.38462 12.1327 7.38462C11.5114 7.38462 10.9373 7.46419 10.4106 7.62334C9.88391 7.77841 9.38806 7.98857 8.92308 8.25383L9.78104 9.91879C10.1925 9.70251 10.5793 9.53928 10.9414 9.4291C11.3077 9.31892 11.6471 9.26382 11.9599 9.26382C12.2109 9.26382 12.4146 9.31483 12.5709 9.41685C12.7273 9.51479 12.8055 9.64946 12.8055 9.82085C12.8055 9.93104 12.7849 10.0412 12.7438 10.1514C12.7026 10.2575 12.6162 10.3758 12.4845 10.5064C12.3529 10.6329 12.1471 10.7839 11.8673 10.9594C11.5751 11.143 11.3468 11.3267 11.1822 11.5103C11.0176 11.6939 10.9023 11.8878 10.8365 12.0918C10.7748 12.2959 10.7439 12.5244 10.7439 12.7774ZM10.855 14.5342C10.604 14.7097 10.4785 15.0137 10.4785 15.4462C10.4785 15.8584 10.604 16.1563 10.855 16.3399C11.1102 16.5236 11.4188 16.6154 11.7809 16.6154C12.1265 16.6154 12.4249 16.5236 12.6759 16.3399C12.931 16.1563 13.0586 15.8584 13.0586 15.4462C13.0586 15.0137 12.931 14.7097 12.6759 14.5342C12.4249 14.3587 12.1265 14.271 11.7809 14.271C11.4188 14.271 11.1102 14.3587 10.855 14.5342Z"
            />
        </svg>
    );
}
