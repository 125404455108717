import { useDispatch } from 'react-redux';
import { ChangeEvent, MouseEvent, useState } from 'react';
import * as LENGTH from '../../constants/length';
import * as alertAction from '../../store/actions/alert';
import * as affiliats from '../../store/actions/affiliats';
import AffiliatsApi from '../../services/api/AffiliatsApi';
import * as AFFILIATS from '../../constants/affiliats';
import * as ERROR from '../../constants/error';

export default function useInitAffiliats() {
    const dispatch = useDispatch();

    const [modalSupportIsOpen, setModalSupportIsOpen] = useState(false);

    const [contacts, setContacts] = useState('');
    const [firstname, setFirstname] = useState('');
    const [messageContent, setMessageContent] = useState('');

    const [validationContacts, setValidationContacts] = useState(false);
    const [validationName, setValidationName] = useState(false);
    const [validationMessage, setValidationMessage] = useState(false);

    const openAffiliatsModal = () => {
        setModalSupportIsOpen(true);
    };

    const closeAffiliatsModal = () => {
        setModalSupportIsOpen(false);
        setContacts('');
        setFirstname('');
        setMessageContent('');
    };

    const validateContacts = (value: string) => {
        return value?.length >= LENGTH.AFFILIATS_CONTACTS_MAX_LENGTH;
    };

    const validateName = (value: string) => {
        return value?.length >= LENGTH.AFFILIATS_NAME_MAX_LENGTH;
    };

    const validateMessage = (value: string) => {
        return value?.length >= LENGTH.AFFILIATS_MSG_MAX_LENGTH;
    };
    const handleContactsInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setContacts(value);
        if (!validateContacts(value)) setValidationContacts(true);
        else setValidationContacts(false);
    };
    const handleNameInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFirstname(value);
        if (!validateName(value)) setValidationName(true);
        else setValidationName(false);
    };

    const handleMessageInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        setMessageContent(value);
        if (!validateMessage(value)) return setValidationMessage(true);
        else setValidationMessage(false);
    };

    const onSubmitAffiliats = (event: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        event?.preventDefault();

        if (!contacts?.length || !firstname?.length || !messageContent?.length) {
            return dispatch(alertAction.setMessage({ message: 'All fields are required.' }));
        }
        if (!validateContacts(contacts)) return setValidationContacts(true);
        if (!validateName(firstname)) return setValidationName(true);
        if (!validateMessage(messageContent)) return setValidationMessage(true);

        dispatch(affiliats.setAffiliatsStatus(true));
        new AffiliatsApi()
            .postAffiliatsRequest({
                contacts: contacts,
                name: firstname,
                message_content: messageContent,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(
                        alertAction.setMessage({ message: 'Thank you for your information!' }),
                    );
                } else {
                    dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
                }
            });
        closeAffiliatsModal();
    };

    return {
        onSubmit: onSubmitAffiliats,
        validationContacts,
        validationName,
        validationMessage,
        handleContactsInput,
        handleNameInput,
        handleMessageInput,
        messageContent,
        contacts,
        openAffiliatsModal,
        closeAffiliatsModal,
        firstname,
        type: AFFILIATS.MODAL_TYPE_AFFILIATS,
        modalSupportIsOpen,
    };
}
