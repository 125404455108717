import React, { useEffect, useState } from 'react';
import HeaderProfile from '../ProfileHeader';
import moment from 'moment';
import Modal from 'react-modal';
import '../../../styles/man-profile/main.scss';
import '../../../styles/man-profile/media.scss';
import '../../../styles/man-profile/profile.scss';
import ProfileApi from '../../../services/api/ProfileApi';
import '../../../styles/pruchase-history/main.scss';
import '../../../styles/pruchase-history/purchase.scss';
import '../../../styles/pruchase-history/contactRequest.scss';
import '../../../styles/pruchase-history/media.scss';
import '../../../styles/columns/column.scss';
import ImgContent from '../../common/Modals/ContentAttachModal/ImgContent';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import NotifyEmailConfirm from '../../Banner';
import EmptyResult from '../../common/EmptyResult';
import Loader from '../../common/LoaderGif';
import { sendErrToSentry } from '../../../services/sentry';
import { IProfileGiftCurrentItem, IProfileGiftOrderItem } from './index.interface';
import avatar_placeholder from '../../../img/placeholder.gif';

export default function ProfileGifts() {
    const [orders, setOrders] = useState<IProfileGiftOrderItem[]>([]);
    const [status, setStatus] = useState<number | null>(null);
    const [currItem, setCurrItem] = useState<IProfileGiftCurrentItem>();
    const [modalContentIsOpen, setModalContentIsOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const showPopup = (link: string) => {
        setCurrItem({
            link: link,
            attachment_payed: 1,
            attachment_free: 0,
        });
        isOpen();
    };

    useEffect(() => {
        try {
            new ProfileApi()
                .getProfileInfo()
                .then((res) => {
                    if (res?.orders?.length) {
                        setOrders(res?.orders);
                    }
                })
                .finally(() => setIsLoaded(true));
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }, []);

    const statuses = {
        All: null,
        'In progress': 0,
        Approved: 1,
        Declined: 2,
    };

    const closeContentModal = () => {
        setModalContentIsOpen(false);
    };

    const isOpen = () => {
        setModalContentIsOpen(true);
    };

    const onChangeStatus = (value: number | null) => {
        setStatus(value);
    };

    let statusText = '';
    const renderStatus = (id: number) => {
        switch (+id) {
            case 0:
                statusText = 'In progress';
                break;
            case 1:
                statusText = 'Approved';
                break;
            case 2:
                statusText = 'Declined';
                break;
            default:
                statusText = 'In progress';
                break;
        }
        return statusText;
    };

    const renderStatusMessage = (item: { status: number; updated_at: string }) => {
        switch (+item?.status) {
            case 1:
                return (
                    <React.Fragment>
                        Gift delivered <span>{moment(item?.updated_at).format('MMM, D YYYY')}</span>
                    </React.Fragment>
                );
            case 2:
                return (
                    <React.Fragment>
                        Gift declined <span>{moment(item?.updated_at).format('MMM, D YYYY')}</span>
                    </React.Fragment>
                );
            default:
                return 'Time of delivery 10 days';
        }
    };

    const ordersFiltered = orders.filter((item: { status: number; updated_at: string }) => {
        return status == null ? true : +item?.status === +status;
    });
    return (
        <React.Fragment>
            <div className="column-3 profile_man">
                <NotifyEmailConfirm />
                <HeaderProfile />
                <main>
                    {isLoaded ? (
                        <div className="c3_profile_gifts_wrap">
                            <div className="c3_profile_gifts_wrap2">
                                <div className="c3_profile_filters">
                                    <div className="c3_profile_filters_title">Filter</div>
                                    {Object.keys(statuses).map((item, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    onChangeStatus(
                                                        statuses[item as keyof typeof statuses] as
                                                            | number
                                                            | null,
                                                    )
                                                }
                                                key={index + 'filter'}
                                                className={
                                                    statuses[item as keyof typeof statuses] ===
                                                    status
                                                        ? 'active c3_profile_filters_item'
                                                        : 'c3_profile_filters_item'
                                                }
                                            >
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="all-humens">
                                    {ordersFiltered.length ? (
                                        ordersFiltered.map((item) => {
                                            const product = item?.product;
                                            const woman_profile = item?.woman_profile;
                                            const order_report = item?.order_report;
                                            let tmpStatus = '';

                                            switch (item?.status) {
                                                case 0:
                                                    tmpStatus = 'in progress';
                                                    break;
                                                case 1:
                                                    tmpStatus = 'approved';
                                                    break;
                                                case 2:
                                                    tmpStatus = 'decline';
                                                    break;
                                                default:
                                                    tmpStatus = 'in progress';
                                                    break;
                                            }

                                            const classStatus = tmpStatus;
                                            const avatar =
                                                woman_profile?.avatar || avatar_placeholder;

                                            return (
                                                <div
                                                    key={item?.id + 'gifts_item'}
                                                    className="c3_profile_gifts_item"
                                                >
                                                    <div className="c3_profile_gifts_item_top">
                                                        <span className="c3_profile_gifts_item_id">
                                                            Gift ID {product.external_id}
                                                        </span>
                                                        <img
                                                            src={product.image}
                                                            alt=""
                                                            className="c3_profile_gifts_item_gift_photo"
                                                        />
                                                        <div className="c3_profile_gifts_item_main_info">
                                                            <p className="c3_profile_gifts_item_gift_name">
                                                                {product.name}
                                                            </p>
                                                            <p className="c3_profile_gifts_item_gift_price">
                                                                Price
                                                                <span>
                                                                    {parseFloat(
                                                                        item?.price,
                                                                    ).toFixed(2)}{' '}
                                                                    credits
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="c3_profile_gifts_item_to">
                                                            <p className="c3_profile_gifts_item_to_text">
                                                                To:
                                                            </p>
                                                            <div className="c3_profile_gifts_item_to_photo">
                                                                <img
                                                                    src={avatar}
                                                                    alt="user avatar"
                                                                />
                                                            </div>
                                                            <div className="c3_profile_gifts_item_to_right">
                                                                <span className="c3_profile_gifts_item_to_id">
                                                                    ID{' '}
                                                                    {woman_profile?.public_external_id ||
                                                                        ''}
                                                                </span>
                                                                <p className="c3_profile_gifts_item_to_name">
                                                                    {woman_profile.name},{' '}
                                                                    {woman_profile.age}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {+item.status !== 0 && (
                                                            <div className="c3_profile_gifts_item_girls_photos">
                                                                {+item.status === 2 ? (
                                                                    <React.Fragment>
                                                                        <span className="c3_profile_gifts_item_girls_photos_text">
                                                                            Decline reason
                                                                        </span>
                                                                        <span>
                                                                            {item.decline_text}
                                                                        </span>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                {order_report?.length > 0 && (
                                                                    <p className="c3_profile_gifts_item_girls_photos_text">
                                                                        Member’s photos with gift
                                                                    </p>
                                                                )}
                                                                <ul className="c3_profile_gifts_item_girls_photos_list">
                                                                    {order_report?.map((item) => (
                                                                        <li
                                                                            key={
                                                                                item?.id +
                                                                                item?.order_id +
                                                                                'photo'
                                                                            }
                                                                            onClick={() =>
                                                                                showPopup(
                                                                                    item.image,
                                                                                )
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={item.image}
                                                                                alt=""
                                                                            />
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {!isNaN(item?.status) && (
                                                        <div className="c3_profile_gifts_item_bottom">
                                                            {
                                                                <span className="c3_profile_gifts_item_gift_date date_sent">
                                                                    Order make{' '}
                                                                    <span>
                                                                        {moment(
                                                                            item.created_at,
                                                                        ).format('MMM, D YYYY')}
                                                                    </span>
                                                                </span>
                                                            }
                                                            <div
                                                                className={`c3_profile_gifts_item_gift_status ${classStatus}`}
                                                            >
                                                                status:{' '}
                                                                <span>
                                                                    {renderStatus(item.status)}
                                                                </span>
                                                            </div>
                                                            <span className="c3_profile_gifts_item_gift_date date_delivered">
                                                                {renderStatusMessage(item)}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <EmptyResult type={'gifts'} />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </main>
            </div>
            {!!currItem && (
                <Modal
                    style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                    isOpen={modalContentIsOpen}
                    onRequestClose={closeContentModal}
                    ariaHideApp={false}
                >
                    <ImgContent item={currItem} closeModal={closeContentModal} />
                </Modal>
            )}
        </React.Fragment>
    );
}
