import ProfileApi from '../../../services/api/ProfileApi';
import * as userAction from '../../../store/actions/user';
import { checkFileAndShowAlert } from '../../../services/methods';
import * as ATTACH from '../../../constants/attach';
import * as alertAction from '../../../store/actions/alert';
import awsUploadFile from '../../../services/aws/uploadFile';
import * as AWS from '../../../constants/aws';
import * as loaderAction from '../../../store/actions/loader';
import { useDispatch, useSelector } from 'react-redux';
import { sendErrToSentry } from '../../../services/sentry';
import { IGetMainInfo } from '../../../models/profile/ManProfile.interface';
import { RootState } from '../../../index';
import React, { ChangeEvent } from 'react';
import { IUseHandleProfileAvatar } from './index.interface';

export default function useHandleProfileAvatar({ mainInfo, setMainInfo }: IUseHandleProfileAvatar) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const updateUserAvatarLocal = (photoLink: string) => {
        return new ProfileApi().updateUserAvatar(photoLink).then(async (res) => {
            if (res) {
                dispatch(userAction.editUserPhotoLink(photoLink));
                setMainInfo({
                    ...(mainInfo as IGetMainInfo),
                    ...{
                        photo_link: photoLink,
                    },
                });
            }
        });
    };

    const uploadUserAvatar = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (event?.target?.files?.[0] && userState?.info?.external_id) {
            if (
                !checkFileAndShowAlert(
                    event?.target?.files?.[0],
                    ATTACH.TYPE_IMAGE,
                    (message: string, title: string) => {
                        dispatch(alertAction.setMessage({ message, title }));
                    },
                )
            )
                return;

            awsUploadUserAvatar({
                file: event?.target?.files?.[0],
                fileName: 'avatar',
                external_id: +userState?.info?.external_id,
            });
        }
    };

    const awsUploadUserAvatar = ({
        file,
        fileName,
        external_id,
    }: {
        file: File;
        fileName: string;
        external_id: number;
    }) => {
        dispatch(loaderAction.setActiveGifLoader(true));

        try {
            awsUploadFile(file, fileName, external_id?.toString(), AWS.MEN_IMAGES)
                .then(async (res) => {
                    if (res?.success && res?.data?.link) {
                        await updateUserAvatarLocal(res?.data?.link);
                    } else {
                        if (res?.message || res?.error) {
                            dispatch(
                                alertAction.setMessage({ message: res?.message || res?.error }),
                            );
                        }
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error as Error);
            dispatch(loaderAction.setActiveGifLoader(false));
        }
    };

    const onDeleteUserAvatar = async () => {
        dispatch(loaderAction.setActiveGifLoader(true));

        await new ProfileApi().deleteUserAvatar().then(async (res) => {
            if (res?.status) {
                dispatch(userAction.deleteUserPhotoLink());
                setMainInfo({
                    ...(mainInfo as IGetMainInfo),
                    ...{
                        photo_link: '',
                    },
                });
            }
        });

        dispatch(loaderAction.setActiveGifLoader(false));
    };

    const openModalDeleteUserAvatar = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event?.stopPropagation();

        dispatch(
            alertAction.setMessage({
                title: 'Delete your profile photo?',
                message: 'Press the “Delete photo” button to delete\n your profile photo',
                btnName: 'Delete photo',
                successCallback: onDeleteUserAvatar,
            }),
        );
    };

    return {
        uploadUserAvatar,
        openModalDeleteUserAvatar,
        updateUserAvatarLocal,
    };
}
