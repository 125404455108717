import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ConfirmStatusPhone from './ConfirmStatusPhone';
import { IConfirmStatusPhoneModalProps } from './index.interface';

export default function ConfirmStatusPhoneModal({
    isActiveConfirmPhoneModal,
    closeConfirmPhoneModal,
    phoneData,
    changePhoneData,
    submitPhoneData,
    isPhoneCodeCanceled,
    setIsPhoneCodeCanceled,
    switchPhoneNumber,
}: IConfirmStatusPhoneModalProps) {
    return (
        <Modal style={modalStyleProps()} isOpen={isActiveConfirmPhoneModal} ariaHideApp={false}>
            <ConfirmStatusPhone
                closeModal={closeConfirmPhoneModal}
                phoneData={phoneData}
                changePhoneData={changePhoneData}
                submitPhoneData={submitPhoneData}
                isPhoneCodeCanceled={isPhoneCodeCanceled}
                setIsPhoneCodeCanceled={setIsPhoneCodeCanceled}
                switchPhoneNumber={switchPhoneNumber}
            />
        </Modal>
    );
}
