export enum ProfileActionTypes {
    CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA',
    GET_PROFILE_DATA = 'GET_PROFILE_DATA',
    SET_SUBSCRIPTION_LIST = 'SET_SUBSCRIPTION_LIST',
    UPDATE_SUBSCRIBED_STATUS = 'UPDATE_SUBSCRIBED_STATUS',
    UPDATE_DOCS_STATE = 'UPDATE_DOCS_STATE',
    UPDATE_IS_SHOW_STATUS = 'UPDATE_IS_SHOW_STATUS',
    REFRESH_LIKED_POSTS = 'REFRESH_LIKED_POSTS',
}

export enum DocsTypes {
    SELFIE_VERIFY = 'SELFIE_VERIFY',
    SELFIE = 'SELFIE',
    VALID_ID = 'VALID_ID',
}
