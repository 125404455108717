import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import SITE from '../../../../../../config/site';
import Logotypes from '../../../../../../img/logotypes';
import { RootState } from '../../../../../../index';
import { isHolidayActive } from '../../../../../../constants/holiday';
import tut_step_3_girl_1 from '../../../../../../img/tutorial/tut_step_3_girl_1.png';
import tut_step_3_man_1 from '../../../../../../img/tutorial/tut_step_3_man_1.png';
import tut_step_3_girl_2 from '../../../../../../img/tutorial/tut_step_3_girl_2.png';
import tut_step_3_man_2 from '../../../../../../img/tutorial/tut_step_3_man_2.png';
import tut_step_3_girl_3 from '../../../../../../img/tutorial/tut_step_3_girl_3.png';
import tut_step_3_man_3 from '../../../../../../img/tutorial/tut_step_3_man_3.png';
import IconArrowDropdown from '../../../../../Icons/IconArrowDropdown';
import IconFilter from '../../../../../Icons/IconFilter';
import IconOnline from '../../../../../Icons/IconOnline';
import IconSearch from '../../../../../Icons/IconSearch';
import IconGallery from '../../../../../Icons/IconGallery';
import IconChat from '../../../../../Icons/IconChat';
import IconLikeHerHeartStroke from '../../../../../Icons/IconLikeHerHeartStroke';
import IconAlarm from '../../../../../Icons/IconAlarm';

export default function Step3() {
    const userState = useSelector((state: RootState) => state.user);
    const activeHoliday = isHolidayActive();

    const LogoSize = () => {
        switch (SITE?.ID) {
            case 1:
                return '';
            case 2:
                return '';
            case 5:
                return '';
            default:
                return 'step_filter_mob_logo_large';
        }
    };

    return (
        <>
            <div className={styles.tutorial_step_illustration}>
                <div className={styles.step_filter}>
                    <div className={styles.step_filter_header}>
                        <div className={styles.step_filter_age_range}>
                            <p className={styles.step_filter_age_text}>Age range</p>
                            <div className={styles.step_filter_inputs}>
                                <div className={styles.step_filter_item}>
                                    <p className={styles.step_filter_item_text}>From</p>
                                    <div className={styles.step_filter_item_icon}>
                                        <IconArrowDropdown />
                                    </div>
                                </div>
                                <div className={styles.step_filter_item}>
                                    <p className={styles.step_filter_item_text}>To</p>
                                    <div className={styles.step_filter_item_icon}>
                                        <IconArrowDropdown />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.step_filter_item_detailed}>
                            <div className={styles.step_filter_item_detailed_icon}>
                                <IconFilter />
                            </div>
                            <p className={styles.step_filter_item_detailed_text}>Detailed filter</p>
                            <div className={styles.step_filter_item_icon}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={styles.step_filter_online}>
                            <p className={styles.step_filter_online_text}>online</p>
                            <div className={styles.step_filter_online_icon}>
                                <IconOnline />
                            </div>
                        </div>
                        <div className={styles.step_filter_search}>
                            <div className={styles.step_filter_text}>Search ID or na</div>
                            <div className={styles.step_filter_search_icon}>
                                <IconSearch />
                            </div>
                        </div>
                    </div>
                    <div className={styles.step_filter_mob_header}>
                        <div className={styles.step_filter_item_mob}>
                            <div className={styles.step_filter_item_mob_icon}>
                                <IconFilter />
                            </div>
                            <p className={styles.step_filter_item_mob_text}>Filters</p>
                        </div>
                        <div
                            className={`${styles.step_filter_mob_logo_wrap} ${styles[`${LogoSize()}`]}`}
                        >
                            {activeHoliday &&
                            Logotypes.images[`logo_${activeHoliday?.name}_light.svg`] ? (
                                <img
                                    src={Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]}
                                    className={styles.step_filter_mob_logo_icon}
                                    alt={SITE.copyrightsText}
                                    width="78"
                                    height="37"
                                />
                            ) : (
                                <img
                                    src={Logotypes.images['logo_light.svg']}
                                    className={styles.step_filter_mob_logo_icon}
                                    alt={SITE.copyrightsText}
                                    width="78"
                                    height="37"
                                />
                            )}
                        </div>

                        <div className={styles.step_filter_mob_bell}>
                            <div className={styles.step_filter_mob_bell_icon}>
                                <IconAlarm />
                            </div>
                        </div>
                    </div>
                    <div className={styles.step_filter_candidates}>
                        <div className={styles.step_filter_candidates_card}>
                            <div className={styles.step_filter_candidates_profile}>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_3_girl_1}
                                        alt="Candidate"
                                        width="147"
                                        height="211"
                                        className={styles.step_filter_candidates_photo}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_3_man_1}
                                        alt="Candidate"
                                        width="147"
                                        height="211"
                                        className={styles.step_filter_candidates_photo}
                                    />
                                )}
                                <div className={styles.step_filter_candidates_more_photos}>
                                    <div className={styles.step_filter_candidates_more_photos_icon}>
                                        <IconGallery />
                                    </div>
                                    <p className={styles.step_filter_candidates_more_photos_text}>
                                        All photo
                                    </p>
                                </div>
                            </div>
                            <div className={styles.step_filter_candidates_online}>
                                <p className={styles.step_filter_candidates_online_text}>
                                    I’m online now
                                </p>
                            </div>
                            <div className={styles.step_filter_candidates_info}>
                                <div className={styles.step_filter_candidates_info_wrap}>
                                    <div className={styles.step_filter_candidates_info_buttons}>
                                        <div
                                            className={
                                                styles.step_filter_candidates_info_button_chat
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.step_filter_candidates_info_button_icon
                                                }
                                            >
                                                <IconChat />
                                            </div>
                                            <p
                                                className={
                                                    styles.step_filter_candidates_info_button_chat_text
                                                }
                                            >
                                                Chat now
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                styles.step_filter_candidates_info_button_like
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.step_filter_candidates_info_button_icon
                                                }
                                            >
                                                <IconLikeHerHeartStroke />
                                            </div>
                                            <p
                                                className={
                                                    styles.step_filter_candidates_info_button_like_text
                                                }
                                            >
                                                Like
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.step_filter_candidates_card}>
                            <div className={styles.step_filter_candidates_profile}>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_3_girl_2}
                                        alt="Candidate"
                                        width="147"
                                        height="211"
                                        className={styles.step_filter_candidates_photo}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_3_man_2}
                                        alt="Candidate"
                                        width="147"
                                        height="211"
                                        className={styles.step_filter_candidates_photo}
                                    />
                                )}
                                <div className={styles.step_filter_candidates_more_photos}>
                                    <div className={styles.step_filter_candidates_more_photos_icon}>
                                        <IconGallery />
                                    </div>
                                    <p className={styles.step_filter_candidates_more_photos_text}>
                                        All photo
                                    </p>
                                </div>
                            </div>
                            <div className={styles.step_filter_candidates_online}>
                                <p className={styles.step_filter_candidates_online_text}>
                                    I’m online now
                                </p>
                            </div>
                            <div className={styles.step_filter_candidates_info}>
                                <div className={styles.step_filter_candidates_info_wrap}>
                                    <div className={styles.step_filter_candidates_info_buttons}>
                                        <div
                                            className={
                                                styles.step_filter_candidates_info_button_chat
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.step_filter_candidates_info_button_icon
                                                }
                                            >
                                                <IconChat />
                                            </div>
                                            <p
                                                className={
                                                    styles.step_filter_candidates_info_button_chat_text
                                                }
                                            >
                                                Chat now
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                styles.step_filter_candidates_info_button_like
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.step_filter_candidates_info_button_icon
                                                }
                                            >
                                                <IconLikeHerHeartStroke />
                                            </div>
                                            <p
                                                className={
                                                    styles.step_filter_candidates_info_button_like_text
                                                }
                                            >
                                                Like
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.step_filter_candidates_card} ${styles.mobile}`}>
                            <div className={styles.step_filter_candidates_profile}>
                                {!Number(userState?.info?.gender) ? (
                                    <img
                                        src={tut_step_3_girl_3}
                                        alt="Candidate"
                                        width="147"
                                        height="211"
                                        className={styles.step_filter_candidates_photo}
                                    />
                                ) : (
                                    <img
                                        src={tut_step_3_man_3}
                                        alt="Candidate"
                                        width="147"
                                        height="211"
                                        className={styles.step_filter_candidates_photo}
                                    />
                                )}
                                <div className={styles.step_filter_candidates_more_photos}>
                                    <div className={styles.step_filter_candidates_more_photos_icon}>
                                        <IconGallery />
                                    </div>
                                    <p className={styles.step_filter_candidates_more_photos_text}>
                                        All photo
                                    </p>
                                </div>
                            </div>
                            <div className={styles.step_filter_candidates_online}>
                                <p className={styles.step_filter_candidates_online_text}>
                                    I’m online now
                                </p>
                            </div>
                            <div className={styles.step_filter_candidates_info}>
                                <div className={styles.step_filter_candidates_info_wrap}>
                                    <div className={styles.step_filter_candidates_info_buttons}>
                                        <div
                                            className={
                                                styles.step_filter_candidates_info_button_chat
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.step_filter_candidates_info_button_icon
                                                }
                                            >
                                                <IconChat />
                                            </div>
                                            <p
                                                className={
                                                    styles.step_filter_candidates_info_button_chat_text
                                                }
                                            >
                                                Chat now
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                styles.step_filter_candidates_info_button_like
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.step_filter_candidates_info_button_icon
                                                }
                                            >
                                                <IconLikeHerHeartStroke />
                                            </div>
                                            <p
                                                className={
                                                    styles.step_filter_candidates_info_button_like_text
                                                }
                                            >
                                                Like
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step_filter_footer}>
                        <p className={styles.step_filter_footer_text}>
                            Use filters to choose your preferences
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.tutorial_body}>
                <h2 className={styles.tutorial_step_title}>
                    Learn everything about a member using the profile
                </h2>
                <p className={styles.tutorial_step_text}>
                    There is all the info about a member: photos, videos, the purpose of staying on
                    our site and what partner the member is looking for
                </p>
            </div>
        </>
    );
}
