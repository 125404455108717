import React from 'react';
import './styles.scss';

export default function LoaderMain() {
    return (
        <div className="loaderDiv">
            <div className="loaderContainerDiv">
                <div className="loaderBoxDiv" />
                <div className="loaderBoxDiv" />
                <div className="loaderBoxDiv" />
                <div className="loaderBoxDiv" />
                <div className="loaderBoxDiv" />
            </div>
        </div>
    );
}
