import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import NoCreditCards from './NoCreditCards';
import CreditCardsList from './CreditCardsList';
import AddNewCreditCard from './AddNewCreditCard';
import '../../../styles/manage-credit-card/style.scss';
import { IPaymentManageCreditCardProps } from './index.interface';

export default function PaymentManageCreditCard({
    isActiveManageCreditCard,
    isActiveNoCreditCardModal,
    isActiveCreditCardListModal,
    isActiveAddCreditCardModal,
    closeNoCreditCardModal,
    createToken,
    closeCreditCardListModal,
    closeAddCreditCardModal,
    fetchTokenList,
    openRemoveCreditCardAlert,
    selectDefaultCard,
    frame,
    frameData,
}: IPaymentManageCreditCardProps) {
    return isActiveManageCreditCard ? (
        <>
            {isActiveNoCreditCardModal && (
                <Modal
                    style={modalStyleProps()}
                    isOpen={isActiveNoCreditCardModal}
                    onRequestClose={closeNoCreditCardModal}
                    ariaHideApp={false}
                >
                    <NoCreditCards
                        createToken={createToken}
                        closeNoCreditCardModal={closeNoCreditCardModal}
                    />
                </Modal>
            )}
            {isActiveCreditCardListModal && (
                <Modal
                    style={modalStyleProps()}
                    isOpen={isActiveCreditCardListModal}
                    onRequestClose={closeCreditCardListModal}
                    ariaHideApp={false}
                >
                    <CreditCardsList
                        fetchTokenList={fetchTokenList}
                        openRemoveCreditCardAlert={openRemoveCreditCardAlert}
                        createToken={createToken}
                        selectDefaultCard={selectDefaultCard}
                        closeCreditCardListModal={closeCreditCardListModal}
                    />
                </Modal>
            )}
            {isActiveAddCreditCardModal && (
                <Modal
                    style={modalStyleProps()}
                    isOpen={isActiveAddCreditCardModal}
                    onRequestClose={closeAddCreditCardModal}
                    ariaHideApp={false}
                >
                    <AddNewCreditCard
                        frame={frame}
                        frameData={frameData}
                        closeAddCreditCardModal={closeAddCreditCardModal}
                    />
                </Modal>
            )}
        </>
    ) : (
        <div />
    );
}
