import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProfileApi from '../../../services/api/ProfileApi';
import ManProfile from '../../../models/profile/ManProfile';
import * as USER from '../../../constants/user';
import { RootState } from '../../../index';
import {
    IGetAboutMySelf,
    IGetGirlsAppearance,
    IGetMainInfo,
    IGetPhotos,
    IManProfileInitData,
    IManProfileReferencies,
} from '../../../models/profile/ManProfile.interface';

export default function useGetManProfile() {
    const userState = useSelector((state: RootState) => state.user);

    const [mainInfo, setMainInfo] = useState<IGetMainInfo | null>(null);
    const [aboutMySelf, setAboutMySelf] = useState<IGetAboutMySelf | null>(null);
    const [girlsAppearance, setGirlsAppearance] = useState<IGetGirlsAppearance | null>(null);
    const [photos, setPhotos] = useState<IGetPhotos | null>(null);

    const isShowContacts =
        !!userState?.userRoles?.heightRole ||
        !!userState?.userRoles?.maybeHeight ||
        +(userState?.info?.payment_total || 0) >= USER.PAYMENT_TOTAL_TO_SHOW_CONTACTS;

    /**
     * Save Profile information to local states
     * @param fetchProfile (Object)
     * @param referencies (Object)
     * @return {Promise<void>}
     */
    const initProfileInfoByModel = async (
        fetchProfile: IManProfileInitData,
        referencies: IManProfileReferencies,
    ) => {
        const modelManProfile = new ManProfile(fetchProfile, referencies);

        setMainInfo(modelManProfile.getMainInfo());
        setAboutMySelf(modelManProfile.getAboutMySelf());
        setGirlsAppearance(modelManProfile.getGirlsAppearance());
        setPhotos(modelManProfile.getPhotos());
    };

    /**
     * UseEffect to getting Profile information from api
     */
    useEffect(() => {
        (async function () {
            const fetchProfile = await new ProfileApi().getProfileInfo();
            await initProfileInfoByModel(
                {
                    ...{
                        man: {
                            ...fetchProfile.user_content,
                            ...fetchProfile.user_detail,
                            ...fetchProfile.user_reference,
                        },
                    },
                    ...{ hobbies: fetchProfile.user_hobby },
                    ...{ woman_hobbies: fetchProfile.user_hobby_match },
                    ...{ woman: fetchProfile.user_match },
                    ...{ template: fetchProfile },
                    ...{ content: fetchProfile.user_content },
                },
                fetchProfile.referencies_list,
            );
        })();
    }, []);

    return {
        initProfileInfoByModel,

        mainInfo: { ...(mainInfo as IGetMainInfo), ...{ email: userState?.info?.email ?? '' } },
        setMainInfo,

        aboutMySelf,
        setAboutMySelf,

        girlsAppearance,
        setGirlsAppearance,

        photos,
        setPhotos,
        isShowContacts,
    };
}
