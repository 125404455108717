import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import useLike from '../likes/useLike';
import { useEffect, useRef, useState } from 'react';
import * as SLIDERS from '../../constants/slider';
import MailboxApi from '../../services/api/MailboxApi';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import * as modalsAction from '../../store/actions/modals';
import * as MENU from '../../constants/menu';
import * as womanAction from '../../store/actions/woman';
import WomanApi from '../../services/api/v3/WomanApi';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import SearchApi from '../../services/api/v3/SearchApi';
import { RootState } from '../../index';
import Slider from 'react-slick';
import { SliderTypeEnum } from '../../constants/slider';
import { IWomanGalleryImage } from '../../store/actions/modals/index.interface';

export default function useInitGirlsSlider() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const like = useLike();

    const userState = useSelector((state: RootState) => state.user);
    const modalsState = useSelector((state: RootState) => state.modals);
    const menuState = useSelector((state: RootState) => state.menu);
    const womanState = useSelector((state: RootState) => state.woman);

    const sliderRef = useRef<Slider | null>(null);

    const [sliderWomanList, setSliderWomanList] = useState<any[]>([]);

    const sliderType = modalsState?.womanProfileModal?.externalID
        ? SLIDERS?.SLIDER_TYPE_PROFILE_WOMAN
        : menuState?.main;
    const sliderObj = SLIDERS.ARRAY_SLIDER[sliderType as SliderTypeEnum];

    const getSimilarWomanList = () => {
        const isMan = !+(userState?.info?.gender || 0);

        if (isMan)
            new WomanApi()
                .getSimilarWoman()
                .then(
                    (res) => res?.status && dispatch(womanAction.saveSimilarWomanList(res?.result)),
                );
        else
            new WomanApi()
                .getSimilarMan()
                .then(
                    (res) => res?.status && dispatch(womanAction.saveSimilarWomanList(res?.result)),
                );
    };

    const getSimilarProfileWomanList = (womanExternalID: number) => {
        const isMan = !+(userState?.info?.gender || 0);

        if (isMan)
            new WomanApi()
                .getSimilarWomanProfile(womanExternalID)
                .then(
                    (res) => res?.status && dispatch(womanAction.saveSimilarWomanList(res?.result)),
                );
        else dispatch(womanAction.saveSimilarWomanList([]));
    };
    const setLike = async (womanExternalID: number) => {
        const woman = sliderWomanList?.find((item: any) => item?.externalID === womanExternalID);
        if (woman?.liked) return;
        const isLike = await like(womanExternalID);

        if (isLike?.status) {
            setSliderWomanList(
                sliderWomanList?.map((item) =>
                    item?.externalID === womanExternalID
                        ? {
                              ...item,
                              liked: 1,
                          }
                        : item,
                ),
            );
        }
    };

    const openChat = (womanExternalID: number) => {
        if (!userState?.info?.external_id) return;

        new MailboxApi()
            .createChatWithProfile(userState?.info?.external_id, womanExternalID)
            .then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    dispatch(modalsAction.closeWomanProfileModal());
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
    };

    const onNextSlide = () => {
        if (sliderRef?.current) sliderRef?.current?.slickNext();
    };

    const onPrevSlide = () => {
        if (sliderRef?.current) sliderRef?.current?.slickPrev();
    };

    const openProfileModal = (womanExternalID: number) => {
        dispatch(modalsAction.closeWomanProfileModal());
        dispatch(modalsAction.openWomanProfileModal(womanExternalID));

        if (window.innerWidth < 1000) {
            const topModalDIV = document.querySelector('.c3_woman_profile_main_block_name');
            if (topModalDIV) topModalDIV.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    };

    const openGalleryModal = (externalID: number, galleryImg: IWomanGalleryImage[]) => {
        dispatch(modalsAction.openWomanGalleryModal(externalID, galleryImg));
    };

    const openLetter = async (womanExternalID: number) => {
        if (!userState?.info?.external_id) return;

        const womanInfo = await new SearchApi()
            .getWoman([womanExternalID])
            .then((res) => (res?.status && res?.response?.length ? res?.response[0] : null));
        if (!+womanInfo?.external_id) return false;

        if (+womanInfo?.is_blocked) {
            return dispatch(
                alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
            );
        }

        dispatch(
            currentChatMailboxAction.addMailboxChatInfo({
                ...womanInfo,
                sender_image: womanInfo?.avatar || womanInfo?.search_img,
                sender_name: womanInfo?.name,
                sender_age: womanInfo?.age,
                sender_city: womanInfo?.city_name,
                sender_id: womanInfo?.external_id,
                female_id: womanInfo?.external_id,
                male_id: userState?.info?.external_id,
                openFromChat: true,
                sender_gifts: womanInfo?.gifts,
                sender_search_img: womanInfo?.link,
            }),
        );

        dispatch(
            currentChatMailboxAction.getMailboxChatHistory({
                userId: userState?.info?.external_id,
                girlId: womanInfo?.public_external_id,
                page: 1,
            }),
        );

        transition.transitionWithHistoryPush('letter', `/letter/`);
    };

    useEffect(() => {
        if (womanState.similarWomanList.length) {
            setSliderWomanList(womanState.similarWomanList);
        }
    }, [womanState.similarWomanList]);

    return {
        getSimilarWomanList,
        getSimilarProfileWomanList,
        womanState,
        sliderWomanList,
        sliderObj,
        sliderRef,
        openChat,
        setLike,
        openProfileModal,
        openGalleryModal,
        openLetter,
        onPrevSlide,
        onNextSlide,
    };
}
