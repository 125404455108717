import React from 'react';
import styles from './styles.module.scss';
import OnboardingDropdown from '../OnboardingDropdown/OnboardingDropdown';
import OnboardingInput from '../OnboardingInput/OnboardingInput';
import { IManProfileReferenceHeightItem as IHeightItem } from '../../../models/profile/ManProfile.interface';

interface IOnboardingHeightWeight {
    title: string;
    activeStep: number;
    maxStep: number;
    value1: string;
    value2: number;
    setValue1: (value: IHeightItem[keyof IHeightItem]) => void;
    setValue2: (value: number) => void;
    title1: string;
    title2: string;
    dropdownArray1: IHeightItem[];
    index1: keyof IHeightItem;
    onClick: () => void;
    skipStep: () => void;
    displayKey: keyof IHeightItem;
}

const OnboardingHeightWeight = ({
    title,
    activeStep,
    maxStep,
    value1,
    value2,
    setValue1,
    setValue2,
    title1,
    title2,
    dropdownArray1,
    index1,
    onClick,
    skipStep,
    displayKey,
}: IOnboardingHeightWeight) => {
    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>Select the correct answer</p>

            <div className={styles.list}>
                <OnboardingDropdown
                    value={value1}
                    setValue={setValue1}
                    title={title1}
                    isCaret={false}
                    dropdownArray={dropdownArray1}
                    index={index1}
                    displayKey={displayKey}
                />
                <OnboardingInput value={value2} setValue={setValue2} title={title2} />
            </div>

            <div className={styles.button_row}>
                <button className={styles.button} onClick={onClick}>
                    Continue
                </button>
                <button className={`${styles.button} ${styles.skip}`} onClick={skipStep}>
                    Skip
                </button>
            </div>
        </div>
    );
};

export default OnboardingHeightWeight;
