import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatAction from '../../store/actions/currentChat';
import ChatsApi from '../../services/api/ChatsApi';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import * as chatsAction from '../../store/actions/chats';
import { initCancelTokenSource } from '../../services/methods';
import useActiveButton from '../activeBtn/useActiveButton';
import useCheckSpecialPaidAction from '../user/useCheckSpecialPaidAction';
import useSendActionSync from '../frontSync/useSendActionSync';
import * as userAction from '../../store/actions/user';
import * as FRONT_SYNC from '../../constants/frontSync';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useHandleRedUser from '../user/useHandleRedUser';
import { getLastMessageContent } from '../../services/methods/chat';
import { RootState } from '../../index';
import * as MENU from '../../constants/menu';

interface IWinkData {
    id: number;
    helloText: string | null;
}

export default function useSendWink() {
    const dispatch = useDispatch();
    const activeButton = useActiveButton();
    const checkSpecialPaidAction = useCheckSpecialPaidAction();
    const sendActionSync = useSendActionSync();
    const handleRedUser = useHandleRedUser();

    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const [canceledToken, setCanceledToken] = useState(null);

    const fetchSetWink = ({ id, helloText = null }: IWinkData) => {
        if (!userState?.info?.external_id) return;
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        return new ChatsApi()
            .postMessage(
                userState?.info?.external_id,
                id,
                helloText ? helloText : `${userState.info.name} winked you`,
                'SENT_WINK',
                '',
                source?.token,
            )
            .then((res) => {
                if (res?.canceled) return false;
                if (res?.status) {
                    if (checkSpecialPaidAction()) {
                        dispatch(userAction.setBalance());
                    }
                    if (!res?.response?.message_object) {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                        return false;
                    }
                    if (menuState?.main === MENU.MENU_CHAT) {
                        dispatch(
                            currentChatAction.addLastMessageToMainChat(
                                res?.response?.message_object,
                            ),
                        );
                    }
                    dispatch(
                        chatsAction.updateMessageInChatList(
                            res?.response?.message_object?.chat_uid,
                            getLastMessageContent(
                                res?.response?.message_object?.message_content,
                                res?.response?.message_object?.message_type,
                            ),
                            res?.response?.message_object?.message_type,
                            res?.response?.message_object?.date_created,
                            res?.response?.message_object?.sender_external_id,
                        ),
                    );
                    sendActionSync.postUserAction(+id, FRONT_SYNC.ACTION_WINK);
                    handleRedUser.submitLog(
                        RED_USER_LOG_TYPES.MESSAGE_FROM,
                        JSON.stringify({
                            womanExternalID: id,
                            action: 'SENT_WINK',
                            content: helloText ? helloText : `${userState?.info?.name} winked you`,
                        }),
                    );
                    return true;
                } else {
                    if (res?.block)
                        dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    else if (res?.message)
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message,
                                title: res?.title,
                                isPaymentBtn: res?.activePayment,
                            }),
                        );
                    else
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.ENOUGH_CREDITS_CHAT,
                            }),
                        );
                    activeButton.onDefaultBlockBtn();
                    return false;
                }
            });
    };

    return async ({ id, helloText = null }: IWinkData) => {
        if (checkSpecialPaidAction()) {
            return await fetchSetWink({ id, helloText });
        } else {
            return fetchSetWink({ id, helloText });
        }
    };
}
