import React from 'react';

export default function IconFavourites() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2C10.2256 2 10.4315 2.13413 10.5314 2.34599L12.6825 6.91109L17.4931 7.64765C17.7162 7.68182 17.9015 7.8457 17.9711 8.07042C18.0406 8.29514 17.9824 8.54174 17.8209 8.70656L14.3404 12.2576L15.1618 17.2743C15.2 17.5072 15.1086 17.7426 14.9261 17.8815C14.7435 18.0203 14.5016 18.0386 14.3019 17.9287L10 15.5588L5.69805 17.9287C5.4984 18.0386 5.25645 18.0203 5.07395 17.8815C4.89144 17.7426 4.80004 17.5072 4.83817 17.2743L5.65955 12.2576L2.17914 8.70656C2.01759 8.54174 1.95935 8.29514 2.02891 8.07042C2.09847 7.8457 2.28376 7.68182 2.5069 7.64765L7.31748 6.91109L9.46862 2.34599C9.56845 2.13413 9.77445 2 10 2ZM10 4.0232L8.2425 7.75294C8.15625 7.93597 7.98962 8.06289 7.79682 8.09241L3.86574 8.6943L6.70977 11.5961C6.84955 11.7387 6.91335 11.9442 6.88037 12.1457L6.2093 16.2443L9.72418 14.308C9.89685 14.2129 10.1032 14.2129 10.2758 14.308L13.7907 16.2443L13.1196 12.1457C13.0866 11.9442 13.1504 11.7387 13.2902 11.5961L16.1343 8.6943L12.2032 8.09241C12.0104 8.06289 11.8437 7.93597 11.7575 7.75294L10 4.0232Z"
            />
        </svg>
    );
}
