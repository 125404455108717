import React, { useState } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import VideoContentAttachModal from '../Modals/ContentAttachModal/VideoContent';
import ContentAttachModal from '../Modals/ContentAttachModal/ImgContent';
import { cutLengthString } from '../../../services/methods';
import * as LENGTH from '../../../constants/length';
import LetterAudioPlayer from '../LetterAudioPlayer';
import * as AWS from '../../../constants/aws';
import { IProfileGiftCurrentItem } from '../../Profile/ProfileGifts/index.interface';
import { IAttachDataToLetterProps } from './index.interface';
import { stopAudio } from '../../../services/methods/chat';

export default function AttachDataToLetter({
    item,
    index,
    deleteAttach,
}: IAttachDataToLetterProps) {
    const [modalImgIsOpen, setModalImgIsOpen] = useState<boolean>(false);
    const [modalVideoIsOpen, setModalVideoIsOpen] = useState<boolean>(false);
    const [currItem, setCurrItem] = useState<IProfileGiftCurrentItem>({
        link: '',
        attachment_payed: 0,
        attachment_free: 0,
    });

    const openContentImgModal = () => {
        setCurrItem({
            link: item.link,
            attachment_payed: 1,
        });
        setModalImgIsOpen(true);
    };
    const closeContentImgModal = () => {
        setCurrItem({
            link: '',
            attachment_payed: 0,
            attachment_free: 0,
        });
        setModalImgIsOpen(false);
    };

    const openContentVideoModal = () => {
        setCurrItem({
            link: item.link,
            attachment_payed: 1,
        });
        setModalVideoIsOpen(true);
    };
    const closeContentVideoModal = () => {
        setCurrItem({
            link: '',
            attachment_payed: 0,
            attachment_free: 0,
        });
        setModalVideoIsOpen(false);
    };

    const makeCDN = (image: string, type = 'IMAGE') => {
        switch (type) {
            case 'VIDEO':
                return image?.replace(
                    AWS.AWS_CLOUDFRONT_VIDEO?.origin,
                    AWS.AWS_CLOUDFRONT_VIDEO?.cdn,
                );
            case 'AUDIO':
                return image?.replace(
                    AWS.AWS_CLOUDFRONT_AUDIO?.origin,
                    AWS.AWS_CLOUDFRONT_AUDIO?.cdn,
                );
            case 'IMAGE':
            default:
                return image?.replace(
                    AWS.AWS_CLOUDFRONT_IMAGE?.origin,
                    AWS.AWS_CLOUDFRONT_IMAGE?.cdn,
                );
        }
    };

    const onViewAudio = () => {
        const idAudio = +`${item?.id}${index}`;
        stopAudio(item?.link, true, idAudio);
    };

    return (
        <React.Fragment>
            {(function () {
                const thumbList: { [key: string]: string } = {};
                switch (item.message_type) {
                    case 'SENT_IMAGE':
                        {
                            AWS.AWS_THUMBS.forEach((size) => {
                                let thumb: string[] | string = item.link.split('/');
                                thumb[thumb?.length - 1] =
                                    `w-${size.width}-h-${size.height}-${thumb[thumb.length - 1]}`;
                                thumb = thumb.join('/');
                                thumbList[size.name] = thumb;
                            });
                        }
                        return (
                            <React.Fragment>
                                <div
                                    className={`letters_attach_files_item file_photo`}
                                    id={`attach-${index}`}
                                >
                                    <img
                                        src={thumbList.small}
                                        alt=""
                                        onClick={openContentImgModal}
                                    />
                                    <div
                                        className="c3_write_msg_textareas_files_close"
                                        data-id={index}
                                        onClick={deleteAttach}
                                    />
                                </div>
                                <Modal
                                    style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                                    isOpen={modalImgIsOpen}
                                    onRequestClose={closeContentImgModal}
                                    ariaHideApp={false}
                                >
                                    <ContentAttachModal
                                        item={currItem}
                                        closeModal={closeContentImgModal}
                                    />
                                </Modal>
                            </React.Fragment>
                        );
                    case 'SENT_VIDEO':
                        return (
                            <React.Fragment>
                                <div
                                    className={`letters_attach_files_item file_video`}
                                    id={`attach-${index}`}
                                >
                                    <div onClick={openContentVideoModal}>
                                        {item?.thumb_link?.length ? (
                                            <img
                                                className="popup_full_img"
                                                src={item?.thumb_link}
                                                alt=""
                                            />
                                        ) : (
                                            <video
                                                width="96"
                                                height="56"
                                                id={'video-' + item?.id}
                                                controls={false}
                                                preload="metadata"
                                            >
                                                <source
                                                    src={`${makeCDN(item?.link, 'VIDEO')}#t=0.6`}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        )}
                                    </div>
                                    <div
                                        className="c3_write_msg_textareas_files_close"
                                        data-id={index}
                                        onClick={deleteAttach}
                                    />
                                </div>
                                <Modal
                                    style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                                    isOpen={modalVideoIsOpen}
                                    onRequestClose={closeContentVideoModal}
                                    ariaHideApp={false}
                                >
                                    <VideoContentAttachModal
                                        item={currItem}
                                        onViewVideo={undefined}
                                        closeModal={closeContentVideoModal}
                                    />
                                </Modal>
                            </React.Fragment>
                        );
                    default:
                        return (
                            <div
                                className={`letters_attach_files_item file_audio`}
                                id={`attach-${item?.id}${index}`}
                            >
                                <div className="letters_attach_files_item_audio_control">
                                    <div className="letters_attach_files_item_play_title">
                                        <img src="../../../img/letter-audio-notes.svg" alt="" />
                                        <span>
                                            {cutLengthString(
                                                item.title,
                                                LENGTH.ATTACH_LETTER_AUDIO_TITLE,
                                            )}
                                        </span>
                                    </div>
                                    <LetterAudioPlayer src={item.link} onViewMedia={onViewAudio} />
                                </div>
                                <div
                                    className="c3_write_msg_textareas_files_close"
                                    data-id={index}
                                    onClick={deleteAttach}
                                />
                            </div>
                        );
                }
            })()}
        </React.Fragment>
    );
}
