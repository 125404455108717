import React from 'react';

export default function IconStarStroke() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_382_214296)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0.941406C10.1903 0.941406 10.3641 1.04944 10.4484 1.22009L13.1131 6.61863L19.0723 7.48965C19.2606 7.51717 19.417 7.64918 19.4756 7.83018C19.5343 8.01118 19.4852 8.20981 19.3489 8.34258L15.0373 12.5421L16.0548 18.4749C16.087 18.6625 16.0099 18.8521 15.8559 18.9639C15.7019 19.0758 15.4977 19.0905 15.3293 19.0019L10 16.1993L4.67074 19.0019C4.50228 19.0905 4.29814 19.0758 4.14415 18.9639C3.99016 18.8521 3.91304 18.6625 3.94521 18.4749L4.96275 12.5421L0.651148 8.34258C0.514839 8.20981 0.465703 8.01118 0.524392 7.83018C0.58308 7.64918 0.73942 7.51717 0.9277 7.48965L6.88688 6.61863L9.55166 1.22009C9.6359 1.04944 9.8097 0.941406 10 0.941406ZM10 2.57103L7.66737 7.29671C7.5946 7.44413 7.45401 7.54636 7.29133 7.57014L2.07422 8.3327L5.84888 12.0092C5.96682 12.1241 6.02065 12.2897 5.99282 12.4519L5.10211 17.6452L9.76729 15.1919C9.91298 15.1152 10.087 15.1152 10.2327 15.1919L14.8979 17.6452L14.0072 12.4519C13.9794 12.2897 14.0332 12.1241 14.1511 12.0092L17.9258 8.3327L12.7087 7.57014C12.546 7.54636 12.4054 7.44413 12.3327 7.29671L10 2.57103Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_382_214296">
                    <rect width="20" height="20" />
                </clipPath>
            </defs>
        </svg>
    );
}
