export const CHAT_ON_PAGE_CHAT_LIST = 20;

export const CHAT_MEDIA_LIST_QUANTITY = 15;

// export const MESSAGE_TYPE_SENT_VIEW_PHOTOS = 'SENT_VIEW_PHOTOS';
export const MESSAGE_CONTENT_TYPE_LETTER = 'SENT_LETTER';
export const MESSAGE_CONTENT_TYPE_TEXT = 'SENT_TEXT';
export const MESSAGE_CONTENT_TYPE_IMAGE = 'SENT_IMAGE';
export const MESSAGE_CONTENT_TYPE_VIDEO = 'SENT_VIDEO';
export const MESSAGE_CONTENT_TYPE_ASK_VIDEO = 'ASK_VIDEO';
export const MESSAGE_CONTENT_TYPE_AUDIO = 'SENT_AUDIO';
export const MESSAGE_CONTENT_TYPE_WINK = 'SENT_WINK';
export const MESSAGE_CONTENT_TYPE_STICKER = 'SENT_STICKER';
export const MESSAGE_CONTENT_TYPE_LIKE = 'SENT_LIKE';
export const MESSAGE_CONTENT_TYPE_VIEW = 'SENT_VIEW';
export const MESSAGE_CONTENT_TYPE_REPLY = 'REPLY_POST';
export const MESSAGE_CONTENT_TYPE_ONLINE = 'SENT_ONLINE';
export const MESSAGE_CONTENT_TYPE_VIEW_GALLERY = 'SENT_VIEW_GALLERY';
export const MESSAGE_CONTENT_TYPE_VIEW_PHOTOS = 'SENT_VIEW_PHOTOS';
export const MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT = 'SENT_VIRTUAL_GIFT';

export const CHAT_MESSAGE_CONTENT = [
    MESSAGE_CONTENT_TYPE_TEXT,
    MESSAGE_CONTENT_TYPE_IMAGE,
    MESSAGE_CONTENT_TYPE_VIDEO,
    MESSAGE_CONTENT_TYPE_AUDIO,
    MESSAGE_CONTENT_TYPE_STICKER,
    MESSAGE_CONTENT_TYPE_WINK,
    MESSAGE_CONTENT_TYPE_LIKE,
    MESSAGE_CONTENT_TYPE_VIEW,
    MESSAGE_CONTENT_TYPE_ASK_VIDEO,
    MESSAGE_CONTENT_TYPE_VIEW_PHOTOS,
    MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT,
];
