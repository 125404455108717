import React from 'react';
import { formatDate } from '../../../../../services/time';
import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';
import IconCross from '../../../../Icons/IconCross';
import IconProfile from '../../../../Icons/IconProfile';
import { IJoinedNotifyProps } from '../JoinedNotify/index.interface';
import { RootState } from '../../../../../index';
import placeholder from '../../../../../img/placeholder.gif';
import IconProfileChat from '../../../../Icons/IconProfileChat';

export default function MatchNotify({ item, deleteNotify, openWomanProfile }: IJoinedNotifyProps) {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <>
            <li className={`${styles.notify_item_match} ${styles.online}`}>
                <p className={styles.notify_item_match_title}>
                    We’ve found the ideal match for you
                </p>
                <div className={styles.notify_item_match_row}>
                    {userState?.info?.photo_link ? (
                        <img
                            src={userState?.info?.photo_link}
                            alt="user avatar"
                            className={styles.notify_item_match_man_img}
                        />
                    ) : (
                        <div
                            className={`${styles.notify_item_match_image} ${styles.notify_item_empty_avatar}`}
                        >
                            <IconProfileChat />
                        </div>
                    )}
                    {/*</div>*/}
                    <div className={styles.notify_item_match_woman_img_wrap}>
                        <img
                            src={item?.avatar || placeholder}
                            alt="user avatar"
                            className={styles.notify_item_match_woman_img}
                        />
                    </div>
                    <p
                        className={styles.notify_item_match_woman_name}
                    >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                </div>
                <button
                    type="button"
                    className={styles.notify_item_activity_btn}
                    onClick={() => openWomanProfile(item, item?.sender_external_id)}
                >
                    <IconProfile />
                    <span>View profile</span>
                </button>
                <button
                    type="button"
                    className={styles.notify_item_clear_mask}
                    onClick={() => deleteNotify(item)}
                >
                    <IconCross />
                </button>
                <p className={styles.notify_item_time}>{formatDate(item?.date_created)}</p>
            </li>
        </>
    );
}
