import * as searchAction from '../../store/actions/search';
import { useDispatch, useSelector } from 'react-redux';
import { UIEvent, useEffect } from 'react';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import SITE from '../../config/site';
import { LOOKING_GENDER_LIST } from '../../constants/search';
import { RootState } from '../../index';
import { ISearchFilter } from '../../store/reducers/search/index.interface';

export default function useSetSearchList() {
    const searchState = useSelector((state: RootState) => state.search);
    const referenciesState = useSelector((state: RootState) => state.referencies);
    const userState = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();

    const lookingGender = LOOKING_GENDER_LIST.find((el) => el?.sexFilter === userState?.info?.sex);

    const isWomanProfile = userState?.info?.gender === 1; // GT-6255
    const searchFilterByType = handleActiveBlocks?.isSearchV3
        ? {
              ...searchState?.filter,
              gender: lookingGender?.id ?? 'ALL',
              // ...isWomanProfile ? {gender: 1} : {}, // Default first load gender filter (for woman)
              sexFilter: lookingGender?.sexFilter,
              ...(isWomanProfile ? { online: false } : {}), // Constant online filter (for woman)
              ...(SITE.ID === 23 ? { images: 1, online: false } : {}),
          }
        : {
              ...searchState?.filter,
              gender: userState?.info?.gender,
              ...(isWomanProfile ? { online: false } : {}),
          };

    const setDefaultFilterValue = () => dispatch(searchAction.setSearchListFilter());

    const handleCountryFilter = (value: string) => {
        const currentCountry = referenciesState?.list?.country_list?.find(
            (el) => el?.country_name?.toLowerCase() === value?.toLowerCase(),
        );

        dispatch(
            searchAction.setSearchListFilter({
                ...(searchFilterByType as ISearchFilter),
                country: currentCountry?.id || 0,
            }),
        );
    };

    const handleCityFilter = (value: string) => {
        const currentCity = searchState?.filter?.cityList?.find(
            (el: any) => el?.city_name?.toLowerCase() === value?.toLowerCase(),
        );

        dispatch(
            searchAction.setSearchListFilter({
                ...searchState?.filter,
                // @ts-expect-error city_name
                city: currentCity?.city_name || '',
            }),
        );
    };

    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;

        const getNextPage =
            target?.scrollTop > target?.scrollHeight - target?.offsetHeight - 460 * 2;
        if (getNextPage && !searchState?.isLoadingPage && !searchState?.isLoadedAll) {
            dispatch(searchAction.setSearchListPage(+searchState?.page + 1));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(searchAction.initSearchState());
        };
    }, []);

    useEffect(() => {
        dispatch(searchAction.setSearchListPage(1));
        dispatch(searchAction.getSearchList(1, searchFilterByType as ISearchFilter));
    }, [
        searchState?.filter?.from,
        searchState?.filter?.to,
        searchState?.filter?.online,
        searchState?.filter?.country,
        searchState?.filter?.country_name,
        searchState?.filter?.city,
        searchState?.filter?.city_name,
        searchState?.filter?.cityList?.length,
        searchState?.filter?.id,
        searchState?.filter?.detail,
        searchState?.filter?.sexFilter,
    ]);

    useEffect(() => {
        if (searchState?.page > 1) {
            dispatch(searchAction.getSearchList(searchState?.page, searchFilterByType));
        }
    }, [searchState?.page]);

    return {
        handleScroll,
        handleCountryFilter,
        handleCityFilter,
        setDefaultFilterValue,
    };
}
