import Base from './Base';
import { ISaveOnboardingData } from './OnboardingApi.interface';

export default class OnboardingApi extends Base {
    async getOnboarding() {
        try {
            const res = await super.get(`v3/user-profile/onboarding`);
            return res?.data;
        } catch {
            return null;
        }
    }

    async saveOnboarding(data: ISaveOnboardingData) {
        try {
            const res = await super.post(`v3/user-profile/onboarding/save`, data);
            return res?.data;
        } catch {
            return null;
        }
    }
}
