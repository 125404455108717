import React, { useEffect } from 'react';
import useTransition from '../../hooks/transition/useTransition';
import { Params } from '@sentry/react/types/types';
import { match } from 'react-router';

export default function PromocodePage({ match }: { match: match<Params> }) {
    const transition = useTransition();

    useEffect(() => {
        if (match?.params?.promocode_hash) {
            localStorage.setItem('promocode', match?.params?.promocode_hash);
            transition.transitionWithHistoryPush('', `/${window?.location?.search}`);
        }
    }, [match.params]);

    return <></>;
}
