import React from 'react';
import LoaderGif from '../common/LoaderGif';
import Questions from './Questions';
import Topics from './Topics';
import styles from './styles.module.scss';
import useInitSupport from '../../hooks/support/useInitSupport';
import useInitFaq from '../../hooks/faq/useInitFaq';
import SupportModal from '../common/Modals/SupportModal';
import Banner from '../Banner';
import { Redirect } from 'react-router-dom';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import IconFAQHelp from '../Icons/IconFAQHelp';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function FAQ() {
    const faqState = useSelector((state: RootState) => state.faq);

    const initSupport = useInitSupport();
    const initFaq = useInitFaq();
    const handleActiveBlocks = useHandleActiveBlocks();

    return (
        <>
            <div className={`column-3 ${styles.faq_column_3}`}>
                {handleActiveBlocks.isTwitterUser ? (
                    <Redirect to="/like" />
                ) : (
                    <>
                        <Banner />

                        <div className={styles.faq_header}>
                            <div className={styles.header_middle}>
                                <IconFAQHelp />
                                <span>Help & FAQs</span>
                            </div>
                        </div>

                        {!faqState?.faqData?.length ? (
                            <LoaderGif />
                        ) : (
                            <div className={styles.faq_content_row}>
                                <Topics
                                    changeTopic={initFaq.changeTopic}
                                    isMobileQuestionsOpened={initFaq.isMobileQuestionsOpened}
                                />
                                <Questions
                                    questionsList={initFaq.questionsList}
                                    activeTopicTitle={initFaq.activeTopicTitle}
                                    isMobileQuestionsOpened={initFaq.isMobileQuestionsOpened}
                                    backToTopics={initFaq.backToTopics}
                                    changeActiveQuestion={initFaq.changeActiveQuestion}
                                    clickAnswerText={initFaq.clickAnswerText}
                                    fixMessageContent={initFaq.fixMessageContent}
                                />
                            </div>
                        )}

                        <div className={styles.faq_bottom_text}>
                            If you have not found the answer to your question, please contact{' '}
                            <span onClick={initSupport.openSupportModal}>support</span>
                        </div>
                    </>
                )}
            </div>

            <SupportModal
                modalIsActiveSupport={initSupport.modalIsActiveSupport}
                formDataSupport={initSupport.formDataSupport}
                deleteUploadedFileSupport={initSupport.deleteUploadedFileSupport}
                handleInputSupport={initSupport.handleInputSupport}
                closeSupportModal={initSupport.closeSupportModal}
                onSubmitSupport={initSupport.onSubmitSupport}
            />
        </>
    );
}
