import React from 'react';

export default function IconMailbox() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1221 2.64502C12.5363 2.64502 12.8721 2.98081 12.8721 3.39502V13.6204L14.5067 11.9791C14.799 11.6856 15.2738 11.6846 15.5673 11.9769C15.8608 12.2692 15.8618 12.744 15.5695 13.0375L12.6535 15.9655C12.5128 16.1069 12.3215 16.1863 12.1221 16.1863C11.9226 16.1863 11.7314 16.1069 11.5907 15.9655L8.67467 13.0375C8.38238 12.744 8.38335 12.2692 8.67685 11.9769C8.97034 11.6846 9.44521 11.6856 9.7375 11.9791L11.3721 13.6204V3.39502C11.3721 2.98081 11.7079 2.64502 12.1221 2.64502ZM17.6881 8.87793C19.3087 8.87793 20.6221 10.191 20.6221 11.8129V16.6969C20.6221 18.3127 19.3129 19.6219 17.6971 19.6219H6.55707C4.93629 19.6219 3.62207 18.3077 3.62207 16.6869V11.8019C3.62207 10.1874 4.93202 8.87793 6.54707 8.87793H7.48907C7.90328 8.87793 8.23907 8.54214 8.23907 8.12793C8.23907 7.71372 7.90328 7.37793 7.48907 7.37793H6.54707C4.10412 7.37793 2.12207 9.35845 2.12207 11.8019V16.6869C2.12207 19.1361 4.10786 21.1219 6.55707 21.1219H17.6971C20.1413 21.1219 22.1221 19.1411 22.1221 16.6969V11.8129C22.1221 9.36285 20.1374 7.37793 17.6881 7.37793H16.7551C16.3409 7.37793 16.0051 7.71372 16.0051 8.12793C16.0051 8.54214 16.3409 8.87793 16.7551 8.87793H17.6881Z"
            />
        </svg>
    );
}
