import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../index';
import useInitSmileMobBlock from '../chat/helpers/useInitSmileMobBlock';
import useHandleSmileBlock from '../smile/useHandleSmileBlock';
import useHandleRedUser from '../user/useHandleRedUser';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IDraftsFullInfo, IMailAttachment } from '../../services/api/MailboxApi.interface';
import Styles from '../../components/common/Modals/ComposeWriteLetterModal/MessageForm/MessageForm.module.css';
import { checkUrl, convertPrice, hideStringStar } from '../../services/methods';
import * as PRICES from '../../constants/prices';
import * as filesAction from '../../store/actions/files';
import * as LENGTH from '../../constants/length';
import * as MIRROR from '../../constants/mirror';
import SITE from '../../config/site';
import * as REGEXP from '../../constants/regExp';
import * as alertAction from '../../store/actions/alert';
import * as loaderAction from '../../store/actions/loader';
import MailboxApi from '../../services/api/MailboxApi';
import * as mailboxAction from '../../store/actions/mailbox';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as userAction from '../../store/actions/user';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import * as ALERT from '../../constants/alert';
import * as TIMER from '../../constants/timer';

export default function useHandleComposeLetterModal(
    closeWriteLetterModal: (param: boolean) => void,
) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const menuState = useSelector((state: RootState) => state.menu);
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);
    const mailboxState = useSelector((state: RootState) => state.mailbox);

    const initSmileMobBlock = useInitSmileMobBlock();
    const handleSmileBlock = useHandleSmileBlock(initSmileMobBlock.setIsOpenMobSmileBlock);
    const handleRedUser = useHandleRedUser();

    const [attachModalIsOpen, setAttachModalIsOpen] = useState<boolean>(false);
    const [inputData, setInputData] = useState<string>('');
    const [attachData, setAttachData] = useState<IMailAttachment[]>([]);
    const [inputDataDraft, setInputDataDraft] = useState<string>('');
    const [attachDataDraft, setAttachDataDraft] = useState<IMailAttachment[]>([]);
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [sendButtonClass, setSendButtonClass] = useState<string>(Styles.enable_send_button);
    const [draftIds, setDraftIds] = useState<number[]>([]);
    const [isChangedDraft, setIsChangedDraft] = useState<boolean>(false);

    const debounceTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const isEmptyDraft = !+inputDataDraft?.trim()?.length && !+attachDataDraft?.length;
    const currentStateDraftId = currentChatMailboxState?.draftId
        ? [currentChatMailboxState?.draftId]
        : [];
    const draftIdsArr = draftIds.length ? draftIds : currentStateDraftId;
    const isDraftSection = menuState.letters === 'drafts';
    const checkedRecipientsID: number[] = mailboxState?.checkedRecipients?.map(
        (item) => item?.external_id,
    );
    const isNewMail = userState?.info?.newMail;
    const priceTitle = isNewMail
        ? `First letter costs ${convertPrice(+(userState.prices?.[PRICES.SENT_MAIL_FIRST] || 0))} credits, next letters will be cost ${convertPrice(+(userState.prices?.[PRICES.SENT_MAIL_SECOND] || 0))} credits`
        : `The cost of sending a letter is ${convertPrice(+(userState.prices?.[PRICES.SENT_MAIL] || 0))} credits`;

    const openAttachModal = () => {
        setAttachModalIsOpen(true);
        dispatch(filesAction.getAllImages());
        dispatch(filesAction.getAllVideos());
        dispatch(filesAction.getAllAudios());
    };
    const closeAttachModal = () => {
        setAttachModalIsOpen(false);
    };

    const addEmoji = (emoji: any) => {
        if (LENGTH.LETTER_MAX_LENGTH >= (inputData + emoji.native).length) {
            setInputData(inputData + emoji.native);
        }
    };
    const submitLetter = async () => {
        if (inputData?.trim()?.length === 0) return;

        setSendButtonClass(Styles.disable_send_button); // disable button for prevent double-click
        const _inputData = MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID)
            ? inputData
            : hideStringStar(inputData, REGEXP.NUMBER_IN_STRING);

        if (!MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID) && checkUrl(_inputData)) {
            return dispatch(alertAction.setMessage({ message: 'Invalid message' }));
        }

        dispatch(loaderAction.setActiveGifLoader(true)); // make visual loader
        setDisabledButton(true);

        const resPostMail =
            userState?.info &&
            (await new MailboxApi().postMail({
                userId: userState?.info?.external_id,
                recipients: checkedRecipientsID,
                messageContent: _inputData,
                messageType: 'SENT_TEXT',
                attachments: attachData.filter((item: any) => !item?.hidden),
            }));

        setDisabledButton(false);
        if (resPostMail?.status) {
            if (draftIdsArr?.length) {
                const res =
                    userState?.info &&
                    (await new MailboxApi().postDeleteDraft({
                        userId: userState?.info?.external_id,
                        draftIds: draftIdsArr,
                    }));

                if (res?.status && res?.result) {
                    dispatch(mailboxAction.deleteDraftMailList(draftIdsArr));
                }
                dispatch(currentChatMailboxAction.removeDraftID());
            }

            closeWriteLetterModal(false);
            setInputData('');
            setAttachData([]);
            dispatch(mailboxAction.removeAllRecipient());
            dispatch(loaderAction.setActiveGifLoader(false)); // remove visual loader
            setSendButtonClass(Styles.enable_send_button); // enable send button
            dispatch(
                alertAction.setMessage({
                    title: ' Mail was sent!',
                    message: 'Your Mail was sent successfully!',
                }),
            );
            dispatch(userAction.setBalance());
            handleRedUser.submitLog(
                RED_USER_LOG_TYPES.LETTER_FROM,
                JSON.stringify({
                    womanExternalID: currentChatMailboxState?.info?.female_id,
                    action: 'SENT_LETTER',
                    content: _inputData,
                }),
            );

            if (!isDraftSection && userState?.info) {
                dispatch(
                    mailboxAction.getMails({
                        userId: userState?.info?.external_id,
                        folder: menuState?.letters,
                        girlFilter: letterFilterState?.girlFilter,
                        girlId: letterFilterState?.girlId,
                        letterFilter: letterFilterState?.lettersFilter,
                        onlineFilter: letterFilterState?.onlineFilter,
                        page: mailboxState?.mailListPage,
                    }),
                );
            } else {
                userState?.info &&
                    dispatch(
                        mailboxAction.getMailDrafts({
                            userId: [userState?.info?.external_id],
                            page: mailboxState?.mailListPage,
                        }),
                    );
            }
        } else if (resPostMail?.block) {
            dispatch(alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }));
        } else {
            if (resPostMail?.message)
                dispatch(
                    alertAction.setMessage({
                        message: resPostMail?.message,
                        title: resPostMail?.title,
                    }),
                );
            else
                dispatch(
                    alertAction.setCustomAlertType({ alertType: ALERT.ENOUGH_CREDITS_LETTER }),
                );
        }

        dispatch(loaderAction.setActiveGifLoader(false)); // remove visual loader
        setSendButtonClass(Styles.enable_send_button); // enable send button
    };

    const handleChangeInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setInputData(value);
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }
        debounceTimerRef.current = setTimeout(() => {
            setInputDataDraft(value);
            if (attachData) setAttachDataDraft(attachData);
            setIsChangedDraft(true);
        }, TIMER.LETTER_UPDATE_DRAFT);
    };

    const sendMedia = (
        e: React.MouseEvent<HTMLDivElement>,
        messageType: 'SENT_IMAGE' | 'SENT_VIDEO' | 'SENT_AUDIO',
    ) => {
        const checkedList =
            e?.currentTarget?.parentNode?.parentNode?.querySelectorAll('input:checked');
        const arr = [];

        if (checkedList && checkedList?.length > 0) {
            for (let i = 0; i < checkedList.length; i++) {
                const element = checkedList[i] as HTMLInputElement;
                // const title =
                //     messageType === 'SENT_IMAGE' ? element?.name : element?.dataset?.filename;
                const title = element?.dataset?.filename;

                arr.push({
                    title: title ?? '',
                    link: element?.dataset?.link ?? '',
                    message_type: messageType,
                    id: +element?.name,
                });
            }
        } else {
            dispatch(alertAction.setMessage({ message: 'File not selected' }));
        }

        if (
            +attachData.filter((item: any) => !item.hidden).length + +arr.length <=
            LENGTH.MAX_ATTACHMENTS_TO_LETTER
        ) {
            setAttachData(attachData.concat(arr));
            setAttachDataDraft(attachData.concat(arr));
            if (inputData) setInputDataDraft(inputData);
            setIsChangedDraft(true);
            closeAttachModal();
        } else {
            dispatch(alertAction.setCustomAlertType({ alertType: ALERT.LETTER_FILES_LIMIT }));
        }
    };

    const deleteAttach = (e: React.MouseEvent<HTMLDivElement>) => {
        const newAttach = attachData?.filter(
            (el, index) => e.currentTarget?.dataset.id && index !== +e.currentTarget?.dataset?.id,
        );
        setAttachData(newAttach);
    };

    const scrollTopPopupWriteLetter = () => {
        if (
            window.innerWidth < 1000 &&
            initSmileMobBlock.isOpenMobSmileBlock &&
            document.querySelector('.mob-smiles')
        ) {
            document
                .querySelector('.mob-smiles')
                ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    };

    const onSubmitMessageEnter = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const { value } = event.target as HTMLTextAreaElement;
        if (event.key === 'Enter') {
            const lines = value.split('\n');

            //Enter + Shift
            if (event.shiftKey) {
                if (lines?.length < LENGTH.MESSAGE_MAX_NUMBER_LINE) return false;
                else return event.preventDefault();
            }

            event.preventDefault();
            await submitLetter();
        }
    };

    useEffect(() => {
        (async function () {
            if (!isChangedDraft) return;
            // When we open a letter form modal in drafts (Updated drafts). Else we add a new draft if compose new letter modal
            if (draftIdsArr?.length) {
                if (isDraftSection) {
                    const res =
                        userState?.info &&
                        (await new MailboxApi().postUpdateDraft({
                            userId: userState?.info?.external_id,
                            draftIds: draftIdsArr,
                            messageContent: inputDataDraft,
                            attachments: attachDataDraft.filter((item) => !item?.hidden),
                        }));

                    if (res?.status && res?.result) {
                        dispatch(mailboxAction.updateMailListDrafts(res?.data));
                    }
                } else {
                    if (isEmptyDraft) {
                        const res =
                            userState?.info &&
                            (await new MailboxApi().postDeleteDraft({
                                userId: userState?.info?.external_id,
                                draftIds: draftIdsArr,
                            }));
                        if (res?.status) {
                            dispatch(currentChatMailboxAction.removeDraftID());
                            setDraftIds([]);
                        }
                    } else {
                        if (userState?.info) {
                            new MailboxApi().postUpdateDraft({
                                userId: userState?.info?.external_id,
                                draftIds: draftIdsArr,
                                messageContent: inputDataDraft,
                                attachments: attachDataDraft.filter((item) => !item?.hidden),
                            });
                        }
                    }
                }
            } else {
                if (!isEmptyDraft) {
                    const res =
                        userState?.info &&
                        (await new MailboxApi().postAddDraft({
                            userId: userState?.info?.external_id,
                            recipients: checkedRecipientsID,
                            messageContent: inputDataDraft,
                            attachments: attachDataDraft.filter((item) => !item?.hidden),
                        }));
                    if (res?.status && userState.info && res?.result?.length) {
                        setDraftIds(res?.result);
                        dispatch(currentChatMailboxAction.addDraftId(res?.result[0]));
                        if (isDraftSection) {
                            dispatch(
                                mailboxAction.getMailDraftsWithoutReload({
                                    userId: [userState.info.external_id],
                                    page: mailboxState?.mailListPage,
                                }),
                            );
                        }
                    }
                }
            }
        })();
    }, [inputDataDraft, attachDataDraft]);

    useEffect(() => {
        if (currentChatMailboxState?.draftId) {
            const foundMail = (mailboxState?.mailList as IDraftsFullInfo[]).find(
                (el) => el?.draft?.id === currentChatMailboxState?.draftId,
            );

            if (foundMail?.attachments?.length) setAttachData(foundMail?.attachments);
            if (foundMail?.draft?.message_content?.length) {
                setInputData(foundMail?.draft.message_content);
            }
        }
    }, [currentChatMailboxState.draftId]);

    useEffect(() => {
        if (initSmileMobBlock?.isOpenMobSmileBlock) scrollTopPopupWriteLetter();
    }, [initSmileMobBlock.isOpenMobSmileBlock]);

    return {
        initSmileMobBlock,
        inputData,
        onSubmitMessageEnter,
        handleChangeInput,
        attachData,
        openAttachModal,
        deleteAttach,
        handleSmileBlock,
        disabledButton,
        addEmoji,
        sendButtonClass,
        submitLetter,
        priceTitle,
        attachModalIsOpen,
        closeAttachModal,
        sendImg: (e: React.MouseEvent<HTMLDivElement>) => sendMedia(e, 'SENT_IMAGE'),
        sendVideo: (e: React.MouseEvent<HTMLDivElement>) => sendMedia(e, 'SENT_VIDEO'),
        sendAudio: (e: React.MouseEvent<HTMLDivElement>) => sendMedia(e, 'SENT_AUDIO'),
    };
}
