import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as mailboxAction from '../../store/actions/mailbox';
import * as LETTER from '../../constants/letters';
import { useDispatch, useSelector } from 'react-redux';
import MailboxApi from '../../services/api/MailboxApi';
import ChatsApi from '../../services/api/ChatsApi';
import { initCancelTokenSource } from '../../services/methods';
import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import * as ALERT from '../../constants/alert';
import * as MENU from '../../constants/menu';
import * as ERROR from '../../constants/error';
import useTransition from '../transition/useTransition';
import { RootState } from '../../index';
import * as chatsAction from '../../store/actions/chats';

export default function useHandleLettersListItem(mailData?: any) {
    const dispatch = useDispatch();
    const history = useHistory();
    const transition = useTransition();
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const chatsState = useSelector((state: RootState) => state.chats);
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);
    const mailboxState = useSelector((state: RootState) => state.mailbox);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const [canceledToken, setCanceledToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const openMailChat = (isBookmark = false) => {
        if (!userState?.info?.external_id) return;

        dispatch(
            currentChatMailboxAction.addMailboxChatInfo({
                ...mailData,
                ...{ actionTarget: LETTER.ACTION_TARGET_LETTER },
            }),
        );
        dispatch(
            currentChatMailboxAction.getMailboxChatHistory({
                userId: userState?.info?.external_id,
                girlId: mailData?.public_external_id,
                mailId: mailData?.id,
                page: 1,
            }),
        );
        dispatch(currentChatMailboxAction.addMailboxSpecialLetter(mailData?.id));
        if (isBookmark) dispatch(currentChatMailboxAction.addMailboxBookmarkLetter(mailData?.id));
    };

    const paidNewLetterText = (mailID: number, womanExternalID: number, isBookmark = false) => {
        if (isBookmark) dispatch(currentChatMailboxAction.addMailboxBookmarkLetter(mailData?.id));
        return new MailboxApi()
            .paidReadMail({
                mail_id: mailID,
                girl_id: womanExternalID,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(userAction.setBalance());
                }
                return res;
            });
    };

    const handleSetFavorite = (
        event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>,
    ) => {
        if ('stopPropagation' in event) {
            event.stopPropagation();
        }
        const source = initCancelTokenSource(canceledToken, setCanceledToken);
        const chatId = mailData?.chat_id ?? currentChatMailboxState?.mailChatInfo?.chat_id;

        new ChatsApi()
            .postSetFavorite({
                userId: mailData?.male_id,
                chat_id: chatId,
                favorite: +!mailData?.is_favorite,
                token: source?.token,
            })
            .then((res) => {
                if (res?.canceled) return;
                if (res?.status) {
                    dispatch(mailboxAction.setFavorite(mailData?.chat_id, !mailData?.is_favorite));
                    if (currentChatMailboxState?.mailChatInfo?.chat_id) {
                        dispatch(currentChatMailboxAction.setFavorite(!mailData?.is_favorite));
                    }
                }
            });
    };

    const updateMails = () => {
        if (!userState?.info?.external_id) return;
        const page =
            mailboxState?.mailList?.length <= 1
                ? mailboxState?.mailListPage - 1
                : mailboxState?.mailListPage;

        dispatch(
            mailboxAction.getMailsWithoutReload({
                userId: userState?.info?.external_id,
                folder: menuState?.letters,
                girlFilter: letterFilterState?.girlFilter,
                girlId: letterFilterState?.girlId || '',
                letterFilter: letterFilterState?.lettersFilter,
                onlineFilter: letterFilterState?.onlineFilter,
                page,
                username: letterFilterState?.username,
            }),
        );
    };

    const addMailToTrash = () => {
        if (isLoading) return false;
        if (!userState?.info?.external_id) return false;
        setIsLoading(true);

        new MailboxApi()
            .postTrashMail({
                userId: userState?.info?.external_id,
                mailId: mailData?.id,
                outbox: true,
            })
            .then((res) => {
                if (res?.status) updateMails();
            })
            .finally(() => setIsLoading(false));
    };

    const restoreMail = (
        event: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.stopPropagation();
        if (isLoading) return false;
        if (!userState?.info?.external_id) return false;
        setIsLoading(true);

        new MailboxApi()
            .postRestoreMail({
                userId: userState?.info?.external_id,
                mailId: mailData?.id,
            })
            .then((res) => {
                if (res?.status) updateMails();
            })
            .finally(() => setIsLoading(false));
    };

    const goToUserProfilePage = (publicExternalId: number) => {
        if (!publicExternalId) return false;
        history.push(`/profile/${publicExternalId}`);
    };

    const goToChat = () => {
        if (!userState?.info?.external_id) return;
        new MailboxApi()
            .createChatWithProfile(userState?.info?.external_id, mailData?.female_id)
            .then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    const foundChat = chatsState?.chatList?.find(
                        (el) => el?.chat_uid === res?.chat_uid,
                    );
                    if (foundChat && res?.chat_info) {
                        dispatch(chatsAction.updateChatListItemInfo(res?.chat_info));
                    }
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
    };

    return {
        openMailChat,
        paidNewLetterText,
        handleSetFavorite,
        addMailToTrash,
        restoreMail,
        goToUserProfilePage,
        goToChat,
    };
}
