import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import SupportFeedback from './SupportFeedback';
import useSupportFeedbackModal from '../../../../hooks/supportFeedback/useSupportFeedbackModal';

export default function SupportFeedbackModal() {
    const supportFeedbackModal = useSupportFeedbackModal();

    return (
        <>
            <Modal
                style={modalStyleProps()}
                isOpen={supportFeedbackModal?.isSupportFeedbackModal}
                ariaHideApp={false}
            >
                <SupportFeedback {...supportFeedbackModal} />
            </Modal>
        </>
    );
}
