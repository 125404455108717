/* eslint-disable */

import React, { useImperativeHandle, useRef, forwardRef, JSX } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import * as ATTACH from '../../../../../constants/attach';
import ItemImage from '../../ListImages/ItemImage';
import ItemVideo from '../../ListVideos/ItemVideo';
import ItemAudio from '../../ListAudios/ItemAudio';
import { ICardHandle, ICardProps } from './index.interface';

const Card = forwardRef<ICardHandle, ICardProps>(
    (
        {
            type,
            data,
            isDragging,
            connectDragSource,
            connectDropTarget,
            setModalContentIsOpen,
            setCurrItem,
        },
        ref,
    ): JSX.Element => {
        const elementRef = useRef<HTMLDivElement>(null);
        connectDragSource(elementRef);
        connectDropTarget(elementRef);
        const opacity = isDragging ? 0 : 1;
        useImperativeHandle(ref, () => ({
            getNode: () => elementRef.current,
        }));

        if (type === ATTACH.IMG_LIST) {
            return (
                <ItemImage
                    elementRef={elementRef}
                    data={data}
                    opacity={opacity}
                    setModalContentIsOpen={setModalContentIsOpen}
                    setCurrItem={setCurrItem}
                />
            );
        } else if (type === ATTACH.VIDEO_LIST) {
            return (
                <ItemVideo
                    elementRef={elementRef}
                    data={data}
                    opacity={opacity}
                    setModalContentIsOpen={setModalContentIsOpen}
                    setCurrItem={setCurrItem}
                />
            );
        } else if (type === ATTACH.AUDIO_LIST) {
            return <ItemAudio elementRef={elementRef} data={data} opacity={opacity} />;
        }
        return <></>;
    },
);
export default DropTarget<ICardProps>(
    'card',
    {
        hover(props, monitor, component) {
            if (!component) {
                return null;
            }

            const node = component.getNode();
            if (!node) {
                return null;
            }
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = node.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset && clientOffset.y - hoverBoundingRect.top;
            if (hoverClientY && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (hoverClientY && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveCard(dragIndex, hoverIndex);
            monitor.getItem().index = hoverIndex;
        },
    },
    (connect) => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(
    DragSource<ICardProps>(
        'card',
        {
            beginDrag: (props) => ({
                id: props.id,
                index: props.index,
            }),
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        }),
    )(Card),
);
