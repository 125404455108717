import { TutorialActionTypes } from '../../constants/tutorial';
import { Dispatch } from 'redux';

export function getTutorialData(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: TutorialActionTypes.GET_TUTORIAL_DATA,
            data,
        });
    };
}

export function setActiveTutorialTopicId(activeTopicId: number | null = null) {
    return function (dispatch: Dispatch<{ type: string; activeTopicId: number | null }>) {
        return dispatch({
            type: TutorialActionTypes.SET_ACTIVE_TUTORIAL_TOPIC_ID,
            activeTopicId,
        });
    };
}

export function setActiveCategoryId(activeCategoryId: number | null = null) {
    return function (dispatch: Dispatch<{ type: string; activeCategoryId: number | null }>) {
        return dispatch({
            type: TutorialActionTypes.SET_ACTIVE_CATEGORY_ID,
            activeCategoryId,
        });
    };
}
