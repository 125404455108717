import { OnlineNowActionTypes } from '../../constants/onlineNow';
import { ONLINE_NOW_FILTERS_DEFAULT } from '../../../constants/search';
import { IOnlineNowState, OnlineNowActions } from './index.interface';

const initState = {
    list: [],
    filter: ONLINE_NOW_FILTERS_DEFAULT,
    page: 1,
    isLoading: true,
    isLoadingPage: false,
    isLoadedAll: false,
    isEmpty: false,
    lastBannerIndex: 0,
};

export default function (
    state: IOnlineNowState = initState,
    action: OnlineNowActions,
): IOnlineNowState {
    switch (action.type) {
        case OnlineNowActionTypes.INIT_ONLINE_NOW_SEARCH: {
            return initState;
        }

        case OnlineNowActionTypes.GET_ONLINE_NOW_LIST: {
            if (!Array.isArray(action?.searchList)) return state;

            const { crossBanners, searchList } = action;
            const bannerFrequency = crossBanners[0]?.show_in?.online_now ?? 0;
            const updatedList =
                state?.page === 1 ? [...searchList] : [...state.list, ...searchList];
            let bannerIndex = state?.page === 1 ? bannerFrequency : state?.lastBannerIndex;

            if (bannerFrequency) {
                const bannersToInsert = Math.max(
                    1,
                    Math.floor(searchList?.length / bannerFrequency),
                );
                for (let i = 0; i < bannersToInsert; i++) {
                    if (bannerIndex && bannerIndex <= updatedList.length) {
                        const bannerToInsert = crossBanners[i];

                        if (bannerToInsert) {
                            updatedList.splice(bannerIndex, 0, bannerToInsert);
                            bannerIndex += bannerFrequency;
                        }
                    }
                }
            }
            return {
                ...state,
                ...{
                    list: updatedList,
                    isEmpty: !updatedList?.length,
                    isLoading: false,
                    isLoadingPage: false,
                    isLoadedAll: !!(searchList?.length % 20),
                    lastBannerIndex: bannerIndex,
                },
            };
        }

        case OnlineNowActionTypes.SET_ONLINE_NOW_LIST_PAGE: {
            return {
                ...state,
                ...{
                    page: action?.page,
                    isLoadingPage: true,
                },
            };
        }

        case OnlineNowActionTypes.SET_IS_LOADING_PAGE: {
            return {
                ...state,
                ...{
                    isLoadingPage: action?.status,
                },
            };
        }

        case OnlineNowActionTypes.SET_ONLINE_NOW_LIST_FILTER: {
            return {
                ...state,
                ...{
                    filter: action?.filter,
                },
            };
        }

        case OnlineNowActionTypes.SET_ONLINE_NOW_LIKE_STATUS: {
            const newList = state?.list?.length
                ? state?.list?.map((item) => {
                      if ('external_id' in item && item?.external_id === action?.womanExternalID) {
                          return { ...item, ...{ liked: 1 } };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ list: newList },
            };
        }

        default:
            return state;
    }
}
