import { TransactionActionType } from '../../constants/transaction';
import { Dispatch } from 'redux';

export function setTransaction(transaction = null) {
    return function (dispatch: Dispatch<{ type: string; transaction: null }>) {
        dispatch({
            type: TransactionActionType.SET_TRANSACTION_SYNC_POSITION,
            transaction,
        });
    };
}
