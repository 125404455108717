import { useRef, useState } from 'react';
import useOutsideClicker from '../../dom/useOutsideClicker';

export default function useInitSelectBlocks() {
    //GIRLS PREF
    const [openGirlsEyeBlock, setOpenGirlsEyeBlock] = useState(false);
    const [openGirlsHairBlock, setOpenGirlsHairBlock] = useState(false);
    const [openGirlsAgeFromBlock, setOpenGirlsAgeFromBlock] = useState(false);
    const [openGirlsAgeToBlock, setOpenGirlsAgeToBlock] = useState(false);
    const [openGirlsBodyBlock, setOpenGirlsBodyBlock] = useState(false);
    const [openGirlsHeightFromBlock, setOpenGirlsHeightFromBlock] = useState(false);
    const [openGirlsHeightToBlock, setOpenGirlsHeightToBlock] = useState(false);
    const [openGirlsReligionBlock, setOpenGirlsReligionBlock] = useState(false);
    const [openGirlsMaritalBlock, setOpenGirlsMaritalBlock] = useState(false);
    const [openGirlsMoreChildrenBlock, setOpenGirlsMoreChildrenBlock] = useState(false);
    const [openGirlsAlcoholBlock, setOpenGirlsAlcoholBlock] = useState(false);
    const [openGirlsSmokingBlock, setOpenGirlsSmokingBlock] = useState(false);

    const selectGirlsEyeRef = useRef(null);
    const selectGirlsHairRef = useRef(null);
    const selectGirlsAgeFromRef = useRef(null);
    const selectGirlsAgeToRef = useRef(null);
    const selectGirlsBodyRef = useRef(null);
    const selectGirlsHeightFromRef = useRef(null);
    const selectGirlsHeightToRef = useRef(null);
    const selectGirlsReligionRef = useRef(null);
    const selectGirlsMaritalRef = useRef(null);
    const selectGirlsMoreChildrenRef = useRef(null);
    const selectGirlsAlcoholRef = useRef(null);
    const selectGirlsSmokingRef = useRef(null);

    useOutsideClicker(selectGirlsSmokingRef, () => setOpenGirlsSmokingBlock(false));
    useOutsideClicker(selectGirlsAlcoholRef, () => setOpenGirlsAlcoholBlock(false));
    useOutsideClicker(selectGirlsMoreChildrenRef, () => setOpenGirlsMoreChildrenBlock(false));
    useOutsideClicker(selectGirlsMaritalRef, () => setOpenGirlsMaritalBlock(false));
    useOutsideClicker(selectGirlsReligionRef, () => setOpenGirlsReligionBlock(false));
    useOutsideClicker(selectGirlsHeightToRef, () => setOpenGirlsHeightToBlock(false));
    useOutsideClicker(selectGirlsHeightFromRef, () => setOpenGirlsHeightFromBlock(false));
    useOutsideClicker(selectGirlsEyeRef, () => setOpenGirlsEyeBlock(false));
    useOutsideClicker(selectGirlsHairRef, () => setOpenGirlsHairBlock(false));
    useOutsideClicker(selectGirlsAgeFromRef, () => setOpenGirlsAgeFromBlock(false));
    useOutsideClicker(selectGirlsAgeToRef, () => setOpenGirlsAgeToBlock(false));
    useOutsideClicker(selectGirlsBodyRef, () => setOpenGirlsBodyBlock(false));

    // ABOUT MY SELF
    const [openMaritalBlock, setOpenMaritalBlock] = useState(false);
    const [openEducationBlock, setOpenEducationBlock] = useState(false);
    const [openChildrenBlock, setOpenChildrenBlock] = useState(false);
    const [openMoreChildrenBlock, setOpenMoreChildrenBlock] = useState(false);
    const [openReligionBlock, setOpenReligionBlock] = useState(false);
    const [openJobBlock, setOpenJobBlock] = useState(false);
    const [openHobbyBlock, setOpenHobbyBlock] = useState(false);
    const [openAlcoholBlock, setOpenAlcoholBlock] = useState(false);
    const [openSmokingBlock, setOpenSmokingBlock] = useState(false);
    const [openReasonBlock, setOpenReasonBlock] = useState(false);

    const selectMaritalRef = useRef(null);
    const selectEducationRef = useRef(null);
    const selectChildrenRef = useRef(null);
    const selectMoreChildrenRef = useRef(null);
    const selectReligionRef = useRef(null);
    const selectJobRef = useRef(null);
    const selectHobbyRef = useRef(null);
    const selectAlcoholRef = useRef(null);
    const selectSmokingRef = useRef(null);
    const selectReasonRef = useRef(null);

    useOutsideClicker(selectSmokingRef, () => setOpenSmokingBlock(false));
    useOutsideClicker(selectAlcoholRef, () => setOpenAlcoholBlock(false));
    useOutsideClicker(selectMaritalRef, () => setOpenMaritalBlock(false));
    useOutsideClicker(selectEducationRef, () => setOpenEducationBlock(false));
    useOutsideClicker(selectChildrenRef, () => setOpenChildrenBlock(false));
    useOutsideClicker(selectMoreChildrenRef, () => setOpenMoreChildrenBlock(false));
    useOutsideClicker(selectReligionRef, () => setOpenReligionBlock(false));
    useOutsideClicker(selectJobRef, () => setOpenJobBlock(false));
    useOutsideClicker(selectHobbyRef, () => setOpenHobbyBlock(false));
    useOutsideClicker(selectReasonRef, () => setOpenReasonBlock(false));

    // MAIN INFO
    const [openMonthBlock, setOpenMonthBlock] = useState(false);
    const [openDayBlock, setOpenDayBlock] = useState(false);
    const [openYearBlock, setOpenYearBlock] = useState(false);
    const [openCountryBlock, setOpenCountryBlock] = useState(false);
    const [openCityBlock, setOpenCityBlock] = useState(false);
    const [openChangePassword, setOpenChangePasswordBlock] = useState(false);

    const [isClickedCountry, setIsClickedCountry] = useState(false);
    const [isClickedCity, setIsClickedCity] = useState(false);

    const selectMonthRef = useRef(null);
    const selectDayRef = useRef(null);
    const selectYearRef = useRef(null);
    const selectCountryRef = useRef(null);
    const selectCityRef = useRef(null);
    const selectChangePasswordRef = useRef(null);

    useOutsideClicker(selectChangePasswordRef, () => setOpenChangePasswordBlock(false));
    useOutsideClicker(selectMonthRef, () => setOpenMonthBlock(false));
    useOutsideClicker(selectDayRef, () => setOpenDayBlock(false));
    useOutsideClicker(selectYearRef, () => setOpenYearBlock(false));
    useOutsideClicker(selectCountryRef, () => {
        setOpenCountryBlock(false);
        setIsClickedCountry(false);
    });
    useOutsideClicker(selectCityRef, () => {
        setOpenCityBlock(false);
        setIsClickedCity(false);
    });

    return {
        //GIRLS PREF
        openGirlsEyeBlock,
        setOpenGirlsEyeBlock,
        openGirlsHairBlock,
        setOpenGirlsHairBlock,
        openGirlsAgeFromBlock,
        setOpenGirlsAgeFromBlock,
        openGirlsAgeToBlock,
        setOpenGirlsAgeToBlock,
        openGirlsBodyBlock,
        setOpenGirlsBodyBlock,
        openGirlsHeightFromBlock,
        setOpenGirlsHeightFromBlock,
        openGirlsHeightToBlock,
        setOpenGirlsHeightToBlock,
        openGirlsReligionBlock,
        setOpenGirlsReligionBlock,
        openGirlsMaritalBlock,
        setOpenGirlsMaritalBlock,
        openGirlsMoreChildrenBlock,
        setOpenGirlsMoreChildrenBlock,
        openGirlsAlcoholBlock,
        setOpenGirlsAlcoholBlock,
        openGirlsSmokingBlock,
        setOpenGirlsSmokingBlock,

        selectGirlsEyeRef,
        selectGirlsHairRef,
        selectGirlsAgeFromRef,
        selectGirlsAgeToRef,
        selectGirlsBodyRef,
        selectGirlsHeightFromRef,
        selectGirlsHeightToRef,
        selectGirlsReligionRef,
        selectGirlsMaritalRef,
        selectGirlsMoreChildrenRef,
        selectGirlsAlcoholRef,
        selectGirlsSmokingRef,

        // MAIN INFO
        openMonthBlock,
        openDayBlock,
        openYearBlock,
        openCountryBlock,
        openCityBlock,
        openChangePassword,
        selectMonthRef,
        selectDayRef,
        selectYearRef,
        selectCountryRef,
        selectCityRef,
        selectChangePasswordRef,
        setOpenChangePasswordBlock,
        setOpenMonthBlock,
        setOpenDayBlock,
        setOpenYearBlock,
        setOpenCountryBlock,
        setOpenCityBlock,
        setIsClickedCountry,
        setIsClickedCity,
        isClickedCountry,
        isClickedCity,

        //ABOUT MY SELF
        openMaritalBlock,
        setOpenMaritalBlock,
        openEducationBlock,
        setOpenEducationBlock,
        openChildrenBlock,
        openMoreChildrenBlock,
        setOpenChildrenBlock,
        setOpenMoreChildrenBlock,
        openReligionBlock,
        setOpenReligionBlock,
        openJobBlock,
        setOpenJobBlock,
        openHobbyBlock,
        openSmokingBlock,
        openAlcoholBlock,
        openReasonBlock,
        setOpenHobbyBlock,
        setOpenAlcoholBlock,
        setOpenSmokingBlock,
        setOpenReasonBlock,
        selectReasonRef,
        selectAlcoholRef,
        selectSmokingRef,
        selectMaritalRef,
        selectEducationRef,
        selectChildrenRef,
        selectMoreChildrenRef,
        selectReligionRef,
        selectJobRef,
        selectHobbyRef,
    };
}
