import React, { ReactElement, useEffect, useState } from 'react';
import HeaderProfile from '../ProfileHeader';
import '../../../styles/man-profile/main.scss';
import '../../../styles/man-profile/media.scss';
import '../../../styles/chat/style.scss';
import ProfileApi from '../../../services/api/ProfileApi';
import '../../../styles/pruchase-history/main.scss';
import '../../../styles/pruchase-history/purchase.scss';
import '../../../styles/pruchase-history/contactRequest.scss';
import '../../../styles/pruchase-history/media.scss';
import '../../../styles/columns/column.scss';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NotifyEmailConfirm from '../../Banner';
import EmptyResult from '../../common/EmptyResult';
import Loader from '../../common/LoaderGif';
import { sendErrToSentry } from '../../../services/sentry';
import { IManProfileMeetingRequestItem } from '../../../models/profile/ManProfile.interface';
import avatar_placeholder from '../../../img/placeholder.gif';

export default function ProfileRequestContact() {
    const [contactRequests, setContactRequests] = useState<IManProfileMeetingRequestItem[]>([]);
    const [status, setStatus] = useState<number | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        try {
            new ProfileApi()
                .getProfileInfo()
                .then((res) => {
                    if (res?.contact_request?.length) {
                        setContactRequests(res?.contact_request);
                    }
                })
                .finally(() => setIsLoaded(true));
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }, []);

    const statuses = {
        All: null,
        'In process': 0,
        Approved: 1,
        Declined: 2,
    };

    const onChangeStatus = (value: number | null) => {
        setStatus(value);
    };

    let statusClass = '';
    let statusText = '';

    const renderStatus = (id: number) => {
        switch (+id) {
            case 0:
                statusClass = 'someStatus proc';
                statusText = 'in progress';
                break;
            case 1:
                statusClass = 'someStatus appr';
                statusText = 'approved';
                break;
            case 2:
                statusClass = 'someStatus decl';
                statusText = 'declined';
                break;
            default:
                statusClass = 'someStatus proc';
                statusText = 'in progress';
                break;
        }
        return <p className={statusClass}>{statusText}</p>;
    };

    const handleCopy = (email: string) => {
        alert('Email: ' + email + ' has been copied to clipboard!');
    };

    let responseText: ReactElement | null = null;
    const renderResponse = (id: number, item: IManProfileMeetingRequestItem) => {
        switch (+id) {
            case 0:
                responseText = <div className="emailInfo mainBlock" />;
                break;
            case 1:
                responseText = (
                    <div className="emailInfo mainBlock">
                        <div className="requestDate">
                            <p className="stnPar">Approved</p>
                            <p className="someDate">
                                {moment(item?.action_date).format('D.MM.YY')}
                            </p>
                        </div>
                        <div className="email">
                            <p className="whoEmail">Girl’s mail</p>
                            <p className="someEmail">{item?.approve_text}</p>
                        </div>
                        <CopyToClipboard
                            text={item?.approve_text}
                            onCopy={() => handleCopy(item?.approve_text)}
                        >
                            <img
                                src="../../../img/profile_menu/Vector.png"
                                alt="copy"
                                className="copy"
                            />
                        </CopyToClipboard>
                    </div>
                );
                break;
            case 2:
                responseText = (
                    <div className="bigText mainBlock">
                        <h4>Reason of decline</h4>
                        <p>{item?.decline_text}</p>
                    </div>
                );
                break;
            default:
                responseText = <div className="emailInfo mainBlock" />;
                break;
        }
        return responseText;
    };

    const contactRequestsFiltered = contactRequests.filter((item) => {
        return status == null ? true : +item?.status === +status;
    });

    return (
        <React.Fragment>
            <div className="column-3 profile_man">
                <NotifyEmailConfirm />
                <HeaderProfile />
                <main>
                    <div className="c3_profile_gifts_wrap">
                        {isLoaded ? (
                            <div className="c3_profile_gifts_wrap2">
                                <div className="c3_profile_filters">
                                    <div className="c3_profile_filters_title">Filter</div>
                                    {Object.keys(statuses).map((item, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    onChangeStatus(
                                                        statuses[item as keyof typeof statuses],
                                                    )
                                                }
                                                key={index}
                                                className={
                                                    statuses[item as keyof typeof statuses] ===
                                                    status
                                                        ? 'active c3_profile_filters_item'
                                                        : 'c3_profile_filters_item'
                                                }
                                            >
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="all-contact-re third-link block">
                                    <div className="all-humens">
                                        {contactRequestsFiltered?.length ? (
                                            contactRequestsFiltered.map((item, key) => (
                                                <div className="humen" key={key}>
                                                    <div className="avatarInfo mainBlock">
                                                        <div className="avatar">
                                                            <img
                                                                src={
                                                                    item?.avatar ||
                                                                    avatar_placeholder
                                                                }
                                                                alt="avatar"
                                                            />
                                                        </div>
                                                        <div className="name">
                                                            <p className="id">
                                                                ID {item?.public_external_id || ''}
                                                            </p>
                                                            <h3>
                                                                {item?.name}, {item?.age}
                                                            </h3>
                                                            <div className="country">
                                                                <img
                                                                    src={item?.country_image}
                                                                    alt="country"
                                                                />
                                                                <p>{item?.city_name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="humen_right_block">
                                                        <div className="requestDate mainBlock">
                                                            <p className="stnPar">Request date</p>
                                                            <p className="someDate">
                                                                {moment(item?.request_date).format(
                                                                    'MMM DD, YYYY',
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="status mainBlock">
                                                            <p className="nameStatus">status:</p>
                                                            {renderStatus(item?.status)}
                                                        </div>
                                                        {renderResponse(item?.status, item)}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <EmptyResult type={'contact'} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Loader />
                        )}
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
}
