import React from 'react';
import useHandleLikeHer from '../../../hooks/likeher/useHandleLikeHer';
import { useDispatch, useSelector } from 'react-redux';
import * as SEX from '../../../constants/sex';
import { RootState } from '../../../index';
import IconArrowRight from '../../Icons/IconArrowRight';
import { isHolidayActive } from '../../../constants/holiday';
import { ICrossBannersData } from '../../../store/reducers/banners/index.interface';
import { checkNationalitySite } from '../../../services/methods';
import MarketingApi from '../../../services/api/MarketingApi';
import * as bannersAction from '../../../store/actions/banners';
import Banners from '../../../img/banner-like-her';
import { getCachedImages } from '../../../services/methods/crossBanner';

export default function LikeCrossBanner(props: ICrossBannersData) {
    const userState = useSelector((state: RootState) => state.user);
    const { target_site_id, log_id } = props;
    const { incrementSelectedIndex } = useHandleLikeHer();
    const nationality = checkNationalitySite(target_site_id);
    const images = getCachedImages(log_id, nationality, 'like');
    const dispatch = useDispatch();
    const openConfirmTermModal = () => {
        if (log_id) {
            new MarketingApi().postClickCrossBanner(log_id);
        }
        dispatch(bannersAction.setOpenConfirmTermsModal(props));
    };
    const activeHoliday = isHolidayActive();

    return (
        <div className="temp_wrap">
            <div className={`likeher_page_wrap ${activeHoliday && activeHoliday?.name}`}>
                <div className="likeher_page_top">
                    <div className="likeher_page_photos">
                        {window.innerWidth > 600 && (
                            <div className={`likeher_page_small_photo small_photo_left`}>
                                <img
                                    src={Banners.images[images[0]]}
                                    className="likeher_page_small_photo_item"
                                    alt={`${nationality} girl`}
                                />
                            </div>
                        )}
                        <div className="likeher_page_big_photo">
                            <img
                                className="likeher_page_big_photo_item"
                                src={Banners.images[images[1]]}
                                alt={`${nationality} girl`}
                            />
                        </div>
                        {window.innerWidth > 600 && (
                            <div className={`likeher_page_small_photo small_photo_right`}>
                                <img
                                    src={Banners.images[images[2]]}
                                    className="likeher_page_small_photo_item"
                                    alt={`${nationality} girl`}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="likeher_page_middle"></div>
                <div className="likeher_page_bottom cross_banner">
                    <button
                        type="button"
                        className="likeher_page_next_btn likeher_page_control_btn"
                        onClick={incrementSelectedIndex}
                    >
                        <IconArrowRight />
                        <span>
                            {
                                SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
                                    .LIKEHER_NEXT_BTN
                            }
                        </span>
                    </button>
                    <button
                        type="button"
                        className="likeher_page_like_btn likeher_page_control_btn cross_banner"
                        onClick={openConfirmTermModal}
                    >
                        <span>Try {nationality} Chatting</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
