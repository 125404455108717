import { MenuActionTypes } from '../../constants/menu';
import * as MENU_CONST from '../../../constants/menu';
import { IMenuState, MenuActions } from './index.interface';

const initState = {
    main: '',
    sub: '',
    letters: 'inbox',
    composeLetterFilter: '',
    composeLetterSearch: '',
    isSiteFooterActive: false,
};

export default function (state: IMenuState = initState, action: MenuActions): IMenuState {
    switch (action.type) {
        case MenuActionTypes.SET_MAIN_MENU:
            return {
                ...state,
                ...{
                    main: action.data,
                    sub: '',
                },
            };

        case MenuActionTypes.SET_SUB_MENU:
            if (action.data === MENU_CONST.MENU_BLOCK) {
                return {
                    ...state,
                    ...{
                        main: '',
                        sub: action.data,
                    },
                };
            } else {
                return {
                    ...state,
                    ...{
                        main: 'chat',
                        sub: action.data,
                    },
                };
            }

        case MenuActionTypes.SET_LETTERS_MENU:
            return {
                ...state,
                ...{
                    letters: action.data,
                },
            };

        case MenuActionTypes.SET_COMPOSE_LETTER_FILTER:
            return {
                ...state,
                ...{
                    composeLetterFilter: action.data,
                },
            };

        case MenuActionTypes.SET_COMPOSE_LETTER_SEARCH:
            return {
                ...state,
                ...{
                    composeLetterSearch: action.data,
                },
            };

        default:
            return state;
    }
}
