import { useDispatch } from 'react-redux';
import { useState } from 'react';
import * as purchaseActions from '../../store/actions/purchase';
import * as userAction from '../../store/actions/user';
import * as purchaseAction from '../../store/actions/purchase';
import * as PURCHASE from '../../constants/purchase';
import PaymentApi from '../../services/api/v3/PaymentApi';
import * as alertAction from '../../store/actions/alert';
import { IPurchaseActiveSubscribeData } from '../../store/reducers/purchase/index.interface';

export interface IUseAutoTopUpChangeProps {
    activeSubscribeData: IPurchaseActiveSubscribeData;
    activeSubscribePaymentMethod: string;
}

export default function useAutoTopUpChange({
    activeSubscribePaymentMethod,
    activeSubscribeData,
}: IUseAutoTopUpChangeProps) {
    const dispatch = useDispatch();
    const [selectedCreditPackage, setSelectedCreditPackage] = useState<number>(0);

    const selectCreditPackage = (id: number) => {
        setSelectedCreditPackage(id);
    };

    const onSubscribeByActivePackage = () => {
        if (selectedCreditPackage && +activeSubscribeData?.id !== +selectedCreditPackage) {
            dispatch(purchaseActions.clearActiveSubscribeInfo());
            dispatch(purchaseActions.setActivePaymentMethod(activeSubscribePaymentMethod));
            dispatch(userAction.addLastBuyCredits(+selectedCreditPackage));
            dispatch(userAction.isBuying(true));
            dispatch(purchaseAction.setIsChangeSubscription(true, +activeSubscribeData?.credits));

            switch (activeSubscribePaymentMethod) {
                case PURCHASE.PAYMENT_METHOD_PASTABANK:
                    return new PaymentApi()
                        .postPastaChangeCreditPack(+selectedCreditPackage)
                        .then((res) => {
                            if (res?.status)
                                dispatch(purchaseActions.setChangeSubscribeModal(false));
                        });
                case PURCHASE.PAYMENT_METHOD_CARDPAY:
                    return new PaymentApi()
                        .postCardpayChangeSubscribe(+selectedCreditPackage)
                        .then((res) => {
                            if (res?.status)
                                dispatch(purchaseActions.setChangeSubscribeModal(false));
                        });
                default:
                    return;
            }
        } else {
            dispatch(alertAction.setMessage({ message: 'Choose another credit pack' }));
        }
    };
    return { selectedCreditPackage, onSubscribeByActivePackage, selectCreditPackage };
}
