import React from 'react';

export default function IconOnline() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="12"
            viewBox="0 0 22 12"
            fill="none"
        >
            <g filter="url(#filter0_d_227_4132)">
                <rect
                    x="2.41748"
                    y="0.355469"
                    width="17.3265"
                    height="7.99686"
                    rx="3.99843"
                    fill="white"
                />
            </g>
            <circle cx="15.3721" cy="4.354" r="3.33203" fill="#27AE60" />
            <defs>
                <filter
                    id="filter0_d_227_4132"
                    x="0.978045"
                    y="0.355469"
                    width="20.2055"
                    height="10.8759"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.43944" />
                    <feGaussianBlur stdDeviation="0.719718" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_227_4132"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_227_4132"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
