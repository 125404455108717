import React from 'react';
import Header from './Header';
import ManList from './ManList';
import * as SEX from '../../../../constants/sex';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';
import { IComComposeModalProps } from './index.interface';
import IconCirclePlus from '../../../Icons/IconCirclePlus';
import IconCross from '../../../Icons/IconCross';

export default function ComposeModal({
    openWriteLetterModal,
    closeComposeModal,
}: IComComposeModalProps) {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <div className="popup_compose_letter" data-testid="new-letter-modal">
            <Header />
            <ManList />
            <div
                className="popup_compose_add_men_btn"
                onClick={openWriteLetterModal}
                data-testid="add-btn"
            >
                <IconCirclePlus />
                <span>
                    {
                        SEX.SEX_TEXT[userState?.info?.sex_text as 'FEMALE_SEX' | 'MALE_SEX']
                            .LETTER_COMPOSE_MODAL_ADD_BTN
                    }
                </span>
            </div>
            <div className="close_one_popup" onClick={closeComposeModal} data-testid="close-btn">
                <IconCross />
            </div>
        </div>
    );
}
