import React, { useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import global from '../../global.module.scss';
import { IAutocompleteSelectProps } from './index.inteface';

export default function AutocompleteSelect({
    selectTitle,
    defaultName,
    arrayFields,
    openBox,
    formData,
    onOpenBlock,
    handleClick,
    index,
    isClicked,
    setIsClicked,
    callback,
    selectRef,
    handleLoad = () => {},
}: IAutocompleteSelectProps) {
    const [inputField, setInputField] = useState('');

    useEffect(() => {
        if (!openBox) setInputField('');
    }, [openBox]);

    useEffect(() => {
        if (index === 'city_name') {
            const timeoutID = setTimeout(() => handleLoad(inputField), 500);
            return () => clearTimeout(timeoutID);
        }
    }, [inputField]);

    return (
        <div className={`${styles.profile_main_name_city_wrap} ${global.city_wrap}`}>
            <span className={global.profile_label}>{selectTitle}</span>
            <div className={`${global.profile_select_wrap}`} ref={selectRef}>
                <div
                    className={`cl2h_tab_wide_filter_item cl2h_tab_wide_filter_item_profile ${openBox ? 'open' : ''}`}
                    data-testid={`${selectTitle}`}
                >
                    <input
                        className={`like_select_head profile_head ${formData ? 'selected' : ''}`}
                        placeholder={
                            inputField
                                ? defaultName
                                : isClicked
                                  ? defaultName
                                  : index === 'country_name'
                                    ? (formData?.country_name ?? formData)
                                    : formData
                        }
                        value={inputField}
                        onChange={(e) => setInputField(e.target.value)}
                        onClick={() => {
                            setIsClicked(true);
                            onOpenBlock();
                        }}
                    />
                    <div
                        className={`like_select_list_wrap profile_like_select_list_wrap ${openBox ? 'open' : ''}`}
                    >
                        <div
                            className={`like_select_list ${openBox ? 'open' : ''}`}
                            data-testid="select-list"
                        >
                            {arrayFields
                                .filter(
                                    (item: any) =>
                                        inputField?.length > 0 &&
                                        item[`${index}`]
                                            .toLowerCase()
                                            .includes(inputField.toLowerCase()),
                                )
                                .map((item: any, key: number) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label like_select_label_profile"
                                            onClick={() => {
                                                setInputField(item[`${index}`]);
                                                handleClick(item);
                                                callback();
                                            }}
                                        >
                                            {item[`${index}`] ? item[`${index}`] : ''}
                                        </label>
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
