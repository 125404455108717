import React, { useState } from 'react';
import Header from './Header';
import List from './List';
import { IFemaleListItem } from '../../../store/reducers/mailbox/index.interface';

interface IGiftsCartRecipientSelect {
    submit: (selectedGirl: IFemaleListItem) => void;
    closeModal: () => void;
}

export default function GiftsCartRecipientSelect({
    submit,
    closeModal,
}: IGiftsCartRecipientSelect) {
    const [selectedItem, setSelectedItem] = useState<IFemaleListItem | null>(null);

    const handleOnSelect = (item: IFemaleListItem) => setSelectedItem(item);

    return (
        <div className="popup_compose_letter">
            <Header />
            <List selectedItem={selectedItem} handleOnSelect={handleOnSelect} />
            <div
                className="popup_compose_add_men_btn"
                onClick={() => (selectedItem ? submit(selectedItem) : null)}
            >
                <img src="../../../img/c3l-add-men-btn.svg" alt="" />
                <span>Add addressee</span>
            </div>
            <div className="close_one_popup" onClick={closeModal}>
                <img src="../../../img/popup-gift-close.svg" alt="" />
            </div>
        </div>
    );
}
