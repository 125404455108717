import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Poll from './Poll';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';

export default function PollModal() {
    const quizState = useSelector((state: RootState) => state.quiz);

    const isActive = !!(quizState?.isActiveModal && quizState?.quizData?.id);

    return (
        <Modal style={modalStyleProps()} isOpen={isActive} ariaHideApp={false}>
            {!!isActive && <Poll />}
        </Modal>
    );
}
