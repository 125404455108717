import React, { useRef } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import ChatMobileHeader from './ChatMobileHeader';
import ChatMobileForm from './ChatMobileForm';
import MessageList from './BodyChat/MessageList';
import useHandleChatForm from '../../../hooks/chat/useHandleChatForm';
import useScrollChatMessageList from '../../../hooks/chat/helpers/useScrollChatMessageList';
import useVirtualGifts from '../../../hooks/virtualGifts/useVirtualGifts';
import VirtualGiftsList from '../Chat/BodyChat/MessageForm/VirtualGiftsList';
import { RootState } from '../../../index';

export default function ChatMobile() {
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const writeMsgRef = useRef<HTMLDivElement | null>(null);

    const handleChatForm = useHandleChatForm(writeMsgRef);
    const scrollChatMessageList = useScrollChatMessageList();
    const virtualGifts = useVirtualGifts({
        womanExternalId: currentChatState?.info?.id || 0,
        chatUid: currentChatState?.info?.chat_uid || '',
    });

    return (
        <>
            <div className={styles.mobile_chat} id="mobile-chat">
                <ChatMobileHeader />
                <div className={styles.mobile_chat_container}>
                    <div className={styles.mobile_chat_body_wrap}>
                        <div className={styles.mobile_chat_body}>
                            <MessageList
                                {...scrollChatMessageList}
                                isLoadingRequest={handleChatForm.isLoadingRequest}
                                setIsLoadingRequest={handleChatForm.setIsLoadingRequest}
                            />

                            {virtualGifts.isOpenVirtualGifts && (
                                <VirtualGiftsList
                                    closeVirtualGifts={virtualGifts.toggleVirtualGifts}
                                    virtualGiftsData={virtualGifts.virtualGiftsData}
                                    sendVirtualGift={virtualGifts.sendVirtualGift}
                                    virtualGiftListRef={virtualGifts.virtualGiftListRef}
                                    isActiveNewMobileChat={true}
                                    womanName={currentChatState?.info?.name || ''}
                                />
                            )}

                            {
                                // Mobile Button Virtual GiftSuccessModal
                                !!virtualGifts?.virtualGiftsData?.length &&
                                    virtualGifts.isMobVirtualGiftsBtn && (
                                        <button
                                            className={`chat_virtual_gift_btn ${virtualGifts.isOpenVirtualGifts ? 'active_btn' : ''}`}
                                            type="button"
                                            ref={virtualGifts.virtualGiftBtnMobRef}
                                            onClick={virtualGifts.toggleVirtualGifts}
                                        >
                                            Virtual gift
                                        </button>
                                    )
                            }
                        </div>
                        <ChatMobileForm writeMsgRef={writeMsgRef} />
                    </div>
                </div>
            </div>
        </>
    );
}
