import React, { useEffect, useRef, useState } from 'react';
import SearchInput from '../../common/SearchInput';
import SearchFilterWomanAge from '../SearchFilterWomanAge';
import SearchFilterDetailedNEW from '../SearchFilterDetailedNEW';
import SearchFilterOnline from '../SearchFilterOnline';
import { useSelector } from 'react-redux';
import ProfileApi from '../../../services/api/ProfileApi';
import { ICityItem, ISearchFilter } from '../../../store/reducers/search/index.interface';
import { RootState } from '../../../index';
import { VIDEO_SHOW_FILTERS_DEFAULT } from '../../../constants/search';

interface ISearchFiltersV2<T> {
    filter: T;
    setFilter: (value: T) => void;
    isLoadingPage: boolean;
}

export default function SearchFiltersV2<
    T extends ISearchFilter | typeof VIDEO_SHOW_FILTERS_DEFAULT,
>({ filter, setFilter, isLoadingPage }: ISearchFiltersV2<T>) {
    const inputCityRef = useRef<HTMLInputElement | null>(null);
    const userState = useSelector((state: RootState) => state.user);
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const isISearchFilter = (filter: any): filter is ISearchFilter => {
        return 'city' in filter && 'country_name' in filter && 'cityList' in filter;
    };

    const setFilterField = async (field: string, value: any) => {
        const newFilter = { ...filter, [field]: value };

        if (field === 'id' && newFilter.id === filter.id) return;

        if (field === 'country' && isISearchFilter(newFilter)) {
            if (newFilter.country === filter.country) return;

            const countryID = referenciesState?.list?.country_list?.find(
                (el) => el?.country_name === value,
            )?.id;

            newFilter.country_name = value;
            newFilter.country = countryID ?? 0;
            newFilter.city = 0;
            newFilter.city_name = '';

            if (value && !newFilter.country) return;
            if (!value?.length) {
                newFilter.city_name = '';
                newFilter.city = 0;
                newFilter.cityList = [];
            }
            if (!isISearchFilter(newFilter)) return;
            setFilter(newFilter);
        }

        if (field === 'city' && isISearchFilter(newFilter)) {
            if (!value.length) {
                newFilter.city_name = '';
                newFilter.city = 0;
                newFilter.cityList = [];
                setFilter(newFilter);
            } else {
                newFilter.city_name = value;
                newFilter.city =
                    newFilter?.cityList?.find((el) =>
                        el?.city_name?.toLowerCase()?.includes(value?.toLowerCase()),
                    )?.id ?? 0;
            }
            if (newFilter.city_name && newFilter.city_name.length >= 3) {
                setFilter(newFilter);
            }
        }

        if (field === 'detail') {
            if (value?.ALCOHOL) newFilter.alcohol = value?.ALCOHOL?.id;
            if (value?.BODY) newFilter.bodytype = value?.BODY?.id;
            if (value?.CHILDREN) newFilter.children = value?.CHILDREN?.id;
            if (value?.EDUCATION) newFilter.education = value?.EDUCATION?.id;
            if (value?.EYE) newFilter.eye = value?.EYE?.id;
            if (value?.HAIR) newFilter.haircolor = value?.HAIR?.id;
            if (value?.HEIGHT_FROM) newFilter.heightfrom = value?.HEIGHT_FROM?.id;
            if (value?.HEIGHT_TO) newFilter.heightto = value?.HEIGHT_TO?.id;
            if (value?.MORECHILDREN) newFilter.wantchildren = value?.MORECHILDREN?.id;
            if (value?.OCCUPATION) newFilter.job = value?.OCCUPATION?.id;
            if (value?.PURPOSE) newFilter.purpose = value?.PURPOSE?.id;
            if (value?.RELIGION) newFilter.religion = value?.RELIGION?.id;
            if (value?.SMOKING) newFilter.smoking = value?.SMOKING?.id;
            if (value?.STATUS) newFilter.marital = value?.STATUS?.id;

            setFilter(newFilter);
        }
        if (field !== 'city') {
            setFilter(newFilter);
        }
    };

    const handleOnline = () => {
        if (isLoadingRequest) return;
        setFilterField('online', !filter?.online);
    };

    useEffect(() => {
        if (isLoadingPage !== isLoadingRequest) {
            setIsLoadingRequest(isLoadingPage);
        }
    }, [isLoadingPage]);

    useEffect(() => {
        if (filter?.country && isISearchFilter(filter)) {
            if (filter?.city_name && filter?.city_name?.length > 1) {
                new ProfileApi()
                    .getCityByCountry(filter?.country, filter?.city_name)
                    .then((res) => {
                        if (res?.status && res?.response?.length) {
                            setFilter({
                                ...filter,
                                cityList: res?.response.sort((a: ICityItem, b: ICityItem) =>
                                    a.city_name.localeCompare(b?.city_name),
                                ),
                            });
                        } else {
                            setFilter({ ...filter, cityList: [] });
                        }
                    });
            } else {
                setFilter({ ...filter, cityList: [] });
            }
        }
    }, [filter?.country, ...(isISearchFilter(filter) ? [filter?.city_name] : [])]);

    useEffect(() => {
        if (isISearchFilter(filter) && !filter?.city && filter?.cityList?.length) {
            setFilter({
                ...filter,
                city:
                    filter?.cityList?.find(
                        (el) => el?.city_name?.toLowerCase() === filter?.city_name?.toLowerCase(),
                    )?.id ?? 0,
            });
        }
    }, [...(isISearchFilter(filter) ? [filter?.cityList?.length] : [])]);

    return (
        <div className="c3lh_search_top" data-testid="filters-block">
            <SearchFilterWomanAge
                ageFromFilter={filter?.from}
                setAgeFromFilter={(value) => setFilterField('from', value)}
                ageToFilter={filter?.to}
                setAgeToFilter={(value) => setFilterField('to', value)}
            />
            <SearchFilterDetailedNEW
                countryFilter={isISearchFilter(filter) ? filter?.country_name : ''}
                handleCountryFilter={(value) => setFilterField('country', value)}
                cityFilter={(isISearchFilter(filter) ? filter?.city_name : '') ?? ''}
                handleCityFilter={(value) => setFilterField('city', value)}
                cityList={(isISearchFilter(filter) ? filter.cityList : []) ?? []}
                ageFromFilter={filter?.from}
                setAgeFromFilter={(value) => setFilterField('from', value)}
                ageToFilter={filter?.to}
                setAgeToFilter={(value) => setFilterField('to', value)}
                nameFilter={filter?.id}
                handleSearchName={(value) => setFilterField('id', value)}
                handleOnline={handleOnline}
                online={filter?.online}
                detailedFilters={filter?.detail}
                setDetailedFilters={(value) => setFilterField('detail', value)}
                inputCityRef={inputCityRef}
            />
            {userState?.info && !+userState?.info?.gender && (
                <SearchFilterOnline handleChange={handleOnline} selected={filter?.online} />
            )}
            <SearchInput handleSearch={(value) => setFilterField('id', value)} value={filter?.id} />
        </div>
    );
}
