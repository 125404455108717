import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import OnboardingDropdown from '../OnboardingDropdown/OnboardingDropdown';
import { useSelector } from 'react-redux';
import ProfileApi from '../../../services/api/ProfileApi';
import { RootState } from '../../../index';
import {
    IManProfileReferenceCityItem,
    IManProfileReferenceCountryItem,
} from '../../../models/profile/ManProfile.interface';

interface IOnboardingLocation {
    title: string;
    subtitle: string;
    activeStep: number;
    maxStep: number;
    onClick: () => void;
    country: IManProfileReferenceCountryItem;
    city: IManProfileReferenceCityItem;
    onChange: (country: any, city: any) => void;
    skipStep: () => void;
}

const OnboardingLocation = ({
    title,
    subtitle,
    activeStep,
    maxStep,
    onClick,
    country,
    city,
    onChange,
    skipStep,
}: IOnboardingLocation) => {
    const [countryName, setCountryName] = useState<string>('');
    const [cityName, setCityName] = useState('');
    const [cityList, setCityList] = useState<IManProfileReferenceCityItem[]>([]);

    const referenciesState = useSelector((state: RootState) => state.referencies);

    const countryList = referenciesState?.list?.country_list;

    useEffect(() => {
        if (city?.city_name) setCityName(city?.city_name);
    }, [city?.city_name]);

    useEffect(() => {
        if (country?.country_name) setCountryName(country?.country_name);
    }, [country?.country_name]);

    useEffect(() => {
        if (country?.id && cityName?.length > 2) {
            const timeoutId = setTimeout(
                () =>
                    new ProfileApi().getCityByCountry(country?.id, cityName).then((res) => {
                        if (res?.status && res?.response?.length) {
                            setCityList(
                                res?.response.sort(
                                    (
                                        a: IManProfileReferenceCityItem,
                                        b: IManProfileReferenceCityItem,
                                    ) => a.city_name.localeCompare(b?.city_name),
                                ),
                            );
                        } else {
                            setCityList([]);
                        }
                    }),
                500,
            );

            return () => clearTimeout(timeoutId);
        } else setCityList([]);
    }, [cityName, country?.id]);

    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>

            <div className={styles.content}>
                <OnboardingDropdown<IManProfileReferenceCountryItem>
                    title={'Country'}
                    index={'country_name'}
                    setValue={(value) => setCountryName(value as string)}
                    value={countryName}
                    dropdownArray={
                        countryList?.filter((item) => {
                            if (countryName?.length) {
                                return (
                                    item?.country_name
                                        ?.toLowerCase()
                                        ?.includes(countryName?.toLowerCase()) &&
                                    item?.country_name !== 'Not Specified'
                                );
                            } else return true;
                        }) || []
                    }
                    onClick={(value: IManProfileReferenceCountryItem) => {
                        onChange(value, null);
                        setCityName('');
                        setCityList([]);
                    }}
                />
                {!!country && (
                    <OnboardingDropdown
                        title={'City'}
                        index={'city_name'}
                        setValue={(value) => setCityName(value as string)}
                        value={cityName}
                        dropdownArray={cityList?.filter((item) => {
                            if (cityName?.length) {
                                return (
                                    item?.city_name
                                        ?.toLowerCase()
                                        ?.includes(cityName?.toLowerCase()) &&
                                    item?.city_name !== 'Not Specified'
                                );
                            } else return true;
                        })}
                        onClick={(value: IManProfileReferenceCityItem) => onChange(country, value)}
                    />
                )}
            </div>

            <div className={styles.button_row}>
                <button className={styles.button} onClick={onClick}>
                    Continue
                </button>
                <button className={`${styles.button} ${styles.skip}`} onClick={skipStep}>
                    Skip
                </button>
            </div>
        </div>
    );
};

export default OnboardingLocation;
