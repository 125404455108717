import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Slider from 'react-slick';
import SliderItem from './SliderItem';
import { useDispatch, useSelector } from 'react-redux';
import useAddWomanList from '../../../hooks/woman/useAddWomanList';
import useGetWomanInfo from '../../../hooks/woman/useGetWomanInfo';
import useTransition from '../../../hooks/transition/useTransition';
import MailboxApi from '../../../services/api/MailboxApi';
import * as MENU from '../../../constants/menu';
import * as ERROR from '../../../constants/error';
import * as ALERT from '../../../constants/alert';
import * as alertAction from '../../../store/actions/alert';
import * as likesAction from '../../../store/actions/likes';
import * as SLIDERS from '../../../constants/slider';
import * as modalsAction from '../../../store/actions/modals';
import useLike from '../../../hooks/likes/useLike';
import { RootState } from '../../../index';
import { SliderTypeEnum } from '../../../constants/slider';
import { IWoman } from '../../../store/reducers/woman/index.interface';
import IconArrowLeft from '../../Icons/IconArrowLeft';

const sliderSettings = {
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    arrows: false,
    touchThreshold: 10,
    swipeToSlide: true,
    draggable: false,
};

const GirlsSliderLikeHer = memo(function GirlsSliderLikeHer() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const addWomanList = useAddWomanList();
    const getWomanInfo = useGetWomanInfo();
    const like = useLike();

    const likesState = useSelector((state: RootState) => state.likes);
    const userState = useSelector((state: RootState) => state.user);
    const modalsState = useSelector((state: RootState) => state.modals);
    const menuState = useSelector((state: RootState) => state.menu);

    const [sliderList, setSliderList] = useState<any>([]);
    const [isOnPreviousOne, setIsOnPreviousOne] = useState<boolean>(false);

    const sliderRef = useRef<Slider | null>(null);

    const sliderType = modalsState?.womanProfileModal?.externalID
        ? SLIDERS?.SLIDER_TYPE_PROFILE_WOMAN
        : menuState?.main;
    const sliderObj = SLIDERS.ARRAY_SLIDER[sliderType as SliderTypeEnum];
    const amountSliderList = 20;

    const setLike = async (womanExternalID: number) => {
        const isLike = await like(womanExternalID);

        if (isLike?.status) {
            // @ts-expect-error getWomanInfo
            dispatch(likesAction.addLikeHistory(getWomanInfo(womanExternalID)));
            dispatch(likesAction.removeFromLikeList(womanExternalID));
        }
    };

    const openChat = (womanExternalID: number) => {
        if (userState?.info?.external_id) {
            new MailboxApi()
                .createChatWithProfile(userState?.info?.external_id, womanExternalID)
                .then((res) => {
                    if (+res?.chat_info?.male_block) {
                        return dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    }
                    if (res?.status && res?.chat_uid) {
                        dispatch(modalsAction.closeWomanProfileModal());
                        transition.transitionWithHistoryPush(
                            MENU.OPEN_CHAT,
                            `/chat/${res?.chat_uid}`,
                        );
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                });
        }
    };

    const onPreviousOne = () => {
        const prevSelectedList = likesState.selectedListIndex - 1;

        setIsOnPreviousOne(true);
        if (prevSelectedList >= 0) {
            dispatch(likesAction.setSelectedPerson(prevSelectedList));
            setSliderList([...[likesState?.list[+prevSelectedList + 1]], ...sliderList]);
        }
    };

    const onNextSlide = () => {
        if (sliderRef.current) sliderRef.current.slickNext();
    };

    const onPrevSlide = () => {
        if (sliderRef.current) sliderRef.current.slickPrev();
    };

    const openProfileModal = (womanExternalID: number) => {
        dispatch(modalsAction.closeWomanProfileModal());
        dispatch(modalsAction.openWomanProfileModal(womanExternalID));

        if (window.innerWidth < 1000) {
            const topModalDIV = document.querySelector('.c3_woman_profile_main_block_name');
            if (topModalDIV) topModalDIV.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    };

    const openGalleryModal = (womanProfile: IWoman) => {
        if (!womanProfile?.link || !womanProfile?.images?.length) return false;

        dispatch(modalsAction.openWomanGalleryModal(womanProfile?.external_id));
    };

    const initLikeherSlider = () => {
        if (!likesState?.list?.length || likesState?.list?.length < 5) {
            return setSliderList([]);
        }

        setIsOnPreviousOne(!+likesState.selectedListIndex);
        setSliderList(
            likesState?.list?.slice(
                +likesState.selectedListIndex + 1,
                +amountSliderList + +likesState.selectedListIndex + 1,
            ),
        );
    };

    useMemo(() => {
        if (likesState?.list?.length) initLikeherSlider();
    }, [likesState.selectedListIndex, likesState.list, menuState]);

    useEffect(() => {
        if (sliderList?.length) addWomanList(sliderList);
    }, [sliderList]);

    return (
        <>
            {sliderList?.length ? (
                <div className={`${styles.girls_slider_wrap} girls_slider_wrap_global`}>
                    <div className={`${styles.girls_slider_info} ${styles.lh}`}>
                        <div className={styles.girls_slider_title}>{sliderObj?.title}</div>
                        <div className={styles.girls_slider_text}>
                            {window.innerWidth > 600 ? sliderObj?.textPC : sliderObj?.textMob}
                        </div>
                        {!isOnPreviousOne && (
                            <div className={styles.girls_slider_like_prev} onClick={onPreviousOne}>
                                <IconArrowLeft />
                                <span>
                                    Previous <span>one</span>
                                </span>
                            </div>
                        )}
                    </div>
                    <div
                        className={`${styles.girls_slider_list_wrap} girls_slider_list_wrap_global`}
                    >
                        <div className={styles.girls_slider_list}>
                            <Slider {...sliderSettings} ref={sliderRef}>
                                {sliderList.map((item: any, key: number) => (
                                    <SliderItem
                                        key={key + item}
                                        // @ts-expect-error getWomanInfo
                                        womanProfile={getWomanInfo(item)}
                                        openChat={openChat}
                                        setLike={setLike}
                                        openProfileModal={openProfileModal}
                                        openGalleryModal={openGalleryModal}
                                    />
                                ))}
                            </Slider>
                        </div>
                    </div>
                    {/*<div className={styles.girls_slider_btn_buttons}>*/}
                    <div
                        className={`${styles.girls_slider_btn} ${styles.girls_slider_prev_btn}`}
                        onClick={onPrevSlide}
                    />
                    <div
                        className={`${styles.girls_slider_btn} ${styles.girls_slider_next_btn}`}
                        onClick={onNextSlide}
                    />
                    {/*</div>*/}
                </div>
            ) : (
                <div />
            )}
        </>
    );
});

export default GirlsSliderLikeHer;
