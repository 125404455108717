import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function useGetWomanInfo() {
    const womanState = useSelector((state: RootState) => state.woman);

    return (externalID: number) => {
        return womanState?.list?.find((item) => +item?.external_id === +externalID);
    };
}
