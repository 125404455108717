import React from 'react';

export default function IconChat() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.39535 7.48162C3.39535 5.78811 4.74891 4.41524 6.4186 4.41524H17.5814C19.2511 4.41524 20.6047 5.78811 20.6047 7.48162V13.1426C20.6047 14.8362 19.2511 16.209 17.5814 16.209H16.8837V16.9167V18.9823L14.3538 16.4163L14.1495 16.209H13.8605H6.4186C4.74891 16.209 3.39535 14.8362 3.39535 13.1426V7.48162ZM6.4186 2.99998C3.97828 2.99998 2 5.00648 2 7.48162V13.1426C2 15.6178 3.97828 17.6243 6.4186 17.6243H13.5715L16.6117 20.7078C17.227 21.3319 18.2791 20.8899 18.2791 20.0073V17.5687C20.3879 17.2295 22 15.377 22 13.1426V7.48162C22 5.00648 20.0217 2.99998 17.5814 2.99998H6.4186ZM5.72093 8.42513C5.72093 8.03432 6.03329 7.7175 6.4186 7.7175L17.5814 7.7175C17.9667 7.7175 18.2791 8.03432 18.2791 8.42513C18.2791 8.81594 17.9667 9.13276 17.5814 9.13276L6.4186 9.13275C6.03329 9.13275 5.72093 8.81594 5.72093 8.42513ZM5.72093 12.1991C5.72093 11.8083 6.03329 11.4915 6.4186 11.4915H17.5814C17.9667 11.4915 18.2791 11.8083 18.2791 12.1991C18.2791 12.59 17.9667 12.9068 17.5814 12.9068H6.4186C6.03329 12.9068 5.72093 12.59 5.72093 12.1991Z"
            />
        </svg>
    );
}
