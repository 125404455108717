import React from 'react';

export default function IconLikeHerHeartStroke() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#821D77"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.318 4.29909C8.52906 3.80644 7.50142 3.53894 6.09895 3.87082C4.64156 4.21569 3.46276 5.45208 2.95806 7.07599C2.45739 8.68695 2.66492 10.5591 3.84239 12.0449C5.78712 14.4987 9.93622 18.1416 12.2181 20.0296C14.5 18.1416 18.6491 14.4987 20.5938 12.0449C21.7713 10.5591 21.9788 8.68695 21.4781 7.07599C20.9735 5.45208 19.7946 4.21569 18.3373 3.87082C16.9348 3.53894 15.9072 3.80644 15.1182 4.29909C14.2981 4.81118 13.6835 5.60138 13.22 6.37978C12.7799 7.1191 11.6563 7.1191 11.2162 6.37978C10.7527 5.60138 10.1381 4.81118 9.318 4.29909ZM11.7454 21.6701C11.72 21.6527 11.6956 21.6338 11.6724 21.6137C9.55915 19.8976 4.77305 15.7725 2.55695 12.9762C1.02887 11.048 0.78545 8.65296 1.41208 6.63672C2.03468 4.63345 3.56142 2.88269 5.71148 2.3739C7.55263 1.93821 9.03953 2.28339 10.1999 3.00797C11.0692 3.55079 11.7252 4.28708 12.2181 4.99518C12.711 4.28708 13.367 3.55079 14.2363 3.00797C15.3967 2.28339 16.8836 1.93821 18.7247 2.3739C20.8748 2.88269 22.4015 4.63345 23.0241 6.63672C23.6508 8.65296 23.4073 11.048 21.8793 12.9762C19.6632 15.7725 14.8771 19.8976 12.7638 21.6137C12.7406 21.6338 12.7162 21.6527 12.6908 21.6701C12.5437 21.773 12.3791 21.819 12.2181 21.8181C12.0571 21.819 11.8925 21.773 11.7454 21.6701Z"
            />
        </svg>
    );
}
