import React from 'react';
import EmptyResult from '../../common/EmptyResult';
import LoaderGif from '../../common/LoaderGif';
import DefaultList from './DefaultList';
import WithBannerList from './WithBannerList';
import useHandleVIP from '../../../hooks/vip/useHandleVIP';
import { useSelector } from 'react-redux';
import useSetSearchList from '../../../hooks/search/useSetSearchList';
import { RootState } from '../../../index';

export default function SearchResults() {
    const searchState = useSelector((state: RootState) => state.search);

    const setSearchList = useSetSearchList();
    const { isShowVIPBanner } = useHandleVIP();

    return (
        <div
            className="c3lh_your_likes_list_wrap2"
            onScroll={setSearchList.handleScroll}
            data-testid="filter-result"
        >
            {searchState?.isEmpty ? (
                <EmptyResult
                    setDefaultFilterValue={setSearchList.setDefaultFilterValue}
                    type={'search'}
                />
            ) : searchState?.isLoading ? (
                <LoaderGif />
            ) : isShowVIPBanner ? (
                <WithBannerList />
            ) : (
                <DefaultList />
            )}
        </div>
    );
}
