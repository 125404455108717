import React, { useRef, useState } from 'react';
import moment from 'moment';
import { LetterButton, ChatButton, GifButton } from '../../../Buttons';
import ModerationModal from '../../ModerationModal';
import GirlVideo from '../../../GirlVideo';
import {
    convertPrice,
    displayDaysUntilBirthdayOnProfilePage,
} from '../../../../../services/methods';
import * as IMAGES from '../../../../../constants/images';
import { getImageLink } from '../../../../../services/methods/search/images';
import '../../../../../styles/woman-profile.scss';
import useTouchMove from '../../../../../hooks/dom/useTouchMove';
import useInitModeration from '../../../../../hooks/support/useInitModeration';
import useInitWomanProfileGallery from '../../../../../hooks/woman-profile/useInitWomanProfileGallery';
import useInitWomanProfileInfo from '../../../../../hooks/woman-profile/useInitWomanProfileInfo';
import useInitWomanProfileFunctions from '../../../../../hooks/woman-profile/useInitWomanProfileFunctions';
import { useSelector } from 'react-redux';
import Loader from '../../../LoaderGif';
import * as SEX from '../../../../../constants/sex';
import * as USER from '../../../../../constants/user';
import * as PRICES from '../../../../../constants/prices';
import useOutsideClicker from '../../../../../hooks/dom/useOutsideClicker';
import SITE from '../../../../../config/site';
import useInitModalMeetingRequest from '../../../../../hooks/dom/useInitModalMeetingRequest';
import MeetingRequestModal from '../../MeetingRequestModal';
import useInitContactRequestModal from '../../../../../hooks/contactRequest/useInitContactRequestModal';
import ContactRequestModal from '../../ContactRequestModal';
import GirlsSliderProfile from '../../../GirlsSliderProfile/GirlsSliderProfile';
import useHandleActiveBlocks from '../../../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../../../index';
import IconFavourites from '../../../../Icons/IconFavourites';
import IconFavouritesActive from '../../../../Icons/IconFavouritesActive';
import IconComplain from '../../../../Icons/IconComplain';
import IconHelloHand from '../../../../Icons/IconHelloHand';
import IconLikeHerHeart from '../../../../Icons/IconLikeHerHeart';
import IconFAQQuestionArrow from '../../../../Icons/IconFAQQuestionArrow';
import IconFAQQuestionBackArrow from '../../../../Icons/IconFAQQuestionBackArrow';
import IconPlayVideo from '../../../../Icons/IconPlayVideo';
import IconVideos from '../../../../Icons/IconVideos';
import IconCopyright from '../../../../Icons/IconCopyright';
import IconCross from '../../../../Icons/IconCross';
import VerifiedProfile from '../../../VerifiedProfile';

export default function WomanProfile({
    closeWomanProfileModal,
    isModalOpen,
}: {
    closeWomanProfileModal: () => void;
    isModalOpen: boolean;
}) {
    const videoPanel = useRef(null);
    const galleryPanel = useRef(null);
    const sayHelloBtn = useRef(null);
    const sayHelloBtnMob = useRef(null);

    const userState = useSelector((state: RootState) => state.user);
    const womanState = useSelector((state: RootState) => state.woman);

    const [isHelloOpen, setIsHelloOpen] = useState(false);

    const changeIsHelloOpen = (param?: boolean) =>
        param ? setIsHelloOpen(param) : setIsHelloOpen(!isHelloOpen);
    const closeIsHelloOpen = () => setIsHelloOpen(false);

    useOutsideClicker(sayHelloBtn, closeIsHelloOpen, sayHelloBtnMob);

    const touchMove = useTouchMove();
    const initModeration = useInitModeration();
    const {
        galleryContent,
        setGalleryContent,
        nextImage,
        prevImage,
        nextVideo,
        prevVideo,
        setSelectedImage,
        videoContent,
        setVideoContent,
        openGalleryModal,
    } = useInitWomanProfileGallery();

    const {
        profile,
        setProfile,
        chatInfo,
        birthdateString,
        favoriteStatus,
        setFavoriteStatus,
        winkStatus,
        setWinkStatus,
        onlineStatus,
        likeStatus,
        sayHelloList,
    } = useInitWomanProfileInfo({ setGalleryContent, setVideoContent });

    const { onSetLike, setFavorites, onSetWink, askVideo, openChat, openVideo } =
        useInitWomanProfileFunctions({
            favoriteStatus,
            setFavoriteStatus,
            profile,
            setProfile,
            chatInfo,
            likeStatus,
            closeWomanProfileModal,
            sayHelloList,
        });

    const initModalMeetingRequest = useInitModalMeetingRequest();
    const initContactRequestModal = useInitContactRequestModal(profile);
    const handleActiveBlocks = useHandleActiveBlocks();

    const isVideo =
        handleActiveBlocks.isVideoShow &&
        !!(
            videoContent?.length ||
            (Number(userState?.info?.convert_user) &&
            Number(userState?.info?.payment_total) >= USER.PAYMENT_TOTAL_TO_ASK_VIDEO
                ? !profile?.ask_video
                : false)
        );
    const woman = womanState?.list?.find((el) => el?.external_id === profile?.external_id);
    const womanAvatar = woman?.search_img || profile?.search_img || '';

    return (
        <>
            <div className="column-3 profile_man profile_flex">
                {profile?.external_id ? (
                    <>
                        <div className="c3_woman_profile_head">
                            <LetterButton
                                type="woman_profile"
                                profile={profile}
                                closeWomanProfileModal={closeWomanProfileModal}
                                isModalOpen={isModalOpen}
                            />
                            {!handleActiveBlocks.isHideGift && (
                                <GifButton
                                    profile={profile}
                                    closeWomanProfileModal={closeWomanProfileModal}
                                    isModalOpen={isModalOpen}
                                />
                            )}
                            <ChatButton
                                female_id={profile?.external_id}
                                closeWomanProfileModal={closeWomanProfileModal}
                                isModalOpen={isModalOpen}
                            />
                            <button
                                type="button"
                                className={`c3_woman_profile_head_item c3wphi_favs`}
                                onClick={setFavorites}
                            >
                                {favoriteStatus ? <IconFavouritesActive /> : <IconFavourites />}
                                <span className="c3_woman_profile_head_item_text">Favorites</span>
                            </button>
                            <button
                                type="button"
                                className="c3_woman_profile_head_item c3wphi_compl"
                                onClick={() => {
                                    initModeration.openModal(
                                        profile?.public_external_id,
                                        profile?.external_id,
                                    );
                                }}
                            >
                                <IconComplain />
                                <span className="c3_woman_profile_head_item_text">Complain</span>
                            </button>
                        </div>
                        <div className="c3_woman_profile_page_wrap">
                            <div className="c3_woman_profile_page_wrap2">
                                <div className="c3_woman_profile_main_block">
                                    {!!+onlineStatus && (
                                        <div className="c3_woman_profile_online_status">
                                            I’m online now
                                        </div>
                                    )}
                                    {!+winkStatus && (
                                        <button
                                            type="button"
                                            className="c3_woman_profile_main_block_say_hello_btn_mob"
                                            onClick={() => changeIsHelloOpen()}
                                        >
                                            <IconHelloHand />
                                            <span>Say “Hello”</span>
                                        </button>
                                    )}
                                    <div className="c3_woman_profile_main_block_top">
                                        <div className="c3_woman_profile_main_block_photo">
                                            <img src={womanAvatar} alt="user avatar" />
                                        </div>
                                        <div className="c3_woman_profile_main_block_info">
                                            <div className="c3_woman_profile_main_block_info_top">
                                                <div className="c3_woman_profile_main_block_info_top_left">
                                                    <div className="c3_woman_profile_main_block_name">
                                                        {profile?.name ?? ''}, {profile?.age ?? ''}
                                                        <VerifiedProfile />
                                                    </div>
                                                    {+profile?.day_birth > 0 &&
                                                    +profile?.month_birth > 0 &&
                                                    +profile?.year_birth > 0 ? (
                                                        <div className="c3_woman_profile_main_block_dob">
                                                            <div className={`display_inline`}>
                                                                {birthdateString &&
                                                                    moment(birthdateString).format(
                                                                        'MMMM D, YYYY',
                                                                    )}
                                                            </div>
                                                            <span>
                                                                {displayDaysUntilBirthdayOnProfilePage(
                                                                    profile?.month_birth,
                                                                    profile?.day_birth,
                                                                )}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`c3_woman_profile_main_block_dob`}
                                                        >
                                                            <div
                                                                className={`c3lh_your_likes_item_profile_${onlineStatus} display_inline display_online_on_profile_page`}
                                                            >
                                                                {onlineStatus}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="c3_woman_profile_main_block_info_top_left_bottom">
                                                        <div className="c3_woman_profile_main_block_id">
                                                            ID {profile?.public_external_id}
                                                        </div>
                                                        <div className="c3_woman_profile_main_block_place">
                                                            <img
                                                                src={profile?.country_icon ?? ''}
                                                                alt=""
                                                            />
                                                            <span>
                                                                {profile?.city_name ?? ''},{' '}
                                                                {profile?.country_name ?? ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c3_woman_profile_main_block_info_top_right">
                                                    {!+winkStatus && (
                                                        <>
                                                            <div
                                                                className="c3_wp_hello_btn_wrap"
                                                                ref={sayHelloBtn}
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className={`c3_woman_profile_main_block_info_btn say_hello ${isHelloOpen ? 'active' : ''}`}
                                                                    onClick={() =>
                                                                        changeIsHelloOpen()
                                                                    }
                                                                >
                                                                    <IconHelloHand />
                                                                    <span>Say “Hello”</span>
                                                                </button>
                                                                {isHelloOpen && (
                                                                    <div className="c3_wp_hello_list_wrap">
                                                                        <div className="c3_wp_hello_list_title">
                                                                            Choose first message to{' '}
                                                                            {profile?.name ?? ''}
                                                                        </div>
                                                                        <div className="c3_wp_hello_list">
                                                                            {!!sayHelloList?.length &&
                                                                                sayHelloList?.map(
                                                                                    (item, key) => (
                                                                                        <div
                                                                                            className="c3_wp_hello_list_item"
                                                                                            onClick={() => {
                                                                                                onSetWink(
                                                                                                    item,
                                                                                                );
                                                                                                setWinkStatus();
                                                                                                changeIsHelloOpen(
                                                                                                    false,
                                                                                                );
                                                                                            }}
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {item}
                                                                                        </div>
                                                                                    ),
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    {!likeStatus ? (
                                                        <button
                                                            type="button"
                                                            className="c3_woman_profile_main_block_info_btn like_her_btn"
                                                            onClick={onSetLike}
                                                        >
                                                            <IconLikeHerHeart />
                                                            <span>
                                                                {
                                                                    SEX.SEX_TEXT[
                                                                        userState?.info
                                                                            ?.sex_text as
                                                                            | 'FEMALE_SEX'
                                                                            | 'MALE_SEX'
                                                                    ].LIKE_SIDE_MENU_BTN
                                                                }
                                                            </span>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="c3_woman_profile_main_block_info_btn like active"
                                                        >
                                                            <IconLikeHerHeart />
                                                            <span>Liked</span>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="c3_woman_profile_gallery_photo_wrap"
                                                ref={galleryPanel}
                                            >
                                                <div className="c3_woman_profile_gallery_photo">
                                                    <div className="c3_woman_profile_gallery_photo_top_big_list_wrap">
                                                        <div
                                                            className="c3_woman_profile_gallery_photo_top_big_list"
                                                            onTouchStart={
                                                                touchMove.handleTouchStart
                                                            }
                                                            onTouchMove={(e) =>
                                                                touchMove.handleTouchMove(
                                                                    e,
                                                                    () => nextImage(e),
                                                                    () => prevImage(),
                                                                )
                                                            }
                                                        >
                                                            {galleryContent.map((item, key) => {
                                                                const isFreeClass =
                                                                    !item?.payed &&
                                                                    +item?.free === 0
                                                                        ? 'unpaid'
                                                                        : '';
                                                                return (
                                                                    <div key={key}>
                                                                        <div
                                                                            className={`c3_woman_profile_gallery_photo_top_big_item ${isFreeClass}`}
                                                                            onClick={() =>
                                                                                openGalleryModal(
                                                                                    item?.link,
                                                                                    item?.payed ||
                                                                                        +item?.free,
                                                                                    galleryContent,
                                                                                    item?.id,
                                                                                )
                                                                            }
                                                                        >
                                                                            {!item?.payed &&
                                                                                +item?.free ===
                                                                                    0 && (
                                                                                    <div className="popup_likeher_photo_unpaid_warning">
                                                                                        <span>
                                                                                            Private
                                                                                            photo
                                                                                        </span>
                                                                                        {handleActiveBlocks.isSearchV3
                                                                                            ? 'To view member’s private photos, start chatting'
                                                                                            : SEX
                                                                                                  .SEX_TEXT[
                                                                                                  userState
                                                                                                      ?.info
                                                                                                      ?.sex_text as
                                                                                                      | 'MALE_SEX'
                                                                                                      | 'FEMALE_SEX'
                                                                                              ]
                                                                                                  .GALLERY_MODAL_START_CHATTING_TITLE}
                                                                                    </div>
                                                                                )}
                                                                            <img
                                                                                src={getImageLink(
                                                                                    item?.link,
                                                                                    IMAGES.SIZE_M,
                                                                                )}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div
                                                            className="c3_woman_profile_gallery_photo_btn btn_prev"
                                                            onClick={prevImage}
                                                        >
                                                            <IconFAQQuestionBackArrow />
                                                        </div>
                                                        <div
                                                            className="c3_woman_profile_gallery_photo_btn btn_next"
                                                            onClick={nextImage}
                                                        >
                                                            <IconFAQQuestionArrow />
                                                        </div>
                                                    </div>
                                                    <div className="c3_woman_profile_gallery_photo_top_small_list_wrap">
                                                        <div className="c3_woman_profile_gallery_photo_top_small_list">
                                                            {galleryContent.map((item, index) => {
                                                                const isFreeClass =
                                                                    !item?.payed &&
                                                                    +item?.free === 0
                                                                        ? 'unpaid'
                                                                        : '';
                                                                return (
                                                                    <div
                                                                        className={`c3_woman_profile_gallery_photo_top_small_list_item ${isFreeClass}`}
                                                                        key={item?.id}
                                                                        onClick={() =>
                                                                            setSelectedImage(index)
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={getImageLink(
                                                                                item?.link,
                                                                                IMAGES.SIZE_XS,
                                                                            )}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isVideo && (
                                        <div
                                            className="c3_woman_profile_gallery_video_wrap"
                                            ref={videoPanel}
                                        >
                                            <div className="c3_woman_profile_page_title">
                                                videos ({videoContent?.length})
                                            </div>
                                            <div className="c3_woman_profile_gallery_video_list_wrap">
                                                <div className="c3_woman_profile_gallery_video_list">
                                                    {videoContent?.length
                                                        ? videoContent?.map(
                                                              (item: any, key: number) => {
                                                                  return (
                                                                      <div
                                                                          key={key}
                                                                          className="c3_woman_profile_gallery_video_list_item"
                                                                      >
                                                                          <GirlVideo
                                                                              item={item}
                                                                              openVideoModal={
                                                                                  openVideo
                                                                              }
                                                                              type="simple"
                                                                          />
                                                                      </div>
                                                                  );
                                                              },
                                                          )
                                                        : !+profile?.ask_video && (
                                                              <div className="c3_woman_profile_empty_video">
                                                                  <div className="c3_woman_profile_empty_video_title">
                                                                      <IconPlayVideo />
                                                                      <span>
                                                                          {
                                                                              SEX.SEX_TEXT[
                                                                                  userState?.info
                                                                                      ?.sex_text as
                                                                                      | 'MALE_SEX'
                                                                                      | 'FEMALE_SEX'
                                                                              ]
                                                                                  .WOMAN_PROFILE_MODAL_ASK_VIDEO_TITLE
                                                                          }
                                                                      </span>
                                                                  </div>
                                                                  <div
                                                                      className="c3_woman_profile_empty_video_btn"
                                                                      onClick={askVideo}
                                                                  >
                                                                      <IconVideos />
                                                                      <span>Ask now</span>
                                                                  </div>
                                                              </div>
                                                          )}
                                                </div>

                                                {videoContent?.length > 1 && (
                                                    <div
                                                        className="c3_woman_profile_gallery_photo_btn btn_prev"
                                                        onClick={prevVideo}
                                                    >
                                                        <IconFAQQuestionBackArrow />
                                                    </div>
                                                )}
                                                {videoContent?.length > 1 && (
                                                    <div
                                                        className="c3_woman_profile_gallery_photo_btn btn_next"
                                                        onClick={nextVideo}
                                                    >
                                                        <IconFAQQuestionArrow />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="c3_woman_profile_page_title_cost">
                                                <IconCopyright />
                                                <span>
                                                    You can view one video per day for free. Each
                                                    additional video costs{' '}
                                                    {convertPrice(
                                                        Number(
                                                            userState?.prices?.[
                                                                PRICES.GET_VIDEO_SHOW
                                                            ],
                                                        ) || 0,
                                                    )}{' '}
                                                    credits
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="c3_woman_profile_mobile_buttons">
                                        <LetterButton
                                            type="woman_profile"
                                            profile={profile}
                                            closeWomanProfileModal={closeWomanProfileModal}
                                            isModalOpen={isModalOpen}
                                        />
                                        {!handleActiveBlocks.isHideGift && (
                                            <GifButton
                                                profile={profile}
                                                closeWomanProfileModal={closeWomanProfileModal}
                                                isModalOpen={isModalOpen}
                                            />
                                        )}
                                        {!!(
                                            !handleActiveBlocks.isNewMailCategory1 &&
                                            initContactRequestModal.enabledContactRequest &&
                                            handleActiveBlocks.isContactRequest
                                        ) && (
                                            <button
                                                type="button"
                                                className="c3_woman_profile_mob_btn contact"
                                                onClick={initContactRequestModal.openContactRequest}
                                            >
                                                Contact request
                                            </button>
                                        )}
                                        {!!(
                                            !handleActiveBlocks.isNewMailCategory1 &&
                                            handleActiveBlocks.isMeetingRequest
                                        ) && (
                                            <button
                                                type="button"
                                                className="c3_woman_profile_mob_btn meeting"
                                                onClick={initModalMeetingRequest.openMeetingModal}
                                            >
                                                Meeting request
                                            </button>
                                        )}
                                        {favoriteStatus ? (
                                            <button
                                                type="button"
                                                className="c3_woman_profile_mob_btn active"
                                                onClick={setFavorites}
                                            >
                                                <IconFavouritesActive />
                                                <span>Added to favorites</span>
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="c3_woman_profile_mob_btn"
                                                onClick={setFavorites}
                                            >
                                                <IconFavourites />
                                                <span>Add to favorites</span>
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="c3_woman_profile_mob_btn complain"
                                            onClick={() => {
                                                initModeration.openModal(
                                                    profile?.public_external_id,
                                                    profile?.external_id,
                                                );
                                            }}
                                        >
                                            <IconComplain />
                                            <span>Complain</span>
                                        </button>
                                    </div>
                                </div>
                                {!!(
                                    handleActiveBlocks.isNewMailCategory1 &&
                                    ((initContactRequestModal.enabledContactRequest &&
                                        handleActiveBlocks.isContactRequest) ||
                                        handleActiveBlocks.isMeetingRequest)
                                ) && (
                                    <div className="c3_woman_profile_second_block middle">
                                        <div className="c3_woman_profile_second_block_row">
                                            {!!(
                                                initContactRequestModal.enabledContactRequest &&
                                                handleActiveBlocks.isContactRequest
                                            ) && (
                                                <div className="c3_woman_profile_tabs_wrap">
                                                    <div className="c3_woman_profile_main_button_wrap">
                                                        <button
                                                            type="button"
                                                            className="c3_woman_profile_main_button"
                                                            onClick={
                                                                initContactRequestModal.openContactRequest
                                                            }
                                                        >
                                                            <span>Contact request</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {handleActiveBlocks.isMeetingRequest && (
                                                <div className="c3_woman_profile_tabs_wrap">
                                                    <div className="c3_woman_profile_main_button_wrap">
                                                        <button
                                                            type="button"
                                                            className="c3_woman_profile_main_button"
                                                            onClick={
                                                                initModalMeetingRequest.openMeetingModal
                                                            }
                                                        >
                                                            <span>Meeting request</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="c3_woman_profile_second_block">
                                    <div className="c3_woman_profile_second_block_row">
                                        <div className="c3_woman_profile_main_block_list_flex_wrap">
                                            <div className="c3_woman_profile_main_block_list">
                                                <div className="c3_woman_profile_main_block_list_title">
                                                    Appearance
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Height: <span>{profile?.height_sm} Cm</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    body type: <span>{profile?.body_type}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Hair color: <span>{profile?.hair_color}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Eyes color: <span>{profile?.eye}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Zodiac: <span>{profile?.zodiac_sign}</span>
                                                </div>
                                            </div>
                                            <div className="c3_woman_profile_main_block_list">
                                                <div className="c3_woman_profile_main_block_list_title">
                                                    Social information
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Education: <span>{profile?.education}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Job or Profession:{' '}
                                                    <span>{profile?.occupation}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Religion: <span>{profile?.religion}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Marital status: <span>{profile?.marital}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Number of children:{' '}
                                                    <span>{profile?.children}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Want to have more children:{' '}
                                                    <span> {profile?.morechildren}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Purpose of staying on the site:{' '}
                                                    <span>{profile?.reason}</span>
                                                </div>
                                            </div>
                                            <div className="c3_woman_profile_main_block_list">
                                                <div className="c3_woman_profile_main_block_list_title">
                                                    Hobby and habits
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Hobby:{' '}
                                                    <span>{profile?.hobbies?.join(', ')} </span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Alcohol: <span>{profile?.alcohol}</span>
                                                </div>
                                                <div className="c3_woman_profile_main_block_list_item">
                                                    Smoking: <span>{profile?.smoking}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="c3_woman_profile_tabs_wrap">
                                            <div
                                                className="c3_woman_profile_tab_block active"
                                                id="tab_about"
                                            >
                                                <div className="c3_woman_profile_tab_about">
                                                    <div className="c3_woman_profile_tab_about_item">
                                                        <div className="c3_woman_profile_tab_about_item_title">
                                                            MY self-summary
                                                        </div>
                                                        <div className="c3_woman_profile_tab_about_item_text">
                                                            {profile?.about}
                                                        </div>
                                                        <div className="c3_woman_profile_tab_about_item_more_btn">
                                                            Read more
                                                        </div>
                                                    </div>
                                                    {SITE.ID !== 4 && (
                                                        <div className="c3_woman_profile_tab_about_item">
                                                            <div className="c3_woman_profile_tab_about_item_title">
                                                                Who Am I looking for
                                                            </div>
                                                            <div className="c3_woman_profile_tab_about_item_text">
                                                                {profile?.seeking}
                                                            </div>
                                                            <div className="c3_woman_profile_tab_about_item_more_btn">
                                                                Read more
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <GirlsSliderProfile womanExternalID={+profile?.external_id} />
                                </div>
                                <div className="c3_woman_profile_mob_nav">
                                    <ChatButton
                                        female_id={profile?.external_id}
                                        closeWomanProfileModal={closeWomanProfileModal}
                                        isModalOpen={isModalOpen}
                                    />
                                    {!likeStatus ? (
                                        <button
                                            type="button"
                                            className="c3_woman_profile_mob_nav_like_btn"
                                            onClick={onSetLike}
                                        >
                                            <IconLikeHerHeart />
                                            <span>
                                                {
                                                    SEX.SEX_TEXT[
                                                        userState?.info?.sex_text as
                                                            | 'MALE_SEX'
                                                            | 'FEMALE_SEX'
                                                    ].LIKE_SIDE_MENU_BTN
                                                }
                                            </span>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="c3_woman_profile_mob_nav_like_btn active"
                                        >
                                            <IconLikeHerHeart />
                                            <span>Liked</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <Loader />
                )}
            </div>
            <div className="pap-btn-close" onClick={closeWomanProfileModal}>
                <IconCross />
            </div>
            {isHelloOpen && (
                <div className="c3_wp_hello_list_wrap_mob">
                    <div className="c3_wp_hello_list_wrap" ref={sayHelloBtnMob}>
                        <div
                            className="c3_wp_hello_list_wrap_close"
                            onClick={() => changeIsHelloOpen()}
                        />
                        <div className="c3_wp_hello_list_title">
                            Choose first message to {profile?.name ?? ''}
                        </div>
                        <div className="c3_wp_hello_list">
                            {!!sayHelloList &&
                                sayHelloList.map((item, key) => (
                                    <div
                                        className="c3_wp_hello_list_item"
                                        key={key * 100}
                                        onClick={() => {
                                            onSetWink(item);
                                            changeIsHelloOpen(false);
                                        }}
                                    >
                                        {item}
                                    </div>
                                ))}
                        </div>
                        <div className="c3_wp_hello_list_to_chat_btn" onClick={openChat}>
                            Start chatting
                        </div>
                    </div>
                </div>
            )}
            <ModerationModal {...initModeration} />

            {!!profile && <MeetingRequestModal {...initModalMeetingRequest} profile={profile} />}
            {!!profile && <ContactRequestModal {...initContactRequestModal} profile={profile} />}
        </>
    );
}
