import React from 'react';
import useHandlePayment from '../../../../hooks/purchase/useHandlePayment';
import IconCopyright from '../../../Icons/IconCopyright';

export default function GetCreditsButton() {
    const handlePayment = useHandlePayment();

    return (
        <>
            {/* eslint-disable-next-line no-undef */}
            <div
                className="c3_profile_credits_balance_get_creds_btn"
                onClick={handlePayment.onOpenPayment}
            >
                <IconCopyright />
                <span>
                    Get <span>credits now</span>
                </span>
            </div>
        </>
    );
}
