import { RequestsActionTypes } from '../../constants/requests';
import RequestsAPI from '../../../services/api/Requests';
import { Dispatch } from 'redux';

export function openContactRequest(profile: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: RequestsActionTypes.SHOW_CONTACT_REQUEST_MODAL,
            data: profile,
        });
    };
}

export function closeContactRequest() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: RequestsActionTypes.CLOSE_CONTACT_REQUEST_MODAL,
        });
    };
}

export function getActiveContactRequests() {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new RequestsAPI().getActiveContactRequests().then((res) => {
            if (res?.data && res?.data?.status) {
                return dispatch({
                    type: RequestsActionTypes.SET_ACTIVE_CONTACT_REQUESTS,
                    data: res?.data?.response,
                });
            }
        });
    };
}

export function isEnabledContactRequest(
    female_external_id: number,
    activeContactRequests: any[] = [],
) {
    const contactRequest = activeContactRequests.find(
        (item) => +item?.female_external_id === +female_external_id,
    );
    return !contactRequest;
}
