import React from 'react';
import { ICreditCardsListProps } from './index.interface';
import credit_card from '../../../../../img/manage-credit-card-modal/credit-card-img.svg';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconTrash from '../../../../Icons/IconTrash';
import IconCirclePlus from '../../../../Icons/IconCirclePlus';
import IconCross from '../../../../Icons/IconCross';

export default function CreditCardsList({
    fetchTokenList,
    openRemoveCreditCardAlert,
    createToken,
    selectDefaultCard,
    closeModal,
    hideAddNewCardButton = false,
}: ICreditCardsListProps) {
    return (
        <div className="popup_your_credit_cards">
            <div className="popup_your_credit_cards_wrap">
                <div className="popup_your_credit_cards_title">Your credit cards</div>
                {
                    <div className="popup_your_credit_cards_list">
                        {fetchTokenList &&
                            fetchTokenList?.length > 0 &&
                            fetchTokenList
                                ?.filter((item) => item?.status === 1)
                                ?.map((item, key) => (
                                    <div
                                        className={
                                            +item?.default_card === 1
                                                ? 'popup_your_credit_cards_current_card'
                                                : 'popup_your_credit_cards_list_item'
                                        }
                                        key={key}
                                    >
                                        <div
                                            className={
                                                +item?.default_card === 1
                                                    ? 'popup_your_credit_cards_current_card_left'
                                                    : 'popup_your_credit_cards_list_item_left'
                                            }
                                        >
                                            <div
                                                className={
                                                    +item?.default_card === 1
                                                        ? 'popup_your_credit_cards_current_card_input'
                                                        : 'popup_your_credit_cards_list_item_input'
                                                }
                                                onClick={() =>
                                                    selectDefaultCard(
                                                        item,
                                                        +item?.default_card === 1,
                                                    )
                                                }
                                            />
                                            <img
                                                src={credit_card}
                                                alt="credit card image"
                                                className="popup_your_credit_cards_current_card_img"
                                            />
                                            <span className="popup_your_credit_cards_current_card_text">
                                                {item?.number}
                                            </span>
                                        </div>
                                        {+item?.default_card === 1 && (
                                            <div className="popup_your_credit_cards_current_card_info_text">
                                                <IconCheckedCircle />
                                                <span>Current card</span>
                                            </div>
                                        )}
                                        <div
                                            className="popup_your_credit_cards_list_item_remove"
                                            onClick={() => openRemoveCreditCardAlert(item)}
                                        >
                                            <IconTrash />
                                        </div>
                                    </div>
                                ))}
                    </div>
                }
                <div className="popup_your_credit_cards_buttons">
                    {!hideAddNewCardButton && (
                        <>
                            <div
                                className="popup_your_credit_cards_add_card_btn"
                                onClick={createToken}
                            >
                                <IconCirclePlus />
                                <span>Add new card</span>
                            </div>
                        </>
                    )}
                    <div className="popup_your_credit_cards_save_changes_btn" onClick={closeModal}>
                        <IconCheckedCircle />
                        <span>Ok</span>
                    </div>
                </div>
            </div>
            <div className="close_one_popup" onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
