import { useDispatch, useSelector } from 'react-redux';
import * as chatsAction from '../../../store/actions/chats';
import { switchChatList } from '../../../services/methods';
import React, { useState } from 'react';
import * as MENU from '../../../constants/menu';
import { RootState } from '../../../index';

export default function useScrollLoadChatList() {
    const dispatch = useDispatch();

    const chatsState = useSelector((state: RootState) => state.chats);
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (event: React.UIEvent<HTMLDivElement>) => {
        if (menuState.sub === MENU.MENU_BLOCK) return;

        const target = event?.target as HTMLElement;

        if (
            !isLoading &&
            !chatsState?.isLoadedAllChats &&
            Math.round(target?.scrollTop + target?.offsetHeight) >= target?.scrollHeight
        ) {
            setIsLoading(true);

            if (menuState.main === MENU.MENU_BLOCK) {
                dispatch(
                    chatsAction.getBlockListByUserId(
                        chatsState?.onlineStatus,
                        chatsState?.searchInput,
                        +chatsState?.page + 1,
                        false,
                        () => setIsLoading(false),
                    ),
                );
            } else {
                if (userState?.info?.external_id) {
                    dispatch(
                        chatsAction.getChatListByPage(
                            userState?.info?.external_id,
                            chatsState?.onlineStatus,
                            chatsState?.searchInput,
                            switchChatList(menuState?.sub),
                            +chatsState?.page + 1,
                            () => setIsLoading(false),
                        ),
                    );
                }
            }
        }
    };
}
