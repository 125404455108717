import React from 'react';
import { animated, interpolate, useSpring, useSprings } from 'react-spring';
import { useGesture } from 'react-with-gesture';
import { renderLastMessage } from '../../../../../services/methods';
import * as SYNC from '../../../../../constants/sync';
import styles from '../styles.module.scss';
import { useSelector } from 'react-redux';
import IconCross from '../../../../Icons/IconCross';
import IconChain from '../../../../Icons/IconChain';
import IconHeartMobile from '../../../../Icons/IconHeartMobile';
import IconProfileChat from '../../../../Icons/IconProfileChat';
import useTouchMove from '../../../../../hooks/dom/useTouchMove';
import { RootState } from '../../../../../index';
import { INotifyItem } from '../../../../../store/reducers/notify/index.interface';
import useHandleMobileNotification from '../../../../../hooks/notify/useHandleMobileNotification';

//showAnimation
const showSettings = {
    to: () => ({ x: 0, y: 0, scale: 1, rot: -10 + Math.random() * 20, delay: 0 }),
    from: () => ({ x: 0, rot: 0, scale: 1.5, y: -1000 }),
};

export default function Item({ item }: { item: INotifyItem }) {
    const userState = useSelector((state: RootState) => state.user);

    const touchMove = useTouchMove(100, 100);
    const handleMobileNotification = useHandleMobileNotification();
    const [bind, { delta, down }] = useGesture();
    const [showAnimation] = useSprings(1, () => ({
        ...showSettings.to(),
        from: showSettings.from(),
    }));
    const { x, size } = useSpring({ x: down ? delta[0] : 0, size: down ? 1.1 : 1 });

    const avSize = x.interpolate({
        // @ts-expect-error map: Math.abs
        map: Math.abs,
        range: [50, 300],
        output: ['scale(0.5)', 'scale(1)'],
        extrapolate: 'clamp',
    });

    return (
        <animated.div
            {...bind()}
            className={`item ${styles.item}`}
            style={{
                transform: interpolate(
                    [showAnimation[0].x, showAnimation[0].y],
                    (x, y) => `translate3d(${x}px,${y}px,0)`,
                ),
            }}
        >
            <animated.div
                className="av"
                style={{ transform: avSize, justifySelf: delta[0] < 0 ? 'end' : 'start' }}
            />
            <animated.div
                className="fg"
                style={{
                    // @ts-expect-error [x, size]
                    transform: interpolate([x, size], (x, s) => {
                        return `translate3d(${x > 0 ? x * 2 : 0}px,0,0) scale(${s})`;
                    }),
                }}
            >
                <div
                    className={`${styles.mob_notify_list_fixed_item} mob_notify_list_fixed_item`}
                    id={`mobile-notify-${item.chat_uid}`}
                    style={item.hide ? { display: 'none' } : {}}
                    onClick={() => handleMobileNotification.openChat(item)}
                    onTouchStart={(event) => touchMove.handleTouchStart(event as any, null)}
                    onTouchMove={(e) =>
                        touchMove.handleTouchMove(
                            e,
                            () => handleMobileNotification.removeNotification(e, item.chat_uid),
                            () => handleMobileNotification.removeNotification(e, item.chat_uid),
                        )
                    }
                >
                    {!SYNC.TRANSACTION_NOTIFY_LIST.includes(item?.notification_type) ? (
                        <>
                            <div
                                className={`mob_notify_list_fixed_item_photo ${item.online ? 'online' : ''}`}
                            >
                                <img src={item.photo_link} alt="" />
                                {item?.favorite && (
                                    <div className="mob_notify_list_fixed_item_photo_fav" />
                                )}
                            </div>
                            <div className="mob_notify_list_fixed_item_right">
                                <div className="mob_notify_list_fixed_item_name">{`${item.name}, ${item.age}`}</div>
                                <div className="mob_notify_list_fixed_item_text">
                                    {renderLastMessage(
                                        item?.message_type,
                                        item?.message_content,
                                        false,
                                        item?.name,
                                    )}
                                </div>
                            </div>
                            <div
                                className="mob_notify_list_fixed_item_close"
                                onClick={(e) =>
                                    handleMobileNotification.removeNotification(e, item.chat_uid)
                                }
                            />
                        </>
                    ) : (
                        (function () {
                            switch (item?.notification_type) {
                                case SYNC.NOTIFY_NEW_GIRL_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.joined}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p
                                                    className={styles.mob_notify_name}
                                                >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                                <p className={styles.mob_notify_text}>
                                                    New member’s joined the site
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_STRANGER_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.unknown_like}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap} />
                                            <div className={styles.mob_notify_right}>
                                                <p className={styles.mob_notify_text}>
                                                    This member liked your profile
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_POSSIBLE_MATCH_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.ideal_match}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <p className={styles.mob_notify_ideal_text}>
                                                We’ve found the ideal match for you
                                            </p>
                                            <div className={styles.mob_notify_ideal_right}>
                                                {userState?.info?.photo_link ? (
                                                    <img
                                                        src={userState?.info?.photo_link}
                                                        alt="user avatar"
                                                        className={
                                                            styles.mob_notify_ideal_man_photo
                                                        }
                                                    />
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.mob_notify_ideal_man_photo_placeholder
                                                        }
                                                    >
                                                        <IconProfileChat />
                                                    </div>
                                                )}

                                                <IconChain />
                                                <div
                                                    className={
                                                        styles.mob_notify_ideal_woman_photo_wrap
                                                    }
                                                >
                                                    <img
                                                        src={item?.photo_link ?? ''}
                                                        className={
                                                            styles.mob_notify_ideal_woman_photo
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_PROFILE_UPDATE_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.updated}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p
                                                    className={styles.mob_notify_name}
                                                >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                                <p className={styles.mob_notify_text}>
                                                    {item?.name ?? ''} has updated content in her...
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_SEARCH_PARTNER_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.searching}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p className={styles.mob_notify_name}>
                                                    {`${item?.name ?? ''}, ${item?.age ?? ''}`}
                                                </p>
                                                <p className={styles.mob_notify_text}>
                                                    {item?.name ?? ''} is searching for a partner
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_VIEW_PROFILE_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.viewed}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p
                                                    className={styles.mob_notify_name}
                                                >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                                <p className={styles.mob_notify_text}>
                                                    {item?.name ?? ''} viewed your profile
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_LIKE_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.liked}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p
                                                    className={styles.mob_notify_name}
                                                >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                                                <p className={styles.mob_notify_text}>
                                                    {item?.name ?? ''} liked your profile
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_MATCH_ACTION:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.each_like}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openChat(item);
                                            }}
                                        >
                                            <div
                                                className={
                                                    styles.mob_notify_activity_each_like_left
                                                }
                                            >
                                                You like each other
                                            </div>
                                            <div
                                                className={
                                                    styles.mob_notify_activity_each_like_right
                                                }
                                            >
                                                {userState?.info?.photo_link ? (
                                                    <img
                                                        src={userState?.info?.photo_link}
                                                        alt="user avatar"
                                                        className={styles.each_like_man_photo}
                                                    />
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.each_like_man_photo_placeholder
                                                        }
                                                    >
                                                        <IconProfileChat />
                                                    </div>
                                                )}

                                                <IconHeartMobile />
                                                <div className={styles.each_like_woman_photo_wrap}>
                                                    <img
                                                        src={item?.photo_link ?? ''}
                                                        alt=""
                                                        className={styles.each_like_woman_photo}
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_each_like_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_ONLINE_NOW:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.online_hi}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p className={styles.mob_notify_text}>
                                                    <span>{item?.name ?? ''} is online now!</span>
                                                    <br />
                                                    Maybe you want to say hi?
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_MAYBE_APPEAL:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.appeal}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p className={styles.mob_notify_text}>
                                                    Maybe {item?.name ?? ''}
                                                    <span> appeals </span> to you
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_NO_CHAT:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.no_chat}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p className={styles.mob_notify_text}>
                                                    {item?.name ?? ''} <span>has no chats</span>{' '}
                                                    now. Wanna say hi?
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                case SYNC.NOTIFY_MAYBE_CHAT:
                                    return (
                                        <div
                                            className={`${styles.mob_notify_activity} ${styles.want_chat}`}
                                            onClick={(e) => {
                                                handleMobileNotification.removeNotification(
                                                    e,
                                                    item?.chat_uid,
                                                );
                                                handleMobileNotification.openWomanProfile(item);
                                            }}
                                        >
                                            <div className={styles.mob_notify_photo_wrap}>
                                                <img
                                                    src={item?.photo_link ?? ''}
                                                    className={styles.mob_notify_photo}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.mob_notify_right}>
                                                <p className={styles.mob_notify_text}>
                                                    {item?.name ?? ''} <span>has no chats</span>{' '}
                                                    now. Wanna say hi?
                                                </p>
                                            </div>
                                            <button
                                                type="button"
                                                className={styles.mob_notify_close}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleMobileNotification.removeNotification(
                                                        e,
                                                        item?.chat_uid,
                                                    );
                                                }}
                                            >
                                                <IconCross />
                                            </button>
                                        </div>
                                    );

                                default:
                                    return <div />;
                            }
                        })()
                    )}
                </div>
            </animated.div>
        </animated.div>
    );
}
