import React from 'react';
import '../../styles/love-stories/love-stories-global.scss';
import LoveStoriesBanner from '../../components/LoveStories/LoveStoriesBanner';
import LoveStoriesPhotoSlider from '../../components/LoveStories/LoveStoriesPhotoSlider';
import LoveStoriesVideoSlider from '../../components/LoveStories/LoveStoriesVideoSlider';
import LoveStoriesComment from '../../components/LoveStories/LoveStoriesComment';
import LoveStoriesProfiles from '../../components/LoveStories/LoveStoriesProfiles';

export default function LoveStoriesPage() {
    return (
        <div className="ls_content_bg">
            <LoveStoriesBanner />
            <LoveStoriesPhotoSlider />
            <LoveStoriesVideoSlider />
            <LoveStoriesComment />
            <LoveStoriesProfiles />
        </div>
    );
}
