import { useDispatch, useSelector } from 'react-redux';
import * as searchAction from '../../store/actions/search';
import * as videoShowAction from '../../store/actions/videoShow';
import * as onlineNowAction from '../../store/actions/onlineNow';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import { RootState } from '../../index';

export default function useActionLike() {
    const dispatch = useDispatch();

    const searchState = useSelector((state: RootState) => state.search);
    const videoShowState = useSelector((state: RootState) => state.videoShow);
    const onlineNowState = useSelector((state: RootState) => state.onlineNow);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const chatsState = useSelector((state: RootState) => state.chats);

    const callbackAfterLike = (womanExternalID: number) => {
        const isExistSearch = searchState?.list?.some(
            (item) => 'external_id' in item && item?.external_id === womanExternalID,
        );

        const isExistVideoShow = videoShowState?.list?.some(
            (item) => item?.external_id === womanExternalID,
        );

        const isExistOnlineNow = onlineNowState?.list?.some(
            (item) => 'external_id' in item && item?.external_id === womanExternalID,
        );

        const isExistCurrentChat = currentChatState?.info?.public_external_id === womanExternalID;
        const isExistChatList = chatsState?.chatList?.some(
            (item) => item?.external_id === womanExternalID,
        );

        if (isExistSearch) {
            dispatch(searchAction.setSearchLikeStatus(womanExternalID));
        }

        if (isExistVideoShow) {
            dispatch(videoShowAction.setVideoShowLikeStatus(womanExternalID));
        }

        if (isExistOnlineNow) {
            dispatch(onlineNowAction.setOnlineNowLikeStatus(womanExternalID));
        }

        if (isExistCurrentChat) {
            dispatch(currentChatAction.setCurrentChatLikeStatus());
        }

        if (isExistChatList) {
            dispatch(chatsAction.setChatListLikeStatus(womanExternalID));
        }
    };

    return { callbackAfterLike };
}
