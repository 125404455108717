import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import moment from 'moment';
import useTransition from '../transition/useTransition';
import * as likesAction from '../../store/actions/likes';
import { RootState } from '../../index';

export default function useHandleLikeHistory(isReverse = false) {
    const likesState = useSelector((state: RootState) => state.likes);

    const transition = useTransition();
    const dispatch = useDispatch();

    const customOffset = isReverse ? 100 * 2 : 460 * 2;

    const diffLikeHistoryDate = (dateCreated?: string) => {
        return moment().diff(moment(dateCreated), 'days');
    };

    const filterByDateLikeHistory = (filterType = '') => {
        switch (filterType) {
            case 'today':
                return likesState?.likeHistory?.filter(
                    (item) => diffLikeHistoryDate(item?.created_at) === 0,
                );
            case 'yesterday':
                return likesState?.likeHistory?.filter(
                    (item) => diffLikeHistoryDate(item?.created_at) === 1,
                );
            default:
                return likesState?.likeHistory?.filter(
                    (item) => diffLikeHistoryDate(item?.created_at) > 1,
                );
        }
    };

    const handleScroll = (event: React.UIEvent<HTMLInputElement>) => {
        const target = event?.target as HTMLInputElement;
        const getNextPage =
            target?.scrollTop > target?.scrollHeight - target?.offsetHeight - customOffset;

        if (
            getNextPage &&
            !likesState?.isLoadingLikeHistoryPage &&
            !likesState?.isLoadedAllLikeHistory
        ) {
            dispatch(likesAction.setLikeHistoryPage(+likesState?.likeHistoryPage + 1));
        }
    };

    const redirectLikeHer = () => {
        transition.transitionWithHistoryPush('like', `/like`);
    };

    useEffect(() => {
        return () => {
            dispatch(likesAction.initSearchState());
        };
    }, []);

    useEffect(() => {
        dispatch(likesAction.getLikeHistory(likesState?.likeHistoryPage, isReverse));
    }, [likesState?.likeHistoryPage]);

    return {
        filterByDateLikeHistory,
        handleScroll,
        redirectLikeHer,
    };
}
