import { TermsActionTypes } from '../../constants/terms';
import { ITermsState, TermsActions } from './index.interface';

const initState = {
    isActiveModal: false,
    pageName: null,
};

export default function (state: ITermsState = initState, action: TermsActions): ITermsState {
    switch (action.type) {
        case TermsActionTypes.TERMS_OPEN_MODEL:
            return {
                ...state,
                ...{
                    isActiveModal: true,
                    pageName: action?.pageName,
                },
            };

        case TermsActionTypes.TERMS_CLOSE_MODEL:
            return {
                ...state,
                ...{
                    isActiveModal: false,
                    pageName: null,
                },
            };

        case TermsActionTypes.TERMS_OPEN_PAGE:
            return {
                ...state,
                ...{
                    pageName: action?.pageName,
                },
            };

        default:
            return state;
    }
}
