import React from 'react';

export default function IconTutorial() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4224 2.51129C14.3819 2.50387 14.3401 2.5 14.2974 2.5C14.2547 2.5 14.2129 2.50387 14.1723 2.51129H8.3031C5.96923 2.50291 4.05607 4.39951 4.00107 6.7783L4.00087 6.79521V17.0341C3.95365 19.4472 5.83143 21.4457 8.19984 21.4994L8.21549 21.4996L8.23203 21.4997L8.25288 21.4999C8.26759 21.5 8.28796 21.5001 8.31122 21.4996H15.7975C15.807 21.4996 15.8165 21.4994 15.826 21.499C18.1689 21.402 20.0153 19.4296 19.9999 17.0391V8.27482C19.9999 8.08964 19.9297 7.91163 19.8038 7.77805L15.0516 2.73185C14.9189 2.59094 14.7356 2.51129 14.544 2.51129H14.4224ZM13.5937 3.94596H8.30181H8.2991C6.73312 3.93982 5.44928 5.21024 5.40825 6.80411V17.0416L5.40808 17.0572C5.37341 18.6807 6.63464 20.0262 8.22636 20.065L8.24434 20.0651L8.26442 20.0653L8.27583 20.0653L8.27842 20.0653C8.28598 20.0651 8.29424 20.0649 8.30181 20.0649H15.7825C17.3617 19.9923 18.6039 18.6599 18.5925 17.0467L18.5925 17.0416L18.5925 9.1798H16.7068L16.7048 9.1798C14.9847 9.17486 13.5937 7.75325 13.5937 5.99963V3.94596ZM17.8219 7.74514H16.7079C15.7638 7.74195 15.0011 6.96201 15.0011 5.99963V4.7498L17.8219 7.74514ZM8.35178 15.2767C8.35178 14.8805 8.66683 14.5593 9.05546 14.5593H14.122C14.5106 14.5593 14.8257 14.8805 14.8257 15.2767C14.8257 15.6728 14.5106 15.994 14.122 15.994H9.05546C8.66683 15.994 8.35178 15.6728 8.35178 15.2767ZM9.05455 10.9703C8.66591 10.9703 8.35086 11.2914 8.35086 11.6876C8.35086 12.0838 8.66591 12.405 9.05455 12.405H12.2033C12.592 12.405 12.907 12.0838 12.907 11.6876C12.907 11.2914 12.592 10.9703 12.2033 10.9703H9.05455Z"
            />
        </svg>
    );
}
