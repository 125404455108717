import React from 'react';

export default function IconChecked() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="14"
            viewBox="0 0 21 14"
            fill="white"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.6199 0.363412C21.1267 0.840269 21.1267 1.62478 20.6199 2.10163L8.35831 13.6385C8.10491 13.8769 7.76976 14 7.43461 14C7.09946 14 6.76431 13.8769 6.5109 13.6385L0.380109 7.87007C-0.126703 7.39321 -0.126703 6.60871 0.380109 6.13185C0.886921 5.65499 1.72071 5.65499 2.22752 6.13185L7.43461 11.0312L18.7725 0.363412C19.2793 -0.121137 20.1131 -0.121137 20.6199 0.363412Z"
            />
        </svg>
    );
}
