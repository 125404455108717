import { useEffect, useRef, useState } from 'react';
import ProfileApi from '../../../services/api/ProfileApi';
import * as modalsAction from '../../../store/actions/modals';
import { useDispatch } from 'react-redux';
import { checkDate, checkDaysAmount } from '../../../services/methods';
import * as userAction from '../../../store/actions/user';
import useHandleProfileAvatar from './useHandleProfileAvatar';
import useHandleProfilePhotos from './useHandleProfilePhotos';
import * as PROFILE from '../../../constants/profile';
import * as MENU from '../../../constants/menu';
import { useHistory } from 'react-router-dom';
import * as REGISTER from '../../../constants/register';
import * as alertAction from '../../../store/actions/alert';
import UserApi from '../../../services/api/UserApi';
import * as ALERT from '../../../constants/alert';
import * as ERROR from '../../../constants/error';
import {
    IGetMainInfo,
    IManProfileReferenceCountryItem,
} from '../../../models/profile/ManProfile.interface';
import {
    IUseHandleMainInfo,
    IUseHandleMainData,
    ISubscribedData,
    ChangeInputParams,
} from './index.interface';

export default function useHandleMainInfo({
    mainInfo,
    setMainInfo,
    saveEdits,
}: IUseHandleMainData): IUseHandleMainInfo {
    const dispatch = useDispatch();
    const history = useHistory();
    const [editedData, setEditedData] = useState<any | null>(null);
    const [subscribedData, setSubscribedData] = useState<ISubscribedData | null>(null);
    const [timerID, setTimerID] = useState<NodeJS.Timeout | number>(0);
    const [isLoyaltyProgramModal, setIsLoyaltyProgramModal] = useState(false);

    const inputUploadPhoto = useRef<HTMLDivElement | null>(null);

    const handleProfileAvatar = useHandleProfileAvatar({ mainInfo, setMainInfo });
    const handleProfilePhotos = useHandleProfilePhotos({
        mainInfo,
        setMainInfo,
        saveEdits,
        ...handleProfileAvatar,
    });

    const openLoyaltyProgramModal = () => setIsLoyaltyProgramModal(true);
    const closeLoyaltyProgramModal = () => setIsLoyaltyProgramModal(false);

    // Open upload files modal
    const showInputFile = () => inputUploadPhoto?.current?.click();

    // Open manage email modal
    const openManageEmailModal = () => dispatch(modalsAction.openEmailEdit());

    // Open purchase credit modal
    const openModalPurchaseCredit = () => dispatch(userAction.setCredit(true));

    const onChangeInputValue = ({ value, field, section }: ChangeInputParams) => {
        setMainInfo({ ...(mainInfo as IGetMainInfo), ...{ [field]: value } });
        setEditedData({
            ...(editedData as IGetMainInfo),
            [section]: { ...(editedData?.[section] as any), [field]: value },
        });
    };

    /**
     * Method to change profile birthday
     * @param field (String)
     * @param value (String)
     */
    const handleChangeBirthday = (value: number, field: string) => {
        const userBirthDate = {
            day: mainInfo?.day || 0,
            month: mainInfo?.month || 0,
            year: mainInfo?.year || 0,
            [field]: value,
        };

        if (!checkDate(userBirthDate.year, userBirthDate.month, userBirthDate.day)) {
            return dispatch(
                alertAction.setMessage({
                    message: `You must be at least ${REGISTER.MIN_AGE} years old.`,
                }),
            );
        }

        const isValidData = checkDaysAmount(
            userBirthDate.day,
            userBirthDate.month,
            userBirthDate.year,
        );
        const updatedData = isValidData
            ? {
                  ...(mainInfo as IGetMainInfo),
                  ...{
                      [field]: value,
                      [`b_${field}`]: value,
                  },
              }
            : {
                  ...(mainInfo as IGetMainInfo),
                  ...{
                      [field]: value,
                      [`b_${field}`]: value,
                      day: 1,
                      b_day: 1,
                  },
              };
        const { day, month, year } = updatedData;

        setMainInfo(updatedData);
        setEditedData({
            ...editedData,
            user_detail: { ...editedData?.user_detail, b_day: day, b_month: month, b_year: year },
        });
    };

    /**
     * Method to change profile country
     * @param country
     * @param city
     */
    const handleChangeCountry = (country: IManProfileReferenceCountryItem) => {
        if (+(mainInfo?.country_id || 0) === +country?.id) return false;

        setMainInfo({
            ...(mainInfo as IGetMainInfo),
            ...{
                city_list: [],
                country_id: country?.id,
                country_name: country?.country_name,
                country_code: country?.country_code,
                city_id: '0',
                city: '',
                city_name: 'Not specified',
            },
        });
        setEditedData({
            ...editedData,
            user_detail: { ...editedData?.user_detail, country: country?.id },
            user_reference: { ...editedData?.user_reference, city_id: 0 },
        });
    };

    /**
     * Method to change profile city
     * @param city
     */
    const handleChangeCity = (city: { id: number; city_name: string }) => {
        setMainInfo({
            ...(mainInfo as IGetMainInfo),
            ...{
                city_id: city?.id,
                city: city?.city_name,
                city_name: city?.city_name,
            },
        });
        setEditedData({
            ...editedData,
            user_reference: { ...editedData?.user_reference, city_id: city?.id },
        });
    };

    const handleLoadCityList = (cityName: string) => {
        const countryID = mainInfo?.country_id || 0;

        if (countryID && cityName?.length > 2) {
            new ProfileApi().getCityByCountry(countryID, cityName).then((res) => {
                if (res?.status && res?.response) {
                    setMainInfo({ ...(mainInfo as IGetMainInfo), ...{ city_list: res?.response } });
                } else {
                    setMainInfo({ ...(mainInfo as IGetMainInfo), ...{ city_list: [] } });
                }
            });
        } else {
            setMainInfo({ ...(mainInfo as IGetMainInfo), ...{ city_list: [] } });
        }
    };

    const fetchMailSubscription = () => {
        new UserApi().getMailSubscription().then((res) => {
            if (res.status) {
                setSubscribedData(res.result);
            }
        });
    };

    const sendEmailSubscription = () => {
        new UserApi().postMailSubscription({ subscribe: true }).then((res) => {
            if (res?.status) {
                dispatch(
                    alertAction.setCustomAlertType({
                        alertType: ALERT.EMAIL_CONFIRM_SENT,
                        message: mainInfo?.email?.toString(),
                    }),
                );
            } else {
                dispatch(
                    alertAction.setMessage({
                        message: res?.message || ERROR.ERROR_REQUEST,
                        title: res?.title,
                    }),
                );
            }
        });
    };

    useEffect(() => {
        if (!mainInfo?.name?.length) return;
        let timer: NodeJS.Timeout | number = 0;
        clearTimeout(timerID);

        //updating profile
        if (editedData) {
            timer = setTimeout(() => {
                saveEdits(editedData);
                setEditedData(null);
            }, PROFILE.EDIT_PROFILE_TIMER);
            setTimerID(timer);
        }

        //updating profile when switching to another page
        history.block(({ pathname }) => {
            if (pathname !== `/${MENU.PROFILE_PAGE}`) {
                clearTimeout(timer);
                if (editedData !== null) {
                    saveEdits(editedData);
                }
            }
        });
    }, [editedData]);

    useEffect(() => {
        fetchMailSubscription();
    }, []);

    return {
        mainInfo,

        onChangeInputValue,
        handleChangeBirthday,
        handleChangeCity,
        handleLoadCityList,
        handleChangeCountry,
        openManageEmailModal,
        isLoyaltyProgramModal,
        openLoyaltyProgramModal,
        closeLoyaltyProgramModal,
        openModalPurchaseCredit,
        sendEmailSubscription,
        subscribedData,

        inputUploadPhoto,
        showInputFile,

        ...handleProfileAvatar,
        ...handleProfilePhotos,
    };
}
