import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ProfileMenu from '../../components/Menu/NewMenuMain';
import Notify from '../../components/common/Notify';
import GiftSuccessModal from '../../components/common/Modals/GiftSuccessModal';
import AlertModal from '../../components/common/Modals/AlertModal';
import WomanProfileModal from '../../components/common/Modals/WomanProfileModal';
import WomanVideoShowModal from '../../components/common/Modals/WomanVideoShowModal';
import WomanGalleryModal from '../../components/common/Modals/WomanGalleryModal';
import MobileHead from '../../components/Mobile/MobileHead';
import MobileFooter from '../../components/Mobile/MobileFooter';
import MobileNotify from '../../components/Mobile/MobileNotify';
import LessCreditsModal from '../../components/common/Modals/LessCreditsModal';
import WelcomeAlertModal from '../../components/common/Modals/WelcomeAlertModal';
import IdentityVerificationModal from '../../components/common/Modals/IdentityVerificationModal';
import NPSModal from '../../components/common/Modals/NPSModal';
import SupportFeedbackModal from '../../components/common/Modals/SupportFeedbackModal';
import TutorialModal from '../../components/common/Modals/TutorialModal';
import PollModal from '../../components/common/Modals/PollModal';
import ManageEmail from '../../components/common/Modals/Manage/ManageEmail';
import RegisteredUserModal from '../../components/common/Modals/RegisteredUserModal';
import UserBirthdayModal from '../../components/common/Modals/UserBirthdayModal';
import ConfirmTermsModal from '../../components/common/Modals/ConfirmTermsModal';
import EmailResubscriptionModal from '../../components/common/Modals/EmailResubscriptionModal';
import * as alertAction from '../../store/actions/alert';
import * as modalsAction from '../../store/actions/modals';
import * as userAction from '../../store/actions/user';
import * as configAction from '../../store/actions/config';
import * as MENU from '../../constants/menu';
import * as ERROR from '../../constants/error';
import {
    closeMobileMenu,
    closePanel,
    closeFilterSearch,
    closeMobChatList,
    initUserLayoutContent,
} from '../../services/dom';
import '../../styles/select.scss';
import '../../styles/columns/column.scss';
import '../../styles/welcome-alert/style.scss';
import '../../styles/emoji.scss';
import modalStyleProps from '../../styles/react-modal/modal.style';
import LoaderGif from '../../components/common/LoaderGif';
import * as SYNC from '../../constants/sync';
import useWebWidget from '../../hooks/ze/useWebWidget';
import useDomainRedirect from '../../hooks/transition/useDomainRedirect';
import UserApi from '../../services/api/UserApi';
import useInitWelcomeAlert from '../../hooks/dom/useInitWelcomeAlert';
import useInitTutorial from '../../hooks/dom/useInitTutorial';
import useInitPollModal from '../../hooks/quiz/useInitPollModal';
import useInitPushNotification from '../../hooks/push/useInitPushNotification';
import useTouchNotification from '../../hooks/notify/useTouchNotification';
import useBlockIphoneSideSwipe from '../../hooks/dom/useBlockIphoneSideSwipe';
import useInitSubscribe from '../../hooks/purchase/subscribe/useInitSubscribe';
import VideoContentModal from '../../components/common/Modals/VideoContentModal';
import PhotoContentModal from '../../components/common/Modals/PhotoContentModal';
import useInitConfig from '../../hooks/transaction/useInitConfig';
import SITE from '../../config/site';
import useInitUtmSpend from '../../hooks/utm/useInitUtmSpend';
import useInitInviteLimit from '../../hooks/transaction/useInitInviteLimit';
import { diffAfficeByRegisterDate, sendAfficeFromStore } from '../../services/affise';
import { diffBinomoByRegisterDate, sendBinomoFromStore } from '../../services/binomo';
import useCheckIsConfirmEmail from '../../hooks/confirmEmail/useCheckIsConfirmEmail';
import AutoTopUpSuccessModal from '../../components/common/Modals/AutoTopUpSuccessModal';
import AutoTopUpChangeModal from '../../components/common/Modals/AutoTopUpChangeModal';
import useInitResize from '../../hooks/resize/useInitResize';
import useInitCrossBanner from '../../hooks/crossBanner/useInitCrossBanner';
import * as USER from '../../constants/user';
import TransactionApi from '../../services/api/v3/TransactionApi';
import * as CONFIG from '../../constants/config';
import ClickHistoryApi from '../../services/api/v3/ClickHistoryApi';
import useTransactionConfig from '../../hooks/transaction/useTransactionConfig';
import TermModal from '../../components/common/Modals/TermModal';
import useInitTerm from '../../hooks/term/useInitTerm';
import useInitUserBirthdayModal from '../../hooks/dom/useInitUserBirthdayModal';
import VIPModal from '../../components/common/Modals/VIPModal';
import useTransitionEffect from '../../hooks/transition/useTransitionEffect';
import useHandleRemarketing from '../../hooks/bonus/useHandleRemarketing';
import ConfirmModal from '../../components/common/Modals/ConfirmModal';
import { onesignalPush } from '../../services/methods/onesignal';
import LoyaltyProgramModal from '../../components/common/LoyaltyProgram/LoyaltyProgramModal';
import useEffectUtm from '../../hooks/utm/useEffectUtm';
import useHandleMobileChat from '../../hooks/chat/helpers/useHandleMobileChat';
import MarketingApi from '../../services/api/MarketingApi';
import useHandlePayment from '../../hooks/purchase/useHandlePayment';
import PaymentRoutingModal from '../../components/common/Modals/PaymentRoutingModal';
import useTransition from '../../hooks/transition/useTransition';
import useGoogleDataLayer from '../../hooks/event/useGoogleDataLayer';
import useSync from '../../hooks/sync/useSync';
import useHandleOnboarding from '../../hooks/onboarding/useHandleOnboarding';
import OnboardingModal from '../../components/common/OnboardingModal/OnboardingModal';
import useFBConversion from '../../hooks/event/useFBConversion';
import * as referenciesAction from '../../store/actions/referencies';
import { initPosthog } from '../../services/posthog';
import * as notifyAction from '../../store/actions/notify';
import * as countersAction from '../../store/actions/counters';
import { generateUTMCDate } from '../../services/methods/register';
import * as USER_CONST from '../../constants/user';
import useHandleRouting from '../../hooks/purchase/useHandleRouting';
import useHandleSift from '../../hooks/sift/useHandleSift';
import UnlockedFeatureModal from '../../components/common/Modals/UnlockedFeatureModal';
import useUnlockedFeatureModal from '../../hooks/modals/useUnlockedFeatureModal';
import { sendErrToSentry } from '../../services/sentry';
import GalleryModal from '../../templates/sendberly/common/Modals/GalleryModal';
import { ConnectionLostModal } from '../../components/common/Modals/ConnectionLostModal';
import { IUserLayoutProps } from './index.interface';
import { RootState } from '../../index';
import BonusCreditsModal from '../../components/common/Modals/BonusCreditsModal';
import useGetWinkList from '../../hooks/wink/useGetWinkList';

// import HolidayModal from '../../components/common/Modals/HolidayModal';

export default function UserLayout({ children }: IUserLayoutProps) {
    const activeGifLoader = useSelector((state: RootState) => state.loader.activeGifLoader);
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const transition = useTransition();
    const initWelcomeAlert = useInitWelcomeAlert();
    const initTutorial = useInitTutorial();
    const initPushNotification = useInitPushNotification();
    const touchNotification = useTouchNotification();
    const initTerm = useInitTerm();
    const transactionConfig = useTransactionConfig();
    const { initSubscribeStatus } = useInitSubscribe();
    const initUserBirthdayModal = useInitUserBirthdayModal();
    const handleRemarketing = useHandleRemarketing();
    const initCrossBanner = useInitCrossBanner();
    const handlePayment = useHandlePayment();
    const handleMobileChat = useHandleMobileChat();
    const handleOnboarding = useHandleOnboarding();
    const handleRouting = useHandleRouting();
    const handleSift = useHandleSift();
    const unlockedFeatureModal = useUnlockedFeatureModal();
    const { initSayHelloList } = useGetWinkList({});
    const {
        pushEventDolphin,
        pushEventLead,
        pushEventRegistration,
        pushEventRegistrationFB,
        pushEventUserId,
        pushEventTarget45Event,
        pushEventAndroid45Event,
        pushEventIos45Event,
        pushEventWin45Event,
        pushEventMac45Event,
        pushEventConfirmedEmail,
        pushEventSecondVisit,
        pushEventSignUpWomen25,
        pushEventPixelOs,
        pushEventPixel,
    } = useGoogleDataLayer();
    const {
        // sentFBAuth,
        // sentFBAuth55,
        sentFBDelfinConversion,
        sentFBConfirmEmailConversion,
        checkFBCapi,
    } = useFBConversion();
    useInitPollModal();
    useCheckIsConfirmEmail();
    useInitUtmSpend();
    useInitConfig();
    useInitInviteLimit();
    useDomainRedirect();
    useWebWidget();
    useBlockIphoneSideSwipe();
    useTransitionEffect(location);
    useEffectUtm();
    useSync();
    useInitResize();

    const userInfo = userState?.info;
    const userTail = userState?.tail;
    const currentPage = location.pathname.split('/')[1];
    const isHideMenuIcon = [
        'videoshow',
        'search',
        'likehistory',
        'gifts',
        'profile-woman',
        'menu',
    ].includes(currentPage);

    const closeAll = () => {
        closePanel();
        if (!MENU.CHAT_PAGES.some((item) => window.location?.pathname.includes(item))) {
            closeMobChatList();
        }
        closeMobileMenu();
        closeFilterSearch();
        dispatch(modalsAction.setActiveSubMenu());
    };

    const effectFirstLoad = () => {
        checkFBCapi();

        initUserLayoutContent();
        onesignalPush();

        dispatch(userAction.getInfo());
        dispatch(referenciesAction.getReferencies());
        dispatch(userAction.setLoyaltyProgram());
        dispatch(configAction.initConfigByExternalID());
        dispatch(notifyAction.getCheck());

        initCrossBanner.getCrossBannerInfo();
        initSubscribeStatus();
        initSayHelloList();
    };

    const effectAfterRegister = () => {
        if (window?.location?.search?.includes('registration=success')) {
            localStorage.setItem(`rd-${userInfo?.external_id}`, JSON.stringify(new Date()));

            if (!location?.search.includes('utm_cdate')) {
                history.push(`${location?.pathname}${location?.search}&${generateUTMCDate()}`);
            }

            if (!userInfo?.gender) {
                handleOnboarding.getOnboarding().then(() => {
                    if (userInfo?.show_already_registration_bonus_popup) {
                        initWelcomeAlert.openWelcomeAlertModal();

                        dispatch(
                            userAction.updateUserInfo({
                                ...userInfo,
                                show_already_registration_bonus_popup: false,
                            }),
                        );
                    }
                });
            }

            pushEventLead();
            pushEventUserId();
            pushEventTarget45Event();
            pushEventAndroid45Event();
            pushEventIos45Event();
            pushEventWin45Event();
            pushEventMac45Event();
            pushEventSignUpWomen25();
            pushEventPixelOs({ isRegister: true });
            pushEventPixel({ isRegister: true });

            if (userInfo?.age && userInfo?.age >= 35) {
                // This check can be used for other events in the future
                // LOGIC GO TO BACK
                // new MarketingApi().postCheckExistEvent('registration').then((res) => {
                //     if (res?.status && res?.response?.check) {
                //         sentFBAuth();
                //     }
                // });
            }

            if (userTail?.includes('utm_age=45')) {
                pushEventRegistration();
                pushEventRegistrationFB();
            }

            // LOGIC GO TO BACK
            // if (userTail?.includes('utm_55age=55')) {
            //     sentFBAuth55();
            // }

            if (userInfo?.external_id) {
                sendBinomoFromStore({
                    externalID: userInfo?.external_id,
                    siteID: userInfo?.site_id,
                    status: USER_CONST.BINOMO_SIGNUP,
                    clickid: userState?.clickid,
                    utm_aff_hash: userState?.utm_aff_hash,
                    ghost: userInfo?.ghost,
                    email: userInfo?.email,
                });
            }

            if (userInfo?.external_id && userInfo?.age && +userInfo?.age > 30) {
                setTimeout(() => {
                    sendBinomoFromStore({
                        externalID: userInfo?.external_id,
                        siteID: userInfo?.site_id,
                        status: USER_CONST.BINOMO_UPLOAD_AVATAR,
                        clickid: userState?.clickid,
                        utm_aff_hash: userState?.utm_aff_hash,
                        ghost: userInfo?.ghost,
                        email: userInfo?.email,
                    });
                }, 1000 * 5);
            }
        }
    };

    const effectAfterUserInfo = () => {
        if (userInfo?.external_id) {
            if (Sentry && typeof Sentry?.setUser === 'function') {
                Sentry.setUser({ id: userInfo?.external_id });
            }

            dispatch(countersAction.getChatCounter(userInfo?.external_id));
            dispatch(countersAction.getLetterCounter(userInfo?.external_id));

            if (!window.location.href.includes(MENU.MENU_CHECKOUT)) handlePayment.getProposition();

            handleSift.init();
            diffBinomoByRegisterDate(userState);
            diffAfficeByRegisterDate(userState);
            initPosthog(userInfo?.external_id, userInfo?.name);
            // Push DataLayer for FB
            pushEventSecondVisit();

            if (!+userInfo?.ghost) {
                transactionConfig.fetchTransactionConfig();
            }
            if (!+userInfo?.ghost) {
                initPushNotification.lastActivity();
            }
            if (location?.search?.includes('utm_camp=all_pay')) {
                handleRemarketing.getRemarketingBonus();
            }
            if (location?.search?.includes('utm_netw=onesg')) {
                initPushNotification.checkPush();
            }
            if (location?.search?.includes('utm_netw=adrl')) {
                initPushNotification.checkAdrol();
            }
            if (location?.search?.includes('utm_netw=tbl')) {
                initPushNotification.postCheckTbl();
            }
            if (location?.search?.includes('utm_source=adroll_email')) {
                initPushNotification.checkEmailAdrol();
            }
            if (location?.search?.includes('utm_source=sndgrd_email')) {
                initPushNotification.checkSendgrig();
            }
            if (location?.search?.includes('utm_source=center_email')) {
                initPushNotification.checkCenterEmail();
            }
            if (
                location?.search?.includes('utm_netw=sofia_ggl_gdn_nik') ||
                location?.search?.includes('utm_netw=myspec_ggl_gdn_nik') ||
                location?.search?.includes('utm_netw=lfh_ggl_gdn_nik')
            ) {
                initPushNotification.postCheckGoogle();
            }
            if (location?.search?.includes('utm_action=invite') && +userInfo?.is_waiting === 1) {
                new ClickHistoryApi().postInvite(userInfo?.external_id);
                new TransactionApi().postAskTransaction({
                    type: SYNC.INVITE_ACTION,
                    delay: 5,
                    lifeTime: 10,
                    configType: CONFIG.CONFIG_TYPE_INFINITY,
                });
            }

            if (userInfo?.external_id) {
                localStorage.setItem('external_id', userInfo.external_id.toString());
            }

            if (+userInfo?.payment_total >= USER.PAYMENT_TOTAL_TO_AFFISE_SPEND_200) {
                sendBinomoFromStore({
                    externalID: userInfo?.external_id,
                    siteID: userInfo?.site_id,
                    status: USER.BINOMO_SPEND_200,
                    clickid: userState?.clickid,
                    utm_aff_hash: userState?.utm_aff_hash,
                    ghost: userInfo?.ghost,
                    email: userInfo?.email,
                });
                sendAfficeFromStore({
                    status: USER.AFFISE_SPEND_200,
                    info: userInfo,
                });
            }

            if (location?.search?.includes('utm_confirm=true')) {
                new MarketingApi().postCheckExistEvent('confirmed_email').then((resp) => {
                    if (resp?.status && resp?.response?.check) {
                        // Push DataLayer for FB
                        pushEventConfirmedEmail();

                        if (userTail?.includes('utm_age=45')) {
                            sentFBConfirmEmailConversion();
                        }
                    }
                });
            }

            const promocode = localStorage.getItem('promocode');
            if (promocode) {
                localStorage.removeItem('promocode');
                new UserApi().activatePromoCode({ hash: promocode }).then((res) => {
                    if (res?.status) {
                        dispatch(
                            alertAction.setMessage({
                                message: 'Please enjoy the services!',
                                title: `You've received ${res?.promo_credits} free credits`,
                            }),
                        );
                        dispatch(userAction.setBalance());
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: ERROR.ERROR_REQUEST,
                                title: res?.message,
                            }),
                        );
                    }
                });
            }
            if (
                location?.search?.includes('cardpay=true') ||
                location?.search?.includes('pastabank=true') ||
                location?.search?.includes('acquiring=true') ||
                location?.search?.includes('securionpay=true')
            ) {
                handlePayment.onOpenPayment();
            }

            if (SITE.ID !== 4) initUserBirthdayModal.checkUserBirthday();

            if (
                !!+userInfo?.is_approve_phone &&
                !!+userInfo?.email_confirmed &&
                !!userInfo?.real_name?.length &&
                !+userInfo?.is_verified
            ) {
                dispatch(userAction.setIsUserVerified());
            }

            if (+userInfo?.spend_first_credit) {
                localStorage.setItem('spend_first_credit', '1');
            }
        }
    };

    useEffect(() => {
        try {
            let id = 0;

            if (userState?.userRoles?.heightRole) id = 3;
            else if (userState?.userRoles?.maybeHeight) id = 2;
            else if (userInfo?.payment_total && userInfo?.payment_total >= 2000) id = 1;

            localStorage.setItem('X-Request-ID', id?.toString());

            const dolphinStore = localStorage.getItem('dolphin');

            if (!!userState?.userRoles?.maybeHeight && !dolphinStore) {
                localStorage.setItem('dolphin', '1');

                new MarketingApi().postCheckExistEvent('dolphin').then((res) => {
                    if (res?.status && res?.response?.check) {
                        if (userTail?.includes('utm_age=45')) {
                            sentFBDelfinConversion();
                        }

                        // Push DataLayer for FB
                        pushEventDolphin();
                    }
                });
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }, [userInfo?.payment_total, userState?.userRoles]);

    useEffect(() => {
        if (menuState.main === MENU.MENU_CHAT && window.innerWidth < 900) {
            if (!isHideMenuIcon) {
                if (
                    location?.pathname?.split(MENU.MENU_CHAT)[1]?.length === 0 ||
                    location?.pathname?.split(MENU.MENU_DIALOGUES)[1]?.length === 0 ||
                    location?.pathname?.split(MENU.MENU_UNANSWERED)[1]?.length === 0 ||
                    location?.pathname?.split(MENU.MENU_FAVORITE)[1]?.length === 0 ||
                    location?.pathname?.split(MENU.MENU_LIKE)[1]?.length === 0
                ) {
                    dispatch(modalsAction.setActiveSubMenu('profiles'));
                }
            }
        }
        if (menuState.main === MENU.MENU_BLOCK && window.innerWidth < 900) {
            if (!isHideMenuIcon) dispatch(modalsAction.setActiveSubMenu('profiles'));
        }
    }, [menuState.main]);

    useEffect(() => {
        if (purchaseState?.paymentMethodList?.length) {
            if (location?.search?.includes('utm_purchaselist=open')) {
                transition.addTail(window?.location?.search?.replace('utm_purchaselist=open', ''));
                handlePayment.onOpenPayment();
            }

            if (handleRouting.isRouting()) handleRouting.init();
        }
    }, [purchaseState?.paymentMethodList]);

    useEffect(() => {
        if (userInfo?.external_id) {
            effectAfterUserInfo();

            if (window?.location?.search?.includes('registration=success')) {
                effectAfterRegister();
            }
        }
    }, [userInfo?.external_id]);

    useEffect(() => {
        effectFirstLoad();
    }, []);

    return (
        <>
            {userInfo?.external_id ? (
                <>
                    <div
                        className="container"
                        id="container_analytics_id"
                        rel={`${userInfo?.external_id}${+userInfo?.age > 34 ? (+userInfo?.age > 44 ? '+45' : '+35') : ''}`}
                    >
                        <div
                            className={`sf_page_wrap ${handleMobileChat.isNewMobileChat && handleMobileChat.isActiveNewMobileChat ? 'hide_for_new_mobile_chat' : ''}`}
                            id="sf_page_wrap_id"
                        >
                            <div
                                className={`overlay ${SITE.ID === 23 ? 'overlay_new' : ''}`}
                                onTouchStart={touchNotification.handleTouchStart}
                                onTouchMove={touchNotification.handleTouchMove}
                                onClick={closeAll}
                                onMouseDown={closeAll}
                            />
                            <MobileHead />
                            <div
                                onTouchStart={touchNotification.handleTouchStart}
                                onTouchMove={touchNotification.handleTouchMove}
                                className={`chat_wrap ${+userInfo?.email_confirmed === 0 || +userInfo?.profile_completed === 0 ? 'need_confirm_email' : ''} ${handleMobileChat?.isActiveNewMobileChat ? 'chat_wrap_mobile' : ''}`}
                            >
                                {menuState.main !== MENU.ADD_CREDITS_PAGE && <ProfileMenu />}
                                {children}
                                {menuState.main !== MENU.ADD_CREDITS_PAGE && (
                                    <Notify {...initTutorial} />
                                )}
                            </div>

                            <MobileFooter />
                            <MobileNotify />
                        </div>
                    </div>

                    <Modal
                        style={modalStyleProps({ isLoaderModal: true })}
                        isOpen={activeGifLoader}
                        ariaHideApp={false}
                    >
                        <LoaderGif />
                    </Modal>
                    <RegisteredUserModal />
                    <ManageEmail />
                    <GiftSuccessModal />
                    <LessCreditsModal />
                    <WelcomeAlertModal
                        isWelcomeAlert={initWelcomeAlert.isWelcomeAlert}
                        closeWelcomeAlertModal={initWelcomeAlert.closeWelcomeAlertModal}
                        openTutorialModal={initTutorial.openTutorialModal}
                    />
                    <NPSModal />
                    <SupportFeedbackModal />
                    <IdentityVerificationModal />
                    <TutorialModal
                        isTutorialPopup={initTutorial.isTutorialPopup}
                        closeTutorialModal={initTutorial.closeTutorialModal}
                    />
                    <PollModal />
                    <WomanProfileModal />
                    <WomanVideoShowModal />
                    <WomanGalleryModal />
                    <GalleryModal />
                    <VideoContentModal />
                    <PhotoContentModal />
                    <AutoTopUpSuccessModal />
                    <AutoTopUpChangeModal />
                    <UserBirthdayModal
                        isUserBirthdayModal={initUserBirthdayModal.isUserBirthdayModal}
                        closeUserBirthdayModal={initUserBirthdayModal.closeUserBirthdayModal}
                        birthdayBonusCredits={initUserBirthdayModal.birthdayBonusCredits}
                    />
                    <VIPModal />
                    <PaymentRoutingModal />
                    <LoyaltyProgramModal />
                    <ConfirmModal />
                    <ConfirmTermsModal
                        openTermModal={initCrossBanner.openTermModal}
                        closeConfirmTermModal={initCrossBanner.closeConfirmTermModal}
                    />
                    <TermModal closeTermModal={initTerm.closeTermModal} />
                    <OnboardingModal {...handleOnboarding} />
                    <UnlockedFeatureModal {...unlockedFeatureModal} />
                    <EmailResubscriptionModal />
                    <BonusCreditsModal />

                    {/*<HolidayModal />*/}

                    {/*Must be connected last*/}
                    <AlertModal />
                    <ConnectionLostModal />
                </>
            ) : (
                <LoaderGif />
            )}
        </>
    );
}
