import React from 'react';

export default function IconSearchActive() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6745 2C7.10475 2 3.40027 5.66313 3.40027 10.1818C3.40027 14.7005 7.10475 18.3636 11.6745 18.3636C13.3128 18.3636 14.8399 17.8928 16.125 17.0805C16.5482 16.7547 17.4281 16.0687 17.5623 15.9304C19.0378 14.4527 19.9487 12.4225 19.9487 10.1818C19.9487 5.66313 16.2442 2 11.6745 2ZM20.8308 21.7337L17.1767 18.1204C17.7109 17.7001 18.3893 17.1582 18.5161 17.026C18.5415 17.0003 18.5667 16.9744 18.5919 16.9485L22.131 20.4481C22.49 20.8031 22.49 21.3787 22.131 21.7337C21.772 22.0887 21.1899 22.0887 20.8308 21.7337Z"
            />
        </svg>
    );
}
