import { SettingsActionTypes } from '../../constants/settings';
import { Dispatch } from 'redux';

export function setIsActiveCallButton(status: number) {
    return function (dispatch: Dispatch<{ type: string; status: number }>) {
        return dispatch({
            type: SettingsActionTypes.SET_IS_ACTIVE_CALL_BUTTON,
            status,
        });
    };
}

export function updateEvent() {
    return function (dispatch: Dispatch<{ type: string }>) {
        return dispatch({
            type: SettingsActionTypes.UPDATE_EVENT_STATUS,
        });
    };
}
