// ATTACH
export const ATTACH_LETTER_AUDIO_TITLE = 20;
export const ATTACH_VIDEO_TITLE = 12;
export const ATTACH_TITLE_NAME = 12;
export const SUPPORT_IMG_MAX_LENGTH = 10;
export const SUPPORT_TEXTAREA_MAX_LENGTH = 1500;

export const SUPPORT_MAX_IMG_SIZE = 1024 ** 2 * 5;
export const MAX_IMG_SIZE = 1024 ** 2 * 30;
export const MAX_VIDEO_SIZE = 1024 ** 2 * 50;
export const MAX_AUDIO_SIZE = 1024 ** 2 * 30;

export const SUPPORT_MAX_IMG_SIZE_TITLE = 5;
export const MAX_IMG_SIZE_TITLE = 5;
export const MAX_VIDEO_SIZE_TITLE = 50;
export const MAX_AUDIO_SIZE_TITLE = 30;

export const MAX_AUDIO_TITLE = 70;
export const MAX_ATTACHMENTS_TO_LETTER = 5;
export const MAX_PROFILE_TEXTAREA = 1500;
export const MAX_BIO_TEXTAREA = 150;

// MESSAGE TEXT
export const LETTER_MAX_LENGTH = 4000;
export const MESSAGE_MAX_LENGTH = 300;
export const AFFILIATS_CONTACTS_MAX_LENGTH = 4;
export const AFFILIATS_NAME_MAX_LENGTH = 2;
export const AFFILIATS_MSG_MAX_LENGTH = 5;
export const GIFT_MESSAGE_MAX_LENGTH = 300;
export const MESSAGE_MAX_NUMBER_LINE = 20;

// INPUT FORM
export const MAX_NAME = 40;
export const MIN_NICKNAME = 4;
export const MAX_NICKNAME = 20;

export const WIDTH_RIGHT_SIDE = 4;

export const MAX_EMAIL_LENGTH = 255;

//IDENTIFY USER POPUP

export const MAX_FILE_SIZE = 5 * 1024 ** 2;
export const MIN_FILE_SIZE = 10 * 1024;

//SUPPORT FEEDBACK, NPS MODALS
export const OPEN_ANSWER_MAX_LENGTH = 500;
