import React from 'react';
import styles from './styles.module.scss';

interface RadioButtonInput {
    status: number;
    title: string;
    id: number;
    onChangeID: (id: number) => void;
}

const RadioButtonInput = ({ status, title, id, onChangeID }: RadioButtonInput) => {
    return (
        <div
            className={`${styles.wrapper} ${status ? styles.wrapper_checked : ''}`}
            onClick={() => onChangeID(id)}
        >
            <input
                type="radio"
                className={styles.custom_radio}
                name={title}
                id={`radio_${id}`}
                checked={!!status}
                readOnly
            />
            <label htmlFor={`radio_${id}`}>
                <span>{title}</span>
            </label>
        </div>
    );
};

export default RadioButtonInput;
