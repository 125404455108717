import React, { useState } from 'react';
import styles from '../styles.module.scss';
import { cutLengthString, getReadableFileSizeString } from '../../../../../../services/methods';
import IconCross from '../../../../../Icons/IconCross';
import UploadedPhoto from '../UploadedPhoto';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import { IUserOnlySelfieBlockProps } from './index.interface';

export default function UserOnlySelfieBlock({
    handleChangeBlock,
    handleInput,
    deleteUploadedFile,
    isWarning,
    isUserProfileVerified,
    setIsUserProfileVerified,
}: IUserOnlySelfieBlockProps) {
    const docsState = useSelector((state: RootState) => state.profile.docsState);
    const [isOpenPhoto, setIsOpenPhoto] = useState<boolean>(false);

    const handleOpenPhoto = () => {
        if (docsState?.userOnlySelfieFile?.name) {
            setIsOpenPhoto((prev) => !prev);
        }
    };

    const deleteFile = () => {
        setIsUserProfileVerified(false);
        deleteUploadedFile('userOnlySelfieFile');
    };
    return (
        <>
            <h3 className={`${styles.block_title} ${styles.user_id_title}`}>
                Take a selfie for verification
            </h3>
            <div className={styles.requirements_details}>
                <ul className={styles.requirements_selfie}>
                    <li className={styles.requirements_selfie_item}>
                        <h4 className={styles.requirements_selfie_item_title}>
                            Clear Visibility of Face
                        </h4>
                        <p className={styles.requirements_selfie_item_text}>
                            Ensure your entire face is visible and centered in the frame. Avoid
                            wearing sunglasses, hats, or other accessories that may obstruct your
                            face.
                        </p>
                    </li>
                    <li className={styles.requirements_selfie_item}>
                        <h4 className={styles.requirements_selfie_item_title}>Good Lighting</h4>
                        <p className={styles.requirements_selfie_item_text}>
                            Take the photo in a well-lit environment, with even lighting that
                            minimizes shadows on your face. Natural daylight works best, but avoid
                            harsh backlighting.
                        </p>
                    </li>
                    <li className={styles.requirements_selfie_item}>
                        <h4 className={styles.requirements_selfie_item_title}>
                            Neutral Background
                        </h4>
                        <p className={styles.requirements_selfie_item_text}>
                            Use a plain or neutral background, free of distractions or clutter. This
                            ensures that the focus remains on your face.
                        </p>
                    </li>
                </ul>
                <ul className={`${styles.examples_list} ${styles.examples_list_selfie}`}>
                    <li className={`${styles.examples_item} ${styles.examples_item_good_selfie}`}>
                        Good
                    </li>
                    <li
                        className={`${styles.examples_item} ${styles.examples_item_selfie_glasses}`}
                    >
                        Bad
                    </li>
                    <li className={`${styles.examples_item} ${styles.examples_item_selfie_hat}`}>
                        Bad
                    </li>
                    <li
                        className={`${styles.examples_item} ${styles.examples_item_selfie_background}`}
                    >
                        Bad
                    </li>
                </ul>
            </div>
            <form className={`${styles.form} ${isWarning ? styles.form_error : ''}`}>
                <p
                    className={`${styles.form_info} ${isUserProfileVerified ? styles.form_info_success : ''}`}
                >
                    {isUserProfileVerified
                        ? 'The documents have been approved, and there’s no need to re-upload them. However, if you wish to, we will review them again.'
                        : 'File size must be between 10KB and 5120KB in jpg/jpeg/png format.'}
                </p>
                {(docsState?.userOnlySelfieFile && docsState?.userOnlySelfieFile?.name) ||
                isUserProfileVerified ? (
                    <div className={styles.form_file}>
                        <button
                            type="button"
                            disabled={isUserProfileVerified}
                            onClick={handleOpenPhoto}
                            className={styles.form_file_info}
                        >
                            <p className={styles.form_file_name}>
                                {isUserProfileVerified
                                    ? 'Previously approved file'
                                    : cutLengthString(docsState?.userOnlySelfieFile?.name, 30)}
                            </p>
                            {docsState?.userOnlySelfieFile?.size && (
                                <p
                                    className={styles.form_file_size}
                                >{`(${getReadableFileSizeString(docsState?.userOnlySelfieFile?.size)})`}</p>
                            )}
                        </button>
                        <button
                            type="button"
                            className={styles.form_file_delete}
                            onClick={deleteFile}
                        >
                            <IconCross />
                        </button>
                    </div>
                ) : (
                    <>
                        <input
                            type="file"
                            name="userOnlySelfieFile"
                            id="popup_user_id_input"
                            className={styles.form_input}
                            onChange={handleInput}
                            accept=".jpg, .jpeg, .png"
                            multiple={false}
                        />
                        <label htmlFor="popup_user_id_input" className={styles.form_label}>
                            <span>Upload photo</span>
                        </label>
                    </>
                )}
            </form>
            {isOpenPhoto ? (
                <UploadedPhoto
                    image={docsState?.userOnlySelfieFile}
                    onClose={() => setIsOpenPhoto(false)}
                />
            ) : null}
            <button
                type="button"
                disabled={!docsState?.userOnlySelfieFile?.name && !isUserProfileVerified}
                className={styles.block_button_main}
                onClick={() => handleChangeBlock()}
            >
                Continue
            </button>
        </>
    );
}
