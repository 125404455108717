import React from 'react';
import styles from './styles.module.scss';

interface IOnboardingInput {
    value: number;
    setValue: (value: number) => void;
    title: string;
}

const OnboardingInput = ({ value, setValue, title }: IOnboardingInput) => {
    return (
        <div className={styles.onboardingInput}>
            <p>{title}</p>
            <input
                className={`${styles.input} ${value ? styles.input_filled : ''}`}
                value={value}
                type={'number'}
                min="0"
                onChange={(e) => setValue(+e?.target?.value)}
                placeholder={'Not specified'}
            />
        </div>
    );
};

export default OnboardingInput;
