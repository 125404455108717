import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FaqApi from '../../services/api/v3/FaqAPI';
import * as faqAction from '../../store/actions/faq';
import * as modalsAction from '../../store/actions/modals';
import useTransition from '../transition/useTransition';
import { getUrlParameter } from '../../utils/url-utils';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { RootState } from '../../index';
import { IFAQQuestionItem } from '../../store/reducers/faq/index.interface';

export default function useInitFaq() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const handleActiveBlocks = useHandleActiveBlocks();

    const faqState = useSelector((state: RootState) => state.faq);
    const userState = useSelector((state: RootState) => state.user);

    const [activeTopicTitle, setActiveTopicTitle] = useState('');
    const [isMobileQuestionsOpened, setIsMobileQuestionsOpened] = useState(false);
    const [questionsList, setQuestionsList] = useState<IFAQQuestionItem[]>([]);

    const getFaqData = () => {
        new FaqApi().getFaq().then((res) => {
            if (res?.status) {
                const filterList =
                    handleActiveBlocks.isNewMailCategory1 && handleActiveBlocks.isPaymentUser
                        ? res?.result?.filter(
                              (item: { code: string }) => !['GENERAL_INFO']?.includes(item?.code),
                          )
                        : res?.result;
                dispatch(faqAction.getFaqData(filterList));
            }
        });
    };

    const changeTopic = (topicId: number, topicTitle: string) => {
        dispatch(faqAction.setActiveTopicId(topicId));
        dispatch(faqAction.setActiveQuestionId());
        setIsMobileQuestionsOpened(true);
        setActiveTopicTitle(topicTitle);
    };

    const backToTopics = () => {
        setIsMobileQuestionsOpened(false);
        setQuestionsList([]);
        dispatch(faqAction.setActiveTopicId());
        dispatch(faqAction.setActiveQuestionId());
    };

    const changeActiveQuestion = (id: number) => {
        if (id === faqState?.activeQuestionId) {
            dispatch(faqAction.setActiveQuestionId());
        } else {
            dispatch(faqAction.setActiveQuestionId(id));
        }
    };

    const fixMessageContent = (message: string) => {
        if (typeof message !== 'string') return '';
        return message.split('\n').join(`<br/>`);
    };

    const clickAnswerText = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        const target = event.target as HTMLElement;

        if (target?.nodeName === 'IMG') {
            const img = target as HTMLImageElement;

            openImageModal(img?.src);
        }
    };

    const openImageModal = (imgLink: string) => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: imgLink,
                attachment_payed: '1',
            }),
        );
    };

    useEffect(() => {
        if (faqState?.activeQuestionId) {
            const openedCategoryItem = document.querySelector(
                `#question_item_${faqState?.activeQuestionId}`,
            );

            if (openedCategoryItem) {
                openedCategoryItem.scrollIntoView({
                    behavior: 'instant' as ScrollBehavior,
                    block: 'start',
                });
            }
        }
    }, [faqState?.activeQuestionId, questionsList]);

    useEffect(() => {
        if (faqState.activeTopicId) {
            const activeTopicObj = faqState?.faqData?.find(
                (q) => +q?.id === +(faqState?.activeTopicId || 0),
            );
            if (activeTopicObj?.question_list?.length)
                setQuestionsList(
                    activeTopicObj?.question_list?.sort(
                        (a: { order: number }, b: { order: number }) => a?.order - b?.order,
                    ),
                );
        }
    }, [faqState?.activeTopicId]);

    useEffect(() => {
        if (faqState?.faqData?.length) {
            const urlTopicId = getUrlParameter('topic');
            const urlQuestionId = getUrlParameter('question');

            if (faqState?.faqData?.find((el) => +el?.id === +(urlTopicId || 0))) {
                dispatch(faqAction.setActiveTopicId(+(urlTopicId || 0)));
                dispatch(faqAction.setActiveQuestionId(+(urlQuestionId || 0)));
                setIsMobileQuestionsOpened(true);
            } else {
                const defaultActiveFaqType = faqState?.faqData?.reduce((p, v) => {
                    return +p?.order < +v?.order ? p : v;
                });

                dispatch(faqAction.setActiveTopicId(+defaultActiveFaqType?.id));
            }
        }
    }, [faqState.faqData]);

    useEffect(() => {
        if (faqState?.activeTopicId && window?.location?.pathname?.includes('/faq')) {
            transition.addTail(
                `?topic=${faqState?.activeTopicId}${faqState?.activeQuestionId ? `&question=${faqState?.activeQuestionId}` : ''}`,
            );
        }
    }, [faqState.activeTopicId, faqState.activeQuestionId]);

    useEffect(() => {
        if (!faqState?.faqData?.length) getFaqData();
    }, [userState?.info?.external_id]);

    return {
        getFaqData,
        changeTopic,
        isMobileQuestionsOpened,
        questionsList,
        activeTopicTitle,
        backToTopics,
        fixMessageContent,
        clickAnswerText,
        changeActiveQuestion,
    };
}
