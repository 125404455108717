import React from 'react';
export default function IconCross() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="white"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.87172 2.87025C3.14339 2.59859 3.58384 2.59859 3.85551 2.87025L8.0013 7.01605L12.1471 2.87025C12.4188 2.59859 12.8592 2.59859 13.1309 2.87025C13.4026 3.14192 13.4026 3.58238 13.1309 3.85405L8.98509 7.99984L13.1309 12.1456C13.4026 12.4173 13.4026 12.8578 13.1309 13.1294C12.8592 13.4011 12.4188 13.4011 12.1471 13.1294L8.0013 8.98363L3.85551 13.1294C3.58384 13.4011 3.14339 13.4011 2.87172 13.1294C2.60005 12.8578 2.60005 12.4173 2.87172 12.1456L7.01751 7.99984L2.87172 3.85405C2.60005 3.58238 2.60005 3.14192 2.87172 2.87025Z"
            />
        </svg>
    );
}
