import React from 'react';

export default function IconCross() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="#DCDCDC"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.2804 0.21967C0.987509 -0.0732233 0.512636 -0.0732233 0.219743 0.21967C-0.0731507 0.512563 -0.0731507 0.987437 0.219743 1.28033L4.68942 5.75001L0.21967 10.2198C-0.0732231 10.5127 -0.0732234 10.9875 0.21967 11.2804C0.512563 11.5733 0.987437 11.5733 1.28033 11.2804L5.75008 6.81067L10.2198 11.2804C10.5127 11.5733 10.9876 11.5733 11.2805 11.2804C11.5734 10.9875 11.5734 10.5126 11.2805 10.2198L6.81074 5.75001L11.2804 1.28034C11.5733 0.987446 11.5733 0.512572 11.2804 0.219679C10.9875 -0.0732145 10.5126 -0.0732145 10.2198 0.219679L5.75008 4.68935L1.2804 0.21967Z"
            />
        </svg>
    );
}
