import React from 'react';
import { useSelector } from 'react-redux';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Modal from 'react-modal';
import ConfirmTerms from './ConfirmTerms';
import { RootState } from '../../../../index';
import { IConfirmTermsModalProps } from './index.interface';

export default function ConfirmTermsModal({
    openTermModal,
    closeConfirmTermModal,
}: IConfirmTermsModalProps) {
    const bannersState = useSelector((state: RootState) => state.banners);
    const { isConfirmTermModal, crossBannersData, customBannerData } = bannersState;
    const bannerData = customBannerData ? customBannerData : crossBannersData;

    return (
        <>
            <Modal
                style={modalStyleProps()}
                isOpen={isConfirmTermModal}
                onRequestClose={closeConfirmTermModal}
                ariaHideApp={false}
            >
                {!!(isConfirmTermModal && bannerData) && (
                    <ConfirmTerms
                        openTermModal={openTermModal}
                        closeConfirmTermModal={closeConfirmTermModal}
                        crossBannersData={bannerData}
                    />
                )}
            </Modal>
        </>
    );
}
