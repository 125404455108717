import { LikesActionTypes } from '../../constants/likes';
import { ILikesState, LikesActions } from './index.interface';
import { LIKE_FILTERS_DEFAULT } from '../../../constants/filter';

const initState = {
    list: [],
    filter: LIKE_FILTERS_DEFAULT,
    isLoading: true,
    isLoadedAll: false,
    pageLikeList: 1,

    selectedListIndex: 0,
    nextWomanData: {},

    likeHistory: [],
    likeHistoryPage: 1,
    isEmptyLikeHistory: false,
    isLoadingLikeHistoryPage: false,
    isLoadedAllLikeHistory: false,
};

export default function (state: ILikesState = initState, action: LikesActions): ILikesState {
    switch (action.type) {
        case LikesActionTypes.REMOVE_FROM_LIKE_LIST: {
            return {
                ...state,
                list: state.list?.filter((item) => +item !== +action.externalID),
            };
        }

        case LikesActionTypes.LIKES_GET_LIST: {
            return {
                ...state,
                selectedListIndex: 0,
                nextWomanData: {},
                list: action.data,
                isLoading: false,
            };
        }

        case LikesActionTypes.FILTER_TYPE_CHANGE: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    type: action.data,
                },
            };
        }

        case LikesActionTypes.SET_SELECTED_INDEX: {
            return {
                ...state,
                selectedListIndex: action.data,
            };
        }

        case LikesActionTypes.INIT_LIKE_HISTORY_PAGE: {
            return {
                ...state,
                likeHistory: [],
                likeHistoryPage: 1,
                isLoadingLikeHistoryPage: false,
            };
        }

        case LikesActionTypes.GET_LIKE_HISTORY_LIST: {
            const newLikeHistory =
                action?.likeHistoryPage === 1
                    ? [...(action?.likeHistory?.length ? action.likeHistory : [])]
                    : [
                          ...state.likeHistory,
                          ...(action?.likeHistory?.length ? action.likeHistory : []),
                      ];

            return {
                ...state,
                likeHistory: newLikeHistory,
                isEmptyLikeHistory: !newLikeHistory?.length,
                isLoadingLikeHistoryPage: false,
                isLoadedAllLikeHistory: !!(action?.likeHistory?.length % 20),
            };
        }

        case LikesActionTypes.SET_LIKE_HISTORY_PAGE: {
            return {
                ...state,
                ...{
                    likeHistoryPage: action?.page,
                    isLoadingLikeHistoryPage: true,
                },
            };
        }

        case LikesActionTypes.ADD_LIKE_HISTORY_LIST: {
            return {
                ...state,
                likeHistory: [...[action?.likeHistory], ...state.likeHistory],
            };
        }

        case LikesActionTypes.SET_FILTER: {
            return {
                ...state,
                filter: action?.filter,
            };
        }

        case LikesActionTypes.GET_LIKE_LIST: {
            if (!Array.isArray(action?.likeList)) return state;

            const updatedList =
                state?.pageLikeList === 1 ? action?.likeList : [...state.list, ...action.likeList];

            return {
                ...state,
                ...{
                    list: updatedList,
                    isLoading: false,
                    isLoadedAll: !!(action?.likeList?.length % 20),
                },
            };
        }

        case LikesActionTypes.SET_LIKE_LIST_PAGE: {
            return {
                ...state,
                ...{
                    pageLikeList: action?.page,
                },
            };
        }

        case LikesActionTypes.SET_LIKE_HISTORY_ONLINE_STATUS: {
            const newList = state?.likeHistory?.length
                ? state?.likeHistory?.map((item) => {
                      if (action?.externalIDList?.includes(item?.external_id)) {
                          return { ...item, ...{ online: action?.status } };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ likeHistory: newList },
            };
        }
        case LikesActionTypes.INIT_LIKES: {
            return {
                ...state,
                ...{
                    list: [],
                    filter: action?.filter,
                    isLoading: true,
                    isLoadedAll: false,
                    pageLikeList: 1,
                    selectedListIndex: 0,
                    nextWomanData: {},
                },
            };
        }

        default:
            return state;
    }
}
