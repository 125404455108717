import { PurchaseActionTypes } from '../../constants/purchase';
import { IPurchaseState, PurchaseActions } from './index.interface';
import SITE from '../../../config/site';

const initState = {
    purchaseList: [],
    vat: null,
    isReverse: null,
    mostPopularPackageID: null,
    lastPurchaseList: [],
    isBuyingPastabank: false,
    isFirstPurchase: null,
    count: null,
    isLoadingBankRes: false,
    isPaymentFailed: false,
    packageBuyCount: null,

    lastBuyPackage: null,
    activePaymentMethodList: '',
    paymentMethodList: [],

    tokenList: [],

    isAddCard: false,

    activeSubscribeStatus: false,
    activeSubscribeData: null,
    activeSubscribePaymentMethod: '',
    activeSubscribeListInfo: [],
    isOpenSuccessSubscribeModal: false,
    isOpenChangeSubscribeModal: false,
    isOpenSuccessChangeSubscribeModal: false,
    errorMassage: '',
    isChangeSubscription: false,
    prevSubscriptionCreditPack: null,

    activePackage: null,
    activePaymentMethod: '',

    isPaymentPastaFailed: false,
};

export default function (
    state: IPurchaseState = initState,
    action: PurchaseActions,
): IPurchaseState {
    switch (action.type) {
        case PurchaseActionTypes.SET_IS_ADD_CARD:
            return {
                ...state,
                isAddCard: action?.status,
            };

        case PurchaseActionTypes.SET_TOKEN_LIST:
            return {
                ...state,
                tokenList: action?.tokenList,
            };

        case PurchaseActionTypes.ADD_LAST_BUY_PACKAGE:
            return {
                ...state,
                lastBuyPackage: action.lastBuyPackage,
            };

        case PurchaseActionTypes.REMOVE_LAST_BUY_PACKAGE:
            return {
                ...state,
                lastBuyPackage: null,
            };

        case PurchaseActionTypes.GET_PURCHASE_LIST: {
            const data =
                action?.isReverse && SITE.ID !== 23
                    ? action?.data?.slice()?.reverse()
                    : action?.data;

            return {
                ...state,
                purchaseList: data,
                vat: action?.vat,
                isReverse: action?.isReverse,
                mostPopularPackageID: action?.mostPopularPackageID,
                isFirstPurchase: action?.isFirstPurchase,
                count: action?.count,

                activePaymentMethodList: action?.activePaymentMethodList,
                paymentMethodList: action?.paymentMethodList,
            };
        }

        case PurchaseActionTypes.REMOVE_PURCHASE_LIST:
            return {
                ...state,
                lastPurchaseList: state?.purchaseList,
                purchaseList: [],
                vat: null,
                isReverse: null,
                mostPopularPackageID: null,
                isFirstPurchase: null,

                activePaymentMethodList: '',
                paymentMethodList: [],
            };

        case PurchaseActionTypes.INIT_SUBSCRIBE_STATUS:
            return {
                ...state,
                activeSubscribeStatus: action?.activeSubscribeStatus,
                activeSubscribeData: action?.activeSubscribeData,
                activeSubscribePaymentMethod:
                    action?.activeSubscribeData?.paymentMethod?.split('_RECURRING')?.[0] || '',
            };

        case PurchaseActionTypes.SET_ACTIVE_SUBSCRIBE:
            return {
                ...state,
                activeSubscribeStatus: action?.status,
            };

        case PurchaseActionTypes.CANCEL_ACTIVE_SUBSCRIBE:
            return {
                ...state,
                activeSubscribeStatus: false,
                activeSubscribeData: null,
                activeSubscribePaymentMethod: '',
            };

        case PurchaseActionTypes.GET_ACTIVE_SUBSCRIBE_LIST_INFO:
            return {
                ...state,
                activeSubscribeListInfo: action?.data,
            };

        case PurchaseActionTypes.CLEAR_ACTIVE_SUBSCRIBE_LIST_INFO:
            return {
                ...state,
                activeSubscribeListInfo: [],
            };

        case PurchaseActionTypes.SET_SUCCESS_SUBSCRIBE_MODAL:
            return {
                ...state,
                isOpenSuccessSubscribeModal: action?.status,
            };

        case PurchaseActionTypes.SET_CHANGE_SUBSCRIBE_MODAL:
            return {
                ...state,
                isOpenChangeSubscribeModal: action?.status,
            };

        case PurchaseActionTypes.SET_SUCCESS_CHANGE_SUBSCRIBE_MODAL:
            return {
                ...state,
                isOpenSuccessChangeSubscribeModal: action?.status,
            };

        case PurchaseActionTypes.SET_IS_CHANGE_SUBSCRIPTION:
            return {
                ...state,
                isChangeSubscription: action?.status,
                prevSubscriptionCreditPack: action?.credits,
            };

        case PurchaseActionTypes.SET_IS_BUYING_PASTABANK: {
            return {
                ...state,
                isBuyingPastabank: action?.status,
            };
        }

        case PurchaseActionTypes.SET_IS_PAYMENT_FAILED: {
            return {
                ...state,
                isPaymentFailed: action?.status,
                packageBuyCount: action?.packageBuyCount,
            };
        }

        case PurchaseActionTypes.SET_IS_PASTA_PAYMENT_FAILED: {
            return {
                ...state,
                isPaymentPastaFailed: action?.status,
                errorMassage: action?.errorMassage,
            };
        }

        case PurchaseActionTypes.SET_ACTIVE_PACKAGE: {
            return {
                ...state,
                activePackage: action?.activePackage,
            };
        }

        case PurchaseActionTypes.SET_ACTIVE_PAYMENT_METHOD: {
            return {
                ...state,
                activePaymentMethod: action?.activePaymentMethod,
            };
        }

        default:
            return state;
    }
}
