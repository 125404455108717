import * as alertAction from '../../store/actions/alert';
import * as purchaseAction from '../../store/actions/purchase';
import { sendBinomoFromStore } from '../../services/binomo';
import * as USER from '../../constants/user';
import * as ALERT from '../../constants/alert';
import { sendAfficeFromStore } from '../../services/affise';
import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import * as userAction from '../../store/actions/user';
import MarketingApi from '../../services/api/MarketingApi';
import useGoogleDataLayer from '../event/useGoogleDataLayer';
import useFBConversion from '../event/useFBConversion';
import { RootState } from '../../index';

interface IUseSuccessPaymentCallback {
    credits: number;
    count: number;
    price: number;
    isRouting?: boolean;
}

export default function useSuccessPaymentCallback() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const {
        pushEventUniversalPurchase,
        pushEventUniversalPurchase1,
        pushEventUniversalPurchaseOS,
        pushEventUniqueUniversalPurchase,
        pushEventFirstPurchase,
        pushEventFirstPurchaseFB,
        pushEventPurchase,
        pushEventFirstPurchaseValue,
        pushEventFirstPurchaseTargetOs,
        pushEventSecondPurchase,
        pushEventSecondPurchaseFB,
        pushEventThirdPurchaseValue,
        pushEventEcommerce,
        pushEventFirstPurchaseOs,
        pushEventSecondPurchaseOs,
        pushEventThirdPurchaseOs,
        pushEventFirstPurchaseWomen25,
        pushEventUniversalPurchaseWomen25,
        pushEventPixelOs,
        pushEventPixel,
    } = useGoogleDataLayer();
    const { sentFBFirstPurchase, sentFBTwoMorePurchase, sentFBEachPurchase } = useFBConversion();

    const userState = useSelector((state: RootState) => state.user);

    const initTail = (credits: number, count: number) => {
        const tail = `?utm_userid=${userState?.info?.external_id}&utm_credits=${credits}&utm_packages=${+count === 1 ? 'first' : 'again'}`;

        transition.addTail(tail, true);
    };

    const initAlert = (message: string) => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.SUCCESSFULLY_BOUGHT,
                message,
            }),
        );
    };

    const initMarketingStatistic = (count: number, price: number, isRouting = false) => {
        if (!userState?.info?.external_id) return;
        const isActiveTab = isRouting ? true : !document.hidden;

        pushEventEcommerce();

        if (count >= 1 && count <= 3) {
            sendBinomoFromStore({
                externalID: userState?.info?.external_id,
                siteID: userState?.info?.site_id,
                status: USER.BINOMO_BUY_CREDIT_LIST[+count - 1],
                clickid: userState?.clickid,
                utm_aff_hash: userState?.utm_aff_hash,
                ghost: userState?.info?.ghost,
                email: userState?.info?.email,
                tail: userState?.tail,
                count,
            });

            sendAfficeFromStore({
                status: USER.AFFISE_BUY_CREDIT_LIST[+count - 1],
                info: userState?.info,
            });
        }

        if (+count >= 1 && isActiveTab) {
            pushEventUniqueUniversalPurchase({ price, count });
            pushEventUniversalPurchaseOS({ price, count });
            sentFBEachPurchase(price);
        }

        if (+count > 1 && isActiveTab) {
            //GT-2199
            new MarketingApi().postCheckExistEvent('universal_purchase_2').then((res) => {
                if (res?.status && !res?.response?.check) {
                    pushEventUniversalPurchase({ price, count });
                    pushEventUniversalPurchase1({ price, count });
                }
            });

            pushEventUniversalPurchaseWomen25({ price, count });
        }

        if (+count === 1 && isActiveTab) {
            pushEventUniversalPurchase({ price, count });
            pushEventUniversalPurchase1({ price, count });
            pushEventPurchase({ count }); // Push DataLayer for FB
            pushEventFirstPurchase();
            pushEventFirstPurchaseFB();
            pushEventFirstPurchaseValue({ count });
            pushEventFirstPurchaseTargetOs({ count });
            pushEventFirstPurchaseOs();
            pushEventFirstPurchaseWomen25();
            pushEventPixelOs({ count });
            pushEventPixel({ count });

            new MarketingApi().postCheckExistEvent('universal_purchase').then((res) => {
                if (res?.status && res?.response?.check) {
                    if (userState?.tail?.includes('utm_age=45')) {
                        sentFBFirstPurchase();
                    }
                }
            });
        } else if (+count === 2 && isActiveTab) {
            if (userState?.tail?.includes('utm_age=45')) {
                sentFBTwoMorePurchase();
            }

            pushEventSecondPurchase();
            pushEventSecondPurchaseOs();
            pushEventSecondPurchaseFB();
        }

        if (+count === 3 && isActiveTab) {
            pushEventThirdPurchaseValue({ price, count });
            pushEventThirdPurchaseOs();
            pushEventPixelOs({ count });
            pushEventPixel({ count });
        }
    };

    const closeBuyingModal = () => {
        dispatch(userAction.getInfo(true));
        dispatch(userAction.isBuying(false));
        dispatch(purchaseAction.removeLastBuyPackage());
    };

    return ({ credits, count, price, isRouting = false }: IUseSuccessPaymentCallback) => {
        if (!isRouting) {
            initAlert(credits?.toString());
            closeBuyingModal();
        }

        initMarketingStatistic(count, price, isRouting);
        initTail(credits, count);
    };
}
