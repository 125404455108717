import React, { memo } from 'react';
import { formatDate } from '../../../../../services/time';
import styles from '../styles.module.scss';
import IconCross from '../../../../Icons/IconCross';
import { cutLengthString } from '../../../../../services/methods';
import { IViewedNotifyProps } from './index.interface';
import { getImageLink } from '../../../../../services/methods/search/images';
import * as IMAGES_CONST from '../../../../../constants/images';

const ViewedNotify = ({ item, deleteNotification, openChat }: IViewedNotifyProps) => {
    const classNameNotify = `${styles.notify_item} ${item?.search_img ? styles.notify_image_scale : ''} ${styles.viewed} ${item?.online ? styles.online : ''} ${+item?.favorite === 1 ? styles.favorites : ''}`;

    return (
        <li
            className={classNameNotify}
            onClick={() =>
                openChat(
                    item?.chat_uid ? item?.chat_uid : null,
                    item?.notification_id,
                    item?.sender_external_id,
                )
            }
            id={`noty-${item.id}`}
            data-id={item.id}
        >
            <div className={styles.notify_item_photo_wrap}>
                <img src={item?.avatar} alt="user avatar" className={styles.notify_item_photo} />
                {item?.search_img && (
                    <img
                        src={getImageLink(item?.search_img, IMAGES_CONST.SIZE_S)}
                        alt="user avatar"
                        className={styles.notify_item_photo_large}
                    />
                )}
                {+item?.unread_count > 1 && (
                    <span className={styles.unread}>{item?.unread_count}</span>
                )}
            </div>
            <div className={styles.notify_content}>
                <p className={styles.notify_item_name}>{`${item.name}, ${item.age}`}</p>
                <p className={styles.notify_item_text}>
                    {cutLengthString(item?.message_content ?? '', 50)}
                </p>
            </div>
            <button
                type="button"
                className={styles.notify_item_clear_mask}
                onClick={(e) => deleteNotification(e, item.chat_uid)}
            >
                <IconCross />
            </button>
            <p className={styles.notify_item_time}>{formatDate(item.date_created)}</p>
        </li>
    );
};

export default memo(ViewedNotify);
