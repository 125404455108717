import React from 'react';
import styles from '../styles.module.scss';
import * as IMAGES_CONST from '../../../constants/images';
import { getImageLink } from '../../../services/methods/search/images';
import * as USER from '../../../constants/user';
import { IOnlineNowItem } from '../../../store/reducers/onlineNow/index.interface';
import { IWomanGalleryImage } from '../../../store/actions/modals/index.interface';
import IconProfileChat from '../../Icons/IconProfileChat';
import IconChat from '../../Icons/IconChat';
import IconLikeHerHeart from '../../Icons/IconLikeHerHeart';
import IconFAQQuestionArrow from '../../Icons/IconFAQQuestionArrow';
import IconPlayVideo from '../../Icons/IconPlayVideo';
import IconVideos from '../../Icons/IconVideos';
import IconHelloHandLine from '../../Icons/IconHelloHandLine';
import IconLetters from '../../Icons/IconLetters';
import useHandleOnlineNowItem from '../../../hooks/onlineNow/useHandleOnlineNowItem';

interface IOnlineNowItemComponent {
    profile: IOnlineNowItem;
    openGalleryModal: (externalID: number, images: IWomanGalleryImage[], index?: number) => void;
    askVideo: (chat_uid: string, external_id: number, is_blocked: number) => void;
    openChat: (chatUid: string | null, is_blocked: number) => void;
    openLetter: (profile: any) => void;
    sayHelloList: string[];
}

const OnlineNowItem = ({
    profile,
    openGalleryModal,
    askVideo,
    openChat,
    openLetter,
    sayHelloList,
}: IOnlineNowItemComponent) => {
    const {
        sortedFreeImages,
        userInfo,
        redirectProfile,
        onOpenChat,
        onClickPhoto,
        onSetLike,
        onClickGalleryBtn,
        isVideo,
        videoPoster,
        onAskVideo,
        isHelloOpen,
        changeIsHelloOpen,
        onSendWink,
        onOpenLetter,
        girlWithVideo,
        sayHelloBtn,
        openVideo,
    } = useHandleOnlineNowItem({
        womanProfile: profile,
        openGalleryModal,
        askVideo,
        openChat,
        openLetter,
    });

    const mainImage = profile?.search_img;
    const winkStatus = !!profile?.wink;

    const mapGalleryListImg =
        !!+sortedFreeImages.length &&
        sortedFreeImages.map((img, index) => {
            const isFreeClass = !img?.payed && +img?.free === 0 ? 'blur_unpaid' : '';
            if (!userInfo) return;
            return (
                <div key={`${index}_${img?.id}`}>
                    <img
                        src={getImageLink(img?.link, IMAGES_CONST.SIZE_S)}
                        alt=""
                        className={isFreeClass}
                        onClick={() => onClickPhoto(img?.id)}
                    />
                </div>
            );
        });

    if (!profile?.external_id) return <div />;

    return (
        <div>
            {profile?.name && (
                <div className={styles.online_now_item}>
                    <span className={styles.online_now_item_status}>I’m online now</span>
                    <div className={styles.online_now_item_profile}>
                        <div
                            className={styles.online_now_item_profile_photo_wrap}
                            onClick={redirectProfile}
                        >
                            <img
                                src={mainImage}
                                alt="main image"
                                className={styles.online_now_item_profile_photo}
                            />
                            <div className={styles.online_now_item_profile_photo_text}>
                                <IconProfileChat />
                                <span>Profile</span>
                            </div>
                        </div>

                        <div className={styles.online_now_item_profile_info}>
                            <div
                                className={styles.online_now_item_profile_name}
                                onClick={redirectProfile}
                            >
                                {profile?.name}, {profile?.age}
                            </div>
                            <div className={styles.online_now_item_profile_buttons}>
                                <div
                                    className={styles.online_now_item_profile_chat_btn}
                                    onClick={onOpenChat}
                                >
                                    <IconChat />
                                    <span>Chat Now</span>
                                </div>
                                {profile?.liked ? (
                                    <div
                                        className={`${styles.online_now_item_profile_like_btn} ${styles.active}`}
                                    >
                                        <IconLikeHerHeart />
                                        <span>Liked</span>
                                    </div>
                                ) : (
                                    <div
                                        className={styles.online_now_item_profile_like_btn}
                                        onClick={onSetLike}
                                    >
                                        <IconLikeHerHeart />
                                        <span>Like</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.online_now_item_gallery}
                        onScroll={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <div className={styles.online_now_item_gallery_list_wrap}>
                            <div className={styles.online_now_item_gallery_list}>
                                {mapGalleryListImg}
                            </div>
                        </div>
                        <div
                            className={styles.online_now_item_gallery_btn}
                            onClick={onClickGalleryBtn}
                        >
                            <IconFAQQuestionArrow />
                            <span>View all photos</span>
                        </div>
                    </div>
                    <div className={styles.online_now_item_video_wrap}>
                        {isVideo && videoPoster ? (
                            <div className={styles.online_now_item_video} onClick={openVideo}>
                                <img
                                    src={videoPoster}
                                    alt=""
                                    className={styles.online_now_item_video_photo}
                                />
                                <div className={styles.online_now_item_video_play_ico}>
                                    <IconPlayVideo />
                                </div>
                            </div>
                        ) : userInfo &&
                          +userInfo?.convert_user &&
                          +userInfo?.payment_total >= USER.PAYMENT_TOTAL_TO_ASK_VIDEO ? (
                            <div className="c3_woman_profile_empty_video" onClick={onAskVideo}>
                                <div className="c3_woman_profile_empty_video_title">
                                    <IconPlayVideo />
                                    <span>Ask girl for a video</span>
                                </div>
                                <div className="c3_woman_profile_empty_video_btn">
                                    <IconVideos />
                                    <span>Ask now</span>
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                        <div
                            className={`${styles.online_now_item_video_buttons} ${userInfo && !+userInfo?.convert_user && +userInfo?.payment_total < USER.PAYMENT_TOTAL_TO_ASK_VIDEO && !girlWithVideo?.preview_link ? styles.dis_ask_video : ''}`}
                        >
                            {!winkStatus && (
                                <div
                                    className={styles.online_now_item_video_hello_btn_wrap}
                                    ref={sayHelloBtn}
                                >
                                    <div
                                        className={`${styles.online_now_item_video_hello_btn} ${isHelloOpen ? styles.active : ''}`}
                                        onClick={changeIsHelloOpen}
                                    >
                                        <IconHelloHandLine />
                                        <span>Say “Hello”</span>
                                    </div>
                                    {isHelloOpen && (
                                        <div className={`${styles.c3_wp_hello_list_wrap}`}>
                                            <div className={styles.c3_wp_hello_list_title}>
                                                Choose first message to {profile?.name ?? ''}
                                            </div>
                                            <div
                                                className={styles.c3_wp_hello_list}
                                                onScroll={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {!!sayHelloList?.length &&
                                                    sayHelloList?.map((item, key) => (
                                                        <div
                                                            className={styles.c3_wp_hello_list_item}
                                                            onClick={() => {
                                                                onSendWink(item);
                                                                changeIsHelloOpen();
                                                            }}
                                                            key={key}
                                                        >
                                                            {item}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div
                                className={styles.online_now_item_video_letter_btn}
                                onClick={onOpenLetter}
                            >
                                <IconLetters />
                                <span>Write a letter</span>
                            </div>
                            {!girlWithVideo &&
                            userInfo &&
                            +userInfo?.convert_user &&
                            +userInfo?.payment_total >= USER.PAYMENT_TOTAL_TO_ASK_VIDEO ? (
                                <div
                                    className={styles.online_now_item_video_her_video_btn}
                                    onClick={onAskVideo}
                                >
                                    <IconVideos />
                                    <span>Ask now</span>
                                </div>
                            ) : isVideo ? (
                                <div
                                    className={styles.online_now_item_video_her_video_btn}
                                    onClick={openVideo}
                                >
                                    <IconVideos />
                                    <span>Her video</span>
                                </div>
                            ) : (
                                !winkStatus && (
                                    <div
                                        className={`${styles.online_now_item_video_letter_btn} ${styles.mob}`}
                                        onClick={onOpenLetter}
                                    >
                                        <IconLetters />
                                        <span>Write letter</span>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(OnlineNowItem);
