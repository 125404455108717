import { useSelector } from 'react-redux';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';

export default function useHandleSift() {
    const userState = useSelector((state: RootState) => state.user);

    const init = () => {
        try {
            if (
                !process.env.REACT_APP_SIFT_BEACON_KEY ||
                !userState?.info?.external_id ||
                +userState?.info?.ghost
            ) {
                const existScript = document.getElementById('sift-script');
                if (existScript) existScript?.parentNode?.removeChild(existScript);

                window._sift = undefined;

                return null;
            }

            const _sift = (window._sift = window?._sift || []);
            _sift.push(['_setAccount', process.env.REACT_APP_SIFT_BEACON_KEY]);
            _sift.push(['_setUserId', userState?.info?.external_id]);
            _sift.push(['_trackPageview']);

            const script = document.createElement('script');
            script.id = 'sift-script';
            script.src = 'https://cdn.sift.com/s.js';
            document.body.appendChild(script);
        } catch (error) {
            sendErrToSentry(error as Error);
            return null;
        }
    };

    return { init };
}
