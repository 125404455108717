import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Moderation from './Moderation';
import Modal from 'react-modal';
import React from 'react';
import { IModerationModalProps } from './index.interface';

export default function ModerationModal({
    modalIsOpen,
    closeModal,
    validationEmail,
    delUploadedFile,
    onSubmitSupport,
    handleInput,
    formData,
    setFormData,
}: IModerationModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            ariaHideApp={false}
        >
            <Moderation
                validationEmail={validationEmail}
                delUploadedFile={delUploadedFile}
                onSubmit={onSubmitSupport}
                handleInput={handleInput}
                formData={formData}
                closeModal={closeModal}
                setFormData={setFormData}
            />
        </Modal>
    );
}
