import React from 'react';
import NotifyPushList from './NotifyPushList';
import BottomNotification from './BottomNotification';
import useHandleMobileNotification from '../../../hooks/notify/useHandleMobileNotification';
import IconAlarm from '../../Icons/IconAlarm';
import useAnimateMobileNotification from '../../../hooks/notify/useAnimateMobileNotification';

export default function MobileNotify() {
    const animateMobileNotification = useAnimateMobileNotification();
    const handleMobileNotification = useHandleMobileNotification();

    const classNameAnimate = animateMobileNotification?.isAnimate ? 'animate' : '';

    return (
        <>
            <div
                className={`mob_notif_fixed ${classNameAnimate}`}
                onClick={handleMobileNotification.openNotifyBlock}
            >
                <IconAlarm />
                {!!handleMobileNotification.countUnread && (
                    <div className="c2_item_photo_counter">
                        {handleMobileNotification.countUnread}
                    </div>
                )}
            </div>
            <NotifyPushList />
            <BottomNotification />
        </>
    );
}
