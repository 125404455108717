import React, { MouseEventHandler } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import '../../../styles/audio-player/letter-style.scss';

export default function LetterAudioPlayer({
    src,
    onViewMedia,
    onPayAudio,
}: {
    src: string;
    onViewMedia?: () => void;
    onPayAudio?: MouseEventHandler<HTMLDivElement>;
}) {
    return (
        <div id={src}>
            <AudioPlayer
                src={src}
                autoPlay={false}
                preload={'none'}
                onPlay={onViewMedia}
                customIcons={{
                    play: (
                        <div
                            className="audioplayer_play"
                            onClick={src?.length === 0 ? onPayAudio : () => null}
                        />
                    ),
                    pause: <div className="audioplayer_pause" />,
                }}
            />
        </div>
    );
}
