import React from 'react';

export default function IconVerifyRequestUpload() {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="#AFAFAF"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M24 8C21.8783 8 19.8434 8.84285 18.3431 10.3431C16.8429 11.8434 16 13.8783 16 16V36.828C17.3076 36.4583 18.6467 36.2106 20 36.088V16C20 14.9391 20.4214 13.9217 21.1716 13.1716C21.9217 12.4214 22.9391 12 24 12H40V26C40 27.5913 40.6321 29.1174 41.7574 30.2426C42.8826 31.3679 44.4087 32 46 32H60V64C60 65.0609 59.5786 66.0783 58.8284 66.8284C58.0783 67.5786 57.0609 68 56 68H41.6C40.8721 69.4249 39.9909 70.7662 38.972 72H56C58.1217 72 60.1566 71.1571 61.6569 69.6569C63.1571 68.1566 64 66.1217 64 64V29.656C63.9986 28.0652 63.3655 26.5401 62.24 25.416L46.584 9.756C45.4592 8.63193 43.9342 8.00034 42.344 8H24ZM59.172 28H46C45.4696 28 44.9609 27.7893 44.5858 27.4142C44.2107 27.0391 44 26.5304 44 26V12.828L59.172 28ZM22 76C26.7739 76 31.3523 74.1036 34.7279 70.7279C38.1036 67.3523 40 62.7739 40 58C40 53.2261 38.1036 48.6477 34.7279 45.2721C31.3523 41.8964 26.7739 40 22 40C17.2261 40 12.6477 41.8964 9.27208 45.2721C5.89642 48.6477 4 53.2261 4 58C4 62.7739 5.89642 67.3523 9.27208 70.7279C12.6477 74.1036 17.2261 76 22 76ZM31.416 56.588C31.6017 56.774 31.7489 56.9947 31.8493 57.2375C31.9497 57.4804 32.0013 57.7406 32.0011 58.0034C32.0009 58.2662 31.949 58.5264 31.8482 58.7691C31.7475 59.0118 31.6 59.2323 31.414 59.418C31.228 59.6037 31.0073 59.7509 30.7645 59.8513C30.5216 59.9517 30.2614 60.0033 29.9986 60.0031C29.7358 60.0029 29.4756 59.951 29.2329 59.8502C28.9902 59.7495 28.7697 59.602 28.584 59.416L24 54.828V66C24 66.5304 23.7893 67.0391 23.4142 67.4142C23.0391 67.7893 22.5304 68 22 68C21.4696 68 20.9609 67.7893 20.5858 67.4142C20.2107 67.0391 20 66.5304 20 66V54.828L15.416 59.416C15.041 59.7915 14.5321 60.0027 14.0014 60.0031C13.4707 60.0035 12.9615 59.793 12.586 59.418C12.2105 59.043 11.9993 58.5341 11.9989 58.0034C11.9985 57.4727 12.209 56.9635 12.584 56.588L20.584 48.588C20.9562 48.2146 21.4608 48.0033 21.988 48H22.012C22.5333 48.0032 23.0328 48.2099 23.404 48.576L23.416 48.588L31.416 56.588Z" />
        </svg>
    );
}
