import React, { memo } from 'react';
import { formatDate } from '../../../../../services/time';
import { cutLengthString, renderLastMessage } from '../../../../../services/methods';
import styles from '../styles.module.scss';
import IconFavouriteNotify from '../../../../Icons/IconFavouriteNotify';
import IconCross from '../../../../Icons/IconCross';
import { IMessageNotify } from './index.interface';
import { getImageLink } from '../../../../../services/methods/search/images';
import * as IMAGES_CONST from '../../../../../constants/images';

const MessageNotify = ({ item, deleteNotification, openChat }: IMessageNotify) => {
    const classNameNotify = `${styles.notify_item} ${item?.search_img ? styles.notify_image_scale : ''} ${styles.message} ${item?.online ? styles.online : ''} ${+item?.female_favorite ? styles.favorites : ''} ${item?.read_status === 1 ? styles.read : ''}`;
    const onOpenChat = () =>
        openChat(item?.chat_uid || null, item?.notification_id, item?.sender_external_id, item);

    const lastMessage =
        item?.message_type &&
        renderLastMessage(item?.message_type, item?.message_content, true, item?.name);

    return (
        <li
            id={`noty-${item?.id}`}
            data-id={item?.id}
            className={classNameNotify}
            onClick={onOpenChat}
            data-testid={`notification-message-${item?.id}`}
        >
            <div
                className={`${styles.notify_item_photo_wrap} ${+item?.female_favorite ? styles.favorite : ''}`}
                data-testid="notification-avatar"
            >
                <img src={item?.avatar} alt="user avatar" className={styles.notify_item_photo} />
                {item?.search_img && (
                    <img
                        src={getImageLink(item?.search_img, IMAGES_CONST.SIZE_S)}
                        alt="user avatar"
                        className={styles.notify_item_photo_large}
                    />
                )}
                {!!+item?.female_favorite && <IconFavouriteNotify />}
                {+item?.unread_count > 1 && (
                    <span className={styles.unread} data-testid="notification-message-count">
                        {item?.unread_count}
                    </span>
                )}
            </div>
            <div className={styles.notify_content}>
                <p
                    className={styles.notify_item_name}
                    data-testid="notification-name"
                >{`${item?.name}, ${item?.age}`}</p>
                <p className={styles.notify_item_place}>
                    <img src={item?.country_icon} alt="" data-testid="notification-country" />
                    <span data-testid="notification-city">{item?.city}</span>
                </p>
                <p className={styles.notify_item_text} data-testid="notification-last-message">
                    {cutLengthString(lastMessage ?? '', 40)}
                </p>
            </div>
            <button
                type="button"
                className={styles.notify_item_clear_mask}
                data-testid="notification-remove-btn"
                onClick={(e) => deleteNotification(e, item?.chat_uid)}
            >
                <IconCross />
            </button>
            <p className={styles.notify_item_time} data-testid="notification-date">
                {formatDate(item?.date_created)}
            </p>
        </li>
    );
};

export default memo(MessageNotify);
