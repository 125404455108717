import Base from './Base';

export default class CommonApi extends Base {
    async getAppConfig() {
        const res = await super.get(`common/appConfig`);
        return res?.data;
    }

    async updateBonusEventStatus(eventId: number) {
        const res = await super.post(`common/activate-bonus-event`, { eventId: eventId });
        return res?.data;
    }
}
