import { ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SupportApi from '../../services/api/SupportApi';
import * as REGEXP from '../../constants/regExp';
import * as loaderAction from '../../store/actions/loader';
import * as SUPPORT from '../../constants/support';
import * as alertAction from '../../store/actions/alert';
import * as LENGTH from '../../constants/length';
import * as MIRROR from '../../constants/mirror';
import SITE from '../../config/site';
import { RootState } from '../../index';

interface IFormData {
    email: string;
    id: number;
    file?: File;
    reason: string;
    womanExternalID?: number;
}

export default function useInitModeration() {
    const dispatch = useDispatch();
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);

    const initFormData = {
        email: userState?.info?.email || '',
        id: 0,
        file: undefined,
        reason: '',
        womanExternalID: 0,
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState<IFormData>(initFormData);
    const [validationEmail, setValidationEmail] = useState(false);
    const [isSuccessComplainModal, setIsSuccessComplainModal] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const openModal = (womanPublicExternalID: number, womanExternalID: number) => {
        setModalIsOpen(true);

        const id = womanPublicExternalID || currentChatState?.info?.public_external_id || 0;
        setFormData({ ...formData, id, womanExternalID });
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setFormData(initFormData);
    };

    const delUploadedFile = () => {
        setFormData({ ...formData, ...{ file: undefined } });

        if (fileInputRef?.current) {
            fileInputRef.current.value = '';
        }
    };

    const validateEmail = (email: string) => {
        return REGEXP.VALIDATION_EMAIL.test(String(email).toLowerCase());
    };

    const onSubmitSupport = (event: ChangeEvent<HTMLInputElement>) => {
        event?.preventDefault();
        if (!validateEmail(formData?.email)) return setValidationEmail(true);

        if (formData?.reason?.length) {
            dispatch(loaderAction.setActiveGifLoader(true));
            new SupportApi()
                .postSupportRequest({
                    email: formData?.email,
                    femaleExternalId: formData?.womanExternalID,
                    messageContent: formData?.reason,
                    file: formData?.file || undefined,
                    supportType: SUPPORT.SUPPORT_TYPE_REPORT,
                    payment_total: userState?.info?.payment_total || 0,
                    giftActive: +userState?.giftActive || 0,
                })
                .then((res) => {
                    dispatch(loaderAction.setActiveGifLoader(false));
                    if (res?.status) {
                        if (MIRROR.SUCCESS_COMPLAIN_MODAL.includes(SITE.ID)) {
                            setIsSuccessComplainModal(true);
                        } else {
                            dispatch(
                                alertAction.setMessage({
                                    title: `Ticket ID #${res?.result}.`,
                                    message: `The answer will be sent to your email.\n If you don't receive a reply within 3 days, please write to us at \n <a href='mailto:${userState?.supportEmail}'>${userState?.supportEmail}</a>`,
                                }),
                            );
                        }
                    }
                });
            closeModal();
        } else {
            dispatch(alertAction.setMessage({ message: `Please, describe the reason` }));
        }
    };

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, type, name } = event.target;
        setValidationEmail(false);

        if (type === 'file') {
            if (
                event?.target?.files?.[0] &&
                event?.target?.files?.[0]?.size > LENGTH.SUPPORT_MAX_IMG_SIZE
            ) {
                dispatch(
                    alertAction.setMessage({
                        message: `File you’ve uploaded is too big \n(max ${LENGTH.SUPPORT_MAX_IMG_SIZE_TITLE}Mb)`,
                    }),
                );
            } else {
                setFormData({ ...formData, ...{ file: event?.target?.files?.[0] as File } });
            }
        } else {
            setFormData({ ...formData, ...{ [name]: value } });
        }
    };

    const closeSuccessComplainModal = () => {
        setIsSuccessComplainModal(false);
    };

    return {
        modalIsOpen,
        validationEmail,
        formData,
        setFormData,
        openModal,
        closeModal,
        delUploadedFile,
        onSubmitSupport,
        handleInput,
        fileInputRef,
        isSuccessComplainModal,
        closeSuccessComplainModal,
        supportEmail: userState?.supportEmail,
    };
}
