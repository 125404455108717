import Base from '../Base';

export default class MeetingRequestApi extends Base {
    async checkUserGetContactRequest(womanExternalID: number) {
        try {
            const res = await super.get(`v3/contact-request/woman/${womanExternalID}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async checkImbraStatus(womanExternalID: number) {
        try {
            const res = await super.post(`v3/meeting-request/imbra`, { womanExternalID });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async saveImbraFile(formData: FormData) {
        try {
            const res = await super.post(`v3/meeting-request/imbra/file`, formData);
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
