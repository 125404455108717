import React, { ChangeEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as filesAction from '../../../../../store/actions/files';
import * as loaderAction from '../../../../../store/actions/loader';
import FilesApi from '../../../../../services/api/FilesApi';
import * as ATTACH from '../../../../../constants/attach';
import * as AWS from '../../../../../constants/aws';
import { IItemImageProps } from './index.interface';
import IconMove from '../../../../Icons/IconMove';
import IconTrash from '../../../../Icons/IconTrash';
import IconPhotoFullSize from '../../../../Icons/IconPhotoFullSize';
import { IUpdateSortOrderList } from '../../../../../services/api/FilesApi.interface';

export default function ItemImage({
    elementRef,
    data,
    opacity,
    setCurrItem,
    setModalContentIsOpen,
}: IItemImageProps) {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleInputChangeImg = (
        e: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>,
    ) => {
        const checkBox = e.currentTarget.querySelector('input');
        if (checkBox) {
            checkBox.checked = !checkBox.checked;
        }
    };

    const thumbList: Record<string, string> = {};
    AWS.AWS_THUMBS.forEach((size) => {
        let thumb: string[] | string = data.link.split('/');
        thumb[thumb?.length - 1] = `w-${size.width}-h-${size.height}-${thumb[thumb.length - 1]}`;
        thumb = thumb.join('/');
        thumbList[size.name] = thumb;
    });

    const openContentModal = (e: React.MouseEvent<HTMLDivElement>) => {
        dispatch(loaderAction.setActiveStatus(true));
        setCurrItem &&
            setCurrItem({
                link: e?.currentTarget?.dataset?.link ?? '',
                attachment_payed: '1',
            });
        setModalContentIsOpen && setModalContentIsOpen(true);
    };

    const onDeleteItem = () => {
        new FilesApi().postDeleteMedia(data?.id, data?.external_id).then((res) => {
            if (res?.status) {
                dispatch(filesAction.getAllImages());
            }
        });
    };

    const handleSort = () => {
        if (containerRef.current) {
            const listItems = containerRef.current?.parentNode?.querySelectorAll<HTMLElement>(
                '.upload_popup_tabs_content_item',
            );
            if (!listItems) return;
            const sortedArr: IUpdateSortOrderList[] = [];

            for (const [key, item] of listItems.entries()) {
                if (item?.dataset?.id) {
                    sortedArr.push({
                        id: +item.dataset.id,
                        sort_order: key,
                    });
                }
            }

            if (sortedArr?.length) {
                new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_IMAGE, sortedArr).then((res) => {
                    if (res?.status) {
                        dispatch(filesAction.setSortedFiles('images', sortedArr));
                    }
                });
            }
        }
    };

    return (
        <div
            className="upload_popup_tabs_content_item"
            ref={containerRef}
            style={{ opacity }}
            data-id={data?.id}
        >
            <div
                className="no-drag upload_popup_tabs_content_item_top"
                onClick={handleInputChangeImg}
                data-testid={data?.filename}
            >
                <input
                    className="no-drag"
                    type="checkbox"
                    name={`${data?.id}`}
                    data-link={data?.link}
                    data-filename={data?.filename}
                    onChange={handleInputChangeImg}
                />
                <label htmlFor="pp1" className="no-drag">
                    <img src={thumbList?.small} alt="small photo" />
                </label>
            </div>
            <div
                className="upload_popup_tabs_content_item_bottom"
                ref={elementRef}
                onDrop={(e) => e.preventDefault()}
                onDragOver={(e) => e.preventDefault()}
                onDragEnd={handleSort}
                onTouchStart={(e) => e.preventDefault()}
                onTouchMove={(e) => e.preventDefault()}
                onTouchEnd={handleSort}
            >
                <div
                    className="popup_full_img"
                    data-link={thumbList?.big}
                    onClick={openContentModal}
                >
                    <IconPhotoFullSize />
                </div>
                <div className="popup_move">
                    <IconMove propStyles={{ cursor: 'grab' }} />
                </div>
                <div className="popup_trash" onClick={onDeleteItem}>
                    <IconTrash />
                </div>
            </div>
        </div>
    );
}
