import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import LetterAudioPlayer from '../../../common/LetterAudioPlayer';
import ContentAttachModal from '../../../common/Modals/ContentAttachModal/ImgContent';
import VideoContentAttachModal from '../../../common/Modals/ContentAttachModal/VideoContent';
import { getAvatarMiniature, stringToColor, substrName } from '../../../../services/methods';
import { formatDate } from '../../../../services/time';
import Styles from './MessageRight.module.css';
import MailboxApi from '../../../../services/api/MailboxApi';
import * as currentChatMailboxAction from '../../../../store/actions/currentChatMailbox';
import { useDispatch, useSelector } from 'react-redux';
import { stopAudio } from '../../../../services/methods/chat';
import * as alertAction from '../../../../store/actions/alert';
import * as ALERT from '../../../../constants/alert';
import SlickContainer from '../../../common/SlickContainer';
import { sendErrToSentry } from '../../../../services/sentry';
import { RootState } from '../../../../index';
import { IMailsAttachment, IMailsFullInfo } from '../../../../services/api/MailboxApi.interface';
import { IProfileGiftCurrentItem } from '../../../Profile/ProfileGifts/index.interface';
import IconTrash from '../../../Icons/IconTrash';

interface IMessageRightProps {
    item: IMailsFullInfo;
    scrollPosition: (value: number) => void;
}

export default function MessageRight({ item, scrollPosition }: IMessageRightProps) {
    const userState = useSelector((state: RootState) => state.user);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);

    const dispatch = useDispatch();

    const [modalPhotoIsOpen, setModalPhotoIsOpen] = useState(false);
    const [modalVideoIsOpen, setModalVideoIsOpen] = useState(false);
    const [currItem, setCurrItem] = useState<IProfileGiftCurrentItem | null>(null);

    const switchAttachment = (data: IMailsAttachment, key: number) => {
        if (data?.message_type === 'SENT_IMAGE') {
            const openContentModal = () => {
                setCurrItem({ link: data?.link, attachment_payed: 1 });
                setModalPhotoIsOpen(true);
            };
            const closeContentModal = () => {
                setCurrItem(null);
                setModalPhotoIsOpen(false);
            };

            return (
                <React.Fragment key={item?.mail?.id + data?.attachment_id + key}>
                    <div
                        className="letters_attach_files_item file_photo"
                        onClick={openContentModal}
                        data-testid={data?.title}
                    >
                        <img src={data?.link} alt="" />
                    </div>
                    {!!currItem && (
                        <Modal
                            style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                            isOpen={modalPhotoIsOpen}
                            onRequestClose={closeContentModal}
                            ariaHideApp={false}
                        >
                            <ContentAttachModal item={currItem} closeModal={closeContentModal} />
                        </Modal>
                    )}
                </React.Fragment>
            );
        } else if (data?.message_type === 'SENT_AUDIO') {
            return (
                <div
                    className="letters_attach_files_item file_audio"
                    id={`lett-${data?.attachment_id}`}
                    key={item?.mail?.id + data?.attachment_id + key}
                    data-testid={data?.title}
                >
                    {/*<div className="letters_attach_files_item_play_title">*/}
                    {/*    <img src="../../../../img/letter-audio-notes.svg" alt="" />*/}
                    {/*    <span>*/}
                    {/*        {cutLengthString(data?.title, LENGTH.ATTACH_LETTER_AUDIO_TITLE)}*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <LetterAudioPlayer
                        src={data?.link}
                        onViewMedia={() => stopAudio(data?.link, true, data?.attachment_id)}
                    />
                </div>
            );
        } else if (data?.message_type === 'SENT_VIDEO') {
            const openContentModal = () => {
                stopAudio();
                setCurrItem({ link: data?.link, attachment_payed: 1 });
                setModalVideoIsOpen(true);
            };

            const closeContentModal = () => {
                setCurrItem(null);
                setModalVideoIsOpen(false);
            };

            return (
                <React.Fragment key={item?.mail?.id + data?.attachment_id + key}>
                    <div
                        className="letters_attach_files_item file_video"
                        onClick={openContentModal}
                        data-testid={data?.title}
                    >
                        {data?.thumb_link?.length ? (
                            <img className="popup_full_img" src={data?.thumb_link} alt="" />
                        ) : (
                            <video
                                width="96"
                                height="56"
                                id={'video-' + data?.attachment_id}
                                controls={false}
                                preload="metadata"
                            >
                                <source src={`${data?.link}#t=0.6`} type="video/mp4" />
                            </video>
                        )}
                    </div>
                    <Modal
                        style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                        isOpen={modalVideoIsOpen}
                        onRequestClose={closeContentModal}
                        ariaHideApp={false}
                    >
                        {!!currItem && (
                            <VideoContentAttachModal
                                item={currItem}
                                closeModal={closeContentModal}
                            />
                        )}
                    </Modal>
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    const userAvatar = () => {
        if (item?.mail?.sender_image) {
            return <img src={getAvatarMiniature(item?.mail?.sender_image)} alt="" />;
        } else {
            return (
                <div
                    className={Styles.user_avatar}
                    style={{ backgroundColor: stringToColor(item?.mail?.sender_name) }}
                >
                    {substrName(item?.mail?.sender_name)}
                </div>
            );
        }
    };

    const moveToTrash = () => {
        if (!userState?.info?.external_id) return;
        try {
            new MailboxApi()
                .postTrashMail({
                    userId: userState?.info?.external_id,
                    mailId: item?.mail?.id,
                    outbox: true,
                })
                .then((res) => {
                    if (res?.status && userState?.info?.external_id) {
                        dispatch(
                            currentChatMailboxAction.getMailboxChatHistoryForce({
                                userId: userState?.info?.external_id,
                                folder: 'dialog',
                                girlId: item?.mail?.public_external_id?.toString(),
                            }),
                        );
                    }
                });
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const openAlertMoveToTrash = () => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.ADD_TO_TRASH_FROM_DIALOGS,
                successCallback: moveToTrash,
            }),
        );
    };

    useEffect(() => {
        if (currentChatMailboxState?.specialLetterId && !currentChatMailboxState?.bookmarkLetterId)
            scrollPosition(currentChatMailboxState?.specialLetterId);
    }, [currentChatMailboxState.specialLetterId]);

    return (
        <>
            <div
                className="c3l_letters_list_item right"
                id={`letter-${item?.mail?.id}`}
                data-testid={`sent-letter-${item?.mail?.id}`}
            >
                <div className="c3l_letters_list_item_head">
                    <div
                        className={`message_status ${+item?.mail?.mail_read_status === 0 ? 'send' : 'readed'}`}
                        data-testid="letter-status"
                    />
                    <div className="c3l_letters_list_item_user" data-testid="letter-avatar">
                        <span data-testid="sender-name">You</span>
                        {userAvatar()}
                    </div>
                </div>
                <div className="c3l_letters_list_item_text" data-testid="letter-content">
                    <p>{item?.mail?.message_content}</p>
                </div>
                <div className="c3l_letters_list_item_time" data-testid="letter-date">
                    <div
                        className="c3l_letters_list_item_delete right"
                        onClick={openAlertMoveToTrash}
                        data-testid="delete-btn"
                    >
                        <IconTrash />
                        <span>Delete</span>
                    </div>
                    {formatDate(item?.mail?.date_created)}
                </div>

                {!!item?.attachments?.length && (
                    <div className="c3l_letters_list_item_bottom" data-testid="letter-attachments">
                        <div className="letters_attach_files_list">
                            <SlickContainer>
                                {item?.attachments?.map((attachment, key) =>
                                    switchAttachment(attachment, key),
                                )}
                            </SlickContainer>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
