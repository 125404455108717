import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Register from './Register';
import PropTypes from 'prop-types';
import { IRegisterModalProps } from './index.interface';

RegisterModal.propTypes = {
    openTermModal: PropTypes.func.isRequired,
    formDataRegister: PropTypes.object.isRequired,
    setFormDataRegister: PropTypes.func.isRequired,
    onSubmitFormRegister: PropTypes.func.isRequired,
    modalIsActiveRegister: PropTypes.bool.isRequired,
    closeRegisterModal: PropTypes.func.isRequired,
    errorFields: PropTypes.array,
    filterErrorFields: PropTypes.func.isRequired,
    openSignInBlock: PropTypes.func.isRequired,
    hasCaptcha: PropTypes.bool.isRequired,
    recaptchaRef: PropTypes.object,
};
export default function RegisterModal({
    openTermModal,
    formDataRegister,
    setFormDataRegister,
    onSubmitFormRegister,
    modalIsActiveRegister,
    closeRegisterModal,
    errorFields,
    filterErrorFields,
    openSignInBlock,
    hasCaptcha,
    recaptchaRef,
    onBirthdayChange,
}: IRegisterModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={modalIsActiveRegister}
            onRequestClose={closeRegisterModal}
            ariaHideApp={false}
        >
            <Register
                openTermModal={openTermModal}
                formDataRegister={formDataRegister}
                setFormDataRegister={setFormDataRegister}
                onRegister={onSubmitFormRegister}
                closeRegisterModal={closeRegisterModal}
                errorFields={errorFields}
                filterErrorFields={filterErrorFields}
                openSignInBlock={openSignInBlock}
                modalIsActiveRegister={modalIsActiveRegister}
                hasCaptcha={hasCaptcha}
                recaptchaRef={recaptchaRef}
                onBirthdayChange={onBirthdayChange}
            />
        </Modal>
    );
}
