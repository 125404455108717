import React from 'react';
import Active from './Active';
import Default from './Default';
import useHandleChatListItem from '../../../../../hooks/chat/useHandleChatListItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../index';
import { IItemProps } from './index.interface';

export default function Item({ item, chatListRef }: IItemProps) {
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const handleChatListItem = useHandleChatListItem(chatListRef);

    const isActiveChat = item?.chat_uid === currentChatState?.info?.chat_uid;

    if (handleChatListItem.isHideChatItem(item)) return <div />;

    return isActiveChat ? (
        <Active
            item={item}
            onDeleteChat={handleChatListItem.onDeleteChat}
            onActiveChat={handleChatListItem.onActiveChat}
            openWomanProfileModal={handleChatListItem.openWomanProfileModal}
        />
    ) : (
        <Default
            item={item}
            onDeleteChat={handleChatListItem.onDeleteChat}
            onActiveChat={handleChatListItem.onActiveChat}
        />
    );
}
