import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import { IManProfileReferenceCountryItem } from '../../../models/profile/ManProfile.interface';

interface ISearchCountry {
    handleCountryFilter: (inputValue: string) => void;
    initInputValue: string;
}

export default function SearchCountry({
    handleCountryFilter,
    initInputValue = '',
}: ISearchCountry) {
    const selectCountryRef = useRef<HTMLDivElement | null>(null);

    const referenciesState = useSelector((state: RootState) => state.referencies);

    const [openCountryBlock, setOpenCountryBlock] = useState(false);
    const [inputValue, setInputValue] = useState(initInputValue);

    useOutsideClicker(selectCountryRef, () => setOpenCountryBlock(false));

    const countryList: IManProfileReferenceCountryItem[] =
        referenciesState?.list?.country_list ?? [];

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) =>
        setInputValue(event.target.value);

    useEffect(() => {
        if (inputValue !== initInputValue) {
            const timeoutId = setTimeout(() => handleCountryFilter(inputValue), 500);
            return () => clearTimeout(timeoutId);
        }
    }, [inputValue]);

    return (
        <div className="c3lh_search_country" ref={selectCountryRef}>
            <div className="fill_data_form_select_item_wrap">
                <div className="fill_data_form_select_item_name">Country</div>
                <div className="fill_data_form_select_item">
                    <div className="cl2h_tab_wide_filter_item_wrap">
                        <div
                            className={`cl2h_tab_wide_filter_item ${openCountryBlock ? 'open' : ''}`}
                            data-testid="country"
                        >
                            <div className="fill_data_form_select_item_country_wrap">
                                <input
                                    className={`like_select_head ${inputValue?.length ? 'selected' : ''}`}
                                    placeholder={inputValue?.length ? inputValue : 'Choose country'}
                                    value={inputValue}
                                    onChange={onChangeInput}
                                    onClick={() => setOpenCountryBlock(!openCountryBlock)}
                                />
                                {!!+inputValue?.length && (
                                    <span
                                        className="like_select_head_remove"
                                        onClick={() => setInputValue('')}
                                    />
                                )}
                            </div>
                            <div
                                className={`like_select_list_wrap ${openCountryBlock ? 'open' : ''}`}
                            >
                                <div
                                    className={`like_select_list ${openCountryBlock ? 'open' : ''}`}
                                    data-testid="select-list"
                                >
                                    {countryList?.length > 0 &&
                                        countryList
                                            .filter(
                                                (item) =>
                                                    item?.country_name
                                                        .toLowerCase()
                                                        .includes(inputValue?.toLowerCase()) &&
                                                    item?.country_name !== 'Not Specified',
                                            )
                                            .map((item, key) => (
                                                <React.Fragment key={key}>
                                                    <input
                                                        type="radio"
                                                        name={`search_sel-${key}`}
                                                        id={`main-reg-${key}`}
                                                        className="like_select"
                                                    />
                                                    <label
                                                        htmlFor={`search_sel-${key}`}
                                                        className="like_select_label"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            setInputValue(item?.country_name);
                                                            setOpenCountryBlock(false);
                                                        }}
                                                    >
                                                        {item?.country_name}
                                                    </label>
                                                </React.Fragment>
                                            ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
