import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import useSubmitLogin from './useSubmitLogin';
import { IUseInitLogin } from './index.interface';

export default function useInitLogin(): IUseInitLogin {
    const initialStateFormDataLogin = { email: '', password: '' };

    const location = useLocation();
    const { hash }: any = useParams();

    const [activeForgetPassword, setActiveForgetPassword] = useState(false);
    const [formDataLogin, setFormDataLogin] = useState(initialStateFormDataLogin);
    const [modalIsActiveLogin, setModalIsActiveLogin] = useState(false);
    const [isOpenSignInBlock, setIsOpenSignInBlock] = useState(false);
    const [activationCode, setActivationCode] = useState<string | null>(null);
    const [activeSetPassword, setActiveSetPassword] = useState(false);
    const [errorLoginFields, setErrorLoginFields] = useState<string[]>([]);

    const openModalLogin = () => {
        setModalIsActiveLogin(true);
    };

    const closeModalLogin = () => {
        setActiveForgetPassword(false);
        setModalIsActiveLogin(false);
        setActiveSetPassword(false);
        setActivationCode(null);
        setFormDataLogin(initialStateFormDataLogin);
    };

    const openSignInBlock = () => {
        setIsOpenSignInBlock(true);

        // Smooth scroll to form
        if (window.innerWidth < 1140) {
            setTimeout(() => {
                document?.querySelector('#anchor-form-scroll-mob')?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }, 100);
        } else {
            setTimeout(() => {
                document
                    ?.querySelector('body')
                    ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 100);
        }
    };
    const closeSignInBlock = () => {
        setIsOpenSignInBlock(false);

        // Smooth scroll to form
        if (window.innerWidth < 1140) {
            setTimeout(() => {
                document?.querySelector('#anchor-form-scroll-mob')?.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }, 100);
        } else {
            setTimeout(() => {
                document
                    ?.querySelector('body')
                    ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 100);
        }
    };

    const openResetPassModal = () => setActiveForgetPassword(true);
    const closeResetPassModal = () => {
        setActiveForgetPassword(false);
        setModalIsActiveLogin(false);
        setActiveSetPassword(false);
        setActivationCode(null);
        setFormDataLogin(initialStateFormDataLogin);
    };

    const handleFormDataLoginEmail = (value: string) => {
        setFormDataLogin({ ...formDataLogin, ...{ email: value } });
        setErrorLoginFields(errorLoginFields?.filter((el) => el !== 'email'));
    };

    const handleFormDataLoginPassword = (value: string) => {
        setFormDataLogin({ ...formDataLogin, ...{ password: value } });
        setErrorLoginFields(errorLoginFields?.filter((el) => el !== 'password'));
    };

    const checkValidFieldsLogin = () => {
        const arr: any = [];

        if (!formDataLogin?.email?.length) {
            arr.push('email');
        }

        if (!formDataLogin?.password?.length) {
            arr.push('password');
        }

        setErrorLoginFields(arr);
        return !arr?.length;
    };

    useEffect(() => {
        activeSetPassword && openModalLogin();
    }, [activeSetPassword]);

    useEffect(() => {
        if (hash) {
            setActiveSetPassword(true);
            setActivationCode(hash);
        }
    }, [hash]);

    useEffect(() => {
        if (
            location.search?.includes('utm_netw=adrl') ||
            location.search?.includes('utm_netw=sofia_ggl_gdn_nik') ||
            location.search?.includes('utm_netw=myspec_ggl_gdn_nik') ||
            location.search?.includes('utm_netw=lfh_ggl_gdn_nik') ||
            location.search?.includes('utm_netw=tbl')
        ) {
            if (!localStorage.getItem('token')?.length) {
                openSignInBlock();
            }
        }
    }, []);

    const onSubmitLogin = useSubmitLogin(
        formDataLogin,
        closeModalLogin,
        activeSetPassword,
        activeForgetPassword,
        activationCode,
        setActiveSetPassword,
        setActivationCode,
        setActiveForgetPassword,
        checkValidFieldsLogin,
    );

    return {
        formDataLogin,
        setFormDataLogin,
        activeSetPassword,
        modalIsActiveLogin,
        activeForgetPassword,
        setActiveForgetPassword,
        openModalLogin,
        closeModalLogin,
        onSubmitLogin,
        isOpenSignInBlock,
        openSignInBlock,
        closeSignInBlock,
        openResetPassModal,
        closeResetPassModal,
        handleFormDataLoginEmail,
        handleFormDataLoginPassword,
        errorLoginFields,
    };
}
