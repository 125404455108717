import React from 'react';
import moment from 'moment/moment';
import GirlSimpleItem from '../../../like/LikeItemSimple';
import { useSelector } from 'react-redux';
import VIPListBanner from '../../../common/VIP/VIPListBanner';
import useWindowSize from '../../../../hooks/resize/useWindowSize';
import styles from '../styles.module.scss';
import { RootState } from '../../../../index';
import SearchCrossBanner from '../../SearchCrossBanner';
import SearchCrossBannerNewDesign from '../../SearchCrossBannerNewDesign';

export default function WithBannerList() {
    const searchState = useSelector((state: RootState) => state.search);
    const userState = useSelector((state: RootState) => state.user);

    const [width] = useWindowSize();

    const calcPointByWidth = () => {
        if (width > 1749) return 4;
        else if (width > 1299 && width < 1750) return 3;
        else if (width > 1199 && width < 1300) return 2;
        else if (width > 999 && width < 1200) return 3;
        else return 2;
    };

    return (
        <>
            <ul className={styles.search_result_wrap}>
                {searchState?.list?.slice(0, calcPointByWidth())?.map((item, key) => {
                    if ('target_site_id' in item) {
                        if (
                            userState?.info?.abtest?.toUpperCase() === 'B' &&
                            moment(userState?.info?.created_at)
                                .utc()
                                .isAfter('2025-04-03T00:00:00Z')
                        ) {
                            return <SearchCrossBannerNewDesign key={key} {...item} />;
                        } else {
                            return <SearchCrossBanner key={key} {...item} />;
                        }
                    }
                    return <GirlSimpleItem key={key} profile={item} />;
                })}
            </ul>
            <VIPListBanner />
            <ul className={styles.search_result_wrap}>
                {searchState?.list?.slice(calcPointByWidth())?.map((item, key) => {
                    if ('target_site_id' in item) {
                        if (
                            userState?.info?.abtest?.toUpperCase() === 'B' &&
                            moment(userState?.info?.created_at)
                                .utc()
                                .isAfter('2025-04-03T00:00:00Z')
                        ) {
                            return <SearchCrossBannerNewDesign key={key} {...item} />;
                        } else {
                            return <SearchCrossBanner key={key} {...item} />;
                        }
                    }
                    return <GirlSimpleItem key={key} profile={item} />;
                })}
            </ul>
        </>
    );
}
