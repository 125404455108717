import React from 'react';

export default function IconChatActive() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 11V9V7.48164C2.5 5.0065 4.47828 3 6.9186 3H8.5H16.5H18.0814C20.5217 3 22.5 5.0065 22.5 7.48164V9V11V13.1427C22.5 15.377 20.8879 17.2295 18.7791 17.5687V20.0073C18.7791 20.8899 17.727 21.3319 17.1117 20.7079L14.0715 17.6243H6.9186C4.47828 17.6243 2.5 15.6178 2.5 13.1427V11ZM6.91859 7.71753C6.53328 7.71753 6.22092 8.03434 6.22092 8.42516C6.22092 8.81597 6.53328 9.13278 6.91859 9.13278L18.0814 9.13278C18.4667 9.13278 18.7791 8.81597 18.7791 8.42516C18.7791 8.03435 18.4667 7.71753 18.0814 7.71753H6.91859ZM6.91859 11.4915C6.53328 11.4915 6.22092 11.8084 6.22092 12.1992C6.22092 12.59 6.53328 12.9068 6.91859 12.9068H18.0814C18.4667 12.9068 18.7791 12.59 18.7791 12.1992C18.7791 11.8084 18.4667 11.4915 18.0814 11.4915H6.91859Z"
            />
        </svg>
    );
}
