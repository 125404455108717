import React from 'react';

export default function IconYourLikes() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.53912 3.97063C6.93646 3.59429 6.15146 3.38995 5.08012 3.64347C3.96684 3.90692 3.06637 4.85137 2.68084 6.09186C2.29838 7.32246 2.45691 8.75259 3.35636 9.88752C4.84192 11.762 8.01137 14.5448 9.75447 15.987C11.4976 14.5448 14.667 11.762 16.1526 9.88752C17.052 8.75259 17.2106 7.32246 16.8281 6.09186C16.4426 4.85137 15.5421 3.90692 14.4288 3.64347C13.3575 3.38995 12.5725 3.59429 11.9698 3.97063C11.3434 4.3618 10.8738 4.96543 10.5198 5.56004C10.1836 6.12479 9.32533 6.12479 8.98911 5.56004C8.63511 4.96543 8.16556 4.3618 7.53912 3.97063ZM9.39338 17.2401C9.37397 17.2268 9.35534 17.2124 9.3376 17.197C7.72333 15.8862 4.06729 12.735 2.37443 10.599C1.20715 9.12607 1.02121 7.29649 1.49988 5.75631C1.97547 4.22604 3.14174 2.88865 4.78414 2.49999C6.19057 2.16718 7.3264 2.43085 8.21278 2.98435C8.87683 3.39901 9.37798 3.96144 9.75447 4.50236C10.131 3.96144 10.6321 3.39901 11.2962 2.98435C12.1826 2.43085 13.3184 2.16718 14.7248 2.49999C16.3672 2.88865 17.5335 4.22604 18.0091 5.75631C18.4877 7.29649 18.3018 9.12607 17.1345 10.599C15.4417 12.735 11.7856 15.8862 10.1713 17.197C10.1536 17.2124 10.135 17.2268 10.1156 17.2401C10.0032 17.3187 9.87744 17.3539 9.75448 17.3532C9.63152 17.3539 9.50572 17.3187 9.39338 17.2401Z"
            />
        </svg>
    );
}
