import React, { useState } from 'react';
import useTransition from '../../../../hooks/transition/useTransition';
import useWindowSize from '../../../../hooks/resize/useWindowSize';
import { useLocation } from 'react-router-dom';
import vipBanner from '../../../../img/vip/vip_banner.svg';

export default function VIPBanner() {
    const transition = useTransition();
    const location = useLocation();
    const [width] = useWindowSize();
    const [showBlock, setShowBlock] = useState<boolean>(true);

    const handleConfirmClick = () => {
        if (location?.pathname !== '/my-profile')
            transition.transitionWithOnlyHistoryPush('/my-profile');

        const profileVipBlock = document.querySelector('#profile-vip-block');
        if (profileVipBlock) profileVipBlock.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };

    const handleConfirmClose = () => {
        setShowBlock(false);
    };

    if (!showBlock) return null;

    return (
        <div className="chat_wrap_confirm_email_wrap vip_banner">
            <div className="chat_wrap_confirm_email_item">
                <img
                    src={vipBanner}
                    alt="vip banner"
                    className="chat_wrap_confirm_email_item_img"
                />
                {width < 700 ? (
                    <>
                        <div className="chat_wrap_confirm_email_item_middle">
                            <div className="chat_wrap_confirm_email_item_title">
                                <span>Get VIP</span> and
                            </div>
                            <div className="chat_wrap_confirm_email_item_text">
                                bonus 100 credits
                            </div>
                        </div>
                        <div className="button_get_credit" onClick={handleConfirmClick}>
                            <span>Get VIP</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="chat_wrap_confirm_email_item_middle">
                            <div className="chat_wrap_confirm_email_item_title">
                                The best privilege for you
                            </div>
                            <div className="chat_wrap_confirm_email_item_text">
                                Get the maximum benefit with VIP status
                            </div>
                        </div>
                        <div className="button_get_credit" onClick={handleConfirmClick}>
                            <span>Get VIP and bonus 100 credits</span>
                        </div>
                    </>
                )}
                <div className="chat_wrap_confirm_email_item_close" onClick={handleConfirmClose} />
            </div>
        </div>
    );
}
