import MarketingApi from '../../api/MarketingApi';
import { ICrossBannersData } from '../../../store/reducers/banners/index.interface';

export default async function fetchCrossBanners() {
    const crossBanners: ICrossBannersData[] = [];
    const marketingApi = new MarketingApi();

    const initialData = await marketingApi.getCrossBannerData();
    const onlineNowValue = initialData?.response?.show_in?.online_now;
    if (!onlineNowValue) {
        return crossBanners;
    }
    crossBanners.push(initialData?.response);
    const numberOfRequests = Math.ceil(20 / onlineNowValue) - 1;
    for (let i = 0; i < numberOfRequests; i++) {
        const data = await marketingApi.getCrossBannerData();
        if (data?.response) {
            crossBanners.push(data.response);
        }
    }
    return crossBanners;
}

export const shuffleArray = (array: string[]) => [...array].sort(() => Math.random() - 0.5);

type imageCacheItem = string[] | string;
const imageCache: Record<string, imageCacheItem> = {};
let previousType: string | null = null;

export const getCachedImages = (
    log_id: number,
    nationality: string,
    type: string,
): imageCacheItem => {
    const cacheKey = `${log_id}_${type}`;
    if (previousType && previousType !== type) {
        Object.keys(imageCache).forEach((key) => delete imageCache[key]);
    }
    previousType = type;
    const randomVariant = Math.floor(Math.random() * 10) + 1;
    if (imageCache[cacheKey]) {
        return imageCache[cacheKey];
    }
    if (type === 'search') {
        const randomImage = `banner-${type}-${nationality}-${randomVariant}.png`;
        imageCache[cacheKey] = randomImage;
        return randomImage;
    }
    const arrayLength = type === 'like' ? 3 : 5;
    const imageKeys = Array.from(
        { length: arrayLength },
        (_, i) => `banner-${type}-${nationality}-${randomVariant}-${i + 1}.png`,
    );
    imageCache[cacheKey] = shuffleArray(imageKeys);
    return imageKeys;
};
