import React from 'react';
import styles from './styles.module.scss';
import { ICrossBannersData } from '../../../store/reducers/banners/index.interface';
import { checkNationalitySite } from '../../../services/methods';
import MarketingApi from '../../../services/api/MarketingApi';
import * as bannersAction from '../../../store/actions/banners';
import { useDispatch } from 'react-redux';
import Banners from '../../../img/banner-online-now';
import { getCachedImages } from '../../../services/methods/crossBanner';

const OnlineNowCrossBanner = (props: ICrossBannersData) => {
    const { target_site_id, log_id } = props;
    const nationality = checkNationalitySite(target_site_id);
    const images = getCachedImages(log_id, nationality, 'online-now');
    const dispatch = useDispatch();
    const openConfirmTermModal = () => {
        if (log_id) {
            new MarketingApi().postClickCrossBanner(log_id);
        }
        dispatch(bannersAction.setOpenConfirmTermsModal(props));
    };
    return (
        <div>
            <div className={styles.online_now_cross_banner_item}>
                <div className={styles.online_now_cross_banner_gallery}>
                    {Array.isArray(images) &&
                        images.map((imageKey, index) => {
                            const imageSrc = Banners.images[imageKey];
                            return imageSrc ? (
                                <img
                                    key={index}
                                    src={imageSrc}
                                    alt={`${nationality} girl ${index + 1}`}
                                />
                            ) : null;
                        })}
                </div>
                <div className={styles.online_now_cross_banner_bottom}>
                    <button
                        type="button"
                        className={styles.online_now_cross_banner_button}
                        onClick={openConfirmTermModal}
                    >
                        <span className={styles.online_now_cross_banner_button_text}>
                            Try {nationality} Chatting
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OnlineNowCrossBanner;
