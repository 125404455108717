import React from 'react';
import styles from '../../../styles/love-stories/style.module.scss';
import Slider from 'react-slick';
import useInitLoveStories from '../../../hooks/loveStories/useInitLoveStories';
import useTransition from '../../../hooks/transition/useTransition';

export default function LoveStoriesProfiles() {
    const { womanProfiles } = useInitLoveStories();
    const transition = useTransition();

    const profileSliderSettings = {
        className: 'slider variable-width',
        infinite: true,
        dots: false,
        arrows: false,
        variableWidth: true,
        slidesToShow: 3,
        swipeToSlide: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <>
            <div className={styles.ls_profiles_wrap}>
                <div className={styles.ls_inner_container}>
                    <h2 className={`${styles.ls_title} ${styles.center}`}>Members you will like</h2>
                    {womanProfiles?.length ? (
                        <div className={styles.ls_profiles_row}>
                            <Slider {...profileSliderSettings}>
                                {womanProfiles?.map((profile) => (
                                    <div
                                        className={styles.ls_profiles_col}
                                        key={profile?.externalID}
                                    >
                                        <div className={styles.ls_profiles_img_wrap}>
                                            <img src={profile?.link} alt="member photo" />
                                        </div>
                                        <p className={styles.ls_profiles_name}>
                                            {profile?.name}, {profile?.age}
                                        </p>
                                        <p className={styles.ls_profiles_country}>
                                            {profile.country}
                                        </p>
                                        <button
                                            type="button"
                                            className={styles.ls_profiles_btn}
                                            onClick={() =>
                                                transition.transitionWithOnlyHistoryPush(
                                                    `/profile-woman/${profile?.externalID}`,
                                                )
                                            }
                                        >
                                            Open profile
                                        </button>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                        <div className="ls_loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
