import React from 'react';

export default function IconProfileChat() {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_6)">
                <path d="M12.6379 9.41652C11.8468 8.62541 10.9051 8.03975 9.8777 7.68522C10.9781 6.92733 11.701 5.65894 11.701 4.2247C11.701 1.90921 9.8172 0.0253906 7.50171 0.0253906C5.18621 0.0253906 3.3024 1.90921 3.3024 4.2247C3.3024 5.65894 4.02533 6.92733 5.12575 7.68522C4.09834 8.03975 3.15667 8.62541 2.36553 9.41652C0.993601 10.7885 0.238037 12.6125 0.238037 14.5527H1.37299C1.37299 11.1733 4.12231 8.42401 7.50171 8.42401C10.8811 8.42401 13.6304 11.1733 13.6304 14.5527H14.7654C14.7654 12.6125 14.0098 10.7885 12.6379 9.41652ZM7.50171 7.28906C5.81203 7.28906 4.43735 5.91441 4.43735 4.2247C4.43735 2.53499 5.81203 1.16034 7.50171 1.16034C9.19139 1.16034 10.5661 2.53499 10.5661 4.2247C10.5661 5.91441 9.19139 7.28906 7.50171 7.28906Z" />
            </g>
            <defs>
                <clipPath id="clip0_1_6">
                    <rect width="15" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
