import Base from '../Base';

export default class BotApi extends Base {
    async sendCrashBot(message: string) {
        return super
            .post(`v3/user-profile/crash`, { message })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }
}
