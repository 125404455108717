import React, { memo } from 'react';
import { formatDate } from '../../../../../services/time';
import { cutLengthString } from '../../../../../services/methods';
import styles from '../styles.module.scss';
import IconFavouriteNotify from '../../../../Icons/IconFavouriteNotify';
import IconCross from '../../../../Icons/IconCross';
import { ILetterNotifyProps } from './index.interface';
import { getImageLink } from '../../../../../services/methods/search/images';
import * as IMAGES_CONST from '../../../../../constants/images';

const LetterNotify = ({ item, deleteNotification, openLetter }: ILetterNotifyProps) => {
    const classNameNotify = `${styles.notify_item} ${item?.search_img ? styles.notify_image_scale : ''} ${styles.message} ${item?.online ? styles.online : ''}  ${styles.read} ${styles.letter}`;
    const mailId = item?.mail_id || item?.message_id;
    const onDeleteNotify = (event: React.MouseEvent<HTMLButtonElement>) =>
        deleteNotification(event, item?.chat_uid, 'letter');
    const onOpenLetter = () =>
        mailId && openLetter(item?.chat_uid, mailId, item?.sender_external_id);

    return (
        <li className={classNameNotify} onClick={onOpenLetter}>
            <div
                className={`${styles.notify_item_photo_wrap} ${+item?.favorite ? styles.favorite : ''}`}
            >
                <img src={item?.avatar} alt="user avatar" className={styles.notify_item_photo} />
                {item?.search_img && (
                    <img
                        src={getImageLink(item?.search_img, IMAGES_CONST.SIZE_S)}
                        alt="user avatar"
                        className={styles.notify_item_photo_large}
                    />
                )}
                {!!+item?.favorite && <IconFavouriteNotify />}
                {+item?.unread_count > 1 && (
                    <span className={styles.unread}>{item?.unread_count}</span>
                )}
            </div>
            <div className={styles.notify_content}>
                <p
                    className={styles.notify_item_name}
                >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                <div className={styles.notify_item_letter_content}>
                    <p
                        className={styles.notify_item_letter_title}
                    >{`${item?.name ?? ''} sent you a letter`}</p>
                    {!!item?.message_content && (
                        <p className={styles.notify_item_letter_text}>
                            {cutLengthString(item?.message_content ?? '', 40)}
                        </p>
                    )}
                    {!!item?.attachments_number && item?.attachments_number > 0 && (
                        <p
                            className={`${styles.notify_item_letter_text} ${styles.notify_attachment}`}
                        >
                            + {+item?.attachments_number}{' '}
                            {+item?.attachments_number > 1 ? 'attachments' : 'attachment'}
                        </p>
                    )}
                </div>
            </div>
            <button
                type="button"
                className={styles.notify_item_clear_mask}
                onClick={onDeleteNotify}
            >
                <IconCross />
            </button>
            <p className={styles.notify_item_time}>{formatDate(item?.date_created)}</p>
        </li>
    );
};

export default memo(LetterNotify);
