import { useDispatch, useSelector } from 'react-redux';
import { closeMobileMenu, closePanel, openNotifyPanel } from '../../services/dom';
import { generationDateOnFewMinutesLonger } from '../../services/methods';
import * as notifyAction from '../../store/actions/notify';
import * as currentChatAction from '../../store/actions/currentChat';
import useTransition from '../transition/useTransition';
import * as MENU from '../../constants/menu';
import * as modalsAction from '../../store/actions/modals';
import { RootState } from '../../index';
import { IUseHandleMobileNotification } from './index.interface';
import { INotifyItem } from '../../store/reducers/notify/index.interface';
import React from 'react';

export default function useHandleMobileNotification(): IUseHandleMobileNotification {
    const notifyState = useSelector((state: RootState) => state.notify);
    const userState = useSelector((state: RootState) => state.user);
    const docsState = useSelector((state: RootState) => state.profile.docsState);
    const dispatch = useDispatch();
    const transition = useTransition();

    const pauseMobileNotification = () => {
        if (notifyState?.settings?.MOBILE_NOTIFICATION_PAUSE_TIME) {
            dispatch(notifyAction.disActivePauseButton());
            localStorage.setItem(
                'mob-banner-pause-to',
                generationDateOnFewMinutesLonger(
                    notifyState.settings.MOBILE_NOTIFICATION_PAUSE_TIME,
                )?.toString(),
            );
            closePanel();
        }
    };

    const removeNotification = (
        event:
            | React.MouseEvent<HTMLDivElement, MouseEvent>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.TouchEvent<HTMLDivElement>,
        chat_uid: string,
    ) => {
        event?.stopPropagation();
        dispatch(notifyAction.deleteMobileNotify(chat_uid));
    };

    const removeMobileBottomNotify = (chat_uid: string) => {
        dispatch(notifyAction.deleteMobileBottomNotify(chat_uid));
    };

    const removeAllNotification = () => {
        dispatch(notifyAction.deleteAllMobileNotify());
    };

    const openChat = (item: INotifyItem) => {
        if (!userState?.info?.external_id) return;

        closePanel();
        dispatch(notifyAction.deleteNotify(userState?.info?.external_id, item?.chat_uid));
        dispatch(
            notifyAction.deleteTransactionNotify(
                item?.id,
                userState?.info?.external_id,
                item?.chat_uid,
            ),
        );
        dispatch(notifyAction.deleteMobileNotify(item?.chat_uid));
        dispatch(notifyAction.deleteMobileBottomNotify(item?.chat_uid));
        dispatch(currentChatAction.openFromNotification(item?.id, item?.sender_external_id));
        transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${item?.chat_uid}`);
    };

    const openWomanProfile = (item: INotifyItem) => {
        if (!userState?.info?.external_id) return;

        dispatch(notifyAction.deleteNotify(userState?.info?.external_id, item?.chat_uid));
        dispatch(
            notifyAction.deleteTransactionNotify(
                item?.id,
                userState?.info?.external_id,
                item?.chat_uid,
            ),
        );
        dispatch(notifyAction.deleteMobileNotify(item?.chat_uid));
        dispatch(notifyAction.deleteMobileBottomNotify(item?.chat_uid));
        dispatch(modalsAction.openWomanProfileModal(item?.sender_external_id));
    };

    const openNotifyBlock = () => {
        removeAllNotification();
        dispatch(modalsAction.setActiveSubMenu(''));
        closeMobileMenu();
        openNotifyPanel();
    };

    return {
        countUnread:
            +notifyState?.list?.length +
            +notifyState?.transactionList?.length +
            (docsState?.banner ? 1 : 0),
        openChat,
        pauseMobileNotification,
        removeNotification,
        removeAllNotification,
        removeMobileBottomNotify,
        openNotifyBlock,
        openWomanProfile,
    };
}
