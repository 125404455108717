export const IS_HALLOWEEN_MODAL = 'isHalloweenModal';
export const IS_HALLOWEEN_BANNER = 'isHalloweenBanner';

interface Holiday {
    name: string;
    start: Date;
    end: Date;
    bannerTitle: string;
    modalText: string;
}
const getHolidays = (): Holiday[] => {
    const currentYear = new Date().getUTCFullYear();
    const nextYear = currentYear + 1;

    return [
        {
            name: 'independence_day',
            start: new Date(Date.UTC(currentYear, 5, 30)),
            end: new Date(Date.UTC(currentYear, 6, 7)),
            modalText: '',
            bannerTitle: '',
        },
        {
            name: 'woman_day',
            start: new Date(Date.UTC(currentYear, 2, 3)),
            end: new Date(Date.UTC(currentYear, 2, 11)),
            modalText:
                'Today, we celebrate the amazing women in our lives - show your appreciation with kind words or a thoughtful gesture.',
            bannerTitle: 'Celebrate the Women Who Inspire You!',
        },
        {
            name: 'valentine_day',
            start: new Date(Date.UTC(currentYear, 1, 9)),
            end: new Date(Date.UTC(currentYear, 1, 17)),
            modalText: `Celebrate Valentine's Day by sharing joy and appreciation with those who matter most.`,
            bannerTitle: 'Love is in the air!',
        },
        {
            name: 'christmas',
            start: new Date(Date.UTC(currentYear, 11, 12)),
            end: new Date(Date.UTC(nextYear, 0, 7)),
            modalText: '',
            bannerTitle: '',
        },
        {
            name: 'thanksgiving_day',
            start: new Date(Date.UTC(currentYear, 10, 26)),
            end: new Date(Date.UTC(currentYear, 11, 3)),
            modalText: '',
            bannerTitle: '',
        },
        {
            name: 'easter',
            start: new Date(Date.UTC(currentYear, 3, 15)),
            end: new Date(Date.UTC(currentYear, 3, 25)),
            modalText: '',
            bannerTitle: '',
        },
        {
            name: 'halloween',
            start: new Date(Date.UTC(currentYear, 9, 25)),
            end: new Date(Date.UTC(currentYear, 10, 2)),
            modalText: '',
            bannerTitle: '',
        },
        {
            name: 'lunar_new_year',
            start: new Date(Date.UTC(currentYear, 0, 20)),
            end: new Date(Date.UTC(currentYear, 1, 3)),
            bannerTitle: 'Happy Lunar New Year!',
            modalText: 'Wishing you joy, prosperity and success in this Year!',
        },
    ];
};

export const isHolidayActive = (): Holiday | undefined => {
    const now = new Date();
    const holidays = getHolidays();
    const activeHoliday = holidays.find((holiday) => now >= holiday.start && now < holiday.end);
    const storedHolidayName = localStorage.getItem('active-holiday');
    const storedHolidayStart = localStorage.getItem('active-holiday-start');
    const storedHolidayEnd = localStorage.getItem('active-holiday-end');

    if (activeHoliday && !storedHolidayName) {
        return activeHoliday;
    }

    if (storedHolidayName) {
        const holidayFromStorage = holidays.find((holiday) => holiday.name === storedHolidayName);

        if (holidayFromStorage) {
            const start = storedHolidayStart
                ? new Date(storedHolidayStart)
                : holidayFromStorage.start;
            const end = storedHolidayEnd ? new Date(storedHolidayEnd) : holidayFromStorage.end;

            if (now >= start && now < end) {
                return {
                    ...holidayFromStorage,
                    start,
                    end,
                };
            }
        }
    }

    return undefined;
};
