// @ts-expect-error socket.io-client
import io from 'socket.io-client';
import { SOCKET_URI, SOCKET_PATH, PROVIDER_SOCKET } from '../../../constants/sync';
import { localStorageGetItem } from '../../../utils/local-storage';
import { sendErrToSentry } from '../../sentry';
import { ISyncMessage } from '../../../hooks/sync/useSync.interface';
import { getSessionID } from '../../../utils/session-storage';

export default () => {
    const userChannels: string[] = [];
    const socket = io(SOCKET_URI, {
        autoConnect: true,
        path: SOCKET_PATH,
        forceNew: true,
        withCredentials: false,
        // transports: ['websocket'],
        // query: {
        //     token: JSON.parse(localStorage.getItem('token')),
        // },
        transportOptions: {
            polling: {
                extraHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    'X-USER-TOKEN': localStorageGetItem('token', true),
                    'x-session-key': getSessionID(),
                },
            },
        },
    });

    return {
        subscribe: (channels: string[]) => {
            try {
                channels.forEach((channel) =>
                    !userChannels.find((item) => item === channel)
                        ? userChannels.push(channel)
                        : null,
                );
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        },
        addListener: (type: string, callback: (value: ISyncMessage) => void) => {
            try {
                userChannels.forEach((channel) =>
                    socket.on(channel, (data: ISyncMessage) => callback(data)),
                );
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        },
        addConnection: (callback: any) => {
            try {
                socket.on('disconnect', () => callback(false));
                socket.on('connect', () => callback(true));
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        },
        providerActive: PROVIDER_SOCKET,
    };
};
