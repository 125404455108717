import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import * as USER from '../../constants/user';
import * as ERROR from '../../constants/error';
import MeetingRequestApi from '../../services/api/v3/MeetingRequestApi';
import { RootState } from '../../index';

export default function useInitMeetingRequest({ profile }: any) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const [isActiveImbraModal, setIsActiveImbraModal] = useState(false);
    const [isUserGetContactApprove, setIsUserGetContactApprove] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);

    const isAllowGetMeetingRequest =
        userState?.imbraData?.status === USER.IMBRA_VERIFIED && isUserGetContactApprove;

    const uploadImbraFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        const type = file?.type?.toLowerCase();

        if (
            !type?.includes('jpeg') &&
            !type?.includes('jpg') &&
            !type?.includes('png') &&
            !type?.includes('pdf')
        ) {
            dispatch(
                alertAction.setMessage({
                    message: `Please upload a file in the pdf, jpg or png format`,
                    title: 'Wrong file format',
                }),
            );
            return false;
        }

        const formData = new FormData();
        formData.append('file', file as Blob);
        formData.append('womanExternalID', profile?.id);

        new MeetingRequestApi().saveImbraFile(formData).then((res) => {
            if (res?.status) {
                dispatch(userAction.setImbraStatus(USER.IMBRA_IN_PROGRESS));
            } else {
                dispatch(alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }));
            }
        });
    };

    useEffect(() => {
        (async function () {
            setIsLoadingModal(true);

            if (userState?.info?.is_verified) {
                const resImbra = await new MeetingRequestApi().checkImbraStatus(profile?.id);
                if (resImbra?.status) {
                    dispatch(userAction.setImbraData(resImbra?.result));
                }
            }

            const resCheckContactRequest = await new MeetingRequestApi().checkUserGetContactRequest(
                profile?.id,
            );
            if (
                resCheckContactRequest?.status &&
                resCheckContactRequest.result?.status === USER.CONTACT_REQUEST_APPROVED
            ) {
                setIsUserGetContactApprove(true);
            }

            setIsLoadingModal(false);
        })();

        return () => {
            dispatch(userAction.setImbraData({}));
        };
    }, []);

    return {
        isActiveImbraModal,
        openImbraModal: () => setIsActiveImbraModal(true),
        closeImbraModal: () => setIsActiveImbraModal(false),
        imbraStatus: userState?.imbraData?.status,
        reasonImbraCanceled: userState?.imbraData?.reason,
        uploadImbraFile,
        isLoadingModal,
        isUserGetContactApprove,
        isAllowGetMeetingRequest,
    };
}
