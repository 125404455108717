import Base from './Base';
import { ISendGiftData } from './Gifts.interface';

export default class GiftAPI extends Base {
    getGifts() {
        return super.post(`shop/products`);
    }

    getCategories() {
        return super.post(`shop/categories`);
    }

    sendGift(data: ISendGiftData) {
        return super.post(`shop/addorder`, data);
    }
}
