import React, { useState } from 'react';
import styles from './styles.module.scss';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Modal from 'react-modal';
import moment from 'moment';
import * as modalsAction from '../../../../store/actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import SITE from '../../../../config/site';
import { RootState } from '../../../../index';
import { localStorageGetItem, localStorageSetItem } from '../../../../utils/local-storage';

const LS_CONST = 'confirm-modal';

export default function ConfirmModal() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);
    const [isShowBlock, setIsShowBlock] = useState(true);

    const isUserConvert = !!userState?.info?.convert_user;
    // const amountCredits = isUserConvert ? 20 : 10;
    const amountDays = isUserConvert ? 3 : 1;
    const isConfirmedEmail = !!userState?.info?.email_confirmed;

    const onConfirm = () => {
        setIsShowBlock(false);
        dispatch(modalsAction.openEmailEdit());
        localStorageSetItem(LS_CONST, new Date().toISOString());
    };

    const onClose = () => {
        setIsShowBlock(false);
        localStorageSetItem(LS_CONST, new Date().toISOString());
    };

    const isShowByLocalStorageCondition = () => {
        const isActiveByLS = localStorageGetItem(LS_CONST);
        if (!isActiveByLS && userState?.info) {
            return (
                moment(new Date()).diff(moment(new Date(userState?.info?.created_at)), 'hours') >=
                24 * amountDays
            );
        }

        return (
            typeof isActiveByLS === 'string' &&
            moment(new Date()).diff(moment(new Date(isActiveByLS)), 'hours') >= 24 * amountDays
        );
    };

    if (!isShowByLocalStorageCondition() || !isShowBlock || isConfirmedEmail) return null;
    return (
        <>
            {SITE.ID !== 4 && (
                <Modal
                    style={modalStyleProps()}
                    isOpen={true}
                    onRequestClose={onClose}
                    ariaHideApp={false}
                >
                    <div className={styles.wrapper} data-testid="confirm-email-modal">
                        <div className={styles.logo} />
                        <div className={styles.title}>Be aware of all events!</div>
                        <div className={styles.sub}>
                            Confirm your email to receive unique regular discounts
                        </div>
                        <div
                            className={styles.button}
                            onClick={onConfirm}
                            data-testid="confirm-btn"
                        >
                            <div className={styles.button_title}>Confirm</div>
                        </div>
                        <div className={styles.cross} onClick={onClose} data-testid="close-btn" />
                    </div>
                </Modal>
            )}
        </>
    );
}
