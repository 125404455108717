import React from 'react';

export default function IconLogout() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#00317B"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_76)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.6 1.5C2.36262 1.5 2.08338 1.62373 1.85355 1.85355C1.62373 2.08338 1.5 2.36262 1.5 2.6V13.4C1.5 13.6374 1.62373 13.9166 1.85355 14.1464C2.08338 14.3763 2.36262 14.5 2.6 14.5H5.7C5.97614 14.5 6.2 14.7239 6.2 15C6.2 15.2761 5.97614 15.5 5.7 15.5H2.6C2.03738 15.5 1.51662 15.2237 1.14645 14.8536C0.776273 14.4834 0.5 13.9626 0.5 13.4V2.6C0.5 2.03738 0.776274 1.51662 1.14645 1.14645C1.51662 0.776274 2.03738 0.5 2.6 0.5H5.7C5.97614 0.5 6.2 0.723858 6.2 1C6.2 1.27614 5.97614 1.5 5.7 1.5H2.6ZM10.7464 3.74645C10.9417 3.55118 11.2583 3.55118 11.4536 3.74645L15.3536 7.64645C15.5488 7.84171 15.5488 8.15829 15.3536 8.35355L11.4536 12.2536C11.2583 12.4488 10.9417 12.4488 10.7464 12.2536C10.5512 12.0583 10.5512 11.7417 10.7464 11.5464L13.7929 8.5H5.7C5.42386 8.5 5.2 8.27614 5.2 8C5.2 7.72386 5.42386 7.5 5.7 7.5H13.7929L10.7464 4.45355C10.5512 4.25829 10.5512 3.94171 10.7464 3.74645Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_76">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
