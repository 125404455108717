import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as letterFilterAction from '../../../../store/actions/letterFilter';
import { RootState } from '../../../../index';
import { IOpenFormLetterButtonNewProps } from './index.interface';

export default function OpenFormLetterButtonNew({
    onPressButton,
    titleButton = '',
    style = null,
}: IOpenFormLetterButtonNewProps) {
    // const menuState = useSelector(state => state.menu);
    const lettersFilter = useSelector((state: RootState) => state.letterFilter.lettersFilter);
    const letterCounter = useSelector((state: RootState) => state.counters.letterCounter);

    const dispatch = useDispatch();

    const onAllLetters = () => {
        dispatch(letterFilterAction.setLettersFilter('all'));
    };

    const onUnreadLetters = () => {
        dispatch(letterFilterAction.setLettersFilter('unread'));
    };

    return (
        <div className="c2l_compose_wrap mobile_c2l_compose_wrap">
            {/*<div className="c3l_mob_folder">*/}
            {/*    <div className={`c3l_mob_folder_img ${menuState.letters ?? ''}`}/>*/}
            {/*    <div*/}
            {/*        className="c3l_mob_folder_text">{firstCharacterToUppercase(menuState.letters === 'inbox' ? 'mailbox' : menuState.letters)}</div>*/}
            {/*</div>*/}

            <button
                type="button"
                className={`c2l_letter_filter_btn ${lettersFilter !== 'unread' ? 'selected' : ''}`}
                onClick={onAllLetters}
            >
                All letters
            </button>

            <button
                type="button"
                className={`c2l_letter_filter_btn ${lettersFilter === 'unread' ? 'selected' : ''}`}
                onClick={onUnreadLetters}
            >
                {!!letterCounter && (
                    <span className="c2l_letter_filter_counter">{letterCounter}</span>
                )}
                Unread
            </button>

            <button
                type="button"
                className="mobile_c2l_compose_btn"
                style={style ?? {}}
                onClick={onPressButton}
            >
                {titleButton}
            </button>
        </div>
    );
}
