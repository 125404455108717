import React, { MouseEventHandler } from 'react';
import styles from './styles.module.scss';
import * as IMAGES from '../../../Images';
import IconCross from '../../../../Icons/IconCross';
import { IBonusData } from '../../../../../hooks/emailResubscription/useEmailResubscription';

export interface IEmailResubscriptionSpecialProps {
    closeRegisteredUserModal: MouseEventHandler<HTMLButtonElement>;
    sendEmailSubscription: MouseEventHandler<HTMLButtonElement>;
    bonusData?: IBonusData;
}

export default function EmailResubscriptionSpecial({
    closeRegisteredUserModal,
    sendEmailSubscription,
    bonusData,
}: IEmailResubscriptionSpecialProps) {
    return (
        <div className={styles.email_resubscribe_popup} data-testid="email-resubscription-modal">
            <h3 className={styles.email_resubscribe_title} data-testid="title">
                Sign up for email newsletters
            </h3>
            <img
                className={styles.email_resubscribe_img}
                src={IMAGES.emailSubscription ?? ''}
                alt="Email subscrtions"
                width="350"
                height="233"
            />
            <p className={styles.email_resubscribe_text}>
                {`We noticed you're not subscribed to our email alerts yet.`}
            </p>
            {!!bonusData?.bonusCredits && (
                <p className={styles.email_resubscribe_text}>
                    {`Stay informed with important updates and notifications—subscribe now and get ${bonusData.bonusCredits} FREE BONUS CREDITS instantly!`}
                </p>
            )}

            <div className={styles.email_resubscribe_btn_wrap}>
                <button
                    className={styles.email_resubscribe_btn}
                    type="button"
                    onClick={sendEmailSubscription}
                >
                    <span>Confirm</span>
                </button>
            </div>

            <button
                type="button"
                className={styles.close_modal}
                onClick={closeRegisteredUserModal}
                data-testid="close-btn"
            >
                <IconCross />
            </button>
        </div>
    );
}
