import React from 'react';

export default function IconDeletePhoto() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="#02317C"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_60)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1422 4.85786 16.5 9 16.5C13.1422 16.5 16.5 13.1422 16.5 9C16.5 4.85786 13.1422 1.5 9 1.5ZM0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9ZM6.24645 6.24645C6.44171 6.05118 6.75829 6.05118 6.95355 6.24645L9 8.29289L11.0464 6.24645C11.2417 6.05118 11.5583 6.05118 11.7536 6.24645C11.9488 6.44171 11.9488 6.75829 11.7536 6.95355L9.70711 9L11.7536 11.0464C11.9488 11.2417 11.9488 11.5583 11.7536 11.7536C11.5583 11.9488 11.2417 11.9488 11.0464 11.7536L9 9.70711L6.95355 11.7536C6.75829 11.9488 6.44171 11.9488 6.24645 11.7536C6.05118 11.5583 6.05118 11.2417 6.24645 11.0464L8.29289 9L6.24645 6.95355C6.05118 6.75829 6.05118 6.44171 6.24645 6.24645Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_60">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
