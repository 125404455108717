import React from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../../services/time';
import * as CHATS from '../../../../../constants/chats';
import styles from '../styles.module.scss';
import IconCross from '../../../../Icons/IconCross';
import IconMatch from '../../../../Icons/IconMatch';
import { RootState } from '../../../../../index';
import { IEachLikeNotifyProps } from './index.interface';
import placeholder from '../../../../../img/placeholder.gif';
import IconProfileChat from '../../../../Icons/IconProfileChat';

export default function EachLikeNotify({ item, deleteNotify, openChat }: IEachLikeNotifyProps) {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <>
            <li className={`${styles.notify_item_each_like} ${styles.online}`}>
                <p className={styles.notify_item_each_like_title}>You like each other</p>
                <div className={styles.notify_item_each_like_row}>
                    {userState?.info?.photo_link ? (
                        <img
                            src={userState?.info?.photo_link}
                            alt="user avatar"
                            className={styles.notify_item_each_like_man_photo}
                        />
                    ) : (
                        <div
                            className={`${styles.notify_item_each_like_man_photo} ${styles.notify_item_empty_avatar}`}
                        >
                            <IconProfileChat />
                        </div>
                    )}

                    <IconMatch />
                    <div className={styles.notify_item_each_like_girl_photo_wrap}>
                        <img
                            src={item?.photo_link || placeholder}
                            alt="user avatar"
                            className={styles.notify_item_each_like_girl_photo}
                        />
                    </div>
                    <button
                        type="button"
                        className={styles.notify_item_each_like_hi_btn}
                        onClick={() =>
                            openChat(
                                item,
                                item?.sender_external_id,
                                CHATS.MESSAGE_CONTENT_TYPE_WINK,
                            )
                        }
                    >
                        <IconMatch />
                        <span>Say “Hi” now</span>
                    </button>
                </div>
                <p className={styles.notify_item_each_like_time}>
                    {formatDate(item?.date_created)}
                </p>
                <button
                    type="button"
                    className={styles.notify_item_clear_mask}
                    onClick={() => deleteNotify(item)}
                >
                    <IconCross />
                </button>
            </li>
        </>
    );
}
