import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoShowApiNew from '../../services/api/v3/VideoShowApi';
import VideoShowApi from '../../services/api/VideoShowApi';
import { compareDates } from '../../services/time';
import * as DATE from '../../constants/date';
import * as alertAction from '../../store/actions/alert';
import * as ALERT from '../../constants/alert';
import * as modalsAction from '../../store/actions/modals';
import { RootState } from '../../index';

export default function useOpenVideoShow(femaleExternalID: number) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const openVideoModal = () => {
        dispatch(modalsAction.openWomanVideoShowModal(femaleExternalID));
    };

    const openVideo = async () => {
        if (isLoadingRequest) return false;
        setIsLoadingRequest(true);

        const videoShowCount = localStorage.getItem('video_show_count') ?? 3;
        const hasUserFreeVideo = await new VideoShowApiNew()
            .checkPaidVideoShow()
            .then((res) => {
                return res?.response?.isFirst;
            })
            .finally(() => setIsLoadingRequest(false));

        const isVideoPaid = await new VideoShowApi()
            .getVideoByExternalID(femaleExternalID)
            .then((res) => {
                if (res?.status && res?.result?.length > 0) {
                    return res?.result[0]?.isAlreadyPaid;
                }
            });

        if (
            !hasUserFreeVideo &&
            +videoShowCount > 0 &&
            !isVideoPaid &&
            !compareDates(DATE.ADDED_VIDEO_SHOW_ALERT_MODAL, userState?.info?.created_at || '')
        ) {
            localStorage.setItem('video_show_count', (+videoShowCount - 1)?.toString());

            dispatch(
                alertAction.setCustomAlertType({
                    alertType: ALERT.VIDEO_SHOW_ALERT,
                    successCallback: openVideoModal,
                }),
            );
        } else {
            openVideoModal();
        }
    };

    return { openVideo };
}
