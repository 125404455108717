import React from 'react';
import AuthImage from '../../img/auth';
import styles from './styles.module.scss';
import global_styles from '../global.module.scss';

export default function AuthQuestions() {
    return (
        <div className={styles.main_info_block}>
            <div className={global_styles.new_main_container}>
                <div className={styles.main_info_block_list}>
                    <div className={styles.main_info_block_item}>
                        <img
                            className={styles.main_info_block_item_ico}
                            src={AuthImage.images['feature-1.svg']}
                            width="100"
                            height="100"
                            alt="Register for free"
                        />
                        <h3 className={styles.main_info_block_item_title}>Join for free</h3>
                        <p className={styles.main_info_block_item_text}>
                            You don’t pay for registration
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            className={styles.main_info_block_item_ico}
                            src={AuthImage.images['feature-2.svg']}
                            width="100"
                            height="100"
                            alt="Free galleries"
                        />
                        <h3 className={styles.main_info_block_item_title}>
                            Adorable free visual content
                        </h3>
                        <p className={styles.main_info_block_item_text}>
                            See how gorgeous our members are
                        </p>
                    </div>

                    <div className={styles.main_info_block_item}>
                        <img
                            className={styles.main_info_block_item_ico}
                            src={AuthImage.images['feature-4.svg']}
                            width="100"
                            height="100"
                            alt="Effecient tools"
                        />
                        <h3 className={styles.main_info_block_item_title}>
                            Effortless interaction tools
                        </h3>
                        <p className={styles.main_info_block_item_text}>
                            Communicate efficiently with your sweet matches
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            className={styles.main_info_block_item_ico}
                            src={AuthImage.images['feature-6.svg']}
                            width="100"
                            height="100"
                            alt="Manual profile verification"
                        />
                        <h3 className={styles.main_info_block_item_title}>
                            All our participants are real
                        </h3>
                        <p className={styles.main_info_block_item_text}>
                            Manual verification of every member’s profile
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            className={styles.main_info_block_item_ico}
                            src={AuthImage.images['feature-8.svg']}
                            width="100"
                            height="100"
                            alt="24/7 Help"
                        />
                        <h3 className={styles.main_info_block_item_title}>
                            Date securely and keep it private
                        </h3>
                        <p className={styles.main_info_block_item_text}>
                            Protected payments and info you provide the site with
                        </p>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            className={styles.main_info_block_item_ico}
                            src={AuthImage.images['feature-9.svg']}
                            width="100"
                            height="100"
                            alt="24/7 Help"
                        />
                        <h3 className={styles.main_info_block_item_title}>
                            Get help when you need it
                        </h3>
                        <p className={styles.main_info_block_item_text}>
                            Expert support round the clock 7 days a week
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
