import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatList from '../../components/Chat/ChatList';
import Chat from '../../components/Chat/Chat';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import * as TIMER from '../../constants/timer';
import '../../styles/chat/ui.scss';
import '../../styles/chat/style.scss';
import '../../styles/chat/media.scss';
import '../../styles/chat/form.scss';
import '../../styles/chat/hello-message.scss';
import * as notifyAction from '../../store/actions/notify';
import * as countersAction from '../../store/actions/counters';
import * as loaderAction from '../../store/actions/loader';
import * as modalsAction from '../../store/actions/modals';
import { useLocation } from 'react-router-dom';
import { getChatListType } from '../../services/methods/chat';
import ChatMobile from '../../components/Chat/ChatMobile';
import useHandleMobileChat from '../../hooks/chat/helpers/useHandleMobileChat';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import { Params } from '@sentry/react/types/types';
import { match } from 'react-router';
import { ChatModel } from '../../models/chat';

export default function ChatPage({ match }: { match: match<Params> }) {
    const userState = useSelector((state: RootState) => state.user);
    const chatsState = useSelector((state: RootState) => state.chats);

    const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);

    const dispatch = useDispatch();
    const location = useLocation();
    const handleMobileChat = useHandleMobileChat();

    const openCurrentChat = (chatInfo: any) => {
        dispatch(modalsAction.setActiveSubMenu());
        dispatch(currentChatAction.addMainChatInfo(chatInfo));
        dispatch(currentChatAction.addMainChatHistoryWithReload(chatInfo?.chat_uid, 1));

        if (chatInfo?.unread_count > 0) {
            setTimeout(() => {
                if (userState?.info?.external_id) {
                    dispatch(countersAction.getChatCounter(userState?.info?.external_id));
                    dispatch(notifyAction.readNotify(chatInfo?.chat_uid));
                    dispatch(chatsAction.removeUnreadCount(chatInfo?.chat_uid));
                }
            }, 0);

            clearTimeout(timerId);
            const timer = setTimeout(() => {
                if (userState?.info?.external_id) {
                    dispatch(
                        chatsAction.updateNewMess(
                            userState?.info?.external_id,
                            chatsState?.onlineStatus,
                            chatsState?.searchInput,
                            getChatListType(location?.pathname),
                            chatInfo?.chat_uid,
                        ),
                    );
                }
            }, TIMER.CHAT_UPD_NEW_MESS);
            setTimerId(timer);
        }
    };

    const loadCurrentChat = (chatList: any) => {
        const chatInfo = chatList?.length
            ? chatList.find((item: any) => item?.chat_uid === match?.params?.chat_uid)
            : null;

        if (chatInfo?.chat_uid) {
            openCurrentChat(chatInfo);
            dispatch(loaderAction.setActiveGifLoader(false));
        } else {
            dispatch(chatsAction.setSearchInput(''));
            dispatch(chatsAction.setOnlineStatus(false));
            try {
                if (match?.params?.chat_uid) {
                    ChatModel.getStoreChatItem(match?.params?.chat_uid)
                        .then((res) => {
                            if (res) {
                                dispatch(chatsAction.addNewChat(res));
                                openCurrentChat(res);
                            }
                        })
                        .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
                }
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }
    };

    useEffect(() => {
        return () => {
            dispatch(chatsAction.initChatFilters());
        };
    }, []);

    useEffect(() => {
        if (!location?.pathname?.split('/')?.[2]?.length && userState?.info?.external_id) {
            dispatch(
                chatsAction.getChatListByUserIdWithReload(
                    userState?.info?.external_id,
                    chatsState?.onlineStatus,
                    chatsState?.searchInput,
                    getChatListType(location?.pathname),
                    1,
                ),
            );
        }
    }, [location?.pathname, chatsState?.onlineStatus, chatsState?.searchInput]);

    useEffect(() => {
        if (match?.params?.chat_uid) {
            dispatch(notifyAction.deleteAllMobileNotify());
            dispatch(notifyAction.deleteMobileNotify(match?.params?.chat_uid));
            dispatch(notifyAction.deleteMobileBottomNotify(match?.params?.chat_uid));

            dispatch(loaderAction.setActiveGifLoader(true));

            if (chatsState?.chatList?.length) {
                loadCurrentChat(chatsState?.chatList);
            } else {
                if (userState?.info?.external_id) {
                    (
                        dispatch(
                            chatsAction.getChatListByUserId(
                                userState?.info?.external_id,
                                chatsState?.onlineStatus,
                                chatsState?.searchInput,
                                getChatListType(location?.pathname),
                            ),
                        ) as any
                    ).then((res: any) => loadCurrentChat(res?.data?.chats));
                }
            }
        }
    }, [match.params.chat_uid]);

    return handleMobileChat?.isNewMobileChat ? (
        <>
            <ChatList />
            {handleMobileChat?.isActiveNewMobileChat && <ChatMobile />}
        </>
    ) : (
        <>
            <ChatList />
            <Chat />
        </>
    );
}
