import React from 'react';
import * as modalsAction from '../../../store/actions/modals';
import { useDispatch } from 'react-redux';
import { ILikeHistoryItem } from '../../../store/reducers/likes/index.interface';

interface ILikeItem {
    womanInfo: ILikeHistoryItem;
}

export default function LikeItem({ womanInfo }: ILikeItem) {
    const dispatch = useDispatch();

    const openWomanProfileModal = (externalID: number) => {
        dispatch(modalsAction.openWomanProfileModal(externalID));
    };

    return womanInfo?.external_id ? (
        <div
            className="c2lh_girls_you_liked_item"
            onClick={() => openWomanProfileModal(womanInfo?.external_id)}
        >
            <div className={`c2lh_girls_you_liked_item_img ${womanInfo?.online ? 'online' : ''}`}>
                <img src={womanInfo?.avatar || ''} alt="" />
            </div>
            <div className="c2lh_girls_you_liked_item_right">
                <div className="c2lh_girls_you_liked_item_name">
                    {womanInfo?.name || ''}, {womanInfo?.age || ''}
                </div>
                <div className="c2lh_girls_you_liked_item_place">
                    {womanInfo?.country_code && (
                        <img
                            src={`/assets/flags/${womanInfo?.country_code?.toLowerCase()}.jpg`}
                            alt=""
                        />
                    )}

                    <span>{womanInfo?.city_name || ''}</span>
                </div>
            </div>
        </div>
    ) : (
        <div />
    );
}
