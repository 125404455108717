import React, { memo, useMemo, useRef, useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import MediaListAudio from './MediaListAudio';
import MediaListPhoto from './MediaListPhoto';
import MediaListVideo from './MediaListVideo';
import useSetChatMediaList from '../../../../hooks/chat/useSetChatMediaList';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import useOutsideClickerRoot from '../../../../hooks/dom/useOutsideClickerRoot';
import { formatDateMedia } from '../../../../services/time';
import { filterByFileType, filterByPayed } from '../../../../services/methods/allMedia';
import * as CHATS from '../../../../constants/chats';
import * as alertAction from '../../../../store/actions/alert';
import { stopAudio } from '../../../../services/methods/chat';
import SITE from '../../../../config/site';
import { RootState } from '../../../../index';
import { IMediaListProps } from './index.interface';
import IconArrowDropdown from '../../../Icons/IconArrowDropdown';
import IconLockPlain from '../../../Icons/IconLockPlain';

const MediaList = ({
    isMediaWrapOpen,
    optionsItemMediaRef,
    onCloseMediaWrap,
    onSetAllMediaList,
}: IMediaListProps) => {
    const mediaListWrapRef = useRef<HTMLDivElement>(null);
    const mediaListMobTitleRef = useRef<HTMLDivElement>(null);
    const mediaListCategoriesRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const allMediaState = useSelector((state: RootState) => state.allMedia);
    const setChatMediaList = useSetChatMediaList();
    const userState = useSelector((state: RootState) => state.user);

    const [mediaListMobTitle, setMediaListMobTitle] = useState<boolean>(false);
    const [mapMediaList, setMapMediaList] = useState<any>([]);

    useOutsideClickerRoot(mediaListWrapRef, onCloseMediaWrap, optionsItemMediaRef);
    useOutsideClicker(mediaListMobTitleRef, setMediaListMobTitle, mediaListCategoriesRef);

    const checkBeforeBuyingMessageContent = (type: string, callback: () => void) => {
        if (Number(userState?.info?.convert_user) || SITE.ID === 4) callback();
        else
            dispatch(
                alertAction.setCustomAlertType({
                    alertType: type,
                    successCallback: () => callback(),
                }),
            );
    };

    const mapFormatDateMedia = (item: any) => {
        if (item?.date_created)
            return {
                ...item,
                date_created_str: formatDateMedia(new Date(item?.date_created).toString()),
            };
        return item;
    };

    const mapMediaListItem = (item: any, key: number, array: any) => {
        const firstDate = array.find(
            (find: any) => find?.date_created_str === item?.date_created_str,
        );

        if (item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_IMAGE) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    <MediaListPhoto
                        message_content={item?.message_content}
                        message_popup={item?.message_popup}
                    />
                </Fragment>
            );
        } else if (item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_VIDEO) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    <MediaListVideo
                        {...item}
                        onSetAllMediaList={onSetAllMediaList}
                        checkBeforeBuyingMessageContent={checkBeforeBuyingMessageContent}
                    />
                </Fragment>
            );
        } else if (item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_AUDIO) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    <MediaListAudio
                        {...item}
                        onSetAllMediaList={onSetAllMediaList}
                        checkBeforeBuyingMessageContent={checkBeforeBuyingMessageContent}
                    />
                </Fragment>
            );
        } else {
            return <div key={key} />;
        }
    };

    useMemo(() => {
        setMapMediaList(
            allMediaState?.allMediaList
                .map(mapFormatDateMedia)
                .filter((item) => filterByFileType(allMediaState?.mediaListType, item))
                .filter((item) => filterByPayed(allMediaState?.mediaListPaid, item))
                .slice(0, CHATS.CHAT_MEDIA_LIST_QUANTITY * allMediaState?.page)
                .map(mapMediaListItem),
        );
    }, [
        allMediaState.allMediaList,
        allMediaState.mediaListType,
        allMediaState.mediaListPaid,
        allMediaState.page,
    ]);

    useEffect(() => {
        stopAudio();
    }, [allMediaState.mediaListType, allMediaState.mediaListPaid]);

    return (
        <div
            className={`${styles.media_list_wrap_slide} ${isMediaWrapOpen ? styles.open : ''}`}
            ref={mediaListWrapRef}
            onScroll={setChatMediaList.handleScroll}
        >
            <div className={styles.media_list_wrap}>
                <div
                    className={`${styles.media_list_head} ${mediaListMobTitle ? styles.open : ''}`}
                >
                    <div
                        ref={mediaListMobTitleRef}
                        className={`${styles.media_list_head_mob_title} ${mediaListMobTitle ? styles.open : ''}`}
                        onClick={() => setMediaListMobTitle(!mediaListMobTitle)}
                    >
                        <span>
                            {allMediaState.mediaListType.charAt(0).toUpperCase() +
                                allMediaState.mediaListType.slice(1)}
                        </span>
                        <IconArrowDropdown />
                    </div>
                    <div
                        className={styles.media_list_head_categories}
                        ref={mediaListCategoriesRef}
                        onClick={() => setMediaListMobTitle(!mediaListMobTitle)}
                    >
                        <div
                            className={`${styles.media_list_head_category_btn} ${allMediaState.mediaListType === 'all media' ? styles.active : ''}`}
                            data-name="all media"
                            onClick={setChatMediaList.onSetMediaListType}
                        >
                            All media
                        </div>
                        <div
                            className={`${styles.media_list_head_category_btn} ${allMediaState.mediaListType === 'photo' ? styles.active : ''}`}
                            data-name="photo"
                            onClick={setChatMediaList.onSetMediaListType}
                        >
                            Photo
                        </div>
                        <div
                            className={`${styles.media_list_head_category_btn} ${allMediaState.mediaListType === 'video' ? styles.active : ''}`}
                            data-name="video"
                            onClick={setChatMediaList.onSetMediaListType}
                        >
                            Video
                        </div>
                        <div
                            className={`${styles.media_list_head_category_btn} ${allMediaState.mediaListType === 'audio' ? styles.active : ''}`}
                            data-name="audio"
                            onClick={setChatMediaList.onSetMediaListType}
                        >
                            Audio
                        </div>
                    </div>
                    <div
                        className={`${styles.media_list_head_type} ${allMediaState.mediaListPaid === 'paid' ? styles.paid : ''}`}
                    >
                        <div
                            className={styles.media_list_head_type_btn}
                            data-name="all"
                            onClick={setChatMediaList.onSetMediaListPaid}
                        >
                            All
                        </div>
                        <div
                            className={styles.media_list_head_type_btn}
                            data-name="paid"
                            onClick={setChatMediaList.onSetMediaListPaid}
                        >
                            Paid
                        </div>
                    </div>
                </div>
                <div className={styles.media_list_scroll}>{mapMediaList}</div>
                <div className={styles.mob_media_paid_info}>
                    <IconLockPlain />
                    <div className={styles.mob_media_paid_info_title}>Unpaid</div>
                    <div className={styles.mob_media_paid_info_text}>
                        - Tap on the{' '}
                        <span className={styles.mob_media_paid_info_text_mob}>media</span> file{' '}
                        <span>to purchase and view it</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(MediaList);
