import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import GiftsCartModalItem from '../GiftsCartModalItem';
import * as giftsAction from '../../../store/actions/gifts';
import useTransition from '../../../hooks/transition/useTransition';
import { ICart, ICategoryItem, IGiftItem } from '../../../store/reducers/gifts/index.interface';

interface IGiftsCartModal {
    closeModal: () => void;
    cart: ICart;
    categories: ICategoryItem[];
}

export default function GiftsCartModal({ closeModal, cart, categories }: IGiftsCartModal) {
    const transition = useTransition();
    const dispatch = useDispatch();
    const cartCount = Object.keys(cart?.items)?.length;
    const deleteFromCart = (item: IGiftItem) => dispatch(giftsAction.deleteFromCart(item));

    useEffect(() => {
        if (!cartCount) closeModal();
    }, [cartCount]);

    return (
        <div className="popup_gifts_cart_wrap">
            <div className="popup_gifts_cart">
                <div className="popup_gifts_cart_title">Cart contains {cartCount} items</div>
                <div className="popup_gifts_cart_list">
                    {Object.keys(cart?.items).map((key) => {
                        const item = cart?.items[+key];
                        return (
                            <GiftsCartModalItem
                                key={key}
                                item={item}
                                deleteFromCart={deleteFromCart}
                                category={categories[item?.category_id]}
                            />
                        );
                    })}
                </div>
                <div className="popup_gifts_cart_buttons">
                    <div className="popup_gifts_cart_continue_btn" onClick={closeModal}>
                        Сontinue choosing gifts<span>Go back</span>
                    </div>
                    <div
                        className="popup_gifts_cart_go_to_cart_btn"
                        onClick={() => transition.transitionWithHistoryPush('gifts', '/gifts/cart')}
                    >
                        <img src="/img/gifts/cart-white.svg" alt="" />
                        <span>Go to the cart</span>
                    </div>
                </div>
            </div>
            <div className="popup_gifts_cart_close" onClick={closeModal}>
                <img src="/img/gifts/cross-darkblue.svg" alt="" />
            </div>
        </div>
    );
}
