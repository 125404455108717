import React, { Fragment } from 'react';
import { sequenceArray } from '../../../../services/time/DateUtils';

interface ISelectAge {
    handleOpen: (param: boolean) => void;
    opened: boolean;
    handleChange: (value: string | number) => void;
    value: number | string;
    defaultValue: string;
}

export default function SelectAge({
    handleOpen,
    opened,
    handleChange,
    value,
    defaultValue,
}: ISelectAge) {
    return (
        <div className="cl2h_tab_wide_filter_item_wrap">
            <div className={`cl2h_tab_wide_filter_item`} onClick={() => handleOpen(true)}>
                <div className={`like_select_head ${opened ? '' : value ? 'selected' : ''}`}>
                    {+value > 0 ? value : defaultValue}
                </div>
                {opened && (
                    <div className="like_select_list_wrap open">
                        <div className="like_select_list open">
                            {['', ...sequenceArray(18, 70)].map((item, index) => {
                                return (
                                    <Fragment key={`height_list${index}`}>
                                        <input
                                            type="radio"
                                            name={`like_sel-height_list-${index}`}
                                            id={`like_sel-height_list-${index}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-height_list-${index}`}
                                            className="like_select_label"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleChange(item);
                                            }}
                                        >
                                            {item || '—'}
                                        </label>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
