import * as currentChatAction from '../../../store/actions/currentChat';
import { useDispatch, useSelector } from 'react-redux';
import { scrollTopToMainChat } from '../../../services/dom';
import { RootState } from '../../../index';
import { IChatHistoryItem } from '../../../store/reducers/currentChat/index.interface';
import React from 'react';

export default function useScrollChatMessageList() {
    const dispatch = useDispatch();
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const START_SLICE_PAGE = 3;
    const COUNT_ON_PAGE = 20;

    const onScrollList = (
        event: React.UIEvent<HTMLDivElement, UIEvent> | React.UIEvent<HTMLUListElement>,
        callback: (value: boolean) => void,
    ) => {
        const target = event?.target as HTMLElement;

        const isScrollUp =
            !currentChatState?.isLoadedAll &&
            target?.scrollTop === 0 &&
            currentChatState?.history?.length >= 10;
        const isScrollDown = target?.scrollTop > target?.scrollHeight - target?.offsetHeight - 100;

        // Pagination on scroll up
        if (isScrollUp && !currentChatState?.historyPageLoading) {
            if (+currentChatState?.historyPage > START_SLICE_PAGE) {
                dispatch(
                    currentChatAction.setHidePageDown(
                        +currentChatState?.historyPage - START_SLICE_PAGE,
                    ),
                );
            }

            if (currentChatState?.info?.chat_uid) {
                dispatch(
                    currentChatAction.addMainChatHistoryWithPage(
                        currentChatState?.info?.chat_uid,
                        currentChatState?.historyPage,
                    ),
                );
            }
        }

        // Pagination on scroll down
        if (isScrollDown && !currentChatState?.historyPageLoading) {
            if (+currentChatState?.hidePageDown > 0) {
                dispatch(
                    currentChatAction.setHidePageDown(+currentChatState?.hidePageDown - 1, true),
                );
            }
        }

        // Hide button "new message", if the scroll is bellow
        if (Math.round(target?.scrollTop + target?.offsetHeight) >= target?.scrollHeight) {
            dispatch(currentChatAction.setIsNewMessage(false));
            callback(false);
        }

        if (Math.round(target?.scrollTop + target?.offsetHeight) + 100 < target?.scrollHeight) {
            callback(true);
        } else {
            callback(false);
        }

        if (currentChatState?.hidePageDown > 1) callback(true);
    };

    const onScrollDown = () => {
        if (currentChatState?.history?.length) {
            dispatch(currentChatAction.setStartHistoryPage());
            scrollTopToMainChat();
        }
    };

    const filterByPageScrollList = (
        item: IChatHistoryItem,
        key: number,
        list: IChatHistoryItem[],
    ) => {
        if (+currentChatState?.hidePageDown > 0) {
            return key < list?.length - +currentChatState?.hidePageDown * COUNT_ON_PAGE;
        } else return true;
    };

    return { onScrollDown, onScrollList, filterByPageScrollList };
}
