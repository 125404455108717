import React from 'react';
import styles from '../../styles.module.scss';
import IconCopyright from '../../../../Icons/IconCopyright';
import useHandlePayment from '../../../../../hooks/purchase/useHandlePayment';
import { isHolidayActive } from '../../../../../constants/holiday';

export default function MenuCreditButton() {
    const handlePayment = useHandlePayment();
    const activeHoliday = isHolidayActive();

    const onOpenPayment = () => handlePayment.onOpenPayment();

    return (
        <button
            className={`${styles.add_credits_btn} ${activeHoliday && styles[`${activeHoliday?.name}`]}`}
            data-testid="add-credits-btn"
            onClick={onOpenPayment}
        >
            <IconCopyright />
            <span>Add credits</span>
        </button>
    );
}
