import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TutorialApi from '../../services/api/v3/TutorialApi';
import * as tutorialAction from '../../store/actions/tutorial';
import * as modalsAction from '../../store/actions/modals';
import useTransition from '../transition/useTransition';
import { getUrlParameter } from '../../utils/url-utils';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { RootState } from '../../index';
import { ICategoryItem } from '../../store/reducers/tutorial/index.interface';

export default function useInitTutorialPage() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const handleActiveBlocks = useHandleActiveBlocks();

    const tutorialState = useSelector((state: RootState) => state.tutorial);
    const userState = useSelector((state: RootState) => state.user);

    const [isMobileCategoryOpened, setIsMobileCategoryOpened] = useState(false);
    const [categoryList, setCategoryList] = useState<ICategoryItem[]>([]);

    const getTutorialData = () => {
        new TutorialApi().getTutorial().then((res) => {
            if (res?.status) {
                const filterList =
                    handleActiveBlocks.isNewMailCategory1 && handleActiveBlocks.isPaymentUser
                        ? res?.result?.filter(
                              (item: any) => !['CONTACT_REQUEST', 'GIFTS']?.includes(item?.code),
                          )
                        : res?.result;
                dispatch(tutorialAction.getTutorialData(filterList));
            }
        });
    };

    const changeTopic = (topicId: number) => {
        dispatch(tutorialAction.setActiveTutorialTopicId(topicId));
        dispatch(tutorialAction.setActiveCategoryId());
        setIsMobileCategoryOpened(true);
    };

    const backToTopics = () => {
        setIsMobileCategoryOpened(false);
        setCategoryList([]);
        dispatch(tutorialAction.setActiveTutorialTopicId());
        dispatch(tutorialAction.setActiveCategoryId());
    };

    const changeActiveCategory = (id: number) => {
        if (id === tutorialState?.activeCategoryId) {
            dispatch(tutorialAction.setActiveCategoryId());
        } else {
            dispatch(tutorialAction.setActiveCategoryId(id));
        }
    };

    const fixMessageContent = (message: string) => {
        if (typeof message !== 'string') return '';
        return message.split('\n').join(`<br/>`);
    };

    const clickSubcategoryText = (event: MouseEvent<HTMLParagraphElement>) => {
        const target = event.target as HTMLElement;

        const videoLink =
            target?.getAttribute('data-link') ?? target?.parentElement?.getAttribute('data-link');

        if (videoLink) {
            openVideoModal(videoLink);
        } else if (target?.nodeName === 'IMG') {
            openImageModal((target as HTMLImageElement).src);
        }
    };

    const openImageModal = (imgLink: string) => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: imgLink,
                attachment_payed: '1',
            }),
        );
    };

    const openVideoModal = (videoLink: string) => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: videoLink,
                attachment_payed: '1',
            }),
        );
    };

    useEffect(() => {
        if (tutorialState?.activeCategoryId) {
            const openedCategoryItem = document.querySelector(
                `#category_item_${tutorialState?.activeCategoryId}`,
            );

            if (openedCategoryItem) {
                openedCategoryItem.scrollIntoView({
                    // @ts-expect-error behavior
                    behavior: 'instant',
                    block: 'start',
                });
            }
        }
    }, [tutorialState?.activeCategoryId, categoryList]);

    useEffect(() => {
        if (tutorialState.activeTopicId) {
            const activeTopicObj = tutorialState?.tutorialData?.find(
                (q) => +q?.id === +(tutorialState?.activeTopicId || 0),
            );
            if (activeTopicObj?.category_list?.length)
                setCategoryList(activeTopicObj?.category_list?.sort((a, b) => a?.order - b?.order));
        }
    }, [tutorialState?.activeTopicId]);

    useEffect(() => {
        if (tutorialState?.tutorialData?.length) {
            const urlTopicId = +(getUrlParameter('tutorial_topic') || 0);
            const urlCategoryId = +(getUrlParameter('category') || 0);

            if (tutorialState?.tutorialData?.find((el) => +el?.id === +urlTopicId)) {
                dispatch(tutorialAction.setActiveTutorialTopicId(+urlTopicId));
                dispatch(tutorialAction.setActiveCategoryId(+urlCategoryId));
                setIsMobileCategoryOpened(true);
            } else {
                const defaultActiveTutorialType = tutorialState?.tutorialData?.reduce((p, v) => {
                    return +p?.order < +v?.order ? p : v;
                });
                dispatch(tutorialAction.setActiveTutorialTopicId(+defaultActiveTutorialType?.id));
            }
        }
    }, [tutorialState.tutorialData]);

    useEffect(() => {
        if (tutorialState?.activeTopicId && window?.location?.pathname?.includes('/tutorial')) {
            transition.addTail(
                `?tutorial_topic=${tutorialState?.activeTopicId}${tutorialState?.activeCategoryId ? `&category=${tutorialState?.activeCategoryId}` : ''}`,
            );
        }
    }, [tutorialState.activeTopicId, tutorialState.activeCategoryId]);

    useEffect(() => {
        if (!tutorialState?.tutorialData?.length) getTutorialData();
    }, [userState?.info?.external_id]);

    return {
        tutorialData: tutorialState?.tutorialData,
        activeTopicId: tutorialState?.activeTopicId,
        activeCategory: tutorialState?.activeCategoryId,
        activeTopicTitle: tutorialState?.activeTopicTitle,
        activeTopicText: tutorialState?.activeTopicText,
        getTutorialData,
        changeTopic,
        isMobileCategoryOpened,
        categoryList,
        backToTopics,
        fixMessageContent,
        clickSubcategoryText,
        changeActiveCategory,
        isPaymentUser: handleActiveBlocks.isPaymentUser,
    };
}
