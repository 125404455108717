import React from 'react';
import styles from './styles.module.scss';
import global from '../global.module.scss';
import useInitSelectBlocks from '../../../../../hooks/profile/my-profile-v2/useInitSelectBlocks';
import useHandleSubInfo from '../../../../../hooks/profile/my-profile-v2/useHandleSubInfo';
import { useSelector } from 'react-redux';
import * as PROFILE from '../../../../../constants/profile';
import { getArrayOfNumbers } from '../../../../../services/methods';
import * as DICTIONARY_CREATE_PROFILE_PAGE from '../../../../../constants/dictionary/create-profile-page';
import * as SEX from '../../../../../constants/sex';
import * as LENGTH from '../../../../../constants/length';
import { RootState } from '../../../../../index';
import { IGirlsPreferencesProps } from './index.interface';
import IconYourLikes from '../../../../Icons/IconYourLikes';
import IconInfo from '../../../../Icons/IconInfo';
import IconArrowDropdown from '../../../../Icons/IconArrowDropdown';

export default function GirlsPreferences({
    girlsAppearance,
    setGirlsAppearance,
    saveEdits,
}: IGirlsPreferencesProps) {
    const userState = useSelector((state: RootState) => state.user);
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const initSelectBlocks = useInitSelectBlocks();
    const handleSubInfo = useHandleSubInfo({
        subInfo: girlsAppearance,
        setSubInfo: setGirlsAppearance,
        saveEdits,
    });

    return (
        <div className={`${global.profile_div}`} data-testid="girl-references-block">
            <div className={`${global.profile_div_head}`}>
                <div className={`${global.profile_div_head_title}`}>
                    <div className={styles.profile_about_icon}>
                        <IconYourLikes />
                    </div>
                    <h2 className={`${global.profile_block_title}`}>
                        {
                            SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
                                .PROFILE_APPEARANCE_TITLE
                        }
                    </h2>
                </div>
                <div className={`${global.profile_div_head_click_info}`}>
                    <IconInfo />
                    <span>Click a field to edit</span>
                </div>
            </div>
            <div className={global.profile_middle_item}>
                <div className={`${global.profile_info_row} ${global.profile_info_colors_row}`}>
                    <div className={`${global.profile_info_row_half}`} data-testid="eye">
                        <span className={global.profile_label}>Eye color</span>
                        {girlsAppearance?.woman_eye?.name === 'Not Specified' ? (
                            <div
                                className={global.profile_info_hobby_add_btn}
                                onClick={() => initSelectBlocks.setOpenGirlsEyeBlock(true)}
                            >
                                <div className={`${global.profile_info_hobby_add_btn_icon}`}></div>
                                <span>Add eye color</span>
                            </div>
                        ) : (
                            <div
                                className={`${global.profile_info_hobby_list_item} ${global.chosen}`}
                                onClick={() => initSelectBlocks.setOpenGirlsEyeBlock(true)}
                            >
                                <div
                                    className={`${global.profile_select_color} ${global[girlsAppearance?.woman_eye?.style as string]}`}
                                />
                                <span>{girlsAppearance?.woman_eye?.name}</span>
                            </div>
                        )}
                    </div>
                    <div className={`${global.profile_info_row_half}`} data-testid="hair">
                        <span className={global.profile_label}>hair color</span>
                        {girlsAppearance?.woman_hair?.name === 'Not Specified' ? (
                            <div
                                className={global.profile_info_hobby_add_btn}
                                onClick={() => initSelectBlocks.setOpenGirlsHairBlock(true)}
                            >
                                <div className={`${global.profile_info_hobby_add_btn_icon}`}></div>
                                <span>Add hair color</span>
                            </div>
                        ) : (
                            <div
                                className={`${global.profile_info_hobby_list_item} ${global.chosen}`}
                                onClick={() => initSelectBlocks.setOpenGirlsHairBlock(true)}
                            >
                                <div
                                    className={`${global.profile_select_color} ${global[girlsAppearance?.woman_hair?.style as string]}`}
                                />
                                <span>{girlsAppearance?.woman_hair?.name}</span>
                            </div>
                        )}
                    </div>
                    <div
                        ref={initSelectBlocks.selectGirlsEyeRef}
                        className={`
                             ${global.profile_info_hobby_list_wrap}
                             ${initSelectBlocks.openGirlsEyeBlock ? global.active : ''}
                        `}
                        data-testid="eye-select"
                    >
                        <div className={global.profile_info_hobby_list_head}>
                            <p className={global.profile_info_hobby_list_head_title}>
                                Choose eye color from the list below
                            </p>
                        </div>
                        <ul
                            className={global.profile_info_hobby_list_scroll}
                            data-testid="select-list"
                        >
                            {PROFILE.EYE_COLOR?.map((item, key) => (
                                <li
                                    key={key}
                                    className={`
                                         ${global.profile_info_hobby_list_item} 
                                         ${girlsAppearance?.woman_eye?.name === item?.name ? global.active : ''}
                                     `}
                                    onClick={() => {
                                        handleSubInfo.onChangeInputValue({
                                            value: item,
                                            field: 'eye_id',
                                            section: 'user_match',
                                        });
                                        initSelectBlocks.setOpenGirlsEyeBlock(false);
                                    }}
                                >
                                    <div
                                        className={`${global.profile_select_color} ${item?.style ? global[item?.style] : ''}`}
                                    />
                                    <span>{item?.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        ref={initSelectBlocks.selectGirlsHairRef}
                        className={`
                             ${global.profile_info_hobby_list_wrap}
                             ${initSelectBlocks.openGirlsHairBlock ? global.active : ''}
                        `}
                        data-testid="hair-select"
                    >
                        <div className={global.profile_info_hobby_list_head}>
                            <p className={global.profile_info_hobby_list_head_title}>
                                Choose hair color from the list below
                            </p>
                        </div>
                        <ul
                            className={global.profile_info_hobby_list_scroll}
                            data-testid="select-list"
                        >
                            {PROFILE.HAIR_TYPE?.map((item, key) => (
                                <li
                                    key={key}
                                    className={`
                                         ${global.profile_info_hobby_list_item} 
                                         ${girlsAppearance?.woman_hair?.name === item?.name ? global.active : ''}
                                     `}
                                    onClick={() => {
                                        handleSubInfo.onChangeInputValue({
                                            value: item,
                                            field: 'hair_id',
                                            section: 'user_match',
                                        });
                                        initSelectBlocks.setOpenGirlsHairBlock(false);
                                    }}
                                >
                                    <div
                                        className={`${global.profile_select_color} ${item?.style ? global[item?.style] : ''}`}
                                    />
                                    <span>{item?.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Age range</span>
                    <div
                        ref={initSelectBlocks.selectGirlsAgeFromRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openGirlsAgeFromBlock ? global.open : ''} 
                            ${+(girlsAppearance?.girls_age_from || 0) > 0 ? global.selected : ''}
                            ${global.age_range_item}
                         `}
                        data-testid="age-from"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenGirlsAgeFromBlock(
                                    !initSelectBlocks.openGirlsAgeFromBlock,
                                )
                            }
                        >
                            <span>
                                From:{' '}
                                {+(girlsAppearance?.girls_age_from || 0) > 0
                                    ? girlsAppearance?.girls_age_from
                                    : 'Not Specified'}
                            </span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {[
                                    ...['Not Specified'],
                                    ...getArrayOfNumbers(
                                        DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_AGE_START,
                                        DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_AGE_END,
                                    ),
                                ].map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(girlsAppearance?.girls_age_from || 0) === +item ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'girls_age_from',
                                                section: 'user_match',
                                            });
                                            initSelectBlocks.setOpenGirlsAgeFromBlock(false);
                                        }}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div
                        ref={initSelectBlocks.selectGirlsAgeToRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openGirlsAgeToBlock ? global.open : ''} 
                            ${+(girlsAppearance?.girls_age_to || 0) > 0 ? global.selected : ''}
                            ${global.age_range_item}
                         `}
                        data-testid="age-to"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenGirlsAgeToBlock(
                                    !initSelectBlocks.openGirlsAgeToBlock,
                                )
                            }
                        >
                            <span>
                                To:{' '}
                                {+(girlsAppearance?.girls_age_to || 0) > 0
                                    ? girlsAppearance?.girls_age_to
                                    : 'Not Specified'}
                            </span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {[
                                    ...['Not Specified'],
                                    ...getArrayOfNumbers(
                                        DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_AGE_START,
                                        DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_AGE_END,
                                    ),
                                ].map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(girlsAppearance?.girls_age_to || 0) === +item ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'girls_age_to',
                                                section: 'user_match',
                                            });
                                            initSelectBlocks.setOpenGirlsAgeToBlock(false);
                                        }}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className={`${global.profile_click_edit}`}>Click to edit</div>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Body type</span>
                    <div
                        ref={initSelectBlocks.selectGirlsBodyRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openGirlsBodyBlock ? global.open : ''} 
                            ${+(girlsAppearance?.body?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="woman-body"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenGirlsBodyBlock(
                                    !initSelectBlocks.openGirlsBodyBlock,
                                )
                            }
                        >
                            <span>{girlsAppearance?.body?.body}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <div
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.body_list?.map((item, key) => (
                                    <div
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(girlsAppearance?.body?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'body',
                                                section: 'user_match',
                                                payloadKey: 'body_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenGirlsBodyBlock(false);
                                        }}
                                    >
                                        {item?.body}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Height</span>
                    <div
                        ref={initSelectBlocks.selectGirlsHeightFromRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openGirlsHeightFromBlock ? global.open : ''} 
                            ${+(girlsAppearance?.height?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="woman-height"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenGirlsHeightFromBlock(
                                    !initSelectBlocks.openGirlsHeightFromBlock,
                                )
                            }
                        >
                            <span>{girlsAppearance?.height?.height}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.height_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(girlsAppearance?.height?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'height',
                                                section: 'user_match',
                                                payloadKey: 'girls_height_to',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenGirlsHeightFromBlock(false);
                                        }}
                                    >
                                        {item?.height}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Religion</span>
                    <div
                        ref={initSelectBlocks.selectGirlsReligionRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openGirlsReligionBlock ? global.open : ''} 
                            ${+(girlsAppearance?.woman_religion?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="woman-religion"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenGirlsReligionBlock(
                                    !initSelectBlocks.openGirlsReligionBlock,
                                )
                            }
                        >
                            <span>{girlsAppearance?.woman_religion?.religion}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.religion_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(girlsAppearance?.woman_religion?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'woman_religion',
                                                section: 'user_match',
                                                payloadKey: 'religion_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenGirlsReligionBlock(false);
                                        }}
                                    >
                                        {item?.religion}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Marital status</span>
                    <div
                        ref={initSelectBlocks.selectGirlsMaritalRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openGirlsMaritalBlock ? global.open : ''} 
                            ${+(girlsAppearance?.woman_marital?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="woman-marital"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenGirlsMaritalBlock(
                                    !initSelectBlocks.openGirlsMaritalBlock,
                                )
                            }
                        >
                            <span>{girlsAppearance?.woman_marital?.marital}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.marital_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(girlsAppearance?.woman_marital?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'woman_marital',
                                                section: 'user_match',
                                                payloadKey: 'marital_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenGirlsMaritalBlock(false);
                                        }}
                                    >
                                        {item?.marital}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Have children</span>
                    <div
                        ref={initSelectBlocks.selectGirlsMoreChildrenRef}
                        className={`
                            ${global.profile_select_wrap} 
                            ${initSelectBlocks.openGirlsMoreChildrenBlock ? global.open : ''} 
                            ${+(girlsAppearance?.woman_morechildren?.id || 0) > 0 ? global.selected : ''}
                         `}
                        data-testid="woman-morechildren"
                    >
                        <div
                            className={`${global.profile_select_head}`}
                            onClick={() =>
                                initSelectBlocks.setOpenGirlsMoreChildrenBlock(
                                    !initSelectBlocks.openGirlsMoreChildrenBlock,
                                )
                            }
                        >
                            <span>{girlsAppearance?.woman_morechildren?.children}</span>
                            <div className={`${global.profile_select_head_arrow}`}>
                                <IconArrowDropdown />
                            </div>
                        </div>
                        <div className={`${global.profile_select_list_wrap}`}>
                            <ul
                                className={`${global.profile_select_list}`}
                                data-testid="select-list"
                            >
                                {referenciesState?.list?.morechildren_list?.map((item, key) => (
                                    <li
                                        key={key}
                                        className={`${global.profile_select_list_item} ${+(girlsAppearance?.woman_morechildren?.id || 0) === +item?.id ? global.active : ''}`}
                                        onClick={() => {
                                            handleSubInfo.onChangeInputValue({
                                                value: item,
                                                field: 'woman_morechildren',
                                                section: 'user_match',
                                                payloadKey: 'morechildren_id',
                                                payloadValue: item?.id,
                                            });
                                            initSelectBlocks.setOpenGirlsMoreChildrenBlock(false);
                                        }}
                                    >
                                        {item?.children}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <div className={`${global.profile_info_row_half}`}>
                        <span className={global.profile_label}>Alcohol</span>
                        <div
                            ref={initSelectBlocks.selectGirlsAlcoholRef}
                            className={`
                                ${global.profile_select_wrap} 
                                ${initSelectBlocks.openGirlsAlcoholBlock ? global.open : ''} 
                                ${+(girlsAppearance?.woman_alcohol?.id || 0) > 0 ? global.selected : ''}
                             `}
                            data-testid="woman-alcohol"
                        >
                            <div
                                className={`${global.profile_select_head}`}
                                onClick={() =>
                                    initSelectBlocks.setOpenGirlsAlcoholBlock(
                                        !initSelectBlocks.openGirlsAlcoholBlock,
                                    )
                                }
                            >
                                <span>{girlsAppearance?.woman_alcohol?.alcohol}</span>
                                <div className={`${global.profile_select_head_arrow}`}>
                                    <IconArrowDropdown />
                                </div>
                            </div>
                            <div className={`${global.profile_select_list_wrap}`}>
                                <ul
                                    className={`${global.profile_select_list}`}
                                    data-testid="select-list"
                                >
                                    {referenciesState?.list?.alcohol_list?.map((item, key) => (
                                        <li
                                            key={key}
                                            className={`${global.profile_select_list_item} ${+(girlsAppearance?.woman_alcohol?.id || 0) === +item?.id ? global.active : ''}`}
                                            onClick={() => {
                                                handleSubInfo.onChangeInputValue({
                                                    value: item,
                                                    field: 'woman_alcohol',
                                                    section: 'user_match',
                                                    payloadKey: 'alcohol_id',
                                                    payloadValue: item?.id,
                                                });
                                                initSelectBlocks.setOpenGirlsAlcoholBlock(false);
                                            }}
                                        >
                                            {item?.alcohol}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={`${global.profile_info_row_half}`}>
                        <span className={global.profile_label}>Smoking</span>
                        <div
                            ref={initSelectBlocks.selectGirlsSmokingRef}
                            className={`
                                ${global.profile_select_wrap} 
                                ${initSelectBlocks.openGirlsSmokingBlock ? global.open : ''} 
                                ${+(girlsAppearance?.woman_smoke?.id || 0) > 0 ? global.selected : ''}
                             `}
                            data-testid="woman-smoke"
                        >
                            <div
                                className={`${global.profile_select_head}`}
                                onClick={() =>
                                    initSelectBlocks.setOpenGirlsSmokingBlock(
                                        !initSelectBlocks.openGirlsSmokingBlock,
                                    )
                                }
                            >
                                <span>{girlsAppearance?.woman_smoke?.smoke}</span>
                                <div className={`${global.profile_select_head_arrow}`}>
                                    <IconArrowDropdown />
                                </div>
                            </div>
                            <div className={`${global.profile_select_list_wrap}`}>
                                <ul
                                    className={`${global.profile_select_list}`}
                                    data-testid="select-list"
                                >
                                    {referenciesState?.list?.smoke_list?.map((item, key) => (
                                        <li
                                            key={key}
                                            className={`${global.profile_select_list_item} ${+(girlsAppearance?.woman_smoke?.id || 0) === +item?.id ? global.active : ''}`}
                                            onClick={() => {
                                                handleSubInfo.onChangeInputValue({
                                                    value: item,
                                                    field: 'woman_smoke',
                                                    section: 'user_match',
                                                    payloadKey: 'smoke_id',
                                                    payloadValue: item?.id,
                                                });
                                                initSelectBlocks.setOpenGirlsSmokingBlock(false);
                                            }}
                                        >
                                            {item?.smoke}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
                <div className={global.profile_info_row}>
                    <span className={global.profile_label}>Tell more about your preferences</span>
                    <div className={global.profile_info_about_text_wrap}>
                        <textarea
                            className={global.profile_info_about_text}
                            maxLength={LENGTH.MAX_PROFILE_TEXTAREA}
                            placeholder="Write about your ideal partner’s appearance, character traits, life principles..."
                            value={girlsAppearance?.looking}
                            data-testid="preferences-text"
                            onChange={(e) =>
                                handleSubInfo.onChangeInputValue({
                                    value: e?.target?.value,
                                    field: 'looking',
                                    section: 'user_reference',
                                })
                            }
                        />
                        <span>{`${girlsAppearance?.looking?.length}/${LENGTH.MAX_PROFILE_TEXTAREA}`}</span>
                    </div>
                    <span className={`${global.profile_click_edit}`}>Click to edit</span>
                </div>
            </div>
        </div>
    );
}
