import React, { useState } from 'react';
import Modal from 'react-modal';
import * as giftsAction from '../../../store/actions/gifts';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import GiftsCartRecipientSelect from '../GiftsCartRecipientSelect';
import { useDispatch, useSelector } from 'react-redux';
import avatar_placeholder from '../../../img/placeholder.gif';
import { RootState } from '../../../index';
import { IFemaleListItem } from '../../../store/reducers/mailbox/index.interface';

export default function GiftsCartRecipient() {
    const currentGiftsState = useSelector((state: RootState) => state.gifts);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const dispatch = useDispatch();

    const { cart, recipientProfile } = currentGiftsState;
    const { recipient } = cart;
    const avatar = recipientProfile?.avatar || avatar_placeholder;

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const onSetRecipient = (selectedGirl: IFemaleListItem) => {
        dispatch(giftsAction.setRecipientProfile(selectedGirl));
        dispatch(giftsAction.setRecipient(selectedGirl?.external_id));
        closeModal();
    };

    const deleteRecipient = () => dispatch(giftsAction.setRecipient(0));

    return (
        <>
            <div className="c3gp_gifts_cart_main_order_title">Gift addressee details:</div>
            <div className="c3gp_gifts_cart_main_order_addressee">
                <div className="c3gp_gifts_cart_main_order_addressee_title">Addressee:</div>

                {recipient &&
                recipientProfile?.external_id &&
                +recipientProfile?.external_id === +recipient ? (
                    <div className="c3gp_gifts_cart_main_order_addressee_item">
                        <div
                            className={`c3gp_gifts_cart_main_order_addressee_item_photo ${recipientProfile?.online ? 'online' : ''}`}
                        >
                            <img src={avatar} alt="avatar" />
                        </div>
                        <div className="c3gp_gifts_cart_main_order_addressee_item_info">
                            <div className="c3gp_gifts_cart_main_order_addressee_item_id">
                                ID {recipientProfile?.public_external_id}
                            </div>
                            <div className="c3gp_gifts_cart_main_order_addressee_item_name">
                                {recipientProfile?.name || ''}, {recipientProfile?.age || ''}
                            </div>
                            <div className="c3gp_gifts_cart_main_order_addressee_item_place">
                                {recipientProfile?.country_code && (
                                    <img
                                        src={`/assets/flags/${recipientProfile?.country_code.toLowerCase()}.jpg`}
                                        alt={recipientProfile?.country_name || ''}
                                    />
                                )}

                                {recipientProfile?.city || ''}
                            </div>
                        </div>
                        <div
                            className="c3gp_gifts_cart_main_order_addressee_item_remove"
                            onClick={deleteRecipient}
                        >
                            <img src="../../../img/gifts/cross-circle-red.svg" alt="" />
                        </div>
                    </div>
                ) : (
                    <div
                        className="c3gp_gifts_cart_main_order_addressee_choose"
                        onClick={openModal}
                    >
                        Choose girl
                    </div>
                )}
            </div>
            <Modal
                style={modalStyleProps()}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <GiftsCartRecipientSelect closeModal={closeModal} submit={onSetRecipient} />
            </Modal>
        </>
    );
}
