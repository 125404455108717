import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileApi from '../../services/api/ProfileApi';
import * as loaderAction from '../../store/actions/loader';
import * as alertAction from '../../store/actions/alert';
import { RootState } from '../../index';

export default function useInitRegisteredUserModal() {
    const userState = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [isRegisteredUserModal, setIsRegisteredUserModal] = useState(false);
    const [userName, setUserName] = useState('');

    const userPassword = localStorage.getItem('user_password');

    const openRegisteredUserModal = () => {
        if (userPassword) setIsRegisteredUserModal(true);
    };
    const closeRegisteredUserModal = () => {
        setIsRegisteredUserModal(false);
        localStorage.removeItem('user_password');
    };

    const saveUserInfo = () => {
        dispatch(loaderAction.setActiveGifLoader(true));
        const editedData = { user_detail: { name: userName } };

        new ProfileApi().editProfileNew(editedData).then((res) => {
            dispatch(loaderAction.setActiveGifLoader(false));

            if (res?.status) {
                closeRegisteredUserModal();
            } else {
                if (res?.message || res?.error) {
                    dispatch(alertAction.setMessage({ message: res?.message || res?.error }));
                }
            }
        });
    };

    useEffect(() => {
        if (userState?.info?.external_id) {
            if (userState?.info?.name) {
                setUserName(userState?.info?.name);
            }

            if (window?.location?.search?.includes('lnd_one_step')) {
                openRegisteredUserModal();
            }
        }
    }, [userState?.info?.external_id]);

    return {
        isRegisteredUserModal,
        openRegisteredUserModal,
        closeRegisteredUserModal,
        userPassword,
        userName,
        setUserName,
        saveUserInfo,
    };
}
