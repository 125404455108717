import { MenuActionTypes } from '../../constants/menu';
import { Dispatch } from 'redux';

export function setMainMenu(menuName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: MenuActionTypes.SET_MAIN_MENU,
            data: menuName,
        });
    };
}

export function setSubMenu(menuName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: MenuActionTypes.SET_SUB_MENU,
            data: menuName,
        });
    };
}

export function setLettersMenu(menuName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: MenuActionTypes.SET_LETTERS_MENU,
            data: menuName,
        });
    };
}

export function setComposeLetterFilter(menuName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: MenuActionTypes.SET_COMPOSE_LETTER_FILTER,
            data: menuName,
        });
    };
}

export function setComposeLetterSearch(searchField: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: MenuActionTypes.SET_COMPOSE_LETTER_SEARCH,
            data: searchField,
        });
    };
}
