export enum NotifyActionTypes {
    NOTIFY_GET = 'notify_get',
    HISTORY_GET = 'notify_history',
    UPDATE_NOTIFY = 'UPDATE_NOTIFY',
    UPDATE_TRANSACTION_NOTIFY = 'UPDATE_TRANSACTION_NOTIFY',
    UPDATE_TRANSACTION_NOTIFY_BOTTOM = 'UPDATE_TRANSACTION_NOTIFY_BOTTOM',
    UPDATE_MOB_BOTTOM_NOTIFY = 'UPDATE_MOB_BOTTOM_NOTIFY',
    UPDATE_MOB_NOTIFY = 'UPDATE_MOB_NOTIFY',
    DELETE_MOB_NOTIFY = 'DELETE_MOB_NOTIFY',
    DELETE_TRANSACTION_NOTIFY = 'DELETE_TRANSACTION_NOTIFY',
    CHECK_TRANSACTION_NOTIFY = 'CHECK_TRANSACTION_NOTIFY',
    DELETE_MOB_BOTTOM_NOTIFY = 'DELETE_MOB_BOTTOM_NOTIFY',
    DELETE_ALL_MOB_NOTIFY = 'DELETE_ALL_MOB_NOTIFY',
    READ_NOTIFY = 'notify_read',
    DELETE_NOTIFY = 'notify_delete',
    DELETE_ALL_NOTIFY = 'DELETE_ALL_NOTIFY',
    DELETE_NOTIFY_LETTER = 'DELETE_NOTIFY_LETTER',
    NOTIFY_GET_COUNT_UNREAD = 'NOTIFY_GET_COUNT_UNREAD',
    CHECK_NOTIFY = 'CHECK_NOTIFY',
    CHECK_MOB_NOTIFY = 'CHECK_MOB_NOTIFY',
    CHECK_MOB_BOTTOM_NOTIFY = 'CHECK_MOB_BOTTOM_NOTIFY',
    CLEAR_MOB_LIST = 'CLEAR_MOB_LIST',
    DISACTIVE_PAUSE_BUTTON = 'DISACTIVE_PAUSE_BUTTON',
    SET_SETTINGS_BOTTOM = 'SET_SETTINGS_BOTTOM',
    SET_SETTINGS_PUSH = 'SET_SETTINGS_PUSH',
    UPDATE_MAIL_NOTIFY = 'UPDATE_MAIL_NOTIFY',
    TOGGLE_FAVORITE = 'TOGGLE_FAVORITE',
}
