import { GiftsActionTypes } from '../../constants/gifts';
import { CART_STORAGE_KEY } from '../../../constants/gifts';
import { GiftsActions, IGiftsState } from './index.interface';

const initState = {
    list: [],
    filters: {
        category: null,
    },
    categories: [],
    giftsByCategories: [],
    filterCategoriesList: [],
    cart: {
        items: {},
        recipient: null,
    },
    recipientProfile: null,
    successGiftModal: false,
    creditGiftModal: false,
    name: null,
    age: null,
    external_id: null,
    newGiftMessageCount: 0,
};

export default function (state: IGiftsState = initState, action: GiftsActions): IGiftsState {
    switch (action.type) {
        case GiftsActionTypes.SAVE_GIFTS: {
            return {
                ...state,
                list: action.data,
            };
        }

        case GiftsActionTypes.SAVE_GIFTS_BY_CATEGORIES: {
            return {
                ...state,
                giftsByCategories: action.data,
            };
        }

        case GiftsActionTypes.SAVE_CATEGORIES: {
            return {
                ...state,
                categories: action.data,
            };
        }

        case GiftsActionTypes.SAVE_FILTER_CATEGORIES_LIST: {
            return {
                ...state,
                filterCategoriesList: action.data,
            };
        }

        case GiftsActionTypes.SAVE_FILTER_CATEGORY: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    category: action.data,
                },
            };
        }

        case GiftsActionTypes.SHOW_SUCCESS_MODAL: {
            return {
                ...state,
                successGiftModal: true,
            };
        }

        case GiftsActionTypes.CLOSE_SUCCESS_MODAL: {
            return {
                ...state,
                successGiftModal: false,
            };
        }

        case GiftsActionTypes.ADD_TO_CART: {
            const cartItems = { ...state.cart.items };
            cartItems[action.data.id] = action.data;
            return {
                ...state,
                cart: {
                    ...state.cart,
                    items: { ...cartItems },
                },
            };
        }

        case GiftsActionTypes.DELETE_FROM_CART: {
            const cartItems = { ...state.cart.items };
            delete cartItems[action.data];
            return {
                ...state,
                cart: {
                    ...state.cart,
                    items: { ...cartItems },
                },
            };
        }

        case GiftsActionTypes.EMPTY_CART: {
            return {
                ...state,
                cart: {
                    ...state.cart,
                    items: {},
                    recipient: null,
                },
                recipientProfile: null,
            };
        }

        case GiftsActionTypes.SET_RECIPIENT: {
            localStorage.setItem(
                CART_STORAGE_KEY,
                JSON.stringify({ ...state.cart, recipient: action.data }),
            );
            return {
                ...state,
                cart: {
                    ...state.cart,
                    recipient: action.data,
                },
            };
        }
        case GiftsActionTypes.SET_RECIPIENT_PROFILE: {
            return {
                ...state,
                recipientProfile: action.profile,
            };
        }

        default:
            return state;
    }
}
