export const sequenceArray = (from: number, to: number) => {
    const list: number[] = [];

    for (let i = from; i <= to; i++) {
        list.push(i);
    }

    return list;
};

export const daysUnitBirthday = (month: number, day: number) => {
    const today = new Date(),
        y = today.getFullYear(),
        next = new Date(y, month - 1, day);
    today.setHours(0, 0, 0, 0);
    if (today > next) next.setFullYear(y + 1);
    return Math.round((+next - +today) / 8.64e7);
};
