import * as likesAction from '../../store/actions/likes';
import { useDispatch, useSelector } from 'react-redux';
import ChatsApi from '../../services/api/ChatsApi';
import { initCancelTokenSource } from '../../services/methods';
import { hideStringStar } from '../../services/methods';
import { MouseEvent, useEffect, useState } from 'react';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import * as MENU from '../../constants/menu';
import * as REGEXP from '../../constants/regExp';
import useTransition from '../transition/useTransition';
import * as currentChatAction from '../../store/actions/currentChat';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as notifyAction from '../../store/actions/notify';
import * as chatsAction from '../../store/actions/chats';
import * as userAction from '../../store/actions/user';
import moment from 'moment';
import useLike from '../likes/useLike';
import useSendWink from '../wink/useSendWink';
import MailboxApi from '../../services/api/MailboxApi';
import * as FRONT_SYNC from '../../constants/frontSync';
import useSendActionSync from '../frontSync/useSendActionSync';
import useOpenVideoShow from '../search/useOpenVideoShow';
import SITE from '../../config/site';
import * as MIRROR from '../../constants/mirror';
import { getLastMessageContent } from '../../services/methods/chat';
import { RootState } from '../../index';

interface IUseInitWomanProfileFunctions {
    favoriteStatus: number | null;
    setFavoriteStatus: (value: number) => void;
    profile: any;
    setProfile: (valeu: any) => void;
    likeStatus: number;
    chatInfo: any;
    closeWomanProfileModal: () => void;
    sayHelloList: string[];
}

export default function useInitWomanProfileFunctions({
    favoriteStatus,
    setFavoriteStatus,
    profile,
    setProfile,
    likeStatus,
    chatInfo,
    closeWomanProfileModal,
    sayHelloList,
}: IUseInitWomanProfileFunctions) {
    const dispatch = useDispatch();
    const transition = useTransition();
    const like = useLike();
    const sendWink = useSendWink();
    const sendActionSync = useSendActionSync();
    const { openVideo } = useOpenVideoShow(profile?.external_id);

    const userState = useSelector((state: RootState) => state.user);
    const [canceledToken, setCanceledToken] = useState(null);

    const openLetter = async () => {
        if (!userState?.info?.external_id) return;
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        if (closeWomanProfileModal) closeWomanProfileModal();

        dispatch(
            currentChatMailboxAction.addMailboxChatInfo({
                ...profile,
                sender_search_img: profile?.search_img,
                sender_gifts: profile?.gifts,
                sender_image: profile?.photo_link,
                sender_name: profile?.name,
                sender_age: profile?.age,
                sender_city: profile?.cityKey,
                sender_id: profile?.external_id,
                female_id: profile?.external_id,
                male_id: userState?.info?.external_id,
                openFromChat: true,
            }),
        );
        dispatch(
            currentChatMailboxAction.getMailboxChatHistory({
                userId: userState?.info?.external_id,
                girlId: profile?.public_external_id,
                page: 1,
            }),
        );
        transition.transitionWithHistoryPush('letter', `/letter/`);
    };

    const onGoToChat = () => {
        if (closeWomanProfileModal) closeWomanProfileModal();
        if (chatInfo?.chat_uid)
            transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${chatInfo?.chat_uid}`);
    };

    const onSetWink = async (helloText: string, isActiveText = false) => {
        if (+profile?.is_blocked)
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));

        await sendWink({
            id: profile?.external_id,
            helloText,
        }).then((res) => {
            if (res) {
                dispatch(
                    alertAction.setMessage({
                        message: isActiveText ? `Message sent: ${helloText}` : 'Message sent',
                        successCallback: onGoToChat,
                    }),
                );
            }
        });
    };

    const onSetLike = async () => {
        if (+profile?.is_blocked)
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));

        if (!likeStatus) {
            const response = await like(profile?.external_id);
            if (response?.status) {
                setProfile({ ...profile, liked: 1 });
                dispatch(likesAction.removeFromLikeList(profile?.external_id));
                dispatch(likesAction.addLikeHistory(profile));
            }
        }
    };

    const askVideo = async () => {
        if (!userState?.info?.external_id) return;
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        const hashId = moment().unix();
        const inputData = 'Hello! Can you send me your video, please?';
        let _inputData = inputData.replace(/(\r\n|\n|\r)/gm, '');

        _inputData = MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID)
            ? _inputData
            : hideStringStar(_inputData, REGEXP.NUMBER_IN_STRING);

        new ChatsApi()
            .postMessage(
                userState?.info?.external_id,
                profile?.external_id,
                _inputData,
                'ASK_VIDEO',
            )
            .then((res) => {
                if (res?.canceled) return;
                if (res?.status) {
                    if (!res?.response?.message_object) {
                        dispatch(currentChatAction.deleteMessageInHistory(hashId));
                        if (res?.message)
                            dispatch(alertAction.setMessage({ message: res?.message }));
                        else
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.ENOUGH_CREDITS_CHAT,
                                }),
                            );
                        return;
                    }
                    sendActionSync.postUserAction(+profile?.external_id, FRONT_SYNC.ACTION_LIKE);
                    dispatch(
                        currentChatAction.updateMessageInHistory(
                            hashId,
                            res?.response?.message_object,
                        ),
                    );
                    dispatch(
                        chatsAction.updateMessageInChatList(
                            res?.response?.message_object?.chat_uid,
                            getLastMessageContent(
                                res?.response?.message_object?.message_content,
                                res?.response?.message_object?.message_type,
                            ),
                            res?.response?.message_object?.message_type,
                            res?.response?.message_object?.date_created,
                            res?.response?.message_object?.sender_external_id,
                        ),
                    );
                    dispatch(userAction.setBalance());
                    if (userState?.info?.external_id) {
                        dispatch(
                            notifyAction.deleteNotify(
                                userState?.info?.external_id,
                                chatInfo?.chat_uid,
                            ),
                        );
                    }
                    closeWomanProfileModal();
                    transition.transitionWithHistoryPush(
                        MENU.OPEN_CHAT,
                        `/chat/${chatInfo?.chat_uid}`,
                    );
                } else if (res?.block) {
                    dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                } else {
                    if (res?.message)
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message,
                                title: res?.title,
                            }),
                        );
                    else
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.ENOUGH_CREDITS_CHAT,
                            }),
                        );
                }
                dispatch(currentChatAction.deleteMessageInHistory(hashId));
            });
    };

    const setFavorites = async () => {
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }
        // const res = await new MailboxApi().createChatWithProfile(userState?.info?.external_id, profile?.external_id);

        if (favoriteStatus !== null && userState?.info?.external_id && chatInfo?.chat_uid) {
            const source = initCancelTokenSource(canceledToken, setCanceledToken);

            new ChatsApi()
                .postSetFavorite({
                    userId: userState?.info?.external_id,
                    chat_uid: chatInfo?.chat_uid,
                    favorite: +!favoriteStatus,
                    token: source?.token,
                })
                .then((res) => {
                    if (res?.canceled) return;
                    if (res?.status) {
                        dispatch(currentChatAction.setFavoriteStatus(!favoriteStatus));
                        dispatch(currentChatMailboxAction.setFavorite(!favoriteStatus));
                        dispatch(
                            notifyAction.toggleFavoriteNotification(
                                chatInfo?.chat_uid,
                                !favoriteStatus,
                            ),
                        );
                        dispatch(chatsAction.setFavorite(chatInfo?.chat_uid, !favoriteStatus));
                        setFavoriteStatus(+!favoriteStatus);
                    }
                });
        }
    };

    const openChat = (event: MouseEvent<HTMLDivElement>) => {
        event?.preventDefault();
        event?.stopPropagation();

        if (window.innerWidth <= 1024) dispatch(currentChatAction.touchSwipeChatList('left'));

        if (!userState?.info?.external_id) return;
        if (+profile?.is_blocked)
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));

        new MailboxApi()
            .createChatWithProfile(userState?.info?.external_id, profile?.external_id)
            .then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    closeWomanProfileModal();
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
    };

    const initAutoLike = async () => {
        const isLikeActive = window?.location?.search?.includes('utm_action=like');

        if (isLikeActive && !likeStatus) {
            await onSetLike();

            dispatch(
                alertAction.setMessage({
                    message: `You liked ${profile?.name} profile`,
                    successCallback: onGoToChat,
                }),
            );
        }
    };

    const initAutoWink = async () => {
        const isWinkActive = window?.location?.search?.includes('utm_action=wink');

        if (isWinkActive && sayHelloList?.length > 1 && !profile?.wink) {
            const randomIndex = Math.floor(Math.random() * (sayHelloList?.length - 1 - 1) + 1);
            const randomSayHelloText = sayHelloList[randomIndex] ?? sayHelloList[0];

            await onSetWink(randomSayHelloText, true);
        }
    };

    useEffect(() => {
        if (profile?.external_id && chatInfo?.chat_uid) {
            initAutoLike();
            initAutoWink();
        }
    }, [profile, chatInfo, sayHelloList]);

    return { onSetLike, setFavorites, onSetWink, askVideo, openLetter, openChat, openVideo };
}
