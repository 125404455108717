import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../LoaderGif';
import MailboxApi from '../../../services/api/MailboxApi';
import * as LETTER from '../../../constants/letters';
import * as currentChatMailboxAction from '../../../store/actions/currentChatMailbox';
import * as mailboxAction from '../../../store/actions/mailbox';
import * as ALERT from '../../../constants/alert';
import * as alertAction from '../../../store/actions/alert';
import * as modalsAction from '../../../store/actions/modals';
import { sendErrToSentry } from '../../../services/sentry';
import { RootState } from '../../../index';
import IconReply from '../../Icons/IconReply';
import IconProfileChat from '../../Icons/IconProfileChat';
import IconTrash from '../../Icons/IconTrash';
import IconRestore from '../../Icons/IconRestore';

export default function SwitchLetterOptions({ folder, item }: { folder: string; item: any }) {
    const dispatch = useDispatch();

    const menuState = useSelector((state: RootState) => state.menu);
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);
    const userState = useSelector((state: RootState) => state.user);
    const mailboxState = useSelector((state: RootState) => state.mailbox);

    const [activeDelete, setActiveDelete] = useState<boolean>(false);

    const openChat = () => {
        dispatch(
            currentChatMailboxAction.addMailboxChatInfo({
                ...item.mail,
                ...{ actionTarget: LETTER.ACTION_TARGET_REPLY },
            }),
        );
        dispatch(currentChatMailboxAction.addMailboxSpecialLetter(item.mail.id));
        if (userState?.info)
            dispatch(
                currentChatMailboxAction.getMailboxChatHistory({
                    userId: userState?.info?.external_id,
                    folder: 'dialog',
                    girlId: item?.mail?.public_external_id,
                }),
            );
    };

    const redirectProfile = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(modalsAction.openWomanProfileModal(item.mail.female_id));
    };

    const setTrashLetter = () => {
        setActiveDelete(true);
        const newPage =
            mailboxState?.mailList?.length === 1
                ? mailboxState?.mailListPage - 1
                : mailboxState?.mailListPage;

        if (menuState.letters === 'trash' && userState?.info) {
            try {
                new MailboxApi()
                    .postRestoreMail({
                        userId: userState?.info?.external_id,
                        mailId: item?.mail?.id,
                    })
                    .then((res) => {
                        if (res?.status && userState?.info) {
                            dispatch(
                                mailboxAction.getMailsWithoutReload({
                                    userId: userState?.info?.external_id,
                                    folder: menuState?.letters,
                                    girlFilter: letterFilterState?.girlFilter,
                                    girlId: letterFilterState?.girlId,
                                    letterFilter: letterFilterState?.lettersFilter,
                                    onlineFilter: letterFilterState?.onlineFilter,
                                    page: newPage,
                                }),
                            );
                        }
                    })
                    .finally(() => setActiveDelete(false));
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        } else {
            try {
                new MailboxApi()
                    .postTrashThreadMail(item?.mail?.chat_id)
                    .then((res) => {
                        if (res?.status && userState?.info) {
                            dispatch(
                                mailboxAction.getMailsWithoutReload({
                                    userId: userState?.info?.external_id,
                                    folder: menuState?.letters,
                                    girlFilter: letterFilterState?.girlFilter,
                                    girlId: letterFilterState?.girlId,
                                    letterFilter: letterFilterState?.lettersFilter,
                                    onlineFilter: letterFilterState?.onlineFilter,
                                    page: newPage,
                                }),
                            );
                        }
                    })
                    .finally(() => {
                        // initLocalAlert.closeModalAlert();
                        setActiveDelete(false);
                    });
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }
    };

    const openAlertMoToTrash = () => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.ADD_TO_TRASH,
                successCallback: setTrashLetter,
            }),
        );
    };

    // const setBookmarkLetter = () => {
    //     if (+item.mail.bookmark === 0) {
    //         (new MailboxApi()).postSetBookmark({
    //             userId: userState.info.external_id,
    //             mailId: item.mail.id
    //         }).then(res => {
    //             if (res?.status) {
    //                 dispatch(mailboxAction.getMailsWithoutReload({
    //                     userId: userState.info.external_id,
    //                     folder: menuState.letters,
    //                     girlFilter: letterFilterState.girlFilter,
    //                     girlId: letterFilterState.girlId,
    //                     letterFilter: letterFilterState.lettersFilter,
    //                     onlineFilter: letterFilterState.onlineFilter,
    //                 }));
    //             }
    //         })
    //     } else {
    //         (new MailboxApi()).postUnsetBookmark({
    //             userId: userState.info.external_id,
    //             mailId: item.mail.id
    //         }).then(res => {
    //             if (res?.status) {
    //                 dispatch(mailboxAction.getMailsWithoutReload({
    //                     userId: userState.info.external_id,
    //                     folder: menuState.letters,
    //                     girlFilter: letterFilterState.girlFilter,
    //                     girlId: letterFilterState.girlId,
    //                     letterFilter: letterFilterState.lettersFilter,
    //                     onlineFilter: letterFilterState.onlineFilter,
    //                 }));
    //             }
    //         })
    //     }
    // };

    switch (folder) {
        case 'inbox':
            return (
                <div className="c3l_men_list_item_options_wrap">
                    <div className="c3l_men_list_item_options">
                        {item?.mail?.recipient_id === userState?.info?.external_id && (
                            <div
                                className="c3l_men_list_item_btn c3l_men_list_item_btn_reply"
                                onClick={openChat}
                                data-testid="letter-item-reply-btn"
                            >
                                <IconReply />
                                <span>Reply</span>
                            </div>
                        )}
                        <div
                            className="c3l_men_list_item_btn"
                            onClick={redirectProfile}
                            data-testid="letter-item-profile-btn"
                        >
                            <IconProfileChat />
                            <span>Profile</span>
                        </div>
                        {!activeDelete ? (
                            <div
                                className="c3l_men_list_item_btn c3l_delete_btn"
                                data-id={item.id}
                                onClick={openAlertMoToTrash}
                                data-testid="letter-item-delete-btn"
                            >
                                <IconTrash />
                                <span>Delete</span>
                                <div className="c3l_delete_read_first">Read the message first</div>
                            </div>
                        ) : (
                            <Loader
                                mainStyle={{
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    height: '100%',
                                    width: '100%',
                                }}
                            />
                        )}
                    </div>
                </div>
            );
        case 'trash':
            return (
                <div className="c3l_men_list_item_options_wrap">
                    <div className="c3l_men_list_item_options">
                        {!activeDelete ? (
                            <div
                                className="c3l_men_list_item_btn restore_msg"
                                onClick={setTrashLetter}
                                data-testid="letter-item-restore-btn"
                            >
                                <IconRestore />
                                <span>Restore</span>
                            </div>
                        ) : (
                            <Loader
                                mainStyle={{
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    height: '30px',
                                    width: '30px',
                                }}
                            />
                        )}
                    </div>
                </div>
            );
        case 'bookmarks':
            return (
                <div className="c3l_men_list_item_options_wrap">
                    <div className="c3l_men_list_item_options">
                        <div
                            className="c3l_men_list_item_btn c3l_men_list_item_btn_reply"
                            onClick={openChat}
                            data-testid="letter-item-reply-btn"
                        >
                            <IconReply />
                            <span>Reply</span>
                        </div>
                        <div
                            className="c3l_men_list_item_btn"
                            onClick={redirectProfile}
                            data-testid="letter-item-profile-btn"
                        >
                            <IconProfileChat />
                            <span>Profile</span>
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
    }
}
