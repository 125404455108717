import React from 'react';
import { hideMobBottomNav } from '../../../../../../services/methods/mobile';
import { IInputTextFieldProps } from './index.interface';

export default function InputTextField({
    inputData,
    handleInput,
    onSubmitMessageEnter,
    inputTextareaRef,
    isActiveLargeInput = false,
}: IInputTextFieldProps) {
    hideMobBottomNav();

    return (
        <textarea
            className={`c3_textaera_wrap ${isActiveLargeInput ? 'large' : ''}`}
            ref={inputTextareaRef}
            name="messageField"
            data-testid="message"
            id="input-mess"
            placeholder="Type in to chat..."
            value={inputData}
            onChange={handleInput}
            onKeyDown={onSubmitMessageEnter}
        />
    );
}
