import React from 'react';

export default function IconVipStar() {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="url(#paint0_linear_17_1231)"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_17_1231)">
                <path d="M5.52526 0.397194C5.62021 -0.132397 6.37979 -0.132399 6.47474 0.397192L7.19511 4.41526C7.23072 4.61384 7.38616 4.76928 7.58474 4.80489L11.6028 5.52526C12.1324 5.62021 12.1324 6.37979 11.6028 6.47474L7.58474 7.19511C7.38616 7.23072 7.23072 7.38616 7.19511 7.58474L6.47474 11.6028C6.37979 12.1324 5.62021 12.1324 5.52526 11.6028L4.80489 7.58474C4.76928 7.38616 4.61384 7.23072 4.41526 7.19511L0.397194 6.47474C-0.132397 6.37979 -0.132399 5.62021 0.397192 5.52526L4.41526 4.80489C4.61384 4.76928 4.76928 4.61384 4.80489 4.41526L5.52526 0.397194Z" />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_17_1231"
                    x1="2.43703e-07"
                    y1="6.62687"
                    x2="13.8438"
                    y2="6.70508"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEB930" />
                    <stop offset="1" stopColor="#FE8730" />
                </linearGradient>
                <clipPath id="clip0_17_1231">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
