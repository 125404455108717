import Base from '../Base';
import {
    IApplePayCreateData,
    IPropositionRes,
    ISecurionpayChargeData,
    IStripeCreateRes,
} from './PaymentApi.interface';

export default class PaymentApi extends Base {
    async getUserLoyaltyProgram() {
        try {
            const res = await super.get(`v3/loyalty-program`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async createUserLoyaltyProgram(loyaltyID: number) {
        try {
            const res = await super.put(`v3/loyalty-program`, { loyaltyID });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getPaymentMethods() {
        try {
            const res = await super.get(`v3/payment/methods`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getPaymentProposition(): Promise<IPropositionRes> {
        try {
            const res = await super.get(`v3/payment/proposition`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getPaymentCount() {
        try {
            const res = await super.get(`v3/payment/count`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postCardPayCreate(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/cardpay/create`, { credits, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postCardPayRecurringOneClickCreate(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/cardpay/recurring/one-click/create`, {
                credits,
                hash,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postCardPayGooglePayCreate(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/cardpay/google-pay/create`, { credits, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postCardPayGooglePayRequest(token: number, orderID: string) {
        try {
            const res = await super.post(`v3/payment/cardpay/google-pay/request`, {
                token,
                orderID,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postSecurionpayCreateV2(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/securionpay/create-v2`, { credits, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postSecurionpayCharge({ orderID, tokenID }: ISecurionpayChargeData) {
        try {
            const res = await super.post(`v3/payment/securionpay/charge`, { orderID, tokenID });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postAcquiringCreate(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/acquiring/create`, { credits, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postAcquiringApplePayCreated(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/acquiring/create-applepay`, { credits, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postAcquiringGooglePayCreated(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/acquiring/create-googlepay`, {
                credits,
                hash,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postApplePayValidation(url: string) {
        try {
            const res = await super.post(`v3/payment/applepay/validate`, { url });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postApplePayCreate({ credits, payment, hash = '' }: IApplePayCreateData) {
        try {
            const res = await super.post(`v3/payment/applepay/create`, { credits, payment, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postApplePayPastaValidation(url: string) {
        try {
            const res = await super.post(`v3/payment/pastabank/applepay/validate`, { url });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postApplePayPastaCreate({ credits, payment, hash = '' }: IApplePayCreateData) {
        try {
            const res = await super.post(`v3/payment/pastabank/applepay/credits`, {
                credits,
                payment,
                hash,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    //recurring payment
    async getInitSubscribe() {
        try {
            const res = await super.get(`v3/payment/subscription`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    //When user hasn't a subscription
    async getPastabankSubscribeInfo() {
        try {
            const res = await super.get(`v3/payment/pastabank/subscription`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    //When user has a subscription
    async getPastabankSubscribeInfoList() {
        try {
            const res = await super.get(`v3/payment/pastabank/subscription-list`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postPastaChangeCreditPack(credits: number) {
        try {
            const res = await super.post(`v3/payment/pastabank/changeCredits`, { credits });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postPastabankCancelSubscribe() {
        try {
            const res = await super.post(`v3/payment/pastabank/subscription/canceled`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getCardpaySubscribeInfo() {
        try {
            const res = await super.get(`v3/payment/cardpay/recurring`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getCardpaySubscribeInfoList() {
        try {
            const res = await super.get(`v3/payment/cardpay/recurring/list`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postCardpayCreateSubscribe(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/cardpay/recurring/create`, { credits, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postCardpayCancelSubscribe() {
        try {
            const res = await super.post(`v3/payment/cardpay/recurring/cancel`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postCardpayChangeSubscribe(credits: number) {
        try {
            const res = await super.post(`v3/payment/cardpay/recurring/change`, { credits });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postUniversepayCreate(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/universepay/create`, { credits, hash });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postUniversepayRecurringCreate(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/universepay/recurring/create`, {
                credits,
                hash,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getLastPurchase() {
        try {
            const res = await super.get('v3/payment/purchase/last');
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postRoutingCancelSubscribe() {
        try {
            const res = await super.post(`v3/payment/sphera/recurring/cancel`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postPastaGooglePayCreate(credits: number, hash = '') {
        try {
            const res = await super.post(`v3/payment/pastabank/google-pay/create`, {
                credits,
                hash,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postPastaGooglePayRequest(token: number, orderID: string) {
        try {
            const res = await super.post(`v3/payment/pastabank/google-pay/request`, {
                token,
                orderID,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postStripeCreate(packageID: number, hash = ''): Promise<IStripeCreateRes> {
        return super
            .post(`v3/payment/stripe/create-payment-intent`, {
                credits: packageID,
                hash,
            })
            .then((res) => res?.data)
            .catch(() => null);
    }
}
