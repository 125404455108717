import { closeMobChatList, openMobChatList } from '../../services/dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatAction from '../../store/actions/currentChat';
import * as LENGTH from '../../constants/length';
import { RootState } from '../../index';

export default function useTouchChatList() {
    const dispatch = useDispatch();
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const [touchStart, setTouchStart] = useState(0);
    const [isSwipeFromRightSide, setIsSwipeFromRightSide] = useState(false);

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        const isOpenAttachModal = document.querySelector('#attach-modal');

        if (window.innerWidth < 1000 && !isOpenAttachModal) {
            if (window.innerWidth - LENGTH.WIDTH_RIGHT_SIDE <= event?.targetTouches?.[0]?.clientX) {
                setIsSwipeFromRightSide(true);
            } else {
                setIsSwipeFromRightSide(false);
            }
            setTouchStart(event?.targetTouches?.[0]?.clientX);
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        const isOpenAttachModal = document.querySelector('#attach-modal');

        if (window.innerWidth < 1000 && !isSwipeFromRightSide && !isOpenAttachModal) {
            if (
                touchStart - 150 > event?.targetTouches?.[0]?.clientX &&
                currentChatState?.info?.id
            ) {
                dispatch(currentChatAction.touchSwipeChatList('left'));
                closeMobChatList();
            }

            if (
                touchStart + 150 < event?.targetTouches?.[0]?.clientX &&
                currentChatState?.info?.id
            ) {
                dispatch(currentChatAction.touchSwipeChatList('right'));
                openMobChatList();
            }
        }
    };

    return {
        handleTouchStart,
        handleTouchMove,
    };
}
