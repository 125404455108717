import {
    osVersion,
    osName,
    isMobile,
    isIOS,
    browserName,
    fullBrowserVersion,
    mobileModel,
    mobileVendor,
    deviceType,
    isAndroid,
    getUA,
    engineName,
    engineVersion,
} from 'react-device-detect';
import { isbot } from 'isbot';

export default function useDetectedDevice() {
    const isMobileIOS = isMobile && isIOS;

    const smallInfo = () => {
        return {
            device: isMobile ? 'mob' : 'desk',
            os: `${osName} ${osVersion}`,
        };
    };

    const fullInfo = () => {
        return {
            os: `${osName} ${osVersion}`,
            browser: `${browserName} ${fullBrowserVersion}`,
            mobile: mobileModel,
            device: deviceType,
        };
    };

    const deviceMemory = (navigator as any)?.deviceMemory ?? 0;
    const fullClientData = {
        browserName,
        fullBrowserVersion,
        osName,
        osVersion,
        mobileVendor,
        deviceType,
        userAgent: getUA,
        engineName,
        engineVersion,
        memory: deviceMemory,
        language: (navigator as any)?.language || 'Unknown',
        isBot: isbot(getUA) ?? 'Unknown',
        screenSize: `${window?.screen?.width}x${window?.screen?.height}`,
        viewportSize: `${window?.innerWidth}x${window?.innerHeight}`,
        devicePixelRatio: window?.devicePixelRatio || 1,
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Unknown',
        currentDateTime: new Date()?.toISOString() || 'Unknown',
    };

    const isNotAndroid = !osName?.toLowerCase()?.includes('android');

    return {
        smallInfo,
        fullInfo,
        isMobileIOS,
        isNotAndroid,
        osVersion,
        isAndroid,
        mobileModel,
        mobileVendor,
        getUA,
        isMobile,
        fullClientData,
    };
}
