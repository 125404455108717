import { ReferenciesActionTypes } from '../../constants/referencies';
import ProfileApi from '../../../services/api/ProfileApi';
import { Dispatch } from 'redux';

export function getReferencies() {
    return function (dispatch: Dispatch<{ type: string; referencies: any }>) {
        return new ProfileApi().getReferencies().then((res) => {
            if (res?.status && res?.result?.referencies_list) {
                dispatch({
                    type: ReferenciesActionTypes.GET_REFERENCIES_LIST,
                    referencies: res?.result?.referencies_list,
                });

                return res?.result?.referencies_list;
            }
        });
    };
}
