import React from 'react';
import GiftsCart from '../../components/gifts/GiftsCart';

export default function GiftsCartPage() {
    return (
        <>
            <GiftsCart />
        </>
    );
}
