import React from 'react';
import { makeCDN } from '../../../../../services/methods';
import SITE from '../../../../../config/site';
import { IImgContentProps } from './index.inteface';
import IconCross from '../../../../Icons/IconCross';

export default function ImgContent({
    item,
    closeModal,
    onViewPhoto = () => {},
    isFreeToWatch,
    image = '',
}: IImgContentProps) {
    const isHideClickToView =
        +item?.attachment_payed === 0 && item?.attachment_free && +item?.attachment_free === 1;
    const isClickToView = !isHideClickToView && !isFreeToWatch && +item?.attachment_payed !== 1;

    const imageLink = image || makeCDN(item?.link, 'IMAGE');

    const stylePointer =
        !isFreeToWatch && +item?.attachment_payed !== 1 ? { cursor: 'pointer' } : {};
    const styleClassWrap = `popup_attach_photo_click_img_wrap ${!isFreeToWatch && +item?.attachment_payed !== 1 ? 'unpaid' : ''}`;

    const onView = () => !isFreeToWatch && +item?.attachment_payed !== 1 && onViewPhoto();

    return (
        <>
            <div style={stylePointer} className={styleClassWrap} onClick={onView}>
                <img
                    src={imageLink}
                    alt=""
                    className={`popup_attach_photo_click_img`}
                    onDragStart={(e) => SITE.ID === 23 && e.preventDefault()}
                    onContextMenu={(e) => SITE.ID === 23 && e.preventDefault()}
                />
                {isClickToView && <div className="click_to_view">Click to view the photo</div>}
            </div>
            <div
                className={`pap-btn-close ${SITE.ID === 23 ? 'pap-btn-close-sendberly' : ''}`}
                onClick={closeModal}
            >
                <IconCross />
            </div>
        </>
    );
}
