import React from 'react';
import { INoCreditCardsProps } from './index.interface';
import credit_card_image from '../../../../img/manage-credit-card-modal/credit-card-img.svg';
import IconCross from '../../../../components/Icons/IconCross';
import IconCheckedCircle from '../../../../components/Icons/IconCheckedCircle';

export default function NoCreditCards({
    createToken,
    closeNoCreditCardModal,
}: INoCreditCardsProps) {
    return (
        <div className="popup_your_credit_cards">
            <div className="popup_your_credit_cards_wrap">
                <div className="popup_your_credit_cards_title">Your credit cards</div>
                <div className="popup_your_credit_cards_no_added">
                    <img src={credit_card_image} alt="credit card" />
                    <span>No credit cards added</span>
                </div>
                <div className="popup_your_credit_cards_empty_add_btn" onClick={createToken}>
                    <IconCheckedCircle />
                    <span>Add new card</span>
                </div>
            </div>
            <div className="close_one_popup" onClick={closeNoCreditCardModal}>
                <IconCross />
            </div>
        </div>
    );
}
