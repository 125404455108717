import React, { memo } from 'react';
import { formatDate } from '../../../../../services/time';
import styles from '../styles.module.scss';
import IconProfile from '../../../../Icons/IconProfile';
import IconCross from '../../../../Icons/IconCross';
import { IJoinedNotifyProps } from '../JoinedNotify/index.interface';
import placeholder from '../../../../../img/placeholder.gif';

const ViewedNotify = ({ item, deleteNotify, openWomanProfile }: IJoinedNotifyProps) => {
    return (
        <>
            <li
                className={`${styles.notify_item} ${styles.activity} ${styles.viewed} ${styles.online}`}
            >
                <div className={styles.notify_item_photo_wrap}>
                    <img
                        src={item?.avatar || placeholder}
                        alt="user avatar"
                        className={styles.notify_item_photo}
                    />
                </div>
                <div className={styles.notify_item_right}>
                    <p
                        className={styles.notify_item_name}
                    >{`${item?.name ?? ''}, ${item?.age ?? ''}`}</p>
                    <p
                        className={styles.notify_item_text}
                    >{`${item?.name ?? ''} viewed your profile`}</p>
                </div>
                <button
                    type="button"
                    className={styles.notify_item_activity_btn}
                    onClick={() => openWomanProfile(item, item?.sender_external_id)}
                >
                    <IconProfile />
                    <span>View profile</span>
                </button>
                <button
                    type="button"
                    className={styles.notify_item_clear_mask}
                    onClick={() => deleteNotify(item)}
                >
                    <IconCross />
                </button>
                <p className={styles.notify_item_time}>{formatDate(item?.date_created)}</p>
            </li>
        </>
    );
};

export default memo(ViewedNotify);
