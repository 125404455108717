import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';

import AutoTopUpUnsubscribe from './AutoTopUpUnsubscribe';
import { IUnsubscribeModalProps } from './index.interface';

export default function AutoTopUpUnsubscribeModal({
    isOpenUnsubscribeModal,
    closeUnsubscribeModal,
    activeSubscribeData,
    activeSubscribePaymentMethod,
    switchCancelSubscribe,
}: IUnsubscribeModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isOpenUnsubscribeModal}
            onRequestClose={closeUnsubscribeModal}
            ariaHideApp={false}
        >
            <AutoTopUpUnsubscribe
                closeModal={closeUnsubscribeModal}
                activeSubscribeData={activeSubscribeData}
                activeSubscribePaymentMethod={activeSubscribePaymentMethod}
                switchCancelSubscribe={switchCancelSubscribe}
            />
        </Modal>
    );
}
