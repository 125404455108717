import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import EmailUsed from './EmailUsed';
import { IEmailUsedModal } from './index.interface';

export default function EmailUsedModal({
    isOpenEmailUsedModal,
    closeEmailUsedModal,
    emailUsedModalText,
    openSignInBlock,
}: IEmailUsedModal) {
    if (!emailUsedModalText || emailUsedModalText.length === 0) {
        return null;
    }
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isOpenEmailUsedModal}
            onRequestClose={closeEmailUsedModal}
            ariaHideApp={false}
        >
            <EmailUsed
                closeModal={closeEmailUsedModal}
                emailUsedModalText={emailUsedModalText}
                openSignInBlock={openSignInBlock}
            />
        </Modal>
    );
}
