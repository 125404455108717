import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../common/LoaderGif';
import FilterMenu from './FilterMenu';
import List from './List';
import * as MENU from '../../../constants/menu';
import useHandleChatFilters from '../../../hooks/chat/useHandleChatFilters';
import useHandleMenu from '../../../hooks/menu/useHandleMenu';
import GirlsSlider from '../../common/GirlsSlider/GirlsSlider';
import useHandleMobileChat from '../../../hooks/chat/helpers/useHandleMobileChat';
import { RootState } from '../../../index';

export default function ChatList() {
    const chatsState = useSelector((state: RootState) => state.chats);
    const menuState = useSelector((state: RootState) => state.menu);
    const countersState = useSelector((state: RootState) => state.counters);

    const handleMenu = useHandleMenu();
    const handleChatFilters = useHandleChatFilters(); // Initialization methods and fields for the logic of the chat list.
    const handleMobileChat = useHandleMobileChat();

    return (
        <div
            className={`column-2 c2_fx column_2_chat_page ${handleMobileChat.isNewMobileChat && handleMobileChat.isActiveNewMobileChat ? 'hide_for_new_mobile_chat' : 'active'}`}
            data-testid="chat-list-block"
        >
            {window.innerWidth < 1140 &&
                !!(chatsState?.empty && !chatsState?.loadChatList && <GirlsSlider />)}

            <div className="c2_chat_mob_top" data-testid="chat-filters">
                {menuState?.main !== MENU.MENU_BLOCK && (
                    <div className="c2_chat_categories_wrap">
                        <div className="c2_chat_categories">
                            <button
                                type="button"
                                className={`c2_chat_categories_btn ${menuState?.main === MENU.MENU_CHAT && menuState?.sub?.length === 0 ? 'active' : ''}`}
                                onClick={() => handleMenu.onSetSubMenu(MENU.MENU_CHAT)}
                            >
                                <span>All chats</span>
                            </button>
                            <button
                                type="button"
                                className={`c2_chat_categories_btn ${menuState?.sub === MENU.MENU_UNANSWERED ? 'active' : ''}`}
                                onClick={() => handleMenu.onSetSubMenu(MENU.MENU_UNANSWERED)}
                            >
                                {!!countersState?.chatCounter?.unanswered && (
                                    <span className="c2_chat_categories_btn_counter">
                                        {countersState?.chatCounter?.unanswered}
                                    </span>
                                )}
                                <span>Unread</span>
                            </button>
                            <button
                                type="button"
                                className={`c2_chat_categories_btn ${menuState?.sub === MENU.MENU_FAVORITE ? 'active' : ''}`}
                                onClick={() => handleMenu.onSetSubMenu(MENU.MENU_FAVORITE)}
                            >
                                <span>Favorite</span>
                            </button>
                            <button
                                type="button"
                                className={`c2_chat_categories_btn ${menuState?.sub === MENU.MENU_LIKE ? 'active' : ''}`}
                                onClick={() => handleMenu.onSetSubMenu(MENU.MENU_LIKE)}
                            >
                                <span>Your likes</span>
                            </button>
                        </div>
                    </div>
                )}

                <FilterMenu {...handleChatFilters} />
            </div>

            {menuState.main === MENU.MENU_BLOCK ? (
                chatsState?.loadBlockList ? (
                    <Loader />
                ) : (
                    <List />
                )
            ) : chatsState?.loadChatList ? (
                <Loader />
            ) : (
                <List />
            )}

            <div className="c2_close" onClick={handleChatFilters.closeMobileFiltersBlock} />
        </div>
    );
}
