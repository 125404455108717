import { MailboxActionTypes } from '../../constants/mailbox';
import MailboxApi from '../../../services/api/MailboxApi';
import { Dispatch } from 'redux';
import { IGetMailsData } from '../../../services/api/MailboxApi.interface';
import { IAddMailboxChatInfoPayload } from './index.interface';

export function currentLetterRoomPaidLetterContent(
    letterID: number,
    contentID: number,
    content: string,
) {
    return function (
        dispatch: Dispatch<{ type: string; letterID: number; contentID: number; content: string }>,
    ) {
        dispatch({
            type: MailboxActionTypes.CURRENT_LETTER_ROOM_PAID_LETTER_CONTENT,
            letterID,
            contentID,
            content,
        });
    };
}

export function removeDraftID() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_REMOVE_DRAFT_ID,
        });
    };
}

export function setReadStatus() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_SET_READ_LETTER,
        });
    };
}

export function setPayedReadStatus(mailID: number, content: string) {
    return function (dispatch: Dispatch<{ type: string; mailID: number; content: any }>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_SET_PAYED_READ_LETTER,
            mailID,
            content,
        });
    };
}

export function setIsNewMessage(isBool: boolean) {
    return function (dispatch: Dispatch<{ type: string; data: boolean }>) {
        return dispatch({
            type: MailboxActionTypes.SET_IS_NEW_MESS,
            data: isBool,
        });
    };
}

export function setInfoCurrentTarget(data: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: MailboxActionTypes.SET_INFO_CURRENT_TARGET,
            data: data,
        });
    };
}

export function setFavorite(value: boolean) {
    return function (dispatch: Dispatch<{ type: string; value: boolean }>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_SET_FAVORITE,
            value,
        });
    };
}
// TODO: unused action
export function setLike() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_SET_LIKE,
        });
    };
}

export function addDraftId(draftId: number) {
    return function (dispatch: Dispatch<{ type: string; data: number }>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_ADD_DRAFT_ID,
            data: draftId,
        });
    };
}

export function addMailboxSpecialLetter(payload: number) {
    return function (dispatch: Dispatch<{ type: string; data: number }>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_CHAT_ADD_SPECIAL_LETTER,
            data: payload,
        });
    };
}

export function addMailboxBookmarkLetter(payload: number) {
    return function (dispatch: Dispatch<{ type: string; data: number }>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_CHAT_ADD_BOOKMARK_LETTER,
            data: payload,
        });
    };
}

export function addMailboxChatInfo(payload: Partial<IAddMailboxChatInfoPayload>) {
    return function (
        dispatch: Dispatch<{ type: string; data: Partial<IAddMailboxChatInfoPayload> }>,
    ) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_CHAT_ADD_INFO,
            data: payload,
        });
    };
}

export function setMailboxChatInfoOpenFromChat(isOpenFromChat: boolean) {
    return function (dispatch: Dispatch<{ type: string; isOpenFromChat: boolean }>) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_CHAT_SET_INFO_OPEN_FROM_CHAT,
            isOpenFromChat,
        });
    };
}

export function removeMailboxChat() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.REMOVE_MAILBOX_CHAT,
        });
    };
}

export function clearMailboxChatInfoForce() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: MailboxActionTypes.MAILBOX_CHAT_CLEAR_INFO,
        });
    };
}

export function getMailboxChatHistory(payload: IGetMailsData) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new MailboxApi().postMails(payload).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: MailboxActionTypes.MAILBOX_CHAT_GET_HISTORY,
                    data: res,
                });
            }
        });
    };
}

export function getMailboxChatHistoryWithPageUp(payload: IGetMailsData) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new MailboxApi().postMails(payload).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: MailboxActionTypes.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP,
                    data: res,
                });
            }
        });
    };
}

export function getMailboxChatHistoryWithPageDown(payload: IGetMailsData) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new MailboxApi().postMails(payload).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: MailboxActionTypes.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN,
                    data: res,
                });
            }
        });
    };
}

export function getMailboxChatHistoryForce(payload: IGetMailsData) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new MailboxApi().postMails(payload).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: MailboxActionTypes.MAILBOX_CHAT_GET_HISTORY_FORCE,
                    data: res,
                });
            }
        });
    };
}

export function getMailboxChatHistorySendMess(payload: IGetMailsData, isReload = false) {
    return function (dispatch: Dispatch<{ type: string; data?: any }>) {
        if (isReload) {
            dispatch({
                type: MailboxActionTypes.FETCH_MAILBOX_GET_SEND_MESS,
            });
        }
        new MailboxApi().postMails(payload).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: MailboxActionTypes.MAILBOX_CHAT_GET_HISTORY_SEND_MESS,
                    data: res,
                });
            }
        });
    };
}

export function setCurrentChatOnlineStatus(status: number) {
    return function (dispatch: Dispatch<{ type: string; status: number }>) {
        return dispatch({
            type: MailboxActionTypes.SET_CURRENT_CHAT_MAILBOX_ONLINE_STATUS,
            status,
        });
    };
}
