import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface IOnboardingTextArea {
    title: string;
    activeStep: number;
    maxStep: number;
    subtitle: string;
    value: string;
    onClick: () => void;
    onChange: (value: string) => void;
    skipStep: () => void;
}

const OnboardingTextArea = ({
    title,
    activeStep,
    maxStep,
    subtitle,
    value,
    onClick,
    onChange,
    skipStep,
}: IOnboardingTextArea) => {
    const [valueInput, setValueInput] = useState('');

    useEffect(() => {
        if (value) setValueInput(value);
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>

            <div className={styles.input_wrapper}>
                <textarea
                    className={`${styles.input} ${valueInput ? styles.input_filled : ''}`}
                    value={valueInput}
                    maxLength={300}
                    onChange={(e) => {
                        setValueInput(e?.target?.value);
                        onChange(e?.target?.value);
                    }}
                    placeholder={`${subtitle} here`}
                />
                <span>{valueInput?.length || 0}/300</span>
            </div>

            <div className={styles.button_row}>
                <button className={styles.button} onClick={onClick}>
                    Continue
                </button>
                <button className={`${styles.button} ${styles.skip}`} onClick={skipStep}>
                    Skip
                </button>
            </div>
        </div>
    );
};

export default OnboardingTextArea;
