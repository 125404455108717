import React from 'react';

export default function IconClockRound() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#6B7280"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.75 12C3.75 7.44329 7.44329 3.75 12 3.75C16.5567 3.75 20.25 7.44329 20.25 12C20.25 16.5567 16.5567 20.25 12 20.25C7.44329 20.25 3.75 16.5567 3.75 12ZM12 2.25C6.61487 2.25 2.25 6.61487 2.25 12C2.25 17.3851 6.61487 21.75 12 21.75C17.3851 21.75 21.75 17.3851 21.75 12C21.75 6.61487 17.3851 2.25 12 2.25ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V12.7814C11.25 13.0193 11.3628 13.243 11.5541 13.3844L14.5541 15.603C14.8871 15.8493 15.3567 15.779 15.603 15.4459C15.8493 15.1129 15.779 14.6433 15.4459 14.397L12.75 12.4033V8Z"
            />
        </svg>
    );
}
