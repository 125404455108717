import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import OnlineNowFilters from './OnlineNowFilters';
import EmptyResult from '../common/EmptyResult';
import LoaderGif from '../common/LoaderGif';
import useSetOnlineNowList from '../../hooks/search/useSetOnlineNowList';
import useHandleVIP from '../../hooks/vip/useHandleVIP';
import OnlineNowWithBannerList from './OnlineNowWithBannerList';
import OnlineNowList from './OnlineNowList';
import SearchFiltersV3 from '../search/SearchFiltersV3';
import * as onlineNowAction from '../../store/actions/onlineNow';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../index';

export default function OnlineNow() {
    const onlineNowState = useSelector((state: RootState) => state.onlineNow);

    const dispatch = useDispatch();
    const { isShowVIPBanner } = useHandleVIP();
    const setOnlineNowList = useSetOnlineNowList();
    const handleActiveBlocks = useHandleActiveBlocks();

    const setFilterField = (field: string, value: number | string) => {
        if (field === 'id' && value === onlineNowState?.filter?.id) return;
        dispatch(
            onlineNowAction.setOnlineNowListFilter({ ...onlineNowState?.filter, [field]: value }),
        );
    };

    return (
        <>
            {handleActiveBlocks.isSearchV3 ? (
                <div className={styles.online_now_filter_v3_wrap}>
                    <SearchFiltersV3
                        filter={onlineNowState?.filter}
                        setFilter={(value) =>
                            dispatch(onlineNowAction.setOnlineNowListFilter(value))
                        }
                        isActiveOnlineFilter={false}
                        isLoadingPage={onlineNowState?.isLoadingPage}
                    />
                </div>
            ) : (
                <OnlineNowFilters
                    nameFilter={onlineNowState?.filter?.id}
                    setNameFilter={(value) => setFilterField('id', value)}
                    ageFromFilter={onlineNowState?.filter?.from}
                    setAgeFromFilter={(value) => setFilterField('from', value)}
                    ageToFilter={onlineNowState?.filter?.to}
                    setAgeToFilter={(value) => setFilterField('to', value)}
                />
            )}
            <div className={styles.online_now_list_wrap} onScroll={setOnlineNowList.handleScroll}>
                {onlineNowState?.isEmpty ? (
                    <EmptyResult setDefaultFilterValue={setOnlineNowList.setDefaultFilterValue} />
                ) : onlineNowState?.isLoading ? (
                    <LoaderGif />
                ) : isShowVIPBanner ? (
                    <OnlineNowWithBannerList />
                ) : (
                    <OnlineNowList />
                )}
            </div>
        </>
    );
}
