import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import IdentificationAlert from './IdentificationAlert';
import useInitIdentityVerification from '../../../../hooks/identityVerification/useInitIdentityVerification';
import UserVerificationApi from '../../../../services/api/v3/UserVerificationApi';
import { useHistory } from 'react-router-dom';
import { SUPPORT_WOMAN } from '../../../../constants/user';
import { RootState } from '../../../../index';

export default function IdentityVerificationModal() {
    const history = useHistory();
    const docsState = useSelector((state: RootState) => state.profile.docsState);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const initIdentityVerification = useInitIdentityVerification(
        docsState.configID,
        docsState.banner,
    );

    const {
        setModalIsActiveIdentityVerification,
        modalIsActiveIdentityVerification,
        closeIdentityVerificationModal,
        setIsDataPassed,
        updateApprovedDocs,
    } = initIdentityVerification;

    useEffect(() => {
        new UserVerificationApi().getIsNeedToVerifyDocs().then((res) => {
            if (res?.status) {
                if (res.result.banner === 'IN_PROGRESS_VERIFICATION') {
                    setIsDataPassed(true);
                }
                updateApprovedDocs(res.result);
            }
        });
    }, [history.location.pathname]);

    useEffect(() => {
        setModalIsActiveIdentityVerification(docsState.isShow ? docsState.isShow : false);
    }, [docsState.isShow]);

    useEffect(() => {
        if (docsState.isBlockClosing && history.location.pathname !== '/chat') {
            if (history.location.pathname?.includes('chat')) {
                if (currentChatState?.info?.id && +currentChatState?.info?.id !== +SUPPORT_WOMAN) {
                    window.location.replace('/chat');
                }
            } else {
                window.location.replace('/chat');
            }
        }
    }, [history.location.pathname, docsState.isBlockClosing, currentChatState?.info?.id]);

    return (
        <>
            <Modal
                style={modalStyleProps({ zIndex: 99999 })}
                isOpen={modalIsActiveIdentityVerification}
                onRequestClose={() => closeIdentityVerificationModal()}
                shouldCloseOnOverlayClick={!docsState.isBlockClosing}
                shouldCloseOnEsc={!docsState.isBlockClosing}
                ariaHideApp={false}
            >
                <IdentificationAlert
                    {...initIdentityVerification}
                    isBlockClosing={docsState.isBlockClosing}
                />
            </Modal>
        </>
    );
}
