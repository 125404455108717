import * as CHAT from '../../constants/chats';
import { getLastMessageContent } from '../../services/methods/chat';
import { IGetStoreChatListModel, IStoreChatListItemModel } from './index.interface';
import ChatsAPI from '../../services/api/ChatsApi';

export class ChatModel {
    static async getStoreChatItem(chatUID: string): Promise<IStoreChatListItemModel | null> {
        const chatItem = await new ChatsAPI().getChatListItem(chatUID);
        if (!chatItem) return null;

        const lastMessageContent = getLastMessageContent(
            chatItem?.last_message_message_type === CHAT.MESSAGE_CONTENT_TYPE_VIEW_PHOTOS
                ? ''
                : chatItem?.last_message_message_content,
            chatItem?.last_message_message_type,
        );

        return {
            ...chatItem,
            ...{
                id: chatItem?.external_id,
                favorite: chatItem?.female_favorite === 1,
                like: !chatItem?.female_like,
                wink: chatItem?.wink,
                gift: chatItem?.is_gift,
                gifts: chatItem?.is_gift,
                date_created: chatItem?.last_message_date_created,
                last_message_date: chatItem?.last_message_date_created,
                last_message: lastMessageContent,
                last_message_read_status: chatItem?.last_message_read_status,
                last_message_type: chatItem?.last_message_message_type,
                sender_external_id: chatItem?.last_message_sender_external_id,
            },
        };
    }

    static async getStoreChatList(
        userId: number,
        isOnline: boolean,
        searchItem: string,
        chatType: string,
        page: number,
    ): Promise<IGetStoreChatListModel> {
        const chatList = await new ChatsAPI().getChatList({
            online: +isOnline,
            search: searchItem,
            type: chatType,
            page: page,
        });

        if (!chatList?.length) {
            return {
                chats: [],
                page: 1,
            };
        }

        return {
            chats: chatList?.map((item) => {
                const lastMessageContent = getLastMessageContent(
                    item?.last_message_message_type === CHAT.MESSAGE_CONTENT_TYPE_VIEW_PHOTOS
                        ? ''
                        : item?.last_message_message_content,
                    item?.last_message_message_type,
                );

                return {
                    ...item,
                    ...{
                        id: item?.external_id,
                        favorite: item?.female_favorite === 1,
                        like: !item?.female_like,
                        wink: item?.wink,
                        gift: item?.is_gift,
                        gifts: item?.is_gift,
                        date_created: item?.last_message_date_created,
                        last_message_date: item?.last_message_date_created,
                        last_message: lastMessageContent,
                        last_message_read_status: item?.last_message_read_status,
                        last_message_type: item?.last_message_message_type,
                        sender_external_id: item?.last_message_sender_external_id,
                        connect: item?.connect,
                    },
                };
            }),
            page: page,
        };
    }
}
