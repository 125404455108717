import { useDispatch, useSelector } from 'react-redux';
import * as womanAction from '../../store/actions/woman';
import SearchApi from '../../services/api/v3/SearchApi';
import { RootState } from '../../index';
import { CancelToken } from 'axios';

export default function useAddWomanList() {
    const dispatch = useDispatch();
    const womanState = useSelector((state: RootState) => state.woman);

    const filterExistWoman = (womanList: any) => {
        return womanList
            ?.filter((item: any, index: number) => womanList?.indexOf(item) === index)
            ?.filter(
                (womanExternalID: number) =>
                    !womanState?.list?.some((item) => +womanExternalID === item?.external_id),
            );
    };

    return async (womanList: any, token?: CancelToken) => {
        if (womanList?.length) {
            const filterExistWomanList = filterExistWoman(womanList);
            if (!filterExistWomanList?.length) return;

            const result = await new SearchApi()
                .getWoman(filterExistWomanList, token)
                .then((res) => (res?.status ? res?.response : []));
            if (!result?.length) return;

            dispatch(womanAction.addWomanList(result));
        }
    };
}
