import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Item from './Item';
import * as mailboxAction from '../../../../store/actions/mailbox';
import { RootState } from '../../../../index';
import { IFemaleListItem } from '../../../../store/reducers/mailbox/index.interface';

interface IList {
    handleOnSelect: (item: IFemaleListItem) => void;
    selectedItem: IFemaleListItem | null;
}

export default function List({ handleOnSelect, selectedItem }: IList) {
    const userState = useSelector((state: RootState) => state.user);
    const mailboxState = useSelector((state: RootState) => state.mailbox);
    const menuState = useSelector((state: RootState) => state.menu);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            mailboxAction.getFemaleList({
                personFilter: menuState?.composeLetterFilter?.length
                    ? menuState?.composeLetterFilter
                    : '',
                userId: userState?.info?.external_id || 0,
                search: menuState?.composeLetterSearch?.length
                    ? menuState?.composeLetterSearch
                    : '',
            }),
        );
    }, [menuState.composeLetterFilter, menuState.composeLetterSearch]);

    return (
        <div className="popup_compose_men_list_wrap">
            <div className="popup_compose_men_list_wrap3">
                <div className="popup_compose_men_list_wrap2">
                    {!!mailboxState?.femaleList?.length &&
                        mailboxState?.femaleList?.map((item, key) => (
                            <Item
                                key={key}
                                item={item}
                                handleOnSelect={handleOnSelect}
                                selected={
                                    selectedItem?.id !== undefined &&
                                    +selectedItem?.id === +item?.id
                                }
                            />
                        ))}
                </div>
            </div>
        </div>
    );
}
