import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../../styles/react-modal/modal.style';
import AttachModal from '../../../AttachModal';
import Emoji from './Emoji';
import * as LENGTH from '../../../../../constants/length';
import AttachDataToLetter from '../../../AttachDataToLetter';
import SITE from '../../../../../config/site';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import SlickContainer from '../../../SlickContainer';
import { IMessageFormProps } from './index.interface';
import useHandleComposeLetterModal from '../../../../../hooks/letters/useHandleComposeLetterModal';

export default function MessageForm({ closeWriteLetterModal }: IMessageFormProps) {
    const {
        initSmileMobBlock,
        inputData,
        onSubmitMessageEnter,
        handleChangeInput,
        attachData,
        openAttachModal,
        deleteAttach,
        handleSmileBlock,
        disabledButton,
        addEmoji,
        sendButtonClass,
        submitLetter,
        priceTitle,
        attachModalIsOpen,
        closeAttachModal,
        sendImg,
        sendVideo,
        sendAudio,
    } = useHandleComposeLetterModal(closeWriteLetterModal);

    return (
        <React.Fragment>
            <div className="popup_write_letter_write_area">
                <div
                    className="popup_c3l_letters_list_write_area"
                    style={initSmileMobBlock.isOpenMobSmileBlock ? { height: '341px' } : {}}
                >
                    <div className="popup_c3l_letters_list_write_top">
                        <div
                            className={`popup_c3_write_msg_textareas ${attachData.length > 0 ? 'with_files' : ''}`}
                        >
                            <textarea
                                name=""
                                maxLength={LENGTH.LETTER_MAX_LENGTH}
                                placeholder="Start writing your letter here"
                                value={inputData}
                                onKeyDown={onSubmitMessageEnter}
                                onChange={handleChangeInput}
                                data-testid="message"
                            />
                            {attachData && attachData.length > 0 ? (
                                <div className="c3_write_msg_textareas_files">
                                    <div className="letters_attach_files_list">
                                        <SlickContainer attachData={attachData}>
                                            {attachData.length > 0 &&
                                                attachData.map((item, key) => (
                                                    <AttachDataToLetter
                                                        key={key}
                                                        index={key}
                                                        item={item}
                                                        deleteAttach={deleteAttach}
                                                    />
                                                ))}
                                        </SlickContainer>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="c3l_letters_list_write_bottom">
                        <div
                            className="c3l_letters_list_write_attach_btn"
                            onClick={openAttachModal}
                            data-testid="add-files-btn"
                        >
                            <div className="c3_attach_img"></div>
                            <div className="c3_attach_text">Add files</div>
                        </div>
                        <div
                            className="c3l_letters_list_write_bottom_count"
                            data-testid="entered-symbols"
                        >{`${inputData.length} symbols entered`}</div>
                        <div className="c3l_letters_list_write_bottom_right">
                            <Emoji {...handleSmileBlock} addEmoji={addEmoji} />
                            <div
                                className={`c3_send_btn unpaid ${disabledButton ? 'disabled' : ''} ${sendButtonClass}`}
                                onClick={() => !disabledButton && submitLetter()}
                                data-testid="send-btn"
                            >
                                <img src="../../../../../img/c3-msg-send.svg" alt="" />
                                <span>Send</span>
                                {SITE.ID !== 4 && (
                                    <div
                                        className="c3_send_btn_cost_text"
                                        data-testid="letter-price"
                                    >
                                        {priceTitle}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth < 1000 && initSmileMobBlock.isOpenMobSmileBlock && (
                    <div
                        className="mob-smiles"
                        style={{ width: window.innerWidth - 30, marginLeft: '-16px' }}
                        ref={initSmileMobBlock.selectRef}
                    >
                        <Picker
                            data={data}
                            onEmojiSelect={addEmoji}
                            emojiSize={32}
                            theme="light"
                            navPosition="none"
                            previewPosition="none"
                            searchPosition="none"
                        />
                    </div>
                )}
            </div>
            <Modal
                style={modalStyleProps({ isAttachment: true })}
                isOpen={attachModalIsOpen}
                onRequestClose={closeAttachModal}
                ariaHideApp={false}
            >
                <AttachModal
                    closeModal={closeAttachModal}
                    sendImg={sendImg}
                    sendVideo={sendVideo}
                    sendAudio={sendAudio}
                />
            </Modal>
        </React.Fragment>
    );
}
