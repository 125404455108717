import { useEffect } from 'react';
import useTransition from '../../../hooks/transition/useTransition';

export default function ProfilePremium() {
    const transition = useTransition(); // Tab Navigation

    useEffect(() => {
        transition.transitionWithOnlyHistoryPush('/my-profile');
    }, []);

    return null;
}
