import React, { useEffect, useRef, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import './custom-gallery-styles.scss';
import { getImageLink } from '../../../../../../services/methods/search/images';
import * as IMAGES from '../../../../../../constants/images';
import MailboxApi from '../../../../../../services/api/MailboxApi';
import * as alertAction from '../../../../../../store/actions/alert';
import * as ERROR from '../../../../../../constants/error';
import * as ALERT from '../../../../../../constants/alert';
import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../../../../../../hooks/transition/useTransition';
import * as MENU from '../../../../../../constants/menu';
import LoaderGif from '../../../../../../components/common/LoaderGif';
import { ImageCarousel } from '../../../../../../components/common/ImageCarousel';
import styles from './styles.module.scss';
import IconChat from '../../../../Icons/IconChat';
import IconCross from '../../../../Icons/IconCross';
import ProfileApi from '../../../../../../services/api/ProfileApi';
import { IGalleryModalProps } from './index.interface';
import { RootState } from '../../../../../../index';

export default function WomanGallery({
    closeGalleryModal,
    profile,
    currentPhotoIndex = 0,
    photosList,
}: IGalleryModalProps) {
    const userState = useSelector((state: RootState) => state.user);
    const [currentIndex, setCurrentIndex] = useState(currentPhotoIndex);
    const [gallery, setGallery] = useState(photosList);
    const mainPhotoRef = useRef<HTMLImageElement | null>(null);
    const listWrapRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const transition = useTransition();

    const onChat = () => {
        if (!userState?.info?.external_id) return;
        if (+profile?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }
        closeGalleryModal();
        new MailboxApi()
            .createChatWithProfile(userState?.info?.external_id, profile?.external_id)
            .then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
    };

    const onProfile = () => {
        closeGalleryModal();
        transition.transitionWithHistoryPush(
            MENU.MENU_PROFILE_WOMAN_PAGE,
            `/profile/${profile?.public_external_id}`,
        );
    };

    useEffect(() => {
        if (!gallery?.length) {
            new ProfileApi().getOperatorContent(profile?.external_id).then((res) => {
                if (res?.length) {
                    const photosList = res.length <= 10 ? res : res.slice(-10);
                    setGallery(photosList);
                }
            });
        }
    }, []);

    useEffect(() => {
        const mainPhotoElement = mainPhotoRef.current;
        const listWrapElement = listWrapRef.current;

        const handleResizeObserver = () => {
            if (window.innerWidth >= 789) {
                if (mainPhotoElement && listWrapElement) {
                    const resizeObserver = new ResizeObserver(() => {
                        const mainPhotoHeight = mainPhotoElement.offsetHeight;
                        listWrapElement.style.height = `${mainPhotoHeight}px`;
                    });
                    resizeObserver.observe(mainPhotoElement);
                    return () => {
                        resizeObserver.unobserve(mainPhotoElement);
                    };
                }
            } else {
                if (listWrapElement) {
                    listWrapElement.style.height = 'auto';
                }
            }
        };
        handleResizeObserver();
        window.addEventListener('resize', handleResizeObserver);
        return () => {
            window.removeEventListener('resize', handleResizeObserver);
        };
    }, []);

    return gallery?.length ? (
        <div className={styles.gallery}>
            <header className={styles.gallery_header}>
                <button type="button" className={styles.user_profile} onClick={onProfile}>
                    <div
                        className={`${styles.user_avatar} ${profile?.online > 0 ? styles.user_online : ''}`}
                    >
                        {profile?.avatar && <img src={profile.avatar} alt={profile?.username} />}
                    </div>
                    {profile?.username && <p className={styles.user_name}>{profile.username}</p>}
                </button>
                <button type="button" onClick={onChat} className={styles.button_chat}>
                    <IconChat />
                    <span>Chat now</span>
                </button>
                <button type="button" className={styles.button_close} onClick={closeGalleryModal}>
                    <IconCross />
                </button>
            </header>
            <div className={`${styles.gallery_photos} custom_gallery`}>
                <div className={styles.main_photo_wrap} ref={mainPhotoRef}>
                    <p
                        className={styles.image_counter}
                    >{`${currentIndex + 1}/${gallery.length}`}</p>
                    <ImageCarousel
                        startIndex={currentIndex}
                        photos={[...gallery]}
                        setCurrentIndex={setCurrentIndex}
                    />
                </div>
                <div className={styles.gallery_photos_list_wrap} ref={listWrapRef}>
                    <div className={styles.gallery_photos_list}>
                        {gallery.map((item, key) => {
                            return (
                                <div
                                    key={key}
                                    className={styles.gallery_photos_list_item}
                                    onClick={() => setCurrentIndex(key)}
                                >
                                    <img src={getImageLink(item?.link, IMAGES.SIZE_L)} alt="" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <LoaderGif />
    );
}
