import React, { useState } from 'react';
import { getReadableFileSizeString, cutLengthString } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
import { IUploadImageModalProps } from './index.interface';

export default function UploadImageModal({
    uploadImg,
    closeModalImg,
    handleUploadImg,
    setImgFile,
    imgFile,
    images,
}: IUploadImageModalProps) {
    const [imgName, setImgName] = useState('');

    const onSubmitImg = () => {
        if (imgFile instanceof File) {
            uploadImg({
                file: imgFile,
                fileName: imgName?.length > 0 ? imgName : imgFile?.name?.split('.')[0],
                index: images?.length > 0 ? +images[images?.length - 1]?.sort_order + 1 : 0,
            });
        }

        closeModalImg();
        setImgFile({});
        setImgName('');
    };

    return (
        <div
            className="add_file_popup_wrap"
            id="add_file_popup_wrap"
            data-testid="upload-image-modal"
        >
            <div className="popup_add_file_head">New image</div>
            {imgFile && imgFile.name && imgFile.size ? (
                <React.Fragment>
                    {imgFile && +imgFile.size > LENGTH.MAX_IMG_SIZE ? (
                        <div className={'popup_add_file_row oversize'}>
                            File you’ve uploaded is too big{' '}
                            <span>(max {LENGTH.MAX_IMG_SIZE_TITLE} Mb)</span>
                        </div>
                    ) : (
                        <div className="popup_add_file_row">
                            {/*<span>Image name</span>*/}
                            {/*<input type="text" placeholder="Enter image name here" value={imgName} required={true}*/}
                            {/*	   onChange={handleImgName}/>*/}
                        </div>
                    )}
                    {+imgFile.size <= LENGTH.MAX_IMG_SIZE ? (
                        <div className="popup_add_file_row uploaded">
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {cutLengthString(imgFile.name, LENGTH.ATTACH_TITLE_NAME)}
                                <span>({getReadableFileSizeString(imgFile.size)})</span>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </React.Fragment>
            ) : (
                <div />
            )}

            {imgFile && imgFile.name && imgFile.size && +imgFile.size <= LENGTH.MAX_IMG_SIZE ? (
                <div className="popup_add_file_row add_btn">
                    <div
                        className="popup_add_file_upload_btn"
                        data-testid="add-image-btn"
                        onClick={imgFile?.name ? onSubmitImg : () => null}
                    >
                        Add image
                    </div>
                </div>
            ) : (
                <div className="popup_add_file_row upload_btn">
                    <input
                        type="file"
                        id="popup_inp_file1"
                        accept="image/*"
                        onChange={handleUploadImg}
                    />
                    <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                        <img src="../../../../img/popup-upload.svg" alt="" />
                        <span>Upload</span>
                    </label>
                </div>
            )}
            <div className="popup_add_file_close" onClick={closeModalImg} data-testid="close-btn">
                <img src="../../../../img/cross_white.svg" alt="" />
            </div>
        </div>
    );
}
