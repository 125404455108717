import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import ModerationModal from '../../Modals/ModerationModal';
import * as MENU from '../../../../constants/menu';
import * as ALERT from '../../../../constants/alert';
import useInitModeration from '../../../../hooks/support/useInitModeration';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import { SUPPORT_WOMAN } from '../../../../constants/user';
import * as alertAction from '../../../../store/actions/alert';
import { IFunctionalityProps } from './index.interface';
import { RootState } from '../../../../index';
import IconStarFill from '../../../Icons/IconStarFill';
import IconStarStroke from '../../../Icons/IconStarStroke';
import IconLetters from '../../../Icons/IconLetters';
import IconChat from '../../../Icons/IconChat';
import IconMoreInfoDots from '../../../Icons/IconMoreInfoDots';
import IconBlock from '../../../Icons/IconBlock';
import IconComplain from '../../../Icons/IconComplain';

export default function Functionality({
    handleSetFavorite,
    goTo,
    handleBlackList,
    currentChatInfo,
}: IFunctionalityProps) {
    const selectRef = useRef(null);
    const initModeration = useInitModeration();
    const dispatch = useDispatch();

    const menuState = useSelector((state: RootState) => state.menu);

    const [isOpenMoreMenu, setIsOpenMoreMenu] = useState(false);

    const isSupportWoman = +currentChatInfo?.id === +SUPPORT_WOMAN;

    useOutsideClicker(selectRef, setIsOpenMoreMenu);

    const openMoreMenu = () => setIsOpenMoreMenu(!isOpenMoreMenu);

    const buttonsStatus = () => {
        if (!!+currentChatInfo?.wink && !currentChatInfo?.liked) return styles.both_visible;
        else if (!!+currentChatInfo?.wink || !currentChatInfo?.liked) return styles.one_visible;
        else return styles.none_visible;
    };

    const openAlertAddToBlock = () => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.ADD_TO_BLOCK_LIST,
                successCallback: () => handleBlackList(),
            }),
        );
    };

    if (!currentChatInfo?.chat_uid) return <div />;
    return (
        <div className="c3_head_right" id="c3_head_right">
            {menuState?.main !== MENU.MENU_BLOCK && (
                <>
                    <div
                        className={`${styles.c3_head_btn} ${styles.favs} ${buttonsStatus()} ${+currentChatInfo?.favorite ? styles.active : ''}`}
                        onClick={handleSetFavorite}
                        data-testid="favorite-btn"
                    >
                        <div className={styles.c3_head_btn_img}>
                            {+currentChatInfo?.favorite ? <IconStarFill /> : <IconStarStroke />}
                        </div>
                        <div className={styles.c3_head_btn_info}>Favorite</div>
                    </div>
                    {!isSupportWoman && (
                        <div
                            className={`${styles.c3_head_btn} ${styles.letter} ${buttonsStatus()}`}
                            onClick={goTo}
                            data-testid="other-message-type-btn"
                        >
                            <div className={styles.c3_head_btn_img}>
                                {currentChatInfo?.goto === 'letter' ? (
                                    <IconLetters />
                                ) : (
                                    <IconChat />
                                )}
                            </div>
                            <span className={styles.c3_head_btn_info}>
                                {currentChatInfo?.goto === 'letter' ? 'Letter' : 'Chat'}
                            </span>
                        </div>
                    )}
                </>
            )}
            {!isSupportWoman && (
                <div className={styles.c3_head_more_menu} ref={selectRef}>
                    <div
                        className={`${styles.c3_head_more_menu_open_btn} ${isOpenMoreMenu ? styles.active : ''}`}
                        onClick={openMoreMenu}
                        data-testid="more-btn"
                    >
                        <IconMoreInfoDots />
                        <span>More</span>
                    </div>
                    <div
                        className={`${styles.c3_head_more_menu_list_wrap} ${isOpenMoreMenu ? styles.active : ''}`}
                        data-testid="more-block"
                    >
                        <div className={styles.c3_head_more_menu_list}>
                            {menuState?.main !== MENU.MENU_BLOCK && (
                                <>
                                    <div
                                        className={`${styles.c3_head_more_btn} ${styles.favs} ${buttonsStatus()} ${+currentChatInfo?.favorite ? styles.active : ''}`}
                                        onClick={handleSetFavorite}
                                        data-testid="more-favorite-btn"
                                    >
                                        {+currentChatInfo?.favorite ? (
                                            <IconStarFill />
                                        ) : (
                                            <IconStarStroke />
                                        )}
                                        <span className={styles.c3_head_more_btn_info}>
                                            Add to favorites
                                        </span>
                                    </div>
                                    <div
                                        className={`${styles.c3_head_more_btn} ${styles.letter} ${buttonsStatus()}`}
                                        onClick={goTo}
                                        data-testid="more-other-message-type-btn"
                                    >
                                        {currentChatInfo?.goto === 'letter' ? (
                                            <IconLetters />
                                        ) : (
                                            <IconChat />
                                        )}
                                        <span className={styles.c3_head_more_btn_info}>
                                            {currentChatInfo?.goto === 'letter'
                                                ? 'Write a letter'
                                                : 'Write a message'}
                                        </span>
                                    </div>
                                </>
                            )}
                            <div
                                className={`${styles.c3_head_more_btn} ${styles.block} ${menuState.main === MENU.MENU_BLOCK ? 'active' : ''}`}
                                onClick={() =>
                                    menuState.main === MENU.MENU_BLOCK
                                        ? handleBlackList()
                                        : openAlertAddToBlock()
                                }
                                data-testid="add-to-block-list-btn"
                            >
                                <IconBlock />
                                <span className={styles.c3_head_more_btn_info}>
                                    {menuState.main === MENU.MENU_BLOCK
                                        ? 'Remove from block list'
                                        : 'Add to block list'}
                                </span>
                            </div>
                            <div
                                className={`${styles.c3_head_more_btn} ${styles.attention}`}
                                onClick={() => {
                                    initModeration.openModal(
                                        currentChatInfo?.public_external_id,
                                        currentChatInfo?.id,
                                    );
                                }}
                                data-testid="complain-btn"
                            >
                                <IconComplain />
                                <span className={styles.c3_head_more_btn_info}>Complain</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ModerationModal {...initModeration} />
        </div>
    );
}
