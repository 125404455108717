export const USER_INFO_FILTER_TYPE = 'referencies_list';
export const FILTER_TYPE = 'hair_list';
export const FILTER_NAME_FIELDS = 'hair';
export const ARR_FILTER_FIELDS = ['Bald', 'Dyed', 'Silvery', 'Snowy'];

export const LIKE_FILTERS_DEFAULT = {
    from: 0,
    to: 0,
    online: true,
    heightFrom: 0,
    heightTo: 0,
    bodyType: 0,
    hairColor: 0,
    eyeColor: 0,
    maritalStatus: 0,
    type: 'ONLINE',
};

export const LIKE_FILTERS_GENDER_DEFAULT: {
    from: number;
    to: number;
    online: boolean;
    gender: 'ALL' | number;
    country: number;
    country_name: string;
    city: string;
    city_name: string;
    sexFilter?: number;
    type: 'ONLINE' | 'LIKED_YOU' | 'WIDE';
} = {
    from: 0,
    to: 0,
    online: true,
    gender: 'ALL',
    country: 0,
    country_name: '',
    city: '',
    city_name: '',
    type: 'ONLINE',
};

export type FilterType =
    | (typeof LIKE_FILTERS_DEFAULT & { sexFilter?: number })
    | typeof LIKE_FILTERS_GENDER_DEFAULT;
