import React from 'react';
import styles from './styles.module.scss';
import Slider from 'react-slick';
import SliderItem from './SliderItem';
import { ITopProfile } from '../../../services/api/v3/SearchApi.interface';
import { SLIDER_SETTINGS } from '../../../constants/slider';

interface IGirlsProfilesProps {
    profiles: ITopProfile[];
    openGalleryModal: (id: number, images: any) => void;
    openChat: (id: number) => void;
    openLetter: (id: number) => void;
    sliderRef: React.MutableRefObject<Slider | null>;
    onNextSlide: () => void;
    onPrevSlide: () => void;
}

export default function GirlsProfiles({
    profiles,
    openChat,
    openLetter,
    openGalleryModal,
    sliderRef,
    onNextSlide,
    onPrevSlide,
}: IGirlsProfilesProps) {
    return (
        <div className={styles.unsubscribe_girls_list}>
            <Slider {...SLIDER_SETTINGS} ref={sliderRef}>
                {profiles?.length &&
                    profiles.map((profile, key) => (
                        <SliderItem
                            className={styles.slider_item}
                            key={key + profile?.id}
                            profile={profile}
                            openChat={openChat}
                            openGalleryModal={openGalleryModal}
                            openLetter={openLetter}
                        />
                    ))}
            </Slider>
            <div className={styles.girls_slider_btn_buttons}>
                <div
                    className={`${styles.girls_slider_btn} ${styles.girls_slider_prev_btn}`}
                    onClick={onPrevSlide}
                />
                <div
                    className={`${styles.girls_slider_btn} ${styles.girls_slider_next_btn}`}
                    onClick={onNextSlide}
                />
            </div>
        </div>
    );
}
