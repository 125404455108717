import React from 'react';

export default function IconLikeActive() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.023 2C8.09979 2 6.36752 3.10895 5.64748 4.83914C5.42748 5.30042 5.32139 5.77633 5.30298 6.25444C5.85189 6.08785 6.43108 6 7.02298 6C8.32333 6 9.55483 6.43166 10.5414 7.17408C11.5304 6.4245 12.7687 6 14.0792 6C16.4015 6 18.5128 7.337 19.3963 9.49581L19.4085 9.52556C19.6238 10.0106 19.7419 10.505 19.7831 10.996C20.0439 10.6628 20.29 10.3232 20.5144 9.98014C21.1042 9.07831 21.5711 8.11156 21.7347 7.14417C21.8643 6.39491 21.8108 5.63651 21.4889 4.91871L21.4708 4.87459C20.7495 3.11213 19.0182 2 17.0792 2C15.7476 2 14.5181 2.5245 13.6479 3.40791C13.6127 3.44283 13.5781 3.47842 13.5443 3.51462C12.6626 2.57502 11.3895 2 10.023 2ZM18.7164 12.2457C18.7229 12.2119 18.729 12.178 18.7347 12.1442C18.8643 11.3949 18.8108 10.6365 18.4889 9.91871L18.4708 9.87459C17.7495 8.11213 16.0182 7 14.0792 7C12.9225 7 11.8429 7.39575 11.0088 8.07873C10.8827 8.18201 10.7622 8.29187 10.6479 8.40791C10.6127 8.44283 10.5782 8.47842 10.5443 8.51462C10.404 8.36506 10.2537 8.22474 10.0947 8.09451C9.25494 7.40653 8.17199 7 7.02298 7C6.4543 7 5.90232 7.09696 5.38826 7.27828C4.93 7.43991 4.50189 7.66857 4.11895 7.95534C3.47873 8.43478 2.96481 9.07663 2.64749 9.83914C2.10572 10.9751 2.25471 12.1997 2.70282 13.3276C2.73093 13.3983 2.76036 13.4691 2.79101 13.5398C3.25091 14.5999 3.98975 15.6457 4.80273 16.5998C4.91134 16.7272 5.02178 16.8536 5.13366 16.9788C6.81179 18.8563 8.81246 20.4574 9.78681 21.1979L9.78851 21.1991L9.88729 21.2738L9.89022 21.276C9.91333 21.2933 9.9372 21.3097 9.96176 21.3253C10.1337 21.4343 10.3397 21.5 10.5581 21.5C10.5763 21.5 10.5943 21.4995 10.6121 21.4987C10.8785 21.4853 11.0983 21.3745 11.2615 21.2393L11.3153 21.1979L11.3171 21.1966C11.4262 21.1136 11.5479 21.02 11.6804 20.9166C12.6075 20.1924 14.06 18.9858 15.4048 17.5779C15.501 17.4772 15.5967 17.3753 15.6919 17.2723C16.3582 16.5512 16.9945 15.7751 17.5144 14.9801C17.5855 14.8714 17.6549 14.7616 17.7221 14.6511C18.1929 13.8772 18.5606 13.0625 18.7164 12.2457Z"
            />
        </svg>
    );
}
