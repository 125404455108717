import React, { ComponentType } from 'react';
import { Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import useTransition from '../../hooks/transition/useTransition';
import { getUrlParameter } from '../../utils/url-utils';
import { localStorageGetItem } from '../../utils/local-storage';

interface IHiddenAfterAuthRouteProps extends RouteProps {
    component: ComponentType<any>;
}
export default function HiddenAfterAuthRoute({
    component: Component,
    ...rest
}: IHiddenAfterAuthRouteProps) {
    const transition = useTransition();
    const location = useLocation();
    const accessKey = getUrlParameter('accessKey');
    const token = localStorageGetItem('token', true);

    if (token && !accessKey) {
        transition.transitionWithHistoryPush('chat', `/chat${location?.search || ''}`);
        return null;
    }

    return <Route {...rest} render={(props: RouteComponentProps) => <Component {...props} />} />;
}
