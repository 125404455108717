import React from 'react';
import { useDispatch } from 'react-redux';
import LikeFilters from '../../components/like/LikeFilters';
import LikeProfile from '../../components/like/LikeProfile';
import LikeHistory from '../../components/like/LikeHistory';
import { closeMobChatList, removeBlur } from '../../services/dom';
import '../../styles/likeher/likeher.scss';
import '../../styles/likeher/likeher-new.scss';
import Banner from '../../components/Banner';
import useTransition from '../../hooks/transition/useTransition';
import * as modalsAction from '../../store/actions/modals';
import useInitResize from '../../hooks/resize/useInitResize';
import GirlsSliderLikeHer from '../../components/common/GirlsSliderLikeHer/GirlsSliderLikeHer';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import LikeFiltersGender from '../../components/like/LikeFiltersGender';
import useHandleLikeHerFilter from '../../hooks/likeher/useHandleLikeHerFilter';
import * as likesAction from '../../store/actions/likes';
import IconClockArchive from '../../components/Icons/IconClockArchive';

export default function LikePage() {
    const transition = useTransition();
    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();
    const handleLikeHerFilter = useHandleLikeHerFilter();

    useInitResize();

    const redirectHistory = () => {
        removeBlur();
        dispatch(likesAction.initSearchState());
        transition.transitionWithHistoryPush('likehistory', `/likehistory`);
    };

    return (
        <>
            <div className="column-2 likeher column_2_likeher_page">
                <div className="column_2_likeher_wrap">
                    {handleActiveBlocks.isSearchV3 ? (
                        <LikeFiltersGender {...handleLikeHerFilter} />
                    ) : (
                        <LikeFilters {...handleLikeHerFilter} />
                    )}
                    <div
                        className={`c2lh_girls_you_liked_wrap ${handleLikeHerFilter?.wideTab ? 'wide' : 'fast'}`}
                    >
                        <LikeHistory />
                    </div>
                    <div className="c2lh_all_likes_btn_wrap">
                        <div className="c2lh_all_likes_btn" onClick={redirectHistory}>
                            <IconClockArchive />
                            <span>Your likes</span>
                        </div>
                    </div>
                    <div
                        className="c2_close"
                        onClick={() => {
                            closeMobChatList();
                            dispatch(modalsAction.setActiveSubMenu());
                        }}
                    />
                </div>
            </div>
            <div className="column-3 likeher c3_fx">
                <Banner />
                <GirlsSliderLikeHer />
                <LikeProfile setDefaultFilterValue={handleLikeHerFilter.setDefaultFilterValue} />
            </div>
        </>
    );
}
