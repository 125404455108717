import React, { useState } from 'react';
import { getReadableFileSizeString } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
import { IUploadAudioModalProps } from './index.interface';

export default function UploadVideoModal({
    closeModalVideo,
    uploadVideo,
    setVideoFile,
    videoFile,
    handleUploadVideo,
    videos,
}: IUploadAudioModalProps) {
    const [videoName, setVideoName] = useState('');

    const onSubmitVideo = () => {
        if (videoFile instanceof File) {
            uploadVideo({
                file: videoFile,
                fileName: videoName?.length > 0 ? videoName : videoFile?.name?.split('.')[0],
                index: videos?.length > 0 ? +videos[videos?.length - 1]?.sort_order + 1 : 0,
            });
        }

        closeModalVideo();
        setVideoFile({});
        setVideoName('');
    };

    return (
        <div className="add_file_popup_wrap" data-testid="upload-video-modal">
            <div className="popup_add_file_head">New video</div>
            {videoFile && videoFile.name && videoFile.size ? (
                <React.Fragment>
                    {videoFile && +videoFile.size > LENGTH.MAX_VIDEO_SIZE ? (
                        <div className={'popup_add_file_row oversize'}>
                            File you’ve uploaded is too big{' '}
                            <span>(max {LENGTH.MAX_VIDEO_SIZE_TITLE} Mb)</span>
                        </div>
                    ) : (
                        <div className="popup_add_file_row">
                            {/*<span>Video name</span>*/}
                            {/*<input type="text"*/}
                            {/*	   placeholder="Enter video name here"*/}
                            {/*	   value={videoName}*/}
                            {/*	   required={true}*/}
                            {/*	   onChange={handleVideoName}/>*/}
                        </div>
                    )}
                    {+videoFile.size <= LENGTH.MAX_VIDEO_SIZE ? (
                        <div className="popup_add_file_row uploaded">
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {videoFile.name.length > 10
                                    ? videoFile.name.substr(0, 10)
                                    : videoFile.name}
                                <span>({getReadableFileSizeString(videoFile.size)})</span>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </React.Fragment>
            ) : (
                <div />
            )}

            {videoFile &&
            videoFile.name &&
            videoFile.size &&
            +videoFile.size <= LENGTH.MAX_VIDEO_SIZE ? (
                <div className="popup_add_file_row add_btn">
                    <div
                        className="popup_add_file_upload_btn"
                        onClick={videoFile?.name ? onSubmitVideo : () => null}
                        data-testid="add-video-btn"
                    >
                        Add Video
                    </div>
                </div>
            ) : (
                <div className="popup_add_file_row upload_btn">
                    <input
                        type="file"
                        id="popup_inp_file1"
                        accept="video/*"
                        onChange={handleUploadVideo}
                    />
                    <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                        <img src="/img/popup-upload.svg" alt="" />
                        <span>Upload</span>
                    </label>
                </div>
            )}
            <div className="popup_add_file_close" onClick={closeModalVideo} data-testid="close-btn">
                <img src="/img/cross_white.svg" alt="" />
            </div>
        </div>
    );
}
