import { QuizActionTypes } from '../../constants/quiz';
import { Dispatch } from 'redux';

export function getQuizData(quizData = {}) {
    return function (dispatch: Dispatch<{ type: string; quizData: any }>) {
        return dispatch({
            type: QuizActionTypes.GET_QUIZ_DATA,
            quizData,
        });
    };
}

export function getTypeList(typeList = []) {
    return function (dispatch: Dispatch<{ type: string; typeList: any }>) {
        return dispatch({
            type: QuizActionTypes.GET_TYPE_LIST,
            typeList,
        });
    };
}

export function setIsActiveQuizModal(isActiveModal: boolean) {
    return function (dispatch: Dispatch<{ type: string; isActiveModal: boolean }>) {
        return dispatch({
            type: QuizActionTypes.IS_ACTIVE_QUIZ_MODAL,
            isActiveModal,
        });
    };
}

export function setIsActiveBeforeDeletingQuizModal(isActiveModal: boolean, callback: () => void) {
    return function (
        dispatch: Dispatch<{ type: string; isActiveModal: boolean; callback: () => void }>,
    ) {
        return dispatch({
            type: QuizActionTypes.IS_ACTIVE_BEFORE_DEL_QUIZ_MODAL,
            isActiveModal,
            callback,
        });
    };
}
