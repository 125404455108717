import Base from './Base';

export default class FBConversion extends Base {
    //Checking if the Facebook Conversion API (CAPI) is needed for current mirror
    async getCheckFBCapi() {
        const res = await super.get('marketing/checkCapi');
        return res?.data;
    }

    sentFBAuth(
        email: string,
        external_id: string | number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        fbp: string,
        fbc: string,
    ) {
        return super.post('marketing/auth_conversion', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            fbp,
            fbc,
        });
    }

    sentFBAuth55(
        email: string,
        external_id: string | number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        fbp: string,
        fbc: string,
    ) {
        return super.post('marketing/auth_conversion_55', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            fbp,
            fbc,
        });
    }

    sentFBFirstPurchase(
        email: string,
        external_id: string | number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        fbp: string,
        fbc: string,
    ) {
        return super.post('marketing/payment_conversion', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            fbp,
            fbc,
        });
    }

    sentFBSpendAllCredits(
        email: string,
        external_id: string | number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        fbp: string,
        fbc: string,
    ) {
        return super.post('marketing/spend_all_credits_conversion', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            fbp,
            fbc,
        });
    }

    sentFBTwoMorePurchase(
        email: string,
        external_id: string | number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        fbp: string,
        fbc: string,
    ) {
        return super.post('marketing/two_more_purchase_conversion', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            fbp,
            fbc,
        });
    }

    sentFBDelfinConversion(
        email: string,
        external_id: string | number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        fbp: string,
        fbc: string,
    ) {
        return super.post('marketing/delfin_conversion', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            fbp,
            fbc,
        });
    }

    sentFBConfirmEmailConversion(
        email: string,
        external_id: string | number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        fbp: string,
        fbc: string,
    ) {
        return super.post('marketing/confirm_email_conversion', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            fbp,
            fbc,
        });
    }

    sentFBEachPurchase(
        email: string,
        external_id: number,
        name: string,
        b_year: number,
        b_month: number,
        b_day: number,
        price: number,
    ) {
        return super.post('marketing/every_one_payment_conversion', {
            email,
            external_id,
            name,
            b_year,
            b_month,
            b_day,
            price,
        });
    }
}
