import React, { useEffect } from 'react';
import * as ATTACH from '../../../../constants/attach';
import { gotoById } from '../../../../services/dom';

export default function Loader({ status }: { status: boolean }) {
    useEffect(() => {
        if (status) gotoById(ATTACH.GO_TO_LOADER);
    }, [status]);

    return (
        <div
            className={`upload_popup_tabs_content_item ${status ? 'active' : 'hidden'}`}
            id={ATTACH.GO_TO_LOADER}
        >
            <div className="upload_popup_tabs_content_item_top">
                <img src="/img/loader_attach.gif" alt="" className="attach_loader_gif" />
            </div>
        </div>
    );
}
