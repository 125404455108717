import { RefObject, useRef, useState } from 'react';
import useOutsideClickerChatMob from '../../dom/useOutsideClickerChatMob';

export default function useInitStickerMobBlock(writeMsgRef?: RefObject<HTMLDivElement | null>) {
    const [isOpenMobStickerBlock, setIsOpenMobStickerBlock] = useState<boolean>(false);
    const selectStickerRef = useRef<HTMLDivElement | null>(null);

    useOutsideClickerChatMob(
        selectStickerRef,
        () => {
            setIsOpenMobStickerBlock(false);
        },
        writeMsgRef,
    );

    return {
        isOpenMobStickerBlock,
        setIsOpenMobStickerBlock,
        selectStickerRef,
    };
}
