import { useEffect, useState } from 'react';
import ProfileApi from '../../services/api/ProfileApi';
import useTransition from '../transition/useTransition';
import { useSelector } from 'react-redux';
import useGetWinkList from '../wink/useGetWinkList';
import * as FRONT_SYNC from '../../constants/frontSync';
import useSendActionSync from '../frontSync/useSendActionSync';
import SearchApi from '../../services/api/v3/SearchApi';
import { RootState } from '../../index';
import { Params } from '@sentry/react/types/types';
import { match } from 'react-router';
import { IWomanGalleryImage } from '../../store/actions/modals/index.interface';
import { IWomanProfileChat, IWomanProfileFull } from '../../services/api/ProfileApi.interface';

interface IUseInitWomanProfileInfo {
    match?: match<Params>;
    setGalleryContent: (value: IWomanGalleryImage[]) => void;
    setVideoContent: (value: string[]) => void;
}

export default function useInitWomanProfileInfo({
    match,
    setGalleryContent,
    setVideoContent,
}: IUseInitWomanProfileInfo) {
    const modalsState = useSelector((state: RootState) => state.modals);

    const [profile, setProfile] = useState<IWomanProfileFull>();
    const [chatInfo, setChatInfo] = useState<IWomanProfileChat>();
    const [favoriteStatus, setFavoriteStatus] = useState<number | null>(null);
    const [womanExternalID, setWomanExternalID] = useState<number | null>(null);

    const newWomanExternalID =
        modalsState?.womanProfileModal?.externalID || +(match?.params?.id || 0);
    const winkStatus = !!profile?.wink;

    const transition = useTransition();
    const sendActionSync = useSendActionSync();
    const { sayHelloList } = useGetWinkList({});

    useEffect(() => {
        if (newWomanExternalID && womanExternalID !== newWomanExternalID) {
            if (+(match?.params?.id || 0)) {
                new SearchApi()
                    .getSearchList(1, {
                        city: 0,
                        city_name: '',
                        country: 0,
                        country_name: '',
                        from: 0,
                        to: 0,
                        gender: 0,
                        id: match?.params?.id || '',
                        online: false,
                    })
                    .then((res) => {
                        if (
                            res?.status &&
                            res?.response?.length &&
                            res?.response?.[0]?.external_id
                        ) {
                            setWomanExternalID(res?.response?.[0]?.external_id);
                        } else {
                            transition.transitionWithOnlyHistoryPush('/search');
                        }
                    })
                    .catch(() => transition.transitionWithOnlyHistoryPush('/search'));
            } else {
                if (modalsState?.womanProfileModal?.externalID) {
                    setWomanExternalID(modalsState?.womanProfileModal?.externalID);
                }
            }
        }
    }, [match?.params?.id, modalsState?.womanProfileModal?.externalID]);

    useEffect(() => {
        if (womanExternalID) {
            new ProfileApi().getOperator(womanExternalID).then((res) => {
                if (res?.status) {
                    sendActionSync.postUserAction(+womanExternalID, FRONT_SYNC.ACTION_VIEW_PROFILE);
                    setProfile({
                        ...res?.result?.profile,
                        ...res?.result?.chat,
                        id: res?.result?.profile?.external_id,
                    });
                    setFavoriteStatus(+res?.result?.chat?.female_favorite);
                    setChatInfo(res?.result?.chat);
                } else transition.transitionWithOnlyHistoryPush('/search');
            });

            new ProfileApi().getOperatorContent(womanExternalID).then((res) => {
                if (res?.length > 0) {
                    const gallery = res?.filter((item: any) => item?.content_type === 'image');
                    const videos = res?.filter((item: any) => item?.content_type === 'video');

                    // eslint-disable-next-line no-inner-declarations
                    function sortByFree(a: any, b: any) {
                        if (+a?.free < +b?.free) return 1;
                        if (+a?.free > +b?.free) return -1;
                        return 0;
                    }

                    setGalleryContent(gallery.sort(sortByFree));
                    setVideoContent(videos);
                }
            });
        }
    }, [womanExternalID]);

    return {
        profile,
        chatInfo,
        favoriteStatus,
        setFavoriteStatus,
        setWinkStatus: () =>
            setProfile({
                ...(profile as IWomanProfileFull),
                wink: +!profile?.wink,
            }),
        winkStatus,
        likeStatus: profile?.liked || 0,
        onlineStatus: !!profile?.online,
        birthdateString:
            profile?.year_birth +
            '-' +
            ((profile?.month_birth || 0) < 10 ? '0' + profile?.month_birth : profile?.month_birth) +
            '-' +
            ((profile?.day_birth || 0) < 10 ? '0' + profile?.day_birth : profile?.day_birth),
        sayHelloList,
        setProfile,
    };
}
