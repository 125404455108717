import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MessageNotify from './MessageNotify';
import LikedNotify from './LikedNotify';
import ViewedNotify from './ViewedNotify';
import LetterNotify from './LetterNotify';
import VerificationNotify from './VerificationNotify';
import styles from './styles.module.scss';
import { notificationsStatuses } from '../../../../constants/checkDocs/notificationsStatuses';
import { RootState } from '../../../../index';
import { INotifyListProps } from './index.interface';

export default function NotifyList({
    list,
    deleteNotification,
    openChat,
    openLetter,
}: INotifyListProps) {
    const docsState = useSelector((state: RootState) => state.profile.docsState);
    const [verificationStatus, setVerificationStatus] = useState({
        banner: '',
        title: '',
        text: '',
        onClick: false,
    });

    useEffect(() => {
        setVerificationStatus(
            notificationsStatuses.find(
                (notification) => notification?.banner === docsState?.banner,
            ) || { banner: '', title: '', text: '', onClick: false },
        );
    }, [docsState?.banner]);

    return (
        <div className="c4_list_wrap_2" data-testid="notification-list">
            <div className={styles.notify_list_wrap}>
                <ul className={styles.notify_list}>
                    {docsState?.banner && (
                        <VerificationNotify
                            item={verificationStatus}
                            configID={docsState?.configID}
                        />
                    )}
                    {/* TODO: We should not use index as a key */}
                    {list.map((item, key: number) => {
                        if (item?.notification_type === 'viewed') {
                            return (
                                <ViewedNotify
                                    item={item}
                                    key={key}
                                    deleteNotification={deleteNotification}
                                    openChat={openChat}
                                />
                            );
                        } else if (item?.notification_type === 'mail') {
                            return (
                                <LetterNotify
                                    item={item}
                                    key={key}
                                    deleteNotification={deleteNotification}
                                    openLetter={openLetter}
                                />
                            );
                        } else if (item?.notification_type === 'liked') {
                            return (
                                <LikedNotify
                                    item={item}
                                    key={key}
                                    deleteNotification={deleteNotification}
                                    openChat={openChat}
                                />
                            );
                        } else {
                            return (
                                <MessageNotify
                                    item={item}
                                    key={key}
                                    deleteNotification={deleteNotification}
                                    openChat={openChat}
                                />
                            );
                        }
                    })}
                </ul>
            </div>
        </div>
    );
}
