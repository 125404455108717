import { ChatsActionTypes } from '../../constants/chats';
import ChatsApi from '../../../services/api/ChatsApi';
import { Dispatch } from 'redux';
import { IAddMessageToHistoryParams } from './index.interface';
import { CancelToken } from 'axios';
import { IStoreChatListItemModel } from '../../../models/chat/index.interface';

export function setHidePageDown(hidePageDown: number, isDown = false) {
    return function (dispatch: Dispatch<{ type: string; hidePageDown: number; isDown: boolean }>) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_SET_HIDE_PAGE_DOWN,
            hidePageDown,
            isDown,
        });
    };
}

export function setStartHistoryPage() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_START_PAGE,
        });
    };
}

export function setFavoriteStatus(favoriteStatus: boolean) {
    return function (dispatch: Dispatch<{ type: string; favoriteStatus: boolean }>) {
        return dispatch({
            type: ChatsActionTypes.CURRENT_CHAT_SET_FAVORITE_STATUS,
            favoriteStatus,
        });
    };
}

export function saveChatPinned(pinned: number) {
    return function (dispatch: Dispatch<{ type: string; pinned: number }>) {
        return dispatch({
            type: ChatsActionTypes.SET_PINNED_CURRENT_CHAT,
            pinned,
        });
    };
}

export function touchSwipeChatList(touchSwipeType: string) {
    return function (dispatch: Dispatch<{ type: string; touchSwipeType: string }>) {
        return dispatch({
            type: ChatsActionTypes.TOUCH_SWIPE_TYPE_CHAT_LIST,
            touchSwipeType,
        });
    };
}

export function readMessageVideo(messageId: number, messageContent: string) {
    return function (
        dispatch: Dispatch<{ type: string; messageId: number; messageContent: string }>,
    ) {
        return dispatch({
            type: ChatsActionTypes.READ_MESS,
            messageId,
            messageContent,
        });
    };
}

export function readMessageImage(messageId: number, messageContent: string) {
    return function (
        dispatch: Dispatch<{ type: string; messageId: number; messageContent: string }>,
    ) {
        return dispatch({
            type: ChatsActionTypes.READ_MESS,
            messageId,
            messageContent,
        });
    };
}

export function readMessageAudio(messageId: number, messageContent: string) {
    return function (
        dispatch: Dispatch<{ type: string; messageId: number; messageContent: string }>,
    ) {
        return dispatch({
            type: ChatsActionTypes.READ_MESS,
            messageId,
            messageContent,
        });
    };
}

export function openFromNotification(notifyId: number, girlExternalId: number) {
    return function (
        dispatch: Dispatch<{ type: string; data: { notifyId: number; girlExternalId: number } }>,
    ) {
        if (!notifyId || !girlExternalId) return;
        return dispatch({
            type: ChatsActionTypes.IS_OPEN_FROM_NOTIFY,
            data: { notifyId, girlExternalId },
        });
    };
}

export function setReadLastMessages() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ChatsActionTypes.SET_READ_LAST_MESS,
        });
    };
}

export function setIsNewMessage(isBool: boolean) {
    return function (dispatch: Dispatch<{ type: string; data: boolean }>) {
        return dispatch({
            type: ChatsActionTypes.SET_IS_NEW_MESS,
            data: isBool,
        });
    };
}

export function removeMainChatInfo() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_REMOVE_INFO,
        });
    };
}

export function addMainChatInfo(payload: IStoreChatListItemModel) {
    return function (dispatch: Dispatch<{ type: string; data: IStoreChatListItemModel }>) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_ADD_INFO,
            data: payload,
        });
    };
}

export function addLastMessageToMainChat(payload: IAddMessageToHistoryParams) {
    return function (dispatch: Dispatch<{ type: string; data: IAddMessageToHistoryParams }>) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_ADD_LAST_MESSAGE,
            data: payload,
        });
    };
}

export function addMainChatHistoryWithReload(
    payload: string,
    page = 1,
    token?: CancelToken | null,
) {
    return function (dispatch: Dispatch<{ type: string; data?: any }>) {
        dispatch({
            type: ChatsActionTypes.FETCH_CHAT_GET,
        });
        new ChatsApi().getHistory(payload, page, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: ChatsActionTypes.MAIN_CHAT_ADD_HISTORY,
                data: res,
            });
        });
    };
}

export function addMainChatHistoryWithPage(chatUid: string, page: number) {
    return function (dispatch: Dispatch<{ type: string; data?: any }>) {
        dispatch({
            type: ChatsActionTypes.MAIN_CHAT_ADD_HISTORY_PAGE_LOADING,
        });
        new ChatsApi().getHistory(chatUid, page).then((res) => {
            if (res) {
                return dispatch({
                    type: ChatsActionTypes.MAIN_CHAT_ADD_HISTORY_PAGE,
                    data: res,
                });
            }
        });
    };
}

export function addMessageToHistory(newMess: IAddMessageToHistoryParams) {
    return function (dispatch: Dispatch<{ type: string; data: IAddMessageToHistoryParams }>) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_ADD_MESSAGE,
            data: newMess,
        });
    };
}

export function updateMessageInHistory(hashId: number, newMess: IAddMessageToHistoryParams) {
    return function (
        dispatch: Dispatch<{ type: string; data: IAddMessageToHistoryParams; hashId: number }>,
    ) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_UPDATE_MESSAGE,
            data: newMess,
            hashId: hashId,
        });
    };
}

export function deleteMessageInHistory(hashId: number) {
    return function (dispatch: Dispatch<{ type: string; hashId: number }>) {
        return dispatch({
            type: ChatsActionTypes.MAIN_CHAT_DELETE_MESSAGE,
            hashId: hashId,
        });
    };
}

export function setCurrentChatOnlineStatus(status: number) {
    return function (dispatch: Dispatch<{ type: string; status: number }>) {
        return dispatch({
            type: ChatsActionTypes.SET_CURRENT_CHAT_ONLINE_STATUS,
            status,
        });
    };
}

export function setCurrentChatLikeStatus() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ChatsActionTypes.SET_CURRENT_CHAT_LIKE_STATUS,
        });
    };
}
