import React from 'react';
import { IGiftItem } from '../../../store/reducers/gifts/index.interface';
import { IContentGallery } from '../GiftsCatalogItemModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';

interface IGiftsCartItem {
    item: IGiftItem;
    onSelectImage: (index: number, gallery: IContentGallery[]) => void;
    deleteFromCart: (item: IGiftItem) => void;
}

export default function GiftsCartItem({ item, onSelectImage, deleteFromCart }: IGiftsCartItem) {
    const contentGallery: IContentGallery[] = [];
    const userState = useSelector((state: RootState) => state.user);

    const addToGallery = (i: { image: string }) => contentGallery.push({ link: i?.image });

    if (item?.image) addToGallery(item);
    else addToGallery({ image: '/img/gifts/img_no_photo.jpg' });

    const actualPrice = item ? +(userState?.giftsDiscount ? item?.new_price : item?.price) : 0;

    return (
        <div className="c3gp_gifts_cart_main_item">
            <div className="c3gp_gifts_cart_main_item_gallery">
                <div
                    className={`c3gp_gifts_cart_main_item_gallery_big_wrap ${userState?.giftsDiscount ? 'c3gp_gifts_cart_main_item_gallery_big_wrap_discount_label' : ''}`}
                >
                    <img
                        src={item?.image || '/img/gifts/img_no_photo.jpg'}
                        alt={item?.name || ''}
                        onClick={() => onSelectImage(0, contentGallery)}
                        className="c3gp_gifts_cart_main_item_gallery_big"
                    />
                    {userState?.giftsDiscount && item?.discount && (
                        <div className="c3gp_gifts_cart_main_item_gallery_big_wrap_discount_amount">{`- ${+item?.discount}%`}</div>
                    )}
                </div>
                <div className="c3gp_gifts_cart_main_item_gallery_list">
                    {item.gallery
                        ? item.gallery.map((i, key) => {
                              addToGallery(i);
                              return (
                                  <img
                                      onClick={() => onSelectImage(key + 1, contentGallery)}
                                      key={key}
                                      src={i?.image}
                                      alt=""
                                  />
                              );
                          })
                        : ''}
                </div>
            </div>
            <div className="c3gp_gifts_cart_main_item_title">{item?.name}</div>
            <div className="c3gp_gifts_cart_main_item_desc_wrap">
                <div className="c3gp_gifts_cart_main_item_desc_text">{item?.description}</div>
                <div
                    className={`c3gp_gifts_cart_main_item_desc_price ${userState?.giftsDiscount ? 'c3gp_gifts_cart_main_item_desc_price_with_discount' : ''}`}
                >
                    Price<span>{actualPrice} credits</span>
                    {userState?.giftsDiscount && item?.old_price && (
                        <span className="c3gp_gifts_cart_main_item_desc_old_price">{`${+item?.old_price} credits`}</span>
                    )}
                </div>
                <div className="c3gp_gifts_cart_main_item_desc_count_wrap"></div>
            </div>
            <div className="c3gp_gifts_cart_main_item_remove" onClick={() => deleteFromCart(item)}>
                <img src="/img/gifts/cross-circle-red.svg" alt="" />
                <span>
                    Remove
                    <br />
                    from cart
                </span>
            </div>
        </div>
    );
}
