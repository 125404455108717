import React, { MouseEventHandler } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import TermCredits from './TermCredits';

export default function TermCreditsModal({
    isActiveCreditModal,
    closeCreditsModal,
}: {
    isActiveCreditModal: boolean;
    closeCreditsModal: MouseEventHandler<HTMLDivElement>;
}) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isActiveCreditModal}
            onRequestClose={closeCreditsModal}
            ariaHideApp={false}
        >
            <TermCredits close={closeCreditsModal} />
        </Modal>
    );
}
