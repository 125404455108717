import React from 'react';

export default function IconBlockList() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.39361 6.49049C4.21088 7.91827 3.5 9.75112 3.5 11.75C3.5 16.3063 7.19365 20 11.75 20C13.512 20 15.1451 19.4476 16.4853 18.5065L5.39361 6.49049ZM6.45205 5.42565L17.6309 17.536C19.096 16.047 20 14.0041 20 11.75C20 7.19365 16.3063 3.5 11.75 3.5C9.73314 3.5 7.88531 4.22373 6.45205 5.42565ZM2 11.75C2 6.36522 6.36522 2 11.75 2C17.1348 2 21.5 6.36522 21.5 11.75C21.5 17.1348 17.1348 21.5 11.75 21.5C6.36522 21.5 2 17.1348 2 11.75Z"
            />
        </svg>
    );
}
