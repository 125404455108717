import Base from './Base';

export default class ConfigApi extends Base {
    async getConfigByExternalID() {
        const res = await super.get(`v3/config`);
        return res?.data;
    }

    async createRedUserLog(type: number, extra = '') {
        const res = await super.post(`v3/config/type/log/create`, { type, extra });
        return res?.data;
    }

    async createRedUserLogNotAuth(type: number, extra = '') {
        const res = await super.post(`v3/config/type/log/create/not-auth`, { type, extra });
        return res?.data;
    }
}
