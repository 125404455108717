import { VideoShowActionTypes } from '../../constants/videoShow';
import SearchApi from '../../../services/api/v3/SearchApi';
import { VIDEO_SHOW_FILTERS_DEFAULT } from '../../../constants/search';
import { Dispatch } from 'redux';
import {
    IGetVideoShowListAction,
    ISetVideoShowIsLoadingPageAction,
} from '../../reducers/videoShow/index.interface';

export function getVideoShowList(page = 1, filter = VIDEO_SHOW_FILTERS_DEFAULT) {
    return function (
        dispatch: Dispatch<IGetVideoShowListAction | ISetVideoShowIsLoadingPageAction>,
    ) {
        return new SearchApi().getSearchList(page, filter).then((res) => {
            if (res?.status) {
                dispatch({
                    type: VideoShowActionTypes.GET_VIDEO_SHOW_LIST,
                    searchList: res?.response,
                });

                return res?.response;
            }

            dispatch({
                type: VideoShowActionTypes.SET_IS_LOADING_PAGE,
                status: false,
            });
            return [];
        });
    };
}

export function initVideoShowState() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: VideoShowActionTypes.INIT_VIDEO_SHOW_SEARCH,
        });
    };
}

export function setVideoShowListPage(page = 1) {
    return function (dispatch: Dispatch<{ type: string; page: number }>) {
        return dispatch({
            type: VideoShowActionTypes.SET_VIDEO_SHOW_LIST_PAGE,
            page,
        });
    };
}

export function setSVideoShowListFilter(filter = VIDEO_SHOW_FILTERS_DEFAULT) {
    return function (
        dispatch: Dispatch<{ type: string; filter: typeof VIDEO_SHOW_FILTERS_DEFAULT }>,
    ) {
        return dispatch({
            type: VideoShowActionTypes.SET_VIDEO_SHOW_LIST_FILTER,
            filter,
        });
    };
}

export function setVideoShowOnlineStatus(externalIDList: number[], status: number) {
    return function (
        dispatch: Dispatch<{ type: string; externalIDList: number[]; status: number }>,
    ) {
        return dispatch({
            type: VideoShowActionTypes.SET_VIDEO_SHOW_ONLINE_STATUS,
            externalIDList,
            status,
        });
    };
}

export function setVideoShowLikeStatus(womanExternalID: number) {
    return function (dispatch: Dispatch<{ type: string; womanExternalID: number }>) {
        return dispatch({
            type: VideoShowActionTypes.SET_VIDEO_SHOW_LIKE_STATUS,
            womanExternalID,
        });
    };
}
