import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import { RootState } from '../../../../index';
import { IDeleteProfileModalProps } from './index.interface';
import IconCheckedCircle from '../../../Icons/IconCheckedCircle';

export default function DeleteProfileModal({
    isDeleteProfileOpen,
    closeModal,
    deletionStep,
    openQuizModal,
    acceptDeleting,
}: IDeleteProfileModalProps) {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <Modal
            isOpen={isDeleteProfileOpen}
            onRequestClose={closeModal}
            style={modalStyleProps()}
            ariaHideApp={false}
        >
            <>
                <div className={styles.delete_modal_wrap}>
                    {(function () {
                        switch (deletionStep) {
                            case 1:
                                return (
                                    <>
                                        <h2 className={styles.delete_modal_title}>
                                            Deletion with the possibility of recovery
                                        </h2>
                                        <div className={styles.delete_modal_desc}>
                                            Your profile data can be restored in the future by
                                            writing to our support{' '}
                                            <a href={`mailto:${userState?.supportEmail}`}>
                                                {userState?.supportEmail}
                                            </a>
                                        </div>
                                        <div
                                            className={`${styles.delete_modal_choose_btn}`}
                                            onClick={openQuizModal}
                                        >
                                            <IconCheckedCircle />
                                            <span>Delete profile</span>
                                        </div>
                                    </>
                                );
                            case 2:
                                return (
                                    <>
                                        <h2 className={styles.delete_modal_title}>
                                            Deletion of your profile
                                        </h2>
                                        <p className={styles.delete_modal_desc}>
                                            If you change your mind and want to come back, you can
                                            contact our Support Team or create a new account
                                        </p>
                                        <div
                                            className={`${styles.delete_modal_choose_btn}`}
                                            onClick={acceptDeleting}
                                        >
                                            <IconCheckedCircle />
                                            <span>Delete profile</span>
                                        </div>
                                    </>
                                );
                            default:
                                return <div />;
                        }
                    })()}
                    <div className={styles.delete_modal_close} onClick={closeModal} />
                </div>
            </>
        </Modal>
    );
}
