import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as modalsAction from '../../../store/actions/modals';
import styles from './styles.module.scss';

export default function EmailConfirmV2() {
    const dispatch = useDispatch();

    const [showConfirmBlock, setShowConfirmBlock] = useState(true);

    const onConfirm = () => {
        dispatch(modalsAction.openEmailEdit());
    };

    const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setShowConfirmBlock(false);
    };

    if (!showConfirmBlock) return null;
    return (
        <div
            className={`${styles.top_banner} ${styles.banner_confirm_email} chat_wrap_confirm_email_wrap`}
            data-testid="confirm-email-banner"
            onClick={onConfirm}
        >
            <div className={styles.top_title_wrap}>
                <span>Be aware of all events!</span>
                <span className={styles.sub_desktop}>
                    Confirm your email to receive unique regular discounts
                </span>
                <span className={styles.sub_mob}>Confirm your email </span>
            </div>
            <div className={styles.top_btn_wrap}>
                <div className={styles.top_btn} data-testid="confirm-btn">
                    <span>Confirm</span>
                </div>
                <div className={styles.top_close} onClick={onClose} />
            </div>
        </div>
    );
}
