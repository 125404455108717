import { ProfileActionTypes } from '../../constants/profile';
import ProfileApi from '../../../services/api/ProfileApi';
import * as alertAction from '../alert';
import * as ERROR from '../../../constants/error';
import { Dispatch } from 'redux';
import { IRefreshLikedPosts } from '../../reducers/profile/index.interface';

export function getMyProfileData() {
    return function (dispatch: Dispatch<any>) {
        new ProfileApi().getMyProfileInfo().then((res: any) => {
            if (res?.status) {
                return dispatch({
                    type: ProfileActionTypes.GET_PROFILE_DATA,
                    profileData: res?.user_info,
                    counters: res?.counters,
                });
            } else {
                dispatch(alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }));
            }
        });
    };
}

export function saveAnotherProfileData(profileData: any, counters: any) {
    return function (dispatch: Dispatch<{ type: string; profileData: any; counters: any }>) {
        return dispatch({
            type: ProfileActionTypes.GET_PROFILE_DATA,
            profileData,
            counters,
        });
    };
}

export function clearProfileData() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ProfileActionTypes.CLEAR_PROFILE_DATA,
        });
    };
}

export function saveSubscriptionList(list: any) {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ProfileActionTypes.SET_SUBSCRIPTION_LIST,
            list,
        });
    };
}

export function updateSubscribedStatus(external_id: number, status: boolean) {
    return function (dispatch: Dispatch<{ type: string; external_id: number; status: boolean }>) {
        return dispatch({
            type: ProfileActionTypes.UPDATE_SUBSCRIBED_STATUS,
            external_id,
            status,
        });
    };
}

export function updateDocsState(docsState: any) {
    return function (dispatch: Dispatch<{ type: string; docsState: any }>) {
        return dispatch({
            type: ProfileActionTypes.UPDATE_DOCS_STATE,
            docsState,
        });
    };
}

export function updateIsShowStatus(isShow: boolean) {
    return function (dispatch: Dispatch<{ type: string; isShow: boolean }>) {
        return dispatch({
            type: ProfileActionTypes.UPDATE_IS_SHOW_STATUS,
            isShow,
        });
    };
}

export function refreshLikedPosts() {
    return function (dispatch: Dispatch<IRefreshLikedPosts>) {
        return dispatch({
            type: ProfileActionTypes.REFRESH_LIKED_POSTS,
        });
    };
}
