import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { sendBinomoFromStore } from '../../services/binomo';
import * as USER from '../../constants/user';
import { sendAfficeFromStore } from '../../services/affise';
import useGoogleDataLayer from '../event/useGoogleDataLayer';
import MarketingApi from '../../services/api/MarketingApi';
import useFBConversion from '../event/useFBConversion';
import { RootState } from '../../index';

export default function useInitUtmSpend() {
    const userState = useSelector((state: RootState) => state.user);
    const { pushEventSpendAllCredit, pushEventSpendAllCreditFB } = useGoogleDataLayer();
    const { sentFBSpendAllCredits } = useFBConversion();

    useEffect(() => {
        const spendAllCreditStore = localStorage.getItem('spend_all_credit');
        // This applies only to bonuses - free credits
        if (
            userState?.balance < 2 &&
            !spendAllCreditStore &&
            !!userState?.info?.spend_first_credit &&
            !+userState?.info?.payment_total
        ) {
            localStorage.setItem('spend_all_credit', '1');

            new MarketingApi().postCheckExistEvent('spend_all_credit').then((res) => {
                if (res?.status && res?.response?.check) {
                    if (userState?.info) {
                        sendBinomoFromStore({
                            externalID: userState?.info?.external_id,
                            siteID: userState?.info?.site_id,
                            status: USER.BINOMO_SPEND_ALL_CREDITS,
                            clickid: userState?.clickid,
                            utm_aff_hash: userState?.utm_aff_hash,
                            ghost: userState?.info?.ghost,
                            email: userState?.info?.email,
                            tail: userState?.tail,
                        });

                        sendAfficeFromStore({
                            status: USER.AFFISE_SPEND_ALL_CREDITS,
                            info: userState?.info,
                        });
                    }

                    if (userState?.tail?.includes('utm_age=45')) {
                        sentFBSpendAllCredits();
                    }

                    // Push DataLayer for FB
                    pushEventSpendAllCredit();
                    pushEventSpendAllCreditFB();
                }
            });
        }
    }, [userState?.info, userState?.balance]);
}
