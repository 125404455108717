import React, { useEffect, useState } from 'react';
import PaymentApi from '../../../../services/api/v3/PaymentApi';
import styles from './styles.module.scss';
import * as PURCHASE from '../../../../constants/purchase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';
import { ISecurionPayMethodForm, ISecurionPayToken } from './index.interface';

export default function SecurionPayMethodForm({
    publicKey,
    amount,
    orderID,
    getDefaultCreditCard,
    getDefaultCreditCardData,
    openManageCreditCardModal,
}: ISecurionPayMethodForm) {
    const userState = useSelector((state: RootState) => state.user);

    const [errMessage, setErrMessage] = useState('');

    const currentCard = getDefaultCreditCardData(PURCHASE.PAYMENT_METHOD_SECURIONPAY);

    const paymentFormSubmitV2 = () => {
        if (!window?.Shift4) return;
        if (!publicKey) return;

        const shift4 = window?.Shift4(publicKey);
        const form = document?.querySelector('#payment-form') as HTMLFormElement;
        if (form) form.addEventListener('submit', paymentFormSubmit);

        let card: HTMLFormElement | null = null;
        if (!currentCard) card = shift4.createComponentGroup().automount('#payment-form');

        function paymentFormSubmit(event: SubmitEvent) {
            event?.preventDefault();

            const buttons = form?.querySelectorAll('button');
            if (buttons?.length) {
                buttons.forEach(function (button) {
                    button.disabled = true;
                    button.innerText = 'Loading...';
                });
            }

            if (currentCard) return tokenCreatedCallback({ id: currentCard?.token });

            shift4
                .createToken(card, {
                    cardholderName: userState?.info?.name,
                    // addressLine1: $('#addressLine1').val(),
                    // addressLine2: $('#addressLine2').val(),
                    // addressZip,
                    // addressState,
                    // addressCity,
                    // addressCountry,
                    fraudCheckData: {
                        // ...(userState?.info?.is_approve_phone
                        //     ? { phone: userState?.info?.phone }
                        //     : {}),
                        email: userState?.info?.email,
                    },
                })
                .then(tokenCreatedCallback)
                .catch((err: Error) => {
                    setErrMessage(err?.message);
                });
        }

        function tokenCreatedCallback(token: ISecurionPayToken) {
            const request = {
                amount: Math.floor(amount * 100),
                currency: 'USD',
                card: token?.id,
            };

            shift4
                .verifyThreeDSecure(request)
                .then(verifyThreeDSecureCallback)
                .catch((err: Error) => {
                    setErrMessage(err?.message);
                });
        }
    };

    const verifyThreeDSecureCallback = (token: ISecurionPayToken) => {
        if (token?.error) {
            setErrMessage(token?.error?.message);
            return;
        }

        new PaymentApi().postSecurionpayCharge({ orderID, tokenID: token?.id }).then((res) => {
            if (!res?.status) {
                setErrMessage(res?.message);
            }
        });
    };

    useEffect(() => {
        paymentFormSubmitV2();
    }, []);

    return (
        <>
            {currentCard && (
                <div className={`${styles.new_payment_method_chosen_card} ${styles.active}`}>
                    <span>{getDefaultCreditCard(PURCHASE.PAYMENT_METHOD_SECURIONPAY)}</span>
                    <div onClick={openManageCreditCardModal}>Change</div>
                </div>
            )}
            {!!errMessage?.length && (
                <div id="payment-error" className={styles.error_message}>
                    {errMessage}
                </div>
            )}

            <div className={styles.card_container}>
                <div className={styles.form_container}>
                    <form method="post" id="payment-form" className="form-horizontal">
                        {!currentCard && (
                            <>
                                <div data-shift4="number"></div>
                                <div data-shift4="expiry"></div>
                                <div data-shift4="cvc"></div>
                            </>
                        )}

                        <div>
                            <button
                                className={styles.form_submit_btn}
                                type="submit"
                                data-testid="submit-btn"
                            >
                                Buy now
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
