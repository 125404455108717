import React, { MouseEventHandler, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import * as MENU from '../../../../../constants/menu';
import PageApi from '../../../../../services/api/PageApi';
import { useHistory } from 'react-router-dom';
import LoaderGif from '../../../LoaderGif';
import { useSelector } from 'react-redux';
import TermCreditsModal from '../../TermCreditsModal';
import useHandleActiveBlocks from '../../../../../hooks/user/useHandleActiveBlocks';
import { crashCallback } from '../../../../../utils/crashBot';
import { RootState } from '../../../../../index';
import IconCross from '../../../../Icons/IconCross';
import SITE from '../../../../../config/site';

export default function Term({ close }: { close: MouseEventHandler<HTMLButtonElement> }) {
    const termsState = useSelector((state: RootState) => state.terms);
    const history = useHistory();
    const bannersState = useSelector((state: RootState) => state.banners);

    const [page, setPage] = useState<string | null>(null);
    const [title, setTitle] = useState<string>('');
    const [isActiveCreditModal, setIsActiveCreditModal] = useState<boolean>(false);

    const handleActiveBlocks = useHandleActiveBlocks();

    const openCreditsModal = () => setIsActiveCreditModal(true);
    const closeCreditsModal = () => setIsActiveCreditModal(false);

    useEffect(() => {
        if (termsState?.pageName?.length && MENU.ARRAY_STATIC_PAGE.includes(termsState?.pageName)) {
            const newPageName =
                handleActiveBlocks.isHideGift &&
                MENU.ARRAY_STATIC_PAGE_200.includes(termsState?.pageName)
                    ? `${termsState?.pageName}-200`
                    : termsState?.pageName;

            new PageApi()
                .postShow(newPageName, bannersState?.crossBannersData?.target_site_id)
                .then((res) => {
                    if (
                        (res?.status && Array.isArray(res?.result) && !res?.result?.length) ||
                        !res?.result
                    ) {
                        crashCallback(`Page: ${newPageName} not found`);
                        history.push('/not-found');
                    } else if (res?.status) {
                        if (res?.result?.content?.length) setPage(res?.result?.content);
                        if (res?.result?.title?.length) setTitle(res?.result?.title);
                    }
                });
        }
    }, [termsState?.pageName]);

    useEffect(() => {
        if (page) {
            setTimeout(() => {
                const block = document.querySelector('#openCostPolicy');
                if (block) block.addEventListener('click', openCreditsModal);
            }, 0);
        }

        return () => {
            const block = document.querySelector('#openCostPolicy');
            if (block) block.removeEventListener('click', openCreditsModal);
        };
    }, [page, isActiveCreditModal]);

    return title?.length && page?.length ? (
        <div className={styles.term_popup}>
            <h2
                className={`${styles.term_popup_title} ${SITE.ID === 23 ? styles.custom_color : ''}`}
            >
                {title}
            </h2>
            <div
                className={`${styles.term_popup_content} ${SITE.ID === 23 ? styles.custom_color : ''}`}
                dangerouslySetInnerHTML={{ __html: page }}
            />
            <button
                type="button"
                className={`${styles.button_close} ${SITE.ID === 23 ? styles.custom_color : ''}`}
                onClick={close}
                title="Close"
            >
                <IconCross />
            </button>
            <TermCreditsModal
                isActiveCreditModal={isActiveCreditModal}
                closeCreditsModal={closeCreditsModal}
            />
        </div>
    ) : (
        <LoaderGif />
    );
}
