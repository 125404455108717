import Base from '../Base';
import SITE from '../../../config/site';
import { ICreateVideoDisplayErrorData, IDevice } from './ClickHistoryApi.interface';

export default class ClickHistoryApi extends Base {
    async postCheckPush(external_id: number, tail: string, device: IDevice) {
        return super
            .post(`v3/click-history/check-push`, { external_id, tail, device })
            .then((res) => res?.data);
    }

    async postCheckAdrol(external_id: number, tail: string, device: IDevice) {
        return super
            .post(`v3/click-history/check-adrol`, { external_id, tail, device })
            .then((res) => res?.data);
    }

    async postCheckEmailAdrol(external_id: number, tail: string, device: IDevice) {
        return super
            .post(`v3/click-history/check-email-adrol`, { external_id, tail, device })
            .then((res) => res?.data);
    }

    async postCheckSendgrig(external_id: number, tail: string, device: IDevice) {
        return super
            .post(`v3/click-history/check-sendgrig`, { external_id, tail, device })
            .then((res) => res?.data);
    }

    async postCheckCenterEmail(external_id: number, tail: string, device: IDevice) {
        return super
            .post(`v3/click-history/check-center-email`, { external_id, tail, device })
            .then((res) => res?.data);
    }

    async postCheckGoogle(external_id: number, tail: string, device: IDevice) {
        return super
            .post(`v3/click-history/check-google`, { external_id, tail, device })
            .then((res) => res?.data);
    }

    async postCheckTbl(external_id: number, tail: string, device: IDevice) {
        return super
            .post(`v3/click-history/check-tbl`, { external_id, tail, device })
            .then((res) => res?.data);
    }

    async postCheckAffiseClickID(external_id: number) {
        return super
            .post(`v3/click-history/check-affise-click-id`, { external_id })
            .then((res) => res?.data)
            .catch(() => null);
    }

    async postLastActivity(external_id: number, device: IDevice) {
        return super
            .post(`v3/click-history/last-activity`, { external_id, device })
            .then((res) => res?.data);
    }

    async postEvents(external_id: number, event: string) {
        return super
            .post(`v3/click-history/events`, { external_id, event })
            .then((res) => res?.data);
    }

    async postInvite(external_id: number) {
        return super.post(`v3/click-history/invite`, { external_id }).then((res) => res?.data);
    }

    async getCaptchaSettings() {
        return super
            .post(`v3/click-history/get-lnd-captcha-setting`, { lnd: SITE.key, site_id: SITE.ID })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    async getVSettings() {
        return super
            .post(`v3/click-history/get-lnd-verify-setting`, { lnd: SITE.key, site_id: SITE.ID })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    async postVideoDisplayError({
        external_id,
        contentSrc,
        mobileVendor,
        os,
        browser,
        mobile,
        device,
    }: ICreateVideoDisplayErrorData) {
        return super
            .post(`v3/click-history/video-error`, {
                browser,
                contentSrc,
                device,
                external_id,
                height: window.screen.height,
                memory: 'deviceMemory' in navigator ? navigator?.deviceMemory : 0,
                mobile,
                mobileVendor,
                os,
                width: window.screen.width,
            })
            .then((res) => res?.data);
    }
}
