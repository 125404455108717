import * as PURCHASE from '../../../constants/purchase';
import * as MIRROR from '../../../constants/mirror';
import SITE from '../../../config/site';
import { IPurchaseBalance, IPurchaseMethodItem } from './index.interface';

export function getActivePaymentMethodType(type: string, list: IPurchaseMethodItem[]): string {
    const isActiveApplePay = list?.find(
        (item) => item?.method === PURCHASE.PAYMENT_METHOD_APPLEPAY && item?.status,
    )?.status;
    if (window?.ApplePaySession && isActiveApplePay) return PURCHASE.PAYMENT_METHOD_APPLEPAY;

    const firstMethod = list
        ?.filter((item) => item?.method !== PURCHASE.PAYMENT_METHOD_APPLEPAY)
        ?.sort((a, b) => a.order - b.order)
        ?.filter((item) => !!item?.status)?.[0]?.method;
    if (type === PURCHASE.PAYMENT_METHOD_APPLEPAY && !window?.ApplePaySession) return firstMethod;
    return type || firstMethod;
}

export function calcBalance(balance: IPurchaseBalance): number {
    return (
        +balance?.credits +
        +balance?.bonus_credits +
        (+balance?.promo_credits ? +balance?.promo_credits : 0)
    );
}

export function getLegalEntityInfo(): string {
    if (MIRROR.SITE_SEMPITERNA.includes(SITE.ID)) {
        return MIRROR.INFO_SEMPITERNA;
    } else if (MIRROR.SITE_ASTRASOFT.includes(SITE.ID)) {
        return MIRROR.INFO_ASTRASOFT;
    } else if (MIRROR.SITE_KRISTALDIGITAL.includes(SITE.ID)) {
        return MIRROR.INFO_KRISTALDIGITAL;
    } else if (MIRROR.SITE_BROADSTREAM.includes(SITE.ID)) {
        return MIRROR.INFO_BROADSTREAM;
    } else {
        return '';
    }
}

export function getRoutingRefundPolicy(): string {
    return `${window?.location?.origin}/policy?policy_page=return-and-refund-policy`;
}
