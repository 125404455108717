import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as MENU from '../../../../constants/menu';
import MailboxApi from '../../../../services/api/MailboxApi';
import useTransition from '../../../../hooks/transition/useTransition';
import * as currentChatAction from '../../../../store/actions/currentChat';
import * as alertAction from '../../../../store/actions/alert';
import * as ERROR from '../../../../constants/error';
import * as ALERT from '../../../../constants/alert';
import IconChat from '../../../Icons/IconChat';
import { RootState } from '../../../../index';
import { IChatButtonProps } from './index.interface';

export default function ChatButton({
    female_id,
    closeWomanProfileModal,
    isModalOpen,
    // TODO: womanProfileInfo is not passed anywhere
    womanProfileInfo,
}: IChatButtonProps) {
    const dispatch = useDispatch();
    const transition = useTransition();
    const male_id = useSelector((state: RootState) => state.user.info?.external_id);

    const openMiniChat = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (window.innerWidth <= 1024) dispatch(currentChatAction.touchSwipeChatList('left'));

        if (womanProfileInfo?.is_blocked && +womanProfileInfo?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        male_id &&
            new MailboxApi().createChatWithProfile(male_id, female_id).then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    if (isModalOpen && closeWomanProfileModal) closeWomanProfileModal();
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
    };

    return window.innerWidth > 767 ? (
        <button
            type="button"
            className="c3_woman_profile_head_item c3wphi_chat"
            onClick={openMiniChat}
            data-testid="woman-chat-btn"
        >
            <IconChat />
            <span className="c3_woman_profile_head_item_text">Start chatting</span>
        </button>
    ) : (
        <button type="button" className="c3_woman_profile_mob_nav_chat_btn" onClick={openMiniChat}>
            <IconChat />
            <span>Start chatting</span>
        </button>
    );
}
