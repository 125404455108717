import React, { useEffect } from 'react';
import styles from '../../../styles/love-stories/style.module.scss';
import useTransition from '../../../hooks/transition/useTransition';
import SITE from '../../../config/site';

export default function LoveStoriesBanner() {
    const transition = useTransition();

    useEffect(() => {
        const block = document.querySelector('body');

        if (block) block?.classList?.add('love_stories_page');
        return () => {
            if (block) block?.classList?.remove('love_stories_page');
        };
    }, []);

    return (
        <div className={styles.ls_banner_wrap}>
            <div className={styles.ls_banner_bg}>
                <button
                    className={styles.back_to_site_btn}
                    onClick={() => transition.transitionWithOnlyHistoryPush(`/`)}
                >
                    Back to {SITE.name}
                </button>
                <h1 className={styles.ls_banner_title}>Love Stories</h1>
                <h2 className={styles.ls_banner_subtitle}>
                    Our users gladly decided to share their happy love stories
                </h2>
            </div>
        </div>
    );
}
