import React from 'react';

export default function IconFAQHelp() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="11" stroke="#04307B" strokeWidth="2" />
            <path
                d="M10.7995 15.002C10.3049 14.4438 10.0576 13.7616 10.0576 12.9554C10.0576 12.3353 10.23 11.7771 10.5747 11.281C10.9194 10.7849 11.2941 10.4128 11.6988 10.1647C12.1035 9.91668 12.4782 9.62211 12.823 9.28102C13.1677 8.93994 13.34 8.59885 13.34 8.25777C13.34 7.87017 13.1902 7.55234 12.8904 7.30428C12.6056 7.05622 12.171 6.93219 11.5864 6.93219C10.5222 6.93219 9.60047 7.41281 8.82108 8.37405L7 6.25777C8.24403 4.75389 9.88525 4.00195 11.9237 4.00195C13.3625 4.00195 14.5016 4.34304 15.341 5.02521C16.1803 5.69188 16.6 6.5756 16.6 7.67637C16.6 8.29653 16.4726 8.85467 16.2178 9.35079C15.963 9.84691 15.6482 10.25 15.2735 10.5601C14.9138 10.8702 14.5466 11.157 14.1719 11.4206C13.7972 11.6841 13.4824 11.9709 13.2276 12.281C12.9728 12.5911 12.8454 12.9244 12.8454 13.281C12.8454 13.6841 12.9803 14.0097 13.2501 14.2578L10.7995 15.002ZM13.1602 19.4438C12.8005 19.8159 12.3733 20.002 11.8787 20.002C11.3841 20.002 10.9569 19.8159 10.5972 19.4438C10.2375 19.0717 10.0576 18.6299 10.0576 18.1182C10.0576 17.6066 10.2375 17.1647 10.5972 16.7927C10.9569 16.4206 11.3841 16.2345 11.8787 16.2345C12.3733 16.2345 12.8005 16.4206 13.1602 16.7927C13.5199 17.1647 13.6998 17.6066 13.6998 18.1182C13.6998 18.6299 13.5199 19.0717 13.1602 19.4438Z"
                fill="#04307B"
            />
        </svg>
    );
}
