export const localStorageGetItem = <T>(key: string, parseJson = false): T | string | null => {
    const item = localStorage.getItem(key);
    if (!item) return null;

    if (parseJson) {
        try {
            return JSON.parse(item) as T;
        } catch (e) {
            return null;
        }
    }
    return item;
};

export const localStorageSetItem = (key: string, value: string, jsonStringify = false) => {
    if (jsonStringify) {
        return localStorage.setItem(key, JSON.stringify(value));
    }
    return localStorage.setItem(key, value);
};
