import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as MENU from '../../constants/menu';
import SITE from '../../config/site';
import PageApi from '../../services/api/PageApi';
import styles from '../../components/Policy/styles.module.scss';
import * as termsAction from '../../store/actions/terms';
import useTransition from '../transition/useTransition';
import { getUrlParameter } from '../../utils/url-utils';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { RootState } from '../../index';
import { crashCallback } from '../../utils/crashBot';

export default function useInitTermsPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const transition = useTransition();
    const termsState = useSelector((state: RootState) => state.terms);
    const mobileScreenWidth = SITE.ID === 23 ? 1140 : 768;

    const [page, setPage] = useState('');
    const [title, setTitle] = useState('');
    const [policyOpen, setPolicyOpen] = useState(false);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isActiveCreditModal, setIsActiveCreditModal] = useState(false);

    const handleActiveBlocks = useHandleActiveBlocks();
    const isSpecialStaticPage =
        handleActiveBlocks.isHideGift &&
        MENU.ARRAY_STATIC_PAGE_200.includes(termsState?.pageName as string);

    const openCreditsModal = () => setIsActiveCreditModal(true);
    const closeCreditsModal = () => setIsActiveCreditModal(false);

    useEffect(() => {
        if (termsState?.pageName?.length && MENU.ARRAY_STATIC_PAGE.includes(termsState?.pageName)) {
            const newPageName = isSpecialStaticPage
                ? `${termsState?.pageName}-200`
                : termsState?.pageName;
            new PageApi().postShow(newPageName).then((res) => {
                if (
                    (res?.status && Array.isArray(res?.result) && !res?.result?.length) ||
                    !res?.result
                ) {
                    crashCallback(`Page: ${newPageName} not found`);
                    history.push('/not-found');
                } else if (res?.status) {
                    if (res?.result?.content?.length) setPage(res?.result?.content);
                    if (res?.result?.title?.length) setTitle(res?.result?.title);

                    const innerTags = document.querySelectorAll(`.${styles.policy_content} *`);
                    innerTags.forEach(function (el) {
                        el.removeAttribute('style');
                    });
                }
            });
        }
    }, [termsState?.pageName]);

    useEffect(() => {
        if (page) {
            setTimeout(() => {
                const block = document?.querySelector('#openCostPolicy');
                if (block) block?.addEventListener('click', openCreditsModal);
            }, 0);
        }

        return () => {
            const block = document?.querySelector('#openCostPolicy');
            if (block) block?.removeEventListener('click', openCreditsModal);
        };
    }, [page, isActiveCreditModal]);

    const changePolicyPage = (pageName: string) => {
        if (pageName !== termsState?.pageName) {
            setPage('');
            setTitle('');
            setPolicyOpen(true);
            dispatch(termsAction.openPage(pageName));
        }
        setIsMobileOpen(true);
    };

    const goBackPolicyPageMob = () => {
        setPage('');
        setTitle('');
        setPolicyOpen(false);
        setIsMobileOpen(false);
        transition.transitionWithOnlyHistoryPush('/policy');
        dispatch(termsAction.closeModal());
    };

    useEffect(() => {
        const policyPageNameUrl = getUrlParameter('policy_page');
        const isPolicyPage = window?.location?.pathname?.includes('/policy');
        const isMobile = +window?.innerWidth < mobileScreenWidth;

        if (termsState?.pageName && isPolicyPage) {
            dispatch(termsAction.openPage(termsState?.pageName));
            transition.addTail(`?policy_page=${termsState?.pageName}`);
        } else if (policyPageNameUrl && isPolicyPage) {
            dispatch(termsAction.openPage(policyPageNameUrl as string));
        } else if (!policyPageNameUrl && isPolicyPage && !isMobile) {
            transition.addTail(`?policy_page=${MENU.LINK_TERMS}`);
            dispatch(termsAction.openPage(MENU.LINK_TERMS));
        }

        if (termsState?.pageName) {
            setPolicyOpen(true);
            setIsMobileOpen(true);
        }
    }, [termsState?.pageName]);

    useEffect(() => {
        return () => {
            if (+window?.innerWidth < mobileScreenWidth) {
                dispatch(termsAction.closeModal());
            }
        };
    }, []);

    return {
        isActiveCreditModal,
        closeCreditsModal,
        pageName: termsState?.pageName,
        changePolicyPage,
        isMobileOpen,
        goBackPolicyPageMob,
        page,
        title,
        policyOpen,
    };
}
