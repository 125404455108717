import React from 'react';
import DoubleSelect from '../../../common/select/DoubleSelectNew';
import * as DICTIONARY_CREATE_PROFILE_PAGE from '../../../../constants/dictionary/create-profile-page';
import { sequenceArray } from '../../../../services/time/DateUtils';
import { useSelector } from 'react-redux';
import LargeSelect from '../../../common/select/LargeSelectNew';
import { RootState } from '../../../../index';
import { LIKE_FILTERS_DEFAULT } from '../../../../constants/filter';

interface ILikeFilter {
    filter: typeof LIKE_FILTERS_DEFAULT;
    setFilter: (value: typeof LIKE_FILTERS_DEFAULT) => void;
}

export default function LikeFilter({ filter, setFilter }: ILikeFilter) {
    const referenciesList = useSelector((state: RootState) => state.referencies.list);

    const setFilterField = async (field: string, value: any) => {
        const newFilter = { ...filter, [field]: value };

        setFilter(newFilter);
    };

    return (
        <React.Fragment>
            <div className="fill_data_form_select_list">
                <DoubleSelect
                    formDataFrom={filter?.from}
                    formDataTo={filter?.to}
                    handleClickFrom={(value) => setFilterField('from', value)}
                    handleClickTo={(value) => setFilterField('to', value)}
                    arrayFieldsFrom={['', ...sequenceArray(18, 70), '70+']}
                    arrayFieldsTo={['', ...sequenceArray(18, 70), '70+']}
                    defaultNameFrom={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_FROM}
                    defaultNameTo={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_TO}
                    selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_AGE}
                    dataTestId="age"
                />
                <DoubleSelect
                    index="height"
                    formDataFrom={filter?.heightFrom}
                    formDataTo={filter?.heightTo}
                    handleClickFrom={(value) => setFilterField('heightFrom', value)}
                    handleClickTo={(value) => setFilterField('heightTo', value)}
                    arrayFieldsFrom={referenciesList?.height_list}
                    arrayFieldsTo={referenciesList?.height_list}
                    defaultNameFrom={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_FROM}
                    defaultNameTo={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_TO}
                    selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_HEIGHT}
                    dataTestId="height"
                />
                <LargeSelect
                    index="body"
                    formData={filter?.bodyType ?? ''}
                    handleClick={(value) => setFilterField('bodyType', value?.id)}
                    arrayFields={referenciesList?.body_list}
                    defaultName={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE}
                    selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_BODY}
                    dataTestId="body"
                />
                <LargeSelect
                    index="hair"
                    formData={filter?.hairColor ?? ''}
                    handleClick={(value) => setFilterField('hairColor', value?.id)}
                    arrayFields={referenciesList?.hair_list}
                    defaultName={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_CHOOSE}
                    selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_HAIR}
                    dataTestId="hair"
                />
                <LargeSelect
                    index="eye"
                    formData={filter?.eyeColor ?? ''}
                    handleClick={(value) => setFilterField('eyeColor', value?.id)}
                    arrayFields={referenciesList?.eye_list}
                    defaultName={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE}
                    selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_EYE}
                    dataTestId="eye"
                />
                <LargeSelect
                    index="marital"
                    formData={filter?.maritalStatus ?? ''}
                    handleClick={(value) => setFilterField('maritalStatus', value?.id)}
                    arrayFields={referenciesList?.marital_list}
                    defaultName={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_CHOOSE}
                    selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_MARITAL}
                    dataTestId="marital"
                />
            </div>
        </React.Fragment>
    );
}
