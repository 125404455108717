import useSubmitSupport from './useSubmitSupport';
import { ChangeEvent, useState } from 'react';
import * as LENGTH from '../../constants/length';
import * as alertAction from '../../store/actions/alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../index';
import * as ATTACH from '../../constants/attach';

export interface IFormDataSupport {
    email: string;
    file?: File;
    reason: string;
    name: string;
}

export default function useInitSupport() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const initFormDataSupport = {
        email: userState?.info?.email ?? '',
        file: undefined,
        reason: '',
        name: '',
    };

    const [formDataSupport, setFormData] = useState<IFormDataSupport>(initFormDataSupport);
    const [modalIsActiveSupport, setModalIsActiveSupport] = useState(false);

    const deleteUploadedFileSupport = () => {
        setFormData({ ...formDataSupport, ...{ file: undefined } });
    };

    const handleInputSupport = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, type, name } = event.target;
        const fileType = event?.target?.files?.[0] && event?.target?.files?.[0].type.split('/');
        if (type === 'file') {
            if (
                event?.target?.files?.[0] &&
                event?.target?.files?.[0]?.size > LENGTH.SUPPORT_MAX_IMG_SIZE
            ) {
                dispatch(
                    alertAction.setMessage({
                        message: `File you’ve uploaded is too big \n(max ${LENGTH.SUPPORT_MAX_IMG_SIZE_TITLE}Mb)`,
                    }),
                );
            } else if (fileType && fileType[0] !== ATTACH.TYPE_IMAGE && fileType[1] !== 'pdf') {
                dispatch(
                    alertAction.setMessage({
                        title: `Invalid file`,
                        message: `Only PDF, PNG, JPG, or JPEG under ${LENGTH.SUPPORT_MAX_IMG_SIZE_TITLE}MB allowed. Please try another file.`,
                    }),
                );
            } else {
                return setFormData({ ...formDataSupport, ...{ file: event?.target?.files?.[0] } });
            }
        }

        return setFormData({ ...formDataSupport, ...{ [name]: value } });
    };

    const openSupportModal = () => {
        setModalIsActiveSupport(true);
    };

    const closeSupportModal = () => {
        setModalIsActiveSupport(false);
        setFormData(initFormDataSupport);
    };

    const onSubmitSupport = useSubmitSupport(formDataSupport, closeSupportModal);

    return {
        modalIsActiveSupport,
        formDataSupport,
        deleteUploadedFileSupport,
        handleInputSupport,
        closeSupportModal,
        openSupportModal,
        onSubmitSupport,
    };
}
