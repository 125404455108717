import { WinkActionTypes } from '../../constants/wink';
import { IWinkState, WinkActions } from './index.interface';

const initState = {
    sayHelloList: [],
};

export default function (state: IWinkState = initState, action: WinkActions): IWinkState {
    switch (action.type) {
        case WinkActionTypes.GET_SAY_HELLO_LIST:
            return { ...state, sayHelloList: action?.sayHelloList };

        default:
            return state;
    }
}
