import modalStyleProps from '../../../../styles/react-modal/modal.style';
import WomanProfile from './WomanProfile';
import Modal from 'react-modal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as modalsAction from '../../../../store/actions/modals';
import * as womanAction from '../../../../store/actions/woman';
import { RootState } from '../../../../index';

export default function WomanProfileModal() {
    const dispatch = useDispatch();
    const modalsState = useSelector((state: RootState) => state.modals);

    const closeWomanProfileModal = () => {
        dispatch(modalsAction.closeWomanProfileModal());
        dispatch(modalsAction.closeWomanVideoShowModal());
        dispatch(womanAction.saveSimilarWomanList([]));
    };

    return (
        <Modal
            style={modalStyleProps({ isProfile: true })}
            isOpen={modalsState?.womanProfileModal?.isActive}
            onRequestClose={closeWomanProfileModal}
            ariaHideApp={false}
        >
            {!!modalsState?.womanProfileModal?.isActive && (
                <WomanProfile
                    closeWomanProfileModal={closeWomanProfileModal}
                    isModalOpen={modalsState?.womanProfileModal?.isActive}
                />
            )}
        </Modal>
    );
}
