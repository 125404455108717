import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ContactRequest from '../../components/Profile/ProfileRequestContact';
import * as menuAction from '../../store/actions/menu';

export default function ProfileRequestContactPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuAction.setMainMenu('my-profile/contact-request'));
    }, []);

    return (
        <>
            <ContactRequest />
        </>
    );
}
