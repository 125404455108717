import { ModalsActionTypes } from '../../constants/modals';
import { Dispatch } from 'redux';
import {
    ILessThanCreditsMessageAction,
    ILessThanCreditsMessageParams,
    IOpenMediaContentModalParams,
    IWomanGalleryImage,
} from './index.interface';
import { localStorageSetItem } from '../../../utils/local-storage';
import { IPost } from '../../reducers/posts/index.interface';
import { IOpenReplyModalAction } from '../../reducers/modals/index.interface';

export function openEmailEdit() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.SHOW_EMAIL_EDIT_MODAL,
        });
    };
}

export function closeEmailEdit() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_EMAIL_EDIT_MODAL,
        });
    };
}

export function openAddCreditCard() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.SHOW_ADD_CREDIT_CARD_MODAL,
        });
    };
}

export function closeAddCreditCard() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_ADD_CREDIT_CARD_MODAL,
        });
    };
}

export function setActiveSubMenu(tabName = '') {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: ModalsActionTypes.ACTIVE_SUB_MENU,
            data: tabName,
        });
    };
}

export function openWomanProfileModal(externalID: number) {
    return function (dispatch: Dispatch<{ type: string; externalID: number }>) {
        return dispatch({
            type: ModalsActionTypes.OPEN_WOMAN_PROFILE_MODAL,
            externalID,
        });
    };
}

export function closeWomanProfileModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_WOMAN_PROFILE_MODAL,
        });
    };
}

export function openWomanVideoShowModal(externalID: number) {
    return function (dispatch: Dispatch<{ type: string; externalID: number }>) {
        return dispatch({
            type: ModalsActionTypes.OPEN_WOMAN_VIDEO_SHOW_MODAL,
            externalID,
        });
    };
}

export function closeWomanVideoShowModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_WOMAN_VIDEO_SHOW_MODAL,
        });
    };
}

export function openWomanGalleryModal(
    externalID: number,
    galleryImg: IWomanGalleryImage[] = [],
    currentPhotoIndex = 0,
) {
    return function (
        dispatch: Dispatch<{
            type: string;
            externalID: number;
            galleryImg: IWomanGalleryImage[];
            currentPhotoIndex: number;
        }>,
    ) {
        return dispatch({
            type: ModalsActionTypes.OPEN_WOMAN_GALLERY_MODAL,
            externalID,
            currentPhotoIndex,
            galleryImg,
        });
    };
}

export function closeWomanGalleryModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_WOMAN_GALLERY_MODAL,
        });
    };
}

export function openVideoContentModal(item: IOpenMediaContentModalParams) {
    return function (dispatch: Dispatch<{ type: string; item: IOpenMediaContentModalParams }>) {
        return dispatch({
            type: ModalsActionTypes.OPEN_VIDEO_CONTENT_MODAL,
            item,
        });
    };
}

export function closeVideoContentModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_VIDEO_CONTENT_MODAL,
        });
    };
}

export function openPhotoContentModal(item: IOpenMediaContentModalParams) {
    return function (dispatch: Dispatch<{ type: string; item: IOpenMediaContentModalParams }>) {
        return dispatch({
            type: ModalsActionTypes.OPEN_PHOTO_CONTENT_MODAL,
            item,
        });
    };
}

export function closePhotoContentModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_PHOTO_CONTENT_MODAL,
        });
    };
}

export function openSendberlyGalleryModal(
    profile: any,
    currentPhotoIndex = 0,
    photosList: any[] = [],
) {
    return function (
        dispatch: Dispatch<{
            type: string;
            profile: any;
            currentPhotoIndex: number;
            photosList: any[];
        }>,
    ) {
        return dispatch({
            type: ModalsActionTypes.OPEN_SENDNERLY_GALLERY_MODAL,
            photosList,
            profile,
            currentPhotoIndex,
        });
    };
}

export function closeSendberlyGalleryModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_SENDNERLY_GALLERY_MODAL,
        });
    };
}

// TODO: unused action
export function openPaymentRoutingModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.OPEN_PAYMENT_ROUTING_MODAL });
    };
}

export function closePaymentRoutingModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.CLOSE_PAYMENT_ROUTING_MODAL });
    };
}

export function openLessThenCreditsMessage({
    message,
    title,
    packId,
    time,
}: ILessThanCreditsMessageParams) {
    const expDate = new Date();
    expDate.setMinutes(expDate.getMinutes() + +time);
    localStorageSetItem('less-then', JSON.stringify(expDate));
    return function (dispatch: Dispatch<ILessThanCreditsMessageAction>) {
        return dispatch({
            type: ModalsActionTypes.OPEN_LESS_THEN_CREDITS,
            message,
            title,
            packId,
        });
    };
}

export function closeLessThenCreditsMessage() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.CLOSE_LESS_THEN_CREDITS });
    };
}

export function openWelcomeModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.OPEN_WELCOME_MODAL });
    };
}

export function closeWelcomeModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.CLOSE_WELCOME_MODAL });
    };
}

export function openNewFeatureModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.OPEN_NEW_FEATURE_MODAL });
    };
}

export function closeNewFeatureModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.CLOSE_NEW_FEATURE_MODAL });
    };
}

export function openReplyModal(
    post: IPost,
    openCurrentPost: ((value: number) => void) | null = null,
) {
    return function (dispatch: Dispatch<IOpenReplyModalAction>) {
        return dispatch({ type: ModalsActionTypes.OPEN_REPLY_MODAL, post, openCurrentPost });
    };
}

export function closeReplyModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.CLOSE_REPLY_MODAL });
    };
}

export function openSuccessReplyModal(goToChat: () => void, goToNewsFeed: () => void) {
    return function (
        dispatch: Dispatch<{
            type: string;
            goToChat: () => void;
            goToNewsFeed: () => void;
        }>,
    ) {
        return dispatch({
            type: ModalsActionTypes.OPEN_SUCCESS_REPLY_MODAL,
            goToChat,
            goToNewsFeed,
        });
    };
}

export function closeSuccessReplyModal() {
    return function (dispatch: Dispatch) {
        return dispatch({ type: ModalsActionTypes.CLOSE_SUCCESS_REPLY_MODAL });
    };
}

export function openProfileIdentificationModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.OPEN_PROFILE_IDENTIFICATION_MODAL,
        });
    };
}

export function closeProfileIdentificationModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ModalsActionTypes.CLOSE_PROFILE_IDENTIFICATION_MODAL,
        });
    };
}
