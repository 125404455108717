import React from 'react';

export default function IconPolicyPayment() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#00317B"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M14.326 9.0467H2.6977C2.31629 9.0467 2 8.73041 2 8.349C2 7.9676 2.31629 7.65131 2.6977 7.65131H14.326C14.7074 7.65131 15.0237 7.9676 15.0237 8.349C15.0237 8.73041 14.7074 9.0467 14.326 9.0467Z" />
            <path d="M8.27917 16.4888H6.41864C6.03724 16.4888 5.72095 16.1725 5.72095 15.7911C5.72095 15.4097 6.03724 15.0934 6.41864 15.0934H8.27917C8.66057 15.0934 8.97686 15.4097 8.97686 15.7911C8.97686 16.1725 8.66057 16.4888 8.27917 16.4888Z" />
            <path d="M14.326 16.4888H10.6049C10.2235 16.4888 9.90723 16.1725 9.90723 15.7911C9.90723 15.4097 10.2235 15.0934 10.6049 15.0934H14.326C14.7074 15.0934 15.0237 15.4097 15.0237 15.7911C15.0237 16.1725 14.7074 16.4888 14.326 16.4888Z" />
            <path d="M17.1726 20.2098H6.82806C3.12562 20.2098 2 19.0935 2 15.4283V7.78154C2 4.11631 3.12562 3 6.82806 3H14.326C14.7074 3 15.0237 3.31629 15.0237 3.6977C15.0237 4.0791 14.7074 4.39539 14.326 4.39539H6.82806C3.90704 4.39539 3.39539 4.89773 3.39539 7.78154V15.419C3.39539 18.3028 3.90704 18.8051 6.82806 18.8051H17.1633C20.0843 18.8051 20.5959 18.3028 20.5959 15.419V13.484C20.5959 13.1026 20.9122 12.7864 21.2936 12.7864C21.675 12.7864 21.9913 13.1026 21.9913 13.484V15.419C22.0006 19.0935 20.875 20.2098 17.1726 20.2098Z" />
            <path d="M18.7486 11.28C18.8582 11.3269 18.9857 11.2878 19.0497 11.1866L21.9624 6.55861C22.0096 6.4837 22.0124 6.38912 21.9698 6.31185C21.9271 6.23434 21.8456 6.18621 21.7571 6.18621H19.8132L20.5361 3.31701C20.5653 3.2011 20.5063 3.08138 20.3965 3.03421C20.2875 2.98729 20.1593 3.02663 20.0955 3.1276L17.1827 7.75563C17.1356 7.83054 17.1327 7.92512 17.1754 8.00239C17.2181 8.0799 17.2996 8.12803 17.388 8.12803H19.332L18.609 10.9972C18.5799 11.1132 18.6389 11.2329 18.7486 11.28Z" />
        </svg>
    );
}
