import ChatsAPI from '../../../services/api/ChatsApi';
import { AllMediaActionTypes } from '../../constants/allMedia';
import { Dispatch } from 'redux';

export function setAllMediaList(payload: string) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new ChatsAPI().getHistoryMedia(payload).then((res) => {
            return dispatch({
                type: AllMediaActionTypes.SET_ALL_MEDIA_LIST,
                data: res?.response,
            });
        });
    };
}

export function updateAllMediaList(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: AllMediaActionTypes.UPDATE_ALL_MEDIA_LIST,
            data,
        });
    };
}

export function setBuyedMedia(messageId: number) {
    return function (dispatch: Dispatch<{ type: string; messageId: number }>) {
        return dispatch({
            type: AllMediaActionTypes.SET_BUYED_MEDIA,
            messageId,
        });
    };
}

export function setChatMediaListByPage(page: number) {
    return function (dispatch: Dispatch<{ type: string; page: number }>) {
        return dispatch({
            type: AllMediaActionTypes.SET_MEDIA_LIST_BY_PAGE,
            page,
        });
    };
}

export function resetChatMediaListByPage() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: AllMediaActionTypes.RESET_MEDIA_LIST_BY_PAGE,
        });
    };
}

export function setMediaListType(mediaListName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: AllMediaActionTypes.SET_MEDIA_LIST_TYPE,
            data: mediaListName,
        });
    };
}

export function setMediaListPaid(mediaListPaidName: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: AllMediaActionTypes.SET_MEDIA_LIST_PAID,
            data: mediaListPaidName,
        });
    };
}

export function resetAllMediaList() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: AllMediaActionTypes.RESET_ALL_MEDIA_LIST,
        });
    };
}
