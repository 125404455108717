import React from 'react';
import styles from './styles.module.scss';
import Slider from 'react-slick';
import SliderItem from './SliderItem';
import { SLIDER_SETTINGS } from '../../../constants/slider';
import { ITopProfile } from '../../../services/api/v3/SearchApi.interface';

interface IGirlsSliderProps {
    profiles: ITopProfile[];
    openGalleryModal: (id: number, images: any) => void;
    openChat: (id: number) => void;
    sliderRef: React.MutableRefObject<Slider | null>;
    onNextSlide: () => void;
    onPrevSlide: () => void;
}

export default function GirlsSlider({
    profiles,
    sliderRef,
    openChat,
    openGalleryModal,
    onPrevSlide,
    onNextSlide,
}: IGirlsSliderProps) {
    return (
        <>
            <div className={`${styles.girls_slider_wrap} girls_slider_wrap_global`}>
                <div className={`${styles.girls_slider_info}`}>
                    <h3 className={styles.girls_slider_title}>Members that you liked</h3>
                    <p className={styles.girls_slider_text}>
                        You might be interested in those members
                    </p>
                </div>
                <div className={`${styles.girls_slider_list_wrap} girls_slider_list_wrap_global`}>
                    <div className={styles.girls_slider_list}>
                        <Slider {...SLIDER_SETTINGS} ref={sliderRef}>
                            {profiles?.length &&
                                profiles.map((profile, key) => (
                                    <SliderItem
                                        key={key + profile?.id}
                                        profile={profile}
                                        openChat={openChat}
                                        openGalleryModal={openGalleryModal}
                                    />
                                ))}
                        </Slider>
                    </div>
                </div>
                {/*<div className={styles.girls_slider_btn_buttons}>*/}
                <div
                    className={`${styles.girls_slider_btn} ${styles.girls_slider_prev_btn}`}
                    onClick={onPrevSlide}
                />
                <div
                    className={`${styles.girls_slider_btn} ${styles.girls_slider_next_btn}`}
                    onClick={onNextSlide}
                />
                {/*</div>*/}
            </div>
        </>
    );
}
