import React from 'react';

export default function IconPolicyTerms() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#D35B00"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.971 2.01189C14.9283 2.00408 14.8843 2 14.8394 2C14.7944 2 14.7504 2.00408 14.7077 2.01189H8.52958C6.07287 2.00306 4.05902 3.99949 4.00112 6.50348L4.00092 6.52127V17.2991C3.95121 19.8392 5.92782 21.9428 8.42088 21.9994L8.43736 21.9996L8.45477 21.9997L8.47672 21.9999C8.4922 22 8.51364 22.0001 8.53812 21.9996H16.4184C16.4284 21.9996 16.4384 21.9993 16.4484 21.9989C18.9146 21.8969 20.8582 19.8206 20.842 17.3043V8.07876C20.842 7.88383 20.7681 7.69646 20.6356 7.55584L15.6333 2.24405C15.4936 2.09573 15.3006 2.01189 15.0989 2.01189H14.971ZM14.0986 3.52206H8.52822H8.52537C6.87696 3.5156 5.52556 4.85288 5.48236 6.53064V17.3069L5.48219 17.3234C5.44569 19.0324 6.77331 20.4487 8.4488 20.4894L8.46772 20.4896L8.48886 20.4898L8.50087 20.4898L8.5036 20.4898C8.51156 20.4895 8.52026 20.4894 8.52822 20.4894H16.4026C18.0649 20.4129 19.3725 19.0104 19.3606 17.3123L19.3605 17.3069L19.3606 9.03137H17.3756L17.3735 9.03137C15.5629 9.02617 14.0986 7.52974 14.0986 5.68382V3.52206ZM18.5494 7.5212H17.3768C16.3829 7.51784 15.5801 6.69685 15.5801 5.68382V4.36821L18.5494 7.5212ZM8.58082 15.4491C8.58082 15.0321 8.91245 14.694 9.32154 14.694H14.6547C15.0638 14.694 15.3955 15.0321 15.3955 15.4491C15.3955 15.8662 15.0638 16.2042 14.6547 16.2042H9.32154C8.91245 16.2042 8.58082 15.8662 8.58082 15.4491ZM9.32058 10.9161C8.91149 10.9161 8.57985 11.2542 8.57985 11.6712C8.57985 12.0882 8.91149 12.4263 9.32058 12.4263H12.6351C13.0442 12.4263 13.3758 12.0882 13.3758 11.6712C13.3758 11.2542 13.0442 10.9161 12.6351 10.9161H9.32058Z"
            />
        </svg>
    );
}
