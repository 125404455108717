import React from 'react';
import '../../styles/mp-video.scss';
import '../../styles/mob_bottom_nav.scss';
import Banner from '../../components/Banner';
import VideoShowFooter from '../../components/video-show/VideoShowFooter';
import VideoShowList from '../../components/video-show/VideoShowList';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import SearchFiltersV3 from '../../components/search/SearchFiltersV3';
import { useDispatch, useSelector } from 'react-redux';
import * as videoShowAction from '../../store/actions/videoShow';
import SearchFiltersV2 from '../../components/search/SearchFiltersV2';
import { RootState } from '../../index';
import { VIDEO_SHOW_FILTERS_DEFAULT } from '../../constants/search';

export default function VideoShowPage() {
    const handleActiveBlocks = useHandleActiveBlocks();
    const videoShowState = useSelector((state: RootState) => state.videoShow);

    const dispatch = useDispatch();

    // if (!handleActiveBlocks.isVideoShow) return <div />;
    return (
        <div className="column-3 likeher c3_fx">
            <Banner />
            <div className="c3mp_videos_page_wrap">
                {handleActiveBlocks.isSearchV3 ? (
                    <SearchFiltersV3<typeof VIDEO_SHOW_FILTERS_DEFAULT>
                        filter={videoShowState?.filter}
                        setFilter={(value) =>
                            dispatch(videoShowAction.setSVideoShowListFilter(value))
                        }
                        isLoadingPage={videoShowState?.isLoadingPage}
                    />
                ) : (
                    <SearchFiltersV2<typeof VIDEO_SHOW_FILTERS_DEFAULT>
                        filter={videoShowState?.filter}
                        setFilter={(value) =>
                            dispatch(videoShowAction.setSVideoShowListFilter(value))
                        }
                        isLoadingPage={videoShowState?.isLoadingPage}
                    />
                )}
                <VideoShowFooter />
                <VideoShowList />
            </div>
        </div>
    );
}
