import React, { useState } from 'react';
import DnDContainer from '../DragAndDrop/Container';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import VideoContentAttachModal from '../../Modals/ContentAttachModal/VideoContent';
import Modal from 'react-modal';
import Loader from '../Loader';
import { IListVideosProps } from './index.interface';
import { IProfileGiftCurrentItem } from '../../../Profile/ProfileGifts/index.interface';
import EmptyFilesList from '../EmptyFilesList';

export default function ListVideos({ type, videos, status }: IListVideosProps) {
    const [modalContentIsOpen, setModalContentIsOpen] = useState<boolean>(false);
    const [currItem, setCurrItem] = useState<IProfileGiftCurrentItem | object>({});

    const closeContentModal = () => {
        setCurrItem({});
        setModalContentIsOpen(false);
    };

    return (
        <>
            {!videos?.length && !status ? (
                <EmptyFilesList />
            ) : (
                <div
                    className="upload_popup_tabs_content_middle video"
                    id="uptcmv"
                    data-testid="file-list"
                >
                    <DnDContainer
                        type={type}
                        data={videos}
                        setModalContentIsOpen={setModalContentIsOpen}
                        setCurrItem={setCurrItem}
                    />
                    <Loader status={status} />
                    <Modal
                        style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                        isOpen={modalContentIsOpen}
                        onRequestClose={closeContentModal}
                        ariaHideApp={false}
                    >
                        {/* @ts-expect-error videoContentModal props */}
                        <VideoContentAttachModal item={currItem} closeModal={closeContentModal} />
                    </Modal>
                </div>
            )}
        </>
    );
}
