import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import '../../../styles/audio-player/attach-style.scss';
import { sendErrToSentry } from '../../../services/sentry';

interface IAudioEventHandler {
    (event: React.SyntheticEvent<HTMLAudioElement>): void;
}

export default function AttachAudioPlayer({ src }: { src: string }) {
    const onPlayHandler: IAudioEventHandler = (e) => {
        try {
            const activeAudioBlock = document.querySelector(
                '.upload_popup_tabs_content_audio_item.playing',
            );
            if (activeAudioBlock) {
                const audio = activeAudioBlock.querySelector('audio');
                activeAudioBlock.classList.remove('playing');
                audio?.pause();
            }
            // @ts-expect-error classList
            e.currentTarget?.parentNode?.parentNode?.parentNode?.parentNode?.classList?.add(
                'playing',
            );
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const onPauseHandler: IAudioEventHandler = () => {
        try {
            const activeAudioBlock = document.querySelector(
                '.upload_popup_tabs_content_audio_item.playing',
            );
            if (activeAudioBlock) {
                const audio = activeAudioBlock.querySelector('audio');
                activeAudioBlock.classList.remove('playing');
                audio?.pause();
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    return (
        // @ts-expect-error audioPlayer
        <AudioPlayer
            src={src}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            customIcons={{
                play: <img src="../../../img/popup-play-played.svg" alt="" />,
                pause: <img src="../../../img/stop_in_attach_file.svg" alt="" />,
                volume: <img src="../../../img/audio-volume.svg" alt="" />,
                volumeMute: <img src="../../../img/chat-audio-volume-mute.svg" alt="" />,
            }}
        />
    );
}
