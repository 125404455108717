import { ConfigActionTypes } from '../../constants/config';
import { ConfigActions, IConfigState } from './index.interface';

const initState = {
    data: null,
};

export default function (state: IConfigState = initState, action: ConfigActions): IConfigState {
    switch (action.type) {
        case ConfigActionTypes.INIT_CONFIG:
            return {
                ...state,
                ...{
                    data: action?.data,
                },
            };
        default:
            return state;
    }
}
