import { PostActionTypes } from '../../constants/posts';
import { IPostsState, PostsActions } from './index.interface';

const initState = {
    posts: [],
    allPages: 0,
    page: 0,
};

export default function (state: IPostsState = initState, action: PostsActions): IPostsState {
    switch (action.type) {
        case PostActionTypes.CLEAR_POSTS_DATA:
            return initState;

        case PostActionTypes.GET_ALL_POSTS:
            return {
                ...state,
                ...{
                    posts: [...state.posts, ...(action?.data?.posts ? action.data.posts : [])],
                    allPages: action?.data?.allPages,
                    page: action?.data?.page,
                },
            };

        case PostActionTypes.UPDATE_CURRENT_POST:
            return {
                ...state,
                ...{
                    posts: state.posts.map((item) =>
                        item?.id === action?.data?.id ? action?.data : item,
                    ),
                },
            };

        case PostActionTypes.UPDATE_SUBSCRIBE_STATUS:
            return {
                ...state,
                ...{
                    posts: state.posts.map((item) => {
                        if (item?.user?.externalId === action?.externalId) {
                            return { ...item, ...{ subscribed: action?.status } };
                        } else {
                            return item;
                        }
                    }),
                },
            };

        default:
            return state;
    }
}
