export enum AllMediaActionTypes {
    SET_ALL_MEDIA_LIST = 'SET_ALL_MEDIA_LIST',
    UPDATE_ALL_MEDIA_LIST = 'UPDATE_ALL_MEDIA_LIST',
    SET_MEDIA_LIST_BY_PAGE = 'SET_MEDIA_LIST_BY_PAGE',
    RESET_MEDIA_LIST_BY_PAGE = 'RESET_MEDIA_LIST_BY_PAGE',
    SET_BUYED_MEDIA = 'SET_BUYED_MEDIA',
    SET_MEDIA_LIST_TYPE = 'SET_MEDIA_LIST_TYPE',
    SET_MEDIA_LIST_PAID = 'SET_MEDIA_LIST_PAID',
    RESET_ALL_MEDIA_LIST = 'RESET_ALL_MEDIA_LIST',
}
