import SITE from '../config/site';

export const UTM_VIP = 'utm=vip';
export const UTM_TRAFF = 'utm_traff=egor_launch';
export const UTM_REM = 'utm_rem=rem';
export const UTM_CAMP = 'utm_camp=20585342147';
export const UTM_USER_ID = 'utm_user_id';

export const UTM_TYPES = {
    [UTM_VIP]: 1,
    [UTM_TRAFF]: 2,
    [UTM_REM]: 3,
    [UTM_CAMP]: 4,
    [UTM_USER_ID]: 5,
};

//UTM FB CONVERSION
//MSD
export const UTM_NETW_IL_FCB_ROAS = 'utm_netw=il_fcb_roas';

export const UTM_GEO_SEARCH_ASIA = 'utm_gsearch=1';
export const UTM_GEO_SEARCH_LATINA = 'utm_gsearch=2';
export const UTM_GEO_SEARCH_AFRICA = 'utm_gsearch=3';

export const UTM_NETW_KEY = 'utm_netw';
export const UTM_NETW_VALUE = SITE.ID === 1 ? 'seosofia' : `seo-tp-${SITE.key}`;
