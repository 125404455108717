import Base from './Base';
import SITE from '../../config/site';
import * as UTM from '../../constants/utm';
import { sessionStorageGetItem } from '../../utils/session-storage';
import { getUrlParameter } from '../../utils/url-utils';
import { IRegisterRequestData } from './LoginApi.interface';

export default class LoginApi extends Base {
    async checkIp() {
        try {
            const res = await super.get(`login/checkCountIp`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getAccessToken(accessKey: string) {
        try {
            const res = await super.get(`login/accessToken?accessKey=${accessKey}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postLoginToken(email: string, password: string) {
        try {
            const res = await super.post(`v3/auth/login`, {
                email: email,
                password: password,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postRegister({
        userRealName,
        username = '',
        email,
        password,
        day,
        month,
        year,
        gender,
        mobileModel = '',
        mobileVendor = '',
    }: IRegisterRequestData) {
        const utm_netw = getUrlParameter(UTM.UTM_NETW_KEY);
        const clickid = getUrlParameter('clickid');
        const click_id = utm_netw === 'MGID' ? getUrlParameter('tid') : getUrlParameter('msclkid');
        // @ts-expect-error-next-line deviceMemory
        const deviceMemory = navigator?.deviceMemory ?? 0;
        const deviceData = {
            memory: deviceMemory,
            height: window?.screen?.height,
            width: window?.screen?.width,
            mobileModel,
            mobileVendor,
            userAgent: window?.navigator?.userAgent,
        };
        const isRealEmail = +sessionStorageGetItem('is_real_email') ?? 0;
        const isSeoReferrer =
            !!document.referrer?.length && !document.referrer?.includes(window?.location?.hostname);
        const frontReferrer = isSeoReferrer ? document.referrer : '';

        try {
            const res = await super.post(`v3/auth/register`, {
                user_real_name: userRealName,
                ...(username ? { username: username } : {}),
                email: email,
                password: password,
                day: day,
                month: month,
                year: year,
                gender: gender,
                click_id: click_id,
                clickid: clickid,
                tail: window?.location?.search || '',
                deviceData: deviceData,
                is_real_email: isRealEmail,
                ...(frontReferrer ? { frontReferrer } : {}),
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postLogout() {
        try {
            const res = await super.post(`v3/auth/logout`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postPasswordRestore(email: string) {
        const res = await super.post('v3/auth/password-restore', { email: email });
        return res?.data;
    }

    async postPasswordSet(password: string, activationCode: string) {
        const res = await super.post('login/passwordSet', {
            password: password,
            activation_code: activationCode,
        });
        return res?.data;
    }

    async postDelete() {
        const res = await super.post('v3/auth/delete', {});
        return res?.data;
    }

    async checkEmail(email: string, hasV = false) {
        const res = await super.post(`login/checkEmail`, {
            email,
            ...(hasV ? { v_check: +hasV } : {}),
        });
        return res && res.data;
    }

    // Always send this request before checkEmail
    async checkLoginValidation() {
        try {
            const res = await super.get(`login/validation`);
            return res && res.data;
        } catch {
            return { status: false };
        }
    }

    async postCheckRecaptchaV2(token: string) {
        let isDevelopment = false;

        if (typeof process !== 'undefined') {
            isDevelopment = process?.env?.NODE_ENV === 'development';
        }

        const res = await super.post(`v3/click-history/verify-captcha-v2`, {
            g_recaptcha_response: token,
            site_id: SITE.ID,
            is_development: isDevelopment,
        });
        return res && res?.data;
    }
}
