import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import * as modalsAction from '../../store/actions/modals';
import PaymentApi from '../../services/api/v3/PaymentApi';
import * as ERROR from '../../constants/error';
import useHandlePayment from '../purchase/useHandlePayment';
import { RootState } from '../../index';
import { IPurchasePackageItem } from '../../store/reducers/purchase/index.interface';

export default function useInitModalLessCredits() {
    const dispatch = useDispatch();
    const handlePayment = useHandlePayment();

    const lessCreditsModal = useSelector((state: RootState) => state.modals.lessCreditsModal);

    const packId = +(lessCreditsModal?.packId || 0);

    const [isLessCreditModal, setIsLessCreditModal] = useState(false);
    const [prices, setPrices] = useState<IPurchasePackageItem[]>([]);
    const [activePack, setActivePack] = useState<number | null>(null);

    const getPrices = () => {
        new PaymentApi().getPaymentProposition().then((res) => {
            if (res?.status && res?.data?.result) {
                setPrices(res?.data?.result);
                setIsLessCreditModal(true);
            } else {
                dispatch(
                    alertAction.setMessage({
                        message: res?.data?.message || ERROR.ERROR_REQUEST,
                        title: res?.data?.title,
                    }),
                );
            }
        });
    };

    const closeModalLessCredits = () => {
        setIsLessCreditModal(false);
        setPrices([]);
        setActivePack(null);
        dispatch(modalsAction.closeLessThenCreditsMessage());
    };

    const onGetCredits = () => {
        closeModalLessCredits();
        dispatch(userAction.setCredit(true));
        dispatch(modalsAction.closeWomanVideoShowModal());
        dispatch(modalsAction.closeWomanProfileModal());

        handlePayment.onOpenPayment();
    };

    useEffect(() => {
        if (lessCreditsModal?.isActive) getPrices();
    }, [lessCreditsModal]);

    return {
        isLessCreditModal,
        packId: prices?.some((item) => +item?.id === +packId) ? packId : prices?.[0]?.id,
        prices,
        activePack,
        message: lessCreditsModal?.message,
        title: lessCreditsModal?.title,
        setActivePack,
        onGetCredits,
        closeModalLessCredits,
    };
}
