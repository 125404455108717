import React from 'react';
import Modal from 'react-modal';
import { formatDate } from '../../../../services/time';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Loader from '../../../common/LoaderGif';
import ComposeWriteLetterModal from '../../../common/Modals/ComposeWriteLetterModal';
import SwitchLetterAttachment from '../../../common/SwitchLetterAttachment';
import useHandleLettersListDraft from '../../../../hooks/letters/useHandleLettersListDraft';
import SlickContainer from '../../../common/SlickContainer';
import { IDraftsFullInfo, IMailsAttachment } from '../../../../services/api/MailboxApi.interface';
import avatar_placeholder from '../../../../img/placeholder.gif';
import IconTrash from '../../../Icons/IconTrash';

interface IDraftItemProps {
    item: IDraftsFullInfo;
}

export default function DraftItem({ item }: IDraftItemProps) {
    const handleLettersListDraft = useHandleLettersListDraft(item);
    const avatar = item?.draft?.recipient_avatar || avatar_placeholder;
    return (
        <>
            <div className="c3l_men_list_item files" data-testid={`letter-item-${item?.draft?.id}`}>
                <div className="c3l_men_list_item_main">
                    <div
                        className="c3l_men_list_item_main_top"
                        onClick={handleLettersListDraft.openChat}
                    >
                        <div className="c3l_men_list_item_users">
                            <div
                                className={`c3l_men_list_item_from ${item?.draft?.recipient_online ? 'online' : ''}`}
                            >
                                <div className="c3l_men_list_item_from_to">To:</div>
                                <div
                                    className="c3l_men_list_item_from_photo"
                                    data-testid="letter-item-photo"
                                >
                                    <img src={avatar} alt="avatar" />
                                </div>
                                <div className="c3l_men_list_item_from_info">
                                    <div
                                        className="c3l_men_list_item_from_id"
                                        data-testid="letter-item-external_id"
                                    >
                                        ID {item?.draft?.recipient_public_external_id || ''}
                                    </div>
                                    <div
                                        className="c3l_men_list_item_from_name"
                                        data-testid="letter-item-name"
                                    >
                                        {`${item?.draft?.recipient_name || ''}, ${item?.draft?.recipient_age || ''}`}
                                    </div>
                                    <div
                                        className="c3l_men_list_item_from_place"
                                        data-testid="letter-item-city"
                                    >
                                        <img src={item?.draft?.recipient_country_icon} alt="" />
                                        {item?.draft?.recipient_city}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="c3l_men_list_item_time" data-testid="letter-item-date">
                            {formatDate(item?.draft?.date_created)}
                        </div>
                        <div className="c3l_men_list_item_text" data-testid="letter-item-content">
                            {item?.draft?.message_content}
                        </div>
                    </div>
                    {item?.attachments?.length && (
                        <div className="c3l_men_list_item_main_bottom">
                            <div
                                className="letters_attach_files_list"
                                data-testid="letter-item-attachments"
                            >
                                <SlickContainer>
                                    {item?.attachments?.map(
                                        (attach: IMailsAttachment, key: number) => (
                                            <SwitchLetterAttachment
                                                key={`${key}_${attach?.attachment_id}_${item?.draft?.id}`}
                                                data={attach}
                                                isFreeToWatch={true}
                                                letterID={item?.draft?.id}
                                            />
                                        ),
                                    )}
                                </SlickContainer>
                            </div>
                        </div>
                    )}
                </div>
                <div className="c3l_men_list_item_options_wrap">
                    <div className="c3l_men_list_item_options">
                        {!handleLettersListDraft?.activeDelete ? (
                            <div
                                className="c3l_men_list_item_btn c3l_delete_btn"
                                onClick={() => handleLettersListDraft.deleteDraft(item?.draft?.id)}
                                data-testid="letter-item-delete-btn"
                            >
                                <IconTrash />
                                <span>Delete</span>
                            </div>
                        ) : (
                            <Loader
                                mainStyle={{
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    height: '100%',
                                    width: '100%',
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                style={modalStyleProps()}
                isOpen={handleLettersListDraft.modalWRLIsOpen}
                onRequestClose={handleLettersListDraft.closeWriteLetterModal}
                ariaHideApp={false}
            >
                <ComposeWriteLetterModal
                    closeWriteLetterModal={handleLettersListDraft.closeWriteLetterModal}
                />
            </Modal>
        </>
    );
}
