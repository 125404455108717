// import BillingApi from '../../services/api/v3/BillingApi';
// import { localStorageGetItem } from '../../utils/local-storage';
import { BillingActionEnum } from '../../services/api/v3/BillingApi.interface';
// import useDetectedDevice from '../device/useDetectedDevice';

export default function useHandleBilling() {
    // const { fullClientData } = useDetectedDevice();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const createBillingLog = (action: keyof typeof BillingActionEnum, extra = null) => {
        // TODO: 403 error
        return;
        // const logData = {
        //     deviceID: (localStorageGetItem('fingerprintHash') as string) || '',
        //     device: JSON.stringify(fullClientData),
        //     link: window.location.href,
        //     action,
        //     ...(extra ? { extra: JSON.stringify(extra) } : {}),
        // };
        //
        // new BillingApi().createBillingLog(logData);
    };

    return { createBillingLog };
}
