import { ChatsActionTypes } from '../../constants/chats';
import { uniqueArrayByID } from '../../../services/methods';
import { ChatDetailsActions, IChatDetailsState, IChatHistoryItem } from './index.interface';
import { IStoreChatListItemModel } from '../../../models/chat/index.interface';

const HISTORY_LENGTH = 20;

const initState = {
    info: null,
    history: [],
    hidePageDown: 0,
    historyPage: 2,
    historyPageLoading: false,
    status: false,
    lastMessageId: null,
    isNewMessage: false,
    isReadLastMessage: false,
    openChatDate: null,
    lastMessageDate: null,
    openFromNotification: null,
    touchSwipeType: '',
    isLoadedAll: false,
};

export default function (
    state: IChatDetailsState = initState,
    action: ChatDetailsActions,
): IChatDetailsState {
    switch (action.type) {
        case ChatsActionTypes.FETCH_CHAT_GET:
            return {
                ...state,
                ...{
                    history: [],
                    status: false,
                    lastMessageId: null,
                    hidePageDown: 0,
                    historyPage: 2,
                    historyPageLoading: false,
                    openChatDate: null,
                    lastMessageDate: null,
                    isReadLastMessage: false,
                },
            };

        case ChatsActionTypes.CURRENT_CHAT_SET_FAVORITE_STATUS:
            return {
                ...state,
                ...{
                    info: {
                        ...(state?.info as IStoreChatListItemModel),
                        ...{ favorite: action?.favoriteStatus },
                    },
                },
            };

        case ChatsActionTypes.SET_PINNED_CURRENT_CHAT:
            return {
                ...state,
                ...{
                    info: {
                        ...(state?.info as IStoreChatListItemModel),
                        ...{ pinned: +action?.pinned },
                    },
                },
            };

        case ChatsActionTypes.SET_READ_LAST_MESS:
            return {
                ...state,
                ...{
                    isReadLastMessage: true,
                    history: state?.history.map((item) => ({
                        ...item,
                        ...{ read_status: 1 },
                    })) as IChatHistoryItem[],
                },
            };

        case ChatsActionTypes.READ_MESS:
            return {
                ...state,
                ...{
                    history: state?.history.map((item) => {
                        if (+item?.id === +action?.messageId) {
                            return {
                                ...item,
                                payed: 1,
                                message_content: action?.messageContent,
                                message_thumb: action?.messageContent,
                            };
                        }

                        return item;
                    }),
                },
            };

        case ChatsActionTypes.SET_IS_NEW_MESS:
            return {
                ...state,
                ...{
                    isNewMessage: action?.data,
                    isReadLastMessage: true,
                },
            };

        case ChatsActionTypes.IS_OPEN_FROM_NOTIFY:
            return {
                ...state,
                ...{
                    openFromNotification: action?.data,
                },
            };

        case ChatsActionTypes.MAIN_CHAT_ADD_INFO:
            return {
                ...state,
                ...{
                    info: action?.data,
                },
            };

        case ChatsActionTypes.TOUCH_SWIPE_TYPE_CHAT_LIST:
            return {
                ...state,
                ...{
                    touchSwipeType: action?.touchSwipeType,
                },
            };

        case ChatsActionTypes.MAIN_CHAT_REMOVE_INFO:
            return initState;

        case ChatsActionTypes.MAIN_CHAT_ADD_LAST_MESSAGE:
            return {
                ...state,
                ...{
                    lastMessageId: action?.data?.id,
                    history: uniqueArrayByID([
                        ...(state?.history || []),
                        ...[action?.data],
                    ]) as IChatHistoryItem[],
                },
            };

        case ChatsActionTypes.MAIN_CHAT_ADD_HISTORY:
            if (
                action.data?.response?.length > 0 &&
                action?.data?.response[action.data?.response?.length - 1]?.id !==
                    state?.lastMessageId
            ) {
                return {
                    ...state,
                    ...{
                        history: uniqueArrayByID(action?.data?.response) as IChatHistoryItem[],
                        status: true,
                        lastMessageId:
                            action?.data?.response[action?.data?.response?.length - 1]?.id,
                        isLoadedAll: action?.data?.response?.length < HISTORY_LENGTH,
                        openChatDate: new Date(),
                        lastMessageDate: new Date(
                            `${action?.data?.response[action?.data?.response?.length - 1]?.date_created} UTC`,
                        ),
                        hidePageDown: 0,
                        historyPage: 2,
                        historyPageLoading: false,
                        isReadLastMessage: false,
                    },
                };
            } else {
                return { ...state, ...{ status: true } };
            }

        case ChatsActionTypes.MAIN_CHAT_ADD_HISTORY_PAGE:
            if (Array.isArray(action?.data?.response) && Array.isArray(state?.history)) {
                return {
                    ...state,
                    ...{
                        history: uniqueArrayByID([
                            ...(action?.data?.response || []),
                            ...(state?.history || []),
                        ]) as IChatHistoryItem[],
                        status: true,
                        historyPage:
                            action?.data?.response?.length === 0
                                ? state?.historyPage
                                : state?.historyPage + 1,
                        isLoadedAll: action?.data?.response?.length < HISTORY_LENGTH,
                        historyPageLoading: false,
                        lastMessageId: state.history[0]?.id,
                        isReadLastMessage: false,
                    },
                };
            } else return { ...state, ...{ status: true } };

        case ChatsActionTypes.MAIN_CHAT_ADD_HISTORY_FORCE:
            return {
                ...state,
                ...{
                    history: uniqueArrayByID(action?.data?.response) as IChatHistoryItem[],
                    status: true,
                    lastMessageId: action?.data?.response[action?.data?.response?.length - 1]?.id,
                },
            };

        case ChatsActionTypes.MAIN_CHAT_ADD_MESSAGE:
            return {
                ...state,
                ...{
                    history: uniqueArrayByID([
                        ...(state?.history || []),
                        ...[action?.data],
                    ]) as IChatHistoryItem[],
                    status: true,
                    lastMessageId: action?.data?.id,
                    lastMessageDate: new Date(),
                },
            };

        case ChatsActionTypes.MAIN_CHAT_UPDATE_MESSAGE:
            return {
                ...state,
                ...{
                    history: uniqueArrayByID([
                        ...(state?.history?.filter((item: any) => item?.id !== action?.hashId) ||
                            []),
                        ...[action?.data],
                    ]) as IChatHistoryItem[],
                    status: true,
                    lastMessageId: action?.data?.id,
                    lastMessageDate: new Date(),
                },
            };

        case ChatsActionTypes.MAIN_CHAT_DELETE_MESSAGE:
            return {
                ...state,
                ...{
                    history: uniqueArrayByID([
                        ...(state?.history?.filter((item: any) => item?.id !== action?.hashId) ||
                            []),
                    ]) as IChatHistoryItem[],
                    status: true,
                    lastMessageId: state?.history[state?.history?.length - 1]?.id,
                    lastMessageDate: new Date(
                        `${state?.history[state?.history?.length - 1]?.date_created} UTC`,
                    ),
                },
            };

        case ChatsActionTypes.MAIN_CHAT_START_PAGE:
            // eslint-disable-next-line no-case-declarations
            const checkedHistory =
                state?.history?.length < HISTORY_LENGTH
                    ? state?.history
                    : state?.history?.slice(
                          state?.history?.length - HISTORY_LENGTH,
                          state?.history?.length,
                      );
            // eslint-disable-next-line no-case-declarations
            const checkedIsLoadedAll = checkedHistory?.length < HISTORY_LENGTH;

            return {
                ...state,
                ...{
                    history: checkedHistory,
                    hidePageDown: 0,
                    historyPage: 2,
                    isLoadedAll: checkedIsLoadedAll,
                    historyPageLoading: false,
                    lastMessageId: null,
                    isReadLastMessage: false,
                },
            };

        case ChatsActionTypes.MAIN_CHAT_ADD_HISTORY_PAGE_LOADING:
            return {
                ...state,
                ...{
                    historyPageLoading: true,
                },
            };

        case ChatsActionTypes.MAIN_CHAT_SET_HIDE_PAGE_DOWN:
            if (+action?.hidePageDown === 0) {
                return {
                    ...state,
                    ...{
                        hidePageDown: 0,
                        historyPage: 2,
                        isLoadedAll: false,

                        historyPageLoading: false,
                        lastMessageId: state?.history[state?.history?.length - 1]?.id,
                        // history: uniqueArrayByID([...state?.history.slice(state?.history?.length - 20, state?.history?.length)]),
                        history: action?.isDown
                            ? (state?.history?.slice(HISTORY_LENGTH) as IChatHistoryItem[])
                            : (state?.history as IChatHistoryItem[]),
                        isReadLastMessage: false,
                    },
                };
            } else {
                return {
                    ...state,
                    ...{
                        hidePageDown: action?.hidePageDown,
                        lastMessageId: +(
                            (action?.isDown ? '-' : '') +
                            state?.history[state?.history?.length - +state?.hidePageDown * 20 - 1]
                                ?.id
                        ),
                        isLoadedAll: false,

                        historyPage: action?.isDown ? state?.historyPage - 1 : state?.historyPage,
                        historyPageLoading: false,
                        history: uniqueArrayByID(
                            action?.isDown ? state?.history.slice(20) : state?.history,
                        ) as IChatHistoryItem[],
                        isReadLastMessage: false,
                    },
                };
            }

        case ChatsActionTypes.SET_CURRENT_CHAT_ONLINE_STATUS: {
            return {
                ...state,
                ...{
                    info: {
                        ...(state?.info as IStoreChatListItemModel),
                        ...{ online: +action?.status },
                    },
                },
            };
        }

        case ChatsActionTypes.SET_CURRENT_CHAT_LIKE_STATUS: {
            return {
                ...state,
                ...{
                    info: { ...(state?.info as IStoreChatListItemModel), ...{ liked: 1 } },
                },
            };
        }

        default:
            return state;
    }
}
