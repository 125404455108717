import { SettingsActionTypes } from '../../constants/settings';
import { ISettingsState, SettingsActions } from './index.interface';

const initState = {
    route: {
        chat: true,
        credit: true,
        letter: true,
        likeHer: true,
        onlineNow: true,
        search: true,
        videoShow: true,
        policy: true,
        tutorial: true,
        faq: true,
    },
    config: {},
    subscribeConfig: [],
    isActiveCallButton: false,
    event: null,
};

export default function (
    state: ISettingsState = initState,
    action: SettingsActions,
): ISettingsState {
    switch (action.type) {
        case SettingsActionTypes.ADD_ROUTES_SETTINGS:
            return {
                ...state,
                ...{
                    route: action?.settings,
                    config: action?.config,
                    subscribeConfig: action?.subscribeConfig,
                    isActiveCallButton: action?.isActiveCallButton,
                    event: action?.event,
                },
            };

        case SettingsActionTypes.SET_IS_ACTIVE_CALL_BUTTON:
            return {
                ...state,
                ...{
                    isActiveCallButton: action?.status,
                },
            };

        case SettingsActionTypes.UPDATE_EVENT_STATUS:
            return {
                ...state,
                ...{
                    event: null,
                },
            };

        default:
            return state;
    }
}
