import React, { useState } from 'react';
import StickersListOpen from './StickersListOpen';
import useSendSticker from '../../../../../../hooks/emoji/sticker/useSendSticker';
import * as STICKERS from '../../../../../../constants/stickers';
import { convertPrice } from '../../../../../../services/methods';
import * as PRICES from '../../../../../../constants/prices';
import SITE from '../../../../../../config/site';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { IEmojiProps, IInnerStickersList, IStickerItem } from './index.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import emoji_sprites from '../../../../../../img/emoji/emoji-sprites.png';
import { isHolidayActive } from '../../../../../../constants/holiday';

export default function Emoji({
    addEmoji,
    isSmileBlock,
    openSmileBlock,
    closeSmileBlock,
    smileBoxRef,
    isStickerBlock,
    openStickerBlock,
    closeStickerBlock,
    stickerBoxRef,
    btnStickerRef,
    isOpenMobSmileBlock,
    setIsOpenMobSmileBlock,
    isOpenMobStickerBlock,
    setIsOpenMobStickerBlock,
}: IEmojiProps) {
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);

    const initInnerStickersList: IInnerStickersList = { stickerList: [], title: '' };

    const [innerStickerList, setInnerStickerList] = useState(initInnerStickersList);
    const [isStickerListOpen, setIsStickerListOpen] = useState(false);

    const sendSticker = useSendSticker();
    const activeHoliday = isHolidayActive();

    const openInnerStickerList = (stickerArray: IStickerItem[], title: string) => {
        setInnerStickerList({ stickerList: stickerArray, title: title });
        setIsStickerListOpen(true);
    };

    const closeInnerStickerList = () => {
        setInnerStickerList(initInnerStickersList);
        setIsStickerListOpen(false);
    };

    const stickerListMap = (stickerArray: IStickerItem[]) => {
        const stickerArraySlice = stickerArray?.slice(0, 4);

        return (
            !!stickerArraySlice?.length &&
            stickerArraySlice?.map((sticker, index) => (
                <li
                    className="c3_stickers_list_item"
                    onClick={() => onSelectSticker(sticker?.name)}
                    key={`${sticker?.name}_${index}`}
                    data-testid={sticker?.name}
                >
                    <img src={sticker?.image} alt="" />
                </li>
            ))
        );
    };

    const onSelectSticker = (stickerName: string) => {
        if (!currentChatState?.info?.id) return;

        closeStickerBlock();
        sendSticker(stickerName, currentChatState?.info?.id, currentChatState?.info?.chat_uid);
    };

    const onSmileWrapActive = () => {
        isSmileBlock ? closeSmileBlock() : openSmileBlock();
        setIsOpenMobSmileBlock(!isOpenMobSmileBlock);
    };

    const onStickerWrapActive = () => {
        if (isStickerBlock && !isStickerListOpen) closeStickerBlock();
        else openStickerBlock();

        setIsOpenMobStickerBlock(!isOpenMobStickerBlock);
    };

    return (
        <div className="c3_smile_btn_wrap">
            <img src={emoji_sprites} alt="emoji sprites" style={{ display: 'none' }} />
            <div
                className={`c3_smile_btn_wrap2 ${isSmileBlock ? 'active ' : ''}`}
                ref={smileBoxRef}
                data-testid="emoji-block"
            >
                <button
                    type="button"
                    className="c3_smile_btn"
                    onClick={onSmileWrapActive}
                    data-testid="emoji-btn"
                >
                    <div className={`c3_smile_btn_img ${activeHoliday && activeHoliday?.name}`} />
                    <span>Emoji</span>
                </button>
                <div className="c3_smiles_list_wrap">
                    <div className="c3_smiles_list" data-testid="emoji-list">
                        <Picker
                            data={data}
                            onEmojiSelect={addEmoji}
                            emojiSize={32}
                            theme="light"
                            navPosition="none"
                            previewPosition="none"
                            searchPosition="none"
                        />
                    </div>
                </div>
            </div>
            <div
                className={`c3_sticker_wrap ${isStickerBlock || isStickerListOpen ? 'active ' : ''}`}
                ref={stickerBoxRef}
                data-testid="sticker-btn"
            >
                <div className="c3_sticker_btn" ref={btnStickerRef} onClick={onStickerWrapActive}>
                    <div className="c3_sticker_btn_img" />
                    <span>Stickers</span>
                </div>
                <div className="c3_stickers_list_wrap" data-testid="sticker-list">
                    <ul className={`c3_stickers_list_rows_wrap ${isStickerListOpen ? 'hide' : ''}`}>
                        {STICKERS.ARRAY_ALL_STICKER.map((item, index) => (
                            <li className="c3_stickers_list_row" key={`${index}_${item?.title}`}>
                                <div className="c3_stickers_list_head">
                                    <div className="c3_stickers_list_head_title">{item?.title}</div>
                                    {!!(index === 0 && SITE.ID !== 4) && (
                                        <div className="c3_stickers_list_head_price">
                                            One sticker costs{' '}
                                            {userState.prices &&
                                                convertPrice(
                                                    +userState.prices?.[PRICES.SENT_STICKER],
                                                )}{' '}
                                            credits
                                        </div>
                                    )}
                                </div>
                                <ul className="c3_stickers_list">
                                    {stickerListMap(item?.stickerArray)}

                                    <div
                                        className="c3_stickers_list_all_btn"
                                        onClick={() =>
                                            openInnerStickerList(item?.stickerArray, item?.title)
                                        }
                                    >
                                        All
                                    </div>
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <StickersListOpen
                        innerStickerList={innerStickerList?.stickerList}
                        innerStickerTitle={innerStickerList?.title}
                        isStickerListOpen={isStickerListOpen}
                        closeInnerStickerList={closeInnerStickerList}
                        onSelectSticker={onSelectSticker}
                        openStickerBlock={openStickerBlock}
                    />
                </div>
            </div>
        </div>
    );
}
