import MailboxApi from '../../services/api/MailboxApi';
import * as mailboxAction from '../../store/actions/mailbox';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';

export default function useHandleLettersListDraft(item: any) {
    const dispatch = useDispatch();
    const menuState = useSelector((state: RootState) => state.menu);
    const mailboxState = useSelector((state: RootState) => state.mailbox);
    const userState = useSelector((state: RootState) => state.user);

    const [modalWRLIsOpen, setModalWRLIsOpen] = useState(false);
    const [activeDelete, setActiveDelete] = useState(false);

    const openWriteLetterModal = () => {
        setModalWRLIsOpen(true);
    };

    const closeWriteLetterModal = () => {
        setModalWRLIsOpen(false);

        dispatch(currentChatMailboxAction.removeDraftID());
        dispatch(mailboxAction.removeAllRecipient());
    };

    const deleteDraft = (draftId: number) => {
        if (menuState.letters === 'drafts' && draftId) {
            const newPage =
                mailboxState?.mailList?.length === 1
                    ? mailboxState?.mailListPage - 1
                    : mailboxState?.mailListPage;
            try {
                if (userState?.info?.external_id) {
                    setActiveDelete(true);
                    new MailboxApi()
                        .postDeleteDraft({
                            userId: userState?.info?.external_id,
                            draftIds: [draftId],
                        })
                        .then((res) => {
                            if (res?.status) {
                                if (userState?.info?.external_id) {
                                    dispatch(
                                        mailboxAction.getMailDraftsWithoutReload({
                                            userId: [userState?.info?.external_id],
                                            page: newPage,
                                        }),
                                    );
                                }
                            }
                        })
                        .finally(() => setActiveDelete(false));
                }
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }
    };

    const openChat = () => {
        if (!userState?.info?.external_id) return;

        new MailboxApi()
            .postFemales({
                personFilter: '',
                userId: userState?.info?.external_id,
                search: item?.draft?.recipient_public_external_id,
            })
            .then((res) => {
                if (res?.status) {
                    if (res?.result?.[0]) {
                        dispatch(mailboxAction.removeAllRecipient());
                        dispatch(mailboxAction.addRecipient(res?.result));
                        dispatch(currentChatMailboxAction.addDraftId(item?.draft?.id));
                        openWriteLetterModal();
                    }
                }
            });
    };

    return {
        openChat,
        deleteDraft,
        closeWriteLetterModal,
        modalWRLIsOpen,
        activeDelete,
    };
}
