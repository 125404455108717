export const HAIR_TYPE = [
    { style: 'not_spec', name: 'Not Specified' },
    { style: 'black', name: 'Jet Black' },
    { style: 'blond', name: 'Blond' },
    { style: 'brown', name: 'Brunette' },
    { style: 'golden', name: 'Golden' },
    { style: 'ginger', name: 'Ginger' },
];

export const EYE_COLOR = [
    { style: 'not_spec', name: 'Not Specified' },
    { style: 'brown', name: 'Brown' },
    { style: 'blue', name: 'Blue' },
    { style: 'green', name: 'Green' },
    { style: 'gray', name: 'Gray' },
    { style: 'hazel', name: 'Hazel' },
    { style: 'amber', name: 'Amber' },
];

export const EDIT_PROFILE_TIMER = 5000;
