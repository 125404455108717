import styles from '../../../components/common/HeaderChatRoom/MediaList/styles.module.scss';
import * as CHATS from '../../../constants/chats';
import { IFilterByItem } from './index.interface';

export const scrollTopMediaList = (): void => {
    const block = document.querySelector(`.${styles.media_list_month}`);
    if (block) block.scrollIntoView();
};

export const filterByFileType = (mediaListType: string, item: IFilterByItem): boolean => {
    if (mediaListType === 'all media') {
        return true;
    } else if (mediaListType === 'photo') {
        return item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_IMAGE;
    } else if (mediaListType === 'video') {
        return item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_VIDEO;
    } else if (mediaListType === 'audio') {
        return item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_AUDIO;
    }
    return false;
};

export const filterByPayed = (mediaListPaid: string, item: IFilterByItem): boolean => {
    if (mediaListPaid === 'paid')
        return !!+item?.payed || item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_IMAGE;
    else return true;
};
