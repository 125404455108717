import React, { memo } from 'react';
import styles from '../styles.module.scss';
import { ITopProfile } from '../../../../services/api/v3/SearchApi.interface';

interface ISliderItemProps {
    profile: ITopProfile;
    openChat: (id: number) => void;
    openGalleryModal: (id: number, images: any) => void;
}

const SliderItem = ({ profile, openGalleryModal, openChat }: ISliderItemProps) => {
    return (
        <>
            <div className={styles.girls_slider_item_wrap_2}>
                <div className={styles.girls_slider_item_wrap}>
                    <div
                        className={`${styles.girls_slider_item} ${styles.girls_slider_item_placeholder}`}
                    >
                        <div className={styles.girls_slider_item_mob_click} />
                        {!!+profile?.online && (
                            <div className={styles.girls_slider_item_status}>Online</div>
                        )}

                        <img
                            className={styles.girls_slider_item_photo}
                            src={profile?.search_img}
                            alt=""
                            onClick={() => openGalleryModal(profile?.external_id, [])}
                        />

                        <div className={styles.girls_slider_item_bg} />
                        <div className={styles.girls_slider_item_buttons}>
                            <button
                                type="button"
                                className={styles.girls_slider_item_btn}
                                onClick={() => openChat(profile?.external_id)}
                            >
                                <span className={styles.girls_slider_item_btn_text}>Chat</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(SliderItem);
