import React, { useState } from 'react';
import DoubleSelect from '../../../../common/select/DoubleSelect';
import * as DICTIONARY_CREATE_PROFILE_PAGE from '../../../../../constants/dictionary/create-profile-page';
import { openFilterSearch } from '../../../../../services/dom';
import SearchCountry from '../../../SearchCountry';
import styles from './styles.module.scss';
import LargeSelect from '../../../../common/select/LargeSelect';
import { sequenceArray } from '../../../../../services/time/DateUtils';
import { useSelector } from 'react-redux';
import SearchInput from '../../../../common/SearchInput';
import SearchFilterOnline from '../../../SearchFilterOnline';
import { LOOKING_GENDER_LIST } from '../../../../../constants/search';
import { RootState } from '../../../../../index';
import { IArrayFields } from '../../../../common/SearchFilter/SearchToolbar/index.interface';

interface ISearchToolbarV3 {
    countryFilter: string;
    handleCountryFilter: (value: string) => void;
    ageFromFilter: number | string;
    setAgeFromFilter: (value: number | string | IArrayFields) => void;
    ageToFilter: number | string;
    setAgeToFilter: (value: number | string | IArrayFields) => void;
    setGenderFilter: (gender: number | string, sexFilter: number) => void;
    setFilterField: (field: string, value: any) => void;
    filterOnline: boolean;
    genderFilter: number;
    isActiveOnlineFilter?: boolean;
    nameFilter: string;
    handleOnline: () => void;
}

export default function SearchToolbarV3({
    countryFilter,
    handleCountryFilter,
    ageFromFilter,
    setAgeFromFilter,
    ageToFilter,
    setAgeToFilter,
    setGenderFilter,
    setFilterField,
    filterOnline,
    isActiveOnlineFilter = true,
    nameFilter,
    handleOnline,
}: ISearchToolbarV3) {
    const userState = useSelector((state: RootState) => state.user);

    const [openAgeFromBox, setOpenAgeFromBox] = useState<boolean>(false);
    const [openAgeToBox, setOpenAgeToBox] = useState<boolean>(false);
    const [openGenderBox, setOpenGenderBox] = useState<boolean>(false);

    const closeAllSelects = () => {
        if (window.innerWidth < 1000) openFilterSearch();

        if (openAgeFromBox || openAgeToBox || openGenderBox) {
            setOpenAgeFromBox(false);
            setOpenAgeToBox(false);
            setOpenGenderBox(false);
        }
    };

    return (
        <>
            <div className={` ${styles.wrapper}`} onClick={closeAllSelects}>
                <div className="c3lh_search_filters_list_mobile_info">
                    All filters are applied automatically
                </div>

                {window?.innerWidth < 1140 && (
                    <>
                        {isActiveOnlineFilter && (
                            <SearchFilterOnline
                                handleChange={handleOnline}
                                selected={filterOnline}
                            />
                        )}
                        <SearchInput
                            handleSearch={(value) => setFilterField('id', value)}
                            value={nameFilter}
                        />
                    </>
                )}

                <div className="c3lh_search_filter_item">
                    <SearchCountry
                        handleCountryFilter={handleCountryFilter}
                        initInputValue={countryFilter}
                    />
                </div>

                <div className="c3lh_search_filter_item">
                    <DoubleSelect
                        formDataFrom={ageFromFilter}
                        formDataTo={ageToFilter}
                        handleClickFrom={(item) => {
                            setAgeFromFilter(item);
                            setOpenAgeFromBox(false);
                        }}
                        handleClickTo={(item) => {
                            setAgeToFilter(item);
                            setOpenAgeToBox(false);
                        }}
                        arrayFieldsFrom={['', ...sequenceArray(18, 70), '70+']}
                        arrayFieldsTo={['', ...sequenceArray(18, 70), '70+']}
                        defaultNameFrom={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_FROM}
                        defaultNameTo={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_TO}
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_AGE}
                    />
                </div>

                <div className="c3lh_search_filter_item">
                    <LargeSelect
                        index="value"
                        formData={
                            LOOKING_GENDER_LIST?.find(
                                (item) => item?.sexFilter === userState?.info?.sex,
                            )?.value || LOOKING_GENDER_LIST?.[0]?.value
                        }
                        handleClick={(item) => {
                            setGenderFilter(item?.id, item?.sexFilter);
                            setOpenGenderBox(false);
                        }}
                        arrayFields={LOOKING_GENDER_LIST}
                        defaultName={
                            LOOKING_GENDER_LIST?.find(
                                (item) => item?.sexFilter === userState?.info?.sex,
                            )?.value || LOOKING_GENDER_LIST?.[0]?.value
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_LOOKING_FOR}
                        dataTestId="sex-filter"
                    />
                </div>
            </div>
        </>
    );
}
