import React from 'react';
import styles from './styles.module.scss';

interface IUnsubscribeFormProps {
    unsubscribeEmail: string;
    handleUnsubscribeEmail: (value: string) => void;
    isUnsubscribeEmailError: boolean;
    onSubmitUnsubscribeForm: (event: React.ChangeEvent<HTMLFormElement>) => void;
    isLoadingSubmit: boolean;
}

export default function UnsubscribeForm({
    unsubscribeEmail,
    handleUnsubscribeEmail,
    isUnsubscribeEmailError,
    onSubmitUnsubscribeForm,
    isLoadingSubmit,
}: IUnsubscribeFormProps) {
    return (
        <>
            <form className={styles.unsubscribe_form} onSubmit={onSubmitUnsubscribeForm}>
                <fieldset
                    className={`${styles.unsubscribe_form_fieldgroup}`}
                    disabled={isLoadingSubmit}
                >
                    <div className={styles.unsubscribe_form_fieldgroup_header}>
                        <h2 className={styles.unsubscribe_form_title}>
                            To unsubscribe, enter the email that is linked to this account.
                        </h2>
                    </div>
                    <div className={styles.unsubscribe_form_fieldgroup_body}>
                        <input
                            className={styles.unsubscribe_form_text_input}
                            type="email"
                            placeholder="Enter your email"
                            required
                            value={unsubscribeEmail}
                            onChange={(e) => handleUnsubscribeEmail(e.currentTarget.value)}
                        />
                        {!!isUnsubscribeEmailError && (
                            <span className={styles.error_text}>
                                The email address you entered does not match the one associated with
                                your account registration.
                            </span>
                        )}
                        <button className={styles.unsubscribe_form_submit_btn} type="submit">
                            <span className={styles.unsubscribe_form_submit_btn_text}>
                                Unsubscribe
                            </span>
                        </button>
                    </div>
                </fieldset>
            </form>
        </>
    );
}
