import React from 'react';

interface ISearchFilterOnline {
    selected: boolean;
    handleChange: () => void;
}

export default function SearchFilterOnline({ selected, handleChange }: ISearchFilterOnline) {
    return (
        <div className="c3lh_search_filter_item_online_status_inp" data-testid="online">
            <div className="c3lh_search_filter_item_online_status_inp_text">Online</div>
            <input type="checkbox" id="csfiosi_1" checked={selected} onChange={handleChange} />
            <label htmlFor="csfiosi_1" />
        </div>
    );
}
