import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Poll from './Poll';
import { IPollMainPageModalProps } from './index.interface';

export default function PollMainPageModal({
    quizData,
    typeList,
    closeQuizPopup,
    isActiveModal,
}: IPollMainPageModalProps) {
    return (
        <Modal style={modalStyleProps()} isOpen={isActiveModal} ariaHideApp={false}>
            <Poll quizData={quizData} typeList={typeList} closeQuizPopup={closeQuizPopup} />
        </Modal>
    );
}
