import * as userAction from '../../store/actions/user';
import * as purchaseAction from '../../store/actions/purchase';
import * as alertAction from '../../store/actions/alert';
import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useHandlePayment from './useHandlePayment';
import * as ERROR from '../../constants/error';
import { RootState } from '../../index';

export default function useInitPurchaseModal() {
    const userState = useSelector((state: RootState) => state.user);
    const [modalPurchaseCreditIsActive, setModalPurchaseCreditIsActive] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();
    const transition = useTransition();
    const handlePayment = useHandlePayment();

    const openModalPurchaseCredit = () => {
        // if (location?.search.includes('utm_purchaselist=reopen')) transition.addTail('');
        if (!location?.search.includes('try_pay_card=true'))
            dispatch(userAction.addLastBuyCredits(null));

        setModalPurchaseCreditIsActive(true);

        if (!window?.location?.href?.includes('purchase-history')) {
            handlePayment.getProposition().then((res) => {
                if (!res?.data) {
                    setModalPurchaseCreditIsActive(false);
                    dispatch(alertAction.setMessage({ message: ERROR.ERROR_REQUEST }));
                }
            });
        }
    };
    const tryAgainPayment = () => {
        if (userState.lastBuyCredits) {
            openModalPurchaseCredit();
            dispatch(userAction.addLastBuyCredits(userState.lastBuyCredits));
            dispatch(userAction.isBuying(true));
        }
    };
    const closeModalPurchaseCredit = () => {
        if (!location?.search.includes('utm_purchaselist=reopen')) transition.addTail('');
        setModalPurchaseCreditIsActive(false);
        dispatch(purchaseAction.removePurchaseCreditsList());
        dispatch(userAction.isBuying(false));
    };

    useEffect(() => {
        if (location?.search.includes('utm_purchaselist=reopen')) openModalPurchaseCredit();
    }, [location]);

    return {
        modalPurchaseCreditIsActive,
        openModalPurchaseCredit,
        closeModalPurchaseCredit,
        tryAgainPayment,
    };
}
