import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import * as DATE from '../../../constants/date';
import SITE from '../../../config/site';
import { RootState } from '../../../index';

export default function MobileMenuFooter() {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <>
            <div className={styles.c1_bottom_wrap}>
                <div className={styles.c1_copyright}>
                    © {DATE.CURRENT_YEAR} {SITE?.copyrightsText} <br />
                    All rights reserved
                </div>
                {!!userState?.supportEmail && (
                    <a
                        href={`mailto: ${userState?.supportEmail}`}
                        className={styles.c1_support_mail}
                    >
                        {userState?.supportEmail}
                    </a>
                )}
            </div>
        </>
    );
}
