import React from 'react';
import styles from '../styles.module.scss';
import IconFAQ from '../../../../../Icons/IconFAQ';
import SITE from '../../../../../../config/site';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import { IVerifyBlockProps } from './index.interface';

export default function VerifyBlock({
    handleChangeBlock,
    onSubmit,
    handleStartVerification,
    isUserIdFileApproved,
    isUserSelfieFileApproved,
    isUserProfileVerified,
}: IVerifyBlockProps) {
    const docsState = useSelector((state: RootState) => state.profile.docsState);
    const userState = useSelector((state: RootState) => state.user);
    const isProfileIdentification = useSelector(
        (state: RootState) => state.modals.isOpenProfileIdentificationModal,
    );
    const approvedTitle = 'Your documents have been approved.';
    const approvedSubtitle = 'No further action is required from your side.';

    const canSubmit =
        docsState?.userOnlySelfieFile?.name ||
        isUserProfileVerified ||
        ((docsState?.userIdFile?.name || isUserIdFileApproved) &&
            (docsState?.userSelfieFile?.name || isUserSelfieFileApproved));
    return (
        <>
            <h3 className={styles.block_title}>Let’s verify your identity</h3>
            <p className={styles.block_text}>
                {`Dear ${userState?.info?.name}, to ensure your safety and secure use of our website, we
                kindly ask you to complete a quick identity verification. This will help us keep
                your experience smooth and protected.`}
            </p>
            <button type="button" className={styles.faq_btn} onClick={() => handleChangeBlock(2)}>
                <IconFAQ />
                Why should I do this?
            </button>
            <div className={styles.block_buttons}>
                {isProfileIdentification ? (
                    <button
                        type="button"
                        onClick={() => handleChangeBlock(3)}
                        className={`${styles.block_button} ${styles.button_user_selfie} ${docsState?.userOnlySelfieFile?.name || isUserProfileVerified ? styles.step_completed : ''}`}
                    >
                        <h4 className={styles.block_button_title}>
                            {isUserProfileVerified ? approvedTitle : 'Take a selfie of yourself'}
                        </h4>
                        <p className={styles.block_button_text}>
                            {isUserProfileVerified
                                ? approvedSubtitle
                                : 'To match your face to your ID photo'}
                        </p>
                    </button>
                ) : (
                    <>
                        <button
                            type="button"
                            onClick={() => handleChangeBlock(3)}
                            className={`${styles.block_button} ${styles.button_user_id} ${docsState?.userIdFile?.name || isUserIdFileApproved ? styles.step_completed : ''}`}
                        >
                            <h4 className={styles.block_button_title}>
                                {isUserIdFileApproved
                                    ? approvedTitle
                                    : 'Take a picture of your valid ID'}
                            </h4>
                            <p className={styles.block_button_text}>
                                {isUserIdFileApproved
                                    ? approvedSubtitle
                                    : 'To check your personal information'}
                            </p>
                        </button>
                        <button
                            type="button"
                            onClick={() => handleChangeBlock(4)}
                            className={`${styles.block_button} ${styles.button_user_selfie} ${docsState?.userSelfieFile?.name || isUserSelfieFileApproved ? styles.step_completed : ''}`}
                        >
                            <h4 className={styles.block_button_title}>
                                {isUserSelfieFileApproved
                                    ? approvedTitle
                                    : 'Take a selfie with the payment card'}
                            </h4>
                            <p className={styles.block_button_text}>
                                {isUserSelfieFileApproved
                                    ? approvedSubtitle
                                    : 'Take a photo of yourself with the payment card that you previously used here in your arms.'}
                            </p>
                        </button>
                    </>
                )}
            </div>
            <div className={styles.block_footer}>
                {canSubmit ? (
                    <button type="button" onClick={onSubmit} className={styles.block_button_main}>
                        Submit
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={handleStartVerification}
                        className={styles.block_button_main}
                    >
                        Start verification
                    </button>
                )}
                <p className={styles.block_info}>
                    This information is used for personal verification only, and is kept private and
                    confidential by {SITE.copyrightsText}.
                </p>
            </div>
        </>
    );
}
