import React from 'react';

export default function IconInfoDanger() {
    return (
        <svg
            width="64"
            height="58"
            viewBox="0 0 64 58"
            fill="#CA2615"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_86)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.3367 3.27257C28.8515 -1.09085 35.1485 -1.09087 37.6633 3.27257L63.117 47.4373C65.6285 51.795 62.4834 57.2378 57.4537 57.2378H6.54626C1.51665 57.2378 -1.62851 51.795 0.882979 47.4373L26.3367 3.27257ZM34.9293 4.84827C33.6285 2.59133 30.3715 2.59133 29.0707 4.84827L3.61698 49.013C2.31793 51.267 3.94474 54.0823 6.54626 54.0823H57.4537C60.0553 54.0823 61.6821 51.267 60.383 49.013L34.9293 4.84827Z"
                />
                <path d="M28.5037 16.4407H35.5925L34.3951 38.5226H29.6533L28.5037 16.4407ZM32.0481 49.7995C30.9305 49.7995 30.0045 49.4535 29.2701 48.7615C28.5357 48.038 28.1685 47.1572 28.1685 46.1192C28.1685 45.0811 28.5357 44.2161 29.2701 43.5241C30.0045 42.8321 30.9305 42.486 32.0481 42.486C33.1338 42.486 34.0278 42.8321 34.7303 43.5241C35.4648 44.2161 35.832 45.0811 35.832 46.1192C35.832 47.1572 35.4648 48.038 34.7303 48.7615C34.0278 49.4535 33.1338 49.7995 32.0481 49.7995Z" />
            </g>
            <defs>
                <clipPath id="clip0_1_86">
                    <rect width="64" height="58" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
