import React from 'react';

export default function IconPolicyPrivacy() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#D35B00"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.06618 9.44186H15.9338C15.9392 9.30459 15.9429 9.14822 15.9429 8.97675C15.9429 8.31275 15.888 7.44389 15.6776 6.58782C15.4662 5.72753 15.1102 4.93017 14.5437 4.3538C13.995 3.79551 13.2018 3.39535 12 3.39535C10.7982 3.39535 10.005 3.79551 9.4563 4.3538C8.88981 4.93017 8.53377 5.72753 8.32238 6.58782C8.11204 7.44389 8.05714 8.31275 8.05714 8.97675C8.05714 9.14822 8.0608 9.30459 8.06618 9.44186ZM17.3062 9.44186C17.3111 9.30144 17.3143 9.14545 17.3143 8.97675C17.3143 8.2454 17.2549 7.25379 17.0081 6.2494C16.7623 5.24922 16.3184 4.18611 15.5134 3.36714C14.6907 2.53007 13.541 2 12 2C10.459 2 9.30927 2.53007 8.48656 3.36714C7.68162 4.18611 7.23766 5.24922 6.9919 6.2494C6.74511 7.25379 6.68571 8.2454 6.68571 8.97675C6.68571 9.14545 6.68888 9.30144 6.69378 9.44186H6.51429C5.12568 9.44186 4 10.5872 4 12V19.4419C4 20.8547 5.12568 22 6.51429 22H17.4857C18.8743 22 20 20.8547 20 19.4419V12C20 10.5872 18.8743 9.44186 17.4857 9.44186H17.3062ZM8.10907 10.0537C8.10908 10.0538 8.10909 10.0538 8.10907 10.0537V10.0537ZM6.51429 10.8372C5.8831 10.8372 5.37143 11.3578 5.37143 12V19.4419C5.37143 20.0841 5.8831 20.6047 6.51429 20.6047H17.4857C18.1169 20.6047 18.6286 20.0841 18.6286 19.4419V12C18.6286 11.3578 18.1169 10.8372 17.4857 10.8372H6.51429Z"
            />
        </svg>
    );
}
