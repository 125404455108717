import React from 'react';
import useTransition from '../../../hooks/transition/useTransition';

export default function GiftsCartHeader() {
    const transition = useTransition();

    return (
        <div className="c3gp_gifts_cart_head">
            {window.innerWidth >= 1000 && (
                <div
                    className="c3gp_gifts_cart_head_return_btn"
                    onClick={() => transition.transitionWithHistoryPush('gifts', '/gifts')}
                >
                    Return <span>to “Gifts”</span>
                </div>
            )}

            <div className="c3gp_gifts_cart_head_title">Cart</div>
            <div className="c3gp_gifts_cart_head_empty" />
        </div>
    );
}
