import React from 'react';
import styles from './styles.module.scss';
import SITE from '../../config/site';
import error_image from '../../img/404-bg.svg';

export default function ErrorPage({
    customError,
    customErrorDescription,
}: {
    customError: string;
    customErrorDescription: string;
}) {
    const goBack = () => (window.location.href = '/');

    return (
        <div className={styles.error_page_wrap}>
            <div className={styles.error_image}>
                <img src={error_image} className={styles.error_image_img} alt="error image" />
                <div className={styles.error_404_text}>
                    {customError?.length ? (
                        <>
                            <div className={styles.small}>{customError}</div>
                        </>
                    ) : (
                        <span className={styles.medium}>
                            Page <br />
                            not found
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.error_page_bottom}>
                <p className={styles.error_subtitle}>Ooops...</p>
                <p className={styles.error_title}>
                    {customErrorDescription?.length
                        ? customErrorDescription
                        : 'We cannot find what you are looking for...'}
                </p>
                <p className={styles.error_bottom_text}>
                    Maybe you are trying to reach a non-existent page. You can go back to the home
                    page
                </p>
                <button type="button" className={styles.error_back_btn} onClick={goBack}>
                    Back to {SITE?.brand}
                </button>
            </div>
        </div>
    );
}
