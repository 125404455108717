import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import '../../styles/main-page/main-select.scss';
import '../../styles/main-page/new-main.scss';
import LoaderGif from '../../components/common/LoaderGif';
import modalStyleProps from '../../styles/react-modal/modal.style';
import useDomainRedirect from '../../hooks/transition/useDomainRedirect';
import useInitResize from '../../hooks/resize/useInitResize';
import { ARRAY_STATIC_PAGE } from '../../constants/menu';
import useInitTerm from '../../hooks/term/useInitTerm';
import { RootState } from '../../index';
import { IMainLayoutProps } from './index.interface';

export default function MainLayout({ children }: IMainLayoutProps) {
    const termsState = useSelector((state: RootState) => state.terms);
    const activeGifLoader = useSelector((state: RootState) => state.loader.activeGifLoader);

    const initTerm = useInitTerm();
    useInitResize();
    useDomainRedirect();

    useEffect(() => {
        document.querySelector('body')?.classList.add('main_page_content');
        document.querySelector('html')?.classList.add('main_page_content');

        const queryPageName = ARRAY_STATIC_PAGE?.find((item) =>
            window?.location?.search?.includes(`${item}=open`),
        );
        if (queryPageName && queryPageName !== termsState?.pageName)
            initTerm.openTermModal(queryPageName);
    }, []);

    return (
        <>
            {children}
            <Modal
                style={modalStyleProps({ isLoaderModal: true })}
                isOpen={activeGifLoader}
                ariaHideApp={false}
            >
                <LoaderGif />
            </Modal>
        </>
    );
}
