import Base from './Base';
import { objectToArray } from '../methods';
import {
    IAddBlackListData,
    IReadAudioData,
    IReadImageData,
    IReadVideoData,
    IRemoveBlackListData,
    ISetFavoriteData,
    IGetChatListReq,
    IGetChatListItem,
} from './ChatsApi.interface';
import { CancelToken } from 'axios';

export default class ChatsAPI extends Base {
    async postBlockListByUserId(isOnline: boolean, searchItem: string, page: number) {
        try {
            const res = await super.post(`chat/blockListByUserID`, {
                ONLINE_STATUS: isOnline,
                SEARCH: searchItem,
                page,
            });
            return res?.data?.status
                ? {
                      chats: objectToArray(res?.data?.chats),
                      status: res?.data?.status,
                      message: 'OK',
                  }
                : {
                      chats: [],
                      status: res?.data?.status,
                      message: res?.data?.error,
                  };
        } catch {
            return { canceled: true };
        }
    }

    async postMessage(
        senderId: number,
        recipientId: number,
        messageContent: string,
        messageType: string,
        filename = '',
        token: CancelToken | null = null,
    ) {
        try {
            const res = await super.post(
                `chat/message`,
                {
                    sender_id: senderId,
                    recipient_id: recipientId,
                    message_content: messageContent,
                    message_type: messageType,
                    filename: filename ? filename : '',
                },
                token ? { cancelToken: token } : {},
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async getHistory(chatUid: string, page = 1, token: CancelToken | null = null) {
        try {
            const res = await super.post(
                `chatList/manChatHistory`,
                {
                    chat_id: chatUid,
                    page: page,
                },
                token
                    ? { ...this.getAuthHeader(), ...{ cancelToken: token } }
                    : this.getAuthHeader(),
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async getHistoryMedia(chatId: string, token = null) {
        try {
            return await super.postThirdParty(
                `/chatList/media`,
                {
                    chat_id: chatId,
                },
                token
                    ? { ...this.getAuthHeader(), ...{ cancelToken: token } }
                    : this.getAuthHeader(),
            );
        } catch {
            return { canceled: true };
        }
    }

    async postSetFavorite({ userId, chat_uid, chat_id, favorite, token = null }: ISetFavoriteData) {
        try {
            const res = await super.post(
                `chat/setFavorite`,
                {
                    user_id: userId,
                    ...(chat_uid ? { chat_uid } : {}),
                    ...(chat_id ? { chat_id } : {}),
                    favorite: favorite ? 1 : 0,
                },
                token ? { cancelToken: token } : {},
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async postAddBlackList({ chatId, femaleExternalId, token = null }: IAddBlackListData) {
        try {
            const res = await super.post(
                `chat/addBlackList`,
                {
                    ...(chatId ? { chat_uid: chatId } : {}),
                    ...(femaleExternalId ? { female_external_id: femaleExternalId } : {}),
                },
                token ? { cancelToken: token } : {},
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async postRemoveBlackList({ chatId, femaleExternalId, token = null }: IRemoveBlackListData) {
        try {
            const res = await super.post(
                `chat/removeBlackList`,
                {
                    ...(chatId ? { chat_uid: chatId } : {}),
                    ...(femaleExternalId ? { female_external_id: femaleExternalId } : {}),
                },
                token ? { cancelToken: token } : {},
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async postReadVideo({ userId, messageId, chatUid }: IReadVideoData) {
        const res = await super.post(`chat/readVideo`, {
            user_id: userId,
            message_id: messageId,
            chat_uid: chatUid,
        });
        return res?.data;
    }

    async postReadImage({ messageId, chatUid }: IReadImageData) {
        const res = await super.post(`chat/readImage`, {
            message_id: messageId,
            chat_uid: chatUid,
        });
        return res?.data;
    }

    async postReadAudio({ userId, messageId, chatUid }: IReadAudioData) {
        const res = await super.post(`chat/readAudio`, {
            user_id: userId,
            message_id: messageId,
            chat_uid: chatUid,
        });
        return res?.data;
    }

    async getCounters(userId: number, token?: CancelToken) {
        try {
            const res = await super.get(
                `chat/counters?user_id=${userId}`,
                token ? { cancelToken: token } : {},
            );
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async postHideChat(userId: number, chatUid: string) {
        const res = await super.post(`chat/hideChat`, {
            user_id: userId,
            chat_uid: chatUid,
        });
        return res?.data;
    }

    async postViewMedia(userId: number, mediaId: number, chatUid: string) {
        const res = await super.post(`chat/viewMedia`, {
            user_id: userId,
            media_id: mediaId,
            chat_uid: chatUid,
        });
        return res?.data;
    }

    async postReadChat(chatUid: string) {
        const res = await super.post(`chat/readChat`, {
            chat_uid: chatUid,
        });
        return res?.data;
    }

    async postMeetingRequest(female_external_id: number) {
        const res = await super.post(`chat/meetingRequest`, { female_external_id });
        return res?.data;
    }

    async getSayHelloList() {
        try {
            const res = await super.get('chat/sayHelloList');
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async postSetChatPinned(chat_uid: string, pinned: number) {
        try {
            const res = await super.post(`chat/setPinned`, { chat_uid, pinned });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async sendReplyPost(post_id: number, message_content: string, message_type: string) {
        try {
            const res = await super.post(`chat/replyPost`, {
                post_id,
                message_content,
                message_type,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getChatList(data: IGetChatListReq): Promise<IGetChatListItem[]> {
        return super
            .post('chatList/manCombinedInfo', {
                ONLINE_STATUS: data?.online,
                SEARCH: data?.search,
                CHAT_TYPE: data?.type,
                page: data?.page,
            })
            .then((res) => (res?.data?.status ? res?.data?.response : null))
            .catch(() => null);
    }

    async getChatListItem(chatUID: string): Promise<IGetChatListItem> {
        return super
            .post('chatList/manChatCombinedInfo', { chat_uid: chatUID })
            .then((res) => (res?.data?.status ? res?.data?.response : null))
            .catch(() => null);
    }
}
