import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MessageLeft from '../../../../common/MessageBlocks/MessageLeft';
import MessageRight from '../../../../common/MessageBlocks/MessageRight';
import LineNewMessage from '../../../../common/MessageBlocks/LineNewMessage';
import MessageListEmpty from './MessageListEmpty';
import * as MENU from '../../../../../constants/menu';
import * as CHATS from '../../../../../constants/chats';
import * as currentChatAction from '../../../../../store/actions/currentChat';
import LoaderGif from '../../../../common/LoaderGif';
import { filterToHideChatHistoryByType } from '../../../../../services/methods/chat';
import { scrollTopToMainChatWithPagination } from '../../../../../services/dom';
import { uniqueArrayByID } from '../../../../../services/methods';
import VirtualGiftRow from '../../../../common/MessageBlocks/VirtualGiftRow';
import { IMessageListProps } from './index.interface';
import { RootState } from '../../../../../index';
import IconArrowDown from '../../../../Icons/IconArrowDown';
import { isHolidayActive } from '../../../../../constants/holiday';

export default function MessageList({
    onScrollList,
    onScrollDown,
    filterByPageScrollList,
    isLoadingRequest,
    setIsLoadingRequest,
}: IMessageListProps) {
    const chatsState = useSelector((state: RootState) => state.chats);
    const menuState = useSelector((state: RootState) => state.menu);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);

    const [scrollUp, setScrollUp] = useState(false);
    const [messageIdArr, setMessageIdArr] = useState<number[]>([]);

    const dispatch = useDispatch();
    const activeHoliday = isHolidayActive();

    let isActiveNewMess = true;

    useEffect(() => {
        return () => {
            const scrollDiv = document?.querySelector(`.c3_chat_scroll`) as HTMLElement;

            if (scrollDiv?.offsetHeight + scrollDiv?.scrollTop === scrollDiv?.scrollHeight) {
                dispatch(currentChatAction.setIsNewMessage(false));
            }
        };
    }, [currentChatState.isNewMessage]);

    useEffect(() => {
        if (currentChatState?.history?.length) {
            const scrollDiv = document?.querySelector(`.c3_chat_scroll`) as HTMLElement;

            if (!scrollUp) {
                if (currentChatState?.isReadLastMessage) onScrollDown();
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            }

            if (scrollDiv) {
                if (
                    Math.round(scrollDiv?.scrollTop + scrollDiv?.offsetHeight) + 50 <
                    scrollDiv?.scrollHeight
                ) {
                    setScrollUp(true);
                }
            }
        }
    }, [currentChatState.history]);

    return (
        <div
            className={`c3_chat_scroll ${menuState.main === MENU.MENU_BLOCK ? 'block_list' : ''} ${activeHoliday && activeHoliday?.name}`}
            onScroll={(e) => onScrollList(e, setScrollUp)}
            data-testid="message-list"
        >
            {currentChatState?.historyPageLoading && <LoaderGif />}

            <div className="c3_chat">
                {Array.isArray(currentChatState?.history) &&
                currentChatState?.history?.filter(filterToHideChatHistoryByType)?.length ? (
                    uniqueArrayByID(currentChatState?.history?.filter(filterByPageScrollList))?.map(
                        (item) => {
                            let lastDate = new Date()?.toString();
                            const newMessage = chatsState?.newMessageList?.find(
                                (newMess) => newMess?.chat_uid === item?.chat_uid,
                            );

                            lastDate = newMessage?.last_message_date ?? lastDate;

                            if (newMessage?.first && +newMessage?.unread_count > 0) {
                                lastDate =
                                    currentChatState?.history[
                                        +currentChatState?.history?.length -
                                            +newMessage?.unread_count -
                                            1
                                    ]?.date_created;
                            }

                            if (item?.message_type === CHATS.MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT) {
                                isActiveNewMess = false;

                                return (
                                    <React.Fragment key={item?.id}>
                                        {moment(item?.date_created).diff(
                                            moment(lastDate),
                                            'seconds',
                                        ) > 0 &&
                                            userState?.info?.external_id !==
                                                item?.sender_external_id && <LineNewMessage />}
                                        <VirtualGiftRow
                                            key={item?.id}
                                            isRecipientSide={
                                                userState?.info?.external_id !==
                                                +item?.sender_external_id
                                            }
                                            message={item}
                                        />
                                    </React.Fragment>
                                );
                            } else if (userState?.info?.external_id === item?.sender_external_id) {
                                return <MessageRight key={item?.id} message={item} />;
                            } else {
                                if (
                                    moment(item?.date_created).diff(moment(lastDate), 'seconds') >
                                        0 &&
                                    isActiveNewMess
                                ) {
                                    isActiveNewMess = false;
                                    return (
                                        <React.Fragment key={item?.id}>
                                            <LineNewMessage />
                                            <MessageLeft
                                                message={item}
                                                isLoadingRequest={isLoadingRequest}
                                                setIsLoadingRequest={setIsLoadingRequest}
                                                messageIdArr={messageIdArr}
                                                setMessageIdArr={setMessageIdArr}
                                            />
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <MessageLeft
                                            message={item}
                                            key={item?.id}
                                            isLoadingRequest={isLoadingRequest}
                                            setIsLoadingRequest={setIsLoadingRequest}
                                            messageIdArr={messageIdArr}
                                            setMessageIdArr={setMessageIdArr}
                                        />
                                    );
                                }
                            }
                        },
                    )
                ) : currentChatState?.status ? (
                    menuState?.main === MENU.MENU_BLOCK ? (
                        <div />
                    ) : (
                        <MessageListEmpty />
                    )
                ) : (
                    <LoaderGif />
                )}
            </div>

            {scrollUp && (
                <div className="c3_chat_go_to_new_message" onClick={onScrollDown}>
                    {!!currentChatState?.isNewMessage && (
                        <span className="c3_chat_go_to_new_message_counter">New</span>
                    )}
                    <IconArrowDown />
                </div>
            )}
        </div>
    );
}
