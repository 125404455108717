import React from 'react';

export default function IconEditBlack() {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon_edit">
                <g id="Group 3286">
                    <path
                        id="Vector"
                        d="M5.19151 15.6499L16.1296 4.71095C16.5617 4.29743 17.1386 4.06953 17.7367 4.07606C18.3348 4.0826 18.9066 4.32305 19.3296 4.74593C19.7525 5.1688 19.9932 5.74049 19.9999 6.33857C20.0066 6.93665 19.7788 7.51358 19.3654 7.94582L8.42549 18.8848C8.17592 19.1345 7.85806 19.3046 7.51196 19.3737L4 20.0763L4.70257 16.5635C4.7718 16.2174 4.94193 15.8995 5.19151 15.6499Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path id="Vector_2" d="M14.2793 7.11548L16.9609 9.79705" strokeWidth="1.5" />
                </g>
            </g>
        </svg>
    );
}
