import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import styles from './styles.module.scss';
import ConnectionLostImage from '../../../../img/connection_lost.svg';
import IconCross from '../../../Icons/IconCross';
import IconUpdate from '../../../Icons/IconUpdate';

export const ConnectionLostModal = () => {
    const [isModalOpen, setModalOpen] = useState<boolean>(!navigator.onLine);

    useEffect(() => {
        const checkConnection = () => {
            setModalOpen(!navigator.onLine);
        };
        const interval = setInterval(checkConnection, 2000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Modal
            style={modalStyleProps({ isShowModal: isModalOpen })}
            isOpen={true}
            onRequestClose={() => setModalOpen(false)}
            ariaHideApp={false}
        >
            <div className={styles.connection_lost_wrapper}>
                <button
                    type="button"
                    className={styles.connection_lost_button_close}
                    onClick={() => setModalOpen(false)}
                >
                    <IconCross />
                </button>
                <div className={styles.connection_lost_container}>
                    <h1 className={styles.connection_lost_title}>Connection Lost</h1>
                    <img
                        className={styles.connection_lost_image}
                        src={ConnectionLostImage}
                        alt="Connection Lost"
                    />
                    <p className={styles.connection_lost_subtitle}>
                        It seems like you’ve lost your internet connection. Please check your
                        network and try again
                    </p>
                    <button
                        type="button"
                        className={styles.connection_lost_button}
                        onClick={() => window.location.reload()}
                    >
                        <IconUpdate />
                        <span className={styles.connection_lost_button_text}>Reload page</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
};
