import React from 'react';
import styles from '../../styles.module.scss';
import Logotypes from '../../../../../img/logotypes';
import SITE from '../../../../../config/site';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../index';
import { isHolidayActive } from '../../../../../constants/holiday';

export default function MenuLogo() {
    const userState = useSelector((state: RootState) => state.user);
    const activeHoliday = isHolidayActive();

    return (
        <div className={styles.c1_logo_wrap} data-testid="logo">
            {userState?.info?.is_verified ? (
                <img src={Logotypes.images['logo_vip.svg']} alt={SITE.name} />
            ) : activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}.svg`] ? (
                <img src={Logotypes.images[`logo_${activeHoliday?.name}.svg`]} alt={SITE.brand} />
            ) : (
                <img src={Logotypes.images[`logo.svg`]} alt={SITE.brand} />
            )}
        </div>
    );
}
