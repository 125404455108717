import * as chatsAction from '../../store/actions/chats';
import * as likesAction from '../../store/actions/likes';
import * as currentChatAction from '../../store/actions/currentChat';
import { useDispatch, useSelector } from 'react-redux';
import * as notifyAction from '../../store/actions/notify';
import moment from 'moment';
import * as alertAction from '../../store/actions/alert';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import * as MENU from '../../constants/menu';
import { getChatListType } from '../../services/methods/chat';
import { useLocation } from 'react-router-dom';
import { insertedMessageBeforeSync } from '../../services/init';
import useCheckSpecialPaidAction from '../user/useCheckSpecialPaidAction';
import * as userAction from '../../store/actions/user';
import useActiveButton from '../activeBtn/useActiveButton';
import useSendActionSync from '../frontSync/useSendActionSync';
import * as FRONT_SYNC from '../../constants/frontSync';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useHandleRedUser from '../user/useHandleRedUser';
import useActionLike from './useActionLike';
import { RootState } from '../../index';

export default function useLike() {
    const dispatch = useDispatch();
    const location = useLocation();
    const checkSpecialPaidAction = useCheckSpecialPaidAction();
    const activeButton = useActiveButton();
    const sendActionSync = useSendActionSync();
    const handleRedUser = useHandleRedUser();
    const actionLike = useActionLike();

    const userState = useSelector((state: RootState) => state.user);
    const chatsState = useSelector((state: RootState) => state.chats);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const fetchLike = async (womanExternalID: number) => {
        const hashId = moment().unix();

        return (
            dispatch(likesAction.setLike(womanExternalID)) as unknown as Promise<{
                status: boolean;
                block: boolean;
                message: string;
                title: string;
                response: { match_object: { chat_uid: string; sender_external_id: number } };
            }>
        ).then((res) => {
            if (res?.status) {
                if (checkSpecialPaidAction()) {
                    actionLike.callbackAfterLike(womanExternalID);
                    dispatch(userAction.setBalance());
                }

                if (res?.response?.match_object?.chat_uid) {
                    dispatch(
                        notifyAction.addTransactionNotify({
                            id: moment().unix() + res?.response?.match_object?.sender_external_id,
                            ...res?.response?.match_object,
                            chat_uid: res?.response?.match_object?.chat_uid,
                        }),
                    );
                }

                if (
                    window.location.pathname.includes(MENU.MENU_LIKES) &&
                    userState?.info?.external_id
                ) {
                    dispatch(
                        chatsAction.getChatListByUserId(
                            userState?.info?.external_id,
                            chatsState?.onlineStatus,
                            chatsState?.searchInput,
                            getChatListType(location?.pathname),
                        ),
                    );
                }

                if (
                    currentChatState?.info?.id === +womanExternalID &&
                    userState?.info?.external_id
                ) {
                    dispatch(
                        currentChatAction.addMessageToHistory(
                            insertedMessageBeforeSync(
                                hashId,
                                `${userState?.info?.name} liked your profile`,
                                userState?.info?.external_id,
                                'SENT_LIKE',
                            ),
                        ),
                    );
                }
                sendActionSync.postUserAction(+womanExternalID, FRONT_SYNC.ACTION_LIKE);
                activeButton.onDefaultBlockBtn();
                handleRedUser.submitLog(
                    RED_USER_LOG_TYPES.MESSAGE_FROM,
                    JSON.stringify({
                        womanExternalID: womanExternalID,
                        action: 'LIKE',
                        content: `${userState?.info?.name} liked your profile`,
                    }),
                );
            } else {
                activeButton.onDefaultBlockBtn();

                if (res?.block) {
                    dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                }
            }

            return res;
        });
    };

    return async (womanExternalID: number): Promise<{ status: boolean } | null> => {
        if (activeButton.onBlockBtn()) return null;

        if (!checkSpecialPaidAction()) {
            actionLike.callbackAfterLike(womanExternalID);
            return fetchLike(womanExternalID);
        } else {
            return fetchLike(womanExternalID);
        }
    };
}
