import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import RequestApi from '../../services/api/Requests';
import * as ALERT from '../../constants/alert';
import * as requestsAction from '../../store/actions/requests';
import * as ERROR from '../../constants/error';
import { RootState } from '../../index';
import { IContactRequestProfile } from '../../components/common/Modals/ContactRequestModal/index.interface';

export default function useInitContactRequestModal(profile?: IContactRequestProfile) {
    const dispatch = useDispatch();

    const requestsState = useSelector((state: RootState) => state.requests);
    const { showContactRequestModal } = requestsState;

    const enabledContactRequest =
        profile?.id &&
        requestsAction.isEnabledContactRequest(profile?.id, requestsState.activeContactRequests);

    const closeContactRequestModal = () => dispatch(requestsAction.closeContactRequest());

    const openContactRequest = () => {
        if (!enabledContactRequest) return false;

        dispatch(requestsAction.openContactRequest(profile));
    };

    const handleSubmit = () => {
        if (!profile?.id) return;

        new RequestApi().sendContactRequest({ female_external_id: profile?.id }).then((res) => {
            if (res?.data?.status) {
                dispatch(
                    alertAction.setCustomAlertType({ alertType: ALERT.SUCCESS_CONTACT_REQUEST }),
                );
                dispatch(requestsAction.getActiveContactRequests());
                dispatch(userAction.setBalance());

                closeContactRequestModal();
            } else {
                if (+res?.data?.credits) {
                    dispatch(
                        alertAction.setCustomAlertType({
                            alertType: ALERT.ENOUGH_CREDITS_REQUEST,
                            message: res?.data?.credits,
                        }),
                    );
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.data?.message || ERROR.ERROR_REQUEST,
                            title: res?.data?.title,
                        }),
                    );
                }
            }
        });
    };

    useEffect(() => {
        if (profile?.id) {
            dispatch(requestsAction.getActiveContactRequests());
        }

        return () => {
            closeContactRequestModal();
        };
    }, []);

    return {
        enabledContactRequest,
        openContactRequest,
        showContactRequestModal,
        closeContactRequestModal,
        handleSubmit,
    };
}
