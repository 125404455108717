import React from 'react';

export default function IconArrowLeft() {
    return (
        <svg
            width="41"
            height="27"
            viewBox="0 0 41 27"
            fill="#374E6C"
            fillOpacity="0.56"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_30)" transform="matrix(-1, 0, 0, 1, 41.375, 0)">
                <path d="M40.1427 15.1192L28.2394 26.3625C27.5131 27.0484 26.3353 27.0484 25.609 26.3625C24.8826 25.6763 24.8826 24.5641 25.609 23.878L34.3373 15.6337H2.54739C1.52029 15.6337 0.6875 14.8471 0.6875 13.877C0.6875 12.907 1.52029 12.1202 2.54739 12.1202H34.3373L25.6093 3.87595C24.8829 3.18983 24.8829 2.07759 25.6093 1.39147C25.9724 1.04869 26.4485 0.876953 26.9245 0.876953C27.4005 0.876953 27.8765 1.04869 28.2397 1.39147L40.1427 12.6347C40.8691 13.3209 40.8691 14.4331 40.1427 15.1192Z" />
            </g>
            <defs>
                <clipPath id="clip0_1_30">
                    <rect width="41" height="27" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
