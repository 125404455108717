import React from 'react';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Modal from 'react-modal';
import * as modalsAction from '../../../../store/actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import PaymentRouting from './PaymentRouting';
import { RootState } from '../../../../index';

export default function PaymentRoutingModal() {
    const modalsState = useSelector((state: RootState) => state.modals);

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(modalsAction.closePaymentRoutingModal());
    };

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={modalsState?.paymentRoutingModal?.isActive}
            onRequestClose={onClose}
            ariaHideApp={false}
        >
            <PaymentRouting onClose={onClose} />
        </Modal>
    );
}
