import { TutorialActionTypes } from '../../constants/tutorial';
import { ITutorialState, TutorialActions } from './index.interface';

const initState = {
    tutorialData: [],
    activeTopicId: null,
    activeTopicTitle: null,
    activeTopicText: null,
    activeCategoryId: null,
};

export default function (
    state: ITutorialState = initState,
    action: TutorialActions,
): ITutorialState {
    switch (action.type) {
        case TutorialActionTypes.GET_TUTORIAL_DATA:
            return { ...state, ...{ tutorialData: action?.data } };

        case TutorialActionTypes.SET_ACTIVE_TUTORIAL_TOPIC_ID:
            // eslint-disable-next-line no-case-declarations
            const foundActiveTopic = state?.tutorialData?.find(
                (el) => +el?.id === +action?.activeTopicId,
            );

            return {
                ...state,
                ...{
                    activeTopicId: action?.activeTopicId,
                    activeTopicTitle: foundActiveTopic?.title,
                    activeTopicText: foundActiveTopic?.text,
                },
            };

        case TutorialActionTypes.SET_ACTIVE_CATEGORY_ID:
            return {
                ...state,
                ...{
                    activeCategoryId: action?.activeCategoryId,
                },
            };

        default:
            return state;
    }
}
