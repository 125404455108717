export const FORM_DATA = {
    step1: {
        text1: 'Too many letters',
        text2: 'Monotonous content',
        text3: 'No longer interested in the product',
        text4: 'Technical problems',
        text5: 'Other',
    },
    step2: {
        text1: 'Notifications of unanswered and unread messages',
        text2: 'New users on the site',
        text3: 'Discounts and promotional offers',
        text4: 'Other',
    },
    step3: {
        text1: 'Change the number of letters',
        text2: 'Variety of content',
        text3: 'Communicate excise offers more often',
        text4: 'Other',
    },
};

export const MAX_LENGTH_TEXTAREA = 4000;
