import WomanVideoShow from './WomanVideoShow';
import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as modalsAction from '../../../../store/actions/modals';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import VideoShowApi from '../../../../services/api/VideoShowApi';
import * as alertAction from '../../../../store/actions/alert';
import * as ALERT from '../../../../constants/alert';
import * as userAction from '../../../../store/actions/user';
import * as loaderAction from '../../../../store/actions/loader';
import * as FRONT_SYNC from '../../../../constants/frontSync';
import useSendActionSync from '../../../../hooks/frontSync/useSendActionSync';
import { sendErrToSentry } from '../../../../services/sentry';
import { RootState } from '../../../../index';
import { IWomanInfo } from './WomanVideoShow/index.interface';

export default function WomanVideoShowModal() {
    const dispatch = useDispatch();
    const sendActionSync = useSendActionSync();

    const modalsState = useSelector((state: RootState) => state.modals);

    const [womanInfo, setWomanInfo] = useState<IWomanInfo | null>(null);
    const [videoLink, setVideoLink] = useState('');
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const closeWomanVideoShowModal = () => {
        dispatch(modalsAction.closeWomanVideoShowModal());
        setWomanInfo(null);
        setVideoLink('');
    };

    useEffect(() => {
        if (modalsState?.womanVideoShowModal?.externalID) {
            dispatch(loaderAction.setActiveGifLoader(true));
            try {
                new VideoShowApi()
                    .getVideoByExternalID(modalsState?.womanVideoShowModal?.externalID)
                    .then((res) => {
                        if (res?.status && res?.result?.length > 0) {
                            setWomanInfo(res?.result[0]);
                            sendActionSync.postUserAction(
                                Number(modalsState?.womanVideoShowModal?.externalID),
                                FRONT_SYNC.ACTION_VIEW_VIDEO_SHOW,
                            );
                        } else closeWomanVideoShowModal();
                    })
                    .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
            } catch (error) {
                sendErrToSentry(error as Error);
                dispatch(loaderAction.setActiveGifLoader(false));
            }
        } else {
            closeWomanVideoShowModal();
        }
    }, [modalsState?.womanVideoShowModal?.externalID]);

    useEffect(() => {
        if (womanInfo?.external_id) {
            if (isLoadingRequest) return;
            setIsLoadingRequest(true);
            dispatch(loaderAction.setActiveGifLoader(true));
            try {
                new VideoShowApi()
                    .getVideo(womanInfo?.id)
                    .then((res) => {
                        if (res?.status) {
                            if (res?.link) setVideoLink(res?.link);
                        } else {
                            dispatch(modalsAction.closeWomanVideoShowModal());

                            if (res?.message)
                                dispatch(
                                    alertAction.setMessage({
                                        message: res?.message,
                                        title: res?.title,
                                    }),
                                );
                            else
                                dispatch(
                                    alertAction.setCustomAlertType({
                                        alertType: ALERT.ENOUGH_CREDITS_VIDEOSHOW,
                                    }),
                                );
                        }

                        dispatch(userAction.setBalance());
                    })
                    .finally(() => {
                        dispatch(loaderAction.setActiveGifLoader(false));
                        setIsLoadingRequest(false);
                    });
            } catch (error) {
                sendErrToSentry(error as Error);
                dispatch(loaderAction.setActiveGifLoader(false));
            }
        }
    }, [womanInfo]);

    return videoLink?.length ? (
        <Modal
            style={modalStyleProps({ isAttachment: true })}
            isOpen={modalsState?.womanVideoShowModal?.isActive}
            onRequestClose={closeWomanVideoShowModal}
            ariaHideApp={false}
        >
            <WomanVideoShow
                videoLink={videoLink}
                womanInfo={womanInfo}
                closeWomanVideoShowModal={closeWomanVideoShowModal}
            />
        </Modal>
    ) : (
        <div />
    );
}
