import React from 'react';

export default function IconVerificationDone() {
    return (
        <svg
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M73 37C73 41.7276 72.0688 46.4089 70.2597 50.7766C68.4505 55.1443 65.7988 59.1129 62.4558 62.4558C59.1129 65.7988 55.1443 68.4505 50.7766 70.2597C46.4089 72.0688 41.7276 73 37 73C32.2724 73 27.5911 72.0688 23.2234 70.2597C18.8557 68.4505 14.8871 65.7988 11.5442 62.4558C8.20125 59.1129 5.5495 55.1443 3.74034 50.7766C1.93117 46.4089 1 41.7276 1 37C1 32.2724 1.93117 27.5911 3.74034 23.2234C5.54951 18.8557 8.20125 14.8871 11.5442 11.5442C14.8871 8.20124 18.8557 5.5495 23.2234 3.74034C27.5911 1.93117 32.2724 0.999999 37 1C41.7276 1 46.4089 1.93117 50.7766 3.74034C55.1443 5.54951 59.1129 8.20125 62.4558 11.5442C65.7988 14.8871 68.4505 18.8557 70.2597 23.2234C72.0688 27.5911 73 32.2724 73 37L73 37Z"
                stroke="#1C9D53"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g clipPath="url(#clip0_230_19336)">
                <path
                    d="M42.3914 28.6771C42.0571 29.0114 42.0571 29.5514 42.3914 29.8857L44.1057 31.6C44.2771 31.7714 44.4914 31.8486 44.7143 31.8486C44.9371 31.8486 45.1514 31.7629 45.3229 31.6L48.7514 28.1714C49.0857 27.8371 49.0857 27.2971 48.7514 26.9629C48.4171 26.6286 47.8771 26.6286 47.5429 26.9629L44.7229 29.7829L43.6171 28.6771C43.2829 28.3429 42.7429 28.3429 42.4086 28.6771H42.3914Z"
                    fill="#1C9D53"
                />
                <path
                    d="M46.5314 32.8171C46.0429 33.3057 45.4 33.5714 44.7143 33.5714C44.0286 33.5714 43.3857 33.3057 42.8971 32.8171L41.1829 31.1029C40.18 30.1 40.18 28.4714 41.1829 27.4686C41.7314 26.92 42.4686 26.68 43.1886 26.7314C41.38 25.6429 39.2714 25 37 25C30.3743 25 25 30.3743 25 37C25 43.6257 30.3743 49 37 49C43.6257 49 49 43.6257 49 37C49 35.0629 48.5286 33.2457 47.7229 31.6343L46.5314 32.8257V32.8171ZM37 31.8571C39.3657 31.8571 41.2857 33.7771 41.2857 36.1429C41.2857 38.5086 39.3657 40.4286 37 40.4286C34.6343 40.4286 32.7143 38.5086 32.7143 36.1429C32.7143 33.7771 34.6343 31.8571 37 31.8571ZM37 46.4286C34.4457 46.4286 32.1229 45.4086 30.4257 43.7543C30.8543 42.4086 31.7543 41.2857 32.92 40.5314C33.9914 41.5257 35.4229 42.1429 37 42.1429C38.5771 42.1429 40.0086 41.5257 41.08 40.5314C42.2457 41.2771 43.1457 42.4086 43.5743 43.7543C41.8771 45.4086 39.5629 46.4286 37 46.4286Z"
                    fill="#1C9D53"
                />
            </g>
            <defs>
                <clipPath id="clip0_230_19336">
                    <rect width="24" height="24" fill="white" transform="translate(25 25)" />
                </clipPath>
            </defs>
        </svg>
    );
}
