import React from 'react';

export default function IconLockPlain() {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8807_63106)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.20898 1.62891C6.46563 1.62891 5.74748 1.93874 5.21403 2.49885C4.6798 3.0598 4.37565 3.8256 4.37565 4.62891V6.92891H10.0423V4.62891C10.0423 3.8256 9.73815 3.0598 9.20394 2.49884C8.67049 1.93873 7.95235 1.62891 7.20898 1.62891ZM11.0423 6.92891V4.62891C11.0423 3.57569 10.6441 2.56101 9.92809 1.80921C9.2113 1.05658 8.23374 0.628906 7.20898 0.628906C6.18424 0.628906 5.20669 1.05657 4.4899 1.8092C3.77389 2.56101 3.37565 3.5757 3.37565 4.62891V6.92891H2.54232C1.5071 6.92891 0.708984 7.80283 0.708984 8.82891V13.7289C0.708984 14.755 1.5071 15.6289 2.54232 15.6289H11.8757C12.9109 15.6289 13.709 14.755 13.709 13.7289V8.82891C13.709 7.80283 12.9109 6.92891 11.8757 6.92891H11.0423ZM2.54232 7.92891C2.10479 7.92891 1.70898 8.30858 1.70898 8.82891V13.7289C1.70898 14.2492 2.10479 14.6289 2.54232 14.6289H11.8757C12.3132 14.6289 12.709 14.2492 12.709 13.7289V8.82891C12.709 8.30858 12.3132 7.92891 11.8757 7.92891H2.54232Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_8807_63106">
                    <rect width="14" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
