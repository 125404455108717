import { SyncActionTypes } from '../../constants/sync';

import syncService from '../../../services/sync';
import { Dispatch } from 'redux';

export function init() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: SyncActionTypes.INIT,
            data: syncService(),
        });
    };
}
export function remove() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: SyncActionTypes.REMOVE,
            data: null,
        });
    };
}
