import React, { useEffect, useState } from 'react';
import * as DICTIONARY_MAIN_PAGE from '../../../constants/dictionary/main-page';
import VimeoApi from '../../../services/api/VimeoApi';
import ProfileApi from '../../../services/api/ProfileApi';
import { sendErrToSentry } from '../../../services/sentry';
import { IGirlVideoProps } from './index.interface';
import IconPlayVideo from '../../Icons/IconPlayVideo';
import IconChat from '../../Icons/IconChat';
import ua_flag from '../../../img/ua.svg';
import avatar_placeholder from '../../../img/placeholder.gif';

export default function GirlVideo({
    item,
    openRegisterModal,
    openVideoModal,
    type,
    typeClass,
}: IGirlVideoProps) {
    const [videoPoster, setVideoPoster] = useState('');

    useEffect(() => {
        if (item?.preview_link?.length) {
            setVideoPoster(item?.preview_link);
        } else {
            try {
                new VimeoApi().getThumbnail(item?.thumbnail_id).then((vimeoRes) => {
                    typeClass === 'auto-open-woman-profile'
                        ? window.innerWidth < 1000
                            ? setVideoPoster(vimeoRes?.data[0]?.sizes[3]?.link ?? '')
                            : setVideoPoster(vimeoRes?.data[0]?.sizes[4]?.link ?? '')
                        : setVideoPoster(vimeoRes?.data[0]?.sizes[3]?.link ?? '');

                    new ProfileApi().showNewVimeoThumb(
                        item?.id,
                        vimeoRes?.data[0]?.sizes[3]?.link ?? '',
                    );
                });
            } catch (error) {
                sendErrToSentry(error as Error);
                setVideoPoster('');
            }
        }
    }, []);

    // for video slider
    if (type === 'simple') {
        return (
            <div className="">
                <div
                    className={`video_aspect_ratio_container ${typeClass}`}
                    data-testid="button-open-video-modal"
                    onClick={() =>
                        item?.thumbnail_id &&
                        openVideoModal(`https://player.vimeo.com/video/${item?.thumbnail_id}/`)
                    }
                >
                    <img
                        src={videoPoster}
                        alt="video-poster"
                        className="c3mp_video_list_item_img"
                    />
                    <div className="c3mp_video_list_item_play_btn">
                        <IconPlayVideo />
                    </div>
                    {item?.cost_message?.length && (
                        <span className="c3mp_video_list_item_free">{item?.cost_message}</span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={`c3mp_video_list_item ${typeClass}`}>
            <div
                className="c3mp_video_list_item_img_wrap"
                onClick={() =>
                    item?.thumbnail_id &&
                    openVideoModal({
                        link: `https://player.vimeo.com/video/${item?.thumbnail_id}/`,
                        external_id: item.external_id,
                        name: item.name,
                        avatar: item.avatar,
                        country_icon: item.country_icon,
                        city_name: item.city_name,
                        age: item.age,
                    })
                }
            >
                <img src={videoPoster} alt="video-poster" className="c3mp_video_list_item_img" />
                <div className="c3mp_video_list_item_play_btn">
                    <IconPlayVideo />
                </div>
            </div>
            <div className="c3mp_video_list_item_bottom">
                <div className="c3mp_video_list_item_profile">
                    <div
                        className={`c3mp_video_list_item_profile_img ${item?.online ? 'online' : ''}`}
                    >
                        <img src={item?.avatar || avatar_placeholder} alt="avatar" />
                    </div>
                    <div className="c3mp_video_list_item_profile_info">
                        <span className="c3mp_video_list_item_profile_name">{`${item?.name}, ${item?.age}`}</span>
                        <div className="c3mp_video_list_item_profile_place">
                            <img
                                src={item?.country_icon ? item?.country_icon : ua_flag}
                                alt="country flag"
                            />
                            <span>{item?.city_name ? item?.city_name : ''}</span>
                        </div>
                    </div>
                </div>
                <div className="c3mp_video_list_item_buttons">
                    <div
                        className="c3mp_video_list_item_bottom_chat"
                        onClick={() => {
                            openRegisterModal &&
                                openRegisterModal(`/profile-woman/${item?.public_external_id}`);
                        }}
                    >
                        <IconChat />
                        <span>{DICTIONARY_MAIN_PAGE.GIRLS_VIDEO_GIRL_ITEM_CHAT_BUTTON}</span>
                    </div>
                </div>
            </div>
            <span className="c3mp_video_list_item_free">
                {DICTIONARY_MAIN_PAGE.GIRLS_VIDEO_GIRL_ITEM_FREE_LABEL}
            </span>
        </div>
    );
}
