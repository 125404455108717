import * as USER from '../../constants/user';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../index';
import * as winkAction from '../../store/actions/wink';

interface IUseGetWinkList {
    isDefaultWinkMess?: boolean;
}

export default function useGetWinkList({ isDefaultWinkMess = false }: IUseGetWinkList) {
    const dispatch = useDispatch();

    const winkState = useSelector((state: RootState) => state.wink);

    const initSayHelloList = () => {
        dispatch(winkAction.getSayHelloList());
    };

    const sayHelloList =
        !isDefaultWinkMess && winkState?.sayHelloList?.length
            ? winkState.sayHelloList
            : [USER.DEFAULT_WINK_MESSAGE];

    return { sayHelloList, initSayHelloList };
}
