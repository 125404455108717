import React, { ChangeEvent, useEffect, useState } from 'react';
import * as menuAction from '../../../../store/actions/menu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../index';

export default function Header() {
    const menuState = useSelector((state: RootState) => state.menu);

    const [girlFilter, setGirlFilter] = useState('');
    const [girlSearch, setGirlSearch] = useState('');
    const [isChooseOpen, setIsChooseOpen] = useState(false);

    const dispatch = useDispatch();

    const styleChooseButton = `popup_compose_head_choose_filter_main ${isChooseOpen ? 'active' : ''}`;
    const styleChooseBlock = `popup_compose_head_choose_filter_list_wrap ${isChooseOpen ? 'active' : ''}`;

    const toggleChooseOpen = () => setIsChooseOpen(!isChooseOpen);
    const removeFilter = () => dispatch(menuAction.setComposeLetterFilter(''));
    const handleChangeInputSearchGirl = (event: ChangeEvent<HTMLInputElement>) =>
        setGirlSearch(event?.currentTarget?.value);

    const handleChangeFilterGirl = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.currentTarget.dataset?.name || '';
        setGirlFilter(name);
        dispatch(menuAction.setComposeLetterFilter(name));
        setIsChooseOpen(false);
    };

    useEffect(() => {
        dispatch(menuAction.setComposeLetterSearch(girlSearch));
    }, [girlSearch]);

    return (
        <div className="popup_compose_head">
            <div className="popup_compose_head_search">
                <input
                    type="text"
                    placeholder="Search ID or name"
                    value={girlSearch}
                    onChange={handleChangeInputSearchGirl}
                />

                {girlSearch.length > 0 ? (
                    <button onClick={() => setGirlSearch('')}>
                        <img src="/img/c2-remove.svg" alt="" />
                    </button>
                ) : (
                    <button>
                        <img src="/img/c2_search_btn.svg" alt="" />
                    </button>
                )}
            </div>
            <div className="popup_compose_head_right">
                <div className="popup_compose_head_choosen_filters">
                    {menuState.composeLetterFilter && (
                        <div
                            className="popup_compose_head_choosen_filters_item"
                            onClick={removeFilter}
                        >
                            <span>{menuState.composeLetterFilter}</span>
                            <img src="/img/c3l-compose-remove-filter.svg" alt="" />
                        </div>
                    )}
                </div>
                <div className="popup_compose_head_choose_filter_wrap">
                    <div className={styleChooseButton} onClick={toggleChooseOpen}>
                        <img src="/img/c3l-choose-filter.svg" alt="" />
                        <span>Choose filter</span>
                    </div>
                    <div className={styleChooseBlock}>
                        <div className="popup_compose_head_choose_filter_list">
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="online"
                                    checked={girlFilter === 'online'}
                                    onChange={handleChangeFilterGirl}
                                    id="pchcf_1"
                                />
                                <label htmlFor="pchcf_1">Online</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="dialogues"
                                    checked={girlFilter === 'dialogues'}
                                    onChange={handleChangeFilterGirl}
                                    id="pchcf_9"
                                />
                                <label htmlFor="pchcf_9">Dialogues</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="favorites"
                                    checked={girlFilter === 'favorites'}
                                    onChange={handleChangeFilterGirl}
                                    id="pchcf_2"
                                />
                                <label htmlFor="pchcf_2">Favorites</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="likes"
                                    checked={girlFilter === 'likes'}
                                    onChange={handleChangeFilterGirl}
                                    id="pchcf_3"
                                />
                                <label htmlFor="pchcf_3">Your likes</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="unanswered"
                                    checked={girlFilter === 'unanswered'}
                                    onChange={handleChangeFilterGirl}
                                    id="pchcf_4"
                                />
                                <label htmlFor="pchcf_4">Unread</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
