import React, { useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import NpsApi from '../../../../../../services/api/v3/NpsApi';
import { OPEN_ANSWER_MAX_LENGTH } from '../../../../../../constants/length';
import { IMixNPSProps } from '../MixNPS/index.interface';

export default function TextareaNPS({
    pollShownId,
    setNpsData,
    npsData,
    npsQuestion,
}: IMixNPSProps) {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [textValue, setTextValue] = useState<string>('');

    const onSubmit = () => {
        new NpsApi()
            .answerNpsPoll({
                pollShownId,
                textAnswer: textValue,
            })
            .then(() => setNpsData({ ...npsData, pollType: 'success' }));
    };

    useEffect(() => {
        const isTextValid =
            textValue?.trim().length > 5 && textValue?.length <= OPEN_ANSWER_MAX_LENGTH;
        setIsDisabled(!isTextValid);
    }, [textValue]);

    return (
        <>
            <p className={styles.nps_modal_text}>{npsQuestion}</p>
            <div className={styles.rating_textarea_wrapper} data-testid="text-block">
                <textarea
                    className={styles.rating_textarea}
                    name="nps_rating_textarea"
                    id="nps_rating_textarea"
                    placeholder="Tell us your thoughts here"
                    value={textValue}
                    onChange={(e) => setTextValue(e.target.value)}
                    data-testid="text-area"
                    maxLength={OPEN_ANSWER_MAX_LENGTH}
                />
                <div className={styles.rating_textarea_counter}>
                    {textValue.length}/{OPEN_ANSWER_MAX_LENGTH}
                </div>
            </div>
            {textValue.length > OPEN_ANSWER_MAX_LENGTH && (
                <p className={styles.textarea_error_message}>
                    The character limit has been exceeded.
                </p>
            )}
            <button
                type="button"
                className={`${styles.nps_button_continue} ${isDisabled ? styles.disabled : ''}`}
                onClick={onSubmit}
                data-testid="send-btn"
                disabled={isDisabled}
            >
                <span className={styles.nps_button_continue_text}>Send</span>
            </button>
        </>
    );
}
