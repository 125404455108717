import { makeCDN } from '../../../../../services/methods';
import React, { useEffect } from 'react';
import VideoPlayerNew from '../../../VideoPlayerNew';
import { stopAudio } from '../../../../../services/methods/chat';
import styles from './styles.module.scss';
import { sendErrToSentry } from '../../../../../services/sentry';
import { IVideoContentProps } from './index.interface';
import IconVideoError from '../../../../Icons/IconVideoError';
import IconCross from '../../../../Icons/IconCross';

export default function VideoContent({ item, closeModal, onViewVideo }: IVideoContentProps) {
    useEffect(() => {
        stopAudio();
    }, []);

    try {
        return (
            <>
                <div className="popup_attach_photo_click_img_wrap">
                    <div
                        className={`popup_attach_photo_click_img ${item?.attachment_payed === '1' ? '' : 'blur_unpaid'}`}
                        data-testid="video"
                    >
                        <VideoPlayerNew data={item.link ? makeCDN(item.link, 'VIDEO') : ''} />
                    </div>
                    {item?.attachment_payed === '1' ? (
                        <div />
                    ) : (
                        <div
                            className="click_to_view"
                            onClick={onViewVideo}
                            data-testid="click-to-view-btn"
                        >
                            Click to view the photo
                        </div>
                    )}
                </div>
                <div className="pap-btn-close" onClick={closeModal} data-testid="close-btn">
                    <IconCross />
                </div>
            </>
        );
    } catch (error) {
        sendErrToSentry(error as Error);

        return (
            <>
                <div className={styles.video_error_wrap} data-testid="video-error">
                    <IconVideoError />
                    <div className={styles.video_error_text}>Failed to load video file</div>
                </div>
                <div className="pap-btn-close" onClick={closeModal} data-testid="close-btn">
                    <IconCross />
                </div>
            </>
        );
    }
}
