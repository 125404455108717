import Base from '../Base';
import {
    ICloseDocsVerificationBannerData,
    ISkipDocsVerificationData,
} from './UserVerificationApi.interface';

export default class UserVerificationApi extends Base {
    async getIsNeedToVerifyDocs() {
        const res = await super.get(`v3/user-profile/document`);
        return res?.data;
    }

    async sendDocsToVerify(data: FormData) {
        const res = await super.post(`v3/user-profile/document/save`, data);
        return res?.data;
    }

    async skipDocsVerification(data: ISkipDocsVerificationData) {
        const res = await super.post(`v3/user-profile/document/skip`, data);
        return res?.data;
    }

    async closeDocsVerificationBanner(data: ICloseDocsVerificationBannerData) {
        const res = await super.post(`v3/user-profile/document/close`, data);
        return res?.data;
    }

    async sendDocsToVerifyProfile(data: FormData) {
        const res = await super.post(`v3/user-profile/document/verification/save`, data);
        return res?.data;
    }

    async getApprovedDocs() {
        const res = await super.get(`v3/user-profile/document/approved/list`);
        return res?.data;
    }
}
