import React from 'react';
import '../../../styles/requests/popup-contact-request.scss';
import useInitContactRequestModal from '../../../hooks/contactRequest/useInitContactRequestModal';
import ContactRequestModal from '../../common/Modals/ContactRequestModal';
import { IContactRequestProfile } from '../../common/Modals/ContactRequestModal/index.interface';

interface IRequestContactButtonProps {
    profile: IContactRequestProfile;
}

export default function RequestContactButton({ profile }: IRequestContactButtonProps) {
    const initContactRequestModal = useInitContactRequestModal(profile);

    return (
        <>
            <div
                className={`c3_options_item ${initContactRequestModal.enabledContactRequest ? '' : 'disabled'}`}
                onClick={initContactRequestModal.openContactRequest}
            >
                <img src="../../../img/c3-opt-contact.svg" alt="" />
                <span>
                    Contact<span> request</span>
                </span>
            </div>

            <ContactRequestModal {...initContactRequestModal} profile={profile} />
        </>
    );
}
