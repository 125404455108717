import { DocsTypes, ProfileActionTypes } from '../../constants/profile';
import { IProfileState, ProfileActions } from './index.interface';

// This reducer is for a new Profile page with posts
const initState = {
    profileData: {},
    counters: {},
    subscriptionList: [],
    docsState: {
        userIdFile: {},
        userSelfieFile: {},
        userOnlySelfieFile: {},
        configID: 0,
        banner: '',
        isBlockClosing: false,
        approvedDocs: [] as Array<DocsTypes>,
    },
    resetPostList: false,
};

export default function (state: IProfileState = initState, action: ProfileActions): IProfileState {
    switch (action.type) {
        case ProfileActionTypes.CLEAR_PROFILE_DATA:
            return initState;

        case ProfileActionTypes.GET_PROFILE_DATA:
            return { ...state, profileData: action.profileData, counters: action.counters };

        case ProfileActionTypes.SET_SUBSCRIPTION_LIST:
            return { ...state, subscriptionList: action.list };

        case ProfileActionTypes.UPDATE_SUBSCRIBED_STATUS:
            return {
                ...state,
                ...{
                    subscriptionList: state.subscriptionList.map((item) => {
                        if (item?.external_id === action?.external_id) {
                            return { ...item, ...{ subscribed: action?.status } };
                        } else {
                            return item;
                        }
                    }),
                },
            };
        case ProfileActionTypes.UPDATE_DOCS_STATE:
            return {
                ...state,
                docsState: action.docsState,
            };
        case ProfileActionTypes.UPDATE_IS_SHOW_STATUS:
            return {
                ...state,
                docsState: {
                    ...state.docsState,
                    isShow: action.isShow,
                },
            };

        case ProfileActionTypes.REFRESH_LIKED_POSTS:
            return {
                ...state,
                resetPostList: !state?.resetPostList,
            };

        default:
            return state;
    }
}
