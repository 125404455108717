import { FilesActionTypes } from '../../constants/files';
import { FilesActions, IFilesState } from './index.interface';

const initState = {
    images: [],
    videos: [],
    audios: [],
    isImagesLoaded: false,
    isVideosLoaded: false,
    isAudiosLoaded: false,
};

export default function (state: IFilesState = initState, action: FilesActions): IFilesState {
    switch (action.type) {
        case FilesActionTypes.FILES_IMG_GET:
            return {
                ...state,
                ...{
                    images: action?.data?.images,
                    isImagesLoaded: true,
                },
            };
        case FilesActionTypes.FILES_VIDEO_GET:
            return {
                ...state,
                ...{
                    videos: action?.data?.videos,
                    isVideosLoaded: true,
                },
            };
        case FilesActionTypes.FILES_AUDIO_GET:
            return {
                ...state,
                ...{
                    audios: action?.data?.audios,
                    isAudiosLoaded: true,
                },
            };
        case FilesActionTypes.SET_DELETE_IMAGE:
            return {
                ...state,
                ...{
                    images: state?.images?.filter((el) => el?.id !== action?.fileId),
                },
            };

        case FilesActionTypes.SET_DELETE_VIDEO:
            return {
                ...state,
                ...{
                    videos: state?.videos?.filter((el) => el?.id !== action?.fileId),
                },
            };

        case FilesActionTypes.SET_DELETE_AUDIO:
            return {
                ...state,
                ...{
                    audios: state?.audios?.filter((el) => el?.id !== action?.fileId),
                },
            };

        case FilesActionTypes.SET_SORTED_FILES: {
            const orderMap: Record<number, number> = {};

            action.sortedArray.forEach((item, index) => {
                orderMap[+item?.id] = index;
            });

            const sortedFiles = [...(state[action?.fileType] || [])].sort(
                (a, b) => orderMap[a?.id] - orderMap[b?.id],
            );

            return {
                ...state,
                ...{
                    [action?.fileType]: sortedFiles,
                },
            };
        }

        default:
            return state;
    }
}
