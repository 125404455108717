import React from 'react';
import styles from '../../../../../styles/confirm-status-modal/styles.module.scss';
import { IConfirmStatusNameProps } from './index.interface';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconCross from '../../../../Icons/IconCross';

export default function ConfirmStatusName({
    closeModal,
    nameData,
    changeNameData,
    submitNameData,
}: IConfirmStatusNameProps) {
    return (
        <div className={styles.popup_confirm}>
            <h2 className={styles.popup_confirm_title}>Confirm your real name</h2>
            <p className={styles.popup_confirm_text}>
                Please write your real name, the information is completely confidential and will
                only be used by our support team to contact you.
            </p>
            <form className={styles.popup_confirm_form} onSubmit={submitNameData}>
                <div className={styles.popup_confirm_input_wrap}>
                    <input
                        className={styles.popup_confirm_input}
                        type="text"
                        name="name"
                        placeholder="Your real name"
                        value={nameData}
                        onChange={changeNameData}
                    />
                </div>
                <button type="submit" className={`${styles.popup_confirm_btn}`}>
                    <IconCheckedCircle />
                    Confirm name
                </button>
            </form>
            <div className={styles.popup_confirm_close} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
