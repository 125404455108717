import { useEffect, useState } from 'react';
import QuizApi from '../../services/api/v3/QuizApi';
import { getUrlParameter } from '../../utils/url-utils';
import * as QUIZ from '../../constants/quiz';

export default function useInitPollModalWithoutAuth() {
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [quizData, setQuizData] = useState(null);
    const [typeList, setTypeList] = useState(null);

    const closeQuizPopup = () => {
        setIsActiveModal(false);
        setQuizData(null);
        setTypeList(null);
    };

    const openQuizPopup = (delay: number) => {
        setTimeout(() => {
            setIsActiveModal(true);
        }, delay * 1000);
    };

    useEffect(() => {
        // ?open_quiz=4&external_id=1111
        if (
            window?.location?.search?.includes('open_quiz') &&
            window?.location?.search?.includes('external_id')
        ) {
            const quizCategory = +(getUrlParameter('open_quiz') || 0);

            switch (quizCategory) {
                case QUIZ.CATEGORY_AFTER_DEL:
                case QUIZ.CATEGORY_2_WEEK:
                    new QuizApi().getQuizByCategoryWithoutAut(+quizCategory).then((res) => {
                        if (res?.status && res?.result) {
                            setQuizData(res?.result);
                            setTypeList(res?.type_list);
                            openQuizPopup(+res?.result?.delay ?? 0);
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }, []);

    return {
        isActiveModal,
        quizData,
        typeList,
        closeQuizPopup,
    };
}
