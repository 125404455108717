export const ANSWER_TYPE_OPEN_QUIZ = 0;
export const ANSWER_TYPE_ANSWER = 1;
export const ANSWER_TYPE_CLOSE_QUIZ = 2;

export const CATEGORY_ALL = 0;
export const CATEGORY_BY_EXTERNAL_ID = 1;
export const CATEGORY_BEFORE_DEL = 2;
export const CATEGORY_AFTER_DEL = 3;
export const CATEGORY_2_WEEK = 4;

export const OTHER_TITLE = 'Other';
