import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Imbra from './Imbra';
import { IImbraModalProps } from './index.interface';

export default function ImbraModal({
    closeImbraModal,
    isActiveImbraModal,
    uploadImbraFile,
    imbraStatus,
}: IImbraModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isActiveImbraModal}
            onRequestClose={closeImbraModal}
            ariaHideApp={false}
        >
            <Imbra
                closeModal={closeImbraModal}
                uploadImbraFile={uploadImbraFile}
                imbraStatus={imbraStatus}
            />
        </Modal>
    );
}
