import React, { useRef } from 'react';
import useOutsideClicker from '../../../../../../../hooks/dom/useOutsideClicker';
import { convertPrice } from '../../../../../../../services/methods';
import * as PRICES from '../../../../../../../constants/prices';
import SITE from '../../../../../../../config/site';
import { IStickersListOpenProps } from './index.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../index';
import IconFAQQuestionBackArrow from '../../../../../../Icons/IconFAQQuestionBackArrow';

export default function StickersListOpen({
    innerStickerList,
    innerStickerTitle,
    isStickerListOpen,
    closeInnerStickerList,
    openStickerBlock,
    onSelectSticker,
}: IStickersListOpenProps) {
    const userState = useSelector((state: RootState) => state.user);

    const stickersListOpenRef = useRef<HTMLDivElement | null>(null);

    useOutsideClicker(stickersListOpenRef, closeInnerStickerList);

    const innerStickerListMap =
        !!innerStickerList?.length &&
        innerStickerList?.map((sticker, index) => (
            <li
                className="c3_stickers_list_item"
                key={`${sticker?.name}_${index}`}
                onClick={(event) => {
                    event?.stopPropagation();
                    closeInnerStickerList();
                    onSelectSticker(sticker?.name);
                }}
            >
                <img src={sticker?.image} alt="" />
            </li>
        ));

    return (
        <>
            {!!innerStickerList?.length && (
                <div
                    className={`c3_stickers_list_open ${isStickerListOpen ? 'active' : ''}`}
                    onClick={() => closeInnerStickerList()}
                    ref={stickersListOpenRef}
                >
                    <div className="c3_stickers_list_open_head">
                        <button
                            type="button"
                            className="c3_stickers_list_open_back_btn"
                            onClick={() => openStickerBlock()}
                        >
                            <IconFAQQuestionBackArrow />
                            <span>Back</span>
                        </button>
                        <h3 className="c3_stickers_list_open_title">
                            {innerStickerTitle ?? 'Stickers'}
                        </h3>
                        {SITE.ID !== 4 && (
                            <p className="c3_stickers_list_open_price">
                                One sticker costs{' '}
                                {userState?.prices &&
                                    convertPrice(+userState?.prices[PRICES.SENT_STICKER])}{' '}
                                credits
                            </p>
                        )}
                    </div>
                    <ul className="c3_stickers_list_open_list">{innerStickerListMap}</ul>
                </div>
            )}
        </>
    );
}
