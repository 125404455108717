import React from 'react';
import styles from '../../../../../styles/confirm-status-modal/styles.module.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import { iConfirmStatusPhoneProps } from './index.interface';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconCross from '../../../../Icons/IconCross';

export default function ConfirmStatusPhone({
    closeModal,
    phoneData,
    changePhoneData,
    submitPhoneData,
    isPhoneCodeCanceled,
    setIsPhoneCodeCanceled,
    switchPhoneNumber,
}: iConfirmStatusPhoneProps) {
    return (
        <div className={styles.popup_confirm}>
            {isPhoneCodeCanceled ? (
                <>
                    <h2 className={styles.popup_confirm_title}>Something went wrong</h2>
                    <p className={styles.popup_confirm_text}>
                        You entered a phone number +{phoneData}, if this is not correct, change the
                        phone number, if the number is correct, receive another SMS and enter 4
                        digits.
                    </p>
                    <div className={styles.popup_confirm_btn_row}>
                        <button
                            type="button"
                            className={`${styles.popup_confirm_btn}`}
                            onClick={() => setIsPhoneCodeCanceled(false)}
                        >
                            Change phone number
                        </button>
                        <button
                            type="button"
                            className={`${styles.popup_confirm_btn}`}
                            onClick={submitPhoneData}
                        >
                            Send new SMS
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <h2 className={styles.popup_confirm_title}>Confirm your number</h2>
                    <p className={styles.popup_confirm_text}>
                        Please enter your real phone number, which you can verify via SMS right now.
                    </p>
                    <div className={styles.popup_confirm_input_wrap}>
                        <PhoneInput
                            inputStyle={{ width: '100%', height: '42px' }}
                            country={'us'}
                            value={phoneData}
                            enableAreaCodes={true}
                            onChange={(value, country, e, formattedValue) => {
                                changePhoneData(value, formattedValue?.length >= 10);
                            }}
                        />
                    </div>
                    <button
                        type="button"
                        className={`${styles.popup_confirm_btn}`}
                        onClick={submitPhoneData}
                    >
                        <IconCheckedCircle />
                        Receive SMS
                    </button>
                    <div className={styles.popup_confirm_diff_text}>
                        If you face difficulties with the number verification, you can call us at:
                        <br />
                        <div>
                            <a
                                href={`tel:${switchPhoneNumber()?.phone.split(' ').join('')}`}
                                rel="noopener noreferrer"
                            >
                                {switchPhoneNumber()?.phone}
                            </a>
                            &nbsp;({switchPhoneNumber()?.code})
                        </div>
                        <br />
                        Provide us with your account ID and we'll help you to manage this on our
                        side.
                    </div>
                </>
            )}

            <div className={styles.popup_confirm_close} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
