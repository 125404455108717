import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderChat from '../../common/HeaderChatRoom';
import BodyChat from './BodyChat';
import NotifyEmailConfirm from '../../Banner';
import useTransition from '../../../hooks/transition/useTransition';
import * as MENU from '../../../constants/menu';
import { closeMobChatList, openMobChatList } from '../../../services/dom';
import GirlsSlider from '../../common/GirlsSlider/GirlsSlider';
import * as SEX from '../../../constants/sex';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import * as currentChatAction from '../../../store/actions/currentChat';
import { RootState } from '../../../index';
import Holiday from '../../../img/holiday';
import Images from '../../../img';
import { isHolidayActive } from '../../../constants/holiday';

export default function Chat() {
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);
    const bannersState = useSelector((state: RootState) => state.banners);

    const [isMediaWrapOpen, setIsMediaWrapOpen] = useState(false);

    const dispatch = useDispatch();
    const transition = useTransition();
    const handleActiveBlocks = useHandleActiveBlocks();
    const activeHoliday = isHolidayActive();

    const handleEmptyClick = (menu: string) => {
        transition.transitionWithOnlyHistoryPush(`/${menu}`);
    };

    useEffect(() => {
        if (window.innerWidth < 1140) {
            if (currentChatState?.info?.id) {
                closeMobChatList();
                dispatch(currentChatAction.touchSwipeChatList('left'));
            } else {
                openMobChatList();
            }
        }
    }, [currentChatState.info]);

    return (
        <>
            {currentChatState?.info?.id ? (
                <div className="column-3 color c3_fx column_3_chat_page" data-testid="chat-block">
                    {!window.location.href.includes(MENU.MENU_BLOCK) && <NotifyEmailConfirm />}
                    <HeaderChat
                        isMediaWrapOpen={isMediaWrapOpen}
                        setIsMediaWrapOpen={setIsMediaWrapOpen}
                    />
                    <BodyChat isMediaWrapOpen={isMediaWrapOpen} />
                </div>
            ) : (
                <div className="column-3 empty color column_3_chat_page" data-testid="chat-block">
                    {window.innerWidth >= 1140 && (
                        <>
                            {!window.location.href.includes(MENU.MENU_BLOCK) && (
                                <NotifyEmailConfirm />
                            )}
                            {!window.location.href.includes(MENU.MENU_BLOCK) &&
                                !bannersState?.isActiveCrossBanners && <GirlsSlider />}
                        </>
                    )}
                    {activeHoliday && Holiday.images[`${activeHoliday?.name}_empty.svg`] ? (
                        <img
                            src={Holiday.images[`${activeHoliday?.name}_empty.svg`]}
                            alt="empty result"
                            className={`c3_chat_empty_img ${activeHoliday?.name}`}
                        />
                    ) : (
                        <img
                            src={Images.images['empty_result_chat.svg']}
                            alt="empty result"
                            className="c3_chat_empty_img"
                        />
                    )}
                    <div className="c3_chat_empty_bottom">
                        <div className="c3_chat_empty_row c3_chat_empty_row_half">
                            <div className="c3_chat_empty_row_title">Find your sweetheart</div>
                            <div
                                className="c3_chat_empty_row_btn"
                                onClick={() => handleEmptyClick(MENU.MENU_LIKE_HER)}
                                data-testid="like-btn"
                            >
                                {
                                    SEX.SEX_TEXT[
                                        userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                    ].LIKE_SIDE_MENU_BTN
                                }
                            </div>
                        </div>
                        <div className="c3_chat_empty_row c3_chat_empty_row_half">
                            <div className="c3_chat_empty_row_title">
                                {
                                    SEX.SEX_TEXT[
                                        userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                    ].CHAT_EMPTY_SEARCH_BTN
                                }
                            </div>
                            <div
                                className="c3_chat_empty_row_btn"
                                onClick={() => handleEmptyClick(MENU.MENU_SEARCH)}
                                data-testid="search-btn"
                            >
                                {
                                    SEX.SEX_TEXT[
                                        userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                    ].SEARCH_SIDE_MENU_BTN
                                }
                            </div>
                        </div>
                        {handleActiveBlocks.isVideoShow && (
                            <div className="c3_chat_empty_row">
                                <div className="c3_chat_empty_row_title">
                                    {
                                        SEX.SEX_TEXT[
                                            userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                        ].CHAT_EMPTY_VIEW_VIDEOS_BTN
                                    }
                                </div>
                                <div
                                    className="c3_chat_empty_row_btn"
                                    onClick={() => handleEmptyClick(MENU.MENU_VIDEO_SHOW)}
                                    data-testid="video-btn"
                                >
                                    View videos
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
