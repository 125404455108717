import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment/moment';
import { RootState } from '../../index';

export default function useSetBannerViewTimer({
    clearCrossBannersData,
}: {
    clearCrossBannersData: () => void;
}) {
    const bannersState = useSelector((state: RootState) => state.banners);
    const { crossBannersData, crossBannersStartTime, isConfirmTermModal } = bannersState;

    useEffect(() => {
        if (crossBannersStartTime && crossBannersData?.time_view && !isConfirmTermModal) {
            const interval = setInterval(() => {
                const currUtcDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');
                const diffTimeView = moment(currUtcDate).diff(crossBannersStartTime, 'seconds');

                if (diffTimeView >= crossBannersData?.time_view) {
                    clearInterval(interval);
                    clearCrossBannersData();
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [crossBannersStartTime, isConfirmTermModal]);
}
