import { makeCDN } from '../../../../../services/methods';
import React, { useEffect } from 'react';
import VideoPlayerNew from '../../../VideoPlayerNew';
import styles from './styles.module.scss';
import SITE from '../../../../../config/site';
import { stopAudio } from '../../../../../services/methods/chat';
import { sendErrToSentry } from '../../../../../services/sentry';
import { VideoContentProps } from './index.interface';
import IconCross from '../../../../Icons/IconCross';
import IconVideoError from '../../../../Icons/IconVideoError';

export default function VideoContent({ item, closeModal, onViewVideo }: VideoContentProps) {
    useEffect(() => {
        stopAudio();
    }, []);

    try {
        return (
            <>
                <div className="popup_attach_photo_click_img_wrap">
                    <div
                        className={`popup_attach_photo_click_img ${+item?.attachment_payed !== 1 ? 'blur_unpaid' : ''}`}
                    >
                        <VideoPlayerNew data={makeCDN(item?.link, 'VIDEO')} />
                    </div>
                    {+item?.attachment_payed !== 1 && (
                        <div className="click_to_view" onClick={onViewVideo}>
                            Click to view the photo
                        </div>
                    )}
                </div>
                <div
                    className={`pap-btn-close ${SITE.ID === 23 ? 'pap-btn-close-sendberly' : ''}`}
                    onClick={closeModal}
                    data-testid="close-btn"
                >
                    <IconCross />
                </div>
            </>
        );
    } catch (error) {
        sendErrToSentry(error as Error);

        return (
            <>
                <div className={styles.video_error_wrap}>
                    <IconVideoError />
                    <span className={styles.video_error_text}>Failed to load video file</span>
                </div>
                <div
                    className={`pap-btn-close ${SITE.ID === 23 ? 'pap-btn-close-sendberly' : ''}`}
                    onClick={closeModal}
                >
                    <IconCross />
                </div>
            </>
        );
    }
}
