import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileInfo from './ProfileInfo';
import Functionality from './Functionality';
import FunctionalityMain from './FunctionalityMain';
import MediaList from './MediaList';
import useHandleChatFunctions from '../../../hooks/chat/useHandleChatFunctions';
import * as allMediaAction from '../../../store/actions/allMedia';
import useHandleChatListItem from '../../../hooks/chat/useHandleChatListItem';
import { scrollTopMediaList } from '../../../services/methods/allMedia';
import { SUPPORT_WOMAN } from '../../../constants/user';
import { RootState } from '../../../index';
import { IStoreChatListItemModel } from '../../../models/chat/index.interface';

interface IHeaderChatRoomProps {
    isMediaWrapOpen?: boolean;
    setIsMediaWrapOpen?: (param: boolean) => void;
}

export default function HeaderChatRoom({
    isMediaWrapOpen,
    setIsMediaWrapOpen,
}: IHeaderChatRoomProps) {
    const optionsItemMediaRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();

    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const userState = useSelector((state: RootState) => state.user);

    const handleChatFunctions = useHandleChatFunctions();
    const { openWomanProfileModal } = useHandleChatListItem();

    const isActiveCommonContent = localStorage.getItem('common') || !!+userState?.common_files;
    const isSupportWoman = currentChatState?.info?.id === +SUPPORT_WOMAN;

    const toggleMediaWrap = () => {
        if (isMediaWrapOpen) {
            if (setIsMediaWrapOpen) setIsMediaWrapOpen(false);
            scrollTopMediaList();
            dispatch(allMediaAction.resetChatMediaListByPage());
            dispatch(allMediaAction.resetAllMediaList());
        } else {
            if (setIsMediaWrapOpen) setIsMediaWrapOpen(true);
        }
    };

    const onCloseMediaWrap = () => {
        if (isMediaWrapOpen) {
            if (setIsMediaWrapOpen) setIsMediaWrapOpen(false);
            scrollTopMediaList();
            dispatch(allMediaAction.resetChatMediaListByPage());
            dispatch(allMediaAction.resetAllMediaList());
        }
    };

    const onSetAllMediaList = () => {
        if (currentChatState?.info?.chat_uid) {
            dispatch(allMediaAction.setAllMediaList(currentChatState?.info?.chat_uid));
        }
    };

    useEffect(() => {
        if (isActiveCommonContent) onSetAllMediaList();
    }, [currentChatState.info]);

    return (
        <div className="c3_top" data-testid="chat-header">
            <div className="c3_head">
                <ProfileInfo
                    info={handleChatFunctions.getProfileInfo() as IStoreChatListItemModel}
                    openWomanProfileModal={openWomanProfileModal}
                />
                <Functionality {...handleChatFunctions} />
            </div>
            {!isSupportWoman && (
                <FunctionalityMain
                    {...handleChatFunctions}
                    toggleMediaWrap={toggleMediaWrap}
                    isMediaWrapOpen={isMediaWrapOpen}
                    optionsItemMediaRef={optionsItemMediaRef}
                />
            )}
            {!isSupportWoman && isMediaWrapOpen !== undefined && (
                <MediaList
                    onCloseMediaWrap={onCloseMediaWrap}
                    isMediaWrapOpen={isMediaWrapOpen}
                    optionsItemMediaRef={optionsItemMediaRef}
                    onSetAllMediaList={onSetAllMediaList}
                />
            )}
        </div>
    );
}
