import React from 'react';

export default function IconMute() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5397 4.46959C18.8327 4.17674 19.3076 4.17681 19.6004 4.46975C21.6157 6.48567 22.7479 9.21949 22.7479 12.07C22.7479 14.9205 21.6157 17.6543 19.6004 19.6702C19.3076 19.9632 18.8327 19.9633 18.5397 19.6704C18.2468 19.3776 18.2467 18.9027 18.5396 18.6097C20.2737 16.8751 21.2479 14.5228 21.2479 12.07C21.2479 9.61723 20.2737 7.26488 18.5396 5.53025C18.2467 5.23731 18.2468 4.76244 18.5397 4.46959ZM11.3249 4.39402C11.5847 4.51891 11.75 4.7817 11.75 5.07V19.07C11.75 19.3583 11.5847 19.6211 11.3249 19.746C11.065 19.8709 10.7566 19.8358 10.5315 19.6557L5.73691 15.82H2C1.58579 15.82 1.25 15.4842 1.25 15.07V9.07C1.25 8.65579 1.58579 8.32 2 8.32H5.73691L10.5315 4.48435C10.7566 4.30425 11.065 4.26913 11.3249 4.39402ZM10.25 6.63047L6.46852 9.65565C6.33554 9.76204 6.1703 9.82 6 9.82H2.75V14.32H6C6.1703 14.32 6.33554 14.378 6.46852 14.4843L10.25 17.5095V6.63047ZM15.0098 7.99959C15.3027 7.70674 15.7776 7.70681 16.0704 7.99975C17.1484 9.07803 17.7539 10.5403 17.7539 12.065C17.7539 13.5897 17.1484 15.052 16.0704 16.1302C15.7776 16.4232 15.3027 16.4233 15.0098 16.1304C14.7168 15.8376 14.7167 15.3627 15.0096 15.0698C15.8063 14.2728 16.2539 13.1919 16.2539 12.065C16.2539 10.9381 15.8063 9.85724 15.0096 9.06025C14.7167 8.76731 14.7168 8.29244 15.0098 7.99959Z"
            />
        </svg>
    );
}
