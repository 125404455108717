import { ChatsActionTypes } from '../../constants/chats';
import ChatsApi from '../../../services/api/ChatsApi';
import { ChatModel } from '../../../models/chat';
import { sendErrToSentry } from '../../../services/sentry';
import { Dispatch } from 'redux';
import { IUpdateNewMessageAction, IUpdateMessageInChatListAction } from './index.interface';
import { IStoreChatListItemModel } from '../../../models/chat/index.interface';
import {
    IChatListItemUpdatedInfo,
    IUpdateChatListItemInfoAction,
} from '../../reducers/chats/index.interface';

export function initChatFilters() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: ChatsActionTypes.INIT_CHAT_FILTERS,
        });
    };
}

export function updateNewMess(
    userId: number,
    isOnline = false,
    searchItem = '',
    chatType: string,
    chatId: number,
) {
    return function (dispatch: Dispatch<IUpdateNewMessageAction>) {
        ChatModel.getStoreChatList(userId, isOnline, searchItem, chatType, 1).then((res) => {
            return dispatch({
                type: ChatsActionTypes.UPDATE_NEW_MESS,
                data: {
                    chatList: res,
                    chatId,
                },
            });
        });
    };
}

export function addNewChat(chat: IStoreChatListItemModel) {
    return function (dispatch: Dispatch<{ type: string; chat: IStoreChatListItemModel }>) {
        return dispatch({
            type: ChatsActionTypes.ADD_NEW_CHAT,
            chat,
        });
    };
}

export function getBlockListByUserId(
    isOnline: boolean,
    searchItem: string,
    page = 1,
    isFirst = false,
    callback = () => {},
) {
    return function (dispatch: Dispatch<{ type: string; data?: any; page?: number }>) {
        if (isFirst) dispatch({ type: ChatsActionTypes.FETCH_BLOCK_LIST });

        new ChatsApi()
            .postBlockListByUserId(isOnline, searchItem, page)
            .then((res) => {
                if (res?.canceled) return;
                return dispatch({
                    type: ChatsActionTypes.BLOCK_CHATS_GET,
                    data: res,
                    page,
                });
            })
            .finally(() => callback());
    };
}

export function getChatListByUserIdWithReload(
    userId: number,
    isOnline = false,
    searchItem = '',
    chatType = '',
    page = 1,
    token: string | null = null,
) {
    return function (dispatch: Dispatch<{ type: string; data?: any; page?: number }>) {
        dispatch({
            type: ChatsActionTypes.FETCH_CHAT_LIST,
        });
        // @ts-expect-error searchItem type
        ChatModel.getStoreChatList(userId, isOnline, searchItem, chatType, page, token).then(
            (res) => {
                return dispatch({
                    type: ChatsActionTypes.CHATS_GET,
                    data: res,
                    page,
                });
            },
        );
    };
}

export function getChatListByUserId(
    userId: number,
    isOnline = false,
    searchItem = '',
    chatType = '',
    page = 1,
    token = null,
) {
    return function (dispatch: Dispatch<{ type: string; data: any; page: number }>) {
        // @ts-expect-error searchItem type
        return ChatModel.getStoreChatList(userId, isOnline, searchItem, chatType, page, token).then(
            (res) => {
                return dispatch({
                    type: ChatsActionTypes.CHATS_GET,
                    data: res,
                    page,
                });
            },
        );
    };
}

export function getChatListByPage(
    userId: number,
    isOnline = false,
    searchItem = '',
    chatType = '',
    page = 1,
    callback: () => void,
) {
    return function (dispatch: Dispatch<{ type: string; data: any; page: number }>) {
        try {
            ChatModel.getStoreChatList(userId, isOnline, searchItem, chatType, page)
                .then((res) => {
                    return dispatch({
                        type: ChatsActionTypes.CHATS_GET_BY_PAGE,
                        data: res,
                        page,
                    });
                })
                .finally(() => callback());
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };
}

export function setChatListByUserId(res: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: ChatsActionTypes.CHATS_GET,
            data: res,
        });
    };
}

export function setOnlineStatus(data: boolean) {
    return function (dispatch: Dispatch<{ type: string; data: boolean }>) {
        return dispatch({
            type: ChatsActionTypes.ONLINE_STATUS,
            data: data,
        });
    };
}

export function setSearchInput(data: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: ChatsActionTypes.SEARCH_INPUT,
            data: data,
        });
    };
}

export function removeUnreadCount(chat_uid: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: ChatsActionTypes.REMOVE_UNREAD_COUNTER,
            data: chat_uid,
        });
    };
}

export function setReadLastMessagesFromYou(chat_uid: string) {
    return function (dispatch: Dispatch<{ type: string; chatId: string }>) {
        return dispatch({
            type: ChatsActionTypes.SET_READ_LAST_MESS_FROM_YOU,
            chatId: chat_uid,
        });
    };
}

export function removeFromChatList(chatUid: string) {
    return function (dispatch: Dispatch<{ type: string; chatUid: string }>) {
        return dispatch({
            type: ChatsActionTypes.REMOVE_FROM_CHATLIST,
            chatUid: chatUid,
        });
    };
}

export function removeFromBlockList(chatUid: string) {
    return function (dispatch: Dispatch<{ type: string; chatUid: string }>) {
        return dispatch({
            type: ChatsActionTypes.REMOVE_FROM_BLOCKLIST,
            chatUid,
        });
    };
}

export function updateMessageInChatList(
    chatUid: string,
    messageContent: string,
    messageType: string,
    messageDate: string,
    senderExternalId: number,
) {
    return function (dispatch: Dispatch<IUpdateMessageInChatListAction>) {
        return dispatch({
            type: ChatsActionTypes.UPDATE_MESS_IN_CHAT_LIST,
            chatUid,
            messageContent,
            messageType,
            messageDate,
            senderExternalId,
        });
    };
}

export function setFavorite(chatUid: string, favoriteStatus: boolean) {
    return function (
        dispatch: Dispatch<{
            type: string;
            chatUid: string;
            favoriteStatus: boolean;
        }>,
    ) {
        return dispatch({
            type: ChatsActionTypes.SET_FAVORITE_CHAT_LIST,
            chatUid,
            favoriteStatus,
        });
    };
}

export function setLike(chatUid: string) {
    return function (dispatch: Dispatch<{ type: string; chatUid: string }>) {
        return dispatch({
            type: ChatsActionTypes.SET_LIKE_CHAT_LIST,
            chatUid,
        });
    };
}

export function setPinnedChatList(chatUid: string, pinned: number) {
    return function (dispatch: Dispatch<{ type: string; chatUid: string; pinned: number }>) {
        return dispatch({
            type: ChatsActionTypes.SET_PINNED_CHAT_LIST,
            chatUid,
            pinned,
        });
    };
}

export function setChatListOnlineStatus(externalIDList: number[], status: any) {
    return function (dispatch: Dispatch<{ type: string; externalIDList: any; status: any }>) {
        return dispatch({
            type: ChatsActionTypes.SET_CHAT_LIST_ONLINE_STATUS,
            externalIDList,
            status,
        });
    };
}

export function setChatListLikeStatus(womanExternalID: number) {
    return function (dispatch: Dispatch<{ type: string; womanExternalID: number }>) {
        return dispatch({
            type: ChatsActionTypes.SET_CHAT_LIST_LIKE_STATUS,
            womanExternalID,
        });
    };
}

export function updateChatListItemInfo(data: IChatListItemUpdatedInfo) {
    return function (dispatch: Dispatch<IUpdateChatListItemInfoAction>) {
        return dispatch({
            type: ChatsActionTypes.UPDATE_CHAT_LIST_ITEM_INFO,
            data,
        });
    };
}
