import React from 'react';

export default function IconLikeHerHeart() {
    return (
        <svg
            width="46"
            height="41"
            viewBox="0 0 46 41"
            fill="#6F0267"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_17)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.0372 40.8701C22.7682 40.7158 5.46788 30.7532 1.21661 18.3477C-1.1723 11.3779 2.54035 3.79162 9.51184 1.401C14.3802 -0.267622 19.5501 1.04147 23.0392 4.35777C26.5283 1.04147 31.6983 -0.267622 36.5666 1.401C43.5363 3.79162 47.2507 11.3779 44.8618 18.3477C40.6105 30.7532 23.3102 40.7158 23.0412 40.8701C23.0407 40.8713 23.0404 40.8719 23.0404 40.8719C23.0404 40.8719 23.04 40.8717 23.0392 40.8712C23.0384 40.8717 23.038 40.8719 23.038 40.8719C23.038 40.8719 23.0377 40.8713 23.0372 40.8701Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_17">
                    <rect width="46" height="41" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
