import * as videoShowAction from '../../store/actions/videoShow';
import { useDispatch, useSelector } from 'react-redux';
import { UIEvent, useEffect } from 'react';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { RootState } from '../../index';

export default function useSetVideoShowList() {
    const userState = useSelector((state: RootState) => state.user);
    const videoShowState = useSelector((state: RootState) => state.videoShow);
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();

    const searchFilterByType = handleActiveBlocks?.isSearchV3
        ? { ...videoShowState?.filter, gender: 'ALL' }
        : { ...videoShowState?.filter, gender: userState?.info?.gender };

    const setDefaultFilterValue = () => dispatch(videoShowAction.setSVideoShowListFilter());

    const handleCountryFilter = (value: string) => {
        const currentCountry = referenciesState?.list?.country_list?.find(
            (el) => el?.country_name?.toLowerCase() === value?.toLowerCase(),
        );

        dispatch(
            videoShowAction.setSVideoShowListFilter({
                ...videoShowState?.filter,
                country: currentCountry?.id || 0,
            }),
        );
    };

    const handleCityFilter = (value: string) => {
        const currentCity = (videoShowState?.filter as any)?.cityList.find(
            (el: any) => el?.city_name?.toLowerCase() === value?.toLowerCase(),
        );

        dispatch(
            videoShowAction.setSVideoShowListFilter({
                ...videoShowState?.filter,
                // @ts-expect-error city_name
                city: currentCity?.city_name || '',
            }),
        );
    };

    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;

        const getNextPage =
            target?.scrollTop > target?.scrollHeight - target?.offsetHeight - 460 * 2;

        if (getNextPage && !videoShowState?.isLoadingPage && !videoShowState?.isLoadedAll) {
            dispatch(videoShowAction.setVideoShowListPage(+videoShowState?.page + 1));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(videoShowAction.initVideoShowState());
        };
    }, []);

    useEffect(() => {
        dispatch(videoShowAction.setVideoShowListPage(1));
        dispatch(videoShowAction.getVideoShowList(1, searchFilterByType));
    }, [videoShowState?.filter]);

    useEffect(() => {
        if (videoShowState?.page > 1) {
            dispatch(videoShowAction.getVideoShowList(videoShowState?.page, searchFilterByType));
        }
    }, [videoShowState?.page]);

    return {
        handleScroll,
        handleCountryFilter,
        handleCityFilter,
        setDefaultFilterValue,
    };
}
