import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Login from './Login';
import PropTypes from 'prop-types';
import { ILoginModalProps } from './index.interface';

LoginModal.propTypes = {
    activeForgetPassword: PropTypes.bool,
    activeSetPassword: PropTypes.bool,
    closeModalLogin: PropTypes.func,
    formDataLogin: PropTypes.object,
    modalIsActiveLogin: PropTypes.bool,
    onSubmitLogin: PropTypes.func,
    setActiveForgetPassword: PropTypes.func,
    setFormDataLogin: PropTypes.func,
};
export default function LoginModal({
    activeForgetPassword,
    activeSetPassword,
    closeModalLogin,
    formDataLogin,
    modalIsActiveLogin,
    onSubmitLogin,
    setActiveForgetPassword,
    setFormDataLogin,
}: ILoginModalProps) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={modalIsActiveLogin}
            onRequestClose={closeModalLogin}
            ariaHideApp={false}
        >
            <Login
                activeForgetPassword={activeForgetPassword}
                setActiveForgetPassword={setActiveForgetPassword}
                activeSetPassword={activeSetPassword}
                formData={formDataLogin}
                changeUsername={(e) =>
                    setFormDataLogin({ ...formDataLogin, ...{ email: e.target.value } })
                }
                changePassword={(e) =>
                    setFormDataLogin({ ...formDataLogin, ...{ password: e.target.value } })
                }
                logIn={onSubmitLogin}
                closeModal={closeModalLogin}
            />
        </Modal>
    );
}
