import { useEffect, useState } from 'react';
import WomanApi from '../../services/api/v3/WomanApi';
import { IWomanLanding } from '../../services/api/v3/WomanApi.interface';

export default function useInitLoveStories() {
    const [womanProfiles, setWomanProfiles] = useState<IWomanLanding[]>([]);

    useEffect(() => {
        new WomanApi().getRandomWomanOnline().then((res) => {
            if (res?.status && res?.result?.length) {
                setWomanProfiles(res?.result);
            }
        });
    }, []);

    return {
        womanProfiles,
    };
}
