import ClickHistoryApi from '../../services/api/v3/ClickHistoryApi';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useDetectedDevice from '../device/useDetectedDevice';
import useGoogleDataLayer from '../event/useGoogleDataLayer';
import MarketingApi from '../../services/api/MarketingApi';
import useFBConversion from '../event/useFBConversion';
import { RootState } from '../../index';

export default function useInitPushNotification() {
    const userState = useSelector((state: RootState) => state.user);
    const location = useLocation();
    const { fullInfo } = useDetectedDevice();
    const { pushEventConfirmedEmail } = useGoogleDataLayer();
    const { sentFBConfirmEmailConversion } = useFBConversion();

    const checkPush = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi().postCheckPush(
            userState?.info?.external_id,
            location?.search,
            fullInfo(),
        );
    };

    const checkAdrol = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi().postCheckAdrol(
            userState?.info?.external_id,
            location?.search,
            fullInfo(),
        );
    };

    const checkEmailAdrol = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi().postCheckEmailAdrol(
            userState?.info?.external_id,
            location?.search,
            fullInfo(),
        );
    };

    const checkSendgrig = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi().postCheckSendgrig(
            userState?.info?.external_id,
            location?.search,
            fullInfo(),
        );
    };

    const checkCenterEmail = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi()
            .postCheckCenterEmail(userState?.info?.external_id, location?.search, fullInfo())
            .then((res) => {
                if (res?.status && res?.confirmedEmail) {
                    new MarketingApi().postCheckExistEvent('confirmed_email').then((resp) => {
                        if (resp?.status && resp?.response?.check) {
                            // Push DataLayer for FB
                            pushEventConfirmedEmail();

                            if (userState?.tail?.includes('utm_age=45')) {
                                sentFBConfirmEmailConversion();
                            }
                        }
                    });
                }
            });
    };

    const postCheckGoogle = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi().postCheckGoogle(
            userState?.info?.external_id,
            location?.search,
            fullInfo(),
        );
    };

    const postCheckTbl = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi().postCheckTbl(
            userState?.info?.external_id,
            location?.search,
            fullInfo(),
        );
    };

    const lastActivity = () => {
        if (!userState?.info?.external_id) return;
        new ClickHistoryApi().postLastActivity(userState?.info?.external_id, fullInfo());
    };

    return {
        checkPush,
        checkAdrol,
        checkEmailAdrol,
        checkSendgrig,
        lastActivity,
        checkCenterEmail,
        postCheckGoogle,
        postCheckTbl,
    };
}
