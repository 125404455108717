import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PURCHASE from '../../constants/purchase';
import AccountApi from '../../services/api/Account';
import * as purchaseActions from '../../store/actions/purchase';
import * as loaderAction from '../../store/actions/loader';
import * as userAction from '../../store/actions/user';
import useTransition from '../transition/useTransition';
import useInitSubscribe from './subscribe/useInitSubscribe';
import * as alertAction from '../../store/actions/alert';
import * as ALERT from '../../constants/alert';
import * as modalsActions from '../../store/actions/modals';
import { RootState } from '../../index';
import { IPurchaseTokenItem } from '../../store/reducers/purchase/index.interface';
import useBuyCredits from './useBuyCredits';
import { IFrameFormData } from '../../pages/CheckoutPage/PaymentFrame/index.interface';

export default function useInitPaymentManageCreditCard({
    switchGetSubscribeInfo,
}: {
    switchGetSubscribeInfo: (activePaymentMethod: string, isDeletedCard?: boolean) => void;
}) {
    const dispatch = useDispatch();
    const transition = useTransition();
    const initSubscribe = useInitSubscribe();
    const { buyCredits } = useBuyCredits();

    const purchaseState = useSelector((state: RootState) => state.purchase);
    const userState = useSelector((state: RootState) => state.user);
    const modalsState = useSelector((state: RootState) => state.modals);

    const [isActiveManageCreditCard, setIsActiveManageCreditCard] = useState(false);
    const [isActiveCreditCardListModal, setIsActiveCreditCardListModal] = useState(false);
    const [isActiveNoCreditCardModal, setIsActiveNoCreditCardModal] = useState(false);
    const [isActiveAddCreditCardModal, setIsActiveAddCreditCardModal] = useState(false);
    const [frame, setFrame] = useState('');
    const [frameData, setFrameData] = useState<IFrameFormData | null>(null);

    const openManageCreditCardModal = () => {
        if (userState?.isBuying) {
            dispatch(userAction.isBuying(false));
            transition.addTail('?utm_purchaselist=reopen');
        }
        setIsActiveManageCreditCard(true);
    };
    const closeManageCreditCardModal = () => {
        setFrame('');
        setFrameData(null);
        setIsActiveManageCreditCard(false);
    };

    const openCreditCardListModal = () => setIsActiveCreditCardListModal(true);
    const closeCreditCardListModal = () => {
        closeManageCreditCardModal();
        setIsActiveCreditCardListModal(false);
    };

    const openAddCreditCardModal = () => {
        setIsActiveAddCreditCardModal(true);
        dispatch(modalsActions.openAddCreditCard());
    };
    const closeAddCreditCardModal = () => {
        closeManageCreditCardModal();
        setIsActiveAddCreditCardModal(false);
        dispatch(modalsActions.closeAddCreditCard());
    };

    const openNoCreditCardModal = () => setIsActiveNoCreditCardModal(true);
    const closeNoCreditCardModal = () => {
        closeManageCreditCardModal();
        setIsActiveNoCreditCardModal(false);
    };

    const createToken = async () => {
        setIsActiveNoCreditCardModal(false);
        setIsActiveCreditCardListModal(false);

        switch (purchaseState.activePaymentMethod) {
            case PURCHASE.PAYMENT_METHOD_PASTABANK:
                setIsActiveAddCreditCardModal(true);
                dispatch(modalsActions.openAddCreditCard());

                new AccountApi().postCreateToken().then((res) => {
                    setFrame(res?.result?.action);
                    setFrameData(res?.result?.fields);
                    setIsActiveManageCreditCard(true);
                });
                break;

            case PURCHASE.PAYMENT_METHOD_CARDPAY:
                setIsActiveAddCreditCardModal(false);
                dispatch(modalsActions.closeAddCreditCard());

                new AccountApi()
                    .postDefaultToken(0, PURCHASE.PAYMENT_METHOD_CARDPAY)
                    .then((res) => {
                        if (res?.status) {
                            buyCredits(
                                +PURCHASE.DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY ||
                                    purchaseState?.purchaseList?.find(
                                        (item) => +item?.id === purchaseState?.mostPopularPackageID,
                                    )?.credits ||
                                    0,
                            );
                        }
                    });
                break;

            case PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK:
                setIsActiveAddCreditCardModal(false);
                dispatch(modalsActions.closeAddCreditCard());

                new AccountApi()
                    .postDefaultToken(0, PURCHASE.PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK)
                    .then((res) => {
                        if (res?.status) {
                            buyCredits(
                                +PURCHASE.DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY ||
                                    purchaseState?.purchaseList?.find(
                                        (item) => +item?.id === purchaseState?.mostPopularPackageID,
                                    )?.credits ||
                                    0,
                            );
                        }
                    });
                break;

            case PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING:
                setIsActiveAddCreditCardModal(false);
                dispatch(modalsActions.closeAddCreditCard());

                new AccountApi()
                    .postDefaultToken(0, PURCHASE.PAYMENT_METHOD_UNIVERSEPAY_RECURRING)
                    .then((res) => {
                        if (res?.status) {
                            buyCredits(
                                +PURCHASE.DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY ||
                                    purchaseState?.purchaseList?.find(
                                        (item) => +item?.id === purchaseState?.mostPopularPackageID,
                                    )?.credits ||
                                    0,
                            );
                        }
                    });
                break;

            case PURCHASE.PAYMENT_METHOD_ACQUIRING:
                setIsActiveAddCreditCardModal(false);
                dispatch(modalsActions.closeAddCreditCard());

                new AccountApi()
                    .postDefaultToken(0, purchaseState.activePaymentMethod)
                    .then((res) => {
                        if (res?.status) dispatch(purchaseActions.setTokenList(res?.list));
                    });
                break;

            case PURCHASE.PAYMENT_METHOD_STRIPE:
                setIsActiveAddCreditCardModal(false);
                dispatch(modalsActions.closeAddCreditCard());

                new AccountApi().postDefaultToken(0, PURCHASE.PAYMENT_METHOD_STRIPE).then((res) => {
                    if (res?.status) {
                        dispatch(purchaseActions.setTokenList(res?.list));

                        buyCredits(
                            +PURCHASE.DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY ||
                                purchaseState?.purchaseList?.find(
                                    (item) => +item?.id === purchaseState?.mostPopularPackageID,
                                )?.credits ||
                                0,
                        );
                    }
                });
                break;

            default:
                break;
        }

        closeManageCreditCardModal();
    };

    const openRemoveCreditCardAlert = (selectedCard: IPurchaseTokenItem) => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.DELETE_CREDIT_CARD,
                message: selectedCard?.payment_method,
                successCallback: () => removeCreditCard(selectedCard),
            }),
        );
    };

    // selectedCard: IPurchaseTokenItem
    const removeCreditCard = async (selectedCard: IPurchaseTokenItem) => {
        if (
            purchaseState?.activeSubscribePaymentMethod?.length &&
            purchaseState?.activeSubscribePaymentMethod === selectedCard?.payment_method
        ) {
            await initSubscribe?.switchCancelSubscribe(purchaseState?.activeSubscribePaymentMethod);
        }

        await new AccountApi().postDeleteToken(selectedCard?.id).then((res) => {
            if (res?.status) {
                switchGetSubscribeInfo(purchaseState.activePaymentMethod, true);
                dispatch(purchaseActions.setTokenList(res?.list));
                closeCreditCardListModal();
                closeNoCreditCardModal();
                closeManageCreditCardModal();
            }
        });
    };

    const selectDefaultCard = (item: IPurchaseTokenItem, isDefault: number) => {
        if (!isDefault) {
            closeCreditCardListModal();
            closeNoCreditCardModal();

            new AccountApi()
                .postDefaultToken(item?.id, purchaseState.activePaymentMethod)
                .then((res) => {
                    if (res?.status) {
                        dispatch(purchaseActions.setTokenList(res?.list));

                        switch (purchaseState.activePaymentMethod) {
                            case PURCHASE.PAYMENT_METHOD_CARDPAY:
                                buyCredits(
                                    +PURCHASE.DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY ||
                                        purchaseState?.purchaseList.find(
                                            (item) =>
                                                +item?.id === purchaseState?.mostPopularPackageID,
                                        )?.credits ||
                                        0,
                                );
                                break;

                            default:
                                break;
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (!modalsState.showAddCreditCardModal && frame?.length) closeAddCreditCardModal();
    }, [modalsState.showAddCreditCardModal]);

    useEffect(() => {
        if (isActiveManageCreditCard) {
            if (+frame?.length) return;

            if (purchaseState?.tokenList?.length > 0) openCreditCardListModal();
            else openNoCreditCardModal();
        }
    }, [purchaseState.tokenList, isActiveManageCreditCard]);

    useEffect(() => {
        if (purchaseState?.isAddCard) {
            closeAddCreditCardModal();
            dispatch(loaderAction.setActiveGifLoader(true));
            dispatch(purchaseActions.setIsAddCard(false));

            new AccountApi()
                .postTokenList()
                .then((res) => {
                    if (res?.status) dispatch(purchaseActions.setTokenList(res?.list));
                })
                .finally(() => {
                    dispatch(loaderAction.setActiveGifLoader(false));
                    openManageCreditCardModal();
                });
        }
    }, [purchaseState.isAddCard]);

    return {
        frame,
        frameData,
        fetchTokenList: purchaseState?.tokenList?.filter(
            (item) =>
                item?.payment_method?.toLowerCase() ===
                purchaseState.activePaymentMethod?.toLowerCase(),
        ),
        isActiveCreditCardListModal,
        isActiveNoCreditCardModal,
        isActiveAddCreditCardModal,
        isActiveManageCreditCard,

        openManageCreditCardModal,
        closeManageCreditCardModal,
        openCreditCardListModal,
        closeCreditCardListModal,
        openAddCreditCardModal,
        closeAddCreditCardModal,
        openNoCreditCardModal,
        closeNoCreditCardModal,

        selectDefaultCard,
        openRemoveCreditCardAlert,
        createToken,
    };
}
