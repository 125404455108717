import React from 'react';
import * as MENU from '../../../constants/menu';
import {
    closeFilterSearch,
    closeMobChatList,
    closeMobileMenu,
    closePanel,
    openMobChatList,
    removeBlur,
} from '../../../services/dom';
import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../../../hooks/transition/useTransition';
import * as currentChatAction from '../../../store/actions/currentChat';
import * as modalsAction from '../../../store/actions/modals';
import * as SEX from '../../../constants/sex';
import useHandleMobileChat from '../../../hooks/chat/helpers/useHandleMobileChat';
import * as currentChatMailboxAction from '../../../store/actions/currentChatMailbox';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import styles from './styles.module.scss';
import IconChat from '../../Icons/IconChat';
import IconLetters from '../../Icons/IconLetters';
import IconSearch from '../../Icons/IconSearch';
import IconMobileMenu from '../../Icons/IconMobileMenu';
import IconLettersActive from '../../Icons/IconLettersActive';
import IconSearchActive from '../../Icons/IconSearchActive';
import IconChatActive from '../../Icons/IconChatActive';
import IconLikeMenuActive from '../../Icons/IconLikeMenuActive';
import IconLikeMenu from '../../Icons/IconLikeMenu';
import { RootState } from '../../../index';

export default function MobileFooter() {
    const countersState = useSelector((state: RootState) => state.counters);
    const menuState = useSelector((state: RootState) => state.menu);
    const userState = useSelector((state: RootState) => state.user);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);

    const dispatch = useDispatch();
    const transition = useTransition();
    const handleMobileChat = useHandleMobileChat();
    const handleActiveBlocks = useHandleActiveBlocks();

    const isLetterActive =
        menuState.main === MENU.MENU_LETTER ||
        localStorage.getItem('from-page')?.includes(MENU.MENU_LETTER);
    const isSearchActive =
        menuState.main === MENU.MENU_SEARCH ||
        localStorage.getItem('from-page')?.includes(MENU.MENU_SEARCH);
    const isChatActive = MENU.CHAT_PAGES.some((item) => window.location?.pathname.includes(item));
    const isLikeActive =
        menuState.main === MENU.MENU_LIKE_HER ||
        menuState.main === MENU.MENU_LIKE_HISTORY_PAGE ||
        localStorage.getItem('from-page')?.includes(MENU.MENU_LIKE_HER) ||
        localStorage.getItem('from-page')?.includes(MENU.MENU_LIKE_HISTORY_PAGE);
    const isMenuActive =
        menuState.main === MENU.MENU_PAGE ||
        menuState.main === MENU.GIFTS_PAGE ||
        menuState.main === MENU.CREDITS_PAGE ||
        menuState.main === MENU.CONTACT_PAGE ||
        menuState.main === MENU.MEETING_PAGE ||
        menuState.main === MENU.PROFILE_PAGE ||
        menuState.main === MENU.EDIT_PROFILE_PAGE ||
        menuState.main === MENU.BLOCK_LIST_PAGE ||
        menuState.main === MENU.VIDEO_SHOW_PAGE ||
        menuState.main === MENU.FAQ_PAGE ||
        menuState.main === MENU.POLICY_PAGE;

    const classNameLetterActive = isLetterActive ? styles.active : '';
    const classNameSearchActive = isSearchActive ? styles.active : '';
    const classNameChatActive = isChatActive ? styles.active : '';
    const classNameLikeActive = isLikeActive ? styles.active : '';
    const classNameMenuActive = isMenuActive ? styles.active : '';

    const titleLike =
        SEX.SEX_TEXT[(userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT) || SEX.MALE_SEX]
            .LIKE_SIDE_MENU_BTN;

    const openPage = (menu: string) => {
        closePanel();
        dispatch(currentChatAction.touchSwipeChatList('right'));
        removeBlur();
        closeMobileMenu();
        closeFilterSearch();
        closeMobChatList();
        dispatch(modalsAction.setActiveSubMenu());

        if (menu === MENU.MENU_CHAT) openMobChatList();

        if (menu === MENU.MENU_LETTER && currentChatMailboxState?.info?.female_id) {
            dispatch(currentChatMailboxAction.removeMailboxChat());
        }

        if (menuState?.main !== menu) {
            transition.transitionWithOnlyHistoryPush(`/${menu}`);
        }
    };

    if (handleMobileChat?.isActiveNewMobileChat) return null;
    return (
        <ul className={styles.mob_bottom_nav}>
            <li className={styles.mob_bottom_nav_item} key="letter">
                <button
                    type="button"
                    className={`${styles.mob_bottom_nav_button} ${classNameLetterActive}`}
                    onClick={() => openPage('letter')}
                >
                    {!!countersState?.letterCounter && (
                        <div className={styles.mob_bottom_nav_item_notify}>
                            {countersState?.letterCounter}
                        </div>
                    )}
                    {isLetterActive ? <IconLettersActive /> : <IconLetters />}
                    <span>Letters</span>
                </button>
            </li>
            {!handleActiveBlocks.isTwitterUser && (
                <li className={styles.mob_bottom_nav_item} key="search">
                    <button
                        type="button"
                        className={`${styles.mob_bottom_nav_button} ${classNameSearchActive}`}
                        onClick={() => openPage('search')}
                    >
                        {isSearchActive ? <IconSearchActive /> : <IconSearch />}
                        <span>Search</span>
                    </button>
                </li>
            )}
            <li className={styles.mob_bottom_nav_item} key="chat">
                {!!countersState?.chatCounter?.unanswered && (
                    <div className={styles.mob_bottom_nav_item_notify}>
                        {countersState?.chatCounter?.unanswered}
                    </div>
                )}
                <button
                    type="button"
                    className={`${styles.mob_bottom_nav_button} ${classNameChatActive}`}
                    onClick={() => openPage('chat')}
                >
                    {isChatActive ? <IconChatActive /> : <IconChat />}
                    <span>Chat</span>
                </button>
            </li>
            <li className={styles.mob_bottom_nav_item} key="like">
                <button
                    type="button"
                    className={`${styles.mob_bottom_nav_button} ${classNameLikeActive}`}
                    onClick={() => openPage('like')}
                >
                    {isLikeActive ? <IconLikeMenuActive /> : <IconLikeMenu />}
                    <span>{titleLike}</span>
                </button>
            </li>
            <li className={styles.mob_bottom_nav_item} key="menu">
                <button
                    type="button"
                    className={`${styles.mob_bottom_nav_button} ${classNameMenuActive}`}
                    onClick={() => openPage('menu')}
                >
                    <IconMobileMenu />
                    <span>Menu</span>
                </button>
            </li>
        </ul>
    );
}
