import { useSelector } from 'react-redux';
import useDetectedDevice from '../../device/useDetectedDevice';
import moment from 'moment';
import SITE from '../../../config/site';
import { sendErrToSentry } from '../../../services/sentry';
import { RootState } from '../../../index';

export default function useHandleMobileChat() {
    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const { isMobileIOS, osVersion } = useDetectedDevice();

    const checkNewMobileChat = () => {
        const isSizeScreen = window.innerWidth < 999;

        if (
            userState?.info?.external_id &&
            [
                1143577805, 1521227398, 1396995329, 1833044056, 1057405402, 1115646471, 2140899877,
                1311435152, 1283094773, 1976819682, 2082265964, 1566263519,
            ]?.includes(userState?.info?.external_id) &&
            isSizeScreen
        ) {
            return true;
        }

        try {
            const isMobileOSVersion = isMobileIOS
                ? +osVersion.split('.')[0] >= 15
                : +osVersion.split('.')[0] >= 10;
            const isMobileOSVersionV2 = isMobileIOS
                ? +osVersion.split('.')[0] >= 16
                : +osVersion.split('.')[0] >= 11;
            const isBTest = userState?.info?.abtest?.toUpperCase() === 'B';
            const isUtmCrixOkamour = userState?.tail
                ?.toLowerCase()
                ?.includes('utm_netw=crix_okamour');

            const isSiteID = SITE.ID === 2;
            const isSiteIDV2 = SITE.ID === 5;
            const isAmourmeet = SITE.ID === 6;
            const isOkamour = SITE.ID === 7;
            const isFeelflame = SITE.ID === 10;

            const isStartDate = moment(userState?.info?.created_at).isAfter('2023-06-20 09:00:00');
            const isEndDate = moment(userState?.info?.created_at).isBefore('2023-07-13 12:30:00');

            const isStartDateV2 = moment(userState?.info?.created_at).isAfter(
                '2023-06-27 10:30:00',
            );
            const isEndDateV2 = moment(userState?.info?.created_at).isBefore('2023-07-13 12:30:00');

            const isStartDateV3 = moment(userState?.info?.created_at).isAfter(
                '2023-07-04 09:00:00',
            );
            const isEndDateV3 = moment(userState?.info?.created_at).isBefore('2023-07-13 12:30:00');

            const isStartDateV4 = moment(userState?.info?.created_at).isAfter(
                '2023-07-11 14:30:00',
            );
            const isEndDateV4 = moment(userState?.info?.created_at).isBefore('2023-07-13 12:30:00');

            const isStartDateV5 = moment(userState?.info?.created_at).isAfter(
                '2023-08-09 10:30:00',
            );
            const isEndDateV5 = moment(userState?.info?.created_at).isBefore('2023-08-18 09:00:00');

            const isStartDateV6 = moment(userState?.info?.created_at).isAfter(
                '2023-10-17 14:00:00',
            );
            const isEndDateV6 = moment(userState?.info?.created_at).isBefore('2023-10-25 16:00:00');

            const isStartDateV7 = moment(userState?.info?.created_at).isAfter(
                '2023-10-25 16:00:00',
            );
            const isEndDateV7 = moment(userState?.info?.created_at).isBefore('2023-11-02 14:00:00');

            // const isMSD = SITE.ID === 2;
            // const isLFH = SITE.ID === 5;
            // const isStartDateV5 = moment(userState?.info?.created_at).isAfter('2023-07-18 14:00:00');
            // if (isSizeScreen && isMobileOSVersion && isBTest && isMSD && isStartDateV5) return true; // AB test - MSD (from 2023-07-18 08:30:00)
            // if (isSizeScreen && isMobileOSVersion && isBTest && isLFH && isStartDateV5) return true; // AB test - LFH (from 2023-07-18 08:30:00)

            // Closed
            if (
                isSizeScreen &&
                isMobileOSVersion &&
                isBTest &&
                isStartDate &&
                isSiteID &&
                isEndDate
            )
                return true; // AB test - MSD (from 2023-06-20 09:00:00)
            if (
                isSizeScreen &&
                isMobileOSVersion &&
                isBTest &&
                isStartDateV2 &&
                isSiteIDV2 &&
                isEndDateV2
            )
                return true; // AB test - LFH (from 2023-06-27 10:30:00)
            if (
                isSizeScreen &&
                isMobileOSVersion &&
                isBTest &&
                isStartDateV3 &&
                isAmourmeet &&
                isEndDateV3
            )
                return true; // AB test - AMOUR (from 2023-07-04 09:00:00)
            if (isSizeScreen && isMobileOSVersion && isBTest && isStartDateV4 && isEndDateV4)
                return true; // AB test - All (from 2023-07-11 11:00:00)
            if (
                isSizeScreen &&
                isMobileOSVersion &&
                isBTest &&
                isFeelflame &&
                isStartDateV5 &&
                isEndDateV5
            )
                return true; // AB test - Feelflame (from 2023-08-09 10:30:00 - 2023-08-18 09:00:00)

            if (
                isSizeScreen &&
                isMobileOSVersionV2 &&
                isBTest &&
                isOkamour &&
                isUtmCrixOkamour &&
                isStartDateV6 &&
                isEndDateV6
            )
                return true; // GT-4612
            if (
                isSizeScreen &&
                isMobileOSVersionV2 &&
                isBTest &&
                isAmourmeet &&
                isStartDateV7 &&
                isEndDateV7
            )
                return true; // GT-4796 - GT-4952

            return false;
        } catch (error) {
            sendErrToSentry(error as Error);
            return false;
        }
    };

    const isNewMobileChat = checkNewMobileChat();

    return {
        isNewMobileChat,
        isActiveNewMobileChat:
            isNewMobileChat && (currentChatState?.info?.id || currentChatMailboxState?.info?.id),
    };
}
