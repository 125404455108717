import { UserActionTypes } from '../../constants/user';
import * as SEX from '../../../constants/sex';
import { calcBalance } from '../../../services/methods/purchase';
import { IUserInfo, IUserState, UserActions } from './index.interface';

const initState = {
    info: null,
    prices: null,
    isCredit: false,
    common_files: null,
    online_now: null,
    lastBuyCredits: null,
    isBuying: false,
    balance: 0,
    credits: 0,
    bonusCredits: 0,
    promoCredits: 0,
    tail: '',
    click_id: '',
    clickid: '',
    utm_aff_hash: '',
    supportEmail: '',
    sender: '',
    abtest: null,
    sender_manual: null,
    parallel_sender: null,
    service_settings: null,
    imbraData: {},
    userRoles: null,
    loyaltyProgram: null,
    isFBConversion: false,
    needShowLetterModal: 0,
    needShowGiftModal: 0,
    giftActive: 0,
    giftsDiscount: false,
    giftsDiscountValue: 0,
    search_multigender: 0,
    letterActive: false,
};

export default function (state: IUserState = initState, action: UserActions): IUserState {
    switch (action.type) {
        case UserActionTypes.SET_LOYALTY_PROGRAM:
            return { ...state, ...{ loyaltyProgram: action?.loyaltyProgram } };

        case UserActionTypes.SET_USER_EMAIL:
            return {
                ...state,
                ...{ info: { ...(state?.info as IUserInfo), ...{ email: action?.email } } },
            };

        case UserActionTypes.SET_USER_SPEND_FIRST_CREDIT:
            return {
                ...state,
                ...{ info: { ...(state?.info as IUserInfo), ...{ spend_first_credit: 1 } } },
            };

        case UserActionTypes.SET_USER_REAL_NAME:
            return {
                ...state,
                ...{ info: { ...(state?.info as IUserInfo), ...{ real_name: action?.realName } } },
            };

        case UserActionTypes.SET_IS_APPROVE_PHONE:
            return {
                ...state,
                ...{
                    info: {
                        ...(state?.info as IUserInfo),
                        ...{
                            is_approve_phone: action?.status,
                            is_bonus_phone: action?.isBonusPhone,
                            phone: action?.phone,
                        },
                    },
                },
            };

        case UserActionTypes.SET_IS_USER_VERIFIED:
            return {
                ...state,
                ...{
                    info: {
                        ...(state?.info as IUserInfo),
                        ...{
                            is_verified: action?.isVerified,
                            is_bonus_phone: action?.isBonus,
                        },
                    },
                },
            };

        case UserActionTypes.USER_INFO_GET:
            return {
                ...state,
                ...{
                    // SUPPORT
                    supportEmail: action?.data?.supportEmail,

                    // ROLE
                    userRoles: action?.data?.userRoles,

                    // SENDER
                    sender: action?.data?.statistic_settings || '',

                    // TAIL
                    tail: action?.data?.utmTail?.tail || '',
                    click_id: action?.data?.utmTail?.click_id || '',
                    clickid: action?.data?.utmTail?.clickid || '',
                    utm_aff_hash: action?.data?.utmTail?.utm_aff_hash || '',

                    // USER_INFO
                    info: {
                        ...action?.data?.userInfo,
                        ...{
                            show_already_registration_bonus_popup:
                                action?.data?.show_already_registration_bonus_popup,
                            sex_text: +action?.data?.multigender
                                ? (
                                      +action?.data?.userInfo?.gender
                                          ? +action?.data?.userInfo?.gender
                                          : 0
                                  )
                                    ? SEX.FEMALE_SEX
                                    : SEX.MALE_SEX
                                : SEX.MALE_SEX,
                            gender: +action?.data?.multigender
                                ? +action?.data?.userInfo?.gender
                                    ? +action?.data?.userInfo?.gender
                                    : 0
                                : 0,
                            hide_videoshow: +action?.data?.multigender
                                ? +action?.data?.hide_videoshow && +action?.data?.userInfo?.gender
                                    ? +action?.data?.hide_videoshow
                                    : 0
                                : 0,
                            hide_gift: +action?.data?.multigender
                                ? +action?.data?.hide_gift && +action?.data?.userInfo?.gender
                                    ? +action?.data?.hide_gift
                                    : 0
                                : 0,
                            disabledUser: action?.data?.userInfo?.disabledUser,
                            is_verified:
                                action?.data?.userInfo?.is_vip ||
                                action?.data?.userInfo?.is_verified,
                            needShowVerify: action?.data?.needShowVerify,
                            verified: action?.data?.verified,
                        },
                    },

                    // OTHER
                    service_settings: action?.data?.service_settings,
                    parallel_sender: action?.data?.parallel_sender,
                    sender_manual: action?.data?.sender_manual,
                    abtest: action?.data?.abtest,
                    search_multigender: action?.data?.search_multigender,
                    online_now: action?.data?.online_now,
                    common_files: action?.data?.common_files,
                    prices: action?.data?.prices,
                    balance: calcBalance(action?.data?.userInfo),
                    credits: action?.data?.userInfo?.credits,
                    bonusCredits: action?.data?.userInfo?.bonus_credits,
                    promoCredits: +action?.data?.userInfo?.promo_credits
                        ? +action?.data?.userInfo?.promo_credits
                        : 0,
                    needShowGiftModal: action?.data?.needShowGiftModal,
                    needShowLetterModal: action?.data?.needShowLetterModal,
                    giftActive: action?.data?.giftActive,
                    giftsDiscount: action?.data.giftsDiscount,
                    giftsDiscountValue: action?.data.giftsDiscountValue,
                    letterActive: action?.data?.letterActive,
                },
            };

        case UserActionTypes.USER_INFO_UPDATE:
            return {
                ...state,
                info: action.data,
            };
        case UserActionTypes.USER_CREDIT:
            return {
                ...state,
                ...{
                    isCredit: action.data,
                },
            };
        case UserActionTypes.ADD_LAST_BUY_CREDITS:
            return {
                ...state,
                ...{
                    lastBuyCredits: action.data,
                },
            };
        case UserActionTypes.IS_BUYING:
            return {
                ...state,
                ...{
                    isBuying: action.data,
                },
            };
        case UserActionTypes.SET_BALANCE:
            return {
                ...state,
                ...{
                    balance: calcBalance(action?.balance),
                    credits: action?.balance?.credits,
                    bonusCredits: action?.balance?.bonus_credits,
                    promoCredits:
                        action?.balance?.promo_credits && +action?.balance?.promo_credits
                            ? +action?.balance?.promo_credits
                            : 0,
                },
            };

        case UserActionTypes.EDIT_USER_PHOTO_LINK:
            if (action?.photoLink) {
                return {
                    ...state,
                    ...{
                        info: {
                            ...(state?.info as IUserInfo),
                            ...{
                                photo_link: action?.photoLink,
                                photo_link_w56_h56: action?.photoLink,
                            },
                        },
                    },
                };
            }
            return state;

        case UserActionTypes.DELETE_USER_PHOTO_LINK:
            return {
                ...state,
                ...{
                    info: {
                        ...(state?.info as IUserInfo),
                        ...{
                            photo_link: '',
                            photo_link_w56_h56: '',
                        },
                    },
                },
            };

        case UserActionTypes.SET_IMBRA_DATA: {
            return {
                ...state,
                ...{
                    imbraData: action?.data,
                },
            };
        }

        case UserActionTypes.SET_IMBRA_STATUS:
            return {
                ...state,
                ...{
                    imbraData: {
                        ...state?.imbraData,
                        ...{ status: action?.status },
                    },
                },
            };

        case UserActionTypes.SET_IS_FB_CONVERSION:
            return {
                ...state,
                ...{
                    isFBConversion: action?.status,
                },
            };

        case UserActionTypes.UPDATE_INFO_SEX:
            return {
                ...state,
                ...{ info: { ...(state?.info as IUserInfo), ...{ sex: action?.sexFilter } } },
            };

        case UserActionTypes.UPDATE_USERNAME:
            return {
                ...state,
                ...{ info: { ...(state?.info as IUserInfo), ...{ username: action?.username } } },
            };

        default:
            return state;
    }
}
