import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import moment from 'moment';
import { checkUrl, hideStringStar } from '../../services/methods';
import { diffTimeInSeconds } from '../../services/time';
import { insertedMessageBeforeSync } from '../../services/init';
import { scrollTopToMainChat } from '../../services/dom';
import ChatsApi from '../../services/api/ChatsApi';
import * as LENGTH from '../../constants/length';
import * as REGEXP from '../../constants/regExp';
import * as alertAction from '../../store/actions/alert';
import * as USER from '../../constants/user';
import * as ALERT from '../../constants/alert';
import * as notifyAction from '../../store/actions/notify';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import * as userAction from '../../store/actions/user';
import * as MIRROR from '../../constants/mirror';
import useInitSmileMobBlock from './helpers/useInitSmileMobBlock';
import useInitStickerMobBlock from './helpers/useInitStickerMobBlock';
import SITE from '../../config/site';
import useScrollChatMessageList from './helpers/useScrollChatMessageList';
import useHandleRedUser from '../user/useHandleRedUser';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import { getLastMessageContent } from '../../services/methods/chat';
import { RootState } from '../../index';
import { PickerProps } from 'emoji-mart/dist-es/utils/shared-props';

export default function useHandleChatForm(
    writeMsgRef: RefObject<HTMLDivElement | null>,
    inputTextareaRef?: RefObject<HTMLTextAreaElement | null>,
) {
    const dispatch = useDispatch();
    const initSmileMobBlock = useInitSmileMobBlock(writeMsgRef);
    const initStickerMobBlock = useInitStickerMobBlock(writeMsgRef);
    const scrollChatMessageList = useScrollChatMessageList();
    const handleRedUser = useHandleRedUser();

    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const [inputData, setStateInputData] = useState<string>('');
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

    const addEmoji = (emoji: PickerProps) => {
        if (LENGTH.MESSAGE_MAX_LENGTH >= (inputData + emoji?.native)?.length) {
            setStateInputData(inputData + emoji?.native);
        }
    };

    const onFocusInput = () => {
        setTimeout(() => {
            scrollChatMessageList.onScrollDown();
        }, 200);
    };

    const onBlurInput = () => {
        setTimeout(() => {
            scrollChatMessageList.onScrollDown();
        }, 200);
    };

    const handleInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTimeout(() => {
            scrollChatMessageList.onScrollDown();
        }, 200);

        const target = event?.target as HTMLInputElement;
        const text = target?.value;
        const newlineCount = (text?.match(/\n/g) || [])?.length;

        if (newlineCount <= LENGTH.MESSAGE_MAX_NUMBER_LINE) {
            setStateInputData(text?.slice(0, LENGTH.MESSAGE_MAX_LENGTH));
        }
    };

    const onSubmitMessage = () => {
        if (inputTextareaRef && inputTextareaRef?.current) inputTextareaRef?.current?.focus();

        setTimeout(() => {
            scrollChatMessageList.onScrollDown();
        }, 200);

        if (isLoadingRequest) return false;
        if (!userState?.info?.external_id) return false;
        if (!currentChatState?.info?.id) return false;

        const hashId = moment().unix();

        initSmileMobBlock.setIsOpenMobSmileBlock(false);
        initStickerMobBlock.setIsOpenMobStickerBlock(false);

        // let _inputData = inputData.replace(/(\r\n|\n|\r)/gm, "");
        const _inputData = MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID)
            ? inputData
            : hideStringStar(inputData, REGEXP.NUMBER_IN_STRING);

        if (!MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID) && checkUrl(_inputData)) {
            return dispatch(alertAction.setMessage({ message: 'Invalid message' }));
        }

        if (_inputData?.trim()?.length > 0) {
            if (
                currentChatState.lastMessageDate &&
                (diffTimeInSeconds(
                    currentChatState.lastMessageDate,
                    currentChatState.openChatDate,
                ) >= USER.CHECK_TIME_LAST_MESS_SEND ||
                    diffTimeInSeconds(
                        currentChatState.lastMessageDate,
                        currentChatState.openChatDate,
                    ) < 0) &&
                +userState?.balance < USER.MIN_STEP_CREDITS_MESS_SEND
            ) {
                dispatch(alertAction.setCustomAlertType({ alertType: ALERT.ENOUGH_CREDITS_CHAT }));
                return;
            }

            setStateInputData('');
            dispatch(
                currentChatAction.addMessageToHistory(
                    insertedMessageBeforeSync(hashId, _inputData, userState?.info?.external_id),
                ),
            );
            dispatch(currentChatAction.setIsNewMessage(false));

            setIsLoadingRequest(true);
            new ChatsApi()
                .postMessage(
                    userState?.info?.external_id,
                    currentChatState?.info?.id,
                    _inputData,
                    'SENT_TEXT',
                )
                .then((res) => {
                    if (res?.status) {
                        if (!res?.response?.message_object) {
                            dispatch(currentChatAction.deleteMessageInHistory(hashId));
                            if (res?.message)
                                dispatch(alertAction.setMessage({ message: res?.message }));
                            else
                                dispatch(
                                    alertAction.setCustomAlertType({
                                        alertType: ALERT.ENOUGH_CREDITS_CHAT,
                                    }),
                                );
                            return;
                        }

                        dispatch(
                            currentChatAction.updateMessageInHistory(
                                hashId,
                                res?.response?.message_object,
                            ),
                        );
                        dispatch(
                            chatsAction.updateMessageInChatList(
                                res?.response?.message_object?.chat_uid,
                                getLastMessageContent(
                                    res?.response?.message_object?.message_content,
                                    res?.response?.message_object?.message_type,
                                ),
                                res?.response?.message_object?.message_type,
                                res?.response?.message_object?.date_created,
                                res?.response?.message_object?.sender_external_id,
                            ),
                        );

                        if (res?.response?.match_object?.chat_uid) {
                            dispatch(
                                notifyAction.addTransactionNotify({
                                    id:
                                        moment().unix() +
                                        res?.response?.match_object?.sender_external_id,
                                    ...res?.response?.match_object,
                                    chat_uid: res?.response?.match_object?.chat_uid,
                                }),
                            );
                        }

                        dispatch(userAction.setBalance());
                        handleRedUser.submitLog(
                            RED_USER_LOG_TYPES.MESSAGE_FROM,
                            JSON.stringify({
                                womanExternalID: currentChatState?.info?.id,
                                action: 'SENT_TEXT',
                                content: _inputData,
                            }),
                        );

                        if (userState?.info?.external_id) {
                            dispatch(
                                notifyAction.deleteNotify(
                                    userState?.info?.external_id,
                                    currentChatState?.info?.chat_uid,
                                ),
                            );
                        }

                        return;
                    } else if (res?.block) {
                        dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    } else {
                        if (res?.message)
                            dispatch(
                                alertAction.setMessage({
                                    message: res?.message,
                                    title: res?.title,
                                    isPaymentBtn: res?.activePayment,
                                }),
                            );
                        else
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.ENOUGH_CREDITS_CHAT,
                                }),
                            );
                    }
                    dispatch(currentChatAction.deleteMessageInHistory(hashId));
                })
                .finally(() => setIsLoadingRequest(false));
        }
    };

    const onSubmitMessageEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event?.key === 'Enter') {
            const target = event?.target as HTMLTextAreaElement;
            const value = target?.value;
            const lines = value.split('\n');

            //Enter + Shift
            if (event?.shiftKey) {
                if (lines?.length < LENGTH.MESSAGE_MAX_NUMBER_LINE) return false;
                else return event.preventDefault();
            }
            event.preventDefault();
            onSubmitMessage();
        }
    };

    const onPressNewMessButton = () => {
        scrollTopToMainChat();
        dispatch(currentChatAction.setIsNewMessage(false));
    };

    useEffect(() => {
        if (currentChatState?.info?.chat_uid) setStateInputData('');
    }, [currentChatState?.info?.chat_uid]);

    return {
        ...initSmileMobBlock,
        ...initStickerMobBlock,
        inputData,
        onPressNewMessButton,
        addEmoji,
        onFocusInput,
        onBlurInput,
        handleInput,
        onSubmitMessage,
        onSubmitMessageEnter,
        inputTextareaRef,
        isActiveLargeInput: true,
        isLoadingRequest,
        setIsLoadingRequest,
        userState,
    };
}
