import React from 'react';
import styles from './style.module.scss';
import { convertPrice, currencyPriceString } from '../../../../../services/methods';
import { ILessCreditsProps } from './index.interface';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconCross from '../../../../Icons/IconCross';

export default function LessCredits({
    title,
    message,
    closeModalLessCredits,
    packId,
    prices,
    activePack,
    setActivePack,
    onGetCredits,
}: ILessCreditsProps) {
    const filteredPrices = [
        ...[
            prices?.find((item: any) => +item?.id === +packId),
            ...(prices?.length ? prices.filter((item: any) => +item?.id !== +packId) : []),
        ],
    ];

    return (
        <div className={styles.popup_credits_out}>
            <div className={styles.popup_credits_out_wrap}>
                <h3 className={styles.popup_credits_out_title}>
                    {title ?? 'You’re almost out of credits'}
                </h3>
                <p className={styles.popup_credits_out_desc}>
                    {message ??
                        "You have only 10 credits left. Don't make your member wait - top up your balance right now!"}
                </p>
                <div className={styles.popup_credits_out_grid}>
                    {!!filteredPrices?.length &&
                        filteredPrices.map((item, key) =>
                            +item?.id === +packId ? (
                                <React.Fragment key={key}>
                                    <input
                                        type="radio"
                                        name="credits_out"
                                        id={`credits_out_${key}`}
                                        checked={!activePack || +activePack === +item?.id}
                                        onChange={() => setActivePack(item?.id)}
                                    />
                                    <label
                                        className={styles.popup_credits_out_item_big}
                                        htmlFor={`credits_out_${key}`}
                                    >
                                        <div className={styles.popup_credits_out_item_big_left}>
                                            <div
                                                className={styles.popup_credits_out_item_big_input}
                                            />
                                            <span
                                                className={styles.popup_credits_out_item_big_title}
                                            >
                                                Most popular credit pack
                                            </span>
                                        </div>
                                        <div className={styles.popup_credits_out_item_big_right}>
                                            <div
                                                className={
                                                    styles.popup_credits_out_item_big_credits_count
                                                }
                                            >
                                                {`${item?.credits} credits only for`}
                                            </div>
                                            <div
                                                className={styles.popup_credits_out_item_big_price}
                                            >
                                                {currencyPriceString(
                                                    item?.currency,
                                                    convertPrice(item?.price),
                                                )}
                                            </div>
                                        </div>
                                    </label>
                                </React.Fragment>
                            ) : (
                                <React.Fragment key={key}>
                                    <input
                                        type="radio"
                                        name="credits_out"
                                        id={`credits_out_${key}`}
                                        onChange={() => setActivePack(item?.id)}
                                    />
                                    <label
                                        className={styles.popup_credits_out_item}
                                        htmlFor={`credits_out_${key}`}
                                        onClick={() => setActivePack(item?.id)}
                                    >
                                        <div className={styles.popup_credits_out_item_left}>
                                            <div
                                                className={styles.popup_credits_out_item_input}
                                            ></div>
                                            <div className={styles.popup_credits_out_item_text}>
                                                <span>{item?.credits}</span> credit pack
                                            </div>
                                        </div>
                                        <div className={styles.popup_credits_out_item_price}>
                                            {currencyPriceString(
                                                item?.currency,
                                                convertPrice(item?.price),
                                            )}
                                        </div>
                                    </label>
                                </React.Fragment>
                            ),
                        )}
                </div>
                <div className={styles.popup_credits_out_buttons}>
                    <div className={styles.popup_credits_out_btn_credit} onClick={onGetCredits}>
                        <IconCheckedCircle />
                        Get credit pack via <span>credit</span> card
                    </div>
                </div>
            </div>
            <div className={styles.popup_credits_out_close} onClick={closeModalLessCredits}>
                <IconCross />
            </div>
        </div>
    );
}
