import React, { ComponentType } from 'react';
import { Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import useTransition from '../../hooks/transition/useTransition';
import UserLayout from '../../layouts/UserLayout';
import { localStorageGetItem } from '../../utils/local-storage';
import { getUrlParameter } from '../../utils/url-utils';

interface IUserRouteProps extends RouteProps {
    component: ComponentType<any>;
}
export default function UserRoute({ component: Component, ...rest }: IUserRouteProps) {
    const transition = useTransition();
    const location = useLocation();
    const accessKey = getUrlParameter('accessKey');
    const token = localStorageGetItem('token', true);

    if (!token && !accessKey) {
        transition.transitionWithHistoryPush('', `/${location?.search || ''}`);
        return null;
    }

    return (
        <UserLayout>
            <Route {...rest} render={(props: RouteComponentProps) => <Component {...props} />} />
        </UserLayout>
    );
}
