import { useEffect, useState } from 'react';
import useTransition from '../transition/useTransition';

export default function useInitTutorial() {
    const transition = useTransition();
    const [isTutorialPopup, setIsTutorialPopup] = useState(false);

    const openTutorialModal = () => setIsTutorialPopup(true);
    const closeTutorialModal = () => setIsTutorialPopup(false);

    useEffect(() => {
        if (
            window?.location?.search?.includes('tutorial=search') ||
            window?.location?.search?.includes('tutorial=profile')
        ) {
            openTutorialModal();
            transition.addTail(window?.location?.search?.replace('tutorial=profile', ''));
        }
    }, []);

    return {
        isTutorialPopup,
        openTutorialModal,
        closeTutorialModal,
    };
}
