import React from 'react';

export default function IconLetters() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 6C3.5 5.72386 3.72386 5.5 4 5.5H12H20C20.0122 5.5 20.1499 5.52118 20.3143 5.68566C20.4788 5.85014 20.5 5.98785 20.5 6V6.61309L12 11.2881L3.5 6.61309V6ZM2 7.5V6C2 5.5 2 4 3.5 4H4H12H20C21.1046 4 22 4.89543 22 6V7.5V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V7.5ZM20.5 8.325V18C20.5 18.2761 20.2761 18.5 20 18.5H4C3.72386 18.5 3.5 18.2761 3.5 18V8.325L12 13L20.5 8.325Z"
            />
        </svg>
    );
}
