export enum LikesActionTypes {
    LIKES_GET_LIST = 'LIKES_GET_LIST',
    FILTER_TYPE_CHANGE = 'FILTER_TYPE_CHANGE',
    SET_SELECTED_INDEX = 'SET_SELECTED_INDEX',
    GET_LIKE_HISTORY_LIST = 'GET_LIKE_HISTORY_LIST',
    SET_LIKE_HISTORY_PAGE = 'SET_LIKE_HISTORY_PAGE',
    INIT_LIKE_HISTORY_PAGE = 'INIT_LIKE_HISTORY_PAGE',
    SET_LIKE_HISTORY_ONLINE_STATUS = 'SET_LIKE_HISTORY_ONLINE_STATUS',
    ADD_LIKE_HISTORY_LIST = 'ADD_LIKE_HISTORY_LIST',
    REMOVE_FROM_LIKE_LIST = 'REMOVE_FROM_LIKE_LIST',
    SET_FILTER = 'SET_FILTER',
    INIT_LIKES = 'INIT_LIKES',
    GET_LIKE_LIST = 'GET_LIKE_LIST',
    SET_LIKE_LIST_PAGE = 'SET_LIKE_LIST_PAGE',
}
