import React from 'react';
import GirlItem from '../LikeItem';
import useHandleLikeHistory from '../../../hooks/likeher/useHandleLikeHistory';
import * as SEX from '../../../constants/sex';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';

export default function LikeHistory() {
    const userState = useSelector((state: RootState) => state.user);

    const { filterByDateLikeHistory, handleScroll } = useHandleLikeHistory(true);

    return (
        <>
            <div className="c2lh_girls_you_liked_title">
                {
                    SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
                        .LIKEHER_YOU_LIKED_TITLE
                }
            </div>
            <div className="c2lh_girls_you_liked_wrap2" onScroll={handleScroll}>
                {!!filterByDateLikeHistory('today')?.length && (
                    <div className="c2lh_girls_you_liked_day_title">Today</div>
                )}

                {filterByDateLikeHistory('today')?.map((item, key) => (
                    <GirlItem key={`${key}_${item?.external_id}`} womanInfo={item} />
                ))}

                {!!filterByDateLikeHistory('yesterday')?.length && (
                    <div className="c2lh_girls_you_liked_day_title">Yesterday</div>
                )}

                {filterByDateLikeHistory('yesterday')?.map((item, key) => (
                    <GirlItem key={`${key}_${item?.external_id}`} womanInfo={item} />
                ))}

                {!!filterByDateLikeHistory()?.length && (
                    <div className="c2lh_girls_you_liked_day_title">Earlier</div>
                )}

                {filterByDateLikeHistory()?.map((item, key) => (
                    <GirlItem key={`${key}_${item?.external_id}`} womanInfo={item} />
                ))}
            </div>
        </>
    );
}
