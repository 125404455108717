import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styles from './styles.module.scss';
import SITE from '../../../../../config/site';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../index';

export default function StripeCheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const userState = useSelector((state: RootState) => state.user);

    const [message, setMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) return;

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                payment_method_data: {
                    allow_redisplay: 'always',
                    billing_details: {
                        name: userState?.info?.name,
                        email: userState?.info?.email,
                        phone: null,
                        address: {
                            city: null,
                            country: null,
                            line1: null,
                            line2: null,
                            postal_code: null,
                            state: null,
                        },
                    },
                },
                return_url:
                    window?.location?.host?.includes('stage') ||
                    window?.location?.host?.includes('localhost')
                        ? `https://stage.${SITE.key}.com`
                        : `https://${SITE.ID === 1 ? 'www.' : ''}${SITE.key}.com`,
            },
        });

        if (error.type === 'card_error' || error.type === 'validation_error') {
            if (error?.message) setMessage(error.message);
        } else {
            setMessage('An unexpected error occurred.');
        }

        setIsLoading(false);
    };

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit} className={styles.stripeForm}>
                <PaymentElement
                    id="payment-element"
                    options={{
                        layout: 'accordion',
                        fields: {
                            billingDetails: 'never',
                        },
                    }}
                />
                <button disabled={isLoading || !stripe || !elements} id="submit">
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
                    </span>
                </button>
                {message && <div id="payment-message">{message}</div>}
            </form>
        </>
    );
}
