import React from 'react';
import { useDispatch } from 'react-redux';
import popup from '../../../../Profile/ProfilePurchaseHistory/popup.module.scss';
import * as purchaseAction from '../../../../../store/actions/purchase';
import { ISuccessChangeSubscribeProps } from './index.interface';
import IconCross from '../../../../Icons/IconCross';

export default function AutoTopUpSuccessChange({
    closeModal,
    activeSubscribeData,
    prevSubscriptionCreditPack,
}: ISuccessChangeSubscribeProps) {
    const dispatch = useDispatch();

    const onCloseModal = () => {
        closeModal();
        dispatch(purchaseAction.setIsChangeSubscription(false));
    };

    return (
        <div className={popup.top_up_modal_wrap}>
            <div className={popup.modal_title}>Thank you!</div>
            <div className={popup.modal_text}>
                <p>
                    You have just changed the amount of credits to automatically top up your account
                    on the site from {prevSubscriptionCreditPack} to {activeSubscribeData?.credits}{' '}
                    credits!
                </p>
            </div>
            <div className={popup.modal_buttons}>
                <div className={`${popup.modal_btn} ${popup.color_btn}`} onClick={onCloseModal}>
                    Close
                </div>
            </div>
            <div className={popup.close_modal} onClick={onCloseModal}>
                <IconCross />
            </div>
        </div>
    );
}
