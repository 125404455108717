import Base from './Base';
import * as FILTER from '../../constants/filter';
import { IGetAnotherProfileInfoData, IGetOperatorRes } from './ProfileApi.interface';

export default class ProfileApi extends Base {
    async getProfileInfo(token?: string) {
        const res = await super.get(`user/myProfile`, token ? { cancelToken: token } : {});
        if (!res || !res?.data) {
            return {};
        }
        if (window?.posthog && !window.posthog?.__loaded) {
            window.posthog.init('phc_m5TIYPDyVFWQMkrOP527y2C8JjQw7gglkc5AGxS7FTZ', {
                api_host: 'https://posthog.cdndate.net',
                session_recording: {
                    recordCrossOriginIframes: true,
                },
            });
            window.posthog.identify(res?.data?.user_info?.user_detail?.external_id, {
                name: res?.data?.user_info?.user_detail?.name,
            });
        }
        if (!FILTER.FILTER_TYPE || FILTER.FILTER_TYPE.length === 0) {
            return res?.data?.user_info;
        }
        const resArrayUserInfo: any = [];
        const resArrayRefedencies: any = [];
        for (const key in res?.data?.user_info) {
            if (key !== FILTER.USER_INFO_FILTER_TYPE) {
                resArrayUserInfo[key] = res?.data?.user_info[key];
            }
        }
        for (const key_1 in res?.data?.user_info?.referencies_list) {
            if (key_1 !== FILTER.FILTER_TYPE) {
                resArrayRefedencies[key_1] = res?.data?.user_info?.referencies_list[key_1];
            }
        }
        const filterArray = res?.data?.user_info?.referencies_list[FILTER.FILTER_TYPE]?.filter(
            function (e: any) {
                // @ts-expect-error indexOf error
                return this.indexOf(e[FILTER.FILTER_NAME_FIELDS]) < 0;
            },
            FILTER.ARR_FILTER_FIELDS,
        );
        return {
            ...resArrayUserInfo,
            ...{
                referencies_list: {
                    ...resArrayRefedencies,
                    ...{ [FILTER.FILTER_TYPE]: filterArray },
                },
            },
        };
    }

    async editProfileNew(data: any) {
        const res = await super.post(`user/editProfileNew`, data);
        return res?.data;
    }

    async getReferencies() {
        try {
            const res = await super.post(`like/getReferencies`);
            return res?.data;
        } catch {
            return { canceled: true };
        }
    }

    async updateUserAvatar(photo_link: string) {
        const res = await super.post(`user/updateUserAvatar`, { photo_link });
        return res?.data?.result;
    }

    async deleteUserAvatar() {
        const res = await super.post(`user/deleteUserAvatar`);
        return res?.data;
    }

    async getOperatorContent(girl_id: number) {
        const res = await super.post(`search/content`, { girl_id });
        return res?.data?.result;
    }

    async getOperator(user_id: number): Promise<IGetOperatorRes> {
        const res = await super.get(`search/profile?user_id=${user_id}`);
        return res?.data;
    }

    async updatePassword(password: string) {
        const res = await super.post(`user/changePassword`, { password });
        return res?.data;
    }

    async getEmail() {
        const res = await super.post(`user/getEmail`, {});
        return res?.data;
    }

    async updateEmail(email: string) {
        const res = await super.post(`user/changeEmail`, { email });
        return res?.data;
    }

    async getCityByCountry(countryId: number, keyword = '') {
        const res = await super.get(`user/cityList?country_id=${countryId}&city_name=${keyword}`);
        return res?.data;
    }

    showNewVimeoThumb(video_id = 0, preview_link = null as string | null) {
        if (!+video_id || !preview_link?.length) return;
        return super
            .post(`search/showNewVimeoThumb`, { video_id, preview_link })
            .then((res) => res?.data);
    }

    //START For new My profile page with posts ==========================================
    async getMyProfileInfo() {
        try {
            const res = await super.get(`user/profile/my/info`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getPurchaseHistory() {
        try {
            const res = await super.get(`user/profile/my/purchaseHistory`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getProfileSubscriptions(external_id: number | null) {
        //If the values external_id is not passed, the current user's subscriptions will be returned
        const mySearch = external_id ? `?external_id=${external_id}` : '';

        try {
            const res = await super.get(`user/profile/subscriptions${mySearch}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getProfileSubscribers(external_id: number | null) {
        //If the values external_id is not passed, the current user's subscribers will be returned
        const mySearch = external_id ? `?external_id=${external_id}` : '';

        try {
            const res = await super.get(`user/profile/subscribers${mySearch}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    // For new another user, profile page with posts
    async getAnotherProfileInfo({ publicExternalId }: IGetAnotherProfileInfoData) {
        //We can also send external_id={external_id}
        try {
            const res = await super.get(`user/profile?public_external_id=${publicExternalId}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getBlockList(isOnline: boolean, searchItem: string, page?: number) {
        try {
            const res = await super.post(`chat/blockListByUserID`, {
                ONLINE_STATUS: isOnline,
                SEARCH: searchItem,
                page,
            });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    //END For new My profile page with posts ==========================================
}
