import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import {
    displayDaysUntilBirthdayOnProfilePage,
    getAvatarMiniature,
    stringToColor,
    substrName,
} from '../../services/methods';
import useTransition from '../../hooks/transition/useTransition';
import * as SEX from '../../constants/sex';
import MobileMenuFooter from './MobileMenuFooter';
import QuizNotify from '../common/Notify/QuizNotify';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import IconProfile from '../Icons/IconProfile';
import IconCoins from '../Icons/IconCoins';
import IconCopyright from '../Icons/IconCopyright';
import IconOnlineNow from '../Icons/IconOnlineNow';
import IconVideos from '../Icons/IconVideos';
import IconFAQ from '../Icons/IconFAQ';
import IconTutorial from '../Icons/IconTutorial';
import IconPolicy from '../Icons/IconPolicy';
import IconGift from '../Icons/IconGift';
import IconContact from '../Icons/IconContact';
import IconBlockList from '../Icons/IconBlockList';
import IconMeetRequest from '../Icons/IconMeetRequest';
import useHandlePayment from '../../hooks/purchase/useHandlePayment';
import { RootState } from '../../index';

export default function MobileMenu() {
    const userState = useSelector((state: RootState) => state.user);
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const transition = useTransition();
    const handleActiveBlocks = useHandleActiveBlocks();
    const handlePayment = useHandlePayment();

    const userAvatar = () => {
        if (userState?.info?.photo_link_w56_h56) {
            return (
                <img
                    src={userState?.info?.photo_link_w56_h56}
                    alt=""
                    className={styles.mob_menu_page_head_photo}
                />
            );
        } else if (userState?.info?.photo_link) {
            return (
                <img
                    src={getAvatarMiniature(userState?.info?.photo_link)}
                    alt=""
                    className={styles.mob_menu_page_head_photo}
                />
            );
        } else {
            return (
                <div className="user-info">
                    <div
                        className={`${styles.user_info_avatar} ${styles.mob_menu_page_head_photo}`}
                        style={{ backgroundColor: stringToColor(userState?.info?.name || '') }}
                    >
                        {substrName(userState?.info?.name)}
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="column-3 profile_man new_mob_menu">
            <div className={styles.mob_menu_page}>
                <div className={styles.mob_menu_page_head}>
                    <div onClick={() => transition.transitionWithOnlyHistoryPush('/my-profile')}>
                        {userAvatar()}
                    </div>
                    <div className={styles.mob_menu_page_head_right}>
                        <div className={styles.mob_menu_page_head_profile_info}>
                            <div className={styles.mob_menu_page_head_profile_info_name_wrap}>
                                <p className={styles.mob_menu_page_head_profile_info_name}>
                                    {userState.info?.name}
                                </p>
                                <p className={styles.mob_menu_page_head_profile_info_id}>
                                    {`ID ${userState.info?.external_id}`}
                                </p>
                            </div>
                            <p className={styles.mob_menu_page_head_profile_info_dob}>
                                {moment(
                                    userState.info?.b_year +
                                        '-' +
                                        (+(userState?.info?.b_month || 0) < 10
                                            ? '0' + userState.info?.b_month
                                            : userState.info?.b_month) +
                                        '-' +
                                        (+(userState.info?.b_day || 0) < 10
                                            ? '0' + userState.info?.b_day
                                            : userState.info?.b_day),
                                ).format('MMMM D, YYYY')}
                                <span>
                                    {displayDaysUntilBirthdayOnProfilePage(
                                        userState.info?.b_month || 0,
                                        userState.info?.b_day || 0,
                                    )}
                                </span>
                            </p>
                            <p className={styles.mob_menu_page_head_profile_info_place}>
                                <span>
                                    {referenciesState?.list?.country_list?.find(
                                        (e) => +e?.id === +(userState.info?.country || 0),
                                    )?.country_name ?? ''}
                                </span>
                            </p>
                        </div>
                        <div className={styles.mob_menu_page_head_buttons}>
                            <button
                                type="button"
                                className={styles.mob_menu_page_head_profile_btn}
                                onClick={() =>
                                    transition.transitionWithOnlyHistoryPush('/my-profile')
                                }
                            >
                                <IconProfile />
                                <span>My profile</span>
                            </button>

                            {handleActiveBlocks.menu?.isActiveCreditMenu && (
                                <>
                                    <button
                                        type="button"
                                        className={styles.mob_menu_page_head_add_credits_btn}
                                        onClick={handlePayment.onOpenPayment}
                                    >
                                        <IconCopyright />
                                        <span>Add credits</span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.mob_menu_page_list_wrap}>
                    <ul className={styles.mob_menu_page_list}>
                        {handleActiveBlocks.menu?.isActiveVideoMenu && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() =>
                                        transition.transitionWithOnlyHistoryPush('/videoshow')
                                    }
                                >
                                    <IconVideos />
                                    <span>
                                        {
                                            SEX.SEX_TEXT[
                                                (userState?.info
                                                    ?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                                    SEX.MALE_SEX
                                            ].VIDEOSHOW_SIDE_MENU_BTN
                                        }
                                    </span>
                                    <span className={styles.mob_menu_page_list_item_text}>
                                        View member’s videos that shows <br />
                                        their everyday life
                                    </span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks.menu?.isActiveOnlineNowMenu && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() =>
                                        transition.transitionWithOnlyHistoryPush('/online-now')
                                    }
                                >
                                    <IconOnlineNow />
                                    <span>Online now</span>
                                </button>
                            </li>
                        )}

                        {handleActiveBlocks.menu?.isActiveCreditMenu && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() =>
                                        transition.transitionWithOnlyHistoryPush(
                                            '/my-profile/purchase-history',
                                        )
                                    }
                                >
                                    <IconCoins />
                                    <span>Credits</span>
                                </button>
                            </li>
                        )}

                        {!handleActiveBlocks.isHideGift && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() =>
                                        transition.transitionWithOnlyHistoryPush(
                                            '/my-profile/gifts',
                                        )
                                    }
                                >
                                    <IconGift />
                                    <span>Sent gifts</span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks.isContactRequest && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() =>
                                        transition.transitionWithOnlyHistoryPush(
                                            '/my-profile/contact-request',
                                        )
                                    }
                                >
                                    <IconContact />
                                    <span>Contact requests</span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks.isMeetingRequest && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() =>
                                        transition.transitionWithOnlyHistoryPush(
                                            '/my-profile/meeting-request',
                                        )
                                    }
                                ></button>
                                <IconMeetRequest />
                                <span>Meeting requests</span>
                            </li>
                        )}
                        <li className={styles.mob_menu_page_list_item}>
                            <button
                                type="button"
                                className={styles.mob_menu_page_list_item_button}
                                onClick={() =>
                                    transition.transitionWithOnlyHistoryPush(
                                        '/my-profile/block_list',
                                    )
                                }
                            >
                                <IconBlockList />
                                <span>Block list</span>
                            </button>
                        </li>
                        {handleActiveBlocks.menu?.isActiveFAQMenu && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() => transition.transitionWithOnlyHistoryPush('/faq')}
                                >
                                    <IconFAQ />
                                    <span>FAQ</span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks.menu?.isActiveTutorialMenu && (
                            <li className={styles.mob_menu_page_list_item}>
                                <button
                                    type="button"
                                    className={styles.mob_menu_page_list_item_button}
                                    onClick={() =>
                                        transition.transitionWithOnlyHistoryPush('/tutorial')
                                    }
                                >
                                    <IconTutorial />
                                    <span>Tutorial</span>
                                </button>
                            </li>
                        )}
                        <li
                            className={`${styles.mob_menu_page_list_item} ${styles.mob_menu_page_list_item_term}`}
                        >
                            <button
                                type="button"
                                className={styles.mob_menu_page_list_item_button}
                                onClick={() => transition.transitionWithOnlyHistoryPush('/policy')}
                            >
                                <IconPolicy />
                                <span>Policy</span>
                            </button>
                        </li>
                    </ul>
                    <MobileMenuFooter />
                </div>
                <QuizNotify />
            </div>
        </div>
    );
}
