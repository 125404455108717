export const LOOKING_FROM = 'From';
export const LOOKING_TO = 'To';
export const LOOKING_CHOOSE = 'Choose option';
export const LOOKING_SELECT_AGE = 'Age range';
export const LOOKING_SELECT_HEIGHT = 'Height range';
export const LOOKING_SELECT_BODY = 'Body Type';
export const LOOKING_SELECT_EYE = 'Eye color';
export const LOOKING_SELECT_RELIGION = 'Religion';
export const LOOKING_SELECT_NUM_CHILD = 'Number of children';
export const LOOKING_SELECT_MORE_CHILD = 'Want to have more children';
export const LOOKING_SELECT_ALCOHOL = 'Alcohol';
export const LOOKING_SELECT_SMOKING = 'Smoking';
export const LOOKING_SELECT_EDUCATION = 'Education';
export const LOOKING_SELECT_JOB = 'Job or profession';
export const LOOKING_AGE_START = 18;
export const LOOKING_AGE_END = 70;
export const PROFILE_INFO_CHOOSE = 'Choose option';
export const PROFILE_INFO_SELECT_HAIR = 'Hair color';
export const PROFILE_INFO_SELECT_LOOKING_FOR = 'I`m looking for';
export const PROFILE_INFO_SELECT_MARITAL = 'Marital status';
export const PROFILE_INFO_PURSOSE = 'Purpose of staying on the site';
