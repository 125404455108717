import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import VerifyStatus from './VerifyStatus';
import { IVerifyStatusProps } from './VerifyStatus/index.interface';

export default function VerifyStatusModal({
    initVerifyStatus,
}: {
    initVerifyStatus: IVerifyStatusProps;
}) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={initVerifyStatus.isActiveVerifyStatusModal}
            ariaHideApp={false}
        >
            <VerifyStatus {...initVerifyStatus} />
        </Modal>
    );
}
