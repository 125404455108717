import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as currentChatAction from '../../store/actions/currentChat';
import * as notifyAction from '../../store/actions/notify';
import * as chatsAction from '../../store/actions/chats';
import { RootState } from '../../index';

export default function useInitNoConnectionChatLoader() {
    const connectionState = useSelector((state: RootState) => state.connection);
    const currentChatState = useSelector((state: RootState) => state.currentChat);
    const dispatch = useDispatch();

    const [isNoConnection, setIsNoConnection] = useState(false);

    useEffect(() => {
        if (connectionState.status !== null) setIsNoConnection(!connectionState.status);
        if (connectionState.status && isNoConnection) {
            if (
                window.location?.pathname?.split('/')?.slice(-1)?.join() ===
                currentChatState?.info?.chat_uid
            ) {
                dispatch(
                    currentChatAction.addMainChatHistoryWithReload(
                        currentChatState?.info?.chat_uid,
                        1,
                    ),
                );

                setTimeout(() => {
                    if (currentChatState?.info?.chat_uid) {
                        dispatch(notifyAction.readNotify(currentChatState?.info?.chat_uid));
                        dispatch(chatsAction.removeUnreadCount(currentChatState?.info?.chat_uid));
                    }
                }, 0);
            }
        }
    }, [connectionState.status]);

    return {
        isNoConnection,
        title: '“No internet connection”',
    };
}
