import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as menuAction from '../../store/actions/menu';
import * as MENU from '../../constants/menu';
import * as modalsAction from '../../store/actions/modals';
import * as SEX from '../../constants/sex';
import SITE from '../../config/site';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';

export default function useTransition() {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentLocation = useLocation();
    const menuState = useSelector((state: RootState) => state.menu);
    const userState = useSelector((state: RootState) => state.user);

    const transition = (transitionName: string) => {
        dispatch(menuAction.setMainMenu(transitionName));
    };

    const transitionWithOnlyHistoryPush = (transitionUrl: string) => {
        dispatch(modalsAction.setActiveSubMenu());

        if (SITE.ID === 23 && transitionUrl !== window.location?.pathname) {
            localStorage.setItem('from-page', window.location?.pathname);
        } else if (
            transitionUrl?.includes('profile-woman') ||
            (transitionUrl?.includes('gifts') && menuState?.main === MENU.MENU_CHAT) ||
            (transitionUrl?.includes('gifts') && menuState?.main === MENU.MENU_LETTER)
        ) {
            localStorage.setItem('from-page', window.location?.pathname);
        }

        if (transitionUrl === '/like') {
            // @ts-expect-error userState?.info?.sex_text
            history.replace(`/${SEX.SEX_TEXT[userState?.info?.sex_text].LIKE_URL}`);
        } else {
            history.push(transitionUrl);
        }

        if (localStorage.getItem('update')) {
            localStorage.removeItem('update');

            try {
                document.location.reload();
            } catch (error) {
                sendErrToSentry(error as Error);
            }
        }
    };

    const transitionWithHistoryPush = (transitionName: string, transitionUrl: string) => {
        transitionWithOnlyHistoryPush(transitionUrl);
    };

    const addTail = (tail: string, userTail = false) => {
        const secondPartTail =
            userState?.tail?.substring(0, 1) === '?' ? userState?.tail?.slice(1) : userState?.tail;

        if (userTail && secondPartTail?.length > 0) {
            history.push(`${currentLocation?.pathname}${tail ?? ''}&${secondPartTail}`);
        } else {
            history.push(`${currentLocation?.pathname}${tail ?? ''}`);
        }
    };

    return {
        transition,
        transitionWithHistoryPush,
        transitionWithOnlyHistoryPush,
        addTail,
    };
}
