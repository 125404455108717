import React from 'react';
import styles from './styles.module.scss';
import SITE from '../../../../../config/site';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../index';
import { IRegisteredUserProps } from './index.interface';

export default function RegisteredUser({
    userPassword,
    userName,
    setUserName,
    saveUserInfo,
}: IRegisteredUserProps) {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <div className={styles.lnd_reg_popup} data-testid="success-mini-reg-modal">
            <div className={styles.lnd_reg_popup_title} data-testid="title">
                You’ve registered at {SITE?.copyrightsText}
            </div>
            <div className={styles.lnd_reg_popup_body}>
                <div className={styles.lnd_reg_popup_item}>
                    <div className={styles.title}>Your name</div>
                    <input
                        type="text"
                        data-testid="name"
                        value={userName || ''}
                        onChange={(e) => setUserName(e?.target?.value)}
                        className={`${styles.text} ${styles.text_input}`}
                    />
                    <div className={styles.lnd_reg_popup_copy}>You can change it here</div>
                </div>
                <div className={styles.lnd_reg_popup_item} data-testid="email">
                    <div className={styles.title}>Your login</div>
                    <div className={styles.text}>{userState?.info?.email || ''}</div>
                </div>
                <div className={styles.lnd_reg_popup_item} data-testid="password">
                    <div className={styles.title}>Your password</div>
                    <div className={styles.text}>{userPassword || ''}</div>
                </div>
                <div
                    className={styles.lnd_reg_popup_ok_btn}
                    onClick={saveUserInfo}
                    data-testid="ok-btn"
                >
                    Ok
                </div>
                <div className={styles.lnd_reg_popup_copy}>
                    Copy of this information will be sent to your email
                </div>
            </div>
        </div>
    );
}
