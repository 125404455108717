import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import ChatsAPI from '../../../services/api/ChatsApi';
import { switchChatList } from '../../../services/methods';
import * as alertAction from '../../../store/actions/alert';
import * as ERROR from '../../../constants/error';
import * as ALERT from '../../../constants/alert';
import * as currentChatAction from '../../../store/actions/currentChat';
import * as chatsAction from '../../../store/actions/chats';
import * as notifyAction from '../../../store/actions/notify';
import * as userAction from '../../../store/actions/user';
import * as PRICES from '../../../constants/prices';
import { RED_USER_LOG_TYPES } from '../../../constants/user';
import useHandleRedUser from '../../user/useHandleRedUser';
import { RootState } from '../../../index';

export default function useSendSticker() {
    const dispatch = useDispatch();
    const handleRedUser = useHandleRedUser();

    const userState = useSelector((state: RootState) => state.user);
    const chatsState = useSelector((state: RootState) => state.chats);
    const menuState = useSelector((state: RootState) => state.menu);

    return (selectedSticker: string, womanExternalId: number, chatUid: string) => {
        if (!userState?.info?.external_id) return;

        const hashId = moment().unix();

        new ChatsAPI()
            .postMessage(
                userState?.info?.external_id,
                womanExternalId,
                selectedSticker,
                PRICES.SENT_STICKER,
            )
            .then((res) => {
                if (res?.status) {
                    if (!res?.response?.message_object) {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                                title: res?.title,
                            }),
                        );

                        dispatch(currentChatAction.deleteMessageInHistory(hashId));
                        return;
                    }

                    dispatch(
                        currentChatAction.updateMessageInHistory(
                            hashId,
                            res?.response?.message_object,
                        ),
                    );
                    if (userState?.info?.external_id) {
                        dispatch(
                            chatsAction.getChatListByUserId(
                                userState?.info?.external_id,
                                chatsState.onlineStatus,
                                chatsState.searchInput,
                                switchChatList(menuState.sub),
                            ),
                        );
                        dispatch(notifyAction.deleteNotify(userState?.info?.external_id, chatUid));
                    }
                    dispatch(userAction.setBalance());
                    handleRedUser.submitLog(
                        RED_USER_LOG_TYPES.MESSAGE_FROM,
                        JSON.stringify({
                            womanExternalID: womanExternalId,
                            action: 'SENT_STICKER',
                            content: selectedSticker,
                        }),
                    );
                } else if (res?.block) {
                    dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                            isPaymentBtn: res?.activePayment,
                        }),
                    );
                    dispatch(currentChatAction.deleteMessageInHistory(hashId));
                }
            });
    };
}
