import visa from '../img/payments/visa.png';
import mastercard from '../img/payments/mastercard.svg';
import maestro from '../img/payments/maestro.svg';
import lpb from '..//img/payments/lpb.svg';
import applepay from '../img/payments/applepay.svg';
import discover_svg from '../img/payments/discover.svg';
import cardpay_logo from '../img/payments/cardpay_logo.png';
import trust from '../img/payments/trust_logo.jpg';
import securionpay from '../img/payments/securionpay.png';

export const PAYMENT_METHOD_PASTABANK = 'PASTABANK';
export const PAYMENT_METHOD_PASTABANK_RECURRING = 'PASTABANK_RECURRING';
export const PAYMENT_METHOD_CARDPAY = 'CARDPAY';
export const PAYMENT_METHOD_CARDPAY_RECURRING = 'PAYMENT_METHOD_CARDPAY_RECURRING';
export const PAYMENT_METHOD_CARDPAY_RECURRING_ONE_CLICK = 'CARDPAY_RECURRING_ONE_CLICK';
export const PAYMENT_METHOD_CARDPAY_GOOGLE_PAY = 'CARDPAY_GOOGLE_PAY';
export const PAYMENT_METHOD_APPLEPAY = 'APPLEPAY';
export const PAYMENT_METHOD_APPLEPAY_PASTABANK = 'PASTABANK_APPLEPAY';
export const PAYMENT_METHOD_ACQUIRING = 'ACQUIRING';
export const PAYMENT_METHOD_ACQUIRING_APPLE_PAY = 'ACQUIRING_APPLE_PAY';
export const PAYMENT_METHOD_ACQUIRING_GOOGLE_PAY = 'ACQUIRING_GOOGLE_PAY';
export const PAYMENT_METHOD_SECURIONPAY = 'SECURIONPAY';
export const PAYMENT_METHOD_UNIVERSEPAY = 'UNIVERSEPAY';
export const PAYMENT_METHOD_UNIVERSEPAY_RECURRING = 'UNIVERSEPAY_RECURRING';
export const PAYMENT_METHOD_ROUTING = 'ROUTING';
export const PAYMENT_METHOD_PASTABANK_GOOGLE_PAY = 'PASTABANK_GOOGLE_PAY';
export const PAYMENT_METHOD_STRIPE = 'STRIPE';

// export const DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY = '400';
export const DEFAULT_PACKAGE_ADD_CREDIT_CARD_PAY = '100';

const RECOMMENDATION_BALANCE =
    'Please, double-check your balance. It must match the cost of the credit package. If the balance is insufficient, choose a smaller package or use another card';
const RECOMMENDATION_CVV =
    'Please, double-check the CVV/CVC code on your card. CVC/CVV - is a three-digit code to verify the authenticity of your card for online payment and other types of operations. It is located on the back of the card, right here (see screenshot https://prnt.sc/233ptwr)';
const RECOMMENDATION_CARD_NUMBER =
    'Please, double-check the correct entry of the card number. The amount of digits should be 16. It is located on the front side of your card (see the screenshot https://prnt.sc/233q955)';
const RECOMMENDATION_CARD_DATA =
    'Please, double-check the correct entry of expiry date. It is located on the front side of your card (month and year), see the screenshot https://prnt.sc/233qkrc.';

export const PAYMENT_METHODS_CONFIG = {
    [PAYMENT_METHOD_PASTABANK]: {
        title: 'LPB',
        logo: lpb,
        cardIcons: [mastercard, visa],
        catchableErrors: [
            'not sufficient funds',
            'invalid card number',
            'card enrollment verification in 3d secure',
        ],
        recommendations: [
            RECOMMENDATION_BALANCE,
            RECOMMENDATION_CARD_NUMBER,
            RECOMMENDATION_CARD_NUMBER,
        ],
    },
    [PAYMENT_METHOD_CARDPAY]: {
        title: 'Unlimint',
        logo: cardpay_logo,
        cardIcons: [discover_svg, mastercard, visa],
        catchableErrors: ['Insufficient funds', 'Incorrect card data'],
        recommendations: [RECOMMENDATION_BALANCE, RECOMMENDATION_CARD_DATA],
    },
    [PAYMENT_METHOD_APPLEPAY]: {
        title: 'ApplePay',
        logo: applepay,
        cardIcons: [mastercard, visa],
        catchableErrors: ['Insufficient funds', 'Incorrect card data'],
        recommendations: [RECOMMENDATION_BALANCE, RECOMMENDATION_CARD_DATA],
    },
    [PAYMENT_METHOD_APPLEPAY_PASTABANK]: {
        title: 'ApplePay',
        logo: applepay,
        cardIcons: [mastercard, visa],
        catchableErrors: ['Insufficient funds', 'Incorrect card data'],
        recommendations: [RECOMMENDATION_BALANCE, RECOMMENDATION_CARD_DATA],
    },
    [PAYMENT_METHOD_ACQUIRING]: {
        title: 'Trust Payments',
        logo: trust,
        cardIcons: [maestro, mastercard, visa],
        catchableErrors: ['Not sufficient funds', 'Decline CVV2 failure', 'Invalid card number'],
        recommendations: [RECOMMENDATION_BALANCE, RECOMMENDATION_CVV, RECOMMENDATION_CARD_NUMBER],
    },
    [PAYMENT_METHOD_SECURIONPAY]: {
        title: 'SecurionPay',
        logo: securionpay,
        cardIcons: [mastercard, visa],
        catchableErrors: [
            'Insufficient Funds',
            "The card's security code failed verification.",
            "The card's security code failed verification.",
            "Your card's security code is invalid.",
            'Invalid Card Number',
            'The card number is not a valid credit card number.',
            "The card's expiration year is invalid.",
        ],
        recommendations: [
            RECOMMENDATION_BALANCE,
            RECOMMENDATION_CVV,
            RECOMMENDATION_CVV,
            RECOMMENDATION_CVV,
            RECOMMENDATION_CARD_NUMBER,
            RECOMMENDATION_CARD_NUMBER,
            RECOMMENDATION_CARD_DATA,
        ],
    },
};
