import React from 'react';

export default function IconShieldEmail() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5512 3.36678C12.1909 3.26733 11.809 3.26733 11.4487 3.36678L5.74335 4.94154C4.98171 5.15176 4.44836 5.75146 4.39549 6.4511C4.22076 8.76365 4.40778 11.69 5.43295 14.3455C6.45312 16.9881 8.29028 19.3374 11.4206 20.5963C11.7905 20.745 12.2095 20.745 12.5794 20.5963C15.7097 19.3374 17.5468 16.9881 18.567 14.3455C19.5922 11.69 19.7792 8.76365 19.6045 6.4511C19.5517 5.75147 19.0183 5.15177 18.2566 4.94154L12.5512 3.36678ZM11.0811 2.12432C11.6816 1.95856 12.3182 1.95856 12.9188 2.12432L18.6241 3.69907C19.8607 4.04039 20.8403 5.05448 20.9387 6.35706C21.1226 8.79217 20.9323 11.9168 19.8204 14.797C18.7034 17.6903 16.6437 20.3614 13.0935 21.7892C12.3945 22.0703 11.6054 22.0703 10.9064 21.7892C7.35628 20.3614 5.2965 17.6903 4.17957 14.797C3.06763 11.9168 2.87734 8.79217 3.06134 6.35705C3.15976 5.05449 4.13919 4.0404 5.37582 3.69907L11.0811 2.12432ZM7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12V12.715C17 13.7 16.235 14.5 15.25 14.5C14.655 14.5 14.095 14.21 13.77 13.765C13.32 14.22 12.69 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5C13.38 9.5 14.5 10.62 14.5 12V12.715C14.5 13.11 14.855 13.5 15.25 13.5C15.645 13.5 16 13.11 16 12.715V12C16 9.83 14.17 8 12 8C9.83 8 8 9.83 8 12C8 14.17 9.83 16 12 16H14.5V17H12C9.24 17 7 14.76 7 12ZM10.5 12C10.5 12.83 11.17 13.5 12 13.5C12.83 13.5 13.5 12.83 13.5 12C13.5 11.17 12.83 10.5 12 10.5C11.17 10.5 10.5 11.17 10.5 12Z"
            />
        </svg>
    );
}
