import React, { RefObject } from 'react';
import { useSelector } from 'react-redux';
import global from '../global.module.scss';
import styles from './styles.module.scss';
import * as REGISTER from '../../../../../constants/register';
import {
    getArrayOfNumbers,
    getAvatarMiniature,
    getDaysAmount,
} from '../../../../../services/methods';
import AutocompleteSelect from './AutocompleteSelect';
import useInitSelectBlocks from '../../../../../hooks/profile/my-profile-v2/useInitSelectBlocks';
import ManagePasswordModal from '../../../../common/Modals/ManagePassword';
import useModalManagePassword from '../../../../../hooks/dom/useModalManagePassword';
import * as USER from '../../../../../constants/user';
import useLogout from '../../../../../hooks/logout/useLogout';
import LoyaltyProgramLightModal from '../../../../common/LoyaltyProgram/LoyaltyProgramLightModal';
import SITE from '../../../../../config/site';
import moment from 'moment';
import * as LENGTH from '../../../../../constants/length';
import useHandlePayment from '../../../../../hooks/purchase/useHandlePayment';
import IconLetters from '../../../../Icons/IconLetters';
import { IMainInfoProps } from './index.interface';
import { RootState } from '../../../../../index';
import IconAvatarVip from '../../../../Icons/IconAvatarVip';
import IconProfileChat from '../../../../Icons/IconProfileChat';
import IconDeletePhoto from '../../../../Icons/IconDeletePhoto';
import IconCrownVip from '../../../../Icons/IconCrownVip';
import IconArrowDropdown from '../../../../Icons/IconArrowDropdown';
import IconChangeEmailAt from '../../../../Icons/IconChangeEmailAt';
import IconLogout from '../../../../Icons/IconLogout';
import IconChangePasswordLock from '../../../../Icons/IconChangePasswordLock';
import IconCopyright from '../../../../Icons/IconCopyright';
import IconAboutBook from '../../../../Icons/IconAboutBook';
import VerifiedProfile from '../../../../common/VerifiedProfile';
import { VerifiedStatus } from '../../../../../store/constants/user';

export default function MainInfo({ handleMainInfo }: IMainInfoProps) {
    const userState = useSelector((state: RootState) => state.user);
    const logout = useLogout();
    const modalManagePassword = useModalManagePassword();
    const initSelectBlocks = useInitSelectBlocks();
    const handlePayment = useHandlePayment();
    const currentDay = +moment().format('D');
    const currentMonth = +moment().format('MM');
    const { mainInfo } = handleMainInfo;

    const checkCurrentDay = (value: number) => {
        return !!(currentDay === +value && currentMonth === mainInfo?.month);
    };

    const checkCurrentMonth = (value: number) => {
        return !!(currentMonth === ++value && currentDay === mainInfo?.day);
    };

    return (
        <>
            <div className={`${styles.profile_main} ${global.profile_div}`}>
                <div
                    className={`${styles.profile_main_top} ${global.profile_main_top}`}
                    data-testid="main-info-block"
                >
                    <div className={`${styles.profile_main_left}`} data-testid="avatar-img">
                        {!!userState?.info?.is_verified && !mainInfo?.photo_link?.length ? (
                            <div className={`${styles.profile_main_photo} ${styles.user_vip}`}>
                                <IconAvatarVip />
                            </div>
                        ) : mainInfo?.photo_link?.length ? (
                            <img
                                className={`${styles.profile_main_photo}`}
                                src={getAvatarMiniature(mainInfo?.photo_link)}
                                alt="user avatar"
                            />
                        ) : (
                            <div className={`${styles.profile_main_photo}`}>
                                <IconProfileChat />
                            </div>
                        )}
                        {!!mainInfo?.photo_link?.length && (
                            <div
                                className={`${styles.profile_main_delete_photo}`}
                                onClick={handleMainInfo.openModalDeleteUserAvatar}
                                data-testid="delete-avatar-btn"
                            >
                                <IconDeletePhoto />
                                <span>Delete</span>
                            </div>
                        )}
                        {!!(userState?.info?.is_verified && mainInfo?.photo_link?.length) && (
                            <div className={styles.profile_main_crown_vip}>
                                <IconCrownVip />
                            </div>
                        )}
                        <div
                            className={`${styles.profile_main_new_avatar}`}
                            onClick={handleMainInfo.showInputFile}
                        >
                            <input
                                type="file"
                                className={styles.hidden_upload}
                                ref={handleMainInfo.inputUploadPhoto as RefObject<HTMLInputElement>}
                                onChange={handleMainInfo.uploadUserAvatar}
                                onClick={(e) => {
                                    const target = e?.target as HTMLInputElement;
                                    target.value = '';
                                }}
                                data-testid="upload-avatar"
                            />
                            <div className={`${styles.profile_main_new_avatar_icon}`}></div>
                            <span>New avatar</span>
                        </div>
                    </div>
                    <div className={`${styles.profile_main_middle}`}>
                        <div className={`${styles.profile_main_info_row} ${styles.name_row}`}>
                            <div className={`${styles.profile_main_name_input_wrap}`}>
                                <label
                                    htmlFor="profile_name_input"
                                    className={global.profile_label}
                                >
                                    Name
                                </label>
                                <input
                                    id="profile_name_input"
                                    type="text"
                                    value={mainInfo?.name || ''}
                                    maxLength={LENGTH.MAX_NAME}
                                    onChange={(e) =>
                                        handleMainInfo.onChangeInputValue({
                                            value: e?.target?.value,
                                            field: 'name',
                                            section: 'user_detail',
                                        })
                                    }
                                    className={`${global.profile_input} ${global.text_input} ${!mainInfo?.name?.length ? global.error : ''}`}
                                    data-testid="user-name"
                                />
                            </div>
                            <span className={`${styles.profile_main_name_click_edit}`}>
                                Click to edit
                            </span>
                        </div>
                        <div className={`${styles.profile_main_info_row} ${styles.dob_row}`}>
                            <span className={global.profile_label}>Date of birth</span>
                            <div className={`${styles.profile_main_info_dob_wrap_row}`}>
                                <div
                                    className={`${styles.profile_main_info_dob_wrap} ${global.dob_wrap}`}
                                >
                                    <div
                                        ref={initSelectBlocks.selectMonthRef}
                                        className={`${global.profile_select_wrap} ${global.selected} ${initSelectBlocks.openMonthBlock ? global.open : ''}`}
                                        data-testid="month"
                                    >
                                        <div
                                            className={`${global.profile_select_head}`}
                                            onClick={() =>
                                                initSelectBlocks.setOpenMonthBlock(
                                                    !initSelectBlocks.openMonthBlock,
                                                )
                                            }
                                        >
                                            <span>
                                                {REGISTER.ARRAY_MONTH[
                                                    +(mainInfo?.month || 0) - 1
                                                ] ?? ''}
                                            </span>
                                            <div className={`${global.profile_select_head_arrow}`}>
                                                <IconArrowDropdown />
                                            </div>
                                        </div>
                                        <div
                                            className={`${global.profile_select_list_wrap}`}
                                            data-testid="select-list"
                                        >
                                            <ul className={`${global.profile_select_list}`}>
                                                {REGISTER.ARRAY_MONTH.map((item, key) => (
                                                    <li
                                                        key={key}
                                                        className={`${global.profile_select_list_item} ${(mainInfo?.month || 0) - 1 === +key ? global.active : ''} ${checkCurrentMonth(key) ? global.disabled : ''}`}
                                                        onClick={() => {
                                                            handleMainInfo.handleChangeBirthday(
                                                                key + 1,
                                                                'month',
                                                            );
                                                            initSelectBlocks.setOpenMonthBlock(
                                                                false,
                                                            );
                                                        }}
                                                    >
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        ref={initSelectBlocks.selectDayRef}
                                        className={`${global.profile_select_wrap} ${initSelectBlocks.openDayBlock ? global.open : ''}`}
                                        data-testid="day"
                                    >
                                        <div
                                            className={`${global.profile_select_head}`}
                                            onClick={() =>
                                                initSelectBlocks.setOpenDayBlock(
                                                    !initSelectBlocks.openDayBlock,
                                                )
                                            }
                                        >
                                            <span>{mainInfo?.day ?? ''}</span>
                                            <div className={`${global.profile_select_head_arrow}`}>
                                                <IconArrowDropdown />
                                            </div>
                                        </div>
                                        <div className={`${global.profile_select_list_wrap}`}>
                                            <ul
                                                className={`${global.profile_select_list}`}
                                                data-testid="select-list"
                                            >
                                                {getDaysAmount(
                                                    mainInfo?.month || 0,
                                                    mainInfo?.year || 0,
                                                ).map((item, key) => (
                                                    <li
                                                        key={key}
                                                        className={`${global.profile_select_list_item} ${+(mainInfo?.day || 0) === +item ? global.active : ''} ${checkCurrentDay(item) ? global.disabled : ''}`}
                                                        onClick={() => {
                                                            handleMainInfo.handleChangeBirthday(
                                                                +item,
                                                                'day',
                                                            );
                                                            initSelectBlocks.setOpenDayBlock(false);
                                                        }}
                                                    >
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        ref={initSelectBlocks.selectYearRef}
                                        className={`${global.profile_select_wrap} ${initSelectBlocks.openYearBlock ? global.open : ''}`}
                                        data-testid="year"
                                    >
                                        <div
                                            className={`${global.profile_select_head}`}
                                            onClick={() =>
                                                initSelectBlocks.setOpenYearBlock(
                                                    !initSelectBlocks.openYearBlock,
                                                )
                                            }
                                        >
                                            <span>{mainInfo?.year ?? ''}</span>
                                            <div className={`${global.profile_select_head_arrow}`}>
                                                <IconArrowDropdown />
                                            </div>
                                        </div>
                                        <div className={`${global.profile_select_list_wrap}`}>
                                            <ul
                                                className={`${global.profile_select_list}`}
                                                data-testid="select-list"
                                            >
                                                {getArrayOfNumbers(
                                                    REGISTER.START_YEAR,
                                                    REGISTER.END_YEAR,
                                                ).map((item, key) => (
                                                    <li
                                                        key={key}
                                                        className={`${global.profile_select_list_item} ${+item === mainInfo?.year ? global.active : ''}`}
                                                        onClick={() => {
                                                            handleMainInfo.handleChangeBirthday(
                                                                item,
                                                                'year',
                                                            );
                                                            initSelectBlocks.setOpenYearBlock(
                                                                false,
                                                            );
                                                        }}
                                                    >
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <span className={`${global.profile_click_edit}`}>
                                    Click to edit
                                </span>
                            </div>
                        </div>
                        <div
                            className={`${styles.profile_main_info_row} ${styles.place_row}`}
                            data-testid="country-city"
                        >
                            <AutocompleteSelect
                                selectRef={initSelectBlocks.selectCountryRef}
                                index="country_name"
                                formData={mainInfo?.country_name ?? 'Not Specified'}
                                handleClick={handleMainInfo.handleChangeCountry}
                                arrayFields={mainInfo?.countryList}
                                defaultName={'Start type'}
                                selectTitle={'Country'}
                                onOpenBlock={() =>
                                    initSelectBlocks.setOpenCountryBlock(
                                        !initSelectBlocks.openCountryBlock,
                                    )
                                }
                                openBox={initSelectBlocks.openCountryBlock}
                                isClicked={initSelectBlocks.isClickedCountry}
                                setIsClicked={initSelectBlocks.setIsClickedCountry}
                                callback={() => {
                                    initSelectBlocks.setOpenCountryBlock(false);
                                    initSelectBlocks.setIsClickedCountry(false);
                                }}
                            />
                            <AutocompleteSelect
                                selectRef={initSelectBlocks.selectCityRef}
                                index="city_name"
                                formData={mainInfo?.city ?? 'Not Specified'}
                                handleClick={handleMainInfo.handleChangeCity}
                                handleLoad={handleMainInfo.handleLoadCityList}
                                arrayFields={mainInfo?.city_list}
                                defaultName={'Start type'}
                                selectTitle={'City'}
                                onOpenBlock={() =>
                                    initSelectBlocks.setOpenCityBlock(
                                        !initSelectBlocks.openCityBlock,
                                    )
                                }
                                openBox={initSelectBlocks.openCityBlock}
                                isClicked={initSelectBlocks.isClickedCity}
                                setIsClicked={initSelectBlocks.setIsClickedCity}
                                callback={() => {
                                    initSelectBlocks.setOpenCityBlock(false);
                                    initSelectBlocks.setIsClickedCity(false);
                                }}
                                data-testid="user-city"
                            />
                            <span className={`${global.profile_click_edit}`}>Click to edit</span>
                        </div>
                    </div>
                    <div className={`${styles.profile_main_right}`}>
                        {SITE.ID !== 4 && (
                            <div className={`${styles.profile_main_credits}`}>
                                <div className={`${styles.profile_main_credits_count}`}>
                                    <div
                                        className={`${styles.profile_main_credits_count_icon}`}
                                    ></div>
                                    <div className={styles.profile_main_credits_info}>
                                        <p className={styles.profile_main_credits_title}>
                                            Your balance
                                        </p>
                                        <p className={styles.count}>
                                            {userState?.balance}
                                            <span className={styles.credits}>credits</span>
                                        </p>
                                    </div>
                                </div>
                                <p
                                    className={`${styles.profile_main_credits_get_more} ${styles.pc}`}
                                    onClick={handlePayment.onOpenPayment}
                                >
                                    Get more credits
                                </p>
                                <div
                                    className={`${styles.profile_main_credits_get_more} ${styles.mob}`}
                                    onClick={handlePayment.onOpenPayment}
                                >
                                    <IconCopyright />
                                    <span>Add</span>
                                </div>
                            </div>
                        )}

                        <div
                            className={`${styles.profile_main_change_email_pass}`}
                            ref={initSelectBlocks.selectChangePasswordRef}
                        >
                            <div
                                className={`${global.profile_select_wrap} ${global.pass_mail} ${initSelectBlocks.openChangePassword ? global.open : ''}`}
                            >
                                <div
                                    className={`${global.profile_select_head}`}
                                    onClick={() =>
                                        initSelectBlocks.setOpenChangePasswordBlock(
                                            !initSelectBlocks.openChangePassword,
                                        )
                                    }
                                >
                                    <span>Change email/password & other</span>
                                    <div className={`${global.profile_select_head_arrow}`}>
                                        <IconArrowDropdown />
                                    </div>
                                </div>
                                <div className={`${global.profile_select_list_wrap}`}>
                                    <ul className={`${global.profile_select_list}`}>
                                        <li
                                            className={`${global.profile_select_list_item}`}
                                            onClick={handleMainInfo.openManageEmailModal}
                                        >
                                            <IconChangeEmailAt />
                                            Change email address
                                        </li>
                                        <li
                                            className={`${global.profile_select_list_item}`}
                                            onClick={modalManagePassword.openManagePasswordModal}
                                        >
                                            <IconChangePasswordLock />
                                            Change password
                                        </li>
                                        {!!userState?.loyaltyProgram?.status && (
                                            <li
                                                className={`${global.profile_select_list_item}`}
                                                onClick={handleMainInfo.openLoyaltyProgramModal}
                                            >
                                                <IconAboutBook />
                                                Loyalty program
                                            </li>
                                        )}
                                        <li
                                            className={`${global.profile_select_list_item}`}
                                            onClick={logout.handleClickLogout}
                                        >
                                            <IconLogout />
                                            Log out
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.profile_main_bottom}`}>
                    <div className={`${styles.profile_main_bottom_left}`}>
                        <div className={`${styles.profile_main_id}`}>
                            {mainInfo?.external_id ? `ID ${mainInfo?.external_id}` : ''}
                        </div>
                        <VerifiedProfile
                            status={
                                userState?.info?.is_passport === USER.PASSPORT_VERIFIED
                                    ? VerifiedStatus.APPROVED
                                    : (userState?.info?.verified ?? null)
                            }
                            isProfile
                        />
                    </div>
                    {!!mainInfo?.email && (
                        <div className={`${styles.profile_main_registered_email}`}>
                            Registered email: <span>{userState?.info?.email ?? ''}</span>
                        </div>
                    )}
                    {!handleMainInfo?.subscribedData?.subscribed &&
                        handleMainInfo?.subscribedData?.resubscribe && (
                            <button
                                type="button"
                                className={styles.profile_main_sign_up_btn}
                                onClick={handleMainInfo?.sendEmailSubscription}
                            >
                                <IconLetters />
                                Sign up for emails
                            </button>
                        )}
                </div>
            </div>
            <ManagePasswordModal {...modalManagePassword} />
            <LoyaltyProgramLightModal
                isActive={handleMainInfo.isLoyaltyProgramModal}
                onClose={handleMainInfo.closeLoyaltyProgramModal}
            />
        </>
    );
}
