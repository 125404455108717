import React from 'react';
import useSlickConfig from '../../../hooks/slickConfig/useSlickConfig';
import Slider from 'react-slick';
import { IMailAttachment } from '../../../services/api/MailboxApi.interface';

function SlickContainer({
    children,
    attachData = [],
}: {
    children: React.ReactNode;
    attachData?: IMailAttachment[] | never[];
}) {
    const { settings, slickContainerRef, sliderRef } = useSlickConfig({ attachData });

    return (
        <div className="carousel_container" id="slick_letter_attach" ref={slickContainerRef}>
            <Slider {...settings} ref={sliderRef}>
                {children}
            </Slider>
        </div>
    );
}

export default React.memo(SlickContainer);
