import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ResetPassword from './ResetPassword';
import { IResetPasswordModal } from './index.interface';

export default function ResetPasswordModal({
    activeForgetPassword,
    closeResetPassModal,
    formDataLogin,
    handleFormDataLoginEmail,
    onSubmitLogin,
}: IResetPasswordModal) {
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={activeForgetPassword}
            onRequestClose={closeResetPassModal}
            ariaHideApp={false}
        >
            <ResetPassword
                closeResetPassModal={closeResetPassModal}
                formDataLogin={formDataLogin}
                handleFormDataLoginEmail={handleFormDataLoginEmail}
                submitResetPassword={onSubmitLogin}
            />
        </Modal>
    );
}
