import React from 'react';

export default function IconVerificationProgress() {
    return (
        <svg
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="1"
                width="72"
                height="72"
                rx="36"
                stroke="#D4D4D4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6"
            />
            <path
                d="M37 1C42.6812 1 48.2817 2.34457 53.3437 4.92376C58.4056 7.50296 62.7853 11.2436 66.1246 15.8397C69.4639 20.4359 71.668 25.7571 72.5568 31.3684C73.4455 36.9796 72.9936 42.7215 71.238 48.1246C69.4825 53.5277 66.473 58.4386 62.4558 62.4558C58.4387 66.473 53.5277 69.4825 48.1246 71.238C42.7215 72.9936 36.9796 73.4455 31.3684 72.5568C25.7571 71.6681 20.4359 69.4639 15.8397 66.1246"
                stroke="#00317B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.7807 34.5064C42.3719 33.0557 44.5238 31.0936 44.7199 26.4062H46.1406V25H27.8594V26.4062H29.2801C29.4762 31.0936 31.6281 33.0557 33.2193 34.5064C34.2867 35.4796 34.8906 36.0752 34.8906 37C34.8906 37.9248 34.2867 38.5204 33.2193 39.4936C31.6281 40.9443 29.4762 42.9064 29.2801 47.5938H27.8594V49H46.1406V47.5938H44.7199C44.5238 42.9064 42.3719 40.9443 40.7807 39.4936C39.7133 38.5204 39.1094 37.9248 39.1094 37C39.1094 36.0752 39.7133 35.4796 40.7807 34.5064ZM36.2969 42.2705C36.0797 42.3153 35.8652 42.3805 35.6558 42.4672L31.349 44.2493C32.0523 42.461 33.1851 41.4278 34.1667 40.5328C35.2622 39.5341 36.2969 38.5907 36.2969 37V42.2705ZM39.8333 40.5328C40.8149 41.4278 41.9476 42.461 42.651 44.2493L38.3442 42.4671C38.1348 42.3805 37.9203 42.3153 37.7031 42.2705V37C37.7031 38.5907 38.7378 39.5341 39.8333 40.5328ZM31.3243 29.6875C30.9843 28.8016 30.7495 27.732 30.6881 26.4062H43.3119C43.2504 27.732 43.0157 28.8016 42.6756 29.6875H31.3243Z"
                fill="#00317B"
            />
        </svg>
    );
}
