import { ChangeEvent, useEffect, useState } from 'react';
import * as LENGTH from '../../constants/length';
import { useDispatch, useSelector } from 'react-redux';
import UserVerificationApi from '../../services/api/v3/UserVerificationApi';
import { updateDocsState, updateIsShowStatus } from '../../store/actions/profile';
import { RootState } from '../../index';
import * as modalsAction from '../../store/actions/modals';
import { DocsTypes } from '../../store/constants/profile';

export default function UseInitIdentityVerification(configID: number, banner: string) {
    const dispatch = useDispatch();
    const modalsState = useSelector((state: RootState) => state.modals);
    const docsState = useSelector((state: RootState) => state.profile.docsState);

    const [modalIsActiveIdentityVerification, setModalIsActiveIdentityVerification] =
        useState<boolean>(false);
    const [isWarning, setIsWarning] = useState<boolean>(false);
    const [isDataPassed, setIsDataPassed] = useState<boolean>(false);

    const deleteUploadedFileIdentityVerification = (fieldName: string) => {
        dispatch(updateDocsState({ ...docsState, [fieldName]: {} }));
    };

    const handleInputIdentityVerification = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event?.target;
        const { name } = event.target;

        if (
            (target?.files?.[0]?.size || 0) > LENGTH.MAX_FILE_SIZE ||
            (target?.files?.[0]?.size || 0) < LENGTH.MIN_FILE_SIZE
        ) {
            setIsWarning(true);
            dispatch(updateDocsState({ ...docsState, [name]: {} }));
        } else {
            setIsWarning(false);
            dispatch(updateDocsState({ ...docsState, [name]: target?.files?.[0] }));
        }
    };

    const updateApprovedDocs = async (data = []) => {
        const res = await new UserVerificationApi().getApprovedDocs();
        if (res?.status) {
            dispatch(
                updateDocsState({
                    ...docsState,
                    ...data,
                    approvedDocs: res.result,
                }),
            );
        } else {
            dispatch(
                updateDocsState({
                    ...docsState,
                    ...data,
                }),
            );
        }
    };

    const openIdentityVerificationModal = () => {
        if (banner === 'IN_PROGRESS_VERIFICATION') {
            setIsDataPassed(true);
        }
        updateApprovedDocs().then(() => setModalIsActiveIdentityVerification(true));
    };

    const closeIdentityVerificationModal = () => {
        if (modalsState.isOpenProfileIdentificationModal) {
            dispatch(modalsAction.closeProfileIdentificationModal());
        } else {
            setModalIsActiveIdentityVerification(false);
            dispatch(updateIsShowStatus(false));
            new UserVerificationApi().skipDocsVerification({ configID });
        }
    };

    const onSubmitIdentityVerification = () => {
        if (!docsState.userIdFile?.name && !docsState.userSelfieFile?.name) {
            return closeIdentityVerificationModal();
        }
        const formData = new FormData();
        if (docsState.userIdFile?.name) {
            formData.append('files', docsState.userIdFile as Blob);
            formData.append('types[]', DocsTypes.VALID_ID);
        }
        if (docsState.userSelfieFile?.name) {
            formData.append('files', docsState.userSelfieFile as Blob);
            formData.append('types[]', DocsTypes.SELFIE);
        }
        formData.append('configID', configID?.toString());
        new UserVerificationApi().sendDocsToVerify(formData);
        dispatch(updateDocsState({ ...docsState, userIdFile: {}, userSelfieFile: {} }));
        setIsDataPassed(true);
    };

    const onSubmitIdentityVerificationProfile = () => {
        if (!docsState.userOnlySelfieFile?.name) {
            return closeIdentityVerificationModal();
        }
        const formData = new FormData();
        formData.append('files', docsState.userOnlySelfieFile as Blob);
        formData.append('types', DocsTypes.SELFIE_VERIFY);
        new UserVerificationApi().sendDocsToVerifyProfile(formData);
        dispatch(updateDocsState({ ...docsState, userOnlySelfieFile: {} }));
        setIsDataPassed(true);
    };

    useEffect(() => {
        if (modalsState.isOpenProfileIdentificationModal) {
            updateApprovedDocs().then(() =>
                setModalIsActiveIdentityVerification(modalsState.isOpenProfileIdentificationModal),
            );
        } else {
            setModalIsActiveIdentityVerification(modalsState.isOpenProfileIdentificationModal);
        }
    }, [modalsState.isOpenProfileIdentificationModal]);
    return {
        modalIsActiveIdentityVerification,
        setModalIsActiveIdentityVerification,
        deleteUploadedFileIdentityVerification,
        handleInputIdentityVerification,
        closeIdentityVerificationModal,
        openIdentityVerificationModal,
        onSubmitIdentityVerification,
        onSubmitIdentityVerificationProfile,
        isWarning,
        isDataPassed,
        setIsDataPassed,
        updateApprovedDocs,
    };
}
