import { FAQActionTypes } from '../../constants/faq';
import { Dispatch } from 'redux';

export function getFaqData(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: FAQActionTypes.GET_FAQ_DATA,
            data,
        });
    };
}

export function setActiveTopicId(activeTopicId: number | null = null) {
    return function (dispatch: Dispatch<{ type: string; activeTopicId: number | null }>) {
        return dispatch({
            type: FAQActionTypes.SET_ACTIVE_TOPIC_ID,
            activeTopicId,
        });
    };
}

export function setActiveQuestionId(activeQuestionId: number | null = null) {
    return function (dispatch: Dispatch<{ type: string; activeQuestionId: number | null }>) {
        return dispatch({
            type: FAQActionTypes.SET_ACTIVE_QUESTION_ID,
            activeQuestionId,
        });
    };
}
