import React from 'react';
import styles from './styles.module.scss';
import ConfirmStatusPhoneModal from '../../ConfirmStatusPhoneModal';
import VerifyPhoneCodeModal from '../../VerifyPhoneCodeModal';
import ConfirmStatusEmailModal from '../../ConfirmStatusEmailModal';
import ConfirmStatusNameModal from '../../ConfirmStatusNameModal';
import useHandleActiveBlocks from '../../../../../hooks/user/useHandleActiveBlocks';
import { IVerifyStatusProps } from './index.interface';
import IconPhoneCircle from '../../../../Icons/IconPhoneCircle';
import IconFAQ from '../../../../Icons/IconFAQ';
import IconCross from '../../../../Icons/IconCross';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconShieldPhone from '../../../../Icons/IconShieldPhone';
import IconShieldEmail from '../../../../Icons/IconShieldEmail';
import IconPolicy from '../../../../Icons/IconPolicy';

export default function VerifyStatus({
    closeVerifyStatusModal,
    isActiveConfirmPhoneModal,
    openConfirmPhoneModal,
    closeConfirmPhoneModal,
    isVerifyPhoneCodeModal,
    closeVerifyPhoneCodeModal,
    isActiveConfirmEmailModal,
    openConfirmEmailModal,
    closeConfirmEmailModal,
    isActiveConfirmNameModal,
    openConfirmNameModal,
    closeConfirmNameModal,
    phoneData,
    changePhoneData,
    submitPhoneData,
    verifyCode,
    handleVerifyCode,
    handleKeyDownEnter,
    submitVerifyCode,
    isPhoneCodeCanceled,
    setIsPhoneCodeCanceled,
    userInfo,
    emailData,
    changeEmailData,
    submitEmailData,
    isEmailSent,
    nameData,
    changeNameData,
    submitNameData,
    switchPhoneNumber,
}: IVerifyStatusProps) {
    const handleActiveBlocks = useHandleActiveBlocks();

    return (
        <>
            <div className={styles.popup_verify_status}>
                <h2 className={styles.popup_verify_status_title}>
                    GET VERIFIED AND BECOME A VIP USER
                </h2>
                <div className={styles.popup_verify_status_benefit}>
                    <p className={styles.popup_verify_status_benefit_title}>
                        Dear user, in order to receive VIP status and enjoy special offers, you must
                        first go through three stages of verification. You will then have access to
                        the following services:
                    </p>
                    {handleActiveBlocks.isContactRequest && (
                        <div className={styles.popup_verify_status_benefit_row}>
                            <div className={styles.popup_verify_status_benefit_icon}>
                                <IconPhoneCircle />
                            </div>
                            <span className={styles.popup_verify_status_benefit_text}>
                                Contact information exchange service
                            </span>
                        </div>
                    )}
                    <div className={styles.popup_verify_status_benefit_row}>
                        <div className={styles.popup_verify_status_benefit_icon}>
                            <IconFAQ />
                        </div>
                        <p className={styles.popup_verify_status_benefit_text}>
                            24/7 premium VIP support service. All of your requests will be processed
                            with priority and as quickly as possible.
                        </p>
                    </div>
                </div>
                <div className={styles.popup_verify_status_condition}>
                    <span className={styles.popup_verify_status_condition_title}>
                        Verify status
                    </span>
                    <div className={styles.popup_verify_status_condition_row}>
                        <div className={styles.popup_verify_status_condition_col}>
                            <div
                                className={`${styles.popup_verify_status_condition_name} ${+userInfo?.is_approve_phone ? styles.verified : ''}`}
                            >
                                <IconShieldPhone />
                                Phone number
                            </div>
                            {+userInfo?.is_approve_phone ? (
                                <div className={`${styles.popup_verify_status_condition_btn}`}>
                                    <IconCheckedCircle />
                                    Verified
                                </div>
                            ) : (
                                <div
                                    className={`${styles.popup_verify_status_condition_btn} ${styles.active}`}
                                    onClick={openConfirmPhoneModal}
                                >
                                    Verify
                                </div>
                            )}
                        </div>
                        <div className={styles.popup_verify_status_condition_col}>
                            <div
                                className={`${styles.popup_verify_status_condition_name} ${+userInfo?.email_confirmed ? styles.verified : ''}`}
                            >
                                <IconShieldEmail />
                                Email
                            </div>
                            {+userInfo?.email_confirmed ? (
                                <div className={`${styles.popup_verify_status_condition_btn}`}>
                                    <IconCheckedCircle />
                                    Verified
                                </div>
                            ) : (
                                <div
                                    className={`${styles.popup_verify_status_condition_btn} ${styles.active}`}
                                    onClick={openConfirmEmailModal}
                                >
                                    Verify
                                </div>
                            )}
                        </div>
                        <div className={styles.popup_verify_status_condition_col}>
                            <div
                                className={`${styles.popup_verify_status_condition_name} ${styles.shield_name} ${userInfo?.real_name?.length ? styles.verified : ''}`}
                            >
                                <IconPolicy />
                                Real name
                            </div>
                            {userInfo?.real_name?.length ? (
                                <div className={`${styles.popup_verify_status_condition_btn}`}>
                                    <IconCheckedCircle />
                                    Verified
                                </div>
                            ) : (
                                <div
                                    className={`${styles.popup_verify_status_condition_btn} ${styles.active}`}
                                    onClick={openConfirmNameModal}
                                >
                                    Verify
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.popup_verify_status_condition_value_row}>
                        <div className={styles.popup_verify_status_condition_value_col}>
                            Phone
                            <div className={styles.popup_verify_status_condition_value_text}>
                                {+userInfo?.is_approve_phone ? userInfo?.phone : ''}
                            </div>
                        </div>
                        <div className={styles.popup_verify_status_condition_value_col}>
                            Email
                            <div className={styles.popup_verify_status_condition_value_text}>
                                {+userInfo?.email_confirmed ? userInfo?.email : ''}
                            </div>
                        </div>
                        <div className={styles.popup_verify_status_condition_value_col}>
                            Name
                            <div className={styles.popup_verify_status_condition_value_text}>
                                {userInfo?.real_name?.length ? userInfo?.real_name : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <p className={styles.popup_verify_status_main_btn}>
                    After verification, you will automatically receive a bonus of 100 credits for
                    communication
                </p>
                <div className={styles.popup_verify_status_close} onClick={closeVerifyStatusModal}>
                    <IconCross />
                </div>
            </div>

            <ConfirmStatusPhoneModal
                isActiveConfirmPhoneModal={isActiveConfirmPhoneModal}
                closeConfirmPhoneModal={closeConfirmPhoneModal}
                phoneData={phoneData}
                changePhoneData={changePhoneData}
                submitPhoneData={submitPhoneData}
                isPhoneCodeCanceled={isPhoneCodeCanceled}
                setIsPhoneCodeCanceled={setIsPhoneCodeCanceled}
                switchPhoneNumber={switchPhoneNumber}
            />
            <VerifyPhoneCodeModal
                isVerifyPhoneCodeModal={isVerifyPhoneCodeModal}
                closeVerifyPhoneCodeModal={closeVerifyPhoneCodeModal}
                verifyCode={verifyCode}
                handleVerifyCode={handleVerifyCode}
                handleKeyDownEnter={handleKeyDownEnter}
                submitVerifyCode={submitVerifyCode}
                submitPhoneData={submitPhoneData}
                isApprovePhone={!!+userInfo?.is_approve_phone}
                switchPhoneNumber={switchPhoneNumber}
            />
            <ConfirmStatusEmailModal
                isActiveConfirmEmailModal={isActiveConfirmEmailModal}
                closeConfirmEmailModal={closeConfirmEmailModal}
                emailData={emailData}
                changeEmailData={changeEmailData}
                submitEmailData={submitEmailData}
                isEmailSent={isEmailSent}
            />
            <ConfirmStatusNameModal
                isActiveConfirmNameModal={isActiveConfirmNameModal}
                closeConfirmNameModal={closeConfirmNameModal}
                nameData={nameData}
                changeNameData={changeNameData}
                submitNameData={submitNameData}
            />
        </>
    );
}
