import React from 'react';

export default function IconFolderUpload() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#AFAFAF"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4.5 3C3.83696 3 3.20107 3.26339 2.73223 3.73223C2.26339 4.20107 2 4.83696 2 5.5V14.5C2 15.163 2.26339 15.7989 2.73223 16.2678C3.20107 16.7366 3.83696 17 4.5 17H9.6C9.43668 16.6803 9.30505 16.3454 9.207 16H4.5C4.10218 16 3.72064 15.842 3.43934 15.5607C3.15804 15.2794 3 14.8978 3 14.5V8H7.086C7.48369 7.99965 7.86497 7.84138 8.146 7.56L9.707 6H15.5C15.8978 6 16.2794 6.15804 16.5607 6.43934C16.842 6.72064 17 7.10218 17 7.5V9.6C17.3587 9.784 17.692 10.003 18 10.257V7.5C18 6.83696 17.7366 6.20107 17.2678 5.73223C16.7989 5.26339 16.163 5 15.5 5H9.707L8.22 3.513C8.05747 3.35035 7.86447 3.22133 7.65205 3.1333C7.43963 3.04528 7.21194 2.99998 6.982 3H4.5ZM3 5.5C3 5.10218 3.15804 4.72064 3.43934 4.43934C3.72064 4.15804 4.10218 4 4.5 4H6.982C7.18085 4.00018 7.37148 4.07931 7.512 4.22L8.792 5.5L7.44 6.854C7.39348 6.9004 7.33827 6.93718 7.27753 6.96223C7.21679 6.98728 7.1517 7.00012 7.086 7H3V5.5ZM14.5 10C15.6935 10 16.8381 10.4741 17.682 11.318C18.5259 12.1619 19 13.3065 19 14.5C19 15.6935 18.5259 16.8381 17.682 17.682C16.8381 18.5259 15.6935 19 14.5 19C13.3065 19 12.1619 18.5259 11.318 17.682C10.4741 16.8381 10 15.6935 10 14.5C10 13.3065 10.4741 12.1619 11.318 11.318C12.1619 10.4741 13.3065 10 14.5 10ZM16.853 14.854L16.856 14.851C16.9475 14.7582 16.9992 14.6333 17 14.503V14.497C16.9992 14.3657 16.9467 14.24 16.854 14.147L14.854 12.147C14.7602 12.0531 14.633 12.0003 14.5004 12.0002C14.3677 12.0001 14.2404 12.0527 14.1465 12.1465C14.0526 12.2403 13.9998 12.3675 13.9997 12.5001C13.9996 12.6328 14.0522 12.7601 14.146 12.854L15.293 14H12.5C12.3674 14 12.2402 14.0527 12.1464 14.1464C12.0527 14.2402 12 14.3674 12 14.5C12 14.6326 12.0527 14.7598 12.1464 14.8536C12.2402 14.9473 12.3674 15 12.5 15H15.293L14.146 16.146C14.0521 16.2399 13.9994 16.3672 13.9994 16.5C13.9994 16.6328 14.0521 16.7601 14.146 16.854C14.2399 16.9479 14.3672 17.0006 14.5 17.0006C14.6328 17.0006 14.7601 16.9479 14.854 16.854L16.853 14.854Z" />
        </svg>
    );
}
