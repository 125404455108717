import React from 'react';
import { IScrollDownButtonProps } from './index.interface';

export default function ScrollDownButton({
    isActiveButton,
    onPressButton,
    bottomIndentation = false,
}: IScrollDownButtonProps) {
    return (
        <div
            className={`arrow_down_btn ${isActiveButton ? 'active' : ''} ${bottomIndentation ? 'open_letter_form' : ''}`}
            onClick={onPressButton}
        >
            <img src="../../../../img/letter/arrow-down.svg" alt="arrow-down" />
        </div>
    );
}
