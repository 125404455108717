import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import RegisteredUser from './RegisteredUser';
import useInitRegisteredUserModal from '../../../../hooks/dom/useInitRegisteredUserModal';

export default function RegisteredUserModal() {
    const {
        isRegisteredUserModal,
        closeRegisteredUserModal,
        userPassword,
        userName,
        setUserName,
        saveUserInfo,
    } = useInitRegisteredUserModal();

    return (
        <Modal
            style={modalStyleProps({ zIndex: 99999 })}
            isOpen={isRegisteredUserModal}
            onRequestClose={closeRegisteredUserModal}
            ariaHideApp={false}
        >
            {!!isRegisteredUserModal && (
                <RegisteredUser
                    userPassword={userPassword}
                    userName={userName}
                    setUserName={setUserName}
                    saveUserInfo={saveUserInfo}
                />
            )}
        </Modal>
    );
}
