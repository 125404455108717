import React from 'react';
import styles from '../../../../../styles/confirm-status-modal/styles.module.scss';
import { IConfirmStatusEmailProps } from './index.interface';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconCross from '../../../../Icons/IconCross';

export default function ConfirmStatusEmail({
    closeModal,
    emailData,
    changeEmailData,
    submitEmailData,
    isEmailSent,
}: IConfirmStatusEmailProps) {
    return (
        <div className={styles.popup_confirm}>
            {isEmailSent ? (
                <>
                    <h2 className={styles.popup_confirm_title}>Letter has been sent</h2>
                    <p className={styles.popup_confirm_text}>
                        A confirmation email has been sent to the email address you provided.
                        Please, check the mail. The letter will be delivered within a few minutes.
                        If you don't see it in your inbox, check your spam. After clicking on the
                        link from the letter, verification will be completed automatically. If you
                        didn't receive an email, please try again in a few minutes.
                    </p>
                </>
            ) : (
                <>
                    <h2 className={styles.popup_confirm_title}>
                        {isEmailSent ? 'Letter has been sent' : 'Confirm your email'}
                    </h2>
                    <p className={styles.popup_confirm_text}>
                        A confirmation email will be sent to the email address you provided.
                    </p>
                    <p className={styles.popup_confirm_important_text}>
                        Important: after confirming the mail, your login to the site will be
                        changed.
                    </p>
                    <form onSubmit={submitEmailData}>
                        <div className={styles.popup_confirm_input_wrap}>
                            <input
                                className={styles.popup_confirm_input}
                                type="email"
                                name="email"
                                placeholder="Your E-mail"
                                value={emailData}
                                onChange={changeEmailData}
                            />
                        </div>
                        <button type="submit" className={`${styles.popup_confirm_btn}`}>
                            <IconCheckedCircle />
                            Confirm email
                        </button>
                    </form>
                </>
            )}
            <div className={styles.popup_confirm_close} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
