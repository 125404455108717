import { useSelector } from 'react-redux';
import ConfigApi from '../../services/api/ConfigApi';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';

export default function useHandleRedUser() {
    const userState = useSelector((state: RootState) => state.user);

    const submitLogNotAuth = (type: number, extra = '') => {
        try {
            if (!type) return null;

            if ([RED_USER_LOG_TYPES.LOGIN_SUCCESS, RED_USER_LOG_TYPES.LOGIN_FAIL]?.includes(type)) {
                new ConfigApi().createRedUserLogNotAuth(type, extra);
            }
        } catch (error) {
            sendErrToSentry(error as Error);
            return null;
        }
    };
    const submitLog = (type: number, extra = '') => {
        try {
            if (!type) return null;
            if (userState?.info?.configType !== 1) return null;

            return new ConfigApi().createRedUserLog(type, extra);
        } catch (error) {
            sendErrToSentry(error as Error);
            return null;
        }
    };

    return {
        submitLog,
        submitLogNotAuth,
    };
}
