import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ChatsApi from '../../services/api/ChatsApi';
import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import * as ERROR from '../../constants/error';

export default function useInitModalMeetingRequest() {
    const dispatch = useDispatch();
    const [modalIsActiveMeeting, aetModalIsActiveMeeting] = useState(false);

    const openMeetingModal = () => {
        aetModalIsActiveMeeting(true);
    };

    const closeMeetingModal = () => {
        aetModalIsActiveMeeting(false);
    };

    const submitMeetingRequest = (profile: { id: number }) => {
        new ChatsApi().postMeetingRequest(profile?.id).then((res) => {
            if (res?.status) {
                closeMeetingModal();
                if (res?.message) dispatch(alertAction.setMessage({ message: res?.message }));
                dispatch(userAction.setBalance());
            } else {
                dispatch(
                    alertAction.setMessage({
                        message: res?.message || ERROR.ERROR_REQUEST,
                        title: res?.title,
                    }),
                );
            }
        });
    };

    return {
        modalIsActiveMeeting,
        openMeetingModal,
        closeMeetingModal,
        submitMeetingRequest,
    };
}
