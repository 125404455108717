import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProfilePurchaseHistory from '../../components/Profile/ProfilePurchaseHistory';
import * as menuAction from '../../store/actions/menu';

export default function ProfilePurchaseHistoryPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuAction.setMainMenu('my-profile/purchase-history'));
    }, []);

    return (
        <>
            <ProfilePurchaseHistory />
        </>
    );
}
