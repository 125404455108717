import React from 'react';
import SITE from '../../config/site';
import { MetricsProvider } from '@cabify/prom-react';
import * as METRICS from '../../constants/metrics';
import * as PATH from '../../constants/path';
import MetricsInnerWrap from './MetricsInnerWrap';
import PropTypes from 'prop-types';
import { IMetricsLayoutProps } from './index.interface';

const MetricsLayout = ({ children }: IMetricsLayoutProps) => {
    const getHostname = (): string => {
        let isDevelopment;

        if (typeof process !== 'undefined') {
            isDevelopment = process?.env?.NODE_ENV === 'development';
        }

        return isDevelopment
            ? `stage.${SITE.key}.com`
            : window?.location?.hostname?.replace(/^www\./, '');
    };

    const normalizePath = (path: string): string => {
        for (const key in PATH.PATHS) {
            // @ts-expect-error PATH.PATHS
            const replacePath = PATH.PATHS[key]?.replacePath;
            if (replacePath) {
                const regex = new RegExp(`(${replacePath})([^/]+)`, 'g');
                if (regex.test(path)) {
                    return path.replace(regex, `${replacePath}id`);
                }
            }
        }
        return path;
    };

    return (
        <MetricsProvider
            appName={getHostname()}
            owner="adaptive"
            getNormalizedPath={normalizePath}
            customMetrics={METRICS.PAGE_PERFORMANCE_METRICS}
            metricsAggregatorUrl="https://gateway.cdndate.net/push"
        >
            <MetricsInnerWrap>{children}</MetricsInnerWrap>
        </MetricsProvider>
    );
};

export default MetricsLayout;

MetricsLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
