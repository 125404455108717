import React, { MouseEventHandler } from 'react';
import IconCirclePlus from '../../../Icons/IconCirclePlus';

export default function AddNewCardButton({
    onClickAction,
}: {
    onClickAction: MouseEventHandler<HTMLDivElement>;
}) {
    return (
        <>
            <div className="c3_profile_credits_your_cards_add_new_btn" onClick={onClickAction}>
                <IconCirclePlus />
                <span>
                    Add <span>new</span> card
                </span>
            </div>
        </>
    );
}
