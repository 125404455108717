import React from 'react';
import useTransition from '../../../hooks/transition/useTransition';
import * as SEX from '../../../constants/sex';
import { useSelector } from 'react-redux';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../index';
import { IEmptyResultProps } from './index.interface';
import Holiday from '../../../img/holiday';
import Images from '../../../img';
import { isHolidayActive } from '../../../constants/holiday';

export default function EmptyResult({
    mainBlockStyles,
    imageBlockStyles,
    titleBlockStyles,
    descriptionBlockStyles,
    setDefaultFilterValue,
    type = '',
}: IEmptyResultProps) {
    const userState = useSelector((state: RootState) => state.user);
    const transition = useTransition();

    const handleActiveBlocks = useHandleActiveBlocks();
    const activeHoliday = isHolidayActive();

    const isVideo = handleActiveBlocks.isVideoShow && !userState?.info?.gender;

    const titleContent = (type: string) => {
        switch (type) {
            case 'chat':
                return 'No chats found';
            case 'block':
                return 'No blocked chats';
            case 'trash':
                return 'No letters found';
            case 'draft':
                return 'No drafts found';
            case 'bookmark':
                return 'No bookmarked letters';
            case 'letter':
                return 'No letters found';
            case 'gifts':
                return 'No sent gifts found';
            case 'contact':
                return 'No contact requests found';
            case 'meeting':
                return 'No meeting requests found';
            case 'like':
                return 'No results found based on filters you applied';
            case 'likeHerHistory':
                return 'There are no members you have liked yet';
            case 'search':
                return 'No results found based on filters you applied';

            default:
                return `No results found`;
        }
    };

    return (
        <div
            id="empty_results"
            className="c3_video_empty"
            style={mainBlockStyles ?? {}}
            data-testid="empty-block"
        >
            {activeHoliday && Holiday.images[`${activeHoliday?.name}_empty.svg`] ? (
                <img
                    src={Holiday.images[`${activeHoliday?.name}_empty.svg`]}
                    alt=""
                    className="c3_video_empty_img sweetheart"
                    style={imageBlockStyles ?? {}}
                />
            ) : (
                <img
                    src={Images.images['empty_result_chat.svg']}
                    alt=""
                    className="c3_video_empty_img sweetheart"
                    style={imageBlockStyles ?? {}}
                />
            )}
            <div>
                <div
                    className="c3_video_empty_title"
                    style={titleBlockStyles ?? {}}
                    dangerouslySetInnerHTML={{ __html: titleContent(type) }}
                />
                <div className="c3_video_empty_text" style={descriptionBlockStyles ?? {}}>
                    {(function () {
                        switch (type) {
                            case 'chat':
                                return (
                                    <>
                                        To find someone to chat with, you may use the following
                                        options: <br />
                                        <span
                                            onClick={() =>
                                                transition.transitionWithHistoryPush(
                                                    'like',
                                                    `/like`,
                                                )
                                            }
                                        >
                                            {
                                                SEX.SEX_TEXT[
                                                    userState?.info?.sex_text as
                                                        | 'MALE_SEX'
                                                        | 'FEMALE_SEX'
                                                ].LIKE_SIDE_MENU_BTN
                                            }
                                        </span>
                                        {!handleActiveBlocks.isTwitterUser && (
                                            <span
                                                onClick={() =>
                                                    transition.transitionWithHistoryPush(
                                                        'search',
                                                        `/search`,
                                                    )
                                                }
                                            >
                                                ,{' '}
                                                {
                                                    SEX.SEX_TEXT[
                                                        userState?.info?.sex_text as
                                                            | 'MALE_SEX'
                                                            | 'FEMALE_SEX'
                                                    ].SEARCH_SIDE_MENU_BTN
                                                }
                                            </span>
                                        )}
                                        {isVideo && (
                                            <span
                                                onClick={() =>
                                                    transition.transitionWithHistoryPush(
                                                        'videoshow',
                                                        `/videoshow`,
                                                    )
                                                }
                                            >
                                                , Video show
                                            </span>
                                        )}
                                        .
                                    </>
                                );
                            case 'block':
                                return <>Here you can find chats with members you've blocked.</>;
                            case 'draft':
                                return <>Here you can find unfinished messages.</>;
                            case 'trash':
                                return <>Here you can find deleted messages and restore them.</>;
                            case 'bookmark':
                                return <>Add a letter to bookmarks to have quick access to it.</>;
                            case 'gifts':
                                return (
                                    <>
                                        All available information about gifts you’ve sent will be
                                        displayed here.
                                    </>
                                );
                            case 'contact':
                                return (
                                    <>
                                        All available information about contact requests you’ve made
                                        will be displayed here.
                                    </>
                                );
                            case 'meeting':
                                return (
                                    <>
                                        All available information about meeting requests you’ve made
                                        will be displayed here.
                                    </>
                                );
                            case 'letter':
                                return (
                                    <>
                                        To find someone to chat with, you may use the following
                                        options: <br />
                                        <span
                                            onClick={() =>
                                                transition.transitionWithHistoryPush(
                                                    'like',
                                                    `/like`,
                                                )
                                            }
                                        >
                                            {
                                                SEX.SEX_TEXT[
                                                    userState?.info?.sex_text as
                                                        | 'MALE_SEX'
                                                        | 'FEMALE_SEX'
                                                ].LIKE_SIDE_MENU_BTN
                                            }
                                        </span>
                                        {!handleActiveBlocks.isTwitterUser && (
                                            <span
                                                onClick={() =>
                                                    transition.transitionWithHistoryPush(
                                                        'search',
                                                        `/search`,
                                                    )
                                                }
                                            >
                                                ,{' '}
                                                {
                                                    SEX.SEX_TEXT[
                                                        userState?.info?.sex_text as
                                                            | 'MALE_SEX'
                                                            | 'FEMALE_SEX'
                                                    ].SEARCH_SIDE_MENU_BTN
                                                }
                                            </span>
                                        )}
                                        {isVideo && (
                                            <span
                                                onClick={() =>
                                                    transition.transitionWithHistoryPush(
                                                        'videoshow',
                                                        `/videoshow`,
                                                    )
                                                }
                                            >
                                                , Video show
                                            </span>
                                        )}
                                        .
                                    </>
                                );
                            case 'like':
                                return (
                                    <>
                                        Please clear or change filter parameters to see relevant
                                        results
                                    </>
                                );
                            case 'likeHerHistory':
                                return (
                                    <>
                                        Here you can find the girls you have liked.
                                        <br />
                                        Please, check our&nbsp;
                                        <span
                                            onClick={() =>
                                                transition.transitionWithHistoryPush(
                                                    'like',
                                                    `/like`,
                                                )
                                            }
                                        >
                                            "
                                            {
                                                SEX.SEX_TEXT[
                                                    userState?.info?.sex_text as
                                                        | 'MALE_SEX'
                                                        | 'FEMALE_SEX'
                                                ].LIKE_SIDE_MENU_BTN
                                            }
                                            "
                                        </span>
                                        {!handleActiveBlocks.isTwitterUser && (
                                            <span
                                                onClick={() =>
                                                    transition.transitionWithHistoryPush(
                                                        'search',
                                                        `/search`,
                                                    )
                                                }
                                            >
                                                , "
                                                {
                                                    SEX.SEX_TEXT[
                                                        userState?.info?.sex_text as
                                                            | 'MALE_SEX'
                                                            | 'FEMALE_SEX'
                                                    ].SEARCH_SIDE_MENU_BTN
                                                }
                                                "
                                            </span>
                                        )}
                                        {isVideo && (
                                            <span
                                                onClick={() =>
                                                    transition.transitionWithHistoryPush(
                                                        'videoshow',
                                                        `/videoshow`,
                                                    )
                                                }
                                            >
                                                , "Video show"
                                            </span>
                                        )}
                                        .
                                    </>
                                );
                            case 'search':
                                return (
                                    <>
                                        Please clear or change filter parameters to see relevant
                                        results
                                    </>
                                );
                            default:
                                return (
                                    <>
                                        But there are a lot of other fascinating members.
                                        <br />
                                        Please, check the data you've entered or choose other
                                        parameters
                                    </>
                                );
                        }
                    })()}
                </div>
                {setDefaultFilterValue && (
                    <div className="c3_video_empty_clear_btn" onClick={setDefaultFilterValue}>
                        Clear all filters
                    </div>
                )}
            </div>
        </div>
    );
}
