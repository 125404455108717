// TODO: THIS IS FULL DUPLICATE OF src/components/letters/LettersChat/MessageForm/Emoji/index.tsx
import React from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { IEmojiProps } from './index.interface';
import { isHolidayActive } from '../../../../../../constants/holiday';

export default function Emoji({
    addEmoji,
    isSmileBlock,
    openSmileBlock,
    closeSmileBlock,
    smileBoxRef,
}: IEmojiProps) {
    const activeHoliday = isHolidayActive();
    return (
        <div className="c3_smile_btn_wrap" ref={smileBoxRef} data-testid="emoji-block">
            <div className={`c3_smile_btn_wrap2 ${isSmileBlock ? 'active ' : ''}`}>
                <div
                    className="c3_smile_btn"
                    onClick={() => (isSmileBlock ? closeSmileBlock() : openSmileBlock())}
                    data-testid="emoji-btn"
                >
                    <div className={`c3_smile_btn_img ${activeHoliday && activeHoliday?.name}`} />
                    <span>Emoji</span>
                </div>
                <div className="c3_smiles_list_wrap">
                    <div className="c3_smiles_list" data-testid="emoji-list">
                        <Picker
                            data={data}
                            onEmojiSelect={addEmoji}
                            emojiSize={32}
                            theme="light"
                            navPosition="none"
                            previewPosition="none"
                            searchPosition="none"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
