import React from 'react';

export default function IconPhotoFullSize() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#6F0267"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.90625 5.83336C1.682 5.83336 1.5 5.65137 1.5 5.42711V2.98961C1.5 2.16789 2.16789 1.5 2.98961 1.5H5.42711C5.65137 1.5 5.83336 1.682 5.83336 1.90625C5.83336 2.1305 5.65137 2.3125 5.42711 2.3125H2.98961C2.61639 2.3125 2.3125 2.61639 2.3125 2.98961V5.42711C2.3125 5.65137 2.1305 5.83336 1.90625 5.83336ZM13.0098 14.4975H10.5723C10.3481 14.4975 10.166 14.3155 10.166 14.0912C10.166 13.867 10.3481 13.685 10.5723 13.685H13.0098C13.383 13.685 13.6869 13.3811 13.6869 13.0079V10.5704C13.6869 10.3461 13.8689 10.1641 14.0932 10.1641C14.3174 10.1641 14.4994 10.3461 14.4994 10.5704V13.0079C14.4994 13.8296 13.8315 14.4975 13.0098 14.4975ZM2.98961 14.4975H5.42711C5.65137 14.4975 5.83336 14.3155 5.83336 14.0912C5.83336 13.867 5.65137 13.685 5.42711 13.685H2.98961C2.61639 13.685 2.3125 13.3811 2.3125 13.0079V10.5704C2.3125 10.3461 2.1305 10.1641 1.90625 10.1641C1.682 10.1641 1.5 10.3461 1.5 10.5704V13.0079C1.5 13.8296 2.16789 14.4975 2.98961 14.4975ZM14.0932 5.83336C13.8689 5.83336 13.6869 5.65137 13.6869 5.42711V2.98961C13.6869 2.61639 13.383 2.3125 13.0098 2.3125H10.5723C10.3481 2.3125 10.166 2.1305 10.166 1.90625C10.166 1.682 10.3481 1.5 10.5723 1.5H13.0098C13.8315 1.5 14.4994 2.16789 14.4994 2.98961V5.42711C14.4994 5.65137 14.3174 5.83336 14.0932 5.83336ZM5.15564 12.3308H10.8432C11.6649 12.3308 12.3328 11.6629 12.3328 10.8411V5.15368C12.3328 4.33195 11.6649 3.66406 10.8432 3.66406H5.15564C4.33392 3.66406 3.66602 4.33195 3.66602 5.15368V10.8411C3.66602 11.6629 4.33392 12.3308 5.15564 12.3308ZM4.47852 5.15368C4.47852 4.78046 4.78242 4.47656 5.15564 4.47656H10.8432C11.2164 4.47656 11.5203 4.78046 11.5203 5.15368V10.8411C11.5203 11.2144 11.2164 11.5183 10.8432 11.5183H5.15564C4.78242 11.5183 4.47852 11.2144 4.47852 10.8411V5.15368Z"
            />
        </svg>
    );
}
