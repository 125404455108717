import React, { useRef } from 'react';
import MessageList from './MessageList';
import MessageForm from './MessageForm';
import useHandleChatFunctions from '../../../../hooks/chat/useHandleChatFunctions';
import useHandleChatForm from '../../../../hooks/chat/useHandleChatForm';
import useScrollChatMessageList from '../../../../hooks/chat/helpers/useScrollChatMessageList';
import useTouchChatList from '../../../../hooks/chat/useTouchChatList';
import LoaderGif from '../../../common/LoaderGif';
import useInitNoConnectionChatLoader from '../../../../hooks/connection/useInitNoConnectionChatLoader';
import { IBodyChatProps } from './index.interface';

export default function BodyChat({ isMediaWrapOpen }: IBodyChatProps) {
    const writeMsgRef = useRef<HTMLInputElement | null>(null);
    const inputTextareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleChatFunctions = useHandleChatFunctions();
    const handleChatForm = useHandleChatForm(writeMsgRef, inputTextareaRef);
    const scrollChatMessageList = useScrollChatMessageList();
    const initNoConnectionChatLoader = useInitNoConnectionChatLoader();
    const { handleTouchMove, handleTouchStart } = useTouchChatList();

    return (
        <div
            className={`c3_chat_wrap ${isMediaWrapOpen ? 'media_open' : ''}`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            data-testid="chat-body"
        >
            {initNoConnectionChatLoader.isNoConnection ? (
                <LoaderGif title={initNoConnectionChatLoader.title} />
            ) : (
                <>
                    <MessageList
                        onScrollList={scrollChatMessageList.onScrollList}
                        onScrollDown={scrollChatMessageList.onScrollDown}
                        filterByPageScrollList={scrollChatMessageList.filterByPageScrollList}
                        isLoadingRequest={handleChatForm.isLoadingRequest}
                        setIsLoadingRequest={handleChatForm.setIsLoadingRequest}
                    />
                    <MessageForm
                        inputTextareaRef={inputTextareaRef}
                        writeMsgRef={writeMsgRef}
                        selectRef={handleChatForm.selectRef}
                        buttonRef={handleChatForm.buttonRef}
                        btnStickerRef={handleChatForm.btnStickerRef}
                        isOpenMobSmileBlock={handleChatForm.isOpenMobSmileBlock}
                        setIsOpenMobSmileBlock={handleChatForm.setIsOpenMobSmileBlock}
                        isOpenMobStickerBlock={handleChatForm.isOpenMobStickerBlock}
                        setIsOpenMobStickerBlock={handleChatForm.setIsOpenMobStickerBlock}
                        selectStickerRef={handleChatForm.selectStickerRef}
                        inputData={handleChatForm.inputData}
                        handleInput={handleChatForm.handleInput}
                        onSubmitMessage={handleChatForm.onSubmitMessage}
                        addEmoji={handleChatForm.addEmoji}
                        onSubmitMessageEnter={handleChatForm.onSubmitMessageEnter}
                        handleLike={handleChatFunctions.handleLike}
                        isLoadingRequest={handleChatForm.isLoadingRequest}
                    />
                </>
            )}
        </div>
    );
}
