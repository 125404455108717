import React from 'react';
import popup from '../../../../Profile/ProfilePurchaseHistory/popup.module.scss';
import { IUnsubscribeProps } from './index.interface';
import IconCross from '../../../../Icons/IconCross';

export default function AutoTopUpUnsubscribe({
    closeModal,
    activeSubscribeData,
    switchCancelSubscribe,
    activeSubscribePaymentMethod,
}: IUnsubscribeProps) {
    return (
        <div className={popup.top_up_modal_wrap}>
            <div className={popup.modal_text}>
                <p>
                    Are you sure you want to unsubscribe from the automatic{' '}
                    {activeSubscribeData?.credits} credits subscription when you have 5 credits or
                    less left to keep chatting?
                </p>
            </div>
            <div className={popup.modal_buttons}>
                <div
                    className={`${popup.modal_btn} ${popup.grey_btn}`}
                    onClick={() => switchCancelSubscribe(activeSubscribePaymentMethod)}
                >
                    Accept
                </div>
                <div className={`${popup.modal_btn} ${popup.color_btn}`} onClick={closeModal}>
                    Close
                </div>
            </div>
            <div className={popup.close_modal} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
