import { SearchActionTypes } from '../../constants/search';
import SearchApi from '../../../services/api/v3/SearchApi';
import { SEARCH_FILTERS_DEFAULT } from '../../../constants/search';
import { Dispatch } from 'redux';
import MarketingApi from '../../../services/api/MarketingApi';
import {
    IGetSearchListAction,
    ISearchFilter,
    ISetIsLoadingPageAction,
} from '../../reducers/search/index.interface';

export function getSearchList(
    page = 1,
    filter: ISearchFilter = SEARCH_FILTERS_DEFAULT as ISearchFilter,
) {
    return async function (dispatch: Dispatch<IGetSearchListAction | ISetIsLoadingPageAction>) {
        const crossBannersInfo = await new MarketingApi().getCrossBannerData();
        return new SearchApi().getSearchList(page, filter).then((res) => {
            if (res?.status) {
                dispatch({
                    type: SearchActionTypes.GET_SEARCH_LIST,
                    searchList: res?.response,
                    crossBannersInfo: crossBannersInfo?.response,
                });

                return res?.response;
            }

            dispatch({
                type: SearchActionTypes.SET_IS_LOADING_PAGE,
                status: false,
            });
            return [];
        });
    };
}

export function initSearchState() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: SearchActionTypes.INIT_SEARCH,
        });
    };
}

export function setSearchListPage(page = 1) {
    return function (dispatch: Dispatch<{ type: string; page: number }>) {
        return dispatch({
            type: SearchActionTypes.SET_SEARCH_LIST_PAGE,
            page,
        });
    };
}

export function setSearchListFilter(
    filter: ISearchFilter = SEARCH_FILTERS_DEFAULT as ISearchFilter,
) {
    return function (dispatch: Dispatch<{ type: string; filter: ISearchFilter }>) {
        return dispatch({
            type: SearchActionTypes.SET_SEARCH_LIST_FILTER,
            filter,
        });
    };
}

export function setSearchListSubscribeStatus(externalId: number, status: boolean) {
    return function (dispatch: Dispatch<{ type: string; externalId: number; status: boolean }>) {
        return dispatch({
            type: SearchActionTypes.SET_SEARCH_LIST_SUBSCRIBE_STATUS,
            externalId,
            status,
        });
    };
}

export function setSearchOnlineStatus(externalIDList: number[], status: number) {
    return function (
        dispatch: Dispatch<{ type: string; externalIDList: number[]; status: number }>,
    ) {
        return dispatch({
            type: SearchActionTypes.SET_SEARCH_ONLINE_STATUS,
            externalIDList,
            status,
        });
    };
}

export function setSearchLikeStatus(womanExternalID: number) {
    return function (dispatch: Dispatch<{ type: string; womanExternalID: number }>) {
        return dispatch({
            type: SearchActionTypes.SET_SEARCH_LIKE_STATUS,
            womanExternalID,
        });
    };
}
