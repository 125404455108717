import { MailboxActionTypes } from '../../constants/mailbox';
import moment from 'moment';
import { IMailboxState, MailboxActions } from './index.interface';
import {
    IDraftsFullInfo,
    IMailsAttachment,
    IMailsFullInfo,
} from '../../../services/api/MailboxApi.interface';

const initState = {
    mailList: [],
    mailListPage: 1,
    mailListAllPages: 1,
    mailChatInfo: {},
    status: false,
    empty: false,

    femaleList: [],
    emptyFemaleList: false,
    checkedRecipients: [],

    countUnread: 0,
};

export default function (state: IMailboxState = initState, action: MailboxActions): IMailboxState {
    const newArr: any[] = [];

    switch (action.type) {
        case MailboxActionTypes.INIT_MAILBOX:
            return {
                mailList: [],
                mailListPage: 1,
                mailListAllPages: 1,
                mailChatInfo: {},
                status: false,
                empty: false,

                femaleList: [],
                emptyFemaleList: false,
                checkedRecipients: [],

                countUnread: 0,
            };

        case MailboxActionTypes.FETCH_MAILBOX_GET:
            return {
                ...state,
                ...{
                    mailList: [],
                    mailListPage: 1,
                    mailListAllPages: 1,
                    mailChatInfo: {},
                    status: false,
                    empty: false,
                },
            };

        case MailboxActionTypes.MAILBOX_GET:
            return {
                ...state,
                ...{
                    mailList: action.data.mails,
                    mailChatInfo: action.data.chat,
                    mailListPage: action.data.page,
                    mailListAllPages: action.data.pages,
                    status: action.data.status,
                    empty: action?.data?.mails?.length <= 0,
                },
            };

        case MailboxActionTypes.MAILBOX_SET_CURRENT_PAGE:
            return {
                ...state,
                ...{
                    mailListPage: action?.page,
                },
            };

        case MailboxActionTypes.PAID_LETTER_CONTENT: {
            const newMailList = state?.mailList?.length
                ? (state.mailList as IMailsFullInfo[]).map((item) => {
                      const isAttachment = item?.attachments?.some(
                          (attachment: IMailsAttachment) =>
                              attachment?.attachment_id === action?.contentID,
                      );

                      if (+item?.mail?.id === +action?.letterID && isAttachment) {
                          return {
                              ...item,
                              attachments: item?.attachments?.map(
                                  (attachment: IMailsAttachment) => {
                                      if (attachment?.attachment_id === action?.contentID) {
                                          return {
                                              ...attachment,
                                              attachment_payed: 1,
                                              link: action?.content,
                                              ma_date_viewed: moment()
                                                  .utc()
                                                  .format('YYYY-MM-DD HH:mm:ss'),
                                          };
                                      }
                                      return attachment;
                                  },
                              ),
                          };
                      }
                      return item;
                  })
                : [];
            return {
                ...state,
                ...{
                    mailList: newMailList as IMailsFullInfo[],
                },
            };
        }

        case MailboxActionTypes.MAILBOX_GET_DRAFTS:
            return {
                ...state,
                ...{
                    mailList: action?.data?.drafts,
                    status: action?.data?.status,
                    mailListPage: action?.page,
                    mailListAllPages: action?.data?.pages,
                    empty: action?.data?.drafts?.length <= 0,
                },
            };

        case MailboxActionTypes.UPDATE_MAIL_LIST_DRAFTS: {
            const newMailList = state?.mailList?.length
                ? (state.mailList as IDraftsFullInfo[]).map((item) => {
                      if (
                          +action?.data?.draft_ids.some(
                              (el) => +el === item?.draft?.id && +item?.draft?.id,
                          )
                      ) {
                          return {
                              ...item,
                              draft: {
                                  ...item?.draft,
                                  message_content: action?.data?.message_content,
                              },
                              ...(action?.data?.attachments?.length
                                  ? { attachments: action?.data?.attachments }
                                  : {}),
                          };
                      } else {
                          return item;
                      }
                  })
                : [];
            return {
                ...state,
                ...{
                    mailList: newMailList as IDraftsFullInfo[],
                },
            };
        }

        case MailboxActionTypes.DELETE_DRAFT_MAIL_LIST:
            return {
                ...state,
                ...{
                    mailList: (state.mailList as IDraftsFullInfo[])?.filter(
                        (item) =>
                            +action?.draftIdArr?.some(
                                (el) => item?.draft?.id && +el !== +item?.draft?.id,
                            ),
                    ),
                },
            };

        // MAILBOX minor --------------------------------------------------------
        case MailboxActionTypes.ADD_CHECKED_RECIPIENTS:
            return {
                ...state,
                ...{
                    checkedRecipients: state.checkedRecipients.concat(action.data),
                },
            };

        case MailboxActionTypes.REMOVE_CHECKED_RECIPIENTS:
            state.checkedRecipients.forEach((item) => {
                if (item.external_id !== action.data.external_id) {
                    newArr.push(item);
                }
            });
            return {
                ...state,
                ...{
                    checkedRecipients: newArr ?? [],
                },
            };

        case MailboxActionTypes.REMOVE_ALL_CHECKED_RECIPIENTS:
            return {
                ...state,
                ...{
                    checkedRecipients: [],
                },
            };

        case MailboxActionTypes.MAILBOX_GET_FEMALE_LIST:
            return {
                ...state,
                ...{
                    femaleList: action?.data?.result,
                    emptyFemaleList: action?.data?.result?.length <= 0,
                },
            };

        case MailboxActionTypes.SET_MAILBOX_LIST_ONLINE_STATUS: {
            const newMailList = state?.mailList?.length
                ? (state?.mailList as IMailsFullInfo[])?.map((item) => {
                      if (action?.externalIDList?.includes(item?.mail?.female_id)) {
                          return {
                              ...item,
                              ...{
                                  mail: {
                                      ...item?.mail,
                                      ...{
                                          sender_online: action?.status,
                                          recipient_online: action?.status,
                                      },
                                  },
                              },
                          };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ mailList: newMailList as IMailsFullInfo[] },
            };
        }

        case MailboxActionTypes.SET_DRAFT_MAILBOX_LIST_ONLINE_STATUS: {
            const newMailList = state?.mailList?.length
                ? (state?.mailList as IDraftsFullInfo[])?.map((item) => {
                      if (item?.draft?.recipient_id) {
                          if (action?.externalIDList?.includes(item?.draft?.recipient_id)) {
                              return {
                                  ...item,
                                  ...{
                                      draft: {
                                          ...item?.draft,
                                          ...{
                                              recipient_online: action?.status,
                                          },
                                      },
                                  },
                              };
                          }
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ mailList: newMailList as IDraftsFullInfo[] },
            };
        }

        case MailboxActionTypes.MAILBOX_LIST_SET_FAVORITE: {
            const newMailList = state?.mailList?.length
                ? (state?.mailList as IMailsFullInfo[])?.map((item) => {
                      if (+action?.chatId === +item?.mail?.chat_id) {
                          return {
                              ...item,
                              ...{
                                  mail: {
                                      ...item?.mail,
                                      ...{
                                          is_favorite: +action?.isFavorite,
                                      },
                                  },
                              },
                          };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ mailList: newMailList as IMailsFullInfo[] },
            };
        }

        default:
            return state;
    }
}
