import React from 'react';
import styles from './styles.module.scss';
import { ITopicsProps } from './index.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';

export default function Topics({ changeTopic, isMobileQuestionsOpened }: ITopicsProps) {
    const faqState = useSelector((state: RootState) => state.faq);

    return (
        <div className={`${styles.topics_col} ${isMobileQuestionsOpened ? styles.mob_opened : ''}`}>
            {faqState.faqData
                ?.sort((a, b) => a?.order - b?.order)
                ?.map((el, idx) => (
                    <div
                        key={idx}
                        className={`${styles.topic_item} ${faqState?.activeTopicId === el?.id ? styles.active : ''}`}
                        onClick={() => changeTopic(el?.id, el?.title)}
                    >
                        <div className={styles.number} />
                        <div className={styles.title}>{el?.title}</div>
                    </div>
                ))}
        </div>
    );
}
