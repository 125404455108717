import React, { useState } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import styles from './styles.module.scss';
import OnboardingTitle from '../../onboarding/OnboardingTitle/OnboardingTitle';
import * as ONBOARDING from '../../../constants/onboarding';
import title_welcome from '../../../img/onboarding/title_welcome.svg';
import title_about from '../../../img/onboarding/title_about.svg';
import title_thank_you from '../../../img/onboarding/title_thank_you.svg';
// import OnboardingDoubleRange from '../../onboarding/OnboardingDoubleRange/OnboardingDoubleRange';
import OnboardingRadioButton from '../../onboarding/OnboardingRadioButton/OnboardingRadioButton';
import { useSelector } from 'react-redux';
import OnboardingHeader from '../../onboarding/OnboardingHeader/OnboardingHeader';
import OnboardingMultiselect from '../../onboarding/OnboardingMultiselect/OnboardingMultiselect';
import OnboardingTextArea from '../../onboarding/OnboardingTextArea/OnboardingTextArea';
import OnboardingUploadPhoto from '../../onboarding/OnboardingUploadPhoto/OnboardingUploadPhoto';
import OnboardingLocation from '../../onboarding/OnboardingLocation/OnboardingLocation';
import OnboardingHeightWeight from '../../onboarding/OnboardingHeightWeight/OnboardingHeightWeight';
import SITE from '../../../config/site';
import OnboardingDoubleRadio from '../../onboarding/OnboardingDoubleRadio/OnboardingDoubleRadio';
import OnboardingSearch from '../../onboarding/OnboardingSearch/OnboardingSearch';
import { RootState } from '../../../index';
import { IOnboardingModalProps } from './index.interface';

const OnboardingModal = ({
    isActiveOnboarding,
    onCloseOnboarding,
    onboarding,
    formData,
    onSetFormData,
    saveOnboarding,
}: IOnboardingModalProps) => {
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const [activeStep, setActiveStep] = useState<number>(0);

    const activeStepKey = `step${activeStep + 1}`;
    const activeStepData = formData[activeStepKey];

    const templateList = onboarding?.templates?.filter((item: any) =>
        ONBOARDING.ONBOARDING_TEMPLATE_LIST?.includes(item?.template),
    );

    const maxStep = templateList?.filter(
        (el: any) => !el?.template?.toLowerCase().includes('title'),
    )?.length;

    const saveStep = (data: Record<string, any>) => {
        const isLastStep = activeStep === templateList?.length - 1;
        const stepData = { [activeStepKey]: data };
        saveOnboarding({ stepData, isLastStep });

        if (!isLastStep) setActiveStep(activeStep + 1);
    };

    const skipStep = (keyList: never[] | string[] = []) => {
        if (keyList?.length) {
            saveStep(Object.fromEntries(keyList.map((key) => [key, null])));
        }
    };

    const skipQuiz = () => {
        saveOnboarding({ isLastStep: true });
    };

    const prevStep = () => {
        if (activeStep > 0) setActiveStep(activeStep - 1);
    };

    const handleFormData = (stepData: Record<string, string | number | number[]>) => {
        onSetFormData({ [activeStepKey]: { ...formData[activeStepKey], ...stepData } });
    };

    const activeOnboardingTemplate = () => {
        if (activeStep > templateList?.length) return <div />;

        const activeTemplate = templateList?.[activeStep];

        if (activeTemplate?.template === ONBOARDING.ONBOARDING_TITLE_WELCOME) {
            return (
                <OnboardingTitle
                    image={title_welcome}
                    title={`Welcome to ${SITE.name}!`}
                    subtitle="Kindly complete the brief survey on your preferred communication partners to help us enhance your experience on the platform"
                    btnTitle="Start quiz"
                    onClick={() => saveStep({ title_welcome: true })}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_TITLE_ABOUT_YOURSELF) {
            return (
                <OnboardingTitle
                    image={title_about}
                    title="About yourself"
                    subtitle="Please, answer several questions about yourself to give other members a chance to know you better."
                    btnTitle="Continue"
                    onClick={() => saveStep({ title_about_yourself: true })}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_TITLE_THANK_YOU) {
            return (
                <OnboardingTitle
                    image={title_thank_you}
                    title="Thank You!"
                    subtitle="Thanks for taking the time and effort to complete the questionnaire"
                    btnTitle="Close"
                    onClick={() => saveStep({ title_thank_you: true })}
                />
            );
        }
        // else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_HEIGHT) {
        //     return (
        //         <OnboardingDoubleRange
        //             activeStep={activeStep}
        //             maxStep={maxStep}
        //             title="Ideal height for women is"
        //             subtitle={`Between ${activeStepData?.womanHeightFrom || 120} and ${activeStepData?.womanHeightTo || 200} cm`}
        //             minDefault={120}
        //             maxDefault={200}
        //             minInit={activeStepData?.womanHeightFrom || 120}
        //             maxInit={activeStepData?.womanHeightTo || 200}
        //             onClick={() =>
        //                 saveStep({
        //                     womanHeightFrom: activeStepData?.womanHeightFrom || 120,
        //                     womanHeightTo: activeStepData?.womanHeightTo || 200,
        //                 })
        //             }
        //             onChange={(min, max) => {
        //                 handleFormData({
        //                     womanHeightFrom: min,
        //                     womanHeightTo: max,
        //                 });
        //             }}
        //             skipStep={() => skipStep(['womanHeightFrom', 'womanHeightTo'])}
        //         />
        //     );
        // } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_WEIGHT) {
        //     return (
        //         <OnboardingDoubleRange
        //             activeStep={activeStep}
        //             maxStep={maxStep}
        //             title="Ideal weight for women is"
        //             subtitle={`Between ${activeStepData?.womanWeightFrom || 40} and ${activeStepData?.womanWeightTo || 200} kg`}
        //             minDefault={40}
        //             maxDefault={200}
        //             minInit={activeStepData?.womanWeightFrom || 40}
        //             maxInit={activeStepData?.womanWeightTo || 200}
        //             onClick={() =>
        //                 saveStep({
        //                     womanWeightFrom: activeStepData?.womanWeightFrom || 40,
        //                     womanWeightTo: activeStepData?.womanWeightTo || 200,
        //                 })
        //             }
        //             onChange={(min, max) => {
        //                 handleFormData({
        //                     womanWeightFrom: min,
        //                     womanWeightTo: max,
        //                 });
        //             }}
        //             skipStep={() => skipStep(['womanWeightFrom', 'womanWeightTo'])}
        //         />
        //     );
        // }
        else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_BODY_TYPE) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="Ideal body type for women is"
                    initList={referenciesState?.list?.body_list || []}
                    value={activeStepData?.womanBodyID}
                    onClick={() => saveStep({ womanBodyID: activeStepData?.womanBodyID || null })}
                    onChange={(value) => handleFormData({ womanBodyID: value })}
                    skipStep={() => skipStep(['womanBodyID'])}
                />
            );
        }
        // else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_AGE) {
        //     return (
        //         <OnboardingDoubleRange
        //             activeStep={activeStep}
        //             maxStep={maxStep}
        //             title="It’s easier for me to get along with women who are"
        //             subtitle={`Between ${activeStepData?.womanAgeFrom || 18} and ${activeStepData?.womanAgeTo || 80} years old`}
        //             minDefault={18}
        //             maxDefault={80}
        //             minInit={activeStepData?.womanAgeFrom || 18}
        //             maxInit={activeStepData?.womanAgeTo || 80}
        //             onClick={() =>
        //                 saveStep({
        //                     womanAgeFrom: activeStepData?.womanAgeFrom || 18,
        //                     womanAgeTo: activeStepData?.womanAgeTo || 80,
        //                 })
        //             }
        //             onChange={(min, max) => handleFormData({ womanAgeFrom: min, womanAgeTo: max })}
        //             skipStep={() => skipStep(['womanAgeFrom', 'womanAgeTo'])}
        //         />
        //     );
        // }
        else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_ALCOHOL) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="In my opinion it’s ok for women to drink alcohol"
                    initList={referenciesState?.list?.alcohol_list || []}
                    value={activeStepData?.womanAlcoholID}
                    onClick={() => saveStep({ womanAlcoholID: activeStepData?.womanAlcoholID })}
                    onChange={(value) => handleFormData({ womanAlcoholID: value })}
                    skipStep={() => skipStep(['womanAlcoholID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_SMOKE) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="In my opinion it’s ok for women to smoke"
                    initList={referenciesState?.list?.smoke_list || []}
                    value={activeStepData?.womanSmokeID}
                    onClick={() => saveStep({ womanSmokeID: activeStepData?.womanSmokeID })}
                    onChange={(value) => handleFormData({ womanSmokeID: value })}
                    skipStep={() => skipStep(['womanSmokeID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_HAVE_CHILDREN) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="Do you think modern women should have children"
                    initList={referenciesState?.list?.children_list || []}
                    value={activeStepData?.womanChildrenID}
                    onClick={() => saveStep({ womanChildrenID: activeStepData?.womanChildrenID })}
                    onChange={(value) => handleFormData({ womanChildrenID: value })}
                    skipStep={() => skipStep(['womanChildrenID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_COUNTRY_CITY) {
            return (
                <OnboardingLocation
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="Where are you from"
                    subtitle="Select the correct answer"
                    country={activeStepData?.manCountry}
                    city={activeStepData?.manCity}
                    onClick={() =>
                        saveStep({
                            manCountry: activeStepData?.manCountry,
                            manCity: activeStepData?.manCity,
                        })
                    }
                    onChange={(country, city) =>
                        handleFormData({
                            manCountry: country,
                            manCity: city,
                        })
                    }
                    skipStep={() => skipStep(['manCountry', 'manCity'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_HEIGHT_WEIGHT) {
            return (
                <OnboardingHeightWeight
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="What are your height and weight?"
                    title1="Height"
                    index1="id"
                    displayKey="value"
                    value1={
                        referenciesState?.list?.height_list?.find(
                            (item) => item?.id === activeStepData?.manHeightID,
                        )?.value || ''
                    }
                    setValue1={(value) => handleFormData({ manHeightID: value })}
                    dropdownArray1={referenciesState?.list?.height_list || []}
                    title2="Weight"
                    value2={activeStepData?.manWeight || ''}
                    setValue2={(value) => handleFormData({ manWeight: value })}
                    onClick={() =>
                        saveStep({
                            manHeightID: activeStepData?.manHeightID,
                            manWeight: activeStepData?.manWeight,
                        })
                    }
                    skipStep={() => skipStep(['manHeightID', 'manWeight'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_BODY_TYPE) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="What’s your body type?"
                    initList={referenciesState?.list?.body_list || []}
                    value={activeStepData?.manBodyID}
                    onClick={() => saveStep({ manBodyID: activeStepData?.manBodyID })}
                    onChange={(value) => handleFormData({ manBodyID: value })}
                    skipStep={() => skipStep(['manBodyID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_HAVE_CHILDREN) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="Do you have children?"
                    initList={referenciesState?.list?.children_list || []}
                    value={activeStepData?.manChildrenID}
                    onClick={() => saveStep({ manChildrenID: activeStepData?.manChildrenID })}
                    onChange={(value) => handleFormData({ manChildrenID: value })}
                    skipStep={() => skipStep(['manChildrenID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_WANT_MORE_CHILDREN) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="Do you want more children?"
                    initList={referenciesState?.list?.morechildren_list || []}
                    value={activeStepData?.manMoreChildrenID}
                    onClick={() =>
                        saveStep({ manMoreChildrenID: activeStepData?.manMoreChildrenID })
                    }
                    onChange={(value) => handleFormData({ manMoreChildrenID: value })}
                    skipStep={() => skipStep(['manMoreChildrenID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_EDUCATION) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="What’s your education?"
                    initList={referenciesState?.list?.education_list || []}
                    value={activeStepData?.manEducationID}
                    onClick={() => saveStep({ manEducationID: activeStepData?.manEducationID })}
                    onChange={(value) => handleFormData({ manEducationID: value })}
                    skipStep={() => skipStep(['manEducationID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_OCCUPATION) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="What do you do for a living?"
                    initList={referenciesState?.list?.occupation_list || []}
                    value={activeStepData?.manOccupationID}
                    onClick={() => saveStep({ manOccupationID: activeStepData?.manOccupationID })}
                    onChange={(value) => handleFormData({ manOccupationID: value })}
                    skipStep={() => skipStep(['manOccupationID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_ALCOHOL) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="Do you drink?"
                    initList={referenciesState?.list?.alcohol_list || []}
                    value={activeStepData?.manAlcoholID}
                    onClick={() => saveStep({ manAlcoholID: activeStepData?.manAlcoholID })}
                    onChange={(value) => handleFormData({ manAlcoholID: value })}
                    skipStep={() => skipStep(['manAlcoholID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_SMOKE) {
            return (
                <OnboardingRadioButton
                    activeStep={activeStep}
                    maxStep={maxStep}
                    title="Do you smoke?"
                    initList={referenciesState?.list?.smoke_list || []}
                    value={activeStepData?.manSmokeID}
                    onClick={() => saveStep({ manSmokeID: activeStepData?.manSmokeID })}
                    onChange={(value) => handleFormData({ manSmokeID: value })}
                    skipStep={() => skipStep(['manSmokeID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_HOBBIES) {
            return (
                <OnboardingMultiselect
                    title="What are your hobbies?"
                    activeStep={activeStep}
                    maxStep={maxStep}
                    subtitle="Please, pick up to 5 options"
                    initList={referenciesState?.list?.hobbie_list || []}
                    value={activeStepData?.manHobbyIDList}
                    onClick={() => saveStep({ manHobbyIDList: activeStepData?.manHobbyIDList })}
                    onChange={(value) => handleFormData({ manHobbyIDList: value })}
                    skipStep={() => skipStep(['manHobbyIDList'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_EYE_HEIR) {
            return (
                <OnboardingDoubleRadio
                    title="Appearance of your perfect partner:"
                    activeStep={activeStep}
                    maxStep={maxStep}
                    subtitle="Pick one option each"
                    titleList1="Her hair color"
                    titleList2="Her eye color"
                    initList1={referenciesState?.list?.hair_list || []}
                    initList2={referenciesState?.list?.eye_list || []}
                    value1={activeStepData?.womanHairID}
                    value2={activeStepData?.womanEyeID}
                    onChange1={(value) => handleFormData({ womanHairID: value })}
                    onChange2={(value) => handleFormData({ womanEyeID: value })}
                    onClick={() =>
                        saveStep({
                            womanHairID: activeStepData?.womanHairID,
                            womanEyeID: activeStepData?.womanEyeID,
                        })
                    }
                    skipStep={() => skipStep(['womanHairID', 'womanEyeID'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_DESCRIBE_YOURSELF) {
            return (
                <OnboardingTextArea
                    title="Describe yourself"
                    activeStep={activeStep}
                    maxStep={maxStep}
                    subtitle="Tell more about yourself"
                    value={activeStepData?.manDescription || ''}
                    onClick={() => saveStep(activeStepData?.manDescription || '')}
                    onChange={(value) => handleFormData({ manDescription: value })}
                    skipStep={() => skipStep(['manDescription'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_DESCRIBE_PARTNER) {
            return (
                <OnboardingTextArea
                    title="Please, describe your perfect partner"
                    activeStep={activeStep}
                    maxStep={maxStep}
                    subtitle="Tell more about your perfect partner"
                    value={activeStepData?.manLooking || ''}
                    onClick={() => saveStep({ manLooking: activeStepData?.manLooking })}
                    onChange={(value) => handleFormData({ manLooking: value })}
                    skipStep={() => skipStep(['manLooking'])}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_WOMAN_SEARCH) {
            return (
                <OnboardingSearch
                    title="We think those women might interest you! We hope it’s mutual"
                    activeStep={activeStep}
                    maxStep={maxStep}
                    formData={formData}
                    subtitle="Pick to select. You can like up to 3 partners."
                    onClick={(value) => saveStep({ womanSearch: value })}
                    skipStep={() => skipStep(['womanSearch'])}
                    onSkip={skipQuiz}
                />
            );
        } else if (activeTemplate?.template === ONBOARDING.ONBOARDING_MAN_PHOTO) {
            return (
                <OnboardingUploadPhoto
                    title="Add your photo"
                    activeStep={activeStep}
                    maxStep={maxStep}
                    value={activeStepData?.manPhotoLink}
                    onClick={() => saveStep({ manPhotoLink: activeStepData?.manPhotoLink })}
                    onChange={(value) => handleFormData({ manPhotoLink: value })}
                    skipStep={() => skipStep(['manPhotoLink'])}
                />
            );
        } else {
            return <div />;
        }
    };

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={isActiveOnboarding}
            onRequestClose={onCloseOnboarding}
        >
            <div className={styles.wrapper}>
                <OnboardingHeader
                    progress={(activeStep * 100) / templateList?.length}
                    onBack={prevStep}
                    onSkip={skipQuiz}
                />

                <div className={styles.content}>{activeOnboardingTemplate()}</div>
            </div>
        </Modal>
    );
};

export default OnboardingModal;
