import { FilesActionTypes } from '../../constants/files';
import FilesApi from '../../../services/api/FilesApi';
import { Dispatch } from 'redux';
import { ISetSortedFilesAction } from '../../reducers/files/index.interface';
import { IUpdateSortOrderList } from '../../../services/api/FilesApi.interface';

export function getAllImages(callback?: () => void) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new FilesApi().getAllImages().then((res) => {
            if (callback) callback();
            return dispatch({
                type: FilesActionTypes.FILES_IMG_GET,
                data: res,
            });
        });
    };
}

export function getAllVideos(callback?: () => void) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new FilesApi().getAllVideos().then((res) => {
            if (callback) callback();
            return dispatch({
                type: FilesActionTypes.FILES_VIDEO_GET,
                data: res,
            });
        });
    };
}

export function getAllAudios(callback?: () => void) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        new FilesApi().getAllAudios().then((res) => {
            if (callback) callback();
            return dispatch({
                type: FilesActionTypes.FILES_AUDIO_GET,
                data: res,
            });
        });
    };
}

export function deleteImage(fileId: number) {
    return function (dispatch: Dispatch<{ type: string; fileId: number }>) {
        return dispatch({
            type: FilesActionTypes.SET_DELETE_IMAGE,
            fileId,
        });
    };
}

export function deleteVideo(fileId: number) {
    return function (dispatch: Dispatch<{ type: string; fileId: number }>) {
        return dispatch({
            type: FilesActionTypes.SET_DELETE_VIDEO,
            fileId,
        });
    };
}

export function deleteAudio(fileId: number) {
    return function (dispatch: Dispatch<{ type: string; fileId: number }>) {
        return dispatch({
            type: FilesActionTypes.SET_DELETE_AUDIO,
            fileId,
        });
    };
}

export function setSortedFiles(
    fileType: 'images' | 'videos' | 'audios',
    sortedArray: IUpdateSortOrderList[],
) {
    return function (dispatch: Dispatch<ISetSortedFilesAction>) {
        return dispatch({
            type: FilesActionTypes.SET_SORTED_FILES,
            fileType,
            sortedArray,
        });
    };
}
