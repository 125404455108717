import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface IInitialList {
    id: number;
    status: number;
    value: string;
}

interface IModifiedList {
    id: number;
    title: string;
}
interface IOnboardingMultiselect {
    activeStep: number;
    maxStep: number;
    title: string;
    subtitle: string;
    onClick: () => void;
    initList: IInitialList[];
    value: number[];
    onChange: (value: number[]) => void;
    skipStep: () => void;
}

const OnboardingMultiselect = ({
    activeStep,
    maxStep,
    title,
    subtitle,
    onClick,
    initList,
    value,
    onChange,
    skipStep,
}: IOnboardingMultiselect) => {
    const [list, setList] = useState<IModifiedList[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
    const [disabledSubmit, setDisabledSubmit] = useState(true);

    const onSelectOption = (option: number) => {
        if (selectedOptions?.includes(option)) {
            setSelectedOptions(selectedOptions?.filter((item) => item !== option));
            onChange(selectedOptions?.filter((item) => item !== option));
        } else {
            if (selectedOptions?.length >= 5) return;
            setSelectedOptions([...selectedOptions, option]);
            onChange([...selectedOptions, option]);
        }
    };

    useEffect(() => {
        setDisabledSubmit(!selectedOptions?.length);
    }, [selectedOptions?.length]);

    useEffect(() => {
        if (initList?.length) {
            setList(initList?.map((item) => ({ title: item?.value, id: item?.id })));
        }
    }, [initList]);

    useEffect(() => {
        if (value?.length) setSelectedOptions(value);
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>

            <div className={styles.list}>
                {list?.map((item, key) => (
                    <div
                        key={key}
                        className={`${styles.option} ${selectedOptions?.includes(item?.id) ? styles.option_active : ''}`}
                        onClick={() => onSelectOption(item?.id)}
                    >
                        {item?.title}
                    </div>
                ))}
            </div>

            <div className={styles.button_row}>
                <button className={styles.button} onClick={onClick} disabled={disabledSubmit}>
                    Continue
                </button>
                <button className={`${styles.button} ${styles.skip}`} onClick={skipStep}>
                    Skip
                </button>
            </div>
        </div>
    );
};

export default OnboardingMultiselect;
