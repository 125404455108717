import React from 'react';
import styles from '../../styles.module.scss';
import { IRateQuestionProps } from './index.interface';

export default function RateQuestion({
    title,
    scoreAnswer,
    handleFormData,
    isOpenQuestion,
}: IRateQuestionProps) {
    const inputs = Array.from({ length: 5 }, (_, i) => (i + 1).toString());

    return (
        <>
            <p className={styles.support_feedback_modal_text}>
                {isOpenQuestion && '1.'} {title}
            </p>
            <div className={styles.rating_text_row}>
                <p className={styles.rating_text}>Dissatisfied</p>
                <ul className={styles.rating_inputs_row} data-testid="rate-support-feedback-list">
                    {inputs.map((item, index) => (
                        <li key={index}>
                            <input
                                className={styles.rating_radio}
                                type="radio"
                                id={'rate_' + item}
                                name="scoreAnswer"
                                checked={scoreAnswer === item}
                                onChange={() => handleFormData('scoreAnswer', item)}
                            />
                            <label className={styles.rating_radio_label} htmlFor={'rate_' + item}>
                                {item}
                                <div className={styles.rating_radio_label_circle_background}>
                                    <span
                                        className={styles.rating_radio_label_emoji}
                                        data-index={'rate_' + item}
                                        aria-hidden="true"
                                    />
                                </div>
                            </label>
                        </li>
                    ))}
                </ul>
                <p className={styles.rating_text}>Very Satisfied</p>
            </div>
        </>
    );
}
