import React, { MouseEventHandler } from 'react';
import Modal from 'react-modal';

import modalStyleProps from '../../../../styles/react-modal/modal.style';

import Term from './Term';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';

export default function TermModal({
    closeTermModal,
}: {
    closeTermModal: MouseEventHandler<HTMLButtonElement>;
}) {
    const termsState = useSelector((state: RootState) => state.terms);

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={termsState?.isActiveModal}
            onRequestClose={closeTermModal}
            ariaHideApp={false}
        >
            {!!termsState?.isActiveModal && <Term close={closeTermModal} />}
        </Modal>
    );
}
