import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Alert from './Alert';
import useAlertModalLogic from '../../../../hooks/alert/useAlertModalLogic';
import * as ALERT from '../../../../constants/alert';

export default function AlertModal() {
    const alertModalLogic = useAlertModalLogic();
    const { alertState, closeModalAlert } = alertModalLogic;

    if (!ALERT.ALERT_TYPE_LIST.includes(alertState?.alertType)) return null;
    return (
        <Modal
            style={modalStyleProps({ zIndex: 99999 })}
            isOpen={!!alertState?.alertType?.length}
            onRequestClose={closeModalAlert}
            ariaHideApp={false}
        >
            <Alert {...alertModalLogic} />
        </Modal>
    );
}
