import Base from './Base';
import SITE from '../../config/site';
import { ICreateSupportData, ICreateSupportHelpData } from './SupportApi.interface';

export default class SupportApi extends Base {
    async postSupportRequest({
        messageContent,
        supportType,
        femaleExternalId,
        file,
        email,
        payment_total,
        giftActive,
    }: ICreateSupportData) {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('message_content', messageContent);
        formData.append('support_type', supportType);
        if (file) formData.append('files[]', file);
        formData.append('brand_url', SITE?.brandUrl);
        formData.append('payment_total', payment_total?.toString());
        formData.append('giftActive', giftActive?.toString());

        if (femaleExternalId) formData.append('female_external_id', femaleExternalId?.toString());

        const res = await super.post(`support/supportRequest`, formData);
        return res?.data;
    }

    async postHelp({
        messageContent,
        file,
        email,
        name,
        payment_total,
        giftActive,
    }: ICreateSupportHelpData) {
        const formData = new FormData();
        formData.append('message_content', messageContent);
        if (file) formData.append('files[]', file);
        formData.append('email', email);
        formData.append('name', name);
        formData.append('brand_url', SITE?.brandUrl);
        formData.append('payment_total', payment_total?.toString());
        formData.append('giftActive', giftActive?.toString());

        const res = await super.post(`support/help`, formData);
        return res?.data;
    }

    async getBrandInfo() {
        const res = await super.get(`support/brandInfo`);
        return res?.data;
    }

    async getZendeskToken() {
        const res = await super.get(`support/auth`);
        return res?.data;
    }
}
