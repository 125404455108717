import React, { useRef, useState } from 'react';
import '../../../../../styles/woman-profile.scss';
import useOutsideClicker from '../../../../../hooks/dom/useOutsideClicker';
import useHandleVideoShowItem from '../../../../../hooks/search/useHandleVideoShowItem';
import useHandleActiveBlocks from '../../../../../hooks/user/useHandleActiveBlocks';
import { IWomanVideoShowProps } from './index.interface';
import IconLikeHerHeart from '../../../../Icons/IconLikeHerHeart';
import IconLikeHerHeartStroke from '../../../../Icons/IconLikeHerHeartStroke';
import IconGiftSquare from '../../../../Icons/IconGiftSquare';
import IconLetters from '../../../../Icons/IconLetters';
import IconChat from '../../../../Icons/IconChat';

export default function WomanVideoShow({
    closeWomanVideoShowModal,
    videoLink,
    womanInfo,
}: IWomanVideoShowProps) {
    const blockMenuRef = useRef(null);

    const handleActiveBlocks = useHandleActiveBlocks();

    const [isOpenMenuBlock, setIsOpenMenuBlock] = useState(false);

    const { openChat, openLetter, setLike, openGifts } = useHandleVideoShowItem({
        profile: womanInfo,
    });
    useOutsideClicker(blockMenuRef, setIsOpenMenuBlock);

    return (
        <div className="popup_mp_videos active">
            {womanInfo?.external_id ? (
                <div className="popup_mp_videos_wrap">
                    <div className="popup_mp_video_temp">
                        {!!videoLink?.length && (
                            <div className="c3lh_your_likes_video_item">
                                <iframe
                                    title={'Iframe'}
                                    src={`https://player.vimeo.com/video/${videoLink.split('vimeo.com/')[1]?.split('/')[0]}/?autoplay=1`}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                />
                            </div>
                        )}
                    </div>
                    <div className="popup_mp_video_bottom">
                        <div className="popup_mp_video_profile">
                            <div
                                className={`popup_mp_video_profile_img ${womanInfo?.online ? 'online' : ''}`}
                            >
                                <img src={womanInfo?.avatar ?? ''} alt="Profile avatar" />
                            </div>
                            <div className="popup_mp_video_profile_info">
                                <div className="popup_mp_video_profile_name">
                                    {womanInfo?.name}, {womanInfo?.age}
                                </div>
                                <div className="popup_mp_video_profile_info_bottom">
                                    {!womanInfo?.hideContent && (
                                        <div className="popup_mp_video_profile_info_id">
                                            ID {womanInfo?.public_external_id || ''}
                                        </div>
                                    )}
                                    <div className="popup_mp_video_profile_info_place">
                                        <img src={womanInfo?.country_icon} alt="" />
                                        <span>
                                            {womanInfo?.city_name &&
                                            womanInfo?.city_name.toLowerCase() !== 'not specified'
                                                ? womanInfo?.city_name
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup_mp_video_chat" onClick={openChat}>
                            <IconChat />
                            <span>
                                Start chatting <span>with this member</span>
                            </span>
                            <div className="popup_mp_video_chat_mobile_text">Chat</div>
                        </div>
                        <div ref={blockMenuRef}>
                            <div
                                className={`popup_mp_video_buttons_mobile_open ${isOpenMenuBlock ? 'active' : ''}`}
                                onClick={() =>
                                    isOpenMenuBlock
                                        ? setIsOpenMenuBlock(false)
                                        : setIsOpenMenuBlock(true)
                                }
                            />
                            <div
                                className={`popup_mp_video_buttons ${isOpenMenuBlock ? 'active' : ''}`}
                            >
                                <div
                                    className="popup_mp_video_buttons_all"
                                    onClick={() => {
                                        if (!womanInfo?.liked) setLike();
                                    }}
                                >
                                    {womanInfo?.liked ? (
                                        <IconLikeHerHeart />
                                    ) : (
                                        <IconLikeHerHeartStroke />
                                    )}
                                    <span>Like</span>
                                </div>
                                <div className="popup_mp_video_buttons_all" onClick={openLetter}>
                                    <IconLetters />
                                    <span>Letter</span>
                                </div>
                                {!handleActiveBlocks.isHideGift && (
                                    <div className="popup_mp_video_buttons_all" onClick={openGifts}>
                                        <IconGiftSquare />
                                        <span>Gift</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div />
            )}
            <button
                type="button"
                className="popup_mp_videos_close"
                onClick={closeWomanVideoShowModal}
            ></button>
        </div>
    );
}
