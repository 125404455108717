import Base from '../Base';
import { IWomanLandingRes } from './WomanApi.interface';

export default class WomanApi extends Base {
    async getSimilarMan() {
        try {
            const res = await super.get(`v3/man/similar`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getSimilarWoman() {
        try {
            const res = await super.get(`v3/woman/similar`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getSimilarWomanProfile(womanExternalID: number) {
        try {
            const res = await super.get(`v3/woman/similar/profile/${womanExternalID}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getRandomWomanOnline(): Promise<IWomanLandingRes> {
        try {
            const res = await super.get(`v3/woman/landing/1`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
