import { PurchaseActionTypes } from '../../constants/purchase';
import AccountApi from '../../../services/api/Account';
import PaymentApi from '../../../services/api/v3/PaymentApi';
import { getActivePaymentMethodType } from '../../../services/methods/purchase';
import { AlertActionTypes } from '../../constants/alert';
import { Dispatch } from 'redux';
import {
    IPurchaseActiveSubscribeData,
    IPurchasePackageItem,
    IPurchaseTokenItem,
} from '../../reducers/purchase/index.interface';

export function setIsAddCard(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; status: boolean }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_IS_ADD_CARD,
            status,
        });
    };
}

export function setTokenList(tokenList: IPurchaseTokenItem[] = []) {
    return function (dispatch: Dispatch<{ type: string; tokenList: IPurchaseTokenItem[] }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_TOKEN_LIST,
            tokenList,
        });
    };
}

export function getPurchaseCreditsList() {
    return function (dispatch: Dispatch) {
        return new PaymentApi().getPaymentProposition().then((res) => {
            if (res?.status) {
                new AccountApi().postTokenList().then((res) => {
                    if (res?.status && Array.isArray(res?.list)) {
                        dispatch({
                            type: PurchaseActionTypes.SET_TOKEN_LIST,
                            tokenList: res?.list,
                        });
                    }
                });

                new PaymentApi().getPaymentMethods().then(async (resPayment) => {
                    const activePaymentMethodType = getActivePaymentMethodType(
                        resPayment?.data?.find((item: any) => !!item?.default && !!item?.status)
                            ?.method,
                        resPayment?.data,
                    );

                    return dispatch({
                        type: PurchaseActionTypes.GET_PURCHASE_LIST,
                        data: res?.data?.result,
                        vat: res?.data?.vat,
                        isReverse: res?.data?.isReverse,
                        mostPopularPackageID: res?.data?.mostPopularPackageID,
                        isFirstPurchase: res?.data?.isFirstPurchase,
                        count: await new PaymentApi().getPaymentCount().then((res) => res?.data),

                        activePaymentMethodList: Array.isArray(resPayment?.data)
                            ? activePaymentMethodType
                            : null,
                        paymentMethodList: Array.isArray(resPayment?.data)
                            ? resPayment?.data
                            : null,
                    });
                });
            } else {
                if (res?.data?.message) {
                    dispatch({
                        type: AlertActionTypes.SET_MESSAGE,
                        message: res?.data?.message,
                        title: res?.data?.title,
                    });
                    dispatch({
                        type: PurchaseActionTypes.REMOVE_PURCHASE_LIST,
                    });
                }
            }

            return res;
        });
    };
}

export function addLastBuyPackage(lastBuyPackage: IPurchasePackageItem) {
    return function (dispatch: Dispatch<{ type: string; lastBuyPackage: IPurchasePackageItem }>) {
        return dispatch({
            type: PurchaseActionTypes.ADD_LAST_BUY_PACKAGE,
            lastBuyPackage,
        });
    };
}

export function removeLastBuyPackage() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: PurchaseActionTypes.REMOVE_LAST_BUY_PACKAGE,
        });
    };
}

export function removePurchaseCreditsList() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: PurchaseActionTypes.REMOVE_PURCHASE_LIST,
        });
    };
}

export function initSubscribeStatus(
    activeSubscribeStatus: boolean,
    activeSubscribeData: IPurchaseActiveSubscribeData | null = null,
) {
    return function (
        dispatch: Dispatch<{
            type: string;
            activeSubscribeStatus: boolean;
            activeSubscribeData: IPurchaseActiveSubscribeData | null;
        }>,
    ) {
        return dispatch({
            type: PurchaseActionTypes.INIT_SUBSCRIBE_STATUS,
            activeSubscribeStatus,
            activeSubscribeData,
        });
    };
}

export function setActiveSubscribe(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; status: boolean }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_ACTIVE_SUBSCRIBE,
            status,
        });
    };
}

export function cancelActiveSubscribe() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: PurchaseActionTypes.CANCEL_ACTIVE_SUBSCRIBE,
        });
    };
}

export function getActiveSubscribeInfo(data = null) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: PurchaseActionTypes.GET_ACTIVE_SUBSCRIBE_LIST_INFO,
            data,
        });
    };
}

export function clearActiveSubscribeInfo() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: PurchaseActionTypes.CLEAR_ACTIVE_SUBSCRIBE_LIST_INFO,
        });
    };
}

export function setSuccessSubscribeModal(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; status: boolean }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_SUCCESS_SUBSCRIBE_MODAL,
            status,
        });
    };
}

export function setChangeSubscribeModal(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; status: boolean }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_CHANGE_SUBSCRIBE_MODAL,
            status,
        });
    };
}

export function setSuccessChangeSubscribeModal(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; status: boolean }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_SUCCESS_CHANGE_SUBSCRIBE_MODAL,
            status,
        });
    };
}

export function setIsChangeSubscription(status: boolean, credits: number | null = null) {
    return function (
        dispatch: Dispatch<{ type: string; status: boolean; credits: number | null }>,
    ) {
        return dispatch({
            type: PurchaseActionTypes.SET_IS_CHANGE_SUBSCRIPTION,
            status,
            credits,
        });
    };
}

export function setIsBuyingPastabank(status: boolean) {
    return function (dispatch: Dispatch<{ type: string; status: boolean }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_IS_BUYING_PASTABANK,
            status,
        });
    };
}

export function setIsPaymentFailed(status: boolean, packageBuyCount: number | null = null) {
    return function (
        dispatch: Dispatch<{ type: string; status: boolean; packageBuyCount: number | null }>,
    ) {
        return dispatch({
            type: PurchaseActionTypes.SET_IS_PAYMENT_FAILED,
            status,
            packageBuyCount,
        });
    };
}

export function setIsPastaPaymentFailed(status: boolean, errorMassage = '') {
    return function (dispatch: Dispatch<{ type: string; status: boolean; errorMassage: string }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_IS_PASTA_PAYMENT_FAILED,
            status,
            errorMassage,
        });
    };
}

export function setActivePackage(activePackage: IPurchasePackageItem) {
    return function (dispatch: Dispatch<{ type: string; activePackage: IPurchasePackageItem }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_ACTIVE_PACKAGE,
            activePackage,
        });
    };
}

export function setActivePaymentMethod(activePaymentMethod: string) {
    return function (dispatch: Dispatch<{ type: string; activePaymentMethod: string }>) {
        return dispatch({
            type: PurchaseActionTypes.SET_ACTIVE_PAYMENT_METHOD,
            activePaymentMethod,
        });
    };
}
