import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlParameter } from '../../utils/url-utils';
import { deleteUrlParameter } from '../../services/methods';
import SITE from '../../config/site';
import * as ALERT from '../../constants/alert';
import * as userAction from '../../store/actions/user';
import * as modalsAction from '../../store/actions/modals';
import * as alertAction from '../../store/actions/alert';
import useHandlePayment from '../purchase/useHandlePayment';
import { RootState } from '../../index';

export default function useAlertModalLogic() {
    const dispatch = useDispatch();
    const handlePayment = useHandlePayment();

    const alertState = useSelector((state: RootState) => state.alert);
    const userState = useSelector((state: RootState) => state.user);
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const closeModalAlert = () => dispatch(alertAction.closeAlertModal());

    const fixMessageContent = (message: string | unknown) => {
        if (typeof message !== 'string') return '';
        return message.split('\n').join(`<br/>`);
    };

    const isNewMail = userState?.info?.newMail;

    const onGetCredits = () => {
        closeModalAlert();

        dispatch(userAction.setCredit(true));
        dispatch(modalsAction.closeWomanVideoShowModal());
        dispatch(modalsAction.closeWomanProfileModal());

        handlePayment.onOpenPayment();
    };

    const onClickActiveBtn = () => {
        if (
            alertState?.alertType === ALERT.NEW_MESSAGE &&
            alertState?.title === 'Unavailable action'
        ) {
            if (userState?.info?.disabledUser) handlePayment.disabledUserCallback();
        }

        if (alertState?.successCallback) alertState?.successCallback();

        closeModalAlert();
    };

    const goToUserInbox = (email: string) => {
        if (!email?.length) return;
        const emailDomain = email.split('@')[1];

        if (emailDomain === 'gmail.com') {
            const googleInboxLink = `https://mail.google.com/mail/u/1/#search/from:${SITE.name}+in:anywhere`;
            window.open(googleInboxLink, '_blank');
        } else if (emailDomain === 'yahoo.com') {
            const yahooInboxLink = `https://mail.yahoo.com/d/search/keyword=from:${SITE.name}`;
            window.open(yahooInboxLink, '_blank');
        } else {
            const otherInboxLink = `https://${emailDomain}`;
            window.open(otherInboxLink, '_blank');
        }

        closeModalAlert();
    };

    useEffect(() => {
        return () => {
            const accessKey = getUrlParameter('accessKey');
            if (accessKey) deleteUrlParameter('accessKey');
        };
    }, []);

    return {
        alertState,
        closeModalAlert,
        fixMessageContent,
        onGetCredits,
        onClickActiveBtn,
        goToUserInbox,
        isNewMail,
        prices: userState?.prices,
        activeSubscribePaymentMethod: purchaseState?.activeSubscribePaymentMethod,
    };
}
