import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as mailboxAction from '../../../../../../store/actions/mailbox';
import * as alertAction from '../../../../../../store/actions/alert';
import * as ERROR from '../../../../../../constants/error';
import { RootState } from '../../../../../../index';
import { IFemaleListItem } from '../../../../../../store/reducers/mailbox/index.interface';
import avatar_placeholder from '../../../../../../img/placeholder.gif';

export default function Man({ item }: { item: IFemaleListItem }) {
    const dispatch = useDispatch();
    const mailboxState = useSelector((state: RootState) => state.mailbox);

    const handleChange = () => {
        if (item?.is_blocked) {
            return dispatch(alertAction.setMessage({ message: ERROR.MEMBER_IS_BLOCKED }));
        }

        if (mailboxState.checkedRecipients.find((find) => find.external_id === item.external_id)) {
            dispatch(mailboxAction.removeRecipient(item));
        } else {
            dispatch(mailboxAction.addRecipient(item));
        }
    };

    return (
        <div
            className={`popup_compose_man_item ${item?.online ? 'online' : ''}`}
            data-id={item.external_id}
            data-testid={`result-item-${item?.public_external_id}`}
            onClick={handleChange}
        >
            <input
                type="checkbox"
                name="pcmi-1"
                checked={
                    !!mailboxState.checkedRecipients.find(
                        (find) => +find.external_id === +item.external_id,
                    )
                }
                onChange={handleChange}
            />
            <label htmlFor="pcmi-1" className="popup_compose_man_item_label">
                <div className="popup_compose_man_item_photo" data-testid="item-photo">
                    <img src={item?.avatar || avatar_placeholder} alt="user avatar" />
                </div>
                <div className="popup_compose_man_item_right">
                    <div className="popup_compose_man_item_id" data-testid="item-external_id">
                        ID {item?.public_external_id}
                    </div>
                    <div
                        className="popup_compose_man_item_name"
                        data-testid="item-name"
                    >{`${item?.name}, ${item?.age}`}</div>
                    <div className="popup_compose_man_item_place" data-testid="item-city">
                        <img src={item.country_icon} alt="" />
                        {item?.city ? item?.city : ''}
                    </div>
                </div>
            </label>
        </div>
    );
}
