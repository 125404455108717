import ClickHistoryApi from '../api/v3/ClickHistoryApi';
import AffiseApi from '../api/AffiseApi';
import * as USER from '../../constants/user';
import { diffTimeInDaysWithCurrentDay } from '../time';
import { AFFISE_FIRST_PACK, AFFISE_FIRST_PACK_SALE_LESS_THEN_45 } from '../../constants/user';
import { ISendAffiseFromStore, IUserState } from '../../store/reducers/user/index.interface';
import { localStorageGetItem, localStorageSetItem } from '../../utils/local-storage';

export async function sendAfficeFromStore({ info, status }: ISendAffiseFromStore): Promise<void> {
    const isTestEmail = info?.email?.includes('test');
    if (isTestEmail) return;
    if (info?.external_id && status && !+info?.ghost) {
        const affiseClickIDRes = await new ClickHistoryApi()
            .postCheckAffiseClickID(info?.external_id)
            .catch(() => null);

        if (affiseClickIDRes?.status) {
            if (status === AFFISE_FIRST_PACK && +info?.age < 45 && +info?.age >= 30) {
                new AffiseApi()
                    .getAffise(
                        AFFISE_FIRST_PACK_SALE_LESS_THEN_45,
                        affiseClickIDRes?.status,
                        info?.external_id,
                    )
                    .catch(() => null);
            } else if (+info?.age >= USER.TRUE_USER_AGE) {
                new AffiseApi()
                    .getAffise(status, affiseClickIDRes?.status, info?.external_id)
                    .catch(() => null);
            }
        }
    }
}

export function diffAfficeByRegisterDate(userInfo: IUserState): void {
    if (!userInfo?.info) return;

    const diffDay: string = localStorageGetItem(`rd-${userInfo?.info?.external_id}`, true) || '';
    const is2Day = localStorageGetItem(`rdaf-2-${userInfo?.info?.external_id}`, true);
    const is6Day = localStorageGetItem(`rdaf-6-${userInfo?.info?.external_id}`, true);

    if (diffTimeInDaysWithCurrentDay(diffDay) >= 2 && !is2Day) {
        localStorageSetItem(`rdaf-2-${userInfo?.info?.external_id}`, '1');
        if (diffTimeInDaysWithCurrentDay(diffDay) === 2) {
            sendAfficeFromStore({
                status: USER.AFFISE_AFTER_REGISTER_2_DAY,
                info: userInfo?.info,
            });
        }
    }

    if (diffTimeInDaysWithCurrentDay(diffDay) >= 6 && !is6Day) {
        localStorageSetItem(`rdaf-6-${userInfo?.info?.external_id}`, '1');
        if (diffTimeInDaysWithCurrentDay(diffDay) === 6) {
            sendAfficeFromStore({
                status: USER.AFFISE_AFTER_REGISTER_6_DAY,
                info: userInfo?.info,
            });
        }
    }
}
