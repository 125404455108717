import { useEffect } from 'react';
import useDetectedDevice from '../device/useDetectedDevice';

export default function useBlockIphoneSideSwipe() {
    const { fullInfo } = useDetectedDevice();

    useEffect(() => {
        if (fullInfo()?.os.includes('iOS')) {
            const element = document.querySelector('#root');

            // TODO: need approve to remove
            if (element) {
                element?.addEventListener('touchstart', (event: Event) => {
                    // eslint-disable-next-line
                    // @ts-ignore
                    if (event?.pageX > 20) return;

                    event.preventDefault();
                    event.stopPropagation();
                });
            }
        }
    }, []);
}
