import Base from '../Base';
import { IQuizRequestData, IQuizRequestWithoutAuthData } from './QuizApi.interface';

export default class QuizApi extends Base {
    async getQuiz() {
        const res = await super.get(`v3/user-quiz/quiz`);
        return res?.data;
    }

    async getQuizByCategory(category: number) {
        try {
            const res = await super.get(`v3/user-quiz/quiz/${category}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async getQuizByCategoryWithoutAut(category: number) {
        try {
            const res = await super.get(`v3/user-quiz/quiz-without-auth/${category}`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async sendQuiz(data: IQuizRequestData) {
        const res = await super.post(`v3/user-quiz/answer`, data);
        return res?.data;
    }

    async sendQuizWithoutAuth(data: IQuizRequestWithoutAuthData) {
        const res = await super.post(`v3/user-quiz/answer-without-auth`, data);
        return res?.data;
    }
}
