import React, { useEffect } from 'react';
import SITE from '../../../../config/site';
import styles from './styles.module.scss';
import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';

Google.propTypes = {
    postResponseGoogle: PropTypes.func,
    shapeBtn: PropTypes.string,
};
export default function Google({
    postResponseGoogle,
    shapeBtn = 'rectangular',
}: {
    postResponseGoogle: (props: any) => void;
    shapeBtn?: string;
}) {
    const handleCallBackResponse = async (response: Record<string, any>) => {
        const userData = jwt_decode(response?.credential);
        postResponseGoogle(userData);
    };

    useEffect(() => {
        //Global google
        if (window.google && SITE?.googleClientId) {
            const typeDevice = window.innerWidth < 600 ? 'icon' : 'button';
            // @ts-expect-error window.google.accounts
            window.google.accounts.id.initialize({
                client_id: SITE?.googleClientId,
                callback: handleCallBackResponse,
            });

            // @ts-expect-error window.google.accounts
            window.google.accounts.id.renderButton(document.getElementById('signInDiv'), {
                theme: 'outline',
                size: 'large',
                type: typeDevice,
                locale: 'en',
                shape: shapeBtn,
            });

            // @ts-expect-error window.google.accounts
            window.google.accounts.id.prompt(); // also display the One Tap dialog
        }
    }, [window.google]);

    return <>{SITE.googleClientId && <div id="signInDiv" className={styles.new_google_btn} />}</>;
}
