import React from 'react';
import { useSelector } from 'react-redux';
import IconBookmark from '../../../Icons/IconBookmark';
import IconTrash from '../../../Icons/IconTrash';
import IconDrafts from '../../../Icons/IconDrafts';
import IconMailbox from '../../../Icons/IconMailbox';
import { RootState } from '../../../../index';

interface IFoldersProps {
    onSetLettersMenu: (value: string) => void;
}

export default function Folders({ onSetLettersMenu }: IFoldersProps) {
    const menuState = useSelector((state: RootState) => state.menu);
    const countersState = useSelector((state: RootState) => state.counters);

    return (
        <div className="c2l_letter_folders" data-testid="letter-menu">
            <div
                className={`c2l_letter_folder_item folder_inbox ${menuState.letters === 'inbox' ? 'active' : ''}`}
                data-testid="inbox"
                onClick={() => onSetLettersMenu('inbox')}
            >
                <div className="c2l_letter_folder_item_left">
                    <IconMailbox />
                    <span>Mailbox</span>
                </div>
                {!!countersState?.letterCounter && (
                    <div className="c2l_letter_folder_item_count">New</div>
                )}
            </div>
            <div
                className={`c2l_letter_folder_item ${menuState.letters === 'drafts' ? 'active' : ''}`}
                data-testid="drafts"
                onClick={() => onSetLettersMenu('drafts')}
            >
                <div className="c2l_letter_folder_item_left">
                    <IconDrafts />
                    <span>Drafts</span>
                </div>
            </div>
            <div
                className={`c2l_letter_folder_item ${menuState.letters === 'trash' ? 'active' : ''}`}
                data-testid="trash"
                onClick={() => onSetLettersMenu('trash')}
            >
                <div className="c2l_letter_folder_item_left">
                    <IconTrash />
                    <span>Trash</span>
                </div>
            </div>
            <div
                className={`c2l_letter_folder_item ${menuState.letters === 'bookmarks' ? 'active' : ''}`}
                data-testid="bookmarks"
                onClick={() => onSetLettersMenu('bookmarks')}
            >
                <div className="c2l_letter_folder_item_left">
                    <IconBookmark />
                    <span>Bookmarks</span>
                </div>
            </div>
        </div>
    );
}
