import React from 'react';

export default function IconMeetRequest() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29892 3.25C3.07281 3.25 1.2512 5.0341 1.25104 7.25656L1.25104 7.25671V7.25686L1.25 9.93456C1.24992 10.1335 1.32891 10.3244 1.46957 10.4651C1.61023 10.6058 1.80104 10.6849 2 10.6849C2.84009 10.6849 3.42668 11.2716 3.42668 12.001C3.42668 12.7687 2.80034 13.4006 2 13.4006C1.58579 13.4006 1.25 13.7364 1.25 14.1506V16.7431C1.25 18.9656 3.07047 20.75 5.29787 20.75H18.7021C20.9295 20.75 22.75 18.9656 22.75 16.7431V14.1506C22.75 13.7364 22.4142 13.4006 22 13.4006C21.1997 13.4006 20.5733 12.7687 20.5733 12.001C20.5733 11.2329 21.2001 10.6004 22 10.6004C22.199 10.6004 22.3898 10.5213 22.5304 10.3806C22.6711 10.2399 22.7501 10.0491 22.75 9.85009L22.749 7.25686V7.25655C22.7488 5.0341 20.9272 3.25 18.7011 3.25H5.29892ZM2.75104 7.25686C2.75104 5.88064 3.88307 4.75 5.29892 4.75H13.0997V6.67024C13.0997 7.08446 13.4355 7.42024 13.8497 7.42024C14.2639 7.42024 14.5997 7.08446 14.5997 6.67024V4.75H18.7011C20.1169 4.75 21.249 5.88064 21.249 7.25686V7.25716L21.2497 9.19628C19.9995 9.5229 19.0733 10.6472 19.0733 12.001C19.0733 13.3553 19.9999 14.4786 21.25 14.8049V16.7431C21.25 18.1195 20.1189 19.25 18.7021 19.25H14.5997V17.76C14.5997 17.3458 14.2639 17.01 13.8497 17.01C13.4355 17.01 13.0997 17.3458 13.0997 17.76V19.25H5.29787C3.88114 19.25 2.75 18.1195 2.75 16.7431V14.8049C4.00011 14.4786 4.92668 13.3553 4.92668 12.001C4.92668 10.6199 3.9792 9.57965 2.75026 9.27572L2.75104 7.25715L2.75104 7.25686ZM13.8497 8.75354C14.2639 8.75354 14.5997 9.08933 14.5997 9.50354V14.3245C14.5997 14.7388 14.2639 15.0745 13.8497 15.0745C13.4355 15.0745 13.0997 14.7388 13.0997 14.3245V9.50354C13.0997 9.08933 13.4355 8.75354 13.8497 8.75354Z"
            />
        </svg>
    );
}
