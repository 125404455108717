import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSendByConfigType from './useSendByConfigType';
import * as TIMER from '../../constants/timer';
import { diffTimeInHours, getStartDate } from '../../services/time';
import useTransactionConfig from './useTransactionConfig';
import { RootState } from '../../index';

export default function useInitConfig() {
    const sendByConfigType = useSendByConfigType();
    const transactionConfig = useTransactionConfig();

    const transactionState = useSelector((state: RootState) => state.transaction);
    const userState = useSelector((state: RootState) => state.user);

    const [storeTimerID, setStoreTimerID] = useState<any>(0);

    const defaultCheck = () => !!+userState?.abtest && !userState?.info?.ghost;

    useEffect(() => {
        if (userState?.info?.external_id) {
            const timer = setInterval(() => {
                const checkLTV = `check-1-${userState?.info?.external_id}`;
                const checkLTV10 = `check-2-${userState?.info?.external_id}`;
                const storageCheckLTV = JSON.parse(localStorage.getItem(checkLTV) as string);
                const storageCheckLTV10 = JSON.parse(localStorage.getItem(checkLTV10) as string);

                function check10MinLTV() {
                    if (!storageCheckLTV10 || diffTimeInHours(new Date(), storageCheckLTV10) > 24) {
                        localStorage.setItem(checkLTV10, JSON.stringify(getStartDate()));
                        transactionConfig.fetchTransactionConfig();
                    }
                }

                if (!storageCheckLTV) {
                    localStorage.setItem(checkLTV, JSON.stringify(getStartDate()));
                    check10MinLTV();
                } else if (storageCheckLTV) {
                    setTimeout(() => check10MinLTV(), TIMER.CHECK_LTV);

                    if (diffTimeInHours(new Date(), storageCheckLTV) > 0) {
                        transactionConfig.fetchTransactionConfig();
                        localStorage.setItem(checkLTV, JSON.stringify(getStartDate()));
                    }
                }
            }, TIMER.CHECK_LTV);

            return () => {
                clearInterval(timer);
            };
        }
    }, [userState.info]);

    useEffect(() => {
        if (defaultCheck() && transactionState?.transaction?.type) {
            clearTimeout(storeTimerID);
            setStoreTimerID(sendByConfigType());
        }
    }, [transactionState?.transaction]);
}
