import SITE from '../config/site';

//==========imports-sticker-heart
import sticker_heart_arrow from '../img/stickers/heart/sticker-heart-arrow.svg';
import sticker_heart_broken from '../img/stickers/heart/sticker-heart-broken.svg';
import sticker_heart_confused from '../img/stickers/heart/sticker-heart-confused.svg';
import sticker_heart_cry from '../img/stickers/heart/sticker-heart-cry.svg';
import sticker_heart_hi from '../img/stickers/heart/sticker-heart-hi.svg';
import sticker_heart_hug from '../img/stickers/heart/sticker-heart-hug.svg';
import sticker_heart_laugh from '../img/stickers/heart/sticker-heart-laugh.svg';
import sticker_heart_like from '../img/stickers/heart/sticker-heart-like.svg';
import sticker_heart_love from '../img/stickers/heart/sticker-heart-love.svg';
import sticker_heart_sad from '../img/stickers/heart/sticker-heart-sad.svg';
//==========imports-sticker-guy
import sticker_guy_angry from '../img/stickers/guy/sticker-guy-angry.svg';
import sticker_guy_confused from '../img/stickers/guy/sticker-guy-confused.svg';
import sticker_guy_cool from '../img/stickers/guy/sticker-guy-cool.svg';
import sticker_guy_happy from '../img/stickers/guy/sticker-guy-happy.svg';
import sticker_guy_kiss from '../img/stickers/guy/sticker-guy-kiss.svg';
import sticker_guy_like from '../img/stickers/guy/sticker-guy-like.svg';
import sticker_guy_please from '../img/stickers/guy/sticker-guy-please.svg';
import sticker_guy_roll_eyes from '../img/stickers/guy/sticker-guy-roll-eyes.svg';
import sticker_guy_sleep from '../img/stickers/guy/sticker-guy-sleep.svg';
import sticker_guy_strong from '../img/stickers/guy/sticker-guy-strong.svg';
import sticker_guy_surprise from '../img/stickers/guy/sticker-guy-surprize.svg';
import sticker_guy_think from '../img/stickers/guy/sticker-guy-think.svg';
import sticker_guy_wave from '../img/stickers/guy/sticker-guy-wave.svg';
//==========imports-sticker-animal
import sticker_animal_1 from '../img/stickers/animal/sticker-animal-1.svg';
import sticker_animal_2 from '../img/stickers/animal/sticker-animal-2.svg';
import sticker_animal_3 from '../img/stickers/animal/sticker-animal-3.svg';
import sticker_animal_4 from '../img/stickers/animal/sticker-animal-4.svg';
import sticker_animal_5 from '../img/stickers/animal/sticker-animal-5.svg';
import sticker_animal_6 from '../img/stickers/animal/sticker-animal-6.svg';
import sticker_animal_7 from '../img/stickers/animal/sticker-animal-7.svg';
import sticker_animal_8 from '../img/stickers/animal/sticker-animal-8.svg';
import sticker_animal_9 from '../img/stickers/animal/sticker-animal-9.svg';
import sticker_animal_10 from '../img/stickers/animal/sticker-animal-10.svg';
//==========imports-sticker-tiger
import sticker_tiger_1 from '../img/stickers/tiger/sticker-tiger-1.png';
import sticker_tiger_2 from '../img/stickers/tiger/sticker-tiger-2.png';
import sticker_tiger_3 from '../img/stickers/tiger/sticker-tiger-3.png';
import sticker_tiger_4 from '../img/stickers/tiger/sticker-tiger-4.png';
import sticker_tiger_5 from '../img/stickers/tiger/sticker-tiger-5.png';
import sticker_tiger_6 from '../img/stickers/tiger/sticker-tiger-6.png';
import sticker_tiger_7 from '../img/stickers/tiger/sticker-tiger-7.png';
import sticker_tiger_8 from '../img/stickers/tiger/sticker-tiger-8.png';
import sticker_tiger_9 from '../img/stickers/tiger/sticker-tiger-9.png';
import sticker_tiger_10 from '../img/stickers/tiger/sticker-tiger-10.png';
//==========imports-sticker-girl
import sticker_girl_angry from '../img/stickers/girl/sticker-girl-angry.svg';
import sticker_girl_confuse from '../img/stickers/girl/sticker-girl-confuse.svg';
import sticker_girl_cry from '../img/stickers/girl/sticker-girl-cry.svg';
import sticker_girl_happy from '../img/stickers/girl/sticker-girl-happy.svg';
import sticker_girl_kiss from '../img/stickers/girl/sticker-girl-kiss.svg';
import sticker_girl_like from '../img/stickers/girl/sticker-girl-like.svg';
import sticker_girl_please from '../img/stickers/girl/sticker-girl-please.svg';
import sticker_girl_roll_eyes from '../img/stickers/girl/sticker-girl-roll-eyes.svg';
import sticker_girl_sleep from '../img/stickers/girl/sticker-girl-sleep.svg';
import sticker_girl_surprise from '../img/stickers/girl/sticker-girl-surprize.svg';
import sticker_girl_think from '../img/stickers/girl/sticker-girl-think.svg';
import sticker_girl_wave from '../img/stickers/girl/sticker-girl-wave.svg';
//==========imports-sticker-type1
import sticker_type1_1 from '../img/stickers/type1/sticker-type1-1.svg';
import sticker_type1_2 from '../img/stickers/type1/sticker-type1-2.svg';
import sticker_type1_3 from '../img/stickers/type1/sticker-type1-3.svg';
import sticker_type1_4 from '../img/stickers/type1/sticker-type1-4.svg';
import sticker_type1_5 from '../img/stickers/type1/sticker-type1-5.svg';
import sticker_type1_6 from '../img/stickers/type1/sticker-type1-6.svg';
import sticker_type1_7 from '../img/stickers/type1/sticker-type1-7.svg';
import sticker_type1_8 from '../img/stickers/type1/sticker-type1-8.svg';
import sticker_type1_9 from '../img/stickers/type1/sticker-type1-9.svg';
import sticker_type1_10 from '../img/stickers/type1/sticker-type1-10.svg';
//==========imports-sticker-type2
import sticker_type2_1 from '../img/stickers/type2/sticker-type2-1.svg';
import sticker_type2_2 from '../img/stickers/type2/sticker-type2-2.svg';
import sticker_type2_3 from '../img/stickers/type2/sticker-type2-3.svg';
import sticker_type2_4 from '../img/stickers/type2/sticker-type2-4.svg';
import sticker_type2_5 from '../img/stickers/type2/sticker-type2-5.svg';
import sticker_type2_6 from '../img/stickers/type2/sticker-type2-6.svg';
import sticker_type2_7 from '../img/stickers/type2/sticker-type2-7.svg';
import sticker_type2_8 from '../img/stickers/type2/sticker-type2-8.svg';
import sticker_type2_9 from '../img/stickers/type2/sticker-type2-9.svg';
import sticker_type2_10 from '../img/stickers/type2/sticker-type2-10.svg';
//==========imports-sticker-type3
import sticker_type3_1 from '../img/stickers/type3/sticker-type3-1.svg';
import sticker_type3_2 from '../img/stickers/type3/sticker-type3-2.svg';
import sticker_type3_3 from '../img/stickers/type3/sticker-type3-3.svg';
import sticker_type3_4 from '../img/stickers/type3/sticker-type3-4.svg';
import sticker_type3_5 from '../img/stickers/type3/sticker-type3-5.svg';
import sticker_type3_6 from '../img/stickers/type3/sticker-type3-6.svg';
import sticker_type3_7 from '../img/stickers/type3/sticker-type3-7.svg';
import sticker_type3_8 from '../img/stickers/type3/sticker-type3-8.svg';
import sticker_type3_9 from '../img/stickers/type3/sticker-type3-9.svg';
import sticker_type3_10 from '../img/stickers/type3/sticker-type3-10.svg';
//==========imports-sticker-type4
import sticker_type4_1 from '../img/stickers/type4/sticker-type4-1.svg';
import sticker_type4_2 from '../img/stickers/type4/sticker-type4-2.svg';
import sticker_type4_3 from '../img/stickers/type4/sticker-type4-3.svg';
import sticker_type4_4 from '../img/stickers/type4/sticker-type4-4.svg';
import sticker_type4_5 from '../img/stickers/type4/sticker-type4-5.svg';
import sticker_type4_6 from '../img/stickers/type4/sticker-type4-6.svg';
import sticker_type4_7 from '../img/stickers/type4/sticker-type4-7.svg';
import sticker_type4_8 from '../img/stickers/type4/sticker-type4-8.svg';
import sticker_type4_9 from '../img/stickers/type4/sticker-type4-9.svg';
import sticker_type4_10 from '../img/stickers/type4/sticker-type4-10.svg';
//==========imports-sticker-type5
import sticker_type5_1 from '../img/stickers/type5/sticker-type5-1.svg';
import sticker_type5_2 from '../img/stickers/type5/sticker-type5-2.svg';
import sticker_type5_3 from '../img/stickers/type5/sticker-type5-3.svg';
import sticker_type5_4 from '../img/stickers/type5/sticker-type5-4.svg';
import sticker_type5_5 from '../img/stickers/type5/sticker-type5-5.svg';
import sticker_type5_6 from '../img/stickers/type5/sticker-type5-6.svg';
import sticker_type5_7 from '../img/stickers/type5/sticker-type5-7.svg';
import sticker_type5_8 from '../img/stickers/type5/sticker-type5-8.svg';
import sticker_type5_9 from '../img/stickers/type5/sticker-type5-9.svg';
import sticker_type5_10 from '../img/stickers/type5/sticker-type5-10.svg';
//==========imports-sticker-type6
import sticker_type6_1 from '../img/stickers/type6/sticker-type6-1.svg';
import sticker_type6_2 from '../img/stickers/type6/sticker-type6-2.svg';
import sticker_type6_3 from '../img/stickers/type6/sticker-type6-3.svg';
import sticker_type6_4 from '../img/stickers/type6/sticker-type6-4.svg';
import sticker_type6_5 from '../img/stickers/type6/sticker-type6-5.svg';
import sticker_type6_6 from '../img/stickers/type6/sticker-type6-6.svg';
import sticker_type6_7 from '../img/stickers/type6/sticker-type6-7.svg';
import sticker_type6_8 from '../img/stickers/type6/sticker-type6-8.svg';
import sticker_type6_9 from '../img/stickers/type6/sticker-type6-9.svg';
import sticker_type6_10 from '../img/stickers/type6/sticker-type6-10.svg';
//==========imports-sticker-type7
import sticker_type7_1 from '../img/stickers/type7/sticker-type7-1.svg';
import sticker_type7_2 from '../img/stickers/type7/sticker-type7-2.svg';
import sticker_type7_3 from '../img/stickers/type7/sticker-type7-3.svg';
import sticker_type7_4 from '../img/stickers/type7/sticker-type7-4.svg';
import sticker_type7_5 from '../img/stickers/type7/sticker-type7-5.svg';
import sticker_type7_6 from '../img/stickers/type7/sticker-type7-6.svg';
import sticker_type7_7 from '../img/stickers/type7/sticker-type7-7.svg';
import sticker_type7_8 from '../img/stickers/type7/sticker-type7-8.svg';
import sticker_type7_9 from '../img/stickers/type7/sticker-type7-9.svg';
import sticker_type7_10 from '../img/stickers/type7/sticker-type7-10.svg';
//==========imports-sticker-type8
import sticker_type8_1 from '../img/stickers/type8/sticker-type8-1.svg';
import sticker_type8_2 from '../img/stickers/type8/sticker-type8-2.svg';
import sticker_type8_3 from '../img/stickers/type8/sticker-type8-3.svg';
import sticker_type8_4 from '../img/stickers/type8/sticker-type8-4.svg';
import sticker_type8_5 from '../img/stickers/type8/sticker-type8-5.svg';
import sticker_type8_6 from '../img/stickers/type8/sticker-type8-6.svg';
import sticker_type8_7 from '../img/stickers/type8/sticker-type8-7.svg';
import sticker_type8_8 from '../img/stickers/type8/sticker-type8-8.svg';
import sticker_type8_9 from '../img/stickers/type8/sticker-type8-9.svg';
import sticker_type8_10 from '../img/stickers/type8/sticker-type8-10.svg';
//==========imports-sticker-type9
import sticker_type9_1 from '../img/stickers/type9/sticker-type9-1.svg';
import sticker_type9_2 from '../img/stickers/type9/sticker-type9-2.svg';
import sticker_type9_3 from '../img/stickers/type9/sticker-type9-3.svg';
import sticker_type9_4 from '../img/stickers/type9/sticker-type9-4.svg';
import sticker_type9_5 from '../img/stickers/type9/sticker-type9-5.svg';
import sticker_type9_6 from '../img/stickers/type9/sticker-type9-6.svg';
import sticker_type9_7 from '../img/stickers/type9/sticker-type9-7.svg';
import sticker_type9_8 from '../img/stickers/type9/sticker-type9-8.svg';
import sticker_type9_9 from '../img/stickers/type9/sticker-type9-9.svg';
import sticker_type9_10 from '../img/stickers/type9/sticker-type9-10.svg';
//==========sticker-type10
import sticker_type10_1 from '../img/stickers/type10/sticker-type10-1.svg';
import sticker_type10_2 from '../img/stickers/type10/sticker-type10-2.svg';
import sticker_type10_3 from '../img/stickers/type10/sticker-type10-3.svg';
import sticker_type10_4 from '../img/stickers/type10/sticker-type10-4.svg';
import sticker_type10_5 from '../img/stickers/type10/sticker-type10-5.svg';
import sticker_type10_6 from '../img/stickers/type10/sticker-type10-6.svg';
import sticker_type10_7 from '../img/stickers/type10/sticker-type10-7.svg';
import sticker_type10_8 from '../img/stickers/type10/sticker-type10-8.svg';
import sticker_type10_9 from '../img/stickers/type10/sticker-type10-9.svg';
import sticker_type10_10 from '../img/stickers/type10/sticker-type10-10.svg';

//==========sticker-heart
//type
export const STICKER_HEART_TYPE = 'heart';
//title
export const STICKER_HEART_TITLE = 'Hearts';
//name
export const STICKER_HEART_NAME_ARROW = 'sticker-heart-arrow';
export const STICKER_HEART_NAME_BROKEN = 'sticker-heart-broken';
export const STICKER_HEART_NAME_CONFUSED = 'sticker-heart-confused';
export const STICKER_HEART_NAME_CRY = 'sticker-heart-cry';
export const STICKER_HEART_NAME_HI = 'sticker-heart-hi';
export const STICKER_HEART_NAME_HUG = 'sticker-heart-hug';
export const STICKER_HEART_NAME_LAUGH = 'sticker-heart-laugh';
export const STICKER_HEART_NAME_LIKE = 'sticker-heart-like';
export const STICKER_HEART_NAME_LOVE = 'sticker-heart-love';
export const STICKER_HEART_NAME_SAD = 'sticker-heart-sad';
export const ARRAY_STICKER_HEART = [
    { name: STICKER_HEART_NAME_ARROW, image: sticker_heart_arrow },
    { name: STICKER_HEART_NAME_BROKEN, image: sticker_heart_broken },
    { name: STICKER_HEART_NAME_CONFUSED, image: sticker_heart_confused },
    { name: STICKER_HEART_NAME_CRY, image: sticker_heart_cry },
    { name: STICKER_HEART_NAME_HI, image: sticker_heart_hi },
    { name: STICKER_HEART_NAME_HUG, image: sticker_heart_hug },
    { name: STICKER_HEART_NAME_LAUGH, image: sticker_heart_laugh },
    { name: STICKER_HEART_NAME_LIKE, image: sticker_heart_like },
    { name: STICKER_HEART_NAME_LOVE, image: sticker_heart_love },
    { name: STICKER_HEART_NAME_SAD, image: sticker_heart_sad },
];

//==========sticker-guy
//type
export const STICKER_GUY_TYPE = 'guy';
//title
export const STICKER_GUY_TITLE = 'Mature man';
//name
export const STICKER_GUY_NAME_ANGRY = 'sticker-guy-angry';
export const STICKER_GUY_NAME_CONFUSED = 'sticker-guy-confused';
export const STICKER_GUY_NAME_COOL = 'sticker-guy-cool';
export const STICKER_GUY_NAME_HAPPY = 'sticker-guy-happy';
export const STICKER_GUY_NAME_KISS = 'sticker-guy-kiss';
export const STICKER_GUY_NAME_LIKE = 'sticker-guy-like';
export const STICKER_GUY_NAME_PLEASE = 'sticker-guy-please';
export const STICKER_GUY_NAME_ROLL_EYES = 'sticker-guy-roll-eyes';
export const STICKER_GUY_NAME_SLEEP = 'sticker-guy-sleep';
export const STICKER_GUY_NAME_STRONG = 'sticker-guy-strong';
export const STICKER_GUY_NAME_SURPRIZE = 'sticker-guy-surprize';
export const STICKER_GUY_NAME_THINK = 'sticker-guy-think';
export const STICKER_GUY_NAME_WAVE = 'sticker-guy-wave';
export const ARRAY_STICKER_GUY = [
    { name: STICKER_GUY_NAME_ANGRY, image: sticker_guy_angry },
    { name: STICKER_GUY_NAME_CONFUSED, image: sticker_guy_confused },
    { name: STICKER_GUY_NAME_COOL, image: sticker_guy_cool },
    { name: STICKER_GUY_NAME_HAPPY, image: sticker_guy_happy },
    { name: STICKER_GUY_NAME_KISS, image: sticker_guy_kiss },
    { name: STICKER_GUY_NAME_LIKE, image: sticker_guy_like },
    { name: STICKER_GUY_NAME_PLEASE, image: sticker_guy_please },
    { name: STICKER_GUY_NAME_ROLL_EYES, image: sticker_guy_roll_eyes },
    { name: STICKER_GUY_NAME_SLEEP, image: sticker_guy_sleep },
    { name: STICKER_GUY_NAME_STRONG, image: sticker_guy_strong },
    { name: STICKER_GUY_NAME_SURPRIZE, image: sticker_guy_surprise },
    { name: STICKER_GUY_NAME_THINK, image: sticker_guy_think },
    { name: STICKER_GUY_NAME_WAVE, image: sticker_guy_wave },
];

//==========sticker-animal
//type
export const STICKER_ANIMAL_TYPE = 'animal';
//title
export const STICKER_ANIMAL_TITLE = 'Animal';
//name
export const STICKER_ANIMAL_NAME_1 = 'sticker-animal-1';
export const STICKER_ANIMAL_NAME_2 = 'sticker-animal-2';
export const STICKER_ANIMAL_NAME_3 = 'sticker-animal-3';
export const STICKER_ANIMAL_NAME_4 = 'sticker-animal-4';
export const STICKER_ANIMAL_NAME_5 = 'sticker-animal-5';
export const STICKER_ANIMAL_NAME_6 = 'sticker-animal-6';
export const STICKER_ANIMAL_NAME_7 = 'sticker-animal-7';
export const STICKER_ANIMAL_NAME_8 = 'sticker-animal-8';
export const STICKER_ANIMAL_NAME_9 = 'sticker-animal-9';
export const STICKER_ANIMAL_NAME_10 = 'sticker-animal-10';
export const ARRAY_STICKER_ANIMAL = [
    { name: STICKER_ANIMAL_NAME_1, image: sticker_animal_1 },
    { name: STICKER_ANIMAL_NAME_2, image: sticker_animal_2 },
    { name: STICKER_ANIMAL_NAME_3, image: sticker_animal_3 },
    { name: STICKER_ANIMAL_NAME_4, image: sticker_animal_4 },
    { name: STICKER_ANIMAL_NAME_5, image: sticker_animal_5 },
    { name: STICKER_ANIMAL_NAME_6, image: sticker_animal_6 },
    { name: STICKER_ANIMAL_NAME_7, image: sticker_animal_7 },
    { name: STICKER_ANIMAL_NAME_8, image: sticker_animal_8 },
    { name: STICKER_ANIMAL_NAME_9, image: sticker_animal_9 },
    { name: STICKER_ANIMAL_NAME_10, image: sticker_animal_10 },
];

//==========sticker-tiger
//type
export const STICKER_TIGER_TYPE = 'tiger';
//title
export const STICKER_TIGER_TITLE = 'Tiger';
//name
export const STICKER_TIGER_NAME_1 = 'sticker-tiger-1';
export const STICKER_TIGER_NAME_2 = 'sticker-tiger-2';
export const STICKER_TIGER_NAME_3 = 'sticker-tiger-3';
export const STICKER_TIGER_NAME_4 = 'sticker-tiger-4';
export const STICKER_TIGER_NAME_5 = 'sticker-tiger-5';
export const STICKER_TIGER_NAME_6 = 'sticker-tiger-6';
export const STICKER_TIGER_NAME_7 = 'sticker-tiger-7';
export const STICKER_TIGER_NAME_8 = 'sticker-tiger-8';
export const STICKER_TIGER_NAME_9 = 'sticker-tiger-9';
export const STICKER_TIGER_NAME_10 = 'sticker-tiger-10';
export const ARRAY_STICKER_TIGER = [
    { name: STICKER_TIGER_NAME_1, image: sticker_tiger_1 },
    { name: STICKER_TIGER_NAME_2, image: sticker_tiger_2 },
    { name: STICKER_TIGER_NAME_3, image: sticker_tiger_3 },
    { name: STICKER_TIGER_NAME_4, image: sticker_tiger_4 },
    { name: STICKER_TIGER_NAME_5, image: sticker_tiger_5 },
    { name: STICKER_TIGER_NAME_6, image: sticker_tiger_6 },
    { name: STICKER_TIGER_NAME_7, image: sticker_tiger_7 },
    { name: STICKER_TIGER_NAME_8, image: sticker_tiger_8 },
    { name: STICKER_TIGER_NAME_9, image: sticker_tiger_9 },
    { name: STICKER_TIGER_NAME_10, image: sticker_tiger_10 },
];

//==========sticker-girl
//type
export const STICKER_GIRL_TYPE = 'girl';
//title
export const STICKER_GIRL_TITLE = 'Cute girl';
//name
export const STICKER_GIRL_NAME_ANGRY = 'sticker-girl-angry';
export const STICKER_GIRL_NAME_CONFUSE = 'sticker-girl-confuse';
export const STICKER_GIRL_NAME_CRY = 'sticker-girl-cry';
export const STICKER_GIRL_NAME_HAPPY = 'sticker-girl-happy';
export const STICKER_GIRL_NAME_KISS = 'sticker-girl-kiss';
export const STICKER_GIRL_NAME_LIKE = 'sticker-girl-like';
export const STICKER_GIRL_NAME_PLEASE = 'sticker-girl-please';
export const STICKER_GIRL_NAME_ROLL_EYES = 'sticker-girl-roll-eyes';
export const STICKER_GIRL_NAME_SLEEP = 'sticker-girl-sleep';
export const STICKER_GIRL_NAME_SURPRIZE = 'sticker-girl-surprize';
export const STICKER_GIRL_NAME_THINK = 'sticker-girl-think';
export const STICKER_GIRL_NAME_WAVE = 'sticker-girl-wave';
export const ARRAY_STICKER_GIRL = [
    { name: STICKER_GIRL_NAME_ANGRY, image: sticker_girl_angry },
    { name: STICKER_GIRL_NAME_CONFUSE, image: sticker_girl_confuse },
    { name: STICKER_GIRL_NAME_CRY, image: sticker_girl_cry },
    { name: STICKER_GIRL_NAME_HAPPY, image: sticker_girl_happy },
    { name: STICKER_GIRL_NAME_KISS, image: sticker_girl_kiss },
    { name: STICKER_GIRL_NAME_LIKE, image: sticker_girl_like },
    { name: STICKER_GIRL_NAME_PLEASE, image: sticker_girl_please },
    { name: STICKER_GIRL_NAME_ROLL_EYES, image: sticker_girl_roll_eyes },
    { name: STICKER_GIRL_NAME_SLEEP, image: sticker_girl_sleep },
    { name: STICKER_GIRL_NAME_SURPRIZE, image: sticker_girl_surprise },
    { name: STICKER_GIRL_NAME_THINK, image: sticker_girl_think },
    { name: STICKER_GIRL_NAME_WAVE, image: sticker_girl_wave },
];

//==========sticker-type1
//type
export const STICKER_TYPE1_TYPE = 'type1';
//title
export const STICKER_TYPE1_TITLE = 'Whishes';
//name
export const STICKER_TYPE1_NAME_1 = 'sticker-type1-1';
export const STICKER_TYPE1_NAME_2 = 'sticker-type1-2';
export const STICKER_TYPE1_NAME_3 = 'sticker-type1-3';
export const STICKER_TYPE1_NAME_4 = 'sticker-type1-4';
export const STICKER_TYPE1_NAME_5 = 'sticker-type1-5';
export const STICKER_TYPE1_NAME_6 = 'sticker-type1-6';
export const STICKER_TYPE1_NAME_7 = 'sticker-type1-7';
export const STICKER_TYPE1_NAME_8 = 'sticker-type1-8';
export const STICKER_TYPE1_NAME_9 = 'sticker-type1-9';
export const STICKER_TYPE1_NAME_10 = 'sticker-type1-10';
export const ARRAY_STICKER_TYPE1 = [
    { name: STICKER_TYPE1_NAME_1, image: sticker_type1_1 },
    { name: STICKER_TYPE1_NAME_2, image: sticker_type1_2 },
    { name: STICKER_TYPE1_NAME_3, image: sticker_type1_3 },
    { name: STICKER_TYPE1_NAME_4, image: sticker_type1_4 },
    { name: STICKER_TYPE1_NAME_5, image: sticker_type1_5 },
    { name: STICKER_TYPE1_NAME_6, image: sticker_type1_6 },
    { name: STICKER_TYPE1_NAME_7, image: sticker_type1_7 },
    { name: STICKER_TYPE1_NAME_8, image: sticker_type1_8 },
    { name: STICKER_TYPE1_NAME_9, image: sticker_type1_9 },
    { name: STICKER_TYPE1_NAME_10, image: sticker_type1_10 },
];

//==========sticker-type2
//type
export const STICKER_TYPE2_TYPE = 'type2';
//title
export const STICKER_TYPE2_TITLE = 'Reactions';
//name
export const STICKER_TYPE2_NAME_1 = 'sticker-type2-1';
export const STICKER_TYPE2_NAME_2 = 'sticker-type2-2';
export const STICKER_TYPE2_NAME_3 = 'sticker-type2-3';
export const STICKER_TYPE2_NAME_4 = 'sticker-type2-4';
export const STICKER_TYPE2_NAME_5 = 'sticker-type2-5';
export const STICKER_TYPE2_NAME_6 = 'sticker-type2-6';
export const STICKER_TYPE2_NAME_7 = 'sticker-type2-7';
export const STICKER_TYPE2_NAME_8 = 'sticker-type2-8';
export const STICKER_TYPE2_NAME_9 = 'sticker-type2-9';
export const STICKER_TYPE2_NAME_10 = 'sticker-type2-10';
export const ARRAY_STICKER_TYPE2 = [
    { name: STICKER_TYPE2_NAME_1, image: sticker_type2_1 },
    { name: STICKER_TYPE2_NAME_2, image: sticker_type2_2 },
    { name: STICKER_TYPE2_NAME_3, image: sticker_type2_3 },
    { name: STICKER_TYPE2_NAME_4, image: sticker_type2_4 },
    { name: STICKER_TYPE2_NAME_5, image: sticker_type2_5 },
    { name: STICKER_TYPE2_NAME_6, image: sticker_type2_6 },
    { name: STICKER_TYPE2_NAME_7, image: sticker_type2_7 },
    { name: STICKER_TYPE2_NAME_8, image: sticker_type2_8 },
    { name: STICKER_TYPE2_NAME_9, image: sticker_type2_9 },
    { name: STICKER_TYPE2_NAME_10, image: sticker_type2_10 },
];

//==========sticker-type3
//type
export const STICKER_TYPE3_TYPE = 'type3';
//title
export const STICKER_TYPE3_TITLE = 'Pets';
//name
export const STICKER_TYPE3_NAME_1 = 'sticker-type3-1';
export const STICKER_TYPE3_NAME_2 = 'sticker-type3-2';
export const STICKER_TYPE3_NAME_3 = 'sticker-type3-3';
export const STICKER_TYPE3_NAME_4 = 'sticker-type3-4';
export const STICKER_TYPE3_NAME_5 = 'sticker-type3-5';
export const STICKER_TYPE3_NAME_6 = 'sticker-type3-6';
export const STICKER_TYPE3_NAME_7 = 'sticker-type3-7';
export const STICKER_TYPE3_NAME_8 = 'sticker-type3-8';
export const STICKER_TYPE3_NAME_9 = 'sticker-type3-9';
export const STICKER_TYPE3_NAME_10 = 'sticker-type3-10';
export const ARRAY_STICKER_TYPE3 = [
    { name: STICKER_TYPE3_NAME_1, image: sticker_type3_1 },
    { name: STICKER_TYPE3_NAME_2, image: sticker_type3_2 },
    { name: STICKER_TYPE3_NAME_3, image: sticker_type3_3 },
    { name: STICKER_TYPE3_NAME_4, image: sticker_type3_4 },
    { name: STICKER_TYPE3_NAME_5, image: sticker_type3_5 },
    { name: STICKER_TYPE3_NAME_6, image: sticker_type3_6 },
    { name: STICKER_TYPE3_NAME_7, image: sticker_type3_7 },
    { name: STICKER_TYPE3_NAME_8, image: sticker_type3_8 },
    { name: STICKER_TYPE3_NAME_9, image: sticker_type3_9 },
    { name: STICKER_TYPE3_NAME_10, image: sticker_type3_10 },
];

//==========sticker-type4
//type
export const STICKER_TYPE4_TYPE = 'type4';
//title
export const STICKER_TYPE4_TITLE = 'Mood';
//name
export const STICKER_TYPE4_NAME_1 = 'sticker-type4-1';
export const STICKER_TYPE4_NAME_2 = 'sticker-type4-2';
export const STICKER_TYPE4_NAME_3 = 'sticker-type4-3';
export const STICKER_TYPE4_NAME_4 = 'sticker-type4-4';
export const STICKER_TYPE4_NAME_5 = 'sticker-type4-5';
export const STICKER_TYPE4_NAME_6 = 'sticker-type4-6';
export const STICKER_TYPE4_NAME_7 = 'sticker-type4-7';
export const STICKER_TYPE4_NAME_8 = 'sticker-type4-8';
export const STICKER_TYPE4_NAME_9 = 'sticker-type4-9';
export const STICKER_TYPE4_NAME_10 = 'sticker-type4-10';
export const ARRAY_STICKER_TYPE4 = [
    { name: STICKER_TYPE4_NAME_1, image: sticker_type4_1 },
    { name: STICKER_TYPE4_NAME_2, image: sticker_type4_2 },
    { name: STICKER_TYPE4_NAME_3, image: sticker_type4_3 },
    { name: STICKER_TYPE4_NAME_4, image: sticker_type4_4 },
    { name: STICKER_TYPE4_NAME_5, image: sticker_type4_5 },
    { name: STICKER_TYPE4_NAME_6, image: sticker_type4_6 },
    { name: STICKER_TYPE4_NAME_7, image: sticker_type4_7 },
    { name: STICKER_TYPE4_NAME_8, image: sticker_type4_8 },
    { name: STICKER_TYPE4_NAME_9, image: sticker_type4_9 },
    { name: STICKER_TYPE4_NAME_10, image: sticker_type4_10 },
];

//==========sticker-type5
//type
export const STICKER_TYPE5_TYPE = 'type5';
//title
export const STICKER_TYPE5_TITLE = 'Bee';
//name
export const STICKER_TYPE5_NAME_1 = 'sticker-type5-1';
export const STICKER_TYPE5_NAME_2 = 'sticker-type5-2';
export const STICKER_TYPE5_NAME_3 = 'sticker-type5-3';
export const STICKER_TYPE5_NAME_4 = 'sticker-type5-4';
export const STICKER_TYPE5_NAME_5 = 'sticker-type5-5';
export const STICKER_TYPE5_NAME_6 = 'sticker-type5-6';
export const STICKER_TYPE5_NAME_7 = 'sticker-type5-7';
export const STICKER_TYPE5_NAME_8 = 'sticker-type5-8';
export const STICKER_TYPE5_NAME_9 = 'sticker-type5-9';
export const STICKER_TYPE5_NAME_10 = 'sticker-type5-10';
export const ARRAY_STICKER_TYPE5 = [
    { name: STICKER_TYPE5_NAME_1, image: sticker_type5_1 },
    { name: STICKER_TYPE5_NAME_2, image: sticker_type5_2 },
    { name: STICKER_TYPE5_NAME_3, image: sticker_type5_3 },
    { name: STICKER_TYPE5_NAME_4, image: sticker_type5_4 },
    { name: STICKER_TYPE5_NAME_5, image: sticker_type5_5 },
    { name: STICKER_TYPE5_NAME_6, image: sticker_type5_6 },
    { name: STICKER_TYPE5_NAME_7, image: sticker_type5_7 },
    { name: STICKER_TYPE5_NAME_8, image: sticker_type5_8 },
    { name: STICKER_TYPE5_NAME_9, image: sticker_type5_9 },
    { name: STICKER_TYPE5_NAME_10, image: sticker_type5_10 },
];

//==========sticker-type6
//type
export const STICKER_TYPE6_TYPE = 'type6';
//title
export const STICKER_TYPE6_TITLE = 'Street Food';
//name
export const STICKER_TYPE6_NAME_1 = 'sticker-type6-1';
export const STICKER_TYPE6_NAME_2 = 'sticker-type6-2';
export const STICKER_TYPE6_NAME_3 = 'sticker-type6-3';
export const STICKER_TYPE6_NAME_4 = 'sticker-type6-4';
export const STICKER_TYPE6_NAME_5 = 'sticker-type6-5';
export const STICKER_TYPE6_NAME_6 = 'sticker-type6-6';
export const STICKER_TYPE6_NAME_7 = 'sticker-type6-7';
export const STICKER_TYPE6_NAME_8 = 'sticker-type6-8';
export const STICKER_TYPE6_NAME_9 = 'sticker-type6-9';
export const STICKER_TYPE6_NAME_10 = 'sticker-type6-10';
export const ARRAY_STICKER_TYPE6 = [
    { name: STICKER_TYPE6_NAME_1, image: sticker_type6_1 },
    { name: STICKER_TYPE6_NAME_2, image: sticker_type6_2 },
    { name: STICKER_TYPE6_NAME_3, image: sticker_type6_3 },
    { name: STICKER_TYPE6_NAME_4, image: sticker_type6_4 },
    { name: STICKER_TYPE6_NAME_5, image: sticker_type6_5 },
    { name: STICKER_TYPE6_NAME_6, image: sticker_type6_6 },
    { name: STICKER_TYPE6_NAME_7, image: sticker_type6_7 },
    { name: STICKER_TYPE6_NAME_8, image: sticker_type6_8 },
    { name: STICKER_TYPE6_NAME_9, image: sticker_type6_9 },
    { name: STICKER_TYPE6_NAME_10, image: sticker_type6_10 },
];

//==========sticker-type7
//type
export const STICKER_TYPE7_TYPE = 'type7';
//title
export const STICKER_TYPE7_TITLE = 'Koala Jim';
//name
export const STICKER_TYPE7_NAME_1 = 'sticker-type7-1';
export const STICKER_TYPE7_NAME_2 = 'sticker-type7-2';
export const STICKER_TYPE7_NAME_3 = 'sticker-type7-3';
export const STICKER_TYPE7_NAME_4 = 'sticker-type7-4';
export const STICKER_TYPE7_NAME_5 = 'sticker-type7-5';
export const STICKER_TYPE7_NAME_6 = 'sticker-type7-6';
export const STICKER_TYPE7_NAME_7 = 'sticker-type7-7';
export const STICKER_TYPE7_NAME_8 = 'sticker-type7-8';
export const STICKER_TYPE7_NAME_9 = 'sticker-type7-9';
export const STICKER_TYPE7_NAME_10 = 'sticker-type7-10';
export const ARRAY_STICKER_TYPE7 = [
    { name: STICKER_TYPE7_NAME_1, image: sticker_type7_1 },
    { name: STICKER_TYPE7_NAME_2, image: sticker_type7_2 },
    { name: STICKER_TYPE7_NAME_3, image: sticker_type7_3 },
    { name: STICKER_TYPE7_NAME_4, image: sticker_type7_4 },
    { name: STICKER_TYPE7_NAME_5, image: sticker_type7_5 },
    { name: STICKER_TYPE7_NAME_6, image: sticker_type7_6 },
    { name: STICKER_TYPE7_NAME_7, image: sticker_type7_7 },
    { name: STICKER_TYPE7_NAME_8, image: sticker_type7_8 },
    { name: STICKER_TYPE7_NAME_9, image: sticker_type7_9 },
    { name: STICKER_TYPE7_NAME_10, image: sticker_type7_10 },
];

//==========sticker-type8
//type
export const STICKER_TYPE8_TYPE = 'type8';
//title
export const STICKER_TYPE8_TITLE = 'Avocado';
//name
export const STICKER_TYPE8_NAME_1 = 'sticker-type8-1';
export const STICKER_TYPE8_NAME_2 = 'sticker-type8-2';
export const STICKER_TYPE8_NAME_3 = 'sticker-type8-3';
export const STICKER_TYPE8_NAME_4 = 'sticker-type8-4';
export const STICKER_TYPE8_NAME_5 = 'sticker-type8-5';
export const STICKER_TYPE8_NAME_6 = 'sticker-type8-6';
export const STICKER_TYPE8_NAME_7 = 'sticker-type8-7';
export const STICKER_TYPE8_NAME_8 = 'sticker-type8-8';
export const STICKER_TYPE8_NAME_9 = 'sticker-type8-9';
export const STICKER_TYPE8_NAME_10 = 'sticker-type8-10';
export const ARRAY_STICKER_TYPE8 = [
    { name: STICKER_TYPE8_NAME_1, image: sticker_type8_1 },
    { name: STICKER_TYPE8_NAME_2, image: sticker_type8_2 },
    { name: STICKER_TYPE8_NAME_3, image: sticker_type8_3 },
    { name: STICKER_TYPE8_NAME_4, image: sticker_type8_4 },
    { name: STICKER_TYPE8_NAME_5, image: sticker_type8_5 },
    { name: STICKER_TYPE8_NAME_6, image: sticker_type8_6 },
    { name: STICKER_TYPE8_NAME_7, image: sticker_type8_7 },
    { name: STICKER_TYPE8_NAME_8, image: sticker_type8_8 },
    { name: STICKER_TYPE8_NAME_9, image: sticker_type8_9 },
    { name: STICKER_TYPE8_NAME_10, image: sticker_type8_10 },
];

//==========sticker-type9
//type
export const STICKER_TYPE9_TYPE = 'type9';
//title
export const STICKER_TYPE9_TITLE = 'Love Heart';
//name
export const STICKER_TYPE9_NAME_1 = 'sticker-type9-1';
export const STICKER_TYPE9_NAME_2 = 'sticker-type9-2';
export const STICKER_TYPE9_NAME_3 = 'sticker-type9-3';
export const STICKER_TYPE9_NAME_4 = 'sticker-type9-4';
export const STICKER_TYPE9_NAME_5 = 'sticker-type9-5';
export const STICKER_TYPE9_NAME_6 = 'sticker-type9-6';
export const STICKER_TYPE9_NAME_7 = 'sticker-type9-7';
export const STICKER_TYPE9_NAME_8 = 'sticker-type9-8';
export const STICKER_TYPE9_NAME_9 = 'sticker-type9-9';
export const STICKER_TYPE9_NAME_10 = 'sticker-type9-10';
//image
//array
export const ARRAY_STICKER_TYPE9 = [
    { name: STICKER_TYPE9_NAME_1, image: sticker_type9_1 },
    { name: STICKER_TYPE9_NAME_2, image: sticker_type9_2 },
    { name: STICKER_TYPE9_NAME_3, image: sticker_type9_3 },
    { name: STICKER_TYPE9_NAME_4, image: sticker_type9_4 },
    { name: STICKER_TYPE9_NAME_5, image: sticker_type9_5 },
    { name: STICKER_TYPE9_NAME_6, image: sticker_type9_6 },
    { name: STICKER_TYPE9_NAME_7, image: sticker_type9_7 },
    { name: STICKER_TYPE9_NAME_8, image: sticker_type9_8 },
    { name: STICKER_TYPE9_NAME_9, image: sticker_type9_9 },
    { name: STICKER_TYPE9_NAME_10, image: sticker_type9_10 },
];

//==========sticker-type10
//type
export const STICKER_TYPE10_TYPE = 'type10';
//title
export const STICKER_TYPE10_TITLE = 'Tom Cat';
//name
export const STICKER_TYPE10_NAME_1 = 'sticker-type10-1';
export const STICKER_TYPE10_NAME_2 = 'sticker-type10-2';
export const STICKER_TYPE10_NAME_3 = 'sticker-type10-3';
export const STICKER_TYPE10_NAME_4 = 'sticker-type10-4';
export const STICKER_TYPE10_NAME_5 = 'sticker-type10-5';
export const STICKER_TYPE10_NAME_6 = 'sticker-type10-6';
export const STICKER_TYPE10_NAME_7 = 'sticker-type10-7';
export const STICKER_TYPE10_NAME_8 = 'sticker-type10-8';
export const STICKER_TYPE10_NAME_9 = 'sticker-type10-9';
export const STICKER_TYPE10_NAME_10 = 'sticker-type10-10';
export const ARRAY_STICKER_TYPE10 = [
    { name: STICKER_TYPE10_NAME_1, image: sticker_type10_1 },
    { name: STICKER_TYPE10_NAME_2, image: sticker_type10_2 },
    { name: STICKER_TYPE10_NAME_3, image: sticker_type10_3 },
    { name: STICKER_TYPE10_NAME_4, image: sticker_type10_4 },
    { name: STICKER_TYPE10_NAME_5, image: sticker_type10_5 },
    { name: STICKER_TYPE10_NAME_6, image: sticker_type10_6 },
    { name: STICKER_TYPE10_NAME_7, image: sticker_type10_7 },
    { name: STICKER_TYPE10_NAME_8, image: sticker_type10_8 },
    { name: STICKER_TYPE10_NAME_9, image: sticker_type10_9 },
    { name: STICKER_TYPE10_NAME_10, image: sticker_type10_10 },
];

//array all stickers ===================================================================
export const OBJ_ALL_STICKER = {
    [STICKER_HEART_TYPE]: { array: ARRAY_STICKER_HEART, title: STICKER_HEART_TITLE },
    [STICKER_GUY_TYPE]: { array: ARRAY_STICKER_GUY, title: STICKER_GUY_TITLE },
    [STICKER_ANIMAL_TYPE]: { array: ARRAY_STICKER_ANIMAL, title: STICKER_ANIMAL_TITLE },
    [STICKER_TIGER_TYPE]: { array: ARRAY_STICKER_TIGER, title: STICKER_TIGER_TITLE },
    [STICKER_TYPE1_TYPE]: { array: ARRAY_STICKER_TYPE1, title: STICKER_TYPE1_TITLE },
    [STICKER_TYPE2_TYPE]: { array: ARRAY_STICKER_TYPE2, title: STICKER_TYPE2_TITLE },
    [STICKER_TYPE3_TYPE]: { array: ARRAY_STICKER_TYPE3, title: STICKER_TYPE3_TITLE },
    [STICKER_TYPE4_TYPE]: { array: ARRAY_STICKER_TYPE4, title: STICKER_TYPE4_TITLE },
    [STICKER_TYPE5_TYPE]: { array: ARRAY_STICKER_TYPE5, title: STICKER_TYPE5_TITLE },
    [STICKER_TYPE6_TYPE]: { array: ARRAY_STICKER_TYPE6, title: STICKER_TYPE6_TITLE },
    [STICKER_TYPE7_TYPE]: { array: ARRAY_STICKER_TYPE7, title: STICKER_TYPE7_TITLE },
    [STICKER_TYPE8_TYPE]: { array: ARRAY_STICKER_TYPE8, title: STICKER_TYPE8_TITLE },
    [STICKER_TYPE9_TYPE]: { array: ARRAY_STICKER_TYPE9, title: STICKER_TYPE9_TITLE },
    [STICKER_TYPE10_TYPE]: { array: ARRAY_STICKER_TYPE10, title: STICKER_TYPE10_TITLE },
};

export const ARRAY_ALL_STICKER = [
    { stickerArray: ARRAY_STICKER_HEART, title: STICKER_HEART_TITLE },
    ...(SITE.ID !== 23 ? [{ stickerArray: ARRAY_STICKER_GUY, title: STICKER_GUY_TITLE }] : []),
    { stickerArray: ARRAY_STICKER_ANIMAL, title: STICKER_ANIMAL_TITLE },
    { stickerArray: ARRAY_STICKER_TIGER, title: STICKER_TIGER_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE1, title: STICKER_TYPE1_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE2, title: STICKER_TYPE2_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE3, title: STICKER_TYPE3_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE4, title: STICKER_TYPE4_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE5, title: STICKER_TYPE5_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE6, title: STICKER_TYPE6_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE7, title: STICKER_TYPE7_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE8, title: STICKER_TYPE8_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE9, title: STICKER_TYPE9_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE10, title: STICKER_TYPE10_TITLE },
];

//array all images stickers
export const ARRAY_ALL_IMAGES_STICKER = [
    ...ARRAY_STICKER_HEART,
    ...ARRAY_STICKER_GUY,
    ...ARRAY_STICKER_GIRL,
    ...ARRAY_STICKER_ANIMAL,
    ...ARRAY_STICKER_TIGER,
    ...ARRAY_STICKER_TYPE1,
    ...ARRAY_STICKER_TYPE2,
    ...ARRAY_STICKER_TYPE3,
    ...ARRAY_STICKER_TYPE4,
    ...ARRAY_STICKER_TYPE5,
    ...ARRAY_STICKER_TYPE6,
    ...ARRAY_STICKER_TYPE7,
    ...ARRAY_STICKER_TYPE8,
    ...ARRAY_STICKER_TYPE9,
    ...ARRAY_STICKER_TYPE10,
];
