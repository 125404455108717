import { NotifyActionTypes } from '../../constants/notify';
import * as SYNC from '../../../constants/sync';
import * as CHATS from '../../../constants/chats';
import { diffWithCurrentDate, diffWithCurrentTimeInSeconds } from '../../../services/time';
import {
    generationDateOnFewSecondsLonger,
    generationDateOnFewSecondsLongerWithoutUTC,
    timeDiffFromMin,
} from '../../../services/methods';
import { INotifyItem, INotifySettings, INotifyState, NotifyActions } from './index.interface';

function isActiveNotificationSettings(settings: INotifySettings): boolean {
    return (
        settings.MOBILE_NOTIFICATION_COUNT !== null &&
        settings.MOBILE_NOTIFICATION_ENABLED !== null &&
        settings.MOBILE_NOTIFICATION_SHOW_TIME !== null
    );
}

const initState = {
    list: [],
    transactionList: [],
    mobileBottomList: [],
    mobileList: [],
    mobileListTmp: [],
    listPage: 0,
    status: false,
    countUnread: 0,
    isActivePauseButton: true,
    settings: {
        MOBILE_NOTIFICATION_ENABLED: 1,
        MOBILE_NOTIFICATION_COUNT: 2,
        MOBILE_TEXT_NOTIFICATION_SHOW_TIME: 10,
        MOBILE_NOTIFICATION_SHOW_TIME: 5,
    },
    settingsBottom: {
        MOBILE_NOTIFICATION_ENABLED: 1,
        MOBILE_NOTIFICATION_COUNT: 6,
        MOBILE_NOTIFICATION_SHOW_TIME: 30,
    },
};

export default function (state: INotifyState = initState, action: NotifyActions): INotifyState {
    const expDate = localStorage.getItem('mob-banner-pause-to');

    switch (action.type) {
        case NotifyActionTypes.SET_SETTINGS_PUSH:
            return {
                ...state,
                ...{
                    settings: {
                        MOBILE_NOTIFICATION_ENABLED: +action.data.TOP_MOBILE_NOTIFICATION_ENABLED,
                        MOBILE_NOTIFICATION_COUNT: +action.data.TOP_MOBILE_NOTIFICATION_COUNT,
                        MOBILE_NOTIFICATION_SHOW_TIME:
                            +action.data.TOP_MOBILE_NOTIFICATION_SHOW_TIME,
                    },
                },
            };

        case NotifyActionTypes.SET_SETTINGS_BOTTOM:
            return {
                ...state,
                ...{
                    settingsBottom: {
                        MOBILE_NOTIFICATION_ENABLED:
                            +action.data.BOTTOM_MOBILE_NOTIFICATION_ENABLED,
                        MOBILE_NOTIFICATION_COUNT: +action.data.BOTTOM_MOBILE_NOTIFICATION_COUNT,
                        MOBILE_NOTIFICATION_SHOW_TIME:
                            +action.data.BOTTOM_MOBILE_NOTIFICATION_SHOW_TIME,
                    },
                },
            };

        case NotifyActionTypes.CHECK_TRANSACTION_NOTIFY:
            // eslint-disable-next-line no-case-declarations
            const filteredTrList = state.transactionList?.filter((item) =>
                diffWithCurrentTimeInSeconds(item?.expired_at),
            );

            if (state.transactionList?.length !== filteredTrList?.length) {
                return {
                    ...state,
                    ...{
                        transactionList: filteredTrList,
                    },
                };
            }
            return state;

        case NotifyActionTypes.CHECK_NOTIFY:
            // eslint-disable-next-line no-case-declarations
            const filteredList = state.list?.filter((item) =>
                diffWithCurrentTimeInSeconds(item?.date_expired.toString()),
            );

            if (state.list?.length !== filteredList?.length) {
                return {
                    ...state,
                    ...{
                        list: filteredList,
                    },
                };
            }
            return state;

        case NotifyActionTypes.CHECK_MOB_NOTIFY:
            // eslint-disable-next-line no-case-declarations
            const filteredListMob = state.mobileList?.filter(
                (item) => diffWithCurrentDate(item?.date_expired.toString()) < 0,
            );

            if (state.mobileList?.length !== filteredListMob?.length) {
                return {
                    ...state,
                    ...{
                        mobileList: filteredListMob,
                    },
                };
            }
            return {
                ...state,
                ...{
                    mobileList: state.mobileList?.filter((item) => !item?.hide),
                },
            };

        case NotifyActionTypes.CHECK_MOB_BOTTOM_NOTIFY:
            // eslint-disable-next-line no-case-declarations
            const filteredListMobBottom = state.mobileBottomList?.filter(
                (item) => diffWithCurrentDate(item?.date_expired.toString()) < 0,
            );

            if (state.mobileBottomList?.length !== filteredListMobBottom?.length) {
                return {
                    ...state,
                    ...{
                        mobileBottomList: filteredListMobBottom,
                    },
                };
            }
            return {
                ...state,
                ...{
                    mobileBottomList: state.mobileBottomList?.filter((item) => !item?.hide),
                },
            };

        case NotifyActionTypes.NOTIFY_GET_COUNT_UNREAD:
            return {
                ...state,
                ...{
                    countUnread: action.data,
                },
            };

        case NotifyActionTypes.TOGGLE_FAVORITE:
            // eslint-disable-next-line no-case-declarations
            const updatedFavoriteNotify = state?.list?.map((item) => {
                if (item.chat_uid === action.chatUid) {
                    return { ...item, ...{ favorite: action?.favorite } };
                } else return item;
            });

            return {
                ...state,
                ...{
                    list: updatedFavoriteNotify as INotifyItem[],
                },
            };

        case NotifyActionTypes.NOTIFY_GET:
            return {
                ...state,
                ...{
                    list:
                        action?.data?.result?.filter(
                            (item) =>
                                item?.notification_type &&
                                !SYNC.TRANSACTION_NOTIFY_LIST.includes(item?.notification_type),
                        ) || [],
                    transactionList: action?.data?.result?.filter(
                        (item) =>
                            item?.notification_type &&
                            SYNC.TRANSACTION_NOTIFY_LIST.includes(item?.notification_type),
                    ),
                    listPage: 1,
                    status: action?.data?.status,
                },
            };

        case NotifyActionTypes.HISTORY_GET:
            return {
                ...state,
                ...{
                    list: [...(state?.list || []), ...(action?.data?.result || [])],
                    listPage:
                        action?.data?.result?.length === 0 ? state?.listPage : state?.listPage + 1,
                    status: action?.data?.status,
                },
            };

        case NotifyActionTypes.UPDATE_TRANSACTION_NOTIFY:
            return {
                ...state,
                ...{
                    transactionList: [...[action?.notify], ...(state?.transactionList || [])],
                },
            };

        case NotifyActionTypes.UPDATE_TRANSACTION_NOTIFY_BOTTOM:
            return {
                ...state,
                ...{
                    mobileBottomList: [
                        ...[
                            {
                                ...action?.notify,
                                ...{
                                    date_expired: generationDateOnFewSecondsLongerWithoutUTC(
                                        action?.notify?.date_created,
                                        state.settingsBottom.MOBILE_NOTIFICATION_SHOW_TIME,
                                    ),
                                },
                            },
                        ],
                        ...(state?.mobileBottomList || []),
                    ],
                },
            };

        case NotifyActionTypes.UPDATE_NOTIFY:
            // eslint-disable-next-line no-case-declarations
            const updatedNotify = state?.list?.find(
                (item) =>
                    item.chat_uid === action?.data?.chat_uid && item?.notification_type !== 'mail',
            );

            if (
                updatedNotify?.chat_uid &&
                action?.data?.chat_uid &&
                +updatedNotify?.notification_id !== +action?.data?.notification_id
            ) {
                const filteredList = state.list?.filter((item) => item?.id !== updatedNotify?.id);

                return {
                    ...state,
                    ...{
                        list: [
                            ...[
                                {
                                    ...updatedNotify,
                                    ...action.data,
                                    ...{
                                        unread_count:
                                            updatedNotify.unread_count &&
                                            +updatedNotify.unread_count + 1,
                                    },
                                },
                            ],
                            ...(filteredList?.length ? filteredList : []),
                        ],
                    },
                };
            } else if (
                action?.data?.chat_uid &&
                // @ts-expect-error nextLine
                +updatedNotify?.notification_id !== +action?.data?.notification_id
            ) {
                return {
                    ...state,
                    ...{
                        list: [...[{ ...action.data, ...{ unread_count: 1 } }], ...state.list],
                    },
                };
            }
            return state;

        case NotifyActionTypes.UPDATE_MOB_BOTTOM_NOTIFY:
            if (+state.settingsBottom?.MOBILE_NOTIFICATION_ENABLED === 1) {
                if (
                    state.mobileBottomList.some((item) => item.chat_uid === action.data?.chat_uid)
                ) {
                    state.mobileBottomList.forEach((item) => {
                        if (item.chat_uid === action.data?.chat_uid) {
                            item.message_type = action.data?.message_type;
                            item.message_content = action.data?.message_content;
                            item.date_expired = generationDateOnFewSecondsLonger(
                                action.data?.date_created,
                                state.settingsBottom.MOBILE_NOTIFICATION_SHOW_TIME,
                            );
                        }
                    });
                    return {
                        ...state,
                        ...{
                            mobileBottomList: state.mobileBottomList,
                        },
                    };
                } else if (
                    state?.mobileBottomList?.length <
                    state?.settingsBottom?.MOBILE_NOTIFICATION_COUNT
                ) {
                    return {
                        ...state,
                        ...{
                            mobileBottomList: [
                                ...state.mobileBottomList,
                                ...[
                                    {
                                        ...action.data,
                                        ...{
                                            date_expired: generationDateOnFewSecondsLonger(
                                                action.data?.date_created,
                                                state.settingsBottom.MOBILE_NOTIFICATION_SHOW_TIME,
                                            ),
                                        },
                                    },
                                ],
                            ],
                        },
                    };
                } else {
                    return state;
                }
            } else {
                return state;
            }

        case NotifyActionTypes.UPDATE_MOB_NOTIFY:
            if (isActiveNotificationSettings(state.settings)) {
                if (+state.settings?.MOBILE_NOTIFICATION_ENABLED === 1) {
                    if (expDate === null || timeDiffFromMin(expDate)) {
                        if (
                            state.mobileList.some((item) => item.chat_uid === action.data?.chat_uid)
                        ) {
                            state.mobileList.forEach((item) => {
                                if (item.chat_uid === action.data?.chat_uid) {
                                    item.hide = false;
                                    item.message_content = action.data?.message_content;
                                    item.message_type = action?.data?.message_type;
                                    item.date_expired = generationDateOnFewSecondsLongerWithoutUTC(
                                        new Date().toString(),
                                        CHATS.MESSAGE_CONTENT_TYPE_TEXT ===
                                            action?.data?.message_type
                                            ? state.settings.MOBILE_TEXT_NOTIFICATION_SHOW_TIME
                                            : state.settings.MOBILE_NOTIFICATION_SHOW_TIME,
                                    );
                                }
                            });
                            return {
                                ...state,
                                ...{
                                    isActivePauseButton: true,
                                    mobileList: state.mobileList?.filter((item) => !item?.hide),
                                },
                            };
                        } else if (
                            state.mobileList?.filter((item) => !item?.hide)?.length <
                            state.settings?.MOBILE_NOTIFICATION_COUNT
                        ) {
                            action.data.date_expired = generationDateOnFewSecondsLongerWithoutUTC(
                                new Date().toString(),
                                CHATS.MESSAGE_CONTENT_TYPE_TEXT === action?.data?.message_type
                                    ? state.settings.MOBILE_TEXT_NOTIFICATION_SHOW_TIME
                                    : state.settings.MOBILE_NOTIFICATION_SHOW_TIME,
                            );
                            return {
                                ...state,
                                ...{
                                    isActivePauseButton: true,
                                    mobileList: [
                                        ...state.mobileList,
                                        ...[
                                            {
                                                ...action.data,
                                                ...{
                                                    date_expired:
                                                        generationDateOnFewSecondsLongerWithoutUTC(
                                                            new Date().toString(),
                                                            CHATS.MESSAGE_CONTENT_TYPE_TEXT ===
                                                                action?.data?.message_type
                                                                ? state.settings
                                                                      .MOBILE_TEXT_NOTIFICATION_SHOW_TIME
                                                                : state.settings
                                                                      .MOBILE_NOTIFICATION_SHOW_TIME,
                                                        ),
                                                },
                                            },
                                        ],
                                    ],
                                },
                            };
                        }
                    }
                }
            }
            return {
                ...state,
                ...{
                    mobileList: state.mobileList?.filter((item) => !item?.hide),
                },
            };

        case NotifyActionTypes.DELETE_ALL_MOB_NOTIFY:
            return {
                ...state,
                ...{
                    mobileList: [],
                },
            };

        case NotifyActionTypes.CLEAR_MOB_LIST:
            return {
                ...state,
                ...{
                    mobileListTmp: state.mobileList,
                    mobileList: [],
                    mobileBottomList: [],
                },
            };

        case NotifyActionTypes.DELETE_MOB_NOTIFY:
            if (state.mobileListTmp.some((item) => item.chat_uid === action.chatUid)) {
                state.mobileListTmp.forEach((item) => {
                    if (item.chat_uid === action.chatUid) {
                        item.hide = true;
                    }
                });
                return {
                    ...state,
                    ...{
                        mobileList: state.mobileListTmp,
                    },
                };
            }
            return state;

        case NotifyActionTypes.DELETE_MOB_BOTTOM_NOTIFY:
            if (state.mobileBottomList?.some((item) => item?.chat_uid === action?.chatUid)) {
                return {
                    ...state,
                    ...{
                        mobileBottomList: state.mobileBottomList?.filter(
                            (item) => item?.chat_uid !== action?.chatUid,
                        ),
                    },
                };
            }
            return state;

        case NotifyActionTypes.READ_NOTIFY:
            return {
                ...state,
                ...{
                    list: state?.list?.map((item) => {
                        if (item?.chat_uid === action?.data) {
                            return { ...item, ...{ read_status: '1' } };
                        } else return item;
                    }),
                },
            };

        case NotifyActionTypes.DELETE_NOTIFY:
            return {
                ...state,
                ...{
                    list: state.list?.filter(
                        (item) =>
                            !(
                                item?.notification_type !== 'mail' &&
                                item?.chat_uid === action?.chatUid
                            ),
                    ),
                },
            };

        case NotifyActionTypes.DELETE_ALL_NOTIFY:
            return {
                ...state,
                ...{
                    list: [],
                },
            };

        case NotifyActionTypes.DELETE_TRANSACTION_NOTIFY:
            return {
                ...state,
                ...{
                    transactionList: state.transactionList?.filter(
                        (item) => item?.id !== action?.notifyID,
                    ),
                },
            };

        case NotifyActionTypes.DELETE_NOTIFY_LETTER:
            return {
                ...state,
                ...{
                    list: state.list?.filter(
                        (item) =>
                            !(
                                item?.notification_type === 'mail' &&
                                item?.chat_uid === action?.chatUid
                            ),
                    ),
                },
            };

        case NotifyActionTypes.DISACTIVE_PAUSE_BUTTON:
            return {
                ...state,
                ...{
                    isActivePauseButton: false,
                },
            };

        case NotifyActionTypes.UPDATE_MAIL_NOTIFY:
            if (
                state?.list?.some(
                    (item) =>
                        item?.notification_type === 'mail' &&
                        item?.chat_uid === action?.data?.chat_uid,
                )
            ) {
                return {
                    ...state,
                    ...{
                        list: state.list.map((notify) => {
                            if (
                                notify?.notification_type === 'mail' &&
                                notify?.chat_uid === action?.data?.chat_uid
                            ) {
                                return {
                                    ...notify,
                                    ...action?.data,
                                    ...{
                                        unread_count:
                                            notify?.unread_count && +notify?.unread_count + 1,
                                    },
                                };
                            } else {
                                return notify;
                            }
                        }),
                    },
                };
            } else if (action?.data?.chat_uid) {
                action.data.unread_count = 1;
                return {
                    ...state,
                    ...{
                        list: [...[action.data], ...state.list],
                    },
                };
            }
            return state;

        default:
            return state;
    }
}
