import React from 'react';
import { IFemaleListItem } from '../../../../../store/reducers/mailbox/index.interface';

interface IItem {
    item: IFemaleListItem;
    selected: boolean;
    handleOnSelect: (item: IFemaleListItem) => void;
}

export default function Item({ item, selected, handleOnSelect }: IItem) {
    if (+item?.gifts !== 1) return null;
    return (
        <div
            className={`popup_compose_man_item ${selected ? 'active' : ''} ${item?.online ? 'online' : ''}`}
            data-id={item?.external_id}
            onClick={() => handleOnSelect(item)}
        >
            <label htmlFor="pcmi-1" className="popup_compose_man_item_label single">
                <div className="popup_compose_man_item_photo">
                    <img src={item?.image} alt="" />
                </div>
                <div className="popup_compose_man_item_right">
                    <div className="popup_compose_man_item_id">
                        ID {item?.public_external_id || ''}
                    </div>
                    <div className="popup_compose_man_item_name">{`${item?.name || ''}, ${item?.age || ''}`}</div>
                    <div className="popup_compose_man_item_place">
                        <img src={item?.country_icon} alt="" />
                        {item?.city || ''}
                    </div>
                </div>
            </label>
        </div>
    );
}
