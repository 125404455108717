import { useEffect, useState } from 'react';
import ClickHistoryApi from '../../services/api/v3/ClickHistoryApi';

export default function useGetCaptchaConfig() {
    const [hasCaptcha, setHasCaptcha] = useState<boolean>(false);

    useEffect(() => {
        new ClickHistoryApi().getCaptchaSettings().then((res) => {
            if (res?.status) {
                setHasCaptcha(res?.status);
            }
        });
    }, []);

    return { hasCaptcha };
}
