import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { MAX_LENGTH_TEXTAREA } from '../../constants/mailUnsubscribe';
import * as alertAction from '../../store/actions/alert';
import UnsubscribeAPI from '../../services/api/UnsubscribeApi';
import useTransition from '../transition/useTransition';
import useDetectedDevice from '../device/useDetectedDevice';
import * as MENU from '../../constants/menu';
import MailboxApi from '../../services/api/MailboxApi';
import * as ALERT from '../../constants/alert';
import * as ERROR from '../../constants/error';
import { RootState } from '../../index';
import SearchApi from '../../services/api/v3/SearchApi';
import { ITopProfilesFull } from '../../services/api/v3/SearchApi.interface';

export default function useInitMailUnsubscribe() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const userState = useSelector((state: RootState) => state.user);
    const countersState = useSelector((state: RootState) => state.counters);
    const { isMobile } = useDetectedDevice();

    const initUserStep = {
        step1: { text: '' },
        step2: { text: '' },
        step3: { text: '' },
    };

    const initOtherText = {
        step1: { text: '' },
        step2: { text: '' },
        step3: { text: '' },
    };

    const [topProfiles, setTopProfiles] = useState<ITopProfilesFull>();
    const [openFormAt, setOpenFormAt] = useState('');
    const [unsubscribeFormId, setUnsubscribeFormId] = useState(0);
    const [userStepData, setUserStepData] = useState(initUserStep);
    const [otherTextData, setOtherTextData] = useState(initOtherText);
    const [unsubscribeEmail, setUnsubscribeEmail] = useState('');
    const [isUnsubscribeEmailError, setIsUnsubscribeEmailError] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const isPayer = !!userState?.info?.payment_total;
    const deviceType = isMobile ? 'mob' : 'web';
    const isValidEmail = unsubscribeEmail === userState?.info?.email;

    // We need to add unsubscribe:1 to submitFormData after the user has unsubscribed and post saveStep.
    const submitFormData = {
        id: unsubscribeFormId,
        openFormAt,
        userStep: {
            step1: {
                text: userStepData?.step1?.text,
                ...(otherTextData?.step1?.text ? { otherText: otherTextData?.step1?.text } : {}),
            },
            step2: {
                text: userStepData?.step2?.text,
                ...(otherTextData?.step2?.text ? { otherText: otherTextData?.step2?.text } : {}),
            },
            step3: {
                text: userStepData?.step3?.text,
                ...(otherTextData?.step3?.text ? { otherText: otherTextData?.step3?.text } : {}),
            },
        },
        ...(activeStep === 5 || !isPayer ? { unsubscribe: 1 } : {}),
    };

    const selectStepText = (step: string, text: string) => {
        if (!step?.length || !text?.length) return false;
        const selectedStepNum = +step.slice(-1);

        setUserStepData({ ...userStepData, ...{ [step]: { text: text } } });

        if (selectedStepNum === activeStep && text !== 'Other') {
            setActiveStep(activeStep + 1);
        } else if (selectedStepNum < activeStep && text === 'Other') {
            setActiveStep(activeStep - 1);
        }
    };

    const handleOtherTextForm = (value: string, step: string) => {
        const selectedStepNum = +step.slice(-1);

        setOtherTextData({
            ...otherTextData,
            ...{ [step]: { text: value.slice(0, MAX_LENGTH_TEXTAREA) } },
        });

        if (selectedStepNum === activeStep && !!+value?.length) {
            setActiveStep(activeStep + 1);
        } else if (selectedStepNum < activeStep && !+value?.length) {
            setActiveStep(activeStep - 1);
        }
    };

    const handleUnsubscribeEmail = (value: string) => {
        setUnsubscribeEmail(value?.trim());
        setIsUnsubscribeEmailError(false);
    };

    const onSubmitUnsubscribeForm = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isLoadingSubmit) return false;

        if (!isValidEmail) {
            return setIsUnsubscribeEmailError(true);
        }

        if (isPayer) {
            for (const el in submitFormData?.userStep) {
                if (
                    // @ts-expect-error submitFormData
                    !submitFormData?.userStep?.[el]?.text?.length &&
                    // @ts-expect-error submitFormData
                    !submitFormData?.userStep?.[el]?.otherText?.length
                ) {
                    return dispatch(
                        alertAction.setMessage({ message: 'Please complete all steps' }),
                    );
                }
            }
        }

        setIsLoadingSubmit(true);
        new UnsubscribeAPI()
            .postMailUnsubscribe({ email: unsubscribeEmail })
            .then((res) => {
                if (res?.status) {
                    setActiveStep(activeStep + 1);
                    dispatch(
                        alertAction.setMessage({
                            title: 'You have successfully unsubscribed',
                            message: 'We wish you good acquaintances!',
                            btnName: 'Done',
                            successCallback: redirectToSite,
                        }),
                    );

                    setTimeout(() => redirectToSite(), 5000);
                }
            })
            .finally(() => setIsLoadingSubmit(false));
    };

    const redirectToSite = () => {
        if (!window.location.pathname.includes('mail-unsubscribe')) return false;

        if (countersState?.chatCounter?.unanswered) {
            transition.transitionWithOnlyHistoryPush('/chat');
        } else if (countersState?.letterCounter) {
            transition.transitionWithOnlyHistoryPush('/letter');
        } else {
            transition.transitionWithOnlyHistoryPush('/search');
        }
    };

    const openChat = (chatUid: string, womanExternalID: number) => {
        if (chatUid) {
            transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${chatUid}`);
        } else {
            if (!userState?.info?.external_id) return;

            new MailboxApi()
                .createChatWithProfile(userState?.info?.external_id, womanExternalID)
                .then((res) => {
                    if (+res?.chat_info?.male_block) {
                        return dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    }
                    if (res?.status && res?.chat_uid) {
                        transition.transitionWithHistoryPush(
                            MENU.OPEN_CHAT,
                            `/chat/${res?.chat_uid}`,
                        );
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                });
        }
    };

    const openSearch = () => {
        transition.transitionWithHistoryPush('search', `/search`);
    };

    useEffect(() => {
        (async function () {
            setOpenFormAt(moment().utc().format('YYYY-MM-DD HH:mm:ss'));

            const resCheck = await new UnsubscribeAPI().checkMailUnsubscribe();

            if (resCheck?.status && !resCheck?.check) {
                const resCreateStep = await new UnsubscribeAPI().createMailUnsubscribeStep({
                    deviceType,
                });
                if (resCreateStep?.status && resCreateStep?.data?.id)
                    setUnsubscribeFormId(resCreateStep?.data?.id);

                const resProfiles = await new SearchApi().getTopProfiles();
                if (resProfiles?.status) setTopProfiles(resProfiles?.response);
            } else {
                dispatch(alertAction.setMessage({ message: 'You have already unsubscribed' }));
                transition.transitionWithOnlyHistoryPush('/search');
            }
        })();
    }, []);

    useEffect(() => {
        if (activeStep > 1) {
            new UnsubscribeAPI().saveMailUnsubscribeStep({ ...submitFormData, deviceType });
        }
    }, [activeStep]);

    return {
        topProfiles,
        userStepData,
        selectStepText,
        otherTextData,
        handleOtherTextForm,
        unsubscribeEmail,
        handleUnsubscribeEmail,
        isUnsubscribeEmailError,
        activeStep,
        onSubmitUnsubscribeForm,
        isLoadingSubmit,
        openChat,
        isValidEmail,
        openSearch,
    };
}
