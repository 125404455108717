import React from 'react';
import GirlSimpleItem from '../../components/like/LikeItemSimple';
import EmptyResult from '../../components/common/EmptyResult';
import useHandleLikeHistory from '../../hooks/likeher/useHandleLikeHistory';
import * as SEX from '../../constants/sex';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function LikeHistoryPage() {
    const likesState = useSelector((state: RootState) => state.likes);
    const userState = useSelector((state: RootState) => state.user);

    const { handleScroll, redirectLikeHer } = useHandleLikeHistory();

    return (
        <div className="column-3 likeher">
            <div className="c3lh_your_likes_wrap">
                <div className="c3lh_top">
                    {window.innerWidth >= 1000 && (
                        <div className="c3lh_top_return_btn" onClick={redirectLikeHer}>
                            Return{' '}
                            <span>
                                to “
                                {
                                    SEX.SEX_TEXT[
                                        userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT
                                    ].LIKE_SIDE_MENU_BTN
                                }
                                ”
                            </span>
                        </div>
                    )}
                    <div className="c3lh_top_title">Your likes</div>
                    <div className="c3lh_top_empty" />
                </div>
                <div className="c3lh_your_likes_list_wrap2" onScroll={handleScroll}>
                    {likesState?.isEmptyLikeHistory ? (
                        <EmptyResult type={'likeHerHistory'} />
                    ) : (
                        <ul className="c3lh_your_likes_list_wrap">
                            {likesState?.likeHistory.map((item, key) => (
                                <GirlSimpleItem key={key} profile={item} isLikeDisabled />
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}
