import React from 'react';
import * as ALERT from '../../../../../constants/alert';
import { convertPrice } from '../../../../../services/methods';
import styles from './styles.module.scss';
import * as PRICES from '../../../../../constants/prices';
import { IAlertModalProps } from './index.interface';
import AlertMessage from './AlertMessage';
import IconCross from '../../../../Icons/IconCross';

export default function Alert({
    alertState,
    activeSubscribePaymentMethod,
    prices,
    isNewMail,
    closeModalAlert,
    fixMessageContent,
    onGetCredits,
    onClickActiveBtn,
    goToUserInbox,
}: IAlertModalProps) {
    const { alertType, message, title, btnName, isPaymentBtn } = alertState;
    return (
        <div className={styles.alert_modal_wrap} data-testid="alert-modal">
            <div className={styles.alert_modal}>
                {(() => {
                    switch (alertType) {
                        case ALERT.NEW_MESSAGE:
                            return (
                                <AlertMessage
                                    titleText={
                                        title?.length
                                            ? fixMessageContent(title)
                                            : fixMessageContent(message)
                                    }
                                    text={title?.length ? fixMessageContent(message) : ''}
                                    mainButtonText={
                                        title === 'You lack credits' || isPaymentBtn
                                            ? 'Get credits'
                                            : btnName || 'Got it'
                                    }
                                    mainButtonHandler={
                                        title === 'You lack credits' || isPaymentBtn
                                            ? onGetCredits
                                            : onClickActiveBtn
                                    }
                                    mainButtonIcon={'check'}
                                />
                            );
                        case ALERT.NOT_EXIST_OR_BLOCK:
                            return (
                                <AlertMessage
                                    titleText={'Oops, an error has occurred?'}
                                    text={'The chat does not exist or the user is blocked.'}
                                    mainButtonText={'Got it'}
                                    mainButtonHandler={closeModalAlert}
                                    mainButtonIcon={'check'}
                                />
                            );
                        case ALERT.SUCCESSFULLY_BOUGHT:
                            return (
                                <AlertMessage
                                    titleText={'Transaction completed successfully'}
                                    text={`${message ?? ''} credits have been transferred to your account. Thank you for your purchase.`}
                                    mainButtonText={'Got it'}
                                    mainButtonHandler={closeModalAlert}
                                    mainButtonIcon={'check'}
                                />
                            );
                        case ALERT.SUCCESS_CONTACT_REQUEST:
                            return (
                                <AlertMessage
                                    titleText={'Request successful'}
                                    titleImage={true}
                                    text={'Your request will be processed during 96 hours'}
                                    subtext={
                                        'Once the request is processed, you will receive a message from our moderation team.'
                                    }
                                    mainButtonText={'Got it'}
                                    mainButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.EMAIL_CONFIRM_SENT:
                            return (
                                <AlertMessage
                                    titleText={'Confirmation was sent'}
                                    titleImage={true}
                                    text={
                                        'Please go to your Inbox and check for a confirmation email'
                                    }
                                    message={message}
                                    mainButtonIcon={'arrow'}
                                    mainButtonText={'Verify in Inbox'}
                                    mainButtonHandler={() => goToUserInbox(message)}
                                    additionalText={
                                        "If you didn't receive the email, please check your Spam/Junk folder"
                                    }
                                />
                            );
                        case ALERT.LETTER_FILES_LIMIT:
                            return (
                                <AlertMessage
                                    titleText={'Attached files limit is exceeded'}
                                    text={
                                        'You can attach a maximum of 5 files <br /> to one letter'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Got it'}
                                    mainButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.ENOUGH_CREDITS_CHAT:
                            return (
                                <AlertMessage
                                    titleText={'You lack credits'}
                                    text={
                                        'You need to get more credits <br /> to be able to send messages'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Get credits'}
                                    mainButtonHandler={onGetCredits}
                                />
                            );
                        case ALERT.ENOUGH_CREDITS_VIDEOSHOW:
                            return (
                                <AlertMessage
                                    titleText={'You lack credits'}
                                    text={
                                        'You need to get more credits <br /> to be able to watch video'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Get credits'}
                                    mainButtonHandler={onGetCredits}
                                />
                            );
                        case ALERT.ENOUGH_CREDITS_LETTER:
                            return (
                                <AlertMessage
                                    titleText={'You lack credits'}
                                    text={
                                        'You need to get more credits <br /> to be able to send letters'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Get credits'}
                                    mainButtonHandler={onGetCredits}
                                />
                            );
                        case ALERT.ENOUGH_CREDITS_TO_VIEW:
                            return (
                                <AlertMessage
                                    titleText={
                                        'You don’t have enough credits <br /> to make this action'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Get credits'}
                                    mainButtonHandler={onGetCredits}
                                />
                            );
                        case ALERT.ENOUGH_CREDITS_TO_LISTEN:
                            return (
                                <AlertMessage
                                    titleText={
                                        'You don’t have enough credits <br /> to listen this content'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Get credits'}
                                    mainButtonHandler={onGetCredits}
                                />
                            );
                        case ALERT.ADD_TO_BLOCK_LIST:
                            return (
                                <AlertMessage
                                    titleText={
                                        'Do you really want to add the member to the block list?'
                                    }
                                    text={
                                        'You won\'t receive messages from the member any longer. <br /> To unblock the member, go to "Block list" menu section in your profile.'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Аdd to block list'}
                                    mainButtonHandler={onClickActiveBtn}
                                    cancelButtonText={'Cancel'}
                                    cancelButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.ADD_TO_TRASH:
                        case ALERT.ADD_TO_TRASH_FROM_DIALOGS:
                            return (
                                <AlertMessage
                                    titleText={
                                        alertType === ALERT.ADD_TO_TRASH
                                            ? 'Are you sure you want to move the letter history with member to the "Trash" folder?'
                                            : 'Are you sure you want to move this letter to the "Trash" folder?'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Move to trash'}
                                    mainButtonHandler={onClickActiveBtn}
                                    mainButtonTestId={'move-btn'}
                                    cancelButtonText={'Cancel'}
                                    cancelButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.ENOUGH_CREDITS_GIFTS:
                            return (
                                <AlertMessage
                                    titleText={'You don’t have enough credit'}
                                    text={`You need ${message ?? ''} credits more to buy this gift`}
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Get credits'}
                                    mainButtonHandler={onGetCredits}
                                />
                            );
                        case ALERT.ENOUGH_CREDITS_REQUEST:
                            return (
                                <AlertMessage
                                    titleText={'You don’t have enough credit'}
                                    text={`You need ${message ?? ''} credits more to make request`}
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Get credits'}
                                    mainButtonHandler={onGetCredits}
                                />
                            );
                        case ALERT.DELETE_CREDIT_CARD:
                            return (
                                <AlertMessage
                                    titleText={'Remove credit card?'}
                                    text={
                                        !(
                                            activeSubscribePaymentMethod?.length &&
                                            activeSubscribePaymentMethod === message
                                        )
                                            ? 'If you remove your credit card, your subscription will be cancelled'
                                            : 'Press the “Remove credit card” button to remove your credit card'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Remove credit card'}
                                    mainButtonHandler={onClickActiveBtn}
                                    cancelButtonText={'Cancel'}
                                    cancelButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.VIDEO_MESSAGE_CONTENT:
                            return (
                                <AlertMessage
                                    titleText={`Video costs ${convertPrice(
                                        prices[isNewMail ? PRICES.GET_VIDEO_NEW : PRICES.GET_VIDEO],
                                    )} credits`}
                                    titleTestId={'cost-title'}
                                    text={'Press “Watch” button to watch member’s video'}
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Watch'}
                                    mainButtonHandler={onClickActiveBtn}
                                    mainButtonTestId={'watch-btn'}
                                    cancelButtonText={'Cancel'}
                                    cancelButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.AUDIO_MESSAGE_CONTENT:
                            return (
                                <AlertMessage
                                    titleText={`An audio clip costs ${convertPrice(
                                        prices[isNewMail ? PRICES.GET_AUDIO_NEW : PRICES.GET_AUDIO],
                                    )} credits`}
                                    titleTestId={'cost-title'}
                                    text={
                                        'Press the “Listen” button to listen to a member’s audio clip'
                                    }
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Listen'}
                                    mainButtonHandler={onClickActiveBtn}
                                    mainButtonTestId={'listen-btn'}
                                    cancelButtonText={'Cancel'}
                                    cancelButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.VIDEO_SHOW_ALERT:
                            return (
                                <AlertMessage
                                    titleText={`You watched 1 free video per day. Now it costs ${convertPrice(prices[PRICES?.GET_VIDEO_SHOW])} credits to watch this video`}
                                    titleTestId={'cost-title'}
                                    text={'Press “Watch” button to watch member’s video'}
                                    mainButtonIcon={'check'}
                                    mainButtonText={'Watch'}
                                    mainButtonHandler={onClickActiveBtn}
                                    mainButtonTestId={'listen-btn'}
                                    cancelButtonText={'Cancel'}
                                    cancelButtonHandler={closeModalAlert}
                                />
                            );
                        case ALERT.MESSAGE_RELOAD_PAGE:
                            return (
                                <AlertMessage
                                    titleText={fixMessageContent(message)}
                                    isWarning={true}
                                    mainButtonText={'Reload'}
                                    mainButtonHandler={() => location.reload()}
                                    cancelButtonText={'Cancel'}
                                    cancelButtonHandler={closeModalAlert}
                                />
                            );
                        default:
                            return <div />;
                    }
                })()}
            </div>
            <button
                className={styles.alert_modal_close_icon}
                onClick={closeModalAlert}
                type="button"
                data-testid="close-btn"
            >
                <IconCross />
            </button>
        </div>
    );
}
