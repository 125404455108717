import * as menuAction from '../../store/actions/menu';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as mailboxAction from '../../store/actions/mailbox';
import { closeMobChatList } from '../../services/dom';
import * as modalsAction from '../../store/actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import * as letterFilterAction from '../../store/actions/letterFilter';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useTransition from '../transition/useTransition';
import { RootState } from '../../index';

export default function useHandleLettersFilters() {
    const dispatch = useDispatch();
    const transition = useTransition();

    const menuState = useSelector((state: RootState) => state.menu);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);

    const [inputId, setInputId] = useState('');
    const [inputUsername, setInputUsername] = useState('');
    const [onlineStatus, setOnlineStatus] = useState('');

    const handleOnline = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOnlineStatus(event.currentTarget.checked ? 'online' : '');
    };

    const setOnline = (status: string) => {
        dispatch(mailboxAction.setCurrentPageMailboxList(1));
        dispatch(letterFilterAction.setOnlineFilter(status));
    };

    const handleInputId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputId(event?.currentTarget?.value);
    };

    const filterById = () => {
        dispatch(mailboxAction.setCurrentPageMailboxList(1));
        dispatch(
            letterFilterAction.setFilterById({
                id: inputId,
                filterName: letterFilterState.girlFilter,
            }),
        );
    };

    const handleInputUsername = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(mailboxAction.setCurrentPageMailboxList(1));
        setInputUsername(event?.currentTarget?.value ?? '');
    };

    const clearInputUsername = () => {
        setInputUsername('');
    };

    const filterByUsername = (username: string) => {
        dispatch(mailboxAction.setCurrentPageMailboxList(1));
        dispatch(letterFilterAction.setFilterByUsername(username));
    };

    const handleChangeFilterMen = (filter: string) => {
        if (filter !== 'id') {
            dispatch(mailboxAction.setCurrentPageMailboxList(1));
        }
        dispatch(letterFilterAction.setGirlFilter(filter || ''));
    };

    const handleChangeFilterLetters = (filter: string) => {
        dispatch(mailboxAction.setCurrentPageMailboxList(1));
        dispatch(letterFilterAction.setLettersFilter(filter || ''));
    };

    const onSetLettersMenu = (type: string) => {
        if (menuState.letters !== type) {
            dispatch(mailboxAction.setCurrentPageMailboxList(1));
            dispatch(menuAction.setLettersMenu(type || ''));
        }
        if (currentChatMailboxState?.info?.id) {
            dispatch(currentChatMailboxAction.removeMailboxChat());
            transition.transitionWithOnlyHistoryPush('/letter');
        }
        closeMobChatList();
        dispatch(modalsAction.setActiveSubMenu());
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => filterByUsername(inputUsername), 500);
        return () => clearTimeout(timeoutId);
    }, [inputUsername]);

    useEffect(() => {
        const timeoutId = setTimeout(() => setOnline(onlineStatus), 500);
        return () => clearTimeout(timeoutId);
    }, [onlineStatus]);

    return {
        inputId,
        letterFilterState,
        onSetLettersMenu,
        onlineStatus,
        handleOnline,
        handleInputId,
        filterById,
        handleChangeFilterMen,
        handleChangeFilterLetters,
        handleInputUsername,
        clearInputUsername,
        inputUsername,
    };
}
