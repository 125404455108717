import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProfilePremium from '../../components/Profile/ProfilePremium';
import * as menuAction from '../../store/actions/menu';

export default function ProfilePremiumPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuAction.setMainMenu('my-profile/premium'));
    }, []);

    return (
        <>
            <ProfilePremium />
        </>
    );
}
