import React from 'react';

export default function IconFAQQuestionArrow() {
    return (
        <svg
            width="9"
            height="17"
            viewBox="0 0 9 17"
            fill="#374E6C"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M-6.93351e-07 1.13799L6.9188 8.4994L0.00055218 15.8605L1.01099 17L9 8.49939L1.01099 -4.41917e-08L-6.93351e-07 1.13799Z" />
        </svg>
    );
}
