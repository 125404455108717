import React from 'react';

export default function IconPolicy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#720066"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4487 3.36678C11.809 3.26733 12.1909 3.26733 12.5512 3.36678L18.2566 4.94154C19.0183 5.15177 19.5517 5.75147 19.6045 6.4511C19.7792 8.76365 19.5922 11.69 18.567 14.3455C17.5468 16.9881 15.7097 19.3374 12.5794 20.5963C12.2095 20.745 11.7905 20.745 11.4206 20.5963C8.29028 19.3374 6.45312 16.9881 5.43295 14.3455C4.40778 11.69 4.22076 8.76365 4.39549 6.4511C4.44836 5.75146 4.98171 5.15176 5.74335 4.94154L11.4487 3.36678ZM12.9188 2.12432C12.3182 1.95856 11.6816 1.95856 11.0811 2.12432L5.37582 3.69907C4.13919 4.0404 3.15976 5.05449 3.06134 6.35705C2.87734 8.79217 3.06763 11.9168 4.17957 14.797C5.2965 17.6903 7.35628 20.3614 10.9064 21.7892C11.6054 22.0703 12.3945 22.0703 13.0935 21.7892C16.6437 20.3614 18.7034 17.6903 19.8204 14.797C20.9323 11.9168 21.1226 8.79217 20.9387 6.35706C20.8403 5.05448 19.8607 4.04039 18.6241 3.69907L12.9188 2.12432ZM16.437 10.0724C16.6786 9.8025 16.6481 9.39448 16.3687 9.16103C16.0893 8.92758 15.6669 8.9571 15.4253 9.22696L11.6155 13.4811L9.00637 11.3438C8.72464 11.1129 8.30258 11.1464 8.06366 11.4186C7.82473 11.6908 7.85943 12.0984 8.14116 12.3293L11.2554 14.8804C11.5352 15.1096 11.9538 15.0783 12.1938 14.8103L16.437 10.0724Z"
            />
        </svg>
    );
}
