import React, { ComponentType } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import LoaderMain from '../../components/common/LoaderMain';
import { sendErrToSentry } from '../../services/sentry';

function ErrorFallback({ error }: FallbackProps) {
    sendErrToSentry(error as Error);
    window.location.href = `/error`;
    return <LoaderMain />;
}

const withErrorCatch =
    <P extends object>(Component: ComponentType<P>) =>
    (props: P) => {
        try {
            return (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Component {...props} />
                </ErrorBoundary>
            );
        } catch (error) {
            sendErrToSentry(error as Error);
            window.location.href = '/error';
            return <LoaderMain />;
        }
    };

export default withErrorCatch;
