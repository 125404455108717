import { useEffect, useState, UIEvent, MouseEvent } from 'react';
import * as allMediaAction from '../../store/actions/allMedia';
import * as CHATS from '../../constants/chats';
import {
    filterByFileType,
    filterByPayed,
    scrollTopMediaList,
} from '../../services/methods/allMedia';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function useSetChatMediaList() {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const allMediaState = useSelector((state: RootState) => state.allMedia);

    const filterMediaList = () => {
        return allMediaState?.allMediaList?.length
            ? allMediaState?.allMediaList
                  ?.filter((item) => filterByFileType(allMediaState?.mediaListType, item))
                  ?.filter((item) => filterByPayed(allMediaState?.mediaListPaid, item))
            : [];
    };

    const filteredMediaListByPage = filterMediaList().slice(
        0,
        CHATS.CHAT_MEDIA_LIST_QUANTITY * allMediaState.page,
    );
    const filteredMediaList = filterMediaList();

    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
        const target = event?.target as HTMLElement;
        const getNextPage =
            target?.scrollTop > target?.scrollHeight - target?.offsetHeight - 100 * 2;
        if (
            getNextPage &&
            !isLoading &&
            !(filteredMediaListByPage?.length === filteredMediaList?.length)
        ) {
            dispatch(allMediaAction.setChatMediaListByPage(+allMediaState?.page + 1));
            setIsLoading(true);
        }
    };

    const onSetMediaListType = (event: MouseEvent<HTMLDivElement>) => {
        const currentTarget = event.currentTarget as HTMLElement;

        if (allMediaState.mediaListType !== currentTarget?.dataset?.name) {
            scrollTopMediaList();
            if (currentTarget?.dataset?.name) {
                dispatch(allMediaAction.setMediaListType(currentTarget?.dataset?.name));
            }
            dispatch(allMediaAction.resetChatMediaListByPage());
        }
    };

    const onSetMediaListPaid = (event: MouseEvent<HTMLDivElement>) => {
        const currentTarget = event.currentTarget as HTMLElement;

        if (allMediaState.mediaListPaid !== currentTarget?.dataset?.name) {
            scrollTopMediaList();
            if (currentTarget?.dataset?.name) {
                dispatch(allMediaAction.setMediaListPaid(currentTarget?.dataset?.name));
            }
            dispatch(allMediaAction.resetChatMediaListByPage());
        }
    };

    useEffect(() => {
        setIsLoading(false);
    }, [allMediaState.page]);

    return {
        handleScroll,
        onSetMediaListType,
        onSetMediaListPaid,
    };
}
