import React from 'react';
import styles from './styles.module.scss';
import MainInfo from './MainInfo';
import AboutMySelf from './AboutMySelf';
import GirlsPreferences from './GirlsPreferences';
import Photos from './Photos';
import Contacts from './Contacts';
import GirlsSlider from '../../../common/GirlsSlider/GirlsSlider';
import useGetManProfile from '../../../../hooks/profile/my-profile-v2/useGetManProfile';
import useEditManProfile from '../../../../hooks/profile/my-profile-v2/useEditManProfile';
import LoaderGif from '../../../common/LoaderGif';
import DeleteProfileModal from '../../../common/Modals/DeleteProfileModal';
import useInitDeleteProfileModal from '../../../../hooks/dom/useInitDeleteProfileModal';
import IconDeleteProfile from '../../../Icons/IconDeleteProfile';
import useHandleMainInfo from '../../../../hooks/profile/my-profile-v2/useHandleMainInfo';

export default function ProfileV2() {
    const getManProfile = useGetManProfile();
    const editManProfile = useEditManProfile();
    const initDeleteProfileModal = useInitDeleteProfileModal();
    const handleMainInfo = useHandleMainInfo({
        mainInfo: getManProfile.mainInfo,
        setMainInfo: getManProfile.setMainInfo,
        saveEdits: editManProfile.saveEdits,
    });
    return (
        <div className={styles.profile_wrap}>
            <GirlsSlider />
            {getManProfile?.mainInfo?.external_id ? (
                <>
                    <MainInfo handleMainInfo={handleMainInfo} />
                    {!!getManProfile?.isShowContacts && <Contacts />}
                    <div className={styles.profile_middle_row}>
                        <AboutMySelf
                            aboutMySelf={getManProfile.aboutMySelf}
                            setAboutMySelf={getManProfile.setAboutMySelf}
                            saveEdits={editManProfile.saveEdits}
                        />
                        <GirlsPreferences
                            girlsAppearance={getManProfile.girlsAppearance}
                            setGirlsAppearance={getManProfile.setGirlsAppearance}
                            saveEdits={editManProfile.saveEdits}
                        />
                    </div>
                    <Photos handleMainInfo={handleMainInfo} />
                    <div
                        className={styles.delete_profile_btn}
                        onClick={initDeleteProfileModal.openDeleteProfileModal}
                    >
                        <IconDeleteProfile />
                        <span>Delete your profile</span>
                    </div>
                </>
            ) : (
                <LoaderGif />
            )}
            <DeleteProfileModal
                isDeleteProfileOpen={initDeleteProfileModal.isDeleteProfileOpen}
                closeModal={initDeleteProfileModal.closeModal}
                deletionStep={initDeleteProfileModal.deletionStep}
                openQuizModal={initDeleteProfileModal.openQuizModal}
                acceptDeleting={initDeleteProfileModal.acceptDeleting}
            />
        </div>
    );
}
