import React from 'react';
import not_found_results from '../../../../img/not_found_results_2.svg';
import IconUploadAttachment from '../../../Icons/IconUploadAttachment';

export default function EmptyFilesList() {
    return (
        <div className="attach_new_popup_tab_content_middle_empty">
            <img
                src={not_found_results}
                alt="not found results"
                className="attach_new_popup_tab_content_middle_empty_img"
            />
            <div className="attach_new_popup_tab_content_middle_empty_title">No files yet</div>
            <div className="attach_new_popup_tab_content_middle_empty_text">
                Use <IconUploadAttachment /> “Upload” button to add files
            </div>
        </div>
    );
}
