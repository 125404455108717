import { OnlineNowActionTypes } from '../../constants/onlineNow';
import SearchApi from '../../../services/api/v3/SearchApi';
import { ONLINE_NOW_FILTERS_DEFAULT } from '../../../constants/search';
import { Dispatch } from 'redux';
import fetchCrossBanners from '../../../services/methods/crossBanner';
import {
    IGetOnlineNowListAction,
    ISetOnlineNowIsLoadingPageAction,
} from '../../reducers/onlineNow/index.interface';

export function getOnlineNowList(page = 1, filter = ONLINE_NOW_FILTERS_DEFAULT) {
    return async function (
        dispatch: Dispatch<IGetOnlineNowListAction | ISetOnlineNowIsLoadingPageAction>,
    ) {
        const crossBanners = await fetchCrossBanners();
        return new SearchApi().getSearchList(page, filter).then((res) => {
            if (res?.status) {
                dispatch({
                    type: OnlineNowActionTypes.GET_ONLINE_NOW_LIST,
                    searchList: res?.response,
                    crossBanners: crossBanners,
                });

                return res?.response;
            }

            dispatch({
                type: OnlineNowActionTypes.SET_IS_LOADING_PAGE,
                status: false,
            });
            return [];
        });
    };
}

export function initOnlineNowState() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: OnlineNowActionTypes.INIT_ONLINE_NOW_SEARCH,
        });
    };
}

export function setOnlineNowListPage(page = 1) {
    return function (dispatch: Dispatch<{ type: string; page: number }>) {
        return dispatch({
            type: OnlineNowActionTypes.SET_ONLINE_NOW_LIST_PAGE,
            page,
        });
    };
}

export function setOnlineNowListFilter(filter = ONLINE_NOW_FILTERS_DEFAULT) {
    return function (
        dispatch: Dispatch<{ type: string; filter: typeof ONLINE_NOW_FILTERS_DEFAULT }>,
    ) {
        return dispatch({
            type: OnlineNowActionTypes.SET_ONLINE_NOW_LIST_FILTER,
            filter,
        });
    };
}

export function setOnlineNowLikeStatus(womanExternalID: number) {
    return function (dispatch: Dispatch<{ type: string; womanExternalID: number }>) {
        return dispatch({
            type: OnlineNowActionTypes.SET_ONLINE_NOW_LIKE_STATUS,
            womanExternalID,
        });
    };
}
