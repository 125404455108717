import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ComposeModal from '../../../common/Modals/ComposeModal';
import ComposeWriteLetterModal from '../../../common/Modals/ComposeWriteLetterModal';
import OpenFormLetterButtonNew from '../../../common/Buttons/OpenFormLetterButtonNew';
import * as mailboxAction from '../../../../store/actions/mailbox';
import useHandleChatFilters from '../../../../hooks/chat/useHandleChatFilters';
import useHandleMobileChat from '../../../../hooks/chat/helpers/useHandleMobileChat';
import OpenFormLetterButton from '../../../common/Buttons/OpenFormLetterButton';
import { RootState } from '../../../../index';
import IconSendMessage from '../../../Icons/IconSendMessage';

interface IComposeLetterProps {
    isMenu?: boolean;
}

export default function ComposeLetter({ isMenu = false }: IComposeLetterProps) {
    const mailboxState = useSelector((state: RootState) => state.mailbox);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalWRLIsOpen, setModalWRLIsOpen] = useState(false);

    const dispatch = useDispatch();
    const handleChatFilters = useHandleChatFilters();
    const handleMobileChat = useHandleMobileChat();

    const { checkedRecipients } = mailboxState;

    const openWriteLetterModal = () => {
        if (checkedRecipients?.length > 0) {
            setModalWRLIsOpen(true);
            setModalIsOpen(false);
        }
    };
    const closeWriteLetterModal = () => {
        setModalWRLIsOpen(false);
        dispatch(mailboxAction.removeAllRecipient());
    };

    const openComposeModal = () => {
        handleChatFilters.closeMobileFiltersBlock();
        setModalIsOpen(true);
        setModalWRLIsOpen(false);
    };

    const closeComposeModal = () => {
        setModalIsOpen(false);
        dispatch(mailboxAction.removeAllRecipient());
    };

    return (
        <>
            {handleMobileChat?.isNewMobileChat && !isMenu ? (
                <OpenFormLetterButtonNew
                    onPressButton={openComposeModal}
                    titleButton={window.innerWidth < 1000 ? 'New letter' : 'Compose new letter'}
                />
            ) : (
                <OpenFormLetterButton
                    onPressButton={openComposeModal}
                    titleButton={'Compose new letter'}
                    SVGButton={IconSendMessage}
                />
            )}

            <Modal
                style={modalStyleProps()}
                isOpen={modalIsOpen}
                onRequestClose={closeComposeModal}
                ariaHideApp={false}
            >
                <ComposeModal
                    closeComposeModal={closeComposeModal}
                    openWriteLetterModal={openWriteLetterModal}
                />
            </Modal>
            <Modal
                style={modalStyleProps()}
                isOpen={modalWRLIsOpen}
                onRequestClose={closeWriteLetterModal}
                ariaHideApp={false}
            >
                <ComposeWriteLetterModal
                    openComposeModal={openComposeModal}
                    closeWriteLetterModal={closeWriteLetterModal}
                />
            </Modal>
        </>
    );
}
