import { makeCDN } from '../../../../../services/methods';
import React from 'react';
import SITE from '../../../../../config/site';
import { IPhotoContentProps } from './index.interface';

export default function PhotoContent({ item, closeModal, onViewPhoto, image }: IPhotoContentProps) {
    return (
        <>
            <div
                className={`popup_attach_photo_click_img_wrap ${+item.attachment_payed === 1 ? '' : 'unpaid'}`}
                style={+item.attachment_payed === 0 ? {} : { cursor: 'pointer' }}
                onClick={() =>
                    item?.attachment_payed
                        ? +item.attachment_payed === 1
                            ? null
                            : onViewPhoto && onViewPhoto()
                        : null
                }
                onDragStart={(e) => SITE.ID === 23 && e.preventDefault()}
                onContextMenu={(e) => SITE.ID === 23 && e.preventDefault()}
                data-testid="photo-content"
            >
                <img
                    src={image ? image : makeCDN(item?.link, 'IMAGE')}
                    alt=""
                    className={`popup_attach_photo_click_img ${+item.attachment_payed === 1 ? '' : ''}`}
                    data-testid="image"
                />
                {item?.attachment_payed && +item?.attachment_payed !== 1 ? (
                    <div
                        className="click_to_view"
                        onClick={() => onViewPhoto && onViewPhoto()}
                        data-testid="click-to-view-btn"
                    >
                        Click to view the photo
                    </div>
                ) : (
                    <div />
                )}
            </div>
            <div className="pap-btn-close" onClick={closeModal} data-testid="close-btn">
                <img src="/img/pap-btn-close.svg" alt="" />
            </div>
        </>
    );
}
