import React, { ChangeEvent, useEffect, useState } from 'react';
import * as menuAction from '../../../../../store/actions/menu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../index';

export default function Header() {
    const dispatch = useDispatch();
    const menuState = useSelector((state: RootState) => state.menu);

    const [girlFilter, setGirlFilter] = useState<string>('');
    const [girlSearch, setGirlSearch] = useState<string>('');
    const [isChooseOpen, setIsChooseOpen] = useState<boolean>(false);

    const styleChooseButton = `popup_compose_head_choose_filter_main ${menuState?.composeLetterFilter?.length ? 'chosen' : ''} ${isChooseOpen ? 'active' : ''}`;
    const styleChooseBlock = `popup_compose_head_choose_filter_list_wrap ${isChooseOpen ? 'active' : ''}`;

    const toggleChooseOpen = () => setIsChooseOpen(!isChooseOpen);
    const removeFilter = () => dispatch(menuAction.setComposeLetterFilter(''));
    const handleChangeInputSearchGirl = (event: ChangeEvent<HTMLInputElement>) =>
        setGirlSearch(event?.currentTarget?.value);

    const handleChangeFilterGirl = (event: React.MouseEvent<HTMLInputElement>) => {
        removeFilter();
        if (girlFilter === event?.currentTarget?.dataset?.name) {
            setGirlFilter('');
            dispatch(menuAction.setComposeLetterFilter(''));
        } else {
            if (event?.currentTarget?.dataset?.name) {
                setGirlFilter(event?.currentTarget?.dataset?.name);
                dispatch(menuAction.setComposeLetterFilter(event?.currentTarget?.dataset?.name));
            }
        }
        setIsChooseOpen(false);
    };

    useEffect(() => {
        const sanitizedSearchItem = /\d/.test(girlSearch)
            ? girlSearch.replace(/\s/g, '')
            : girlSearch;
        dispatch(menuAction.setComposeLetterSearch(sanitizedSearchItem));
    }, [girlSearch]);

    useEffect(() => {
        return () => {
            removeFilter();
            setGirlFilter('');
            dispatch(menuAction.setComposeLetterFilter(''));
        };
    }, []);

    return (
        <div className="popup_compose_head">
            <div className="popup_compose_head_search">
                <input
                    type="text"
                    placeholder="Search ID or name"
                    value={girlSearch}
                    data-testid="idOrName"
                    onChange={handleChangeInputSearchGirl}
                />
                {girlSearch?.length ? (
                    <button onClick={() => setGirlSearch('')}>
                        <img src="../../../../../img/c2-remove.svg" alt="" />
                    </button>
                ) : (
                    <button>
                        <img src="../../../../../img/c2_search_btn.svg" alt="" />
                    </button>
                )}
            </div>
            <div className="popup_compose_head_right">
                <div className="popup_compose_head_choosen_filters" />
                <div className="popup_compose_head_choose_filter_wrap">
                    <div
                        className={styleChooseButton}
                        onClick={toggleChooseOpen}
                        data-testid="choose-filter-btn"
                    >
                        <img src="../../../../../img/c3l-choose-filter.svg" alt="" />
                        <span>
                            {menuState?.composeLetterFilter?.length
                                ? menuState.composeLetterFilter
                                : 'Choose filter'}
                        </span>
                    </div>
                    <div className={styleChooseBlock}>
                        <div className="popup_compose_head_choose_filter_list">
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="online"
                                    checked={girlFilter === 'online'}
                                    onClick={handleChangeFilterGirl}
                                    id="pchcf_1"
                                    readOnly
                                />
                                <label htmlFor="pchcf_1">Online</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="dialogues"
                                    checked={girlFilter === 'dialogues'}
                                    onClick={handleChangeFilterGirl}
                                    id="pchcf_9"
                                    readOnly
                                />
                                <label htmlFor="pchcf_9">Dialogues</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="favorites"
                                    checked={girlFilter === 'favorites'}
                                    onClick={handleChangeFilterGirl}
                                    id="pchcf_2"
                                    readOnly
                                />
                                <label htmlFor="pchcf_2">Favorites</label>
                            </div>
                            <div className="popup_compose_head_choose_filter_row">
                                <input
                                    type="radio"
                                    name="pchcf_1"
                                    data-name="likes"
                                    checked={girlFilter === 'likes'}
                                    onClick={handleChangeFilterGirl}
                                    id="pchcf_3"
                                    readOnly
                                />
                                <label htmlFor="pchcf_3">Your likes</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
