import React from 'react';
import loaderGIF from '../../../img/loading.gif';
import styles from './LoaderGif.module.scss';
import { ILoaderGifProps } from './index.interface';

export default function LoaderGif({ mainStyle, style2, title = '' }: ILoaderGifProps) {
    return (
        <div
            className={`${styles.loaderDiv} ${style2 ? styles[style2] : ''}`}
            data-testid="loader-gif-block"
        >
            {!!title?.length && <div className={styles.title}>{title}</div>}
            <img
                className={mainStyle ? '' : styles.loaderImage}
                style={mainStyle}
                src={loaderGIF}
                alt={'Loading'}
            />
        </div>
    );
}
