import React from 'react';
import ComposeLetter from './ComposeLetter';
import Folders from './Folders';
import Filters from './Filters';
import { closeMobChatList } from '../../../services/dom';
import * as modalsAction from '../../../store/actions/modals';
import { useDispatch } from 'react-redux';
import useHandleLettersFilters from '../../../hooks/letters/useHandleLettersFilters';
import { isHolidayActive } from '../../../constants/holiday';

export default function LettersMenu() {
    const dispatch = useDispatch();
    const handleLettersFilters = useHandleLettersFilters();

    const closeMenu = () => {
        closeMobChatList();
        dispatch(modalsAction.setActiveSubMenu());
    };

    const activeHoliday = isHolidayActive();

    return (
        <div className="column-2 letters column_2_letters_page" data-testid="letter-filter-block">
            <div className={`column_2_letters_wrap ${activeHoliday && activeHoliday?.name}`}>
                <ComposeLetter isMenu />
                <div className="column_2_letters_mob_scroll">
                    <Folders onSetLettersMenu={handleLettersFilters.onSetLettersMenu} />
                    <Filters
                        inputId={handleLettersFilters.inputId}
                        handleOnline={handleLettersFilters.handleOnline}
                        handleInputId={handleLettersFilters.handleInputId}
                        filterById={handleLettersFilters.filterById}
                        handleChangeFilterMen={handleLettersFilters.handleChangeFilterMen}
                        handleChangeFilterLetters={handleLettersFilters.handleChangeFilterLetters}
                        onlineStatus={handleLettersFilters.onlineStatus}
                    />
                </div>
            </div>
            <div className="c2_close" onClick={closeMenu} />
        </div>
    );
}
