import Base from '../Base';
import { AnswerType } from './SupportFeedbackApi.interface';

export default class SupportFeedbackApi extends Base {
    async getSupportFeedback() {
        const res = await super.get('v3/support-feedback/questionnaire');
        return res?.data;
    }

    async answerSupportFeedback(data: AnswerType) {
        const res = await super.post('v3/support-feedback/answer', data);
        return res?.data;
    }
}
