import { convertPrice } from '../../services/methods';
import PaymentApi from '../../services/api/v3/PaymentApi';
import { sendErrToSentry } from '../../services/sentry';
import { CurrencyEnum, IPurchasePackageItem } from '../../store/reducers/purchase/index.interface';
import { useRef } from 'react';

export default function useInitApplePay() {
    const isActiveRef = useRef(false);

    const createApplePaySession = (
        currentPackage: IPurchasePackageItem,
        bestPrice: number | null = null,
        bestCredits: number | null = null,
    ) => {
        const countryCodeByCurrency = {
            [CurrencyEnum.USD]: 'US',
            [CurrencyEnum.EUR]: 'DE',
            [CurrencyEnum.GBP]: 'GB',
        };

        const paymentRequest = {
            countryCode: countryCodeByCurrency[currentPackage?.currency || CurrencyEnum.USD],
            currencyCode: currentPackage?.currency || CurrencyEnum.USD,
            merchantCapabilities: ['supports3DS'],
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            total: {
                label: `Buy ${bestCredits || currentPackage?.credits} credits`,
                type: 'final',
                amount: convertPrice(bestPrice || currentPackage?.price),
            },
        };

        return new window.ApplePaySession(3, paymentRequest);
    };

    const startApplePaySession = (
        currentPackage: IPurchasePackageItem,
        hash = '',
        bestPrice: number | null = null,
        bestCredits: number | null = null,
    ) => {
        try {
            if (
                window?.ApplePaySession &&
                // @ts-expect-error ApplePaySession
                ApplePaySession?.canMakePayments() &&
                !isActiveRef.current
            ) {
                const applePaySession = createApplePaySession(
                    currentPackage,
                    bestPrice,
                    bestCredits,
                );

                isActiveRef.current = true;

                applePaySession.onvalidatemerchant = (event: { validationURL: string }) => {
                    new PaymentApi()
                        .postApplePayValidation(event?.validationURL)
                        .then((res) => {
                            if (res?.status) applePaySession.completeMerchantValidation(res?.data);
                            else applePaySession.abort();
                        })
                        .catch(() => applePaySession.abort());
                };

                applePaySession.onpaymentauthorized = (event: { payment: string }) => {
                    new PaymentApi()
                        .postApplePayCreate({
                            credits: currentPackage?.id,
                            payment: event?.payment,
                            hash,
                        })
                        .then((res) => {
                            if (res?.status)
                                applePaySession.completePayment(
                                    window.ApplePaySession.STATUS_SUCCESS,
                                );
                            else
                                applePaySession.completePayment(
                                    window.ApplePaySession.STATUS_FAILURE,
                                );
                        })
                        .catch(() =>
                            applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE),
                        );
                };

                applePaySession.oncomplete = () => {
                    isActiveRef.current = false;
                };
                applePaySession.oncancel = () => {
                    isActiveRef.current = false;
                };

                applePaySession.begin();
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const startApplePayPastaSession = (
        currentPackage: IPurchasePackageItem,
        hash = '',
        bestPrice: number | null = null,
        bestCredits: number | null = null,
    ) => {
        try {
            if (
                window?.ApplePaySession &&
                // @ts-expect-error ApplePaySession
                ApplePaySession?.canMakePayments() &&
                !isActiveRef.current
            ) {
                const applePaySession = createApplePaySession(
                    currentPackage,
                    bestPrice,
                    bestCredits,
                );

                isActiveRef.current = true;

                applePaySession.onvalidatemerchant = (event: { validationURL: string }) => {
                    new PaymentApi()
                        .postApplePayPastaValidation(event?.validationURL)
                        .then((res) => {
                            if (res?.status) applePaySession.completeMerchantValidation(res?.data);
                            else applePaySession.abort();
                        })
                        .catch(() => applePaySession.abort());
                };

                applePaySession.onpaymentauthorized = (event: { payment: string }) => {
                    new PaymentApi()
                        .postApplePayPastaCreate({
                            credits: currentPackage?.id,
                            payment: event?.payment,
                            hash,
                        })
                        .then((res) => {
                            if (res?.status)
                                applePaySession.completePayment(
                                    window.ApplePaySession.STATUS_SUCCESS,
                                );
                            else
                                applePaySession.completePayment(
                                    window.ApplePaySession.STATUS_FAILURE,
                                );
                        })
                        .catch(() =>
                            applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE),
                        );
                };

                applePaySession.oncomplete = () => {
                    isActiveRef.current = false;
                };
                applePaySession.oncancel = () => {
                    isActiveRef.current = false;
                };

                applePaySession.begin();
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    return {
        startApplePaySession,
        startApplePayPastaSession,
    };
}
