import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import GiftsCatalogCategory from '../GiftsCatalogCategory';
import GiftsCartModal from '../GiftsCartModal';
import GiftsCatalogItemModal, { IContentGallery } from '../GiftsCatalogItemModal';
import * as giftsAction from '../../../store/actions/gifts';
import modalStyleProps from '../../../styles/react-modal/modal.style';
import GallerySlider from '../../common/Modals/GallerySlider';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import { RootState } from '../../../index';
import { IGiftItem } from '../../../store/reducers/gifts/index.interface';

export default function GiftsCatalog() {
    const currentGiftsState = useSelector((state: RootState) => state.gifts);

    const { list, giftsByCategories, categories, filters, cart } = currentGiftsState;
    const [modalCartIsOpen, setModalCartIsOpen] = useState(false);
    const [modalItemIsOpen, setModalItemIsOpen] = useState(false);
    const [modalItem, setModalItem] = useState<IGiftItem | null>(null);
    const [galleryContent, setGalleryContent] = useState<IContentGallery[]>([]);
    const [modalOpenGallery, setmodalOpenGallery] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();

    const openGalleryModal = () => setmodalOpenGallery(true);
    const closeGalleryModal = () => setmodalOpenGallery(false);

    const onSelectImage = (index: number, gallery: IContentGallery[]) => {
        setGalleryContent(gallery);
        setSelectedIndex(index);
        openGalleryModal();
    };

    const addToCart = (item: IGiftItem) => {
        dispatch(giftsAction.addToCart(item));
        closeItemModal();
        openCartModal();
    };

    const openCartModal = () => setModalCartIsOpen(true);
    const closeCartModal = () => setModalCartIsOpen(false);

    const openItemModal = (item: IGiftItem) => {
        setModalItem(item);
        setModalItemIsOpen(true);
    };

    const closeItemModal = () => setModalItemIsOpen(false);

    useEffect(() => {
        dispatch(giftsAction.getCategories());
        dispatch(giftsAction.getGifts(handleActiveBlocks.isGiftPaymentUser));
    }, []);

    useEffect(() => {
        dispatch(giftsAction.getGiftsByCategories(list, filters));
    }, [list, filters]);

    return (
        <>
            <div className="c3gp_gifts_page_main">
                {giftsByCategories.map((gifts, key) => (
                    <GiftsCatalogCategory
                        key={key}
                        category={categories[key]}
                        gifts={gifts}
                        addToCart={addToCart}
                        openItemModal={openItemModal}
                    />
                ))}
            </div>
            <Modal
                style={modalStyleProps()}
                isOpen={modalCartIsOpen}
                onRequestClose={closeCartModal}
                ariaHideApp={false}
            >
                <GiftsCartModal closeModal={closeCartModal} cart={cart} categories={categories} />
            </Modal>
            <Modal
                style={modalStyleProps()}
                isOpen={modalItemIsOpen}
                onRequestClose={closeItemModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
            >
                <GiftsCatalogItemModal
                    closeModal={closeItemModal}
                    item={modalItem}
                    cart={cart}
                    addToCart={addToCart}
                    onSelectImage={onSelectImage}
                />
            </Modal>
            <Modal
                style={modalStyleProps({ isAttachment: true })}
                isOpen={modalOpenGallery}
                onRequestClose={closeGalleryModal}
                ariaHideApp={false}
            >
                <GallerySlider
                    gallery={galleryContent}
                    selectedIndex={selectedIndex}
                    closeGalleryModal={closeGalleryModal}
                    gifts={true}
                />
            </Modal>
        </>
    );
}
