import React from 'react';
import ProfileBlockList from '../../components/Profile/ProfileBlockList';

export default function ProfilePageBlockListPage() {
    return (
        <>
            <ProfileBlockList />
        </>
    );
}
