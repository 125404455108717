import BonusApi from '../../services/api/v3/BonusApi';
import * as alertAction from '../../store/actions/alert';
import { useDispatch } from 'react-redux';
import * as userAction from '../../store/actions/user';

export default function useHandleRemarketing() {
    const dispatch = useDispatch();

    const getRemarketingBonus = async () => {
        const result = await new BonusApi()
            .getRemarketingBonus()
            .then((res) => (res?.status ? res?.result : null));

        if (result?.message) {
            dispatch(alertAction.setMessage({ message: result?.message }));
            dispatch(userAction.setBalance());
        }
    };

    return { getRemarketingBonus };
}
