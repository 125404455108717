import React, { useState, useRef } from 'react';
import styles from '../styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeFilterSearch,
    closeMobileMenu,
    closePanel,
    openMobChatList,
    removeBlur,
} from '../../../../services/dom';
import * as currentChatAction from '../../../../store/actions/currentChat';
import * as modalsAction from '../../../../store/actions/modals';
import { useLocation } from 'react-router-dom';
import useTransition from '../../../../hooks/transition/useTransition';
import useHandleChatListItem from '../../../../hooks/chat/useHandleChatListItem';
import useHandleChatFunctions from '../../../../hooks/chat/useHandleChatFunctions';
import useInitModeration from '../../../../hooks/support/useInitModeration';
import ModerationModal from '../../../common/Modals/ModerationModal';
import useHandleMobileNotification from '../../../../hooks/notify/useHandleMobileNotification';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import * as alertAction from '../../../../store/actions/alert';
import * as ALERT from '../../../../constants/alert';
import { RootState } from '../../../../index';

export default function ChatMobileHeader() {
    const notifyRef = useRef(null);
    const menuRef = useRef(null);

    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const [isOpenNotify, setIsOpenNotify] = useState(false);
    const [isOpenMenu, setOpenMenu] = useState(false);

    const handleIsOpen = () => {
        closePanel();
        setOpenMenu(!isOpenMenu);
    };
    const closeMenu = () => {
        setOpenMenu(false);
    };

    const dispatch = useDispatch();
    const location = useLocation();
    const transition = useTransition();
    const initModeration = useInitModeration();
    const handleChatListItem = useHandleChatListItem();
    const handleChatFunctions = useHandleChatFunctions();
    const handleMobileNotification = useHandleMobileNotification();
    const handleActiveBlocks = useHandleActiveBlocks();

    useOutsideClicker(notifyRef, () => setIsOpenNotify(false));
    useOutsideClicker(menuRef, closeMenu);

    const isActiveGift = handleActiveBlocks.isNewMailCategory1
        ? handleActiveBlocks.isPaymentUser
            ? !handleActiveBlocks.isHideGift
            : false
        : !handleActiveBlocks.isHideGift;

    const onBack = () => {
        if (!currentChatState?.info?.chat_uid) return;

        closePanel();
        dispatch(currentChatAction.removeMainChatInfo());
        dispatch(currentChatAction.touchSwipeChatList('right'));
        removeBlur();
        closeMobileMenu();
        closeFilterSearch();
        dispatch(modalsAction.setActiveSubMenu());
        openMobChatList();
        transition.transitionWithOnlyHistoryPush(
            location?.pathname?.replace(`/${currentChatState?.info?.chat_uid}`, ''),
        );
    };
    const onProfile = () => {
        if (!currentChatState?.info?.id) return;
        handleChatListItem.openWomanProfileModal(currentChatState?.info?.id);
    };
    const onFavorite = () => handleChatFunctions.handleSetFavorite();
    const onWriteLetter = () => handleChatFunctions.goTo();
    const onSendGift = () => handleChatFunctions.openGift();
    const onComplain = () => {
        if (!currentChatState?.info?.public_external_id) return;
        initModeration.openModal(
            currentChatState?.info?.public_external_id,
            currentChatState?.info?.id,
        );
    };
    const onBlock = () => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.ADD_TO_BLOCK_LIST,
                successCallback: handleChatFunctions.handleBlackList,
            }),
        );
    };
    const onNotify = () => {
        setIsOpenNotify(true);
        handleMobileNotification.openNotifyBlock();
    };

    return (
        <div className={styles.mobile_chat_header} id="mobile-chat-header">
            <button
                type="button"
                className={styles.mobile_chat_btn_back}
                title="Back"
                onClick={onBack}
            />
            <button type="button" className={styles.mobile_chat_profile} onClick={onProfile}>
                <div className={styles.mobile_chat_profile_photo_wrapper}>
                    <img
                        className={styles.mobile_chat_profile_photo}
                        src={currentChatState?.info?.avatar}
                        width="32"
                        height="32"
                        alt=""
                    />
                    <div
                        className={`${styles.mobile_chat_profile_status} ${currentChatState?.info?.online ? `${styles.active}` : ''}`}
                    />
                </div>
                {currentChatState?.info?.name && (
                    <span className={styles.mobile_chat_profile_name}>
                        {currentChatState?.info?.name}, {currentChatState?.info?.age}
                    </span>
                )}
            </button>
            <button
                onClick={onFavorite}
                type="button"
                className={`${styles.mobile_chat_btn_favourite} ${currentChatState?.info?.favorite ? styles.selected : ''}`}
            />
            <nav className={styles.mobile_chat_navigation} ref={menuRef}>
                <button
                    type="button"
                    className={`${styles.mobile_chat_navigation_burger} ${isOpenMenu ? styles.active : ''}`}
                    onClick={handleIsOpen}
                />
                <ul
                    className={`${styles.mobile_chat_navigation_list} ${isOpenMenu ? styles.active : ''}`}
                >
                    <li className={styles.mobile_chat_navigation_list_item}>
                        <button
                            type="button"
                            className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon} ${styles.icon_letter}`}
                            onClick={onWriteLetter}
                        >
                            Write a letter
                        </button>
                    </li>
                    {isActiveGift && (
                        <li className={styles.mobile_chat_navigation_list_item}>
                            <button
                                type="button"
                                className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon}  ${styles.icon_gift}`}
                                onClick={onSendGift}
                            >
                                Send a Gift
                            </button>
                        </li>
                    )}
                    <li className={styles.mobile_chat_navigation_list_item}>
                        <button
                            type="button"
                            className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon}  ${styles.icon_complaine}`}
                            onClick={onComplain}
                        >
                            Complain
                        </button>
                    </li>
                    <li className={styles.mobile_chat_navigation_list_item}>
                        <button
                            type="button"
                            className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon}  ${styles.icon_blacklist}`}
                            onClick={onBlock}
                        >
                            Add to a block list
                        </button>
                    </li>
                </ul>
            </nav>

            <button
                ref={notifyRef}
                type="button"
                className={`${styles.mobile_chat_btn_notification} ${isOpenNotify ? styles.selected : ''}`}
                onClick={onNotify}
            >
                {!!handleMobileNotification?.countUnread && (
                    <span className={styles.mobile_chat_btn_notification_counter}>
                        {handleMobileNotification?.countUnread}
                    </span>
                )}
                <span className={styles.mobile_chat_btn_notification_icon}></span>
            </button>

            <ModerationModal {...initModeration} />
        </div>
    );
}
