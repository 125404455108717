import { useEffect, useState } from 'react';
import ChatsApi from '../../services/api/ChatsApi';
import { useDispatch, useSelector } from 'react-redux';
import { initCancelTokenSource } from '../../services/methods';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import * as loaderAction from '../../store/actions/loader';
import { useParams } from 'react-router-dom';
import { RootState } from '../../index';
import { IStoreChatListItemModel } from '../../models/chat/index.interface';

export default function useBLockListPage() {
    const dispatch = useDispatch();
    const params: { block_chat_uid?: string } = useParams();

    const chatsState = useSelector((state: RootState) => state.chats);

    const [canceledToken, setCanceledToken] = useState(null);

    const openCurrentChat = (chatInfo: IStoreChatListItemModel) => {
        if (!chatInfo?.chat_uid) return;

        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        dispatch(currentChatAction.addMainChatInfo(chatInfo));
        dispatch(
            currentChatAction.addMainChatHistoryWithReload(
                chatInfo?.chat_uid,
                1,
                source?.token || null,
            ),
        );
    };

    useEffect(() => {
        return () => {
            dispatch(chatsAction.initChatFilters());
        };
    }, []);

    useEffect(() => {
        dispatch(
            chatsAction.getBlockListByUserId(
                chatsState?.onlineStatus,
                chatsState?.searchInput,
                1,
                true,
            ),
        );
    }, [chatsState?.onlineStatus, chatsState?.searchInput]);

    useEffect(() => {
        if (params?.block_chat_uid) {
            let chatInfo = chatsState?.blockList?.length
                ? chatsState?.blockList?.find((item) => item?.chat_uid === params?.block_chat_uid)
                : null;
            dispatch(loaderAction.setActiveGifLoader(true));

            if (chatInfo) {
                openCurrentChat(chatInfo);
                dispatch(loaderAction.setActiveGifLoader(false));
            } else {
                dispatch(chatsAction.setSearchInput(''));
                dispatch(chatsAction.setOnlineStatus(false));
                new ChatsApi().postBlockListByUserId(false, '', 1).then((res) => {
                    if (res?.status && res?.chats?.length) {
                        chatInfo = res?.chats.find(
                            (item) => item?.chat_uid === params?.block_chat_uid,
                        );
                        if (chatInfo) {
                            dispatch(chatsAction.setChatListByUserId(res));
                            openCurrentChat(chatInfo);
                            dispatch(loaderAction.setActiveGifLoader(false));
                        } else {
                            dispatch(loaderAction.setActiveGifLoader(false));
                        }
                    } else {
                        dispatch(loaderAction.setActiveGifLoader(false));
                    }
                });
            }
        }
    }, [params.block_chat_uid, chatsState.status]);
}
