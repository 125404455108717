import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Support from './Support';
import * as SUPPORT from '../../../../constants/support';
import { ISupportModalProps } from './index.interface';

export default function SupportModal({
    modalIsActiveSupport,
    formDataSupport,
    deleteUploadedFileSupport,
    handleInputSupport,
    closeSupportModal,
    onSubmitSupport,
}: ISupportModalProps) {
    return (
        <Modal
            style={modalStyleProps({ zIndex: 99999 })}
            isOpen={modalIsActiveSupport}
            onRequestClose={closeSupportModal}
            ariaHideApp={false}
        >
            <Support
                type={SUPPORT.MODAL_TYPE_HELP}
                delUploadedFile={deleteUploadedFileSupport}
                onSubmitSupport={onSubmitSupport}
                handleInput={handleInputSupport}
                formData={formDataSupport}
                closeSupportModal={closeSupportModal}
            />
        </Modal>
    );
}
