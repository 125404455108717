import React from 'react';

export default function IconTrash() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.15103 3.50576C8.38907 2.61549 9.19522 2.00049 10.1126 2.00049H14.3456C15.2629 2.00049 16.0691 2.61549 16.3071 3.50576C16.3113 3.52122 16.3149 3.53682 16.318 3.55252L16.561 4.76822C16.6437 5.18033 17.0019 5.4798 17.4202 5.48926H20.708C21.1222 5.48926 21.458 5.82504 21.458 6.23926C21.458 6.65347 21.1222 6.98926 20.708 6.98926H17.4594L17.4406 6.98949L17.4071 6.98926H7.05102L7.01758 6.98949L6.99879 6.98926H3.75C3.33579 6.98926 3 6.65347 3 6.23926C3 5.82504 3.33579 5.48926 3.75 5.48926H7.038C7.45629 5.4798 7.81445 5.18062 7.89712 4.76852L8.14012 3.55252C8.14326 3.53682 8.1469 3.52122 8.15103 3.50576ZM15.0902 5.06276C15.1198 5.21075 15.1627 5.3533 15.2175 5.48926H9.24055C9.29542 5.35321 9.33838 5.21056 9.36804 5.06246L9.60531 3.8751C9.67313 3.65314 9.87826 3.50049 10.1126 3.50049H14.3456C14.5799 3.50049 14.785 3.65314 14.8528 3.8751L15.0902 5.06276ZM6.24745 8.93776C6.21305 8.52497 5.85054 8.21823 5.43776 8.25263C5.02497 8.28703 4.71823 8.64954 4.75263 9.06233L5.59981 19.2284C5.71858 20.6537 6.91006 21.75 8.34031 21.75H15.6598C17.09 21.75 18.2815 20.6537 18.4003 19.2284L19.2475 9.06233C19.2818 8.64954 18.9751 8.28703 18.5623 8.25263C18.1495 8.21823 17.787 8.52497 17.7526 8.93776L16.9055 19.1038C16.8515 19.7517 16.3099 20.25 15.6598 20.25H8.34031C7.6902 20.25 7.14861 19.7517 7.09463 19.1038L6.24745 8.93776Z"
            />
        </svg>
    );
}
