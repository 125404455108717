import React from 'react';
import styles from './styles.module.scss';
import stylesImbra from '../../../../Requests/NewRequestContactModal/styles.module.scss';
import useInitMeetingRequest from '../../../../../hooks/meetingRequest/useInitMeetingRequest';
import * as USER from '../../../../../constants/user';
import ImbraModal from '../../ImbraModal';
import LoaderGif from '../../../LoaderGif';
import { IMeetingRequestProps } from './index.interface';
import IconSearch from '../../../../Icons/IconSearch';
import IconVerifyRequestCanceled from '../../../../Icons/IconVerifyRequestCanceled';
import IconVerifyRequestVerified from '../../../../Icons/IconVerifyRequestVerified';
import IconVerifyRequestUpload from '../../../../Icons/IconVerifyRequestUpload';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconCross from '../../../../Icons/IconCross';
import placeholder from '../../../../../img/placeholder.gif';

export default function MeetingRequest({
    profile,
    closeMeetingModal,
    submitMeetingRequest,
}: IMeetingRequestProps) {
    const initMeetingRequest = useInitMeetingRequest({ profile });

    const switcherImbraStatusIcon = () => {
        switch (initMeetingRequest?.imbraStatus) {
            case USER.IMBRA_IN_PROGRESS:
                return (
                    <>
                        <div
                            className={`${stylesImbra.popup_contact_request_imbra_icon} ${stylesImbra.request_icon_search}`}
                        >
                            <IconSearch />
                        </div>
                        <span className={stylesImbra.popup_contact_request_imbra_status}>
                            Status: Verification of documents
                        </span>
                    </>
                );
            case USER.IMBRA_DECLINE:
                return (
                    <>
                        <div className={stylesImbra.popup_contact_request_imbra_icon}>
                            <IconVerifyRequestCanceled />
                        </div>
                        <span
                            className={`${stylesImbra.popup_contact_request_imbra_status} ${stylesImbra.canceled}`}
                        >
                            Canceled
                        </span>
                    </>
                );
            case USER.IMBRA_VERIFIED:
                return (
                    <>
                        <div className={stylesImbra.popup_contact_request_imbra_icon}>
                            <IconVerifyRequestVerified />
                        </div>
                        <span
                            className={`${stylesImbra.popup_contact_request_imbra_status} ${stylesImbra.verified}`}
                        >
                            Verified
                        </span>
                    </>
                );
            default:
                return (
                    <>
                        <div className={stylesImbra.popup_contact_request_imbra_icon}>
                            <IconVerifyRequestUpload />
                        </div>
                        <span className={stylesImbra.popup_contact_request_imbra_status}>
                            Please upload IMBRA
                        </span>
                    </>
                );
        }
    };

    const switcherImbraStatusBtn = (isMob = false) => {
        switch (initMeetingRequest?.imbraStatus) {
            case USER.IMBRA_IN_PROGRESS:
                return (
                    <div
                        className={`${stylesImbra.popup_contact_request_send_btn} ${isMob ? stylesImbra.mob : ''}`}
                    >
                        In progress
                    </div>
                );
            case USER.IMBRA_DECLINE:
                return (
                    <>
                        <div
                            className={`${stylesImbra.popup_contact_request_send_btn} ${isMob ? stylesImbra.mob : ''} ${stylesImbra.canceled}`}
                            onClick={initMeetingRequest.openImbraModal}
                        >
                            Please upload IMBRA document
                        </div>
                        <div
                            className={`${stylesImbra.popup_contact_request_text_reason} ${isMob ? stylesImbra.mob : ''}`}
                        >
                            {initMeetingRequest?.reasonImbraCanceled}
                        </div>
                    </>
                );
            case USER.IMBRA_VERIFIED:
                return (
                    <div
                        className={`${stylesImbra.popup_contact_request_send_btn} ${isMob ? stylesImbra.mob : ''} ${stylesImbra.verified}`}
                    >
                        Verified
                    </div>
                );
            default:
                return (
                    <div
                        className={`${stylesImbra.popup_contact_request_send_btn} ${isMob ? stylesImbra.mob : ''} ${stylesImbra.active}`}
                        onClick={initMeetingRequest.openImbraModal}
                    >
                        Start verify
                    </div>
                );
        }
    };

    return (
        <>
            {initMeetingRequest?.isLoadingModal ? (
                <LoaderGif />
            ) : (
                <div className={styles.popup_big_meeting_request}>
                    <div className={styles.popup_big_meeting_request_wrap}>
                        <div className={styles.popup_big_meeting_request_left}>
                            <div className={styles.popup_big_meeting_request_photo}>
                                <img src={profile?.search_img || placeholder} alt="user avatar" />
                            </div>
                            <div className={styles.popup_big_meeting_request_profile_info}>
                                <div className={styles.popup_big_meeting_request_name}>
                                    {`${profile?.name || ''}, ${profile?.age || ''}`}
                                </div>
                                <div className={styles.popup_big_meeting_request_profile_info_bot}>
                                    <div className={styles.popup_big_meeting_request_id}>
                                        ID {profile?.public_external_id}
                                    </div>
                                    <div className={styles.popup_big_meeting_request_place}>
                                        {profile?.country_image?.length ? (
                                            <img
                                                src={`/assets/flags/${profile?.country_image?.split('/flags/')[1]}`}
                                                alt=""
                                            />
                                        ) : profile?.country_code?.length ? (
                                            <img
                                                src={`/assets/flags/${profile?.country_code?.toLowerCase()}.jpg`}
                                                alt=""
                                            />
                                        ) : (
                                            <div />
                                        )}
                                        <span>{profile?.city}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.popup_big_meeting_request_top}>
                            <div className={styles.popup_big_meeting_request_title}>
                                Meeting request
                            </div>
                            <div className={styles.popup_big_meeting_request_subtitle}>
                                Requesting a <span>meeting with a member</span> you like implies
                                that you will visit their city of residence. As a rule, this meeting
                                may last from 3 to 7 days.
                            </div>
                        </div>
                        <div className={styles.popup_big_meeting_request_middle}>
                            <div className={styles.popup_big_meeting_request_middle_wrap}>
                                <div className={styles.popup_big_meeting_request_second_text}>
                                    <p>
                                        After receiving a request, your conversational partner has
                                        72 hours to confirm or reject a meeting request.
                                    </p>
                                    <p>
                                        You can organize a meeting absolutely independently or
                                        contact our partners, who will help you to set it up. They
                                        will do everything possible to make your meeting flow
                                        perfectly so that you will remember it for a long time!
                                    </p>
                                </div>
                                <div className={styles.popup_big_meeting_request_first_text}>
                                    <p>
                                        Please keep in mind that to make a meeting request, you must
                                        first get the member’s contact details through the “Contact
                                        Request” Service.
                                    </p>
                                    <div className={styles.popup_big_meeting_request_first_subtext}>
                                        <IconCheckedCircle />
                                        <span>
                                            If a member agrees to meet you in person, a
                                            representative of our partner company will contact you
                                            to discuss all the details.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {initMeetingRequest?.isUserGetContactApprove && (
                                <div className={stylesImbra.popup_contact_request_imbra}>
                                    <div className={stylesImbra.popup_contact_request_imbra_left}>
                                        <div
                                            className={
                                                stylesImbra.popup_contact_request_imbra_title
                                            }
                                        >
                                            IMBRA verification
                                        </div>
                                        <div
                                            className={stylesImbra.popup_contact_request_imbra_text}
                                        >
                                            After you’ve made a meeting request, our moderation team
                                            will give you feedback within 96 hours and inform you of
                                            whether the member has agreed to your request or not.
                                        </div>
                                        {switcherImbraStatusBtn()}
                                    </div>
                                    <div className={stylesImbra.popup_contact_request_imbra_right}>
                                        <div
                                            className={
                                                stylesImbra.popup_contact_request_imbra_right_col
                                            }
                                        >
                                            <div
                                                className={
                                                    stylesImbra.popup_contact_request_imbra_subtitle
                                                }
                                            >
                                                Status
                                            </div>
                                            {switcherImbraStatusIcon()}
                                        </div>
                                    </div>
                                    {switcherImbraStatusBtn(true)}
                                </div>
                            )}
                        </div>
                        <div className={styles.popup_big_meeting_request_bottom}>
                            <div className={styles.popup_big_meeting_request_price} />
                            <div
                                className={`${styles.popup_big_meeting_request_send_btn} ${!initMeetingRequest?.isAllowGetMeetingRequest ? styles.popup_big_meeting_request_send_btn_dis : ''}`}
                                onClick={() =>
                                    initMeetingRequest?.isAllowGetMeetingRequest &&
                                    submitMeetingRequest(profile)
                                }
                            >
                                <IconCheckedCircle />
                                <span>Make request</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.popup_big_meeting_request_close}
                        onClick={closeMeetingModal}
                    >
                        <IconCross />
                    </div>
                    <ImbraModal {...initMeetingRequest} />
                </div>
            )}
        </>
    );
}
