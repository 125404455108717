import React from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatAction from '../../../../store/actions/currentChat';
import * as currentChatMailboxAction from '../../../../store/actions/currentChatMailbox';
import * as MENU from '../../../../constants/menu';
import { closeMobChatList, closePanel, openMobChatList } from '../../../../services/dom';
import { closeMobileMenu } from '../../../../services/dom';
import useTransition from '../../../../hooks/transition/useTransition';
import * as SEX from '../../../../constants/sex';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import IconChat from '../../../Icons/IconChat';
import IconUnread from '../../../Icons/IconUnread';
import IconFavourites from '../../../Icons/IconFavourites';
import IconLetters from '../../../Icons/IconLetters';
import IconOnlineNow from '../../../Icons/IconOnlineNow';
import IconSearch from '../../../Icons/IconSearch';
import IconLikeMenu from '../../../Icons/IconLikeMenu';
import IconVideos from '../../../Icons/IconVideos';
import IconFAQ from '../../../Icons/IconFAQ';
import IconTutorial from '../../../Icons/IconTutorial';
import IconPolicy from '../../../Icons/IconPolicy';
import IconYourLikes from '../../../Icons/IconYourLikes';
import { RootState } from '../../../../index';
import { isHolidayActive } from '../../../../constants/holiday';

export default function MenuBody() {
    const menuState = useSelector((state: RootState) => state.menu);
    const countersState = useSelector((state: RootState) => state.counters);
    const userState = useSelector((state: RootState) => state.user);
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);

    const transition = useTransition();
    const dispatch = useDispatch();
    const handleActiveBlocks = useHandleActiveBlocks();
    const activeHoliday = isHolidayActive();

    const onSetMainMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const menuTitle = event?.currentTarget?.dataset?.name || '';

        if (menuTitle === MENU.MENU_CHAT) {
            dispatch(currentChatAction.removeMainChatInfo());
        }

        if (menuTitle === MENU.MENU_LETTER) {
            if (currentChatMailboxState?.info?.female_id) {
                dispatch(currentChatMailboxAction.removeMailboxChat());
            }

            closeMobChatList();
            closePanel();
        }

        transition.transitionWithHistoryPush(menuTitle, `/${menuTitle}`);

        closeMobileMenu();
    };

    const onSetSubMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const menuTitle = event?.currentTarget?.dataset?.name || '';

        dispatch(currentChatAction.removeMainChatInfo());
        transition.transitionWithHistoryPush(menuTitle, `/${menuTitle}`);

        closeMobileMenu();
        if (window.innerWidth < 900) openMobChatList();
    };

    return (
        <>
            <div className={styles.menu_wrap} data-testid="main-menu">
                <div className={styles.menu_scroll}>
                    <ul
                        className={`${styles.menu_list} ${activeHoliday && styles[`${activeHoliday?.name}`]}`}
                    >
                        {handleActiveBlocks?.menu?.isActiveChatMenu && (
                            <li className={`${styles.menu_list_item} ${styles.item_with_submenu}`}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${menuState.main === MENU.MENU_CHAT && !menuState?.sub?.length ? styles.active : ''}`}
                                    data-name={MENU.MENU_CHAT}
                                    data-testid={MENU.MENU_CHAT}
                                    onClick={onSetMainMenu}
                                >
                                    <IconChat />
                                    <span>All chats</span>
                                </button>
                                <ul className={styles.submenu_list}>
                                    <li className={styles.submenu_list_item}>
                                        <button
                                            type="button"
                                            className={`${styles.submenu_list_button}
                                                ${+(countersState?.chatCounter?.unanswered || 0) > 0 ? styles.new_badge : ''}
                                                ${menuState.sub === MENU.MENU_UNANSWERED ? styles.active : ''}`}
                                            data-name={MENU.MENU_UNANSWERED}
                                            data-testid={MENU.MENU_UNANSWERED}
                                            onClick={onSetSubMenu}
                                        >
                                            <IconUnread />
                                            <span>Unread</span>
                                        </button>
                                    </li>
                                    <li className={styles.submenu_list_item}>
                                        <button
                                            type="button"
                                            className={`${styles.submenu_list_button} ${menuState.sub === MENU.MENU_FAVORITE ? styles.active : ''}`}
                                            data-name={MENU.MENU_FAVORITE}
                                            data-testid={MENU.MENU_FAVORITE}
                                            onClick={onSetSubMenu}
                                        >
                                            <IconFavourites />
                                            <span>Favorites</span>
                                        </button>
                                    </li>
                                    <li className={styles.submenu_list_item}>
                                        <button
                                            type="button"
                                            className={`${styles.submenu_list_button} ${menuState.sub === MENU.MENU_LIKE ? styles.active : ''}`}
                                            data-name={MENU.MENU_LIKE}
                                            data-testid={MENU.MENU_LIKE}
                                            onClick={onSetSubMenu}
                                        >
                                            <IconYourLikes />
                                            <span>Your likes</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveLetterMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} 
                                        ${+(countersState?.letterCounter || 0) > 0 ? styles.new_badge : ''}
                                        ${menuState.main === MENU.MENU_LETTER ? styles.active : ''}`}
                                    data-name={MENU.MENU_LETTER}
                                    data-testid={MENU.MENU_LETTER}
                                    onClick={onSetMainMenu}
                                >
                                    <IconLetters />
                                    <span>Letters</span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveNewsfeedMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${menuState.main === MENU.MENU_POSTS ? styles.active : ''}`}
                                    data-name={MENU.MENU_POSTS}
                                    data-testid={MENU.MENU_POSTS}
                                    onClick={onSetSubMenu}
                                >
                                    <IconOnlineNow />
                                    <span>Newsfeed</span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveOnlineNowMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${menuState.main === MENU.MENU_ONLINE_NOW ? styles.active : ''}`}
                                    data-name={MENU.MENU_ONLINE_NOW}
                                    data-testid={MENU.MENU_ONLINE_NOW}
                                    onClick={onSetSubMenu}
                                >
                                    <IconOnlineNow />
                                    <span>Online now</span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveSearchMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${menuState.main === MENU.MENU_SEARCH ? styles.active : ''}`}
                                    data-name={MENU.MENU_SEARCH}
                                    data-testid={MENU.MENU_SEARCH}
                                    onClick={onSetMainMenu}
                                >
                                    <IconSearch />
                                    <span>
                                        {
                                            SEX.SEX_TEXT[
                                                (userState?.info
                                                    ?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                                    SEX.MALE_SEX
                                            ].SEARCH_SIDE_MENU_BTN
                                        }
                                    </span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveLikeMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${
                                        menuState.main ===
                                        SEX.SEX_TEXT[
                                            (userState?.info
                                                ?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                                SEX.MALE_SEX
                                        ].LIKE_URL
                                            ? styles.active
                                            : ''
                                    }`}
                                    data-name={MENU.MENU_LIKE_HER}
                                    data-testid={MENU.MENU_LIKE_HER}
                                    onClick={onSetMainMenu}
                                >
                                    <IconLikeMenu />
                                    <span>
                                        {
                                            SEX.SEX_TEXT[
                                                (userState?.info
                                                    ?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                                    SEX.MALE_SEX
                                            ].LIKE_SIDE_MENU_BTN
                                        }
                                    </span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveVideoMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${menuState.main === MENU.VIDEO_SHOW ? styles.active : ''}`}
                                    data-name={MENU.VIDEO_SHOW}
                                    data-testid={MENU.VIDEO_SHOW}
                                    onClick={onSetMainMenu}
                                >
                                    <IconVideos />
                                    <span>
                                        {
                                            SEX.SEX_TEXT[
                                                (userState?.info
                                                    ?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                                    SEX.MALE_SEX
                                            ].VIDEOSHOW_SIDE_MENU_BTN
                                        }
                                    </span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveFAQMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${menuState.main === MENU.FAQ_PAGE ? styles.active : ''}`}
                                    data-name={MENU.FAQ_PAGE}
                                    data-testid={MENU.FAQ_PAGE}
                                    onClick={onSetMainMenu}
                                >
                                    <IconFAQ />
                                    <span>
                                        {
                                            SEX.SEX_TEXT[
                                                (userState?.info
                                                    ?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                                    SEX.MALE_SEX
                                            ].FAQ_SIDE_MENU_BTN
                                        }
                                    </span>
                                </button>
                            </li>
                        )}
                        {handleActiveBlocks?.menu?.isActiveTutorialMenu && (
                            <li className={styles.menu_list_item}>
                                <button
                                    type="button"
                                    className={`${styles.menu_list_button} ${menuState.main === MENU.TUTORIAL_PAGE ? styles.active : ''}`}
                                    data-name={MENU.TUTORIAL_PAGE}
                                    data-testid={MENU.TUTORIAL_PAGE}
                                    onClick={onSetMainMenu}
                                >
                                    <IconTutorial />
                                    <span>
                                        {
                                            SEX.SEX_TEXT[
                                                (userState?.info
                                                    ?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                                    SEX.MALE_SEX
                                            ].TUTORIAL_SIDE_MENU_BTN
                                        }
                                    </span>
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className={styles.bottom_wrap} data-testid="policy-menu">
                <button
                    type="button"
                    className={styles.policy}
                    data-name={MENU.POLICY_PAGE}
                    data-testid={MENU.POLICY_PAGE}
                    onClick={(event) => {
                        onSetMainMenu(event);
                        event?.currentTarget?.blur();
                    }}
                >
                    <IconPolicy />
                    <span>
                        {
                            SEX.SEX_TEXT[
                                (userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT) ||
                                    SEX.MALE_SEX
                            ].POLICY_SIDE_MENU_BTN
                        }
                    </span>
                </button>
            </div>
        </>
    );
}
