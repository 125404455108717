import React from 'react';

export default function IconMobileMenu() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.05005 6C2.05005 5.30964 2.60969 4.75 3.30005 4.75H21.3C21.9904 4.75 22.55 5.30964 22.55 6C22.55 6.69036 21.9904 7.25 21.3 7.25H3.30005C2.60969 7.25 2.05005 6.69036 2.05005 6ZM2.05005 12C2.05005 11.3096 2.60969 10.75 3.30005 10.75H21.3C21.9904 10.75 22.55 11.3096 22.55 12C22.55 12.6904 21.9904 13.25 21.3 13.25H3.30005C2.60969 13.25 2.05005 12.6904 2.05005 12ZM3.30005 16.75C2.60969 16.75 2.05005 17.3096 2.05005 18C2.05005 18.6904 2.60969 19.25 3.30005 19.25H21.3C21.9904 19.25 22.55 18.6904 22.55 18C22.55 17.3096 21.9904 16.75 21.3 16.75H3.30005Z"
            />
        </svg>
    );
}
