import React, { useEffect } from 'react';
import './style.scss';
import * as SEX from '../../../../../constants/sex';
import { getReadableFileSizeString, cutLengthString } from '../../../../../services/methods';
import ProfileApi from '../../../../../services/api/ProfileApi';
import * as LENGTH from '../../../../../constants/length';
import { useSelector } from 'react-redux';
import { IModerationProps } from './index.interface';
import { RootState } from '../../../../../index';

export default function Moderation({
    delUploadedFile,
    onSubmit,
    handleInput,
    formData,
    closeModal,
    setFormData,
    validationEmail,
}: IModerationProps) {
    const userState = useSelector((state: RootState) => state.user);

    useEffect(() => {
        new ProfileApi().getEmail().then((res) => {
            if (res?.status) {
                setFormData({ ...formData, ...{ email: res.message } });
            }
        });
    }, []);

    return (
        <div className="popup_support_wrap">
            <form onSubmit={onSubmit}>
                <div className="popup_support_head">Complaint</div>
                <div className="popup_support_body">
                    <input
                        type="text"
                        name="email"
                        className={`popup_support_email_input ${validationEmail ? 'invalid' : ''}`}
                        placeholder="Your E-Mail"
                        value={formData.email}
                        onChange={handleInput}
                    />
                    <div className="popup_support_title_id_wrap">
                        <div className="popup_support_title">
                            {
                                SEX.SEX_TEXT[userState?.info?.sex_text as 'MALE_SEX' | 'FEMALE_SEX']
                                    .MODERATION_MODAL_ID
                            }
                        </div>
                        <div className="popup_support_title_id">{formData.id}</div>
                    </div>
                    {/*<input type="text" className="popup_support_input_text" value={formData.id} disabled/>*/}
                    {/*<div className="popup_support_title">Reason</div>*/}
                    {/*<div className="popup_support_desc">Please, describe the reason</div>*/}
                    <textarea
                        className="popup_support_textarea"
                        name="reason"
                        placeholder="Please, describe the reason"
                        value={formData.reason}
                        onChange={handleInput}
                    />
                    {formData.file && formData.file.name ? (
                        <div className="popup_support_uploaded">
                            <div className="popup_support_uploaded_text">File uploaded</div>
                            <div className="popup_support_uploaded_name">
                                {cutLengthString(formData.file.name, LENGTH.SUPPORT_IMG_MAX_LENGTH)}
                            </div>
                            <div className="popup_support_uploaded_size">
                                {`(${getReadableFileSizeString(formData.file.size)})`}
                            </div>
                            <div className="popup_support_uploaded_del" onClick={delUploadedFile}>
                                {/*<img src="/img/popup_support_uploaded_del.svg" alt=""/>*/}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            {/*<div className="popup_support_title">Upload file</div>*/}
                            <input
                                type="file"
                                id="popup_support_input"
                                className="popup_support_input"
                                onChange={handleInput}
                            />
                            <label htmlFor="popup_support_input" className="popup_support_label">
                                {/*<img src="/img/support-file-ico.svg" alt=""/>*/}
                                <span>"Choose your file, please"</span>
                            </label>
                        </React.Fragment>
                    )}
                    <div className="popup_support_send_info_hr" />
                    <div className="popup_support_send_info_text">
                        If you haven't mentioned your e-mail address during registration, please,
                        contact us at{' '}
                        <a href={`mailto:${userState?.supportEmail}`}>{userState?.supportEmail}</a>
                    </div>
                    <button className="popup_support_send_btn" type="submit" onClick={onSubmit}>
                        <span className="popup_support_send_btn_text">Send</span>
                    </button>
                </div>
            </form>
            <div className="popup_complaint_close" onClick={closeModal}></div>
        </div>
    );
}
