import React from 'react';
import styles from './styles.module.scss';
import * as USER from '../../../../../constants/user';
import ImbraFormModal from '../../ImbraFormModal';
import useInitImbraFormModal from '../../../../../hooks/imbra/useInitImbraFormModal';
import PropTypes from 'prop-types';
import { IImbraProps } from './index.interface';
import IconSearch from '../../../../Icons/IconSearch';
import IconFolderDownload from '../../../../Icons/IconFolderDownload';
import IconFolderUpload from '../../../../Icons/IconFolderUpload';
import IconVerifyRequestCanceled from '../../../../Icons/IconVerifyRequestCanceled';
import IconVerifyRequestVerified from '../../../../Icons/IconVerifyRequestVerified';
import IconClockRound from '../../../../Icons/IconClockRound';
import IconCloudDownload from '../../../../Icons/IconCloudDownload';
import IconCloudUpload from '../../../../Icons/IconCloudUpload';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';

Imbra.propTypes = {
    closeModal: PropTypes.func,
    uploadImbraFile: PropTypes.func,
    imbraStatus: PropTypes.number,
};
export default function Imbra({ closeModal, uploadImbraFile, imbraStatus }: IImbraProps) {
    const initImbraFormModal = useInitImbraFormModal();

    const switcherImbraStatusIcon = () => {
        switch (imbraStatus) {
            case USER.IMBRA_IN_PROGRESS:
                return (
                    <>
                        <div
                            className={`${styles.popup_imbra_verification_img} ${styles.status_verification}`}
                        >
                            <IconSearch />
                        </div>
                        <span className={styles.popup_imbra_verification_status}>
                            Status: Verification of documents
                        </span>
                    </>
                );
            case USER.IMBRA_DECLINE:
                return (
                    <>
                        <div
                            className={`${styles.popup_imbra_verification_img} ${styles.status_canceled}`}
                        >
                            <IconVerifyRequestCanceled />
                        </div>
                        <span
                            className={`${styles.popup_imbra_verification_status} ${styles.canceled}`}
                        >
                            Status: Canceled
                        </span>
                    </>
                );
            case USER.IMBRA_VERIFIED:
                return (
                    <>
                        <div
                            className={`${styles.popup_imbra_verification_img} ${styles.status_verified}`}
                        >
                            <IconVerifyRequestVerified />
                        </div>
                        <span
                            className={`${styles.popup_imbra_verification_status} ${styles.verified}`}
                        >
                            Status: verified
                        </span>
                    </>
                );
            default:
                return (
                    <>
                        <div
                            className={`${styles.popup_imbra_verification_img} ${styles.status_waiting}`}
                        >
                            <IconClockRound />
                        </div>
                        <span className={styles.popup_imbra_verification_status}>
                            Status: waiting for IMBRA scan/photo
                        </span>
                    </>
                );
        }
    };

    return (
        <div className={styles.popup_imbra}>
            <div className={styles.popup_imbra_title}>Why IMBRA is important?</div>
            <div className={styles.popup_imbra_text}>
                <p>
                    We are concerned about the safety of our users. We also cannot violate the
                    standards set by the current US law. Therefore, before contact details can be
                    transferred or a meeting can take place, we ask that you fill out the
                    appropriate questionnaire. We emphasize that, once again, this is not an
                    initiative set forth by the platform, but rather a requirement of the law. Thank
                    you for your patience and understanding.
                </p>
            </div>
            <div className={styles.popup_imbra_verification}>
                <h3 className={styles.popup_imbra_verification_title}>IMBRA verification steps</h3>
                <div className={styles.popup_imbra_verification_steps}>
                    <div className={styles.popup_imbra_verification_steps_col}>
                        <div
                            className={`${styles.popup_imbra_verification_img} ${styles.imbra_download}`}
                        >
                            <IconFolderDownload />
                        </div>
                        <div
                            className={`${styles.popup_imbra_btn} ${styles.active}`}
                            onClick={initImbraFormModal.openImbraFormModal}
                        >
                            <IconCloudDownload />
                            Fill out the IMBRA form
                        </div>
                    </div>

                    <div className={styles.popup_imbra_verification_steps_col}>
                        <div
                            className={`${styles.popup_imbra_verification_img} ${styles.imbra_upload}`}
                        >
                            <IconFolderUpload />
                        </div>
                        {imbraStatus === USER.IMBRA_IN_PROGRESS ? (
                            <div
                                className={`${styles.popup_imbra_btn} ${styles.upload_successful}`}
                            >
                                <IconCheckedCircle />
                                Upload successful
                            </div>
                        ) : (
                            <label>
                                <div className={`${styles.popup_imbra_btn} ${styles.active}`}>
                                    <IconCloudUpload />
                                    Upload a scan/photo of your IMBRA form.
                                </div>
                                <input type="file" onChange={uploadImbraFile} />
                            </label>
                        )}
                    </div>
                    <div className={styles.popup_imbra_verification_steps_col}>
                        {switcherImbraStatusIcon()}
                    </div>
                </div>
            </div>

            <div className={styles.popup_imbra_close} onClick={() => closeModal()} />

            <ImbraFormModal {...initImbraFormModal} />
        </div>
    );
}
