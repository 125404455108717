export enum BillingActionEnum {
    CLOSE_PAYMENT_PAGE = 'CLOSE_PAYMENT_PAGE',
    OPEN_PAYMENT_PAGE = 'OPEN_PAYMENT_PAGE',
    RECURRING_CANCEL = 'RECURRING_CANCEL',
    RECURRING_CHANGE = 'RECURRING_CHANGE',
    RECURRING_CONFIRM = 'RECURRING_CONFIRM',
    RECURRING_CREATE = 'RECURRING_CREATE',
    OPEN_PAYMENT_ROUTING_MODAL = 'OPEN_PAYMENT_ROUTING_MODAL',
    CLOSE_PAYMENT_ROUTING_MODAL = 'CLOSE_PAYMENT_ROUTING_MODAL',
    RECURRING_ROUTING_CANCEL = 'RECURRING_ROUTING_CANCEL',
    OPEN_PAYMENT_ROUTING_PAGE = 'OPEN_PAYMENT_ROUTING_PAGE',
    CLOSE_PAYMENT_ROUTING_PAGE = 'CLOSE_PAYMENT_ROUTING_PAGE',
    LAUNCH_PAYMENT_ROUTING_ERROR = 'LAUNCH_PAYMENT_ROUTING_ERROR',
    INIT_PAYMENT_ROUTING_ERROR = 'INIT_PAYMENT_ROUTING_ERROR',
}

export interface ICreateBillingLogData {
    deviceID: string;
    device: string;
    link: string;
    action: keyof typeof BillingActionEnum;
    extra?: any;
}
