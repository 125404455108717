import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import uploadImg from '../../../img/onboarding/upload.svg';
import { checkFileAndShowAlert } from '../../../services/methods';
import * as ATTACH from '../../../constants/attach';
import * as alertAction from '../../../store/actions/alert';
import { useDispatch, useSelector } from 'react-redux';
import awsUploadFile from '../../../services/aws/uploadFile';
import * as AWS from '../../../constants/aws';
import * as loaderAction from '../../../store/actions/loader';
import { sendErrToSentry } from '../../../services/sentry';
import { RootState } from '../../../index';

interface IOnboardingUploadPhoto {
    title: string;
    activeStep: number;
    maxStep: number;
    value: string;
    onClick: () => void;
    onChange: (value: string) => void;
    skipStep: () => void;
}

const OnboardingUploadPhoto = ({
    title,
    activeStep,
    maxStep,
    value,
    onClick,
    onChange,
    skipStep,
}: IOnboardingUploadPhoto) => {
    const fileRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch();

    const userState = useSelector((state: RootState) => state.user);

    const [file, setFile] = useState('');

    const onClickUpload = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };
    const uploadPhoto = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e?.target?.files && e?.target?.files[0]) {
            if (
                !checkFileAndShowAlert(
                    e?.target?.files[0],
                    ATTACH.TYPE_IMAGE,
                    (message: string, title: string) => {
                        dispatch(alertAction.setMessage({ message, title }));
                    },
                )
            ) {
                return;
            }

            dispatch(loaderAction.setActiveGifLoader(true));

            const reader = new FileReader();
            reader.readAsDataURL(e?.target?.files[0]);
            reader.onload = function () {
                setFile(reader.result as string);
            };

            try {
                awsUploadFile(
                    e?.target?.files[0],
                    'avatar',
                    userState?.info?.external_id?.toString() || '',
                    AWS.MEN_IMAGES,
                )
                    .then(async (res) => {
                        if (res?.success && res?.data?.link) onChange(res?.data?.link);
                        else {
                            if (res?.message || res?.error) {
                                dispatch(
                                    alertAction.setMessage({ message: res?.message || res?.error }),
                                );
                            }
                        }
                    })
                    .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
            } catch (error) {
                if (error instanceof Error) {
                    sendErrToSentry(error);
                }
                dispatch(loaderAction.setActiveGifLoader(false));
            }
        }
    };

    useEffect(() => {
        if (value) setFile(value);
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>Upload photo</p>

            <div
                className={`${styles.upload_wrapper} ${file ? styles.upload_img : ''}`}
                onClick={onClickUpload}
            >
                <input type="file" hidden ref={fileRef} accept="image/*" onChange={uploadPhoto} />

                {file ? (
                    <img src={file} alt={''} />
                ) : (
                    <>
                        <img src={uploadImg} alt={''} />
                        <p>Upload from device</p>
                    </>
                )}
            </div>

            {file ? (
                <button className={styles.button} onClick={onClick}>
                    Confirm
                </button>
            ) : (
                <button className={styles.button} onClick={skipStep}>
                    I’ll do it later
                </button>
            )}
        </div>
    );
};

export default OnboardingUploadPhoto;
