import React from 'react';
import styles from './styles.module.scss';
import RateNPS from './RateNPS';
import MixNPS from './MixNPS';
import TextareaNPS from './TextareaNPS';
import SuccessNPS from './SuccessNPS';
import { INPSProps } from './index.interface';

export default function NPS({
    closeNPSModal,
    pollShownId,
    npsData,
    setNpsData,
    npsTitle,
    npsQuestion,
}: INPSProps) {
    const getNps = () => {
        switch (npsData.pollType) {
            case 'scale':
                return (
                    <RateNPS
                        pollShownId={pollShownId}
                        setNpsData={setNpsData}
                        npsData={npsData}
                        npsTitle={npsTitle}
                    />
                );
            case 'mix':
                return (
                    <MixNPS
                        pollShownId={pollShownId}
                        setNpsData={setNpsData}
                        npsData={npsData}
                        npsTitle={npsTitle}
                        npsQuestion={npsQuestion}
                    />
                );
            case 'text':
                return (
                    <TextareaNPS
                        pollShownId={pollShownId}
                        setNpsData={setNpsData}
                        npsData={npsData}
                        npsQuestion={npsQuestion}
                    />
                );
            case 'success':
                return <SuccessNPS closeNPSModal={closeNPSModal} />;
            default:
                return;
        }
    };

    return (
        <div
            className={`${styles.nps_modal} ${npsData.pollType === 'success' ? styles.success : ''}`}
            data-testid="nps-modal"
        >
            <h2 className={styles.nps_modal_title}>
                {npsData.pollType === 'success' ? 'Thank You!' : 'Survey'}
            </h2>
            {getNps()}
            <button
                type="button"
                className={styles.nps_modal_btn_close}
                onClick={closeNPSModal}
                data-testid="close-btn"
            />
        </div>
    );
}
