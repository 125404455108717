import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as LETTER from '../../constants/letters';
import { scrollSmoothDownInLetterChat } from '../../services/dom';
import { isTopPositionInArrHistoryMails } from '../../services/methods';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as notifyAction from '../../store/actions/notify';
import { closeMobChatList, closePanel } from '../../services/dom';
import * as countersAction from '../../store/actions/counters';
import MailboxApi from '../../services/api/MailboxApi';
import useHandleLettersListItem from './useHandleLettersListItem';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { RootState } from '../../index';

export default function useHandleLettersChat() {
    const dispatch = useDispatch();
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const userState = useSelector((state: RootState) => state.user);
    const notifyState = useSelector((state: RootState) => state.notify);
    const handleLettersListItem = useHandleLettersListItem();
    const handleActiveBlocks = useHandleActiveBlocks();

    const [pageShiftUp, setPageShiftUp] = useState<number>(0);
    const [pageShiftDown, setPageShiftDown] = useState<number>(0);
    const [isViewLetterHistory, setIsViewLetterHistory] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(
        currentChatMailboxState?.info?.actionTarget === LETTER.ACTION_TARGET_REPLY ?? false,
    );

    function scrollTopToSelectedLetter(id: number, alignToTop: boolean) {
        const block = document.getElementById(`letter-${id}`);
        if (block) block.scrollIntoView(alignToTop);
    }

    const scrollPosition = (position: number) => {
        if (
            currentChatMailboxState?.history?.length > 0 &&
            currentChatMailboxState?.specialLetterId
        ) {
            scrollTopToSelectedLetter(
                currentChatMailboxState?.specialLetterId,
                isTopPositionInArrHistoryMails(currentChatMailboxState?.history, position),
            );
        }
    };

    const readLetter = () => {
        if (handleActiveBlocks.isNewMailCategory1) {
            const newHistory = [
                ...(currentChatMailboxState?.history?.length
                    ? currentChatMailboxState.history
                    : []),
            ];
            const lastFreeMail = [...newHistory]
                ?.reverse()
                ?.find((item) => !+item?.mail?.mail_read_status && !!+item?.mail?.paid_read);
            if (lastFreeMail?.mail?.id) {
                handleLettersListItem.paidNewLetterText(
                    lastFreeMail?.mail?.id,
                    lastFreeMail?.mail?.female_id,
                );
            }
        }

        dispatch(currentChatMailboxAction.setIsNewMessage(false));

        if (userState?.info?.external_id) {
            new MailboxApi()
                .postReadMail(
                    currentChatMailboxState?.info?.id,
                    userState?.info?.external_id,
                    currentChatMailboxState?.info?.female_id,
                )
                .finally(() => {
                    if (userState?.info?.external_id) {
                        dispatch(countersAction.getLetterCounter(userState?.info?.external_id));
                    }
                });
        }
    };

    const onScrollList = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const target = event.target as HTMLElement;
        // Pagination on scroll up
        if (
            target.scrollTop === 0 &&
            target.classList.value.includes('scroll') &&
            pageShiftUp &&
            +pageShiftUp < +(currentChatMailboxState.historyAllPages || 0) &&
            userState?.info?.external_id &&
            isViewLetterHistory
        ) {
            dispatch(
                currentChatMailboxAction.getMailboxChatHistoryWithPageUp({
                    folder: 'dialog',
                    userId: userState?.info?.external_id,
                    girlId: currentChatMailboxState?.info?.public_external_id,
                    page: +pageShiftUp + 1,
                }),
            );
            dispatch(
                currentChatMailboxAction.addMailboxSpecialLetter(
                    currentChatMailboxState.history[0]?.mail?.id,
                ),
            );
            setPageShiftUp(+pageShiftUp + 1);
        }
        // Pagination on scroll down
        if (
            Math.round(target.scrollTop + target.offsetHeight) >= target.scrollHeight &&
            pageShiftDown &&
            +pageShiftDown > 1 &&
            userState?.info?.external_id &&
            isViewLetterHistory
        ) {
            dispatch(
                currentChatMailboxAction.getMailboxChatHistoryWithPageDown({
                    folder: 'dialog',
                    userId: userState?.info?.external_id,
                    girlId: currentChatMailboxState?.info?.public_external_id,
                    page: +pageShiftDown - 1,
                }),
            );
            dispatch(
                currentChatMailboxAction.addMailboxSpecialLetter(
                    currentChatMailboxState?.history[currentChatMailboxState?.history?.length - 1]
                        ?.mail?.id,
                ),
            );
            setPageShiftDown(+pageShiftDown - 1);
        }
        // Hide button "new message", if the scroll is bellow
        // Math.round(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight && dispatch(currentChatAction.setIsNewMessage(false));
    };

    const scrollDown = () => {
        if (!userState?.info?.external_id) return;

        dispatch(
            currentChatMailboxAction.getMailboxChatHistory({
                userId: userState?.info?.external_id,
                girlId: currentChatMailboxState?.info?.public_external_id,
                folder: 'dialog',
                page: 1,
            }),
        );

        dispatch(currentChatMailboxAction.setIsNewMessage(false));
        setTimeout(() => scrollSmoothDownInLetterChat(), 500);
    };

    const returnToLetterList = () => {
        if (currentChatMailboxState?.info?.female_id) {
            dispatch(currentChatMailboxAction.removeMailboxChat());
        }
        closeMobChatList();
        closePanel();
    };

    const onOpenForm = () => {
        scrollDown();
        dispatch(currentChatMailboxAction.setInfoCurrentTarget(LETTER.ACTION_TARGET_REPLY));
        setIsOpenForm(true);
        setIsViewLetterHistory(true);
    };

    const viewLetterHistory = () => {
        if (!userState?.info?.external_id) return;

        dispatch(currentChatMailboxAction.setInfoCurrentTarget(LETTER.ACTION_TARGET_REPLY));
        setIsViewLetterHistory(true);
        dispatch(
            currentChatMailboxAction.getMailboxChatHistorySendMess(
                {
                    userId: userState?.info?.external_id,
                    folder: 'dialog',
                    girlId: currentChatMailboxState?.info?.public_external_id,
                    page: 1,
                },
                true,
            ),
        );
    };

    const scrollToBookmark = (messageRef: React.RefObject<HTMLDivElement>, id: number) => {
        if (!isViewLetterHistory) {
            dispatch(currentChatMailboxAction.setInfoCurrentTarget(LETTER.ACTION_TARGET_REPLY));
            setIsViewLetterHistory(true);
        }
        if (messageRef?.current && id === currentChatMailboxState?.bookmarkLetterId) {
            const parentBlock = messageRef.current?.parentElement;
            if (parentBlock) {
                const messageRect = messageRef.current.getBoundingClientRect();
                const parentRect = parentBlock.getBoundingClientRect();
                const currentScroll = parentBlock.scrollTop;
                const offsetFromParentTop = messageRect.top - parentRect.top;
                const newScrollPosition = currentScroll + offsetFromParentTop + 10;

                parentBlock.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
            }
            dispatch(currentChatMailboxAction.addMailboxBookmarkLetter(0));
        }
    };

    useEffect(() => {
        setPageShiftUp(currentChatMailboxState.historyCurrentPage || 0);
        setPageShiftDown(currentChatMailboxState.historyCurrentPage || 0);
    }, [currentChatMailboxState.historyCurrentPage]);

    useEffect(() => {
        if (currentChatMailboxState?.mailChatInfo?.chat_uid && userState?.info?.external_id) {
            const isLetterNotify = notifyState?.list.some(
                (notify) =>
                    notify?.chat_uid === currentChatMailboxState?.mailChatInfo?.chat_uid &&
                    notify?.notification_type === 'mail',
            );
            if (isLetterNotify) {
                dispatch(
                    notifyAction.deleteNotify(
                        userState?.info?.external_id,
                        currentChatMailboxState?.mailChatInfo?.chat_uid,
                        'letter',
                    ),
                );
            }
        }
    }, [currentChatMailboxState?.mailChatInfo]);

    useEffect(() => {
        if (currentChatMailboxState?.info?.openFromChat) onOpenForm();
    }, [currentChatMailboxState?.info?.openFromChat]);

    useEffect(() => {
        if (currentChatMailboxState?.history?.length) {
            readLetter();
        }
    }, [currentChatMailboxState?.history?.length]);

    return {
        pageShiftUp,
        pageShiftDown,
        isViewLetterHistory,
        isOpenForm,
        setIsOpenForm,
        scrollPosition,
        onScrollList,
        scrollDown,
        returnToLetterList,
        onOpenForm,
        viewLetterHistory,
        scrollToBookmark,
    };
}
