import React from 'react';

export default function IconCircleInfoV2() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#333333"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9.98388 7.23777C10.5273 7.23777 10.9678 6.79727 10.9678 6.25388C10.9678 5.7105 10.5273 5.27 9.98388 5.27C9.4405 5.27 9 5.7105 9 6.25388C9 6.79727 9.4405 7.23777 9.98388 7.23777Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5Z"
            />
            <path d="M10 15.5991C9.58579 15.5991 9.25 15.2633 9.25 14.8491V9.55C9.25 9.13579 9.58579 8.8 10 8.8C10.4142 8.8 10.75 9.13579 10.75 9.55V14.8491C10.75 15.2633 10.4142 15.5991 10 15.5991Z" />
        </svg>
    );
}
