import * as MIRROR from '../../constants/mirror';
import SITE from '../../config/site';
import {
    ACTIVE_CONTACT_REQUEST_LIST,
    GIFT_ACTIVE_FOR_USER_LIST,
    PAYMENT_TOTAL_HIDE_GIFT,
} from '../../constants/user';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { RootState } from '../../index';

export default function useHandleActiveBlocks() {
    const userState = useSelector((state: RootState) => state.user);
    const settingsState = useSelector((state: RootState) => state.settings);

    // const isHideBefore2Purchase = SITE.ID === 1 && moment(userState?.info?.created_at).isAfter('2023-07-25 15:00') && +purchaseState?.count < 2;
    // const isContactRequestAmourmeet = SITE.ID === 6 && moment(userState?.info?.created_at).isAfter('2023-10-24 12:00:00'); // GT-4686
    const isAmourmeet = SITE.ID === 6;
    // const isAvodate = SITE.ID === 8;
    // const isDatempire = SITE.ID === 9;
    // const isLatidate = SITE.ID === 11;
    // const isSakuradate = SITE.ID === 12;
    const isConektik = SITE.ID === 26;

    const isHideBefore2Purchase = false;
    const isNewMailCategory1 =
        !!userState?.info?.newMail && +userState?.info?.newMailCategory === 1;
    const isPaymentUser = !!userState?.info?.payment_total;
    const isTwitterUser = userState?.info?.t_user === 1; // by GT-2413
    const isRedUser = userState?.info?.configType === 1; // by GT-3045
    const isGiftStartDate = moment(userState?.info?.created_at).isAfter('2023-08-16 15:00:00');
    const isForceActiveGift = GIFT_ACTIVE_FOR_USER_LIST.includes(userState?.info?.external_id || 0); // GT-3739
    const isNewUserToHideVideoShow = moment(userState?.info?.created_at).isAfter(
        '2023-11-15 18:00:00',
    ); // GT-5267
    const isHideContactRequest =
        SITE.ID === 7 &&
        moment(userState?.info?.created_at).isAfter('2024-01-16 16:00:00') && // GT-6926
        moment(userState?.info?.created_at).isBefore('2024-03-05 16:00:00'); // GT-8021

    const isContactRequest = ACTIVE_CONTACT_REQUEST_LIST?.includes(
        userState?.info?.external_id || 0,
    )
        ? true
        : isHideContactRequest
          ? false
          : !!(
                !isRedUser &&
                !isHideBefore2Purchase &&
                !MIRROR.HIDDEN_CONTACT_REQUEST.includes(SITE.ID) &&
                userState?.info?.payment_total
            );
    const isMeetingRequest = false;
    const isHideGift = isForceActiveGift
        ? false
        : isRedUser ||
          !!(isNewMailCategory1 && !isPaymentUser) ||
          !!userState?.info?.hide_gift ||
          (isGiftStartDate
              ? +(userState?.info?.payment_total || 0) < PAYMENT_TOTAL_HIDE_GIFT
              : MIRROR.HIDDEN_GIFT.includes(SITE.ID) &&
                +(userState?.info?.payment_total || 0) < PAYMENT_TOTAL_HIDE_GIFT) ||
          !!userState?.info?.gender;
    const isVideoShow = isNewUserToHideVideoShow
        ? false
        : !!(
              !isHideBefore2Purchase &&
              !userState?.info?.hide_videoshow &&
              settingsState?.route?.videoShow &&
              !MIRROR.HIDDEN_VIDEO_SHOW.includes(SITE.ID)
          );

    const isSearchV3 = userState?.search_multigender || SITE.ID === 23;
    const isGiftPaymentUser = +(userState?.info?.payment_total || 0) > 200;
    const isNewPaymentPackages = () => {
        if (
            isAmourmeet &&
            userState?.info?.abtest?.toUpperCase() === 'B' &&
            moment(userState?.info?.created_at).utc().isAfter('2024-05-13T14:00:00Z') &&
            moment(userState?.info?.created_at).utc().isBefore('2024-06-28T14:00:00Z')
        ) {
            // GT-9144 - GT-10797
            return true;
        } else if (isConektik) {
            return true;
        }

        return false;
    };
    const isDiscoverUniverse = moment(userState?.info?.created_at)
        .utc()
        .isAfter('2024-07-09T10:00:00Z');

    const isUnlimitName = moment(userState?.info?.created_at).utc().isAfter('2024-09-26T12:30:00Z');

    const isActiveChatMenu = settingsState?.route?.chat;
    const isActiveLetterMenu = settingsState?.route?.letter;
    const isActiveNewsfeedMenu = SITE.ID === 23;
    const isActiveSearchMenu = !!(settingsState?.route?.search && !isTwitterUser);
    const isActiveProfileMenu = !isTwitterUser;
    const isActiveLikeMenu = settingsState?.route?.likeHer;
    const isActiveOnlineNowMenu = !!(
        !userState?.info?.gender &&
        !!+userState?.online_now &&
        settingsState?.route?.onlineNow
    );
    const isActiveVideoMenu = isVideoShow;
    const isActiveFAQMenu = settingsState?.route?.faq && !isTwitterUser;
    const isActiveTutorialMenu = settingsState?.route?.tutorial && !isTwitterUser;
    const isActiveCreditMenu = !!(settingsState?.route?.credit && SITE.ID !== 4);
    const isActiveSupportMenu = !userState?.info?.gender && !isTwitterUser;

    const isNewTrustProcessing = moment(userState?.info?.created_at).isAfter('2023-04-25');
    const isNewPastaLogo = moment(userState?.info?.created_at).isAfter('2024-05-02 12:00:00');

    return {
        isRedUser,
        isNewMailCategory1,
        isPaymentUser,
        isHideGift,
        isContactRequest,
        isMeetingRequest,
        isTwitterUser,
        isHideBefore2Purchase,
        isVideoShow,
        isSearchV3,
        isGiftPaymentUser,
        isNewPaymentPackages: isNewPaymentPackages(),
        isDiscoverUniverse,
        isUnlimitName,
        menu: {
            isActiveChatMenu,
            isActiveLetterMenu,
            isActiveNewsfeedMenu,
            isActiveSearchMenu,
            isActiveLikeMenu,
            isActiveOnlineNowMenu,
            isActiveVideoMenu,
            isActiveFAQMenu,
            isActiveTutorialMenu,
            isActiveCreditMenu,
            isActiveProfileMenu,
            isActiveSupportMenu,
        },
        isNewTrustProcessing,
        isNewPastaLogo,
    };
}
