import React from 'react';

export default function IconCloudDownload() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_771_1828)">
                <path d="M6.609 2.013C8.10822 0.720203 10.0204 0.00620777 12 0C16.035 0 19.3845 3 19.749 6.8685C22.137 7.206 24 9.2055 24 11.6595C24 14.3535 21.753 16.5 19.0305 16.5H15C14.8011 16.5 14.6103 16.421 14.4697 16.2803C14.329 16.1397 14.25 15.9489 14.25 15.75C14.25 15.5511 14.329 15.3603 14.4697 15.2197C14.6103 15.079 14.8011 15 15 15H19.032C20.9685 15 22.5 13.482 22.5 11.6595C22.5 9.8355 20.97 8.3175 19.0305 8.3175H18.2805V7.5675C18.282 4.2375 15.492 1.5 12 1.5C10.3798 1.50647 8.81526 2.09165 7.5885 3.15C6.453 4.128 5.859 5.307 5.859 6.2325V6.9045L5.1915 6.978C3.096 7.2075 1.5 8.928 1.5 10.977C1.5 13.1775 3.345 15 5.6715 15H9C9.19891 15 9.38968 15.079 9.53033 15.2197C9.67098 15.3603 9.75 15.5511 9.75 15.75C9.75 15.9489 9.67098 16.1397 9.53033 16.2803C9.38968 16.421 9.19891 16.5 9 16.5H5.6715C2.562 16.5 0 14.049 0 10.977C0 8.3325 1.899 6.1425 4.413 5.5875C4.6275 4.293 5.46 3.003 6.609 2.013Z" />
                <path d="M11.469 23.781C11.5386 23.8508 11.6214 23.9063 11.7125 23.9441C11.8036 23.9819 11.9013 24.0013 12 24.0013C12.0986 24.0013 12.1963 23.9819 12.2874 23.9441C12.3785 23.9063 12.4613 23.8508 12.531 23.781L17.031 19.281C17.1718 19.1402 17.2509 18.9492 17.2509 18.75C17.2509 18.5508 17.1718 18.3598 17.031 18.219C16.8901 18.0782 16.6991 17.9991 16.5 17.9991C16.3008 17.9991 16.1098 18.0782 15.969 18.219L12.75 21.4395V8.25C12.75 8.05109 12.671 7.86032 12.5303 7.71967C12.3896 7.57902 12.1989 7.5 12 7.5C11.8011 7.5 11.6103 7.57902 11.4696 7.71967C11.329 7.86032 11.25 8.05109 11.25 8.25V21.4395L8.03097 18.219C7.89014 18.0782 7.69913 17.9991 7.49997 17.9991C7.30081 17.9991 7.1098 18.0782 6.96897 18.219C6.82814 18.3598 6.74902 18.5508 6.74902 18.75C6.74902 18.9492 6.82814 19.1402 6.96897 19.281L11.469 23.781Z" />
            </g>
            <defs>
                <clipPath id="clip0_771_1828">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
