import { useSelector } from 'react-redux';
import LoginApi from '../../services/api/LoginApi';
import { RED_USER_LOG_TYPES } from '../../constants/user';
import useHandleRedUser from '../user/useHandleRedUser';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import useHandleZendesk from '../ze/useHandleZendesk';

export default function useLogout() {
    const handleRedUser = useHandleRedUser();
    const handleZendesk = useHandleZendesk();

    const userState = useSelector((state: RootState) => state.user);

    const handleClickLogout = async () => {
        await handleRedUser.submitLog(RED_USER_LOG_TYPES.LOGOUT);

        if (userState?.info?.ghost) return handleActionLogout();

        await new LoginApi().postLogout();

        handleActionLogout();
    };

    const handleActionLogout = () => {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('user_age');
            localStorage.removeItem('X-Request-ID');
            window.location.href = '/';
            handleZendesk?.zendeskLogout();
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    return { handleClickLogout, handleActionLogout };
}
