import React, { useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { LetterButton, ChatButton, GifButton } from '../../components/common/Buttons';
import * as modalsAction from '../../store/actions/modals';
import ModerationModal from '../../components/common/Modals/ModerationModal';
import GirlVideo from '../../components/common/GirlVideo';
import { displayDaysUntilBirthdayOnProfilePage } from '../../services/methods';
import * as IMAGES from '../../constants/images';
import { getImageLink } from '../../services/methods/search/images';
import '../../styles/woman-profile.scss';
import useTouchMove from '../../hooks/dom/useTouchMove';
import useInitModeration from '../../hooks/support/useInitModeration';
import useInitWomanProfileGallery from '../../hooks/woman-profile/useInitWomanProfileGallery';
import useInitWomanProfileInfo from '../../hooks/woman-profile/useInitWomanProfileInfo';
import useInitWomanProfileFunctions from '../../hooks/woman-profile/useInitWomanProfileFunctions';
import Loader from '../../components/common/LoaderGif';
import GirlsSlider from '../../components/common/GirlsSlider/GirlsSlider';
import * as SEX from '../../constants/sex';
import * as USER from '../../constants/user';
import useOutsideClicker from '../../hooks/dom/useOutsideClicker';
import SITE from '../../config/site';
import useHandleActiveBlocks from '../../hooks/user/useHandleActiveBlocks';
import IconFavouritesActive from '../../components/Icons/IconFavouritesActive';
import IconFavourites from '../../components/Icons/IconFavourites';
import IconComplain from '../../components/Icons/IconComplain';
import { RootState } from '../../index';
import { Params } from '@sentry/react/types/types';
import { match } from 'react-router';
import IconHelloHand from '../../components/Icons/IconHelloHand';
import IconLikeHerHeart from '../../components/Icons/IconLikeHerHeart';
import IconFAQQuestionBackArrow from '../../components/Icons/IconFAQQuestionBackArrow';
import IconFAQQuestionArrow from '../../components/Icons/IconFAQQuestionArrow';
import IconPlayVideo from '../../components/Icons/IconPlayVideo';
import IconVideos from '../../components/Icons/IconVideos';
import IconCopyright from '../../components/Icons/IconCopyright';
import VerifiedProfile from '../../components/common/VerifiedProfile';

export default function ProfileWomanPage({ match }: { match: match<Params> }) {
    const dispatch = useDispatch();
    const videoPanel = useRef(null);
    const galleryPanel = useRef(null);
    const sayHelloBtn = useRef(null);
    const sayHelloBtnMob = useRef(null);

    const userState = useSelector((state: RootState) => state.user);

    const [isHelloOpen, setIsHelloOpen] = useState(false);

    const changeIsHelloOpen = () => setIsHelloOpen(!isHelloOpen);
    const closeIsHelloOpen = () => setIsHelloOpen(false);

    useOutsideClicker(sayHelloBtn, closeIsHelloOpen, sayHelloBtnMob);

    // useInitResize();
    const touchMove = useTouchMove();
    const initModeration = useInitModeration();
    const {
        galleryContent,
        setGalleryContent,
        nextImage,
        prevImage,
        nextVideo,
        prevVideo,
        setSelectedImage,
        videoContent,
        setVideoContent,
        openGalleryModal,
    } = useInitWomanProfileGallery();

    const {
        profile,
        setProfile,
        chatInfo,
        birthdateString,
        favoriteStatus,
        setFavoriteStatus,
        winkStatus,
        onlineStatus,
        likeStatus,
        sayHelloList,
    } = useInitWomanProfileInfo({ match, setGalleryContent, setVideoContent });

    const { onSetLike, setFavorites, onSetWink, askVideo, openChat } = useInitWomanProfileFunctions(
        {
            favoriteStatus,
            setFavoriteStatus,
            profile,
            chatInfo,
            likeStatus,
            sayHelloList,
            setProfile,
            closeWomanProfileModal: () => {},
        },
    );

    const handleActiveBlocks = useHandleActiveBlocks();

    const isVideo =
        handleActiveBlocks.isVideoShow &&
        !!(
            videoContent?.length ||
            (userState?.info?.convert_user &&
            userState?.info?.payment_total >= USER.PAYMENT_TOTAL_TO_ASK_VIDEO
                ? !profile?.ask_video
                : false)
        );

    return (
        <>
            <div className="column-3 profile_man profile_flex">
                {profile?.external_id ? (
                    <>
                        <div className="c3_woman_profile_head">
                            <LetterButton type="woman_profile" profile={profile} />
                            {!handleActiveBlocks.isHideGift && <GifButton profile={profile} />}
                            <ChatButton female_id={profile?.external_id} />
                            <button
                                type="button"
                                className={`c3_woman_profile_head_item c3wphi_favs`}
                                onClick={setFavorites}
                                data-testid="woman-favorite-btn"
                            >
                                {favoriteStatus ? <IconFavouritesActive /> : <IconFavourites />}
                                <span className="c3_woman_profile_head_item_text">Favorites</span>
                            </button>
                            <button
                                type="button"
                                className="c3_woman_profile_head_item c3wphi_compl"
                                onClick={() => {
                                    initModeration.openModal(
                                        profile?.public_external_id,
                                        profile?.external_id,
                                    );
                                }}
                                data-testid="woman-complain-btn"
                            >
                                <IconComplain />
                                <span className="c3_woman_profile_head_item_text">Complain</span>
                            </button>
                        </div>
                        <div className="c3_woman_profile_page_wrap">
                            <div
                                className="c3_woman_profile_page_wrap2"
                                data-testid="profile-woman-block"
                            >
                                <div className="c3_woman_profile_main_block">
                                    {!!+onlineStatus && (
                                        <span className="c3_woman_profile_online_status">
                                            I’m online now
                                        </span>
                                    )}
                                    {!winkStatus && (
                                        <button
                                            type="button"
                                            className="c3_woman_profile_main_block_say_hello_btn_mob"
                                            onClick={changeIsHelloOpen}
                                        >
                                            <IconHelloHand />
                                            <span>Say “Hello”</span>
                                        </button>
                                    )}
                                    <div className="c3_woman_profile_main_block_top">
                                        <div className="c3_woman_profile_main_block_photo">
                                            <img src={profile?.search_img} alt="user avatar" />
                                        </div>
                                        <div className="c3_woman_profile_main_block_info">
                                            <div className="c3_woman_profile_main_block_info_top">
                                                <div className="c3_woman_profile_main_block_info_top_left">
                                                    <div
                                                        className="c3_woman_profile_main_block_name"
                                                        data-testid="woman-name-age"
                                                    >
                                                        {profile?.name ?? ''}, {profile?.age ?? ''}
                                                        <VerifiedProfile />
                                                    </div>
                                                    {+profile?.day_birth > 0 &&
                                                    +profile?.month_birth > 0 &&
                                                    +profile?.year_birth > 0 ? (
                                                        <div
                                                            className="c3_woman_profile_main_block_dob"
                                                            data-testid="woman-dob"
                                                        >
                                                            <div className={`display_inline`}>
                                                                {birthdateString &&
                                                                    moment(birthdateString).format(
                                                                        'MMMM D, YYYY',
                                                                    )}
                                                            </div>
                                                            <span>
                                                                {displayDaysUntilBirthdayOnProfilePage(
                                                                    profile?.month_birth,
                                                                    profile?.day_birth,
                                                                )}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`c3_woman_profile_main_block_dob`}
                                                        >
                                                            <div
                                                                className={`c3lh_your_likes_item_profile_${onlineStatus} display_inline display_online_on_profile_page`}
                                                            >
                                                                {onlineStatus}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="c3_woman_profile_main_block_info_top_left_bottom">
                                                        <p
                                                            className="c3_woman_profile_main_block_id"
                                                            data-testid="woman-id"
                                                        >
                                                            ID {profile?.public_external_id}
                                                        </p>
                                                        <div
                                                            className="c3_woman_profile_main_block_place"
                                                            data-testid="woman-place"
                                                        >
                                                            <img
                                                                src={profile?.country_icon ?? ''}
                                                                alt="country icon"
                                                            />
                                                            <span>
                                                                {profile?.city_name ?? ''},{' '}
                                                                {profile?.country_name ?? ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c3_woman_profile_main_block_info_top_right">
                                                    {!winkStatus && (
                                                        <>
                                                            <div
                                                                className="c3_wp_hello_btn_wrap"
                                                                ref={sayHelloBtn}
                                                            >
                                                                <button
                                                                    type="button"
                                                                    className={`c3_woman_profile_main_block_info_btn say_hello ${isHelloOpen ? 'active' : ''}`}
                                                                    onClick={changeIsHelloOpen}
                                                                    data-testid="woman-say-hello-btn"
                                                                >
                                                                    <IconHelloHand />
                                                                    <span>Say “Hello”</span>
                                                                </button>
                                                                {isHelloOpen && (
                                                                    <div
                                                                        className="c3_wp_hello_list_wrap"
                                                                        data-testid="woman-hello-list"
                                                                    >
                                                                        <p className="c3_wp_hello_list_title">
                                                                            Choose first message to{' '}
                                                                            {profile?.name ?? ''}
                                                                        </p>
                                                                        <ul
                                                                            className="c3_wp_hello_list"
                                                                            data-testid="select-list"
                                                                        >
                                                                            {!!sayHelloList?.length &&
                                                                                sayHelloList?.map(
                                                                                    (item, key) => (
                                                                                        <li
                                                                                            className="c3_wp_hello_list_item"
                                                                                            onClick={() => {
                                                                                                onSetWink(
                                                                                                    item,
                                                                                                );
                                                                                                changeIsHelloOpen();
                                                                                            }}
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {item}
                                                                                        </li>
                                                                                    ),
                                                                                )}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    {!likeStatus ? (
                                                        <button
                                                            type="button"
                                                            className="c3_woman_profile_main_block_info_btn like_her_btn"
                                                            onClick={onSetLike}
                                                            data-testid="woman-like-btn"
                                                        >
                                                            <IconLikeHerHeart />
                                                            <span>
                                                                {
                                                                    SEX.SEX_TEXT[
                                                                        userState?.info
                                                                            ?.sex_text as keyof typeof SEX.SEX_TEXT
                                                                    ].LIKE_SIDE_MENU_BTN
                                                                }
                                                            </span>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="c3_woman_profile_main_block_info_btn like active"
                                                            data-testid="woman-liked-btn"
                                                        >
                                                            <IconLikeHerHeart />
                                                            <span>Liked</span>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="c3_woman_profile_gallery_photo_wrap"
                                                ref={galleryPanel}
                                            >
                                                <div className="c3_woman_profile_gallery_photo">
                                                    <div className="c3_woman_profile_gallery_photo_top_big_list_wrap">
                                                        <div
                                                            className="c3_woman_profile_gallery_photo_top_big_list"
                                                            onTouchStart={
                                                                touchMove.handleTouchStart
                                                            }
                                                            onTouchMove={(event) =>
                                                                touchMove.handleTouchMove(
                                                                    event,
                                                                    () => nextImage(event),
                                                                    () => prevImage(),
                                                                )
                                                            }
                                                        >
                                                            {galleryContent.map(
                                                                (item: any, key) => {
                                                                    const isFreeClass =
                                                                        !item?.payed &&
                                                                        +item?.free === 0
                                                                            ? 'unpaid'
                                                                            : '';
                                                                    return (
                                                                        <div key={key}>
                                                                            <div
                                                                                className={`c3_woman_profile_gallery_photo_top_big_item ${isFreeClass}`}
                                                                                onClick={() =>
                                                                                    openGalleryModal(
                                                                                        item?.link,
                                                                                        item?.payed ||
                                                                                            +item?.free,
                                                                                        galleryContent,
                                                                                        item?.id,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {!item?.payed &&
                                                                                    +item?.free ===
                                                                                        0 && (
                                                                                        <div className="popup_likeher_photo_unpaid_warning">
                                                                                            <span>
                                                                                                Private
                                                                                                photo
                                                                                            </span>
                                                                                            {handleActiveBlocks.isSearchV3
                                                                                                ? 'To view member’s private photos, start chatting'
                                                                                                : SEX
                                                                                                      .SEX_TEXT[
                                                                                                      userState
                                                                                                          ?.info
                                                                                                          ?.sex_text as keyof typeof SEX.SEX_TEXT
                                                                                                  ]
                                                                                                      .GALLERY_MODAL_START_CHATTING_TITLE}
                                                                                        </div>
                                                                                    )}
                                                                                <img
                                                                                    src={getImageLink(
                                                                                        item?.link,
                                                                                        IMAGES.SIZE_M,
                                                                                    )}
                                                                                    alt="user photo"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                        <div
                                                            className="c3_woman_profile_gallery_photo_btn btn_prev"
                                                            onClick={prevImage}
                                                        >
                                                            <IconFAQQuestionBackArrow />
                                                        </div>
                                                        <div
                                                            className="c3_woman_profile_gallery_photo_btn btn_next"
                                                            onClick={(event) =>
                                                                nextImage(
                                                                    event as unknown as React.TouchEvent<HTMLDivElement>,
                                                                )
                                                            }
                                                        >
                                                            <IconFAQQuestionArrow />
                                                        </div>
                                                    </div>
                                                    <div className="c3_woman_profile_gallery_photo_top_small_list_wrap">
                                                        <ul className="c3_woman_profile_gallery_photo_top_small_list">
                                                            {galleryContent.map(
                                                                (item: any, index) => {
                                                                    const isFreeClass =
                                                                        !item?.payed &&
                                                                        +item?.free === 0
                                                                            ? 'unpaid'
                                                                            : '';
                                                                    return (
                                                                        <li
                                                                            className={`c3_woman_profile_gallery_photo_top_small_list_item ${isFreeClass}`}
                                                                            key={item?.id}
                                                                            onClick={() =>
                                                                                setSelectedImage(
                                                                                    index,
                                                                                )
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={getImageLink(
                                                                                    item?.link,
                                                                                    IMAGES.SIZE_XS,
                                                                                )}
                                                                                alt="user photo"
                                                                            />
                                                                        </li>
                                                                    );
                                                                },
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isVideo && (
                                        <div
                                            className="c3_woman_profile_gallery_video_wrap"
                                            ref={videoPanel}
                                        >
                                            <h3 className="c3_woman_profile_page_title">
                                                videos ({videoContent?.length})
                                            </h3>
                                            <div className="c3_woman_profile_gallery_video_list_wrap">
                                                <div className="c3_woman_profile_gallery_video_list">
                                                    {videoContent?.length
                                                        ? videoContent?.map(
                                                              (item: any, key: number) => {
                                                                  return (
                                                                      <div
                                                                          key={key}
                                                                          className="c3_woman_profile_gallery_video_list_item"
                                                                      >
                                                                          <GirlVideo
                                                                              item={item}
                                                                              openVideoModal={() =>
                                                                                  dispatch(
                                                                                      modalsAction.openWomanVideoShowModal(
                                                                                          profile?.external_id,
                                                                                      ),
                                                                                  )
                                                                              }
                                                                              type="simple"
                                                                          />
                                                                      </div>
                                                                  );
                                                              },
                                                          )
                                                        : !+profile?.ask_video && (
                                                              <div className="c3_woman_profile_empty_video">
                                                                  <div className="c3_woman_profile_empty_video_title">
                                                                      <IconPlayVideo />
                                                                      <span>
                                                                          {
                                                                              SEX.SEX_TEXT[
                                                                                  userState?.info
                                                                                      ?.sex_text as keyof typeof SEX.SEX_TEXT
                                                                              ]
                                                                                  .WOMAN_PROFILE_MODAL_ASK_VIDEO_TITLE
                                                                          }
                                                                      </span>
                                                                  </div>
                                                                  <div
                                                                      className="c3_woman_profile_empty_video_btn"
                                                                      onClick={askVideo}
                                                                  >
                                                                      <IconVideos />
                                                                      <span>Ask now</span>
                                                                  </div>
                                                              </div>
                                                          )}
                                                </div>

                                                {videoContent?.length > 1 && (
                                                    <div
                                                        className="c3_woman_profile_gallery_photo_btn btn_prev"
                                                        onClick={prevVideo}
                                                    >
                                                        <img
                                                            src="../../img/c3wp-btn-prev.svg"
                                                            alt="arrow previous"
                                                        />
                                                    </div>
                                                )}
                                                {videoContent?.length > 1 && (
                                                    <div
                                                        className="c3_woman_profile_gallery_photo_btn btn_next"
                                                        onClick={nextVideo}
                                                    >
                                                        <img
                                                            src="../../img/c3wp-btn-next.svg "
                                                            alt="arrow next"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="c3_woman_profile_page_title_cost">
                                                <IconCopyright />
                                                <span>
                                                    You can view one video per day for free. Each
                                                    additional video costs credits
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="c3_woman_profile_mobile_buttons">
                                        <LetterButton type="woman_profile" profile={profile} />
                                        <GifButton profile={profile} />
                                        {favoriteStatus ? (
                                            <button
                                                type="button"
                                                className="c3_woman_profile_mob_btn active"
                                                onClick={setFavorites}
                                            >
                                                <IconFavouritesActive />
                                                <span>Added to favorites</span>
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="c3_woman_profile_mob_btn"
                                                onClick={setFavorites}
                                            >
                                                <IconFavourites />
                                                <span>Add to favorites</span>
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="c3_woman_profile_mob_btn complain"
                                            onClick={() => {
                                                initModeration.openModal(
                                                    profile?.public_external_id,
                                                    profile?.external_id,
                                                );
                                            }}
                                        >
                                            <IconComplain />
                                            <span>Complain</span>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="c3_woman_profile_second_block c3_woman_profile_custom"
                                    data-testid="woman-second-block"
                                >
                                    <div className="c3_woman_profile_second_block_row">
                                        <div className="c3_woman_profile_main_block_list_flex_wrap">
                                            <div className="c3_woman_profile_main_block_list">
                                                <h3 className="c3_woman_profile_main_block_list_title">
                                                    Appearance
                                                </h3>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Height: <span>{profile?.height_sm} Cm</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    body type: <span>{profile?.body_type}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Hair color: <span>{profile?.hair_color}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Eyes color: <span>{profile?.eye}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Zodiac: <span>{profile?.zodiac_sign}</span>
                                                </p>
                                            </div>
                                            <h3 className="c3_woman_profile_main_block_list">
                                                <p className="c3_woman_profile_main_block_list_title">
                                                    Social information
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Education: <span>{profile?.education}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Job or Profession:{' '}
                                                    <span>{profile?.occupation}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Religion: <span>{profile?.religion}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Marital status: <span>{profile?.marital}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Number of children:{' '}
                                                    <span>{profile?.children}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Want to have more children:{' '}
                                                    <span> {profile?.morechildren}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Purpose of staying on the site:{' '}
                                                    <span>{profile?.reason}</span>
                                                </p>
                                            </h3>
                                            <div className="c3_woman_profile_main_block_list">
                                                <h3 className="c3_woman_profile_main_block_list_title">
                                                    Hobby and habits
                                                </h3>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Hobby:{' '}
                                                    <span>{profile?.hobbies?.join(', ')} </span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Alcohol: <span>{profile?.alcohol}</span>
                                                </p>
                                                <p className="c3_woman_profile_main_block_list_item">
                                                    Smoking: <span>{profile?.smoking}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="c3_woman_profile_tabs_wrap">
                                            <div
                                                className="c3_woman_profile_tab_block active"
                                                id="tab_about"
                                            >
                                                <div className="c3_woman_profile_tab_about">
                                                    <div className="c3_woman_profile_tab_about_item">
                                                        <h3 className="c3_woman_profile_tab_about_item_title">
                                                            MY self-summary
                                                        </h3>
                                                        <p className="c3_woman_profile_tab_about_item_text">
                                                            {profile?.about}
                                                        </p>
                                                        <p className="c3_woman_profile_tab_about_item_more_btn">
                                                            Read more
                                                        </p>
                                                    </div>
                                                    {SITE.ID !== 4 && (
                                                        <div className="c3_woman_profile_tab_about_item">
                                                            <h3 className="c3_woman_profile_tab_about_item_title">
                                                                Who Am I looking for
                                                            </h3>
                                                            <p className="c3_woman_profile_tab_about_item_text">
                                                                {profile?.seeking}
                                                            </p>
                                                            <p className="c3_woman_profile_tab_about_item_more_btn">
                                                                Read more
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <GirlsSlider />
                                </div>
                                <div className="c3_woman_profile_mob_nav">
                                    <ChatButton female_id={profile?.external_id} />
                                    {!likeStatus ? (
                                        <button
                                            type="button"
                                            className="c3_woman_profile_mob_nav_like_btn"
                                            onClick={onSetLike}
                                        >
                                            <IconLikeHerHeart />
                                            <span>
                                                {
                                                    SEX.SEX_TEXT[
                                                        userState?.info
                                                            ?.sex_text as keyof typeof SEX.SEX_TEXT
                                                    ].LIKE_SIDE_MENU_BTN
                                                }
                                            </span>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="c3_woman_profile_mob_nav_like_btn active"
                                        >
                                            <IconLikeHerHeart />
                                            <span>Liked</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <Loader />
                )}
            </div>
            {isHelloOpen && (
                <div className="c3_wp_hello_list_wrap_mob">
                    <div className="c3_wp_hello_list_wrap" ref={sayHelloBtnMob}>
                        <div className="c3_wp_hello_list_wrap_close" onClick={changeIsHelloOpen} />
                        <p className="c3_wp_hello_list_title">
                            Choose first message to {profile?.name ?? ''}
                        </p>
                        <ul className="c3_wp_hello_list">
                            {!!sayHelloList &&
                                sayHelloList.map((item, key) => (
                                    <li
                                        className="c3_wp_hello_list_item"
                                        key={key * 100}
                                        onClick={() => {
                                            onSetWink(item);
                                            changeIsHelloOpen();
                                        }}
                                    >
                                        {item}
                                    </li>
                                ))}
                        </ul>
                        <div
                            className="c3_wp_hello_list_to_chat_btn"
                            onClick={(event) => {
                                openChat(event);
                            }}
                        >
                            Start chatting
                        </div>
                    </div>
                </div>
            )}
            <ModerationModal {...initModeration} />
        </>
    );
}
