import React from 'react';

export default function IconChangePasswordLock() {
    return (
        <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="#00317B"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_72)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.66992 1.78125C6.83986 1.78125 6.04681 2.101 5.46447 2.66541C4.88267 3.22931 4.55881 3.99073 4.55881 4.78125V7.08125H10.781V4.78125C10.781 3.99071 10.4572 3.22929 9.87541 2.66541C9.29304 2.10099 8.5 1.78125 7.66992 1.78125ZM11.781 7.08125V4.78125C11.781 3.71527 11.344 2.69621 10.5714 1.94735C9.79926 1.19901 8.75531 0.78125 7.66992 0.78125C6.58455 0.78125 5.5406 1.199 4.7685 1.94735C3.99587 2.69619 3.55881 3.71525 3.55881 4.78125V7.08125H2.61437C1.55533 7.08125 0.669922 7.91729 0.669922 8.98125V13.8812C0.669922 14.9452 1.55533 15.7812 2.61437 15.7812H12.7255C13.7845 15.7812 14.6699 14.9451 14.6699 13.8812V8.98125C14.6699 7.9173 13.7845 7.08125 12.7255 7.08125H11.781ZM2.61437 8.08125C2.07792 8.08125 1.66992 8.49881 1.66992 8.98125V13.8812C1.66992 14.3636 2.07792 14.7812 2.61437 14.7812H12.7255C13.2619 14.7812 13.6699 14.3637 13.6699 13.8812V8.98125C13.6699 8.4988 13.2619 8.08125 12.7255 8.08125H2.61437Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_72">
                    <rect width="15" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
