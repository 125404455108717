import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import * as REGISTER from '../../../../../constants/register';
import * as DICTIONARY_MODAL from '../../../../../constants/dictionary/modals';
import SITE from '../../../../../config/site';
import useOutsideClicker from '../../../../../hooks/dom/useOutsideClicker';
import { IResetPassword } from '../index.interface';
import IconCross from '../../../../Icons/IconCross';
import IconResetPasswordTimer from '../../../../Icons/IconResetPasswordTimer';
import IconResetPasswordKey from '../../../../Icons/IconResetPasswordKey';
import IconResetPasswordSearchEmail from '../../../../Icons/IconResetPasswordSearchEmail';
import IconResetPasswordEnjoyEmoji from '../../../../Icons/IconResetPasswordEnjoyEmoji';

export default function ResetPassword({
    closeResetPassModal,
    formDataLogin,
    handleFormDataLoginEmail,
    submitResetPassword,
}: IResetPassword) {
    const [openEmailList, setOpenEmailList] = useState(false);

    const selectEmailRef = useRef<HTMLDivElement | null>(null);

    useOutsideClicker(selectEmailRef, setOpenEmailList);

    const trimEmailLogin = formDataLogin?.email?.split('@')[0];

    const onSelectDomainLogin = (e: React.MouseEvent<HTMLDivElement>, item: string) => {
        e.stopPropagation();
        handleFormDataLoginEmail(`${trimEmailLogin}@${item}`);
        setOpenEmailList(false);
        // setWarningText('');
    };

    return (
        <div className={styles.reset_pass_modal}>
            <h2 className={styles.reset_pass_title}>{SITE.resetPasswordTitle}</h2>
            <form onSubmit={submitResetPassword} className={styles.reset_pass_form}>
                <div
                    className={`${styles.reset_pass_form_input_wrap} ${openEmailList ? styles.opened : ''}`}
                    ref={selectEmailRef}
                    onClick={() => setOpenEmailList(true)}
                >
                    <input
                        className="main_registration_form_input_text"
                        type="email"
                        placeholder="Your Email"
                        value={formDataLogin?.email}
                        onChange={(e) => handleFormDataLoginEmail(e.target.value)}
                    />
                    <span className="error_text">Field is to be completed</span>
                    <div className={styles.registration_form_emails_list_wrap}>
                        <div className={styles.registration_form_emails_list}>
                            {REGISTER.DOMAIN_ARRAY.map((item, index) => (
                                <div
                                    key={`${index}_${item}`}
                                    className={styles.registration_form_emails_list_item}
                                    onClick={(e) => onSelectDomainLogin(e, item)}
                                >
                                    {trimEmailLogin}@{item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <button type="submit" className={styles.reset_pass_form_btn}>
                    <span className={styles.reset_pass_form_btn_text}>
                        {DICTIONARY_MODAL.FORGET_BUTTON}
                    </span>
                </button>
            </form>
            <h2 className={styles.reset_pass_title}>
                {DICTIONARY_MODAL.RESTORE_EMAIL_INSTRUCTIONS_TITLE}
            </h2>
            <ul className={styles.reset_pass_instruction}>
                <li className={styles.reset_pass_instruction_item}>
                    <div className={styles.item_img}>
                        <IconResetPasswordTimer />
                    </div>
                    <div className={styles.item_content}>
                        <h4 className={styles.item_content_title}>Step 1</h4>
                        <p className={styles.item_content_text}>
                            {DICTIONARY_MODAL.RESTORE_EMAIL_STEP_1}
                        </p>
                    </div>
                </li>
                <li className={styles.reset_pass_instruction_item}>
                    <div className={styles.item_img}>
                        <IconResetPasswordSearchEmail />
                    </div>
                    <div className={styles.item_content}>
                        <h4 className={styles.item_content_title}>Step 2</h4>
                        <p className={styles.item_content_text}>
                            {DICTIONARY_MODAL.RESTORE_EMAIL_STEP_2}
                        </p>
                    </div>
                </li>
                <li className={styles.reset_pass_instruction_item}>
                    <div className={styles.item_img}>
                        <IconResetPasswordKey />
                    </div>
                    <div className={styles.item_content}>
                        <h4 className={styles.item_content_title}>Step 3</h4>
                        <p className={styles.item_content_text}>
                            {DICTIONARY_MODAL.RESTORE_EMAIL_STEP_3}
                        </p>
                    </div>
                </li>
                <li className={styles.reset_pass_instruction_item}>
                    <div className={styles.item_img}>
                        <IconResetPasswordEnjoyEmoji />
                    </div>
                    <div className={styles.item_content}>
                        <h4 className={styles.item_content_title}>Step 4</h4>
                        <p className={styles.item_content_text}>
                            {DICTIONARY_MODAL.RESTORE_EMAIL_STEP_4}
                        </p>
                    </div>
                </li>
            </ul>
            <button type="button" className={styles.reset_pass_close} onClick={closeResetPassModal}>
                <IconCross />
            </button>
        </div>
    );
}
