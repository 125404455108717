import React from 'react';
import * as MENU from '../../../../constants/menu';
import BottomNotificationList from './BottomNotificationList';
import { NOTIFY_MOBILE_ACTIVE_PAGE } from '../../../../constants/notify';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';

export default function BottomNotification() {
    const menuState = useSelector((state: RootState) => state.menu);

    const classNameLike = window?.location?.href?.includes(MENU.MENU_LIKE_HER) ? 'like' : '';

    if (!NOTIFY_MOBILE_ACTIVE_PAGE?.includes(menuState?.main)) return <div />;
    return (
        <div className={`mob_notify_bottom ${classNameLike}`}>
            <BottomNotificationList />
        </div>
    );
}
