import React from 'react';
import global from '../global.module.scss';
import styles from './styles.module.scss';
import VerifyPhoneCodeModal from '../../../../common/Modals/VerifyPhoneCodeModal';
import useInitVerifyStatus from '../../../../../hooks/verifyStatus/useInitVerifyStatus';
import ConfirmStatusPhoneModal from '../../../../common/Modals/ConfirmStatusPhoneModal';
import ConfirmStatusEmailModal from '../../../../common/Modals/ConfirmStatusEmailModal';
import ConfirmStatusNameModal from '../../../../common/Modals/ConfirmStatusNameModal';
import useHandleActiveBlocks from '../../../../../hooks/user/useHandleActiveBlocks';
import IconPolicy from '../../../../Icons/IconPolicy';
import IconPhoneCircle from '../../../../Icons/IconPhoneCircle';
import IconOffersPercents from '../../../../Icons/IconOffersPercents';
import IconHeadphones from '../../../../Icons/IconHeadphones';
import IconEditBlack from '../../../../Icons/IconEditBlack';
import IconFAQ from '../../../../Icons/IconFAQ';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconShieldPhone from '../../../../Icons/IconShieldPhone';
import IconShieldEmail from '../../../../Icons/IconShieldEmail';

export default function Contacts() {
    const initVerifyStatus = useInitVerifyStatus();
    const handleActiveBlocks = useHandleActiveBlocks();

    return (
        <>
            <div id="profile-vip-block" className={`${global.profile_div} ${styles.contacts_wrap}`}>
                <div className={styles.contacts_title_wrapper}>
                    {initVerifyStatus?.userInfo?.is_verified ? (
                        <>
                            <IconPolicy />
                            <h2 className={styles.contacts_title}>VERIFIED VIP USER</h2>
                        </>
                    ) : (
                        <h2 className={styles.contacts_title}>
                            GET VERIFIED AND BECOME A VIP USER
                        </h2>
                    )}
                </div>
                {initVerifyStatus?.userInfo?.is_verified ? (
                    <div className={styles.contacts_wrapper}>
                        <div className={styles.contacts_text}>
                            <h3 className={styles.contacts_info_title}>
                                These VIP status privileges are now available to you:
                            </h3>
                            <ul className={styles.contacts_text_list}>
                                <li className={styles.contacts_text_list_item}>
                                    <div className={styles.contacts_title_image}>
                                        <IconPhoneCircle />
                                    </div>
                                    <span className={styles.contacts_text_list_item_text}>
                                        Contact exchange service
                                    </span>
                                </li>
                                <li className={styles.contacts_text_list_item}>
                                    <div className={styles.contacts_title_image}>
                                        <IconOffersPercents />
                                    </div>
                                    <span className={styles.contacts_text_list_item_text}>
                                        Special offers
                                    </span>
                                </li>
                                <li className={styles.contacts_text_list_item}>
                                    <div className={styles.contacts_title_image}>
                                        <IconHeadphones />
                                    </div>
                                    <span className={styles.contacts_text_list_item_text}>
                                        A dedicated 24/7 support team for VIP users
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.contacts_info}>
                            <h3 className={styles.contacts_info_title}>
                                Your contact information:
                            </h3>
                            <div className={styles.contacts_info_row}>
                                <div className={styles.contacts_info_col}>
                                    <div className={styles.contacts_info_value_wrap}>
                                        <div className={styles.contacts_info_value_name}>PHONE</div>
                                        <input
                                            type="text"
                                            className={styles.contacts_info_value}
                                            value={initVerifyStatus?.userInfo?.phone}
                                            onChange={() => {}}
                                            readOnly
                                        />
                                        <div
                                            className={styles.contacts_info_value_btn}
                                            onClick={initVerifyStatus.openConfirmPhoneModal}
                                        >
                                            <IconEditBlack />
                                            Edit
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contacts_info_col}>
                                    <div className={styles.contacts_info_value_wrap}>
                                        <div className={styles.contacts_info_value_name}>EMAIL</div>
                                        <input
                                            type="text"
                                            className={styles.contacts_info_value}
                                            value={initVerifyStatus?.userInfo?.email}
                                            onChange={() => {}}
                                            readOnly
                                        />
                                        <div
                                            className={styles.contacts_info_value_btn}
                                            onClick={initVerifyStatus.openConfirmEmailModal}
                                        >
                                            <IconEditBlack />
                                            Edit
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contacts_info_col}>
                                    <div className={styles.contacts_info_value_wrap}>
                                        <div className={styles.contacts_info_value_name}>NAME</div>
                                        <input
                                            type="text"
                                            className={styles.contacts_info_value}
                                            value={initVerifyStatus?.userInfo?.real_name}
                                            onChange={() => {}}
                                            readOnly
                                        />
                                        <div
                                            className={styles.contacts_info_value_btn}
                                            onClick={initVerifyStatus.openConfirmNameModal}
                                        >
                                            <IconEditBlack />
                                            Edit
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={styles.contacts_benefit}>
                            <p className={styles.contacts_benefit_title}>
                                Dear user, in order to receive VIP status and enjoy special offers,
                                you must first go through three stages of verification. You will
                                then have access to the following services:
                            </p>
                            {handleActiveBlocks.isContactRequest && (
                                <div className={styles.contacts_benefit_row}>
                                    <div className={styles.contacts_benefit_icon}>
                                        <IconPhoneCircle />
                                    </div>
                                    <p className={styles.contacts_benefit_text}>
                                        Contact information exchange service
                                    </p>
                                </div>
                            )}
                            <div className={styles.contacts_benefit_row}>
                                <div className={styles.contacts_benefit_icon}>
                                    <IconFAQ />
                                </div>
                                <p className={styles.contacts_benefit_text}>
                                    24/7 premium VIP support service. All of your requests will be
                                    processed with priority and as quickly as possible.
                                </p>
                            </div>
                        </div>
                        <div className={styles.contacts_verify_status}>
                            <p className={styles.contacts_verify_status_title}>Verify status</p>
                            <div className={styles.contacts_verify_status_row}>
                                <div className={styles.contacts_verify_status_col}>
                                    <div
                                        className={`${styles.contacts_verify_status_name} ${initVerifyStatus.userInfo?.is_approve_phone ? styles.verified : ''}`}
                                    >
                                        <div className={styles.shield_phone_svg}>
                                            <IconShieldPhone />
                                        </div>
                                        Phone number
                                    </div>
                                    {initVerifyStatus.userInfo?.is_approve_phone ? (
                                        <div className={`${styles.contacts_verify_status_btn}`}>
                                            <IconCheckedCircle />
                                            Verified
                                        </div>
                                    ) : (
                                        <div
                                            className={`${styles.contacts_verify_status_btn} ${styles.active}`}
                                            onClick={initVerifyStatus.openConfirmPhoneModal}
                                        >
                                            Verify
                                        </div>
                                    )}
                                </div>
                                <div className={styles.contacts_verify_status_col}>
                                    <div
                                        className={`${styles.contacts_verify_status_name} ${initVerifyStatus.userInfo?.email_confirmed ? styles.verified : ''}`}
                                    >
                                        <div className={styles.shield_email_svg}>
                                            <IconShieldEmail />
                                        </div>
                                        Email
                                    </div>
                                    {initVerifyStatus.userInfo?.email_confirmed ? (
                                        <div className={`${styles.contacts_verify_status_btn}`}>
                                            <IconCheckedCircle />
                                            Verified
                                        </div>
                                    ) : (
                                        <div
                                            className={`${styles.contacts_verify_status_btn} ${styles.active}`}
                                            onClick={initVerifyStatus.openConfirmEmailModal}
                                        >
                                            Verify
                                        </div>
                                    )}
                                </div>
                                <div className={styles.contacts_verify_status_col}>
                                    <div
                                        className={`${styles.contacts_verify_status_name} ${styles.shield_name} ${initVerifyStatus.userInfo?.real_name?.length ? styles.verified : ''}`}
                                    >
                                        <div className={styles.shield_name_svg}>
                                            <IconPolicy />
                                        </div>
                                        Real name
                                    </div>
                                    {initVerifyStatus.userInfo?.real_name?.length ? (
                                        <div className={`${styles.contacts_verify_status_btn}`}>
                                            <IconCheckedCircle />
                                            Verified
                                        </div>
                                    ) : (
                                        <div
                                            className={`${styles.contacts_verify_status_btn} ${styles.active}`}
                                            onClick={initVerifyStatus.openConfirmNameModal}
                                        >
                                            Verify
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <p className={`${styles.contacts_main_btn}`}>
                            After verification, you will automatically receive a bonus of 100
                            credits for communication
                        </p>
                    </>
                )}
            </div>

            <ConfirmStatusPhoneModal {...initVerifyStatus} />
            <VerifyPhoneCodeModal {...initVerifyStatus} />
            <ConfirmStatusEmailModal {...initVerifyStatus} />
            <ConfirmStatusNameModal {...initVerifyStatus} />
        </>
    );
}
