import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import useTouchMove from '../dom/useTouchMove';
import useAddWomanList from '../woman/useAddWomanList';
import useGetWomanInfo from '../woman/useGetWomanInfo';
import { MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react';
import * as alertAction from '../../store/actions/alert';
import * as likesAction from '../../store/actions/likes';
import MailboxApi from '../../services/api/MailboxApi';
import * as MENU from '../../constants/menu';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as modalsAction from '../../store/actions/modals';
import useLike from '../likes/useLike';
import { RootState } from '../../index';
import { IWoman } from '../../store/reducers/woman/index.interface';
import { IImages } from '../../store/actions/likes/index.interface';
import MarketingApi from '../../services/api/MarketingApi';
import { ICrossBannersData } from '../../store/reducers/banners/index.interface';

export default function useHandleLikeHer() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const touchMove = useTouchMove();
    const addWomanList = useAddWomanList();
    const getWomanInfo = useGetWomanInfo();
    const like = useLike();

    const likesState = useSelector((state: RootState) => state.likes);
    const userState = useSelector((state: RootState) => state.user);
    const womanState = useSelector((state: RootState) => state.woman);

    const [animationVisible, setAnimationVisible] = useState(true);
    const [countPrivateImage, setCountPrivateImage] = useState(0);
    const [isLoadFirstImg, setIsLoadFirstImg] = useState(false);
    const [womanInfo, setWomanInfo] = useState<IWoman | ICrossBannersData | null>(null);
    const [gallery, setGallery] = useState<IImages[]>([]);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [crossBanners, setCrossBanners] = useState<Record<string, ICrossBannersData>>({});
    // const [lastBannerIndex, setLastBannerIndex] = useState(0);
    const isIWomanType = womanInfo && 'external_id' in womanInfo;

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const currentWomanExternalID = likesState?.list[likesState?.selectedListIndex];

    const incrementSelectedIndex = () => {
        setGallery([]);
        setGalleryIndex(0);

        dispatch(likesAction.setSelectedPerson(likesState?.selectedListIndex + 1));
    };

    const openChat = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!isIWomanType) return;

        if (!userState?.info?.external_id || !womanInfo?.external_id) return;
        new MailboxApi()
            .createChatWithProfile(userState?.info?.external_id, womanInfo?.external_id)
            .then((res) => {
                if (+res?.chat_info?.male_block) {
                    return dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                }
                if (res?.status && res?.chat_uid) {
                    transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${res?.chat_uid}`);
                } else {
                    dispatch(
                        alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }),
                    );
                }
            });
    };

    const openLetter = async () => {
        if (!isIWomanType) return;
        dispatch(
            // @ts-expect-error addMailboxChatInfo
            currentChatMailboxAction.addMailboxChatInfo({
                ...womanInfo,
                sender_image: womanInfo?.avatar || '',
                sender_name: womanInfo?.name || '',
                sender_age: womanInfo?.age || 0,
                sender_city: womanInfo?.city_name || '',
                sender_id: womanInfo?.external_id || 0,
                female_id: womanInfo?.external_id || 0,
                male_id: userState?.info?.external_id || 0,
                openFromChat: true,
                sender_gifts: womanInfo?.gifts,
                sender_search_img: womanInfo?.link || '',
            }),
        );

        if (userState?.info?.external_id && womanInfo?.public_external_id) {
            dispatch(
                currentChatMailboxAction.getMailboxChatHistory({
                    userId: userState?.info?.external_id,
                    girlId: womanInfo?.public_external_id?.toString() || '',
                    page: 1,
                }),
            );
        }
        transition.transitionWithHistoryPush('letter', `/letter/`);
    };

    const onSetLike = async (external_id: number) => {
        const isLike = await like(external_id);

        if (isLike?.status) {
            const newWoman = getWomanInfo(external_id);
            if (newWoman) dispatch(likesAction.addLikeHistory(newWoman));
            setAnimationVisible(false);
            setTimeout(() => {
                setGallery([]);
                setGalleryIndex(0);
                setAnimationVisible(true);
                dispatch(likesAction.removeFromLikeList(external_id));
            }, 500);
        }
    };

    const incrementSelectedIndexGallery = (
        event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>,
    ) => {
        event.preventDefault();

        let index = galleryIndex + 1;
        index = gallery?.filter((item: any) => item?.link)?.length - 1 >= index ? index : 0;
        setGalleryIndex(index);
    };

    const decrementSelectedIndexGallery = (
        event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>,
    ) => {
        event.preventDefault();

        let index = galleryIndex - 1;
        index = index < 0 ? gallery?.filter((item: any) => item?.link)?.length - 1 : index;
        setGalleryIndex(index);
    };

    const redirectVideoProfile = () => {
        if (!isIWomanType || !womanInfo?.has_video) return;
        dispatch(modalsAction.openWomanVideoShowModal(womanInfo?.external_id));
    };

    const redirectProfile = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!isIWomanType) return;

        if (womanInfo?.external_id) {
            dispatch(modalsAction.openWomanProfileModal(womanInfo?.external_id));
        }
    };

    useEffect(() => {
        setGallery([]);
        if (currentWomanExternalID) {
            setIsLoadFirstImg(false);
            addWomanList([currentWomanExternalID]);
        }
    }, [likesState?.list, likesState?.selectedListIndex]);

    useEffect(() => {
        fetchCrossBanners();
    }, []);

    const fetchCrossBanners = (prevIndex = 0) => {
        new MarketingApi().getCrossBannerData().then((res) => {
            if (res?.status) {
                const crossBannerInfo = res?.response;
                const bannerIndex = prevIndex + (crossBannerInfo?.show_in?.like ?? 0);
                if (crossBanners[`banner_${bannerIndex}`] || !crossBannerInfo?.show_in?.like)
                    return;
                setCrossBanners((prev) => ({
                    ...prev,
                    [`banner_${bannerIndex}`]: crossBannerInfo,
                }));
            }
        });
    };

    useEffect(() => {
        const bannerKey = `banner_${likesState?.selectedListIndex + 1}`;
        if (crossBanners[bannerKey] && isIWomanType) {
            setWomanInfo(crossBanners[bannerKey]);
            fetchCrossBanners(likesState?.selectedListIndex + 1);
            return;
        }
        const womanInfo = getWomanInfo(+currentWomanExternalID);

        if (womanInfo?.external_id && !gallery?.length) {
            setCountPrivateImage(
                womanInfo?.images?.filter((image: any) => +image?.free === 0 && !image?.payed)
                    ?.length,
            );
            const openImages = womanInfo?.images?.filter(
                (item: any) => +item?.free === 1 && +item?.landscape === 0,
            );
            const privatePhoto = womanInfo?.images?.find(
                (item: any) => +item?.free === 0 && +item?.landscape === 0,
            );
            const newGallery = [...openImages, ...[privatePhoto]]?.filter((item) => item?.link);

            if (!newGallery?.length)
                dispatch(likesAction.removeFromLikeList(womanInfo?.external_id));
            else {
                // @ts-expect-error setGallery
                setGallery([...openImages, ...[privatePhoto]]?.filter((item) => item?.link));
                setWomanInfo(womanInfo);
            }
        }
    }, [womanState.list, gallery]);

    return {
        redirectVideoProfile,
        redirectProfile,
        onSetLike,
        openLetter,
        openChat,
        incrementSelectedIndex,
        decrementSelectedIndexGallery,
        incrementSelectedIndexGallery,
        setIsLoadFirstImg,
        prevRef,
        nextRef,
        womanInfo,
        isLoadFirstImg,
        countPrivateImage,
        animationVisible,
        touchMove,
        gallery,
        galleryIndex,
        isEmpty: !likesState?.list[likesState?.selectedListIndex],
    };
}
