import React, { useRef, useState } from 'react';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import { ILargeSelectNewProps } from './index.interface';

export default function LargeSelect({
    selectTitle,
    defaultName,
    arrayFields,
    formData,
    handleClick,
    index,
    dataTestId = '',
}: ILargeSelectNewProps) {
    const [openBox, setOpenBox] = useState<boolean>(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useOutsideClicker(selectRef, setOpenBox);

    const activeItem = arrayFields?.find((el: any) => +el?.id === +formData) ?? formData;

    return arrayFields && arrayFields?.length > 0 ? (
        <div className="fill_data_form_select_item_wrap">
            <div className="fill_data_form_select_item_name">{selectTitle}</div>
            <div className="fill_data_form_select_item">
                <div className="cl2h_tab_wide_filter_item_wrap">
                    <div
                        className={`cl2h_tab_wide_filter_item ${openBox ? 'open' : ''}`}
                        ref={selectRef}
                        data-testid={dataTestId}
                    >
                        <div
                            className={`like_select_head ${formData && +formData !== 0 && formData !== arrayFields[0][index] ? 'selected' : ''}`}
                            onClick={() => setOpenBox(!openBox)}
                        >
                            {formData && +activeItem !== 0 && +activeItem[`${index}`] !== 0
                                ? index
                                    ? activeItem[`${index}`]
                                    : activeItem
                                : defaultName}
                        </div>
                        <div className={`like_select_list_wrap ${openBox ? 'open' : ''}`}>
                            <div
                                className={`like_select_list ${openBox ? 'open' : ''}`}
                                data-testid="select-list"
                            >
                                {arrayFields.map((item: any, key: number) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label"
                                            onClick={() => {
                                                handleClick(item);
                                                setOpenBox(false);
                                            }}
                                        >
                                            {item[`${index}`] ? item[`${index}`] : ''}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}
