import React from 'react';

export default function IconShieldPhone() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5512 3.36678C12.1909 3.26733 11.809 3.26733 11.4487 3.36678L5.74335 4.94154C4.98171 5.15176 4.44836 5.75146 4.39549 6.4511C4.22076 8.76365 4.40778 11.69 5.43295 14.3455C6.45312 16.9881 8.29028 19.3374 11.4206 20.5963C11.7905 20.745 12.2095 20.745 12.5794 20.5963C15.7097 19.3374 17.5468 16.9881 18.567 14.3455C19.5922 11.69 19.7792 8.76365 19.6045 6.4511C19.5517 5.75147 19.0183 5.15177 18.2566 4.94154L12.5512 3.36678ZM11.0811 2.12432C11.6816 1.95856 12.3182 1.95856 12.9188 2.12432L18.6241 3.69907C19.8607 4.04039 20.8403 5.05448 20.9387 6.35706C21.1226 8.79217 20.9323 11.9168 19.8204 14.797C18.7034 17.6903 16.6437 20.3614 13.0935 21.7892C12.3945 22.0703 11.6054 22.0703 10.9064 21.7892C7.35628 20.3614 5.2965 17.6903 4.17957 14.797C3.06763 11.9168 2.87734 8.79217 3.06134 6.35705C3.15976 5.05449 4.13919 4.0404 5.37582 3.69907L11.0811 2.12432ZM12.342 13.865L9.09 10.635H9.0905C9.0905 10.635 12.342 8.269 8.626 7C8.626 7 7 7.058 7 9.423C7 10.577 7.813 11.846 8.858 12.827C9.04182 13.001 9.23752 13.1946 9.4431 13.3979C10.6013 14.5438 12.0733 16 13.503 16C15.884 16 16 14.327 16 14.327C14.897 11.096 12.6905 13.4615 12.342 13.865Z"
            />
        </svg>
    );
}
