import React from 'react';
import { useSelector } from 'react-redux';
import '../../../../styles/liluclub.css';
import useWindowSize from '../../../../hooks/resize/useWindowSize';
import VideoShowItem from '../VideoShowItem';
import VIPListBanner from '../../../common/VIP/VIPListBanner';
import styles from '../../../common/VIP/VIPListBanner/styles.module.scss';
import { RootState } from '../../../../index';

export default function VideoShowWithBannerList() {
    const videoShowState = useSelector((state: RootState) => state.videoShow);

    const [width] = useWindowSize();

    const calcPointByWidth = () => {
        if (width > 4699) return 4;
        else if (width > 3666 && width < 4700) return 3;
        else if (width > 3199 && width < 3667) return 2;
        else if (width > 3138 && width < 3200) return 4;
        else if (width > 2496 && width < 3139) return 3;
        else if (width > 1920 && width < 2497) return 2;
        else if (width > 1899 && width < 1921) return 3;
        else if (width > 649 && width < 1900) return 2;
        else return 1;
    };

    return (
        <>
            <div className="c3mp_video_list">
                {videoShowState?.list
                    ?.slice(0, calcPointByWidth())
                    ?.map((item, key) => (
                        <VideoShowItem key={`${key}-${item?.external_id}`} profile={item} />
                    ))}
            </div>
            <VIPListBanner customStyleItem={styles.vip_onb_item__video_show} />
            <div className="c3mp_video_list">
                {videoShowState?.list
                    ?.slice(calcPointByWidth())
                    ?.map((item, key) => (
                        <VideoShowItem key={`${key}-${item?.external_id}`} profile={item} />
                    ))}
            </div>
        </>
    );
}
