//replacePath is needed to hide dynamic unique values when passing metrics.
export const PATHS = {
    ONLINE_NOW: { path: '/online-now' },
    LETTER: { path: '/letter/:letter_uid?', replacePath: '/letter/' },
    LIKE_HISTORY: { path: '/likehistory' },
    LIKE: { path: '/like' },
    MENU: { path: '/menu' },
    SEARCH: { path: '/search' },
    CHAT: { path: '/chat/:chat_uid?', replacePath: '/chat/' },
    CHAT_UNANSWERED: { path: '/unanswered/:chat_uid?', replacePath: '/unanswered/' },
    CHAT_FAVORITE: { path: '/favorite/:chat_uid?', replacePath: '/favorite/' },
    CHAT_LIKES: { path: '/likes/:chat_uid?', replacePath: '/likes/' },
    MY_PROFILE: { path: '/my-profile' },
    MY_PROFILE_PURCHASE_HISTORY: { path: '/my-profile/purchase-history' },
    MY_PROFILE_CONTACT_REQUEST: { path: '/my-profile/contact-request' },
    MY_PROFILE_MEETING_REQUEST: { path: '/my-profile/meeting-request' },
    MY_PROFILE_GIFTS: { path: '/my-profile/gifts' },
    MY_PROFILE_BLOCK_LIST: {
        path: '/my-profile/block_list/:block_chat_uid?',
        replacePath: '/my-profile/block_list/',
    },
    MY_PROFILE_EDIT: { path: '/my-profile/edit' },
    MY_PROFILE_ACCOUNT_SETTINGS: { path: '/my-profile/account-settings' },
    MY_PROFILE_PREMIUM: { path: '/my-profile/premium' },
    VIDEO_SHOW: { path: '/videoshow/:profile_id?', replacePath: '/videoshow/' },
    PROFILE_WOMAN: {
        path: '/profile-woman/:id/:video_id?',
        replacePath: '/profile-woman/',
    },
    PROFILE: { path: '/profile/:id', replacePath: '/profile/' },
    GIFTS: { path: '/gifts' },
    GIFTS_CART: { path: '/gifts/cart' },
    FAQ: { path: '/faq' },
    TUTORIAL: { path: '/tutorial' },
    POLICY: { path: '/policy' },
    CHECKOUT: { path: '/checkout' },
    MAIL_UNSUBSCRIBE: { path: '/mail-unsubscribe' },
    POSTS: { path: '/posts/:post_id?', replacePath: '/posts/' },
    HASH: { path: '/hash/:hash', replacePath: '/hash/' },
    CONFIRM: { path: '/confirm/:confirm_hash', replacePath: '/confirm/' },
    LOVE_STORIES: { path: '/love-stories' },
    PROMOCODE: { path: '/promocode/:promocode_hash', replacePath: '/promocode/' },
    ERROR: { path: '/error' },
    NOT_FOUND: { path: '/not-found' },
    STATIC_PAGE: { path: '/:page' },
    FORBIDDEN: { path: '/forbidden' },
};
