import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import * as menuAction from '../../store/actions/menu';
import * as currentChatAction from '../../store/actions/currentChat';
import * as MENU from '../../constants/menu';
import SITE from '../../config/site';

export default function useTransitionEffect(location: { pathname: string }) {
    const dispatch = useDispatch();

    const SUB_MENU_LIST = ['dialogues', 'unanswered', 'favorite', 'likes'];
    const MAIN_MENU_LIST = [
        'chat',
        'letter',
        'search',
        'like',
        'videoshow',
        'block_list',
        'my-profile',
        'menu',
        'likehistory',
        'cart',
        'profile-woman',
        'gifts',
        'online-now',
        'faq',
        'tutorial',
        'posts',
        'policy',
    ];

    useEffect(() => {
        if (location?.pathname) {
            setByLocationMenu(location.pathname);
            if (
                !location?.pathname.includes('profile-woman') &&
                !location?.pathname.includes('gifts') &&
                !location?.pathname.includes('cart') &&
                SITE.ID !== 23
            ) {
                localStorage.removeItem('from-page');
            }
        }
    }, [location]);

    const isMenu = (menuList: string[], path: string) => {
        if (!path) return null;

        return menuList.some((item) => path?.includes(item));
    };

    const getMenuName = (menuList: string[], path: string) => {
        return menuList.find((item) => path?.includes(item)) || '';
    };

    const setByLocationMenu = (location: string) => {
        if (isMenu(SUB_MENU_LIST, location)) {
            const subMenuName = getMenuName(SUB_MENU_LIST, location);
            if (subMenuName) {
                dispatch(menuAction.setSubMenu(subMenuName));
            }
        } else {
            if (
                getMenuName(MAIN_MENU_LIST, location) !== MENU.MENU_CHAT &&
                getMenuName(MAIN_MENU_LIST, location) !== MENU.MENU_BLOCK
            ) {
                dispatch(currentChatAction.removeMainChatInfo());
            }
            dispatch(menuAction.setMainMenu(getMenuName(MAIN_MENU_LIST, location)));
        }
    };
}
