import React from 'react';

export default function IconQuestionMark() {
    return (
        <svg
            width="10"
            height="17"
            viewBox="0 0 10 17"
            fill="white"
            stroke="#1C2675"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.52897 1.27968L8.52896 1.27969L8.53115 1.28146C9.34426 1.94021 9.75 2.80647 9.75 3.90407C9.75 4.52854 9.62448 5.08234 9.37858 5.57071C9.12599 6.07236 8.81787 6.47239 8.45701 6.77698L8.45698 6.77694L8.45319 6.78027C8.08444 7.1045 7.70836 7.40409 7.32499 7.67914C6.91877 7.97058 6.57377 8.29046 6.29244 8.63967C5.99565 9.00807 5.83899 9.4164 5.83899 9.85901C5.83899 10.2009 5.91881 10.5066 6.0822 10.7678L4.04414 11.3991C3.63992 10.8796 3.43501 10.255 3.43501 9.51308C3.43501 8.90261 3.60042 8.35896 3.93027 7.87476C4.27517 7.36847 4.64201 7.00074 5.02715 6.75994C5.46548 6.48588 5.87084 6.16072 6.24316 5.78498C6.62951 5.39506 6.85422 4.97305 6.85422 4.5218C6.85422 4.03805 6.66694 3.63094 6.29948 3.3197C5.93811 3.00007 5.41473 2.86337 4.77752 2.86337C3.66916 2.86337 2.70695 3.33983 1.89634 4.25691L0.326327 2.39591C1.55578 0.964394 3.14971 0.25 5.12881 0.25C6.58817 0.25 7.71212 0.602507 8.52897 1.27968ZM6.23541 16.235C5.90489 16.5837 5.52428 16.75 5.08197 16.75C4.63965 16.75 4.25905 16.5837 3.92852 16.235C3.59773 15.886 3.43501 15.478 3.43501 14.9985C3.43501 14.5191 3.59773 14.1111 3.92852 13.7621C4.25905 13.4134 4.63965 13.2471 5.08197 13.2471C5.52428 13.2471 5.90489 13.4134 6.23541 13.7621C6.5662 14.1111 6.72892 14.5191 6.72892 14.9985C6.72892 15.478 6.5662 15.886 6.23541 16.235Z"
                strokeWidth="0.5"
            />
        </svg>
    );
}
