import React from 'react';

export default function IconFilter() {
    return (
        <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="#446077"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2_5)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.4656 0.700378C1.04277 0.700378 0.656504 0.951814 0.463475 1.31019L0.461363 1.3142L0.460681 1.31557C0.264328 1.70816 0.321885 2.15597 0.571349 2.48849L0.573949 2.49186L8.63119 12.6231V19.1826C8.63119 19.6018 8.85657 19.9669 9.21826 20.1615L9.21949 20.1622L9.22217 20.1636L9.22364 20.1643C9.37812 20.2415 9.54707 20.3001 9.74902 20.3001C9.96518 20.3001 10.1904 20.246 10.3902 20.1042L10.3933 20.1021L12.938 18.3373L12.9411 18.3353L12.9437 18.3333C13.3597 18.0214 13.5974 17.5499 13.5974 17.0493V12.6001L21.6309 2.4929L21.6319 2.49163L21.6336 2.48942L21.6343 2.48848C21.8838 2.15596 21.9413 1.70816 21.7449 1.31556C21.5486 0.922987 21.1558 0.700378 20.74 0.700378H1.4656ZM3.76496 4.37058L1.88183 2.01793H20.3256L12.4148 11.97L12.4074 11.9799L12.405 11.9835C12.3482 12.0686 12.2796 12.1995 12.2796 12.3698V17.0263C12.2796 17.1244 12.2398 17.2029 12.1757 17.2564L9.94902 18.8005V12.3698C9.94902 12.1687 9.86755 11.9703 9.70208 11.8533L4.79455 5.68813H12.709C13.0718 5.68813 13.3679 5.39219 13.3679 5.02936C13.3679 4.66651 13.0718 4.37058 12.709 4.37058H3.76496Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_2_5">
                    <rect width="22" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
