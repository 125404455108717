import React from 'react';
import styles from './styles.module.scss';
import ImbraModal from '../../common/Modals/ImbraModal';
import VerifyStatusModal from '../../common/Modals/VerifyStatusModal';
import useInitContactRequest from '../../../hooks/contactRequest/useInitContactRequest';
import useInitVerifyStatus from '../../../hooks/verifyStatus/useInitVerifyStatus';
import * as USER from '../../../constants/user';
import LoaderGif from '../../common/LoaderGif';
import { IVerifyStatusProps } from '../../common/Modals/VerifyStatusModal/VerifyStatus/index.interface';
import { IContactRequestProfile } from '../../common/Modals/ContactRequestModal/index.interface';
import avatar_placeholder from '../../../img/placeholder.gif';
import IconVerifyRequestCanceled from '../../Icons/IconVerifyRequestCanceled';
import IconVerifyRequestUpload from '../../Icons/IconVerifyRequestUpload';
import IconVerifyRequestVerified from '../../Icons/IconVerifyRequestVerified';
import IconSearch from '../../Icons/IconSearch';
import IconCheckedCircle from '../../Icons/IconCheckedCircle';
import IconCross from '../../Icons/IconCross';

interface INewRequestContactModalProps {
    profile: IContactRequestProfile;
    closeContactRequestModal: () => void;
}

export default function NewRequestContactModal({
    profile,
    closeContactRequestModal,
}: INewRequestContactModalProps) {
    const initContactRequest = useInitContactRequest({ profile, closeContactRequestModal });
    const initVerifyStatus = useInitVerifyStatus();

    const isContactRequestDone =
        initContactRequest.isConditionContactRequestDone &&
        !initVerifyStatus?.userInfo?.is_verified &&
        !initContactRequest?.isUserGetContactBeforeUpdated;

    const isContactRequestVIPDone =
        initContactRequest.isConditionContactRequestDone &&
        !!initVerifyStatus?.userInfo?.is_verified &&
        !initContactRequest?.isUserGetContactBeforeUpdated;

    const switcherImbraStatusIcon = () => {
        switch (initContactRequest?.imbraStatus) {
            case USER.IMBRA_IN_PROGRESS:
                return (
                    <>
                        <div
                            className={`${styles.popup_contact_request_imbra_icon} ${styles.request_icon_search}`}
                        >
                            <IconSearch />
                        </div>
                        <div className={styles.popup_contact_request_imbra_status}>
                            Status: Verification of documents
                        </div>
                    </>
                );
            case USER.IMBRA_DECLINE:
                return (
                    <>
                        <div className={styles.popup_contact_request_imbra_icon}>
                            <IconVerifyRequestCanceled />
                        </div>
                        <div
                            className={`${styles.popup_contact_request_imbra_status} ${styles.canceled}`}
                        >
                            Canceled
                        </div>
                    </>
                );
            case USER.IMBRA_VERIFIED:
                return (
                    <>
                        <div className={styles.popup_contact_request_imbra_icon}>
                            <IconVerifyRequestVerified />
                        </div>
                        <div
                            className={`${styles.popup_contact_request_imbra_status} ${styles.verified}`}
                        >
                            Verified
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <div className={styles.popup_contact_request_imbra_icon}>
                            <IconVerifyRequestUpload />
                        </div>
                        <div className={styles.popup_contact_request_imbra_status}>
                            Please upload IMBRA
                        </div>
                    </>
                );
        }
    };

    const switcherImbraStatusText = () => {
        switch (initContactRequest?.imbraStatus) {
            case USER.IMBRA_IN_PROGRESS:
                return (
                    <div className={styles.popup_contact_request_imbra_text}>
                        Please wait while we check the correctness of filling out the form.
                    </div>
                );
            case USER.IMBRA_DECLINE:
                return (
                    <div className={styles.popup_contact_request_imbra_text}>
                        You have incorrectly filled out the IMBRA. Please upload a new scan based on
                        the corrections listed below. Review our comments carefully. If you have any
                        questions or misunderstandings, please contact our support team.
                    </div>
                );
            case USER.IMBRA_VERIFIED:
                return (
                    <div className={styles.popup_contact_request_imbra_text}>
                        You have filled in the IMBRA correctly. You may now make a contact request.
                    </div>
                );
            default:
                return (
                    <div className={styles.popup_contact_request_imbra_text}>
                        In order to exchange contact information, please download the IMBRA form,
                        fill it out and upload it for verification.
                    </div>
                );
        }
    };

    const switcherImbraStatusBtn = (isMob = false) => {
        switch (initContactRequest?.imbraStatus) {
            case USER.IMBRA_IN_PROGRESS:
                return (
                    <div
                        className={`${styles.popup_contact_request_send_btn} ${isMob ? styles.mob : ''}`}
                    >
                        In progress
                    </div>
                );
            case USER.IMBRA_DECLINE:
                return (
                    <>
                        <div
                            className={`${styles.popup_contact_request_send_btn} ${isMob ? styles.mob : ''} ${styles.canceled}`}
                            onClick={initContactRequest.openImbraModal}
                        >
                            Please fill out the IMBRA again.
                        </div>
                        <div
                            className={`${styles.popup_contact_request_text_reason} ${isMob ? styles.mob : ''}`}
                        >
                            {initContactRequest?.reasonImbraCanceled}
                        </div>
                    </>
                );
            case USER.IMBRA_VERIFIED:
                return (
                    <div
                        className={`${styles.popup_contact_request_send_btn} ${isMob ? styles.mob : ''} ${styles.verified}`}
                    >
                        Verified
                    </div>
                );
            default:
                return (
                    <div
                        className={`${styles.popup_contact_request_send_btn} ${isMob ? styles.mob : ''} ${styles.active}`}
                        onClick={initContactRequest.openImbraModal}
                    >
                        Start verify
                    </div>
                );
        }
    };

    const CheckCircleVerification = () => {
        return (
            <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.4853 4.51469C19.2188 2.24823 16.2053 1 13 1C9.79464 1 6.78114 2.24823 4.51469 4.51469C2.24819 6.78119 1 9.79469 1 13C1 16.2053 2.24819 19.2188 4.51469 21.4853C6.78119 23.7518 9.79464 25 13 25C16.2053 25 19.2188 23.7518 21.4853 21.4853C23.7518 19.2188 25 16.2053 25 13C25 9.79469 23.7518 6.78123 21.4853 4.51469ZM13 23.5938C7.15858 23.5938 2.40625 18.8414 2.40625 13C2.40625 7.15858 7.15858 2.40625 13 2.40625C18.8414 2.40625 23.5938 7.15858 23.5938 13C23.5938 18.8414 18.8414 23.5938 13 23.5938Z"
                    fill="#EBEDFA"
                    stroke="#EBEDFA"
                    strokeWidth="0.5"
                />
                <path
                    d="M18.7919 9.20302C18.5146 8.93232 18.0648 8.93232 17.7874 9.20306L11.5146 15.3261L8.21263 12.1029C7.93526 11.8322 7.48547 11.8322 7.20806 12.1029C6.93065 12.3736 6.93065 12.8127 7.20806 13.0835L11.0123 16.7969C11.151 16.9323 11.3328 17 11.5146 17C11.6964 17 11.8782 16.9323 12.0169 16.7969L18.7919 10.1836C19.0694 9.91286 19.0694 9.47381 18.7919 9.20302Z"
                    fill="#EBEDFA"
                    stroke="#EBEDFA"
                    strokeWidth="0.5"
                />
            </svg>
        );
    };

    return (
        <>
            {!initContactRequest?.isLoadedData ? (
                <LoaderGif mainStyle={{ width: '100px', height: '100px' }} />
            ) : (
                <div className={styles.popup_contact_request}>
                    <div className={styles.popup_contact_request_title}>Contact request</div>
                    <div className={styles.popup_contact_request_top}>
                        <div className={styles.popup_contact_request_profile_info_wrap}>
                            <div className={styles.popup_contact_request_profile_photo}>
                                <img
                                    src={
                                        profile?.avatar || profile?.photo_link || avatar_placeholder
                                    }
                                    alt="user avatar"
                                />
                            </div>
                            <div className={styles.popup_contact_request_profile_info}>
                                <div className={styles.popup_contact_request_profile_name}>
                                    {profile?.name || ''}, {profile?.age || ''}
                                </div>
                                <div className={styles.popup_contact_request_profile_id}>
                                    ID {profile?.public_external_id || ''}
                                </div>
                                <div className={styles.popup_contact_request_profile_place}>
                                    {!!profile?.country_code?.length && (
                                        <img
                                            src={`/assets/flags/${profile?.country_code?.toLowerCase()}.jpg`}
                                            alt=""
                                        />
                                    )}
                                    <span>{profile?.city_name || ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.popup_contact_request_top_text_wrap}>
                            <p>
                                A contact request is made so that you can receive the member’s
                                e-mail and start communicating with her outside of our platform. We
                                care about the privacy of our users. Therefore, you should chat with
                                your partner for a while and establish a comfortable relationship
                                before requesting contact details. Once you get to know each other
                                better and are confident that your relationship is building, your
                                partner will be more likely to agree to this request.{' '}
                                {initContactRequest?.isAllowGetContactRequest
                                    ? 'After you’ve made a contact request, our moderation team will provide you with feedback within 96 hours and inform you of whether the girl has agreed or not.'
                                    : ''}
                            </p>
                        </div>
                    </div>
                    <div
                        className={`${styles.popup_contact_request_conditions} ${initContactRequest.isConditionContactRequestDone ? styles.done : ''}`}
                    >
                        <div className={styles.popup_contact_request_credit_counter}>
                            <div className={styles.popup_contact_request_credit_counter_title}>
                                Credits counter
                            </div>
                            <div className={styles.popup_contact_request_credit_counter_text}>
                                You should spend the required amount of credits in communication
                                (chat, letter, etc.).
                            </div>
                            <div className={styles.popup_contact_request_credit_counter_row}>
                                <div className={styles.popup_contact_request_spent_credits}>
                                    {initContactRequest?.countCredits >=
                                    initContactRequest?.creditsLimit
                                        ? initContactRequest?.creditsLimit
                                        : initContactRequest?.countCredits}{' '}
                                    Credits
                                </div>
                                <div className={styles.popup_contact_request_limit_credits}>
                                    of {initContactRequest?.creditsLimit} Credits
                                </div>
                            </div>
                            <div className={styles.popup_contact_request_progressbar_line}>
                                <div
                                    className={
                                        styles.popup_contact_request_progressbar_line_indicator
                                    }
                                    style={{
                                        width: `${initContactRequest?.countCreditsInPercent > 100 ? 100 : initContactRequest?.countCreditsInPercent}%`,
                                    }}
                                />
                            </div>
                        </div>
                        {!initContactRequest?.isUserGetContactBeforeUpdated && (
                            <div className={styles.popup_contact_request_chat_days}>
                                <div className={styles.popup_contact_request_chat_days_title}>
                                    Chat days
                                </div>
                                <div className={styles.popup_contact_request_chat_days_text}>
                                    You should spend at least the required number of days in
                                    communication to get to know your partner better.
                                </div>
                                <div className={styles.popup_contact_request_chat_days_subtitle}>
                                    {initContactRequest?.spendDays >=
                                    USER.SPEND_DAYS_TO_CONTACT_REQUEST
                                        ? 'Success'
                                        : `${USER.SPEND_DAYS_TO_CONTACT_REQUEST - initContactRequest?.spendDays} Days left`}
                                </div>
                                <div className={styles.popup_contact_request_chat_days_row}>
                                    {[...Array(USER.SPEND_DAYS_TO_CONTACT_REQUEST).keys()].map(
                                        (item) => (
                                            <div
                                                key={item}
                                                className={`${styles.popup_contact_request_chat_days_item} ${initContactRequest?.spendDays > item ? styles.fill : ''}`}
                                            >
                                                <span>{++item}</span>
                                            </div>
                                        ),
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {isContactRequestDone && (
                        <div className={styles.popup_contact_request_verification}>
                            <div className={styles.popup_contact_request_verification_left}>
                                <div className={styles.popup_contact_request_verification_title}>
                                    GET VERIFIED AND BECOME A VIP USER
                                </div>
                                <div className={styles.popup_contact_request_verification_text}>
                                    Dear user, become verified and get special offers and VIP status
                                </div>
                                <div
                                    className={`${styles.popup_contact_request_send_btn} ${styles.active}`}
                                    onClick={initVerifyStatus.openVerifyStatusModal}
                                >
                                    Start verify
                                </div>
                            </div>
                            <div className={styles.popup_contact_request_verification_right}>
                                <div
                                    className={styles.popup_contact_request_verification_right_col}
                                >
                                    <div
                                        className={`${styles.popup_contact_request_verification_icon} ${initVerifyStatus.userInfo?.is_approve_phone ? styles.active : ''}`}
                                    >
                                        <CheckCircleVerification />1 step
                                    </div>
                                </div>
                                <div
                                    className={styles.popup_contact_request_verification_right_col}
                                >
                                    <div
                                        className={`${styles.popup_contact_request_verification_icon} ${initVerifyStatus.userInfo?.email_confirmed ? styles.active : ''}`}
                                    >
                                        <CheckCircleVerification />2 step
                                    </div>
                                </div>
                                <div
                                    className={styles.popup_contact_request_verification_right_col}
                                >
                                    <div
                                        className={`${styles.popup_contact_request_verification_icon} ${initVerifyStatus.userInfo?.real_name?.length ? styles.active : ''}`}
                                    >
                                        <CheckCircleVerification />3 step
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles.popup_contact_request_send_btn} ${styles.mob} ${styles.active}`}
                                onClick={initVerifyStatus.openVerifyStatusModal}
                            >
                                Start verify
                            </div>
                        </div>
                    )}
                    {isContactRequestVIPDone && (
                        <div className={styles.popup_contact_request_imbra}>
                            <div className={styles.popup_contact_request_imbra_left}>
                                <div className={styles.popup_contact_request_imbra_title}>
                                    IMBRA verification
                                </div>
                                {switcherImbraStatusText()}
                                {switcherImbraStatusBtn()}
                            </div>
                            <div className={styles.popup_contact_request_imbra_right}>
                                <div className={styles.popup_contact_request_imbra_right_col}>
                                    <div className={styles.popup_contact_request_imbra_subtitle}>
                                        Status
                                    </div>
                                    {switcherImbraStatusIcon()}
                                </div>
                            </div>
                            {switcherImbraStatusBtn(true)}
                        </div>
                    )}
                    <div className={styles.popup_contact_request_bottom}>
                        <div className={styles.popup_contact_request_price}>
                            <div className={styles.popup_contact_request_price_title}>
                                Request price
                            </div>
                            <div className={styles.popup_contact_request_price_value}>
                                {initContactRequest?.creditsPrice} credits
                            </div>
                            {initContactRequest?.isAllowGetContactRequest && (
                                <div className={styles.popup_contact_request_price_text}>
                                    If the member has declined the request,{' '}
                                    {initContactRequest?.creditsPrice} credits would be transferred
                                    back to your account.
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.popup_contact_request_send_btn} ${styles.main} ${initContactRequest?.isAllowGetContactRequest ? styles.active : ''}`}
                            onClick={initContactRequest.submitMakeRequest}
                        >
                            <IconCheckedCircle />
                            Make request
                        </div>
                    </div>
                    <div
                        className={styles.popup_contact_request_close}
                        onClick={closeContactRequestModal}
                    >
                        <IconCross />
                    </div>
                </div>
            )}

            <VerifyStatusModal initVerifyStatus={initVerifyStatus as IVerifyStatusProps} />
            <ImbraModal
                isActiveImbraModal={initContactRequest.isActiveImbraModal}
                closeImbraModal={initContactRequest.closeImbraModal}
                uploadImbraFile={initContactRequest.uploadImbraFile}
                imbraStatus={initContactRequest?.imbraStatus}
            />
        </>
    );
}
