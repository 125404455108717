import React from 'react';

export default function IconArrowDown() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.83207 15.3569L0.778006 9.77752C0.408633 9.4371 0.408633 8.88502 0.778006 8.54461C1.14746 8.20412 1.74635 8.20412 2.1158 8.54461L6.55502 12.6358L6.55502 1.48409C6.55502 1.00266 6.97858 0.612305 7.50097 0.612305C8.02328 0.612305 8.44692 1.00266 8.44692 1.48409L8.44691 12.6358L12.8861 8.54474C13.2556 8.20426 13.8545 8.20426 14.2239 8.54474C14.4085 8.71492 14.501 8.93809 14.501 9.1612C14.501 9.38431 14.4085 9.60741 14.2239 9.77766L8.16987 15.3569C7.80042 15.6974 7.20152 15.6974 6.83207 15.3569Z" />
        </svg>
    );
}
