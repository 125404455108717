import React from 'react';
import popup from '../../../../Profile/ProfilePurchaseHistory/popup.module.scss';
import { IChangeAmountSubscribeProps } from './index.interface';
import { convertPrice, currencyPriceString } from '../../../../../services/methods';
import IconCross from '../../../../Icons/IconCross';
import useAutoTopUpChange from '../../../../../hooks/autoTopUp/useAutoTopUpChange';

export default function AutoTopUpChange({
    closeModal,
    activeSubscribeListInfo,
    activeSubscribeData,
    activeSubscribePaymentMethod,
}: IChangeAmountSubscribeProps) {
    const { selectedCreditPackage, onSubscribeByActivePackage, selectCreditPackage } =
        useAutoTopUpChange({
            activeSubscribePaymentMethod,
            activeSubscribeData,
        });

    return (
        <div className={popup.top_up_modal_wrap}>
            <div className={popup.modal_title}>Choose new credit pack</div>
            <div className={popup.modal_choose_form}>
                {!!activeSubscribeListInfo &&
                    activeSubscribeListInfo?.map((el, index) => (
                        <div
                            className={`${popup.modal_choose_item} ${+activeSubscribeData?.id === +el?.id && popup.default}`}
                            key={el?.id}
                        >
                            <input
                                type="radio"
                                id={`choose_sub_${index}`}
                                name="choose_sub"
                                value={el?.id}
                                checked={selectedCreditPackage === el?.id}
                                onChange={() =>
                                    +activeSubscribeData?.id !== +el?.id &&
                                    selectCreditPackage(el?.id)
                                }
                            />
                            <label htmlFor={`choose_sub_${index}`}>
                                {el?.credits} credit pack /{' '}
                                {currencyPriceString(el?.currency, convertPrice(el?.amount))}
                            </label>
                        </div>
                    ))}
            </div>
            <div className={popup.modal_buttons}>
                <div
                    className={`${popup.modal_btn} ${popup.color_btn}`}
                    onClick={onSubscribeByActivePackage}
                >
                    Accept
                </div>
            </div>
            <div className={popup.close_modal} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
