import React from 'react';
import * as DICTIONARY_MODAL from '../../../../../constants/dictionary/modals';
import styles from './styles.module.scss';
import { ILoginProps } from './index.interface';
import IconCross from '../../../../Icons/IconCross';

export default function Login({
    formData,
    closeModal,
    changeUsername,
    changePassword,
    logIn,
    activeSetPassword,
    activeForgetPassword,
    setActiveForgetPassword,
}: ILoginProps) {
    const switchButtonName = () => {
        if (activeSetPassword) return DICTIONARY_MODAL.SET_PASSWORD_BUTTON;
        if (activeForgetPassword) return DICTIONARY_MODAL.FORGET_BUTTON;

        return DICTIONARY_MODAL.LOGIN_BUTTON;
    };

    const switchInputs = () => {
        if (activeSetPassword) {
            return (
                <input
                    type="text"
                    required
                    data-testid="password"
                    className={styles.popup_main_login_input}
                    placeholder="Your Password"
                    value={formData.password}
                    onChange={changePassword}
                    autoComplete="new-password"
                />
            );
        }
        if (activeForgetPassword) {
            return (
                <input
                    type="email"
                    id="email"
                    name="email"
                    data-testid="email"
                    required
                    className={styles.popup_main_login_input}
                    placeholder="Your E-mail"
                    value={formData?.email}
                    onChange={changeUsername}
                />
            );
        }
        return (
            <>
                <input
                    type="email"
                    id="email"
                    name="email"
                    data-testid="email"
                    required
                    className={styles.popup_main_login_input}
                    placeholder="Your E-mail"
                    value={formData?.email}
                    onChange={changeUsername}
                    autoComplete="username"
                />
                <input
                    type="password"
                    required
                    data-testid="password"
                    className={styles.popup_main_login_input}
                    placeholder="Your Password"
                    value={formData.password}
                    onChange={changePassword}
                    autoComplete="current-password"
                />
            </>
        );
    };

    const switchModalHeader = () => {
        if (activeSetPassword) {
            return 'Enter your new password';
        }
        if (activeForgetPassword) {
            return 'Type your email to reset password';
        }
        return '';
    };

    return (
        <div className={styles.popup_main_login} data-testid="login-modal">
            <div className={styles.popup_main_login_top}>
                <form onSubmit={logIn}>
                    <div className={styles.popup_error_title}>{switchModalHeader()}</div>
                    <div>{switchInputs()}</div>
                    <button
                        className={styles.popup_main_login_btn}
                        data-testid="submit-btn"
                        type="submit"
                    >
                        {switchButtonName()}
                    </button>
                    {!activeForgetPassword && !activeSetPassword && (
                        <div
                            className={styles.popup_main_login_forget_btn}
                            onClick={() => setActiveForgetPassword(true)}
                            data-testid="forgot-password-btn"
                        >
                            {DICTIONARY_MODAL.LOGIN_FORGET_PASSWORD}
                        </div>
                    )}
                </form>
            </div>
            <div
                className={styles.popup_main_login_close}
                data-testid="close-login-cross"
                onClick={closeModal}
            >
                <IconCross />
            </div>
        </div>
    );
}
