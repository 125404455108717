import React, { useEffect } from 'react';
import ProfileAccountSettings from '../../components/Profile/ProfileAccountSettings';
import { useDispatch } from 'react-redux';
import * as menuAction from '../../store/actions/menu';

export default function ProfileAccountSettingsPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuAction.setMainMenu('my-profile/account-settings'));
    }, []);

    return (
        <>
            <ProfileAccountSettings />
        </>
    );
}
