import React from 'react';
import styles from './styles.module.scss';
import { ITopProfile } from '../../../services/api/v3/SearchApi.interface';

interface IGirlsProfilesSmallProps {
    profiles: ITopProfile[];
    openChat: (id: number) => void;
    openGalleryModal: (id: number, images: any) => void;
}

export default function GirlsProfilesSmall({
    profiles,
    openChat,
    openGalleryModal,
}: IGirlsProfilesSmallProps) {
    return (
        <div className={styles.unsubscribe_girls_block_small}>
            <ul className={styles.unsubscribe_girls_list_small}>
                {!!profiles?.length &&
                    profiles.map((profile) => (
                        <li className={styles.unsubscribe_girl_card_small} key={profile?.id}>
                            <img
                                className={styles.girl_card_small_photo}
                                src={profile?.search_img}
                                alt=""
                                onClick={() => openGalleryModal(profile?.external_id, [])}
                            />
                            {/*{!!+profile?.online &&*/}
                            <div className={styles.girl_card_small_status}>Online</div>
                            {/*}*/}
                            <button
                                type="button"
                                className={`${styles.girl_card_btn} ${styles.girl_card_btn_chat}`}
                                onClick={() => openChat(profile?.external_id)}
                            >
                                Chat
                            </button>
                        </li>
                    ))}
            </ul>
            <div className={styles.unsubscribe_girls_small_content}>
                <p className={styles.unsubscribe_girls_small_text}>
                    Members who like you. Right now they are waiting for a message from you.
                </p>
            </div>
        </div>
    );
}
