import VideoShowItem from '../VideoShowItem';
import React from 'react';
import { useSelector } from 'react-redux';
import '../../../../styles/liluclub.css';
import { RootState } from '../../../../index';

export default function VideoShowDefaultList() {
    const videoShowState = useSelector((state: RootState) => state.videoShow);

    return (
        <div className="c3mp_video_list">
            {videoShowState?.list?.map((item, key) => <VideoShowItem key={key} profile={item} />)}
        </div>
    );
}
