import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import PageApi from '../../services/api/PageApi';
import * as MENU from '../../constants/menu';
import Footer from '../../components/Auth/AuthFooter';
import AlertModal from '../../components/common/Modals/AlertModal';
import useInitTerm from '../../hooks/term/useInitTerm';
import TermModal from '../../components/common/Modals/TermModal';
import useInitSupport from '../../hooks/support/useInitSupport';
import SupportModal from '../../components/common/Modals/SupportModal';
import Logotypes from '../../img/logotypes';
import SITE from '../../config/site';
import { Params } from '@sentry/react/types/types';
import { match } from 'react-router';
import useInitAffiliats from '../../hooks/support/useInitAffiliats';

export default function StaticPage({ match }: { match: match<Params> }) {
    const history = useHistory();
    const initTerm = useInitTerm();
    const initAffiliats = useInitAffiliats();
    const initSupport = useInitSupport();

    const [page, setPage] = useState('');
    const [title, setTitle] = useState('');
    const [header, setHeader] = useState('');
    const [keywords, setKeywords] = useState('');
    const [description, setDescription] = useState('');

    const goBack = () => (window.location.href = '/');

    useEffect(() => {
        if (match?.params?.page && MENU.ARRAY_STATIC_PAGE.includes(match?.params?.page)) {
            new PageApi().postShow(match?.params?.page).then((res) => {
                if (
                    (res?.status && Array.isArray(res?.result) && !res?.result?.length) ||
                    !res?.result
                ) {
                    history.push('/not-found');
                } else if (res?.status) {
                    if (res?.result?.content) setPage(res?.result?.content);
                    if (res?.result?.keywords) setKeywords(res?.result?.keywords);
                    if (res?.result?.header) setHeader(res?.result?.header);
                    if (res?.result?.title) setTitle(res?.result?.title);
                    if (res?.result?.description) setDescription(res?.result?.description);
                }
            });
        } else {
            history.push('/not-found');
        }
    }, [match.params.page]);

    useEffect(() => {
        document?.body?.querySelector('#root > .content')?.classList?.add('main_page_content');
    }, []);

    if (!page) return null;
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
            </Helmet>
            <div className={styles.page_header}>
                <div className={styles.page_header_container}>
                    <div className={styles.main_header_logo} onClick={goBack}>
                        <img src={Logotypes.images['logo.svg']} alt={SITE.name} />
                    </div>
                </div>
            </div>
            <div className={styles.page_content}>
                <h1 className={styles.page_content_title}>{header}</h1>
                <div dangerouslySetInnerHTML={{ __html: page }} />
            </div>
            <Footer
                openTermModal={initTerm.openTermModal}
                openAffiliatsModal={initAffiliats.openAffiliatsModal}
            />
            <TermModal {...initTerm} />
            <SupportModal {...initSupport} />

            <AlertModal />
        </>
    );
}
