import React, { useEffect, useState } from 'react';
import { IFilterMenuProps } from './index.interface';
import IconCross from '../../../Icons/IconCross';
import IconSearch from '../../../Icons/IconSearch';

export default function FilterMenu({ searchInput, handleOnline, handleSearch }: IFilterMenuProps) {
    const [inputValue, setInputValue] = useState('');

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event?.target?.value);
    };

    useEffect(() => {
        if (inputValue !== searchInput) {
            const timeoutID = setTimeout(() => handleSearch(inputValue), 500);
            return () => clearTimeout(timeoutID);
        }
    }, [inputValue]);

    return (
        <div className="c2_top">
            <span className="c2_top_online_text">online</span>
            <div className="c2_top_tumbler" data-testid="online">
                <input
                    type="checkbox"
                    id="tumbler_onoff"
                    data-testid="button-online-chats"
                    onClick={handleOnline}
                />
                <label htmlFor="tumbler_onoff" />
            </div>
            <div className="c2_top_search">
                <input
                    type="text"
                    name="searchField"
                    data-testid="search"
                    value={inputValue}
                    onChange={onChangeInput}
                    placeholder="Search ID or name"
                />
                <button
                    className={inputValue?.length ? 'c2_top_search_close' : 'c2_top_search_search'}
                    type="button"
                    data-testid="search-btn"
                    onClick={() => setInputValue('')}
                >
                    {inputValue?.length ? <IconCross /> : <IconSearch />}
                </button>
            </div>
        </div>
    );
}
