import * as REGISTER from '../../../constants/register';

export function checkErrorField(errorFields: string[], fiendName: string): string {
    return errorFields?.length && errorFields.includes(fiendName) ? 'error' : '';
}

export function checkErrorSelectDate(errorFields: string[]): boolean {
    if (errorFields?.length) {
        return errorFields.some((e) => REGISTER.ARRAY_SELECT_DATE.includes(e));
    }

    return false;
}

export function generateUTMCDate(): string {
    const date = new Date();

    const dd = +date.getDate() >= 10 ? +date.getDate() : `0${+date.getDate()}`;
    const mm = +date.getMonth() + 1 >= 10 ? +date.getMonth() + 1 : `0${+date.getMonth() + 1}`;
    const yyyy = +date.getFullYear();

    return `utm_cdate=${dd}${mm}${yyyy}`;
}
