import * as AWS from '../../../constants/aws';
import SITE from '../../../config/site';

export const getImageLink = (link: string, size: number[]): string => {
    if (!link?.length) return '';

    let exceptionImage = false;

    AWS.AWS_IMAGE_EXCEPTION.forEach((imgLink) => {
        if (link.includes(imgLink)) {
            exceptionImage = true;
        }
    });

    if (exceptionImage) return link;

    const [width, height] = size;
    const sizePrefix = `${+SITE.ID > 1 ? 's' + SITE.ID + '-' : ''}w-${width}-h-${height}-`;
    // Replace add prefix before last slash
    link = link.replace(/(\/)([^/]*)$/, '$1' + sizePrefix + '$2');

    // Replace png to jpg
    link = link.replace(/\.(png|PNG)$/, '.jpg');

    return link;
};
