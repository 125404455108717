import React from 'react';
import RequestContactButton from '../../../Requests/RequestContactButton';
import MeetingRequestModal from '../../Modals/MeetingRequestModal';
import useInitModalMeetingRequest from '../../../../hooks/dom/useInitModalMeetingRequest';
import * as MENU from '../../../../constants/menu';
import { useSelector } from 'react-redux';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import IconGift from '../../../Icons/IconGift';
import { RootState } from '../../../../index';
import { localStorageGetItem } from '../../../../utils/local-storage';
import { IFunctionalityMainProps } from './index.interface';
import IconActiveMeetingRequest from '../../../Icons/IconActiveMeetingRequest';

export default function FunctionalityMain({
    openGift,
    toggleMediaWrap,
    isMediaWrapOpen,
    optionsItemMediaRef,
    profileChatInfo,
}: IFunctionalityMainProps) {
    const initModalMeetingRequest = useInitModalMeetingRequest();
    const handleActiveBlocks = useHandleActiveBlocks();

    const userState = useSelector((state: RootState) => state.user);

    let isActiveGift;
    let isActiveContactRequest;
    let isActiveMeetingRequest;
    let isActiveCommonContent;

    if (handleActiveBlocks.isNewMailCategory1) {
        if (handleActiveBlocks.isPaymentUser) {
            isActiveGift = !handleActiveBlocks.isHideGift;
            isActiveContactRequest = false;
            isActiveMeetingRequest = handleActiveBlocks.isMeetingRequest;
            isActiveCommonContent = false;
        } else {
            isActiveGift = false;
            isActiveContactRequest = false;
            isActiveMeetingRequest = false;
            isActiveCommonContent = false;
        }
    } else {
        isActiveGift = !handleActiveBlocks.isHideGift;
        isActiveContactRequest = handleActiveBlocks.isContactRequest;
        isActiveMeetingRequest = handleActiveBlocks.isMeetingRequest;
        isActiveCommonContent =
            (localStorageGetItem('common') || !!+userState?.common_files) &&
            MENU.CHAT_PAGES.some((e) => window.location.pathname.includes(e));
    }

    const isActiveOptions =
        isActiveGift || isActiveContactRequest || isActiveMeetingRequest || isActiveCommonContent;

    if (handleActiveBlocks.isRedUser || !isActiveOptions) return null;
    return (
        <>
            <div className="c3_options">
                {isActiveGift && (
                    <button
                        type="button"
                        className={`c3_options_item c3_options_gift`}
                        disabled={!+profileChatInfo?.gift}
                        onClick={openGift}
                        data-testid="send-gift-btn"
                    >
                        <IconGift />
                        <span>
                            <span>Send </span>gift
                        </span>
                        {userState?.giftsDiscount && +userState?.giftsDiscountValue > 0 && (
                            <div className="c3_options_gift_discounts">{`-${+userState.giftsDiscountValue}%`}</div>
                        )}
                        <span className="c3_woman_profile_gift_btn_tip">
                            <strong>
                                We apologize, but this user does not want to receive gifts.
                            </strong>
                            Please consider expressing your support in a different manner.
                        </span>
                    </button>
                )}
                {isActiveContactRequest && <RequestContactButton profile={profileChatInfo} />}
                {isActiveMeetingRequest && (
                    <div
                        className="c3_options_item"
                        onClick={initModalMeetingRequest.openMeetingModal}
                        data-testid="meeting-request-btn"
                    >
                        <IconActiveMeetingRequest />
                        <span>
                            Meeting<span> request</span>
                        </span>
                    </div>
                )}
                {isActiveCommonContent && (
                    <div
                        className={`c3_options_item media ${isMediaWrapOpen ? 'active' : ''}`}
                        ref={optionsItemMediaRef}
                        onClick={toggleMediaWrap}
                    >
                        <svg fill="none" viewBox="0 0 24 29" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.686 1.12891H4.49854C3.80234 1.12891 3.13466 1.40283 2.64238 1.89043C2.1501 2.37802 1.87354 3.03934 1.87354 3.72891V24.5289C1.87354 25.2185 2.1501 25.8798 2.64238 26.3674C3.13466 26.855 3.80234 27.1289 4.49854 27.1289H20.2485C20.9447 27.1289 21.6124 26.855 22.1047 26.3674C22.597 25.8798 22.8735 25.2185 22.8735 24.5289V10.2289M13.686 1.12891L22.8735 10.2289M13.686 1.12891V10.2289H22.8735"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6.66672 21.7329C6.92586 22.0797 7.25647 22.3666 7.63613 22.5743C8.01578 22.7819 8.43561 22.9054 8.86713 22.9363C9.29865 22.9673 9.73178 22.9049 10.1371 22.7536C10.5425 22.6022 10.9105 22.3654 11.2164 22.0591L13.0266 20.247C13.5762 19.6775 13.8803 18.9146 13.8734 18.1227C13.8665 17.3309 13.5492 16.5734 12.9899 16.0135C12.4305 15.4535 11.6738 15.1359 10.8827 15.129C10.0917 15.1221 9.32956 15.4265 8.76054 15.9767L7.72268 17.0095M9.08035 20.5249C8.82121 20.1781 8.4906 19.8912 8.11094 19.6835C7.73129 19.4759 7.31146 19.3524 6.87994 19.3215C6.44842 19.2906 6.01529 19.3529 5.60995 19.5042C5.20461 19.6556 4.83653 19.8924 4.53067 20.1987L2.72045 22.0108C2.17087 22.5804 1.86678 23.3432 1.87365 24.1351C1.88052 24.9269 2.19782 25.6844 2.7572 26.2443C3.31658 26.8043 4.07329 27.1219 4.86435 27.1288C5.6554 27.1357 6.41751 26.8313 6.98653 26.2811L8.01835 25.2483"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span>
                            <span>Common</span> content
                        </span>
                        <div className="c3_options_item_media_close" />
                        <div className="c3_options_item_border" />
                    </div>
                )}
            </div>
            <MeetingRequestModal {...initModalMeetingRequest} profile={profileChatInfo} />
        </>
    );
}
