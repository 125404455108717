import React from 'react';
import styles from '../styles.module.scss';
import { useDispatch } from 'react-redux';
import IconVerificationProgress from '../../../../Icons/IconVerificationProgress';
import IconVerificationDone from '../../../../Icons/IconVerificationDone';
import IconVerificationFailed from '../../../../Icons/IconVerificationFailed';
import IconVerificationPass from '../../../../Icons/IconVerificationPass';
import IconVerificationComplete from '../../../../Icons/IconVerificationComplete';
import { updateDocsState, updateIsShowStatus } from '../../../../../store/actions/profile';
import IconCross from '../../../../Icons/IconCross';
import UserVerificationApi from '../../../../../services/api/v3/UserVerificationApi';
import { IVerificationNotifyProps } from './index.interface';

const VerificationNotify = ({ item, configID }: IVerificationNotifyProps) => {
    const dispatch = useDispatch();

    const getIcon = () => {
        switch (item?.banner) {
            case 'IN_PROGRESS_VERIFICATION':
                return <IconVerificationProgress />;
            case 'DONE_VERIFICATION':
                return <IconVerificationDone />;
            case 'FAILED_VERIFICATION':
                return <IconVerificationFailed />;
            case 'START_VERIFICATION':
                return <IconVerificationPass />;
            case 'COMPLETE_VERIFICATION':
                return <IconVerificationComplete />;
            default:
                return <IconVerificationProgress />;
        }
    };
    return (
        <li
            className={`${styles.notify_item} ${styles.notify_verification} ${styles[`notify_verification_${item?.banner}`]}`}
            onClick={() => item?.onClick && dispatch(updateIsShowStatus(true))}
        >
            {getIcon()}
            <div className={styles.notify_verification_content}>
                <p className={styles.notify_verification_title}>{item?.title}</p>
                <p className={styles.notify_verification_text}>{item?.text}</p>
                {item?.banner === 'DONE_VERIFICATION' && (
                    <button
                        type="button"
                        className={styles.close_btn}
                        onClick={() => {
                            new UserVerificationApi().closeDocsVerificationBanner({ configID });
                            dispatch(updateDocsState({}));
                        }}
                    >
                        <IconCross />
                    </button>
                )}
            </div>
        </li>
    );
};

export default VerificationNotify;
