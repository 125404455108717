import { useEffect } from 'react';
import { getUrlParameter } from '../../utils/url-utils';
import * as PURCHASE from '../../constants/purchase';
import { useLocation } from 'react-router-dom';
import useBuyCredits from './useBuyCredits';
import { useDispatch, useSelector } from 'react-redux';
import useInitPurchaseModal from './useInitPurchaseModal';
import { RootState } from '../../index';
import { IPurchaseMethodItem } from '../../services/methods/purchase/index.interface';
import * as purchaseActions from '../../store/actions/purchase';

interface IUseQueryOpenPayment {
    setFrameLink: (value: any) => void;
    setFrameFormData: (value: any) => void;
}

export default function useQueryOpenPayment({
    setFrameLink,
    setFrameFormData,
}: IUseQueryOpenPayment) {
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const dispatch = useDispatch();
    const location = useLocation();
    const { buyCredits } = useBuyCredits();
    const initPurchaseModal = useInitPurchaseModal();

    const purchaseBuyCardPayWithLink = (credits: number, paymentMethod: string) => {
        setFrameLink('');
        setFrameFormData('');
        buyCredits(credits);

        if (paymentMethod) {
            dispatch(purchaseActions.clearActiveSubscribeInfo());
            dispatch(purchaseActions.setActivePaymentMethod(paymentMethod));
        }
    };

    const checkHasPaymentMethod = (name: string) => {
        return purchaseState?.paymentMethodList?.some(
            (el: IPurchaseMethodItem) => el?.method === name,
        );
    };

    useEffect(() => {
        if (purchaseState?.purchaseList?.length) {
            const credits = +(getUrlParameter('credits_amount') || 0);

            if (location?.search?.includes('cardpay=true')) {
                if (!checkHasPaymentMethod(PURCHASE.PAYMENT_METHOD_CARDPAY)) {
                    return initPurchaseModal.openModalPurchaseCredit();
                }
                if (credits) {
                    purchaseBuyCardPayWithLink(credits, PURCHASE.PAYMENT_METHOD_CARDPAY);
                    buyCredits(credits);
                }
            } else if (location?.search?.includes('pastabank=true')) {
                if (!checkHasPaymentMethod(PURCHASE.PAYMENT_METHOD_PASTABANK)) {
                    return initPurchaseModal.openModalPurchaseCredit();
                }
                if (credits) {
                    purchaseBuyCardPayWithLink(credits, PURCHASE.PAYMENT_METHOD_PASTABANK);
                    buyCredits(credits);
                }
            } else if (location?.search?.includes('acquiring=true')) {
                if (!checkHasPaymentMethod(PURCHASE.PAYMENT_METHOD_ACQUIRING)) {
                    return initPurchaseModal.openModalPurchaseCredit();
                }
                if (credits) {
                    purchaseBuyCardPayWithLink(credits, PURCHASE.PAYMENT_METHOD_ACQUIRING);
                    buyCredits(credits);
                }
            } else if (location?.search?.includes('securionpay=true')) {
                if (!checkHasPaymentMethod(PURCHASE.PAYMENT_METHOD_SECURIONPAY)) {
                    return initPurchaseModal.openModalPurchaseCredit();
                }
                if (credits) {
                    purchaseBuyCardPayWithLink(credits, PURCHASE.PAYMENT_METHOD_SECURIONPAY);
                }
            }
        }
    }, [location, purchaseState?.purchaseList]);
}
