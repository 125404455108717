import * as notifyAction from '../../store/actions/notify';
import * as modalsAction from '../../store/actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import MailboxApi from '../../services/api/MailboxApi';
import * as MENU from '../../constants/menu';
import * as alertAction from '../../store/actions/alert';
import useTransition from '../transition/useTransition';
import { closePanel } from '../../services/dom';
import * as currentChatAction from '../../store/actions/currentChat';
import * as CHATS from '../../constants/chats';
import * as ERROR from '../../constants/error';
import * as ALERT from '../../constants/alert';
import useSendWink from '../wink/useSendWink';
import * as loaderAction from '../../store/actions/loader';
import { RootState } from '../../index';

export default function useHandleTransactionNotify() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const sendWink = useSendWink();
    const userState = useSelector((state: RootState) => state.user);

    const deleteNotify = (notify: any) => {
        if (!userState?.info?.external_id) return;

        dispatch(
            notifyAction.deleteTransactionNotify(
                notify?.id,
                userState?.info?.external_id,
                notify?.chat_uid,
            ),
        );
    };

    const openWomanProfile = (notifyID: any, womanProfileExternalID: number) => {
        closePanel();

        if (notifyID?.id) deleteNotify(notifyID);
        if (womanProfileExternalID) {
            dispatch(modalsAction.openWomanProfileModal(womanProfileExternalID));
        }
    };

    const openWomanGallery = (notifyID: any, womanProfileExternalID: number, galleryImg: any) => {
        closePanel();

        if (notifyID?.id) deleteNotify(notifyID);
        if (womanProfileExternalID) {
            dispatch(modalsAction.openWomanGalleryModal(womanProfileExternalID, galleryImg));
        }
    };

    const openChat = (notifyID: any, womanProfileExternalID: number, type?: string) => {
        if (!userState?.info?.external_id) return;
        if (window.innerWidth <= 1024) dispatch(currentChatAction.touchSwipeChatList('left'));

        closePanel();
        if (womanProfileExternalID) {
            dispatch(loaderAction.setActiveGifLoader(true));
            new MailboxApi()
                .createChatWithProfile(userState?.info?.external_id, womanProfileExternalID)
                .then((res) => {
                    const winkStatus = !!res?.chat_info?.wink;
                    dispatch(loaderAction.setActiveGifLoader(false));
                    if (+res?.chat_info?.male_block) {
                        return dispatch(
                            alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                        );
                    }
                    if (res?.status && res?.chat_uid) {
                        transition.transitionWithHistoryPush(
                            MENU.OPEN_CHAT,
                            `/chat/${res?.chat_uid}`,
                        );
                        if (type === CHATS.MESSAGE_CONTENT_TYPE_WINK && !winkStatus) {
                            sendWink({
                                id: womanProfileExternalID,
                                helloText: null,
                            });
                        }
                        if (notifyID?.id)
                            deleteNotify({ ...notifyID, ...{ chat_uid: res?.chat_uid } });
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                })
                .catch(() => dispatch(loaderAction.setActiveGifLoader(false)));
        }
    };

    return {
        deleteNotify,
        openWomanProfile,
        openWomanGallery,
        openChat,
    };
}
