export const PAYMENT_TOTAL_HIDE_GIFT = 200;
export const GIFT_ACTIVE_FOR_USER_LIST = [1888325161, 1978908114, 2023371020, 1675480810];

// GT-8132 + GT-9511 + GT-11733
export const ACTIVE_CONTACT_REQUEST_LIST = [
    1484149523, 1739863845, 1155258856, 1365833422, 1949573718, 1925480076, 1802646677, 1220146871,
    2030574399,
];

export const CHECK_TIME_LAST_MESS_SEND = 60;
export const MIN_STEP_CREDITS_MESS_SEND = 2;

export const SPEND_CREDITS_TO_CONTACT_REQUEST = 8000;

export const WELCOME_CREDITS = 25;

export const TRUE_USER_AGE = 35;
export const USER_AGE_PAID_ACTION = 35;

export const BINOMO_SIGNUP = 'signup';
export const BINOMO_SIGNUP_30 = 'signup_30';
export const BINOMO_FIRST_PACK = 'approve';
export const BINOMO_IS_FRAUD = 'reject';
export const BINOMO_NEXT_PACK = 'rebills';
export const BINOMO_CHECKOUT = 'checkout';
// export const BINOMO_SPEND_FIRST_CREDITS = 'buyouts';
export const BINOMO_SPEND_FIRST_CREDITS = '1_cr';
export const BINOMO_SPEND_ALL_CREDITS = 'all_cr';
export const BINOMO_UPLOAD_AVATAR = 'photo';
export const BINOMO_CONFIRM_EMAIL = 'mail';
export const BINOMO_AFTER_REGISTER_2_DAY = '2day';
export const BINOMO_AFTER_REGISTER_6_DAY = '6day';
export const BINOMO_THIRD_PACK = '3d_buy';
export const BINOMO_SECOND_PACK = '2d_buy';
export const BINOMO_SPEND_200 = 'usd200';

export const BINOMO_BUY_CREDIT_LIST = [BINOMO_FIRST_PACK, BINOMO_SECOND_PACK, BINOMO_THIRD_PACK];
export const BINOMO_EVENT = [
    BINOMO_UPLOAD_AVATAR,
    BINOMO_CONFIRM_EMAIL,
    BINOMO_AFTER_REGISTER_2_DAY,
    BINOMO_AFTER_REGISTER_6_DAY,
    BINOMO_SPEND_FIRST_CREDITS,
    BINOMO_SPEND_ALL_CREDITS,
    BINOMO_SECOND_PACK,
    BINOMO_THIRD_PACK,
    BINOMO_FIRST_PACK,
    BINOMO_SIGNUP_30,
];

export const AFFISE_FIRST_PACK_SALE_LESS_THEN_45 = 'sale_less_than_45';
export const AFFISE_FIRST_PACK = 'sale';
export const AFFISE_SECOND_PACK = 'sale2';
export const AFFISE_THIRD_PACK = 'sale3';
export const AFFISE_UPLOAD_AVATAR = 'photo';
export const AFFISE_CONFIRM_EMAIL = 'mail';
export const AFFISE_AFTER_REGISTER_2_DAY = '2day';
export const AFFISE_AFTER_REGISTER_6_DAY = '6day';
export const AFFISE_SPEND_FIRST_CREDITS = '1_cr';
export const AFFISE_SPEND_ALL_CREDITS = 'all_cr';
export const AFFISE_SPEND_200 = 'usd200';
export const AFFISE_BUY_CREDIT_LIST = [AFFISE_FIRST_PACK, AFFISE_SECOND_PACK, AFFISE_THIRD_PACK];

export const VIDEO_COST = 30;
export const PAYMENT_TOTAL_TO_ASK_VIDEO = 150000;
export const PAYMENT_TOTAL_TO_AFFISE_SPEND_200 = 200;

export const VALENTINE_THEME = 'VALENTINE_THEME';
export const DEFAULT_WINK_MESSAGE = 'Send a wink 😉';

export const PASSPORT_VERIFIED = 1;
export const PASSPORT_IN_PROGRESS = 2;
export const PASSPORT_DECLINE = 0;

export const SPEND_DAYS_TO_CONTACT_REQUEST = 15;
export const PAYMENT_TOTAL_TO_SHOW_CONTACTS = 500;

export const IMBRA_IN_PROGRESS = 0;
export const IMBRA_DECLINE = 1;
export const IMBRA_VERIFIED = 2;

export const CONTACT_REQUEST_IN_PROGRESS = 0;
export const CONTACT_REQUEST_APPROVED = 1;
export const CONTACT_REQUEST_DECLINE = 2;

export const RED_USER_LOG_TYPES = {
    LOGIN_SUCCESS: 1,
    LOGOUT: 2,
    START_SESSION: 3,
    END_SESSION: 4,
    MESSAGE_TO: 5,
    MESSAGE_FROM: 6,
    LETTER_TO: 7,
    LETTER_FROM: 8,
    PAYMENT_OPEN: 9,
    PAYMENT_SUCCESS: 10,
    PAYMENT_DECLINE: 11,
    LOGIN_FAIL: 12,
};

export const SUPPORT_WOMAN = 1512236905;
