import React from 'react';

export default function IconMove({ propStyles }: { propStyles?: React.CSSProperties }) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#02317C"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...propStyles }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.33297 4.27592L7.8043 5.80458C7.54397 6.06492 7.12197 6.06492 6.86163 5.80458C6.6013 5.54425 6.6013 5.12225 6.86163 4.86192L9.5283 2.19525C9.78863 1.93492 10.2107 1.93492 10.471 2.19525L13.1377 4.86192C13.398 5.12225 13.398 5.54425 13.1377 5.80458C13.0073 5.93492 12.837 5.99992 12.6663 5.99992C12.4957 5.99992 12.3253 5.93492 12.195 5.80458L10.6663 4.27592V7.99992C10.6663 8.36792 10.3677 8.66658 9.99963 8.66658C9.63163 8.66658 9.33297 8.36792 9.33297 7.99992V4.27592ZM15.1377 6.86175L17.8044 9.52843C18.0647 9.78876 18.0647 10.2108 17.8044 10.4711L15.1377 13.1378C15.0074 13.2681 14.8371 13.3331 14.6664 13.3331C14.4957 13.3331 14.3254 13.2681 14.1951 13.1378C13.9347 12.8774 13.9347 12.4554 14.1951 12.1951L15.7237 10.6664H11.9997C11.6317 10.6664 11.3331 10.3678 11.3331 9.99976C11.3331 9.63176 11.6317 9.33309 11.9997 9.33309H15.7237L14.1951 7.80442C13.9347 7.54409 13.9347 7.12209 14.1951 6.86175C14.4554 6.60142 14.8774 6.60142 15.1377 6.86175ZM12.195 14.1955L10.6663 15.7242V12.0002C10.6663 11.6322 10.3677 11.3335 9.99963 11.3335C9.63163 11.3335 9.33297 11.6322 9.33297 12.0002V15.7242L7.8043 14.1955C7.54397 13.9352 7.12197 13.9352 6.86163 14.1955C6.6013 14.4558 6.6013 14.8778 6.86163 15.1382L9.5283 17.8048C9.65863 17.9352 9.82897 18.0002 9.99963 18.0002C10.1703 18.0002 10.3407 17.9352 10.471 17.8048L13.1377 15.1382C13.398 14.8778 13.398 14.4558 13.1377 14.1955C12.8773 13.9352 12.4553 13.9352 12.195 14.1955ZM7.99992 10.6664H4.27592L5.80458 12.1951C6.06492 12.4554 6.06492 12.8774 5.80458 13.1378C5.67425 13.2681 5.50392 13.3331 5.33325 13.3331C5.16258 13.3331 4.99225 13.2681 4.86192 13.1378L2.19525 10.4711C1.93492 10.2108 1.93492 9.78876 2.19525 9.52843L4.86192 6.86175C5.12225 6.60142 5.54425 6.60142 5.80458 6.86175C6.06492 7.12209 6.06492 7.54409 5.80458 7.80442L4.27592 9.33309H7.99992C8.36792 9.33309 8.66658 9.63176 8.66658 9.99976C8.66658 10.3678 8.36792 10.6664 7.99992 10.6664Z"
            />
        </svg>
    );
}
