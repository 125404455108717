import React from 'react';
import styles from './styles.module.scss';
import arrowBack from '../../../img/onboarding/arrow_back.svg';
import Logotypes from '../../../img/logotypes';
import SITE from '../../../config/site';

interface IOnboardingHeader {
    progress: number;
    onBack: () => void;
    onSkip: () => void;
}
const OnboardingHeader = ({ progress, onBack, onSkip }: IOnboardingHeader) => {
    return (
        <div className={styles.header}>
            <div className={styles.wrapper}>
                <div className={styles.back} onClick={progress ? onBack : undefined}>
                    {!!progress && (
                        <>
                            <img src={arrowBack} alt={''} />
                            <span>Back</span>
                        </>
                    )}
                </div>

                <div className={styles.logo}>
                    <img src={Logotypes.images['logo_light.svg']} alt={SITE.name} />
                </div>

                <div className={styles.skip} onClick={onSkip} data-testid="skip-quiz-btn">
                    Skip quiz
                </div>
            </div>

            <div className={styles.progress_wrapper}>
                <div className={styles.progress_line} style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
};

export default OnboardingHeader;
