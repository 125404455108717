import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import SITE from '../../../../../../config/site';
import { RootState } from '../../../../../../index';
import tut_step_4_girl_primary_photo from '../../../../../../img/tutorial/tut_step_4_primary_girl.png';
import tut_step_4_man_primary_photo from '../../../../../../img/tutorial/tut_step_4_primary_man.png';
import tut_step_4_girl_secondary_1 from '../../../../../../img/tutorial/tut_step_4_girl_secondary_1.png';
import tut_step_4_girl_secondary_2 from '../../../../../../img/tutorial/tut_step_4_girl_secondary_2.png';
import tut_step_4_girl_secondary_3 from '../../../../../../img/tutorial/tut_step_4_girl_secondary_3.png';
import tut_step_4_man_secondary_1 from '../../../../../../img/tutorial/tut_step_4_man_secondary_1.png';
import tut_step_4_man_secondary_2 from '../../../../../../img/tutorial/tut_step_4_man_secondary_2.png';
import tut_step_4_man_secondary_3 from '../../../../../../img/tutorial/tut_step_4_man_secondary_3.png';
import tut_step_4_girl_small_1 from '../../../../../../img/tutorial/tut_step_4_girl_small_1.png';
import tut_step_4_girl_small_2 from '../../../../../../img/tutorial/tut_step_4_girl_small_2.png';
import tut_step_4_man_small_1 from '../../../../../../img/tutorial/tut_step_4_man_small_1.png';
import tut_step_4_man_small_2 from '../../../../../../img/tutorial/tut_step_4_man_small_2.png';
import IconLetters from '../../../../../Icons/IconLetters';
import IconChat from '../../../../../Icons/IconChat';
import IconFavourites from '../../../../../Icons/IconFavourites';
import IconInfoDanger from '../../../../../Icons/IconInfoDanger';

export default function Step4() {
    const userState = useSelector((state: RootState) => state.user);
    const switcherGirlName = () => {
        switch (SITE?.ID) {
            case 1:
                return { name: 'Kristina, 26', birthday: 'May 17, 1993', country: 'Kyiv, Ukraine' };
            case 2:
                return {
                    name: 'Kristina, 26',
                    birthday: 'October 15, 1993',
                    country: 'Kyiv, Ukraine',
                };
            case 5:
                return {
                    name: 'Elsa, 25',
                    birthday: 'March 25, 1998',
                    country: 'Los Angeles, United States',
                };
            case 6:
                return {
                    name: 'Kateryna, 29',
                    birthday: 'July 14, 1995',
                    country: 'Kherson, Ukraine',
                };
            case 7:
                return {
                    name: 'Iryna, 24',
                    birthday: 'October 16, 1998',
                    country: 'Odessa, Ukraine',
                };
            case 8:
                return { name: 'Maria, 27', birthday: 'May 15, 1997', country: 'Kyiv, Ukraine' };
            case 9:
                return { name: 'Olga, 39', birthday: 'October 5, 1983', country: 'Rivne, Ukraine' };
            case 10:
                return { name: 'Vlada, 36', birthday: 'June 4, 1987', country: 'Odessa, Ukraine' };
            case 11:
                return {
                    name: 'Julia, 22',
                    birthday: 'March 30, 2001',
                    country: 'Córdoba, Argentina',
                };
            case 12:
                return {
                    name: 'Lea, 26',
                    birthday: 'September 20, 1996',
                    country: 'Quezon City, Philippines',
                };
            case 13:
                return {
                    name: 'Carla, 28',
                    birthday: 'September 7, 1994',
                    country: 'São Paulo, Brazil',
                };
            case 14:
                return {
                    name: 'Yunita, 25',
                    birthday: 'July 28, 1998',
                    country: 'Manajao, Philippines',
                };
            case 15:
                return {
                    name: 'Diana, 23',
                    birthday: 'March 31, 2000',
                    country: 'Kharkiv, Ukraine',
                };
            case 16:
                return {
                    name: 'Iryna, 24',
                    birthday: 'October 22, 1998',
                    country: 'Kyiv, Ukraine',
                };
            case 17:
                return { name: 'Natali, 25', birthday: 'July 2, 1998', country: 'Odessa, Ukraine' };
            case 18:
                return {
                    name: 'Amina, 27',
                    birthday: 'October 18, 1995',
                    country: 'Odessa, Ukraine',
                };
            case 19:
                return { name: 'Vita, 28', birthday: 'June 11, 1995', country: 'Kyiv, Ukraine' };
            case 20:
                return {
                    name: 'Svetlana, 38',
                    birthday: 'June 24, 1985',
                    country: 'Kyiv, Ukraine',
                };
            case 21:
                return {
                    name: 'Kira, 29',
                    birthday: 'November 4, 1993',
                    country: 'Odessa, Ukraine',
                };
            case 22:
                return {
                    name: 'Rose, 28',
                    birthday: 'January 4, 1995',
                    country: 'Odessa, Ukraine',
                };
            case 24:
                return {
                    name: 'Natalia, 37',
                    birthday: 'October 6, 1985',
                    country: 'Kyiv, Ukraine',
                };
            case 25:
                return {
                    name: 'Iryna, 24',
                    birthday: 'January 10, 1999',
                    country: 'Kyiv, Ukraine',
                };
            case 26:
                return {
                    name: 'Viktoria, 36',
                    birthday: 'March 23, 1987',
                    country: 'Kyiv, Ukraine',
                };
            case 27:
                return {
                    name: 'Alyona, 29',
                    birthday: 'October 29, 1994',
                    country: 'Kharkiv, Ukraine',
                };
            default:
                return { name: 'Kristina, 26', birthday: 'May 17, 1993', country: 'Kyiv, Ukraine' };
        }
    };

    const switcherMenName = () => {
        switch (SITE?.ID) {
            case 5:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 7:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 9:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 11:
                return {
                    name: 'Tom, 28',
                    birthday: 'January 31, 1995',
                    country: 'Not Specified, Bahamas',
                };
            case 12:
                return {
                    name: 'Wang, 62',
                    birthday: 'May 9, 1961',
                    country: 'Not Specified, China',
                };
            case 13:
                return {
                    name: 'Tom, 28',
                    birthday: 'January 31, 1995',
                    country: 'Not Specified, Bahamas',
                };
            case 14:
                return {
                    name: 'Wang, 62',
                    birthday: 'May 9, 1961',
                    country: 'Not Specified, China',
                };
            case 16:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 18:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 20:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 22:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            case 24:
                return {
                    name: 'Beeman, 51',
                    birthday: 'June 7, 1972',
                    country: 'Melbourne, Australia',
                };
            default:
                return {
                    name: 'Maickel, 32',
                    birthday: 'September 10, 1990',
                    country: 'Lyon, France',
                };
        }
    };

    const CountryClass = () => {
        switch (SITE?.ID) {
            case 5:
                return {
                    country: !Number(userState?.info?.gender) ? 'united_states' : 'australia',
                };
            case 7:
                return { country: !Number(userState?.info?.gender) ? 'ukraine' : 'australia' };
            case 9:
                return { country: !Number(userState?.info?.gender) ? 'ukraine' : 'australia' };
            case 11:
                return { country: !Number(userState?.info?.gender) ? 'argentina' : 'bahamas' };
            case 12:
                return { country: !Number(userState?.info?.gender) ? 'philippines' : 'china' };
            case 13:
                return { country: !Number(userState?.info?.gender) ? 'brazil' : 'bahamas' };
            case 14:
                return { country: !Number(userState?.info?.gender) ? 'philippines' : 'china' };
            case 16:
                return { country: !Number(userState?.info?.gender) ? 'ukraine' : 'australia' };
            case 18:
                return { country: !Number(userState?.info?.gender) ? 'ukraine' : 'australia' };
            case 20:
                return { country: !Number(userState?.info?.gender) ? 'ukraine' : 'australia' };
            case 22:
                return { country: !Number(userState?.info?.gender) ? 'ukraine' : 'australia' };
            default:
                return { country: !Number(userState?.info?.gender) ? 'ukraine' : 'france' };
        }
    };

    return (
        <>
            <div className={styles.tutorial_step_illustration}>
                <div className={styles.step_select}>
                    <div className={styles.step_select_buttons}>
                        <div className={styles.step_select_button}>
                            <div className={styles.step_select_button_icon}>
                                <IconLetters />
                            </div>
                            <p className={styles.step_select_button_text}>Write a letter</p>
                        </div>
                        <div className={styles.step_select_button_accent}>
                            <div className={styles.step_select_button_icon}>
                                <IconChat />
                            </div>
                            <p className={styles.step_select_button_accent_text}>Start chatting</p>
                        </div>
                        <div className={styles.step_select_button}>
                            <div className={styles.step_select_button_icon}>
                                <IconFavourites />
                            </div>
                            <p className={styles.step_select_button_text}>Favorites</p>
                        </div>
                        <div className={styles.step_select_button}>
                            <div className={styles.step_select_button_icon}>
                                <IconInfoDanger />
                            </div>
                            <p className={styles.step_select_button_text}>To complain</p>
                        </div>
                    </div>
                    <div className={styles.step_select_candidate}>
                        {!Number(userState?.info?.gender) ? (
                            <img
                                src={tut_step_4_girl_primary_photo}
                                alt="Candidate"
                                width="147"
                                height="207"
                                className={styles.step_select_main_candidate_photo}
                            />
                        ) : (
                            <img
                                src={tut_step_4_man_primary_photo}
                                alt="Candidate"
                                width="147"
                                height="207"
                                className={styles.step_select_main_candidate_photo}
                            />
                        )}
                        <div className={styles.step_select_candidate_info}>
                            <div className={styles.step_select_candidate_header}>
                                <div className={styles.step_select_candidate_details}>
                                    <p className={styles.step_select_candidate_name}>
                                        {!Number(userState?.info?.gender)
                                            ? switcherGirlName()?.name
                                            : switcherMenName()?.name}
                                    </p>
                                    <p className={styles.step_select_candidate_birth}>
                                        {!Number(userState?.info?.gender)
                                            ? switcherGirlName()?.birthday
                                            : switcherMenName()?.birthday}
                                    </p>
                                    <div className={styles.step_select_candidate_details_bottom}>
                                        <p className={styles.step_select_candidate_id}>
                                            ID 1945945456
                                        </p>
                                        <p
                                            className={`${styles.step_select_candidate_country} ${styles[`flag_${CountryClass()?.country}`]}`}
                                        >
                                            <span>
                                                {!Number(userState?.info?.gender)
                                                    ? switcherGirlName()?.country
                                                    : switcherMenName()?.country}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.step_select_candidate_buttons}>
                                    <div
                                        className={`${styles.step_select_candidate_button} ${styles.step_select_candidate_button_hello}`}
                                    >
                                        <p className={styles.step_select_candidate_button_text}>
                                            Say “Hello”
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.step_select_candidate_button} ${styles.step_select_candidate_button_like}`}
                                    >
                                        <p className={styles.step_select_candidate_button_text}>
                                            Like
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.step_select_gallery}>
                                <div className={styles.step_select_gallery_big}>
                                    {!Number(userState?.info?.gender) ? (
                                        <>
                                            <img
                                                src={tut_step_4_girl_secondary_1}
                                                alt="Candidate"
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_girl_secondary_2}
                                                alt="Candidate"
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_girl_secondary_3}
                                                alt="Candidate"
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={tut_step_4_man_secondary_1}
                                                alt="Candidate"
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_man_secondary_2}
                                                alt="Candidate"
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                            <img
                                                src={tut_step_4_man_secondary_3}
                                                alt="Candidate"
                                                width="90"
                                                height="121"
                                                className={styles.step_select_gallery_big_item}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className={styles.step_select_gallery_small}>
                                    {!Number(userState?.info?.gender) ? (
                                        <>
                                            <img
                                                src={tut_step_4_girl_small_1}
                                                alt="Candidate"
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                            <img
                                                src={tut_step_4_girl_small_2}
                                                alt="Candidate"
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={tut_step_4_man_small_1}
                                                alt="Candidate"
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                            <img
                                                src={tut_step_4_man_small_2}
                                                alt="Candidate"
                                                width="39"
                                                height="39"
                                                className={styles.step_select_gallery_small_item}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.step_select_online_status}>
                            <p className={styles.step_select_online_status_text}>I’m online now</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.tutorial_body}>
                <h2 className={styles.tutorial_step_title}>
                    Find a member you’ve got interested in
                </h2>
                <p className={styles.tutorial_step_text}>
                    On our site, you will find more than 800 charming members focused on a long-term
                    communication.
                </p>
            </div>
        </>
    );
}
