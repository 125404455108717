import React from 'react';
import styles from './styles.module.scss';
import Logotypes from '../../img/logotypes';
import SITE from '../../config/site';
import useInitTerm from '../../hooks/term/useInitTerm';
import * as DICTIONARY_MAIN_PAGE from '../../constants/dictionary/main-page';
import * as MENU from '../../constants/menu';
import TermModal from '../../components/common/Modals/TermModal';
import { useLocation } from 'react-router-dom';
import { isHolidayActive } from '../../constants/holiday';

export default function ForbiddenPage() {
    const initTerm = useInitTerm();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const countryNameQuery = queryParams.get('country_name');
    countryNameQuery && localStorage.setItem('country_name', countryNameQuery);
    const countryName = countryNameQuery || localStorage.getItem('country_name');
    const activeHoliday = isHolidayActive();

    const logoDefaultSrc =
        activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}.svg`]
            ? Logotypes.images[`logo_${activeHoliday?.name}.svg`]
            : Logotypes.images[`logo.svg`];

    return (
        <div className={styles.forbidden_page}>
            <div className={styles.forbidden_logo}>
                <img src={logoDefaultSrc} width="135" height="64" alt={SITE.brand} />
            </div>
            <div className={styles.forbidden_content}>
                <div className={styles.forbidden_map_bg}></div>
                {countryName && (
                    <p className={styles.forbidden_text}>
                        Your country <span>{countryName}</span>
                    </p>
                )}
                <h1 className={styles.forbidden_title}>
                    I'm sorry, our website is not working in your area.
                </h1>
            </div>
            <ul className={styles.forbidden_links}>
                <li onClick={() => initTerm.openTermModal(MENU.LINK_PRIVACY)}>
                    {DICTIONARY_MAIN_PAGE.FOOTER_PRIVACY_POLICY}
                </li>
                <li onClick={() => initTerm.openTermModal(MENU.LINK_TERMS)}>
                    {DICTIONARY_MAIN_PAGE.FOOTER_TERMS}
                </li>
                <li onClick={() => initTerm.openTermModal(MENU.LINK_STANDARDS)}>
                    {DICTIONARY_MAIN_PAGE.FOOTER_STANDARTS}
                </li>
                <li onClick={() => initTerm.openTermModal(MENU.LINK_DISPUTE_MANAGEMENT)}>
                    {DICTIONARY_MAIN_PAGE.FOOTER_DISPUTE_RESOLUTION}
                </li>
            </ul>
            <TermModal {...initTerm} />
        </div>
    );
}
