import NOTIFICATION_IPHONE_SOUND from '../../assets/audio/notification/iphone-sound.mp3';
import { Howl } from 'howler';
import { sendErrToSentry } from '../../services/sentry';

export default function useMakeNotificationSound() {
    const makeSound = () => {
        try {
            const iphoneSound = new Howl({ src: [NOTIFICATION_IPHONE_SOUND] });
            const isActiveSound = JSON.parse(localStorage.getItem('sound') as string) ?? null;

            if (iphoneSound && +isActiveSound === 1) iphoneSound.play();
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    return () => makeSound();
}
