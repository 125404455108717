import Base from '../Base';
import { IAnswerData } from './NpsApi.interface';

export default class NpsApi extends Base {
    async getNpsPoll() {
        const res = await super.get('v3/nps/poll');
        return res?.data;
    }
    async answerNpsPoll(data: IAnswerData) {
        const res = await super.post('v3/nps/answer', data);
        return res?.data;
    }
}
