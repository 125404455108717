import React from 'react';

export default function IconReply() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7919 14.0651C10.6582 14.1765 10.4688 14.1988 10.3091 14.1245C10.1495 14.0466 10.0492 13.8869 10.0492 13.7124V10.6117C7.1491 10.6229 5.15133 10.8308 2.72281 15.1086C2.6374 15.2534 2.48516 15.3388 2.32548 15.3388C2.28835 15.3388 2.2475 15.3351 2.21037 15.3239C2.00985 15.272 1.87246 15.0937 1.87246 14.8858V14.5256L1.87244 14.5226C1.85758 11.4987 1.82693 5.26444 10.0492 4.96747V1.85942C10.0492 1.68118 10.1495 1.52151 10.3091 1.44724C10.4688 1.36926 10.6545 1.39525 10.7919 1.50665L17.9623 7.4257C18.07 7.5111 18.1294 7.63736 18.1294 7.77475C18.1294 7.90843 18.07 8.0384 17.966 8.1238L10.7919 14.0651ZM3.72545 9.06327C3.00506 10.3926 2.83053 11.9634 2.78969 13.2816C5.12537 9.89135 7.31995 9.70197 10.5023 9.69825C10.6248 9.69825 10.7399 9.74653 10.8253 9.83193C10.9107 9.91734 10.959 10.0362 10.959 10.155V12.7432L16.9597 7.77475L10.9553 2.82117V5.41307C10.9553 5.66187 10.7548 5.86239 10.506 5.8661C7.08601 5.89952 4.86915 6.94668 3.72545 9.06327ZM18.0387 10.7536V19.1457C18.0387 19.3945 17.8345 19.5988 17.582 19.5988H2.32393C2.07142 19.5988 1.86719 19.3945 1.86719 19.142V17.1443C1.86719 16.8917 2.07142 16.6875 2.32393 16.6875C2.57643 16.6875 2.78067 16.8917 2.78067 17.1443V18.689H17.1252V10.7536C17.1252 10.5011 17.3295 10.2969 17.582 10.2969C17.8345 10.2969 18.0387 10.5011 18.0387 10.7536Z"
            />
        </svg>
    );
}
