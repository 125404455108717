import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import { IGiftItem } from '../../../store/reducers/gifts/index.interface';

interface IGiftsCatalogItem {
    item: IGiftItem;
    addToCart: (item: IGiftItem) => void;
    openItemModal: (item: IGiftItem) => void;
}

export default function GiftsCatalogItem({
    item = {
        id: 0,
        name: '',
        category_id: 0,
        external_id: 0,
        price: 0,
        special: 0,
        image: '',
        description: '',
        gallery: [],
        old_price: 0,
        new_price: 0,
        discount: 0,
    },
    addToCart,
    openItemModal,
}: IGiftsCatalogItem) {
    const currentGiftsState = useSelector((state: RootState) => state.gifts);
    const userState = useSelector((state: RootState) => state.user);
    const { cart } = currentGiftsState;

    const actualPrice = item ? +(userState?.giftsDiscount ? item?.new_price : item?.price) : 0;

    const addToCartButton = (item: IGiftItem) => {
        if (cart?.items[item?.id]) {
            return (
                <div className="c3gp_gifts_page_main_item_added">
                    <img src="/img/gifts/check-circle-darkblue.svg" alt="" />
                    <span>Added to cart</span>
                </div>
            );
        }

        return (
            <div
                className="c3gp_gifts_page_main_item_add_btn"
                onClick={(e) => {
                    e.stopPropagation();
                    addToCart(item);
                }}
            >
                <img src="/img/gifts/cart-white.svg" alt="" />
                <span>Add to cart</span>
            </div>
        );
    };

    return (
        <div
            className={`c3gp_gifts_page_main_item ${userState?.giftsDiscount ? 'c3gp_gifts_page_main_item_discount_label' : ''}`}
            onClick={() => openItemModal(item)}
        >
            <div className="c3gp_gifts_page_main_item_top">
                <img
                    src={item?.image || '/img/gifts/img_no_photo.jpg'}
                    alt={item?.name || ''}
                    className="c3gp_gifts_page_main_item_img"
                />
            </div>
            <div className="c3gp_gifts_page_main_item_bottom_wrap">
                <div className="c3gp_gifts_page_main_item_title">{item?.name || ''}</div>
                <div className="c3gp_gifts_page_main_item_bottom">
                    <div
                        className={`c3gp_gifts_page_main_item_price ${userState?.giftsDiscount ? 'c3gp_gifts_page_main_item_price_with_discount' : ''}`}
                    >
                        {userState?.giftsDiscount && item?.old_price && (
                            <span className="c3gp_gifts_page_main_item_old_price">{`${+item?.old_price} credits`}</span>
                        )}
                        {actualPrice}
                        <span> credits</span>
                    </div>
                    {addToCartButton(item)}
                </div>
            </div>
            {userState?.giftsDiscount && item?.discount && (
                <div className="c3gp_gifts_page_main_item_discount_amount">{`- ${+item?.discount}%`}</div>
            )}
        </div>
    );
}
