import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GiftsCartButton from '../GiftsCartButton';
import * as GIFTS from '../../../constants/gifts';
import * as giftsAction from '../../../store/actions/gifts';
import { RootState } from '../../../index';

export default function GiftsFilters() {
    const currentGiftsState = useSelector((state: RootState) => state.gifts);

    const dispatch = useDispatch();

    const { categories, filterCategoriesList, filters } = currentGiftsState;

    const setFilterCategoriesList = (limit: number) => {
        dispatch(giftsAction.setFilterCategoriesList(categories, limit));
        const giftPageHead = document.querySelector('.c3gp_gifts_page_head');
        giftPageHead?.classList.toggle('active');
    };

    const setFilterCategory = (id: number) => dispatch(giftsAction.setFilterCategory(id));

    useEffect(() => {
        setFilterCategoriesList(GIFTS.FILTERS_CATEGORIES_LIMIT);
    }, [categories]);

    return (
        <div className="c3gp_gifts_page_head">
            <div className="c3gp_gifts_page_head_title">Choose the gift category</div>
            <div
                className={`c3gp_gifts_page_head_category_item ${!filters?.category ? 'active' : ''}`}
                onClick={() => setFilterCategory(0)}
            >
                All
            </div>

            {filterCategoriesList?.map((category, key) => (
                <div
                    key={key}
                    className={`c3gp_gifts_page_head_category_item ${category?.id === filters?.category ? 'active' : ''}`}
                    onClick={() => setFilterCategory(category?.id)}
                >
                    {category?.name}
                </div>
            ))}

            {Object.keys(filterCategoriesList)?.length === Object.keys(categories)?.length ? (
                <div
                    className="c3gp_gifts_page_head_show_all"
                    onClick={() => setFilterCategoriesList(GIFTS.FILTERS_CATEGORIES_LIMIT)}
                >
                    Show less categories
                </div>
            ) : (
                <div
                    className="c3gp_gifts_page_head_show_all"
                    onClick={() => setFilterCategoriesList(Object.keys(categories)?.length)}
                >
                    Show all categories
                </div>
            )}

            <GiftsCartButton />
        </div>
    );
}
