import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import NpsApi from '../../../../../../services/api/v3/NpsApi';
import { IMixNPSProps } from '../MixNPS/index.interface';

export default function RateNPS({ pollShownId, setNpsData, npsData, npsTitle }: IMixNPSProps) {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [values, setValues] = useState<{ scoreAnswer: number | null }>({
        scoreAnswer: null,
    });

    const inputs = Array.from({ length: 10 }, (_, i) => (i + 1).toString());

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, scoreAnswer: parseInt(event.target.value) });
    };

    const onSubmit = () => {
        if (!isDisabled) {
            new NpsApi()
                .answerNpsPoll({
                    pollShownId,
                    scoreAnswer: values.scoreAnswer,
                })
                .then(() => setNpsData({ ...npsData, pollType: 'success' }));
        }
    };

    useEffect(() => {
        setIsDisabled(!values.scoreAnswer);
    }, [values]);

    return (
        <>
            <p className={styles.nps_modal_text}>{npsTitle}</p>
            <div className={styles.rating_inputs_row} data-testid="rate-list">
                {inputs.map((input, index) => (
                    <>
                        <input
                            className={styles.rating_radio}
                            type="radio"
                            id={'rate_' + input}
                            name="rate"
                            value={input}
                            key={index}
                            onChange={handleChange}
                        />
                        <label className={styles.rating_radio_label} htmlFor={'rate_' + input}>
                            {index + 1}
                            <div className={styles.rating_radio_label_circle_background}>
                                <span
                                    className={styles.rating_radio_label_emoji}
                                    data-index={'rate_' + input}
                                    aria-hidden="true"
                                />
                            </div>
                        </label>
                    </>
                ))}
            </div>
            <button
                type="button"
                className={`${styles.nps_button_continue} ${isDisabled ? styles.disabled : ''}`}
                onClick={onSubmit}
                data-testid="send-btn"
                disabled={isDisabled}
            >
                <span className={styles.nps_button_continue_text}>Send</span>
            </button>
        </>
    );
}
