import { NotifyActionTypes } from '../../constants/notify';
import NotifyApi from '../../../services/api/NotifyApi';
import * as SYNC from '../../../constants/sync';
import { Dispatch } from 'redux';

export function getCheck() {
    return function (dispatch: Dispatch) {
        new NotifyApi().postCheck().then((res) => {
            return dispatch({
                type: NotifyActionTypes.NOTIFY_GET,
                data: res,
            });
        });
    };
}

export function toggleFavoriteNotification(chatUid: string, favorite: boolean) {
    return function (dispatch: Dispatch<{ type: string; chatUid: string; favorite: boolean }>) {
        return dispatch({
            type: NotifyActionTypes.TOGGLE_FAVORITE,
            chatUid,
            favorite,
        });
    };
}

export function setMobilePushNotification(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: NotifyActionTypes.SET_SETTINGS_PUSH,
            data,
        });
    };
}

export function setMobileBottomNotification(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: NotifyActionTypes.SET_SETTINGS_BOTTOM,
            data,
        });
    };
}

export function deleteAllMobileNotify() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: NotifyActionTypes.DELETE_ALL_MOB_NOTIFY,
        });
    };
}

export function deleteMobileNotify(chatUid: string) {
    return function (dispatch: Dispatch<{ type: string; chatUid?: string }>) {
        dispatch({
            type: NotifyActionTypes.CLEAR_MOB_LIST,
        });
        return dispatch({
            type: NotifyActionTypes.DELETE_MOB_NOTIFY,
            chatUid,
        });
    };
}

export function deleteMobileBottomNotify(chatUid: string) {
    return function (dispatch: Dispatch<{ type: string; chatUid: string }>) {
        return dispatch({
            type: NotifyActionTypes.DELETE_MOB_BOTTOM_NOTIFY,
            chatUid,
        });
    };
}

export function addTransactionNotify(notify: any) {
    return function (dispatch: Dispatch) {
        dispatch({
            type: NotifyActionTypes.UPDATE_MOB_NOTIFY,
            data: notify,
        });
        dispatch({
            type: NotifyActionTypes.UPDATE_TRANSACTION_NOTIFY_BOTTOM,
            notify,
        });
        return dispatch({
            type: NotifyActionTypes.UPDATE_TRANSACTION_NOTIFY,
            notify,
        });
    };
}

export function updateNotify(data: any, messageType: string) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        if (messageType === SYNC.MESS_ACTION) {
            dispatch({
                type: NotifyActionTypes.UPDATE_MOB_BOTTOM_NOTIFY,
                data,
            });
        }
        dispatch({
            type: NotifyActionTypes.UPDATE_NOTIFY,
            data: data,
        });
        return dispatch({
            type: NotifyActionTypes.UPDATE_MOB_NOTIFY,
            data,
        });
    };
}

export function checkNotify() {
    return function (dispatch: Dispatch) {
        dispatch({
            type: NotifyActionTypes.CHECK_TRANSACTION_NOTIFY,
        });
        dispatch({
            type: NotifyActionTypes.CHECK_MOB_BOTTOM_NOTIFY,
        });
        dispatch({
            type: NotifyActionTypes.CHECK_MOB_NOTIFY,
        });
        return dispatch({
            type: NotifyActionTypes.CHECK_NOTIFY,
        });
    };
}

export function readNotify(chatUId: string) {
    return function (dispatch: Dispatch<{ type: string; data: string }>) {
        return dispatch({
            type: NotifyActionTypes.READ_NOTIFY,
            data: chatUId,
        });
    };
}

export function deleteTransactionNotify(notifyID: number, userID: number, chatUID: string) {
    if (notifyID && userID && chatUID) {
        return function (dispatch: Dispatch<{ type: string; notifyID: number }>) {
            new NotifyApi().postDeleteNotification(userID, chatUID);
            return dispatch({
                type: NotifyActionTypes.DELETE_TRANSACTION_NOTIFY,
                notifyID,
            });
        };
    }
}

export function deleteNotify(userId: number, chatUid: string | null = null, notifyType = 'chat') {
    if (!userId && !chatUid) return;
    return function (dispatch: Dispatch<{ type: string; chatUid: string | null }>) {
        new NotifyApi().postDeleteNotification(userId, chatUid).then((res) => {
            if (res?.status) {
                return dispatch({
                    type:
                        notifyType === 'chat'
                            ? NotifyActionTypes.DELETE_NOTIFY
                            : NotifyActionTypes.DELETE_NOTIFY_LETTER,
                    chatUid,
                });
            }
        });
    };
}

export function deleteAllNotify() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: NotifyActionTypes.DELETE_ALL_NOTIFY,
        });
    };
}

export function disActivePauseButton() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: NotifyActionTypes.DISACTIVE_PAUSE_BUTTON,
        });
    };
}

export function updateMailNotify(data: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        return dispatch({
            type: NotifyActionTypes.UPDATE_MAIL_NOTIFY,
            data: data,
        });
    };
}
