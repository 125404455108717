export const sessionStorageGetItem = (key: string) => {
    if (!sessionStorage) return '';
    return sessionStorage.getItem(key) || '';
};

export const sessionStorageSetItem = (key: string, value: string) => {
    if (!sessionStorage) return null;

    return sessionStorage.setItem(key, value);
};

export function generateSessionID() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function getSessionID() {
    const sessionID = sessionStorageGetItem('sessionID');
    if (sessionID) return sessionID;

    const newSessionID = generateSessionID();
    sessionStorageSetItem('sessionID', newSessionID);
    return newSessionID;
}
