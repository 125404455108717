import { SearchActionTypes } from '../../constants/search';
import { SEARCH_FILTERS_DEFAULT } from '../../../constants/search';
import { ISearchFilter, ISearchState, SearchActions } from './index.interface';

const initState = {
    list: [],
    filter: SEARCH_FILTERS_DEFAULT as ISearchFilter,
    page: 1,
    isLoading: true,
    isLoadingPage: false,
    isLoadedAll: false,
    isEmpty: false,
    lastBannerIndex: 0,
};

export default function (state: ISearchState = initState, action: SearchActions): ISearchState {
    switch (action.type) {
        case SearchActionTypes.INIT_SEARCH: {
            return initState;
        }

        case SearchActionTypes.GET_SEARCH_LIST: {
            if (!Array.isArray(action?.searchList)) return state;

            const { crossBannersInfo, searchList } = action;
            const bannerFrequency = crossBannersInfo?.show_in?.search ?? 0;
            const bannerIndex =
                state?.page === 1 ? bannerFrequency : state?.lastBannerIndex + bannerFrequency;
            const updatedList =
                state?.page === 1 ? [...searchList] : [...state.list, ...searchList];
            if (bannerIndex && bannerIndex <= updatedList?.length) {
                updatedList.splice(bannerIndex, 0, crossBannersInfo);
            }
            return {
                ...state,
                list: updatedList,
                isEmpty: !updatedList?.length,
                isLoading: false,
                isLoadingPage: false,
                isLoadedAll: !!(searchList?.length % 20),
                lastBannerIndex:
                    bannerIndex <= updatedList?.length ? bannerIndex : state?.lastBannerIndex,
            };
        }

        case SearchActionTypes.SET_SEARCH_LIST_PAGE: {
            return {
                ...state,
                ...{
                    page: action?.page,
                    isLoadingPage: true,
                },
            };
        }

        case SearchActionTypes.SET_IS_LOADING_PAGE: {
            return {
                ...state,
                ...{
                    isLoadingPage: action?.status,
                },
            };
        }

        case SearchActionTypes.SET_SEARCH_LIST_FILTER: {
            return {
                ...state,
                ...{
                    filter: action?.filter as ISearchFilter,
                },
            };
        }

        case SearchActionTypes.SET_SEARCH_LIST_SUBSCRIBE_STATUS:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if ('external_id' in item && item?.external_id === action?.externalId) {
                            return { ...item, ...{ subscribed: action?.status } };
                        } else {
                            return item;
                        }
                    }),
                },
            };

        case SearchActionTypes.SET_SEARCH_ONLINE_STATUS: {
            const newList = state?.list?.length
                ? state?.list?.map((item) => {
                      if (
                          'external_id' in item &&
                          action?.externalIDList?.includes(item?.external_id)
                      ) {
                          return { ...item, ...{ online: action?.status } };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ list: newList },
            };
        }

        case SearchActionTypes.SET_SEARCH_LIKE_STATUS: {
            const newList = state?.list?.length
                ? state?.list?.map((item) => {
                      if ('external_id' in item && item?.external_id === action?.womanExternalID) {
                          return { ...item, ...{ liked: 1 } };
                      }
                      return item;
                  })
                : [];

            return {
                ...state,
                ...{ list: newList },
            };
        }

        default:
            return state;
    }
}
