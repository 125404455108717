import React from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayerNew.module.css';
import useCheckSuccessfulDisplay from '../../../hooks/player/useCheckVideoPlayer';

export default function VideoPlayerNew({ data }: { data: string }) {
    const { sendVideoDisplayError } = useCheckSuccessfulDisplay();

    return (
        <div className={styles.player_wrapper}>
            <ReactPlayer
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                // Disable right click
                onContextMenu={(e: Event) => e.preventDefault()}
                // Your props
                className={styles.react_player}
                onError={() => sendVideoDisplayError(data)}
                url={data}
                playing
                controls
                width="100%"
                height="100%"
            />
        </div>
    );
}
