import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UTM_TYPES } from '../../constants/utm';
import UtmApi from '../../services/api/v3/UtmApi';

export default function useEffectUtm() {
    const location = useLocation();

    const createLogByLocation = async (location: { search: string }) => {
        if (!location?.search?.length) return null;

        for (const utm of Object.keys(UTM_TYPES)) {
            if (!location?.search?.includes(utm)) continue;

            const type = UTM_TYPES[utm as keyof typeof UTM_TYPES] || 0;
            if (type) {
                await new UtmApi().createLog({ type, tail: location?.search });
            }
        }
    };

    useEffect(() => {
        createLogByLocation(location);
    }, [location]);
}
