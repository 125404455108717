import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserApi from '../../services/api/UserApi';
import * as alertAction from '../../store/actions/alert';
import AuthPage from '../AuthPage';
import MainLayout from '../../layouts/MainLayout';
import useTransition from '../../hooks/transition/useTransition';
import { sleepPromise } from '../../services/methods';
import { isMobile } from 'react-device-detect';
import { redirectToMobileVersion } from '../../services/methods/mobile';
import * as ERROR from '../../constants/error';
import { Params } from '@sentry/react/types/types';
import { match } from 'react-router';

export default function ConfirmPage({ match }: { match: match<Params> }) {
    const transition = useTransition();
    const dispatch = useDispatch();

    // Check email confirmation for any user or device
    useEffect(() => {
        (async function () {
            const sessionConfirmEmail = sessionStorage.getItem('confirm_email');

            if (match.params?.confirm_hash && !sessionConfirmEmail) {
                sessionStorage.setItem('confirm_email', '1');
                new UserApi()
                    .confirmEmailByHash({ hash: match?.params?.confirm_hash })
                    .then((res) => {
                        if (res?.status) {
                            // Login user if success confirm
                            if (res?.fingerprint) {
                                localStorage.setItem('fingerprintHash', res?.fingerprint);
                            }
                            if (res?.token) {
                                localStorage.setItem('token', JSON.stringify(res?.token));
                            }

                            if (
                                res?.isMobileData?.forMobile &&
                                res?.isMobileData?.accessKey?.length &&
                                isMobile
                            ) {
                                return redirectToMobileVersion(
                                    res?.isMobileData?.accessKey,
                                    window?.location?.pathname,
                                );
                            }

                            const confirmBonus = +res?.bonus ? `&confirm_bonus=${res?.bonus}` : '';

                            transition.transitionWithHistoryPush(
                                'search',
                                `/search?confirm_email=1${confirmBonus}`,
                            );
                        } else {
                            // Redirect to search if user is logged
                            if (
                                localStorage.getItem('token') &&
                                (localStorage.getItem('token') as string)?.length > 0
                            ) {
                                transition.transitionWithHistoryPush('search', '/search');
                            } else {
                                transition.transitionWithHistoryPush('', '/');
                            }
                            dispatch(
                                alertAction.setMessage({
                                    message: 'Confirmation email is not valid',
                                    title: ERROR.ERROR_REQUEST,
                                }),
                            );
                        }
                    })
                    .finally(async () => {
                        await sleepPromise(2000);
                        sessionStorage.removeItem('confirm_email');
                    });
            } else {
                await sleepPromise(2000);
                sessionStorage.removeItem('confirm_email');
            }
        })();
    }, [match.params]);

    return (
        <MainLayout>
            <AuthPage />
        </MainLayout>
    );
}
