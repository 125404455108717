import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as mailboxAction from '../../store/actions/mailbox';
import * as MENU from '../../constants/menu';
import * as letterFilterAction from '../../store/actions/letterFilter';
import { RootState } from '../../index';

export default function useHandleLettersList() {
    const dispatch = useDispatch();

    const userState = useSelector((state: RootState) => state.user);
    const letterFilterState = useSelector((state: RootState) => state.letterFilter);
    const menuState = useSelector((state: RootState) => state.menu);
    const mailboxState = useSelector((state: RootState) => state.mailbox);

    const [isLoading, setIsLoading] = useState(false);

    const setDefaultFilterValue = () => dispatch(letterFilterAction.setDefaultFilterValue());

    const dispatchGetLetters = () => {
        setIsLoading(true);
        if (menuState.letters !== MENU.DRAFT_FOLDER) {
            if (userState?.info?.external_id) {
                dispatch(
                    mailboxAction.getMails(
                        {
                            userId: userState?.info?.external_id,
                            folder: menuState?.letters,
                            girlFilter: letterFilterState?.girlFilter,
                            girlId: letterFilterState?.girlId || '',
                            letterFilter: letterFilterState?.lettersFilter,
                            onlineFilter: letterFilterState?.onlineFilter,
                            username: letterFilterState?.username,
                            page: mailboxState?.mailListPage,
                        },
                        () => setIsLoading(false),
                    ),
                );
            }
        } else {
            if (userState?.info?.external_id) {
                dispatch(
                    mailboxAction.getMailDrafts(
                        {
                            userId: [userState?.info?.external_id],
                            page: 1,
                        },
                        () => setIsLoading(false),
                    ),
                );
            }
        }
    };

    const handleChangePage = (page: number) => {
        if (!isLoading) {
            if (menuState?.main === 'letter') {
                if (menuState?.letters !== 'drafts') {
                    if (userState?.info?.external_id) {
                        dispatch(
                            mailboxAction.getMailsWithoutReload({
                                userId: userState?.info?.external_id,
                                folder: menuState?.letters,
                                girlFilter: letterFilterState?.girlFilter,
                                girlId: letterFilterState?.girlId || '',
                                letterFilter: letterFilterState?.lettersFilter,
                                onlineFilter: letterFilterState?.onlineFilter,
                                page: page,
                                username: letterFilterState?.username,
                            }),
                        );
                    }
                } else {
                    if (userState?.info?.external_id) {
                        dispatch(
                            mailboxAction.getMailDraftsWithoutReload({
                                userId: [userState?.info?.external_id],
                                page: page,
                            }),
                        );
                    }
                }
            }
        }
    };

    const switchMailBox = (type: string) => {
        switch (type) {
            case MENU.BOOKMARK_FOLDER:
                return 'bookmark';
            case MENU.DRAFT_FOLDER:
                return 'draft';
            case MENU.TRASH_FOLDER:
                return 'trash';
            default:
                return 'letter';
        }
    };

    useEffect(() => {
        if (letterFilterState?.girlFilter !== 'id' || letterFilterState?.girlId?.length > 0) {
            setIsLoading(true);
            dispatchGetLetters();
        }
    }, [
        menuState.letters,
        letterFilterState?.girlFilter,
        letterFilterState?.lettersFilter,
        letterFilterState?.girlId,
        letterFilterState?.username,
        letterFilterState?.onlineFilter,
    ]);

    return {
        switchMailBox,
        handleChangePage,
        setDefaultFilterValue,
    };
}
