import React from 'react';
import styles from './styles.module.scss';
import '../../../styles/faq-global.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import { IQuestionsProps } from './index.interface';
import IconFAQQuestionArrow from '../../Icons/IconFAQQuestionArrow';
import IconFAQQuestionBackArrow from '../../Icons/IconFAQQuestionBackArrow';

export default function Questions({
    questionsList,
    activeTopicTitle,
    isMobileQuestionsOpened,
    backToTopics,
    changeActiveQuestion,
    clickAnswerText,
    fixMessageContent,
}: IQuestionsProps) {
    const faqState = useSelector((state: RootState) => state.faq);

    const handleActiveBlocks = useHandleActiveBlocks();

    const isQuestionActive = (title = '') => {
        if (title?.toLowerCase()?.includes('contact details')) return false;
        else if (title?.toLowerCase()?.includes('meeting request')) return false;
        else if (title?.toLowerCase()?.includes('gift')) return false;
        else return !title?.toLowerCase()?.includes('delivery');
    };

    return (
        <div
            className={`${styles.questions_col} ${isMobileQuestionsOpened ? styles.mob_opened : ''}`}
        >
            <div className={styles.questions_col_mob_head}>
                <div>
                    <div className={styles.head_back_btn} onClick={backToTopics}>
                        <IconFAQQuestionBackArrow />
                        <span>To topics</span>
                    </div>
                </div>
                <div className={styles.head_title}>{activeTopicTitle}</div>
                <div />
            </div>

            {questionsList
                .filter((el) =>
                    handleActiveBlocks.isPaymentUser ? el : isQuestionActive(el?.title),
                )
                .filter((question) => +question?.id !== 60 && +question?.id !== 68)
                .sort((a, b) => a?.order - b?.order)
                .map((el, idx) => {
                    if (el?.answer_list?.length > 0) {
                        return (
                            <div
                                key={idx}
                                className={`${styles.question_item} ${el?.id === faqState?.activeQuestionId ? styles.opened : ''}`}
                            >
                                <div
                                    className={styles.question_title}
                                    id={`question_item_${el?.id}`}
                                    onClick={() => changeActiveQuestion(el?.id)}
                                >
                                    <span>{el?.title}</span>
                                    <IconFAQQuestionArrow />
                                </div>

                                {el?.answer_list?.map((answer, id) => {
                                    return (
                                        <div key={`${idx}_${id}`} className={styles.answer}>
                                            <p
                                                onClick={(e) => clickAnswerText(e)}
                                                dangerouslySetInnerHTML={{
                                                    __html: fixMessageContent(answer?.text),
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return false;
                    }
                })}
        </div>
    );
}
