import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import modalStyleProps from '../../../../../styles/react-modal/modal.style';
import * as modalsAction from '../../../../../store/actions/modals';
import Gallery from './Gallery';
import { RootState } from '../../../../../index';

export default function GalleryModal() {
    const dispatch = useDispatch();
    const modalsState = useSelector((state: RootState) => state.modals);
    const onCloseGalleryModal = () => {
        dispatch(modalsAction.closeSendberlyGalleryModal());
    };

    return (
        <Modal
            style={modalStyleProps({ isAttachment: true })}
            isOpen={modalsState?.galleryModal?.isActive}
            onRequestClose={onCloseGalleryModal}
            ariaHideApp={false}
        >
            {!!modalsState?.galleryModal?.photosList?.length && (
                <Gallery
                    profile={modalsState.galleryModal?.profile}
                    photosList={modalsState?.galleryModal?.photosList}
                    closeGalleryModal={onCloseGalleryModal}
                    currentPhotoIndex={modalsState?.galleryModal?.currentPhotoIndex}
                />
            )}
        </Modal>
    );
}
