import React from 'react';

export default function IconCirclePlus() {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="#446077"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_82)">
                <path d="M17.2175 3.32734C15.3288 1.43863 12.8176 0.398438 10.1465 0.398438C7.47535 0.398438 4.9641 1.43863 3.07539 3.32734C1.18664 5.21609 0.146484 7.72734 0.146484 10.3984C0.146484 13.0695 1.18664 15.5807 3.07539 17.4695C4.96414 19.3582 7.47535 20.3984 10.1465 20.3984C12.8176 20.3984 15.3288 19.3582 17.2175 17.4695C19.1063 15.5807 20.1465 13.0695 20.1465 10.3984C20.1465 7.72734 19.1063 5.21613 17.2175 3.32734ZM10.1465 19.2266C5.27863 19.2266 1.31836 15.2663 1.31836 10.3984C1.31836 5.53059 5.27863 1.57031 10.1465 1.57031C15.0143 1.57031 18.9746 5.53059 18.9746 10.3984C18.9746 15.2663 15.0143 19.2266 10.1465 19.2266Z" />
                <path d="M10.1492 4.77734C10.5756 4.77734 10.9214 5.12307 10.9214 5.54956V9.63124H15.0457C15.469 9.63124 15.8121 9.97438 15.8121 10.3977C15.8121 10.821 15.469 11.1641 15.0457 11.1641H10.9214V15.2461C10.9214 15.6726 10.5756 16.0183 10.1492 16.0183C9.72268 16.0183 9.37695 15.6726 9.37695 15.2461V11.1641H5.25276C4.82947 11.1641 4.48633 10.821 4.48633 10.3977C4.48633 9.97438 4.82947 9.63124 5.25276 9.63124H9.37695V5.54956C9.37695 5.12307 9.72268 4.77734 10.1492 4.77734Z" />
            </g>
            <defs>
                <clipPath id="clip0_1_82">
                    <rect width="21" height="21" />
                </clipPath>
            </defs>
        </svg>
    );
}
