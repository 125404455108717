import React from 'react';

export default function IconVideoError() {
    return (
        <svg
            width="118"
            height="118"
            viewBox="0 0 118 118"
            fill="#C3CCD7"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_17_1229)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.52513 1.52513C2.89196 0.158291 5.10804 0.158291 6.47487 1.52513L116.475 111.525C117.842 112.892 117.842 115.108 116.475 116.475C115.108 117.842 112.892 117.842 111.525 116.475L82.1439 87.0936C80.7454 93.0585 75.3914 97.5 69 97.5H14C6.54416 97.5 0.5 91.4558 0.5 84V34C0.5 26.5442 6.54416 20.5 14 20.5H15.5503L1.52513 6.47487C0.158291 5.10804 0.158291 2.89196 1.52513 1.52513ZM22.5503 27.5L75.5 80.4497V84C75.5 87.5899 72.5899 90.5 69 90.5H14C10.4101 90.5 7.5 87.5899 7.5 84V34C7.5 30.4101 10.4101 27.5 14 27.5H22.5503ZM49.5 20.5C47.567 20.5 46 22.067 46 24C46 25.933 47.567 27.5 49.5 27.5H69.5C72.5376 27.5 75 29.9624 75 33V50.5576C75 51.8255 75.5348 53.0345 76.473 53.8874L81.039 58.0383C82.6263 59.4813 85.0113 59.6033 86.7375 58.3299L110.931 40.4824L110.5 83.9653C110.481 85.8983 112.032 87.4807 113.965 87.4998C115.898 87.519 117.481 85.9676 117.5 84.0347L117.98 35.5395C118.017 31.8274 113.796 29.6699 110.809 31.8737L84.2312 51.4801L82 49.4517V33C82 26.0964 76.4036 20.5 69.5 20.5H49.5Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_17_1229">
                    <rect width="118" height="118" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
