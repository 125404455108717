import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import SearchApi from '../../../services/api/v3/SearchApi';
import useLike from '../../../hooks/likes/useLike';
import * as loaderAction from '../../../store/actions/loader';
import MailboxApi from '../../../services/api/MailboxApi';
import * as alertAction from '../../../store/actions/alert';
import * as MENU from '../../../constants/menu';
import * as ERROR from '../../../constants/error';
import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../../../hooks/transition/useTransition';
import useInitWelcomeAlert from '../../../hooks/dom/useInitWelcomeAlert';
import { RootState } from '../../../index';
import { ISaveOnboardingData } from '../../../services/api/OnboardingApi.interface';
import { IWoman } from '../../../store/reducers/woman/index.interface';

interface IOnboardingSearch {
    title: string;
    activeStep: number;
    maxStep: number;
    subtitle: string;
    formData: any;
    onClick: (value: any) => void;
    skipStep: () => void;
    onSkip: () => void;
}

const OnboardingSearch = ({
    title,
    activeStep,
    maxStep,
    subtitle,
    formData,
    onClick,
    skipStep,
    onSkip,
}: IOnboardingSearch) => {
    const userState = useSelector((state: RootState) => state.user);

    const [searchList, setSearchList] = useState<IWoman[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const like = useLike();
    const dispatch = useDispatch();
    const transition = useTransition();
    const initWelcomeAlert = useInitWelcomeAlert();

    const onContinue = () => {
        if (!searchList?.length) onClick([]);
        else onClick(searchList?.map((item) => item?.external_id));
    };

    const onChat = (womanExternalID: number) => {
        if (womanExternalID && userState?.info) {
            dispatch(loaderAction.setActiveGifLoader(true));
            new MailboxApi()
                .createChatWithProfile(userState?.info?.external_id, womanExternalID)
                .then((res) => {
                    if (res?.status && res?.chat_uid) {
                        initWelcomeAlert.closeWelcomeAlertModal();
                        onSkip();
                        transition.transitionWithHistoryPush(
                            MENU.OPEN_CHAT,
                            `/chat/${res?.chat_uid}`,
                        );
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                            }),
                        );
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)))
                .catch(() => dispatch(loaderAction.setActiveGifLoader(false)));
        }
    };

    const onLike = (womanExternalID: number) => {
        if (searchList?.filter((item) => item?.liked)?.length >= 3) return;

        if (!searchList?.find((item) => item?.external_id === womanExternalID)?.liked) {
            like(womanExternalID);
            setSearchList(
                searchList?.map((item) =>
                    item?.external_id === womanExternalID
                        ? {
                              ...item,
                              liked: 1,
                          }
                        : item,
                ),
            );
        }
    };

    const loadSearchList = () => {
        let searchData: Partial<ISaveOnboardingData> = {};
        for (const step in formData) {
            if (typeof formData[step] === 'object' && formData[step] !== null) {
                searchData = { ...searchData, ...formData[step] };
            }
        }

        new SearchApi()
            .getSearchList(1, {
                online: true,
                gender: userState?.info?.gender,
                from: searchData?.womanAgeFrom || 0,
                to: searchData?.womanAgeTo || 0,
                alcohol: searchData?.womanAlcoholID || undefined,
                bodytype: searchData?.womanBodyID || undefined,
                children: searchData?.womanChildrenID || undefined,
                eye: searchData?.womanEyeID || undefined,
                haircolor: searchData?.womanHairID || undefined,
                heightfrom: searchData?.womanHeightFrom || undefined,
                heightto: searchData?.womanHeightTo || undefined,
                smoking: searchData?.womanSmokeID || undefined,
                country: searchData?.womanCountryID || 0,
                city: searchData?.womanCity || 0,
                education: searchData?.womanEducationID || undefined,
                wantchildren: searchData?.womanMoreChildrenID || undefined,
                job: searchData?.womanOccupationID || undefined,
                purpose: searchData?.womanPurposeID || undefined,
                religion: searchData?.womanReligionID || undefined,
                marital: searchData?.womanMaritalID || undefined,
            })
            .then((res) => {
                if (res?.status) {
                    if (res?.response?.length >= 9) {
                        setSearchList(res?.response);
                    } else {
                        new SearchApi()
                            .getSearchList(1, {
                                gender: userState?.info?.gender,
                            })
                            .then((resRandom) => {
                                if (resRandom?.status) {
                                    const preferList = res?.response?.length ? res?.response : [];
                                    const randomList = resRandom?.response?.length
                                        ? resRandom?.response
                                        : [];
                                    const doubleList = [...preferList, ...randomList];

                                    setSearchList(doubleList?.slice(0, 9));
                                } else {
                                    setSearchList(res?.response);
                                }
                            });
                    }
                }
            })
            .finally(() => setIsLoaded(true));
    };

    useEffect(() => {
        if (formData) loadSearchList();
    }, [formData]);

    return (
        <div className={styles.wrapper}>
            <p className={styles.step}>
                {activeStep} of {maxStep}
            </p>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>

            <div className={styles.input_wrapper}>
                {searchList?.map((item, key) => (
                    <div key={key} className={styles.list_item}>
                        <div className={styles.list_item_image}>
                            <img
                                className={styles.list_item_image_photo}
                                src={item?.search_img}
                                alt={''}
                                onClick={() => onChat(item?.external_id || 0)}
                            />
                            <div
                                className={`${styles.list_item_like} ${item?.liked ? styles.liked : ''}`}
                                onClick={() => onLike(item?.external_id || 0)}
                            ></div>
                            <p className={styles.list_item_name}>
                                {item?.name}, {item?.age}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.button_row}>
                <button className={styles.button} disabled={!isLoaded} onClick={onContinue}>
                    Continue
                </button>
                <button
                    className={`${styles.button} ${styles.skip}`}
                    disabled={!isLoaded}
                    onClick={skipStep}
                >
                    Skip
                </button>
            </div>
        </div>
    );
};

export default OnboardingSearch;
