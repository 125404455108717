import React from 'react';
import { renderLastMessage } from '../../../../../../services/methods';
import { formatDate } from '../../../../../../services/time';
import { useLocation } from 'react-router-dom';
import * as MENU from '../../../../../../constants/menu';
import IconFavouriteNotify from '../../../../../Icons/IconFavouriteNotify';
import { IDefaultProps } from './index.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';
import avatar_placeholder from '../../../../../../img/placeholder.gif';
import chat_hello_yellow from '../../../../../../img/chat-hello-yellow.svg';
import IconCross from '../../../../../Icons/IconCross';

export default function Default({ item, onActiveChat, onDeleteChat }: IDefaultProps) {
    const menuState = useSelector((state: RootState) => state.menu);
    const userState = useSelector((state: RootState) => state.user);

    const location = useLocation();

    const isBlock = menuState.main === MENU.MENU_BLOCK;
    const isMessageStatus =
        item?.sender_external_id === userState?.info?.external_id &&
        item?.last_message_type &&
        item?.last_message_type !== 'SENT_VIEW' &&
        item?.last_message;

    return (
        <>
            {!!(
                +item?.woman_status === 1 || location?.pathname?.includes(MENU.BLOCK_LIST_PAGE)
            ) && (
                <div
                    className={`c2_item ${item?.online ? 'online' : ''} ${item?.favorite ? 'favorites' : ''}`}
                    onClick={() => onActiveChat(item)}
                    data-testid={item.chat_uid}
                >
                    <div className="c2_item_top">
                        <div className="c2_item_photo_wrap">
                            {!isBlock && (
                                <div
                                    className="c2_item_photo_delete"
                                    id={item?.id?.toString()}
                                    data-testid="delete-chat-btn"
                                    onClick={(e) => onDeleteChat(e, item)}
                                >
                                    <IconCross />
                                </div>
                            )}
                            <div className="c2_item_photo_fav">
                                <IconFavouriteNotify />
                            </div>
                            <img
                                src={item?.avatar || avatar_placeholder}
                                alt=""
                                className="c2_item_photo"
                                data-testid="profile-avatar"
                            />
                        </div>
                        <div className="c2_item_right">
                            <p className="c2_item_name" data-testid="profile-name">
                                {`${item?.name || ''}, ${item?.age || ''}`}
                            </p>
                            <p
                                className={`c2_item_text ${item?.sender_external_id !== userState?.info?.external_id ? '' : 'from_you'}`}
                                data-testid="last-message"
                            >
                                <span>
                                    {renderLastMessage(
                                        item?.last_message_type,
                                        item?.last_message,
                                        item?.sender_external_id !== userState?.info?.external_id,
                                        item?.name,
                                    )}
                                </span>
                                {item?.last_message_type === 'SENT_WINK' && (
                                    <img src={chat_hello_yellow} alt="hello wink" />
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="c2_item_bottom">
                        <span className="c2_item_id" data-testid="profile-id">
                            {`ID ${item?.id?.toString()}`}
                        </span>
                        <span className="c2_item_time" data-testid="last-message-date">
                            {formatDate(item?.last_message_date)}
                        </span>
                    </div>

                    {!!isMessageStatus && (
                        <div
                            className={`message_status ${+item?.last_message_read_status === 1 ? 'readed' : 'send'}`}
                            data-testid="message-status"
                        />
                    )}

                    {+item?.unread_count > 0 && (
                        <p className="c2_item_new_message" data-testid="is-new-message">
                            New
                        </p>
                    )}
                </div>
            )}
        </>
    );
}
