import OnboardingApi from '../../services/api/OnboardingApi';
import { useState } from 'react';
import * as referenciesAction from '../../store/actions/referencies';
import { useDispatch, useSelector } from 'react-redux';
import * as userAction from '../../store/actions/user';
import { RootState } from '../../index';

export default function useHandleOnboarding() {
    const referenciesState = useSelector((state: RootState) => state.referencies);

    const [onboarding, setOnboarding] = useState<any>(null);
    const [isActiveOnboarding, setIsActiveOnboarding] = useState(false);
    const [formData, setFormData] = useState({});

    const dispatch = useDispatch();

    const onOpenOnboarding = () => setIsActiveOnboarding(true);
    const onCloseOnboarding = () => setIsActiveOnboarding(false);
    const getOnboarding = async () => {
        const res = await new OnboardingApi().getOnboarding();

        if (res?.status && res?.result?.templates?.filter((item: any) => item?.template)?.length) {
            setOnboarding(res?.result);
            onOpenOnboarding();

            if (!(referenciesState?.list as any)?.country_list?.length) {
                dispatch(referenciesAction.getReferencies());
            }
        }
    };

    const onSetFormData = (stepObj: any) => {
        setFormData({ ...formData, ...stepObj });
    };

    const saveOnboarding = async ({ stepData = {}, isLastStep = false }) => {
        //GT-8709
        if (!onboarding?.onboardingID) return;
        const updatedFormData = { ...formData, ...stepData };
        setFormData(updatedFormData);
        if (isLastStep) onCloseOnboarding();
        let needFormatAnswer = { onboardingID: onboarding?.onboardingID };
        for (const step in updatedFormData) {
            // @ts-expect-error updatedFormData
            if (typeof updatedFormData[step] === 'object' && updatedFormData[step] !== null) {
                // @ts-expect-error updatedFormData
                needFormatAnswer = { ...needFormatAnswer, ...updatedFormData[step] };
            }
        }

        new OnboardingApi().saveOnboarding({
            ...needFormatAnswer,
            // @ts-expect-error updatedFormData
            questions: updatedFormData,
        });
        if (isLastStep) dispatch(userAction.getInfo());
    };

    return {
        getOnboarding,
        onboarding,
        isActiveOnboarding,
        onOpenOnboarding,
        onCloseOnboarding,
        saveOnboarding,
        formData,
        onSetFormData,
    };
}
