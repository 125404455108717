export const ACTION_WINK = 'WINK';
export const ACTION_LIKE = 'LIKE';
export const ACTION_ASK_VIDEO = 'ASK_VIDEO';
export const ACTION_VIEW_PROFILE = 'VIEW_PROFILE';
export const ACTION_VIEW_PHOTO = 'VIEW_PHOTO';
export const ACTION_VIEW_VIDEO_SHOW = 'VIEW_VIDEO_SHOW';

export const ACTION_LIST = [
    ACTION_WINK,
    ACTION_LIKE,
    ACTION_ASK_VIDEO,
    ACTION_VIEW_PROFILE,
    ACTION_VIEW_PHOTO,
    ACTION_VIEW_VIDEO_SHOW,
];
