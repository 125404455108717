export const notificationsStatuses = [
    {
        banner: 'START_VERIFICATION',
        title: 'Pass the verification',
        text: 'Send documents for processing',
        onClick: true,
    },
    {
        banner: 'COMPLETE_VERIFICATION',
        title: 'Complete verification',
        text: 'Complete to validate tour documents',
        onClick: true,
    },
    {
        banner: 'IN_PROGRESS_VERIFICATION',
        //status options: progress, done, pass, failed, complete
        title: 'Verification is in progress',
        text: 'Your documents are being processed.',
        onClick: true,
    },
    {
        banner: 'FAILED_VERIFICATION',
        title: 'Verification failed',
        text: 'Your documents have not been processed',
        onClick: true,
    },
    {
        banner: 'DONE_VERIFICATION',
        title: 'Verification done',
        text: 'Your documents have been processed',
        onClick: false,
    },
];
