import React, { useEffect } from 'react';
import GiftsCatalog from '../../components/gifts/GiftsCatalog';
import GiftsFilters from '../../components/gifts/GiftsFilters';
import '../../styles/gifts.scss';
import Banner from '../../components/Banner';
import * as GIFTS from '../../constants/gifts';

export default function GiftsPage() {
    useEffect(() => {
        if (window?.location?.search?.includes('remove=true')) {
            localStorage?.removeItem(GIFTS.CART_STORAGE_KEY);
        }
    }, []);

    return (
        <>
            <div className="column-3 gifts-page-column">
                <Banner />
                <div className="gifts_page_wrap">
                    <GiftsFilters />
                    <GiftsCatalog />
                </div>
            </div>
        </>
    );
}
