import React, { useState } from 'react';
import { formatDate } from '../../../../../../services/time';
import { hideStringStar, renderLastMessage } from '../../../../../../services/methods';
import * as alertAction from '../../../../../../store/actions/alert';
import moment from 'moment';
import * as REGEXP from '../../../../../../constants/regExp';
import * as USER from '../../../../../../constants/user';
import ChatsApi from '../../../../../../services/api/ChatsApi';
import * as currentChatAction from '../../../../../../store/actions/currentChat';
import * as ALERT from '../../../../../../constants/alert';
import * as chatsAction from '../../../../../../store/actions/chats';
import { useDispatch, useSelector } from 'react-redux';
import * as MENU from '../../../../../../constants/menu';
import * as userAction from '../../../../../../store/actions/user';
import useCheckSpecialPaidAction from '../../../../../../hooks/user/useCheckSpecialPaidAction';
import useSendActionSync from '../../../../../../hooks/frontSync/useSendActionSync';
import * as FRONT_SYNC from '../../../../../../constants/frontSync';
import useOpenVideoShow from '../../../../../../hooks/search/useOpenVideoShow';
import SITE from '../../../../../../config/site';
import * as MIRROR from '../../../../../../constants/mirror';
import useHandleActiveBlocks from '../../../../../../hooks/user/useHandleActiveBlocks';
import { getLastMessageContent } from '../../../../../../services/methods/chat';
import { SUPPORT_WOMAN } from '../../../../../../constants/user';
import * as modalsAction from '../../../../../../store/actions/modals';
import { RootState } from '../../../../../../index';
import { IActiveProps } from './index.interface';
import avatar_placeholder from '../../../../../../img/placeholder.gif';
import IconFavouriteNotify from '../../../../../Icons/IconFavouriteNotify';
import IconCross from '../../../../../Icons/IconCross';
import IconGallery from '../../../../../Icons/IconGallery';
import IconProfileChat from '../../../../../Icons/IconProfileChat';
import video_icon from '../../../../../../img/c2-video-ico.svg';
import chat_hello_yellow from '../../../../../../img/chat-hello-yellow.svg';

export default function Active({
    item,
    onDeleteChat,
    onActiveChat,
    openWomanProfileModal,
}: IActiveProps) {
    const menuState = useSelector((state: RootState) => state.menu);
    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const [isAskedVideo, setIsAskedVideo] = useState(+item?.ask_video);

    const dispatch = useDispatch();
    const sendActionSync = useSendActionSync();
    const checkSpecialPaidAction = useCheckSpecialPaidAction();
    const { openVideo } = useOpenVideoShow(currentChatState?.info?.id || 0);
    const handleActiveBlocks = useHandleActiveBlocks();

    const isBlock = menuState.main === MENU.MENU_BLOCK;
    const avatar = item?.search_img || avatar_placeholder;
    const isVideo = !!(
        handleActiveBlocks.isVideoShow &&
        item?.has_video &&
        menuState.main !== MENU.MENU_BLOCK
    );
    const isAskVideo =
        !handleActiveBlocks.isHideBefore2Purchase &&
        !item?.has_video &&
        (userState?.info?.convert_user &&
        userState?.info?.payment_total >= USER.PAYMENT_TOTAL_TO_ASK_VIDEO
            ? !isAskedVideo
            : false) &&
        menuState.main !== MENU.MENU_BLOCK;
    const isMessageStatus =
        +item?.sender_external_id === userState?.info?.external_id &&
        item?.last_message_type &&
        item?.last_message_type !== 'SENT_VIEW' &&
        item?.last_message;
    const isSupportWoman = currentChatState?.info?.id === +SUPPORT_WOMAN;
    const nameTitle = isSupportWoman ? item?.name : `${item?.name}, ${item?.age}`;
    const isFavorite = item?.favorite ?? item?.female_favorite;

    const askVideo = () => {
        if (!userState?.info?.external_id) return;

        const hashId = moment().unix();
        const inputData = 'Hello! Can you send me your video, please?';
        let _inputData = inputData.replace(/(\r\n|\n|\r)/gm, '');

        _inputData = MIRROR.NOT_HIDE_STRING_STAR.includes(SITE.ID)
            ? _inputData
            : hideStringStar(_inputData, REGEXP.NUMBER_IN_STRING);

        new ChatsApi()
            .postMessage(userState?.info?.external_id, item?.id, _inputData, 'ASK_VIDEO')
            .then((res) => {
                if (res?.canceled) return;
                if (res?.status) {
                    if (checkSpecialPaidAction()) {
                        dispatch(userAction.setBalance());
                    }
                    if (!res?.response?.message_object) {
                        dispatch(currentChatAction.deleteMessageInHistory(hashId));
                        if (res?.message)
                            dispatch(alertAction.setMessage({ message: res?.message }));
                        else
                            dispatch(
                                alertAction.setCustomAlertType({
                                    alertType: ALERT.ENOUGH_CREDITS_CHAT,
                                }),
                            );
                        return;
                    }
                    dispatch(
                        currentChatAction.updateMessageInHistory(
                            hashId,
                            res?.response?.message_object,
                        ),
                    );
                    dispatch(
                        chatsAction.updateMessageInChatList(
                            res?.response?.message_object?.chat_uid,
                            getLastMessageContent(
                                res?.response?.message_object?.message_content,
                                res?.response?.message_object?.message_type,
                            ),
                            res?.response?.message_object?.message_type,
                            res?.response?.message_object?.date_created,
                            res?.response?.message_object?.sender_external_id,
                        ),
                    );
                    setIsAskedVideo(1);
                    sendActionSync.postUserAction(+item?.id, FRONT_SYNC.ACTION_ASK_VIDEO);
                } else if (res?.block) {
                    dispatch(
                        alertAction.setCustomAlertType({ alertType: ALERT.NOT_EXIST_OR_BLOCK }),
                    );
                } else {
                    if (res?.message) {
                        dispatch(
                            alertAction.setMessage({ message: res?.message, title: res?.title }),
                        );
                    } else
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.ENOUGH_CREDITS_CHAT,
                            }),
                        );
                }
            });
    };

    const openGalleryModal = () => {
        if (!item?.has_image) return;
        dispatch(modalsAction.openWomanGalleryModal(item?.id));
    };

    return (
        <div
            className="c2_new_item_big"
            onClick={() => window.innerWidth < 1140 && onActiveChat(item)}
            data-testid={item?.chat_uid}
        >
            {/*{+item.unread_count > 0 && <div className="c2_new_item_big_count">{item.unread_count}</div>}*/}
            <div
                className={`c2_new_item_big_img_wrap ${item?.online ? 'online' : ''}`}
                onClick={openGalleryModal}
            >
                <img
                    src={avatar}
                    className="c2_new_item_big_img"
                    alt="avatar"
                    data-testid="profile-avatar"
                />
                {!!item?.has_image && (
                    <div className="c2_new_item_big_gallery_btn" data-testid="photo-btn">
                        <IconGallery />
                        <span>
                            <span>View all</span> photo
                        </span>
                    </div>
                )}
                {!!isFavorite && <IconFavouriteNotify />}
            </div>

            {!isBlock && (
                <div
                    className="c2_item_photo_delete"
                    id={item?.id?.toString()}
                    onClick={(e) => onDeleteChat(e, item)}
                    data-testid="delete-chat-btn"
                >
                    <IconCross />
                </div>
            )}

            <div className="c2_new_item_big_rigth">
                <div className="c2_new_item_big_time" data-testid="last-message-date">
                    <span>{formatDate(item?.last_message_date)}</span>

                    {!!isMessageStatus && (
                        <div
                            className={`message_status ${+item?.last_message_read_status === 1 ? 'readed' : 'send'}`}
                            data-testid="message-status"
                        />
                    )}
                </div>
                <div className="c2_new_item_big_middle">
                    <div className="c2_new_item_big_name" data-testid="profile-name">
                        <span>{nameTitle}</span>
                    </div>
                    <div
                        className={`c2_new_item_big_text ${+item?.sender_external_id !== userState?.info?.external_id ? '' : 'from_you'}`}
                        data-testid="last-message"
                    >
                        <span>
                            {renderLastMessage(
                                item?.last_message_type,
                                item?.last_message,
                                item?.sender_external_id !== userState?.info?.external_id,
                                item?.name,
                            )}
                        </span>

                        {item?.last_message_type === 'SENT_WINK' && (
                            <img src={chat_hello_yellow} alt="hello wink" />
                        )}
                    </div>
                </div>
                <div className="c2_new_item_big_bottom">
                    {isVideo && (
                        <div
                            className="c2_new_item_big_link c2_new_item_big_link_video"
                            onClick={openVideo}
                            data-testid="video-btn"
                        >
                            <img src={video_icon} alt="profie icon" />
                            <div className="c2_new_item_big_link_info">Video</div>
                        </div>
                    )}

                    {isAskVideo && (
                        <div
                            className="c2_new_item_big_link c2_new_item_big_link_video"
                            onClick={askVideo}
                            data-testid="ask-video-btn"
                        >
                            <img src={video_icon} alt="profie icon" />
                            <div className="c2_new_item_big_link_info min">Ask video</div>
                        </div>
                    )}

                    {!isSupportWoman && (
                        <div
                            className="c2_new_item_big_link"
                            onClick={() => openWomanProfileModal(currentChatState?.info?.id || 0)}
                            data-testid="profile-btn"
                        >
                            <IconProfileChat />
                            <div className="c2_new_item_big_link_info">Profile</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
