import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Man from './Man';
import EmptyResult from '../../../EmptyResult';
import * as mailboxAction from '../../../../../store/actions/mailbox';
import { initCancelTokenSource } from '../../../../../services/methods';
import { RootState } from '../../../../../index';

export default function ManList() {
    const dispatch = useDispatch();

    const userState = useSelector((state: RootState) => state.user);
    const mailboxState = useSelector((state: RootState) => state.mailbox);
    const menuState = useSelector((state: RootState) => state.menu);

    const [canceledToken, setCanceledToken] = useState(null);

    const isWomanFilterList = !!mailboxState?.femaleList?.length;

    useEffect(() => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        userState?.info?.external_id &&
            dispatch(
                mailboxAction.getFemaleList(
                    {
                        personFilter:
                            menuState?.composeLetterFilter?.length > 0
                                ? menuState?.composeLetterFilter
                                : '',
                        userId: userState?.info?.external_id,
                        search:
                            menuState?.composeLetterSearch?.length > 0
                                ? menuState?.composeLetterSearch
                                : '',
                    },
                    source?.token,
                ),
            );
    }, [menuState.composeLetterFilter, menuState.composeLetterSearch]);

    return (
        <div className="popup_compose_men_list_wrap" data-testid="result-list">
            <div className="popup_compose_men_list_wrap3">
                {isWomanFilterList ? (
                    <div className="popup_compose_men_list_wrap2">
                        {mailboxState?.femaleList?.map((item, key) => (
                            <Man key={key} item={item} />
                        ))}
                    </div>
                ) : (
                    mailboxState?.emptyFemaleList && <EmptyResult />
                )}
            </div>
        </div>
    );
}
