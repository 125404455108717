import Base from './Base';
import { IGetPageRes } from './PageApi.interface';

export default class PageApi extends Base {
    // We need a siteId if we want another mirrors` page/show to be displayed
    async postShow(seoUrl: string, siteId = 0): Promise<IGetPageRes> {
        const res = await super.post(`page/show`, {
            seo_url: seoUrl,
            ...(siteId > 0 ? { site_id: siteId } : {}),
        });
        return res?.data;
    }
}
