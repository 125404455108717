import Base from '../Base';

export default class BonusApi extends Base {
    async getRemarketingBonus() {
        return super
            .post(`v3/bonus/remarketing`)
            .then((res) => res?.data)
            .catch(() => null);
    }
}
