import React from 'react';

export default function IconUploadAttachment() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#8A9BA9"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_17_1144)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.54039 0.540379C7.79423 0.286538 8.20578 0.286538 8.45962 0.540378L12.3485 4.42927C12.6024 4.68311 12.6024 5.09467 12.3485 5.34851C12.0947 5.60235 11.6831 5.60235 11.4293 5.34851L8.65001 2.56923V10.3333C8.65001 10.6923 8.35899 10.9833 8.00001 10.9833C7.64102 10.9833 7.35001 10.6923 7.35001 10.3333V2.56924L4.57074 5.34851C4.31689 5.60235 3.90534 5.60235 3.6515 5.34851C3.39766 5.09467 3.39766 4.68311 3.6515 4.42927L7.54039 0.540379ZM1.00001 9.6833C1.35899 9.6833 1.65001 9.97431 1.65001 10.3333V13.4444C1.65001 13.6846 1.74543 13.915 1.91522 14.0848C2.08505 14.2546 2.3154 14.35 2.55557 14.35H13.4444C13.6846 14.35 13.915 14.2546 14.0848 14.0848C14.2546 13.915 14.35 13.6846 14.35 13.4444V10.3333C14.35 9.97431 14.641 9.6833 15 9.6833C15.359 9.6833 15.65 9.97431 15.65 10.3333V13.4444C15.65 14.0294 15.4176 14.5904 15.004 15.004C14.5904 15.4176 14.0294 15.65 13.4444 15.65H2.55557C1.97061 15.65 1.40962 15.4176 0.996013 15.004C0.582358 14.5904 0.350006 14.0294 0.350006 13.4444V10.3333C0.350006 9.97431 0.641021 9.6833 1.00001 9.6833Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_17_1144">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
