import React from 'react';
import styles from './styles.module.scss';
import IconChat from '../../Icons/IconChat';
import Banners from '../../../img/banner-search';
import { checkNationalitySite } from '../../../services/methods';
import { ICrossBannersData } from '../../../store/reducers/banners/index.interface';
import MarketingApi from '../../../services/api/MarketingApi';
import * as bannersAction from '../../../store/actions/banners';
import { useDispatch } from 'react-redux';
import { getCachedImages } from '../../../services/methods/crossBanner';

export default function SearchCrossBanner(props: ICrossBannersData) {
    const { target_site_id, log_id } = props;
    const nationality = checkNationalitySite(target_site_id);
    const dispatch = useDispatch();
    const openConfirmTermModal = () => {
        if (log_id) {
            new MarketingApi().postClickCrossBanner(log_id);
        }
        dispatch(bannersAction.setOpenConfirmTermsModal(props));
    };
    const image = getCachedImages(log_id, nationality, 'search');
    const imageSrc = Array.isArray(image) ? image[0] : image;
    return (
        <li
            className={styles.search_cross_banner_item_wrap}
            id="search-cross-banner"
            data-testid="result-cross-banner-item"
            onClick={openConfirmTermModal}
        >
            <div className={styles.search_cross_banner_item_photo_wrap}>
                <img
                    className={styles.search_cross_banner_item_photo}
                    src={Banners.images[imageSrc]}
                    alt={`${nationality} girl`}
                />
            </div>
            <div className={styles.search_cross_banner_item_bottom}>
                <div className={styles.search_cross_banner_item_chat_btn}>
                    <IconChat />
                    <span>Try {nationality} Chatting</span>
                </div>
            </div>
        </li>
    );
}
