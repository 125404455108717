import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ConfirmStatusName from './ConfirmStatusName';
import { IConfirmStatusNameModalProps } from './index.interface';

export default function ConfirmStatusNameModal({
    isActiveConfirmNameModal,
    closeConfirmNameModal,
    nameData,
    changeNameData,
    submitNameData,
}: IConfirmStatusNameModalProps) {
    return (
        <Modal style={modalStyleProps()} isOpen={isActiveConfirmNameModal} ariaHideApp={false}>
            <ConfirmStatusName
                closeModal={closeConfirmNameModal}
                nameData={nameData}
                changeNameData={changeNameData}
                submitNameData={submitNameData}
            />
        </Modal>
    );
}
