import React from 'react';

export default function IconBlock() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="#6F0267"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17.2404 8.74932C17.2404 13.3625 13.452 17.1022 8.77875 17.1022C4.10553 17.1022 0.317139 13.3625 0.317139 8.74932C0.317139 4.13618 4.10553 0.396484 8.77875 0.396484C13.452 0.396484 17.2404 4.13618 17.2404 8.74932ZM1.50176 8.74932C1.50176 12.7166 4.75978 15.9328 8.77875 15.9328C12.7977 15.9328 16.0557 12.7166 16.0557 8.74932C16.0557 4.78202 12.7977 1.56588 8.77875 1.56588C4.75978 1.56588 1.50176 4.78202 1.50176 8.74932Z" />
            <rect
                width="15.0132"
                height="1.00088"
                transform="matrix(0.711666 -0.702518 0.711666 0.702518 3.08081 13.6719)"
            />
        </svg>
    );
}
