import React, { useState } from 'react';
import styles from './styles.module.scss';
import useHandleVIP from '../../../../hooks/vip/useHandleVIP';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Modal from 'react-modal';
import moment from 'moment';
import useTransition from '../../../../hooks/transition/useTransition';
import IconVipStar from '../../../Icons/IconVipStar';
import IconCross from '../../../Icons/IconCross';
import vipOnlineNowBanner from '../../../../img/vip/vip_online_now_banner.svg';
import SITE from '../../../../config/site';

export default function VIPModal() {
    const transition = useTransition();
    const { isShowVIPBanner } = useHandleVIP();
    const [showBlock, setShowBlock] = useState<boolean>(true);

    const handleOpen = () => {
        setShowBlock(false);
        localStorage.setItem('vip-modal', new Date().toISOString());
        transition.transitionWithOnlyHistoryPush('/my-profile');

        const profileVipBlock = document.querySelector('#profile-vip-block');
        if (profileVipBlock) profileVipBlock.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };

    const handleClose = () => {
        setShowBlock(false);
        localStorage.setItem('vip-modal', new Date().toISOString());
    };

    const isShowByLocalStorageCondition = () => {
        const isActiveByLS = localStorage.getItem('vip-modal') || null;
        if (!isActiveByLS) return true;

        return moment(new Date()).diff(moment(new Date(isActiveByLS)), 'hours') >= 24;
    };

    if (!isShowByLocalStorageCondition() || !showBlock || !isShowVIPBanner || SITE.ID === 23)
        return null;
    return (
        <Modal
            style={modalStyleProps()}
            isOpen={true}
            onRequestClose={handleClose}
            ariaHideApp={false}
        >
            <div className={styles.vip_onb_wrapper} data-testid="vip-modal">
                <div className={styles.vip_onb_item}>
                    <div className={styles.vip_onb_title}>
                        Get the maximum benefit with <span>VIP status</span>
                    </div>
                    <div className={styles.vip_onb_container}>
                        <div>
                            <img
                                className={styles.vip_onb_pict}
                                src={vipOnlineNowBanner}
                                alt="vip online banner"
                            />
                        </div>
                        <div>
                            <div className={styles.vip_onb_container_txt}>
                                <IconVipStar />
                                <span className={styles.vip_onb_txt}>
                                    Finding a soul mate is even easier. Members trust verified users
                                    more
                                </span>
                            </div>
                            <div className={styles.vip_onb_container_txt}>
                                <IconVipStar />
                                <span className={styles.vip_onb_txt}>
                                    Your profile is always at the top of the search
                                </span>
                            </div>
                            <div className={styles.vip_onb_container_txt}>
                                <IconVipStar />
                                <span className={styles.vip_onb_txt}>
                                    The VIP support team processes your requests out of turn 24/7
                                </span>
                            </div>
                            <div className={styles.vip_onb_container_txt}>
                                <IconVipStar />
                                <span className={styles.vip_onb_txt}>
                                    Possibility to exchange contacts with members
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.vip_onb_btn}
                        onClick={handleOpen}
                        data-testid="get-vip-btn"
                    >
                        Get VIP and bonus 100 free credits
                    </div>
                    <div
                        className={styles.vip_onb_cross}
                        onClick={handleClose}
                        data-testid="close-btn"
                    >
                        <IconCross />
                    </div>
                </div>
            </div>
        </Modal>
    );
}
