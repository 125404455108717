import React from 'react';

export default function IconBookmark() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.79167 1.5498C7.40049 1.5498 6.07339 1.81904 5.07498 2.53419C4.03565 3.27867 3.4502 4.43645 3.4502 5.96998V20.6938C3.4502 21.9928 4.84807 22.8113 5.98065 22.176L5.98069 22.176L11.9944 18.8026L17.9536 22.1689L17.9544 22.1693C19.0872 22.8074 20.489 21.9903 20.489 20.6888V6.15344C20.489 4.58986 19.9434 3.38658 18.9164 2.59969C17.9243 1.83959 16.5879 1.5498 15.1506 1.5498H8.79167ZM4.9502 5.96998C4.9502 4.86091 5.3482 4.1836 5.94846 3.75364C6.58965 3.29435 7.5583 3.0498 8.79167 3.0498H15.1506C16.421 3.0498 17.3789 3.3114 18.0041 3.79039C18.5943 4.2426 18.989 4.96614 18.989 6.15344V20.6888C18.989 20.8409 18.8251 20.938 18.6907 20.8625L18.6906 20.8624L12.3646 17.2889C12.1363 17.16 11.8574 17.1596 11.6288 17.2878L5.2468 20.8677L5.24677 20.8678C5.1142 20.9421 4.9502 20.8464 4.9502 20.6938V5.96998ZM8.271 8.27811C7.85678 8.27811 7.521 8.61389 7.521 9.02811C7.521 9.44232 7.85678 9.77811 8.271 9.77811H15.5893C16.0035 9.77811 16.3393 9.44232 16.3393 9.02811C16.3393 8.61389 16.0035 8.27811 15.5893 8.27811H8.271Z"
            />
        </svg>
    );
}
