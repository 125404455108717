import { useState } from 'react';

export default function useActiveButton() {
    const [activeBtn, setActiveBtn] = useState(true);

    const onBlockBtn = () => {
        if (activeBtn) {
            setActiveBtn(false);
            return false;
        } else {
            return true;
        }
    };

    const onDefaultBlockBtn = () => {
        setActiveBtn(true);
    };

    return {
        onBlockBtn,
        onDefaultBlockBtn,
    };
}
