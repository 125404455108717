import { VirtualGiftsActionTypes } from '../../constants/virtualGifts';
import { IVirtualGiftsState, VirtualGiftsActions } from './index.interface';

const initState = {
    chat: [],
    stream: [],
};

export default function (
    state: IVirtualGiftsState = initState,
    action: VirtualGiftsActions,
): IVirtualGiftsState {
    switch (action.type) {
        case VirtualGiftsActionTypes.GET_VIRTUAL_GIFTS_DATA:
            return {
                ...state,
                ...{
                    chat: action.chat,
                    stream: action.stream,
                },
            };

        default:
            return state;
    }
}
