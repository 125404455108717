import React from 'react';

export default function IconAboutPhoto() {
    return (
        <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="#446077"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_91)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.96671 10.5067C3.87188 12.5222 3.25 14.8318 3.25 17.2867C3.25 19.0236 3.5613 20.6877 4.13114 22.2264H11.7344L9.25974 17.941C9.24833 17.9224 9.23743 17.9035 9.22705 17.8844L4.96671 10.5067ZM3.94283 7.45612C1.93466 10.2155 0.75 13.6128 0.75 17.2867C0.75 19.6332 1.23323 21.8667 2.1056 23.8934C2.12556 23.9498 2.14946 24.0044 2.17696 24.0567C4.52269 29.3492 9.53439 33.1971 15.5083 33.8966C15.5709 33.9084 15.6339 33.9154 15.6967 33.9176C16.2808 33.9793 16.8738 34.0109 17.4742 34.0109C22.9464 34.0109 27.8048 31.3827 30.8559 27.3198C30.9131 27.2569 30.9633 27.1888 31.0059 27.1168C33.0139 24.3575 34.1984 20.9604 34.1984 17.2867C34.1984 14.9406 33.7153 12.7073 32.8432 10.6808C32.823 10.6238 32.7989 10.5688 32.7711 10.5159C30.4252 5.22366 25.4134 1.37606 19.4396 0.67676C19.3773 0.664999 19.3147 0.658092 19.2522 0.655899C18.668 0.594159 18.0748 0.5625 17.4742 0.5625C12.0018 0.5625 7.14329 3.19087 4.0922 7.25407C4.03523 7.31672 3.98533 7.38449 3.94283 7.45612ZM6.52511 8.20618L10.3252 14.7869L17.0927 3.06752C12.8436 3.17938 9.05866 5.15468 6.52511 8.20618ZM19.8668 3.26286L16.0647 9.847H21.0248C21.04 9.84672 21.0553 9.84672 21.0704 9.847H29.6C27.4953 6.42382 23.9816 3.95983 19.8668 3.26286ZM30.8173 12.347H23.2141L25.6901 16.6348C25.7005 16.6518 25.7105 16.6691 25.72 16.6866L29.9817 24.0666C31.0765 22.0512 31.6984 19.7416 31.6984 17.2867C31.6984 15.5498 31.3871 13.8857 30.8173 12.347ZM28.4233 26.3672L24.6232 19.7865L22.138 24.0901C22.1341 24.0971 22.13 24.1041 22.126 24.111L17.8557 31.5059C22.1048 31.394 25.8898 29.4187 28.4233 26.3672ZM15.0816 31.3105L18.8837 24.7264H13.9237C13.9085 24.7267 13.8932 24.7267 13.878 24.7264H5.34836C7.4531 28.1496 10.9668 30.6136 15.0816 31.3105ZM14.6213 22.2264H20.3274L23.1798 17.2868L20.3272 12.347H14.6211L11.7687 17.2865L14.6213 22.2264Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_91">
                    <rect width="35" height="35" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
