import React, { useEffect } from 'react';
import { scrollTopToMainChatWithPagination } from '../../../../services/dom';
import Emoji from 'react-emoji-render';
import { useDispatch, useSelector } from 'react-redux';
import ChatsApi from '../../../../services/api/ChatsApi';
import * as currentChatAction from '../../../../store/actions/currentChat';
import * as allMediaAction from '../../../../store/actions/allMedia';
import * as modalsAction from '../../../../store/actions/modals';
import * as userAction from '../../../../store/actions/user';
import { formatDate } from '../../../../services/time';
import AudioPlayer from '../../../common/AudioPlayer';
import * as alertAction from '../../../../store/actions/alert';
import * as ERROR from '../../../../constants/error';
import * as ALERT from '../../../../constants/alert';
import * as PRICES from '../../../../constants/prices';
import { convertPrice, renderLastMessage } from '../../../../services/methods';
import * as STICKERS from '../../../../constants/stickers';
import * as CHATS from '../../../../constants/chats';
import { filterToHideChatHistoryByType, stopAudio } from '../../../../services/methods/chat';
import SITE from '../../../../config/site';
import IconLock from '../../../Icons/IconLock';
import styles from './index.module.scss';
import { IMessageLeftProps } from './index.interface';
import { RootState } from '../../../../index';
import { IChatHistoryItem } from '../../../../store/reducers/currentChat/index.interface';
import avatar_placeholder from '../../../../img/placeholder.gif';
import ChatMedia from '../ChatMedia';

export default function MessageLeft({
    message,
    isLoadingRequest,
    setIsLoadingRequest,
    messageIdArr,
    setMessageIdArr,
}: IMessageLeftProps) {
    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const dispatch = useDispatch();

    const selectedSticker = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
        e?.name?.includes(message?.message_content),
    )?.image;
    const selectedStickerName = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
        e?.name?.includes(message?.message_content),
    )?.name;
    const isNewMail = userState?.info?.newMail;

    const openImageModal = (link: string) =>
        dispatch(modalsAction.openPhotoContentModal({ link, attachment_payed: '1' }));
    const openVideoModal = (link: string) =>
        dispatch(modalsAction.openVideoContentModal({ link, attachment_payed: '1' }));

    const readAudio = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.id)) return;

        const newMessageIdArr = [...messageIdArr, data?.id];

        setMessageIdArr([...newMessageIdArr]);
        setIsLoadingRequest(true);

        new ChatsApi()
            .postReadAudio({
                userId: userState?.info?.external_id,
                messageId: data?.id,
                chatUid: currentChatState?.info?.chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(currentChatAction.readMessageAudio(data?.id, res?.message_content));
                    dispatch(allMediaAction.setBuyedMedia(data?.id));
                    dispatch(userAction.setBalance());

                    if (res?.message_content)
                        viewedAudio({ ...data, message_content: res?.message_content });
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                }
            })
            .finally(() => {
                setIsLoadingRequest(false);
                setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.id));
            });
    };
    const readVideo = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.id)) return false;

        const newMessageIdArr = [...messageIdArr, data?.id];

        setMessageIdArr([...newMessageIdArr]);
        setIsLoadingRequest(true);

        new ChatsApi()
            .postReadVideo({
                userId: userState?.info?.external_id,
                messageId: data?.id,
                chatUid: currentChatState?.info?.chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(currentChatAction.readMessageVideo(data?.id, res?.message_content));
                    dispatch(allMediaAction.setBuyedMedia(data?.id));
                    dispatch(userAction.setBalance());

                    if (res?.message_content)
                        viewedVideo({ ...data, message_content: res?.message_content });
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                }
            })
            .finally(() => {
                setIsLoadingRequest(false);
                setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.id));
            });
    };

    const readImage = (data: IChatHistoryItem) => {
        if (!currentChatState?.info?.chat_uid) return;

        if (isLoadingRequest && messageIdArr?.some((el) => +el === +data?.id)) return false;

        const newMessageIdArr = [...messageIdArr, data?.id];

        setMessageIdArr([...newMessageIdArr]);
        setIsLoadingRequest(true);

        new ChatsApi()
            .postReadImage({
                messageId: data?.id,
                chatUid: currentChatState?.info?.chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(currentChatAction.readMessageImage(data?.id, res?.message_content));
                    dispatch(allMediaAction.setBuyedMedia(data?.id));
                    dispatch(userAction.setBalance());

                    if (res?.message_content) {
                        viewedImage({ ...data, message_thumb: res?.message_content });
                    }
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                }
            })
            .finally(() => {
                setIsLoadingRequest(false);
                setMessageIdArr(newMessageIdArr.filter((el) => +el === +data?.id));
            });
    };

    const viewedVideo = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        new ChatsApi().postViewMedia(
            userState?.info?.external_id,
            data?.id,
            currentChatState?.info?.chat_uid,
        );

        openVideoModal(data?.message_content);
    };

    const viewedAudio = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        stopAudio(data?.message_content, true, data?.id);
        new ChatsApi().postViewMedia(
            userState?.info?.external_id,
            data?.id,
            currentChatState?.info?.chat_uid,
        );
    };

    const viewedImage = (data: IChatHistoryItem) => {
        if (!userState?.info?.external_id) return;
        if (!currentChatState?.info?.chat_uid) return;

        new ChatsApi().postViewMedia(
            userState?.info?.external_id,
            data?.id,
            currentChatState?.info?.chat_uid,
        );

        if (data?.message_popup) {
            openImageModal(data?.message_popup);
        }
    };

    const checkBeforeBuyingMessageContent = (type: string, callback: () => void) => {
        if (userState?.info?.convert_user || SITE.ID === 4) callback();
        else
            dispatch(
                alertAction.setCustomAlertType({
                    alertType: type,
                    successCallback: () => callback(),
                }),
            );
    };

    useEffect(() => {
        if (currentChatState?.history?.length) {
            if (!currentChatState?.isNewMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            } else if (!currentChatState?.isReadLastMessage) {
                scrollTopToMainChatWithPagination(currentChatState?.lastMessageId);
            }
        }
    }, [currentChatState?.history]);

    const renderVideoContent = () => {
        const isBlurABTest = SITE.ID !== 4 && message?.payed === 0 && message?.thumb_link_blur;

        const priceAmount = isNewMail
            ? convertPrice(+(userState?.prices?.[PRICES.GET_VIDEO_NEW] || 0))
            : convertPrice(+(userState?.prices?.[PRICES.GET_VIDEO] || 0));

        const price = isBlurABTest
            ? `Reveal video for \u00A9 ${priceAmount}`
            : `Viewing this video costs ${priceAmount} credits`;

        if (isBlurABTest) {
            return (
                <div
                    className="message_video_wrap"
                    onClick={() =>
                        checkBeforeBuyingMessageContent(ALERT.VIDEO_MESSAGE_CONTENT, () =>
                            readVideo(message),
                        )
                    }
                >
                    <div className="message_video_without_play">
                        {!!message?.thumb_link_blur?.length && (
                            <div className={styles.video_thumbnail_container}>
                                <div className={styles.video_thumbnail_lock_screen}>
                                    <IconLock />
                                    <span className="need_pay" data-testid="action-cost">
                                        {price}
                                    </span>
                                </div>
                                <ChatMedia
                                    mediaUrl={message?.thumb_link_blur}
                                    dataTestId={message?.filename}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        if (+message?.payed === 0) {
            const hasThumbLink = !!message?.thumb_link?.length;
            const mediaUrl = hasThumbLink ? message?.thumb_link : message?.message_content;

            return (
                <div
                    className="message_video_wrap"
                    onClick={() =>
                        checkBeforeBuyingMessageContent(ALERT.VIDEO_MESSAGE_CONTENT, () =>
                            readVideo(message),
                        )
                    }
                >
                    {SITE.ID !== 4 && (
                        <span className="need_pay" data-testid="action-cost">
                            {price}
                        </span>
                    )}
                    <div className="message_video">
                        <ChatMedia
                            mediaUrl={mediaUrl}
                            dataTestId={message?.filename}
                            isVideo={!hasThumbLink}
                        />
                    </div>
                </div>
            );
        } else {
            if (!message?.date_viewed && SITE.ID !== 4) {
                return (
                    <div className="message_video_wrap" onClick={() => viewedVideo(message)}>
                        <div className="message_video">
                            {!!message?.thumb_link?.length && (
                                <ChatMedia
                                    mediaUrl={message?.thumb_link}
                                    dataTestId={message?.filename}
                                />
                            )}
                        </div>
                        <div className="message_video_status paid" data-testid="cost-status">
                            Paid
                        </div>
                    </div>
                );
            } else {
                const hasThumbLink = !!message?.thumb_link?.length;
                const mediaUrl = hasThumbLink ? message?.thumb_link : message?.message_content;

                return (
                    <div className="message_video_wrap" onClick={() => viewedVideo(message)}>
                        <div className="message_video">
                            <ChatMedia
                                mediaUrl={mediaUrl}
                                dataTestId={message?.filename}
                                isVideo={!hasThumbLink}
                            />
                        </div>
                        <div className="message_video_status viewed" data-testid="cost-status">
                            <span>
                                Viewed{' '}
                                {message?.date_viewed ? formatDate(message?.date_viewed) : ''}
                            </span>
                        </div>
                    </div>
                );
            }
        }
    };

    return (
        <div
            className={`chat_message_wrap left 
                ${message?.message_type === CHATS.MESSAGE_CONTENT_TYPE_WINK ? 'wink' : ''}
                ${message?.message_type === CHATS.MESSAGE_CONTENT_TYPE_STICKER ? 'sticker' : ''}
                ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}
            `}
            id={`mess-${message?.id}`}
            data-testid="received-message"
        >
            <div
                className={`chat_message ${!filterToHideChatHistoryByType(message) ? 'disabled' : ''}`}
            >
                <div className="message_avatar" data-testid="message-avatar">
                    <img
                        src={currentChatState?.info?.avatar || avatar_placeholder}
                        alt="user avatar"
                    />
                </div>
                <div className="message_content" data-testid="message-content">
                    {(function () {
                        switch (message?.message_type) {
                            case CHATS.MESSAGE_CONTENT_TYPE_IMAGE: {
                                if (+message?.payed === 0) {
                                    return (
                                        <div
                                            className="message_text"
                                            data-testid="message-image"
                                            onClick={() => readImage(message)}
                                        >
                                            <ChatMedia
                                                mediaUrl={message?.message_thumb}
                                                dataTestId={message?.filename}
                                            />

                                            <div
                                                className="message_text--overlay"
                                                data-testid="action-cost"
                                            >
                                                <IconLock />
                                                <div className="need_pay">
                                                    {'Reveal for'}
                                                    <span>
                                                        {convertPrice(
                                                            +(
                                                                userState?.prices?.[
                                                                    PRICES.GET_IMAGE
                                                                ] || 0
                                                            ),
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                return (
                                    <div className="message_text" data-testid="message-image">
                                        <ChatMedia
                                            mediaUrl={message?.message_thumb}
                                            onClickCallback={() => viewedImage(message)}
                                            dataTestId={message?.filename}
                                        />
                                    </div>
                                );
                            }

                            case CHATS.MESSAGE_CONTENT_TYPE_AUDIO: {
                                const price = isNewMail
                                    ? `Listening to this audio costs ${convertPrice(+(userState?.prices?.[PRICES.GET_AUDIO_NEW] || 0))} credits`
                                    : `Listening to this audio costs ${convertPrice(+(userState?.prices?.[PRICES.GET_AUDIO] || 0))} credits`;

                                return (
                                    <div className="message_text" data-testid="message-audio">
                                        {+message?.payed === 0 ? (
                                            <div
                                                className={`message_audio_wrap ${isLoadingRequest && messageIdArr?.some((el: number) => +el === +message?.id) ? 'blocked' : ''}`}
                                                onClick={() =>
                                                    checkBeforeBuyingMessageContent(
                                                        ALERT.AUDIO_MESSAGE_CONTENT,
                                                        () => readAudio(message),
                                                    )
                                                }
                                                data-testid={message?.filename}
                                            >
                                                {SITE.ID !== 4 && (
                                                    <span
                                                        className="need_pay"
                                                        data-testid="action-cost"
                                                    >
                                                        {price}
                                                    </span>
                                                )}
                                                <AudioPlayer
                                                    src={''}
                                                    onPayAudio={() =>
                                                        checkBeforeBuyingMessageContent(
                                                            ALERT.AUDIO_MESSAGE_CONTENT,
                                                            () => readAudio(message),
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="message_audio_wrap"
                                                onClick={() => viewedAudio(message)}
                                                data-testid={message?.filename}
                                            >
                                                <AudioPlayer
                                                    src={message?.message_content}
                                                    viewedAudio={() => viewedAudio(message)}
                                                />
                                                <div
                                                    className="message_video_status paid"
                                                    data-testid="cost-status"
                                                >
                                                    {message?.date_viewed === null &&
                                                    SITE.ID !== 4 ? (
                                                        `Paid`
                                                    ) : (
                                                        <span>
                                                            Listened{' '}
                                                            {message?.date_viewed
                                                                ? formatDate(message?.date_viewed)
                                                                : ''}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }

                            case CHATS.MESSAGE_CONTENT_TYPE_VIDEO: {
                                return (
                                    <>
                                        <div className="message_text" data-testid="message-video">
                                            {renderVideoContent()}
                                        </div>
                                    </>
                                );
                            }

                            case CHATS.MESSAGE_CONTENT_TYPE_TEXT:
                            case CHATS.MESSAGE_CONTENT_TYPE_WINK:
                                // eslint-disable-next-line no-case-declarations
                                const lines = message?.message_content.split('\n');

                                return (
                                    <div
                                        className="message_text"
                                        data-testid={`message-${CHATS.MESSAGE_CONTENT_TYPE_TEXT ? 'text' : 'wink'}`}
                                    >
                                        {/*<Emoji text={message?.message_content}/>*/}
                                        {!!+lines?.length &&
                                            lines.map((line: string, index: number) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_STICKER:
                                return (
                                    <div className="message_text" data-testid="message-sticker">
                                        {selectedSticker && (
                                            <img
                                                src={selectedSticker}
                                                data-testid={selectedStickerName}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_LIKE:
                                return (
                                    <div className="message_text like" data-testid="message-like">
                                        <img src="../../../../img/c4-like.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            case CHATS.MESSAGE_CONTENT_TYPE_VIEW:
                                return (
                                    <div className="message_text like" data-testid="message-view">
                                        <img src="../../../../img/c4-viwed.svg" alt="" />
                                        <Emoji
                                            text={renderLastMessage(
                                                message?.message_type,
                                                message?.message_content,
                                                message?.sender_external_id !==
                                                    userState?.info?.external_id,
                                                currentChatState?.info?.name || '',
                                            )}
                                        />
                                    </div>
                                );

                            default:
                                return null;
                        }
                    })()}
                    <div className="message_bottom">
                        <div className="message_date" data-testid="message-date">
                            {formatDate(message?.date_created)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
