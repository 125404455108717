import React from 'react';
import { useSelector } from 'react-redux';
import SwitchLetterAttachment from '../../../common/SwitchLetterAttachment';
import SwitchLetterOptions from '../../../common/SwitchLetterOptions';
import * as PRICES from '../../../../constants/prices';
import { formatDate } from '../../../../services/time';
import { convertPrice } from '../../../../services/methods';
import * as MENU from '../../../../constants/menu';
import * as SEX from '../../../../constants/sex';
import useHandleLettersListItem from '../../../../hooks/letters/useHandleLettersListItem';
import SITE from '../../../../config/site';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import '../../../../styles/slick.scss';
import SlickContainer from '../../../common/SlickContainer';
import avatar_placeholder from '../../../../img/placeholder.gif';
import { RootState } from '../../../../index';
import { IMailsAttachment, IMailsFullInfo } from '../../../../services/api/MailboxApi.interface';

interface ILetterItemProps {
    item: IMailsFullInfo;
    type: string;
}

export default function LetterItem({ item, type }: ILetterItemProps) {
    const userState = useSelector((state: RootState) => state.user);
    const menuState = useSelector((state: RootState) => state.menu);
    const handleActiveBlocks = useHandleActiveBlocks();
    const handleLettersListItem = useHandleLettersListItem(item?.mail);

    const isUserMail = +item?.mail?.operator === 0;
    const womanExternalID = isUserMail ? item?.mail?.recipient_id : item?.mail?.sender_id;
    const womanPublicExternalID = item?.mail?.public_external_id;
    const womanName = isUserMail ? item?.mail?.recipient_name : item?.mail?.sender_name;
    const womanAge = isUserMail ? item?.mail?.recipient_age : item?.mail?.sender_age;
    const womanCountryIcon = isUserMail
        ? item?.mail?.recipient_country_icon
        : item?.mail?.sender_country_icon;
    const womanCity = isUserMail ? item?.mail?.recipient_city : item?.mail?.sender_city;
    const womanOnline = isUserMail ? item?.mail?.recipient_online : item?.mail?.sender_online;
    const womanAvatar =
        (isUserMail ? item?.mail?.recipient_avatar : item?.mail?.sender_avatar) ||
        avatar_placeholder;
    const mailDate = formatDate(item?.mail?.date_created);
    const mailText = item?.mail?.message_content || '';
    const mailUnreadCount = +item?.mail?.unread_count;

    const styleOnline = womanOnline ? 'online' : '';
    const styleRead = isUserMail ? 'read' : +item?.mail?.mail_read_status ? 'read' : 'unread';
    const styleFavorite = +item?.mail?.is_favorite ? 'favorites' : '';
    const styleMainTop =
        menuState.letters === MENU.LETTERS_TRASH ? { cursor: 'unset' } : { cursor: 'pointer' };
    const styleUnReadCount = mailUnreadCount
        ? 'count'
        : +item?.mail?.answered === 1
          ? 'answered'
          : +item?.mail?.answered === 0
            ? 'unanswered'
            : 'is_first';

    const isNewMail = userState?.info?.newMail;
    const isFreeReading =
        !isUserMail &&
        isNewMail &&
        !!mailUnreadCount &&
        (!+item?.mail?.connect || !!(+item?.mail?.paid_read && !+item?.mail?.read_status)) &&
        SITE.ID !== 4;
    const isHideText =
        !isUserMail &&
        isNewMail &&
        (handleActiveBlocks.isNewMailCategory1
            ? !+item?.mail?.paid_read
            : !+item?.mail?.paid_read || !!(+item?.mail?.paid_read && !+item?.mail?.read_status));
    const isFreeAttachments = item?.attachments?.every(
        (item: IMailsAttachment) => +item?.attachment_free,
    );
    const isTitleAttachmentPrice = !isFreeAttachments;

    const titleUnreadCount = mailUnreadCount
        ? mailUnreadCount
        : +item?.mail?.answered === 1
          ? 'Answered'
          : +item?.mail?.answered === 0
            ? 'Unanswered'
            : SEX.SEX_TEXT?.[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
                  ?.LETTER_FIRST_LETTER_TO_HER;
    const titleAttachmentPrice = `The cost of each attachment is: photo - ${convertPrice(+(userState?.prices?.[PRICES.GET_IMAGE_MAIL] || 0))} credits, video - ${convertPrice(+(userState?.prices?.[isNewMail ? PRICES.GET_VIDEO_MAIL_NEW : PRICES.GET_VIDEO_MAIL] || 0))} credits, audio - ${convertPrice(+(userState?.prices?.[isNewMail ? PRICES.GET_AUDIO_MAIL_NEW : PRICES.GET_AUDIO_MAIL] || 0))} credits. Special bonus - the first photo is free.`;

    const textContent = () => {
        if (!isHideText) return mailText;

        const visibleText = mailText.substr(0, 25);
        const unVisibleText = mailText.substr(25);

        return (
            <>
                <span data-testid="letter-item-visible-content">{visibleText}</span>{' '}
                <span
                    className="c3l_men_list_item_text_blur unselectable"
                    data-testid="letter-item-blur-content"
                >
                    {unVisibleText}
                </span>
            </>
        );
    };

    const onOpenLetterChat = () => {
        if (menuState.letters !== MENU.LETTERS_TRASH) {
            const isBookmark =
                item?.mail?.bookmark > 0 && menuState.letters === MENU.BOOKMARK_FOLDER;
            if (
                handleActiveBlocks.isNewMailCategory1 &&
                !+item?.mail?.mail_read_status &&
                !!+item?.mail?.paid_read
            ) {
                handleLettersListItem.paidNewLetterText(
                    item?.mail?.id,
                    womanExternalID,
                    isBookmark,
                );
            }
            handleLettersListItem.openMailChat(isBookmark);
        }
    };

    return (
        <div
            className={`c3l_men_list_item ${styleOnline} ${styleRead}`}
            id={`letter-${item?.mail?.id}`}
            data-testid={`letter-item-${item?.mail?.id}`}
        >
            <div className={`c3l_men_list_item_main ${styleFavorite}`}>
                <div
                    className="c3l_men_list_item_main_top"
                    style={styleMainTop}
                    onClick={onOpenLetterChat}
                >
                    <div className="c3l_men_list_item_users">
                        <div className="c3l_men_list_item_from">
                            {isUserMail && <div className="c3l_men_list_item_from_to">To:</div>}
                            <div
                                className="c3l_men_list_item_from_photo"
                                data-testid="letter-item-photo"
                            >
                                <img src={womanAvatar} alt="avatar" />
                                {!!+item?.mail?.is_favorite && (
                                    <div className="c3l_men_list_item_from_photo_fav" />
                                )}
                            </div>
                            <div className="c3l_men_list_item_from_info">
                                <div
                                    className="c3l_men_list_item_from_id"
                                    data-testid="letter-item-external_id"
                                >{`ID ${womanPublicExternalID}`}</div>
                                <div
                                    className="c3l_men_list_item_from_name"
                                    data-testid="letter-item-name"
                                >{`${womanName}, ${womanAge}`}</div>
                                <div
                                    className="c3l_men_list_item_from_place"
                                    data-testid="letter-item-city"
                                >
                                    <img src={womanCountryIcon} alt="" />
                                    {womanCity}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="c3l_men_list_item_text" data-testid="letter-item-content">
                        <div>{textContent()}</div>
                    </div>
                </div>
                {!!item?.attachments?.length && (
                    <div className="c3l_men_list_item_main_bottom">
                        {!!(isTitleAttachmentPrice && SITE.ID !== 4) && (
                            <div
                                className="c3l_men_list_item_main_bottom_cost_text"
                                data-testid="letter-item-attachment-price"
                            >
                                {titleAttachmentPrice}
                            </div>
                        )}
                        <div
                            className="letters_attach_files_list"
                            data-testid="letter-item-attachments"
                        >
                            <SlickContainer>
                                {item?.attachments?.map((attach: IMailsAttachment, key: number) => (
                                    <SwitchLetterAttachment
                                        key={`${key}_${attach?.attachment_id}_${item?.mail?.id}`}
                                        data={attach}
                                        letterID={item?.mail?.id}
                                        isFreeToWatch={isUserMail}
                                    />
                                ))}
                            </SlickContainer>
                        </div>
                    </div>
                )}
                {!!isFreeReading && (
                    <div className={`c3l_men_list_item_status free`}>Free reading</div>
                )}
                <div
                    className={`c3l_men_list_item_status ${styleUnReadCount}`}
                    data-testid="letter-item-status"
                >
                    {titleUnreadCount}
                </div>
                <div className="c3l_men_list_item_time" data-testid="letter-item-date">
                    {mailDate}
                </div>
            </div>
            <SwitchLetterOptions folder={type} item={item} />
        </div>
    );
}
