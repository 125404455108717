export const FILTERS_CATEGORIES_LIMIT = 4;
export const CART_STORAGE_KEY = 'cart';

export const CART_ERROR_RECIPIENT_MESSAGE = 'Please select gift addressee';
export const CART_ERROR_ITEMS_MESSAGE = 'Cart is empty';

export const GIFTS_NOT_PAYMENT_USER = [
    {
        id: 12,
        price: 1299,
    },
    {
        id: 17,
        price: 849,
    },
    {
        id: 20,
        price: 849,
    },
    {
        id: 34,
        price: 1699,
    },
    {
        id: 59,
        price: 2499,
    },
    {
        id: 60,
        price: 3399,
    },
    {
        id: 64,
        price: 1249,
    },
    {
        id: 65,
        price: 1649,
    },
    {
        id: 66,
        price: 2499,
    },
    {
        id: 67,
        price: 2899,
    },
    {
        id: 68,
        price: 399,
    },
    {
        id: 69,
        price: 399,
    },
    {
        id: 70,
        price: 399,
    },
    {
        id: 71,
        price: 399,
    },
    {
        id: 73,
        price: 399,
    },
    {
        id: 74,
        price: 399,
    },
    {
        id: 75,
        price: 399,
    },
    {
        id: 76,
        price: 399,
    },
    {
        id: 77,
        price: 399,
    },
    {
        id: 83,
        price: 1249,
    },
    {
        id: 84,
        price: 649,
    },
    {
        id: 85,
        price: 899,
    },
    {
        id: 86,
        price: 899,
    },
    {
        id: 88,
        price: 649,
    },
    {
        id: 90,
        price: 649,
    },
    {
        id: 91,
        price: 699,
    },
    {
        id: 92,
        price: 549,
    },
    {
        id: 93,
        price: 649,
    },
    {
        id: 94,
        price: 399,
    },
    {
        id: 97,
        price: 599,
    },
    {
        id: 99,
        price: 349,
    },
    {
        id: 100,
        price: 349,
    },
    {
        id: 102,
        price: 849,
    },
    {
        id: 103,
        price: 849,
    },
    {
        id: 105,
        price: 849,
    },
    {
        id: 107,
        price: 999,
    },
    {
        id: 113,
        price: 849,
    },
    {
        id: 114,
        price: 849,
    },
    {
        id: 115,
        price: 649,
    },
    {
        id: 116,
        price: 399,
    },
    {
        id: 117,
        price: 399,
    },
    {
        id: 118,
        price: 649,
    },
    {
        id: 120,
        price: 999,
    },
    {
        id: 121,
        price: 999,
    },
    {
        id: 123,
        price: 999,
    },
    {
        id: 129,
        price: 5999,
    },
    {
        id: 137,
        price: 849,
    },
    {
        id: 138,
        price: 599,
    },
    {
        id: 141,
        price: 2099,
    },
    {
        id: 142,
        price: 549,
    },
    {
        id: 143,
        price: 399,
    },
    {
        id: 144,
        price: 349,
    },
    {
        id: 145,
        price: 299,
    },
    {
        id: 146,
        price: 3299,
    },
    {
        id: 147,
        price: 2249,
    },
    {
        id: 148,
        price: 1699,
    },
    {
        id: 149,
        price: 1459,
    },
    {
        id: 150,
        price: 1099,
    },
    {
        id: 151,
        price: 849,
    },
    {
        id: 152,
        price: 899,
    },
    {
        id: 153,
        price: 699,
    },
    {
        id: 154,
        price: 699,
    },
    {
        id: 155,
        price: 999,
    },
    {
        id: 158,
        price: 599,
    },
    {
        id: 165,
        price: 4999,
    },
    {
        id: 166,
        price: 849,
    },
    {
        id: 167,
        price: 5999,
    },
    {
        id: 168,
        price: 4999,
    },
    {
        id: 181,
        price: 4999,
    },
    {
        id: 182,
        price: 3499,
    },
    {
        id: 183,
        price: 2999,
    },
    {
        id: 184,
        price: 2499,
    },
    {
        id: 185,
        price: 11999,
    },
    {
        id: 186,
        price: 6999,
    },
    {
        id: 188,
        price: 999,
    },
    {
        id: 189,
        price: 1699,
    },
    {
        id: 190,
        price: 1449,
    },
    {
        id: 191,
        price: 7799,
    },
    {
        id: 192,
        price: 1699,
    },
    {
        id: 193,
        price: 1199,
    },
    {
        id: 195,
        price: 399,
    },
    {
        id: 196,
        price: 1299,
    },
    {
        id: 197,
        price: 349,
    },
    {
        id: 198,
        price: 399,
    },
    {
        id: 199,
        price: 349,
    },
    {
        id: 200,
        price: 2099,
    },
    {
        id: 201,
        price: 2099,
    },
    {
        id: 202,
        price: 349,
    },
    {
        id: 203,
        price: 1699,
    },
    {
        id: 204,
        price: 1299,
    },
    {
        id: 205,
        price: 649,
    },
    {
        id: 206,
        price: 699,
    },
    {
        id: 207,
        price: 399,
    },
    {
        id: 208,
        price: 399,
    },
    {
        id: 209,
        price: 1699,
    },
    {
        id: 210,
        price: 6999,
    },
    {
        id: 211,
        price: 3449,
    },
    {
        id: 212,
        price: 8699,
    },
    {
        id: 213,
        price: 6999,
    },
    {
        id: 214,
        price: 5199,
    },
    {
        id: 215,
        price: 3449,
    },
    {
        id: 216,
        price: 2999,
    },
    {
        id: 217,
        price: 699,
    },
    {
        id: 218,
        price: 1699,
    },
    {
        id: 219,
        price: 399,
    },
    {
        id: 220,
        price: 5199,
    },
    {
        id: 221,
        price: 999,
    },
    {
        id: 222,
        price: 6099,
    },
    {
        id: 223,
        price: 6499,
    },
    {
        id: 224,
        price: 6999,
    },
    {
        id: 225,
        price: 2999,
    },
    {
        id: 226,
        price: 8699,
    },
    {
        id: 227,
        price: 12099,
    },
    {
        id: 228,
        price: 12799,
    },
    {
        id: 229,
        price: 3499,
    },
];
