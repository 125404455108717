import { useDispatch, useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import useSendWink from '../wink/useSendWink';
import useLike from '../likes/useLike';
import useActiveButton from '../activeBtn/useActiveButton';
import useHandleActiveBlocks from '../user/useHandleActiveBlocks';
import { RootState } from '../../index';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { IWomanGalleryImage } from '../../store/actions/modals/index.interface';
import MailboxApi from '../../services/api/MailboxApi';
import * as loaderAction from '../../store/actions/loader';
import * as MENU from '../../constants/menu';
import * as modalsAction from '../../store/actions/modals';
import VimeoApi from '../../services/api/VimeoApi';
import ProfileApi from '../../services/api/ProfileApi';
import { IOnlineNowItem } from '../../store/reducers/onlineNow/index.interface';
import useOutsideClicker from '../dom/useOutsideClicker';
import useOpenVideoShow from '../search/useOpenVideoShow';

interface IUseHandleOnlineNowItem {
    womanProfile: IOnlineNowItem;
    openGalleryModal: (externalID: number, images: IWomanGalleryImage[], index?: number) => void;
    askVideo: (chat_uid: string, external_id: number, is_blocked: number) => void;
    openChat: (chatUid: string | null, is_blocked: number) => void;
    openLetter: (profile: any) => void;
}

export default function useHandleOnlineNowItem({
    womanProfile,
    openGalleryModal,
    askVideo,
    openChat,
    openLetter,
}: IUseHandleOnlineNowItem) {
    const dispatch = useDispatch();
    const transition = useTransition();
    const sendWink = useSendWink();
    const like = useLike();
    const activeButton = useActiveButton();
    const handleActiveBlocks = useHandleActiveBlocks();
    const { openVideo } = useOpenVideoShow(womanProfile?.external_id);

    const videoShowState = useSelector((state: RootState) => state.videoShow);
    const userState = useSelector((state: RootState) => state.user);

    const [sortedFreeImages, setSortedFreeImages] = useState<IWomanGalleryImage[]>([]);
    const [videoPoster, setVideoPoster] = useState('');
    const [isHelloOpen, setIsHelloOpen] = useState(false);

    const sayHelloBtn = useRef(null);

    const girlWithVideo = videoShowState?.list?.find(
        (e) => +e?.external_id === +womanProfile.external_id,
    );
    const isVideo = !!(handleActiveBlocks.isVideoShow && girlWithVideo);

    const changeIsHelloOpen = () => setIsHelloOpen(!isHelloOpen);
    const closeIsHelloOpen = () => setIsHelloOpen(false);

    useOutsideClicker(sayHelloBtn, closeIsHelloOpen);

    const getChatUId = async () => {
        if (womanProfile?.chat_uid) return womanProfile?.chat_uid;

        if (userState?.info?.external_id) {
            const res = await new MailboxApi().createChatWithProfile(
                userState?.info?.external_id,
                womanProfile?.external_id,
            );

            if (!res?.status && !res?.chat_uid) return null;

            return res?.chat_uid;
        }
    };

    const onAskVideo = async () => {
        const chatUID = await getChatUId();
        askVideo(chatUID, womanProfile?.external_id, womanProfile?.is_blocked);
    };
    const onOpenChat = async () => {
        const chatUID = await getChatUId();
        openChat(chatUID, womanProfile?.is_blocked);
    };

    const onSetLike = async () => like(womanProfile?.external_id);

    const onSendWink = async (helloText: string) => {
        if (activeButton.onBlockBtn()) return false;
        dispatch(loaderAction.setActiveGifLoader(true));
        const chatUID = await getChatUId();
        dispatch(loaderAction.setActiveGifLoader(false));
        transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${chatUID}`);
        await sendWink({
            id: womanProfile?.external_id,
            helloText,
        });
    };

    const onOpenLetter = async () => openLetter(womanProfile);

    const onClickPhoto = (id = 0) =>
        openGalleryModal(womanProfile?.external_id, sortedFreeImages, id);

    const onClickGalleryBtn = () => {
        openGalleryModal(womanProfile?.external_id, womanProfile?.images);
    };

    const redirectProfile = (e: MouseEvent<HTMLDivElement>) => {
        if (!womanProfile?.external_id) return;
        e.stopPropagation();
        e.preventDefault();

        dispatch(modalsAction.openWomanProfileModal(womanProfile?.external_id));
    };

    useEffect(() => {
        if (womanProfile?.images?.length) {
            setSortedFreeImages(
                womanProfile?.images
                    .sort((a, b) => (b?.landscape > a?.landscape ? 1 : -1))
                    .sort((a, b) => (b?.free > a?.free ? 1 : -1)),
            );
        }
    }, [womanProfile?.images]);

    useMemo(() => {
        if (girlWithVideo?.preview_link?.length) {
            setVideoPoster(girlWithVideo?.preview_link);
        } else {
            if (+girlWithVideo?.thumbnail_id) {
                new VimeoApi().getThumbnail(girlWithVideo?.thumbnail_id).then((vimeoRes) => {
                    setVideoPoster(vimeoRes?.data[0]?.sizes[3]?.link ?? '');

                    new ProfileApi().showNewVimeoThumb(
                        girlWithVideo?.id,
                        vimeoRes?.data[0]?.sizes[3]?.link ?? '',
                    );
                });
            }
        }
    }, [girlWithVideo?.preview_link]);

    return {
        closeIsHelloOpen,
        sortedFreeImages,
        userInfo: userState?.info,
        redirectProfile,
        onOpenChat,
        onClickPhoto,
        onSetLike,
        onClickGalleryBtn,
        isVideo,
        videoPoster,
        onAskVideo,
        isHelloOpen,
        changeIsHelloOpen,
        onSendWink,
        onOpenLetter,
        girlWithVideo,
        sayHelloBtn,
        openVideo,
    };
}
