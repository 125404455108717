import { getCookie } from '../../services/methods';
import FBConversion from '../../services/api/FBConversion';
import { useDispatch, useSelector } from 'react-redux';
import * as userAction from '../../store/actions/user';
import { RootState } from '../../index';

export default function useFBConversion() {
    const userState = useSelector((state: RootState) => state.user);
    // //GT-13889
    const hasUtmSourceFacebook = userState?.tail?.includes('utm_source=facebook');
    const isTestEmail = userState?.info?.email?.includes('test');

    const dispatch = useDispatch();

    // init before we get user info
    const checkFBCapi = () => {
        new FBConversion().getCheckFBCapi().then((res) => {
            if (res?.status && res?.response?.status) {
                dispatch(userAction.setIsFBConversion(res?.response?.status));
            }
        });
    };

    const sentFBAuth = () => {
        if (isTestEmail) return;
        if (hasUtmSourceFacebook && userState?.isFBConversion && userState?.info?.email) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';

            new FBConversion().sentFBAuth(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                fbp,
                fbc,
            );
        }
    };

    const sentFBAuth55 = () => {
        if (isTestEmail) return;
        if (hasUtmSourceFacebook && userState?.isFBConversion && userState?.info?.email) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';

            new FBConversion().sentFBAuth55(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                fbp,
                fbc,
            );
        }
    };

    const sentFBDelfinConversion = () => {
        if (isTestEmail) return;
        if (hasUtmSourceFacebook && userState?.isFBConversion && userState?.info?.email) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';

            new FBConversion().sentFBDelfinConversion(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                fbp,
                fbc,
            );
        }
    };

    const sentFBConfirmEmailConversion = () => {
        if (isTestEmail) return;
        if (hasUtmSourceFacebook && userState?.isFBConversion && userState?.info?.email) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';

            new FBConversion().sentFBConfirmEmailConversion(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                fbp,
                fbc,
            );
        }
    };

    const sentFBFirstPurchase = () => {
        if (isTestEmail) return;
        if (hasUtmSourceFacebook && userState?.isFBConversion && userState?.info?.email) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';

            new FBConversion().sentFBFirstPurchase(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                fbp,
                fbc,
            );
        }
    };

    const sentFBTwoMorePurchase = () => {
        if (isTestEmail) return;
        if (hasUtmSourceFacebook && userState?.isFBConversion && userState?.info?.email) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';

            new FBConversion().sentFBTwoMorePurchase(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                fbp,
                fbc,
            );
        }
    };

    const sentFBSpendAllCredits = () => {
        if (isTestEmail) return;
        if (hasUtmSourceFacebook && userState?.isFBConversion && userState?.info?.email) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';

            new FBConversion().sentFBSpendAllCredits(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                fbp,
                fbc,
            );
        }
    };

    const sentFBEachPurchase = (price: number) => {
        if (!hasUtmSourceFacebook || !userState?.info || isTestEmail) return;

        const summaryPrice = parseFloat((price + userState?.info?.payment_total).toFixed(2));
        //GT-14192. In this case, do not check for isFBConversion
        if (userState?.info?.age >= 35) {
            new FBConversion().sentFBEachPurchase(
                userState?.info?.email,
                userState?.info?.external_id,
                userState?.info?.name,
                userState?.info?.b_year,
                userState?.info?.b_month,
                userState?.info?.b_day,
                summaryPrice,
            );
        }
    };

    return {
        sentFBAuth,
        sentFBAuth55,
        sentFBDelfinConversion,
        sentFBConfirmEmailConversion,
        sentFBFirstPurchase,
        sentFBTwoMorePurchase,
        sentFBSpendAllCredits,
        checkFBCapi,
        sentFBEachPurchase,
    };
}
