import React from 'react';
import AuthHeader from './AuthHeader';
import AuthRegistration from './AuthRegistration';
import AuthQuestions from './AuthQuestions';
import AuthFooter from './AuthFooter';
import { IAuth } from '../../../components/Auth/index.interface';

export default function Auth({
    initLogin,
    initRegister,
    openSupportModal,
    openTermModal,
    openAffiliatsModal,
}: IAuth) {
    return (
        <>
            <AuthHeader
                openSignInBlock={initLogin.openSignInBlock}
                openSupportModal={openSupportModal}
                postResponseGoogle={initRegister.postResponseGoogle}
            />
            <AuthRegistration openTermModal={openTermModal} {...initRegister} {...initLogin} />
            <AuthQuestions />
            <AuthFooter openTermModal={openTermModal} openAffiliatsModal={openAffiliatsModal} />
        </>
    );
}
