import { MailboxActionTypes } from '../../constants/mailbox';
import moment from 'moment';
import { IMailboxState, MailboxActions } from './index.interface';
import { IMailsAttachment } from '../../../services/api/MailboxApi.interface';

const initState = {
    info: {},

    history: [],
    mailChatInfo: {},
    specialLetterId: null,
    bookmarkLetterId: null,
    historyAllPages: null,
    historyCurrentPage: null,

    draftId: null,
    isNewMessage: false,
};

export default function (state: IMailboxState = initState, action: MailboxActions): IMailboxState {
    switch (action.type) {
        case MailboxActionTypes.REMOVE_MAILBOX_CHAT:
            return initState;

        case MailboxActionTypes.FETCH_MAILBOX_GET:
            return {
                ...state,
                ...{
                    historyAllPages: null,
                    historyCurrentPage: null,
                    history: [],
                    mailChatInfo: {},
                    lastMessageId: '',
                    specialLetterId: null,
                },
            };

        case MailboxActionTypes.FETCH_MAILBOX_GET_SEND_MESS:
            return {
                ...state,
                ...{
                    history: [],
                },
            };

        case MailboxActionTypes.CURRENT_LETTER_ROOM_PAID_LETTER_CONTENT:
            return {
                ...state,
                ...{
                    history: state.history.map((item) => {
                        const isAttachment = item?.attachments?.some(
                            (attachment: IMailsAttachment) =>
                                attachment?.attachment_id === action?.contentID,
                        );
                        if (+item?.mail?.id === +action?.letterID && isAttachment) {
                            return {
                                ...item,
                                attachments: item?.attachments?.map(
                                    (attachment: IMailsAttachment) => {
                                        if (attachment?.attachment_id === action?.contentID) {
                                            return {
                                                ...attachment,
                                                attachment_payed: 1,
                                                link: action?.content,
                                                ma_date_viewed: moment()
                                                    .utc()
                                                    .format('YYYY-MM-DD HH:mm:ss'),
                                            };
                                        }
                                        return attachment;
                                    },
                                ),
                            };
                        }
                        return item;
                    }),
                },
            };

        case MailboxActionTypes.MAILBOX_SET_READ_LETTER:
            return {
                ...state,
                ...{
                    history: state.history.map((item) => ({
                        ...item,
                        mail: { ...item?.mail, mail_read_status: '1' },
                    })),
                },
            };

        case MailboxActionTypes.MAILBOX_SET_PAYED_READ_LETTER:
            return {
                ...state,
                ...{
                    history: state.history.map((item) => {
                        if (+action?.mailID !== +item?.mail?.id) return item;

                        return {
                            ...item,
                            mail: {
                                ...item?.mail,
                                paid_read: '1',
                                read_status: '1',
                                message_content: action?.content,
                            },
                        };
                    }),
                },
            };

        case MailboxActionTypes.SET_IS_NEW_MESS:
            return {
                ...state,
                ...{
                    isNewMessage: action.data,
                },
            };

        case MailboxActionTypes.SET_INFO_CURRENT_TARGET:
            return {
                ...state,
                ...{
                    info: { ...state.info, ...{ actionTarget: action.data } },
                },
            };

        case MailboxActionTypes.MAILBOX_SET_FAVORITE:
            return {
                ...state,
                ...{
                    mailChatInfo: {
                        ...state.mailChatInfo,
                        ...{ female_favorite: action?.value ? 1 : 0 },
                    },
                    info: {
                        ...state.info,
                        ...{ is_favorite: action?.value ? 1 : 0 },
                    },
                },
            };

        case MailboxActionTypes.MAILBOX_SET_LIKE:
            return {
                ...state,
                ...{
                    mailChatInfo: {
                        ...state.mailChatInfo,
                        ...{ female_like: +state.mailChatInfo.female_like === 0 ? '1' : '0' },
                    },
                },
            };

        case MailboxActionTypes.MAILBOX_ADD_DRAFT_ID:
            return { ...state, ...{ draftId: action.data } };

        case MailboxActionTypes.MAILBOX_REMOVE_DRAFT_ID:
            return { ...state, ...{ draftId: null } };

        case MailboxActionTypes.MAILBOX_CHAT_ADD_SPECIAL_LETTER:
            return {
                ...state,
                ...{
                    specialLetterId: action.data,
                },
            };

        case MailboxActionTypes.MAILBOX_CHAT_ADD_BOOKMARK_LETTER:
            return {
                ...state,
                ...{
                    bookmarkLetterId: action.data,
                },
            };

        case MailboxActionTypes.MAILBOX_CHAT_ADD_INFO:
            return {
                ...state,
                ...{
                    info: action?.data ?? {},
                    specialLetterId: null,
                    draftId: null,
                },
            };

        case MailboxActionTypes.MAILBOX_CHAT_SET_INFO_OPEN_FROM_CHAT:
            return {
                ...state,
                ...{
                    info: { ...state?.info, openFromChat: action.isOpenFromChat },
                },
            };

        case MailboxActionTypes.MAILBOX_CHAT_CLEAR_INFO:
            return {
                ...state,
                ...{
                    info: {},
                },
            };

        case MailboxActionTypes.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP:
            if (action?.data?.mails?.length !== 0) {
                return {
                    ...state,
                    ...{
                        history: [...(action?.data?.mails || []), ...(state?.history || [])],
                    },
                };
            } else {
                return state;
            }

        case MailboxActionTypes.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN:
            if (action?.data?.mails?.length !== 0) {
                return {
                    ...state,
                    ...{
                        history: [...state.history, ...action.data.mails],
                    },
                };
            } else {
                return state;
            }

        case MailboxActionTypes.MAILBOX_CHAT_GET_HISTORY:
            return {
                ...state,
                ...{
                    historyAllPages: action.data.pages,
                    historyCurrentPage: action.data.page,
                    history: action.data.mails,
                    mailChatInfo: action.data.chat,
                    specialLetterId: action?.data?.mails?.length
                        ? action?.data?.mails[action?.data?.mails?.length - 1]?.mail?.id || null
                        : null,
                    info: { ...state?.info, online: action?.data?.womanInfo?.online },
                },
            };

        case MailboxActionTypes.MAILBOX_CHAT_GET_HISTORY_FORCE:
            return {
                ...state,
                ...{
                    history: action.data.mails,
                    isNewMessage: false,
                },
            };

        case MailboxActionTypes.MAILBOX_CHAT_GET_HISTORY_SEND_MESS:
            return {
                ...state,
                ...{
                    history: action.data.mails,
                    specialLetterId: action?.data?.mails?.length
                        ? action?.data?.mails[action?.data?.mails?.length - 1]?.mail?.id || null
                        : null,
                },
            };

        case MailboxActionTypes.SET_CURRENT_CHAT_MAILBOX_ONLINE_STATUS: {
            return {
                ...state,
                ...{
                    info: {
                        ...state?.info,
                        ...{
                            sender_online: action?.status,
                            recipient_online: action?.status,
                        },
                    },
                },
            };
        }

        case MailboxActionTypes.SET_CURRENT_CHAT_MAILBOX_LIKE_STATUS: {
            return {
                ...state,
                ...{
                    info: {
                        ...state?.info,
                        ...{
                            sender_online: action?.status,
                            recipient_online: action?.status,
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
}
