import React from 'react';
import * as modalsAction from '../../../../../store/actions/modals';
import styles from '../styles.module.scss';
import { makeCDN } from '../../../../../services/methods';
import { useDispatch } from 'react-redux';

export default function MediaListPhoto({
    message_content,
    message_popup,
}: {
    message_content: string;
    message_popup: string;
}) {
    const dispatch = useDispatch();

    const openContentModal = () => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: message_popup,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <div className={`${styles.media_list_item} ${styles.photo}`}>
            <img
                alt="photo"
                src={makeCDN(message_content, 'IMAGE')}
                className={styles.media_list_item_img}
                onClick={openContentModal}
            />
        </div>
    );
}
