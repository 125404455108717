export enum SearchActionTypes {
    INIT_SEARCH = 'INIT_SEARCH',
    GET_SEARCH_LIST = 'GET_SEARCH_LIST',
    SET_SEARCH_LIST_PAGE = 'SET_SEARCH_LIST_PAGE',
    SET_SEARCH_LIST_FILTER = 'SET_SEARCH_LIST_FILTER',
    SET_POPULAR_COUNTRY_LIST = 'SET_POPULAR_COUNTRY_LIST',
    SET_SEARCH_LIST_SUBSCRIBE_STATUS = 'SET_SEARCH_LIST_SUBSCRIBE_STATUS',
    SET_SEARCH_ONLINE_STATUS = 'SET_SEARCH_ONLINE_STATUS',
    SET_SEARCH_LIKE_STATUS = 'SET_SEARCH_LIKE_STATUS',
    SET_IS_LOADING_PAGE = 'SET_IS_LOADING_PAGE',
}
