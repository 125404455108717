import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import NotifyList from './NotifyList';
import * as notifyAction from '../../../store/actions/notify';
import * as TIMER from '../../../constants/timer';
import { closePanel } from '../../../services/dom';
import useTransition from '../../../hooks/transition/useTransition';
import * as MENU from '../../../constants/menu';
import GirlsList from './GirlsList';
import QuizNotify from './QuizNotify';
import useInitSound from '../../../hooks/sound/useInitSound';
import TransactionApi from '../../../services/api/v3/TransactionApi';
import bottomStyles from './bottom.module.scss';
import useInitSupport from '../../../hooks/support/useInitSupport';
import SupportModal from '../Modals/SupportModal';
import SITE from '../../../config/site';
// import * as SUPPORT from '../../../constants/support';
import useHandleMobileChat from '../../../hooks/chat/helpers/useHandleMobileChat';
import useHandleActiveBlocks from '../../../hooks/user/useHandleActiveBlocks';
import IconFAQ from '../../Icons/IconFAQ';
import IconMute from '../../Icons/IconMute';
import IconMuted from '../../Icons/IconMuted';
import IconLiveChat from '../../Icons/IconLiveChat';
import IconLetters from '../../Icons/IconLetters';
import IconContact from '../../Icons/IconContact';
import IconTutorial from '../../Icons/IconTutorial';
import IconCross from '../../Icons/IconCross';
import useHandleZendesk from '../../../hooks/ze/useHandleZendesk';
import { localStorageGetItem, localStorageSetItem } from '../../../utils/local-storage';
import { RootState } from '../../../index';
import { isHolidayActive } from '../../../constants/holiday';
import SupportApi from '../../../services/api/SupportApi';

const Notify = ({ openTutorialModal }: { openTutorialModal: () => void }) => {
    const dispatch = useDispatch();
    const transition = useTransition();
    const initSupport = useInitSupport();
    const selectRef = useRef(null);

    const userState = useSelector((state: RootState) => state.user);
    const notifyState = useSelector((state: RootState) => state.notify);
    const settingsState = useSelector((state: RootState) => state.settings);
    const { list, status } = notifyState;
    const { info } = userState;
    const { isActiveSound, setSoundNotification } = useInitSound();
    const handleActiveBlocks = useHandleActiveBlocks();
    const { zendeskRequestCall, openZendesk, isNewZendeskWidget } = useHandleZendesk();
    const activeHoliday = isHolidayActive();

    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [supportPhone, setSupportPhone] = useState('');

    const handleIsHelpOpen = () => {
        if (!isHelpOpen && !supportPhone) {
            new SupportApi().getBrandInfo().then((res) => {
                if (res?.status) {
                    setSupportPhone(res?.response?.phone);
                }
            });
        }
        setIsHelpOpen(!isHelpOpen);
    };

    const deleteNotification = (
        e: React.MouseEvent<HTMLButtonElement>,
        chatUid: string,
        notifyType = 'chat',
    ) => {
        e.stopPropagation();
        localStorageSetItem('open-chat-banner', chatUid, true);
        info && dispatch(notifyAction.deleteNotify(info.external_id, chatUid, notifyType));
    };

    const handleMobileChat = useHandleMobileChat();

    const openChat = async (
        chatUid: string | null,
        notifyId: number,
        girlExternalId: number,
        notifyInfo: any,
    ) => {
        if (notifyInfo?.message_id && !+notifyInfo?.status && notifyId) {
            await new TransactionApi().setActiveMessage(notifyInfo?.message_id);
        }

        if (chatUid) {
            closePanel();
            userState?.info &&
                dispatch(notifyAction.deleteNotify(userState.info.external_id, chatUid));
            transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${chatUid}`);
        }
    };

    const openLetter = (chatUid: string) => {
        if (chatUid) {
            closePanel();
            userState?.info &&
                dispatch(
                    notifyAction.deleteNotify(userState?.info?.external_id, chatUid, 'letter'),
                );
            transition.transitionWithOnlyHistoryPush(`/letter/${chatUid}`);
        }
    };

    // const switchPhoneNumber = () => {
    //     switch (info?.country_code) {
    //         case 'US':
    //             return { phone: SUPPORT.SUPPORT_PHONE_US, code: 'US' };
    //         case 'CA':
    //             return { phone: SUPPORT.SUPPORT_PHONE_CA, code: 'CA' };
    //         case 'GB':
    //             return { phone: SUPPORT.SUPPORT_PHONE_GB, code: 'GB' };
    //         default:
    //             return { phone: SUPPORT.SUPPORT_PHONE_US, code: 'US' };
    //     }
    // };

    useEffect(() => {
        const timer = setInterval(() => {
            const storageChatData = localStorageGetItem<string>('open-chat-banner', true);

            if (storageChatData) {
                closePanel();
                setTimeout(
                    () =>
                        info &&
                        dispatch(notifyAction.deleteNotify(info.external_id, storageChatData)),
                    3000,
                );
                dispatch(notifyAction.getCheck());
            }
        }, TIMER.CHECK_NOTICE_EXP_DATE);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useMemo(() => {
        const timer = setInterval(() => {
            localStorageSetItem('open-chat-banner', '');
            dispatch(notifyAction.checkNotify());
        }, TIMER.CHECK_NOTICE_EXP_DATE);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div
            className={`column-4 ${handleMobileChat.isNewMobileChat && handleMobileChat.isActiveNewMobileChat ? 'hide_for_new_mobile_chat' : ''} ${activeHoliday && activeHoliday?.name}`}
            ref={selectRef}
            data-testid="notification-block"
        >
            <Header />
            {status ? (
                <NotifyList
                    list={list}
                    deleteNotification={deleteNotification}
                    openChat={openChat}
                    openLetter={openLetter}
                />
            ) : (
                <div />
            )}
            <GirlsList />
            <div className={bottomStyles.bottom_buttons_wrap}>
                <div className={bottomStyles.buttons_row}>
                    <button
                        type="button"
                        className={bottomStyles.help_btn}
                        onClick={handleIsHelpOpen}
                        data-testid="help-btn"
                    >
                        <IconFAQ />
                        <span>Help</span>
                    </button>
                    <button
                        type="button"
                        className={bottomStyles.mute_btn}
                        onClick={setSoundNotification}
                        data-testid="mute-btn"
                    >
                        {isActiveSound ? <IconMute /> : <IconMuted />}
                        {isActiveSound ? (
                            <span>
                                Mute <span>notifications</span>
                            </span>
                        ) : (
                            <span>
                                Unmute <span>notifications</span>
                            </span>
                        )}
                    </button>
                </div>
                <div
                    className={`${bottomStyles.bottom_all_buttons_wrap} ${isHelpOpen ? bottomStyles.opened : ''}`}
                    data-testid="support-block"
                >
                    <h3 className={bottomStyles.opened_title}>
                        <IconFAQ />
                        <span>Support</span>
                    </h3>
                    {!!(info && info?.payment_total > 0 && settingsState?.isActiveCallButton) && (
                        <p className={bottomStyles.opened_text}>
                            Customer Support numbers:
                            <br /> US{' '}
                            {supportPhone?.length ? (
                                <a
                                    className={bottomStyles.opened_phone_link}
                                    href={`tel:${supportPhone.split(' ').join('')}`}
                                    rel="noopener noreferrer"
                                >
                                    {supportPhone}
                                </a>
                            ) : (
                                '+* *** *** ****'
                            )}
                        </p>
                    )}
                    <p className={bottomStyles.opened_text}>
                        Choose a communication method that is convenient for you
                    </p>
                    <ul className={bottomStyles.opened_buttons_col}>
                        {info && info?.payment_total > 0 && (
                            <li className={bottomStyles.opened_btn_wrap}>
                                <button
                                    type="button"
                                    className={`${bottomStyles.opened_col_btn} ${bottomStyles.email}`}
                                    onClick={openZendesk}
                                    data-testid="live-chat-btn"
                                >
                                    <IconLiveChat />
                                    <span>Live chat</span>
                                </button>
                            </li>
                        )}
                        <li className={bottomStyles.opened_btn_wrap}>
                            <button
                                type="button"
                                className={`${bottomStyles.opened_col_btn} ${bottomStyles.email}`}
                                onClick={initSupport.openSupportModal}
                                data-testid="email-btn"
                            >
                                <IconLetters />
                                <span>Email</span>
                            </button>
                        </li>

                        {!!(
                            info &&
                            info?.payment_total > 0 &&
                            settingsState?.isActiveCallButton &&
                            !isNewZendeskWidget
                        ) && (
                            <li className={bottomStyles.opened_btn_wrap}>
                                <button
                                    type="button"
                                    className={`${bottomStyles.opened_col_btn} ${bottomStyles.call}`}
                                    onClick={zendeskRequestCall}
                                    data-testid="request-call-btn"
                                >
                                    <IconContact />
                                    <span>Request call</span>
                                </button>
                            </li>
                        )}
                    </ul>
                    <ul className={bottomStyles.opened_buttons_row}>
                        {!!(SITE.ID !== 4 && !handleActiveBlocks.isTwitterUser) && (
                            <li className={bottomStyles.opened_btn_start_wrap}>
                                <button
                                    type="button"
                                    className={`${bottomStyles.opened_row_btn} ${bottomStyles.start}`}
                                    onClick={openTutorialModal}
                                    data-testid="how-to-start-btn"
                                >
                                    <IconTutorial />
                                    <span>How to Start</span>
                                </button>
                            </li>
                        )}
                        <li className={bottomStyles.opened_btn_help_wrap}>
                            <button
                                type="button"
                                className={`${bottomStyles.opened_row_btn} ${bottomStyles.help}`}
                                onClick={handleIsHelpOpen}
                                data-testid="support-help-btn"
                            >
                                <IconFAQ />
                                <span>Help</span>
                            </button>
                        </li>
                    </ul>
                    <button
                        type="button"
                        className={bottomStyles.close}
                        onClick={handleIsHelpOpen}
                        data-testid="close-btn"
                    >
                        <IconCross />
                    </button>
                </div>
            </div>

            <QuizNotify />
            <SupportModal {...initSupport} />
            <div className="column_4_overlay" />
        </div>
    );
};

export default memo(Notify);
