import React from 'react';
import GiftsCartRecipient from '../GiftsCartRecipient';
import * as SEX from '../../../constants/sex';
import { useSelector } from 'react-redux';
import * as LENGTH from '../../../constants/length';
import { RootState } from '../../../index';
import { ICart } from '../../../store/reducers/gifts/index.interface';

interface IGiftsCartOrder {
    cart: ICart;
    totalPrice: number;
    giftMessage: string;
    handleGiftMessage: (value: string) => void;
    giftPreferences: string;
    handleGiftPreferences: (value: string) => void;
    submitBuyGifts: () => void;
    cartErrorMessage: string;
    isDisabledBuyBtn: boolean;
}

export default function GiftsCartOrder({
    cart,
    totalPrice,
    giftMessage,
    handleGiftMessage,
    giftPreferences,
    handleGiftPreferences,
    submitBuyGifts,
    cartErrorMessage,
    isDisabledBuyBtn,
}: IGiftsCartOrder) {
    const userState = useSelector((state: RootState) => state.user);

    return (
        <div className="c3gp_gifts_cart_main_order_wrap">
            <GiftsCartRecipient />
            <div className="c3gp_gifts_cart_main_order_messages_wrap">
                <div className="c3gp_gifts_cart_main_order_message_item">
                    <div className="c3gp_gifts_cart_main_order_message_item_title">
                        Message for the{' '}
                        {
                            SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
                                .GIFT_MODAL_MESSAGE_TITLE
                        }
                    </div>
                    <div className="c3gp_gifts_cart_main_order_message_item_textarea">
                        <textarea
                            value={giftMessage}
                            onChange={(e) => handleGiftMessage(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="c3gp_gifts_cart_main_order_message_item_info">
                        The{' '}
                        {
                            SEX.SEX_TEXT[userState?.info?.sex_text as keyof typeof SEX.SEX_TEXT]
                                .GIFT_MODAL_MESSAGE_DESCRIPTION
                        }{' '}
                        will receive this gift with your printed message (maximum{' '}
                        {LENGTH.GIFT_MESSAGE_MAX_LENGTH} symbols) on a greeting card
                    </div>
                </div>
                <div className="c3gp_gifts_cart_main_order_message_item">
                    <div className="c3gp_gifts_cart_main_order_message_item_title">
                        Special gift giving preferences
                    </div>
                    <div className="c3gp_gifts_cart_main_order_message_item_textarea">
                        <textarea
                            value={giftPreferences}
                            onChange={(e) => handleGiftPreferences(e?.target?.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="c3gp_gifts_cart_main_order_results">
                <div className="c3gp_gifts_cart_main_order_results_price">
                    Total gift price<span>{totalPrice} credits </span>
                </div>
                <div className="c3gp_gifts_cart_main_order_results_buy_btn_wrap">
                    <div
                        className={`c3gp_gifts_cart_main_order_results_buy_btn ${!cart?.recipient || !totalPrice || isDisabledBuyBtn ? 'disabled' : ''}`}
                        onClick={submitBuyGifts}
                    >
                        <img src="../../../img/gifts/check-circle-white.svg" alt="Buy gifts" />
                        <span>Buy gifts</span>
                    </div>
                </div>
            </div>
            <div className="c3gp_gifts_cart_main_order_results_error">
                {cartErrorMessage ? <span>{cartErrorMessage}</span> : ''}
            </div>
        </div>
    );
}
