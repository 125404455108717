import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import Modal from 'react-modal';
import * as modalsAction from '../../../../store/actions/modals';
import PhotoContent from './PhotoContent';
import { RootState } from '../../../../index';

export default function PhotoContentModal() {
    const dispatch = useDispatch();
    const modalsState = useSelector((state: RootState) => state.modals);

    const onClosePhotoContentModal = () => {
        dispatch(modalsAction.closePhotoContentModal());
    };

    return (
        <Modal
            style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
            isOpen={modalsState?.photoContentModal?.isActive}
            onRequestClose={onClosePhotoContentModal}
            ariaHideApp={false}
            testId={'view-photo-modal'}
        >
            {!!modalsState?.photoContentModal?.isActive && (
                <PhotoContent
                    item={modalsState?.photoContentModal?.item}
                    closeModal={onClosePhotoContentModal}
                />
            )}
        </Modal>
    );
}
