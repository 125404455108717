import React from 'react';
import { INewMessageInChatButtonProps } from './index.interface';
import IconArrowDown from '../../../Icons/IconArrowDown';

export default function NewMessageInChatButton({
    title,
    isActiveButton,
    isLetterFormOpen,
    onPressButton,
}: INewMessageInChatButtonProps) {
    const isLetterFormOpenStyle = isLetterFormOpen ? 'is_form_open' : '';
    return (
        <div
            className={`new_message_btn ${isActiveButton ? 'active' : ''} ${isLetterFormOpenStyle}`}
            onClick={onPressButton}
        >
            <IconArrowDown />
            {title}
        </div>
    );
}
