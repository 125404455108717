import Base from '../Base';

export default class ContactsApi extends Base {
    async getUserContactRequestInfo() {
        try {
            const res = await super.get(`v3/contact-request/first`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async saveRealName(realName: string) {
        try {
            const res = await super.put(`v3/user-profile/real-name`, { realName });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async sendVerifyStatus() {
        try {
            const res = await super.post(`v3/user-profile/verify`);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async checkImbraStatus(womanExternalID: number) {
        try {
            const res = await super.post(`v3/contact-request/imbra`, { womanExternalID });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async saveImbraFile(formData: FormData) {
        try {
            const res = await super.post(`v3/contact-request/imbra/file`, formData);
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async sendContacts(phone: string) {
        try {
            const res = await super.post(`v3/sms/send`, { phone });
            return res?.data;
        } catch {
            return { status: false };
        }
    }

    async verifyContacts(code: string) {
        try {
            const res = await super.post(`v3/sms/verify`, { code });
            return res?.data;
        } catch {
            return { status: false };
        }
    }
}
