import { combineReducers } from 'redux';

import chats from './chats';
import user from './user';
import currentChat from './currentChat';
import config from './config';
import files from './files';
import notify from './notify';
import menu from './menu';
import mailbox from './mailbox';
import currentChatMailbox from './currentChatMailbox';
import letterFilter from './letterFilter';
import likes from './likes';
import gifts from './gifts';
import search from './search';
import alert from './alert';
import counters from './counters';
import loader from './loader';
import sync from './sync';
import requests from './requests';
import modals from './modals';
import purchase from './purchase';
import woman from './woman';
import referencies from './referencies';
import allMedia from './allMedia';
import wink from './wink';
import transaction from './transaction';
import settings from './settings';
import quiz from './quiz';
import connection from './connection';
import faq from './faq';
import tutorial from './tutorial';
import terms from './terms';
import banners from './banners';
import virtualGifts from './virtualGifts';
import posts from './posts';
import videoShow from './videoShow';
import onlineNow from './onlineNow';
import profile from './profile';
import statusNotify from './statusNotify';

export default combineReducers({
    terms,
    connection,
    quiz,
    settings,
    transaction,
    wink,
    referencies,
    woman,
    purchase,
    chats,
    user,
    currentChat,
    files,
    notify,
    menu,
    mailbox,
    currentChatMailbox,
    letterFilter,
    likes,
    gifts,
    search,
    alert,
    counters,
    loader,
    sync,
    requests,
    modals,
    allMedia,
    config,
    faq,
    tutorial,
    banners,
    virtualGifts,
    posts,
    videoShow,
    onlineNow,
    profile,
    statusNotify,
});
