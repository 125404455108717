export function hideMobBottomNav(): void {
    if (window.innerHeight < 300) {
        const mobBottomNav = document.querySelector('.mob_bottom_nav');
        if (mobBottomNav) mobBottomNav.classList.add('mob-hide');
    }
}

export function redirectToMobileVersion(accessKey = '', pathname = '/chat'): void {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    urlSearchParams.set('accessKey', accessKey);
    const newTail = '?' + urlSearchParams.toString();

    localStorage.removeItem('token');
    window.location.href = `${pathname}${newTail}`;
}
