import { useEffect, useState } from 'react';
import UserApi from '../../services/api/UserApi';
import * as alertAction from '../../store/actions/alert';
import * as ALERT from '../../constants/alert';
import { useDispatch, useSelector } from 'react-redux';
import * as ERROR from '../../constants/error';
import { deleteUrlParameter } from '../../services/methods';
import { RootState } from '../../index';
import * as userAction from '../../store/actions/user';

export interface IBonusData {
    eventId: number;
    bonusCredits: number;
}

export default function useEmailResubscription() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.user.info);

    const [isEmailResubscription, setIsEmailResubscription] = useState(false);
    const [isEmailResubscriptionSpecial, setIsEmailResubscriptionSpecial] = useState(false);
    const [bonusData, setBonusData] = useState<IBonusData | undefined>();

    const openRegisteredUserModal = () => setIsEmailResubscription(true);
    const closeRegisteredUserModal = () => setIsEmailResubscription(false);
    const openResubscriptionSpecialModal = () => setIsEmailResubscriptionSpecial(true);
    const closeResubscriptionSpecialModal = () => setIsEmailResubscriptionSpecial(false);

    const fetchMailSubscription = () => {
        setBonusData(undefined);

        new UserApi().getMailSubscription().then((res) => {
            if (res?.status && res?.result?.resubscribe && !res?.result?.subscribed) {
                if (res?.bonuses?.eventId) {
                    setBonusData(res?.bonuses);
                    openResubscriptionSpecialModal();
                } else {
                    openRegisteredUserModal();
                }
            }
        });
    };

    const sendEmailSubscription = () => {
        if (isEmailResubscriptionSpecial && bonusData?.eventId) {
            new UserApi()
                .postMailSubscription({
                    subscribe: true,
                    eventId: bonusData?.eventId,
                    confirm: true,
                })
                .then((res) => {
                    if (res?.status) {
                        dispatch(
                            alertAction.setMessage({
                                message: 'Please enjoy the services!',
                                title: `You've received ${bonusData?.bonusCredits} credits`,
                            }),
                        );
                        dispatch(userAction.setBalance());
                        closeResubscriptionSpecialModal();
                    } else {
                        dispatch(
                            alertAction.setMessage({
                                message: res?.message || ERROR.ERROR_REQUEST,
                                title: res?.title,
                            }),
                        );
                    }
                });
        } else {
            new UserApi().postMailSubscription({ subscribe: true }).then((res) => {
                if (res?.status) {
                    closeRegisteredUserModal();
                    dispatch(
                        alertAction.setCustomAlertType({
                            alertType: ALERT.EMAIL_CONFIRM_SENT,
                            message: userInfo?.email,
                        }),
                    );
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.message || ERROR.ERROR_REQUEST,
                            title: res?.title,
                        }),
                    );
                }
            });
        }
    };

    const sendConfirmEmailSubscription = () => {
        return new UserApi()
            .postMailSubscription({ subscribe: true, confirm: true })
            .then((res) => {
                if (res?.status) {
                    deleteUrlParameter('mail_resubscribe');
                }
            });
    };

    useEffect(() => {
        const searchStr = window?.location?.search;

        // ?utm_source=mailer&mail_resubscribe=true
        if (searchStr?.includes('mail_resubscribe=true')) {
            sendConfirmEmailSubscription().then(() => {
                if (searchStr?.includes('utm_source=mailer')) {
                    fetchMailSubscription();
                }
            });
        } else {
            if (
                searchStr?.includes('utm_source=mailer') ||
                searchStr?.includes('utm_source=resubscribemailer')
            ) {
                fetchMailSubscription();
            }
        }
    }, []);

    return {
        isEmailResubscription,
        isEmailResubscriptionSpecial,
        openRegisteredUserModal,
        closeRegisteredUserModal,
        openResubscriptionSpecialModal,
        closeResubscriptionSpecialModal,
        sendEmailSubscription,
        bonusData,
    };
}
