import React, { RefObject, useRef, useState } from 'react';
import SearchToolbar from '../../common/SearchFilter/SearchToolbar';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import * as modalsAction from '../../../store/actions/modals';
import SITE from '../../../config/site';
import { RootState } from '../../../index';
import { IManProfileReferenceCityItem } from '../../../models/profile/ManProfile.interface';
import { ISearchFilter } from '../../../store/reducers/search/index.interface';
import IconFilter from '../../Icons/IconFilter';

interface ISearchFilterDetailedNEW {
    detailedFilters: ISearchFilter;
    setDetailedFilters: (value: ISearchFilter) => void;
    nameFilter: string;
    handleSearchName: (value: string) => void;
    handleOnline: () => void;
    online: boolean;
    ageFromFilter: number | string;
    setAgeFromFilter: (value: number | string) => void;
    ageToFilter: number | string;
    setAgeToFilter: (value: number | string) => void;
    countryFilter: string;
    handleCountryFilter: (value: string) => void;
    inputCityRef: RefObject<HTMLInputElement>;
    cityList: IManProfileReferenceCityItem[];
    cityFilter: string;
    handleCityFilter: (value: string) => void;
}

export default function SearchFilterDetailedNEW({
    detailedFilters,
    setDetailedFilters,
    handleSearchName,
    handleOnline,
    online,
    ageFromFilter,
    setAgeFromFilter,
    ageToFilter,
    setAgeToFilter,
    countryFilter,
    handleCountryFilter,
    inputCityRef,
    cityList,
    cityFilter,
    handleCityFilter,
    nameFilter,
}: ISearchFilterDetailedNEW) {
    const selectRef = useRef<HTMLDivElement | null>(null);
    const select2Ref = useRef<HTMLDivElement | null>(null);

    const [isOpenFilterDetailedBlock, setIsOpenFilterDetailedBlock] = useState(false);

    const referenciesState = useSelector((state: RootState) => state.referencies);
    const modalsState = useSelector((state: RootState) => state.modals);

    const dispatch = useDispatch();
    useOutsideClicker(selectRef, () => setIsOpenFilterDetailedBlock(false), select2Ref);

    const openFilterDetail = () => {
        setIsOpenFilterDetailedBlock(!isOpenFilterDetailedBlock);
    };
    const onShow = () => setIsOpenFilterDetailedBlock(false);

    return (
        <>
            {SITE.ID !== 4 && (
                <div
                    className={`c3lh_search_filters_btn_wrap ${isOpenFilterDetailedBlock ? 'active' : ''}`}
                    ref={selectRef}
                    onClick={openFilterDetail}
                    data-testid="detailed-filter-btn"
                >
                    <IconFilter />
                    <span>Detailed filter</span>
                </div>
            )}

            {window.innerWidth < 1140
                ? modalsState?.activeSubmenu === 'filters' && (
                      <div
                          className="c3lh_search_filters_list_wrap"
                          ref={selectRef}
                          data-testid="detailed-filter-block"
                      >
                          {referenciesState.list && (
                              <SearchToolbar
                                  submitSearch={() => {
                                      dispatch(modalsAction.setActiveSubMenu(''));
                                      setIsOpenFilterDetailedBlock(false);
                                  }}
                                  countryFilter={countryFilter}
                                  handleCountryFilter={handleCountryFilter}
                                  inputCityRef={inputCityRef}
                                  cityFilter={cityFilter}
                                  handleCityFilter={handleCityFilter}
                                  cityList={cityList}
                                  ageFromFilter={ageFromFilter}
                                  setAgeFromFilter={setAgeFromFilter}
                                  ageToFilter={ageToFilter}
                                  setAgeToFilter={setAgeToFilter}
                                  nameFilter={nameFilter}
                                  handleSearchName={handleSearchName}
                                  handleOnline={handleOnline}
                                  online={online}
                                  formDataLooking={detailedFilters}
                                  setFormDataLooking={setDetailedFilters}
                                  heightList={referenciesState.list['height_list']}
                                  bodyList={referenciesState.list['body_list']}
                                  hairList={referenciesState.list['hair_list']}
                                  eyeList={referenciesState.list['eye_list']}
                                  maritalList={referenciesState.list['marital_list']}
                                  alcoholList={referenciesState.list['alcohol_list']}
                                  smokeList={referenciesState.list['smoke_list']}
                                  educationList={referenciesState.list['education_list']}
                                  childrenList={referenciesState.list['children_list']}
                                  moreChildrenList={referenciesState.list['morechildren_list']}
                                  religionList={referenciesState.list['religion_list']}
                                  occupationList={referenciesState.list['occupation_list']}
                                  reasonList={referenciesState.list['reason_list']}
                              />
                          )}
                      </div>
                  )
                : isOpenFilterDetailedBlock && (
                      <div
                          className="c3lh_search_filters_list_wrap"
                          ref={select2Ref}
                          data-testid="detailed-filter-modal"
                      >
                          {referenciesState.list && (
                              <SearchToolbar
                                  submitSearch={() => {
                                      dispatch(modalsAction.setActiveSubMenu(''));
                                      setIsOpenFilterDetailedBlock(false);
                                  }}
                                  ageFromFilter={ageFromFilter}
                                  setAgeFromFilter={setAgeFromFilter}
                                  ageToFilter={ageToFilter}
                                  setAgeToFilter={setAgeToFilter}
                                  nameFilter={nameFilter}
                                  handleSearchName={handleSearchName}
                                  handleOnline={handleOnline}
                                  online={online}
                                  formDataLooking={detailedFilters}
                                  setFormDataLooking={setDetailedFilters}
                                  heightList={referenciesState.list['height_list']}
                                  bodyList={referenciesState.list['body_list']}
                                  hairList={referenciesState.list['hair_list']}
                                  eyeList={referenciesState.list['eye_list']}
                                  maritalList={referenciesState.list['marital_list']}
                                  alcoholList={referenciesState.list['alcohol_list']}
                                  smokeList={referenciesState.list['smoke_list']}
                                  educationList={referenciesState.list['education_list']}
                                  childrenList={referenciesState.list['children_list']}
                                  moreChildrenList={referenciesState.list['morechildren_list']}
                                  religionList={referenciesState.list['religion_list']}
                                  occupationList={referenciesState.list['occupation_list']}
                                  reasonList={referenciesState.list['reason_list']}
                                  countryFilter={countryFilter}
                                  handleCountryFilter={handleCountryFilter}
                                  inputCityRef={inputCityRef}
                                  cityFilter={cityFilter}
                                  handleCityFilter={handleCityFilter}
                                  cityList={cityList}
                              />
                          )}
                          <div
                              className="c3lh_search_filters_list_btn_find"
                              onClick={onShow}
                              data-testid="submit-btn"
                          >
                              <img src="../../../img/c3s-filters-btn-find.svg" alt="" />
                              <span>Show matches</span>
                          </div>
                      </div>
                  )}
        </>
    );
}
