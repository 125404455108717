import React, { ChangeEvent, FormEvent, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import * as DICTIONARY_MODALS from '../../../../../constants/dictionary/modals';
import ProfileApi from '../../../../../services/api/ProfileApi';
import * as alertAction from '../../../../../store/actions/alert';
import * as userAction from '../../../../../store/actions/user';
import modalStyleProps from '../../../../../styles/react-modal/modal.style';
import * as modalsAction from '../../../../../store/actions/modals';
import * as loaderAction from '../../../../../store/actions/loader';
import styles from './styles.module.scss';
import * as ALERT from '../../../../../constants/alert';
import useTransition from '../../../../../hooks/transition/useTransition';
import { sendErrToSentry } from '../../../../../services/sentry';
import { RootState } from '../../../../../index';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconCross from '../../../../Icons/IconCross';

export default function ManageEmail() {
    const transition = useTransition();
    const dispatch = useDispatch();
    const modalsState = useSelector((state: RootState) => state.modals);
    const [emailInput, setEmailInput] = useState('');
    const [emailFromBase, setEmailFromBase] = useState('');

    const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailInput(e.target.value);
    };

    const closeModal = () => {
        dispatch(modalsAction.closeEmailEdit());
    };

    const afterOpen = () => {
        try {
            dispatch(loaderAction.setActiveGifLoader(true));
            new ProfileApi()
                .getEmail()
                .then((res) => {
                    if (res?.status) {
                        setEmailFromBase(res.message);
                        setEmailInput(res.message);
                    }
                })
                .finally(() => {
                    dispatch(loaderAction.setActiveGifLoader(false));
                });
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const changeEmail = (e: FormEvent) => {
        e.preventDefault();
        try {
            dispatch(loaderAction.setActiveGifLoader(true));
            new ProfileApi()
                .updateEmail(emailInput)
                .then((res) => {
                    if (res?.status) {
                        closeModal();
                        dispatch(userAction.setUserEmail(emailInput));
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.EMAIL_CONFIRM_SENT,
                                message: emailInput,
                            }),
                        );
                        transition.transitionWithHistoryPush('my-profile', `/my-profile/`);
                    } else {
                        dispatch(alertAction.setMessage({ message: res?.message }));
                    }
                })
                .finally(() => {
                    dispatch(loaderAction.setActiveGifLoader(false));
                });
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={modalsState.showEmailEditModal}
            onRequestClose={closeModal}
            onAfterOpen={afterOpen}
            ariaHideApp={false}
        >
            {!!modalsState.showEmailEditModal && (
                <div className={styles.email}>
                    <div className={styles.email_wrap}>
                        <h2 className={styles.email_title}>Confirm your email</h2>

                        <div className={styles.email_textinfo}>
                            {emailFromBase
                                ? DICTIONARY_MODALS.EDIT_EMAIL_MESSAGE_WITH_EMAIL
                                : DICTIONARY_MODALS.EDIT_EMAIL_MESSAGE_WITHOUT_EMAIL}
                            <span>
                                {emailFromBase
                                    ? DICTIONARY_MODALS.EDIT_EMAIL_MESSAGE_WITH_EMAIL_HIGHLIGHTED
                                    : ''}
                            </span>
                        </div>

                        <form onSubmit={changeEmail}>
                            <div className={styles.email_row}>
                                <div className={styles.email_item}>
                                    <label htmlFor="email" className={styles.email_label} />
                                    <div className={styles.email_input_wrap}>
                                        <input
                                            type="email"
                                            id="email"
                                            placeholder="Your Email"
                                            className={styles.email_input}
                                            value={emailInput}
                                            onChange={handleEmail}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button className={styles.email_save_btn} type="submit">
                                <IconCheckedCircle />
                                <span>Confirm email</span>
                            </button>
                        </form>
                    </div>
                    <div className={styles.email_close} onClick={closeModal}>
                        <IconCross />
                    </div>
                </div>
            )}
        </Modal>
    );
}
