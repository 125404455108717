import SITE from '../../config/site';
import { useSelector } from 'react-redux';
import { sendErrToSentry } from '../../services/sentry';
import { RootState } from '../../index';
import * as MIRROR from '../../constants/mirror';
import SupportApi from '../../services/api/SupportApi';

export default function useHandleZendesk() {
    const userState = useSelector((state: RootState) => state.user);

    const isNewZendeskWidget = MIRROR.NEW_ZENDESK_WIDGET.includes(SITE?.ID);

    const tagRanges = {
        '1_100': 1,
        '100_1000': 2,
        '1000_10000': 3,
        '10000+': 4,
    };

    // Function to get the numeric value of a tag range
    const getTagValue = (tag: string) => {
        const range = tag?.split(':')?.[1];
        // @ts-expect-error tagRanges[range]
        return tagRanges[range] || -1; // Return -1 if the tag is unknown
    };

    // Function to handle adding and removing tags
    const updatePaymentRangeTags = (newTag: string) => {
        try {
            if (!window?.zE) return;
            // Get the value of the new tag
            const newTagValue = getTagValue(newTag);

            if (newTagValue === -1) return;

            // Remove tags with smaller values
            Object.keys(tagRanges).forEach((tagRange) => {
                const tag = `payment_total:${tagRange}`;
                if (getTagValue(tag) < newTagValue) {
                    window.zE('webWidget', 'chat:removeTags', [tag]);
                }
            });

            // Add the new tag
            window.zE('webWidget', 'chat:addTags', [newTag]);
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const sendPaymentTotalTag = () => {
        const paymentTotal = userState?.info?.payment_total || 0;
        let newTag = '';

        if (paymentTotal >= 1 && paymentTotal < 100) {
            newTag = 'payment_total:1_100';
        } else if (paymentTotal >= 100 && paymentTotal < 1000) {
            newTag = 'payment_total:100_1000';
        } else if (paymentTotal >= 1000 && paymentTotal <= 10000) {
            newTag = 'payment_total:1000_10000';
        } else if (paymentTotal > 10000) {
            newTag = 'payment_total:10000+';
        }

        if (isNewZendeskWidget) {
            newUpdatePaymentRangeTags(newTag);
        } else {
            if (newTag?.length) {
                updatePaymentRangeTags(newTag);
            }
        }
    };

    const openZendesk = () => {
        try {
            if (!window?.zE) return;

            sendPaymentTotalTag();

            if (isNewZendeskWidget) {
                window.zE('messenger', 'open');
                window.zE('messenger', 'show');
            } else {
                if (userState?.giftActive) {
                    window.zE('webWidget', 'chat:addTags', [`gift_active:true`]);
                }
                if (window?.zE?.activate) {
                    window.zE.activate();
                }
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const zendeskPaymentFailed = async () => {
        try {
            if (!window?.zE) return;
            if (isNewZendeskWidget) return openZendesk();

            const isChatting = await window.zE('webWidget:get', 'chat:isChatting');

            if (!isChatting) {
                await window.zE('webWidget', 'chat:addTags', [SITE?.key]);
                await window.zE('webWidget', 'chat:send', 'My payment has Failed');
                await openZendesk();
            } else {
                await openZendesk();
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const zendeskPaymentFailedAgain = async () => {
        try {
            if (!window?.zE) return;
            if (isNewZendeskWidget) return openZendesk();

            const isChatting = await window.zE('webWidget:get', 'chat:isChatting');
            const isChattingSession = sessionStorage.getItem('zendesk_chat');

            if (!isChatting && !isChattingSession) {
                sessionStorage.setItem('zendesk_chat', '1');
                await window.zE('webWidget', 'chat:addTags', [SITE?.key]);
                await window.zE('webWidget', 'chat:send', 'My payment has Failed');
                await openZendesk();
            } else {
                await window.zE('webWidget', 'chat:send', 'My payment has Failed again');
                await openZendesk();
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const zendeskDeleteProfile = async () => {
        try {
            if (!window?.zE) return;
            if (isNewZendeskWidget) return openZendesk();

            const isChatting = await window.zE('webWidget:get', 'chat:isChatting');

            if (!isChatting) {
                await window.zE('webWidget', 'chat:send', 'I want to delete my profile');
                await openZendesk();
            } else {
                openZendesk();
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const zendeskRequestCall = async () => {
        try {
            if (!window?.zE) return;
            const isChatting = await window.zE('webWidget:get', 'chat:isChatting');

            if (!isChatting) {
                await window.zE('webWidget', 'chat:send', 'Contact me via phone');
                await openZendesk();
            } else {
                await openZendesk();
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    // START New Zendesk widget logic
    const zendeskLogin = async () => {
        try {
            const res = await new SupportApi().getZendeskToken();
            if (res?.status && res?.token) {
                window.zE(
                    'messenger',
                    'loginUser',
                    function jwtCallback(callback: (token: string) => void) {
                        callback(res?.token);
                    },
                    function loginCallback(error?: {
                        type: string;
                        reason: string;
                        message: string;
                    }) {
                        if (error) {
                            sendErrToSentry({
                                name: error?.type,
                                message: error?.message,
                                stack: undefined,
                            });
                        }
                    },
                );
                window.zE('messenger:set', 'locale', 'en');
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const zendeskLogout = () => {
        try {
            if (window?.zE && isNewZendeskWidget) {
                window?.zE('messenger', 'logoutUser');
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const newUpdatePaymentRangeTags = (totalPaymentTag?: string) => {
        try {
            if (!window?.zE) return;
            const tags: string[] = [SITE?.key];
            if (userState?.giftActive) tags.push('gift_active:true');
            if (totalPaymentTag?.length) tags.push(totalPaymentTag);

            window.zE('messenger:set', 'conversationTags', tags);
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };
    // END New Zendesk widget logic

    const zendeskIdentify = async () => {
        try {
            if (!window?.zE) return;
            if (userState?.info?.external_id) {
                if (isNewZendeskWidget) {
                    zendeskLogin();
                } else {
                    window.zE('webWidget', 'identify', {
                        name: `${userState.info?.name}, ${userState.info?.age} (${userState.info?.external_id})`,
                        email: userState.info?.email,
                    });
                    window.zE('webWidget', 'prefill', {
                        name: { value: userState.info?.name, readOnly: true },
                        email: { value: userState.info?.email, readOnly: true },
                    });
                }
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    return {
        openZendesk,
        zendeskPaymentFailed,
        zendeskPaymentFailedAgain,
        zendeskDeleteProfile,
        zendeskRequestCall,
        zendeskIdentify,
        zendeskLogout,
        isNewZendeskWidget,
    };
}
