import React from 'react';
import styles from './styles.module.scss';
import LettersChatMobileHeader from './LettersChatMobileHeader';
import LettersChatMobileForm from './LettersChatMobileForm';
import LettersChatMobileHistory from './LettersChatMobileHistory';
import useInitResize from '../../../hooks/resize/useInitResize';

export default function LettersChatMobile() {
    useInitResize();
    return (
        <>
            <div className={styles.mobile_chat} id="mobile-chat">
                <LettersChatMobileHeader />
                <div className={styles.mobile_chat_container}>
                    <div className={styles.mobile_chat_body_wrap}>
                        <LettersChatMobileHistory />
                        <LettersChatMobileForm />
                    </div>
                </div>
            </div>
        </>
    );
}
