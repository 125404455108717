import React from 'react';
import styles from '../../../styles/love-stories/style.module.scss';
import IconQuoteOpen from '../../Icons/IconQuoteOpen';
import IconQuoteClose from '../../Icons/IconQuoteClose';
import photo_comment_1 from '../../../img/love-stories-page/ls_photo_comment_1.png';
import photo_comment_2 from '../../../img/love-stories-page/ls_photo_comment_2.png';

export default function LoveStoriesComment() {
    return (
        <div className={styles.ls_comment_wrap}>
            <div className={styles.ls_inner_container}>
                <h2 className={`${styles.ls_title} ${styles.center}`}>Feedbacks</h2>
                <div className={styles.ls_comment_row}>
                    <div className={styles.ls_comment_img_wrap}>
                        <img src={photo_comment_1} alt="Photo comment" />
                    </div>
                    <div className={styles.ls_comment_content}>
                        <div className={`${styles.ls_comment_quot} ${styles.open}`}>
                            <IconQuoteOpen />
                        </div>
                        <div className={`${styles.ls_comment_quot} ${styles.close}`}>
                            <IconQuoteClose />
                        </div>
                        <span className={`${styles.ls_title} ${styles.name}`}>Gennadiy, 49</span>
                        <p className={styles.ls_text}>
                            Neat and nice platform, with all basic functions like filters and
                            messaging. Filter by age, looks, tastes, and don’t be afraid to write
                            first. People seek dating, so do it!
                        </p>
                        <span className={styles.ls_comment_content_data}>11.02.2021</span>
                    </div>
                </div>
                <div className={`${styles.ls_comment_row} ${styles.reverse}`}>
                    <div className={styles.ls_comment_img_wrap}>
                        <img src={photo_comment_2} alt="photo comment" />
                    </div>
                    <div className={styles.ls_comment_content}>
                        <div className={`${styles.ls_comment_quot} ${styles.open}`}>
                            <IconQuoteOpen />
                        </div>
                        <div className={`${styles.ls_comment_quot} ${styles.close}`}>
                            <IconQuoteClose />
                        </div>
                        <span className={`${styles.ls_title} ${styles.name}`}>Henry, 56</span>
                        <p className={styles.ls_text}>
                            I signed up to Sofia Date as I was looking to meet Eastern European
                            women for romance leading to love. The site is very easy to use and
                            there are a lot of gorgeous women online all of the time. I have had
                            lots of success in finding conversation, and I actually have my first
                            real-life meet up soon! I am very happy with this site.
                        </p>
                        <span className={styles.ls_comment_content_data}>24.06.2021</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
