import React, { memo } from 'react';
import styles from '../styles.module.scss';
import { useDispatch } from 'react-redux';
import ChatsAPI from '../../../../../services/api/ChatsApi';
import * as currentChatAction from '../../../../../store/actions/currentChat';
import * as allMediaAction from '../../../../../store/actions/allMedia';
import * as alertAction from '../../../../../store/actions/alert';
import * as ALERT from '../../../../../constants/alert';
import { makeCDN } from '../../../../../services/methods';
import * as modalsAction from '../../../../../store/actions/modals';
import { IMediaListVideoProps } from './index.interface';
import IconLockPlain from '../../../../Icons/IconLockPlain';
import IconPlayVideo from '../../../../Icons/IconPlayVideo';

const MediaListVideo = ({
    message_content,
    payed,
    chat_uid,
    id,
    recipient_external_id,
    thumb_link,
    checkBeforeBuyingMessageContent,
}: IMediaListVideoProps) => {
    const dispatch = useDispatch();

    const openVideoModal = (link: string) =>
        dispatch(modalsAction.openVideoContentModal({ link, attachment_payed: '1' }));

    const readVideo = () => {
        new ChatsAPI()
            .postReadVideo({
                userId: +recipient_external_id,
                messageId: +id,
                chatUid: chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(allMediaAction.setBuyedMedia(id));
                    dispatch(currentChatAction.readMessageVideo(id, res?.message_content));

                    if (res?.message_content) viewedVideo(res?.message_content);
                } else {
                    if (res?.message)
                        dispatch(
                            alertAction.setMessage({ message: res?.message, title: res?.title }),
                        );
                    else
                        dispatch(
                            alertAction.setCustomAlertType({
                                alertType: ALERT.ENOUGH_CREDITS_TO_VIEW,
                            }),
                        );
                }
            });
    };

    const viewedVideo = (link: string) => {
        new ChatsAPI().postViewMedia(+recipient_external_id, +id, chat_uid);
        openVideoModal(link);
    };

    return (
        <>
            <div
                className={`${styles.media_list_item} ${styles.video}`}
                onClick={() => {
                    if (!+payed)
                        checkBeforeBuyingMessageContent(ALERT.VIDEO_MESSAGE_CONTENT, () =>
                            readVideo(),
                        );
                    else viewedVideo(message_content);
                }}
            >
                <div className={styles.media_list_item_video_play}>
                    <IconPlayVideo />
                </div>
                {thumb_link?.length ? (
                    <img src={thumb_link} alt="video preview" />
                ) : (
                    <video preload="metadata">
                        <source src={makeCDN(message_content, 'VIDEO')} type="video/mp4" />
                    </video>
                )}
                {!+payed && (
                    <div className={styles.media_list_item_unpaid_wrap}>
                        <div className={styles.media_list_item_unpaid_main}>
                            <IconLockPlain />
                            <span>Unpaid</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default memo(MediaListVideo);
