import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MailboxApi from '../../services/api/MailboxApi';
import { SUPPORT_WOMAN } from '../../constants/user';
import * as MENU from '../../constants/menu';
import useTransition from '../transition/useTransition';
import * as purchaseAction from '../../store/actions/purchase';
import useHandleRouting from './useHandleRouting';
import { RootState } from '../../index';

export default function useHandlePayment() {
    const purchaseState = useSelector((state: RootState) => state.purchase);
    const userState = useSelector((state: RootState) => state.user);
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const history = useHistory();
    const dispatch = useDispatch();
    const transition = useTransition();
    const handleRouting = useHandleRouting();

    const disabledUserCallback = async () => {
        if (currentChatState?.info?.id === SUPPORT_WOMAN) return;
        if (!userState?.info?.external_id) return;

        const chatData = await new MailboxApi().createChatWithProfile(
            userState?.info?.external_id,
            SUPPORT_WOMAN,
        );
        if (!chatData?.status || !chatData.chat_uid) return;

        transition.transitionWithHistoryPush(MENU.OPEN_CHAT, `/chat/${chatData.chat_uid}`);
    };

    const getProposition = async () => {
        return (dispatch(purchaseAction.getPurchaseCreditsList()) as any).then((res: any) => {
            if (res?.data?.openSupportChat) return disabledUserCallback();

            return res;
        });
    };

    const onOpenPayment = () => {
        if (userState?.info?.disabledUser) return disabledUserCallback();
        if (!purchaseState?.paymentMethodList?.length) return;

        if (handleRouting.isRouting()) handleRouting.launch();
        else history.push('/checkout');
    };

    return {
        onOpenPayment,
        getProposition,
        disabledUserCallback,
    };
}
