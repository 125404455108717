import React, { useState } from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import ContentAttachModal from '../../Modals/ContentAttachModal/ImgContent';
import DnDContainer from '../DragAndDrop/Container';
import Loader from '../Loader';
import { IListImagesProps } from './index.interface';
import { IProfileGiftCurrentItem } from '../../../Profile/ProfileGifts/index.interface';
import EmptyFilesList from '../EmptyFilesList';

export default function ListImages({ type, images, status }: IListImagesProps) {
    const [modalContentIsOpen, setModalContentIsOpen] = useState<boolean>(false);
    const [currItem, setCurrItem] = useState<IProfileGiftCurrentItem>({
        link: '',
        attachment_payed: 0,
    });

    const closeContentModal = () => {
        setCurrItem({
            link: '',
            attachment_payed: 0,
        });
        setModalContentIsOpen(false);
    };

    return (
        <>
            {!images?.length && !status ? (
                <EmptyFilesList />
            ) : (
                <div
                    className="upload_popup_tabs_content_middle photo"
                    id="uptcmp"
                    data-testid="file-list"
                >
                    <DnDContainer
                        type={type}
                        data={images}
                        setModalContentIsOpen={setModalContentIsOpen}
                        setCurrItem={setCurrItem}
                    />
                    <Loader status={status} />
                    <Modal
                        style={modalStyleProps({ isAttachment: true, zIndex: 99999 })}
                        isOpen={modalContentIsOpen}
                        onRequestClose={closeContentModal}
                        ariaHideApp={false}
                    >
                        <ContentAttachModal item={currItem} closeModal={closeContentModal} />
                    </Modal>
                </div>
            )}
        </>
    );
}
