import React from 'react';
import styles from '../styles.module.scss';
import OpenQuestion from './OpenQuestion';
import RateQuestion from './RateQuestion';
import { OPEN_ANSWER_MAX_LENGTH } from '../../../../../../constants/length';
import { IMixSupportFeedbackProps } from './index.interface';

export default function MixSupportFeedback({
    onSubmit,
    formData,
    handleFormData,
    questionData,
}: IMixSupportFeedbackProps) {
    const isRateQuestion = questionData?.isScale && questionData?.title;
    const isOpenQuestion = questionData?.questionText;
    const isScoreAnswerValid =
        formData?.scoreAnswer && formData?.textAnswer?.length <= OPEN_ANSWER_MAX_LENGTH;
    const isTextAnswerValid =
        formData?.textAnswer?.length > 5 && formData?.textAnswer?.length <= OPEN_ANSWER_MAX_LENGTH;
    const isDisabled = isRateQuestion ? !isScoreAnswerValid : !isTextAnswerValid;

    return (
        <>
            {isRateQuestion && (
                <RateQuestion
                    title={questionData?.title}
                    scoreAnswer={formData?.scoreAnswer}
                    handleFormData={handleFormData}
                    isOpenQuestion={isOpenQuestion}
                />
            )}

            {isOpenQuestion && (
                <OpenQuestion
                    questionText={questionData?.questionText}
                    textAnswer={formData?.textAnswer}
                    handleFormData={handleFormData}
                    isRateQuestion={isRateQuestion}
                />
            )}

            <button
                type="button"
                className={`${styles.support_feedback_button_continue} ${isDisabled ? styles.disabled : ''}`}
                onClick={onSubmit}
                data-testid="send-support-feedback-btn"
                disabled={isDisabled}
            >
                <span className={styles.support_feedback_button_continue_text}>Send</span>
            </button>
        </>
    );
}
