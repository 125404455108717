import React from 'react';
import { Document, Page, Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import * as IMBRA from '../../../../../../constants/imbra';
import SITE from '../../../../../../config/site';

export default function ImbraDoc({
    supportEmail,
    imbraFormData,
}: {
    supportEmail: string;
    imbraFormData: any;
}) {
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
            backgroundColor: '#fff',
            width: 200,
        },
        title: {
            fontSize: 12,
            textAlign: 'center',
            marginBottom: 10,
            paddingBottom: 5,
            borderBottom: '1px solid #000',
        },
        text: {
            fontSize: 9,
            marginBottom: 10,
            fontWeight: 'normal',
        },
        textBold: {
            fontSize: 9,
            fontWeight: 'bold',
            marginBottom: 5,
        },
        table: {
            marginTop: 5,
            border: '1px solid #000',
            flexDirection: 'row',
        },
        tableCol: {
            borderRight: '1px solid #000',
            flexGrow: 1,
            alignItems: 'center',
        },
        tableHead: {
            fontSize: 10,
            padding: 5,
        },
        tableBody: {
            fontSize: 11,
            color: '#00008B',
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 5,
            paddingRight: 5,
        },
        line: {
            borderTop: '2px solid #000',
            width: '100%',
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 5,
        },
        question: {
            marginTop: 5,
            width: '100%',
        },
        questionText: {
            fontSize: 9,
            width: '100%',
        },
        checkBox: {
            fontSize: 10,
            color: '#000',
            width: 16,
            minWidth: 16,
            height: 12,
            minHeight: 12,
            border: '1px solid #000',
            borderRadius: 4,
            marginRight: 5,
            textAlign: 'center',
            fontWeight: 'bold',
        },
        checkBoxText: {
            fontSize: 11,
            marginRight: 10,
        },
        answer: {
            fontSize: 10,
            color: '#00008B',
            marginTop: 5,
            // textDecoration: 'underline',
            // textDecorationColor: '#000',
            backgroundColor: '#eee',
            borderRadius: 4,
            padding: 2,
            paddingLeft: 5,
            paddingRight: 3,
            lineHeight: 1.2,
        },
        bottomDate: {
            marginTop: 5,
            width: '60%',
            fontSize: 10,
        },
        bottomSign: {
            marginTop: 5,
            width: '40%',
            fontSize: 10,
        },
        bottomValue: {
            marginTop: 3,
            border: '1px solid #000',
            width: '70%',
            padding: 3,
            paddingLeft: 5,
            minHeight: 19,
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>PERSONAL DISCLOSURE STATEMENT</Text>
                    <Text style={styles.textBold}>
                        This form is used to meet the requirements of IMBRA 2005 (HR03657). This
                        information will be translated, if necessary, into the user’s native
                        language and provided to any user you wish to contact through the purchase
                        of their contact information.
                    </Text>
                    <Text style={styles.textBold}>
                        Please, print this page, fill in all required information below, date, sign
                        and email a scanned copy of this Personal Disclosure Statement and copy of
                        your passport (or driver license) to {SITE.copyrightsText} at{' '}
                        <Link>{supportEmail ?? ''}</Link>.
                    </Text>
                    <View style={styles.table}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableHead}>First Name</Text>
                            <View style={styles.line} />
                            <Text style={styles.tableBody}>{imbraFormData?.firstName}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableHead}>Middle Name</Text>
                            <View style={styles.line} />
                            <Text style={styles.tableBody}>{imbraFormData?.middleName}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableHead}>Last Name</Text>
                            <View style={styles.line} />
                            <Text style={styles.tableBody}>{imbraFormData?.lastName}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableHead}>Date of Birth (MM/DD/YYYY)</Text>
                            <View style={styles.line} />
                            <Text style={styles.tableBody}>{imbraFormData?.dateOfBirth}</Text>
                        </View>
                    </View>

                    {IMBRA.QUESTIONS_DATA.map((el) => (
                        <View style={styles.question} key={el?.id}>
                            <Text style={styles.questionText}>
                                {el?.question}
                                {!!el?.questionLink?.length && <Link>{el?.questionLink}</Link>}
                            </Text>
                            {!!+imbraFormData[`answer${el?.id}`]?.checkbox?.length && (
                                <View style={styles.row}>
                                    <Text style={styles.checkBox}>
                                        {imbraFormData[`answer${el?.id}`]?.checkbox === 'Yes' &&
                                            'X'}
                                    </Text>
                                    <Text style={styles.checkBoxText}>Yes</Text>
                                    <Text style={styles.checkBox}>
                                        {imbraFormData[`answer${el?.id}`]?.checkbox === 'No' && 'X'}
                                    </Text>
                                    <Text style={styles.checkBoxText}>No</Text>

                                    {!!+el?.questionDetails?.length && (
                                        <Text style={styles.questionText}>
                                            {el?.questionDetails}
                                        </Text>
                                    )}
                                </View>
                            )}
                            {!!(
                                !!+el?.questionDetails?.length &&
                                imbraFormData[`answer${el?.id}`]?.checkbox !== 'No'
                            ) && (
                                <Text style={styles.answer}>
                                    {imbraFormData[`answer${el?.id}`]?.answer?.trim()}
                                </Text>
                            )}
                        </View>
                    ))}

                    <View style={styles.row}>
                        <View style={styles.bottomDate}>
                            <Text>Date (MM/DD/YYYY)</Text>
                            <Text style={styles.bottomValue}>{imbraFormData?.todayDate}</Text>
                        </View>
                        <View style={styles.bottomSign}>
                            <Text>Signature</Text>
                            <Text style={styles.bottomValue}></Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}
