import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserApi from '../../services/api/UserApi';
import { convertPrice } from '../../services/methods';
import ContactsApi from '../../services/api/v3/Contacts';
import * as alertAction from '../../store/actions/alert';
import * as userAction from '../../store/actions/user';
import RequestApi from '../../services/api/Requests';
import * as ALERT from '../../constants/alert';
import * as ERROR from '../../constants/error';
import * as requestsAction from '../../store/actions/requests';
import * as USER from '../../constants/user';
import { compareDates } from '../../services/time';
import * as DATE from '../../constants/date';
import { RootState } from '../../index';
import { IContactRequestProfile } from '../../components/common/Modals/ContactRequestModal/index.interface';

interface IUseInitContactRequest {
    profile: IContactRequestProfile;
    closeContactRequestModal: () => void;
}

export default function useInitContactRequest({
    profile,
    closeContactRequestModal,
}: IUseInitContactRequest) {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.user);

    const [countCreditsInPercent, setCountCreditsInPercent] = useState<number>(0);
    const [countCredits, setCountCredits] = useState<number>(0);
    const [spendDays, setSpendDays] = useState<number>(0);
    const [isActiveImbraModal, setIsActiveImbraModal] = useState<boolean>(false);
    const [isLoadedData, setIsLoadedData] = useState<boolean>(false);
    const [firstContactRequestCreatedAt, setFirstContactRequestCreatedAt] = useState<string>('');

    const creditsLimit = +(userState?.prices?.GET_CONTACT_LIMIT || 0);
    const creditsPrice = +(userState?.prices?.GET_CONTACT || 0);

    const isConditionContactRequestDone =
        countCredits >= creditsLimit && spendDays >= USER.SPEND_DAYS_TO_CONTACT_REQUEST;
    const isUserGetContactBeforeUpdated = compareDates(
        DATE.ADDED_VIP_STATUS_DATE,
        firstContactRequestCreatedAt,
    );
    const isAllowGetContactRequest =
        (isConditionContactRequestDone && userState?.imbraData?.status === USER.IMBRA_VERIFIED) ||
        (countCredits >= creditsLimit && isUserGetContactBeforeUpdated);

    const openImbraModal = () => setIsActiveImbraModal(true);
    const closeImbraModal = () => setIsActiveImbraModal(false);

    const uploadImbraFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event?.target as HTMLInputElement;
        const file = input?.files?.[0];

        if (
            !file?.type?.includes('jpeg') &&
            !file?.type?.includes('jpg') &&
            !file?.type?.includes('png') &&
            !file?.type?.includes('pdf')
        ) {
            dispatch(
                alertAction.setMessage({
                    message: `Please upload a file in the pdf, jpg or png format`,
                    title: 'Wrong file format',
                }),
            );
            return false;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('womanExternalID', profile?.id?.toString());

        new ContactsApi().saveImbraFile(formData).then((res) => {
            if (res?.status) {
                dispatch(userAction.setImbraStatus(USER.IMBRA_IN_PROGRESS));
            } else {
                dispatch(alertAction.setMessage({ message: res?.message || ERROR.ERROR_REQUEST }));
            }
        });
    };

    const submitMakeRequest = () => {
        if (!isAllowGetContactRequest) return;

        new RequestApi().sendContactRequest({ female_external_id: profile?.id }).then((res) => {
            if (res?.data?.status) {
                dispatch(
                    alertAction.setCustomAlertType({ alertType: ALERT.SUCCESS_CONTACT_REQUEST }),
                );
                dispatch(requestsAction.getActiveContactRequests());
                dispatch(userAction.setBalance());
                closeContactRequestModal();
            } else {
                if (+res?.data?.credits) {
                    dispatch(
                        alertAction.setCustomAlertType({
                            alertType: ALERT.ENOUGH_CREDITS_REQUEST,
                            message: res?.data?.credits,
                        }),
                    );
                } else {
                    dispatch(
                        alertAction.setMessage({
                            message: res?.data?.message || ERROR.ERROR_REQUEST,
                            title: res?.data?.title,
                        }),
                    );
                }
            }
        });
    };

    useEffect(() => {
        (async function () {
            const resContactInfo = await new ContactsApi().getUserContactRequestInfo();
            if (resContactInfo?.status && resContactInfo?.result?.createdAt) {
                setFirstContactRequestCreatedAt(resContactInfo?.result?.createdAt);
            }

            if (profile?.id) {
                const resSpent = await new UserApi().getSpentCreditsForContactRequest(profile?.id);
                if (resSpent?.status) {
                    setCountCredits(+convertPrice(resSpent.message));
                    setCountCreditsInPercent(
                        (100 * +convertPrice(resSpent.message)) / creditsLimit,
                    );
                    setSpendDays(resSpent?.spendDays);
                }
            }

            if (userState?.info?.is_verified) {
                const resImbra = await new ContactsApi().checkImbraStatus(profile?.id);
                if (resImbra?.status) {
                    dispatch(userAction.setImbraData(resImbra?.result));
                }
            }

            setIsLoadedData(true);
        })();

        return () => {
            dispatch(userAction.setImbraData({}));
        };
    }, []);

    return {
        countCreditsInPercent,
        countCredits,
        isConditionContactRequestDone,
        spendDays,
        isActiveImbraModal,
        openImbraModal,
        closeImbraModal,
        creditsLimit,
        creditsPrice,
        imbraStatus: userState?.imbraData?.status,
        reasonImbraCanceled: userState?.imbraData?.reason,
        uploadImbraFile,
        submitMakeRequest,
        isLoadedData,
        isUserGetContactBeforeUpdated,
        isAllowGetContactRequest,
    };
}
