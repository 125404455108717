import React from 'react';

export default function IconCheckedCircle() {
    return (
        <svg
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="#446077"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17.5798 3.92753C15.6723 2.02007 13.1356 0.969336 10.438 0.969336C7.74033 0.969336 5.20365 2.02007 3.29618 3.92753C1.38868 5.83504 0.337988 8.37172 0.337988 11.0693C0.337988 13.767 1.38868 16.3036 3.29618 18.2111C5.20369 20.1186 7.74033 21.1693 10.438 21.1693C13.1356 21.1693 15.6723 20.1186 17.5798 18.2111C19.4873 16.3036 20.538 13.767 20.538 11.0693C20.538 8.37172 19.4873 5.83507 17.5798 3.92753L17.509 3.99824L17.5798 3.92753ZM10.438 19.7975C5.62537 19.7975 1.70986 15.882 1.70986 11.0693C1.70986 6.25671 5.62537 2.34121 10.438 2.34121C15.2506 2.34121 19.1661 6.25671 19.1661 11.0693C19.1661 15.882 15.2506 19.7975 10.438 19.7975Z" />
            <path d="M15.3306 8.14647L15.3306 8.14647C15.0617 7.91029 14.6304 7.91029 14.3615 8.14651L9.20019 12.6808L6.51451 10.3214C6.24562 10.0852 5.8143 10.0852 5.54538 10.3214C5.26885 10.5643 5.26886 10.9642 5.54537 11.2071L8.7156 13.9922C8.85134 14.1114 9.02706 14.1693 9.20015 14.1693C9.37325 14.1693 9.54901 14.1114 9.6847 13.9922L15.3306 9.03217C15.3306 9.03217 15.3306 9.03217 15.3306 9.03217C15.6071 8.78928 15.6071 8.38939 15.3306 8.14647Z" />
        </svg>
    );
}
