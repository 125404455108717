import ClickHistoryApi from '../../services/api/v3/ClickHistoryApi';
import useDetectedDevice from '../device/useDetectedDevice';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';

export default function useCheckSuccessfulDisplay() {
    const userState = useSelector((state: RootState) => state.user);

    const { fullInfo, mobileVendor } = useDetectedDevice();
    const { os, browser, mobile, device } = fullInfo();

    const sendVideoDisplayError = (contentSrc: string) => {
        if (!userState?.info?.external_id) return;

        new ClickHistoryApi().postVideoDisplayError({
            external_id: userState?.info?.external_id,
            contentSrc,
            mobileVendor,
            os,
            browser,
            mobile,
            device,
        });
    };

    return {
        sendVideoDisplayError,
    };
}
