import { useDispatch, useSelector } from 'react-redux';
import * as termsAction from '../../store/actions/terms';
import useTransition from '../transition/useTransition';
import { RootState } from '../../index';

export default function useInitTerm() {
    const termsState = useSelector((state: RootState) => state.terms);

    const dispatch = useDispatch();
    const transition = useTransition();

    const openTermModal = (pageName: string) => {
        transition.addTail(`?${pageName}=open`);
        dispatch(termsAction.openModel(pageName));
    };

    const closeTermModal = () => {
        transition.addTail(window?.location?.search?.replace(`${termsState?.pageName}=open`, ''));
        dispatch(termsAction.closeModal());
    };

    return {
        openTermModal,
        closeTermModal,
    };
}
