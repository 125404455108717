import React from 'react';
import styles from './styles.module.scss';
import { IVerifyPhoneCodeProps } from './index.interface';
import IconShieldPhone from '../../../../Icons/IconShieldPhone';
import IconCheckedCircle from '../../../../Icons/IconCheckedCircle';
import IconCircleInfoV2 from '../../../../Icons/IconCircleInfoV2';
import IconCross from '../../../../Icons/IconCross';

export default function VerifyPhoneCode({
    closeModal,
    verifyCode,
    handleVerifyCode,
    handleKeyDownEnter,
    submitVerifyCode,
    submitPhoneData,
    isApprovePhone,
    switchPhoneNumber,
}: IVerifyPhoneCodeProps) {
    return (
        <div className={styles.verify_phone_modal}>
            <div className={styles.verify_phone_title}>
                <IconShieldPhone />
                <span>{isApprovePhone ? 'Verified' : 'Verify'} your phone</span>
            </div>
            {isApprovePhone ? (
                <div className={styles.verify_phone_success_title}>
                    <IconCheckedCircle />
                    <span>Successfully!</span>
                </div>
            ) : (
                <>
                    <div className={styles.verify_phone_text}>
                        An SMS with a code has been sent to your phone number. Please enter the 4
                        digits you received.
                    </div>
                    <div className={styles.verify_phone_label}>SMS code</div>
                    <div className={styles.verify_phone_inputs_row}>
                        <input
                            type="text"
                            name="code1"
                            maxLength={1}
                            value={verifyCode.code1}
                            onChange={handleVerifyCode}
                            onKeyDown={handleKeyDownEnter}
                            autoFocus
                        />
                        <input
                            type="text"
                            name="code2"
                            maxLength={1}
                            value={verifyCode.code2}
                            onChange={handleVerifyCode}
                            onKeyDown={handleKeyDownEnter}
                        />
                        <input
                            type="text"
                            name="code3"
                            maxLength={1}
                            value={verifyCode.code3}
                            onChange={handleVerifyCode}
                            onKeyDown={handleKeyDownEnter}
                        />
                        <input
                            type="text"
                            name="code4"
                            maxLength={1}
                            value={verifyCode.code4}
                            onChange={handleVerifyCode}
                            onKeyDown={handleKeyDownEnter}
                        />
                    </div>
                </>
            )}
            {isApprovePhone ? (
                <>
                    <div className={styles.verify_phone_data_saved}>
                        <IconCircleInfoV2 />
                        <span>Your data has been saved</span>
                    </div>
                    <div className={styles.verify_phone_button} onClick={closeModal}>
                        Got it
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.verify_phone_button} onClick={submitVerifyCode}>
                        Check number
                    </div>
                    <div className={styles.verify_phone_receive_row}>
                        <span>Didn't receive SMS?</span>
                        <button type="button" onClick={submitPhoneData}>
                            Resend code
                        </button>
                    </div>
                    <div className={styles.verify_phone_diff_text}>
                        If you face difficulties with the number verification, you can call us at:
                        <br />
                        <div>
                            <a
                                href={`tel:${switchPhoneNumber()?.phone.split(' ').join('')}`}
                                rel="noopener noreferrer"
                            >
                                {switchPhoneNumber()?.phone}
                            </a>
                            &nbsp;({switchPhoneNumber()?.code})
                        </div>
                        <br />
                        Provide us with your account ID and we'll help you to manage this on our
                        side.
                    </div>
                </>
            )}
            <div className={styles.verify_phone_close} onClick={closeModal}>
                <IconCross />
            </div>
        </div>
    );
}
