import React from 'react';

export default function IconDrafts() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#446077"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6081 2.01175C14.5649 2.00403 14.5205 2 14.4751 2C14.4297 2 14.3853 2.00403 14.3422 2.01175H8.08631C5.59883 2.00298 3.55976 3.98595 3.50114 6.47308L3.50093 6.49075V17.1959C3.4506 19.719 5.45197 21.8084 7.97625 21.8646L7.99293 21.8647L8.01056 21.8649L8.03278 21.8651C8.04846 21.8652 8.07017 21.8653 8.09496 21.8647H16.0739C16.0841 21.8647 16.0942 21.8645 16.1043 21.8641C18.6014 21.7628 20.5693 19.7005 20.5529 17.2012V8.03775C20.5529 7.84413 20.4781 7.65802 20.344 7.51835L15.279 2.24235C15.1375 2.09503 14.9421 2.01175 14.7379 2.01175H14.6081ZM13.7251 3.51175H8.08493H8.08204V3.51174C6.41299 3.50532 5.04466 4.8336 5.00093 6.50006V17.2037L5.00075 17.2201C4.9638 18.9175 6.30804 20.3243 8.00451 20.3648L8.02367 20.3649L8.04508 20.3651L8.05724 20.3652L8.06023 20.3651L8.06075 20.3651C8.06881 20.3649 8.07687 20.3647 8.08493 20.3647H16.0579C17.7411 20.2888 19.065 18.8957 19.0529 17.2091L19.0529 17.2037H19.0529V8.984H17.0431L17.041 8.984C15.2077 8.97883 13.7251 7.49248 13.7251 5.659V3.51175ZM18.2317 7.484H17.0443C16.038 7.48066 15.2251 6.66521 15.2251 5.659V4.35219L18.2317 7.484ZM8.13819 15.3584C8.13819 14.9442 8.47397 14.6084 8.88819 14.6084H14.2882C14.7024 14.6084 15.0382 14.9442 15.0382 15.3584C15.0382 15.7726 14.7024 16.1084 14.2882 16.1084H8.88819C8.47397 16.1084 8.13819 15.7726 8.13819 15.3584ZM8.88721 10.856C8.473 10.856 8.13721 11.1917 8.13721 11.606C8.13721 12.0202 8.473 12.356 8.88721 12.356H12.2432C12.6574 12.356 12.9932 12.0202 12.9932 11.606C12.9932 11.1917 12.6574 10.856 12.2432 10.856H8.88721Z"
            />
        </svg>
    );
}
