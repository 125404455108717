import React, { MouseEventHandler } from 'react';
import styles from '../styles.module.scss';
import useLogout from '../../../../../../hooks/logout/useLogout';
import useInitSupport from '../../../../../../hooks/support/useInitSupport';
import SupportModal from '../../../SupportModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../index';

export default function SuccessBlock({
    onClose,
    isBlockClosing,
}: {
    onClose: MouseEventHandler<HTMLButtonElement>;
    isBlockClosing: boolean;
}) {
    const logout = useLogout();
    const initSupport = useInitSupport();
    const isProfileIdentification = useSelector(
        (state: RootState) => state.modals.isOpenProfileIdentificationModal,
    );

    return (
        <>
            <header
                className={`${styles.logout_header} ${isProfileIdentification ? styles.logout_header_static : ''}`}
            >
                {!isProfileIdentification && (
                    <button
                        type="button"
                        className={styles.help_button}
                        onClick={logout.handleClickLogout}
                        data-testid="email-btn"
                    >
                        <span>Logout</span>
                    </button>
                )}
                <button
                    type="button"
                    className={`${isBlockClosing ? styles.help_button : styles.help_button_with_close} ${isProfileIdentification ? styles.help_button_right : ''}`}
                    onClick={initSupport.openSupportModal}
                    data-testid="email-btn"
                >
                    <span>Help</span>
                </button>
            </header>
            <h3 className={`${styles.block_title} ${styles.block_title_success}`}>
                Successfully!{' '}
            </h3>
            <p className={`${styles.block_text} ${styles.block_text_success}`}>
                Your application will be processed within 3 days
            </p>
            {!isBlockClosing && (
                <button
                    type="button"
                    className={`${styles.block_button_main} ${styles.block_button_success}`}
                    onClick={onClose}
                >
                    Got it
                </button>
            )}
            <SupportModal {...initSupport} />
        </>
    );
}
