import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../index';
import { IUseAnimateMobileNotification } from './index.interface';
import { NOTIFY_MOBILE_DURATION_ANIMATION } from '../../constants/notify';

export default function useAnimateMobileNotification(): IUseAnimateMobileNotification {
    const notifyState = useSelector((state: RootState) => state.notify);

    const [isAnimate, setIsAnimate] = useState(false);

    useEffect(() => {
        if (notifyState?.list?.length > 0 || notifyState?.transactionList?.length > 0) {
            setIsAnimate(true);
        }
    }, [notifyState?.list?.length, notifyState?.transactionList?.length]);

    useEffect(() => {
        if (!isAnimate) return;

        const timerID = setTimeout(() => {
            setIsAnimate(false);
        }, NOTIFY_MOBILE_DURATION_ANIMATION);

        return () => clearTimeout(timerID);
    }, [isAnimate]);

    return {
        isAnimate,
    };
}
