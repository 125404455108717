import SITE from '../../config/site';
import DataLayerLogApi from '../api/DataLayerLogApi';
import { sendErrToSentry } from '../sentry';
import {
    IDataLayerRegular,
    IDataLayerEcommerce,
    LogConfig,
    LogType,
    IInputRegularData,
} from './index.interface';

async function sendLog<T extends LogType>(type: T, data: LogConfig[T]['type']): Promise<void> {
    const api = new DataLayerLogApi();

    try {
        const method = type === 'regular' ? 'regularLog' : 'ecommerceLog';
        await api[method](data);
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}

export const sendDataLayerRegularLog = (data: IInputRegularData | any, status: number): void => {
    try {
        const siteID = SITE.ID;
        const userData = data?.user_data;

        const newData: IDataLayerRegular = {
            value: data?.value ?? 0,
            currency: data?.currency ?? 'USD',
            general_data: {
                externalID: isNaN(Number(userData?.external_id))
                    ? 0
                    : Number(userData?.external_id),
                siteID,
                source: 'gtm',
                event: 'dataLayer',
                conversionType: data?.event ?? 'unknown',
                status,
            },
            user_data: {
                email: userData?.email ?? 'none@none.com',
                first_name: userData?.first_name ?? 'None',
                last_name: userData?.last_name ?? 'None',
                gender: userData?.gender ?? 'm',
                dob: userData?.dob ?? 'None',
                country: userData?.country ?? 'XX',
                city: userData?.city ?? 'None',
                external_id: Number(userData?.external_id) || 0,
                transaction_id: userData?.transaction_id ?? 'None',
                new_customer: Boolean(userData?.new_customer) ?? false,
                customer_lifetime_value: userData?.customer_lifetime_value ?? 0,
                ...(userData?.fbp && { fbp: userData?.fbp }),
                ...(userData?.fbc && { fbc: userData?.fbc }),
            },
        };
        sendLog('regular', newData);
    } catch (error) {
        sendErrToSentry(error as Error);
    }
};

export const sendDataLayerEcommerceLog = (
    data: IDataLayerEcommerce | any,
    status: number,
    externalID = 0,
): void => {
    try {
        const siteID = SITE.ID;
        const newData: IDataLayerEcommerce = {
            general_data: {
                externalID,
                siteID,
                source: 'gtm',
                event: 'dataLayer',
                conversionType: 'ecommerce',
                status,
            },
            ecommerce: {
                currencyCode: data?.ecommerce?.currencyCode ?? 'USD',
                impressions: data?.ecommerce?.impressions ?? [],
            },
        };
        sendLog('ecommerce', newData);
    } catch (error) {
        sendErrToSentry(error as Error);
    }
};
