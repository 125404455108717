import { ConnectionActionTypes } from '../../constants/connection';
import { Dispatch } from 'redux';

export function setConnection(status: string) {
    return function (dispatch: Dispatch<{ type: string; status: string }>) {
        return dispatch({
            type: ConnectionActionTypes.SET_CONNECTION,
            status,
        });
    };
}
