import React, { MouseEventHandler } from 'react';
import styles from './styles.module.scss';
import IconCross from '../../../../Icons/IconCross';
import IconChecked from '../../../../Icons/IconChecked';

export default function UnlockedFeature({
    onClose,
    type,
}: {
    onClose: MouseEventHandler<HTMLButtonElement>;
    type: string;
}) {
    const getContext = () => {
        switch (type) {
            case 'GIFT':
                return (
                    <>
                        <p>
                            You have discovered a new exclusive feature on our platform! Now you
                            have a unique opportunity to send original and beautiful virtual gifts.
                        </p>
                        <h3>Now available to you:</h3>
                        <ol>
                            <li>Sending original and beautiful virtual gifts</li>
                            <li>Express your feelings and sympathies in unusual ways</li>
                            <li>Create deeper and more meaningful connections through gifts</li>
                            <li>Choosing themed gifts</li>
                        </ol>
                    </>
                );
            case 'LETTERS':
                return (
                    <>
                        <p>
                            You have unlocked a new exclusive feature on our platform! You now have
                            the unique ability to send and receive personalized emails to help you
                            create deeper and more meaningful connections.
                        </p>
                        <h3>Now available to you:</h3>
                        <ol>
                            <li>Send and receive personalized letters</li>
                            <li>Create deeper and more meaningful connections through letters</li>
                            <li>Ability to save important letters</li>
                            <li>Receive prioritized responses to your letters</li>
                        </ol>
                    </>
                );
        }
    };
    return (
        <div className={styles.modal_wrap}>
            <header
                className={`${styles.modal_header} ${type === 'GIFT' ? styles.header_gift : ''}`}
            ></header>
            <div className={styles.modal_body}>
                <h2 className={styles.modal_title}>New feature unlocked!</h2>
                {getContext()}
                <button type="button" className={styles.modal_button} onClick={onClose}>
                    <span>
                        <IconChecked />
                    </span>
                    Got it
                </button>
            </div>
            <button type="button" className={styles.modal_close} onClick={onClose}>
                <IconCross />
            </button>
        </div>
    );
}
