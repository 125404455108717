import React from 'react';
import moment from 'moment';
import GirlSimpleItem from '../../../like/LikeItemSimple';
import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';
import { RootState } from '../../../../index';
import SearchCrossBanner from '../../SearchCrossBanner';
import SearchCrossBannerNewDesign from '../../SearchCrossBannerNewDesign';

export default function DefaultList() {
    const searchState = useSelector((state: RootState) => state.search);
    const userState = useSelector((state: RootState) => state.user);
    return (
        <ul className={styles.search_result_wrap}>
            {searchState?.list?.map((item, key) => {
                if ('target_site_id' in item) {
                    if (
                        userState?.info?.abtest?.toUpperCase() === 'B' &&
                        moment(userState?.info?.created_at).utc().isAfter('2025-04-03T00:00:00Z')
                    ) {
                        return <SearchCrossBannerNewDesign key={key} {...item} />;
                    } else {
                        return <SearchCrossBanner key={key} {...item} />;
                    }
                }
                return <GirlSimpleItem key={key} profile={item} />;
            })}
        </ul>
    );
}
