import React from 'react';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import IconGift from '../../../Icons/IconGift';
import { IGiftButtonProps } from './index.interface';
import useHandleGiftsPage from '../../../../hooks/gifts/useHandleGiftsPage';

export default function GiftButton({
    profile,
    closeWomanProfileModal,
    isModalOpen,
}: IGiftButtonProps) {
    const enableGift = +profile?.gifts;
    const handleActiveBlocks = useHandleActiveBlocks();
    const handleGiftsPage = useHandleGiftsPage();

    const openGift = () => {
        const womanProfile = {
            avatar: profile?.avatar,
            external_id: profile?.external_id,
            public_external_id: profile?.public_external_id,
            online: profile?.online,
            name: profile?.name,
            age: profile?.age,
            country_code: profile?.country_code,
            country_name: profile?.country_name,
            city: profile?.city_name,
        };

        if (enableGift) {
            if (isModalOpen && closeWomanProfileModal) closeWomanProfileModal();
            handleGiftsPage.openGiftsPage(womanProfile);
        }
    };

    if (handleActiveBlocks.isHideGift) return null;

    return window.innerWidth > 767 ? (
        <button
            type="button"
            className={`c3_woman_profile_head_item c3wphi_gift`}
            disabled={!enableGift}
            onClick={openGift}
        >
            <IconGift />
            <span className="c3_woman_profile_head_item_text">Send a gift</span>
            <span className="c3_woman_profile_gift_btn_tip">
                <strong>We apologize, but this user does not want to receive gifts.</strong>
                Please consider expressing your support in a different manner.
            </span>
        </button>
    ) : (
        <button
            type="button"
            className="c3_woman_profile_mob_btn"
            onClick={openGift}
            disabled={!enableGift}
        >
            <IconGift />
            <span>Send a gift</span>
            <span className="c3_woman_profile_gift_btn_tip">
                <strong>We apologize, but this user does not want to receive gifts.</strong>
                Please consider expressing your support in a different manner.
            </span>
        </button>
    );
}
