import React, { useRef, useState } from 'react';
import styles from '../styles.module.scss';
import useHandleLettersChat from '../../../../hooks/letters/useHandleLettersChat';
import { useDispatch, useSelector } from 'react-redux';
import useHandleChatListItem from '../../../../hooks/chat/useHandleChatListItem';
import useHandleChatFunctions from '../../../../hooks/chat/useHandleChatFunctions';
import useHandleMobileNotification from '../../../../hooks/notify/useHandleMobileNotification';
import ModerationModal from '../../../common/Modals/ModerationModal';
import useInitModeration from '../../../../hooks/support/useInitModeration';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import { closePanel } from '../../../../services/dom';
import useHandleActiveBlocks from '../../../../hooks/user/useHandleActiveBlocks';
import * as alertAction from '../../../../store/actions/alert';
import * as ALERT from '../../../../constants/alert';
import { RootState } from '../../../../index';

export default function LettersChatMobileHeader() {
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);

    const [isOpenNotify, setIsOpenNotify] = useState(false);
    const [isOpenMenu, setOpenMenu] = useState(false);

    const notifyRef = useRef(null);
    const menuRef = useRef(null);

    const dispatch = useDispatch();
    const handleChatFunctions = useHandleChatFunctions();
    const handleLettersChat = useHandleLettersChat();
    const handleChatListItem = useHandleChatListItem();
    const handleMobileNotification = useHandleMobileNotification();
    const initModeration = useInitModeration();
    const handleActiveBlocks = useHandleActiveBlocks();

    const handleIsOpen = () => {
        closePanel();
        setOpenMenu(!isOpenMenu);
    };
    const closeMenu = () => {
        setOpenMenu(false);
    };

    useOutsideClicker(notifyRef, () => setIsOpenNotify(false));
    useOutsideClicker(menuRef, closeMenu);

    const avatar =
        currentChatMailboxState?.info?.female_id ===
        currentChatMailboxState?.info?.sender_external_id
            ? currentChatMailboxState?.info?.sender_avatar
            : currentChatMailboxState?.info?.recipient_avatar;
    const online =
        currentChatMailboxState?.info?.female_id ===
        currentChatMailboxState?.info?.sender_external_id
            ? currentChatMailboxState?.info?.sender_online
            : currentChatMailboxState?.info?.recipient_online;
    const name =
        currentChatMailboxState?.info?.female_id ===
        currentChatMailboxState?.info?.sender_external_id
            ? currentChatMailboxState?.info?.sender_name
            : currentChatMailboxState?.info?.recipient_name;
    const age =
        currentChatMailboxState?.info?.female_id ===
        currentChatMailboxState?.info?.sender_external_id
            ? currentChatMailboxState?.info?.sender_age
            : currentChatMailboxState?.info?.recipient_age;

    const isActiveGift = handleActiveBlocks.isNewMailCategory1
        ? handleActiveBlocks.isPaymentUser
            ? !handleActiveBlocks.isHideGift
            : false
        : !handleActiveBlocks.isHideGift;
    const onBack = () => handleLettersChat.returnToLetterList();
    const onProfile = () =>
        handleChatListItem.openWomanProfileModal(currentChatMailboxState?.info?.female_id);
    const onFavorite = () => handleChatFunctions.handleSetFavorite();
    const onWriteMessage = () => handleChatFunctions.goTo();
    const onSendGift = () => handleChatFunctions.openGift();
    const onComplain = () => {
        initModeration.openModal(
            currentChatMailboxState?.info?.public_external_id,
            currentChatMailboxState?.info?.female_id,
        );
    };
    const onBlock = () => {
        dispatch(
            alertAction.setCustomAlertType({
                alertType: ALERT.ADD_TO_BLOCK_LIST,
                successCallback: handleChatFunctions.handleBlackList,
            }),
        );
    };
    const onNotify = () => {
        setIsOpenNotify(true);
        handleMobileNotification.openNotifyBlock();
    };

    return (
        <div className={styles.mobile_chat_header} id="mobile-chat-header">
            <button
                type="button"
                className={styles.mobile_chat_btn_back}
                title="Back"
                onClick={onBack}
            />
            <button type="button" className={styles.mobile_chat_profile} onClick={onProfile}>
                <div className={styles.mobile_chat_profile_photo_wrapper}>
                    {!!avatar && (
                        <img
                            className={styles.mobile_chat_profile_photo}
                            src={avatar}
                            width="32"
                            height="32"
                            alt=""
                        />
                    )}
                    <div
                        className={`${styles.mobile_chat_profile_status} ${online ? `${styles.active}` : ''}`}
                    />
                </div>
                {!!name && (
                    <span className={styles.mobile_chat_profile_name}>
                        {name || ''}, {age || ''}
                    </span>
                )}
            </button>
            <button
                type="button"
                className={`${styles.mobile_chat_btn_favourite} ${+currentChatMailboxState?.mailChatInfo?.female_favorite ? styles.selected : ''}`}
                onClick={onFavorite}
            />

            <nav className={styles.mobile_chat_navigation} ref={menuRef}>
                <button
                    type="button"
                    className={`${styles.mobile_chat_navigation_burger} ${isOpenMenu ? styles.active : ''}`}
                    onClick={handleIsOpen}
                />
                <ul
                    className={`${styles.mobile_chat_navigation_list} ${isOpenMenu ? styles.active : ''}`}
                >
                    <li className={styles.mobile_chat_navigation_list_item}>
                        <button
                            type="button"
                            className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon} ${styles.icon_letter}`}
                            onClick={onWriteMessage}
                        >
                            Write a message
                        </button>
                    </li>
                    {isActiveGift && (
                        <li className={styles.mobile_chat_navigation_list_item}>
                            <button
                                type="button"
                                className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon}  ${styles.icon_gift}`}
                                onClick={onSendGift}
                            >
                                Send a Gift
                            </button>
                        </li>
                    )}
                    <li className={styles.mobile_chat_navigation_list_item}>
                        <button
                            type="button"
                            className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon}  ${styles.icon_complaine}`}
                            onClick={onComplain}
                        >
                            Complain
                        </button>
                    </li>
                    <li className={styles.mobile_chat_navigation_list_item}>
                        <button
                            type="button"
                            className={`${styles.mobile_chat_navigation_btn} ${styles.btn_icon}  ${styles.icon_blacklist}`}
                            onClick={onBlock}
                        >
                            Add to a block list
                        </button>
                    </li>
                </ul>
            </nav>

            <button
                ref={notifyRef}
                type="button"
                className={`${styles.mobile_chat_btn_notification} ${isOpenNotify ? styles.selected : ''}`}
                onClick={onNotify}
            >
                {!!handleMobileNotification?.countUnread && (
                    <span className={styles.mobile_chat_btn_notification_counter}>
                        {handleMobileNotification?.countUnread}
                    </span>
                )}
                <span className={styles.mobile_chat_btn_notification_icon}></span>
            </button>

            <ModerationModal {...initModeration} />
        </div>
    );
}
