import React from 'react';
import styles from '../styles.module.scss';
import useHandleLettersChat from '../../../../hooks/letters/useHandleLettersChat';
import { useSelector } from 'react-redux';
import NewMessageInChat from '../../../common/Buttons/NewMessageInChatButton';
import ScrollDownButton from '../../../common/Buttons/ScrollDownButton';
import MessageRight from '../../LettersChat/MessageRight';
import MessageLeft from '../../LettersChat/MessageLeft';
import { RootState } from '../../../../index';

export default function LettersChatMobileHistory() {
    const currentChatMailboxState = useSelector((state: RootState) => state.currentChatMailbox);
    const userState = useSelector((state: RootState) => state.user);

    const handleLettersChat = useHandleLettersChat();

    return (
        <div className={styles.mobile_letters_content} id="mobile-chat-body">
            <div
                className={`c3l_letters_list_box_wrap mobile_c3l_letters_list_box_wrap ${handleLettersChat.isOpenForm ? '' : 'close_letter_form'} c3l_letters_scroll`}
                onScroll={handleLettersChat.onScrollList}
            >
                <NewMessageInChat
                    title={'View new letters'}
                    isActiveButton={currentChatMailboxState.isNewMessage}
                    onPressButton={handleLettersChat.scrollDown}
                />

                {handleLettersChat?.isOpenForm && (
                    <ScrollDownButton
                        bottomIndentation={handleLettersChat.isOpenForm}
                        isActiveButton={handleLettersChat.pageShiftDown > 1}
                        onPressButton={handleLettersChat.scrollDown}
                    />
                )}

                {!!currentChatMailboxState?.history?.length &&
                    currentChatMailboxState?.history?.map((item, key) => {
                        const isUserMail =
                            +item?.mail?.sender_id === +(userState?.info?.external_id || 0);

                        return isUserMail ? (
                            <MessageRight
                                item={item}
                                key={`${key}_${item?.mail?.id}`}
                                scrollPosition={handleLettersChat.scrollPosition}
                            />
                        ) : (
                            <MessageLeft
                                item={item}
                                key={`${key}_${item?.mail?.id}`}
                                scrollPosition={handleLettersChat.scrollPosition}
                                scrollToBookmark={handleLettersChat.scrollToBookmark}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
