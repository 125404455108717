import React, { useEffect, useState } from 'react';
import GooglePayButton from '@google-pay/button-react';
import PaymentApi from '../../../../services/api/v3/PaymentApi';
import { useDispatch, useSelector } from 'react-redux';
import * as purchaseAction from '../../../../store/actions/purchase';
import * as userAction from '../../../../store/actions/user';
import * as PURCHASE from '../../../../constants/purchase';
import * as purchaseActions from '../../../../store/actions/purchase';
import { sendErrToSentry } from '../../../../services/sentry';
import { RootState } from '../../../../index';
import { IGooglePayAuthenticationPage, IGooglePayFormProps } from './index.interface';

export default function GooglePayForm({ paymentData, method }: IGooglePayFormProps) {
    const dispatch = useDispatch();
    const purchaseState = useSelector((state: RootState) => state.purchase);

    const [authenticationPage, setAuthenticationPage] =
        useState<IGooglePayAuthenticationPage | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const onLoadPaymentData = async (paymentRequest: google.payments.api.PaymentData) => {
        try {
            const token = paymentRequest?.paymentMethodData?.tokenizationData?.token;
            dispatch(purchaseActions.setIsPaymentFailed(false));

            switch (method) {
                case PURCHASE.PAYMENT_METHOD_CARDPAY_GOOGLE_PAY: {
                    new PaymentApi()
                        .postCardPayGooglePayRequest(JSON.parse(token), paymentData?.orderID)
                        .then((res) => {
                            if (res?.status) {
                                setAuthenticationPage({ redirectUrl: res?.data?.redirect_url });
                            }
                        });
                    break;
                }
                case PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY: {
                    new PaymentApi()
                        .postPastaGooglePayRequest(JSON.parse(token), paymentData?.orderID)
                        .then((res) => {
                            if (res?.status) {
                                setAuthenticationPage({
                                    redirectUrl: res?.data?.redirectUrl,
                                    termUrl: res?.data?.termUrl,
                                    md: res?.data?.md,
                                });

                                setTimeout(() => {
                                    const block = document?.querySelector(
                                        '#form-gp-credit',
                                    ) as HTMLFormElement;
                                    if (block) block?.submit();
                                }, 500);
                            }
                        });
                    break;
                }
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    };

    const onClick = () => setIsLoading(true);
    const onCancel = () => {
        dispatch(purchaseAction.setIsPastaPaymentFailed(false));
        dispatch(userAction.isBuying(false));
        setIsLoading(true);
    };

    useEffect(() => {
        if (purchaseState?.isPaymentFailed) {
            setAuthenticationPage(null);

            if (isLoading) onCancel();
        }
    }, [purchaseState?.isPaymentFailed]);

    if (authenticationPage?.redirectUrl) {
        if (method === PURCHASE.PAYMENT_METHOD_PASTABANK_GOOGLE_PAY) {
            return (
                <>
                    <form
                        action={authenticationPage?.redirectUrl}
                        target="credits-iframe"
                        id="form-gp-credit"
                        method="post"
                    >
                        <input type="hidden" name="TermUrl" value={authenticationPage?.termUrl} />
                        <input type="hidden" name="MD" value={authenticationPage?.md} />
                    </form>
                    <iframe
                        id="frame-credit"
                        title="GooglePay"
                        name="credits-iframe"
                        style={{ border: 'none', overflow: 'hidden' }}
                        width="100%"
                        height="1100px"
                    />
                </>
            );
        }

        return (
            <iframe
                id="frame-credit"
                title="GooglePay"
                name="credits-iframe"
                src={authenticationPage?.redirectUrl}
                style={{ border: 'none', overflow: 'hidden' }}
                width="100%"
                height="1100px"
            />
        );
    }

    if (isLoading) {
        return <div>Loading, please wait...</div>;
    }

    return (
        <GooglePayButton
            environment={paymentData?.env}
            paymentRequest={paymentData?.paymentRequest}
            onLoadPaymentData={onLoadPaymentData}
            onClick={onClick}
            onCancel={onCancel}
        />
    );
}
