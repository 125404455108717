import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import useHandleBonusCredits from '../../../../hooks/bonusCredits/useHandleBonusCredits';
import styles from './styles.module.scss';
import IconCross from '../../../Icons/IconCross';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../index';
import IconCheckedCircle from '../../../Icons/IconCheckedCircle';

export default function BonusCreditsModal() {
    const eventState = useSelector((state: RootState) => state.settings.event);
    const { isOpenModal, handleCloseModal } = useHandleBonusCredits();

    const getContent = () => {
        switch (eventState?.eventId) {
            case 2:
                return (
                    <p className={styles.modal_text}>
                        Hey there! We’ve got something just for you! 🎁
                        <br />
                        🔥<strong> Exclusive 30% discount </strong> on your
                        <strong> 5 purchases </strong>
                        (starting from March 11) – but hurry, this offer expires
                        <strong> at the end of this week!</strong> <br />
                        💎 <strong>Plus, 50 FREE credits </strong> were added to your account on
                        March 11! Use them before they’re gone!
                    </p>
                );
            case 3:
                return (
                    <p className={styles.modal_text}>
                        Hey there! We’ve got something just for you! 🎁
                        <br />
                        🔥<strong> Exclusive 30% discount</strong> on your
                        <strong> 10 purchases </strong>
                        (starting from March 11) – but hurry, this offer expires
                        <strong> at the end of this week!</strong> <br />
                        💎<strong> Plus, 50 FREE credits </strong> were added to your account on
                        March 11! Use them before they’re gone!
                    </p>
                );
        }
    };

    return (
        <Modal
            style={modalStyleProps({ zIndex: 99999 })}
            isOpen={isOpenModal}
            onRequestClose={handleCloseModal}
            ariaHideApp={false}
        >
            <div className={styles.modal_wrap}>
                <h2 className={styles.modal_title}>
                    🎉 Welcome Back! You've Got a Special Gift! 🎉
                </h2>
                {getContent()}
                <button className={styles.modal_button} onClick={handleCloseModal} type="button">
                    <IconCheckedCircle />
                    Get Started
                </button>
                <button className={styles.modal_close} onClick={handleCloseModal} type="button">
                    <IconCross />
                </button>
            </div>
        </Modal>
    );
}
