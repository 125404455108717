import GiftAPI from '../../../services/api/Gifts';
import { Dispatch } from 'redux';
import { GiftsActionTypes } from '../../constants/gifts';
import { GIFTS_NOT_PAYMENT_USER } from '../../../constants/gifts';
import { ISendGiftData } from '../../../services/api/Gifts.interface';
import { IRecipientProfile } from '../../reducers/gifts/index.interface';

export function getGifts(isGiftPaymentUser = true) {
    return function (dispatch: Dispatch) {
        new GiftAPI().getGifts().then((res) => {
            const list = res?.data && res?.data?.result ? res?.data?.result : [];

            return dispatch({
                type: GiftsActionTypes.SAVE_GIFTS,
                data: isGiftPaymentUser
                    ? list
                    : list
                          ?.filter(
                              (item: any) =>
                                  !!GIFTS_NOT_PAYMENT_USER?.find((gift) => +gift?.id === +item?.id)
                                      ?.price,
                          )
                          ?.map((item: any) => ({
                              ...item,
                              price: GIFTS_NOT_PAYMENT_USER?.find(
                                  (gift) => +gift?.id === +item?.id,
                              )?.price?.toString(),
                          })),
            });
        });
    };
}

export function getGiftsByCategories(gifts: any[], filters: { category: string | null }) {
    return function (dispatch: Dispatch) {
        const giftsByCategories: any[] = [];
        gifts.forEach((gift: any) => {
            if (filters?.category && +filters?.category !== +gift?.category_id) {
                return;
            }

            if (!giftsByCategories[gift?.category_id]) {
                giftsByCategories[gift?.category_id] = [];
            }
            giftsByCategories[gift?.category_id].push(gift);
        });
        return dispatch({
            type: GiftsActionTypes.SAVE_GIFTS_BY_CATEGORIES,
            data: giftsByCategories,
        });
    };
}

export function addToCart(item: any) {
    return function (dispatch: Dispatch<{ type: string; data: any }>) {
        item['added_at'] = Date.now();
        return dispatch({
            type: GiftsActionTypes.ADD_TO_CART,
            data: item,
        });
    };
}

export function deleteFromCart(item: any) {
    return function (dispatch: Dispatch<{ type: string; data: number }>) {
        return dispatch({
            type: GiftsActionTypes.DELETE_FROM_CART,
            data: item?.id,
        });
    };
}

export function emptyCart() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: GiftsActionTypes.EMPTY_CART,
        });
    };
}

export function getCategories() {
    return function (dispatch: Dispatch) {
        new GiftAPI().getCategories().then((res) => {
            const list = res?.data && res?.data?.result ? res?.data?.result : [];
            const categories: any[] = [];
            list.forEach((c: any) => {
                categories[c?.id] = c;
            });
            return dispatch({
                type: GiftsActionTypes.SAVE_CATEGORIES,
                data: categories,
            });
        });
    };
}

export function setFilterCategoriesList(list: any[], limit: number) {
    return function (dispatch: Dispatch) {
        const filterCategoriesList: any[] = [];
        list.forEach((c) => {
            if (Object.keys(filterCategoriesList)?.length < limit) {
                filterCategoriesList[c?.id] = c;
            }
        });
        return dispatch({
            type: GiftsActionTypes.SAVE_FILTER_CATEGORIES_LIST,
            data: filterCategoriesList,
        });
    };
}

export function setFilterCategory(id: number) {
    return function (dispatch: Dispatch<{ type: string; data: number }>) {
        return dispatch({
            type: GiftsActionTypes.SAVE_FILTER_CATEGORY,
            data: id,
        });
    };
}

export function closeSuccesGift() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: GiftsActionTypes.CLOSE_SUCCESS_MODAL,
        });
    };
}

export function openSuccesGift() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: GiftsActionTypes.SHOW_SUCCESS_MODAL,
        });
    };
}

export async function sendGift(data: ISendGiftData, callback?: (res: any) => void) {
    return new GiftAPI().sendGift(data).then((res) => {
        if (callback) callback(res);
        return res;
    });
}

export function setRecipient(external_id: number) {
    return function (dispatch: Dispatch<{ type: string; data: number }>) {
        return dispatch({
            type: GiftsActionTypes.SET_RECIPIENT,
            data: external_id,
        });
    };
}

export function setRecipientProfile(profile: IRecipientProfile) {
    return function (dispatch: Dispatch<{ type: string; profile: IRecipientProfile }>) {
        return dispatch({
            type: GiftsActionTypes.SET_RECIPIENT_PROFILE,
            profile,
        });
    };
}
