import React from 'react';
import { SUPPORT_WOMAN } from '../../../../constants/user';
import { IProfileInfoProps } from './index.interface';
import avatar_placeholder from '../../../../img/placeholder.gif';
import IconProfileChat from '../../../Icons/IconProfileChat';

export default function ProfileInfo({ info, openWomanProfileModal }: IProfileInfoProps) {
    const avatar = info?.avatar || info?.photo_link || avatar_placeholder;
    const isSupportWoman = info?.id === +SUPPORT_WOMAN;
    const nameTitle = isSupportWoman
        ? `${info?.name || ''}`
        : `${info?.name || ''}, ${info?.age || ''}`;

    return (
        <div className="c3_head_left">
            <div
                className={`c3_head_photo_wrap ${info?.online ? 'online' : ''}`}
                onClick={() => !isSupportWoman && info?.id && openWomanProfileModal(info?.id)}
                data-testid="view-profile-btn"
            >
                <div className="c3_head_photo" data-testid="profile-avatar">
                    <img src={avatar} alt="" />
                    {!isSupportWoman && (
                        <div className="c3_head_photo_bottom">
                            <IconProfileChat />
                            <span>
                                View <br />
                                profile
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className={`c3_head_info ${info?.liked ? 'liked' : ''}`}>
                {!isSupportWoman && (
                    <span className="c3_head_id" data-testid="profile-id">
                        ID {info?.public_external_id || ''}
                    </span>
                )}
                <div
                    className="c3_head_name"
                    onClick={() => !isSupportWoman && info?.id && openWomanProfileModal(info?.id)}
                    data-testid="profile-name"
                >
                    {nameTitle}
                </div>
                {!isSupportWoman && (
                    <div className="c3_head_place" data-testid="profile-place">
                        {!!info?.country_code && (
                            <img
                                src={`/assets/flags/${info?.country_code?.toLowerCase()}.jpg`}
                                alt=""
                            />
                        )}
                        <span>{info?.city || ''}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
