import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import { IStoreChatListItemModel } from '../../../models/chat/index.interface';

export default function useSortChatListByCurrentChatUid() {
    const currentChatState = useSelector((state: RootState) => state.currentChat);

    const chatUid = currentChatState?.info?.chat_uid;

    /**
     @param arr (Array)
     @description Method for sorting array of chat list by chat_uid
     @return Array
	 */
    return (arr: IStoreChatListItemModel[]): IStoreChatListItemModel[] => {
        if (!chatUid) return [...arr];

        const filteredWithChatUID = arr?.filter((item) => item?.chat_uid === chatUid);
        const filteredWithOutChatUID = arr?.filter((item) => item?.chat_uid !== chatUid);

        return [
            ...(filteredWithChatUID?.length ? filteredWithChatUID : []),
            ...(filteredWithOutChatUID?.length ? filteredWithOutChatUID : []),
        ];
    };
}
