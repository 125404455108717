import React from 'react';

export default function IconResetPasswordSearchEmail() {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="#0965B1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2695_836)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.05556 4.05546C4.57128 3.53973 5.27076 3.25 6.0001 3.25H46.0001C46.7294 3.25 47.4289 3.53973 47.9446 4.05546C48.4604 4.57118 48.7501 5.27066 48.7501 6V6.39276L26.0001 22.4694L3.2501 6.39276V6C3.2501 5.27065 3.53983 4.57118 4.05556 4.05546ZM0.750102 7.05499V34C0.750102 35.3924 1.30323 36.7277 2.28779 37.7123C3.27236 38.6969 4.60772 39.25 6.0001 39.25H20.0001C20.6905 39.25 21.2501 38.6904 21.2501 38C21.2501 37.3096 20.6905 36.75 20.0001 36.75H6.0001C5.27076 36.75 4.57128 36.4603 4.05556 35.9445C3.53983 35.4288 3.2501 34.7293 3.2501 34V9.45399L25.2787 25.0209C25.7111 25.3264 26.2891 25.3264 26.7215 25.0209L48.7501 9.45399V22C48.7501 22.6904 49.3097 23.25 50.0001 23.25C50.6905 23.25 51.2501 22.6904 51.2501 22V7.05496C51.2502 7.0443 51.2502 7.03364 51.2501 7.02297V6C51.2501 4.60761 50.697 3.27225 49.7124 2.28769C48.7278 1.30312 47.3925 0.75 46.0001 0.75H6.0001C4.60772 0.75 3.27236 1.30312 2.28779 2.28769C1.30323 3.27226 0.750102 4.60761 0.750102 6V7.02294C0.749966 7.03363 0.749966 7.04431 0.750102 7.05499ZM40.0001 28.75C37.0164 28.75 34.1549 29.9353 32.0452 32.0451C29.9354 34.1549 28.7501 37.0163 28.7501 40C28.7501 42.9837 29.9354 45.8452 32.0452 47.955C34.1549 50.0648 37.0164 51.25 40.0001 51.25C42.5641 51.25 45.0379 50.3748 47.0219 48.7896L53.1162 54.8839C53.6044 55.3721 54.3958 55.3721 54.884 54.8839C55.3721 54.3958 55.3721 53.6043 54.884 53.1162L48.7897 47.0219C50.3748 45.0378 51.2501 42.5641 51.2501 40C51.2501 37.0163 50.0648 34.1549 47.9551 32.0451C45.8453 29.9353 42.9838 28.75 40.0001 28.75ZM33.8129 33.8129C35.4539 32.1719 37.6795 31.25 40.0001 31.25C42.3207 31.25 44.5463 32.1719 46.1873 33.8129C47.8282 35.4538 48.7501 37.6794 48.7501 40C48.7501 42.3207 47.8282 44.5463 46.1873 46.1872C44.5463 47.8282 42.3207 48.75 40.0001 48.75C37.6795 48.75 35.4539 47.8282 33.8129 46.1872C32.172 44.5463 31.2501 42.3207 31.2501 40C31.2501 37.6794 32.172 35.4538 33.8129 33.8129Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_2695_836">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
