import React, { memo } from 'react';
import styles from '../styles.module.scss';
import { ISliderItemProps } from './index.interface';

const SliderItem = ({
    womanProfile,
    openProfileModal,
    openGalleryModal,
    openChat,
    setLike,
}: ISliderItemProps) => {
    const avatar = womanProfile?.search_img;

    return (
        <>
            <div className={styles.girls_slider_item_wrap_2}>
                <div className={styles.girls_slider_item_wrap}>
                    <div
                        className={`${styles.girls_slider_item} ${styles.girls_slider_item_placeholder}`}
                    >
                        <div
                            className={styles.girls_slider_item_mob_click}
                            onClick={() => openProfileModal(womanProfile?.external_id)}
                        />
                        {!!+womanProfile?.online && (
                            <div className={styles.girls_slider_item_status}>Online</div>
                        )}
                        {avatar && (
                            <img
                                className={styles.girls_slider_item_photo}
                                src={avatar}
                                alt=""
                                onClick={() => openGalleryModal(womanProfile, womanProfile?.images)}
                            />
                        )}
                        <div className={styles.girls_slider_item_bg} />
                        {womanProfile?.external_id && (
                            <>
                                <div className={styles.girls_slider_item_buttons}>
                                    <div
                                        className={styles.girls_slider_item_chat_btn}
                                        onClick={() => openChat(womanProfile?.external_id)}
                                    >
                                        Chat
                                    </div>
                                    <div
                                        className={`${styles.girls_slider_item_like_btn} ${womanProfile?.liked ? styles.active : ''}`}
                                        onClick={() => {
                                            if (!womanProfile?.liked) {
                                                setLike(womanProfile?.external_id);
                                            }
                                        }}
                                    >
                                        {womanProfile?.liked ? 'Liked' : 'Like'}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(SliderItem);
