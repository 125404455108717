import { FAQActionTypes } from '../../constants/faq';
import { FAQActions, IFAQData, IFAQState } from './index.interface';

const initState = {
    faqData: [],
    activeTopicId: null,
    topicTitle: null,
    activeQuestionId: null,
};

export default function (state: IFAQState = initState, action: FAQActions): IFAQState {
    switch (action.type) {
        case FAQActionTypes.GET_FAQ_DATA:
            return { ...state, ...{ faqData: action?.data as IFAQData[] } };

        case FAQActionTypes.SET_ACTIVE_TOPIC_ID:
            return {
                ...state,
                ...{
                    activeTopicId: action?.activeTopicId,
                    topicTitle: state?.faqData?.find((el) => +el?.id === +action?.activeTopicId)
                        ?.title,
                },
            };

        case FAQActionTypes.SET_ACTIVE_QUESTION_ID:
            return {
                ...state,
                ...{
                    activeQuestionId: action?.activeQuestionId,
                },
            };

        default:
            return state;
    }
}
