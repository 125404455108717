import React from 'react';
import Modal from 'react-modal';
import modalStyleProps from '../../../../styles/react-modal/modal.style';
import LessCredits from './LessCredits';
import useInitModalLessCredits from '../../../../hooks/dom/useInitModalLessCredits';

export default function LessCreditsModal() {
    const initModalLessCredits = useInitModalLessCredits();

    return (
        <Modal
            style={modalStyleProps()}
            isOpen={initModalLessCredits?.isLessCreditModal}
            onRequestClose={initModalLessCredits?.closeModalLessCredits}
            ariaHideApp={false}
        >
            {!!initModalLessCredits?.isLessCreditModal && <LessCredits {...initModalLessCredits} />}
        </Modal>
    );
}
