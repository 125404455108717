import * as MENU from '../../constants/menu';
import { sendErrToSentry } from '../sentry';

export function scrollSmoothDownInLetterChat(): void {
    const letterList = document.querySelectorAll('.c3l_letters_list_item');

    if (letterList && letterList[letterList?.length - 1]) {
        letterList[letterList?.length - 1].scrollIntoView({
            block: 'end',
            behavior: 'smooth',
            inline: 'nearest',
        });
    }
}

export function gotoById(domId: string): void {
    const blockByDomID = document.getElementById(domId);
    if (blockByDomID) blockByDomID.scrollIntoView();
}

export function initUserLayoutContent(): void {
    try {
        const mainContent = document.querySelectorAll('.main_page_content');
        if (mainContent) mainContent.forEach((el) => el.classList.remove('main_page_content'));
    } catch (error) {
        sendErrToSentry(error as Error);
    }
}

export function scrollTopToMainChat(): void {
    const blockChatScroll = document.querySelector('.c3_chat_scroll');
    if (blockChatScroll) blockChatScroll.scrollTo(99999, 99999);
}

export function scrollTopToMainChatWithPagination(index: number | null = null): void {
    if (index === -1) return;

    const blockChatScroll = document.querySelector('.c3_chat_scroll');
    if (!index && blockChatScroll) return blockChatScroll.scrollTo(99999, 99999);

    if (index?.toString()?.includes('-') && index?.toString()?.split('-')[1]) {
        const blockByMessageID = document.getElementById(
            `mess-${index?.toString()?.split('-')[1]}`,
        );
        if (blockByMessageID) return blockByMessageID.scrollIntoView(false);
    } else {
        const blockByMessageID = document.getElementById(`mess-${index}`);
        if (blockByMessageID) {
            const messDiv = document.getElementById(`mess-${index}`);
            if (messDiv) return messDiv.scrollIntoView({ block: 'end', inline: 'nearest' });
        }
    }
}

export function closeMobileMenu(): void {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) blockOverlay.classList.remove('dark');

    if (!MENU.CHAT_PAGES.some((item) => window.location?.pathname.includes(item))) {
        if (blockOverlay) blockOverlay.classList.remove('active');

        const blockColumn2 = document.querySelector('.column-2');
        if (blockColumn2) blockColumn2.classList.remove('active');
    }

    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.remove('blur');

    const blockColumn3 = document.querySelector('.column-3 main');
    if (blockColumn3) blockColumn3.classList.remove('blur');

    const blockColumn4 = document.querySelector('.column-4');
    if (blockColumn4) blockColumn4.classList.remove('blur');

    const blockMobNotifyFixed = document.querySelector('.mob_notif_fixed');
    if (blockMobNotifyFixed) blockMobNotifyFixed.classList.remove('active');
}

export function openMobChatList(): void {
    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.add('active');
}

export function closeMobChatList(): void {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.remove('active');

    const blockC3Header = document.querySelector('.c3_header');
    if (blockC3Header) blockC3Header.classList.remove('active');
}

export function removeBlur(): void {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
        blockOverlay.classList.remove('blur');
    }
}

export function openFilterSearch(): void {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.add('dark');
        blockOverlay.classList.add('active');
    }

    const blockFilterWrap = document.querySelector('.c3lh_search_filters_btn_wrap');
    if (blockFilterWrap) blockFilterWrap.classList.add('active');
}

export function closeFilterSearch(): void {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockFilterWrap = document.querySelector('.c3lh_search_filters_btn_wrap');
    if (blockFilterWrap) blockFilterWrap.classList.remove('active');
}

export function openNotifyPanel(): void {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.add('dark');
        blockOverlay.classList.add('active');
    }

    const blockColumn4 = document.querySelector('.column-4');
    if (blockColumn4) blockColumn4.classList.add('active');

    const notifyBlock = document.querySelector('#notify_new');
    if (notifyBlock) notifyBlock.classList.add('active');

    const blockMobNotifyFixed = document.querySelector('.mob_notif_fixed');
    if (blockMobNotifyFixed) blockMobNotifyFixed.classList.add('active');

    const mobChat = document.querySelector('#mobile-chat');
    if (mobChat) {
        if (blockOverlay) blockOverlay.classList.add('full');
        if (blockColumn4) blockColumn4.classList.add('full');
    }
}

export function closePanel(): void {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
        blockOverlay.classList.remove('full');
    }

    const blockColumn4 = document.querySelector('.column-4');
    if (blockColumn4) blockColumn4.classList.remove('active');

    const notifyBlock = document.querySelector('#notify_new');
    if (notifyBlock) notifyBlock.classList.remove('active');

    const blockMobNotifyFixed = document.querySelector('.mob_notif_fixed');
    if (blockMobNotifyFixed) blockMobNotifyFixed.classList.remove('active');

    const mobChat = document.querySelector('#mobile-chat');
    if (mobChat) {
        if (blockOverlay) blockOverlay.classList.remove('full');
        if (blockColumn4) blockColumn4.classList.remove('full');
    }
}

export function scrollSmoothDownInLetterChatV2(): void {
    const blockLetterScroll = document.querySelector('.c3l_letters_scroll');
    if (blockLetterScroll) blockLetterScroll.scrollTo(99999, 99999);
}
