import { BannerActionTypes } from '../../constants/banners';
import { Dispatch } from 'redux';
import { ICrossBannersData } from '../../reducers/banners/index.interface';

export function initCrossBannerData(data: ICrossBannersData) {
    return function (dispatch: Dispatch<{ type: string; data: ICrossBannersData }>) {
        return dispatch({
            type: BannerActionTypes.INIT_CROSS_BANNERS,
            data,
        });
    };
}

export function setActiveStatusCrossBanners(status: boolean, startTime = '') {
    return function (dispatch: Dispatch<{ type: string; status: boolean; startTime: string }>) {
        return dispatch({
            type: BannerActionTypes.SET_ACTIVE_CROSS_BANNERS,
            status,
            startTime,
        });
    };
}

export function clearCrossBanners() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: BannerActionTypes.CLEAR_CROSS_BANNERS,
        });
    };
}

export function setOpenConfirmTermsModal(customBannerData: ICrossBannersData | null) {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: BannerActionTypes.OPEN_CONFIRM_TERMS_MODAL,
            customBannerData,
        });
    };
}

export function setCloseConfirmTermsModal() {
    return function (dispatch: Dispatch) {
        return dispatch({
            type: BannerActionTypes.CLOSE_CONFIRM_TERMS_MODAL,
        });
    };
}
