import React from 'react';

export default function IconVerificationPass() {
    return (
        <svg
            width="74"
            height="74"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="1"
                width="72"
                height="72"
                rx="36"
                stroke="#D4D4D4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6"
            />
            <path
                d="M45.9534 28.2948C44.6949 28.0632 43.4579 27.7265 42.2556 27.2882C40.8567 26.7778 39.5114 26.131 38.2391 25.3572C37.855 25.1234 37.414 24.9998 36.9644 25C36.5147 25.0002 36.0738 25.1242 35.69 25.3584C34.4176 26.1313 33.0725 26.7777 31.674 27.2882C30.4719 27.7263 29.2352 28.063 27.9768 28.2948C27.4209 28.3972 26.9185 28.6915 26.5573 29.1263C26.196 29.5611 25.9988 30.1089 26.0001 30.6743V36.2756C25.9924 38.5398 26.6192 40.7608 27.8096 42.6868C28.9999 44.6129 30.7061 46.1668 32.7347 47.1726L35.8798 48.7454C36.2169 48.9129 36.5881 49 36.9645 49C37.3409 49 37.7122 48.9129 38.0493 48.7454L41.1956 47.1726C43.224 46.1667 44.93 44.6127 46.1202 42.6867C47.3105 40.7607 47.9373 38.5397 47.9296 36.2756V30.6743C47.9307 30.1091 47.7336 29.5614 47.3725 29.1266C47.0114 28.6918 46.5092 28.3974 45.9534 28.2948ZM42.0902 35.3856L37.217 40.2589C37.1039 40.372 36.9696 40.4618 36.8218 40.523C36.674 40.5843 36.5156 40.6158 36.3556 40.6158C36.1957 40.6158 36.0372 40.5843 35.8894 40.523C35.7416 40.4618 35.6074 40.372 35.4943 40.2589L33.0576 37.8222C32.8316 37.5933 32.7053 37.2842 32.7063 36.9625C32.7073 36.6407 32.8356 36.3324 33.0631 36.1049C33.2906 35.8774 33.5989 35.7492 33.9206 35.7482C34.2424 35.7472 34.5515 35.8735 34.7804 36.0996L36.3557 37.6748L40.3676 33.663C40.5967 33.4379 40.9054 33.3125 41.2266 33.3139C41.5478 33.3154 41.8554 33.4436 42.0825 33.6707C42.3096 33.8978 42.4378 34.2054 42.4393 34.5266C42.4407 34.8477 42.3153 35.1565 42.0902 35.3856Z"
                fill="#00317B"
            />
        </svg>
    );
}
